import { Component, OnInit } from '@angular/core';
import { CRUDService } from 'src/app/utilities/crud.service';
import { MessageService, ConfirmationService } from 'primeng/api';
import { Router } from '@angular/router';
import { EncrDecrService } from 'src/app/utilities/encr-decr.service';

@Component({
  selector: 'app-register',
  templateUrl: './register.component.html',
  styleUrls: ['./register.component.css']
})
export class RegisterComponent implements OnInit {

  submitted: boolean;
  companyList: any[];
  sizeList: any;
  stateList: any[];
  countryList: any[];
  treeCrewsList: any;
  selectedState: any;
  selectedCountry: any;
  selectedSize: any;
  selectedTree: any;
  register: any;
  user: any;
  registerList: any;
  templist: any;
  staff: any;

  constructor(private crudService: CRUDService,
    private messageService: MessageService,
    private EncrDecr: EncrDecrService,
    private router: Router) { }

  ngOnInit(): void {
    this.crudService.getAllCompanies().subscribe((data: any[]) => {
      this.companyList = data;
    });

    this.crudService.getAll('State').subscribe((data: any[]) => {
      this.stateList = data;
    });

    this.crudService.getAll('Country').subscribe((data: any[]) => {
      this.countryList = data;
    });

    this.sizeList = [
      { id: '1', value: '2 to 5' },
      { id: '2', value: '6 to 10' },
      { id: '3', value: '11 to 25' },
      { id: '4', value: '26 to 50' },
      { id: '5', value: '51 to 100' }
    ];

    this.treeCrewsList = [
      { id: '1', value: '1' },
      { id: '2', value: '2' },
      { id: '3', value: '3' },
      { id: '4', value: '4' },
      { id: '5', value: '5 or more available for work' }
    ];



    this.register = {
      pwd: null,
      userid: null,
      business_name: null,
      business_ABN: null,
      first_name: null,
      last_name: null,
      email: null,
      phone: null,
      website: null,
      emp_size: null,
      street_address: null,
      invalid_login_count: '5',
      trading_name: null,
      mobile_no: null,
      main_contact_name: null,
      suburb: null,
      postcode: null,
      state_id: null,
      country_id: null,
      status: '1',
      remarks: null,
      priority: null,
      regn_status: null,
      margin: null,
      client_type_id: '3',
      account_name: null,
      account_BSB: null,
      account_number: null,
      default_depot: '2',
      account_email: null,
      no_of_crew: null,
      company_inactive_from: null,
      company_inactive_to: null,
      rebate: null,
      account_id: null,
      client_subtype: null,
      compliancelevel_id: null,
      addl_address: null,
      operations_circle: null,
      payment_terms: null,
      fence_required: '1'
    };

    this.user = {
      alternate_phone: null,
      colour: null,
      colour_scheme: null,
      company_id: null,
      created_at: null,
      created_by: '1',
      description: null,
      dob: null,
      email: null,
      first_name: null,
      id: null,
      image_name: null,
      invalid_passwd_count: '5',
      last_modified_by: null,
      last_name: null,
      last_updated_at: null,
      login_attempt: null,
      menu_type: null,
      mobile: null,
      owner: null,
      password: null,
      person_id: null,
      position_id: null,
      process_id: null,
      staff_id: null,
      status: '1',
      title: 'Mr.',
      user_group_id: '5',
      user_locked: null,
      username: null,
      work_phone: null
    };


    this.staff = {
      id: null,
      first_name: null,
      last_name: null,
      phone: null,
      company_id: null,
      mobile: null,
      email: null,
      role: null,
      stafftype: null,
      induction: null,
      client_type_id: null,
      inducted_date: null,
      username: null,
      password: null,
      create_login: null,
      process_id: 1,
      user_role_id: null,
      company_inactive_from: null,
      company_inactive_to: null,
    };


  }

  hideDialog() {
    this.router.navigate(['/login']);
  }



  options = {
    componentRestrictions: {
      country: ['AU']
    }
  }
  componentForm = {
    street_number: 'short_name',
    route: 'long_name',
    locality: 'long_name',
    sublocality_level_1: 'long_name',
    administrative_area_level_1: 'short_name',
    country: 'long_name',
    postal_code: 'short_name'
  };


  //   this.messageService.add({ severity: 'error', summary: 'Error', detail: 'Please enter all mandatory field to proceed with saving', life: 1000 });



  saveUser() {
    this.staff.id = null;
    this.staff.first_name = this.register.first_name;
    this.staff.last_name = this.register.last_name;
    this.staff.phone = this.register.phone;
    this.staff.company_id = this.register.id;
    this.staff.mobile = this.register.mobile_no;
    this.staff.email = this.register.email;
    this.staff.role = 10021;
    this.staff.stafftype = 10038;
    this.staff.induction = null;
    this.staff.client_type_id = null;
    this.staff.inducted_date = null;
    this.staff.username = this.register.userid;
    this.staff.password = this.register.pwd;
    this.staff.create_login = '1';
    this.staff.process_id = 1,
    this.staff.user_role_id = 1;
    this.staff.company_inactive_from = null;
    this.staff.company_inactive_to = null;

    this.staff.created_at = new Date().toDateString();
    this.staff.created_by = 1;
    this.crudService.Create(this.staff, 'CompanyStaffInfo').subscribe((CompanyStaffInfoData: any) => {
      if (CompanyStaffInfoData.success === 1) {
        this.staff.id = CompanyStaffInfoData.id;
        this.user.company_id = this.register.id;
        this.user.dob = null;
        this.user.person_id = null;
        this.user.status = '1';
        this.user.owner = '1';
        this.user.created_at = new Date();
        this.user.position_id = null;
        this.user.invalid_passwd_count = null;
        this.user.title = null;
        this.user.mobile = this.register.mobile_no;
        this.user.alternate_phone = this.register.phone;
        this.user.work_phone = this.register.phone;
        this.user.user_locked = '0';
        this.user.user_group_id = 1;
        this.user.created_at = new Date().toDateString();
        this.user.created_by = 1;
        this.user.menu_colour = 'layout-menu-dark';
        this.user.layout_colour = 'blue';
        this.user.colour_scheme = 'light';
        this.user.colour = 'green';
        this.user.menu_type = '1';
        this.user.homepage_url = '/home';
        this.user.first_name = this.register.first_name;
        this.user.last_name = this.register.last_name;
        this.user.email = this.register.email;
        this.user.staff_id = this.staff.id;
        this.user.login_attempt = '0';
        this.user.process_id = this.register.id;
        this.user.last_updated_at = new Date().toDateString();
        this.user.last_modified_by = null;
        this.user.username = this.register.userid;
        this.user.password = this.register.pwd;
        this.crudService.Create(this.user, 'User').subscribe(async (UserData: any) => {
          let encrypted = this.EncrDecr.set('123456$#@$^@1ERF', 'PID_' + this.register.process_id + '_COMP_ID_' + this.register.id);
          let decrypted = this.EncrDecr.get('123456$#@$^@1ERF', encrypted);
          //console.log('encrypted : ' + encrypted);
          //console.log('encrypted : ' + decrypted);
          let data: any = await this.crudService.updateCompanyToken(this.register.id, encrypted).toPromise();
          this.crudService.confirmRegistration(this.register.id, this.register.email).subscribe((mailData: any) => {
            //console.log(mailData);
            if (mailData.success === 200 || mailData.success === '200') {
              this.messageService.add({ severity: 'success', summary: 'Successful', detail: 'Your registration is successful. Please check your registered mail id for next steps', life: 1000 });
              this.router.navigate(['/login']);
            } else {
              this.messageService.add({ severity: 'error', summary: 'Error', detail: 'Registration Unsuccessful.', life: 1000 });
            }
          });
        });
      }
    });
  }

  saveRecord() {

    if (this.register.first_name !== '' && this.register.last_name !== '' && this.register.business_name !== '' && this.register.phone !== '' &&
      this.register.mobile_no !== '' && this.register.email !== '' && this.register.userid !== '' && this.register.pwd !== '' && this.register.street_address) {
      this.register.process_id = 1;
      this.register.client_subtype = null;
      this.register.client_category = null;
      this.register.client_type_id = null;
      this.register.emp_size = null;
      this.register.no_of_crew = null;
      this.register.compliancelevel_id = null;
      this.register.client_comm_type = null;
      this.register.payment_terms = null;
      this.register.status = '2';
      this.register.created_at = new Date().toDateString();
      this.register.created_by = 1;
      this.register.main_contact_name = this.register.first_name + ' ' + this.register.last_name;
     /*  this.crudService.Create(this.register, 'company').subscribe((data: any) => {
        if (data.success === 1) {
          this.register.id = data.id;
          this.saveUser();
        }
      }); */
    } else {
      this.messageService.add({ severity: 'error', summary: 'Error', detail: 'Please fill in All the required Information to proceed', life: 1000 });
    }
  }

  public AddressChange(address: any) {
    const fullAddress = [];

    // tslint:disable-next-line: prefer-for-of
    for (let i = 0; i < address.address_components.length; i++) {
      const addressType = address.address_components[i].types[0];
      if (this.componentForm[addressType]) {

        const val = address.address_components[i][this.componentForm[addressType]];

        if (addressType === 'street_number') {
          fullAddress[0] = val;
        } else if (addressType == 'route') {
          fullAddress[1] = val;
        }

        if (addressType === 'sublocality_level_1') {// suburb
          this.register.street_address = val;
        }
        if (addressType === 'locality') {// suburb
          this.register.suburb = val;
        } else if (addressType === 'administrative_area_level_1') {// state
          this.stateList.forEach((stat) => {
            if (stat.name === val) {
              this.register.state_id = stat.id;
              this.selectedState = stat;
            }
          });

        } else if (addressType === 'administrative_area_level_2') {// council
          //                                                $scope.suburb = val;
        } else if (addressType === 'country') {
          this.countryList.forEach((ctry) => {
            if (ctry.name === val) {
              this.register.country_id = ctry.id;
              this.selectedCountry = ctry;
            }
          });
        } else if (addressType === 'postal_code') {
          this.register.postcode = val;
        }

      }
    }
    const fullAdr = fullAddress.join(' ');
    this.register.street_address = fullAdr;
    //console.log(this.register.street_address);
  }
}