import { Component, Input, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ConfirmationService, MessageService } from 'primeng/api';
import { DialogService, DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { CRUDService } from 'src/app/utilities/crud.service';
import { JOBService } from 'src/app/utilities/job.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-reject-quote',
  templateUrl: './reject-quote.component.html',
  styleUrls: ['./reject-quote.component.css'],
  providers: [MessageService, ConfirmationService]
})
export class RejectQuoteComponent implements OnInit {
  @Input() jobId: any
  @Input() jobAllocationId: any;
  @Input() jobSummary: any;
  rejectList: any[];
  selectedRejectReason: any;
  otherReason: any = false;
  adviseTP: any;
  toEmail: any;
  toSms: any;
  smryData: any;
  companyId: any;
  userDetails: any;
  message_flow_value: any;
  reject_other: any;
  reject_comment: any;
  notifyOptions: any;
  env : any;
  scheduleId : any;
  params : any;

  constructor(
    private route: ActivatedRoute,
    private crudService: CRUDService,
    private messageService: MessageService,
    private confirmationService: ConfirmationService,
    private router: Router,
    public ref: DynamicDialogRef,
    public config: DynamicDialogConfig,
    public dialogService: DialogService,
    public JobService: JOBService,
    public dialogRef: DynamicDialogRef,
  ) {

    this.companyId = sessionStorage.getItem('company_id');
    this.userDetails = JSON.parse(sessionStorage.getItem('loggedInUser'));

    this.notifyOptions = [
      { value: '1', label: 'Yes' },
      { value: '2', label: 'No' }
    ];
    this.adviseTP = { value: '1', label: 'Yes' };

  }

  async ngOnInit() {
    this.env = environment;
    let data: any  = await this.crudService.getByValuesetName('QuoteRejectReasons').toPromise();
    this.rejectList = data;
    if (typeof this.jobId === 'undefined') {
      if (typeof this.config.data == 'undefined' || typeof (this.config.data.jobId) == 'undefined') {
        if (this.jobId == null || this.jobId === '') {
          this.jobId = parseInt(this.route.snapshot.paramMap.get('jobId'));
          if (this.jobAllocationId === null || this.jobAllocationId === '') {
            this.jobAllocationId = '1';
          } else {
            this.jobAllocationId = parseInt(this.route.snapshot.paramMap.get('jobAllocationId'));
            this.jobSummary = this.crudService.getDataByField('JobInfo', 'id', this.jobId);
          }
        }
        //this.showHeader = false;
      } else {
        this.jobId = this.config.data.jobId;
        this.jobAllocationId = this.config.data.jobAllocationId;
        this.jobSummary = this.config.data.jobSummary;
        //this.showHeader = true;
      }
    }

    this.crudService.getDataByField('Company', 'id', this.companyId).subscribe((data: any[]) => {
      let companyDetails = data[0];
      if (companyDetails.client_type_id === '4') {
        this.adviseTP = { value: '2', label: 'No' };
      } else {
        this.adviseTP = { value: '1', label: 'Yes' };
      }
    });

  }
  onRejectReasonChange(value) {
    if (value.valueset_detail_name === 'Others') {
      this.otherReason = true;
      this.message_flow_value = this.selectedRejectReason.name + this.reject_other;
      this.reject_comment = 'This message is regarding '+this.jobSummary.JobNo + ' @ ' + this.jobSummary.location + '. Thank you for your attendance. We are closing the job at our end. No further works or attendance required. If you would like to know more details, please contact Enviro Trees @1300077233';
    } else {
      this.otherReason = false;
      if (value.valueset_detail_name === 'Another TP was more successful') {
        this.reject_comment = 'This message is regarding '+this.jobSummary.JobNo + ' @ ' + this.jobSummary.location + '. Thank you for your attendance. Our client requested a minimum of 2 quotes for this job. Another TP was successful. The reason being, you were more expensive.';
      }else if (value.valueset_detail_name === 'Another TP was more Successful for ETA') {
        this.reject_comment = 'This message is regarding '+this.jobSummary.JobNo + ' @ ' + this.jobSummary.location + '. Thank you for your attendance. Another TP was successful. The reason being, our client required the work to be completed at the earliest possible. Another TP was able to undertake the works within the time frame required.';
      }else if (value.valueset_detail_name === 'Another TP was more Successful for Equipment') {
        this.reject_comment = 'This message is regarding '+this.jobSummary.JobNo + ' @ ' + this.jobSummary.location + '. Thank you for your attendance. Another TP was successful. If you would like to know more details, please contact Enviro Management @1300077233.';
      }else if (value.valueset_detail_name === 'Cash Payout') {
        this.reject_comment = 'This message is regarding '+this.jobSummary.JobNo + ' @ ' + this.jobSummary.location + '. Thank you for your attendance. The insured has accepted a cash payout and we are closing the job at our end. No further works or attendance required.';
      }else if (value.valueset_detail_name === 'Client Not Proceeding') {
        this.reject_comment = 'This message is regarding '+this.jobSummary.JobNo + ' @ ' + this.jobSummary.location + '. Thank you for your attendance. The client has decided not to proceed with Enviro Trees on this occasion. We are closing the job at our end. No further works or attendance required.';
      }
      this.message_flow_value = this.selectedRejectReason.name;
    }
  }


  rejectQuote() {
    this.companyId = sessionStorage.getItem('company_id');
    this.userDetails = JSON.parse(sessionStorage.getItem('loggedInUser'));
    //console.log('userinfo', this.userDetails);
    if (this.otherReason) {
        if(this.reject_other)
        {
          this.message_flow_value = this.reject_comment + this.reject_other;
        }
        else
        {
          this.message_flow_value = this.reject_comment;
        }
    }else{
      this.message_flow_value = this.reject_comment;
    }
    if (this.adviseTP.value == '1') {
      this.toEmail = this.jobSummary.info.TPEmail;
      this.toSms = this.jobSummary.info.TPMobile;
    } else {
      this.toEmail = '';
      this.toSms = '';
    }
    if(this.message_flow_value == "" || this.message_flow_value == null)
    {
      this.message_flow_value = 'This message is regarding '+this.jobSummary.JobNo + ' @ ' + this.jobSummary.location + '. Thank you for your attendance. We are closing the job at our end. No further works or attendance required. If you would like to know more details.Please contact our customer care team';
    }
    this.crudService.updateCostFormHeadStatus(this.jobId, this.jobAllocationId, 3).subscribe(async (res: any) => {
      //console.log('updateCostFormHeadStatus res', res);
      if (res.success == 1) {
        this.messageService.add({ severity: 'success', summary: 'Successful', detail: ' Reject Quote', life: 1000 });
        this.dialogRef.close()
      }
      const params = {
        email: {
          to: this.toEmail, cc: null, bcc: null,
          msg: this.message_flow_value,
          //+ 'This mail/sms is  to notify that the quote for Job Number: ' + this.jobSummary.JobNo + ' has been rejected. Site address: ' + this.jobSummary.location + '  Please call enviro frontier if you require more details.         1300077233 option 1',
          message: this.message_flow_value,
          // + 'This mail/sms is  to notify that the quote for Job Number: ' + this.jobSummary.JobNo + ' has been rejected. Site address: ' + this.jobSummary.location + '  Please call enviro frontier if you require more details.         1300077233 option 1',
          subject: 'Tree Manager: Quote unsuccessful for Job No ' + this.jobSummary.JobNo
        },
        sms: { to: this.toSms, message: this.message_flow_value},
          //+ 'This mail/sms is  to notify that the quote for Job Number: ' + this.jobSummary.JobNo + ' has been rejected. Site address: ' + this.jobSummary.location + '  Please call enviro frontier if you require more details.         1300077233 option 1' 
        type: 'sms-email',//sms //email//sms-email
        selectedTpl: null,
        sufix: null
      }
      this.params = params;
      await this.storeInSchedule('1');
      // this.crudService.sendTMV2Message(params).subscribe((res2: any[]) => {
      //   this.storeInSchedule(res2);
      // });
    });
  }

  storeInSchedule(obj) {
    let message = '';
    let sms = '';
    let email = '';
    let contactUI = '';
    if (this.jobSummary.info.TPEmail == '' && this.jobSummary.info.TPMobile == '') {
      message = 'Quote unsuccessful. Notification not sent to the TP';
      sms = '';
      email = '';
      if (sms == '1' && email == '1') {
        contactUI = 'N/A';
      } else {
        contactUI = '';
      }
    } else {
      message = 'Quote unsuccessful. Notification has been sent to the TP (Email/SMS)';
      sms = '2';
      email = '2';
    }
    let store_data = {
      id: null,
      job_id: this.jobId,
      job_alloc_id: this.jobAllocationId,
      process_id: 1,
      visit_type: null,
      status: null,
      sched_date: null,
      PMOnly: '2',
      sched_note: message + '. ' + this.message_flow_value,
      start_time: null,
      end_time: null,
      created_by: this.userDetails.id,
      created_at: new Date(),
      phoned: '1',
      phone_no: null,
      sms,
      sms_no: this.jobSummary.info.TPMobile,
      email,
      emailaddress: this.jobSummary.info.TPEmail,
      callback: '1',
      contactUI,
      comm_recipient: 'Quote Rejection',
      comm_recipient_subcatg: this.selectedRejectReason,
      message_flow: 1,
    }
    this.crudService.Create(store_data, 'JobSchedule').subscribe((data: any) => {
      this.scheduleId = data.id;

      if(this.env.emailRoutes.common == 'sendgrid')
      {
        let to = this.toEmail;
        let subject = 'Enviro Trees : Quote unsuccessful for Job No ' + this.jobSummary.JobNo;
        let message = this.message_flow_value
        const datobj = {
          subject : subject,
          body : message,
          toEmail : to,
          ref_id : this.scheduleId,
          ref_type : 'JobSchedule'
          }
        this.crudService.sendmailSendgrid(datobj,'commonMail').subscribe((res2: any[]) => {
           this.crudService.sendTMV2Message(this.params).subscribe((res2: any[]) => {
            
            });
          });
      }
      else if(this.env.emailRoutes.common == 'nodemailer')
      {
        this.crudService.sendTMV2Message(this.params).subscribe((res2: any[]) => {
        //  this.storeInSchedule(res2);
        });
      }
    });
  }

}
