import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { TpJobAdminComponent } from './tp-job-admin/tp-job-admin.component';
import { TpJobCostingComponent } from './tp-job-costing/tp-job-costing.component';
import { TpJobDetailHeader } from './tp-job-detail-header/tp-job-detail-header.component'
import { TpJobDetailsComponent } from './tp-job-details/tp-job-details.component';
import { TpJobDocumentsComponent } from './tp-job-documents/tp-job-documents.component'
import { TpJobImagesComponent } from './tp-job-images/tp-job-images.component';
import { TpJobInvoiceSendComponent } from './tp-job-invoice-send/tp-job-invoice-send.component';
import { TpJobOperationsComponent } from './tp-job-operations/tp-job-operations.component';
import { TpJobListComponent } from './tp-job-list/tp-job-list.component';
import { TpJobRiskcontactComponent } from './tp-job-riskcontact/tp-job-riskcontact.component';
import { TpJobScheduleComponent } from './tp-job-schedule/tp-job-schedule.component';
import { TpShfComponent } from './tp-shf/shf.component';
import { TpScheduleAdminComponent } from './tp-schedule-admin/tp-schedule-admin.component';



const routes: Routes = [
  { path: 'admin', component: TpJobAdminComponent },
  { path: 'costing/:jobId/:jobAllocationId', component: TpJobCostingComponent },
  { path: 'detail/:jobId/:AllocId', component: TpJobDetailsComponent },
  { path: 'document/:jobId/:jobAllocationId', component: TpJobDocumentsComponent },
  { path: 'images/:jobId/:jobAllocationId', component: TpJobImagesComponent },
  { path: 'myjobs', component: TpJobOperationsComponent },
  { path: 'joblist', component: TpJobListComponent },
  { path: 'schedule', component: TpJobScheduleComponent },
  { path: 'shf', component: TpShfComponent },
  { path: 'schedulecreate', component: TpScheduleAdminComponent },
  { path: 'TpJobInvoiceSendComponent', component: TpJobInvoiceSendComponent },

];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class TpJobRoutingModule { }
