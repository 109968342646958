import { Component, ViewChild } from '@angular/core';
import { Router, NavigationStart, NavigationEnd, ActivationStart, RouterOutlet } from '@angular/router';
import 'rxjs/add/operator/filter';
import 'babel-polyfill';
import 'rxjs/add/observable/interval';
import { Location } from '@angular/common'


@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./style.css']
})

export class AppComponent {

    private history: any[] = [];
    public data: any;
    navigationSubscription: any;
    currentUser: any;
    @ViewChild(RouterOutlet) outlet: RouterOutlet;

    constructor(private router: Router, private location: Location) {
        router.events
            .filter(event => event instanceof NavigationStart)
            .subscribe((event: NavigationStart) => {
                let loggedInUser = localStorage.getItem('loggedInUser');
                let currentuser = localStorage.getItem('currentUser')
                if (loggedInUser !== '') {
                    sessionStorage.setItem('loggedInUser', loggedInUser);
                    sessionStorage.setItem('currentUser', currentuser);
                    this.currentUser = JSON.parse(sessionStorage.getItem('loggedInUser'));     
                } else {
                    this.currentUser = null;
                }                
                if (event.url !== '/login' && event.url !== '/register' && event.url !== '/termsofuse'
                    && event.url !== '/privacypolicy' && event.url !== '/forgotPassword' && event.url !== '/sign-up'
                    && event.url !== '/signup' && !event.url.includes('/channel/')  && !event.url.includes('/tpcompliance/')
                    && !event.url.includes('/chat/') && !event.url.includes('/channel/')
                    && event.url !== '/request-reset' && !event.url.includes('/registration/')
                    && event.url.indexOf('/reset-password') === -1 && event.url.indexOf('/authredirect/') === -1)  {
                    if (sessionStorage.length == 0 || (this.currentUser == null) || (this.currentUser === '')) {
                      //  router.navigate(['/login']);
                    } else {

                        let index = this.history.findIndex(val => (val.url == event.url));

                       // sessionStorage.setItem('menuData', '');
                        if (event.url == sessionStorage.getItem('selectedRoute')) {
                            if (index > -1) {
                                if(typeof router.getCurrentNavigation().extras?.state != 'undefined'){
                                    sessionStorage.setItem('menuData', JSON.stringify(router.getCurrentNavigation().extras?.state));
                                }
                            } else {
                                // debugger
                                if(typeof router.getCurrentNavigation().extras?.state != 'undefined'){
                                    sessionStorage.setItem('menuData', JSON.stringify(router.getCurrentNavigation().extras?.state));
                                    //this.history.push({ url: event.url, menuData: router.getCurrentNavigation().extras?.state });
                                }
                            }
                        } else {
                            if (index > -1) {
                                sessionStorage.setItem('menuData', JSON.stringify(this.history[index]?.menuData));
                                //this.history[index] = { url: event.url, state: router.getCurrentNavigation().extras?.state };
                            } else {
                                sessionStorage.setItem('menuData', JSON.stringify(router.getCurrentNavigation().extras?.state));
                                //this.history.push({ url: event.url, menuData: router.getCurrentNavigation().extras?.state });
                            }
                            sessionStorage.setItem('navHistory', JSON.stringify(this.history));
                        }
                    }
                } else {
                    localStorage.clear();
                    sessionStorage.setItem('loggedInUser', null);
                    window.sessionStorage.clear();
                    sessionStorage.setItem('loggedInUser', null);
                    sessionStorage.setItem('currentUser',null);
                    sessionStorage.setItem('auth_token', null);
                    sessionStorage.setItem('user_id',null);
                }
            });
        router.events
            .filter(event => event instanceof ActivationStart)
            .subscribe((e: ActivationStart) => {
                if (e.snapshot.outlet === 'outletname')
                    this.outlet.deactivate();
            });

        /* router.events
            .filter(event => event instanceof NavigationEnd)
            .subscribe((e: NavigationEnd) => {
                sessionStorage.setItem('menuData', '');
            }); */

        /* this.navigationSubscription = this.router.events.subscribe((e: any) => {
            if (e instanceof NavigationEnd) {
                if (this.navigationSubscription) {
                    this.navigationSubscription.unsubscribe();
                }
            }
        }); */
    }

    /* back(): void {
        this.history.pop()
        if (this.history.length > 0) {
            this.location.back()
        } else {
            this.router.navigateByUrl('/')
        }
    } */
}