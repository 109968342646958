import { Component, OnInit } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { ActivatedRoute } from '@angular/router';
import { ConfirmationService, MessageService } from 'primeng/api';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { CRUDService } from 'src/app/utilities/crud.service';
import { environment } from 'src/environments/environment';
import { CommonService } from 'src/app/utilities/common.service';
import { Table } from 'primeng/table';
import { workflowsteps } from './workflow-steps'


@Component({
  selector: 'app-workflow-steps',
  templateUrl: './workflow-steps.component.html',
  styleUrls: ['./workflow-steps.component.scss']
})
export class WorkflowStepsComponent implements OnInit {
  paginationvalue:any;
  dateformat:any;
  cols: any;
  selectedWorkflowDefn: any;
  dataList: any[];
  exportColumns: any;
  speedDial: Boolean = false;
  exportdisplay:Boolean = true;
  workflow: any;
  workflowDialog: Boolean = false;
  submitted: Boolean = true;
  category: any[];
  rowData: any;
  jobPriority: any;
  selectedRecords: any[] = [];
  //list: any[] = [];
  workflowDefnList:any;
  wf_code: any;
  isEditAll: boolean = false;
  isActive: boolean;
  selectedCode: any[];
  constructor(

    private CrudService: CRUDService,
    private messageService: MessageService,
    private confirmationService: ConfirmationService,
    public ref: DynamicDialogRef,
    public config: DynamicDialogConfig,
    public sanitizer: DomSanitizer,
    private route: ActivatedRoute,
    private commonservice: CommonService
  ) { }

  async ngOnInit() {
    this.dateformat=environment.dateFormat
    this.paginationvalue=environment.paginatorValue;
    //this.selectedWorkflowDefn = await this.CrudService.getAllData('WorkflowDefinition').toPromise();
    this.selectedWorkflowDefn = null;
    this.cols = [
      //{ field: 'id', header: 'id' },
      { field: 'created_by', header: 'Created By' },
      { field: 'created_at', header: 'Created at' },
      { field: 'updated_by', header: 'Updated By' },
      { field: 'updated_at', header: 'Updated at' },
      { field: 'wf_code', header: 'Workflow Code' },
      { field: 'name', header: 'Name' },
      { field: 'seq', header: 'Seq' },
      { field: 'sla', header: 'Sla' },
      { field: 'status', header: 'Status' },
      { field: 'skip_yn', header: 'Skip yn' },
    ];
    
    //console.log("check-list",this.cols)

    this.exportColumns = this.cols.map(col => ({ title: col.header, dataKey: col.field }));
    this.exportdisplay = await this.commonservice.exportCheck();

    this.getWorkflow();
  }
  async getWorkflow() {
    let selectedWorkflowDefn: any = await this.CrudService.getAllData('WorkflowDefinition').toPromise();
    this.selectedCode = selectedWorkflowDefn;
    let data: any = await this.CrudService.getDataByField('workflowdefnSteps', 'wf_code', selectedWorkflowDefn[0].id).toPromise();
    this.dataList = data;
    //console.log('dataList',this.dataList)
      //this.wf_code = data;
  }

  openNew(){
    //this.isActive = true;
    const rowData = {
      id:null,
      created_by:null,
      created_at:null,
      updated_by:null,
      updated_at:null,
      wf_code:null,
      name:null,
      seq:null,
      sla:null,
      status:'1',
      skip_yn:null,
    };
    this.dataList = [rowData, ...this.dataList]; 
    // this.workflowDialog=true;
    // //console.log('test',this.dataList)
  }

  onSelectedItemChange(event, rowData){
    rowData.id = event.value?.id;
    rowData.changed=true;
  }

  findIndexById(id: string): number {
    let index = -1;
    for (let i = 0; i < this.dataList.length; i++) {
      if (this.dataList[i].id === id) {
        index = i;
        break;
      }
    }
    return index;
  }

  async saveRecordNew(rowData){
    let status: Boolean = true;
    if (rowData.name.trim()) {
    if(rowData.id){
      //console.log("this is working",rowData);
      if(rowData.isActive){
        rowData.status='1';
      }
      if(!rowData.isActive){
        rowData.status='2';
      }
      let data: any = await this.CrudService.Update(rowData,'workflowDefnSteps').toPromise();
      //console.log('workflow', data)
      if(data.success == '1'){
        rowData.changed = false;
      }else{
        status = false;
      }
    }else{
      rowData.status='1'
      rowData.isActive=true;
      // //console.log("this is working",itemdefinitions);
      let data: any = await this.CrudService.Create(rowData, 'workflowDefnSteps').toPromise();
      //console.log('workflow1', data);
      if(data.success == '1'){
        rowData.changed = false;
      }else{
        status = false;
      }
    }
    }else{
      status = false;
      // //console.log("This is not working");
    }
    return status;
    }

  async saveRecords(){
    let saveStatus = true;
    let changedRecords = this.dataList.filter(val=> (val.changed === true));
      changedRecords.forEach(async rowData=> {
        //console.log("workflow",rowData)
        rowData
        let status = await this.saveRecordNew(rowData);
        if (!status){
          saveStatus = false;
        }
      });
      if(saveStatus){
        // SuccessMessage
        this.messageService.add({ severity: 'success', summary: 'Successful', detail: changedRecords.length+' Records Updated', life: 1000 });
      }
    
      this.isEditAll=false;
      let editedRecords = this.dataList.filter(val=> (val.isEdit === true));
      editedRecords.forEach(element => {
        element.isEdit = false;
      });
  }

  editRecord(rowData:any){
    this.workflow = {...rowData};
    // this.CrudService.getAllData('WorkflowDefinition', this.workflow.id).subscribe((data:any[])=>{
    //     this.selectedCompany=data[0];
    //     //console.log('value',this.selectedCompany);
    // });
    this.workflowDialog=true;
    // this.list = [
    //   { id: '1', title: 'Job' },
    //   { id: '2', title: 'Admin' },
    //   { id: '3', title: 'Compliance' },
    // ];
  }

  deleteWorkflow(rowData: workflowsteps) {
    this.rowData = { ...rowData }
    //console.log(this.rowData)
    this.confirmationService.confirm({
      message: 'Are you sure you want to delete value set : ' + this.rowData.name + '?',
      header: 'Confirm',
      icon: 'pi pi-exclamation-triangle',
      accept: () => {
        this.dataList = this.rowData.filter(val => val.id !== this.rowData.id);
        // this.itemdefinitions = {};
        this.CrudService.Delete(this.rowData.id, 'WorkflowDefnSteps').subscribe((data: any[]) => {
          this.messageService.add({ severity: 'success', summary: 'Successful', detail: 'Value Set Deleted', life: 1000 });
        });
      }
    });
  }

  hideDialog() {
    this.workflowDialog = false;
    this.submitted = false;
  }
  backup(rowData){
    //console.log("backup",rowData);
    rowData=rowData.backup;
  }
  resetLastCheckPoint(rowData){
    //console.log(rowData);
    rowData = rowData.backup; 
  }
}