import { Component, OnChanges, Input, OnInit } from '@angular/core';
import { CdkDragDrop, moveItemInArray, transferArrayItem } from '@angular/cdk/drag-drop';
import { ActivatedRoute, Router } from '@angular/router';
import { ConfirmationService, MessageService } from 'primeng/api';
import { DialogService, DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { CRUDService } from 'src/app/utilities/crud.service';
import { WorkFlowService } from 'src/app/utilities/workflow.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-task-checklist',
  templateUrl: './task-checklist.component.html',
  styleUrls: ['./task-checklist.component.scss'],
  providers: [DialogService, MessageService]
})
export class TaskCheckListComponent implements OnChanges, OnInit {

  @Input() task_id: any;
  @Input() checklist: any[];
  @Input() from: any;
  companyId: any;
  userDetails: any;
  date: Date;
  dateFormat: any= environment.DateFormat;

  constructor(
    private route: ActivatedRoute,
    private crudService: CRUDService,
    private messageService: MessageService,
    private confirmationService: ConfirmationService,
    private router: Router,
    public ref: DynamicDialogRef,
    private wfService: WorkFlowService,
    public config: DynamicDialogConfig,
    public dialogService: DialogService
  ) { }

  ngOnInit(){
    this.convertStringToDate(this.checklist);
    this.ngOnChanges();
  }

  ngOnChanges() {
    this.dateFormat = environment.DateFormat;
    this.companyId = sessionStorage.getItem('company_id');
    this.userDetails = JSON.parse(sessionStorage.getItem('loggedInUser'));
    if(this.checklist && this.checklist.length > 0) {
      this.convertStringToDate(this.checklist);
    }
    if(this.from == 'details'){
      this.crudService.getDataByField('task_checklist', 'task_id', this.task_id).subscribe((data: any) => {
        this.checklist =data;
        this.convertStringToDate(this.checklist);
      }); 
    }
  }

  convertStringToDate(list){
    if(list && list.length > 0) {
      list.forEach(l => {
        if(l.date) {
          l.date = new Date(l.date);
        }
      });
    }
  }

  addCheckListItem() {
    let taskCheckListItem = {
      id: null,
      task_id: this.task_id,
      description: null,
      status: 'Active',
      checked: '2',
      date: new Date(),
      isChecked: false,
      created_by: this.userDetails.id,
      created_at: new Date(),
      updated_by: null,
      updated_at: null
    };
    taskCheckListItem.isChecked=false;
    this.checklist.push(taskCheckListItem);
    // this.crudService.Create(taskCheckListItem, 'taskchecklist').subscribe((data: any) => {
    //   if (data.success === 1) {
    //     taskCheckListItem.id = data.id
    //     if (typeof this.checklist != 'undefined' && this.checklist.length > 0) {
    //     } else {
    //       this.checklist = [];
    //     }
    //     this.checklist.push(taskCheckListItem);
    //   } else {
    //     this.messageService.add({ severity: 'warning', summary: 'Error', detail: 'Checklist Item Not Created', life: 1000 });
    //   }
    // });
  }




  removeItem(item) {

    this.confirmationService.confirm({
         message: 'Are you sure you want to delete - '+ item.description +' ?',
         header: 'Confirm',
         icon: 'pi pi-exclamation-triangle',
         accept: () => {
    this.crudService.Delete(item.id, 'taskchecklist').subscribe((data: any) => {
      if (data.success === 1) {
        this.checklist = this.checklist.filter((val: any) => val.id !== item.id)
       this.messageService.add({ severity: 'success', summary: 'Successful', detail: 'Task Deleted', life: 1000 });
      } else {
        this.messageService.add({ severity: 'warning', summary: 'Error', detail: 'Checklist Item Not Removed', life: 1000 });
      }
    })
  }
  })

  }

  checkItem(item) {
    //console.log('item', item);
    if(item.isChecked){
      item.checked='1';
    }else{
      item.checked='2';
    }
    this.crudService.Update(item, 'taskchecklist').subscribe((data: any) => {
      if (data.success === 1) {
        this.messageService.add({ severity: 'success', summary: 'success', detail: 'Item Marked', life: 1000 });
      } else {
        this.messageService.add({ severity: 'warning', summary: 'Error', detail: 'Checklist Item Not Removed', life: 1000 });
      }
    });
  }

  onValueChange(value, item) {
    if(value) {
      item.description = value;
    }
    if(item.id){
      this.crudService.Update(item, 'taskchecklist').subscribe((data: any) => {
        if (data.success === 1) {
          this.messageService.add({ severity: 'success', summary: 'success', detail: 'Item Marked', life: 1000 });
        }
      });
    }
    else if(value) {
      this.crudService.Create(item, 'taskchecklist').subscribe((data: any) => {
          if (data.success === 1) {
            item.id = data.id
            if (typeof this.checklist != 'undefined' && this.checklist.length > 0) {
            } else {
              this.checklist = [];
            }
            // this.checklist.push(item);
          } else {
            this.messageService.add({ severity: 'warning', summary: 'Error', detail: 'Checklist Item Not Created', life: 1000 });
          }
        });
    }
 
  }
  onFocusInput(event: any) {
    event.target.classList.add('editable');
  }
  onBlurInput(event: any, item) {
    event.target.classList.remove('editable');
    this.onValueChange(event.target.value, item);
  }
  setDate(event: any, item) {
    this.onValueChange(null, item);
  }

  updateAction(message, action) {
    let taskMessage: any = {
      id: null,
      task_id: this.task_id,
      message: message,
      file_type: null,
      file_path: null,
      file_name: null,
      status: '1',
      action: action,
      star_colour: null,
      process_id: this.companyId,
      created_by: this.userDetails.id,
      created_at: new Date(),
      updated_by: null,
      updated_at: null
    };
    this.crudService.Create(taskMessage, 'TaskMessages').subscribe((res: any) => {
    })
  }
}
