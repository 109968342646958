import { Component, OnInit } from '@angular/core';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';

@Component({
  selector: 'app-proceed-confirmation',
  templateUrl: './proceed-confirmation.component.html',
  styleUrls: ['./proceed-confirmation.component.css']
})
export class ProceedConfirmationComponent implements OnInit {
  configData: any;
  constructor(private dialogRef: DynamicDialogRef, public config: DynamicDialogConfig,) { }

  ngOnInit() {
    //console.log(this.config.data)
    this.configData = this.config.data;
  }
  confirm() {
    this.dialogRef.close("yes")
  }
  close() {
    this.dialogRef.close();
  }

}
