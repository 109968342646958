import { Component, Input, OnInit } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { DomSanitizer } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { ConfirmationService, MessageService } from 'primeng/api';
import { DialogService, DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { CRUDService } from 'src/app/utilities/crud.service';
import { CommonService } from 'src/app/utilities/common.service';
import { JOBService } from 'src/app/utilities/job.service';
import { environment } from 'src/environments/environment';
import { DatePipe, formatDate } from '@angular/common';
import * as moment from 'moment';

@Component({
  selector: 'app-tp-schedule-admin',
  templateUrl: './tp-schedule-admin.component.html',
  styleUrls: ['./tp-schedule-admin.component.scss']
})
export class TpScheduleAdminComponent implements OnInit {
  scheduleForm: FormGroup;
  city: string;
  notesChanged: boolean = false;
  @Input() job_id: any;
  @Input() job_alloc_id: any;
  @Input() type: any;
  @Input() jobSummary: any;
  jobschedule: any
  showHeader: Boolean = false;
  Recipient: any[];
  loading: Boolean = false;
  communicationType: any[];
  Date_str: any;
  attachmentType: any[];
  selectedAttachmentType: any[] = [];
  selectedRecipient: any = null;
  isEmail: boolean = false;
  isSMS: boolean = false;
  display: boolean = false;
  title: any;
  jobDetails: any;
  jobInfo: any;
  date: any;
  isPhoned: boolean = false;
  isCallBack: boolean = false;
  isPMOnly: boolean = true;
  submitted: boolean = false;
  createSchedule: any = false
  scheduleJob: any;
  currentUser: any;
  viewType: any;
  messageCategory: any[];
  selectedmessageCategory: any = null;
  jobAllocationResults: any;
  type_of_visit: any[];
  emptySchedNote: any;
  userDetails: any;
  toggleCommSched: any;
  smsInvalid: any;
  smsEmpty: any;
  schedule_data: any;
  selectedVisitType: any;
  visitTypeList: any[];
  selectedStartTime: any;
  selectedEndTime: any;
  timeSlots: any[];
  filteredTimeSlots: any[];
  selectdEmailId: any[] = [];
  mailIdOptions: string[] = [];
  emailId: any;
  showError: boolean = false;
  minDate: Date;
  maxDate: Date;
  appProps: any;
  emailsList :any;
  sched_date: Date;
  env : any;
  pipe=new DatePipe('en-US');
  jstoday = '';
  current_min: any='';
  today= new Date();
  final_today ='';
  endTimeSlots: any[];
  filteredEndTimeSlots: any[];
  /*   emailsList: any[] = [{ value: 'krishnanshridhar@gmail.com' },
    { value: 'tmsupport@agileitexperts.com' },
    { value: 'shri@envirofrontier.com.au' },
    { value: 'accounts@envirofrontier.com.au' },
    { value: 'mark@envirofrontier.com.au' },
    { value: 'peter@envirofrontier.com.au' },
    { value: 'teamleader@envirofrontier.com.au' },
    { value: 'documents@envirofrontier.com.au' },
    { value: 'support@envirofrontier.com.au' },
    { value: 'admin@envirofrontier.com.au' }];
   */
  constructor(
    private route: ActivatedRoute,
    private crudService: CRUDService,
    private messageService: MessageService,
    private confirmationService: ConfirmationService,
    private router: Router,
    public ref: DynamicDialogRef,
    public config: DynamicDialogConfig,

    public JobService: JOBService,
    public sanitizer: DomSanitizer,
    public dialogRef: DynamicDialogRef,
    private commonservice: CommonService
  ) {
    this.userDetails = JSON.parse(sessionStorage.getItem('loggedInUser'));
    this.timeSlots = ['12:00 AM', '12:30 AM', '01:00 AM', '01:30 AM', '02:00 AM', '02:30 AM', '03:00 AM', '03:30 AM', '04:00 AM', '04:30 AM', '05:00 AM', '05:30 AM', '06:00 AM', '06:30 AM', '07:00 AM', '07:30 AM', '08:00 AM', '08:30 AM', '09:00 AM', '09:30 AM', '10:00 AM', '10:30 AM', '11:00 AM', '11:30 AM',
    '12:00 PM', '12:30 PM', '01:00 PM', '01:30 PM', '02:00 PM', '02:30 PM', '03:00 PM', '03:30 PM', '04:00 PM', '04:30 PM', '05:00 PM', '05:30 PM', '06:00 PM', '06:30 PM', '07:00 PM', '07:30 PM', '08:00 PM', '08:30 PM', '09:00 PM', '09:30 PM', '10:00 PM', '10:30 PM', '11:00 PM', '11:30 PM'];
    this.filteredTimeSlots = this.timeSlots;
    this.endTimeSlots = this.timeSlots;
  }
  async ngOnInit() {
    this.env = environment;
    this.loading = true;
    this.appProps = this.commonservice.getAppProperties();
    this.emailsList = await this.commonservice.getMailerlist();
    //console.log(this.job_alloc_id, this.job_id);
    this.showHeader = false;
    this.currentUser = JSON.parse(sessionStorage.getItem('loggedInUser'));
    if (typeof this.config.data == 'undefined' || typeof (this.config.data.jobId) == 'undefined') {
    }
    else {
      this.job_id = this.config.data.jobId;
      this.job_alloc_id = this.config.data.jobAllocationId;
      this.type = this.config.data.type;
      this.jobSummary = this.config.data.jobSummary;
      this.crudService.getDataByField('jobInfo', 'id', this.jobSummary.id).subscribe((data: any[]) => {
        this.minDate = new Date(data[0].job_recd_dt)
      })
      this.showHeader = true;
    }
    this.scheduleJob = {
      PMOnly: null,
      action_required: null,
      callback: '1',
      created_at: new Date(),
      created_by: this.currentUser.id,
      email: '1',
      emailaddress: null,
      end_time: null,
      id: null,
      job_alloc_id: this.job_alloc_id,
      job_id: this.job_id,
      last_modified_by: null,
      last_updated_at: null,
      owner: null,
      phone_no: null,
      phoned: '1',
      process_id: this.currentUser.company_id,
      sched_date: new Date(),
      sched_note: null,
      sms: '1',
      sms_no: null,
      start_time: null,
      status: '1',
      username: this.currentUser.username,
      visit_type: null,
      source: null,
      message_received: 1,
      message_flow: 1,
      comm_recipient: null,
      comm_recipient_subcatg: null
    }

    if (this.job_alloc_id === null || this.job_alloc_id === '') {
      this.job_alloc_id = '1';
    }

    if (this.job_alloc_id !== '1') {
      let smryData: any = await this.crudService.getJobSummary(this.job_id, this.job_alloc_id).toPromise();
      this.jobAllocationResults = await this.JobService.getJobInfo(smryData[0]);
      this.showHeader = true;
    } else {
      this.jobAllocationResults = this.job_alloc_id;
      this.jobSummary = this.crudService.getDataByField('JobInfo', 'id', this.job_id);
    }
    this.communicationType = [
      { id: '1', title: 'Phoned' },
      { id: '2', title: 'SMS' },
      { id: '3', title: 'Email' },
      { id: '4', title: 'Call Back' },
    ]
    this.crudService.getUploadDocumentsByJobIdAllocId(this.job_id, this.job_alloc_id).subscribe((dataList: any[]) => {
      this.attachmentType = dataList;
    });
    if(this.env.hosting == 'UK'){
      this.visitTypeList = [
        { id: '1', title: 'Arb Site Inspection' },
        { id: '2', title: 'Quote Inspection' },
        { id: '3', title: 'Perform Works' }
      ]
    }
    else{
      this.visitTypeList = [
        { id: '1', title: 'Site Inspection' },
        { id: '2', title: 'Make Safe' },
        { id: '3', title: 'Perform Works' }
      ]
    }

    this.selectedVisitType = null;
    this.selectedAttachmentType = [];

    this.scheduleForm = new FormGroup({
      schednote: new FormControl(''),
      sched_date: new FormControl(''),
      isEmail: new FormControl(''),
      isPhoned: new FormControl(''),
      isSMS: new FormControl(''),
      isCallBack: new FormControl(''),
      isPMOnly: new FormControl(''),
      phone_no: new FormControl(''),
      sms_no: new FormControl(''),
      email: new FormControl(''),
      atch: new FormControl('')
    })
    if (this.type === 'Schedule') {
      this.switchToSchedule();
    } else {
      this.switchToComm();
    }
    this.scheduleJob.message_received = 2;
    this.loading = false;
    //this.minDate = new Date();
	  this.maxDate = new Date(this.minDate.getTime() + (1000 * 60 * 60 * 24 * 90));
  }

  onChangeRecipient(selectedRecipient) {
    this.Recipient.forEach(item => {
      if (item.id === this.selectedRecipient.id) {
        item.selected = 'true';
      } else {
        item.selected = 'false';
      }
    });
    this.crudService.getByValuesetName(selectedRecipient.valueset_detail_desc).subscribe((details: any) => {
      this.messageCategory = details;
      this.selectedmessageCategory = this.messageCategory[0];
      this.onChangeMessageCategory(this.selectedmessageCategory);
      if (selectedRecipient.valueset_detail_name == 'TP') {
        this.isPMOnly = false;
        this.scheduleJob.PMOnly = '1';
      } else {
        this.isPMOnly = true;
        this.scheduleJob.PMOnly = '2';
      }
    });
  }
  onChangeMessageCategory(selectedMessage) {
    if (typeof this.scheduleJob.sched_note !== 'undefined') {
      if (this.notesChanged) {
        this.confirmationService.confirm({
          message: 'Do you want to override the Notes?',
          header: 'Confirm',
          icon: 'pi pi-exclamation-triangle',
          accept: () => {
            this.loadMessage();
            this.confirmationService.close();
          },
          reject: () => {
            this.confirmationService.close();
          }
        });
      } else {
        this.loadMessage();
      }
    } else {
      this.loadMessage();
    }
  }

  loadMessage() {
    if (this.job_alloc_id !== '1') {
      if (this.selectedRecipient.id != null) {
        this.scheduleJob.sched_note = '';
        let valueset = this.messageCategory.find(valueset => (this.selectedmessageCategory.id == valueset.id));
        if (valueset.valueset_detail_desc != null) {
          this.scheduleJob.sched_note = valueset.valueset_detail_desc;
        }
        
        let claimNo = this.jobAllocationResults.info.job_claim_no;
        if(claimNo == '' || claimNo == 'null' || claimNo == null || claimNo == undefined)
        {
          claimNo = "N/A";
        }

        if (this.selectedRecipient.id == 10135) {
          this.scheduleJob.sched_note = 'Hi ' + this.jobAllocationResults?.info?.SiteContactName?.trim() + ', this message is from Enviro Trees Team regarding tree works in your property. ' + this.scheduleJob.sched_note;
        } else if (this.selectedRecipient.id == 10136) {
          this.scheduleJob.sched_note = 'Hi ' + this.jobAllocationResults?.TPContactShortName?.trim() + ', this message is regarding  ' + this.jobAllocationResults.JobNo + ' @ ' + this.jobAllocationResults.info.AddressForComms + '. ' + this.scheduleJob.sched_note;
        } else if (this.selectedRecipient.id == 10137) {
          this.scheduleJob.sched_note = 'Hi ' + this.jobAllocationResults?.info?.ClientName?.trim() + ',  this message is from Enviro Trees Team regarding tree works @'
            + this.jobAllocationResults.info.AddressForComms + '. Claim Number for your reference is '
            + claimNo + '. ' + this.scheduleJob.sched_note +
            ' Our tracking number for reference ' + this.jobAllocationResults.JobNo;
        } else if (this.selectedRecipient.id == 10138) {
          this.scheduleJob.sched_note = 'Hi ' + 'Team, ' + this.scheduleJob.sched_note;
        }
      }
    }
    /* else {
      this.scheduleJob.sched_note = this.selectedmessageCategory.valueset_detail_desc;
    } */
  }

  saveRecord() {
    this.submitted = true;
    this.scheduleJob.visit_type = null;
    this.scheduleJob.phoned = '1';
    if (this.scheduleJob.phone_no !== null && this.scheduleJob.phone_no !== '') {
      this.scheduleJob.phoned = '2';
    }
    this.scheduleJob.email = '1';
    if (typeof this.selectdEmailId !== 'undefined') {

      if (this.selectdEmailId !== null && this.selectdEmailId.length > 0) {
        let emailid = '';
        this.selectdEmailId.forEach(mailid => {
          if (emailid !== '') {
            emailid = emailid + ', ' + mailid.value;
          } else {
            emailid = mailid.value;
          }
        });
        this.scheduleJob.emailaddress = emailid;
        this.scheduleJob.email = '2';
      }
    }
    this.scheduleJob.sms = '1';
    if (this.scheduleJob.sms_no !== null && this.scheduleJob.sms_no !== '') {
      this.scheduleJob.sms = '2';
    }
    if (this.isCallBack) {
      this.scheduleJob.callback = '2';
    } else {
      this.scheduleJob.callback = '1';
    }
    if (this.isPMOnly) {
      this.scheduleJob.PMOnly = '1';
    }
    else {
      this.scheduleJob.PMOnly = '1';
    }
    this.scheduleJob.status = '';
    let isTimeSlotValid = true;
    if(this.type == 'Schedule'){
    if (typeof this.selectedVisitType !== 'undefined') {
        if (this.selectedVisitType != null && this.selectedVisitType != '') {
          this.scheduleJob.visit_type = this.selectedVisitType.id;
          this.scheduleJob.status = '1';

          let startTime = this.scheduleJob.start_time;
          if (startTime == '12:00') {
            startTime = 0;
          } else {
            if (startTime.includes('am')) {
              startTime = startTime.replace('am', '').replace(':', '');
              startTime = parseInt(startTime);
            } else if (startTime.includes('pm')) {
              startTime = startTime.replace('pm', '').replace(':', '');
              startTime = parseInt(startTime) + 1200;
            }
          }
          let endTime = this.scheduleJob.end_time;
          if (endTime == '12:00') {
            endTime = 0;
          } else {
            if (endTime.includes('am')) {
              endTime = endTime.replace('am', '').replace(':', '');
              endTime = parseInt(endTime);
            } else if (endTime.includes('pm')) {
              endTime = endTime.replace('pm', '').replace(':', '');
              endTime = parseInt(endTime) + 1200;
            }
          }
          if (startTime > endTime) {
            isTimeSlotValid = false;
          }
        }
      }
    }
    if (isTimeSlotValid) {
      this.scheduleJob.source = '1';
      /* if (this.scheduleJob.end_time!=null && this.scheduleJob.end_time!=='') {
        this.scheduleJob.end_time = this.scheduleJob.end_time).toTimeString();
      }
      if (this.scheduleJob.start_time!=null && this.scheduleJob.start_time!=='') {
        this.scheduleJob.end_time = new Date(this.scheduleJob.end_time).toTimeString();
        this.scheduleJob.start_time = this.scheduleJob.start_time.toString().split(' ')[4];
      } */
      if (typeof this.selectedRecipient !== 'undefined' && this.selectedRecipient != null) {
        this.scheduleJob.comm_recipient = this.selectedRecipient.id;
      }
      if (typeof this.selectedmessageCategory !== 'undefined' && this.selectedmessageCategory != null) {
        this.scheduleJob.comm_recipient_subcatg = this.selectedmessageCategory.id;
      }
      this.scheduleJob.sched_date = moment(this.scheduleJob.sched_date).format('YYYY-MM-DD').toString();
      this.crudService.Create(this.scheduleJob, 'JobSchedule').subscribe((data: any) => {
        if (data.success === 1) {
          this.scheduleJob.id = data.id;
          if (typeof this.selectedVisitType !== 'undefined') {
            if (this.selectedVisitType !== null && this.selectedVisitType !== '') {
              if (this.job_alloc_id !== '1') {
                this.sendScheduleNotification(this.scheduleJob);
                this.messageService.add({ severity: 'success', summary: 'Success', detail: 'Schedule Saved Succesfully.', life: 1000 });
              } else {
                this.messageService.add({ severity: 'success', summary: 'Success', detail: 'Schedule Saved.', life: 1000 });
              }
            } else {
              if (this.job_alloc_id !== '1') {
                this.sendCommsNotification(this.scheduleJob);
                this.messageService.add({ severity: 'success', summary: 'Success', detail: 'Message Saved Succesfully.', life: 1000 });
              } else {
                this.messageService.add({ severity: 'success', summary: 'Success', detail: 'Communication Saved.', life: 1000 });
              }
            }
          }
          this.dialogRef.close();
        } else {
          this.messageService.add({ severity: 'error', summary: 'Error', detail: 'Schedule/Communication not saved', life: 1000 });
        }
      })
    } else {
      this.messageService.add({ severity: 'error', summary: 'Error', detail: 'Schedule Slots invalid', life: 1000 });
    }
  }

  sendCommsNotification(ScheduleFormData) {
    debugger
    this.scheduleJob.message_flow = '2';
    this.scheduleJob.message_received = '2';
    this.scheduleJob.message_comm_type = '2';
    let emailSubject = 'TM Job Number - ' + this.jobAllocationResults.JobNo + ', Site Address - ' + this.jobAllocationResults.info.location;
    let sch_notes = encodeURIComponent(this.scheduleJob.sched_note);
    //console.log('encode', sch_notes);
    let mailSubject = '';
    let EmailAccount = 'AU';
    if (this.jobAllocationResults.info.AccountType == 'AU') {
      EmailAccount = 'AU';
    } else if (this.jobAllocationResults.info.AccountType == 'NZ') {
      EmailAccount = 'NZ';
    }
    let documentList = [];
    this.selectedAttachmentType.forEach(file => {
      let docObj = { fileName: file.file_name, filePath: this.crudService.getAPIRoot() + file.file_path }
      documentList.push(docObj);
    });
    let data: any = {
      email: {
        from: '',
        to: this.scheduleJob.emailaddress,
        cc: '',
        bcc: '',
        subject: emailSubject,
        message: sch_notes, msg: sch_notes
      },
      sms: {
        to: this.scheduleJob.sms_no,
        message: sch_notes
      }
    }
    let temp_type = 'email';
    if (this.scheduleJob.sms == '2' && this.scheduleJob.email == '2') {
      temp_type = 'sms-email';
    } else if (this.scheduleJob.sms == '2') {
      temp_type = 'sms';
    } else if (this.scheduleJob.email == '2') {
      temp_type = 'email';
    }
    let atts = { jobId: this.jobAllocationResults.id, allocId: this.jobAllocationResults.job_alloc_id, documentList: documentList };
    let dataObj = {
      sms: data.sms,
      email: data.email,
      type: temp_type,
      selectedTpl: null,
      sufix: null,
      jobData: null,
      attachments: atts,
      account: EmailAccount
    }
    JSON.stringify(dataObj);
    console.log("ddataObjatobj2",dataObj)
    debugger
    //console.log(dataObj);
    if(this.env.emailRoutes.common == 'sendgrid')
    {
      const datobj2 = {
        subject : data.email.subject,
        body : data.email.message,
        toEmail : this.scheduleJob.emailaddress  ,
        ref_id : this.scheduleJob.id,
        ref_type : 'JobSchedule'
        }
        console.log("datobj2",datobj2)
        console.log("this.jobAllocationResults.info.Email",this.jobAllocationResults.info.Email)
        console.log("this.scheduleJob.emailaddress",this.scheduleJob)
        debugger
      this.crudService.sendmailSendgrid(datobj2,'commonMail').subscribe((res2: any[]) => { 
        this.crudService.sendTMV2Message(dataObj).subscribe((res2: any[]) => {
        });
       });
    }
    else if(this.env.emailRoutes.common == 'nodemailer')
    {
      this.crudService.sendTMV2Message(dataObj).subscribe((res2: any[]) => {
      });
    }
    this.JobService.updateNotifications(this.userDetails.id, emailSubject, sch_notes, this.jobAllocationResults.job_alloc_id);
  }

  sendScheduleNotification(ScheduleFormData) {
    let claimNo = '';
    if (typeof this.jobAllocationResults.info.job_claim_no !== 'undefined') {
      claimNo = this.jobAllocationResults.info.job_claim_no;
    }
    let mailSubject = '';
    let visitDesc = this.selectedVisitType.title;
    let EmailAccount = null;
    if (this.jobAllocationResults.info.AccountType == 'AU') {
      EmailAccount = 'AU';
    } else if (this.jobAllocationResults.info.AccountType == 'NZ') {
      EmailAccount = 'NZ';
    }
    let documentList = [];
    this.selectedAttachmentType.forEach(file => {
      let docObj = { fileName: file.file_name, filePath: this.crudService.getAPIRoot() + file.file_path }
      documentList.push(docObj);
    });
    let atts = { jobId: this.jobAllocationResults.id, allocId: this.jobAllocationResults.job_alloc_id, documentList: documentList };
    if(claimNo == '' || claimNo == 'null' || claimNo == null || claimNo == undefined)
    {
      claimNo = "N/A";
    }
    if (this.jobAllocationResults.info.ClientCommType = 'Email Client') {
      if (this.jobAllocationResults.info.Email != 'NA') {
        let params = {
          email: {
            to: this.scheduleJob.emailaddress,
            cc: 'documents@envirofrontier.com.au',
            bcc: null,
            msg: 'This is a mail to notify that ' + visitDesc + ' has been scheduled on (' +
              new Date(ScheduleFormData.sched_date).toDateString() + ') between ' +
              ScheduleFormData.start_time + ' and ' +
              ScheduleFormData.end_time +
              ' for Job No : ' + this.jobAllocationResults.JobNo + '  The details are as below . \n  ' +
              'Risk Address : ' + this.jobAllocationResults.info.location + '. \n',
            subject: 'Claim Number - ' + claimNo +
              ', ' + this.jobAllocationResults.ShortAddr + ' - ' +
              visitDesc + ' scheduled on (' + new Date(ScheduleFormData.sched_date).toDateString() + ') between ' +
              ScheduleFormData.start_time + ' and ' +
              ScheduleFormData.end_time + ', TM Job Number - ' +
              this.jobAllocationResults.JobNo + ', Site Address - ' + this.jobAllocationResults.info.location,
          },
          sms: {
            to: '',
            message: ''
          },
          type: 'email',
          selectedTpl: null,
          sufix: null,
          jobData: null,
          account: EmailAccount,
          attachments: atts
        }
        if(this.env.hosting == 'UK'){
          params.email.subject = 'Claim Number - '+claimNo +
          ', ' + this.jobAllocationResults.ShortAddr + ' - ' +
          visitDesc + ' scheduled on (' + new Date(ScheduleFormData.sched_date).toDateString() + ') between ' +
          ScheduleFormData.start_time + ' and ' +
          ScheduleFormData.end_time + ', TM Job Number - ' +
          this.jobAllocationResults.JobNo + ', Site Address - ' + this.jobAllocationResults.info.location
        }
        this.JobService.updateNotifications(this.userDetails.id, params.email.subject, params.email.msg, this.jobAllocationResults.job_alloc_id);
        if(this.env.emailRoutes.common == 'sendgrid')
        {
          const datobj = {
            subject : params.email.subject,
            body : params.email.msg,
            toEmail : params.email.to,
            ref_id : this.scheduleJob.id,
            ref_type : 'JobSchedule'
            }
          this.crudService.sendmailSendgrid(datobj,'commonMail').subscribe((res2: any[]) => { 
            this.crudService.sendTMV2Message(params).subscribe((res2: any[]) => {
            });
           });
        }
        else if(this.env.emailRoutes.common == 'nodemailer')
        {
          // this.sendMail(params);
          this.crudService.sendTMV2Message(params).subscribe((res2: any[]) => {
          });
        }
      } else {
        let params = {
          email: {
            to: 'documents@envirofrontier.com.au', cc: '', bcc: null,
            msg: 'Missing Client Email Details -- This is a mail to notify that ' + visitDesc + ' has been scheduled on '
              + (new Date(ScheduleFormData.sched_date).toDateString()) + ' between ' + ScheduleFormData.start_time + ' and ' +
              ScheduleFormData.end_time + ' for Job No : ' + this.jobAllocationResults.JobNo + '  The details are as below . \n  ' +
              'Risk Address : ' + this.jobAllocationResults.info.location + '. \n',
            subject: 'Missing Client Email Details --   ' + visitDesc + ' scheduled on ' + (new Date(ScheduleFormData.sched_date).toDateString()) +
              ' between ' + ScheduleFormData.start_time + ' and ' +
              ScheduleFormData.end_time + ', TM Job Number - '
              + this.jobAllocationResults.JobNo + ', Site Address - ' + this.jobAllocationResults.info.location,
          },
          sms: {
            to: '',
            message: ''
          },
          type: 'email', selectedTpl: null, sufix: null, jobData: null,
          account: EmailAccount, attachments: atts
        }
        this.JobService.updateNotifications(this.userDetails.id, params.email.subject, params.email.msg, this.jobAllocationResults.job_alloc_id);
        
        if(this.env.emailRoutes.common == 'sendgrid')
          {
            const datobj = {
              subject : params.email.subject,
              body : params.email.msg,
              toEmail : params.email.to,
              ref_id : this.scheduleJob.id,
              ref_type : 'JobSchedule'
              }
            this.crudService.sendmailSendgrid(datobj,'commonMail').subscribe((res2: any[]) => { 
              this.crudService.sendTMV2Message(params).subscribe((res2: any[]) => {
              });
             });
          }
          else if(this.env.emailRoutes.common == 'nodemailer')
          {
            this.crudService.sendTMV2Message(params).subscribe((res2: any[]) => {
            });
          }
      }

    } else if (this.jobAllocationResults.info.ClientCommType = 'Portal Client') {
      let params = {
        email: {
          to: 'documents@envirofrontier.com.au', cc: '', bcc: null,
          msg: ' To load in portal -- This is a mail to notify that ' + visitDesc + ' has been scheduled on ' + (new Date(ScheduleFormData.sched_date).toDateString()) + ' between ' + ScheduleFormData.start_time + ' and ' + ScheduleFormData.end_time + ' for Job No : ' + this.jobAllocationResults.JobNo + '  The details are as below . \n  ' +
            'Risk Address : ' + this.jobAllocationResults.info.location + '. \n',
          subject: 'To load in portal --  ' + visitDesc + ' scheduled on ' + (new Date(ScheduleFormData.sched_date).toDateString()) + ' between ' +
            ScheduleFormData.start_time + ' and ' +
            ScheduleFormData.end_time + ', TM Job Number - ' +
            this.jobAllocationResults.JobNo + ', Site Address - ' + this.jobAllocationResults.info.location,
        }, sms: {
          to: '',
          message: ''
        }, type: 'email', selectedTpl: null, sufix: null, jobData: null,
        account: EmailAccount, attachments: atts
      }
      this.JobService.updateNotifications(this.userDetails.id, params.email.subject, params.email.msg, this.jobAllocationResults.job_alloc_id);
      this.crudService.sendTMV2Message(params).subscribe((res2: any[]) => {
        //console.log(res2);
      });
    }
  }

  switchToSchedule() {
    this.type = 'Schedule';
    this.toggleCommSched = false;
    this.selectedVisitType = this.visitTypeList[0];
  }
  async switchToComm() {
    this.type = 'Communication';
    let details: any = await this.crudService.getByValuesetName('CommsCatgType').toPromise();
    this.Recipient = details;
    this.toggleCommSched = true;
    this.selectedmessageCategory.id = null;
    this.selectedRecipient.id = null;//this.onChangeRecipient(this.selectedRecipient);
  }

  search(event) {
    // this.filteredTimeSlots = this.timeSlots.filter((val: any) => val.includes(event.query));

    this.filteredTimeSlots = this.timeSlots;
    this.endTimeSlots = this.timeSlots;
    // this.endTimeSlots = this.timeSlots;
    // const now = moment();
    if(this.env.hosting == 'UK'){
      this.filteredTimeSlots = this.timeSlots.filter((val: any) => val.includes(event.query));
    }
    else{
      const now = moment();
      var date1= this.pipe.transform(now, 'shortDate');
      var date2= this.pipe.transform(this.scheduleJob.sched_date,'shortDate');
      if(date1  === date2){
        const date = new Date();
        this.jstoday = formatDate(this.today, 'hh:mm a', 'en-US', '+0530');
        this.current_min =formatDate(this.today, 'mm', 'en-US', '+0530');
             
            if(this.current_min < 30 ){
              date.setMinutes(30);
            }
            else{
              date.setMinutes(60);   
            }
            this.final_today = formatDate(date, 'hh:mm a', 'en-US', '+0530');
           let index = this.timeSlots.indexOf(this.final_today)
            this.filteredTimeSlots = this.timeSlots.slice(index);
  
      }
      else{
        this.filteredTimeSlots = this.timeSlots.filter((val: any) => val.includes(event.query));   
      }
    }

    // var date1= this.pipe.transform(now, 'shortDate');
    // var date2= this.pipe.transform(this.scheduleJob.sched_date,'shortDate');
    // if(date1  === date2){
    //   const date = new Date();
    //   this.jstoday = formatDate(this.today, 'hh:mm a', 'en-US', '+0530');
    //   this.current_min =formatDate(this.today, 'mm', 'en-US', '+0530');
           
    //       if(this.current_min < 30 ){
    //         date.setMinutes(30);
    //       }
    //       else{
    //         date.setMinutes(60);   
    //       }
    //       this.final_today = formatDate(date, 'hh:mm a', 'en-US', '+0530');
    //      let index = this.timeSlots.indexOf(this.final_today)
    //       this.filteredTimeSlots = this.timeSlots.slice(index);

    // }
    // else{
    //   this.filteredTimeSlots = this.timeSlots.filter((val: any) => val.includes(event.query));   
    // }
  }


  resetInput() {
    if (this.job_alloc_id !== '1') {

      let SMSAddressforComms = null;
      this.selectdEmailId = [];
      if (this.selectedRecipient.id == '10135') {
        if (this.jobAllocationResults.info.SiteContactMobile !== 'undefined') {
          SMSAddressforComms = this.jobAllocationResults.info.SiteContactMobile;
        }
      } else if (this.selectedRecipient.id === '10136') {
        SMSAddressforComms = this.jobAllocationResults.info.TPMobile;
      } else if (this.selectedRecipient.id === '10137') {
        SMSAddressforComms = null;
      } else if (this.selectedRecipient.id === '10138') {

      }
      this.scheduleJob.sms_no = SMSAddressforComms;

      let emailAddressforComms = null;
      if (this.selectedRecipient.id === '10135') {
        emailAddressforComms = this.jobAllocationResults.info.SiteContactEmail;
      } else if (this.selectedRecipient.id === '10136') {
        emailAddressforComms = this.jobAllocationResults.info.TPEmail;
      } else if (this.selectedRecipient.id === '10137') {
        if (this.jobAllocationResults.info.Email !== 'NA') {
          emailAddressforComms = this.jobAllocationResults.info.Email;
        }
      } else if (this.selectedRecipient.id === '10138') {

      }
      if (emailAddressforComms !== null && emailAddressforComms !== '') {
        this.selectdEmailId.push({ value: emailAddressforComms });
      }
    }

  }

  removeToMailId(value) {
    this.selectdEmailId = this.selectdEmailId.filter(val => val.value !== value.value);
  }

  searchMail(event) {
    this.showError = true
    this.mailIdOptions = [];
    let value = event.query.toLowerCase();
    let option = this.emailsList.filter(val => val.toLowerCase().includes(value));
    if (option.length > 0) {
      this.mailIdOptions = option;
    } else {
      if ((value.includes(',') || value.includes(';') || value.includes('.co')) && (value.includes('.co'))) {
        let mailId = event.query.replaceAll(',', '').replaceAll(';', '').toLowerCase();
        this.mailIdOptions.push(mailId);
      }
    }
    this.emailId = '';
    this.emailId = event.query.toLowerCase();
    if (this.emailValidation(event.query.toLowerCase())) {
      this.showError = false
    }
    // this.dummy=[];
  }
  emailValidation(val: any) {
    var regex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/; // Uppercase character pattern

    if (regex.test(val)) return true;
    else return false;
  }
  addMailId(mailFieldValue, arrayObject) {
    this.showError = true
    if (typeof mailFieldValue !== 'undefined' && mailFieldValue !== null && mailFieldValue !== '') {
      if (this.emailsList.includes(mailFieldValue)) {
        if (arrayObject.length > 0) {
          let index =
            arrayObject.findIndex((res: any) => res.value == mailFieldValue);
          if (index < 0) {
            this.showError = false
            arrayObject.push({ value: mailFieldValue });
          }
        }
        else
          this.showError = false
        arrayObject.push({ value: mailFieldValue });
      }
      else {
        if (this.emailValidation(mailFieldValue)) {
          let index =
            arrayObject.findIndex((res: any) => res.value == mailFieldValue);
          if (index < 0) {
            this.showError = false
            arrayObject.push({ value: mailFieldValue });

          }
          mailFieldValue = null;
        }
      }
    }
    mailFieldValue = null;
  }

  selectId(value, arrayObject) {
    this.addMailId(value, arrayObject);
  }

  loadJobSummary() {
    //console.log('Job Notes', this.jobSummary);
    this.Date_str = new Date(this.jobSummary.Date).toLocaleDateString();
    this.title = this.jobSummary.JobNo + ' - (' + this.jobSummary.JobStatus + ')';
    this.crudService.getDataByField('jobinfo', 'id', this.job_id).subscribe((data: any[]) => {
      this.jobDetails = data;
      this.jobInfo = data[0];
      this.date = this.jobDetails[0].job_recd_dt.split('T')[0];
      this.crudService.getCostingInfo(this.job_id, this.job_alloc_id).subscribe((costingInfo: any[]) => {
        if (costingInfo.length > 0) {
          if (this.jobSummary.wp_grand_total !== null && this.jobSummary.wp_grand_total !== '') {
            this.jobSummary.tp_grand_total = parseFloat(costingInfo[0].tp_grand_total).toFixed(2);
            this.jobSummary.wp_grand_total = parseFloat(costingInfo[0].wp_grand_total).toFixed(2);
            let variance = this.jobSummary.tp_grand_total / this.jobSummary.wp_grand_total;
            variance = 1 - variance;
            variance = variance * 100;
            this.jobSummary.margin = parseFloat(variance.toString()).toFixed(2);
          }
        }
      });
    })
  }
  searchEndTime(event) {
    // console.log("searchEndTime",);
     debugger
     if(!this.scheduleJob.start_time) {
       this.filteredEndTimeSlots = this.timeSlots.filter((val: any) => val.includes(event.query));
     }else {
       this.filteredEndTimeSlots = this.endTimeSlots.filter((val: any) => val.includes(event.query));
     }
   }

  changeTimeslot() {
    this.scheduleJob.end_time = '';
    this.endTimeSlots = this.timeSlots
    let index = this.timeSlots.indexOf(this.scheduleJob.start_time)
    index = index+1;
    this.endTimeSlots = this.timeSlots.slice(index); 
  }

}