import { Component, OnInit } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { ActivatedRoute } from '@angular/router';
import { ConfirmationService, MessageService } from 'primeng/api';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { CRUDService } from 'src/app/utilities/crud.service';
import { Table } from 'primeng/table';
import { CommonService } from 'src/app/utilities/common.service';
import { async } from '@angular/core/testing';

@Component({
  selector: 'app-assign-rateset',
  templateUrl: './assign-rateset.component.html',
  styleUrls: ['./assign-rateset.component.scss']
})
export class AssignRateSetComponent implements OnInit {

  selectedSourceCompanies: any[];
  selectedDestinationCompanies: any[];
  selectedRateSet: any;
  sourceCompanies: any;
  olddesign:any;
  destinationCompanies: any[] = [];
  rateSetList: any;
  loading: Boolean = false;
  targetHeader: any;
  allTpsList: any;
  clearsearch: string;

  constructor(private CrudService: CRUDService,
    private messageService: MessageService,
    private confirmationService: ConfirmationService,
    private CommonService: CommonService) { }

  async ngOnInit() {
    this.allTpsList = await this.CrudService.getAllTPAdmin().toPromise();
    this.rateSetList = await this.CrudService.getAllRateSets().toPromise();
    this.selectedRateSet = this.rateSetList[0];
      this.loadPickList();
  }

  async loadPickList() {
    this.loading = true;
    this.destinationCompanies = [];
    this.sourceCompanies = await this.CrudService.getAllTPAdmin().toPromise();
    
    let res: any = await this.CrudService.getSelectedCompaniesByRateSetId(this.selectedRateSet.id).toPromise();
    res.forEach(rsc => {
      let companyData = this.allTpsList.find(val => (val.id == rsc.company_id));
      if(companyData!=undefined){
        companyData.ratesetcompany_id = rsc.id;
        this.destinationCompanies.push(companyData);
        let index = this.sourceCompanies.findIndex(val => (val.id == rsc.company_id));
        if(index>-1){
          this.sourceCompanies.splice(index, 1);
        }
      }
    });
    //pl.target = this.destinationCompanies;
    this.sourceCompanies.sort();
    this.destinationCompanies.sort();
    this.loading = false;
    this.targetHeader = ' Target : ' + this.destinationCompanies.length;
  }
  async submit() {
    this.loading = true;
    let success: Boolean = true;
    let exisitingRateSetCompanies: any = await this.CrudService.getSelectedCompaniesByRateSetId(this.selectedRateSet.id).toPromise();
    exisitingRateSetCompanies.forEach(comps => {
      let data: any = this.destinationCompanies.find(val => (comps.company_id === val.id));
      if (data !== null) {

      } else {
        this.CrudService.Delete(comps.id, 'RatesetCompanies').subscribe((res: any) => {
          if (res.success == 1) {
          } else {
            success = false;
          }
        });
      }
    });
    this.destinationCompanies.forEach(comp => {
      let data: any = exisitingRateSetCompanies.find(val => (val.company_id === comp.id));
      if (data !== null) {

      } else {
        let rateSet_company = {
          id: null,
          rateset_id: this.selectedRateSet.id,
          company_id: comp.id,
          status: 1,
        }
        this.CrudService.Create(rateSet_company, 'RatesetCompanies').subscribe((res: any) => {
          if (res.success == 1) {
          } else {
            success = false;
          }
        });
      }
    });
    this.loading = false;
    if (success) {
      this.messageService.add({ severity: 'success', summary: 'Successful', detail: 'Records Updated', life: 1000 });
    } else {
      this.messageService.add({ severity: 'error', summary: 'Error', detail: 'Records Not Updated', life: 1000 });
    }
  }
   addTarget() {
    if(this.selectedSourceCompanies.length >0){
    let message = 'Are you sure you want to add selected Company';
    if(this.selectedSourceCompanies.length > 1){
      message = 'Are you sure you want to add selected Companies';
    }
    this.confirmationService.confirm({
      message: message,
      header: 'Confirm',
      icon: 'pi pi-exclamation-triangle',
        accept: () => {
      
      this.selectedSourceCompanies.forEach(async value => {
      let ratesetCompany = {rateset_id: this.selectedRateSet.id, company_id : value.id, status: '1'};
      let response = await this.CommonService.updateRecord( 'RatesetCompanies', ratesetCompany);
      value.ratesetcompany_id = response.id;
      const pos = this.destinationCompanies.findIndex(val => (val.id===value.id));
        if(pos == -1){
          this.destinationCompanies.push(value);
        }

        const index = this.sourceCompanies.findIndex(val => (val.id===value.id));
        //console.log("index",index);
        if(index>-1){
            this.sourceCompanies.splice(index, 1);
        }
      });  
      this.messageService.add({ severity: 'success', summary: 'Successful', detail: 'Company Added.', life: 1000 });
      this.selectedSourceCompanies = [];
      this.sourceCompanies.sort((a,b) => a.business_name - b.business_name);
      this.destinationCompanies.sort((a,b) => a.business_name - b.business_name);
      //console.log("destination",this.destinationCompanies)
    }
  });
}
  }
  async removeTarget() {
    if(this.selectedDestinationCompanies.length >0){
    let message = 'Are you sure you want to remove selected Company';
    if(this.selectedDestinationCompanies.length > 1){
      message = 'Are you sure you want to remove selected Companies';
    }
    this.confirmationService.confirm({
      message: message,
      header: 'Confirm',
      icon: 'pi pi-exclamation-triangle',
        accept: async () => {
   await this.selectedDestinationCompanies.forEach(async value =>{
      let ratesetCompany = {rateset_id: this.selectedRateSet.id, company_id : value.id, status: '1'};
      debugger
      let response = await this.CrudService.Delete(value.ratesetcompany_id,  'RatesetCompanies').toPromise();
      console.log(response);
      if(response){
  const pos = this.sourceCompanies.findIndex(val => (val.id===value.id));
      if(pos == -1){
        this.sourceCompanies.push(value);
      }
      const index = this.destinationCompanies.findIndex(val => (val.id === value.id));
      if(index>-1){
          this.destinationCompanies.splice(index, 1);
      }
      }
    

    });
    this.messageService.add({ severity: 'success', summary: 'Successful', detail: 'Company Removed.', life: 1000 });
    this.selectedDestinationCompanies = [];
    this.sourceCompanies.sort((a,b) => a.business_name - b.business_name);
    this.destinationCompanies.sort((a,b) => a.business_name - b.business_name);
      }
    }); 
  }
  }
  clear(table: Table) {
    table.clear();
    this.clearsearch ="";
  }
  async saveTarget(){
  //   let res: any = await this.CrudService.getSelectedCompaniesByRateSetId(this.selectedRateSet.id).toPromise();
  //   //console.log("response",res);
  //   //console.log("source",this.sourceCompanies);
  //   //console.log("distination",this.destinationCompanies);
  //   res.forEach(rsc => {
  //     let destinationCompanyData = this.destinationCompanies.find(val => (val.id != rsc.company_id));
  //     if(destinationCompanyData!=undefined){
  //       this.CrudService.Create(destinationCompanyData, 'RatesetCompanies').subscribe((data: any[]) => {});
  //     }
  //     let sourceCompanyData = this.sourceCompanies.find(val => (val.id == rsc.company_id));
  //     if(sourceCompanyData!=undefined){
  //       this.CrudService.Delete(sourceCompanyData.id, 'RatesetCompanies').subscribe((data: any[]) => {});
  //     }
  //   });

  //   this.allTpsList.forEach(data => {
  //     let sourcedata = this.sourceCompanies.find(val => (val.id != data.id));
  //     if (sourcedata != undefined){
  //       this.CrudService.Create(sourcedata, 'alltps').subscribe((data: any[]) => {});
  //     }
  //   });
  //   this.sourceCompanies.forEach(data => {
  //     let sourcedata = this.allTpsList.find(val => (val.id != data.id));
  //     if (sourcedata != undefined){
  //       this.CrudService.Delete(sourcedata.id, 'alltps').subscribe((data: any[]) => {});
  //     }
  //   });
  // }
  }
}
