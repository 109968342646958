import { Injectable } from '@angular/core';
import { Subject, Observable } from 'rxjs';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { map, delay } from 'rxjs/operators';
import { CRUDService } from './crud.service';
import * as moment from 'moment';

@Injectable({ providedIn: 'root' })
export class WorkFlowService {
    private WorkFlowSource = new Subject<string>();
    private resetSource = new Subject();
    WorkFlowSource$ = this.WorkFlowSource.asObservable();
    resetSource$ = this.resetSource.asObservable();
    apiRoot = null;
    userDetails: any;
    token: any = localStorage.getItem('auth_token');
    UserId:any = localStorage.getItem('user_id');

    //    apiRoot = 'http://54.206.66.198:4003/';
    //    token = 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpZCI6IjEiLCJpYXQiOjE1MDY1NjA5ODB9.LAGHEE6tyd9FmZXaK40yDeUkHVZkOl8MdXSrtEcGwB4';

    constructor(private http: HttpClient, private crudService: CRUDService) {

        this.apiRoot = this.crudService.apiRoot;
        this.token = this.crudService.token;
    }
    // tslint:disable: max-line-length
    getAPIRoot() {
        return this.apiRoot;
    }
    getAll(moduleName) {
      const headers = new HttpHeaders().set('Authorization', this.token).set('UserId', this.UserId);
        return this.http.get(this.apiRoot + moduleName + '/getAll', { headers });
    }
    getDescByField(moduleName, descField, filterField, filterValue) {
      const headers = new HttpHeaders().set('Authorization', this.token).set('UserId', this.UserId);
        return this.http.get(this.apiRoot + 'Company/getDescriptionValue/', { headers, params: { table_name: moduleName, column_name: descField, field_name: filterField, value: filterValue } });
    }
    getDataByField(moduleName, filterField, filterValue) {
      const headers = new HttpHeaders().set('Authorization', this.token).set('UserId', this.UserId);
        return this.http.get(this.apiRoot + 'Company/getDescriptionObject/', { headers, params: { table_name: moduleName, field_name: filterField, value: filterValue } });
    }
    getDataByCID(moduleName, id) {
      const headers = new HttpHeaders().set('Authorization', this.token).set('UserId', this.UserId);
        return this.http.get(this.apiRoot + moduleName + '/getByCID/', { headers, params: { companyID: id } });
    }
    fetchBoardDetails(wf_code) {
        const headers = new HttpHeaders().set('Authorization', this.token).set('UserId', this.UserId);
        return this.http.get(this.apiRoot + 'workflow/fetchBoardDetails', { headers, params: { workflow_code: wf_code } });
    };
    fetchColumnsList(workflow_code, user_id) {
      const headers = new HttpHeaders().set('Authorization', this.token).set('UserId', this.UserId);
        return this.http.get(this.apiRoot + 'workflow/fetchColumnsList', { headers, params: { workflow_code, user_id } });
    };
    fetchTasksList(wf_code, user_id) {
      const headers = new HttpHeaders().set('Authorization', this.token).set('UserId', this.UserId);
        return this.http.get(this.apiRoot + 'workflow/fetchTasksList', { headers, params: { workflow_id: wf_code, user_id } });
    };
    fetchTasksCheckList(wf_code: any, user_id: any) {
      const headers = new HttpHeaders().set('Authorization', this.token).set('UserId', this.UserId);
        return this.http.get(this.apiRoot + 'workflow/fetchTasksCheckList', { headers, params: { workflow_code: wf_code, user_id } });
    }
    fetchRequesterTasks(wf_code, userid, companyid) {
      const headers = new HttpHeaders().set('Authorization', this.token).set('UserId', this.UserId);
        return this.http.get(this.apiRoot + 'taskHead/getallTaskByReqUser', { headers, params: { wf_code, user_id: userid, companyID: companyid } });
    };
    fetchAllocatedTasks(wf_code, userid, companyid) {
      const headers = new HttpHeaders().set('Authorization', this.token).set('UserId', this.UserId);
        return this.http.get(this.apiRoot + 'taskHead/getallTaskByUser', { headers, params: { wf_code, user_id: userid, companyID: companyid } });
    };
    fetchAdminTasks(wf_code, userid, companyid) {
      const headers = new HttpHeaders().set('Authorization', this.token).set('UserId', this.UserId);
        return this.http.get(this.apiRoot + 'taskHead/getallTaskForAdmin', { headers, params: { wf_code, user_id: userid, companyID: companyid } });
    };
    fetchTasksListByCreater(wf_code, userid) {
      const headers = new HttpHeaders().set('Authorization', this.token).set('UserId', this.UserId);
        return this.http.get(this.apiRoot + 'workflow/fetchTasksListByCreater', { headers, params: { wf_code, user_id: userid } });
    };
    fetchTasksCheckListByUser(wf_code, userid) {
      const headers = new HttpHeaders().set('Authorization', this.token).set('UserId', this.UserId);
        return this.http.get(this.apiRoot + 'workflow/fetchTasksCheckListByUser', { headers, params: { wf_code, user_id: userid } });
    };

    async updateTask(task_id, jobId, jobAllocId, step, desc, reqby_dt, wf_code, selectedAllocatees,priority) {
        this.userDetails = JSON.parse(sessionStorage.getItem('loggedInUser'));
        const companyId = sessionStorage.getItem('company_id');
        let response: any;
        if (task_id !== null) {
            const taskDetailsData = await this.crudService.getDataByField('task_head', 'id', task_id).toPromise();
            const taskDetails = taskDetailsData[0];
            taskDetails.updated_at = new Date();
            taskDetails.updated_by = this.userDetails.id;
            taskDetails.name = 'Event : ' + step;
            taskDetails.description = desc;
            taskDetails.notes = step;
            taskDetails.end_dt = new Date(reqby_dt);
            taskDetails.next_followup = null;
            response = await this.crudService.Update(taskDetails, 'taskHead').toPromise();
            if (response.success === '1' || response.success === 1) {
                // const taskValuesResponse: any = await this.updateTaskValues(jobId, jobAllocId, response.id, 'Await Cost', step, companyId, userDetails.id)
                const actionresponse: any = await this.updateAction(response.id, 'Incident Updated', 'Updated', companyId, this.userDetails.id);
                const taskAllocationResponse = await this.updateTaskUsers(taskDetails.id, selectedAllocatees, taskDetails);
            }
            taskDetails.id = task_id;

        } else {
            const taskDetails = {
                description: desc,
                category: 1,
                created_at: new Date(),
                created_by: this.userDetails.id,
                end_dt: null,
                id: null,
                name: 'Event : ' + step.charAt(0).toUpperCase() +step.slice(1),
                next_followup: null,
                notes: step,
                priority: priority,
                process_id: companyId,
                start_dt: new Date(),
                status: '1',
                tags: jobAllocId,
                type: step,
                updated_at: null,
                updated_by: null,
                wd_code: null,// this.stagesList[0].id,
                wf_code
            }
            taskDetails.end_dt = new Date(reqby_dt);
            taskDetails.next_followup = null;
            const boardData: any = await this.fetchBoardDetails(wf_code).toPromise();
            const columnsData: any = await this.fetchColumnsList(wf_code, this.userDetails.id).toPromise();
            taskDetails.wd_code = columnsData[0].code;
            response = await this.crudService.Create(taskDetails, 'taskHead').toPromise();
            if (response.success === '1' || response.success === 1) {
                const taskValuesResponse: any = await this.updateTaskValues(jobId, jobAllocId, response.id, 'Await Cost', step, companyId, this.userDetails.id)
                const actionresponse: any = await this.updateAction(response.id, 'Incident Raised', 'Created', companyId, this.userDetails.id);
                if (selectedAllocatees.length > 0) {
                    const taskAllocationResponse = await this.updateTaskUsers(response.id, selectedAllocatees, taskDetails);
                }
            }
        }
        return response;
    }
    async createTaskValue(task_id, jobId, jobAllocId, step, desc, reqby_dt, wf_code, selectedAllocatees) {
      this.userDetails = JSON.parse(sessionStorage.getItem('loggedInUser'));
      const companyId = sessionStorage.getItem('company_id');
      let response:any;
      const taskDetails = {
        description: desc,
        category: 1,
        created_at: new Date(),
        created_by: this.userDetails.id,
        end_dt: null,
        id: null,
        name: 'Event : ' + step,
        next_followup: null,
        notes: step,
        priority: 'High',
        process_id: companyId,
        start_dt: new Date(),
        status: '2',
        tags: jobAllocId,
        type: '1',
        updated_at: null,
        updated_by: null,
        wd_code: null,// this.stagesList[0].id,
        wf_code
      }
      taskDetails.end_dt = new Date(reqby_dt);
      taskDetails.next_followup = null;
      const boardData: any = await this.fetchBoardDetails(wf_code).toPromise();
      const columnsData: any = await this.fetchColumnsList(wf_code, this.userDetails.id).toPromise();
      taskDetails.wd_code = columnsData[0].code;
      response = await this.crudService.Create(taskDetails, 'taskHead').toPromise();
      if (response.success === '1' || response.success === 1) {
        const taskValuesResponse: any = await this.updateTaskValues(companyId, jobAllocId, response.id,step, 'notes', companyId, this.userDetails.id)
        const actionresponse: any = await this.updateAction(response.id, 'Incident Raised', 'Created', jobId, this.userDetails.id);
        if (selectedAllocatees.length > 0) {
          const taskAllocationResponse = await this.updateTaskUsers(response.id, selectedAllocatees, taskDetails);
        }
      }
      return response;
    }

    async updateTaskUsers(task_id, allocatedUsers, taskDetails) {
        const userDetails = JSON.parse(sessionStorage.getItem('loggedInUser'));
        const companyId = sessionStorage.getItem('company_id');
        //console.log(allocatedUsers);
        this.crudService.getTaskUsers(task_id).subscribe((taskusersList: any) => {
            taskusersList.forEach(taskuser => {
                const userList = allocatedUsers.filter(val => val.user_id === taskuser.user_id);
                taskuser.id = taskuser.task_user_id;
                taskuser.updated_at = new Date().toLocaleString();
                taskuser.updated_by = userDetails.id;
                taskuser.action_yn = 'Y';
                taskuser.notify_yn = 'Y';
                taskuser.task_id = task_id;
                taskuser.wf_wd_id = null;
                // taskuser.wf_wd_code = null;
                taskuser.created_by = taskuser.task_created_by;
                taskuser.created_at = taskuser.task_created_at;
                if (userList.length > 0) {
                    let changed = false;
                    if (taskuser.status == '3') {
                        changed = true;
                    }
                    taskuser.status = '1';
                    this.crudService.Update(taskuser, 'taskUsers').subscribe((data: any) => {
                        if (changed) {

                        }
                    });
                } else {
                    taskuser.status = '3';
                    this.crudService.Update(taskuser, 'taskUsers').subscribe((data: any) => {
                    });
                }
            });

            allocatedUsers.forEach(async allocatee => {
                const newuser: any = {};
                const userList = taskusersList.filter(val => val.user_id === allocatee.user_id);
                if (userList.length > 0) {
                } else {
                    newuser.user_id = allocatee.user_id;
                    newuser.task_id = task_id;
                    newuser.action_yn = 'Y';
                    newuser.notify_yn = 'Y';
                    newuser.wf_wd_id = null;
                    newuser.status = '1';
                    newuser.created_at = new Date().toLocaleString();
                    newuser.created_by = userDetails.id;
                    newuser.updated_at = null;
                    newuser.updated_by = null;
                    const data: any = await this.crudService.Create(newuser, 'taskUsers').toPromise();
                    if (data.success == 1) {
                        newuser.id = data.id;
                        const actionresponse: any = await this.updateAction(task_id, 'Incident Allocated To ' + newuser.first_name + ' ' + newuser.last_name, 'Staff Allocated', companyId, userDetails.id)
                        const notifyresponse: any = await this.notifyUsers(allocatedUsers, taskDetails, 'Ticket Allocated : ' + task_id,
                            'Please Login to the portal to view Additional Details',
                            'Allocation Notification to ' + newuser.user_id + ' - ' + newuser.first_name + ' ' + newuser.last_name + ' Sent.', userDetails);
                    }
                }
            });
        });
    }

    async updateAction(task_id, message, action, companyId, user_id) {
        const userDetails = JSON.parse(sessionStorage.getItem('loggedInUser'));
        const taskMessage: any = {
            id: null,
            task_id,
            message,
            file_type: null,
            file_path: null,
            file_name: null,
            status: '1',
            action,
            star_colour: null,
            process_id: companyId,
            created_by: user_id,
            created_at: new Date(),
            updated_by: null,
            updated_at: null
        };
        const response: any = await this.crudService.Create(taskMessage, 'TaskMessages').toPromise();
        return response;
    }


    async notifyUsers(usersList, taskDetails, subject, message, notification, reqUser) {
        let allocatees = null;
        let mail_ids = null;
        if (usersList.length > 0) {
            usersList.forEach(allocatee => {
                if (allocatees === null) {
                    allocatees = allocatee.first_name + ' ' + allocatee.last_name;
                    mail_ids = allocatee.email ? allocatee.email : 'admin@agileitexperts.com';
                } else {
                    allocatees = allocatees + ', ' + allocatee.first_name + ' ' + allocatee.last_name;
                    mail_ids = mail_ids + ', ' + allocatee.email ? allocatee.email : 'admin@agileitexperts.com'
                }
                const AppNotifications: any = {
                    status: '1',
                    user_id: allocatee.id,
                    message,
                    title: subject,
                    read_status: '0',
                    ref_type: 'TASK',
                    ref_id: taskDetails.id
                }
                AppNotifications.title = 'Task Notification';
                this.updateRecord('AppNotifications', AppNotifications);
            });
        } else {
            allocatees = 'Unallocated.'
        }

        const data = {
            sms: null,
            email: {
                cc: mail_ids,
                subject,
                bcc: 'envirofrontiersk@gmail.com',
                to: 'venkat@agileitexperts.com, ' + reqUser.email,
                attachments: '',
                msg: message,
            },
            issueDetails: {
                id: taskDetails.id,
                issue_description: taskDetails.description,
                priority: taskDetails.priority,
                created_by: reqUser.id,
                AllocatedTo: allocatees
            },
            type: 'email',
            selectedTpl: null,
            sufix: null,
            jobData: null,
            attachments: {},
            replyTo: 'krishnanshridhar@gmail.com',
            account: 'AU'
        };
        const response: any = await this.crudService.sendMail(data).toPromise();
        return response;
    }


    async updateTaskValues(jobId, jobAllocID, taskId, type, step, companyId, userId) {
        const taskValues: any = {
            id: null,
            task_id: taskId,
            job_id: jobId,
            job_alloc_id: jobAllocID,
            type,
            step,
            status: '1',
            process_id: companyId,
            created_by: userId,
            created_at: new Date(),
            updated_by: null,
            updated_at: null
        }
        const response: any = await this.crudService.Create(taskValues, 'TaskValues').toPromise();
        return response;
    }


    async closeTask(task_id, remarks) {
        //console.log('closed');
        let selectedAllocatees: any;
        const userDeatails = JSON.parse(sessionStorage.getItem('loggedInUser'));
        const companyId = sessionStorage.getItem('company_id');
        const userDetailsData: any = await this.crudService.getDataByField('Users', 'id', userDeatails.id).toPromise();
        const userDetails = userDetailsData[0];
        const action = 'Closed';
        const taskHeadData: any = await this.crudService.getDataByField('task_head', 'id', task_id).toPromise();
        const taskHead: any = taskHeadData[0];
        const reqUserData: any = await this.crudService.getDataByField('Users', 'id', taskHead.created_by).toPromise();
        const reqUser: any = reqUserData[0];
        taskHead.status = '3';
        taskHead.updated_at = new Date();
        taskHead.updated_by = userDetails.id;
        taskHead.notes = remarks;
        const response: any = await this.crudService.Update(taskHead, 'taskHead').toPromise();
        const actionresponse: any = await this.updateAction(task_id, 'Status ' + action, action, companyId, reqUser);
        this.crudService.getTaskUsers(task_id).subscribe(async (userData: any[]) => {
            if (userData.length > 0) {
                selectedAllocatees = userData.filter(val => val.status === '1');;
            }
            const notifyresponse: any = await this.notifyUsers(
                selectedAllocatees, taskHead, 'Issue No ' + task_id + ' - ' + action,
                'The incident has been closed by ' + userDetails.first_name + ' ' + userDetails.last_name,
                'Notification Sent to Support Team Involved.', reqUser);
        });
    }

    async reopenTask(task_id, remarks) {
        //console.log('reopened');
        let selectedAllocatees: any;
        const userDeatails = JSON.parse(sessionStorage.getItem('loggedInUser'));
        const companyId = sessionStorage.getItem('company_id');
        const userDetailsData: any = await this.crudService.getDataByField('Users', 'id', userDeatails.id).toPromise();
        const userDetails = userDetailsData[0];
        const action = 'Reopened';
        const taskHeadData: any = await this.crudService.getDataByField('task_head', 'id', task_id).toPromise();
        const taskHead: any = taskHeadData[0];
        const reqUserData: any = await this.crudService.getDataByField('Users', 'id', taskHead.created_by).toPromise();
        const reqUser: any = reqUserData[0];
        taskHead.status = '4';
        taskHead.updated_at = new Date();
        taskHead.updated_by = userDetails.id;
        taskHead.notes = remarks;
        const response: any = await this.crudService.Update(taskHead, 'taskHead').toPromise();
        const actionresponse: any = await this.updateAction(task_id, 'Status ' + action, action, companyId, reqUser);
        this.crudService.getTaskUsers(task_id).subscribe(async (userData: any[]) => {
            if (userData.length > 0) {
                selectedAllocatees = userData.filter(val => val.status === '1');;
            }
            const notifyresponse: any = await this.notifyUsers(
                selectedAllocatees, taskHead, 'Issue No ' + task_id + ' - ' + action,
                'The incident has been reopened by ' + userDetails.first_name + ' ' + userDetails.last_name,
                'Notification Sent to Support Team Involved.', reqUser);
        });
    }

    getCurrentDateTime(): any {
        const date = new Date();

        const DateTime =
            date.getFullYear() +
            '-' +
            ('00' + (date.getMonth() + 1)).slice(-2) +
            '-' +
            ('00' + date.getDate()).slice(-2)
            + ' ' + ('00' + date.getHours()).slice(-2) + ':' + ('00' + date.getMinutes()).slice(-2) + ':00';
        return DateTime;
    }

    async updateRecord(moduleName: any, data: any): Promise<any> {
        const userInfo = JSON.parse(sessionStorage.getItem('loggedInUser'));
        //console.log('update', data);
        let response: any;
        if (typeof data.id !== 'undefined') {
            if (data.id !== null && data.id !== '') {
                data.updated_at = this.getCurrentDateTime();
                data.updated_by = userInfo.id;
                response = await this.crudService.Update(data, moduleName).toPromise();
                response.id = data.id;
            } else {
                data.updated_at = null;
                data.updated_by = null;
                data.created_at = moment(this.getCurrentDateTime()).format('MM/DD/YYYY HH:mm:ss');
                data.created_by = Number(userInfo.id);
                if (typeof data.status === 'undefined') {
                    data.status = 1;
                }
                if (data.status === '' || data.status == null) {
                    data.status = 1;
                }
                response = await this.crudService.Create(data, moduleName).toPromise();
            }
        } else {
            data.updated_at = null;
            data.updated_by = null;
            data.created_at = moment(this.getCurrentDateTime()).format('MM/DD/YYYY HH:mm:ss');
            data.created_by = Number(userInfo.id);
            if (typeof data.status === 'undefined') {
                data.status = 1;
            }
            if (data.status === '' || data.status == null) {
                data.status = 1;
            }
            response = await this.crudService.Create(data, moduleName).toPromise();
        }
        return response;
    }

    async searchNow(companyId, selectedOverAllStatus, selectedClient, selectedJobStatus, searchValue) {
    let jobList: any[] = [];
    let finalQuery = null;
    let initialWhereConditionVal = this.initialWhereCondition(selectedOverAllStatus);
    let clientQuery = this.constructClientQuery(selectedClient);
    let statusQuery = this.constructJobStatusQuery(selectedJobStatus);
    let whereAvail = false;
    if (initialWhereConditionVal === 'ALL') {
      //console.log('All Status');
    } else {
      finalQuery = initialWhereConditionVal;
      whereAvail = true;
    }
    if (clientQuery === 'ALL') {
      //console.log('All Clients');
    } else {
      if (whereAvail) {
        finalQuery = finalQuery + ' and ' + clientQuery;
      } else {
        if (finalQuery === null) {
          finalQuery = ' where ' + clientQuery;
        } else {
          finalQuery = finalQuery + ' where ' + clientQuery;
        }

        whereAvail = true;
      }
    }
    if (statusQuery === 'ALL') {
      //console.log('All status ');
    } else {
      if (whereAvail) {
        finalQuery = finalQuery + ' and ' + statusQuery;
      } else {
        if (finalQuery === null) {
          finalQuery = ' where ' + clientQuery;
        } else {
          finalQuery = finalQuery + ' where ' + statusQuery;
        }
        whereAvail = true;
      }
    }
    let appendSearchString = null;
    if ((searchValue != null) && (searchValue != '')) {
      appendSearchString = ' and ( ( FullAddress like \'%' + searchValue + '%\') or (job_claim_no like \'%' + searchValue + '%\' ) or (FullAddress like \'%' + searchValue + '%\' ) or ( JobNo like \'%' + searchValue + '%\') )';
      if (finalQuery === null) {
        finalQuery = ' where ' + appendSearchString;
      } else {
        finalQuery = finalQuery + appendSearchString;
      }
    }

    let respobj = { status: null, client: null, query: null };
    respobj.query = finalQuery;
    //respobj.company_id=companyId;
    if (finalQuery === null) {
        let data: any = await this.crudService.ListingPageOperationsActive2(respobj).toPromise();
        jobList = data;
    } 
    jobList = jobList.sort((b, a) => a.JobId - b.JobId);
    return jobList;
  }

    initialWhereCondition(selectedOverAllStatus) {
    /*     let Active = " where  ( contractor_id  = " + companyId + " ) and JobStatus not in ('Invoice Processed','Accounts Processed','Offer Rejected,Re-issued','Quote Rejected','Job Completed','Job Cancelled', 'Allocation Cancelled','On Hold')";
        let Invoiced = " where ( contractor_id  =" + companyId + " )   and  JobStatus in ('Invoice Processed','Accounts Processed')";
        let Hold = " where ( contractor_id  =" + companyId + " )   and JobStatus in ('On Hold') ";
        let Completed = " where ( contractor_id  =" + companyId + " ) and JobStatus in ('Job Completed') ";
        let Cancelled = " where ( contractor_id  =" + companyId + " ) and JobStatus in ('Job Cancelled', 'Allocation Cancelled','Offer Rejected,Re-issued') ";
        let selectionQuery = " where ";
     */
    let Active = " where  ( contractor_id  is null or contractor_id not in (276,970,830,1008) ) and JobStatus not in ('Invoice Processed','Accounts Processed','Offer Rejected,Re-issued','Quote Rejected','Job Completed','Job Cancelled', 'Allocation Cancelled','On Hold')";
    let Invoiced = " where ( contractor_id  is null or contractor_id not in (276,970,830,1008) ) and  JobStatus in ('Invoice Processed','Accounts Processed')";
    let Hold = " where ( contractor_id  is null or contractor_id not in (276,970,830,1008) ) and JobStatus in ('On Hold') ";
    let Completed = " where ( contractor_id  is null or contractor_id not in (276,970,830,1008) ) and JobStatus in ('Job Completed') ";
    let Cancelled = " where ( contractor_id  is null or contractor_id not in (276,970,830,1008) ) and JobStatus in ('Job Cancelled', 'Allocation Cancelled','Offer Rejected,Re-issued') ";
    let selectionQuery = " where ";

    let initialWhereCondition = null;

    if (selectedOverAllStatus.id === 1) {
      initialWhereCondition = Active;
    } if (selectedOverAllStatus.id === 2) {
      initialWhereCondition = Invoiced;
    } if (selectedOverAllStatus.id === 3) {
      initialWhereCondition = Completed;
    } if (selectedOverAllStatus.id === 4) {
      initialWhereCondition = Cancelled;
    } if (selectedOverAllStatus.id === 5) {
      initialWhereCondition = Hold;
    } if (selectedOverAllStatus.id === 6) {
      initialWhereCondition = 'ALL';
    }
    return initialWhereCondition;
  }

  constructClientQuery(selectedClient) {
    let ClientQuery = null;
    selectedClient.forEach(client => {
      if (ClientQuery === null) {
        ClientQuery = ' WPType in (\'' + client.name + '\'';
      } else {
        ClientQuery = ClientQuery + ',\'' + client.name + '\'';
      }
    });
    if (ClientQuery === null) {
      ClientQuery = 'ALL';
    } else {
      ClientQuery = ClientQuery + ')';
    }
    return ClientQuery;
  }

  constructJobStatusQuery(selectedJobStatus) {
    let JobStatusQuery = null;
    selectedJobStatus.forEach(status => {
      if (JobStatusQuery === null) {
        JobStatusQuery = ' JobStatus in (\'' + status.id + '\'';
      } else {
        JobStatusQuery = JobStatusQuery + ',\'' + status.id + '\'';
      }
    })
    if (JobStatusQuery === null) {
      JobStatusQuery = 'ALL';
    } else {
      JobStatusQuery = JobStatusQuery + ')';
    }
    return JobStatusQuery;
  }
}
