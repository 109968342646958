import { Component, OnInit, Input } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ConfirmationService, MessageService } from 'primeng/api';
import { CRUDService } from 'src/app/utilities/crud.service';
import { WorkFlowService } from 'src/app/utilities/workflow.service';
import { DynamicDialogRef, DialogService, DynamicDialogConfig } from 'primeng/dynamicdialog';
import { JOBService } from 'src/app/utilities/job.service';
import { environment } from 'src/environments/environment';
import { JobScheduleComponent } from '../job-schedule/job-schedule.component';
import { JobCostingComponent } from '../job-costing/job-costing.component';
import { WpCostingComponent } from '../wp-costing/wp-costing.component';
import { TreeInfoComponent } from '../../modules/tree-info/tree-info.component';
import { RejectQuoteComponent } from '../reject-quote/reject-quote.component';
import { ApproveQuoteComponent } from '../approve-quote/approve-quote.component';
import { JobImagesComponent } from '../job-images/job-images.component';
import { JobDocumentsComponent } from '../job-documents/job-documents.component';
import { SendQuoteComponent } from '../send-quote/send-quote.component';
import { SubContractAgreementCreateComponent } from '../sub-contract-agreement-create/sub-contract-agreement-create.component';
import { JobInvoiceSendComponent } from '../job-invoice-send/job-invoice-send.component';
import { JobKpiComponent } from '../job-kpi/job-kpi.component';
import { JobDetailsApprovalsFlaggingComponent } from '../../modules/job-details-approvals-flagging/job-details-approvals-flagging.component';
import { ShfComponent } from '../shf/shf.component';
import { JobAdminComponent } from '../job-admin/job-admin.component';
import { WorkflowAutomationExceptionsComponent } from '../../modules/workflowautomationexceptions/workflowautomationexceptions.component';
import { TaskDetailsComponent } from '../../modules/task-details/task-details.component';
import { AppReminderComponent } from '../../modules/app-reminder/app-reminder.component';
import { THIS_EXPR } from '@angular/compiler/src/output/output_ast';
import { formatDate } from 'ngx-bootstrap';
import { DatePipe } from '@angular/common';


@Component({
  selector: 'app-job-details-approvals',
  templateUrl: './job-details-approvals.component.html',
  styleUrls: ['./job-details-approvals.component.scss'],
  providers: [DialogService, MessageService, ConfirmationService, JOBService, WorkFlowService,
    JobScheduleComponent, JobCostingComponent, WpCostingComponent, TreeInfoComponent,
    JobDocumentsComponent, JobImagesComponent, ApproveQuoteComponent, RejectQuoteComponent,
    SendQuoteComponent, SubContractAgreementCreateComponent, JobInvoiceSendComponent, ShfComponent, JobKpiComponent, JobDetailsApprovalsFlaggingComponent]
})
export class JobDetailsApprovalsComponent implements OnInit {
  @Input() jobId: any;
  @Input() allocId: any;
  @Input() jobSummary: any;
  @Input() viewType: any;
  dateFormat:any;
  DateFormat:any;
  defaultTab: any;
  jobDetails: any[];
  jobInfo: any;
  title: any;
  date: any;
  address: any;
  communication: boolean = false;
  jobschedule: boolean = false;
  schedNote: any;
  costing: boolean = false;
  documents: boolean = false;
  display: boolean = false;
  companyId: any;
  userDetails: any;
  Date_str: any;
  docSummary: any[];
  wizardMetaData = [];
  selectedAllocatees: any[] = [];
  staffList: any[] = [];
  timeSlots: any[];
  filteredTimeSlots: any[];
  time: any;
  jobAllocationList: any[] = [];
  workflowautomationexceptions: any[] = [];
  showFlaggingConfirmation: Boolean = false;
  showWFAutomationExceptions: Boolean = false;
  showUnFlaggingConfirmation: Boolean = false;
  showRevokeConfirmation: Boolean = false;
  selectedReason: any;
  loading : Boolean = false;
  unflag_remarks: any;
  revokeReasonList = [
    { code: 'Data Corrected', desc: 'Corrections made.' },
    { code: 'Client Acted', desc: 'Client updated required Info. Responded to Follow-up.' },
    { code: 'Contractor Acted', desc: 'Contractor updated required Info. Responded to Follow-up.' },
    { code: 'Updated', desc: 'Details Updated.' },
    { code: 'Others', desc: 'Others' }
  ]
  flagReasonList = [
    { code: 'Wrong Info', desc: 'Wrong Info Entered. Corrections to be made.' },
    { code: 'Client On Hold', desc: 'Client Requested to put on hold.' },
    { code: 'Contractor On Hold', desc: 'Contractor Requested to put on hold.' },
    { code: 'No Update', desc: 'Details not Updated.' },
    { code: 'Others', desc: 'Others' }
  ]
  reason: any;
  remarks: any;
  followup_dt: any;
  currentWiz: any;
  timeFromPicker :any;
  quoteWizardMetaData = [
    { id: '1', code: 'schedule', title: 'Sched & Comms', class: 'active', iconClass: 'fa fa-calendar', flagged: false, approved: false, acceptanceCriteria: ['Schedule should match this criteria - Lorem Ipsum is simply dummy text of the printing and typesetting industry'] },
    { id: '2', code: 'documents', title: 'Document', iconClass: 'fa fa-picture-o', flagged: false, approved: false, acceptanceCriteria: ['All images are clear', 'Images show Details of Damages', 'Images show details of access if special equipment required'] },
    { id: '3', code: 'images', title: 'Images', iconClass: 'fa fa-book', flagged: false, approved: false, acceptanceCriteria: ['Document should match this criteria', 'Document should match this criteria', 'Document should match this criteria'] },
    { id: '4', code: 'treeinfo', title: 'Tree Info', iconClass: 'fa fa-tree', flagged: false, approved: false, acceptanceCriteria: ['Tree information details are correct', 'Work required highlighted correctly', 'Damages section is meaningful'] },
    { id: '5', code: 'costing', title: 'Costing', iconClass: 'fa fa-money', flagged: false, approved: false, acceptanceCriteria: ['TP substantiation is meaningful', 'Make safe jobs dont have cleanup component', 'Staff and Equipment relavant to scope of works'] },
    { id: '6', code: 'wpcosting', title: 'WP Costing', iconClass: 'fa fa-money', flagged: false, approved: false, acceptanceCriteria: ['% Margin acceptable', 'WP notes clear', 'Handling fee added in case of SES jobs'] },
    { id: '7', code: 'sendemail', title: 'Send Email', iconClass: 'fa fa-envelope-o', flagged: false, approved: false, acceptanceCriteria: ['To add details in portal for this client'] },
    { id: '7', code: 'quoteapproval', title: 'Approval', iconClass: 'fa fa-thump-o', flagged: false, approved: false, acceptanceCriteria: ['To add details in portal for this client'] },
  ];

  approvalWizardMetaData = [
    { id: '1', code: 'schedule', title: 'Sched & Comms', class: 'active', iconClass: 'fa fa-calendar', flagged: false, approved: false, acceptanceCriteria: ['Schedule should match this criteria - Lorem Ipsum is simply dummy text of the printing and typesetting industry'] },
    { id: '2', code: 'documents', title: 'Document', iconClass: 'fa fa-picture-o', flagged: false, approved: false, acceptanceCriteria: ['All images are clear', 'Images show Details of Damages', 'Images show details of access if special equipment required'] },
    { id: '3', code: 'images', title: 'Images', iconClass: 'fa fa-picture-o', flagged: false, approved: false, acceptanceCriteria: ['All images are clear', 'Images show Details of Damages', 'Images show details of access if special equipment required'] },
    { id: '4', code: 'treeinfo', title: 'Tree Info', iconClass: 'fa fa-tree', flagged: false, approved: false, acceptanceCriteria: ['Tree information details are correct', 'Work required highlighted correctly', 'Damages section is meaningful'] },
    { id: '5', code: 'costing', title: 'Costing', iconClass: 'fa fa-money', flagged: false, approved: false, acceptanceCriteria: ['TP substantiation is meaningful', 'Make safe jobs dont have cleanup component', 'Staff and Equipment relavant to scope of works'] },
    { id: '6', code: 'wpcosting', title: 'WP Costing', iconClass: 'fa fa-money', flagged: false, approved: false, acceptanceCriteria: ['% Margin acceptable', 'WP notes clear', 'Handling fee added in case of SES jobs'] },
    { id: '7', code: 'shf', title: 'SHF', iconClass: 'fa fa-envelope-o', flagged: false, approved: false, acceptanceCriteria: ['To add details in portal for this client'] },
    { id: '8', code: 'sca', title: 'SCA', iconClass: 'fa fa-envelope-o', flagged: false, approved: false, acceptanceCriteria: ['To add details in portal for this client'] },
    { id: '9', code: 'kpi', title: 'KPI', iconClass: 'fa fa-envelope-o', flagged: false, approved: false, acceptanceCriteria: ['To add details in portal for this client'] },
    { id: '10', code: 'jobinvoicesend', title: 'Invoice Details', iconClass: 'fa fa-money', flagged: false, approved: false, acceptanceCriteria: ['To add details in portal for this client'] },
    { id: '11', code: 'sendemail', title: 'Send Email', iconClass: 'fa fa-envelope-o', flagged: false, approved: false, acceptanceCriteria: ['To add details in portal for this client'] },
  ];

  priorityOptions = [
    { code: 'Critical', name: 'Critical' },
    { code: 'High', name: 'High' },
    { code: 'Normal', name: 'Normal' },
    { code: 'Low', name: 'Low' }
    ];

  // Sub Contract Agreement
  // job-invoice-sendpl.
  // Site Hazard Form
  // KPI Form

  tabsActive: any = { id: '1', code: 'schedule', title: 'Sched & Comms', class: 'active', iconClass: 'fa fa-calendar', flagged: false, approved: false, acceptanceCriteria: ['Schedule should match this criteria - Lorem Ipsum is simply dummy text of the printing and typesetting industry'] };
  env : any;
  minDate: Date;
  Un_timeSlots: string[];
  Job_status_data: any='';
  selectedPriority: any;
  jobAllocationResults: any;
  selectedAllocatees_to_unflag: any[];
  constructor(
    private route: ActivatedRoute,
    private crudService: CRUDService,
    private messageService: MessageService,
    private confirmationService: ConfirmationService,
    private router: Router,
    private dialogService: DialogService,
    private JobService: JOBService,
    private wfService: WorkFlowService,
    public config: DynamicDialogConfig,
    private datepipe: DatePipe
  ) {
    this.companyId = sessionStorage.getItem('company_id');
    this.userDetails = JSON.parse(sessionStorage.getItem('loggedInUser'));
    this.timeSlots = ['6:00am', '6:30am', '7:00am', '7:30am', '8:00am', '8:30am', '9:00am', '9:30am', '10:00am', '10:30am', '11:00am', '11:30am',
      '12:00pm', '12:30pm', '1:00pm', '1:30pm', '2:00pm', '2:30pm', '3:00pm', '3:30pm', '4:00pm', '4:30pm', '5:00pm', '5:30pm', '6:00pm', '6:30pm'];
    //'12:00am', '12:30am', '1:00am', '1:30am', '2:00am', '2:30am', '3:00am', '3:30am', '4:00am', '4:30am', '5:00am', '5:30am','7:00pm', '7:30pm', '8:00pm', '8:30pm', '9:00pm', '9:30pm', '10:00pm', '10:30pm', '11:00pm', '11:30pm'
    this.Un_timeSlots = ['7:00pm', '7:30pm', '8:00pm', '8:30pm', '9:00pm', '9:30pm', '10:00pm', '10:30pm','11:00pm',  '11:30pm'];
    this.filteredTimeSlots = this.timeSlots;
  }

  async ngOnInit() {
    this.env = environment;
    //this.followup_dt = new Date().toLocaleString();;
    if(this.env.hosting === 'UK')
    {
      this.approvalWizardMetaData = this.approvalWizardMetaData.filter(item => item.code !== 'sca');
    }

    this.dateFormat = environment.dateFormat;
    this.DateFormat = environment.DateFormat;

    if (this.config && this.config.data) {
      this.jobId = this.config.data.jobId;
      this.allocId = this.config.data.allocId;
      this.jobSummary = this.config.data.jobSummary;
      this.Job_status_data = this.config.data.jobStatus;
      this.viewType = this.config.data.viewType;
      this.defaultTab = this.config.data.defaultTab;
      this.enableTabs();
    }
    console.log("this.defaultTab",this.defaultTab);
    /* this.menuData = JSON.parse(sessionStorage.getItem('menuData')); */
    if (this.viewType === 'Quote') {
      this.wizardMetaData = this.quoteWizardMetaData;
    } else {
      this.wizardMetaData = this.approvalWizardMetaData;
    }
    await this.updateWizardInfo();
    this.wizardMetaData[0].active = true;
    if (this.defaultTab !== null && this.defaultTab !== '') {
      this.wizardMetaData.forEach(wiz => {
       // wiz.followup_dt = null;
        
        if (wiz.code === this.defaultTab) {
          wiz.active = true;
          this.updateTab(wiz)
        } else {
          wiz.active = false;
        }
      })
    }
    this.crudService.getAllSSPUsers(this.companyId).subscribe((data: any[]) => {
      this.staffList = data;
    });

    this.loadJobSummary();
    this.getOtherJobAllocationDetails();
    var currentTime = new Date();
    const cValue = formatDate(currentTime, 'h:00a', 'en-US');
    if(this.Un_timeSlots.includes(cValue)){
      this.minDate = new Date() ;
      this.minDate.setDate(this.minDate.getDate() + 1);
    }
    else{
      this.minDate = new Date();
    }
  }

  async updateWizardInfo() {
    debugger
    let jobWFStatus: any = await this.crudService.getDataByField('Job_wf_status', 'job_alloc_id', this.allocId).toPromise();
    let workflowautomationexceptions: any = await this.crudService.getDataByField('workflowautomationexceptions', 'job_alloc_id', this.allocId).toPromise();
    this.wizardMetaData.forEach(wmd => {
      let jobsteps = jobWFStatus.filter(val => (wmd.code === val.step));
      if (jobsteps.length > 0) {
        let jobstep = jobsteps[0];
        if (jobstep.flag === '2') {
          wmd.flagged = true;
        } else {
          wmd.flagged = false;
        }
        if (jobstep.status === '2') {
          wmd.approved = true;
        } else {
          wmd.approved = false;
        }
        wmd.revokeReason = jobstep.remarks;
        wmd.reason = jobstep.remarks;
        wmd.remarks = jobstep.additional_remarks;

        wmd.jobstepid = jobstep.id;
        wmd.task_id = jobstep.task_id;
        wmd.created_at = jobstep.created_at;
        wmd.created_by = jobstep.created_by;
        // if(jobstep.follow_up_dt == null){
        //   jobstep.follow_up_dt = new Date()
        // }
        wmd.followup_dt = new Date(jobstep.follow_up_dt).toLocaleString();
      } else {
        let jobstep: any = {};
        wmd.flagged = false;
        wmd.approved = false;
        wmd.revokeReason = null;
        wmd.reason = null;
        wmd.remarks = null;
        wmd.jobstepid = null;
        wmd.task_id = null;
        wmd.created_at = null;
        wmd.created_by = null;
        wmd.followup_dt = null;
      }
      let exceptionData = workflowautomationexceptions.filter(val => (val.stage === wmd.code));
      let acceptanceCriteria: string[] = [];
      //console.log(exceptionData);
      exceptionData.forEach(exception => {
        wmd.acceptanceCriteria.push('Workflow Automation Exception Enabled. Paused Till : ' + new Date(exception.pause_till).toLocaleString());
      });

    })
    this.workflowautomationexceptions = workflowautomationexceptions;
  }

  loadJobSummary() {
    //console.log('Job Notes', this.jobSummary);
    this.Date_str = new Date(this.jobSummary.Date).toLocaleDateString();
    this.title = this.jobSummary.JobNo + ' - (' + this.jobSummary.JobStatus + ')';
    this.crudService.getDataByField('jobinfo', 'id', this.jobId).subscribe((data: any[]) => {
      this.jobDetails = data;
      this.jobInfo = data[0];
      this.date = this.jobDetails[0].job_recd_dt.split('T')[0];
      this.crudService.getCostingInfo(this.jobId, this.allocId).subscribe((costingInfo: any[]) => {
        if (costingInfo.length > 0) {
          if (this.jobSummary.wp_grand_total !== null && this.jobSummary.wp_grand_total !== '') {
            this.jobSummary.tp_grand_total = parseFloat(costingInfo[0].tp_grand_total).toFixed(2);
            this.jobSummary.wp_grand_total = parseFloat(costingInfo[0].wp_grand_total).toFixed(2);
            let variance = this.jobSummary.tp_grand_total / this.jobSummary.wp_grand_total;
            variance = 1 - variance;
            variance = variance * 100;
            this.jobSummary.margin = parseFloat(variance.toString()).toFixed(2);
          }
        }
      });
      this.crudService.getUploadDocumentsSummary(this.jobId, this.allocId).subscribe((uploadStatus: any[]) => {
        if (uploadStatus.length > 0) {
          this.docSummary = uploadStatus;
        }
      });
    })
  }
  //
  /* editSelectedRecord() {
    if (this.viewType === 'TM') {
      const ref = this.dialogService.open(JobAdminComponent, {
        data: {
          jobId: this.jobId,
        },
        header: 'Job Info',
        width: '100%',
        height: 'calc(100% - 69px)'
      })
      ref.onClose.subscribe((response: any) => {
        this.loadJobSummary();
      });

    } else {
      const ref = this.dialogService.open(JobUserComponent, {
        data: {
          JobId: this.jobId,
          jobAllocationId: this.allocId,
        },
        header: 'Job Info',
        width: '100%',
        height: 'calc(100% - 69px)'
      })
      ref.onClose.subscribe((response: any) => {
        this.loadJobSummary();
      });
    }
  } */

  editSelectedRecord() {
    const ref = this.dialogService.open(JobAdminComponent, {
      data: {
        jobId: this.jobId,
      },
      header: 'Job Info',
      width: '100%',
      height: 'calc(100% - 69px)'
    })
    ref.onClose.subscribe((response: any) => {
      this.loadJobSummary();
    });
  }

  approvalProcess() {

  }

  communicationTab() {
    this.communication = true;
    // this.crudService.getDataByField('Communications','id',this.jobId).subscribe((data:any)=>{
    //   //console.log(data);
    // })

  }


  jobscheduleTab() {
    const ref = this.dialogService.open(JobScheduleComponent, {
      data: {
        jobAllocationId: this.allocId,
        jobId: this.jobId
      },
      header: 'Job Schedule',
      width: '100%',
      height: '100%'
    });
    ref.onClose.subscribe((response: any) => {
      this.loadJobSummary();
    });
  }

  costingTab() {
    const ref = this.dialogService.open(JobCostingComponent, {
      data: {
        jobAllocationId: this.allocId,
        jobId: this.jobId
      },
      header: 'Job Costing',
      width: '100%',
      height: '100%'
    });
    ref.onClose.subscribe((response: any) => {
      this.loadJobSummary();
    });
  }

  loadDocuments() {
    const ref = this.dialogService.open(JobDocumentsComponent, {
      data: {
        jobAllocationId: this.allocId,
        jobId: this.jobId
      },
      header: 'Upload Documents',
      width: '100%',
      height: '100%'
    })
    ref.onClose.subscribe((response: any) => {
      this.loadJobSummary();
    });
  }

  loadImages() {
    const ref = this.dialogService.open(JobImagesComponent, {
      data: {
        jobAllocationId: this.allocId,
        jobId: this.jobId
      },
      header: 'Images',
      width: '100%',
      height: '100%'
    });
    ref.onClose.subscribe((response: any) => {
      this.loadJobSummary();
    });
  }

  loadApproveQuote() {
    const ref = this.dialogService.open(ApproveQuoteComponent, {
      data: {
        jobAllocationId: this.allocId,
        jobId: this.jobId
      },
      header: 'Approve Quote',
      width: '100%',
      height: '100%'
    })
    ref.onClose.subscribe((response: any) => {
      this.loadJobSummary();
    });
  }

  loadRejectQuote() {
    const ref = this.dialogService.open(RejectQuoteComponent, {
      data: {
        jobAllocationId: this.allocId,
        jobId: this.jobId
      },
      header: 'Reject Quote',
      width: '100%',
      height: '100%'
    })
    ref.onClose.subscribe((response: any) => {
      this.loadJobSummary();
    });
  }

  loadSubContract() {
    const ref = this.dialogService.open(SubContractAgreementCreateComponent, {
      data: {
        jobAllocationId: this.allocId,
        jobId: this.jobId
      },
      header: 'Sub Contract Agreement',
      width: '100%',
      height: '100%'
    })
    ref.onClose.subscribe((response: any) => {
      this.loadJobSummary();
    });
  }

  loadKPI() {
    //console.log(this.allocId, this.jobId);
    const ref = this.dialogService.open(JobKpiComponent, {
      data: {
        jobAllocationId: this.allocId,
        jobId: this.jobId
      },
      header: 'Job KPI',
      width: '100%',
      height: '100%'
    });
    ref.onClose.subscribe((response: any) => {
      this.loadJobSummary();
    });
  }

  loadWpCosting() {
    //console.log(this.allocId, this.jobId);
    const ref = this.dialogService.open(WpCostingComponent, {
      data: {
        jobAllocationId: this.allocId,
        jobId: this.jobId
      },
      header: 'WP Costing',
      width: '100%',
      height: '100%'
    });
    ref.onClose.subscribe((response: any) => {
      this.loadJobSummary();
    });
  }

  loadSendEmail() {
    //console.log(this.allocId, this.jobId);
    const ref = this.dialogService.open(SendQuoteComponent, {
      data: {
        jobAllocationId: this.allocId,
        jobId: this.jobId
      },
      header: 'Send Email',
      width: '100%',
      height: '100%'
    });
  }

  loadTreeInfo() {
    //console.log(this.allocId, this.jobId);
    const ref = this.dialogService.open(TreeInfoComponent, {
      data: {
        jobAllocationId: this.allocId,
        jobId: this.jobId
      },
      header: 'Tree Info',
      width: '100%',
      height: '100%'
    })
  }

  jobInvoiceSend() {
    //console.log(this.allocId, this.jobId);
    const ref = this.dialogService.open(JobInvoiceSendComponent, {
      data: {
        jobAllocationId: this.allocId,
        jobId: this.jobId
      },
      header: 'Job Invoice Send',
      width: '100%',
      height: '100%'
    })
    ref.onClose.subscribe((response: any) => {
      this.loadJobSummary();
    });
  }

  loadSiteHazard() {
    //console.log(this.allocId, this.jobId);
    const ref = this.dialogService.open(ShfComponent, {
      data: {
        jobAllocationId: this.allocId,
        jobId: this.jobId
      },
      header: 'Site Hazard Form',
      width: '100%',
      height: '100%'
    })
    ref.onClose.subscribe((response: any) => {
      this.loadJobSummary();
    });
  }

  enableTabs() {
    setTimeout(() => {
      this.initTab();
    })
  }

  tagConfig;
  initTab() {
    this.tagConfig = [];
    let l1 = [];
    let l2 = [];
    this.wizardMetaData.forEach(w => {
      l1.push(document.getElementById(w.id));
      l2.push(document.getElementById(w.id + '-info-tab-activate'));
      this.tagConfig.push({
        id: w.id,
        activeTab: document.getElementById(w.id),
        activeContent: document.getElementById(w.id + '-info-tab-activate')
      })
    })
    function initializeTabs(config) {
      config.forEach(c => {
        document.getElementById(c.id).addEventListener('click', () => {
          l1.forEach((ele) => {
            ele?.classList?.remove('active');
          });
          c.activeTab?.classList?.add('active');

          l2.forEach((ele) => {
            if (ele && ele.length > 0) {
              for (let i = 0; i < ele.length; i++) {
                ele[i]?.classList?.add('hideme');
              }
            }
          });
          if (c.activeContent && c.activeContent.length > 0) {
            for (let i = 0; i < c.activeContent.length; i++) {
              c.activeContent[i]?.classList?.remove('hideme');
            }
          }

        });
      })
    }
    initializeTabs(this.tagConfig);
  }

  updateTab(wiz) {
    this.tagConfig?.forEach(c => {
      if (c.content && c.id === wiz.id) {
        this[c.content] = true;
      }
    });
    this.wizardMetaData.forEach((w) => {
      w.active = false;
      if (wiz.code === w.code) {
        w.active = true;
        w.class = 'active';
      } else {
        w.class = '';
      }
    })
    let activeTabs = this.wizardMetaData.filter(val => (val.active === true));
    this.tabsActive = activeTabs[0];
  }

  removeUser(user) {
    this.selectedAllocatees = this.selectedAllocatees.filter(val => (val.user_id !== user.user_id));
  }

  async editFlag(wizInfo) {
    let jobWFStatusData: any = await this.crudService.getDataByField('Job_wf_status', 'id', wizInfo.jobstepid).toPromise();
    let jobWFStatus = jobWFStatusData[0];
    const ref = this.dialogService.open(JobDetailsApprovalsFlaggingComponent, {
      data: {
        jobId: jobWFStatus.job_id,
        allocId: this.allocId,
        FlagUnflag: 'FLAG',
        job_status: this.jobSummary.JobStatus,
        step: jobWFStatus.step,
        wf_status_id: jobWFStatus.id,
        flagType: '1'
      },
      width: '75%',
      height: '75%',
      styleClass: ''
    })
    ref.onClose.subscribe((response: any) => {
      if (response.success === 1) {
      }
    })
  }
  
  async updateJobStepStatus(wiz, action) {
    debugger
    let desc: any = null;
    let jobStepStatus: any = {};
    jobStepStatus.remarks = null;
    jobStepStatus.task_id = null;
    jobStepStatus.additional_remarks = null
    jobStepStatus.follow_up_dt = null;
    jobStepStatus.id = null;
    if (action === 'flagged') {
      jobStepStatus.status = '1';
      jobStepStatus.flag = '2';
    } else if (action === 'unflagged') {
      jobStepStatus.flag = '1';
      jobStepStatus.status = '1';
    } else if (action === 'approved') {
      jobStepStatus.flag = '1';
      jobStepStatus.status = '2';
    } else if (action === 'revoked') {
      jobStepStatus.flag = '1';
      jobStepStatus.status = '3';
    }
    jobStepStatus.job_id = this.jobId;
    jobStepStatus.job_alloc_id = this.allocId;
    jobStepStatus.step = wiz.code;
   jobStepStatus.type = wiz.code;
   if(!this.selectedPriority || this.selectedPriority == undefined){
    jobStepStatus.priority = 'Normal'
   }
   else{
    jobStepStatus.priority =  this.selectedPriority.code;
   }
  
   

    if (typeof wiz.created_at !== 'undefined') {
      if (wiz.created_at !== null) {
        jobStepStatus.created_at = wiz.created_at;
      }
    }
    if (typeof wiz.created_by !== 'undefined') {
      jobStepStatus.created_by = wiz.created_by;
    }

    if (typeof this.selectedReason !== 'undefined') {
      if (this.selectedReason !== null && this.selectedReason !== '') {
        jobStepStatus.remarks = this.selectedReason.code;
        desc = 'Reason : ' + this.selectedReason.desc;
      }
    }

    if (typeof this.remarks !== 'undefined') {
      if (this.remarks !== null && this.remarks !== '') {
        if (action === 'unflagged') {
          desc = desc + ' - Un-Flagging Remarks : ' + this.remarks;
        } else {
          desc = desc + ', Remarks : ' + this.remarks;
        }
        jobStepStatus.additional_remarks = this.remarks;
      }
    }
    if((this.selectedReason && this.remarks) || action === 'unflagged')
    { 
        this.loading = true;
        jobStepStatus.follow_up_dt = null;
        let followup_dateTime: any = null;
        if (typeof this.followup_dt !== 'undefined') {
          if (this.followup_dt !== null && this.time !== '' && this.time !== null) {

            const dateFromPicker1 = formatDate(this.followup_dt, 'M/DD/YYYY', 'en-US')
            const dateFromPicker = dateFromPicker1.toLocaleString().replace(', 00:00:00', '');
            if(this.time != undefined)
            {
              this.timeFromPicker = this.time;
            }
            else
            {
              this.timeFromPicker = '10:00am';
            }
            
            const dateParts: any[] = dateFromPicker.split("/");
            const timeParts: any[] = this.timeFromPicker.split(":");
            let minutes: any;
            let hours: any;
            if (timeParts[1].includes('pm')) {
              hours = parseInt(timeParts[0]) + 12;
              minutes = timeParts[1].replace('pm', '');
            } else {
              hours = parseInt(timeParts[0]);
              minutes = timeParts[1].replace('am', '');

            }
            const localDate = new Date(dateParts[2],dateParts[0]-1,dateParts[1], hours, minutes);
            followup_dateTime = localDate;
            jobStepStatus.follow_up_dt = followup_dateTime;
          }
        }
        if (action !== 'unflagged') {
          wiz.reason = jobStepStatus.remarks;
          wiz.remarks = this.remarks;
          wiz.followup_dt = followup_dateTime?.toLocaleString();
        }
        else
        {
          wiz.reason = null;
          wiz.remarks = null;
          wiz.unflag_remarks=this.unflag_remarks;
          wiz.followup_dt = null;
        }
        

        /*     if (typeof this.followup_dt !== 'undefined') {
              if (this.followup_dt !== null) {
                jobStepStatus.follow_up_dt = this.followup_dt;
              }
            } */
        if (wiz.jobstepid) {
          jobStepStatus.id = wiz.jobstepid;
        }
        if (wiz.task_id) {
          jobStepStatus.task_id = wiz.task_id;
        }
        let res: any = await this.JobService.updateJobWFStatus(jobStepStatus);
        if (res.success === 1 || res.success === '1') {
          this.loading = false;
          this.showFlaggingConfirmation=false;
          this.showUnFlaggingConfirmation=false;
          if (res.id) {
            wiz.jobstepid = res.id;
            jobStepStatus.id = res.id;
          }
          
          if (action === 'flagged') {
            /* let End_Date = new Date();
            if (this.followup_dt !== null) {
              End_Date = new Date(jobStepStatus.follow_up_dt);
            } */
            desc = jobStepStatus.step +' '+ desc;
            if (jobStepStatus.task_id !== null && jobStepStatus.task_id !== '') {
              let taskResponse = await this.wfService.reopenTask(jobStepStatus.task_id, jobStepStatus.step + ' Flagged. Reason: ' + desc);
              const actionresponse: any = await this.wfService.updateAction(jobStepStatus.task_id, 'Worklow Step Flagged', 'Flagged', this.companyId, this.userDetails.id);
              let taskDetails = await this.crudService.getDataByField('task_head', 'id', jobStepStatus.task_id).toPromise();
              if (this.selectedAllocatees.length > 0) {
                const taskAllocationResponse = await this.wfService.updateTaskUsers(jobStepStatus.task_id, this.selectedAllocatees, taskDetails[0]);
              }
            } else {
              let taskResponse = await this.wfService.updateTask(null, this.jobId,
                this.allocId,
                jobStepStatus.step,
                desc,
                jobStepStatus.follow_up_dt,
                '7',
                this.selectedAllocatees,jobStepStatus.priority);
              if (taskResponse.id) {
                jobStepStatus.task_id = taskResponse.id;
                wiz.task_id = taskResponse.id;
                let res2: any = await this.JobService.updateJobWFStatus(jobStepStatus);
              }
            }

            let allocationData: any = await this.crudService.getDataByField('JobAllocation', 'id', this.allocId).toPromise();
            if (allocationData.length > 0) {
            await  this.selectedAllocatees.forEach( async user =>{
                let taskResponse ={"id":jobStepStatus.task_id}
              await  this.sendallocationNotification(taskResponse,allocationData,user,action)
              })
              this.messageService.add({ severity: 'success', detail: 'Notification to Allocated Users Sent', life: 4000 });
              let allocDtls: any = allocationData[0];
              allocDtls.wf_status = '2';
              let response: any = await this.crudService.updateAllocationWorkflowStatus(jobStepStatus.id, allocDtls.wf_status, this.remarks, allocDtls.id).toPromise();
            }
          } else if (action === 'unflagged') {
            let wfStatusData: any = await this.crudService.getDataByField('job_wf_status', 'Job_alloc_id', this.allocId).toPromise();
            let wfStepStatusData = wfStatusData.filter(val => (val.step === jobStepStatus.step));
            let wfStepStatus: any;
            if (wfStepStatusData.length > 0) {
              wfStepStatus = wfStepStatusData[0];
              wfStepStatus.flag = '1';
              let res2: any = await this.JobService.updateJobWFStatus(wfStepStatus);
              let taskResponse = await this.wfService.closeTask(wfStepStatus.task_id, jobStepStatus.step + ' Unflagged. Reason: ' + desc);
            }
            let data: any = await this.crudService.getDataByField('task_head', 'id', wfStepStatus.task_id).toPromise();
            if (data.length > 0) {
              let taskDetails = data[0];
            await  this.crudService.getTaskUsers(taskDetails.id).subscribe((data: any[]) => {
                let usersList = data.filter(val => val.status === '1');
                this.selectedAllocatees_to_unflag = usersList;
              });
            let allocationData: any = await this.crudService.getDataByField('JobAllocation', 'id', this.allocId).toPromise();
            await this.selectedAllocatees_to_unflag.forEach( async user =>{
              await this.sendallocationNotification(taskDetails,allocationData,user,action)
            })
            this.messageService.add({ severity: 'success', detail: 'Notification to Allocated Users Sent', life: 4000 });
            this.selectedAllocatees_to_unflag =[]
            wfStatusData = wfStatusData.filter(val => (val.step !== jobStepStatus.step));
            wfStatusData = wfStatusData.filter(val => (val.flag === '2'));
            if (allocationData.length > 0) {
              let allocDtls: any = allocationData[0];
              if (wfStatusData.length < 1) {
                allocDtls.wf_status = '1';
              } else {
                allocDtls.wf_status = '2';
              }
              let response: any = await this.crudService.updateAllocationWorkflowStatus(jobStepStatus.wf_status_id, allocDtls.wf_status, this.remarks, allocDtls.id).toPromise();

            }
          }
          await this.updateWizardInfo();
          this.selectedReason = null;
          this.time = null;
          this.followup_dt = null;
          this.remarks = null;
          this.selectedAllocatees = [];
          this.showFlaggingConfirmation=false;
          this.loading = false;
          this.messageService.add({ severity: 'success', summary: 'Successful', detail: 'Status Updated', life: 1000 });
        }
      }
    else
    {
      this.loading = false;
      this.messageService.add({ severity: 'error', summary: 'Failed', detail: 'Please Fill Required Fields', life: 1000 });
    }
  }
}
  async sendallocationNotification(taskResponse,ScheduleFormData,user,action) {
  let jobtaskData: any = await this.crudService.getTaskdetail( taskResponse.id).toPromise();
    console.log("wfStatusData",job_task_Data);
    var job_task_Data =jobtaskData[0]
    this.loading = true;
    this.jobAllocationResults = ScheduleFormData
    //console.log('this.jobAllocationResults?.info?.job_claim_no',this.jobAllocationResults?.info?.job_claim_no)
    if(job_task_Data.Job_Claim_No != '' || job_task_Data.Job_Claim_No != null)
    {
      var claimNo :any = job_task_Data.Job_Claim_No;
    }
    if(job_task_Data.Job_Claim_No == '' || job_task_Data.Job_Claim_No == null)
    {
      var claimNo :any = "N/A";
    }
    if(this.env.hosting == 'UK'){
      var emailSubject =  claimNo + ', Site Address - ' + job_task_Data.Client_Address +','+job_task_Data.Client_Suburb+','+job_task_Data.Client_Post_Code;
    }
    else{
      var emailSubject = 'Claim Number - ' + claimNo + ', Site Address - ' + job_task_Data.Client_Address +','+job_task_Data.Client_Suburb+','+job_task_Data.Client_Post_Code;
    } 
    var time = this.datepipe.transform( new Date(job_task_Data.Followup_Date), "dd/MM/yyyy h:mma" ).toLowerCase();
if(action=="flagged"){
  var message_content = 'Hi ' +user.full_name  + ' , <br/><br/>You have been allocated a task for job number '+ job_task_Data.Job_No+'.' 
  + '<p style="margin-left:30px"><br/><b>Job No:</b> ' + job_task_Data.Job_No
  + '<br/><b>Task ID: </b>' + job_task_Data.Task_ID  
  + '<br/><b>Task Details: </b>' + job_task_Data.Task_Name +', '+job_task_Data.Task_Description+ '<br/>' 
  + '<b>Follow Up Date: </b>' + time + '<br/>' 
  + '<b>Priority: </b>' +job_task_Data.Priority + '<br/>' 
  + '<br/><b>Client Name: </b>' +job_task_Data.Client_Name + '<br/>' + '<b>Address: </b>' +job_task_Data.Client_Address+', '+job_task_Data.Client_Suburb+', '+job_task_Data.Client_Post_Code
  + '<br/><b>Contact: </b>' +job_task_Data.Client_Mobile +', '+job_task_Data.Client_Mail + '<br/>' 
  + '<br/><b>TP Name: </b>' +job_task_Data.TP_Name + '<br/>' + '<b>Address: </b>' +job_task_Data.TP_Address+', '+job_task_Data.TP_Suburb
  + '<br/><b>Contact: </b>' +job_task_Data.TP_Contact_Name +', '+job_task_Data.TP_Phone+', '+job_task_Data.TP_Email
  +'</p>'
  }
  else{
    var message_content = 'Hi ' +user.full_name  + ' , <br/><br/>You have been unallocated from a task for job number '+ job_task_Data.Job_No +'.'
    + '<p style="margin-left:30px"><br/><b>Job No:</b> ' + job_task_Data.Job_No
    + '<br/><b>Task ID: </b>' + job_task_Data.Task_ID  
    + '<br/><b>Task Details: </b>' + job_task_Data.Task_Name +', '+job_task_Data.Task_Description+ '<br/>' 
    + '<b>Follow Up Date: </b>' + time + '<br/>' 
    + '<b>Priority: </b>' +job_task_Data.Priority + '<br/>' 
    + '<br/><b>Client Name: </b>' +job_task_Data.Client_Name + '<br/>' + '<b>Address: </b>' +job_task_Data.Client_Address+', '+job_task_Data.Client_Suburb+', '+job_task_Data.Client_Post_Code
    + '<br/><b>Contact: </b>' +job_task_Data.Client_Mobile +', '+job_task_Data.Client_Mail + '<br/>' 
    + '<br/><b>TP Name: </b>' +job_task_Data.TP_Name + '<br/>' + '<b>Address: </b>' +job_task_Data.TP_Address+', '+job_task_Data.TP_Suburb
    + '<br/><b>Contact: </b>' +job_task_Data.TP_Contact_Name +', '+job_task_Data.TP_Phone+', '+job_task_Data.TP_Email
    +'</p>'
  }

  
   let mailSubject = '';

    let documentList = [];
    let data: any = {
      email: {
        from: '',
        //need to configure user mails (to:user.email) while moving to live
        to: user.email,
        cc: '',
        bcc: '',
        subject: emailSubject,
        message: message_content,
        msg: message_content,
        ClientId: this.jobAllocationResults?.WPCompanyId,
        ref_type: 'Flagging',
        ref_id: 1   // doubt
      },
      
    }
    let temp_type = 'email';

    let atts = { jobId: this.jobAllocationResults.id, allocId: this.jobAllocationResults.job_alloc_id, documentList };
    if(this.env.emailRoutes.common == 'sendgrid')
    {
      const datobj2 = {
        subject : data.email.subject,
        body : data.email.message,
        toEmail : data.email.to,
        ref_id : 1,
        ref_type : 'Flagging',
        attachments: atts
        }
  
      if(datobj2.toEmail?.length>0)
      {
        this.crudService.sendmailSendgrid(datobj2,'commonMail2').subscribe((res2: any[]) => {        
         });
      }

    }
    this.loading = false;
}
  openWFException(wizinfo) {
    const ref = this.dialogService.open(WorkflowAutomationExceptionsComponent, {
      data: {

        allocId: this.allocId,
        jobId: this.jobId,
        job_status: this.jobSummary.JobStatus,
        step: wizinfo.code,
      },
      header: '',
      width: '100%',
      height: '100%'
    })

    ref.onClose.subscribe((response: any) => {
      this.ngOnInit();
    });
  }

  openTask(wizinfo) {
    console.log("wizinfo",wizinfo);
    const ref = this.dialogService.open(TaskDetailsComponent, {
      data: {
        wf_code: '7',
        task_id: wizinfo.task_id,
        checkList: null,
        redirectMenu: null,
        step: wizinfo.code,
      },
      header: '',
      width: '100%',
      height: '100%'
    })

    ref.onClose.subscribe((taskdetails: any) => {
    });
  }

  // search(event) {
  //   this.filteredTimeSlots = this.timeSlots.filter((val: any) => val.includes(event.query));
  // }

  getOtherJobAllocationDetails() {
    this.jobAllocationList = [];
    // this.jobAllocationList.push({ id: 12701, TreeProfessional: 'Joeys Tree Services', Status: 'Approve Quote', Amount: '$ 20120' });
    //  this.jobAllocationList.push({ id: 12786, TreeProfessional: 'MSV Trees', Status: 'Approve Quote', Amount: '$ 9569' });


    this.crudService.getOtherAllocationDetails(this.jobId).subscribe((res: any) => {
      this.jobAllocationList = res;
      //console.log('alloc list : ' + res);
    })
  }

  openReminders(wiz){
    const ref = this.dialogService.open(AppReminderComponent, {
      data: {
        job_id: this.jobId,
        alloc_id: this.allocId,
        wf_step: wiz.code,
        task_id: null
      },
      width: 'auto',
      height: '100%',
      header:'Event'
    })
    ref.onClose.subscribe((response: any) => {
    });
  }

  refereshFlag(event)
  {
    this.updateWizardInfo();
    console.log("Event",event)
  }

  changeTimeslot() {
    var currentTime = new Date();
    const cValue = formatDate(currentTime, 'h:00a', 'en-US');
    var currentdate = new Date();
     if(formatDate(this.followup_dt, 'M/d/yy', 'en-US') == formatDate(currentdate, 'M/d/yy', 'en-US')){
      let index = this.timeSlots.indexOf(cValue)
      index = index+1;
      this.filteredTimeSlots = this.timeSlots.slice(index);
      if(this.filteredTimeSlots.includes(this.time)){
     }
     else{
       this.time = '';
     }
     }
   }
     search(event) {
       var currentTime = new Date();
       if(formatDate(this.followup_dt, 'M/d/yy', 'en-US') == formatDate(currentTime, 'M/d/yy', 'en-US')){
       this.filteredTimeSlots = this.filteredTimeSlots.filter((val: any) => val.includes(event.query));
     }else {
       this.filteredTimeSlots = this.timeSlots.filter((val: any) => val.includes(event.query));
     }
   }
   clearform(){
    this.selectedReason =null;
    this.selectedPriority=null;
    this.remarks = null;
    this.followup_dt =null;
    this.time = null
    this.selectedAllocatees= []
   }
}