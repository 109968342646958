import { Component, OnInit } from '@angular/core';
import { CRUDService } from 'src/app/utilities/crud.service';
import { ConfirmationService } from 'primeng/api';
import { MessageService } from 'primeng/api';
import { Valueset } from './valuseset';
import { Table } from 'primeng/table';
import { promise } from 'protractor';
import { environment } from 'src/environments/environment';
import { CommonService } from 'src/app/utilities/common.service';
import { DatePipe } from '@angular/common';



@Component({
  selector: 'app-valueset',
  templateUrl: './valueset.component.html',
  styleUrls: ['./valueset.component.scss']
})
export class ValuesetComponent implements OnInit {

  companyId: any;
  cols: any[];
  exportColumns: any[];
  valueSetDialog: boolean;
  submitted: boolean;
  paginationvalue: any;
  valueSetList: any[];
  valueSet: any;
  selectedValueSet: any[];
  valueSetDetailList: any[];
  valueSetDetail: any;
  selectedValueSetDetail: any[];
  valueSetDetailDialogue: any = false;
  isActive: boolean;
  isEditAll: boolean = false;
  disableNew:boolean = false;
  exportdisplay: Boolean = false;
  reformattedArray: any[];
  valueset: any[];
  Status = [
    {label: 'Active', value: "Active"},
    {label: 'In-Active', value: "In-Active"}, 
]
  successCount: number;
  failedCount: number;

  isExport: boolean=false;
  isDelete: boolean=false;
  selectedroute: string;
  userDetails: any;
  constructor(private CRUDService: CRUDService,private datePipe: DatePipe,
    private messageService: MessageService,
    private confirmationService: ConfirmationService,private commonservice: CommonService) {

      this.userDetails = JSON.parse(sessionStorage.getItem('loggedInUser'));
      if (this.userDetails === null) {
        this.userDetails = JSON.parse(sessionStorage.getItem('adminUser'));
      }
     }

  async ngOnInit(): Promise<void> {
    this.paginationvalue = environment.paginatorValue;
    this.companyId = sessionStorage.getItem('company_id');
    this.valueset=[];

    this.selectedroute = sessionStorage.getItem('selectedRoute')

    let per_data :any=await this.CRUDService.getmenupermissions()
    this.isExport = per_data.isExport;
    this.isDelete = per_data.isDelete;
    //console.log(this.companyId);
    // this.CRUDService.getDataByField('ValueSet', 'process_id', this.companyId).subscribe((data: any[]) => {
    await this.CRUDService.getDataByField('ValueSet', 'process_id', 1).subscribe(async (data: any[]) => {
      this.valueSetList = data;
      console.log("mycheck", this.valueSetList);
      await this.valueSetList.forEach(element => {
        element.isEdit = false;
        if (element.status == null || element.status == 1) {
          element.status = '1';
          element.status_desc = 'Active'
        } else {
          element.status = '2';
          element.status_desc = 'In-Active'
        }
      });
      // this.valueSetList.sort((a, b) => a.valueset_name> b.valueset_name && 1 || -1);
    });

    this.cols = [
      { field: 'valueset_name', header: 'Value Set Name' },
      { field: 'valueset_desc', header: 'Value Set Description' },
      { field: 'status_desc', header: 'Status' }
    ];

    this.exportColumns = this.cols.map(col => ({ title: col.header, dataKey: col.field }));
    this.exportdisplay = await this.commonservice.exportCheck();
    // this.loading = false;
  }

  openNew() {
    this.valueSet = {
      id: null,
      process_id: '1',
      valueset_name: null,
      valueset_desc: null,
      status: null,
      created_by: null,
      last_modified_by: null,
      created_at: null,
      last_updated_at: null,
      owner: null,
      make_default: null
    };
    this.valueSetDetailList = [];
    this.submitted = false;
    this.valueSetDialog = true;
  }
  addNewRecord() {

    this.valueSet = {
      id: null,
      process_id: '1',
      valueset_name: null,
      valueset_desc: null,
      status: '1',
      created_by: null,
      last_modified_by: null,
      created_at: null,
      last_updated_at: null,
      owner: null,
      make_default: null,
      isEdit: true,

    };
    this.valueSetList = [this.valueSet, ...this.valueSetList];
    this.disableNew = true;
  }

  editValueSet(valueset: Valueset) {
    this.valueSetDetailDialogue = false;
    this.valueSet = { ...valueset };
    if (this.valueSet.status === '' || this.valueSet.status === null || this.valueSet.status == '1') {
      this.valueSet.status = '1'
      this.isActive = true;
    } else {
      this.valueSet.status = '2'
      this.isActive = false;
    }
    if (this.valueSet.id != null) {
      this.CRUDService.getDataByField('ValueSetDetail', 'valueset_id', this.valueSet.id).subscribe((data: any[]) => {
        this.valueSetDetailList = data;
      });
    }
    this.valueSetDialog = true;
  }

  deleteSelectedValueSet() {
    this.confirmationService.confirm({
      message: 'Are you sure you want to delete the selected value set ?',
      header: 'Confirm',
      icon: 'pi pi-exclamation-triangle',
      accept: () => {
        // tslint:disable-next-line: prefer-for-of
        for (let i = 0; i < this.selectedValueSet.length; i++) {
          this.CRUDService.Delete(this.selectedValueSet[i].id, 'ValueSet').subscribe((data: any[]) => { });
        }
        this.valueSetList = this.valueSetList.filter(val => !this.selectedValueSet.includes(val));
        this.selectedValueSet = null;
        this.messageService.add({ severity: 'success', summary: 'Successful', detail: 'Value Set Deleted', life: 1000 });
      }
    });
  }

  deleteValueSet(valueset: Valueset) {
    this.confirmationService.confirm({
      message: 'Are you sure you want to delete value set : ' + valueset.valueset_name + ' ?',
      header: 'Confirm',
      icon: 'pi pi-exclamation-triangle',
      accept: () => {
        this.valueSetList = this.valueSetList.filter(val => val.id !== valueset.id);
        this.valueSet = {};
        this.CRUDService.Delete(valueset.id, 'ValueSet').subscribe((data: any[]) => {
          this.messageService.add({ severity: 'success', summary: 'Successful', detail: 'Value Set Deleted', life: 1000 });
        });
      }
    });
  }

  saveValueSet() {
    this.submitted = true;
    // this.valueSet.process_id=this.companyId;
    console.log("valueSetDetailList",this.valueSetDetailList);
    if (this.isActive) {
      this.valueSet.status = '1';
    } else {
      this.valueSet.status = '2';
    }
    if (this.valueSet.valueset_name.trim() && this.valueSet.valueset_desc.trim()) {
      if (this.valueSet.id) {
        //console.log(this.valueSetDetail);
        this.CRUDService.Update(this.valueSet, 'ValueSet').subscribe((data: any[]) => {
          this.messageService.add({ severity: 'success', summary: 'Successful', detail: 'Value Set Updated', life: 1000 });
          this.valueSetList[this.findIndexById(this.valueSet.id)] = this.valueSet;

          this.valueSetDetailList.forEach(async valueSetDetail => {
            this.CRUDService.Update(valueSetDetail, 'ValueSetDetail').subscribe((data: any[]) => {
            });
          });

          
        });
      }
      else {
        this.CRUDService.Create(this.valueSet, 'ValueSet').subscribe((data: any[]) => {
          this.messageService.add({ severity: 'success', summary: 'Successful', detail: 'Value Set Created', life: 1000 });
          this.valueSetList.push(this.valueSet);
        });
      }
      //console.log(this.valueSet);
  //    this.valueSetDialog = false;
    }
  }
  findIndexById(id: string): number {
    let index = -1;
    for (let i = 0; i < this.valueSetList.length; i++) {
      if (this.valueSetList[i].id === id) {
        index = i;
        break;
      }
    }

    return index;
  }

  saveValueSetDetail() {
    this.submitted = true;
    if (this.isActive) {
      this.valueSet.status = '1';
    } else {
      this.valueSet.status = '2';
    }
    this.valueSetDetail.valueset_id = this.valueSet.id;
    if (this.valueSet.valueset_name.trim() && this.valueSet.valueset_desc.trim()) {
      if (this.valueSetDetail.id) {
        //console.log(this.valueSetDetail);
        this.CRUDService.Update(this.valueSetDetail, 'ValueSetDetail').subscribe((data: any[]) => {
        });
        this.messageService.add({ severity: 'success', summary: 'Successful', detail: 'Value Set Detail Updated', life: 1000 });
      }
      else {
        this.CRUDService.Create(this.valueSetDetail, 'ValueSetDetail').subscribe((data: any[]) => {
          this.messageService.add({ severity: 'success', summary: 'Successful', detail: 'Value Set Detail Created', life: 1000 });
          this.valueSetList.push(this.valueSetDetail);
          this.valueSetDetailList.push(this.valueSetDetail);
        });
      }
    }
    //
    this.valueSetDetailDialogue = false;
  //  this.valueSetDialog = false;
  }


  addValueSetDetail(valueset_name) {
    this.valueSetDetailDialogue = true;
    //console.log(this.valueSetDetailDialogue);
    //console.log("in add detail function");
    this.valueSetDetail = {
      id: null,
      process_id: '1',
      valueset_detail_name: valueset_name,
      valueset_detail_desc: null,
      status: null,
      created_by: null,
      last_modified_by: null,
      created_at: null,
      last_updated_at: null,
      owner: null,
      make_default: null,
      valueset_id: this.valueSet.id
    };
    //console.log("value set id in details :"+this.valueSetDetail.valueset_id);
  }

  removeValueSetDetail(valuesetdetail: any) {
    this.confirmationService.confirm({
      message: 'Are you sure you want to delete selected value set detail?',
      header: 'Confirm',
      icon: 'pi pi-exclamation-triangle',
      accept: () => {
        this.valueSetDetailList = this.valueSetDetailList.filter(val => val.id !== valuesetdetail.id);
       // this.valueSet = {};
        this.CRUDService.Delete(valuesetdetail.id, 'ValueSetDetail').subscribe((data: any[]) => {
         // this.valueSetDialog = false;
          this.messageService.add({ severity: 'success', summary: 'Successful', detail: 'Value Set Detail Deleted', life: 1000 });
        });
      }
    });
  
  }


  onRowSelect(event) {
    this.valueSet = event.data;
    this.valueSetDialog = true;
  }

  onRowUnselect(event) {
    this.messageService.add({ severity: 'info', summary: 'Product Unselected', detail: event.data.name });
  }

  hideDialog() {

    this.valueSetDetailDialogue = false;
    this.submitted = false;
  }
  hideDetailsDialog() {
    this.valueSetDialog = false;
  }
  exportPdf() {
    import('jspdf').then(jsPDF => {
      import('jspdf-autotable').then(x => {
        /* const doc = new jsPDF.default(0,0);
        doc.autoTable(this.exportColumns, this.selectedDepots);
        doc.save('products.pdf'); */
      })
    })
  }

  exportExcel(selectedValueSet,table) {
    if (selectedValueSet && selectedValueSet?.length !== 0) {
      this.reformattedArray = this.selectedValueSet.map(obj => {
        let rObj = {};
        this.cols.forEach(pair => {
          rObj[pair.header.toString()] = obj[pair.field];
        });
        return rObj;
      })

    } else {
      let JsonData: any[] = [];
      if (this.valueset != null && this.valueset?.length > 0) {
        JsonData = this.valueset;
      } else {

        if (typeof table.filteredValue !== 'undefined') {
          if (table.filteredValue?.length !== this.valueSetList?.length && table.filteredValue?.length > 0) {
            JsonData = table.filteredValue;
          } else {
            JsonData = this.valueSetList;
          }
        } else {
          JsonData = this.valueSetList;
        }
      }
      this.reformattedArray = JsonData.map(obj => {
        let rObj = {};
        this.cols.forEach(pair => {
          rObj[pair.header.toString()] = obj[pair.field];
        });
        return rObj;
      })
    }
    import('xlsx').then(xlsx => {
      const worksheet = xlsx.utils.json_to_sheet(this.reformattedArray);
      const workbook = { Sheets: { data: worksheet }, SheetNames: ['data'] };
      const excelBuffer: any = xlsx.write(workbook, { bookType: 'xlsx', type: 'array' });
      this.saveAsExcelFile(excelBuffer, 'Valueset');
    });
  }

  saveAsExcelFile(buffer: any, fileName: string): void {
    import('file-saver').then(FileSaver => {
      const EXCEL_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
      const EXCEL_EXTENSION = '.xlsx';
      const data: Blob = new Blob([buffer], {
        type: EXCEL_TYPE
      });
      FileSaver.saveAs(data, fileName + '_export_' + new Date().getTime() + EXCEL_EXTENSION);
      let timeStamp = this.datePipe.transform(new Date(), 'ddMMyy_HHmm');
      FileSaver.saveAs(data, fileName + '_' + timeStamp + EXCEL_EXTENSION);
    });
  }

  clear(table: Table) {
    table.clear();
  }

  resetLastCheckPoint(valueset) {
    if(valueset.id == null && (!valueset.valueset_name  && !valueset.valueset_desc ) ){
      this.valueSetList = this.valueSetList.filter(val => val !== valueset);
      this.disableNew = false
    }
    //console.log(rateset);
    valueset = valueset.backup;
    //console.log(rateset);
  }

  backup(valueset) {
    //console.log("backup",rateset);
    valueset = valueset.backup;
  }

  async saveRecords() {
    let saveStatus = true;
    this.successCount = 0;
    this.failedCount = 0;
    let changedRecords = this.valueSetList.filter(val => (val.changed === true));
    if (changedRecords.length != 0) {
      changedRecords.forEach(async valueset => {
        if (valueset.status === '' || valueset.status === null ||
        valueset.status == '1') {
          valueset.status = '1'
          valueset.isActive = true;
       }
        else {
          valueset.status = '2'
          valueset.isActive = false;
       }

        let status = await this.saveRecordNew(valueset);
        if (status){
          this.successCount = this.successCount +1;
        }
        else{
          this.failedCount = this.failedCount +1;
        }
        if(changedRecords.length == (this.successCount + this.failedCount)){
          if(this.successCount >0){ 
            if(this.successCount ==1){
              var Records = ' Record'
            }
            else{
              var Records = ' Records'
            }
              this.messageService.add({ severity: 'success', summary: 'Successful', detail: this.successCount+ Records+' Updated Successfully', life: 3500 });
            }
          if(this.failedCount >0){
            // this.disableSave=true;
            if(this.failedCount ==1){
              var Records = ' Record'
            }
            else{
              var Records = ' Records'
            }
              this.messageService.add({ severity: 'error', summary: 'Error', detail: this.failedCount + Records+' Not Updated, Please Enter The Mandatory Values!', life: 3500 });
          }
        }
      });

    }
    else{
      let editedRow= this.valueSetList.filter(val=> ( val.id == null));
      editedRow.forEach(valueset => {
        if(valueset.id == null && (!valueset.valueset_name  && !valueset.valueset_desc ) ){
          this.valueSetList = this.valueSetList.filter(val => val !== valueset);
          this.disableNew = false
        }
        valueset.isEdit = true;
      });
      this.disableNew = false;
      this.isEditAll = false;
      if(editedRow.length ==0){
        this.messageService.add({ severity: 'info', summary: 'Info', detail: 'No Records to Save', life: 3500 });
      }

    }

  }
  async saveRecordNew(valueset) {
    let status: Boolean = true;
    if (valueset.valueset_name.trim() && valueset.valueset_desc.trim()) {
      if (valueset.id) {
        if (valueset.isActive) {
          valueset.status = '1';
        }
        else if (!valueset.isActive) {
          valueset.status = '2';
        }
        this.CRUDService.Update(valueset, 'ValueSet').subscribe((data: any) => {
          if(data.success == '1'){
            valueset.changed = false;
            valueset.isEdit = false
          }else{
            status = false;
            valueset.isEdit = true;
          }
        });
      }
      else {
        this.resetLastCheckPoint(valueset)
        valueset.status = '1'
        valueset.isActive = true;
        this.CRUDService.Create(valueset, 'ValueSet').subscribe((data: any) => {
          if (data.success == '1') {
            debugger
            valueset.id= data.id
            valueset.changed = false; 
            valueset.isEdit = false
            this.disableNew = false;
          } else {
            this.disableNew = true;
            valueset.isEdit = true;
            status = false;
          }
        });
      }

    }
    else {
      status = false;
    }
    return status;
  }
}
