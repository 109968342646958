import { Component, Input, OnInit } from '@angular/core';
import { CRUDService } from 'src/app/utilities/crud.service';
import { DynamicDialogRef, DialogService } from 'primeng/dynamicdialog';
import { ConfirmationService } from 'primeng/api';
import { MessageService } from 'primeng/api';
import { Table } from 'primeng/table';
import { Staff } from './staff';
import { ComplianceComponent } from '../compliance/compliance.component';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { CommonService } from 'src/app/utilities/common.service';
import { environment } from 'src/environments/environment';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { DatePipe } from '@angular/common';
import { EncryptionService } from 'src/app/utilities/encryption.service';
import { debug } from 'console';
import { EncrDecrService } from 'src/app/utilities/encr-decr.service';
import { NgTinyUrlService } from 'ng-tiny-url';
@Component({
  selector: 'admin-app-staffs',
  templateUrl: './staffs.component.html',
  styleUrls: ['./staffs.component.scss'],
  providers: [DialogService, MessageService, ConfirmationService, DatePipe]
})
export class StaffsComponent implements OnInit {
  notificationflag: boolean = false;
  paginationvalue: any;
  @Input() contractor_id: any;
  @Input() videoUrl: any;
  @Input() pdfUrl: any;
  viewType: any;
  menuData: any;
  userDetails: any;
  companyId: any;
  user: any;
  userExists: boolean;
  uploadedFiles: any[] = [];
  cols: any[];
  dataValue: any[];
  viewAllStaffData: any;
  staffCreateList: any;
  exportColumns: any[];
  itemList: any[];
  docList: any[];
  dateFormat: any;
  excelColumnsList: any[];
  selectedCompany: any;
  closeVideo: boolean = true;
  closepdf: boolean = true;
  notification_flag_d: boolean = false;
  public saveView = true;
  public editView = false;
  public listView = false;
  public createView = false;
  staffDialog: boolean;
  notificationDialog: boolean;
  mytable: boolean = true;
  complianceDialog: boolean;
  staffs: any[];
  staff: any;
  compliance: any;
  compliances: any[];
  selectedStaffs: any[];
  submitted: boolean;
  enableLogin = false;
  statuses: any[];
  issuedDate: Date;
  expirationDate: Date;
  staffRoleList: any[];
  staffTypeList: any[];
  userRoleList: any[];
  selectedUserRole: any;
  selectedStaffRole: any;
  selectedStaffType: any;
  checkboxEnabled = false;
  mobNumberPattern = `^((\\+61-?)|0)?[0-9]{10}$`;
  companyList: any[];
  selectedStaffCompliance: any;
  staffComplianceList: any[];
  sortOptions: any[];
  sortOrder: number;
  ref: DynamicDialogRef;
  sortField: string;
  complianceSubmitted: boolean;
  complianceTypes: any[];
  NewComplianceEnabled: boolean;
  selectedComplianceDoc: any;
  staffId: any;
  isActive: boolean;
  formStatus: boolean;
  complianceNewLayout = true;
  inductionExists = false;
  inductionExpired = false;
  exportdisplay: Boolean = false;
  notificationMobile: Boolean;
  notificationMail: Boolean;
  notificationApp: Boolean;
  dupUserId: boolean = false;
  dupEmail: boolean = false;
  noOfStaff: number;
  WFCategories: any;
  selectedWFCategories: any;
  showWorkflowDefnSteps: Boolean = false;
  showDefnSteps: Boolean = false;

  passKeyTextType: boolean;
  confirmpassKeyTextType: boolean;
  passwordMatch: boolean = true;
  WFSteps: any;
  loading: boolean = false;
  appProps: any;
  searchvalue: any;
  StaffNotificationMatrix: any = [];

  haslowercase: boolean;
  hasuppercase: boolean;
  hasspecialchar: boolean;
  hasnumbers: boolean;
  buttonDisabled1: boolean=true;
  buttonDisabled2: boolean=true;
  buttonDisabled3: boolean=true;
  buttonDisabled4: boolean=true;
  buttonDisabled5: boolean=true;
  showConfirmDialog : Boolean = true;
  encryptedPass: any;
  env = environment;
  efInduction : any;
  isExport: boolean=false;
  isDelete: boolean=false;
  selectedroute: string;

  staffForm = new FormGroup({
    first_name: new FormControl('', Validators.required),
    last_name: new FormControl('', Validators.required),
    phone: new FormControl('', Validators.required),
    email: new FormControl('', Validators.required),
    mobile: new FormControl('', Validators.required),
    company: new FormControl('',),
    role: new FormControl('',),
    staffType: new FormControl('',),
  })
  staffname =
    {
      manage_staff: 'Manage Staff',
      first_name: 'First Name',
      last_name: 'Last Name',
      email: 'Email',
      mobile: 'Mobile',
      companyName: 'Company',
      inductionStatus: 'Induction Status',
      staffTypeName: 'Staff Role',
      action: 'Action',
      phone: 'Phone',
      staff_type: 'Staff Type',
    }

  staffLoginForm = new FormGroup({
    username: new FormControl('', Validators.required),
    password: new FormControl('', Validators.required),
    userRole: new FormControl('', Validators.required),
    pass_key: new FormControl('', Validators.required),
    confirm_pass_key: new FormControl('', Validators.required)
  })
  visible: boolean;
  sendlinkvia: any;
  btnvisible: boolean;
  emailSubject: string;
  emailContent: string;
  templ: any;
  ef_access_Url: string;
  send_induction_link: any = 0;
  staffusername: string;

  constructor(private crudService: CRUDService,private datePipe: DatePipe,
    private messageService: MessageService,
    private confirmationService: ConfirmationService,
    private dialogService: DialogService,
    private sanitizer: DomSanitizer,
    private commonservice: CommonService,    private encryptService: EncryptionService,
    private encodeDecode: EncrDecrService, private tinyUrl: NgTinyUrlService,
    private fb: FormBuilder) {

    this.userDetails = JSON.parse(sessionStorage.getItem('loggedInUser'));
    if (this.userDetails === null) {
      this.userDetails = JSON.parse(sessionStorage.getItem('adminUser'));
    }
    this.menuData = JSON.parse(sessionStorage.getItem('menuData'));
    if (this.menuData == null) {
      this.menuData = {};
      this.menuData.para_1 = 'TP';
      this.menuData.para_2 = null;
    }
    this.viewType = 'TP';
    this.selectedCompany = null;
    this.NewComplianceEnabled = false;
  }

  chooseNotification(staff_id) {
    //console.log("Satff Id", staff_id);
  }

  passwordValidator(pass_key) {
  
   

   const re = /[0-9]/;
    if(re.test(pass_key)) {
      this.hasnumbers=true 
      this.buttonDisabled1= false;    
    }
    else{
      this.hasnumbers=false
      this.buttonDisabled1= true;
    }

    const re1 = /[a-z]/;
    if(re1.test(pass_key)) {
      this.haslowercase=true  
      this.buttonDisabled2= false; 
    }
    else{
      this.haslowercase=false;
      this.buttonDisabled2= true;

    }

    const re2 = /[A-Z]/;
    if(re2.test(pass_key)) {
      this.hasuppercase=true   
      this.buttonDisabled3= false; 
    }
    else{
      this.hasuppercase=false
      this.buttonDisabled3= true;
    }
   
    const re3 =  /[-+_!@#$%^&*.,?]/;
    if(re3.test(pass_key)) {
      this.hasspecialchar=true;
      this.buttonDisabled4= false;   
    }
    else{
      this.hasspecialchar=false;
      this.buttonDisabled4= true;
    }
  }

  loadAllStaffList() {
    this.crudService.getAllCompanies().subscribe((data: any[]) => {
      this.companyList = data;
    });

    this.crudService.getAllStaffbyCompName().subscribe((data: any[]) => {
      debugger
      this.staffs = data;
      console.log('frst staff11',this.staffs)
      if (this.staffs.length > 0) {
        localStorage.setItem("staffCompletionResult", "15");
      }
    });
  }
  notificationToggle(type, staff) {
    if (type == 1) {
      if (!staff.notificationMobile) {
        staff.notificationMobile = true;
      } else {
        staff.notificationMobile = false;
      }
    }
    if (type == 2) {
      if (!staff.notificationMail) {
        staff.notificationMail = true;
      } else {
        staff.notificationMail = false;
      }
    }
    if (type == 3) {
      if (!staff.notificationApp) {
        staff.notificationApp = true;
      } else { staff.notificationApp = false; }
    }

  }

  async chooseWorkflow(event) {
    this.showDefnSteps = false;
    this.showWorkflowDefnSteps = true;
    this.selectedWFCategories = event.value;
    this.WFSteps = await this.crudService.getDataByField('WFSteps', 'catg_code', this.selectedWFCategories.code).toPromise();
    let data: any = await this.crudService.getDataByField('StaffNotificationMatrix', 'company_id', this.companyId).toPromise();
    if (data.length > 0) {
      this.StaffNotificationMatrix = data;
    } else {
      this.StaffNotificationMatrix = [];
    }

    this.staffs.forEach(async staff => {
      staff.steps = [];
      let workflowSteps = this.WFSteps;
      await workflowSteps.forEach(stepInfo => {
        let step = { step: stepInfo.code, staff_id: staff.id, checked: false };
        let index = this.StaffNotificationMatrix.findIndex(val => (val.step == stepInfo.code && val.staff_id == staff.id && val.wf_category == event.value.code));
        if (index > -1) {
          step.checked = true;
        }
        if (typeof staff.steps == 'undefined') {
          staff.steps = [];
        }
        staff.steps.push(step);
      });
    });
    console.log(this.staffs);

    /* this.WorkflowDefnSteps = [
      { columnDef: 'job_creation', header: 'Job Creation', code: '1002', sequence: 1, checked: true },
      { columnDef: 'quote_approvals', header: 'Quote Approval', code: '1002', sequence: 2, checked: true },
      { columnDef: 'payments', header: 'Payments', code: '1002', sequence: 3, checked: false },
      { columnDef: 'approval', header: 'Approval', code: '1002', sequence: 4, checked: false },
      { columnDef: 'complaince', header: 'Compliance', code: '1002', sequence: 5, checked: true }
    ]; */
  }

  checkDuplicateEmail(value: any, field: any, table: any) {
    this.crudService.getDataByField(table, field, value).subscribe((data: any) => {
      if (data.length == 0) { this.dupEmail = false; }
      else { this.dupEmail = true; }

    })
  }
  checkDuplicateUserId(value: any, field: any, table: any) {
    if(this.staffusername !== value ){
      this.crudService.getDataByField(table, field, value).subscribe((data: any) => {
        if (data.length == 0) { this.dupUserId = false; }
        else { this.dupUserId = true; }
      })
    }
    else{
      this.dupUserId = false;
    }

  }

  onStrengthChange(score) {
    // //console.log('new score', score);
  }

 
  toggle_pass_key() {
    this.passKeyTextType = !this.passKeyTextType;
  }
  toggle_confirm_pass_key() {
    this.confirmpassKeyTextType = !this.confirmpassKeyTextType;
  }
  loadHelpvideo() {
    this.closeVideo = true;
    this.closepdf = false;
    document.getElementById('help-video').style.display = 'block';
  }
  loadHelppdf() {
    this.closeVideo = false;
    this.closepdf = true;
    document.getElementById('help-video').style.display = 'block';
  }
  videoClose() {
    this.closeVideo = false;
    this.closepdf = false;
    document.getElementById('help-video').style.display = 'none';

  }
  loadStaffByCompany() {
    this.crudService.getStaffByCID(this.companyId).subscribe((data: any[]) => {
      this.staffs = data;
      console.log('frst staff22',this.staffs)
      this.noOfStaff = this.staffs.length;
      if (this.staffs.length > 0) {
        localStorage.setItem('staffStrength', '15');
      }
    });
  }

  async ngOnInit(): Promise<void> {
    this.dateFormat = environment.dateFormat;
    this.loading = true;
    this.appProps = this.commonservice.getAppProperties();
    this.paginationvalue = environment.paginatorValue;
    this.formStatus = true;

    this.notificationMobile = true;
    this.notificationMail = true;
    this.notificationApp = true;
    if (this.menuData != null && this.menuData !== '') {
      this.viewType = this.menuData.para_1;
    }
    this.companyId = sessionStorage.getItem('company_id');

    this.selectedroute = sessionStorage.getItem('selectedRoute')

    let per_data :any=await this.crudService.getmenupermissions()
        this.isExport = per_data.isExport;
        this.isDelete = per_data.isDelete;

    this.crudService.getDataByField('WFCategories', 'status', '1').subscribe((data: any) => {
      this.WFCategories = data;
      this.chooseWorkflow(this.WFCategories[0]);
    });

    // this.crudService.getDataByField('WorkflowDefnSteps', 'wf_code', 'P1_JOB').subscribe((data: any[]) => {
    //   //console.log("WorkflowDefnSteps:",data);
    //  });

    let staffData: any = await this.crudService.GetAllForStaffLicense().toPromise();
    this.complianceTypes = staffData;
    if (this.menuData.para_2 === 'SSP') {
      let data: any = await this.crudService.getByValuesetName('SSPStaffType').toPromise();
      this.staffTypeList = data;
      let data2: any = await this.crudService.getByValuesetName('SSPStaffRole').toPromise();
      this.staffRoleList = data2;
      let data3: any = await this.crudService.getByValuesetName('SSPUserRole').toPromise();
      this.userRoleList = data3;
    } else {
      let data: any = await this.crudService.getByStaff('ItemDefn').toPromise();
      this.staffRoleList = data;

      let items: any = await this.crudService.getByValuesetstafftype().toPromise();
      this.staffTypeList = items;
      this.userRoleList = [
        { id: '1', name: 'Admin' },
        { id: '2', name: 'User' },
        // { id: '3', name: 'Only Jobs - View' }
      ];
    }

    if (typeof this.contractor_id !== 'undefined') {
      this.companyId = this.contractor_id;
      this.viewType = 'TP';
      this.loadStaffByCompany();
    } else {
      // this.showConfirmDialog = true;
      if (this.viewType === 'TM') {
        this.loadAllStaffList();
      } else if (this.viewType === 'TL') {
        this.loadStaffByCompany();
      } else if (this.viewType === 'TP') {
        this.crudService.getDataByField('CompanyStaffInfo', 'id', this.userDetails.staff_id).subscribe((data: any[]) => {
          if (data.length > 0) {
            this.staffs = data[0];
            console.log('frst staff',this.staffs)
            this.editSelectedStaff(data[0]);
          }
        });
      }
    }

    let items: any = await this.crudService.GetAllForStaffLicense().toPromise();
    this.docList = items;

    this.cols = [
      { field: 'first_name', header: 'First Name' },
      { field: 'last_name', header: 'Last Name' },
      { field: 'email', header: 'Email' },
      { field: 'mobile', header: 'Mobile' },
      { field: 'CompanyName', header: 'Company' },
      { field: 'InductionStatus', header: 'Induction Status' },
      { field: 'StaffTypeName', header: 'Staff Role' }
    ];
    this.sortOptions = [
      { label: 'Ascending', value: '!name' },
      { label: 'Descending', value: 'name' }
    ];
    this.exportColumns = this.cols.map(col => ({ title: col.header, dataKey: col.field }));
    this.exportdisplay = await this.commonservice.exportCheck();
    //console.log(this.exportdisplay);
    this.crudService.getDataByField('businessSettings', 'company_id', '108').subscribe((data: any[]) => {
      this.send_induction_link = data[0].send_induction_link;
      });

    this.loading = false;

  }

  checkbox() {
    this.checkboxEnabled = true;
  }

  EnableDisableTextBox() {
    if (this.enableLogin) {
      this.staff.create_login = '2';
    } else {
      this.staff.create_login = '1';
    }
  }

  openNew() {
    this.staffForm = new FormGroup({
      first_name: new FormControl('', Validators.required),
      last_name: new FormControl('', Validators.required),
      phone: new FormControl('', Validators.required),
      email: new FormControl('', Validators.required),
      mobile: new FormControl('', Validators.required),
      company: new FormControl('',),
      role: new FormControl('',),
      staffType: new FormControl('',),
    })

    this.staffLoginForm = new FormGroup({
      username: new FormControl('', Validators.required),
      password: new FormControl('', Validators.required),
      userRole: new FormControl('', Validators.required),
      pass_key: new FormControl('', Validators.required),
      confirm_pass_key: new FormControl('', Validators.required)
    })
    this.staffusername=null
    this.formStatus = true;
    this.isActive = true;
    this.enableLogin = false;
    this.NewComplianceEnabled = true;
    this.staff = {
      id: null,
      first_name: null,
      last_name: null,
      phone: null,
      company_id: null,
      mobile: null,
      email: null,
      role: null,
      stafftype: null,
      induction: null,
      client_type_id: null,
      inducted_date: null,
      username: null,
      password: null,
      create_login: null,
      process_id: this.companyId,
      user_role_id: null,
      company_inactive_from: null,
      company_inactive_to: null,
    };
    this.dupUserId =false
    this.submitted = false;
    this.staffDialog = true;
    this.mytable = false;
    this.selectedStaffRole = null;
    this.selectedStaffType = null;
    this.selectedUserRole = null;
    if (this.viewType === 'TM') {
      this.selectedCompany = null;
    } else {
      let companyList = this.companyList.filter(val => val.id === this.companyId);
      this.selectedCompany = companyList[0]
      this.staff.company_id = this.companyId;
    }
    this.enableLogin = false;
    this.user = {};
  }

  openNotofication() {
    this.mytable = false;
    this.notificationDialog = true;
  }

  deleteSelectedStaffs() {
    this.confirmationService.confirm({
      message: 'Are you sure you want to delete the selected staff',
      header: 'Confirm',
      icon: 'pi pi-exclamation-triangle',
      accept: () => {
        // tslint:disable-next-line: prefer-for-of
        for (let i = 0; i < this.selectedStaffs.length; i++) {
          this.crudService.getDataByField('Users', 'staff_id', this.selectedStaffs[i].id).subscribe((userInfo: any[]) => {
            this.crudService.Delete(userInfo[0].id, 'User').subscribe((data2: any[]) => { });
          });
          this.crudService.Delete(this.selectedStaffs[i].id, 'CompanyStaffInfo').subscribe((data: any[]) => { });

        }
        this.staffs = this.staffs.filter(val => !this.selectedStaffs.includes(val));
        this.selectedStaffs = null;
        this.messageService.add({ severity: 'success', summary: 'Successful', detail: 'Staffs Deleted', life: 1000 });
      }
    });
  }

  getStaffComplianceDetail(id) {
    this.crudService.getCompliancesByStaffId(id).subscribe((data: any[]) => {
      data.forEach(compl => {
        compl.exist = true;
        if (compl.compliance_id == 74) {
          this.inductionExists = true;
          if (compl.expiry_date) {
            if (new Date(compl.expiry_date) < new Date()) {
              this.inductionExpired = true;
            }
          }
        }
      });
    });
  }

  editSelectedStaff(staff: Staff) {
    this.staffusername = staff.username
    this.dupUserId =false
    this.formStatus = true;
    this.submitted = false;
    this.userExists = false;
    this.selectedStaffRole = null;
    this.selectedStaffType = null;
    this.selectedUserRole = null;
    this.user = {};


        if(staff.create_login == '2')
        {
          this.enableLogin = true;
        }
        else
        {
          this.enableLogin = false;
        }
    

    //console.log(this.enableLogin);
    this.staff = { ...staff };
    console.log('this.staffff',this.staff);
    this.passwordValidator(this.staff.password);
    this.staffId = this.staff.id;
    if (typeof this.staff.created_at == 'undefined') {
      this.staff.created_at = new Date().toDateString();
    }
    //console.log(this.staff);
    this.staffDialog = true;
    this.mytable = false;
    if (this.staff.id !== null) {
      this.crudService.getDataByField('CompanyStaffInfo', 'id', this.staff.id).subscribe((StaffInfo: any[]) => {
        this.staff = StaffInfo[0];
        // if (this.staff.username !== null) {
        //   this.enableLogin = true;
        // }
        this.selectedCompany = null;
        this.crudService.getDataByField('Company', 'id', this.staff.company_id).subscribe((companyInfo: any[]) => {
          this.selectedCompany = companyInfo[0];


          if (this.staff.role !== '' && this.staff.role !== null) {
            const staffrole = this.staffRoleList.filter(val => val.id == this.staff.role);
            if (staffrole.length > 0) {
              this.selectedStaffRole = staffrole[0];
            }
          } else {
            this.selectedStaffRole = null;
          }
          if (this.staff.stafftype !== '' && this.staff.stafftype != null) {
            const stafftype = this.staffTypeList.filter(val => val.id == this.staff.stafftype);
            if (stafftype.length > 0) {
              this.selectedStaffType = stafftype[0];
            }
          } else {
            this.selectedStaffType = null;
          }
          if (this.staff.user_role_id !== null && this.staff.user_role_id !== '') {
            const userrole = this.userRoleList.filter(val => val.id == this.staff.user_role_id);
            if (userrole.length > 0) {
              this.selectedUserRole = userrole[0];
            }
          } else {
            this.selectedUserRole = null;
          }

          this.crudService.getDataByField('Users', 'staff_id', this.staff.id).subscribe((userInfo: any[]) => {
            if (userInfo.length > 0) {
              this.user = userInfo[0];
              this.staff.password = this.encryptService.decryptionAES(this.user.encrypted_password);
              if (typeof this.user.created_at == 'undefined') {
                this.user.created_at = new Date().toDateString();
              }
              this.userExists = true;
            } else {
              this.user = null;
              this.userExists = false;
            }
          });
        });
      });
      if (this.viewType === 'TP' || this.viewType === 'TM') {
        this.getStaffComplianceDetail(this.staff.id);
      }
    } else {
      this.user = {};
    }

    setTimeout(() => {

      this.initTab();

      document.getElementById('help-video-trigger').addEventListener('click', function () {
        document.getElementById('help-video').style.display = 'block';
      })

      document.getElementById('vid-close')?.addEventListener('click', function () {
        document.getElementById('help-video').style.display = 'none';
      })
      document.getElementById('walkthrough-trigger').addEventListener('click', function () {

        if (addEle) {
          document.body.appendChild(mainEle);
          document.body.appendChild(mainEleNote);
        }
        count = 0;
        walkthroughScreen(walkthrough[count]);
      })
      const walkthrough = [
        {
          element: document.getElementById('d-1'),
          purpose: 'Enter your Business here'
        },
        {
          element: document.getElementById('d-2'),
          purpose: 'Enter your Trading Name'
        },
        {
          element: document.getElementById('d-3'),
          purpose: 'Enter your ABN/ACN'
        },
        {
          element: document.getElementById('d-4'),
          purpose: 'Enter Payment Terms'
        },
        {
          element: document.getElementById('d-5'),
          purpose: 'Enter Client Type'
        },
        {
          element: document.getElementById('d-6'),
          purpose: 'Enter your Email'
        },
        {
          element: document.getElementById('d-7'),
          purpose: 'Enter your Email'
        },
        {
          element: document.getElementById('d-8'),
          purpose: 'Enter your Email'
        },
        {
          element: document.getElementById('d-9'),
          purpose: 'Enter your Email'
        }
      ]

      let mainEle = document.getElementById('walkthrough-ele');
      let mainEleNote = document.getElementById('walkthrough-ele-note');
      let addEle = false;
      let count = 0;
      if (!mainEle) {
        mainEle = document.createElement('div');
        mainEle.id = 'walkthrough-ele';
        mainEleNote = document.createElement('div');
        let nextElement = document.createElement('div');
        let closeElement = document.createElement('div');
        let imgElement = document.createElement('img');
        imgElement.src = 'thinking.png';
        mainEleNote.id = 'walkthrough-ele-note';
        nextElement.id = 'nextWalkthrough';
        nextElement.innerHTML = 'Next';
        closeElement.id = 'closeWalkthrough';
        closeElement.innerHTML = 'x';
        mainEleNote.appendChild(nextElement);
        mainEleNote.appendChild(closeElement);
        mainEleNote.appendChild(imgElement);
        addEle = true;
        closeElement.addEventListener('click', function () {
          closeWalkthrough();
        });
        nextElement.addEventListener('click', function () {
          document.getElementById('wlk-info')?.remove();
          if (count < walkthrough.length - 1) {
            count++;
            walkthroughScreen(walkthrough[count]);
            if (count === walkthrough.length - 1) {
              nextElement.innerHTML = 'Close';
            }
          } else {
            closeWalkthrough();
          }
          if (count > 0) {
            enableBack();
          }
        });
      }
      function enableBack() {
        if (!document.getElementById('backWalkthrough')) {
          let backElement = document.createElement('div');
          backElement.id = 'backWalkthrough';
          backElement.innerHTML = 'Back';
          mainEleNote.appendChild(backElement);
          backElement.addEventListener('click', function () {
            document.getElementById('wlk-info')?.remove();
            if (count > 0) {
              count--;
              walkthroughScreen(walkthrough[count]);
              if (count === 0) {
                backElement.remove();
              }
            }
          });
        }
      }

      function walkthroughScreen(deet) {

        let o = deet;
        let x = o.element.offsetLeft;
        let y = o.element.offsetTop;
        let w = o.element.offsetWidth;
        let h = o.element.offsetHeight;
        //console.log('left-' + x);
        //console.log('top-' + y);
        //console.log('w-' + w);
        //console.log('h-' + h);
        mainEle.style.width = w + 'px';
        mainEle.style.height = h + 'px';
        mainEle.style.top = y + 'px';
        mainEle.style.left = x + 'px';
        mainEleNote.style.width = '500px';
        mainEleNote.style.height = '100px';
        mainEleNote.style.top = (y + h + 5) + 'px';
        mainEleNote.style.left = x + 'px';
        let ol = document.getElementById('wlk-info');
        if (ol) {
          ol.remove();
        }
        let l = document.createElement('div');
        l.id = 'wlk-info';
        l.innerHTML = o.purpose;
        mainEleNote.appendChild(l)
      }


      function closeWalkthrough() {
        mainEle.remove();
        mainEleNote.remove();
      }
    }, 1000);
  }




  tagConfig;
  initTab() {
    const tabGeneral = document.getElementById('tab-Staff');
    const tabStaffCompliance = document.getElementById('tab-staff-compliance');

    let general = document.getElementsByClassName('staff-g-info-tab-activate');
    let staffcompliance = document.getElementsByClassName('staff-compliance-info-tab-activate');

    function initializeTabs(config) {

      let l1 = [tabGeneral, tabStaffCompliance];
      let l2 = [general, staffcompliance];

      config.forEach(c => {
        document.getElementById(c.id).addEventListener('click', () => {
          l1.forEach((ele) => {
            ele.classList.remove('active');
          });
          c.activeTab.classList.add('active');

          l2.forEach((ele) => {
            if (ele && ele.length > 0) {
              for (let i = 0; i < ele.length; i++) {
                ele[i].classList.add('hideme');
              }
            }
          });
          if (c.activeContent && c.activeContent.length > 0) {
            for (let i = 0; i < c.activeContent.length; i++) {
              c.activeContent[i].classList.remove('hideme');
            }
          }

        });
      })
    }
    this.tagConfig = [{
      id: 'tab-Staff',
      activeTab: tabGeneral,
      activeContent: general,
      general: true
    }, {
      id: 'tab-staff-compliance',
      activeTab: tabStaffCompliance,
      activeContent: staffcompliance
    }];
    initializeTabs(this.tagConfig);
  }

  updateTab(v) {
    // if(v==='tab-setting'){
    //   this.viewCompliance(this.staff);
    // }
  }

  async updateNotification(staffStep) {
    debugger
    if (staffStep.checked) {
      let data = {
        company_id: this.companyId,
        staff_id: staffStep.staff_id,
        wf_category: this.selectedWFCategories.code,
        step: staffStep.step
      }
      let response = await this.commonservice.saveRecord('StaffNotificationMatrix', data);
    } else {
      let index = this.StaffNotificationMatrix.find(val => (val.step == staffStep.step && val.wf_code == this.selectedWFCategories.code && val.staff_id == staffStep.staffid));
      if (index > -1) {
        let data = this.StaffNotificationMatrix[index];
        let response = await this.crudService.deleteRecordByID('StaffNotificationMatrix', data.id);
      }
    }
  }

  deleteStaff(staff: Staff) {
    this.confirmationService.confirm({
      message: 'Are you sure you want to delete the selected staff',
      header: 'Confirm',
      icon: 'pi pi-exclamation-triangle',
      accept: () => {
        this.staffs = this.staffs.filter(val => val.id !== staff.id);
        this.staff = {};
        this.staff.deleted = '1';
        this.crudService.getDataByField('Users', 'staff_id', staff.id).subscribe((userInfo: any[]) => {
          this.crudService.Delete(userInfo[0].id, 'User').subscribe((data2: any[]) => { });
        });
        this.crudService.Update(this.staff, 'CompanyStaffInfo').subscribe((data: any) => {
          this.messageService.add({ severity: 'success', summary: 'Successful', detail: 'Staff Deleted', life: 1000 });
        });

      }

    });

  }

  onRowSelect(event) {
    this.staff = event.data;

    
    this.staffDialog = true;
    this.mytable = false;
    // this.messageService.add({severity:'info', summary:'Product Selected', detail: event.data.name});
  }

  onRowUnselect(event) {
    this.messageService.add({ severity: 'info', summary: 'Product Unselected', detail: event.data.name });
  }

  hideDialog() {
    this.staffDialog = false;
    this.notificationDialog = false;
    this.mytable = true;
  }

  saveRecord() {
    this.submitted = true;
    if (this.enableLogin) {
      this.staff.create_login = '2';
      if (this.staffLoginForm.status !== 'VALID') {
        this.formStatus = false;
      } else {
        this.formStatus = true;
      }
    } else {
      this.staff.create_login = '1';
      this.formStatus = true;
    }
    this.formStatus = true;
    if (this.selectedUserRole !== null && this.selectedUserRole !== '') {
      this.formStatus = true;
    } else {
      this.formStatus = false;
    }
    if (this.isActive) {
      this.staff.deleted = null;
    } else {
      this.staff.deleted = null;
    }
    if (this.viewType === 'TP') {
      this.staff.company_id = this.companyId;
    } else {
      if (typeof this.selectedCompany !== 'undefined' && this.selectedCompany !== null) {
        if (this.selectedCompany.id !== '' && this.selectedCompany.id !== null) {
          this.staff.company_id = this.selectedCompany.id;
        }
      }
    }

    if (typeof this.staff.company_id === 'undefined') {
      this.formStatus = false;
    }
    if (this.staff.company_id === '' || this.staff.company_id === null) {
      this.formStatus = false;
    }
    if (this.staff.last_name === '' || this.staff.last_name === null) {
      this.formStatus = false;
    }


    this.staff.user_role_id = null;
    this.staff.role = null;
    this.staff.stafftype = null;

    if (typeof this.selectedUserRole !== 'undefined' && this.selectedUserRole !== null) {
      if (this.selectedUserRole.id !== '' && this.selectedUserRole.id !== null) {
        this.staff.user_role_id = this.selectedUserRole.id;
      }
    }
    if (typeof this.selectedStaffRole !== 'undefined' && this.selectedStaffRole !== null) {
      if (this.selectedStaffRole.id !== '' && this.selectedStaffRole.id !== null) {
        this.staff.role = this.selectedStaffRole.id;
      }
    }
    if (typeof this.selectedStaffType !== 'undefined' && this.selectedStaffType !== null) {
      if (this.selectedStaffType.id !== '' && this.selectedStaffType.id !== null) {
        this.staff.stafftype = this.selectedStaffType.id;
      }
    }
    if (this.formStatus) {
      this.callAPI()
    } else {
      if (!this.enableLogin) {
        this.confirmationService.confirm({
          message: 'Are you sure you want to save record?',
          header: 'Confirm',
          icon: 'pi pi-exclamation-triangle',
          accept: () => {
            //console.log(this.staff.first_name)
            if (this.staff.first_name !== null && this.staff.first_name !== '' && this.staff.last_name !== undefined && this.staff.last_name !== '' && this.staff.last_name !== null && this.staff.last_name !== undefined) {
              //this.staff.first_name = this.staff.first_name.charAt(0).toUpperCase() + this.staff.first_name.substr(1).toLowerCase()
              //this.staff.last_name = this.staff.last_name.charAt(0).toUpperCase() + this.staff.last_name.substr(1).toLowerCase()
              this.callAPI();
            } else {
              //console.log('**************')
              this.messageService.add({ severity: 'error', summary: 'Error', detail: 'Invalid Details Entered. First Name and Last Name are Mandatory', life: 1000 });
            }
          }
        });
      } else {
        if (this.enableLogin) {
          if (this.selectedUserRole === null || this.selectedUserRole === '') {
            this.confirmationService.confirm({
              message: 'You haven\'t selected role, Are you sure you want to save record?',
              header: 'Confirm',
              icon: 'pi pi-exclamation-triangle',
              accept: () => {
                //console.log(this.staff.first_name)
                this.callAPI();
              }
            });
          }
        }
      }
    }
  }

  deleteStaffs(staff: Staff) {
    this.confirmationService.confirm({
        message: 'Are you sure you want to delete the selected Staff?',
        header: 'Confirm',
        icon: 'pi pi-exclamation-triangle',
        accept: async () => {
          staff.deleted = "1";
          let staffDt = await this.crudService.getDataByField('CompanyStaffInfo', 'id', staff.id).toPromise();
          let staffData  = staffDt[0];
          staffData.deleted = "1";
          this.crudService.Update(staffData, 'CompanyStaffInfo').subscribe(async (data: any) => {
                if (data.success === 1) {
                    this.staffs = this.staffs.filter(val => val.deleted != '1');
                    this.messageService.add({ severity: 'success', summary: 'Successful', detail: 'Staff Deleted', life: 1000 });
                } else {
                    this.messageService.add({ severity: 'error', summary: 'Error', detail: 'Staff Not Updated', life: 1000 });
                }
            });
        }
        });
}

  callAPI() {
    if (this.staff.id) {
      this.staff.last_modified_at = new Date().toDateString();
      this.staff.last_modified_by = this.userDetails.id; 
      this.staff.deleted = null;
      this.encryptedPass = this.encryptService.encryptionAES(this.staff.password);
      this.staff.encrypted_password = this.encryptService.encryptionAES(this.staff.password);
      //this.staff.first_name = this.staff.first_name.charAt(0).toUpperCase() + this.staff.first_name.substr(1).toLowerCase();
     // this.staff.last_name = this.staff.last_name.charAt(0).toUpperCase() + this.staff.last_name.substr(1).toLowerCase();
      this.crudService.Update(this.staff, 'CompanyStaffInfo').subscribe((data: any) => {
        if (data.success === 1) {
          this.staffDialog = false;
          this.staffs[this.findIndexById(this.staff.id)] = this.staff;
          this.messageService.add({ severity: 'success', summary: 'Successful', detail: 'Staff Updated', life: 1000 });
          this.saveUserInfo();
        } else {
          this.staffDialog = true;
          this.mytable = false;
          this.messageService.add({ severity: 'error', summary: 'Error', detail: 'Staff Not Updated', life: 1000 });
        }
      });
    } else {
      this.staff.created_at = new Date().toDateString();
      this.staff.created_by = this.userDetails.id;
      this.staff.deleted = null;
      this.encryptedPass = this.encryptService.encryptionAES(this.staff.password);
      this.staff.encrypted_password = this.encryptService.encryptionAES(this.staff.password);
      //this.staff.first_name = this.staff.first_name.charAt(0).toUpperCase() + this.staff.first_name.substr(1).toLowerCase();
     // this.staff.last_name = this.staff.last_name.charAt(0).toUpperCase() + this.staff.last_name.substr(1).toLowerCase();
      this.crudService.Create(this.staff, 'CompanyStaffInfo').subscribe((data: any) => {
        //console.log(data);
        if (data.success === 1) {
          this.staff.id = data.id;
          this.compliances = [];
          this.staffId = data.id;
          this.staffs = [...this.staffs, this.staff];
          this.saveUserInfo();
          this.staffDialog = true;
          this.mytable = false;
          this.messageService.add({ severity: 'success', summary: 'Successful', detail: 'Staff Created', life: 1000 });
        } else {
          this.staffDialog = true;
          this.mytable = false;
          this.messageService.add({ severity: 'error', summary: 'Error', detail: 'Staff Not Created', life: 1000 });
        }
      });
    }
    this.ngOnInit();
  }
  saveUserInfo() {
    debugger
    if (this.enableLogin && !this.userExists) {
      this.user = {};
      this.user.dob = null;
      this.user.person_id = null;
      this.user.status = '1';
      this.user.owner = '1';
      this.user.created_at = new Date();
      this.user.position_id = null;
      this.user.invalid_passwd_count = null;
      this.user.title = null;
      this.user.mobile = this.staff.mobile;
      this.user.alternate_phone = this.staff.phone;
      this.user.work_phone = this.staff.phone;
      this.user.user_locked = '0';
      this.user.user_group_id = 1;
      if (typeof this.user.created_at === null) {
        this.user.created_at = new Date().toDateString();
      }
      if (this.user.menu_colour === '' || this.user.menu_colour == null) {
        this.user.menu_colour = 'layout-menu-dark';
      }
      if (this.user.layout_colour === '' || this.user.layout_colour == null) {
        this.user.layout_colour = 'blue';
      }
      if (this.user.colour_scheme === '' || this.user.colour_scheme == null) {
        this.user.colour_scheme = 'light';
      }
      if (this.user.colour === '' || this.user.colour == null) {
        this.user.colour = 'green';
      }
      if (this.user.menu_type === '' || this.user.menu_type == null) {
        this.user.menu_type = '1';
      }
      if (this.user.homepage_url === '' || this.user.homepage_url == null) {
        this.user.homepage_url = '/home';
      }
      if (this.selectedUserRole?.id != null && this.selectedUserRole?.id != '') {
        this.user.user_group_id = this.selectedUserRole?.id;
      } else {
        this.user.user_group_id = null;
      }
      if (this.menuData.para_2 === 'SSP') {
        if (this.user.homepage_url === '' || this.user.homepage_url == null) {
          this.user.homepage_url = '/ssp-homepage';
        }
      } else {
        if (this.user.homepage_url === '' || this.user.homepage_url == null) {
          this.user.homepage_url = '/home';
        }
      }

      this.encryptedPass = this.encryptService.encryptionAES(this.staff.password);
      this.user.username = this.staff.username;
      this.user.password = this.staff.password;
      this.user.encrypted_password = this.encryptedPass;
      this.user.company_id = this.staff.company_id;
      this.user.first_name = this.staff.first_name;
      this.user.last_name = this.staff.last_name;
      this.user.email = this.staff.email;
      this.user.staff_id = this.staff.id;
      this.user.login_attempt = '0';
      this.user.process_id = this.staff.company_id;
      this.user.image_name = null;
      this.user.description = null;
      this.user.menu_colour = 'layout-menu-dark';
      this.user.layout_colour = 'blue';
      this.user.colour_scheme = 'light';
      this.user.colour = 'green';
      this.user.menu_type = '1';
      this.user.homepage_url = '/home';
      this.user.created_at = new Date().toDateString();
      this.user.created_by = this.userDetails.id;
      this.user.last_updated_at = new Date().toDateString();
      this.user.last_modified_by = null;
        this.user.username = this.staff.username;
        this.user.password = this.staff.password;
        this.user.encrypted_password = this.encryptedPass;
        this.user.deleted = null;
      this.crudService.Create(this.user, 'User').subscribe((data: any) => {
        //console.log(data);
        this.updateMenuRoles();
        this.editSelectedStaff(this.staff);
      });
    } else if (this.userExists) {
      if (this.selectedUserRole.id != null && this.selectedUserRole.id != '') {
        this.user.user_group_id = this.selectedUserRole.id;
      } else {
        this.user.user_group_id = null;
      }
      let changed = false;
      if (this.staff.username !== this.user.username) {
        changed = true;
      }

      if ((this.enableLogin) && changed) {
        this.user.username = this.staff.username;
        this.user.password = this.staff.password;
        this.user.encrypted_password = this.encryptedPass;
        this.user.status = '1';
        this.user.user_locked = 0;
      } else if (!this.enableLogin) {
        this.user.status = '2';
        this.user.user_locked = 0;
      } else if (this.enableLogin && this.user.status === '2') {
        this.user.username = this.staff.username;
        this.user.password = this.staff.password;
        this.user.encrypted_password = this.encryptedPass;
        this.user.user_locked = 0;
        this.user.status = '1';
      }
      this.user.last_modified_at = new Date().toDateString();
      this.user.last_modified_by = this.userDetails.id;
      this.user.username = this.staff.username;
        this.user.password = this.staff.password;
        this.user.encrypted_password = this.encryptedPass;
        this.user.deleted = null;
      this.crudService.Update(this.user, 'User').subscribe((data: any) => {
        console.log('outt',data);
        this.updateMenuRoles();
        this.editSelectedStaff(this.staff);
      });
    } else {
      this.editSelectedStaff(this.staff);
    }
  }

  findIndexById(id: string): number {
    let index = -1;
    for (let i = 0; i < this.staffs.length; i++) {
      if (this.staffs[i].id === id) {
        index = i;
        break;
      }
    }

    return index;
  }

  exportPdf() {


    import('jspdf').then(jsPDF => {
      import('jspdf-autotable').then(x => {
        /* const doc = new jsPDF.default(0,0);
        doc.autoTable(this.exportColumns, this.staffs);
        doc.save('products.pdf'); */
      })
    })
  }

  exportExcel(selectedStaffs, table) {
    let JsonData: any[] = [];
    if (this.selectedStaffs != null && this.selectedStaffs.length > 0) {
      //console.log("this is working");
      JsonData = this.selectedStaffs;
    } else {
      //console.log("this is else working", table.filteredValue);

      if (typeof table.filteredValue !== 'undefined') {
        if (table.filteredValue.length !== this.staffs.length && table.filteredValue.length > 0) {
          JsonData = table.filteredValue;
        } else {
          JsonData = this.staffs;
        }
      } else {
        JsonData = this.staffs;
      }
    }


    let reformattedArray = JsonData.map(obj => {

      let rObj = {};
      this.cols.forEach(pair => {
        rObj[pair.header.toString()] = obj[pair.field];
      });
      return rObj;
    })
    //console.log(reformattedArray);

    import('xlsx').then(xlsx => {
      const worksheet = xlsx.utils.json_to_sheet(reformattedArray);
      const workbook = { Sheets: { data: worksheet }, SheetNames: ['data'] };
      const excelBuffer: any = xlsx.write(workbook, { bookType: 'xlsx', type: 'array' });
      this.saveAsExcelFile(excelBuffer, 'staffs');
    });
  }

  saveAsExcelFile(buffer: any, fileName: string): void {
    import('file-saver').then(FileSaver => {
      const EXCEL_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
      const EXCEL_EXTENSION = '.xlsx';
      const data: Blob = new Blob([buffer], {
        type: EXCEL_TYPE
      });
      let timeStamp = this.datePipe.transform(new Date(), 'ddMMyy_HHmm');
      FileSaver.saveAs(data, fileName + '_' + timeStamp + EXCEL_EXTENSION);
    });
  }

  clear(table: Table) {
    table.clear();
    this.searchvalue = "";
  }

  onSortChange(event) {
    const value = event.value;

    if (value.indexOf('!') === 0) {
      this.sortOrder = -1;
      this.sortField = value.substring(1, value.length);
    }
    else {
      this.sortOrder = 1;
      this.sortField = value;
    }
  }

  viewCompliance(staff: Staff) {
    this.staff = { ...staff }
    this.staffId = this.staff.id;
    //console.log('Staff ID : ' + this.staffId);
    const ref = this.dialogService.open(ComplianceComponent, {
      data: {
        staffId: this.staffId,
        company_id: this.staff.company_id,
        type: 'staff',
        allowCreate: 'true'
      },
      header: 'Staff Compliance',
      width: '100%',
      height: '100%'
    });
  }

  updateMenuRoles() {
    this.crudService.updateMenuRoles(this.user.user_group_id, this.staff.company_id).subscribe((data: any) => {
      //console.log(data);
    });
  }

  
  loadStaffList(){
     if (typeof this.contractor_id !== 'undefined') {
      this.companyId = this.contractor_id;
      this.viewType = 'TP';
      this.loadStaffByCompany();
    } else {
      if (this.viewType === 'TM') {
        this.loadAllStaffList();
      } else if (this.viewType === 'TL') {
        this.loadStaffByCompany();
      } else if (this.viewType === 'TP') {
        this.crudService.getDataByField('CompanyStaffInfo', 'id', this.userDetails.staff_id).subscribe((data: any[]) => {
          if (data.length > 0) {
            this.staffs = data[0];
            console.log('frst staff44',this.staffs)
            this.editSelectedStaff(data[0]);
          }
        });
      }
    }
  }

  async sendMAil(){
    debugger
    let encrypted = this.encodeDecode.encrypt(  this.staff.company_id +' '+this.staff.id +' '+this.sendlinkvia ,'123456$#@$^@1ERF',);
    this.emailSubject = 'EF-Induction Access URL'
    this.ef_access_Url = this.env.base_url+'business/EFinduction/'+ encrypted 

    console.log("this.ef_access_Url",this.ef_access_Url);
    
      await this.tinyUrl.shorten(this.ef_access_Url).subscribe(async res => {
      this.ef_access_Url = res;
      if(res){
        this.emailContent = 'Hi ' + this.staff.first_name?.trim() + ' , <br/><br/>Please sign EF-induction by clicking the below link<br/> '+ '<body> <a href="'+this.ef_access_Url +'">' + this.ef_access_Url + '</a></body><br/>'
        let temp_type = 'sms-email';
        let smsData  = ''
        this.templ = {};
        this.templ.template = [{ email: {} }];
        this.templ.template[0].email= this.staff.email
        
        let dataObj = {
          sms: smsData,
          email: this.staff.email,
          type: temp_type,
          selectedTpl: 'd-fa99d348216144cfb8d39f2a67111474',
          sufix: null,
          jobData: null,
          attachments: null,
          account: this.env.hosting,
        }
        console.log("dataObj",dataObj);
        debugger
        await this.storeInSchedule(dataObj);
      }
      console.log( this.ef_access_Url); 
    });
  }
  
  
  storeInSchedule(obj) {
    const datobj2 = {
      subject : this. emailSubject,
      body : this.emailContent,
      toEmail : obj.email,
      ref_id:this.staff.id,
      ref_type:'accounts'
      }
      console.log("datobj2",datobj2);
      if(this.sendlinkvia == false){
        this.crudService.sendmailSendgrid(datobj2,'commonMail').subscribe((res2: any) => { 
          this.loading = false;
          if(res2.success == 200)
          {
            this.messageService.add({ severity: 'success', summary: 'Successful', detail: 'Email Sent', life: 3000 });
          }
          else
          {
            console.log("res2.Data.body.errors[0].message",res2);
            let message = res2.Data.response.body.errors[0].message;
            this.messageService.add({ severity: 'error', summary: 'Error', detail: message, life: 3000 });
          }

         });
      }
      else if(this.sendlinkvia == true){
        let params = {
           sms: {
            to: this.staff.mobile,
            message: 'Hi '+ this.staff.first_name?.trim() + ', Please sign EF-induction by clicking ' + this.ef_access_Url ,
           subject: this. emailSubject,
          },   type: 'sms-email',
          selectedTpl: null,
          sufix: null,
        }
        this.crudService.sendTMV2Message(params).subscribe((res2: any) => {
            this.visible = false;
            this.messageService.add({ severity: 'success', summary: 'Successful', detail: 'SMS Sent ', life: 1000 });
        });
      }
   
  }
  
  showDialog() {
    this.visible = true;
     this.sendlinkvia = undefined
    this.btnvisible =false
  }
  changeVia(event,via){
    console.log(via);
    this.btnvisible =true
  }

  async sendLink(via,email,mobile,company_id){
        this.sendMAil()  
        let otp_datas:any  = await this.crudService.getDataByField('StaffOTPTransact', 'staff_id', this.staff.id).toPromise();
        console.log("otp_datas",otp_datas);
        let filtered_otp = otp_datas.filter(val => (val.status == 0));
        filtered_otp?.forEach( val => {
          val.status= 2
          let ress: any =  this.commonservice.saveRecord('StaffOTPTransact', val);

        })

      var random_OTP : any = Math.floor(100000 + Math.random() * 900000);
      random_OTP = random_OTP.toString().substring(0, 4);
      random_OTP =  parseInt(random_OTP);
      console.log("random_OTP",random_OTP);
      let otp_details ={
        id : null,
        company_id : company_id,
        staff_id : this.staff.id,
        otp :random_OTP,
        status  :0,
        email_to :null,
        phone_to  :null,
        created_at  :  new Date(), 
        page: 0,
        created_by : this.staff.id,
      }
      if(this.sendlinkvia == false){
        otp_details.email_to = email
      }
      else{
        otp_details.phone_to = mobile
      }
      let res: any = await this.commonservice.saveRecord('StaffOTPTransact', otp_details);
      console.log("Res",res);

      let params = {
        email: {
          to: email, cc: '', bcc: null,
          msg: 'Your OTP (One Time Password) for access ef-induction is ' + '<h4>'+random_OTP+'</h4>'
          + 'Please use this password to access and sign the document. Do not share this password with anyone',
          subject: 'Verification Code To Access EF-Induction ',
        },    type: 'sms-email',
        selectedTpl: null,
        sufix: null,
      }
      if(this.sendlinkvia == false){
        this.sendOTPMail(params);
      }
      else if(this.sendlinkvia == true){
        let params = {
          sms: {
            to: this.staff.mobile,
            message: 'Your OTP (One Time Password) for access ef-induction is '+ random_OTP
            + '. Please use this password to access and sign the document. Do not share this password with anyone',
          subject:'Verification Code To Access EF-Induction',
          },   type: 'sms-email',
          selectedTpl: null,
          sufix: null,
        }

        this.crudService.sendTMV2Message(params).subscribe((res2: any[]) => {
          console.log(res2);
            this.visible = false;
            this.messageService.add({ severity: 'success', summary: 'Successful', detail: 'OTP SMS Sent ', life: 1000 });
        });
        }
      }
  sendOTPMail(obj) {
    const datobj2 = {
      subject :obj.email.subject,
      body : obj.email.msg,
      toEmail : obj.email.to,
      ref_id:this.staff.id,
      ref_type:'accounts'
      }
      console.log("datobj2",datobj2)
      this.crudService.sendmailSendgrid(datobj2,'commonMail').subscribe((res2: any) => { 
      this.loading = false;
      if(res2.success == 200)
      {
        this.visible = false;
        this.messageService.add({ severity: 'success', summary: 'Successful', detail: 'OTP Email Sent', life: 3000 });
      }
      else
      {
        console.log("res2.Data.body.errors[0].message",res2);
        let message = res2.Data.response.body.errors[0].message;
        this.messageService.add({ severity: 'error', summary: 'Error', detail: message, life: 3000 });
      }
     });
  }

  
}