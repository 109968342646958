import { Component, Input } from '@angular/core';
import { FormGroup } from '@angular/forms';

@Component({
    selector: 'checkbox',
    template: `
      <div [formGroup]="form">
        <div [formGroupName]="field.name" >
          <div *ngFor="let opt of field.options" class="form-check form-check">
          <label class="form-check-label">
             <input [formControlName]="field.name" [name]="field.name" class="form-check-input" [checked]="opt.code == field.value"  [attr.required]="field.required" type="checkbox" id="inlineCheckbox1" [value]="opt.code" />
             {{opt.description}}</label>
          </div>
        </div>

      </div> 
      <div class="alert alert-danger my-1 p-2 fadeInDown animated" *ngIf="!isValid && isDirty" > Enter valid {{field.description}} </div>
    `
})
export class CheckBoxComponent {
    @Input() field:any = {};
    @Input() form:FormGroup;
    get isValid() { return this.form.controls[this.field.name].valid; }
    get isDirty() { return this.form.controls[this.field.name].dirty; }
}