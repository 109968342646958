import { Component, Input, OnInit } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { ActivatedRoute } from '@angular/router';
import { ConfirmationService, MessageService } from 'primeng/api';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { CRUDService } from 'src/app/utilities/crud.service';
import { Height, Item, Diameter } from './tp-job-costing';
import { JOBService } from 'src/app/utilities/job.service';
import { environment } from 'src/environments/environment';
import { async } from '@angular/core/testing';

@Component({
  selector: 'app-tp-job-costing',
  templateUrl: './tp-job-costing.component.html',
  styleUrls: ['./tp-job-costing.component.scss']
})
export class TpJobCostingComponent implements OnInit {
  // *ngIf="isEditingAllowed && allowEditing"
  submitFlag: Boolean = false;
  saveFlag: Boolean = false;
  editAllFlag: Boolean = false;
  loading: Boolean = false;
  rasetflag: boolean = false;
  @Input() jobId: any;
  @Input() jobAllocationId: any;
  @Input() jobAllocationResults: any;
  dateFormat: any;
  userDetails: any;
  heights: any[];
  taxrate: any;
  taxamount: any = 0;
  RISD: any[];
  selecteRISD: any;
  costDetails: any;
  selectedRecords: any[] = [];
  costFormHead: any;
  costDetailsList: any[] = [];
  rateClassList: any[];
  selectedRateClass: any;
  subTotal: any = 0;
  grandTotal: any = 0;
  rateSetList: any[] = [];
  selectedRateSet: any;
  crewList: any[] = [];
  selectedCrew: any;
  companyId: any;
  additionalDamageInfo: any;
  allowEditing: Boolean = true;
  itemsList: any[] = [];
  statusDesc: any;
  Client_Type: any;
  wp_inv_dt: any;
  tp_pay_dt: any;
  wp_invoice_no: any;
  invoice_no: any;
  mark_completeUI: any;
  mark_complete: any;
  amount_invoice: any;
  wp_amount_invoice: any;
  imgCount: any;
  initialTPInvDate: any;
  costedHours: any;
  costedTotal: any;
  costedStaff: any;
  SHFTotal: any;
  SHFHours: any;
  SHFStaff: any;
  SHFMatrix: any;
  isAdmin: Boolean = false;
  adminUser: any[] = ['1', '68', '62', '608'];//['1', '68','62','608'];
  isEditingAllowed: Boolean = false;
  invoicedStages: any[] = ['Invoice Processed', 'Account Processed', 'Accounts Processed', 'Approve Invoice', 'Await Invoice'];
  env: any;
  validationError: string;
  lastAddedRateClass: any;
  jobReqAction: any;
  disableSave: Boolean = false;
  tp_job_substantiation_temp: any;
  UKTaxFlag: boolean = false;

  constructor(
    private CrudService: CRUDService,
    private messageService: MessageService,
    private confirmationService: ConfirmationService,
    public ref: DynamicDialogRef,
    public config: DynamicDialogConfig,
    public sanitizer: DomSanitizer,
    private route: ActivatedRoute, public JobService: JOBService

  ) { }

  async ngOnInit() {
    this.env = environment;
    this.dateFormat = environment.DateFormat;
    this.companyId = sessionStorage.getItem('company_id');
    this.userDetails = JSON.parse(sessionStorage.getItem('loggedInUser'));
    this.Client_Type = '1';
    this.CrudService.getDataByField('businessSettings', 'company_id', '108').subscribe((data: any[]) => {
      this.taxrate = data[0].TaxPerc;
    });
    if (typeof this.jobId === 'undefined') {
      if (typeof this.config.data == 'undefined' || typeof (this.config.data.jobId) == 'undefined') {
        if (this.jobId == null || this.jobId === '') {
          this.jobId = parseInt(this.route.snapshot.paramMap.get('jobId'));
          if (this.jobAllocationId === null || this.jobAllocationId === '') {
            this.jobAllocationId = '1';
          } else {
            this.jobAllocationId = parseInt(this.route.snapshot.paramMap.get('jobAllocationId'));
          }
        }
        let smryData: any = await this.CrudService.getJobSummary(this.jobId, this.jobAllocationId).toPromise();
        this.jobAllocationResults = await this.JobService.getJobInfo(smryData[0]);
        if (this.env.hosting == 'UK') {
          let data: any = await this.CrudService.getDataByField('Company', 'id', this.jobAllocationResults?.info?.TPId).toPromise();
          if (data.length > 0) {
            this.UKTaxFlag = data[0].vat_avail;
          }
        }
        //console.log(' this.jobAllocationResults', this.jobAllocationResults)
      } else {
        this.jobId = this.config.data.jobId;
        this.jobAllocationId = this.config.data.jobAllocationId;
        this.jobAllocationResults = this.config.data.jobSummary;
        if (this.env.hosting == 'UK') {
          let data: any = await this.CrudService.getDataByField('Company', 'id', this.jobAllocationResults?.info?.TPId).toPromise();
          if (data.length > 0) {
            this.UKTaxFlag = data[0].vat_avail;
          }
        }
      }
    }
    this.CrudService.getCostFormHeadByJobIdAllocId(this.jobId, this.jobAllocationId).subscribe(async (data: any[]) => {
      this.costFormHead = data[0];
      if (data.length == 0) {
        this.costFormHead = {
          id: null,
          rate_set_id: null,
          wp_rate_set_id: null,
          job_id: this.jobId,
          job_alloc_id: this.jobAllocationId,
          cost_refno: null,
          date: new Date(),
          quote_date: null,
          quote_no: null,
          sub_total: null,
          tax_rate: this.taxrate,
          tp_tax_total: null,
          tp_grand_total: null,
          job_manager: this.jobAllocationResults.info.TPContactName,
          job_contact: this.jobAllocationResults.info.TPMobile,
          job_notes: null,
          wp_pm_notes: null,
          wp_pm_substantiation: null,
          wp_grand_total: null,
          wp_sub_total: null,
          wp_tax_total: null,
          tp_job_substantiation: null,
          process_id: this.companyId,
          tp_invoice_no: null,
          rebate_total: null,
          Upload_by: null,
          source: null,
        }
        this.costFormHead.job_manager = this.costFormHead.job_manager ? this.costFormHead.job_manager : this.jobAllocationResults.info.TPContactName;
        this.costFormHead.job_contact = this.costFormHead.job_contact ? this.costFormHead.job_contact : this.jobAllocationResults.info.TPMobile;
        this.costFormHead.Upload_by = this.costFormHead.Upload_by ? this.costFormHead.Upload_by : this.userDetails.id;
      }
      this.showStatus(this.costFormHead.status);
      this.tp_job_substantiation_temp = this.costFormHead.tp_job_substantiation;
      let treeInfo: any = await this.CrudService.getWorkOrderInfo(this.jobId, this.jobAllocationId).toPromise();
      if (treeInfo.length === 0) {
        this.allowEditing = false;
      } else {
        this.allowEditing = true;
      }

      let imageInfo: any = await this.CrudService.getBeforeImgCount(this.jobId, this.jobAllocationId).toPromise();
      this.imgCount = imageInfo ? imageInfo[0].count : 0;

      let index2 = this.invoicedStages.findIndex(val => (val === this.jobAllocationResults.JobStatus));
      if (index2 > -1) {
        this.isEditingAllowed = false;
      }
      if (typeof this.costFormHead.tax_rate !== 'undefined') {
        // this.taxrate = this.costFormHead.tax_rate;
      } else {
        if (this.jobAllocationResults.info.AccountType === 'AU') {
          //    this.taxrate = 10;
        } else {
          //    this.taxrate = 15;
        }
      }

      if (this.costFormHead.date) {
        this.costFormHead.date = new Date(this.costFormHead.date);
      }
      if (environment.hosting == 'UK') {
        this.selectedRateSet = { company_id: "108", id: "10082", rateset_desc: "Standard", rateset_id: "10082", rateset_name: "Standard", status: "1" };
      }
      else {
        this.selectedRateSet = { company_id: "108", id: "10081", rateset_desc: "Standard", rateset_id: "10081", rateset_name: "Standard", status: "1" };
      }
      await this.getItemDefn();
      await this.getJobCosting();
      await this.getRateClassList();
      await this.getAllCrewList();

      let jobData = await this.CrudService.getDataByField('jobInfo', 'id', this.jobAllocationResults.id).toPromise();
      // console.log('jobdata outtt',jobData)
      if (jobData[0].job_req_action_id == "0") {
        this.jobReqAction = "";
      }
      else {
        let reqActionData = await this.CrudService.getDataByField('JobActions', 'id', jobData[0].job_req_action_id).toPromise();
        this.jobReqAction = reqActionData[0].job_action_name;
      }
      // console.log('jobReqAction outtt',this.jobReqAction)
    });
  }


  async checkCostDetailsList() {
    debugger;
    if (this.costDetailsList.some(costList => costList.selectedItem.item_name === 'Arborist' && this.jobReqAction === 'Subsidence Claim')) {
      this.tp_job_substantiation_temp = this.costFormHead.tp_job_substantiation;
      this.costFormHead.tp_job_substantiation = 'Site data collection as requested.';
    }

    else if (this.costDetailsList.some(costList => costList.selectedItem.item_name != 'Arborist' && this.jobReqAction != 'Subsidence Claim')) {
      this.costFormHead.tp_job_substantiation = this.tp_job_substantiation_temp;
    }

    else if (this.costDetailsList.some(costList => costList.selectedItem.item_name != 'Arborist' && this.jobReqAction == 'Subsidence Claim')) {
      this.costFormHead.tp_job_substantiation = this.tp_job_substantiation_temp;
    }
  }

  async getJobCosting() {
    this.costDetailsList = [];
    let data: any = await this.CrudService.getCostFormDetailByJobIdAllocIdNoOrder(this.jobId, this.jobAllocationId).toPromise();

    //console.log('getJobCosting data',data)
    data.sort(function (a, b) { return a.display_no - b.display_no });
    let subTotal: number = 0;
    for (let details of data) {
      let fixedRate = 1;
      let itemInfo = null;
      subTotal = Number(subTotal) + Number(details.item_total);
      if (details.item_id) {
        let coItem = this.itemsList.filter(val => details.item_id === val.id);
        if (coItem.length > 0) {
          itemInfo = coItem[0];
          if (details.item_rate) {
            let rateClass = this.rateClassList.filter(val => details.item_rate === val.id);
            if (rateClass.length > 0) {
              details.selectedRateClass = rateClass[0];
            } else {
              details.selectedRateClass = {};
            }
          }
          details.item_title = itemInfo.item;
          if (itemInfo.FixedRate == 2 || itemInfo.FixedRate == '2') {
            fixedRate = 2;
          } else {
            fixedRate = 1;
          }
        }


        details.selectedItem = itemInfo;
        details.FixedRate = fixedRate;
        details.changed = false;
        this.costDetailsList.push(details);
        // console.log('getJobCosting data22',this.costDetailsList)
      }
    }
    this.subTotal = Number(parseFloat(subTotal.toString()).toFixed(2));
    if (this.env.hosting == 'UK') {
      if (this.UKTaxFlag) {
        this.taxamount = this.subTotal * (this.taxrate / 100);
        this.taxamount = parseFloat(this.taxamount).toFixed(2);
      }
    }
    else {
      this.taxamount = this.subTotal * (this.taxrate / 100);
      this.taxamount = parseFloat(this.taxamount).toFixed(2);
    }
    this.grandTotal = parseFloat(this.subTotal) + parseFloat(this.taxamount);
    this.grandTotal = parseFloat(this.grandTotal).toFixed(2);

    if (this.env.hosting == 'UK') {
      await this.checkCostDetailsList();
    }
  }

  getOrder() {
    this.CrudService.getCostFormDetailByJobIdAllocIdNoOrder(this.jobId, this.jobAllocationId).subscribe((data: any) => {
    })
  }

  async getRateClassList() {
    let data: any = await this.CrudService.getRateClassByRSID(this.selectedRateSet.id).toPromise();
    this.rateClassList = data;
  }
  async getAllCrewList() {
    let data: any = await this.CrudService.getAllCostHeadTemplates().toPromise();
    this.crewList = data;
  }

  async addNew() {
    // this.isEditingAllowed = false;
    if (this.selectedRateSet) {
      var costDetails = {
        created_at: null,
        created_by: this.userDetails.id,
        display_no: null,
        display_order: null,
        entry_point: '1',
        head_id: null,
        id: null,
        item_desc: null,
        item_hrs: null,
        item_id: null,
        item_name: null,
        item_price: null,
        item_qty: null,
        item_rate: null,
        item_total: null,
        job_alloc_id: this.jobAllocationId,
        job_id: this.jobId,
        last_modified_by: null,
        last_updated_at: null,
        owner: null,
        process_id: this.companyId,
        quote_inc: '1',
        selectedItem: {},
        selectedRateClass: {},
        wp_desc: null,
        wp_hrs: null,
        wp_qty: null,
        wp_rate: null,
        wp_total: null,
        changed: true
      }
      if (this.rateClassList?.length > 0) {
        let defaultRateClass = this.rateClassList.filter(val => val.id === '10081');
        if (this.costDetailsList.length > 0) {
          // console.log('costDetailsList start',this.costDetailsList)
          // console.log('lastAddedRateClass11',this.lastAddedRateClass)

          if (this.lastAddedRateClass == undefined) {
            const lastElement = this.costDetailsList[this.costDetailsList.length - 1]
            // console.log('lastElement frstt',lastElement)
            costDetails.selectedRateClass = lastElement.selectedRateClass;
            costDetails.item_hrs = lastElement.item_hrs;
            costDetails.item_qty = 1;
          } else {
            costDetails.selectedRateClass = this.lastAddedRateClass.selectedRateClass;
            costDetails.item_hrs = this.lastAddedRateClass.item_hrs;
            costDetails.item_qty = 1;
          }

        }
        else {
          costDetails.selectedRateClass = defaultRateClass[0];
        }
      } else {
        this.messageService.add({ severity: 'error', summary: 'error', detail: 'Costings Not defined for selected Rate Set. Contact Support Desk to get this fixed', life: 1000 });
      }
      costDetails.display_order = this.costDetailsList.length + 1;
      costDetails.display_no = this.costDetailsList.length + 1;
      this.costDetailsList = [...this.costDetailsList, costDetails];
    } else {
      this.messageService.add({ severity: 'error', summary: 'error', detail: 'Rate Set Not Selected.', life: 1000 });
    }

    if (this.env.hosting == 'UK') {
      await this.checkCostDetailsList();
    }
  }


  async copyRecord() {
    let count = this.costDetailsList.length;
    for (let record of this.selectedRecords) {
      count = count + 1;
      let costDetails = {
        created_at: new Date(),
        created_by: this.userDetails.id,
        display_no: count,
        display_order: count,
        entry_point: '1',
        head_id: record.head_id,
        id: null,
        item_desc: record.item_desc,
        item_hrs: record.item_hrs,
        item_id: record.item_id,
        item_name: record.item_name,
        item_price: record.item_price,
        item_qty: record.item_qty,
        item_rate: record.item_rate,
        item_total: record.item_total,
        job_alloc_id: this.jobAllocationId,
        job_id: this.jobId,
        last_modified_by: null,
        last_updated_at: null,
        owner: null,
        process_id: this.companyId,
        quote_inc: '1',
        selectedItem: record.selectedItem,
        selectedRateClass: record.selectedRateClass,
        wp_desc: null,
        wp_hrs: null,
        wp_qty: null,
        wp_rate: null,
        wp_total: null
      }
      this.costDetailsList = [...this.costDetailsList, costDetails];
    }
    this.messageService.add({ severity: 'success', summary: 'Successful', detail: 'Copied', life: 1000 });
    this.selectedRecords = [];
    if (this.env.hosting == 'UK') {
      await this.checkCostDetailsList();
    }
    this.calcResultTotalTemp();
  }

  async save(saveType): Promise<any> {
    let tpSubstantiation = this.costFormHead.tp_job_substantiation;
    this.tp_job_substantiation_temp = this.costFormHead.tp_job_substantiation;
    // console.log('sub commnets33',tpSubstantiation)
    let validationFailed = false;
    if (this.costDetailsList.length > 0) {

      //console.log('this.costDetailsList111',this.costDetailsList)

      this.costDetailsList.forEach(async item => {
        if (item.item_qty === "0" || item.item_qty === "0.0" || item.item_qty == null || item.item_qty == '') {
          // Display an alert message if item_qty is zero
          // alert(`Item ${item.item_name} has a quantity of zero.`);
          validationFailed = true;
          this.validationError = " Quantity and Hours cannot be Zero";
          // this.saveFlag = true;

        }

        if (item.item_hrs === "0" || item.item_hrs === "0.0" || item.item_hrs == null || item.item_hrs == '') {
          // Display an alert message if item_qty is zero
          //alert(`Item ${item.item_name} has a hours of zero.`);
          validationFailed = true;
          // this.saveFlag = true;
          this.validationError = " Quantity and Hours cannot be Zero";

        }
      })

      let tpSubstantiation = this.costFormHead.tp_job_substantiation;
      // console.log('sub commnets22',tpSubstantiation)
      if (tpSubstantiation == null || tpSubstantiation == "" || tpSubstantiation == undefined) {
        this.messageService.add({ severity: 'error', summary: 'Error', detail: 'Please Substantiate Costing', life: 1500 });

        validationFailed = true;

      }

      if (validationFailed) {
        return false;
      } else {
        this.validationError = '';
        let treeInfo: any = await this.CrudService.getWorkOrderInfo(this.jobId, this.jobAllocationId).toPromise();
        this.costFormHead.job_notes = this.costFormHead.tp_job_substantiation;
        this.costFormHead.sub_total = this.subTotal;
        this.costFormHead.tax_rate = this.taxrate;
        this.costFormHead.status = '4';
        this.costFormHead.tp_tax_total = this.taxamount;
        this.costFormHead.tp_grand_total = this.grandTotal;
        this.costFormHead.exceptionComment = null;
        this.costFormHead.exception_reason = null;
        if (this.selectedRateSet) {
          this.costFormHead.rate_set_id = this.selectedRateSet.id;
          if (this.costFormHead.id) {
            //  console.log('this.costFormHead',this.costFormHead)
            let data: any = await this.CrudService.Update(this.costFormHead, 'CostFormHead').toPromise();
            //  console.log("Data11",data)
            if (data.success === 1) {
              let result = await this.saveDetails(saveType);
              this.showStatus(this.costFormHead.status);
              return true;
            } else {
              return false;
            }
          } else {
            this.costFormHead.owner = this.userDetails.id;
            this.costFormHead.Upload_by = this.userDetails.id;
            let data: any = await this.CrudService.Create(this.costFormHead, 'CostFormHead').toPromise();
            // console.log("Data22",data)
            if (data.success === 1) {
              this.costFormHead.id = data.id;
              let result = await this.saveDetails(saveType);
              this.showStatus(this.costFormHead.status);
              if (result) {
                return true;
              } else {
                return false;
              }
            } else {
              return false;
            }
          }
        } else {
          this.messageService.add({ severity: 'error', summary: 'Error', detail: 'RateSet Not Selected', life: 1500 });
          return false;
        }
      }
    } else {
      // this.messageService.add({ severity: 'error', summary: 'Error', detail: 'Costing Details Required.', life: 1500 });
      //  return false;
      debugger;
      let tpSubstantiation = this.costFormHead.tp_job_substantiation;
      // console.log('sub commnets11',tpSubstantiation)
      if (tpSubstantiation == null || tpSubstantiation == "" || tpSubstantiation == undefined) {
        this.messageService.add({ severity: 'error', summary: 'Error', detail: 'Please Substantiate Costing', life: 1500 });

        validationFailed = true;

      }


      if (validationFailed) {
        return false;
      } else {
        this.validationError = '';
        let treeInfo: any = await this.CrudService.getWorkOrderInfo(this.jobId, this.jobAllocationId).toPromise();
        this.costFormHead.job_notes = this.costFormHead.tp_job_substantiation;
        this.costFormHead.sub_total = this.subTotal;
        this.costFormHead.tax_rate = this.taxrate;
        this.costFormHead.status = '4';
        this.costFormHead.tp_tax_total = this.taxamount;
        this.costFormHead.tp_grand_total = this.grandTotal;
        this.costFormHead.exceptionComment = null;
        this.costFormHead.exception_reason = null;
        if (this.selectedRateSet) {
          this.costFormHead.rate_set_id = this.selectedRateSet.id;
          if (this.costFormHead.id) {
            //  console.log('this.costFormHead',this.costFormHead)
            let data: any = await this.CrudService.Update(this.costFormHead, 'CostFormHead').toPromise();
            //  console.log("Data11",data)
            if (data.success === 1) {
              //  let result = await this.saveDetails(saveType);
              this.showStatus(this.costFormHead.status);
              return true;
            } else {
              return false;
            }
          } else {
            this.costFormHead.owner = this.userDetails.id;
            this.costFormHead.Upload_by = this.userDetails.id;
            let data: any = await this.CrudService.Create(this.costFormHead, 'CostFormHead').toPromise();
            // console.log("Data22",data)
            if (data.success === 1) {
              this.costFormHead.id = data.id;
              // let result = await this.saveDetails(saveType);
              this.showStatus(this.costFormHead.status);
              // if (result) {
              //   return true;
              // } else {
              //   return false;
              // }
            } else {
              return false;
            }
          }
        } else {
          this.messageService.add({ severity: 'error', summary: 'Error', detail: 'RateSet Not Selected', life: 1500 });
          return false;
        }
      }
    }
    this.disableSave = false;
    if (this.env.hosting == 'UK') {
      await this.checkCostDetailsList();
    }

  }

  async saveDetails(saveType): Promise<any> {
    let result = true;
    let i = 0;

    let changedRecords = this.costDetailsList.filter(val => (val.changed === true));
    for (let costDetail of this.costDetailsList) {
      i = i + 1;
      costDetail.display_no = i;
      // costDetail.selectedRateClass = this.selectedRateSet;
      if (costDetail.selectedRateClass.id) {
        costDetail.item_rate = costDetail.selectedRateClass.id;
        if (costDetail.selectedItem.id) {
          costDetail.item_id = costDetail.selectedItem.id;
        }
        if (costDetail.FixedRate == 2 || costDetail.FixedRate == '2') {
          costDetail.wp_total = costDetail.item_total;
          costDetail.wp_rate = costDetail.item_price;
          costDetail.wp_qty = costDetail.item_qty;
        }

        if (costDetail.id) {
          let data: any = await this.CrudService.Update(costDetail, 'CostFormDetail').toPromise();
          if (data.success != '1') {
            result = false;
          }
        } else {
          costDetail.head_id = this.costFormHead.id;
          let data: any = await this.CrudService.Create(costDetail, 'CostFormDetail').toPromise();
          if (data.success === 1) {
            costDetail.id = data.id;
          } else {
            result = false;
          }
        }
      } else {
        this.messageService.add({ severity: 'error', summary: 'Error', detail: 'Rate Class Not Selected.', life: 1000 });
        result = false;
      }
    }

    return result;
  }

  removeRecord() {
    this.confirmationService.confirm({
      message: 'Are you sure you want to delete the selected Records?',
      header: 'Confirm',
      icon: 'pi pi-exclamation-triangle',
      accept: async () => {
        for (let record of this.selectedRecords) {
          this.CrudService.Delete(record.id, 'CostFormDetail').subscribe((data: any) => {
          });
        }
        this.costDetailsList = this.costDetailsList.filter(val => !this.selectedRecords.includes(val))
        this.calcResultTotal();
        if (this.env.hosting == 'UK') {
          await this.checkCostDetailsList();
        }
        this.messageService.add({ severity: 'success', summary: 'Successful', detail: 'Records Deleted', life: 1000 });
        this.selectedRecords = [];
        this.disableSave = false;
      }
    });
  }

  async onChangeRateSet(value) {
    this.selectedRateSet = value;
    await this.getItemDefn();
    await this.getRateClassList();
    await this.getAllCrewList();
    //this.getOrder();
  }

  onChangeRateSet2(value) {
    this.selectedRateSet = value;

  }


  async getItemDefn() {
    let data: any = await this.CrudService.getByItemDefn(this.selectedRateSet.id).toPromise();
    this.itemsList = data;
    let data2: any = await this.CrudService.getRateClassByRSID(this.selectedRateSet.id).toPromise();
    this.rateClassList = data2;
  }
  async getItemDefn2() {
    let data: any = await this.CrudService.getByItemDefn(this.selectedRateSet.id).toPromise();
    for (let itemDefn of data) {
      let costItem = {
        id: itemDefn.id,
        item: itemDefn.item_name,
        desc: itemDefn.item_desc,
        price: 1,
        itemTypeId: itemDefn.item_type_id,
        defaultRate: itemDefn.default_rate,
        status: itemDefn.status,
        FixedRate: itemDefn.FixedRate
      }
      this.itemsList.push(costItem);
    }
  }
  onChangeRateClass(value) {
    this.CrudService.getByItemDefn(this.selectedRateSet.id).subscribe((data: any) => {
    })
  }

  onChangeCrew(value) {
    this.CrudService.getByItemDefn(this.selectedCrew.id).subscribe((data: any) => {
    })
  }

  generate() { }

  getCostFormHeadData() {
    this.CrudService.getCostFormHeadByJobIdAllocId(this.jobId, this.jobAllocationId).subscribe((data: any[]) => {
      if (data.length > 0) {
        this.costFormHead = data[0];
      } else {
        this.costFormHead = {
          id: null,
          rate_set_id: null,
          wp_rate_set_id: null,
          job_id: this.jobId,
          job_alloc_id: this.jobAllocationId,
          cost_refno: null,
          date: new Date(),
          quote_date: null,
          quote_no: null,
          sub_total: null,
          tax_rate: 10,
          tp_tax_total: null,
          tp_grand_total: null,
          job_manager: this.jobAllocationResults.info.TPContactName,
          job_contact: this.jobAllocationResults.info.TPMobile,
          job_notes: null,
          wp_pm_notes: null,
          wp_pm_substantiation: null,
          wp_grand_total: null,
          wp_sub_total: null,
          wp_tax_total: null,
          tp_job_substantiation: null,
          process_id: 1,
          tp_invoice_no: null,
          rebate_total: null,
          Upload_by: null
        }
        this.costFormHead.job_manager = this.costFormHead.job_manager ? this.costFormHead.job_manager : this.jobAllocationResults.info.TPContactName;
        this.costFormHead.job_contact = this.costFormHead.job_contact ? this.costFormHead.job_contact : this.jobAllocationResults.info.TPMobile;
        this.costFormHead.Upload_by = this.costFormHead.Upload_by ? this.costFormHead.Upload_by : this.userDetails.id;
        if (typeof this.costFormHead.quote_date === 'undefined') {
          this.costFormHead.quote_date = this.costFormHead.date;
        }
        if (this.costFormHead.quote_date === null || this.costFormHead.quote_date === '') {
          this.costFormHead.quote_date = this.costFormHead.date;
        }
      }
    })
  }

  async calcResultTotal() {
    let subTotal: number = 0;
    for (let details of this.costDetailsList) {
      subTotal = Number(subTotal) + Number(details.item_total);
    }
    this.subTotal = subTotal.toFixed(2);
    if (this.env.hosting == 'UK') {
      if (this.UKTaxFlag) {
        this.taxamount = this.subTotal * (this.taxrate / 100);
        this.taxamount = this.taxamount.toFixed(2);
      }
    }
    else {
      this.taxamount = this.subTotal * (this.taxrate / 100);
      this.taxamount = this.taxamount.toFixed(2);
    }
    this.grandTotal = parseFloat(this.subTotal) + parseFloat(this.taxamount);
    this.grandTotal = this.grandTotal.toFixed(2);
    if (this.costFormHead.id) {
      this.costFormHead.sub_total = this.subTotal;
      this.costFormHead.tp_tax_total = this.taxamount;
      this.costFormHead.tp_grand_total = this.grandTotal;
      this.costFormHead.id = this.costFormHead.id;
      let res: any = await this.CrudService.Update(this.costFormHead, 'costFormHead').toPromise();
      if (res.success === 1) {
        this.loading = false;
        this.disableSave = false;
      }
      else {
        this.loading = false;
        this.disableSave = false;
      }
    }
  }

  async calcResultTotalTemp() {
    let subTotal: number = 0;
    for (let details of this.costDetailsList) {
      subTotal = Number(subTotal) + Number(details.item_total);
    }
    this.subTotal = subTotal.toFixed(2);
    if (this.env.hosting == 'UK') {
      if (this.UKTaxFlag) {
        this.taxamount = this.subTotal * (this.taxrate / 100);
        this.taxamount = this.taxamount.toFixed(2);
      }
    }
    else {
      this.taxamount = this.subTotal * (this.taxrate / 100);
      this.taxamount = this.taxamount.toFixed(2);
    }
    this.grandTotal = parseFloat(this.subTotal) + parseFloat(this.taxamount);
    this.grandTotal = this.grandTotal.toFixed(2);
    if (this.costFormHead.id) {
      this.costFormHead.sub_total = this.subTotal;
      this.costFormHead.tp_tax_total = this.taxamount;
      this.costFormHead.tp_grand_total = this.grandTotal;
      this.costFormHead.id = this.costFormHead.id;
      //let res: any = await this.CrudService.Update(this.costFormHead,'costFormHead').toPromise();
    }
  }

  async reCalculateTotals(costDetail) {
    costDetail.item_total = parseFloat(costDetail.item_hrs) * parseFloat(costDetail.item_qty) * parseFloat(costDetail.item_price);
    costDetail.item_total = costDetail.item_total.toFixed(2);
    if (this.env.hosting == 'UK') {
      await this.checkCostDetailsList();
    }
    this.calcResultTotalTemp();
  }

  async saveRecord(saveType) {
    let result = await this.save(saveType);
    if (result) {
      this.disableSave = false;
      this.messageService.add({ severity: 'success', summary: 'Successful', detail: 'Costing Details Updated', life: 1000 });
    } else {
      this.disableSave = false;
      this.messageService.add({ severity: 'error', summary: 'Error', detail: 'Costing Details Not Updated', life: 1000 });
    }
  }
  async submit() {
    this.confirmationService.confirm({
      message: 'Are you sure you want to Submit?',
      header: 'Confirm',
      icon: 'pi pi-exclamation-triangle',
      /* reject: (type: string) => {
        if (type === 'close') {
          console.log('Dialog closed without any action.');
          this.disableSave = false;
        } else {
          console.log('Action explicitly rejected.');
          this.disableSave = false;
        }
      }, */
      
      accept: async () => {
        if (this.costFormHead.job_manager !== '' && this.costFormHead.job_contact !== '' && this.costFormHead.tp_job_substantiation !== '' && this.costFormHead.job_manager !== null && this.costFormHead.job_contact !== null && this.costFormHead.tp_job_substantiation !== null) {
          let result = await this.save(2);
          if (result) {
            if (this.taxrate == 0.0) {
              this.messageService.add({ severity: 'error', summary: 'No tax available for this costings - Please Contact Support to get this fixed', detail: 'No tax available for this costings - Please Contact Support to get this fixed', life: 1000 });
              return false;
            } else {
              let res: any = await this.CrudService.getBeforeImgCount(this.jobId, this.jobAllocationId).toPromise();
              const count = res ? res[0].count : 0;
              if (count < 3) {
                this.messageService.add({ severity: 'error', summary: 'Please upload Before Images', detail: 'Please upload min 3 Before Images', life: 3000 });
                return false;
              } else {
                let treeInfo: any = await this.CrudService.getWorkOrderInfo(this.jobId, this.jobAllocationId).toPromise();
                if (treeInfo.length < 1) {
                  this.messageService.add({ severity: 'error', summary: 'Please complete work order information', detail: 'Please complete work order information', life: 3000 });
                } else {
                  let res2: any = await this.CrudService.getApprovalLimitDetails(this.jobAllocationResults.info.WPCompanyId, this.jobAllocationResults.info.JobReqId).toPromise();
                  if (res2.length > 0) {
                    if (this.subTotal < res2[0].approval_limit) {
                      if (this.costFormHead.status === '2') {
                        let res3: any = await this.CrudService.updateCostFormHeadStatus(this.jobId, this.jobAllocationId, 2).toPromise();
                        this.messageService.add({ severity: 'success', summary: 'Successful', detail: 'Submitted.', life: 1000 });
                        this.showStatus(this.costFormHead.status);
                      } else {
                        this.costFormHead.status = '2';
                        this.autoApprovalOnSubmit();
                        this.messageService.add({ severity: 'success', summary: 'Successful', detail: 'Submitted & Apporved', life: 1000 });
                        this.showStatus(this.costFormHead.status);
                      }
                    } else {
                      this.costFormHead.status = '1';
                      let res3: any = await this.CrudService.updateCostFormHeadStatus(this.jobId, this.jobAllocationId, 1).toPromise();
                      this.messageService.add({ severity: 'success', summary: 'Successful', detail: 'Submitted.', life: 1000 });
                      this.showStatus(this.costFormHead.status);
                    }
                  } else {
                    this.costFormHead.status = '1';
                    let res3: any = await this.CrudService.updateCostFormHeadStatus(this.jobId, this.jobAllocationId, 1).toPromise();
                    this.messageService.add({ severity: 'success', summary: 'Successful', detail: 'Submitted.', life: 1000 });
                    this.showStatus(this.costFormHead.status);
                  }
                }
              }
            }
            this.editAllFlag = true;
          } else {
            return false;
            //this.messageService.add({ severity: 'error', summary: 'error', detail: 'Manager, Contact and Substantiation are required to Submit.', life: 1000 });
          }
        }
        else {
          this.messageService.add({ severity: 'error', summary: 'Manager, Contact and Substantiation are required to Submit.', detail: 'Manager, Contact and Substantiation are required to Submit.', life: 3000 });
          return false;
        }
        this.getJobCosting();
      },
      });
  }


  autoApprovalOnSubmit() {
    this.CrudService.updateCostFormHeadStatus(this.jobId, this.jobAllocationId, 2).subscribe((res: any[]) => {
      const params = {
        email:
        {
          to: this.jobAllocationResults.info.TPEmail,
          cc: null, bcc: null,
          msg: 'This is a mail to notify that the quote for Job Number: ' + this.jobAllocationResults.JobNo + ' has been approved. This approval is subject to an audit and not final. Please schedule with client and update in Tree Manager. \nThe details are as follows: \nSite address: ' + this.jobAllocationResults.info.location + ' \nClient Contact: ' + this.jobAllocationResults.info.contact + '. \nRegards,  \nEnviro Trees Team ',
          subject: 'Tree Manager: Quote Approved for Job No ' + this.jobAllocationResults.JobNo
        },
        sms:
        {
          to: this.jobAllocationResults.info.TPMobile,
          message: 'This is a message to notify that the quote for Job Number: ' + this.jobAllocationResults.JobNo + ' has been approved. This approval is subject to an audit and not final. Please schedule with client and update in Tree Manager. \nThe details are as follows: \nSite address: ' + this.jobAllocationResults.info.location + ' \nClient Contact: ' + this.jobAllocationResults.info.contact + '. \nRegards,  \nEnviro Trees Team '
        },
        type: 'sms-email',
        selectedTpl: null,
        sufix: null
      }
      this.CrudService.sendMessage(params).subscribe((res2: any[]) => {
        this.storeInSchedule(res2);
      });
    })
  }

  storeInSchedule(obj) {
    let message = '';
    let sms = '';
    let email = '';
    let contactUI = '';
    if (this.jobAllocationResults.info.TPEmail == '' && this.jobAllocationResults.info.TPMobile == '') {
      message = 'Quote approved. Notification not sent to the TP';
      sms = '';
      email = '';
      if (sms === '1' && email === '1') {
        contactUI = 'N/A';
      } else {
        contactUI = '';
      }
    } else {
      message = 'Quote auto Approved.  Notification has been sent to the TP (Email/SMS)';
      sms = '2';
      email = '2';
    }
    let store_data = {
      id: null,
      job_id: this.jobId,
      job_alloc_id: this.jobAllocationId,
      process_id: 1,
      visit_type: null,
      status: null,
      sched_date: null,
      PMOnly: '2',
      sched_note: message,
      start_time: null,
      end_time: null,
      created_by: this.userDetails.id,
      created_at: new Date(),
      phoned: '1',
      phone_no: null,
      sms: sms,
      sms_no: this.jobAllocationResults.info.TPMobile,
      email: email,
      emailaddress: this.jobAllocationResults.info.TPEmail,
      callback: '1',
      contactUI: contactUI
    }
    this.CrudService.Create(store_data, 'JobSchedule').subscribe((data: any) => {
    });
  }

  showStatus(status) {
    // debugger
    switch (status) {
      case '1':
        this.statusDesc = 'Submitted';
        this.submitFlag = false;
        this.isEditingAllowed = false;
        //  this.saveFlag = false;
        this.editAllFlag = false;
        break;
      case '2':
        this.isEditingAllowed = false;
        this.statusDesc = 'Approved';
        this.submitFlag = false;
        //  this.saveFlag = false;
        break;
      case '3':
        this.statusDesc = 'Rejected';
        this.isEditingAllowed = false;
        this.submitFlag = false;
        // this.saveFlag = false;
        this.editAllFlag = false;
        break;
      case '4':
        this.statusDesc = 'Saved';
        this.isEditingAllowed = true;
        this.submitFlag = true;
        // this.saveFlag = true;
        this.editAllFlag = false;
        break;
      default:
        this.statusDesc = 'Pending';
        this.isEditingAllowed = true;
        this.submitFlag = true;
        // this.saveFlag = true;
        this.editAllFlag = false;
    }
  }

  calculateItemPrice(costdetail) {
    this.lastAddedRateClass = [];
    //console.log('costDetailsListtt', this.costDetailsList)
    if (this.costDetailsList.length > 1 && !costdetail.id) {
      //const lastElement = this.costDetailsList[this.costDetailsList.length - 2]
      // costdetail.selectedRateClass = lastElement.selectedRateClass
      // costdetail.item_qty = lastElement.item_qty
      // costdetail.item_hrs = lastElement.item_hrs

    }
    if (costdetail.selectedItem != null && costdetail.selectedRateClass != null) {
      this.lastAddedRateClass = costdetail;

      // console.log('lastAddedRateClass33',this.lastAddedRateClass)
      costdetail.item = costdetail.selectedItem.id;
      costdetail.item_rate = costdetail.selectedRateClass.id;
      this.CrudService.getSelectedItemDetails(costdetail.selectedItem.id, this.selectedRateSet.id, costdetail.selectedRateClass.id).subscribe(async (data: any[]) => {
        if (data.length > 0) {
          let costView = data[0];
          costdetail.item_price = costView.contractor_rate;
          costdetail.wp_rate = costView.invoice_rate;
          costdetail.FixedRate = costView.FixedRate;
          if (costdetail.item_qty == null) {
            costdetail.item_qty = 1;
          }
          if (costdetail.item_hrs == null) {
            costdetail.item_hrs = 1;
          }
          this.reCalculateTotals(costdetail);
          if (this.env.hosting == 'UK') {
            await this.checkCostDetailsList();
          }
        } else {
          this.messageService.add({ severity: 'error', summary: 'error', detail: 'Costings Not defined for selected Items. Contact Support Desk to get this fixed', life: 1000 });
        }
      });
    }
  }

  itemQty(item_qty, item_hrs, item_price) {
    if (item_qty == '0.0' || item_qty == '0' || item_qty == null || item_qty == '') {
      //  this.saveFlag = true;
    }
    else if (item_hrs == '0.0' || item_hrs == '0' || item_hrs == null || item_hrs == '') {
      //this.saveFlag = true;
    }

    else {
      //  this.saveFlag = false;
    }
  }
}
