import { Component, OnInit } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { ActivatedRoute } from '@angular/router';
import { ConfirmationService, MessageService } from 'primeng/api';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { CRUDService } from 'src/app/utilities/crud.service';
import { environment } from 'src/environments/environment';
import { CommonService } from 'src/app/utilities/common.service';
import { Table } from 'primeng/table';
import { formatDate } from "@angular/common";


@Component({
  selector: 'app-workflow-definition',
  templateUrl: './workflow-definition.component.html',
  styleUrls: ['./workflow-definition.component.scss']
})
export class WorkflowDefinitionComponent implements OnInit {
  paginationvalue:any;
  dateformat:any;
  selectedRecords: any;
  selectedCategory: any;
  cols: any;
  selectedCompany: any;
  dataList: any[];
  exportColumns: any;
  speedDial: Boolean = false;
  exportdisplay:Boolean = true;
  allStaff: any[];
  workflow: any;
  workflowDialog: Boolean = false;
  submitted: Boolean = true;
  category: any[];
  rowData: any;
  jobPriority: any;
  list: any[] = [];
  workflowsteps: Boolean = false;
  SelectedDate: Date = null;
  selectedDate_str: any = '';
  myDates: Date;
  date = new Date('Tue Feb 05 2019 00:00:00 GMT+0530 (India Standard Time)');
  myDate: string;
  format = 'dd/MM/yyyy hh:mm:ss';
  locale = 'en-US';
  isActive: boolean;
  constructor(

    private CrudService: CRUDService,
    private messageService: MessageService,
    private confirmationService: ConfirmationService,
    public ref: DynamicDialogRef,
    public config: DynamicDialogConfig,
    public sanitizer: DomSanitizer,
    private route: ActivatedRoute,
    private commonservice: CommonService
  ) { }

  async ngOnInit() {
    this.dateformat=environment.dateFormat
    this.paginationvalue=environment.paginatorValue;
    this.selectedCompany = null;
    this.cols = [
      { field: 'code', header: 'Code' },
      { field: 'name', header: 'Name' },
      { field: 'category', header: 'Type' },
      { field: 'default_yn', header: 'Default' },
      { field: 'created_at', header: 'Created Date' },
      
    ];
    
    //console.log("check-list",this.list)

    this.exportColumns = this.cols.map(col => ({ title: col.header, dataKey: col.field }));
    this.exportdisplay = await this.commonservice.exportCheck();
    
    this.getWorkflow();
  }
  async getWorkflow() {
      let data: any = await this.CrudService.getAllData('WorkflowDefinition').subscribe((data: any[]) => {
      this.dataList = data;
      //console.log('workflow Definition',this.dataList)
      this.dataList.forEach(rowData => {
        if (rowData.default_yn == 'N') {
          rowData.default_yn = 'No';
        } else {
          rowData.default_yn = 'Yes';
        }
      });
      //console.log('dataList',this.dataList)
      this.category = data;
      this.dataList.forEach(element => {
        element.created_at = formatDate(element.created_at, this.format, this.locale);
        //console.log(element.created_at)
      });
    });
  }

  getWorkflowSteps(){
    this.workflowsteps = true;
  }

  editRecord(rowData:any){
    this.workflow = {...rowData};
    // this.CrudService.getAllData('WorkflowDefinition', this.workflow.id).subscribe((data:any[])=>{
    //     this.selectedCompany=data[0];
    //     //console.log('value',this.selectedCompany);
    // });
    this.workflowDialog=true;
    this.list = [
      { id: '1', title: 'Job' },
      { id: '2', title: 'Admin' },
      { id: '3', title: 'Compliance' },
    ];
  }

  onSelectedItemChange(event, rowData){
    //console.log("vehile change",event);
    rowData.id = event.value?.id;
    rowData.changed=true;
  }

  hideDialog() {
    this.workflowDialog = false;
    this.submitted = false;
  }

  exportExcel(selectedCompany) {
    if (this.selectedCompany != null && this.selectedCompany.length > 0) {
      import('xlsx').then(xlsx => {
        const worksheet = xlsx.utils.json_to_sheet(this.selectedCompany);
        const workbook = { Sheets: { data: worksheet }, SheetNames: ['data'] };
        const excelBuffer: any = xlsx.write(workbook, { bookType: 'xlsx', type: 'array' });
        this.saveAsExcelFile(excelBuffer, 'datasList');
      });
    } else {
      import('xlsx').then(xlsx => {
        const worksheet = xlsx.utils.json_to_sheet(this.dataList);
        const workbook = { Sheets: { data: worksheet }, SheetNames: ['data'] };
        const excelBuffer: any = xlsx.write(workbook, { bookType: 'xlsx', type: 'array' });
        this.saveAsExcelFile(excelBuffer, 'datasList');
      });
    }
  }

  saveAsExcelFile(buffer: any, fileName: string): void {
    import('file-saver').then(FileSaver => {
      const EXCEL_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
      const EXCEL_EXTENSION = '.xlsx';
      const data: Blob = new Blob([buffer], {
        type: EXCEL_TYPE
      });
      FileSaver.saveAs(data, fileName + '_export_' + new Date().getTime() + EXCEL_EXTENSION);
    });
  }

  exportPdf() {
    import('jspdf').then(jsPDF => {
      import('jspdf-autotable').then(x => {
        /* const doc = new jsPDF.default(0,0);
        if(typeof this.selectedDepots == 'undefined'){
            doc.autoTable(this.exportColumns, this.depots);
           }else{
               doc.autoTable(this.exportColumns, this.selectedDepots);
        }
       doc.save('products.pdf'); */
      })
    })
  }

  clear(t){

  }

  onSelectDate() {
    let dd = String(this.SelectedDate.getDate()).padStart(2, '0');
    let mm = String(this.SelectedDate.getMonth() + 1).padStart(2, '0'); //January is 0!
    let yyyy = this.SelectedDate.getFullYear();
    this.selectedDate_str = dd + '/' + mm + '/' + yyyy;
  }
}