import { Component, Input, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import * as markerjs2 from "markerjs2";
import * as cropro from "cropro";
import { ConfirmationService, MessageService } from 'primeng/api';
import { DynamicDialogConfig, DynamicDialogRef, DialogService } from 'primeng/dynamicdialog';
import { element } from 'protractor';
import { CRUDService } from 'src/app/utilities/crud.service';
import { ImageDocument, Type } from './tp-job-images';
import { DomSanitizer } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { ImageMarkingComponent } from '../../modules/image-marking/image-marking.component'
import { FileUploader } from 'ng2-file-upload';
import { environment } from 'src/environments/environment';
import { NgxImageCompressService } from 'ngx-image-compress';
import heic2any from "heic2any";
// import {
//   DataUrl,
//   DOC_ORIENTATION,
//   NgxImageCompressService,
//   UploadResponse,
// } from 'ngx-image-compress';

@Component({
  selector: 'app-tp-job-images',
  templateUrl: './tp-job-images.component.html',
  styleUrls: ['./tp-job-images.component.scss'],
  providers: [DialogService, MessageService, ConfirmationService, NgxImageCompressService]
})
export class TpJobImagesComponent implements OnInit {
  @Input() jobId: any
  @Input() jobAllocationId: any;
  @Input() jobSummary: any;
  showHeader = false;
  image: any;
  addNewImage: any = false;
  types: Type[];
  imageFile: any;
  msg: any;
  url: any;
  sourceImage: any;
  targetRoot: any;
  maState: any;
  currentUser: any;
  imageTab: boolean = false;
  uploadedFiles: any[] = [];
  imageDocuments: any[];
  imageDocument: any;
  imageForm: FormGroup;
  submitted: any;
  isEditImage: any = false;
  uploader: FileUploader;
  path: any;
  companyId: any;
  beforeImgSize: any;
  afterImgSize: any;
  uploadLabel: any;
  file: any;
  loading: Boolean = false;
  env:any;

  // imageString : string ;
  // imgResultBeforeCompress: DataUrl = '';
  // imgResultAfterCompress: DataUrl = '';
  // imgResultAfterResize: DataUrl = '';
  // imgResultUpload: DataUrl = '';
  // imgResultAfterResizeMax: DataUrl = '';
  // imgResultMultiple: UploadResponse[] = [];


  constructor(
    private CrudService: CRUDService,
    private messageService: MessageService,
    private confirmationService: ConfirmationService,
    public ref: DynamicDialogRef,
    public config: DynamicDialogConfig,
    private route: ActivatedRoute,
    public sanitizer: DomSanitizer,
    private router: Router,
    private dialogService: DialogService,
    private imageCompress: NgxImageCompressService


  ) {
    this.companyId = sessionStorage.getItem('company_id');
    this.currentUser = JSON.parse(sessionStorage.getItem('currentUser'));
  }
  imgResultBeforeCompress: string;
  imgResultAfterCompress: string;
  imageString: string;

  compressFile() {

    this.imageCompress.uploadFile().then(({ image, orientation }) => {

      this.imgResultBeforeCompress = image;
      console.warn('Size in bytes was:', this.imageCompress.byteCount(image));

      this.imageCompress.compressFile(image, orientation, 50, 50).then(
        result => {
          this.imgResultAfterCompress = result;
          console.warn('Size in bytes is now:', this.imageCompress.byteCount(result));
        }
      );

    });

  }
  // compressFile() {
  //   return this.imageCompress 
  //     .uploadFile()
  //     .then(({ image, orientation }: UploadResponse) => {
  //       this.imgResultBeforeCompress = image;
  //       console.warn('Size in bytes was:', this.imageCompress.byteCount(image));

  //       this.imageCompress
  //         .compressFile(image, orientation, 50, 50)
  //         .then((result: DataUrl) => {
  //           this.imgResultAfterCompress = result;
  //           console.warn(
  //             'Size in bytes is now:',
  //             this.imageCompress.byteCount(result)
  //           );
  //         });
  //     });
  // }


  async ngOnInit() {
    this.env = environment;
    if (typeof this.jobId === 'undefined') {
      if (typeof this.config.data == 'undefined' || typeof (this.config.data.jobId) == 'undefined') {
        if (this.jobId == null || this.jobId === '') {
          this.jobId = parseInt(this.route.snapshot.paramMap.get('jobId'));
          if (this.jobAllocationId === null || this.jobAllocationId === '') {
            this.jobAllocationId = '1';
          } else {
            this.jobAllocationId = parseInt(this.route.snapshot.paramMap.get('jobAllocationId'));
            this.jobSummary = this.CrudService.getDataByField('JobInfo', 'id', this.jobId);
          }
        }
        this.showHeader = false;
      } else {
        this.jobId = this.config.data.jobId;
        this.jobAllocationId = this.config.data.jobAllocationId;
        this.jobSummary = this.config.data.jobSummary;
        this.showHeader = true;
      }
    }

    this.imageDocument = {
      company_id: this.companyId,
      created_at: null,
      created_by: null,
      deleted: null,
      id: null,
      img_description: null,
      img_inc: '2',
      img_name: null,
      img_path: null,
      img_type: null,
      included: null,
      job_alloc_id: this.jobAllocationId,
      job_id: this.jobId,
      last_modified_by: null,
      last_updated_at: null,
      owner: null,
      process_id: this.companyId,
      source: null,
      status: '1',
      upload_by: null,
      upload_time: null,
      upload_type: null,
      upload_type_detail: null,
      user_id: null,
      username: null,
      vs: null,
      image_url: null,
      isIncluded: true,
      selectedType: null,
      upload_time_str: null,
      upload_time_str2: null
    }

    this.types = [
      { type: 'Before', id: '1' },
      { type: 'Hazard', id: '3' },
      { type: 'After', id: '2' }
    ];
    this.imageForm = new FormGroup({
      type: new FormControl('', Validators.required),
      img_description: new FormControl('', Validators.required),
      image_upload: new FormControl('')
    })

    this.getUploadImages();
    this.image = localStorage.getItem('image');
    //   this.openImageTab();
    //   this.showMarkerArea()
    this.uploader = new FileUploader({
      disableMultipart: true, // 'DisableMultipart' must be 'true' for formatDataFunction to be called.
      formatDataFunctionIsAsync: true,
      formatDataFunction: async (item) => {
        return new Promise((resolve, reject) => {
          resolve({
            name: item._file.name,
            length: item._file.size,
            contentType: item._file.type,
            date: new Date()
          });
        });
      }
    });
  }

  showMarkerArea(img, index) {
    const markerArea = new markerjs2.MarkerArea(img);
    markerArea.settings.displayMode = 'popup';
    markerArea.addRenderEventListener(async (imgURL) => {
      await this.updateImage(imgURL, this.imageDocuments[index]);
    });
    markerArea.show();
  }

  showMarkerArea2(imageDtls, index) {
    let imageSrc: HTMLImageElement;
    this.imageDocument = imageDtls;

    imageSrc = (document.getElementById(imageDtls.id) as HTMLImageElement)
    const markerArea = new markerjs2.MarkerArea(imageSrc);
    markerArea.settings.displayMode = 'popup';
    markerArea.addRenderEventListener(async (imgURL) => {
      /* imageDtls.src = imgURL
      imageSrc.src = imgURL; */
      await this.updateImage(imgURL, this.imageDocuments[index]);
    });
    markerArea.show();
  }

  // imageCompress(imageDtls, index) {
  //   let imageSrc: HTMLImageElement;
  //   this.imageDocument = imageDtls;
  //   //console.log(imageDtls);
  //   let file: string;
  //   file=environment.base_url+imageDtls.img_path;
  //   //console.log(file);
  // }

  showCropArea(imageDtls, index) {
    let imageSrc: HTMLImageElement;
    imageSrc = (document.getElementById(imageDtls.id) as HTMLImageElement)
    const cropArea = new cropro.CropArea(imageSrc);
    cropArea.displayMode = 'popup';
    cropArea.addRenderEventListener(async (imgURL) => {
      /* imageDtls.src = imgURL
      imageSrc.src = imgURL; */
      await this.updateImage(imgURL, this.imageDocuments[index]);
    });
    cropArea.show();
  }

  async backupImage(imagePath) {
    const backupResponse = await this.CrudService.backupImage(imagePath).toPromise();
  }

  async updateImage(imgURL, imageInfo) {
    await this.backupImage(imageInfo.img_path);
    let fileName = imageInfo.img_name.substr(0, (imageInfo.img_name.lastIndexOf('.')))
    let ext = imageInfo.img_name.substr(imageInfo.img_name.lastIndexOf('.'));
    let datetime = new Date().toISOString();
    datetime = datetime.replace(':', '').substr(0, 13);
    fileName = fileName + '_' + datetime + ext;
    let res = this.CrudService.uploadImage(imgURL, this.jobId, this.jobAllocationId, fileName);
    let response = JSON.parse(res);
    if (response.status === 1) {

      let imageDocument = {
        company_id: this.currentUser.company,
        created_at: new Date(),
        created_by: this.currentUser.userId,
        deleted: null,
        id: null,
        img_description: null,
        img_inc: imageInfo.img_inc,
        img_name: fileName,
        img_path: response.filepath,
        img_type: imageInfo.img_type,
        included: imageInfo.included,
        job_alloc_id: this.jobAllocationId,
        job_id: this.jobId,
        last_modified_by: null,
        last_updated_at: null,
        owner: null,
        process_id: this.currentUser.company,
        source: null,
        status: '1',
        upload_by: this.currentUser.userId,
        upload_time: new Date(),
        upload_type: null,
        upload_type_detail: null,
        user_id: this.currentUser.userId,
        username: this.currentUser.username,
        vs: null,
        image_url: imgURL,
        isIncluded: imageInfo.isIncluded,
        selectedType: imageInfo.selectedType,
        upload_time_str: new Date().toLocaleString(),
        upload_time_str2: new Date(),
        s3_url: response?.s3Url,
        sync_status: response?.s3Url ? 1 : 0
      }
      this.imageDocuments = [imageDocument, ...this.imageDocuments];

      this.messageService.add({ severity: 'success', summary: 'Successful', detail: 'File Uploaded Successfully', life: 1000 });
    } else {
      this.messageService.add({ severity: 'error', summary: 'error', detail: 'File Not Uploaded', life: 1000 });
    }
  }
  hideDialog() {
    this.imageTab = false;
  }
  openImageTab() {
    this.imageTab = true;
  }

  // uploadFile(fileInfo) {
  //   let file = fileInfo.file;
  //   let imageDocument = {
  //     company_id: this.currentUser.company,
  //     created_at: new Date(),
  //     created_by: null,
  //     deleted: null,
  //     id: null,
  //     img_description: null,
  //     img_inc: '2',
  //     img_name: null,
  //     img_path: null,
  //     img_type: fileInfo.id,
  //     included: null,
  //     job_alloc_id: this.jobAllocationId,
  //     job_id: this.jobId,
  //     last_modified_by: null,
  //     last_updated_at: null,
  //     owner: null,
  //     process_id: this.currentUser.company,
  //     source: null,
  //     status: "1",
  //     upload_by: this.currentUser.userId,
  //     upload_time: null,
  //     upload_type: null,
  //     upload_type_detail: null,
  //     user_id: this.currentUser.userId,
  //     username: this.currentUser.username,
  //     vs: null,
  //     image_url: null,
  //     isIncluded: true,
  //     selectedType: null,
  //     upload_time_str: null
  //   }
  //   let imgPath = null;
  //   let reader = new FileReader();
  //   reader.readAsDataURL(file);
  //   reader.onload = (_event) => {
  //     if (reader.DONE) {
  //       imgPath = reader.result;
  //       let res = this.CrudService.uploadImage(imgPath, this.jobId, this.jobAllocationId, file.name);
  //       let response = JSON.parse(res)
  //       if (response.status == '1') {
  //         imageDocument.img_name = file.name;
  //         imageDocument.upload_time_str = new Date().toLocaleString();
  //         imageDocument.upload_time = new Date();
  //         imageDocument.img_path = response.filepath;
  //         imageDocument.img_inc = '2';
  //         imageDocument.isIncluded = true;
  //         imageDocument.selectedType = { type: fileInfo.type, id: fileInfo.id };
  //         this.CrudService.Create(imageDocument, 'UploadImages').subscribe((data: any) => {
  //           //console.log('uploadimage', data);
  //           if (data.success === 1) {
  //             imageDocument.id = data.id
  //             this.CrudService.getImageFile(imageDocument.img_path).subscribe(blob => {
  //               let objectURL = URL.createObjectURL(blob);
  //               let image_url = this.sanitizer.bypassSecurityTrustUrl(objectURL);
  //               imageDocument.image_url = image_url;
  //             });
  //             this.imageDocuments = [...this.imageDocuments, imageDocument];
  //             this.messageService.add({ severity: 'success', summary: 'Successful', detail: 'File Uploaded Successfully', life: 1000 });
  //           } else {
  //             this.messageService.add({ severity: 'error', summary: 'Error', detail: 'File Not Uploaded', life: 1000 });
  //           }
  //         });
  //         this.addNewImage = false;
  //       } else {
  //         this.messageService.add({ severity: 'error', summary: 'Error', detail: 'File Not Uploaded', life: 1000 });
  //       }
  //     }
  //   }
  // }


  uploadFile(fileInfo) {
    let file = fileInfo.file;
    let imageDocument = {
      company_id: this.currentUser.company,
      created_at: new Date(),
      created_by: null,
      deleted: null,
      id: null,
      img_description: null,
      img_inc: '2',
      img_name: null,
      img_path: null,
      img_type: fileInfo.id,
      included: null,
      job_alloc_id: this.jobAllocationId,
      job_id: this.jobId,
      last_modified_by: null,
      last_updated_at: null,
      owner: null,
      process_id: this.currentUser.company,
      source: null,
      status: "1",
      upload_by: this.currentUser.userId,
      upload_time: null,
      upload_type: null,
      upload_type_detail: null,
      user_id: this.currentUser.userId,
      username: this.currentUser.username,
      vs: null,
      image_url: null,
      isIncluded: true,
      selectedType: null,
      upload_time_str: null,
      upload_time_str2: null,
      s3_url: null,
      sync_status: 0,
    }
    let imgPath = null;
    let reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = (_event) => {
      if (reader.DONE) {
        imgPath = reader.result;
        let res = this.CrudService.uploadImage(imgPath, this.jobId, this.jobAllocationId, file.name);
        let response = JSON.parse(res)
        if (response.status == '1') {
          imageDocument.img_name = file.name;
          imageDocument.upload_time_str = new Date().toLocaleString();
          imageDocument.upload_time_str2 = new Date();
          imageDocument.upload_time = new Date();
          imageDocument.img_path = response.filepath;
          imageDocument.img_inc = '2';
          imageDocument.isIncluded = true;
          imageDocument.selectedType = { type: fileInfo.type, id: fileInfo.id };
          imageDocument.s3_url = response?.s3Url;
          imageDocument.sync_status = response?.s3Url ? 1 : 0;
          this.CrudService.Create(imageDocument, 'UploadImages').subscribe((data: any) => {
            //console.log('uploadimage', data);
            if (data.success === 1) {
              imageDocument.id = data.id
              this.CrudService.getImageFile(imageDocument.img_path).subscribe(blob => {
                let objectURL = URL.createObjectURL(blob);
                let image_url = this.sanitizer.bypassSecurityTrustUrl(objectURL);
                imageDocument.image_url = image_url;
              });
              this.imageDocuments = [...this.imageDocuments, imageDocument];
              this.messageService.add({ severity: 'success', summary: 'Successful', detail: 'File Uploaded Successfully', life: 1000 });
            } else {
              this.messageService.add({ severity: 'error', summary: 'Error', detail: 'File Not Uploaded', life: 1000 });
            }
          });
          this.addNewImage = false;
        } else {
          this.messageService.add({ severity: 'error', summary: 'Error', detail: 'File Not Uploaded', life: 1000 });
        }
      }
    }
  }

  addCompressedImage(fileInfo) {
    let file = fileInfo.file;
    let imageDocument = {
      company_id: this.currentUser.company,
      created_at: new Date(),
      created_by: null,
      deleted: null,
      id: null,
      img_description: null,
      img_inc: '2',
      img_name: file.name,
      img_path: null,
      img_type: fileInfo.id,
      included: null,
      job_alloc_id: this.jobAllocationId,
      job_id: this.jobId,
      last_modified_by: null,
      last_updated_at: null,
      owner: null,
      process_id: this.currentUser.company,
      source: null,
      status: '1',
      upload_by: this.currentUser.userId,
      upload_time: null,
      upload_type: null,
      upload_type_detail: null,
      user_id: this.currentUser.userId,
      username: this.currentUser.username,
      vs: null,
      image_url: null,
      isIncluded: true,
      selectedType: null,
      upload_time_str: null,
      upload_time_str2: null,
      s3_url: null,
      sync_status: 0,
    }
    let imgPath = fileInfo.imageData;
    let res = this.CrudService.uploadImage(imgPath, this.jobId, this.jobAllocationId, file.name);
    let response = JSON.parse(res)
    if (response.status === 1) {
      imageDocument.img_name = file.name;
      imageDocument.upload_time_str = new Date().toLocaleString();
      imageDocument.upload_time_str2 = new Date();
      imageDocument.upload_time = new Date();
      imageDocument.img_path = response.filepath;
      imageDocument.img_inc = '2';
      imageDocument.isIncluded = true;
      imageDocument.selectedType = { type: fileInfo.type, id: fileInfo.id };
      imageDocument.s3_url = response?.s3Url;
      imageDocument.sync_status = response?.s3Url ? 1 : 0;
      this.CrudService.getImageFile(imageDocument.img_path).subscribe(blob => {
        let objectURL = URL.createObjectURL(blob);
        let image_url = this.sanitizer.bypassSecurityTrustUrl(objectURL);
        imageDocument.image_url = image_url;
      });
      this.imageDocuments = [imageDocument, ...this.imageDocuments];
      this.addNewImage = false;
    } else {
      this.messageService.add({ severity: 'error', summary: 'Error', detail: 'File Not Uploaded', life: 1000 });
    }
  }

  async addNewImageFileNew(fileInfo) {
    let file = fileInfo.file;
    let imageDocument = {
      company_id: this.currentUser.company,
      created_at: new Date(),
      created_by: null,
      deleted: null,
      id: null,
      img_description: null,
      img_inc: '2',
      img_name: null,
      img_path: null,
      img_type: fileInfo.id,
      included: null,
      job_alloc_id: this.jobAllocationId,
      job_id: this.jobId,
      last_modified_by: null,
      last_updated_at: null,
      owner: null,
      process_id: this.currentUser.company,
      source: null,
      status: "1",
      upload_by: this.currentUser.userId,
      upload_time: null,
      upload_type: null,
      upload_type_detail: null,
      user_id: this.currentUser.userId,
      username: this.currentUser.username,
      vs: null,
      image_url: null,
      isIncluded: true,
      selectedType: null,
      upload_time_str: null,
      upload_time_str2: null,
    }
    //let response = await this.compressUploadImageFile(file);
    let response = await this.uploadImageFile(file);
    if (response.success === true) {
      imageDocument.img_name = file.name;
      imageDocument.upload_time_str = new Date().toLocaleString();
      imageDocument.upload_time_str2 = new Date();
      imageDocument.upload_time = new Date();
      imageDocument.img_path = response.filepath;
      imageDocument.img_inc = '2';
      imageDocument.isIncluded = true;
      imageDocument.selectedType = { type: fileInfo.type, id: fileInfo.id };
      this.CrudService.getImageFile(imageDocument.img_path).subscribe(blob => {
        let objectURL = URL.createObjectURL(blob);
        let image_url = this.sanitizer.bypassSecurityTrustUrl(objectURL);
        imageDocument.image_url = image_url;
      });
      this.imageDocuments = [...this.imageDocuments, imageDocument];
      this.addNewImage = false;
    } else {
      this.messageService.add({ severity: 'error', summary: 'Error', detail: 'This file type is not supported', life: 1000 });
    }
  }

  async uploadImageFile(file: any): Promise<any> {
    let res: any;
    if (file.size <= environment.imageDocSize) {
      let filepath: any = this.jobId + '/' + this.jobAllocationId;
      let filename: any = file.name;
      res = await this.CrudService.uploadImageV2(file, filepath, filename);
      res = JSON.parse(res);
      if (res.success === true) {
        res.filepath = filepath + '/' + filename;
      }
    }
    return res;
  }

  async compressUploadImageFile(file: any): Promise<any> {
    let res: any;
    if (file.size <= environment.imageDocSize) {
      let filepath: any = this.jobId + '/' + this.jobAllocationId;
      let filename: any = file.name;
      res = await this.CrudService.compressUploadImageV2(file, filepath, filename);
      res = JSON.parse(res);
      if (res.success === true) {
        res.filepath = filepath + '/' + filename;
      }
    }
    return res;
  }

  /*
    uploadBeforeImages(event) {
      for (let file of event) {
        let duplicates = this.imageDocuments.filter(val => val.img_name === file.name);
        if (duplicates.length > 0) {
          this.messageService.add({ severity: 'error', summary: 'Error', detail: 'Duplicate Image Found : ' + file.name, life: 1000 });
        } else {
          let fileInfo = { order: this.uploadedFiles.length, type: 'Before', id: '1', file: file };
          //this.uploadedFiles.push(fileInfo);
          this.addNewImageFile(fileInfo);
        }
      }
      //this.uploadLabel = this.uploadedFiles.length + ' Images';
    }
   
   
    uploadAfterImages(event) {
      //this.selectedType = { type: 'After', id: '2' };
      for (let file of event) {
        let duplicates = this.imageDocuments.filter(val => val.img_name === file.name);
        if (duplicates.length > 0) {
          this.messageService.add({ severity: 'error', summary: 'Error', detail: 'Duplicate Image Found : ' + file.name, life: 1000 });
        } else {
          let fileInfo = { order: this.uploadedFiles.length, type: 'After', id: '2', file: file };
          //this.uploadedFiles.push(fileInfo);
          //this.uploadFile(file);
          this.addNewImageFile(fileInfo);
        }
      }
      //this.uploadLabel = this.uploadedFiles.length + ' Images';
    }
  */
 
    uploadBeforeImages(filess) {
      this.loading = true;
      for (let event of filess) {
      let f: File;
      debugger
      //RECEIVE IMAGE
      if (event) {
        f = event;
        if (event.length > 1) {
          event.splice(0, event.length - 1);
          f = event;
        }
      } else if (event.target && event.target.files && event.target.files) {
        f = event.target.files;
      }
      if (!f) {
        //Handle error and exit
      }
      let blob: Blob = f;
      let file: File = f;
  
      let convProm: Promise<any>;
  
      //CONVERT HEIC TO JPG
  
      convProm = heic2any({ blob, toType: "image/jpeg", quality: 0 }).then((jpgBlob: Blob) => {
  
        //Change the name of the file according to the new format
        let newName = f.name.replace(/\.[^/.]+$/, ".jpg");
  
        //Convert blob back to file
        file = this.blobToFile(jpgBlob, newName);
  
      }).catch(err => {
        //Handle error
      });
  
      convProm.then(() => {
        let reader = new FileReader();
        if (file) {
          let duplicates = this.imageDocuments.filter(val => val.img_name === file.name);
          if (duplicates.length > 0) {
            this.loading = false;
            this.messageService.add({ severity: 'error', summary: 'Error', detail: 'Duplicate Image Found : ' + file.name, life: 1000 });
          } else {
  
            reader.readAsDataURL(file);
            reader.onload = (_event) => {
              if (reader.DONE) {
                let imgPath = null;
                imgPath = reader.result;
               
                //reader.onload = (_event, imgPath:void,imgResultBeforeCompress:void,imgResultAfterCompress:void) => {
                if (file.size > 200000) {
                  let cp = 100 - ((200000 * 100) / (file.size / 10));
                  this.imageCompress.compressFile(imgPath, -2, 25, 50).then(
                    result => {
                      //console.log(result);
                      let fileInfo = { order: this.uploadedFiles.length, type: 'Before', id: '1', file: file, imageData: result };
  
                      this.uploadedFiles.push(fileInfo);
                    
                      this.addCompressedImage(fileInfo);
                    }
                  );
                } else {
                  let fileInfo = { order: this.uploadedFiles.length, type: 'Before', id: '1', file: file, imageData: imgPath };
                  this.loading = false;
                  this.uploadedFiles.push(fileInfo);
               
                  this.uploadFile(fileInfo);
                }
              }
            };
          }
  
        }
      });
    }
  
      this.uploadLabel = this.uploadedFiles.length + ' Images';
    }
    

  /*uploadAfterImages(event) {
    for (let file of event) {
      let duplicates = this.imageDocuments.filter(val => val.img_name === file.name);
      if (duplicates.length > 0) {
        this.messageService.add({ severity: 'error', summary: 'Error', detail: 'Duplicate Image Found : ' + file.name, life: 1000 });
      } else {
        let fileInfo = { order: this.uploadedFiles.length, type: 'After', id: '2', file: file };
        this.uploadedFiles.push(fileInfo);
      }
    }
    this.uploadLabel = this.uploadedFiles.length + ' Images';
  }*/


  uploadAfterImages(filess) {
    this.loading = true;
          for (let event of filess) {
                let f: File;
                //RECEIVE IMAGE
                if (event) {
                  f = event;
                  if (event.length > 1) {
                    event.splice(0, event.length - 1);
                    f = event;
                  }
                } else if (event.target && event.target.files && event.target.files) {
                  f = event.target.files;
                }
                if (!f) {
                  //Handle error and exit
                }
                let blob: Blob = f;
                let file: File = f;

                let convProm: Promise<any>;

                //CONVERT HEIC TO JPG
              
                convProm = heic2any({ blob, toType: "image/jpeg", quality: 0 }).then((jpgBlob: Blob) => {

                  //Change the name of the file according to the new format
                  let newName = f.name.replace(/\.[^/.]+$/, ".jpg");

                  //Convert blob back to file
                  file = this.blobToFile(jpgBlob, newName);

                }).catch(err => {
                  //Handle error
                });
              
                convProm.then(() => {
                  let reader = new FileReader();
                  if (file) {
            let duplicates = this.imageDocuments.filter(val => val.img_name === file.name);
            if (duplicates.length > 0) {
              this.messageService.add({ severity: 'error', summary: 'Error', detail: 'Duplicate Image Found : ' + file.name, life: 1000 });
              this.loading = false;
            } else {
              let imgPath = null;
              let reader = new FileReader();
              reader.readAsDataURL(file);
              reader.onload = (_event) => {
                if (reader.DONE) {
                  imgPath = reader.result;
                  //reader.onload = (_event, imgPath:void,imgResultBeforeCompress:void,imgResultAfterCompress:void) => {
                  if (file.size > 6000000) {
                    let cp = (200000 * 100) / (file.size / 10);
                    this.imageCompress.compressFile(imgPath, -2, 25, 50).then(
                      result => {
                        let fileInfo = { order: this.uploadedFiles.length, type: 'After', id: '2', file: file, imageData: result };
                        this.loading = false;
                        this.uploadedFiles.push(fileInfo);
                        this.addCompressedImage(fileInfo);
                      }
                    );
                  }else {
                    let fileInfo = { order: this.uploadedFiles.length, type: 'After', id: '2', file: file, imageData: imgPath };
                    this.loading = false;
                    this.uploadedFiles.push(fileInfo);
                    this.uploadFile(fileInfo);
                  }
                }
              };
            }
          }
        });
      }
    this.uploadLabel = this.uploadedFiles.length + ' Images';
  }

  private blobToFile = (theBlob: Blob, fileName: string): File => {
    let b: any = theBlob;

    //A Blob() is almost a File() - it's just missing the two properties below which we will add
    b.lastModified = new Date();
    b.name = fileName;

    //Cast to a File() type
    return <File>theBlob;
  }

  removeImage(fileInfo) {
    this.uploadedFiles = this.uploadedFiles.filter(val => val.order !== fileInfo.order);
  }
  getUploadImages() {
    this.imageDocuments = [];
    this.CrudService.getUploadImgsByJobIdAllocId(this.jobId, this.jobAllocationId).subscribe((data: any[]) => {
      if (data) {
        data.forEach(imagedocument => {
          // //console.log(imagedocument.upload_time.split("T")[0] + imagedocument.upload_time.split("T")[1].split(".")[0]);
          if (imagedocument.img_inc === '2') {
            imagedocument.isIncluded = true;
          } else {
            imagedocument.isIncluded = false;
          }
          imagedocument.upload_time = imagedocument.upload_time.split("T")[0] + ' ' + imagedocument.upload_time.split("T")[1].split(".")[0];
          imagedocument.upload_time_str = new Date(imagedocument.upload_time).toLocaleString();
          imagedocument.upload_time_str2 = new Date(imagedocument.upload_time);
          if (imagedocument.img_path != null && imagedocument.img_path !== '') {
            this.CrudService.getImageFile(imagedocument.img_path).subscribe(blob => {
              imagedocument.blob = blob;
              let objectURL = URL.createObjectURL(blob);
              imagedocument.objUrl = objectURL;
              let image_url = this.sanitizer.bypassSecurityTrustUrl(objectURL);
              imagedocument.image_url = image_url;
            });
          }
          //imagedocument.upload_time = new Date(imagedocument.upload_time).toLocaleString();
          this.types.forEach(type => {
            if (type.id === imagedocument.img_type) {
              imagedocument.selectedType = type;
              //console.log(type);
            }
          });
          this.imageDocuments.push(imagedocument);
          // //console.log(imagedocument.id, imagedocument);
        });
        ////console.log('All Images', this.imageDocuments);
      }

    })
  }

  onChangeImageType(selecetdType) {
    //console.log(selecetdType)
    this.imageDocument.img_type = selecetdType.id
  }

  saveNewImageRecord() {
    this.submitted = true;

    //console.log(this.currentUser.id)
    //console.log(this.imageDocument);
    if (this.imageForm.status === 'VALID') {
      this.imageDocuments.push(this.imageDocument);
      this.CrudService.Create(this.imageDocument, 'UploadImages').subscribe((data: any) => {
        //console.log(data);
        if (data.success == 1) {
          this.imageDocument.id = data.id;
          this.addNewImage = false;
        }
      })
    }
  }
  deleteImage(image, index) {
    if (image.id !== null) {
      this.confirmationService.confirm({
        message: 'Are you sure you want to delete the selected Image?',
        header: 'Confirm',
        icon: 'pi pi-exclamation-triangle',
        accept: () => {
          this.CrudService.Delete(image.id, 'UploadImages').subscribe((data: any) => {
            //console.log(data)
            if (data.success == 1) {
              this.messageService.add({ severity: 'success', summary: 'Successful', detail: 'Image Deleted', life: 1000 });
              this.imageDocuments = this.imageDocuments.filter((val: any) => val.id !== image.id)
            }
          })
        }
      })
    } else {
      this.imageDocuments.splice(index, 1);
    }
  }

  saveRecord() {
    //console.log(this.imageDocuments)
    this.imageDocuments.forEach(document => {
      this.CrudService.Update(document, 'UploadImages').subscribe((data: any) => {
        //console.log(data);
        this.imageTab = false
      })
    });
  }
  onChangeTpe(id, type) {
    //console.log(id, type);
    this.imageDocuments.forEach(element => {
      if (id === element.id) {
        element.img_type = type.id;
      }
    });
  }
  DateToLocalTimeString(d) {

    let date = new Date(d);
    d = date.toLocaleDateString('en-GB');
    let t = date.toLocaleTimeString();

    return d + ' ' + t;

  }

  editImage(image) {
    //console.log('image', image);
    this.image = { ...image };
    //console.log('image 2', this.image);
    const ref = this.dialogService.open(ImageMarkingComponent, {
      data: {
        image: this.image
      },
      header: 'Image Marking',
      width: '100%',
      height: '100%'
    });
  }

  rotateImage(imageDocument) {
    let reader = new FileReader();
    reader.readAsDataURL(imageDocument.blob);
    reader.onloadend = function (e) {
      let imageSize = e.total;
      let image = new Image();
      imageDocument.src = e.target.result;
      image.onload = function () {
        //console.log('rotateImage');

        let width = image.width;
        let height = image.height;
        let canvas = document.createElement("canvas");
        let ctx = canvas.getContext('2d');

        let newImage = new Image();
        //console.log('rotate90�');
        canvas.height = width;
        canvas.width = height;
        ctx.rotate(Math.PI / 2);
        ctx.translate(0, -height);

        ctx.drawImage(image, 0, 0)
        let imageDate = canvas.toDataURL('Image/jpeg', 1)
        newImage.src = imageDate;
      }
    }
  }

  save(imageDetails) {
    if (imageDetails.selectedType) {
      imageDetails.img_type = imageDetails.selectedType.id;
    }
    if (imageDetails.isIncluded) {
      imageDetails.img_inc = '2';
    } else {
      imageDetails.img_inc = '1';
    }
    //console.log(imageDetails.img_description);
    imageDetails.img_type = imageDetails.selectedType.id;
    this.CrudService.Update(imageDetails, 'UploadImages').subscribe((data: any) => {
      //console.log(data);
      if (data.success == '1') {
        this.messageService.add({ severity: 'success', summary: 'Successful', detail: 'Image Information Updated', life: 1000 });

      }
    })

  }

  saveAllImages() {
    this.uploadedFiles.forEach(fileInfo => {
      this.uploadFile(fileInfo);
    });
    this.uploadedFiles = [];
  }

  saveAll() {
    let status = true;
    this.imageDocuments.forEach(imageDetails => {
      if (imageDetails.selectedType) {
        imageDetails.img_type = imageDetails.selectedType.id;
      }
      if (imageDetails.isIncluded) {
        imageDetails.img_inc = '2';
      } else {
        imageDetails.img_inc = '1';
      }
      imageDetails.img_type = imageDetails.selectedType.id;
      if (imageDetails.id) {
        this.CrudService.Update(imageDetails, 'UploadImages').subscribe((data: any) => {
          if (data.success == '1') {
          } else {
            status = false;
          }
        })
      } else {
        this.CrudService.Create(imageDetails, 'UploadImages').subscribe((data: any) => {
          if (data.success === 1) {
            imageDetails.id = data.id;
          } else {
            status = false;
            this.messageService.add({ severity: 'warning', summary: 'Error', detail: 'File Not Uploaded', life: 1000 });
          }
        });
      }
    });
    if (status) {
      this.messageService.add({ severity: 'success', summary: 'Successful', detail: 'Image Information Updated', life: 1000 });
    } else {
      this.messageService.add({ severity: 'error', summary: 'Error', detail: 'Image Information  Not Updated', life: 1000 });
    }
  }

  compressSelectedImage(index) {
    let imgPath = null;
    let reader = new FileReader();
    reader.readAsDataURL(this.imageDocuments[index].blob);
    reader.onload = async (_event) => {
      if (reader.DONE) {
        imgPath = reader.result;
        let size = this.imageCompress.byteCount(imgPath);
        //reader.onload = (_event, imgPath:void,imgResultBeforeCompress:void,imgResultAfterCompress:void) => {
        if (size > 200000) {
          await this.backupImage(this.imageDocuments[index].img_path);
          this.imageCompress.compressFile(imgPath, -2, 25, 50).then(
            result => {
              let res = this.CrudService.uploadImage(result, this.jobId, this.jobAllocationId, this.imageDocuments[index].img_name);
              this.loading = false;
            }
          );
        }
      }
    };
  }
}
