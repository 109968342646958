import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, ActivatedRoute, NavigationEnd } from '@angular/router';
import { Observable } from 'rxjs';
import { Router } from '@angular/router';
import * as authData from '../../app/services/auth.json';

@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate {
  userSettings: any;

  authList: any = (authData as any).default;
  url: string;


  constructor(private router: Router,private activatedRoute: ActivatedRoute) {

  }
  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {

    this.userSettings = JSON.parse(localStorage.getItem('loggedInUser'));

    console.log('authList', this.authList.tpusermenus);
    console.log('state url', state);

    this.router.events.subscribe((val) => {
      if (val instanceof NavigationEnd) {
        const urlTree = this.router.parseUrl(val.url);
        const urlSegments = urlTree.root.children['primary'].segments.map(segment => segment.path);
        const url = (`/${urlSegments[0]}/${urlSegments[1]}`);
        console.log("url",url);       
      }
    });

    if (this.userSettings == 'null' || this.userSettings == null || this.userSettings == '') {
      window.sessionStorage.clear();
      this.router.navigate(['/login']);
      return false;
    }
    else {
      // return true;

      if (this.userSettings.Client_Type == "1" && this.userSettings.user_group_id == "5") {
        var datalen = this.authList.tpadminmenus.filter(data => data.routerLink == state.url).length;
      }
      else if (this.userSettings.Client_Type == "3" && this.userSettings.user_group_id == "1") {
        var datalen = this.authList.tpadminmenu.filter(data => data.routerLink == state.url).length;
      }
      else if (this.userSettings.Client_Type == "3" && this.userSettings.user_group_id == "2") {
        var datalen = this.authList.tpusermenus.filter(data => data.routerLink == state.url).length;
      }
      else {
        var datalen = this.authList.commonmenu.filter(data => data.routerLink == state.url).length;
      }

      if (datalen >= 0 || datalen == 0) {
        return true;
      }
      else {
        this.router.navigate(['/notfound']);
        return false;
      }

    }
  }

}
