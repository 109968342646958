import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-date-time-comp',
  templateUrl: './date-time-comp.component.html',
  styleUrls: ['./date-time-comp.component.scss']
})
export class DateTimeCompComponent implements OnInit {

  selectedDate: Date;
  finish: boolean = true;
  pickdatetime;
  @Input() date: any;
  @Input() label: any;
  @Input() disabled: boolean;
  @Output() updateDate = new EventEmitter<any>();

  constructor() { }

  ngOnInit(): void {
    if(this.date) {
      this.pickdatetime = this.date;
    }
  }

  trigger() {
    if(!this.disabled) {
      this.finish = false;
    }
  }

  selectDate(){
    if(this.selectedDate) {
      this.finish = true;
      this.pickdatetime = this.selectedDate;
      //console.log('Date Selected');
      
      this.updateDate.emit(this.pickdatetime);
    }
  }

  closeDate(){
    this.finish = true;
  }

}
