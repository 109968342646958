import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { CRUDService } from 'src/app/utilities/crud.service';
import { DynamicDialogRef, DialogService } from 'primeng/dynamicdialog';
import { ConfirmationService } from 'primeng/api';
import { MessageService } from 'primeng/api';
import { Table } from 'primeng/table';
import { Staff } from './staff';
import { ComplianceComponent } from '../../admin/compliance/compliance.component';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { CommonService } from 'src/app/utilities/common.service';
import { environment } from 'src/environments/environment';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { JOBService } from 'src/app/utilities/job.service';
import { DeviceDetectorService } from 'ngx-device-detector';
import { HttpClient } from '@angular/common/http';
import { SignaturePad } from 'angular2-signaturepad';
import { Subject } from 'rxjs';
import { EncryptionService } from 'src/app/utilities/encryption.service';
import { EncrDecrService } from 'src/app/utilities/encr-decr.service';
import { BsDatepickerAbstractComponent } from 'ngx-bootstrap/datepicker/base/bs-datepicker-container';
import { idLocale } from 'ngx-bootstrap';
import { NgTinyUrlService } from 'ng-tiny-url';
import { DatePipe } from '@angular/common';

@Component({
  selector: 'app-staffs',
  templateUrl: './staffs.component.html',
  styleUrls: ['./staffs.component.scss', '../../style.css'],
  providers: [DialogService, MessageService, ConfirmationService]
})

export class StaffsComponent implements OnInit {
  @ViewChild(SignaturePad) signaturePad: SignaturePad;
  signature: any;
  userDetails: any;
  docPdfUrl: any = '';
  displayPdf = false;
  signedBy: any;
  ip: any;
  public lat: any;
  public lng: any;


  paginationvalue: any;
  @Input() contractor_id: any;
  @Input() videoUrl: any;
  @Input() pdfUrl: any;
  viewType: any;
  companyId: any;
  user: any;
  userExists: boolean;
  uploadedFiles: any[] = [];
  cols: any[];
  dataValue: any[];
  viewAllStaffData: any;
  staffCreateList: any;
  exportColumns: any[];
  itemList: any[];
  docList: any[];
  excelColumnsList: any[];
  selectedCompany: any;
  closeVideo: boolean = true;
  closepdf: boolean = true;
  notification_flag: boolean = false;
  notification_flag_d: boolean = false;
  public saveView = true;
  public editView = false;
  public listView = false;
  public createView = false;
  staffDialog: boolean;
  notificationDialog: boolean;
  mytable: boolean = true;
  complianceDialog: boolean;
  staffs: any[];
  staff: any;
  compliance: any;
  compliances: any[];
  selectedStaffs: any[];
  submitted: boolean;
  enableLogin = false;
  statuses: any[];
  issuedDate: Date;
  expirationDate: Date;
  staffRoleList: any[];
  staffTypeList: any[];
  userRoleList: any[];
  selectedUserRole: any;
  selectedStaffRole: any;
  selectedStaffType: any;
  checkboxEnabled = false;
  mobNumberPattern = `^((\\+61-?)|0)?[0-9]{10}$`;
  companyList: any[];
  selectedStaffCompliance: any;
  staffComplianceList: any[];
  sortOptions: any[];
  sortOrder: number;
  ref: DynamicDialogRef;
  sortField: string;
  complianceSubmitted: boolean;
  complianceTypes: any[];
  NewComplianceEnabled: boolean;
  selectedComplianceDoc: any;
  staffId: any;
  isActive: boolean;
  formStatus: boolean;
  complianceNewLayout = true;
  inductionExists = false;
  inductionExpired = false;
  exportdisplay: Boolean = false;
  notificationMobile: Boolean;
  notificationMail: Boolean;
  notificationApp: Boolean;
  dupUserId: boolean = false;
  dupEmail: boolean = false;
  noOfStaff: number;
  workflowDefinition: any;
  showWorkflowDefnSteps: Boolean = false;
  showEf: Boolean = false;
  showEfstart: Boolean = true;
  searchvalue: any;

  passKeyTextType: boolean;
  confirmpassKeyTextType: boolean;
  passwordMatch: boolean = true;
  WorkflowDefnSteps: any;
  InductionStatus: any;
  empSize: any;
  minEmpSize: number;
  employeSize: any;
  appProps: any;
  env = environment;

  haslowercase: boolean;
  hasuppercase: boolean;
  hasspecialchar: boolean;
  hasnumbers: boolean;
  buttonDisabled1: boolean=true;
  buttonDisabled2: boolean=true;
  buttonDisabled3: boolean=true;
  buttonDisabled4: boolean=true;
  buttonDisabled5: boolean=true;
  encryptedPass : any;


  eventsSubject: Subject<void> = new Subject<void>();
  emailSubject: string;
  access_token: string;
  emailContent: string;
  templ: any;
  ef_access_Url: string;
  spa_access_Url: string;
  visible: boolean=false;
  btnvisible: boolean;
  sendlinkvia: boolean;
  send_induction_link: any =0;

  emitEventToChild() {
    this.eventsSubject.next();
  }


  signaturePadOptions: Object = { // passed through to szimek/signature_pad constructor
    minWidth: 2,
    canvasWidth: 400,
    canvasHeight: 200
  };

  isSigned: Boolean = false;
  loading: Boolean = false;
  isChecked: Boolean = false;
  companyCompliance: any;
  isAccepted: Boolean = false;
  acceptedDatetime: any;
  signaturePath: any;
  deviceInfo: any;
  ipAddress: any;
  inputCompanyId: any;

  staffForm = new FormGroup({
    first_name: new FormControl('', Validators.required),
    last_name: new FormControl('', Validators.required),
    phone: new FormControl('', Validators.required),
    email: new FormControl('', Validators.required),
    mobile: new FormControl('', Validators.required),
    company: new FormControl('',),
    role: new FormControl('',),
    staffType: new FormControl('',),
  })

  staffLoginForm = new FormGroup({
    username: new FormControl('', Validators.required),
    password: new FormControl('', Validators.required),
    userRole: new FormControl('', Validators.required),
    pass_key: new FormControl('', Validators.required),
    confirm_pass_key: new FormControl('', Validators.required)
  })

  constructor(private crudService: CRUDService,
    private messageService: MessageService,
    private confirmationService: ConfirmationService,
    private dialogService: DialogService,
    private sanitizer: DomSanitizer,
    private commonservice: CommonService,
    private fb: FormBuilder,
    private JobService: JOBService,
    private CommonService: CommonService,
    private deviceService: DeviceDetectorService,
    private http: HttpClient,
    private encryptService: EncryptionService,
    private encodeDecode: EncrDecrService,
    private tinyUrl: NgTinyUrlService,private datePipe: DatePipe
  ) {
    this.userDetails = JSON.parse(sessionStorage.getItem('loggedInUser'));
    if (this.userDetails === null) {
      this.userDetails = JSON.parse(sessionStorage.getItem('adminUser'));
    }
    this.viewType = 'TP';
    this.selectedCompany = null;
    this.NewComplianceEnabled = false;
  }

  chooseNotification(staff_id) {
  }

  async loadAllStaffList() {
    this.crudService.getAllCompanies().subscribe((data: any[]) => {
      this.companyList = data;
    });

    this.crudService.getAllStaffbyCompName().subscribe((data: any[]) => {
      this.staffs = data;
    });

    this.employeSize = [
      { name: '2-5', id: '0' },
      { name: '6-10', id: '1' },
      { name: '11-25', id: '2' },
      { name: '26-50', id: '3' },
      { name: '50-100', id: '4' },
    ];
    let compData: any = await this.crudService.getDataByField('Company', 'id', this.contractor_id).toPromise();
    let empSize = compData[0].emp_size;
    console.log("this.staffs",this.staffs);
    let staffData = this.staffs?.filter(val => (val.InductionStatus == 'Yes'));
    for (let i = 0; i < this.employeSize.length; i++) {
      if (this.employeSize[i].id === empSize) {
        let minSizeall = this.employeSize[i].name;
        let minSize = minSizeall.split("-", 1)
        this.minEmpSize = minSize[0];
        break;
      }
    }
    if (staffData?.length >= this.minEmpSize) {
      localStorage.setItem('staffStrength', '20');
    }
  }
  notificationToggle(type) {
    if (type == 1) {
      if (!this.notificationMobile) { this.notificationMobile = true; }
      else { this.notificationMobile = false; }
    }
    if (type == 2) {
      if (!this.notificationMail) { this.notificationMail = true; }
      else { this.notificationMail = false; }
    }
    if (type == 3) {
      if (!this.notificationApp) { this.notificationApp = true; }
      else { this.notificationApp = false; }
    }

  }

  chooseWorkflow(event) {
    this.showWorkflowDefnSteps = true;
    this.WorkflowDefnSteps = [
      { columnDef: 'job_creation', header: 'Job Creation', code: '1002', sequence: 1, checked: true },
      { columnDef: 'quote_approvals', header: 'Quote Approval', code: '1002', sequence: 2, checked: true },
      { columnDef: 'payments', header: 'Payments', code: '1002', sequence: 3, checked: false },
      { columnDef: 'approval', header: 'Approval', code: '1002', sequence: 4, checked: false },
      { columnDef: 'complaince', header: 'Compliance', code: '1002', sequence: 5, checked: true }
    ];
  }

  checkDuplicateEmail(value: any, field: any, table: any) {
    this.crudService.getDataByField(table, field, value).subscribe((data: any) => {
      if (data.length == 0) { this.dupEmail = false; }
      else { this.dupEmail = true; }

    })
  }
  checkDuplicateUserId(value: any, field: any, table: any) {
    this.crudService.getDataByField(table, field, value).subscribe((data: any) => {
      if (data.length == 0) { this.dupUserId = false; }
      else { this.dupUserId = true; }
    })
  }

  onStrengthChange(score) {
    // console.log('new score', score);
  }

  passwordMatchValidator(pass_key, confirm_pass_key) {
    if (pass_key === confirm_pass_key) {
      this.passwordMatch = false;
    }
    else {
      this.passwordMatch = true;
    }

  }
  toggle_pass_key() {
    this.passKeyTextType = !this.passKeyTextType;
  }
  toggle_confirm_pass_key() {
    this.confirmpassKeyTextType = !this.confirmpassKeyTextType;
  }
  loadHelpvideo() {
    this.closeVideo = true;
    this.closepdf = false;
    document.getElementById('help-video').style.display = 'block';
  }
  loadHelppdf() {
    this.closeVideo = false;
    this.closepdf = true;
    document.getElementById('help-video').style.display = 'block';
  }
  videoClose() {
    this.closeVideo = false;
    this.closepdf = false;
    document.getElementById('help-video').style.display = 'none';

  }
  async loadStaffByCompany() {
    this.crudService.getStaffByCID(this.companyId).subscribe((data: any[]) => {
      this.staffs = data;
      console.log("Staff Data", this.staffs);
      this.noOfStaff = this.staffs.length;
    });
    this.employeSize = [
      { name: '2-5', id: '0' },
      { name: '6-10', id: '1' },
      { name: '11-25', id: '2' },
      { name: '26-50', id: '3' },
      { name: '50-100', id: '4' },
    ];
    let compData: any = await this.crudService.getDataByField('Company', 'id', this.contractor_id).toPromise();
    let empSize = compData[0].emp_size;
    let staffData = this.staffs?.filter(val => (val.InductionStatus == 'Yes'));
    for (let i = 0; i < this.employeSize.length; i++) {
      if (this.employeSize[i].id === empSize) {
        let minSizeall = this.employeSize[i].name;
        let minSize = minSizeall.split("-", 1)
        this.minEmpSize = minSize[0];
        break;
      }
    }

    if (staffData?.length >= this.minEmpSize) {
      localStorage.setItem('staffStrength', '20');
    }

  }

  async ngOnInit(): Promise<void> {
    debugger
    this.appProps = this.commonservice.getAppProperties();
    this.paginationvalue = environment.paginatorValue;
    this.formStatus = true;
    if(this.env.hosting == 'AU')
    {
      this.inputCompanyId = '74';
    }
    else if(this.env.hosting == 'UK')
    {
      this.inputCompanyId = '170';
    }
    this.notificationMobile = true;
    this.notificationMail = true;
    this.notificationApp = true;
    this.companyId = sessionStorage.getItem('company_id');
    // this.crudService.getDataByField('WorkflowDefnSteps', 'wf_code', 'P1_JOB').subscribe((data: any[]) => {
    //   console.log("WorkflowDefnSteps:",data);
    //  });
    if (typeof this.contractor_id !== 'undefined') {
      this.companyId = this.contractor_id;
      this.viewType = 'TP';
      this.loadStaffByCompany();
    } else {
      if (this.viewType === 'TM') {
        this.loadAllStaffList();
      } else if (this.viewType === 'TL') {
        this.loadStaffByCompany();
      } else if (this.viewType === 'TP') {
        this.crudService.getDataByField('CompanyStaffInfo', 'id', this.userDetails.staff_id).subscribe((data: any[]) => {
          if (data.length > 0) {
            this.staffs = data[0];
            this.editSelectedStaff(data[0]);
          }
        });
      }
    }
    this.crudService.GetAllForStaffLicense().subscribe((data: any[]) => {
      this.complianceTypes = data;
    });

    this.crudService.getByStaff('ItemDefn').subscribe((data: any[]) => {
      this.staffRoleList = data;
    });
    this.crudService.getByValuesetstafftype().subscribe((items: any[]) => {
      this.staffTypeList = items;
    });
    this.userRoleList = [
      { id: '1', name: 'Admin' },
      { id: '2', name: 'User' },
      // { id: '3', name: 'Only Jobs - View' }
    ];

    this.crudService.GetAllForStaffLicense().subscribe((items: any[]) => {
      this.docList = items;
    });

    this.cols = [
      { field: 'first_name', header: 'First Name' },
      { field: 'last_name', header: 'Last Name' },
      { field: 'email', header: 'Email' },
      { field: 'mobile', header: 'Mobile' },
      { field: 'CompanyName', header: 'Company' },
      { field: 'InductionStatus', header: 'Induction Status' },
      { field: 'StaffTypeName', header: 'Staff Role' }
    ];
    this.sortOptions = [
      { label: 'Ascending', value: '!name' },
      { label: 'Descending', value: 'name' }
    ];
    this.exportColumns = this.cols.map(col => ({ title: col.header, dataKey: col.field }));

     this.crudService.getDataByField('businessSettings', 'company_id', '108').subscribe((data: any[]) => {
      this.send_induction_link = data[0].send_induction_link;
      });

    this.loading = false;

  }


  loadStaffList(){
     if (typeof this.contractor_id !== 'undefined') {
      this.companyId = this.contractor_id;
      this.viewType = 'TP';
      this.loadStaffByCompany();
    } else {
      if (this.viewType === 'TM') {
        this.loadAllStaffList();
      } else if (this.viewType === 'TL') {
        this.loadStaffByCompany();
      } else if (this.viewType === 'TP') {
        this.crudService.getDataByField('CompanyStaffInfo', 'id', this.userDetails.staff_id).subscribe((data: any[]) => {
          if (data.length > 0) {
            this.staffs = data[0];
            this.editSelectedStaff(data[0]);
          }
        });
      }
    }
  }
  checkbox() {
    this.checkboxEnabled = true;
  }

  EnableDisableTextBox() {
    if (this.enableLogin) {
      this.staff.create_login = '2';
    } else {
      this.staff.create_login = '1';
    }
  }

  openNew() {
    debugger
    this.staffForm = new FormGroup({
      first_name: new FormControl('', Validators.required),
      last_name: new FormControl('', Validators.required),
      phone: new FormControl('', Validators.required),
      email: new FormControl('', Validators.required),
      mobile: new FormControl('', Validators.required),
      company: new FormControl('',),
      role: new FormControl('',),
      staffType: new FormControl('',),
    })

    this.staffLoginForm = new FormGroup({
      username: new FormControl('', Validators.required),
      password: new FormControl('', Validators.required),
      userRole: new FormControl('', Validators.required),
      pass_key: new FormControl('', Validators.required),
      confirm_pass_key: new FormControl('', Validators.required)
    })

    this.formStatus = true;
    this.isActive = true;
    this.enableLogin = false;
    this.NewComplianceEnabled = true;
    this.staff = {
      id: null,
      first_name: null,
      last_name: null,
      phone: null,
      company_id: null,
      mobile: null,
      email: null,
      role: null,
      stafftype: null,
      induction: null,
      client_type_id: null,
      inducted_date: null,
      username: null,
      password: null,
      create_login: null,
      process_id: this.companyId,
      user_role_id: null,
      company_inactive_from: null,
      company_inactive_to: null,
    };

    this.submitted = false;
    this.staffDialog = true;
    this.mytable = false;
    this.selectedStaffRole = null;
    this.selectedStaffType = null;
    this.selectedUserRole = null;
    if (this.viewType === 'TM') {
      this.selectedCompany = null;
    } else {
      let companyList = this.companyList?.filter(val => val.id === this.companyId);
      if (companyList !== undefined) {
        this.selectedCompany = companyList[0];
        this.staff.company_id = this.companyId;
      }
    }
    this.enableLogin = false;
    this.user = {};
  }

  openNotofication() {
    this.mytable = false;
    this.crudService.getDataByField('WorkflowDefinition', 'process_id', '108').subscribe((data: any[]) => {
      this.workflowDefinition = data;
    });
    this.notificationDialog = true;
  }

  deleteSelectedStaffs() {
    this.confirmationService.confirm({
      message: 'Are you sure you want to delete the selected staff',
      header: 'Confirm',
      icon: 'pi pi-exclamation-triangle',
      accept: () => {
        // tslint:disable-next-line: prefer-for-of
        for (let i = 0; i < this.selectedStaffs.length; i++) {
          this.crudService.getDataByField('Users', 'staff_id', this.selectedStaffs[i].id).subscribe((userInfo: any[]) => {
            this.crudService.Delete(userInfo[0].id, 'User').subscribe((data2: any[]) => { });
          });
          this.crudService.Delete(this.selectedStaffs[i].id, 'CompanyStaffInfo').subscribe((data: any[]) => { });

        }
        this.staffs = this.staffs.filter(val => !this.selectedStaffs.includes(val));
        this.selectedStaffs = null;
        this.messageService.add({ severity: 'success', summary: 'Successful', detail: 'Staffs Deleted', life: 1000 });
      }
    });
  }

  getStaffComplianceDetail(id) {
    this.crudService.getCompliancesByStaffId(id).subscribe((data: any[]) => {
      data.forEach(compl => {
        compl.exist = true;
        if (compl.compliance_id == 74) {
          this.inductionExists = true;
          if (compl.expiry_date) {
            if (new Date(compl.expiry_date) < new Date()) {
              this.inductionExpired = true;
            }
          }
        }
      });
    });
  }

  passwordValidator(pass_key) {
  
   

    const re = /[0-9]/;
     if(re.test(pass_key)) {
       this.hasnumbers=true 
       this.buttonDisabled1= false;    
     }
     else{
       this.hasnumbers=false
       this.buttonDisabled1= true;
     }
 
     const re1 = /[a-z]/;
     if(re1.test(pass_key)) {
       this.haslowercase=true  
       this.buttonDisabled2= false; 
     }
     else{
       this.haslowercase=false;
       this.buttonDisabled2= true;
 
     }
 
     const re2 = /[A-Z]/;
     if(re2.test(pass_key)) {
       this.hasuppercase=true   
       this.buttonDisabled3= false; 
     }
     else{
       this.hasuppercase=false
       this.buttonDisabled3= true;
     }
    
     const re3 =  /[-+_!@#$%^&*.,?]/;
     if(re3.test(pass_key)) {
       this.hasspecialchar=true;
       this.buttonDisabled4= false;   
     }
     else{
       this.hasspecialchar=false;
       this.buttonDisabled4= true;
     }
   }

  editSelectedStaff(staff: Staff) {
    this.formStatus = true;
    this.submitted = false;
    this.userExists = false;
    this.selectedStaffRole = null;
    this.selectedStaffType = null;
    this.selectedUserRole = null;
    this.user = {};
    this.enableLogin = false;
    this.showEfstart = true;
    this.showEf = false;

    console.log("staff",staff);
    this.staff = { ...staff };
    this.staffId = this.staff.id;
    this.staff.InductionStatus = this.staff.InductionStatus;
    this.InductionStatus = this.staff.InductionStatus;
    try{
      this.loadTrainingInfo();
    }catch(error){

    }
    if (typeof this.staff.created_at == 'undefined') {
      this.staff.created_at = new Date().toDateString();
    }
    // console.log(this.staff);
    this.staffDialog = true;
    this.mytable = false;
    if (this.staff.id !== null) {
      this.crudService.getDataByField('CompanyStaffInfo', 'id', this.staff.id).subscribe((StaffInfo: any[]) => {
        this.staff = StaffInfo[0];
        if (this.staff.username !== null) {
          this.enableLogin = true;
        }
        this.selectedCompany = null;
        this.crudService.getDataByField('Company', 'id', this.staff.company_id).subscribe((companyInfo: any[]) => {
          this.selectedCompany = companyInfo[0];
        });

        if (this.staff.role !== '' && this.staff.role !== null) {
          const staffrole = this.staffRoleList.filter(val => val.id === this.staff.role);
          if (staffrole.length > 0) {
            this.selectedStaffRole = staffrole[0];
          }
        } else {
          this.selectedStaffRole = null;
        }
        if (this.staff.stafftype !== '' && this.staff.stafftype !== null) {
          const stafftype = this.staffTypeList.filter(val => val.id === this.staff.stafftype);
          if (stafftype.length > 0) {
            this.selectedStaffType = stafftype[0];
          }
        } else {
          this.selectedStaffType = null;
        }
        if (this.staff.user_role_id !== null && this.staff.user_role_id !== '') {
          const userrole = this.userRoleList.filter(val => val.id === this.staff.user_role_id);
          if (userrole.length > 0) {
            this.selectedUserRole = userrole[0];
          }
        } else {
          this.selectedUserRole = null;
        }

        this.crudService.getDataByField('Users', 'staff_id', this.staff.id).subscribe((userInfo: any[]) => {
          if (userInfo.length > 0) {
            this.user = userInfo[0];
            this.staff.password = this.encryptService.decryptionAES(this.user.encrypted_password);
            this.passwordValidator(this.staff.password);
            if (typeof this.user.created_at == 'undefined') {
              this.user.created_at = new Date().toDateString();
            }
            this.userExists = true;
          } else {
            this.user = null;
            this.userExists = false;
          }
        });
      });
      if (this.viewType === 'TP' || this.viewType === 'TM') {
        this.getStaffComplianceDetail(this.staff.id);
      }
    } else {
      this.user = {};
    }

    setTimeout(() => {

      this.initTab();

      document.getElementById('help-video-trigger').addEventListener('click', function () {
        document.getElementById('help-video').style.display = 'block';
      })

      document.getElementById('vid-close')?.addEventListener('click', function () {
        document.getElementById('help-video').style.display = 'none';
      })
      document.getElementById('walkthrough-trigger').addEventListener('click', function () {

        if (addEle) {
          document.body.appendChild(mainEle);
          document.body.appendChild(mainEleNote);
        }
        count = 0;
        walkthroughScreen(walkthrough[count]);
      })
      const walkthrough = [
        {
          element: document.getElementById('d-1'),
          purpose: 'Enter your Business here'
        },
        {
          element: document.getElementById('d-2'),
          purpose: 'Enter your Trading Name'
        },
        {
          element: document.getElementById('d-3'),
          purpose: 'Enter your ABN/ACN'
        },
        {
          element: document.getElementById('d-4'),
          purpose: 'Enter Payment Terms'
        },
        {
          element: document.getElementById('d-5'),
          purpose: 'Enter Client Type'
        },
        {
          element: document.getElementById('d-6'),
          purpose: 'Enter your Email'
        },
        {
          element: document.getElementById('d-7'),
          purpose: 'Enter your Email'
        },
        {
          element: document.getElementById('d-8'),
          purpose: 'Enter your Email'
        },
        {
          element: document.getElementById('d-9'),
          purpose: 'Enter your Email'
        }
      ]

      let mainEle = document.getElementById('walkthrough-ele');
      let mainEleNote = document.getElementById('walkthrough-ele-note');
      let addEle = false;
      let count = 0;
      if (!mainEle) {
        mainEle = document.createElement('div');
        mainEle.id = 'walkthrough-ele';
        mainEleNote = document.createElement('div');
        let nextElement = document.createElement('div');
        let closeElement = document.createElement('div');
        let imgElement = document.createElement('img');
        imgElement.src = 'thinking.png';
        mainEleNote.id = 'walkthrough-ele-note';
        nextElement.id = 'nextWalkthrough';
        nextElement.innerHTML = 'Next';
        closeElement.id = 'closeWalkthrough';
        closeElement.innerHTML = 'x';
        mainEleNote.appendChild(nextElement);
        mainEleNote.appendChild(closeElement);
        mainEleNote.appendChild(imgElement);
        addEle = true;
        closeElement.addEventListener('click', function () {
          closeWalkthrough();
        });
        nextElement.addEventListener('click', function () {
          document.getElementById('wlk-info')?.remove();
          if (count < walkthrough.length - 1) {
            count++;
            walkthroughScreen(walkthrough[count]);
            if (count === walkthrough.length - 1) {
              nextElement.innerHTML = 'Close';
            }
          } else {
            closeWalkthrough();
          }
          if (count > 0) {
            enableBack();
          }
        });
      }
      function enableBack() {
        if (!document.getElementById('backWalkthrough')) {
          let backElement = document.createElement('div');
          backElement.id = 'backWalkthrough';
          backElement.innerHTML = 'Back';
          mainEleNote.appendChild(backElement);
          backElement.addEventListener('click', function () {
            document.getElementById('wlk-info')?.remove();
            if (count > 0) {
              count--;
              walkthroughScreen(walkthrough[count]);
              if (count === 0) {
                backElement.remove();
              }
            }
          });
        }
      }

      function walkthroughScreen(deet) {

        let o = deet;
        let x = o.element.offsetLeft;
        let y = o.element.offsetTop;
        let w = o.element.offsetWidth;
        let h = o.element.offsetHeight;
        //console.log('left-' + x);
        //console.log('top-' + y);
        //console.log('w-' + w);
        //console.log('h-' + h);
        mainEle.style.width = w + 'px';
        mainEle.style.height = h + 'px';
        mainEle.style.top = y + 'px';
        mainEle.style.left = x + 'px';
        mainEleNote.style.width = '500px';
        mainEleNote.style.height = '100px';
        mainEleNote.style.top = (y + h + 5) + 'px';
        mainEleNote.style.left = x + 'px';
        let ol = document.getElementById('wlk-info');
        if (ol) {
          ol.remove();
        }
        let l = document.createElement('div');
        l.id = 'wlk-info';
        l.innerHTML = o.purpose;
        mainEleNote.appendChild(l)
      }


      function closeWalkthrough() {
        mainEle.remove();
        mainEleNote.remove();
      }
    }, 1000);
  }


  deleteStaff(staff: Staff) {
    this.confirmationService.confirm({
        message: 'Are you sure you want to delete the selected Staff?',
        header: 'Confirm',
        icon: 'pi pi-exclamation-triangle',
        accept: async () => {
          staff.deleted = "1";
          let staffDt = await this.crudService.getDataByField('CompanyStaffInfo', 'id', staff.id).toPromise();
          let staffData  = staffDt[0];
          staffData.deleted = "1";
            this.crudService.Update(staffData, 'CompanyStaffInfo').subscribe(async (data: any) => {
                if (data.success === 1) {
                    this.staffs = this.staffs.filter(val => val.deleted != '1');
                    this.messageService.add({ severity: 'success', summary: 'Successful', detail: 'Staff Deleted', life: 1000 });
                } else {
                    this.messageService.add({ severity: 'error', summary: 'Error', detail: 'Staff Not Updated', life: 1000 });
                }
            });
        }
        });
}

  tagConfig;
  initTab() {
    const tabGeneral = document.getElementById('tab-Staff');
    const tabStaffCompliance = document.getElementById('tab-staff-compliance');

    let general = document.getElementsByClassName('staff-g-info-tab-activate');
    let staffcompliance = document.getElementsByClassName('staff-compliance-info-tab-activate');

    function initializeTabs(config) {

      let l1 = [tabGeneral, tabStaffCompliance];
      let l2 = [general, staffcompliance];

      config.forEach(c => {
        document.getElementById(c.id).addEventListener('click', () => {
          l1.forEach((ele) => {
            ele.classList.remove('active');
          });
          c.activeTab.classList.add('active');

          l2.forEach((ele) => {
            if (ele && ele.length > 0) {
              for (let i = 0; i < ele.length; i++) {
                ele[i].classList.add('hideme');
              }
            }
          });
          if (c.activeContent && c.activeContent.length > 0) {
            for (let i = 0; i < c.activeContent.length; i++) {
              c.activeContent[i].classList.remove('hideme');
            }
          }

        });
      })
    }
    this.tagConfig = [{
      id: 'tab-Staff',
      activeTab: tabGeneral,
      activeContent: general,
      general: true
    }, {
      id: 'tab-staff-compliance',
      activeTab: tabStaffCompliance,
      activeContent: staffcompliance
    }];
    initializeTabs(this.tagConfig);
  }

  updateTab(v) {
    // if(v==='tab-setting'){
    //   this.viewCompliance(this.staff);
    // }
  }

  async updateNotification(staff_id, category_id, code) {
    //console.log("staff_id", staff_id);
    //console.log("category_id", category_id);
    //console.log("code", code);
    let data = { staff_id: staff_id, category: category_id, wf_code: code };
    // let response = await this.commonservice.updateRecord('UserNotificationMatrix', data);
    // console.log("code:",response);
  }

  // deleteStaff(staff: Staff) {
  //   this.confirmationService.confirm({
  //     message: 'Are you sure you want to delete the selected staff',
  //     header: 'Confirm',
  //     icon: 'pi pi-exclamation-triangle',
  //     accept: () => {
  //       this.staffs = this.staffs.filter(val => val.id !== staff.id);
  //       this.staff = {};
  //       this.staff.deleted = '1';
  //       this.crudService.getDataByField('Users', 'staff_id', staff.id).subscribe((userInfo: any[]) => {
  //         this.crudService.Delete(userInfo[0].id, 'User').subscribe((data2: any[]) => { });
  //       });
  //       this.crudService.Update(this.staff, 'CompanyStaffInfo').subscribe((data: any) => {
  //         this.messageService.add({ severity: 'success', summary: 'Successful', detail: 'Staff Deleted', life: 1000 });
  //       });

  //     }

  //   });

  // }

  onRowSelect(event) {
    this.staff = event.data;
    this.staffDialog = true;
    this.mytable = false;
    // this.messageService.add({severity:'info', summary:'Product Selected', detail: event.data.name});
  }

  onRowUnselect(event) {
    this.messageService.add({ severity: 'info', summary: 'Product Unselected', detail: event.data.name });
  }

  hideDialog() {
    this.staffDialog = false;
    this.notificationDialog = false;
    this.mytable = true;
    this.ngOnInit();
  }

  saveRecord() {
 
    this.submitted = true;
    if (this.enableLogin) {
      this.staff.create_login = '2';
      if (this.staffLoginForm.status !== 'VALID') {
        this.formStatus = false;
      } else {
        this.formStatus = true;
      }
    } else {
      this.staff.create_login = '1';
      this.formStatus = true;
    }
    this.staff.InductionStatus = this.InductionStatus;
    this.formStatus = true;
    if (this.selectedUserRole !== null && this.selectedUserRole !== '') {
      this.formStatus = true;
    } else {
      this.formStatus = false;
    }
    if (this.isActive) {
      this.staff.deleted = null;
    } else {
      this.staff.deleted = null;
    }
    if (this.viewType === 'TP') {
      this.staff.company_id = this.companyId;
    } else {
      if (typeof this.selectedCompany !== 'undefined' && this.selectedCompany !== null) {
        if (this.selectedCompany.id !== '' && this.selectedCompany.id !== null) {
          this.staff.company_id = this.selectedCompany.id;
        }
      }
    }

    if (typeof this.staff.company_id === 'undefined') {
      this.formStatus = false;
    }
    if (this.staff.company_id === '' || this.staff.company_id === null) {
      this.formStatus = false;
    }
    if (this.staff.last_name === '' || this.staff.last_name === null) {
      this.formStatus = false;
    }


    this.staff.user_role_id = null;
    this.staff.role = null;
    this.staff.stafftype = null;

    if (typeof this.selectedUserRole !== 'undefined' && this.selectedUserRole !== null) {
      if (this.selectedUserRole.id !== '' && this.selectedUserRole.id !== null) {
        this.staff.user_role_id = this.selectedUserRole.id;
      }
    }
    if (typeof this.selectedStaffRole !== 'undefined' && this.selectedStaffRole !== null) {
      if (this.selectedStaffRole.id !== '' && this.selectedStaffRole.id !== null) {
        this.staff.role = this.selectedStaffRole.id;
      }
    }
    if (typeof this.selectedStaffType !== 'undefined' && this.selectedStaffType !== null) {
      if (this.selectedStaffType.id !== '' && this.selectedStaffType.id !== null) {
        this.staff.stafftype = this.selectedStaffType.id;
      }
    }
    if (this.formStatus) {
      this.callAPI()
    } else {
      if (!this.enableLogin) {
        this.confirmationService.confirm({
          message: 'Are you sure you want to save record?',
          header: 'Confirm',
          icon: 'pi pi-exclamation-triangle',
          accept: () => {
            //console.log(this.staff.first_name)
            if (this.staff.first_name !== null && this.staff.first_name !== '' && this.staff.last_name !== undefined && this.staff.last_name !== '' && this.staff.last_name !== null && this.staff.last_name !== undefined && this.selectedStaffRole != null) {
              this.staff.first_name = this.staff.first_name.charAt(0).toUpperCase() + this.staff.first_name.substr(1).toLowerCase()
              this.staff.last_name = this.staff.last_name.charAt(0).toUpperCase() + this.staff.last_name.substr(1).toLowerCase()
              this.callAPI();
            } else {
              //console.log('**************')
              this.messageService.add({ severity: 'error', summary: 'Error', detail: 'Invalid Details Entered. First Name,Last Name & Staff Role are Mandatory', life: 1000 });
            }
          }
        });
      } else {
        if (this.enableLogin) {
          if (this.selectedUserRole === null || this.selectedUserRole === '') {
            this.confirmationService.confirm({
              message: 'You haven\'t selected role, Are you sure you want to save record?',
              header: 'Confirm',
              icon: 'pi pi-exclamation-triangle',
              accept: () => {
                //console.log(this.staff.first_name)
                this.callAPI();
              }
            });
          }
        }
      }
    }
  }

  callAPI() {
    if (this.staff.id) {
      this.staff.last_modified_at = new Date().toDateString();
      this.staff.last_modified_by = this.userDetails?.id;
      this.staff.password = this.staff.password;
      this.encryptedPass = this.encryptService.encryptionAES(this.staff.password);
      this.staff.deleted = null;
      this.staff.encrypted_password = this.encryptService.encryptionAES(this.staff.password);
     // this.staff.first_name = this.staff.first_name.charAt(0).toUpperCase() + this.staff.first_name.substr(1).toLowerCase();
    //  this.staff.last_name = this.staff.last_name.charAt(0).toUpperCase() + this.staff.last_name.substr(1).toLowerCase();
      this.crudService.Update(this.staff, 'CompanyStaffInfo').subscribe((data: any) => {
        if (data.success === 1) {
          this.staffDialog = false;
          this.staffs[this.findIndexById(this.staff.id)] = this.staff;
          this.messageService.add({ severity: 'success', summary: 'Successful', detail: 'Staff Updated', life: 1000 });
          this.saveUserInfo();
        } else {
          this.staffDialog = true;
          this.mytable = false;
          this.messageService.add({ severity: 'error', summary: 'Error', detail: 'Staff Not Updated', life: 1000 });
        }
      });
    } else {
      this.staff.created_at = new Date().toDateString();
      this.staff.created_by = this.userDetails?.id;
      this.staff.password = this.staff.password;
      this.encryptedPass = this.encryptService.encryptionAES(this.staff.password);
      this.staff.deleted = null;
      this.staff.encrypted_password = this.encryptService.encryptionAES(this.staff.password);
      //this.staff.first_name = this.staff.first_name.charAt(0).toUpperCase() + this.staff.first_name.substr(1).toLowerCase();
      //this.staff.last_name = this.staff.last_name.charAt(0).toUpperCase() + this.staff.last_name.substr(1).toLowerCase();
      this.crudService.Create(this.staff, 'CompanyStaffInfo').subscribe((data: any) => {
        //console.log(data);
        if (data.success === 1) {
          this.staff.id = data.id;
          this.staffs = [...this.staffs, this.staff];
          this.saveUserInfo();
          this.staffDialog = true;
          this.mytable = false;
          this.messageService.add({ severity: 'success', summary: 'Successful', detail: 'Staff Created', life: 1000 });
        } else {
          this.staffDialog = true;
          this.mytable = false;
          this.messageService.add({ severity: 'error', summary: 'Error', detail: 'Staff Not Created', life: 1000 });
        }
      });
    }
  }
  saveUserInfo() {
    debugger
    if (this.enableLogin && !this.userExists) {
      this.user = {};
      this.user.dob = null;
      this.user.person_id = null;
      this.user.status = '1';
      this.user.owner = '1';
      this.user.created_at = new Date();
      this.user.position_id = null;
      this.user.invalid_passwd_count = null;
      this.user.title = null;
      this.user.mobile = this.staff.mobile;
      this.user.alternate_phone = this.staff.phone;
      this.user.work_phone = this.staff.phone;
      this.user.user_locked = '0';
      this.user.user_group_id = 1;
      if (typeof this.user.created_at === null) {
        this.user.created_at = new Date().toDateString();
      }
      if (this.user.menu_colour === '' || this.user.menu_colour == null) {
        this.user.menu_colour = 'layout-menu-dark';
      }
      if (this.user.layout_colour === '' || this.user.layout_colour == null) {
        this.user.layout_colour = 'blue';
      }
      if (this.user.colour_scheme === '' || this.user.colour_scheme == null) {
        this.user.colour_scheme = 'light';
      }
      if (this.user.colour === '' || this.user.colour == null) {
        this.user.colour = 'green';
      }
      if (this.user.menu_type === '' || this.user.menu_type == null) {
        this.user.menu_type = '1';
      }
      if (this.user.homepage_url === '' || this.user.homepage_url == null) {
        this.user.homepage_url = '/home';
      }
      if (this.selectedUserRole?.id !== null && this.selectedUserRole?.id !== '') {
        this.user.user_group_id = this.selectedUserRole?.id;
      } else {
        this.user.user_group_id = null;
      }
      if (this.user.homepage_url === '' || this.user.homepage_url == null) {
          this.user.homepage_url = '/home';
        }
      this.user.username = this.staff.username;
      this.user.password = this.staff.password;
      this.user.encrypted_password = this.encryptedPass;
      this.user.company_id = this.staff.company_id;
      this.user.first_name = this.staff.first_name;
      this.user.last_name = this.staff.last_name;
      this.user.email = this.staff.email;
      this.user.staff_id = this.staff.id;
      this.user.login_attempt = '0';
      this.user.process_id = this.staff.company_id;
      this.user.image_name = null;
      this.user.description = null;
      this.user.menu_colour = 'layout-menu-dark';
      this.user.layout_colour = 'blue';
      this.user.colour_scheme = 'light';
      this.user.colour = 'green';
      this.user.menu_type = '1';
      this.user.homepage_url = '/home';
      this.user.created_at = new Date().toDateString();
      this.user.created_by = this.userDetails?.id;
      this.user.last_updated_at = new Date().toDateString();
      this.user.last_modified_by = null;
      this.user.username = this.staff.username;
        this.user.password = this.staff.password;
        this.user.encrypted_password = this.encryptedPass;
        this.user.deleted = null;
      this.crudService.Create(this.user, 'User').subscribe((data: any) => {
        //console.log(data);
        this.updateMenuRoles();
        this.editSelectedStaff(this.staff);
      });
    } else if (this.userExists) {
      if (this.selectedUserRole.id !== null && this.selectedUserRole.id !== '') {
        this.user.user_group_id = this.selectedUserRole.id;
      } else {
        this.user.user_group_id = null;
      }
      let changed = false;
      if (this.staff.username !== this.user.username) {
        changed = true;
      }

      if ((this.enableLogin) && changed) {
        this.user.username = this.staff.username;
        this.user.password = this.staff.password;
        this.user.encrypted_password = this.encryptedPass;
        this.user.status = '1';
        this.user.user_locked = 0;
      } else if (!this.enableLogin) {
        this.user.status = '2';
        this.user.user_locked = 0;
        this.user.username = this.staff.username;
        this.user.password = this.staff.password;
        this.user.encrypted_password = this.encryptedPass;
      } else if (this.enableLogin && this.user.status === '2') {
        this.user.username = this.staff.username;
        this.user.password = this.staff.password;
        this.user.encrypted_password = this.encryptedPass;
        this.user.user_locked = 0;
        this.user.status = '1';
      }
        this.user.username = this.staff.username;
        this.user.password = this.staff.password;
        this.user.encrypted_password = this.encryptedPass;
      this.user.last_modified_at = new Date().toDateString();
      this.user.last_modified_by = this.userDetails?.id;
      this.user.deleted = null;
      console.log("this.user",this.user);
      this.crudService.Update(this.user, 'User').subscribe((data: any) => {
        //console.log(data);
        this.updateMenuRoles();
        this.editSelectedStaff(this.staff);
      });
    } else {
      this.editSelectedStaff(this.staff);
    }
  }

  findIndexById(id: string): number {
    let index = -1;
    for (let i = 0; i < this.staffs.length; i++) {
      if (this.staffs[i].id === id) {
        index = i;
        break;
      }
    }

    return index;
  }

  exportPdf() {


    import('jspdf').then(jsPDF => {
      import('jspdf-autotable').then(x => {
        /* const doc = new jsPDF.default(0,0);
        doc.autoTable(this.exportColumns, this.staffs);
        doc.save('products.pdf'); */
      })
    })
  }

  exportExcel(selectedStaffs, table) {
    let JsonData: any[] = [];
    if (this.selectedStaffs != null && this.selectedStaffs.length > 0) {
      //console.log("this is working");
      JsonData = this.selectedStaffs;
    } else {
      //console.log("this is else working", table.filteredValue);

      if (typeof table.filteredValue !== 'undefined') {
        if (table.filteredValue.length !== this.staffs.length && table.filteredValue.length > 0) {
          JsonData = table.filteredValue;
        } else {
          JsonData = this.staffs;
        }
      } else {
        JsonData = this.staffs;
      }
    }


    let reformattedArray = JsonData.map(obj => {

      let rObj = {};
      this.cols.forEach(pair => {
        rObj[pair.header.toString()] = obj[pair.field];
      });
      return rObj;
    })
    //console.log(reformattedArray);

    import('xlsx').then(xlsx => {
      const worksheet = xlsx.utils.json_to_sheet(reformattedArray);
      const workbook = { Sheets: { data: worksheet }, SheetNames: ['data'] };
      const excelBuffer: any = xlsx.write(workbook, { bookType: 'xlsx', type: 'array' });
      this.saveAsExcelFile(excelBuffer, 'staffs');
    });
  }

  saveAsExcelFile(buffer: any, fileName: string): void {
    import('file-saver').then(FileSaver => {
      const EXCEL_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
      const EXCEL_EXTENSION = '.xlsx';
      const data: Blob = new Blob([buffer], {
        type: EXCEL_TYPE
      });
      FileSaver.saveAs(data, fileName + '_export_' + new Date().getTime() + EXCEL_EXTENSION);
    });
  }

  clear(table: Table) {
    table.clear();
    this.searchvalue = "";
  }

  onSortChange(event) {
    const value = event.value;

    if (value.indexOf('!') === 0) {
      this.sortOrder = -1;
      this.sortField = value.substring(1, value.length);
    }
    else {
      this.sortOrder = 1;
      this.sortField = value;
    }
  }

  viewCompliance(staff: Staff) {
    this.staff = { ...staff }
    this.staffId = this.staff.id;
    //console.log('Staff ID : ' + this.staffId);
    const ref = this.dialogService.open(ComplianceComponent, {
      data: {
        staffId: this.staffId,
        company_id: this.staff.company_id,
        type: 'staff',
        allowCreate: 'true'
      },
      header: 'Staff Compliance - ' + staff.first_name,
      width: '100%',
      height: '100%'
    });
  }

  updateMenuRoles() {
    this.crudService.updateMenuRoles(this.user.user_group_id, this.staff.company_id).subscribe((data: any) => {
      //console.log(data);
    });
  }

  async loadTrainingInfo() {
    this.loading = true;
    // this.deviceInfo = this.deviceDetectorService.getDeviceInfo();
    // console.log(this.deviceInfo);
    this.userDetails = JSON.parse(sessionStorage.getItem('loggedInUser'));
    await this.loadComplianceInfo();
    this.getLocation();
    //this.signaturePad.set('minWidth', 5);
    this.deviceInfo = this.deviceService.getDeviceInfo();
    this.getIP();
    this.loading = false;
    this.signaturePad.clear();
  }


  getIP() {
    this.crudService.getIpAddress().subscribe((res: any) => {
      this.ipAddress = res.ip;
    });
  }

  getLocation() {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition((position) => {
        if (position) {
          this.lat = position.coords.latitude;
          this.lng = position.coords.longitude;
          //console.log(this.lat);
          //console.log(this.lng);
        }
      });
    }
  }

  async loadComplianceInfo() {
    let data: any = await this.crudService.getDataByField('CompanyCompliance', 'staff_id', this.staff.id).toPromise();
    let slaData = data.filter(val => (val.compliance_id == this.inputCompanyId));
    if (slaData.length > 0) {
      this.companyCompliance = slaData[0];
      this.signaturePath = 'C://uploadImgs//SLA//' + this.companyId + '//' + this.staff.id + '.png';
      this.acceptedDatetime = this.companyCompliance.created_at;
      this.isAccepted = true;
      this.docPdfUrl = null;
      this.displayPdf = true;
      this.signedBy = this.companyCompliance.comment;
      this.crudService.fetchStaffComplianceFile(this.companyId, this.staff.id, this.companyCompliance.file_path).subscribe(blob => {
        const objectUrl = URL.createObjectURL(blob);
        this.docPdfUrl = this.sanitizer.bypassSecurityTrustResourceUrl(objectUrl);

      })
    } else {
      this.signaturePath = null;
      this.acceptedDatetime = null;
      this.isAccepted = false;
      this.displayPdf = false;
      this.crudService.fetchComplianceFile('1', '1/compliance_docs/EFI.pdf', 'EFI.pdf').subscribe(blob => {
        const objectUrl = URL.createObjectURL(blob);
        this.docPdfUrl = this.sanitizer.bypassSecurityTrustResourceUrl(objectUrl);
      })
      this.signedBy = this.staff.first_name + ' ' + this.staff.last_name;
      //this.signaturePad.clear();
      //this.pdfUrl="http://docs.google.com/gview?url="+this.crudService.getAPIRoot() + "/policy_documents/SLA - Template 2021.docx&embedded=true";
      //this.pdfUrl = this.sanitizer.bypassSecurityTrustResourceUrl(this.crudService.getAPIRoot() + '/policy_documents/SLA - Template 2021.docx');
      //  this.pdfUrl = this.crudService.getAPIRoot() + '/policy_documents/SLA - Template 2021.docx';
    }
  }

  drawComplete() {
    this.isSigned = true;
  }
  onEdit() {
    window.scrollTo(0, 0);
  }


  async saveSLACompliance(filePath, fileName) {
    let expiryDate = new Date(new Date().getTime() + (1000 * 60 * 60 * 24 * 365 * 2));

    let company_compliance = {
      amount: null,
      comment: this.signedBy,
      company_id: this.companyId,
      staff_id: this.staff.id,
      compliance_id: this.inputCompanyId,
      compliance_type_id: null,
      created_at: new Date(),
      created_by: '1',
      expiry_date: expiryDate,
      file_path: filePath,
      filename: fileName,
      id: null,
      issued_by: 'N/A',
      issued_date: new Date(),
      last_modified_by: null,
      last_reminder_date: null,
      last_updated_at: null,
      owner: null,
      policy_no: 'N/A',
      reminder_count: null,
      reminder_status: null,
      status: null,
      exists: false,
      expired: false,
      expiry_date_str: null,
      issued_date_str: null,
      s3_url: null,
      sync_status: 0
    };
    console.log("CompanyCompliance",company_compliance);
    let res: any = await this.CommonService.updateRecord('CompanyCompliance', company_compliance);
    console.log("Res",res);
    this.crudService.fetchComplianceFile(this.companyId, company_compliance.file_path, company_compliance.filename).subscribe(blob => {
      const objectUrl = URL.createObjectURL(blob);
      this.docPdfUrl = this.sanitizer.bypassSecurityTrustResourceUrl(objectUrl);
      this.displayPdf = true;
    })
  }

  async acceptTerms() {
    this.loading = true;
    console.log("this.signaturePad",this.signaturePad);
    debugger
    let resp = this.crudService.uploadImage(this.signaturePad.toDataURL(), 'SLA', this.companyId, this.staff.id, 'LOCAL');
    console.log("this.signaturePad",resp);
    debugger
    let response = JSON.parse(resp)
    let reportId = 'EFI';
    let date = new Date();
    this.acceptedDatetime = date;
    let tempTime = date.getTime();
    let fileDesc = 'EFI';
    let data: any = await this.loadData();
    const fileName = fileDesc + '-' + tempTime + '.pdf';;
    const filePath = 'C:/companyCompliances/' + this.companyId + '/compliance_docs/staff/' + this.staff.id + '/' + fileName;
    data[0].newReportData.filename = fileName;
    data[0].newReportData.filepath = filePath;
console.log("dataaaaaaaaaa",data);
debugger
    let res = await this.crudService.generateEFIReport(this.companyId, this.staff.id, data, reportId, tempTime, 1).toPromise();
    this.saveSLACompliance(this.companyId + '/compliance_docs/staff/' + this.staff.id + '/' + fileName, fileName);
    //this.companyId + '/' + 'report-template-' + reportId + '-' + tempTime + '.pdf';
    let blob = new Blob([res], { type: "application/pdf" });
    const objectUrl = URL.createObjectURL(blob);
    this.docPdfUrl = this.sanitizer.bypassSecurityTrustResourceUrl(objectUrl);
    this.InductionStatus = 'Yes';
    this.eventsSubject.next();
    this.displayPdf = true;
    document.getElementById("sigContainer").style.display = "none";
    this.messageService.add({ severity: 'success', summary: 'Successful', detail: 'EF Induction Completed', life: 1000 });
     this.loading = false;
  }

  async loadData() {
    let companyInfo = await this.crudService.getDataByField('Company', 'id', this.companyId).toPromise();
    companyInfo[0].fullname = this.staff.first_name + ' ' + this.staff.last_name;
    let date =new Date()
    companyInfo[0].date = this.datePipe.transform(date,'dd/MM/yyyy hh:mm a');
    let reportData = {
      TEMPLATE_ID: 'C://Source//Report_Templates//EFI.docx',
      ImageURL: 'C://uploadImgs//SLA//' + this.companyId + '//' + this.staff.id + '.png',
      CompanyDetails: companyInfo[0]
    };
    let res: any;
    let data = [
      {
        info: {
          id: '67993bdd-f7d9-48a6-93d9-8026b657041a',
          CompanyID: this.companyId,
          reportid: 'EFI'
        },
        newReportData: reportData,
      }
    ];
    return data;



  }
  fileLoaded() {
    this.loading = false;
    //console.log('file Loaded');
  }
  toTop() {
    window.scrollTo(0,0);
    this.isSigned = false;
    this.isChecked = false;
  }
  
  loadStaffInfo(){
    this.crudService.getStaffByCID(this.companyId).subscribe((data: any[]) => {
      let staffInfo = data.find(val=> (val.id = this.staff.id));
      this.staff.InductionStatus = staffInfo.InductionStatus;
      let index = this.staffs.findIndex(val=> (val.id = this.staff.id));
      this.staffs[index].InductionStatus = this.staff.InductionStatus;
      this.InductionStatus = this.staff.InductionStatus;
      if(this.InductionStatus=='No'){
        this.showEfstart = true;
        this.signaturePath = null;
        this.acceptedDatetime = null;
        this.isAccepted = false;
        this.displayPdf = false;
        this.showEf = true;
        document.getElementById("sigContainer").style.display = "block";
      }

    });
  }
  async sendMAil(){
    let encrypted = this.encodeDecode.encrypt(  this.staff.company_id +' '+this.staff.id +' '+this.sendlinkvia ,'123456$#@$^@1ERF',);
    this.emailSubject = 'EF-Induction Access URL'
    this.ef_access_Url = this.env.base_url+'business/EFinduction/'+ encrypted 
      await this.tinyUrl.shorten(this.ef_access_Url).subscribe(async res => {
      this.ef_access_Url = res;
      if(res){
        this.emailContent = 'Hi ' + this.staff.first_name?.trim() + ' , <br/><br/>Please sign EF-induction by clicking the below link<br/> '+ '<body> <a href="'+this.ef_access_Url +'">' + this.ef_access_Url + '</a></body><br/>'
        let temp_type = 'sms-email';
        let smsData  = ''
        this.templ = {};
        this.templ.template = [{ email: {} }];
        this.templ.template[0].email= this.staff.email
        
        let dataObj = {
          sms: smsData,
          email: this.staff.email,
          type: temp_type,
          selectedTpl: 'd-fa99d348216144cfb8d39f2a67111474',
          sufix: null,
          jobData: null,
          attachments: null,
          account: this.env.hosting,
        }
        await this.storeInSchedule(dataObj);
      }
      console.log( this.ef_access_Url); 
    });
  }
  
  
  storeInSchedule(obj) {
    const datobj2 = {
      subject : this. emailSubject,
      body : this.emailContent,
      toEmail : obj.email,
      ref_id:this.staff.id,
      ref_type:'accounts'
      }
      if(this.sendlinkvia == false){
        this.crudService.sendmailSendgrid(datobj2,'commonMail').subscribe((res2: any) => { 
          this.loading = false;
          if(res2.success == 200)
          {
            this.messageService.add({ severity: 'success', summary: 'Successful', detail: 'Email Sent', life: 3000 });
          }
          else
          {
            console.log("res2.Data.body.errors[0].message",res2);
            let message = res2.Data.response.body.errors[0].message;
            this.messageService.add({ severity: 'error', summary: 'Error', detail: message, life: 3000 });
          }

         });
      }
      else if(this.sendlinkvia == true){
        let params = {
           sms: {
            to: this.staff.mobile,
            message: 'Hi '+ this.staff.first_name?.trim() + ', Please sign EF-induction by clicking ' + this.ef_access_Url ,
           subject: this. emailSubject,
          },   type: 'sms-email',
          selectedTpl: null,
          sufix: null,
        }
        this.crudService.sendTMV2Message(params).subscribe((res2: any) => {
            this.visible = false;
            this.messageService.add({ severity: 'success', summary: 'Successful', detail: 'SMS Sent ', life: 1000 });
        });
      }
   
  }
  
  showDialog() {
    this.visible = true;
     this.sendlinkvia = undefined
    this.btnvisible =false
  }
  changeVia(event,via){
    console.log(via);
    this.btnvisible =true
  }

  async sendLink(via,email,mobile){
        this.sendMAil()  
        let otp_datas:any  = await this.crudService.getDataByField('StaffOTPTransact', 'staff_id', this.staff.id).toPromise();
        console.log("otp_datas",otp_datas);
        let filtered_otp = otp_datas.filter(val => (val.status == 0 && val.page == 0));
        filtered_otp?.forEach( val => {
          val.status= 2
          let ress: any =  this.CommonService.saveRecord('StaffOTPTransact', val);

        })

      var random_OTP : any = Math.floor(100000 + Math.random() * 900000);
      random_OTP = random_OTP.toString().substring(0, 4);
      random_OTP =  parseInt(random_OTP);
      console.log("random_OTP",random_OTP);
      let otp_details ={
        id : null,
        company_id : this.companyId,
        staff_id : this.staff.id,
        otp :random_OTP,
        status  :0,
        email_to :null,
        phone_to  :null,
        created_at  :  new Date(), 
        page: 0, // page = 0 for Ef-induction
        created_by : this.staff.id,
      }
      if(this.sendlinkvia == false){
        otp_details.email_to = email
      }
      else{
        otp_details.phone_to = mobile
      }
      let res: any = await this.CommonService.saveRecord('StaffOTPTransact', otp_details);
      console.log("Res",res);

      let params = {
        email: {
          to: email, cc: '', bcc: null,
          msg: 'Your OTP (One Time Password) for access ef-induction is ' + '<h4>'+random_OTP+'</h4>'
          + 'Please use this password to access and sign the document. Do not share this password with anyone',
          subject: 'Verification Code To Access EF-Induction ',
        },    type: 'sms-email',
        selectedTpl: null,
        sufix: null,
      }
      if(this.sendlinkvia == false){
        this.sendOTPMail(params);
      }
      else if(this.sendlinkvia == true){
        let params = {
          sms: {
            to: this.staff.mobile,
            message: 'Your OTP (One Time Password) for access ef-induction is '+ random_OTP
            + '. Please use this password to access and sign the document. Do not share this password with anyone',
          subject:'Verification Code To Access EF-Induction',
          },   type: 'sms-email',
          selectedTpl: null,
          sufix: null,
        }

        this.crudService.sendTMV2Message(params).subscribe((res2: any[]) => {
          console.log(res2);
            this.visible = false;
            this.messageService.add({ severity: 'success', summary: 'Successful', detail: 'OTP SMS Sent ', life: 1000 });
        });
        }
      }
  sendOTPMail(obj) {
    const datobj2 = {
      subject :obj.email.subject,
      body : obj.email.msg,
      toEmail : obj.email.to,
      ref_id:this.staff.id,
      ref_type:'accounts'
      }
      console.log("datobj2",datobj2)
      this.crudService.sendmailSendgrid(datobj2,'commonMail').subscribe((res2: any) => { 
      this.loading = false;
      if(res2.success == 200)
      {
        this.visible = false;
        this.messageService.add({ severity: 'success', summary: 'Successful', detail: 'OTP Email Sent', life: 3000 });
      }
      else
      {
        console.log("res2.Data.body.errors[0].message",res2);
        let message = res2.Data.response.body.errors[0].message;
        this.messageService.add({ severity: 'error', summary: 'Error', detail: message, life: 3000 });
      }
     });
  }

  
}