import { Component, OnInit } from '@angular/core';
import { ConfirmationService, MessageService } from 'primeng/api';
import { AppMainComponent } from 'src/app/app.main.component';
import { CRUDService } from 'src/app/utilities/crud.service';
import {tpCompliance} from './tp-compliance'
@Component({
  selector: 'app-tp-compliance',
  templateUrl: './tp-compliance.component.html',
  styleUrls: ['./tp-compliance.component.css']
})
export class TpComplianceComponent implements OnInit {
  id:any;
  tpComplianceList:tpCompliance[];
  selectedCompliance:tpCompliance;
  company_id: any;

  constructor(private crudService: CRUDService,
     private messageService: MessageService,
    private confirmationService: ConfirmationService,
    public app: AppMainComponent) { }

  ngOnInit() {
    //console.log(this.app.userSettings.id);
    this.id=this.app.userSettings.id;
    /* this.crudService.getAllTP(this.id).subscribe((data:any)=>{
      //console.log(data);
      this.tpComplianceList=data;
      this.company_id=this.tpComplianceList[0].id;
    }) */
  }
  onChangeCompliance(event){
    this.company_id=null;
    //console.log(event.value.id)
    this.company_id=event.value.id;
  }

}
