import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { AdminRoutingModule } from './admin-routing.module';
import { DepotsComponent } from './depots/depots.component';
import { StaffsComponent } from './staffs/staffs.component';
import { CompaniesComponent } from './companies/companies.component';
import { ContractorsComponent } from './contractors/contractors.component';
import { ClientsComponent } from './clients/clients.component';
import { ValuesetComponent } from './valueset/valueset.component';
import { ComplianceComponent } from './compliance/compliance.component';
import { CompanyNotesComponent } from './company-notes/company-notes.component'
import { ContactComponent } from './contact/contact.component';
import { AutoCompleteModule } from 'primeng/autocomplete';
import { ToggleButtonModule } from 'primeng/togglebutton';
import { TableModule } from 'primeng/table';
import { ButtonModule } from 'primeng/button';
import { InputMaskModule } from 'primeng/inputmask';
import { InputNumberModule } from 'primeng/inputnumber';
import { InputSwitchModule } from 'primeng/inputswitch';
import { MultiSelectModule } from 'primeng/multiselect';
import { InputTextModule } from 'primeng/inputtext';
import { InputTextareaModule } from 'primeng/inputtextarea';
import { ConfirmDialogModule } from 'primeng/confirmdialog';
import { DialogModule } from 'primeng/dialog';
import { DynamicDialogRef } from 'primeng/dynamicdialog';
import { DynamicDialogConfig } from 'primeng/dynamicdialog';
import { DropdownModule } from 'primeng/dropdown';
import { FieldsetModule } from 'primeng/fieldset';
import { CheckboxModule } from 'primeng/checkbox';
import { ToastModule } from 'primeng/toast';
import { GooglePlaceModule } from "ngx-google-places-autocomplete";
import { TooltipModule } from 'primeng/tooltip';
import { AccordionModule } from 'primeng/accordion';
import { GMapModule } from 'primeng/gmap';
import { StrengthMeterModule } from "ngx-strength-meter";
import { TabMenuModule } from 'primeng/tabmenu';
import { TabViewModule } from 'primeng/tabview';

import { SharedModule } from '../shared/shared.module';
import { JobModule } from '../job/job.module'
import { FileUploadModule } from 'ng2-file-upload';
import { CalendarModule } from 'primeng/calendar';
import { ComponentDefnHeadComponent } from './component-defn-head/component-defn-head.component'; 
import { ComponentDefnDetailComponent } from './component-defn-detail/component-defn-detail.component';
import { AppconfigComponent } from './appconfig/appconfig.component'; 
import { ReportMasterComponent } from './report-master/report-master.component'; 
import { MenuComponent } from './menu/menu.component';
import { MenuPermissionsComponent } from './menu-permissions/menu-permissions.component';
import { RegionComponent } from './region/region.component';
import { AppMasterComponent } from './master/master.component';
import { RadioButtonModule } from 'primeng/radiobutton';
import { SelectButtonModule } from 'primeng/selectbutton';
import {SlideMenuModule} from 'primeng/slidemenu';

import { FormbuilderModule } from '../formbuilder/formbuilder.module';

import { AppconfigurationComponent } from './appconfiguration/appconfiguration.component';
import { MasterChildComponent } from './master-child/master-child.component';
import { DefnChildComponent } from './defn-child/defn-child.component';
import { TpJobModule } from '../tp-job/tp-job.module';
import { DynamicformComponent } from './dynamicform/dynamicform.component';
import { FormBuilderComponent } from './form-builder/form-builder.component';
import { NgDragDropModule } from 'ng-drag-drop';
import { DefnHooksComponent } from './defn-hooks/defn-hooks.component';
import { DragDropModule } from 'primeng/dragdrop';
import { AutoApprovalComponent } from './auto-approval/auto-approval.component';
import { StateComponent } from '../modules/state/state.component';
import { AuditReportComponent } from './audit-report/audit-report.component';
import { ViewAuditComponent } from './view-audit/view-audit.component';
import { SplitButtonModule } from 'primeng/splitbutton';
import { ViewAuditChildComponent } from './view-audit-child/view-audit-child.component';
import { AuditlogMasterComponent } from './auditlog-master/auditlog-master.component';



@NgModule({
  declarations: [
    RegionComponent, DepotsComponent, StaffsComponent, MenuComponent, MenuPermissionsComponent,StateComponent,
    CompaniesComponent, ContractorsComponent, ClientsComponent, ValuesetComponent,
    ComplianceComponent, CompanyNotesComponent, ContactComponent,AppconfigComponent,ReportMasterComponent,ComponentDefnHeadComponent, 
    ComponentDefnDetailComponent, AppMasterComponent, AppconfigurationComponent, MasterChildComponent, DefnChildComponent, DynamicformComponent, FormBuilderComponent, DefnHooksComponent, AutoApprovalComponent, AuditReportComponent, ViewAuditComponent, ViewAuditChildComponent, AuditlogMasterComponent],
  imports: [
    AdminRoutingModule, CommonModule, FormsModule, ReactiveFormsModule, ConfirmDialogModule, DialogModule,
    TableModule, InputMaskModule, InputNumberModule, InputSwitchModule, MultiSelectModule, InputTextModule, InputTextareaModule,
    ButtonModule, DropdownModule, FieldsetModule, CheckboxModule, ToastModule, GooglePlaceModule, TooltipModule,
    AccordionModule, GMapModule, StrengthMeterModule, TabMenuModule, TabViewModule,SharedModule,JobModule,FileUploadModule,
    CalendarModule,ToggleButtonModule,FormbuilderModule, RadioButtonModule, SelectButtonModule, SlideMenuModule,
    TpJobModule, NgDragDropModule.forRoot(),DragDropModule,AutoCompleteModule,SplitButtonModule
  ],
  exports: [
    DepotsComponent, StaffsComponent, CompaniesComponent, ContractorsComponent, MenuPermissionsComponent, RegionComponent, GMapModule,
    ClientsComponent, ValuesetComponent, ComplianceComponent, CompanyNotesComponent, ContactComponent, DynamicformComponent],
  providers: [
    DynamicDialogRef, DynamicDialogConfig
  ],
  entryComponents:[MenuPermissionsComponent,DefnChildComponent,DynamicformComponent],
  schemas: [
    CUSTOM_ELEMENTS_SCHEMA
  ],
})
export class AdminModule { }