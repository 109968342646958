import { Component, OnInit } from '@angular/core';
import { CRUDService } from 'src/app/utilities/crud.service';
import { ConfirmationService } from 'primeng/api';
import { MessageService } from 'primeng/api';
import { Table } from 'primeng/table';

@Component({
  selector: 'app-email-config',
  templateUrl: './email-config.component.html',
  styleUrls: ['./email-config.component.scss']
})
export class EmailConfigComponent implements OnInit {

  cols: any[];
  exportColumns: any[];
  emailDialog: boolean;
  submitted: boolean;
  selectedEmail: any;
  selectedAssign: any;

  emailTemplate:any;
  assignList: any[];
  email: any;
  ccInpt: boolean;
  bccInpt: boolean;

  constructor(private crudService: CRUDService,
    private messageService: MessageService,
    private confirmationService: ConfirmationService ) { }

  ngOnInit(): void {

    this.assignList = [
      {id: '1', value: 'Job Scheduling'},
      {id: '2', value: 'Cost Form'},
      {id: '3', value: 'Job List'}
    ];

    this.cols = [
            { field: '', header: 'Template Name' },
            { field: '', header: 'Assign To' }
        ];

    this.exportColumns = this.cols.map(col => ({ title: col.header, dataKey: col.field }));
  }

  openNew(){
    this.emailTemplate = {
      template_name: null,
      assign_value: null,
      emailCC: null,
      emailBCC: null,
      subject: null,
      message_email: null,
      message_sms: null,
      sms: null,
      cc_template: null,
      bcc_template: null
    }
    this.emailDialog = true;
  }

  setCategory(value){
    if(value===1){
      this.ccInpt=true;
    }
    if(value===2){
      this.bccInpt=true;
    }
  }

  deleteSelectedTemplate(){

  }
  editEmail(email){

  }

  deleteEmail(email){

  }

  saveRecord(){

  }

    hideDialog() {
        this.emailDialog = false;
        this.submitted = false;
    }

    exportPdf() {
        import('jspdf').then(jsPDF => {
            import('jspdf-autotable').then(x => {
                /* const doc = new jsPDF.default(0,0);
                doc.autoTable(this.exportColumns, this.selectedDepots);
                doc.save('products.pdf'); */
            })
        })
    }

    exportExcel(selectedEmail) {
        import('xlsx').then(xlsx => {
            const worksheet = xlsx.utils.json_to_sheet(this.selectedEmail);
            const workbook = { Sheets: { data: worksheet }, SheetNames: ['data'] };
            const excelBuffer: any = xlsx.write(workbook, { bookType: 'xlsx', type: 'array' });
            this.saveAsExcelFile(excelBuffer, 'Email_Config');
        });
    }

    saveAsExcelFile(buffer: any, fileName: string): void {
        import('file-saver').then(FileSaver => {
            const EXCEL_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
            const EXCEL_EXTENSION = '.xlsx';
            const data: Blob = new Blob([buffer], {
                type: EXCEL_TYPE
            });
            FileSaver.saveAs(data, fileName + '_export_' + new Date().getTime() + EXCEL_EXTENSION);
        });
    }

    clear(table: Table) {
        table.clear();
    }

}
