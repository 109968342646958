import { Component, Inject, OnInit, forwardRef } from '@angular/core';
import { AppMainComponent } from './app.main.component';
import dayGridPlugin from '@fullcalendar/daygrid';
import timeGridPlugin from '@fullcalendar/timegrid';
import interactionPlugin, { Draggable } from '@fullcalendar/interaction';
import listPlugin from '@fullcalendar/list';
import { WorkFlowService } from 'src/app/utilities/workflow.service';
import { CRUDService } from 'src/app/utilities/crud.service';
import { CommonService } from 'src/app/utilities/common.service';
import { ConfirmationService, MessageService } from 'primeng/api';
import { DialogService, DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';

@Component({
	selector: 'app-rightpanel',
	templateUrl: './app.rightpanel.component.html',
	providers: [DialogService, MessageService, ConfirmationService]
})
export class AppRightPanelComponent implements OnInit {
	options: any;
	userDetails: any;
	companyId: any;
	toDoList: any[];
	taskList: any[] = [];
	events: any[];
	wf_code: any;
	appProps: any;

	constructor(public app: AppMainComponent,
		private wfService: WorkFlowService,
		private crudService: CRUDService,
		private messageService: MessageService,private commonservice: CommonService) {

		this.companyId = localStorage.getItem('company_id');
		this.wf_code='6';
		//console.log('test');
	}

	ngOnInit() {
		this.appProps = this.commonservice.getAppProperties();
		// this.wfService.fetchTasksListByCreater(this.wf_code, this.app.userSettings.id).subscribe((taskListData: any[]) => {
		// 	if (taskListData.length > 0) {

		// 		this.taskList = taskListData.filter((val: any) => (val.status !== '3' && val.status !== '5'));
		// 		this.taskList.sort(function (a, b) { return b.id - a.id });
		// 		this.wfService.fetchTasksCheckListByUser(this.wf_code, this.app.userSettings.id).subscribe((checkListData: any[]) => {
		// 			checkListData.forEach(item => {
		// 				if (item.checked == '1') {
		// 					item.isChecked = true;
		// 				} else {
		// 					item.isChecked = false;
		// 				}
		// 			});
		// 			this.taskList.forEach(task => {
		// 				let checks = checkListData.filter(val => val.task_id === task.id);
		// 				if (checks.length > 0) {
		// 					task.checkList = checks.filter(val => val.task_id === task.id);
		// 				} else {
		// 					checks = [];
		// 					task.checkList = checks;
		// 				}
		// 			});
		// 		});
		// 	} else {
		// 		let taskDetails = {
		// 			description: null,
		// 			category: 1,
		// 			created_at: new Date(),
		// 			created_by: this.app.userSettings.id,
		// 			end_dt: null,
		// 			id: null,
		// 			name: 'To Do',
		// 			next_followup: null,
		// 			notes: null,
		// 			priority: 'Normal',
		// 			process_id: this.companyId,
		// 			start_dt: null,
		// 			status: '2',
		// 			tags: null,
		// 			type: null,
		// 			updated_at: null,
		// 			updated_by: null,
		// 			wd_code: 0,
		// 			wf_code: this.wf_code,
		// 			checkList: []
		// 		}
		// 		//console.log(taskDetails);
		// 		this.crudService.Create(taskDetails, 'taskHead').subscribe((data: any) => {
		// 			if (data.success === 1) {
		// 				taskDetails.id = data.id;
		// 				this.taskList.push([...this.taskList, taskDetails]);
		// 			}
		// 		});
		// 	}
		// });
		this.options = {
			initialView: 'dayGridMonth',
			plugins: [dayGridPlugin, timeGridPlugin, interactionPlugin, listPlugin],
			nowIndicator: true,
			minTime: '09:00:00',
			maxTime: '19:00:00',
			dayHeaders: false,
			defaultView: 'dayGridMonth',
			header: {
				left: 'prev,today,next',
				center: 'title',
				right: 'dayGridMonth,timeGridWeek,timeGridDay,listMonth'
			},
			editable: true,
			nextDayThreshold: '09:00:00',
			dateClick: (e) => {
				this.dateClicked(e);
			},
			eventClick: (e) => {
				this.showSelectedEvent(e);
			},
			eventDrop: (e) => {
				alert('eventChanged : ');
				// this.eventChanged(e);
			},
		};
	}

	dateClicked(e) {

	}

	showSelectedEvent(e) {

	}

	eventChanged(e) {

	}

}