import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ConfirmationService, MessageService } from 'primeng/api';
import { CRUDService } from 'src/app/utilities/crud.service';
import { jobscheduleArray, Visit } from './job-details';
import { DynamicDialogRef, DialogService } from 'primeng/dynamicdialog';
import { JobScheduleComponent } from '../job-schedule/job-schedule.component';
import { JobCostingComponent } from '../job-costing/job-costing.component'
import { WpCostingComponent } from '../wp-costing/wp-costing.component';
import { TreeInfoComponent } from '../../modules/tree-info/tree-info.component';
import { JobDocumentsComponent } from '../job-documents/job-documents.component';
import { JobImagesComponent } from '../job-images/job-images.component';
import { ApproveQuoteComponent } from '../approve-quote/approve-quote.component';
import { RejectQuoteComponent } from '../reject-quote/reject-quote.component';
import { SendQuoteComponent } from '../send-quote/send-quote.component';
import { JOBService } from 'src/app/utilities/job.service';
import { SubContractAgreementCreateComponent } from '../sub-contract-agreement-create/sub-contract-agreement-create.component';
import { JobInvoiceSendComponent } from '../job-invoice-send/job-invoice-send.component';
import { ShfComponent } from '../shf/shf.component';
import { JobKpiComponent } from '../job-kpi/job-kpi.component';
import { JobAdminComponent } from '../job-admin/job-admin.component';
import { JobUserComponent } from '../../modules/job-user/job-user.component';
import { DomSanitizer } from '@angular/platform-browser';
import { delay } from 'rxjs/operators';
import { TaskDetailsComponent } from '../../modules/task-details/task-details.component';
import { JobDetailsApprovalsFlaggingComponent } from '../../modules/job-details-approvals-flagging/job-details-approvals-flagging.component';
import { JobDetailsInvoiceComponent } from '../../modules/job-details-invoice/job-details-invoice.component';
import { JobDetailsApprovalsComponent } from 'src/app/job/job-details-approvals/job-details-approvals.component';
import { environment } from 'src/environments/environment';
import { Dialog } from 'primeng/dialog';
import { log } from 'console';
import { S3Service } from 'src/app/utilities/s3.service';
import { NgZone } from '@angular/core';

@Component({
  selector: 'app-mail-document',
  templateUrl: './mail-document.component.html',
  styleUrls: ['./mail-document.component.scss'],
  providers: [DialogService, MessageService, ConfirmationService, JOBService,
    JobScheduleComponent, JobCostingComponent, WpCostingComponent, TreeInfoComponent,
    JobDocumentsComponent, JobImagesComponent, ApproveQuoteComponent, RejectQuoteComponent,
    SendQuoteComponent, SubContractAgreementCreateComponent, JobInvoiceSendComponent, ShfComponent, JobKpiComponent]
})
export class MailDocumentComponent implements OnInit {

  jobId: any;
  allocId: any;
  jobDetails: any[];
  jobInfo: any;
  title: any;
  date: any;
  address: any;
  communication: boolean = false;
  jobschedule: boolean = false;
  jobscheduleArray: jobscheduleArray;
  schedNote: any;
  costing: boolean = false;
  documents: boolean = false;
  jobSummary: any;
  companyId: any;
  userDetails: any;
  companyInfo: any;
  Date_str: any;
  menuData: any;
  viewType: any;
  docSummary: any[];
  pdfUrl: any;
  showPDF: Boolean = false;
  jobAllocationList: any[] = [];
  scaExists;
  kpiExists;
  SHFExists;
  selectedJob: any;
  jobSearchResults: any[];
  allJobsList: any[] = [];
  loading: Boolean = true;
  showAllocations: Boolean = false;
  showFlagsHistory: Boolean = false;
  displayConfirmation: Boolean = false;
  reportRes: any = {};
  showColours: Boolean = false;
  fullscreen: Boolean = false;
  allFlagInfo: any[];
  isFlagged: Boolean = false;
  TreeData: any
  costMatrix: any;
  deviation: any;
  includesStump: any;
  costFormHead: any;
  costEstimate: any;
  extrasAvailable: any;
  jobTrackerList: any;
  custCareExpList_324: any;
  is324Exempt: Boolean = false;
  totalTree : any;
  jobAllocationChecklist:any;
  jobFlags = [
    { name: 'red', code: 'firebrick' },
    { name: 'blue', code: 'darkcyan' },
    { name: 'yellow', code: 'darkgoldenrod' },
    { name: 'orange', code: 'darkorange' },
    { name: 'purple', code: 'purple' },
    { name: 'deeppink', code: 'deeppink' },
    { name: '', code: '' }];
  jobActivityLog = [{
    action_name: 'Ezhil',
    action_desc: 'Allocated to Shri tree services',
    action_datetime: new Date(),
    action_user: 'Shri',
    action_colour: '#fff',
    reaction_desc: 'Accepted by Shri tree services',
    reaction_datetime: new Date(),
    reaction_user: 'Shri Tree Services',
    reaction_colour: '#8bc34a'
  }, {
    action_name: 'Job Allocation',
    action_desc: 'Allocated to Shri tree services',
    action_datetime: new Date(),
    action_user: 'Shri',
    action_colour: '#fff',
    reaction_desc: 'Accepted by Shri tree services',
    reaction_datetime: new Date(),
    reaction_user: 'Shri Tree Services',
    reaction_colour: '#fff'
  }, {
    action_name: 'Job Allocation',
    action_desc: 'Allocated to Shri tree services',
    action_datetime: new Date(),
    action_user: 'Shri',
    action_colour: '#fff',
    reaction_desc: 'Accepted by Shri tree services',
    reaction_datetime: new Date(),
    reaction_user: 'Shri Tree Services',
    reaction_colour: '#ff9800'
  }]
  types = [
    { type: 'Before', id: '1' },
    { type: 'Hazard', id: '3' },
    { type: 'After', id: '2' }
  ];
  imageDocuments: any[];
  uploadVideos: any;
  accesstoken: any;
  display: boolean=false;
  PO_PDFurl: any;
  pdfView: boolean;
  imgreportPdfUrl: string;
  costingDetails: any;
  itemType: any;
  displayImage: boolean;
  imagename: any;
  totaldoc: any[];
  totalimg: any[];
  displayVideo : boolean = false;
  videoUrl : any
  currentUser:  any;
  loggedInUser : any;
  token: any;
  UserId: any;
  selectedCompany: any;
  companyToken: any;
  authtoken: any;
  videoUploadCheck: boolean;
  // userloggedin: boolean=false;


  constructor(
    private route: ActivatedRoute,
    private crudService: CRUDService,
    private messageService: MessageService,
    private confirmationService: ConfirmationService,
    public sanitizer: DomSanitizer,
    private router: Router,
    private dialogService: DialogService,
    private s3Service: S3Service,
    private JobService: JOBService,private ngZone: NgZone
  ) {
    // this.currentUser = JSON.parse(sessionStorage.getItem('currentUser'));
    // this.loggedInUser = JSON.parse(sessionStorage.getItem('loggedInUser'));
     this.route.params.subscribe(params => {
       this.accesstoken = params.accesstoken;
     });
     console.log('this.accesstoken111',this.accesstoken);
  }
  env: any;

  async ngOnInit() {
   
    this.env = environment;
    this.loading = true;
    this.fullscreen = true;
    this.viewType = 'TM';
    debugger

     (await this.crudService.getUserLogin('login', 'Admin@123')).subscribe(async (data1: any) => {
      if(data1){
        // console.log('temp data',data1);
        // this.authtoken = data1.data[0].auth_token;
        // this.UserId = data1.data[0].id;

    let data = await this.crudService.getDataByField('joballocation', 'accesstoken',  this.accesstoken).toPromise();
      console.log("data getdatabyfield",data);
      this.jobId = data[0]?.job_id;
      this.allocId = data[0]?.id;
      await this.loadJobSummary();
      this.getOtherJobAllocationDetails();
        this. getUploadImages()
    this.fullscreen = false;
    this.loading = false;


    this.crudService.getDataByField('businessSettings', 'company_id', '108').subscribe(async (data: any[]) => {
      let  videoOut = data[0].videoUpload;
     // console.log('videoOut',videoOut)
      if(videoOut == 1){
       this.videoUploadCheck =true;

    let videoData = await this.crudService.getDataByField('uploadvideos', 'job_id',  this.jobId).toPromise();
    this.uploadVideos = videoData;

    this.uploadVideos.forEach(element => {
      const url: string = element.file_name; // Replace this with your actual URL
      const filename: string = url.split('/').pop() || '';
      const decodedFileName = decodeURIComponent(filename);
      element.filename = decodedFileName
  });
    console.log("uploadVideos",this.uploadVideos);

      }
      else{
        this.videoUploadCheck =false;
       }
      });
    }
    });


  }

  async loadJobSummary() {
    this.loading = true;
    let smryData: any = await this.crudService.getJobSummary_dup(this.jobId, this.allocId).toPromise();
    this.jobSummary = await this.JobService.getJobInfo_dup(smryData[0]);
    if (this.jobSummary.JobAllocDetails.wf_status === '2' || this.jobSummary.JobAllocDetails.wf_status === 2) {
      this.isFlagged = true;
    } else {
      this.isFlagged = false;
    }
    this.Date_str = new Date(this.jobSummary.Date).toLocaleDateString();
    this.title = this.jobSummary.JobNo + ' - (' + this.jobSummary.JobStatus + ')';
    this.docSummary=[]
    this.crudService.getUploadDocumentsByJobIdAllocId_dup(this.jobId, this.allocId).subscribe((uploadStatus: any[]) => {
      if (uploadStatus.length > 0) {
       this.docSummary = uploadStatus;
       console.log(this.docSummary);
       
       this.totaldoc = []
       this.totaldoc = this.docSummary.filter(val => (val.inc_quote == 2))
       
        
      }
      this.loading = false;
    });
  }

  getOtherJobAllocationDetails() {
    this.jobAllocationList = [];
    this.crudService.getOtherAllocationDetails_dup(this.jobId).subscribe((res: any) => {
      this.jobAllocationList = res;
      this.jobAllocationList= this.jobAllocationList
      
    })
  }

  async openallocation(data){
    this.loading = true;
    this.allocId=data.AllocationId
    this.docSummary=[]
    this.getUploadImages()
    this.loadJobSummary()
  }

  async openimagereport(){
    this.loading = true;
    let imgs: any;
    this.itemType=1
    imgs = await this.JobService.getImgeData_dup(this.jobId, this.allocId, this.itemType);
    console.log("imgs",imgs);
    const reportId = 4;
    const data = await this.JobService.setData(reportId, imgs, this.jobSummary, this.costingDetails);
    console.log("data",data);
    const date = new Date();
    const tempTime = date.getTime();
    const fileName = reportId + '_' + this.jobSummary.JobNo + '.pdf';
    const result = await this.crudService.generateReport_dup(data, reportId, this.jobId, this.allocId, tempTime, data).toPromise();
    const pdfurl = this.crudService.getAPIRoot() + '/' + this.jobId + '/' + this.allocId + '/' + 'report-template-' + reportId + '-' + tempTime + '.pdf';
    this.imgreportPdfUrl = pdfurl;
    console.log("this.imgPdfUrl",this.imgreportPdfUrl);
    console.log("this.result",result);
    this.loading = false;
        this.confirmationService.confirm({
       message: 'Do you want to view the report in separate Window?',
       header: 'Confirm',
       icon: 'pi pi-exclamation-triangle',
       acceptLabel: 'Yes, Open in new Tab',
       rejectLabel: 'No, Download the File',
       accept: () => {
         window.open(this.imgreportPdfUrl);
       //  this.JobService.deleteDoc_dup(this.allocId, fileName);
       //  this.JobService.addUploadDoc(this.jobId, this.allocId, this.userDetails.id, fileName, filePath, fileDesc);
         this.loading = false;
;
       },
       reject: () => {
         import('file-saver').then(FileSaver => {
           let blob = new Blob([result], { type: "application/pdf" });
           console.log("this.blob",blob);
           const a = document.createElement('a')
           const objectUrl = URL.createObjectURL(blob)
           console.log("this.objectUrl",objectUrl);
           a.href = objectUrl
           a.download = fileName;
           a.click();
           URL.revokeObjectURL(this.imgreportPdfUrl);
           // this.JobService.deleteDoc_dup(this.allocId, fileName);
          //  this.JobService.addUploadDoc(this.jobId, this.allocId, this.userDetails.id, fileName, filePath, fileDesc);
           this.loading = false;
         })
       }
     });
  }

  

   openVideo(path)
  {
    //this.videoUrl = this.sanitizer.bypassSecurityTrustUrl(path);
    this.videoUrl = path;
    this.displayVideo = true;
  }

 
  openfile(path,name,file){
    console.log("this.file",file);
    debugger
    var allowedExtensions = /(\.jpg|\.jpeg|\.png|\.pdf)$/i;
    this.PO_PDFurl=this.env.apiRoot+path
    if (allowedExtensions.exec(name)) {
      this.pdfView = false;
      this.PO_PDFurl = this.sanitizer.bypassSecurityTrustResourceUrl(this.PO_PDFurl);
    } else {
      this.pdfView = true;
      this.PO_PDFurl=this.env.apiRoot+path
    }
   this.imagename= name
    this.display=true
     console.log("this.PO_PDFurl",this.PO_PDFurl);
     
  }
  openfileinnewtab(path,name,file){
    this.PO_PDFurl=this.env.apiRoot+path;
    window.open(this.PO_PDFurl);

  }
  onVideoDialogHide()
  {
   
    this.videoUrl = '';
    console.log("asd",this.videoUrl);
  }
  showDialogMaximized(event, dialog: Dialog) {
    dialog.maximized = true;
    // dialog.toggleMaximize(event);
  }
 
   downloadSelectedImage(SelectedDocument) {
    const a = document.createElement('a')
        const objectUrl = URL.createObjectURL(SelectedDocument.blob)
        a.href = objectUrl
        a.download = SelectedDocument.img_name;
        a.click();
        URL.revokeObjectURL(objectUrl);
  };

  downloadSelectedItem(SelectedDocument) {  
    console.log("SelectedDocument",SelectedDocument);
    debugger
    import('file-saver').then(FileSaver => {
      this.crudService.downloadDocument_dup(SelectedDocument.file_path).subscribe((blob) => {
        console.log("blob",blob);
        debugger
        const a = document.createElement('a')
        const objectUrl = URL.createObjectURL(blob)
        a.href = objectUrl
        a.download = SelectedDocument.file_name;
        a.click();
        URL.revokeObjectURL(objectUrl);
      });
    });
  }


   getUploadImages() {
    this.imageDocuments = [];
    this.crudService.getUploadImgsByJobIdAllocId_dup(this.jobId, this.allocId).subscribe(async (data: any[]) => {
      console.log("data",data);
      
      if (data) {
        // this.loading = false
        data.forEach(imagedocument => {
          // //console.log(imagedocument.upload_time.split("T")[0] + imagedocument.upload_time.split("T")[1].split(".")[0]);
          if (imagedocument.img_inc === '2') {
            imagedocument.isIncluded = true;
          } else {
            imagedocument.isIncluded = false;
          }
          imagedocument.upload_time = imagedocument.upload_time.split("T")[0] + ' ' + imagedocument.upload_time.split("T")[1].split(".")[0];
          imagedocument.upload_time_str = new Date(imagedocument.upload_time).toLocaleString();
          if (imagedocument.img_path != null && imagedocument.img_path !== '') {
            this.crudService.getImageFile_dup(imagedocument.img_path).subscribe(blob => {
              imagedocument.blob = blob;
              let objectURL = URL.createObjectURL(blob);
              imagedocument.objUrl = objectURL;
              let image_url = this.sanitizer.bypassSecurityTrustUrl(objectURL);
              imagedocument.image_url = image_url;
            });
          }
          this.types.forEach(type => {
            if (type.id === imagedocument.img_type) {
              imagedocument.selectedType = type;
            }
          });
          this.imageDocuments.push(imagedocument);
         this.totalimg = []
          this.totalimg = this.imageDocuments.filter(val => (val.isIncluded))
          
        });
      }
    })
  }



  async viewReport(type) {
    if (type === 'Tab') {
      let blob = new Blob([this.reportRes.res], { type: "application/pdf" });
      const objectUrl = URL.createObjectURL(blob);
      window.open(objectUrl);
    //  this.JobService.deleteDoc_dup(this.allocId, this.reportRes.fileName);
      //this.JobService.addUploadDoc(this.jobId, this.allocId, this.userDetails.id, this.reportRes.fileName, this.reportRes.filePath, this.reportRes.fileDesc, this.reportRes.reportId, this.reportRes.tempTime, this.jobSummary.info.WPCompanyId);
      this.loading = false;
      //await this.loadJobSummary();
      this.displayConfirmation = false;
      this.reportRes = {}
    } else {
      import('file-saver').then(async FileSaver => {
        let blob = new Blob([this.reportRes.res], { type: "application/pdf" });
        const a = document.createElement('a')
        const objectUrl = URL.createObjectURL(blob)
        a.href = objectUrl
        a.download = this.reportRes.fileName;
        a.click();
        URL.revokeObjectURL(objectUrl);
       // this.JobService.deleteDoc_dup(this.allocId, this.reportRes.fileName);
        //this.JobService.addUploadDoc(this.jobId, this.allocId, this.userDetails.id, this.reportRes.fileName, this.reportRes.filePath, this.reportRes.fileDesc, this.reportRes.reportId, this.reportRes.tempTime, this.jobSummary.info.WPCompanyId);
        this.loading = false;
        //await this.loadJobSummary();
        this.reportRes = {}
        this.displayConfirmation = false;
      })
    }
  }
  loadDocuments() {
    const ref = this.dialogService.open(JobDocumentsComponent, {
      data: {
        jobAllocationId: this.allocId,
        jobId: this.jobId,
        jobSummary: this.jobSummary
      },
      /* header: 'Upload Documents', */
      width: '100%',
      height: '100%'
    })
    ref.onClose.subscribe(async (response: any) => {
      await this.loadJobSummary();
    });
  }

  loadImages() {
    this.displayImage = true
  }

  toggleTimeline() {
    let t = document.getElementById('toggle-timeline');
    if (t) {
      t.classList.toggle('hidden');
    }
  }


  onFileSelected(event: any) {
    const file: File = event.target.files[0];
    this.messageService.add({ severity: 'success', summary: 'Uploading', detail: 'File Uploading to the Server..', life: 100000 });
    if (file) {
      this.s3Service.uploadFile(file, this.jobId,this.allocId)
        .then((url) => {
          console.log(`File uploaded to S3: ${url}`);
          let document = {
           // Inv: null,
            company_id: '108',
            created_at: new Date(),
            created_by: null,
            file_description: null,
            file_name: url,
            file_path: url,
            file_size: null,
            hide: '2',
            id: null,
            inc_quote: '1',
            job_alloc_id: this.allocId,
            job_id: this.jobId,
            last_modified_by: null,
            last_updated_at: null,
            owner: null,
            process_id: '108',
            status: '1',
            upload_at: new Date(),
            upload_by: 1325,
            upload_type: null,
            upload_type_detail: null,
          }
            this.crudService.Create(document, 'UploadVideos').subscribe((data: any) => {
              document.id = data.id
              if (data.success == 1) {
                this.messageService.clear();
                this.messageService.add({ severity: 'success', summary: 'Successful', detail: 'File Uploaded Successfully', life: 1000 });
                 this.ngOnInit()
              }
            })
        })
        .catch(error => {
          this.messageService.clear();
          this.messageService.add({ severity: 'error', summary: 'Failed', detail: 'Failed to Upload!', life: 1000 });
          console.error('File upload failed:', error);
        });
    }
  }

}


