import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { CRUDService } from 'src/app/utilities/crud.service';
import { DynamicDialogRef, DialogService } from 'primeng/dynamicdialog';
import { ConfirmationService } from 'primeng/api';
import { MessageService } from 'primeng/api';
import { Table } from 'primeng/table';
 import { Staff } from './staffs';
import { ComplianceComponent } from '../../admin/compliance/compliance.component';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { CommonService } from 'src/app/utilities/common.service';
import { environment } from 'src/environments/environment';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { JOBService } from 'src/app/utilities/job.service';
import { DeviceDetectorService } from 'ngx-device-detector';
import { HttpClient } from '@angular/common/http';
import { SignaturePad } from 'angular2-signaturepad';
import { Subject } from 'rxjs';
import { EncryptionService } from 'src/app/utilities/encryption.service';
import { EncrDecrService } from 'src/app/utilities/encr-decr.service';
import { ActivatedRoute } from '@angular/router';
import { Page } from 'powerbi-client';
import { log } from 'console';
import { DatePipe } from '@angular/common';

@Component({
  selector: 'app-staff-public',
  templateUrl: './staff-public.component.html',
  styleUrls: ['./staff-public.component.scss'],
  providers: [DialogService, MessageService, ConfirmationService]
})
export class StaffPublicComponent implements OnInit {

  @ViewChild(SignaturePad) signaturePad: SignaturePad;
  @Output() updateEvent = new EventEmitter<Boolean>();
  visible:boolean=true
  signature: any;
  userDetails: any;
  docPdfUrl: any = '';
  displayPdf :boolean = false;
  signedBy: any;
  ip: any;
  public lat: any;
  public lng: any;


  paginationvalue: any;
  @Input() contractor_id: any;
  @Input() videoUrl: any;
  @Input() pdfUrl: any;
  viewType: any;
  companyId: any;
  user: any;
  userExists: boolean;
  uploadedFiles: any[] = [];
  cols: any[];
  dataValue: any[];
  viewAllStaffData: any;
  staffCreateList: any;
  exportColumns: any[];
  itemList: any[];
  docList: any[];
  excelColumnsList: any[];
  selectedCompany: any;
  closeVideo: boolean = true;
  closepdf: boolean = true;
  notification_flag: boolean = false;
  notification_flag_d: boolean = false;
  public saveView = true;
  public editView = false;
  public listView = false;
  public createView = false;
  staffDialog: boolean;
  notificationDialog: boolean;
  mytable: boolean = true;
  complianceDialog: boolean;
  staffs: any[];
  staff: any;
  compliance: any;
  compliances: any[];
  selectedStaffs: any[];
  submitted: boolean;
  enableLogin = false;
  statuses: any[];
  issuedDate: Date;
  expirationDate: Date;
  staffRoleList: any[];
  staffTypeList: any[];
  userRoleList: any[];
  selectedUserRole: any;
  selectedStaffRole: any;
  selectedStaffType: any;
  checkboxEnabled = false;
  mobNumberPattern = `^((\\+61-?)|0)?[0-9]{10}$`;
  companyList: any[];
  selectedStaffCompliance: any;
  staffComplianceList: any[];
  sortOptions: any[];
  sortOrder: number;
  ref: DynamicDialogRef;
  sortField: string;
  complianceSubmitted: boolean;
  complianceTypes: any[];
  NewComplianceEnabled: boolean;
  selectedComplianceDoc: any;
  staffId: any;
  isActive: boolean;
  formStatus: boolean;
  complianceNewLayout = true;
  inductionExists = false;
  inductionExpired = false;
  exportdisplay: Boolean = false;
  notificationMobile: Boolean;
  notificationMail: Boolean;
  notificationApp: Boolean;
  dupUserId: boolean = false;
  dupEmail: boolean = false;
  noOfStaff: number;
  workflowDefinition: any;
  showWorkflowDefnSteps: Boolean = false;
  showEf: Boolean = false;
  showEfstart: Boolean = true;
  searchvalue: any;

  passKeyTextType: boolean;
  confirmpassKeyTextType: boolean;
  passwordMatch: boolean = true;
  WorkflowDefnSteps: any;
  InductionStatus: any;
  empSize: any;
  minEmpSize: number;
  employeSize: any;
  appProps: any;
  env = environment;

  haslowercase: boolean;
  hasuppercase: boolean;
  hasspecialchar: boolean;
  hasnumbers: boolean;
  buttonDisabled1: boolean=true;
  buttonDisabled2: boolean=true;
  buttonDisabled3: boolean=true;
  buttonDisabled4: boolean=true;
  buttonDisabled5: boolean=true;
  encryptedPass : any;


  eventsSubject: Subject<void> = new Subject<void>();
  staff_Id: any;
  staffInfo: any;
  slaId: string;
  slaData: any;
  page: any;
  ef_inductionpage: boolean=false;
  entered_OTP: any;
  sendlinkvia: any;
  currentUser: any;
  currentOTPData: any;
  pageloaded: boolean =false;
  show_sign: boolean =false;
  current_page: any;

  emitEventToChild() {
    this.eventsSubject.next();
  }


  signaturePadOptions: Object = { // passed through to szimek/signature_pad constructor
    minWidth: 2,
    canvasWidth: 400,
    canvasHeight: 200
  };

  isSigned: Boolean = false;
  loading: Boolean = false;
  isChecked: Boolean = false;
  companyCompliance: any;
  isAccepted: Boolean;
  acceptedDatetime: any;
  signaturePath: any;
  deviceInfo: any;
  ipAddress: any;
  inputCompanyId: any;
  showOtpdiv:boolean=false
  staffForm = new FormGroup({
    first_name: new FormControl('', Validators.required),
    last_name: new FormControl('', Validators.required),
    phone: new FormControl('', Validators.required),
    email: new FormControl('', Validators.required),
    mobile: new FormControl('', Validators.required),
    company: new FormControl('',),
    role: new FormControl('',),
    staffType: new FormControl('',),
  })

  staffLoginForm = new FormGroup({
    username: new FormControl('', Validators.required),
    password: new FormControl('', Validators.required),
    userRole: new FormControl('', Validators.required),
    pass_key: new FormControl('', Validators.required),
    confirm_pass_key: new FormControl('', Validators.required)
  })

  constructor(private crudService: CRUDService,
    private messageService: MessageService,
    private confirmationService: ConfirmationService,
    private dialogService: DialogService,
    private sanitizer: DomSanitizer,
    private commonservice: CommonService,
    private fb: FormBuilder,
    private JobService: JOBService,
    private CommonService: CommonService,
    private deviceService: DeviceDetectorService,
    private http: HttpClient,
    private encryptService: EncryptionService,
    private encodeDecode: EncrDecrService,
    private route: ActivatedRoute,private datePipe: DatePipe
  ) {
    this.env = environment;
    this.route.params.subscribe( params => {
      this.page = params.page;
      let encrypted = params.com_staff_id;
      let decrypted :string= this.encodeDecode.decrypt( encrypted,'123456$#@$^@1ERF',);
      let toArray = decrypted.split(' ');
      console.log("toArray",toArray);
      this.companyId = toArray[0]
       this.staff_Id = toArray[1]
       this.sendlinkvia = toArray[2]  // if true send via sms , if false send via mail
      
    });
    this.viewType = 'TP';
    this.selectedCompany = null;
    this.NewComplianceEnabled = false;
  //   this.crudService.getDataByField('Users', 'staff_id', this.staff_Id).subscribe( (userInfo: any) => {
  //     this.currentUser= userInfo[0]
  //     console.log("toArray",this.currentUser);
  //     if(userInfo){
  //       debugger
  //       this.crudService.getUserLogin(userInfo[0].username, userInfo[0].password).subscribe((data: any) => {
  //       });
  //     }

  // });
  }

  async ngOnInit(): Promise<void> {
    debugger
//  await  this.crudService.getDataByField('Users', 'staff_id', this.staff_Id).subscribe( (userInfo: any) => {
  // let userData: any = await this.crudService.getDataByField('CompanyStaffInfo', 'id', this.staff_Id).toPromise();
  // console.log("userData",userData);  
  // await  this.crudService.getDataByField('CompanyStaffInfo', 'id', this.staff_Id).subscribe( async (userInfo: any) => {
  //   this.staff= userInfo[0];
  //   console.log("this.staff",this.staff);  
  //   if(this.staff){
     // this.crudService.getUserLogin(userInfo[0].username, userInfo[0].password).subscribe(async (data: any) => {
        (await this.crudService.getUserLogin("login", "Admin@123")).subscribe(async (data: any) => {
        if(data){
          let userData: any = await this.crudService.getDataByField('CompanyStaffInfo', 'id', this.staff_Id).toPromise();
          console.log("userData",userData);  
          this.staff= userData[0];
            if(this.page == "EFinduction"){
              this.ef_inductionpage =true
              this.current_page = 0
              this.appProps = this.commonservice.getAppProperties();
              this.paginationvalue = environment.paginatorValue;
              this.formStatus = true;
              if(this.env.hosting == 'AU')
              {
                this.inputCompanyId = '74';
              }
              else if(this.env.hosting == 'UK')
              {
                this.inputCompanyId = '170';
              }
              this.notificationMobile = true;
              this.notificationMail = true;
              this.notificationApp = true;
              if (typeof this.contractor_id !== 'undefined') {
                this.companyId = this.contractor_id;
                this.viewType = 'TP';
                // this.loadStaffByCompany();
              } else {
                if (this.viewType === 'TM') {
                  // this.loadAllStaffList();
                } else if (this.viewType === 'TL') {
                  // this.loadStaffByCompany();
                } else if (this.viewType === 'TP') {
                  this.crudService.getDataByField('CompanyStaffInfo', 'id', this.staff_Id ).subscribe((data: any[]) => {
                    if (data.length > 0) {
                      this.staffs = data[0];  
                      this.editSelectedStaff(data[0]);
                    }
                  });
                }
              }
              this.userRoleList = [
                { id: '1', name: 'Admin' },
                { id: '2', name: 'User' },
                // { id: '3', name: 'Only Jobs - View' }
              ];
          
              // this.crudService.GetAllForStaffLicense().subscribe((items: any[]) => {
              //   this.docList = items;
              // });
          
              this.cols = [
                { field: 'first_name', header: 'First Name' },
                { field: 'last_name', header: 'Last Name' },
                { field: 'email', header: 'Email' },
                { field: 'mobile', header: 'Mobile' },
                { field: 'CompanyName', header: 'Company' },
                { field: 'InductionStatus', header: 'Induction Status' },
                { field: 'StaffTypeName', header: 'Staff Role' }
              ];
              this.sortOptions = [
                { label: 'Ascending', value: '!name' },
                { label: 'Descending', value: 'name' }
              ];
              this.exportColumns = this.cols.map(col => ({ title: col.header, dataKey: col.field }));
              //this.exportdisplay = await this.commonservice.exportCheck();
              // console.log(this.exportdisplay);
              this.loading = false;
          
            //  await this.crudService.getDataByField('Users', 'staff_id', this.staff_Id).subscribe((userInfo: any[]) => {
            //    this.staff= userInfo[0]
            //    console.log("userInfo",userInfo[0])
            //   });
            await  this.loadComplianceInfo()
            }
            else if(this.page == "SPA"){
              this.ef_inductionpage =false
              this.current_page = 1
              // this.userDetails = JSON.parse(sessionStorage.getItem('loggedInUser'));
              let data = await this.crudService.getDataByField('CompanyStaffInfo', 'id', this.staff_Id).toPromise();
              this.staffInfo = data[0];
              console.log("userInfo",this.staffInfo)
              this.loading = true;
              await this.loadComplianceInfoSPA();
              this.getLocation();
              //this.signaturePad.set('minWidth', 5);
              this.signaturePad?.clear();
              this.deviceInfo = this.deviceService.getDeviceInfo();
              this.getIP();
              this.loading = false;
          
              if(environment.hosting == 'AU')
              {
                this.slaId = '75';
              }
              else if(environment.hosting == 'UK')
              {
                this.slaId = '165';
              }
            }
            else{
              console.log("no page");
              
            }
          }
        });
   //   }

  // });

 
   
  }

  checkbox() {
    this.checkboxEnabled = true;
  }

  EnableDisableTextBox() {
    if (this.enableLogin) {
      this.staff.create_login = '2';
    } else {
      this.staff.create_login = '1';
    }
  }





  getStaffComplianceDetail(id) {
    this.crudService.getCompliancesByStaffId(id).subscribe((data: any[]) => {
      data.forEach(compl => {
        compl.exist = true;
        if (compl.compliance_id == 74) {
          this.inductionExists = true;
          if (compl.expiry_date) {
            if (new Date(compl.expiry_date) < new Date()) {
              this.inductionExpired = true;
            }
          }
        }
      });
    });
  }

  passwordValidator(pass_key) {
    const re = /[0-9]/;
     if(re.test(pass_key)) {
       this.hasnumbers=true 
       this.buttonDisabled1= false;    
     }
     else{
       this.hasnumbers=false
       this.buttonDisabled1= true;
     }
 
     const re1 = /[a-z]/;
     if(re1.test(pass_key)) {
       this.haslowercase=true  
       this.buttonDisabled2= false; 
     }
     else{
       this.haslowercase=false;
       this.buttonDisabled2= true;
 
     }
 
     const re2 = /[A-Z]/;
     if(re2.test(pass_key)) {
       this.hasuppercase=true   
       this.buttonDisabled3= false; 
     }
     else{
       this.hasuppercase=false
       this.buttonDisabled3= true;
     }
    
     const re3 =  /[-+_!@#$%^&*.,?]/;
     if(re3.test(pass_key)) {
       this.hasspecialchar=true;
       this.buttonDisabled4= false;   
     }
     else{
       this.hasspecialchar=false;
       this.buttonDisabled4= true;
     }
   }

  editSelectedStaff(staff: Staff) {
    this.formStatus = true;
    this.submitted = false;
    this.userExists = false;
    this.selectedStaffRole = null;
    this.selectedStaffType = null;
    this.selectedUserRole = null;
    this.user = {};
    this.enableLogin = false;
    this.showEfstart = true;
    this.showEf = false;
    this.staff = { ...staff };
    this.staffId = this.staff.id;
    this.staff.InductionStatus = this.staff.InductionStatus;
    this.InductionStatus = this.staff.InductionStatus;
    try{
      this.loadTrainingInfo();
    }catch(error){

    }
    if (typeof this.staff.created_at == 'undefined') {
      this.staff.created_at = new Date().toDateString();
    }
    // console.log(this.staff);
    this.staffDialog = true;
    this.mytable = false;
    if (this.staff.id !== null) {
      this.crudService.getDataByField('CompanyStaffInfo', 'id', this.staff.id).subscribe((StaffInfo: any[]) => {
        this.staff = StaffInfo[0];
        if (this.staff.username !== null) {
          this.enableLogin = true;
        }
        this.selectedCompany = null;
        this.crudService.getDataByField('Company', 'id', this.staff.company_id).subscribe((companyInfo: any[]) => {
          this.selectedCompany = companyInfo[0];
        });

        if (this.staff.role !== '' && this.staff.role !== null) {
          const staffrole = this.staffRoleList?.filter(val => val.id === this.staff.role);
          if (staffrole?.length > 0) {
            this.selectedStaffRole = staffrole[0];
          }
        } else {
          this.selectedStaffRole = null;
        }
        if (this.staff.stafftype !== '' && this.staff.stafftype !== null) {
          const stafftype = this.staffTypeList.filter(val => val.id === this.staff.stafftype);
          if (stafftype.length > 0) {
            this.selectedStaffType = stafftype[0];
          }
        } else {
          this.selectedStaffType = null;
        }
        if (this.staff.user_role_id !== null && this.staff.user_role_id !== '') {
          const userrole = this.userRoleList.filter(val => val.id === this.staff.user_role_id);
          if (userrole.length > 0) {
            this.selectedUserRole = userrole[0];
          }
        } else {
          this.selectedUserRole = null;
        }

        this.crudService.getDataByField('Users', 'staff_id', this.staff.id).subscribe((userInfo: any[]) => {
          if (userInfo.length > 0) {
            this.user = userInfo[0];
            this.staff.password = this.encryptService.decryptionAES(this.user.encrypted_password);
            this.passwordValidator(this.staff.password);
            if (typeof this.user.created_at == 'undefined') {
              this.user.created_at = new Date().toDateString();
            }
            this.userExists = true;
          } else {
            this.user = null;
            this.userExists = false;
          }
        });
      });
      if (this.viewType === 'TP' || this.viewType === 'TM') {
        this.getStaffComplianceDetail(this.staff.id);
      }
    } else {
      this.user = {};
    }

    setTimeout(() => {

      this.initTab();

      // document.getElementById('help-video-trigger').addEventListener('click', function () {
      //   document.getElementById('help-video').style.display = 'block';
      // })

      // document.getElementById('vid-close')?.addEventListener('click', function () {
      //   document.getElementById('help-video').style.display = 'none';
      // })
      // document.getElementById('walkthrough-trigger').addEventListener('click', function () {

      //   if (addEle) {
      //     document.body.appendChild(mainEle);
      //     document.body.appendChild(mainEleNote);
      //   }
      //   count = 0;
      //   walkthroughScreen(walkthrough[count]);
      // })
      // const walkthrough = [
      //   {
      //     element: document.getElementById('d-1'),
      //     purpose: 'Enter your Business here'
      //   },
      //   {
      //     element: document.getElementById('d-2'),
      //     purpose: 'Enter your Trading Name'
      //   },
      //   {
      //     element: document.getElementById('d-3'),
      //     purpose: 'Enter your ABN/ACN'
      //   },
      //   {
      //     element: document.getElementById('d-4'),
      //     purpose: 'Enter Payment Terms'
      //   },
      //   {
      //     element: document.getElementById('d-5'),
      //     purpose: 'Enter Client Type'
      //   },
      //   {
      //     element: document.getElementById('d-6'),
      //     purpose: 'Enter your Email'
      //   },
      //   {
      //     element: document.getElementById('d-7'),
      //     purpose: 'Enter your Email'
      //   },
      //   {
      //     element: document.getElementById('d-8'),
      //     purpose: 'Enter your Email'
      //   },
      //   {
      //     element: document.getElementById('d-9'),
      //     purpose: 'Enter your Email'
      //   }
      // ]

      // let mainEle = document.getElementById('walkthrough-ele');
      // let mainEleNote = document.getElementById('walkthrough-ele-note');
      // let addEle = false;
      // let count = 0;
      // if (!mainEle) {
      //   mainEle = document.createElement('div');
      //   mainEle.id = 'walkthrough-ele';
      //   mainEleNote = document.createElement('div');
      //   let nextElement = document.createElement('div');
      //   let closeElement = document.createElement('div');
      //   let imgElement = document.createElement('img');
      //   imgElement.src = 'thinking.png';
      //   mainEleNote.id = 'walkthrough-ele-note';
      //   nextElement.id = 'nextWalkthrough';
      //   nextElement.innerHTML = 'Next';
      //   closeElement.id = 'closeWalkthrough';
      //   closeElement.innerHTML = 'x';
      //   mainEleNote.appendChild(nextElement);
      //   mainEleNote.appendChild(closeElement);
      //   mainEleNote.appendChild(imgElement);
      //   addEle = true;
      //   closeElement.addEventListener('click', function () {
      //     closeWalkthrough();
      //   });
      //   nextElement.addEventListener('click', function () {
      //     document.getElementById('wlk-info')?.remove();
      //     if (count < walkthrough.length - 1) {
      //       count++;
      //       walkthroughScreen(walkthrough[count]);
      //       if (count === walkthrough.length - 1) {
      //         nextElement.innerHTML = 'Close';
      //       }
      //     } else {
      //       closeWalkthrough();
      //     }
      //     if (count > 0) {
      //       enableBack();
      //     }
      //   });
      // }
      // function enableBack() {
      //   if (!document.getElementById('backWalkthrough')) {
      //     let backElement = document.createElement('div');
      //     backElement.id = 'backWalkthrough';
      //     backElement.innerHTML = 'Back';
      //     mainEleNote.appendChild(backElement);
      //     backElement.addEventListener('click', function () {
      //       document.getElementById('wlk-info')?.remove();
      //       if (count > 0) {
      //         count--;
      //         walkthroughScreen(walkthrough[count]);
      //         if (count === 0) {
      //           backElement.remove();
      //         }
      //       }
      //     });
      //   }
      // }

      // function walkthroughScreen(deet) {

      //   let o = deet;
      //   let x = o.element.offsetLeft;
      //   let y = o.element.offsetTop;
      //   let w = o.element.offsetWidth;
      //   let h = o.element.offsetHeight;
      //   //console.log('left-' + x);
      //   //console.log('top-' + y);
      //   //console.log('w-' + w);
      //   //console.log('h-' + h);
      //   mainEle.style.width = w + 'px';
      //   mainEle.style.height = h + 'px';
      //   mainEle.style.top = y + 'px';
      //   mainEle.style.left = x + 'px';
      //   mainEleNote.style.width = '500px';
      //   mainEleNote.style.height = '100px';
      //   mainEleNote.style.top = (y + h + 5) + 'px';
      //   mainEleNote.style.left = x + 'px';
      //   let ol = document.getElementById('wlk-info');
      //   if (ol) {
      //     ol.remove();
      //   }
      //   let l = document.createElement('div');
      //   l.id = 'wlk-info';
      //   l.innerHTML = o.purpose;
      //   mainEleNote.appendChild(l)
      // }


      // function closeWalkthrough() {
      //   mainEle.remove();
      //   mainEleNote.remove();
      // }
    }, 1000);
  }

  tagConfig;
  initTab() {
    const tabGeneral = document.getElementById('tab-Staff');
    const tabStaffCompliance = document.getElementById('tab-staff-compliance');

    let general = document.getElementsByClassName('staff-g-info-tab-activate');
    let staffcompliance = document.getElementsByClassName('staff-compliance-info-tab-activate');

    function initializeTabs(config) {

      let l1 = [tabGeneral, tabStaffCompliance];
      let l2 = [general, staffcompliance];

      config.forEach(c => {
        document.getElementById(c.id)?.addEventListener('click', () => {
          l1.forEach((ele) => {
            ele.classList.remove('active');
          });
          c.activeTab.classList.add('active');

          l2.forEach((ele) => {
            if (ele && ele.length > 0) {
              for (let i = 0; i < ele.length; i++) {
                ele[i].classList.add('hideme');
              }
            }
          });
          if (c.activeContent && c.activeContent.length > 0) {
            for (let i = 0; i < c.activeContent.length; i++) {
              c.activeContent[i].classList.remove('hideme');
            }
          }

        });
      })
    }
    this.tagConfig = [{
      id: 'tab-Staff',
      activeTab: tabGeneral,
      activeContent: general,
      general: true
    }, {
      id: 'tab-staff-compliance',
      activeTab: tabStaffCompliance,
      activeContent: staffcompliance
    }];
    initializeTabs(this.tagConfig);
  }

  saveRecord() {
    this.submitted = true;
    if (this.enableLogin) {
      this.staff.create_login = '2';
      if (this.staffLoginForm.status !== 'VALID') {
        this.formStatus = false;
      } else {
        this.formStatus = true;
      }
    } else {
      this.staff.create_login = '1';
      this.formStatus = true;
    }
    this.staff.InductionStatus = this.InductionStatus;
    this.formStatus = true;
    if (this.selectedUserRole !== null && this.selectedUserRole !== '') {
      this.formStatus = true;
    } else {
      this.formStatus = false;
    }
    if (this.isActive) {
      this.staff.deleted = null;
    } else {
      this.staff.deleted = null;
    }
    if (this.viewType === 'TP') {
      this.staff.company_id = this.companyId;
    } else {
      if (typeof this.selectedCompany !== 'undefined' && this.selectedCompany !== null) {
        if (this.selectedCompany.id !== '' && this.selectedCompany.id !== null) {
          this.staff.company_id = this.selectedCompany.id;
        }
      }
    }

    if (typeof this.staff.company_id === 'undefined') {
      this.formStatus = false;
    }
    if (this.staff.company_id === '' || this.staff.company_id === null) {
      this.formStatus = false;
    }
    if (this.staff.last_name === '' || this.staff.last_name === null) {
      this.formStatus = false;
    }


    this.staff.user_role_id = null;
    this.staff.role = null;
    this.staff.stafftype = null;

    if (typeof this.selectedUserRole !== 'undefined' && this.selectedUserRole !== null) {
      if (this.selectedUserRole.id !== '' && this.selectedUserRole.id !== null) {
        this.staff.user_role_id = this.selectedUserRole.id;
      }
    }
    if (typeof this.selectedStaffRole !== 'undefined' && this.selectedStaffRole !== null) {
      if (this.selectedStaffRole.id !== '' && this.selectedStaffRole.id !== null) {
        this.staff.role = this.selectedStaffRole.id;
      }
    }
    if (typeof this.selectedStaffType !== 'undefined' && this.selectedStaffType !== null) {
      if (this.selectedStaffType.id !== '' && this.selectedStaffType.id !== null) {
        this.staff.stafftype = this.selectedStaffType.id;
      }
    }
    if (this.formStatus) {
      this.callAPI()
    } else {
      if (!this.enableLogin) {
        this.confirmationService.confirm({
          message: 'Are you sure you want to save record?',
          header: 'Confirm',
          icon: 'pi pi-exclamation-triangle',
          accept: () => {
            //console.log(this.staff.first_name)
            if (this.staff.first_name !== null && this.staff.first_name !== '' && this.staff.last_name !== undefined && this.staff.last_name !== '' && this.staff.last_name !== null && this.staff.last_name !== undefined) {
              this.staff.first_name = this.staff.first_name.charAt(0).toUpperCase() + this.staff.first_name.substr(1).toLowerCase()
              this.staff.last_name = this.staff.last_name.charAt(0).toUpperCase() + this.staff.last_name.substr(1).toLowerCase()
              this.callAPI();
            } else {
              //console.log('**************')
              this.messageService.add({ severity: 'error', summary: 'Error', detail: 'Invalid Details Entered. First Name and Last Name are Mandatory', life: 1000 });
            }
          }
        });
      } else {
        if (this.enableLogin) {
          if (this.selectedUserRole === null || this.selectedUserRole === '') {
            this.confirmationService.confirm({
              message: 'You haven\'t selected role, Are you sure you want to save record?',
              header: 'Confirm',
              icon: 'pi pi-exclamation-triangle',
              accept: () => {
                //console.log(this.staff.first_name)
                this.callAPI();
              }
            });
          }
        }
      }
    }
  }

  callAPI() {
    if (this.staff.id) {
      this.staff.last_modified_at = new Date().toDateString();
      this.staff.last_modified_by =  this.staff.id ;
      this.staff.password = this.staff.password;
      this.encryptedPass = this.encryptService.encryptionAES(this.staff.password);
      this.staff.deleted = null;
      this.staff.encrypted_password = this.encryptService.encryptionAES(this.staff.password);
     // this.staff.first_name = this.staff.first_name.charAt(0).toUpperCase() + this.staff.first_name.substr(1).toLowerCase();
     // this.staff.last_name = this.staff.last_name.charAt(0).toUpperCase() + this.staff.last_name.substr(1).toLowerCase();
      this.crudService.Update(this.staff, 'CompanyStaffInfo').subscribe((data: any) => {
        if (data.success === 1) {
          this.staffDialog = false;
          this.staffs[this.findIndexById(this.staff.id)] = this.staff;
          this.messageService.add({ severity: 'success', summary: 'Successful', detail: 'Staff Updated', life: 1000 });
          this.saveUserInfo();
        } else {
          this.staffDialog = true;
          this.mytable = false;
          this.messageService.add({ severity: 'error', summary: 'Error', detail: 'Staff Not Updated', life: 1000 });
        }
      });
    } else {
      this.staff.created_at = new Date().toDateString();
      this.staff.created_by = this.staff.id 
      this.staff.password = this.staff.password;
      this.encryptedPass = this.encryptService.encryptionAES(this.staff.password);
      this.staff.deleted = null;
      this.staff.encrypted_password = this.encryptService.encryptionAES(this.staff.password);
    //  this.staff.first_name = this.staff.first_name.charAt(0).toUpperCase() + this.staff.first_name.substr(1).toLowerCase();
    //  this.staff.last_name = this.staff.last_name.charAt(0).toUpperCase() + this.staff.last_name.substr(1).toLowerCase();
      this.crudService.Create(this.staff, 'CompanyStaffInfo').subscribe((data: any) => {
        //console.log(data);
        if (data.success === 1) {
          this.staff.id = data.id;
          this.staffs = [...this.staffs, this.staff];
          this.saveUserInfo();
          this.staffDialog = true;
          this.mytable = false;
          this.messageService.add({ severity: 'success', summary: 'Successful', detail: 'Staff Created', life: 1000 });
        } else {
          this.staffDialog = true;
          this.mytable = false;
          this.messageService.add({ severity: 'error', summary: 'Error', detail: 'Staff Not Created', life: 1000 });
        }
      });
    }
  }
  saveUserInfo() {
    if (this.enableLogin && !this.userExists) {
      this.user = {};
      this.user.dob = null;
      this.user.person_id = null;
      this.user.status = '1';
      this.user.owner = '1';
      this.user.created_at = new Date();
      this.user.position_id = null;
      this.user.invalid_passwd_count = null;
      this.user.title = null;
      this.user.mobile = this.staff.mobile;
      this.user.alternate_phone = this.staff.phone;
      this.user.work_phone = this.staff.phone;
      this.user.user_locked = '0';
      this.user.user_group_id = 1;
      if (typeof this.user.created_at === null) {
        this.user.created_at = new Date().toDateString();
      }
      if (this.user.menu_colour === '' || this.user.menu_colour == null) {
        this.user.menu_colour = 'layout-menu-dark';
      }
      if (this.user.layout_colour === '' || this.user.layout_colour == null) {
        this.user.layout_colour = 'blue';
      }
      if (this.user.colour_scheme === '' || this.user.colour_scheme == null) {
        this.user.colour_scheme = 'light';
      }
      if (this.user.colour === '' || this.user.colour == null) {
        this.user.colour = 'green';
      }
      if (this.user.menu_type === '' || this.user.menu_type == null) {
        this.user.menu_type = '1';
      }
      if (this.user.homepage_url === '' || this.user.homepage_url == null) {
        this.user.homepage_url = '/home';
      }
      if (this.selectedUserRole?.id !== null && this.selectedUserRole?.id !== '') {
        this.user.user_group_id = this.selectedUserRole?.id;
      } else {
        this.user.user_group_id = null;
      }
      if (this.user.homepage_url === '' || this.user.homepage_url == null) {
          this.user.homepage_url = '/home';
        }
      this.user.username = this.staff.username;
      this.user.password = this.staff.password;
      this.user.encrypted_password = this.encryptedPass;
      this.user.company_id = this.staff.company_id;
      this.user.first_name = this.staff.first_name;
      this.user.last_name = this.staff.last_name;
      this.user.email = this.staff.email;
      this.user.staff_id = this.staff.id;
      this.user.login_attempt = '0';
      this.user.process_id = this.staff.company_id;
      this.user.image_name = null;
      this.user.description = null;
      this.user.menu_colour = 'layout-menu-dark';
      this.user.layout_colour = 'blue';
      this.user.colour_scheme = 'light';
      this.user.colour = 'green';
      this.user.menu_type = '1';
      this.user.homepage_url = '/home';
      this.user.created_at = new Date().toDateString();
      this.user.created_by = this.userDetails?.id;
      this.user.last_updated_at = new Date().toDateString();
      this.user.last_modified_by = null;
      this.user.username = this.staff.username;
        this.user.password = this.staff.password;
        this.user.encrypted_password = this.encryptedPass;
        this.user.deleted = null;
      this.crudService.Create(this.user, 'User').subscribe((data: any) => {
        //console.log(data);
        this.updateMenuRoles();
        this.editSelectedStaff(this.staff);
      });
    } else if (this.userExists) {
      if (this.selectedUserRole.id !== null && this.selectedUserRole.id !== '') {
        this.user.user_group_id = this.selectedUserRole.id;
      } else {
        this.user.user_group_id = null;
      }
      let changed = false;
      if (this.staff.username !== this.user.username) {
        changed = true;
      }

      if ((this.enableLogin) && changed) {
        this.user.username = this.staff.username;
        this.user.password = this.staff.password;
        this.user.encrypted_password = this.encryptedPass;
        this.user.status = '1';
        this.user.user_locked = 0;
      } else if (!this.enableLogin) {
        this.user.status = '2';
        this.user.user_locked = 0;
        this.user.username = this.staff.username;
        this.user.password = this.staff.password;
        this.user.encrypted_password = this.encryptedPass;
      } else if (this.enableLogin && this.user.status === '2') {
        this.user.username = this.staff.username;
        this.user.password = this.staff.password;
        this.user.encrypted_password = this.encryptedPass;
        this.user.user_locked = 0;
        this.user.status = '1';
      }
        this.user.username = this.staff.username;
        this.user.password = this.staff.password;
        this.user.encrypted_password = this.encryptedPass;
      this.user.last_modified_at = new Date().toDateString();
      this.user.last_modified_by = this.userDetails?.id;
      this.user.deleted = null;
      console.log("this.user",this.user);
      this.crudService.Update(this.user, 'User').subscribe((data: any) => {
        //console.log(data);
        this.updateMenuRoles();
        this.editSelectedStaff(this.staff);
      });
    } else {
      this.editSelectedStaff(this.staff);
    }
  }

  findIndexById(id: string): number {
    let index = -1;
    for (let i = 0; i < this.staffs.length; i++) {
      if (this.staffs[i].id === id) {
        index = i;
        break;
      }
    }

    return index;
  }

  clear(table: Table) {
    table.clear();
    this.searchvalue = "";
  }


  updateMenuRoles() {
    this.crudService.updateMenuRoles(this.user.user_group_id, this.staff.company_id).subscribe((data: any) => {
      //console.log(data);
    });
  }

  async loadTrainingInfo() {
    this.loading = true;
    // this.deviceInfo = this.deviceDetectorService.getDeviceInfo();
    // console.log(this.deviceInfo);
    this.userDetails = JSON.parse(sessionStorage.getItem('loggedInUser'));
    await this.loadComplianceInfo();
    this.getLocation();
    //this.signaturePad.set('minWidth', 5);
    this.deviceInfo = this.deviceService.getDeviceInfo();
    this.getIP();
    this.loading = false;
    this.signaturePad?.clear();
  }


  getIP() {
    this.crudService.getIpAddress().subscribe((res: any) => {
      this.ipAddress = res.ip;
    });
  }

  getLocation() {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition((position) => {
        if (position) {
          this.lat = position.coords.latitude;
          this.lng = position.coords.longitude;
          //console.log(this.lat);
          //console.log(this.lng);
        }
      });
    }
  }

  async loadComplianceInfo() {
    let data: any = await this.crudService.getDataByField('CompanyCompliance', 'staff_id', this.staff_Id).toPromise();
    let slaData = data.filter(val => (val.compliance_id == this.inputCompanyId));
    if (slaData.length > 0) {
      this.companyCompliance = slaData[0];
      console.log(" this.companyCompliance.file_path", this.companyCompliance.file_path);
      this.signaturePath = 'C://uploadImgs//SLA//' + this.companyId + '//' + this.staff_Id + '.png';
      this.acceptedDatetime = this.companyCompliance.created_at;
      this.isAccepted = true;
      this.pageloaded =true
      this.docPdfUrl = null;
      this.displayPdf = true;
      this.signedBy = this.companyCompliance.comment;
      this.docPdfUrl = this.env.apiRoot + this.companyCompliance.file_path
    } else {
      this.signaturePath = null;
      this.acceptedDatetime = null;
      this.pageloaded =true
      this.isAccepted = false;
      this.displayPdf = false;
      this.docPdfUrl =  this.env.apiRoot +'1/compliance_docs/EFI.pdf'
      this.signedBy = this.staff?.first_name + ' ' + this.staff?.last_name;   
      //this.signaturePad.clear();
      //this.pdfUrl="http://docs.google.com/gview?url="+this.crudService.getAPIRoot() + "/policy_documents/SLA - Template 2021.docx&embedded=true";
      //this.pdfUrl = this.sanitizer.bypassSecurityTrustResourceUrl(this.crudService.getAPIRoot() + '/policy_documents/SLA - Template 2021.docx');
      //  this.pdfUrl = this.crudService.getAPIRoot() + '/policy_documents/SLA - Template 2021.docx';
    }
  }

  drawComplete() {
    this.isSigned = true;
  }
  onEdit() {
    window.scrollTo(0, 0);
  }


  async saveSLACompliance(filePath, fileName) {
    let expiryDate = new Date(new Date().getTime() + (1000 * 60 * 60 * 24 * 365 * 2));

    let company_compliance = {
      amount: null,
      comment: this.signedBy,
      company_id: this.companyId,
      staff_id: this.staff.id,
      compliance_id: this.inputCompanyId,
      compliance_type_id: null,
      created_at: new Date(),
      created_by: '1',
      expiry_date: expiryDate,
      file_path: filePath,
      filename: fileName,
      id: null,
      issued_by: 'N/A',
      issued_date: new Date(),
      last_modified_by: null,
      last_reminder_date: null,
      last_updated_at: null,
      owner: null,
      policy_no: 'N/A',
      reminder_count: null,
      reminder_status: null,
      status: null,
      exists: false,
      expired: false,
      expiry_date_str: null,
      issued_date_str: null,
      s3_url: null,
      sync_status: 0
    };
    console.log("CompanyCompliance",company_compliance);
    debugger
    let res: any = await this.CommonService.updateRecord('CompanyCompliance', company_compliance);
    console.log("Res",res);
    this.docPdfUrl = this.env.apiRoot + company_compliance.file_path
    this.displayPdf = true;
  }

  async acceptTerms() {
    this.loading = true;
    if(this.companyCompliance != undefined){
      this.crudService.Delete( this.companyCompliance.id, 'CompanyCompliance').subscribe(() => {
      });
    }
    let resp = this.crudService.uploadImage(this.signaturePad.toDataURL(), 'SLA', this.companyId, this.staff_Id, 'LOCAL');
    console.log("this.signaturePad",resp);
    let response = JSON.parse(resp)
    let reportId = 'EFI';
    let date = new Date();
    this.acceptedDatetime = date;
    let tempTime = date.getTime();
    let fileDesc = 'EFI';
    let data: any = await this.loadData();
    const fileName = fileDesc + '-' + tempTime + '.pdf';
    const filePath = 'C:/companyCompliances/' + this.companyId + '/compliance_docs/staff/' + this.staff_Id+ '/' + fileName;
    data[0].newReportData.filename = fileName;
    data[0].newReportData.filepath = filePath;

    let res = await this.crudService.generateEFIReport(this.companyId, this.staff_Id, data, reportId, tempTime, 1).toPromise();
    console.log(res);
    this.saveSLACompliance(this.companyId + '/compliance_docs/staff/' + this.staff_Id + '/' + fileName, fileName);
    //this.companyId + '/' + 'report-template-' + reportId + '-' + tempTime + '.pdf';
    // let blob = new Blob([res], { type: "application/pdf" });
    // const objectUrl = URL.createObjectURL(blob);
    // this.docPdfUrl = this.sanitizer.bypassSecurityTrustResourceUrl(objectUrl);
    this.docPdfUrl = this.env.apiRoot + '/compliance_docs/staff/' + this.staff_Id + '/' + fileName
    this.InductionStatus = 'Yes';
    this.eventsSubject.next();
    this.displayPdf = true;
    this.currentOTPData.status = 1
    let ress: any =  this.CommonService.saveRecord('StaffOTPTransact', this.currentOTPData);
    document.getElementById("sigContainer").style.display = "none";
    this.messageService.add({ severity: 'success', summary: 'Successful', detail: 'EF Induction Completed', life: 1000 });
    this.show_sign = true
     this.loading = false;
  }

  async loadData() {
    let companyInfo = await this.crudService.getDataByField('Company', 'id', this.staff.company_id).toPromise();
    console.log("companyInfo",companyInfo);
    
    companyInfo[0].fullname = this.staff.first_name + ' ' + this.staff.last_name;
    let date =new Date()
    companyInfo[0].date = this.datePipe.transform(date,'dd/MM/yyyy hh:mm a');
    let reportData = {
      TEMPLATE_ID: 'C://Source//Report_Templates//EFI.docx',
      ImageURL: 'C://uploadImgs//SLA//' + this.companyId + '//' + this.staff_Id + '.png',
      CompanyDetails: companyInfo[0]
    };
    let res: any;
    let data = [
      {
        info: {
          id: '67993bdd-f7d9-48a6-93d9-8026b657041a',
          CompanyID: this.companyId,
          reportid: 'EFI'
        },
        newReportData: reportData,
      }
    ];
    return data;



  }

  async loadComplianceInfoSPA() {
    let data: any = await this.crudService.getDataByField('CompanyCompliance', 'company_id', this.companyId).toPromise();
    if(environment.hosting == 'AU')
    {
      console.log("data",data);
      
      this.slaData = data.filter(val => (val.compliance_id == '75' && val.status == '1'));
    }
    else if(environment.hosting == 'UK')
    {
      this.slaData = data.filter(val => (val.compliance_id == '165' && val.status == '1'));
    }
    
    if (this.slaData.length > 0) {
      this.companyCompliance = this.slaData[0];
      this.signaturePath = 'C://uploadImgs//SLA//' + this.companyId + '//' + this.companyId + '.png';
      this.acceptedDatetime = this.companyCompliance.issued_date;
      this.isAccepted = true;
      this.docPdfUrl = null;
      this.pdfUrl = null;
      this.displayPdf = true;
      this.signedBy = this.companyCompliance.comment;
      this.docPdfUrl = this.env.apiRoot + this.companyCompliance.file_path
      this.pageloaded =true
    } else {
      this.signaturePath = null;
      this.acceptedDatetime = null;
      this.isAccepted = false;
      this.displayPdf = false;
      this.show_sign=false
      // this.signaturePad.clear();
      this.docPdfUrl =  this.env.apiRoot +'1/compliance_docs/SLA.pdf'
      this.signedBy = this.staffInfo.first_name + ' ' + this.staffInfo.last_name;
      this.pageloaded =true
    }
    this.loading = false;
    this.visible= true 
    // this.isAccepted = false 
  }


  async saveSPACompliance(filePath, fileName) {
    let expiryDate = new Date(new Date().getTime() + (1000 * 60 * 60 * 24 * 365 * 2));
    let company_compliance = {
      amount: null,
      comment: this.signedBy,
      company_id: this.companyId,
      staff_id: null,
      compliance_id: this.slaId,
      compliance_type_id: null,
      created_at: new Date(),
      created_by: '1',
      expiry_date: expiryDate,
      file_path: filePath,
      filename: fileName,
      id: null,
      issued_by: null,
      issued_date: this.CommonService.getCurrentDateTime(),
      last_modified_by: null,
      last_reminder_date: null,
      last_updated_at: null,
      owner: null,
      policy_no: null,
      reminder_count: null,
      reminder_status: null,
      status: null,
      exists: false,
      expired: false,
      expiry_date_str: null,
      issued_date_str: null,
      s3_url: null,
      sync_status: 0,
    };
    let res: any = await this.CommonService.updateRecord('CompanyCompliance', company_compliance);
    this.crudService.fetchComplianceFile(this.companyId, company_compliance.file_path, company_compliance.filename).subscribe(blob => {
      const objectUrl = URL.createObjectURL(blob);
      this.pdfUrl = this.sanitizer.bypassSecurityTrustResourceUrl(objectUrl);
      this.displayPdf = true;
    })
  }

  async acceptTermsSPA() {
    this.loading = true;
    if(this.companyCompliance !== undefined){
      this.companyCompliance.status='2';  
      await this.CommonService.updateRecord('companycompliance', this.companyCompliance);
    }
    let resp = this.crudService.uploadImage(this.signaturePad.toDataURL(), 'SLA', this.companyId, this.companyId, 'LOCAL');
    let response = JSON.parse(resp)
    let reportId = 'SLA';
    let date = new Date();
    this.acceptedDatetime = date;
    let tempTime = date.getTime();
    let fileDesc = 'SLA';
    let data: any = await this.loadDataSLA();
    const fileName = fileDesc + '-' + tempTime + '.pdf';
    const filePath = 'C:/companyCompliances/' + this.companyId + '/compliance_docs/' + fileName;
    data[0].newReportData.filename = fileName;
    data[0].newReportData.filepath = filePath;
    data[0].newReportData.AuthSign = "C:/uploadImgs/SPA/AuthSign.png";
    let res = await this.crudService.generateSLAReport(this.companyId, data, reportId, tempTime, 1).toPromise();
    await this.saveSPACompliance(this.companyId + '/compliance_docs/' + fileName, fileName);
    //  await this.loadComplianceInfoSPA();
    // this.updateEvent.emit(true);
    this.docPdfUrl = this.env.apiRoot + this.companyId  + '/compliance_docs/' + fileName
    this.displayPdf = true;
    this.currentOTPData.status = 1
    let ress: any =  this.CommonService.saveRecord('StaffOTPTransact', this.currentOTPData);
    document.getElementById("sigContainer").style.display = "none";
    this.messageService.add({ severity: 'success', summary: 'Successful', detail: 'SPA Signed and Submitted Successfully', life: 1000 });
    this.show_sign = true
     this.loading = false;

   
    /* this.confirmationService.confirm({
      message: 'Do you want to view the report in separate Window?',
      header: 'Confirm',
      icon: 'pi pi-exclamation-triangle',
      acceptLabel: 'Yes, Open in new Tab',
      rejectLabel: 'No, Download the File',
      accept: () => {
        let blob = new Blob([res], { type: "application/pdf" });
        const objectUrl = URL.createObjectURL(blob);
        window.open(objectUrl);
        this.loading = false;
      },
      reject: () => {
        import('file-saver').then(FileSaver => {
          let blob = new Blob([res], { type: "application/pdf" });
          const a = document.createElement('a')
          const objectUrl = URL.createObjectURL(blob)
          a.href = objectUrl
          a.download = 'SLA (' + this.companyId + ')';
          a.click();
          URL.revokeObjectURL(objectUrl);
          this.loading = false;
        })
      }
    }) */
  }

  async loadDataSLA() {
    let companyInfo = await this.crudService.getDataByField('Company', 'id', this.companyId).toPromise();
    companyInfo[0].signed_by = this.signedBy
    companyInfo[0].agreement_date = new Date().toLocaleDateString("en-GB");
    companyInfo[0].commencement_date = new Date().toLocaleDateString("en-GB");
    let reportData = {
      TEMPLATE_ID: 'C://Source//Report_Templates//SLA.docx',
      ImageURL: 'C://uploadImgs//SLA//' + this.companyId + '//' + this.companyId + '.png',
      CompanyDetails: companyInfo[0]
    };
    let res: any;
    let data = [
      {
        info: {
          id: '67993bdd-f7d9-48a6-93d9-8026b657041a',
          CompanyID: this.companyId,
          reportid: 'SLA',
        },
        newReportData: reportData,
      }
    ];
    return data;



  }
  fileLoaded() {
    this.loading = false;
    //console.log('file Loaded');
  }
  toTop() {
    window.scrollTo(0,0);
    this.isSigned = false;
    this.isChecked = false;
  }
  async resign(){
    this.loading = true;
    this.companyCompliance.status='2';
    await this.CommonService.updateRecord('companycompliance', this.companyCompliance);
    await this.loadComplianceInfoSPA();
    this.loading = false;
  }

  async verifyOTP(){
    let otp_details :any  = await this.crudService.getDataByField('StaffOTPTransact', 'otp', this.entered_OTP).toPromise();
    console.log(otp_details);
    let filtered_otp = otp_details.filter(val => (val.status == 0 ));
    if(filtered_otp.length>0 && filtered_otp[0].staff_id == this.staff_Id && filtered_otp[0].company_id == this.companyId && filtered_otp[0].page == this.current_page){
      this.currentOTPData = filtered_otp[0]
      console.log("this.currentOTPData",this.currentOTPData);
      this.messageService.add({ severity: 'success', summary: 'Successful', detail: 'Verified Successfully', life: 1000 });
      this.visible= false 
      this.isAccepted = false  
    }
    else{
      this.messageService.add({severity: 'error', summary: 'Error', detail: 'Invaid OTP, Enter the correct OTP or Resend OTP', life: 3000 });
    }
  }

  async resendOTP(){
    this.entered_OTP ='';
    let otp_datas:any  = await this.crudService.getDataByField('StaffOTPTransact', 'staff_id', this.staff_Id).toPromise();
     let filtered_otp = otp_datas.filter(val => (val.status == 0 && val.page == this.current_page));
     filtered_otp?.forEach( val => {
       val.status= 2
      let ress: any =  this.CommonService.saveRecord('StaffOTPTransact', val);
     })

  var random_OTP : any = Math.floor(100000 + Math.random() * 900000);
  random_OTP = random_OTP.toString().substring(0, 4);
  random_OTP =  parseInt(random_OTP);

  let otp_details ={
    id : null,
    company_id : this.companyId,
    staff_id : this.staff_Id,
    otp :random_OTP,
    status  :0,
    page : this.current_page,
    email_to :null,
    phone_to  :null,
    created_at  :  new Date(), 
    created_by : this.staff.id,
  }
  if(this.sendlinkvia == false){
    otp_details.email_to = this.staff.email
  }
  else{
    otp_details.phone_to = this.staff.mobile
  }
  let res: any = await this.CommonService.saveRecord('StaffOTPTransact', otp_details);
  console.log("Res",res);
  var page_name
  if(this.page == "EFinduction"){page_name = 'EF-induction'}
  else{page_name = 'SPA' }
    let params = {
      email: {
        to: this.staff.email, cc: '', bcc: null,
        msg: 'Your OTP (One Time Password) for access '+page_name+' is ' + '<h4>'+random_OTP+'</h4>'
         + 'Please use this password to access and sign the document. Do not share this password with anyone',
        subject: 'Verification Code To Access '+page_name+' ',
      },    type: 'sms-email',
      selectedTpl: null,
      sufix: null,
    }
    if(this.sendlinkvia == "false"){
      this.sendOTPMail(params);
    }
    else if(this.sendlinkvia == "true"){
      let params = {
         sms: {
          to: this.staff.mobile,
          message: 'Your OTP (One Time Password) for access '+page_name+' is '+ random_OTP
          + '. Please use this password to access and sign the document. Do not share this password with anyone',
         subject:'Verification Code To Access '+page_name+'',
        },   type: 'sms-email',
        selectedTpl: null,
        sufix: null,
      }
      this.crudService.sendTMV2Message(params).subscribe((res2: any[]) => {
        console.log(res2);
        if(res2){
          this.messageService.add({ severity: 'success', summary: 'Successful', detail: 'OTP SMS Sent ', life: 1000 });
        }
      });
      }
    }
    sendOTPMail(obj) {
      const datobj2 = {
        subject :obj.email.subject,
        body : obj.email.msg,
        toEmail : obj.email.to,
        ref_id:this.staff.id,
        ref_type:'accounts'
        }
        console.log("datobj2",datobj2)
      this.crudService.sendmailSendgrid(datobj2,'commonMail').subscribe((res2: any) => { 
        this.loading = false;
        if(res2.success == 200)
        {
          this.messageService.add({ severity: 'success', summary: 'Successful', detail: 'Email Sent', life: 3000 });
        }
        else
        {
          console.log("res2.Data.body.errors[0].message",res2);
          let message = res2.Data.response.body.errors[0].message;
          this.messageService.add({ severity: 'error', summary: 'Error', detail: message, life: 3000 });
        }
       });
    }
}
