
import { CUSTOM_ELEMENTS_SCHEMA,NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { DropdownModule } from 'primeng/dropdown';
import { TableModule } from 'primeng/table';
import { ButtonModule } from 'primeng/button';
import { InputMaskModule } from 'primeng/inputmask';
import { InputNumberModule } from 'primeng/inputnumber';
import { InputSwitchModule } from 'primeng/inputswitch';
import { InputTextModule } from 'primeng/inputtext';
import { InputTextareaModule } from 'primeng/inputtextarea';
import { ConfirmDialogModule } from 'primeng/confirmdialog';
import { DialogModule } from 'primeng/dialog';
import { FileUploadModule } from 'ng2-file-upload';
import { DynamicDialogRef } from 'primeng/dynamicdialog';
import { DynamicDialogConfig } from 'primeng/dynamicdialog';
import { TooltipModule } from 'primeng/tooltip';
import { GooglePlaceModule } from "ngx-google-places-autocomplete";
import { CalendarModule } from 'primeng/calendar';
import { FullCalendarModule } from 'primeng/fullcalendar';
import { TabMenuModule } from 'primeng/tabmenu';
import { TabViewModule } from 'primeng/tabview';
import { Ng2ImgMaxModule } from 'ng2-img-max';
import { MultiSelectModule } from 'primeng/multiselect';
import { CheckboxModule } from 'primeng/checkbox';
import { CardModule } from 'primeng/card';
import { ToastModule } from 'primeng/toast';
import { EditorModule } from 'primeng/editor';
import { AutoCompleteModule } from 'primeng/autocomplete';
import { ProgressBarModule } from 'primeng/progressbar';
import { RadioButtonModule } from 'primeng/radiobutton';
import { ToggleButtonModule } from 'primeng/togglebutton';
import { SelectButtonModule } from 'primeng/selectbutton';
import { SharedModule } from '../shared/shared.module';
import { SignaturePadModule } from 'angular2-signaturepad';
import { DragDropModule } from '@angular/cdk/drag-drop';
// import { TimelineModule } from 'primeng/timeline';
import { JobRoutingModule } from './job-routing.module';
import { JobOperationsComponent } from './job-operations/job-operations.component';
import { JobDetailsComponent } from './job-details/job-details.component';

// import { JobDetailsApprovalsComponent } from './job-details-approvals/job-details-approvals.component';
//import { JobDetailsApprovalsComponent } from './job-details-approvals/job-details-approvals.component';
import { JobScheduleComponent } from './job-schedule/job-schedule.component';
import { JobDocumentsComponent } from './job-documents/job-documents.component';
import { JobCostingComponent } from './job-costing/job-costing.component';
import { ScheduleCreateComponent } from './schedule-create/schedule-create.component';
import { JobScheduleCreateComponent } from './job-schedule-create/job-schedule-create.component';
import { JobImagesComponent } from './job-images/job-images.component';
import { ApproveQuoteComponent } from './approve-quote/approve-quote.component';
import { RejectQuoteComponent } from './reject-quote/reject-quote.component';
import { SendQuoteComponent } from './send-quote/send-quote.component';
import { SubContractAgreementCreateComponent } from './sub-contract-agreement-create/sub-contract-agreement-create.component';
import { JobInvoiceSendComponent } from './job-invoice-send/job-invoice-send.component';
import { WpCostingComponent } from './wp-costing/wp-costing.component';
import { JobKpiComponent } from './job-kpi/job-kpi.component';
import { JobAdminComponent } from './job-admin/job-admin.component';
import { ScheduleAdminComponent } from './schedule-admin/schedule-admin.component';
import { CommunicationAdminComponent } from './communication-admin/communication-admin.component';
import { JobDetailHeader } from '../modules/job-detail-header/job-detail-header.component'
import { JobRiskcontactComponent } from '../modules/job-riskcontact/job-riskcontact.component';
import { SidebarModule } from 'primeng/sidebar';
import { JobDetailsApprovalsComponent } from './job-details-approvals/job-details-approvals.component';
import { ShfComponent } from '../job/shf/shf.component';
import { TreeInfoComponent } from '../modules/tree-info/tree-info.component';
import { WorkflowParentComponent } from  './workflow-parent/workflow-parent.component'
import { WorkflowChildComponent } from  './workflow-child/workflow-child.component';
import { WorkflowComponent } from './workflow/workflow.component'
import { JobChecklistComponent } from './job-checklist/job-checklist.component';
import { WorkflowTimelineComponent } from './workflow-timeline/workflow-timeline.component'
import { NgxDocViewerModule } from 'ngx-doc-viewer';
   
import { DatePipe } from '@angular/common';  
import { MailDocumentComponent } from './mail-document/mail-document.component';
@NgModule({
  declarations: [
    JobOperationsComponent,
    JobDetailsComponent,
    JobDetailsApprovalsComponent,
    JobScheduleComponent,
    JobDocumentsComponent,
    JobCostingComponent,
    ScheduleCreateComponent,
    JobScheduleCreateComponent,
    JobImagesComponent,
    WpCostingComponent,
    ApproveQuoteComponent, 
    RejectQuoteComponent,
    SendQuoteComponent,
    SubContractAgreementCreateComponent,
    JobInvoiceSendComponent,
    JobKpiComponent,
    JobAdminComponent,
    ScheduleAdminComponent,
    CommunicationAdminComponent,
    JobDetailHeader,
    JobRiskcontactComponent,
    ShfComponent,
    TreeInfoComponent,
    WorkflowParentComponent,
    WorkflowChildComponent,
    WorkflowComponent,
    JobChecklistComponent,
    WorkflowTimelineComponent,
    MailDocumentComponent
  ],
  exports: [
    JobOperationsComponent,
    JobDetailsComponent,
    JobDetailsApprovalsComponent,
    JobScheduleComponent,
    JobDocumentsComponent,
    JobCostingComponent,
    ScheduleCreateComponent,
    JobScheduleCreateComponent,
    JobImagesComponent,
    WpCostingComponent,
    ApproveQuoteComponent, 
    RejectQuoteComponent,
    SendQuoteComponent,
    SubContractAgreementCreateComponent,
    JobInvoiceSendComponent,
    JobKpiComponent,
    JobAdminComponent,
    ScheduleAdminComponent,
    CommunicationAdminComponent,
    JobDetailHeader,
    JobRiskcontactComponent,
    ShfComponent,
    TreeInfoComponent,
    WorkflowChildComponent,
    WorkflowComponent,
    JobChecklistComponent
  ],
  imports: [
    CommonModule,SidebarModule,SignaturePadModule,
    JobRoutingModule,FormsModule, ReactiveFormsModule,ConfirmDialogModule,DialogModule,TableModule,InputMaskModule,InputNumberModule,InputSwitchModule,InputTextModule,InputTextareaModule,
    ButtonModule,FileUploadModule,DropdownModule,TooltipModule,GooglePlaceModule,CalendarModule,EditorModule,AutoCompleteModule, ToggleButtonModule, SelectButtonModule,
    FullCalendarModule,TabMenuModule,TabViewModule,Ng2ImgMaxModule,MultiSelectModule,CheckboxModule,CardModule,ToastModule,
    ProgressBarModule,RadioButtonModule,SharedModule,DragDropModule,NgxDocViewerModule
  ],
  providers: [
    DynamicDialogRef, DynamicDialogConfig,DatePipe
],
  schemas: [
    CUSTOM_ELEMENTS_SCHEMA
],
})

export class JobModule { }