import { Component, OnInit,Output,EventEmitter, Input } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { DomSanitizer } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { ConfirmationService, MessageService } from 'primeng/api';
import { DialogService, DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { CRUDService } from 'src/app/utilities/crud.service';
import { JOBService } from 'src/app/utilities/job.service';
import { JobDocumentsComponent } from '../../job/job-documents/job-documents.component';
import { JobScheduleComponent } from '../../job/job-schedule/job-schedule.component';
import { JobImagesComponent } from '../../job/job-images/job-images.component';
import { ComplianceComponent } from '../../admin/compliance/compliance.component';
import { Table } from 'primeng/table';
import { ProceedConfirmationComponent } from '../../modules/proceed-confirmation/proceed-confirmation.component';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-tp-job-admin',
  templateUrl: './tp-job-admin.component.html',
  styleUrls: ['./tp-job-admin.component.scss'],
  providers: [MessageService, ConfirmationService, JobImagesComponent, JobScheduleComponent, JobDocumentsComponent]
})

export class TpJobAdminComponent implements OnInit {
  @Output() emitEventToParent: EventEmitter<any> = new EventEmitter();
  riskContactdata: any;
  firstname='';
  lastname='';
  phone='';
  dateFormat:any;
  jobCreationForm: FormGroup;
  companyId: any;
  index: any;
  depots: any[];
  tpList: any[] = [];
  selectedDepots: any;
  companyList: any[];
  selectedCompany: any;
  providerUsers: any[];
  selectedProviderUsers: any;
  selectedContact: any;
  contactPhone: any;
  contactName: any;
  contactEmail: any;
  job_desc: any;
  JobPriories: any[];
  totalListSize: any;
  selectedPriority: any;
  JobRequirements: any[];
  selectedJobRequirements: any
  jobActions: any[];
  selectedJobAction: any;
  JobEvents: any[];
  selectedJobEvent: any;
  tree_work: any[];
  selectedTreeWork: any[] = [];
  tree_condition: any[] = [];
  selectedTreeCondition: any[] = [];
  tree_description: any[] = [];
  isCouncilAppr: Boolean;
  selectedTreeDescription: any[] = [];
  jobId: any;
  riskContact: any = false;
  component_info = '';
  isSaved: Boolean;
  showEmailDialog: Boolean = false;
  checkOn: any;
  complianceLevelId: any;
  interStateSearch: any;
  jobAllocations: any[] = [];
  countryList: any[] = [];
  stateList: any[] = [];
  selectedState: any;
  selectedCountry: any;
  userDetails: any;
  JobInfo: any;
  orderInfo: any;
  JobRiskContacts: any[] = [];
  submitted: any;
  isContactNotification: any = false;
  jobReceviedialog: any;
  sendMessageData: any
  sendSMSData: any;
  clientEmail: Boolean = false;
  clientSms: Boolean = false;
  siteEmail: Boolean = false;
  siteSms: Boolean = false;
  cols: any[];
  selectedTPList: any[];
  jobAllocationList: any[];
  selectedTPAllocationList: any[];
  result = [];
  adTpChecked: any;
  allTPList: any[] = [];
  selectedTreePro: any = {};
  datobj: any;
  rangeOn: Boolean;
  showConfirmDialog: Boolean;
  workOperationsInfo: any;
  WPName: any;
  tempList: any[] = [];
  progress: any = '';
  failList: any[] = [];
  jobAddr: any;
  loading: Boolean = false;
  showComplianceInfo: Boolean = false;
  complianceInfoList: any[] = [];
  allocId: any = '1';
  showFlag: boolean = false;
  loadData: Boolean = false;
  isActive: boolean = true;
  offerData = {
    offerCcEmail: null,
    offerBccEmail: 'shri@envirofrontier.com.au',
    offerSubject: null,
    offerEmailContent: null,
    offerMsgSMS: null
  }
  apprData = {
    apprCcEmail: null,
    apprBccEmail: 'shri@envirofrontier.com.au',
    apprSubject: null,
    apprEmailContent: null,
    apprMsgSMS: null
  }
  ClientMessageData = {
    ClientToEmail: null, ClientMessageCcEmail: null, ClientMessageBccEmail: 'shri@envirofrontier.com.au',
    ClientMessageSubject: 'Enviro Trees Job Received Notification - Job No - Claim Number Test Job',
    ClientMessageEmailContent: 'Hi Team, Please be advised job has been logged in our system and a tree professional had been assigned to contact insured and attend site. Further updates will be provided as we progress. Please contact us for queries and refer to TM . Regards, Customer Care Team - Enviro Trees',
    ClientMessageSMS: null,
    Subject: null, EmailContent: null, SMS: null,
  }

  ContactMessageData = {
    ContactToEmail: null, ContactMessageCcEmail: null,
    ContactMessageBccEmail: 'shri@envirofrontier.com.au', ContactMessageSubject: null,
    ContactMessageEmailContent: null, ContactSMSNumber: null, ContactSMSMessage: null, Subject: null, EmailContent: null
  }

  filteredPlaces: any[] = [];
  inputAddress:any = [];

  text1: string = '<div>Hi Enviro Team</div>';
  env: any;
  JobHoldReasonList: any[] = [];
  JobCancelledReasonList: any[] = [];
  showCancelJobDialog: Boolean = false;
  selectedReason: any;
  status_reopen_dt: any;
  jobStatus: any;
  status_reason_other: any;
  wizardMetaData = [
    { id: 'tab-job', title: 'Home', class: 'active', iconClass: 'fa fa-home', approved: false, acceptanceCriteria: ['Lorem Ipsum is simply dummy text of the printing and typesetting industry', 'Lorem Ipsum is simply dummy text of the printing and typesetting industry', 'Lorem Ipsum is simply dummy text of the printing and typesetting industry'] },
    { id: 'tab-contact', title: 'Insured Details', iconClass: 'fa fa-phone', approved: false, acceptanceCriteria: ['Lorem Ipsum is simply dummy text of the printing and typesetting industry', 'Lorem Ipsum is simply dummy text of the printing and typesetting industry', 'Lorem Ipsum is simply dummy text of the printing and typesetting industry'] },
    { id: 'tab-allocation', title: 'Allocation', iconClass: 'fa fa-puzzle-piece', approved: false, acceptanceCriteria: ['Lorem Ipsum is simply dummy text of the printing and typesetting industry', 'Lorem Ipsum is simply dummy text of the printing and typesetting industry', 'Lorem Ipsum is simply dummy text of the printing and typesetting industry'] },
    { id: 'tab-schedule', title: 'Schedule & Comm', iconClass: 'fa fa-envelope', approved: false, acceptanceCriteria: ['Lorem Ipsum is simply dummy text of the printing and typesetting industry', 'Lorem Ipsum is simply dummy text of the printing and typesetting industry', 'Lorem Ipsum is simply dummy text of the printing and typesetting industry'] },
    { id: 'tab-document', title: 'Documents', iconClass: 'fa fa-file-text-o', approved: false, acceptanceCriteria: ['Lorem Ipsum is simply dummy text of the printing and typesetting industry', 'Lorem Ipsum is simply dummy text of the printing and typesetting industry', 'Lorem Ipsum is simply dummy text of the printing and typesetting industry'] },
    { id: 'tab-image', title: 'Images', iconClass: 'fa fa-picture-o', approved: false, acceptanceCriteria: ['Lorem Ipsum is simply dummy text of the printing and typesetting industry', 'Lorem Ipsum is simply dummy text of the printing and typesetting industry', 'Lorem Ipsum is simply dummy text of the printing and typesetting industry'] },
    { id: 'tab-canceljob', title: 'Update Job Status', iconClass: 'fa fa-check-square', approved: false, acceptanceCriteria: ['Lorem Ipsum is simply dummy text of the printing and typesetting industry', 'Lorem Ipsum is simply dummy text of the printing and typesetting industry', 'Lorem Ipsum is simply dummy text of the printing and typesetting industry'] },
  ]
  constructor(
    private route: ActivatedRoute,
    private crudService: CRUDService,
    private messageService: MessageService,
    private confirmationService: ConfirmationService,
    private router: Router,
    public ref: DynamicDialogRef,
    public config: DynamicDialogConfig,
    public JobService: JOBService,
    public sanitizer: DomSanitizer,
    public dialogService: DialogService,
  ) {
    this.companyId = sessionStorage.getItem('company_id');
    this.userDetails = JSON.parse(sessionStorage.getItem('currentUser'));
    //console.log('userinfo', this.userDetails);
  }

  componentForm = {
    street_number: 'short_name',
    route: 'long_name',
    locality: 'long_name',
    sublocality_level_1: 'long_name',
    administrative_area_level_1: 'short_name',
    country: 'long_name',
    postal_code: 'short_name'
  };
  options = {
    componentRestrictions: {
      country: ['AU']
    }
  }
  jobInfoActive = true;
  contactInfoActive = false;
  allocationInfoActive = false;
  scheduleInfoActive = false;
  documentInfoActive = false;
  imageInfoActive = false;
  allCompaniesList: any[];
  async ngOnInit() {
    this.env = environment;
    this.dateFormat = environment.dateFormat;
    this.loading = true;
    if(this.config.data.jobId !==0){
      this.loading = true;
      this.crudService.getDataByField('jobriskcontacts', 'job_id', this.config.data.jobId).subscribe((data: any[]) => {
        this.riskContactdata = data;
        let index = this.riskContactdata.findIndex(val =>(val.contact_type == '1'));
        let index1 = this.riskContactdata.findIndex(val =>(val.contact_type == '2'));
        let res = this.riskContactdata.find(val =>(val.contact_type == '2'));
        if( index == -1 && index1 !=-1){
          this.firstname=this.riskContactdata[index1].first_name;
          this.lastname=this.riskContactdata[index1].last_name;
          this.phone=this.riskContactdata[index1].contact_best;
        }
        else if(index == 0){
          this.firstname=this.riskContactdata[index].first_name;
          this.lastname=this.riskContactdata[index].last_name;
          this.phone=this.riskContactdata[index].contact_best;
        }
        
      }); 
    }
    this.crudService.getAll('Country').subscribe((countryData: any[]) => {
      this.countryList = countryData;

      this.crudService.getAll('State').subscribe((stateData: any[]) => {
        this.stateList = stateData;

        this.selectedCompany = null;
        this.selectedJobRequirements = null;
        this.selectedJobAction = null;
        this.selectedJobEvent = null;
        this.selectedPriority = null;

        this.crudService.getAllProviders().subscribe((data: any[]) => {
          data.sort((a, b) => a.business_name > b.business_name && 1 || -1);
          this.allCompaniesList = data;
          this.crudService.getAllJobRequirements().subscribe((JobRequirementsData: any) => {
            JobRequirementsData.sort((a, b) => a.job_req_name > b.job_req_name && 1 || -1);
            this.JobRequirements = JobRequirementsData;
            this.crudService.getAll('JobActions').subscribe((jobActionsData: any) => {
              jobActionsData.sort((a, b) => a.job_action_name > b.job_action_name && 1 || -1);
              this.jobActions = jobActionsData;
              this.jobActions = [{id: '0', job_action_name: 'N/A', job_action_desc: 'data', status: '2'}, ...this.jobActions]
              this.selectedJobAction=this.jobActions[0];
              this.crudService.getAll('JobPriority').subscribe((JobPrioriesData: any) => {
                JobPrioriesData.sort((a, b) => a.job_priority_name > b.job_priority_name && 1 || -1);
                this.JobPriories = JobPrioriesData;
                this.crudService.getAll('JobEvents').subscribe((JobEventsData: any) => {
                  JobEventsData.sort((a, b) => a.event_name > b.event_name && 1 || -1);
                  this.JobEvents = JobEventsData;
          

                  if (typeof this.config.data === 'undefined' || typeof (this.config.data.jobId) === 'undefined') {
                    this.jobId = parseInt(this.route.snapshot.paramMap.get('jobId'));
                  } else {
                    this.jobId = this.config.data.jobId;
                    this.config = null;
                  }
                  if (this.jobId !== 0 && this.jobId != null) {
                    this.jobEditWithJobId();
                  } else {
                    this.companyList = this.allCompaniesList.filter(val => val.status !== '2');
                    this.loading = false;
                  }
                  this.enableTabs();
                })
              })
            })
          })
        });
      });
    });

    this.jobCreationForm = new FormGroup({
      business_name: new FormControl(''),
      //  contact: new FormControl('',),
      job_contact_name: new FormControl(''),
      job_contact_best: new FormControl(''),
      job_contact_alt: new FormControl(''),
      job_desc: new FormControl(''),
      job_sp_desc: new FormControl(''),
      job_req_name: new FormControl(''),
      job_action_name: new FormControl(''),
      job_priority_name: new FormControl(''),
      event_name: new FormControl(''),
      risk_street_address: new FormControl(''),
      risk_suburb: new FormControl(''),
      risk_postcode: new FormControl(''),
      state: new FormControl(''),
      country: new FormControl('')
    })

    this.isActive = true;
    this.JobInfo = {
      job_no: null,
      job_type_id: null,
      job_desc: null,
      job_sp_desc: null,
      job_ref_id: null,
      job_req_id: null,
      job_req_action_id: null,
      job_priority_id: null,
      job_event_id: null,
      job_claim_no: null,
      job_provider_ref: null,
      job_recd_dt: (new Date()),
      job_act_dt: (new Date()),
      status: null,
      job_temp_no: null,
      companyId: null,
      job_contact_id: null,
      job_contact_name: null,
      job_contact_best: null,
      job_contact_alt: null,
      risk_street_address: null,
      risk_street_address_2: null,
      risk_addl_address: null,
      risk_suburb: null,
      risk_postcode: null,
      risk_state_id: null,
      risk_country_id: null,
      wf_status_id: null,
      old_job_no: null,
      job_depot_id: null,
      status_reason: null,
      status_reason_other: null,
      status_reopen_dt: null,
      risk_lng: null,
      risk_lat: null,
      process_id: this.companyId,
      council_approval_reqd: null,
      job_type: null,
      client_category: null,
      job_company_id: null,
      created_by: this.userDetails.userId,
      created_at: null,
      updated_by: null,
      updated_at: null
    };
  }

  onChangeCompany(company) {
    this.selectedCompany = company;
    this.JobInfo.companyId = this.selectedCompany.id;
    this.WPName = this.selectedCompany.business_name.replace('&', 'and');

    this.crudService.getContactsByCID(this.selectedCompany.id).subscribe((users: any[]) => {
      users.sort((a, b) => a.position_name > b.position_name && 1 || -1);
      this.providerUsers = users;
    })
    if (this.selectedCompany.id == 126) {
      this.JobInfo.job_desc = 'SES- Make Safe. Cut and leave only.';
      this.JobInfo.job_req_id = '11';
    }
    if ((this.selectedCompany.id == 366) || (this.selectedCompany.id == 578)) {
      this.JobInfo.job_desc = 'Please Note: CUSTOMER SIGN OFF is Required for this job.';
    }
    if ((this.selectedCompany.id == 832)) {
      this.JobInfo.job_desc = 'Please do not forget to load photos of all safety equipment used on site. Like safety cones, barrier tape, PPE or equipment.';
    }
    if (this.selectedCompany.compliancelevel_id != null) {
      this.complianceLevelId = this.selectedCompany.compliancelevel_id;
    } else {
      this.complianceLevelId = 43;
    }
    if (this.selectedCompany.id == 745) {
      if ((this.JobInfo.job_desc == '') || (this.JobInfo.job_desc == null)) {
        this.JobInfo.job_desc = 'LM - Please also take photo of front of property and upload as before image.';
      }
    }
    if (this.selectedCompany.id == 743) {
      if ((this.JobInfo.job_sp_desc == '') || (this.JobInfo.job_sp_desc == null)) {
        this.JobInfo.job_sp_desc = 'Please take a close up photo of the rear of the sign, clearly showing the yellow sticker and the number on it.';
      }
    }
    if ((this.selectedCompany.client_subtype == 10047) || (this.selectedCompany.client_subtype == 10097)) {
      if ((this.JobInfo.job_desc == '') || (this.JobInfo.job_desc == null)) {
        this.JobInfo.job_desc = 'INS � Provide cost and photos for approval prior to proceeding.';
      }
    }
    if (this.selectedCompany.id == null) {
      this.crudService.getDefaultPriorities().subscribe((data: any) => {
        //console.log(data);
        data.sort((a, b) => a.job_priority_name > b.job_priority_name && 1 || -1);
        this.JobPriories = data;
      });
    } else if (this.selectedCompany.id != null) {
      this.crudService.getAllByCompanyId(this.selectedCompany.id).subscribe((data: any[]) => {
        //console.log(data);
        data.sort((a, b) => a.job_priority_name > b.job_priority_name && 1 || -1);
        if (data.length > 0) {
          this.JobPriories = data;
        } else {
          this.crudService.getDefaultPriorities().subscribe((data: any[]) => {
            //console.log(data);
            data.sort((a, b) => a.job_priority_name > b.job_priority_name && 1 || -1);
            this.JobPriories = data;
          });
        }
      });
    }
    this.crudService.getByDepotCID(this.selectedCompany.id).subscribe((res: any[]) => {
      res.sort((a, b) => a.depot_name > b.depot_name && 1 || -1);
      this.depots = res;
      if (res.length > 1) {
        this.JobInfo.job_depot_id = this.depots[0].id;
      }
    })
  }

  onChangeContact(selectedContact) {
    this.providerUsers.forEach((contact) => {
      if (selectedContact.id === contact.id) {
        this.JobInfo.job_contact_id = contact.id
        this.JobInfo.job_contact_name = contact.name;
        this.JobInfo.job_contact_alt = contact.email;
        this.JobInfo.job_contact_best = contact.phone;
      }
    })
  }



  onChangeDepot(selectedDepot) {
    this.depots.forEach((depot) => {
      if (selectedDepot.id === depot.id) {
        // this.JobInfo.job_contact_id = contact.id
        this.JobInfo.job_depot_id = depot.id;
        this.JobInfo.job_contact_name = depot.contact_name;
        this.JobInfo.job_contact_alt = depot.email;
        this.JobInfo.job_contact_best = depot.contact_best;
      }
    })
  }

  staticDropDown() {
    this.tree_work = [
      { id: '1', name: 'Tree' },
      { id: '2', name: 'Branch' },
      { id: '3', name: 'Stump' },
      { id: '4', name: 'N/A' },
    ];
    this.tree_condition = [
      { id: '1', name: 'Standing' },
      { id: '2', name: 'Fallen' },
      { id: '3', name: 'Hanging' },
      { id: '4', name: 'N/A' },
    ];
    this.tree_description = [
      { id: '1', name: 'Remove' },
      { id: '2', name: 'Prune' },
      { id: '3', name: 'Cut&Leave' },
      { id: '4', name: 'N/A' },
    ];
    this.orderInfo = {
      id: null,
      job_id: null,
      job_no: null,
      no_of_trees: 1,
      component_info: null,
      component_condition: null,
      job_category: null,
      council_approval: null,
      tree_perc_on_insured: 10,
      JobDesc: null
    }
  }
  AddressChange(address: any) {
    const fullAddress = [];
    for (let i = 0; i < address.address_components.length; i++) {
      const addressType = address.address_components[i].types[0];
      // }
      if (this.componentForm[addressType]) {

        const val = address.address_components[i][this.componentForm[addressType]];

        if (addressType === 'street_number') {
          fullAddress[0] = val;
        } else if (addressType === 'route') {
          fullAddress[1] = val;
        }

        if (addressType === 'sublocality_level_1') {// suburb
          this.JobInfo.risk_street_address = val;
        }
        if (addressType === 'locality') {// suburb
          this.JobInfo.risk_suburb = val;
        } else if (addressType === 'administrative_area_level_1') {// state
          this.stateList.forEach((stat) => {
            if (stat.name === val) {
              this.JobInfo.risk_state_id = stat.id;
              this.selectedState = stat;
              //console.log(this.selectedState)
            }
          });

        } else if (addressType === 'administrative_area_level_2') {//council
          //                                              this.suburb = val;
        } else if (addressType === 'country') {
          this.countryList.forEach((ctry) => {
            if (ctry.name === val) {
              this.JobInfo.risk_country_id = ctry.id;
              this.selectedCountry = ctry;
              //console.log(this.selectedCountry)
            }
          });
        } else if (addressType === 'postal_code') {
          this.JobInfo.risk_postcode = val;
        }

      }
    }
    const fullAdr = fullAddress.join(' ');
    this.JobInfo.risk_street_address = fullAdr;
    // this.refreshCompliance();
  }

  saveRecord() {
    this.submitted = true;
    this.JobInfo.job_firstname=this.firstname;
    this.JobInfo.job_lastname=this.lastname;
    this.JobInfo.phone=this.phone;
    this.JobInfo.job_company_id = this.selectedCompany?.id;
    if (this.jobCreationForm.valid) {
      if (this.selectedJobRequirements != null && this.selectedJobRequirements !== '') {
        this.JobInfo.job_req_id = this.selectedJobRequirements.id;
      }
      if (this.selectedJobAction != null && this.selectedJobAction !== '') {
        this.JobInfo.job_req_action_id = this.selectedJobAction.id;
      }
      if (this.selectedPriority != null && this.selectedPriority !== '') {
        this.JobInfo.job_priority_id = this.selectedPriority.id;
      }
      if (this.selectedJobEvent != null && this.selectedJobEvent !== '') {
        this.JobInfo.job_event_id = this.selectedJobEvent.id;
      }
      this.callAPI();

    }
    else {
      this.confirmationService.confirm({
        message: 'Some field are not filled where its required. Are you sure you want to proceed with saving now ',
        header: 'Confirm',
        icon: 'pi pi-exclamation-triangle',
        accept: () => {
          this.callAPI();
        }
      });
      // this.messageService.add({ severity: 'error', summary: 'Error', detail: 'Job Information Not Created', life: 1000 });
    }
  }
  callAPI() {
    if (this.jobId !== 0) {
      this.JobInfo.last_updated_at = new Date();
      this.crudService.getDataByField('JobInfo', 'job_claim_no', this.JobInfo.job_claim_no).subscribe((data: any) => {
        //        //console.log(data);
        //        if (data.length > 0) {
        //         this.showFlag = true;
        //      }
        //     else {
        this.crudService.Update(this.JobInfo, 'JobInfo').subscribe((res: any) => {
          //console.log(res);
          if (res.success == 1) {
            //this.saveWorkOrderInfo(this.jobId, this.JobInfo.job_no);
            this.submitted = false;
            this.showFlag = false;
            this.messageService.add({ severity: 'success', summary: 'Successful', detail: 'Job Information Updated', life: 1000 });
          } else {
            this.messageService.add({ severity: 'error', summary: 'Error', detail: 'Job Information Not Updated', life: 1000 });
          }
        })

      })

    } else {
      this.crudService.getLastJobNo().subscribe((jobRes: any) => {
        this.JobInfo.job_no = parseInt(jobRes[0].job_no);
        this.JobInfo.job_no = this.JobInfo.job_no + 1;

        this.JobInfo.status = 1;
        this.JobInfo.wf_status_id = 1;
        this.JobInfo.process_id = this.companyId;
        this.JobInfo.job_type = '1';
        if (this.JobInfo.job_recd_dt == null || this.JobInfo.job_recd_dt === '') {
          this.JobInfo.job_recd_dt = new Date();
        }
        if (this.JobInfo.job_act_dt == null || this.JobInfo.job_act_dt === '') {
          this.JobInfo.job_act_dt = new Date();
        }
        this.JobInfo.created_by = this.userDetails.userId;
        this.JobInfo.created_at = new Date();
        this.JobInfo.updated_by = null;
        this.JobInfo.updated_at = null;
        //console.log(this.JobInfo)
        this.crudService.getDataByField('jobInfo', 'job_claim_no', this.JobInfo.job_claim_no).subscribe((data: any) => {
          //console.log("detaildata",data);
          if (data.length > 0) {
            this.showFlag = true;
          }
            this.crudService.Create(this.JobInfo, 'JobInfo').subscribe((res: any) => {
              if (res.success === 1) {

                this.jobId = res.id;
                this.JobInfo.id = this.jobId;
                this.submitted = false;
                this.showFlag = false;
                this.jobEditWithJobId();
                this.enableTabs();
                //this.saveWorkOrderInfo(this.jobId, this.JobInfo.job_no);
                this.messageService.add({ severity: 'success', summary: 'Successful', detail: 'Job Information Created', life: 1000 });
                this.crudService.getDataByField('jobriskcontacts', 'job_id', this.jobId).subscribe((JobRiskContactsData: any[]) => {
                  this.JobRiskContacts = JobRiskContactsData;
                  //console.log("riskdata",this.JobRiskContacts);
                })

              } else {
                this.messageService.add({ severity: 'error', summary: 'Error', detail: 'Job Information Not Created', life: 1000 });
              }
            })
          
        })

      })
    }
  }

  createJobDeatils() {
    this.orderInfo = {
      id: null,
      job_id: this.jobId,
      job_no: this.JobInfo.job_no,
      no_of_trees: null,
      component_info: null,
      component_condition: null,
      job_category: null,
      council_approval: null,
      tree_perc_on_insured: null,
      JobDesc: null
    }
    if (this.selectedTreeWork.length > 0) {
      const works = this.selectedTreeWork.map(function (item) {
        return item.id;
      });
      this.orderInfo.component_info = works;
    } else {
      this.orderInfo.component_info = null;
    }

    if (this.selectedTreeCondition.length > 0) {
      const condition = this.selectedTreeCondition.map(function (item) {
        return item.id;
      });
      this.orderInfo.component_condition = condition;
    } else {
      this.orderInfo.component_condition = null;
    }

    if (this.selectedTreeDescription.length > 0) {
      const desc = this.selectedTreeDescription.map(function (item) {
        return item.id;
      });
      this.orderInfo.JobDesc = desc;
    } else {
      this.orderInfo.JobDesc = null;
    }
    this.crudService.Create(this.orderInfo, 'WorkOrderInfo').subscribe((res: any) => {
      //console.log(res);
    })
  }

  saveWorkOrderInfo(id, job_no) {
    this.orderInfo.job_id = id;
    this.orderInfo.job_no = job_no;
    if (this.isCouncilAppr) {
      this.orderInfo.council_approval = '2';
    } else {
      this.orderInfo.council_approval = '1';
    }

    if (this.selectedTreeWork.length > 0) {
      const works = this.selectedTreeWork.map(function (item) {
        return item.id;
      });
      this.orderInfo.component_info = works;
    } else {
      this.orderInfo.component_info = null;
    }

    if (this.selectedTreeCondition.length > 0) {
      const condition = this.selectedTreeCondition.map(function (item) {
        return item.id;
      });
      this.orderInfo.component_condition = condition;
    } else {
      this.orderInfo.component_condition = null;
    }

    if (this.selectedTreeDescription.length > 0) {
      const desc = this.selectedTreeDescription.map(function (item) {
        return item.id;
      });
      this.orderInfo.JobDesc = desc;
    } else {
      this.orderInfo.JobDesc = null;
    }

    if (this.orderInfo.id) {
      this.crudService.Update(this.orderInfo, 'WorkOrderInfo').subscribe((res: any) => {
        //console.log(res);
      })
    } else {
      this.crudService.Create(this.orderInfo, 'WorkOrderInfo').subscribe((res: any) => {
        //console.log(res);
      })
    }
  }


  createTestJob() {

    this.selectedCompany.id = '181'
    this.JobInfo.job_contact_id = '';
    this.JobInfo.job_contact_name = 'Shri ';

    this.JobInfo.old_job_no = 'NA';
    this.JobInfo.job_desc = 'Remove Pine tree in decline, grind stump.Tree is located corner of Central Park and is marked with a yellow \'x\'.';
    this.JobInfo.job_sp_desc = 'Remove all debris. Note: Road parked out early AM.';
    this.JobInfo.job_req_id = '10';
    this.JobInfo.job_req_action_id = '11';
    this.JobInfo.job_priority_id = '3';
    this.JobInfo.job_event_id = '10';
    this.JobInfo.job_claim_no = 'Test Job';
    this.JobInfo.job_provider_ref = 'Test Job';
    this.JobInfo.job_act_dt = this.JobInfo.job_recd_dt;
    this.JobInfo.risk_street_address = '309 Peatsferry road';
    this.JobInfo.risk_suburb = 'Asquith';
    this.JobInfo.risk_postcode = '2077';
    this.JobInfo.risk_state_id = '111';
    this.JobInfo.risk_country_id = '1';
    this.JobInfo.job_depot_id = null;

  }
  Schedule() {
    const ref = this.dialogService.open(JobScheduleComponent, {
      data: {
        jobAllocationId: '1',
        jobId: this.jobId
      },
      header: 'Job Schedule',
      width: '100%',
      height: '100%'
    });
  }

  loadImages() {
    const ref = this.dialogService.open(JobImagesComponent, {
      data: {
        jobAllocationId: '1',
        jobId: this.jobId
      },
      header: 'Images',
      width: '100%',
      height: '100%'
    });
  }

  uploadDocument() {

    const ref = this.dialogService.open(JobDocumentsComponent, {
      data: {
        jobAllocationId: '1',
        jobId: this.jobId
      },
      header: 'Upload Documents',
      width: '100%',
      height: '100%'
    })

  }


  showClientEmailDetail() {
    this.jobReceviedialog = true;
    this.ClientMessageData.ClientToEmail = this.JobInfo.job_contact_alt;
    let mainContacts = this.JobRiskContacts.filter(val => val.contact_type === '2');
    if (mainContacts.length > 0) {
      this.ContactMessageData.ContactToEmail = mainContacts[0].contact_email;
      this.ContactMessageData.ContactSMSNumber = mainContacts[0].contact_best;
    }
    if (!this.isContactNotification) {
      this.ClientMessageData.Subject = 'Enviro Trees Job Received Notification - Job No ' + this.JobInfo.job_no + ' - Claim Number Test Job';
      this.ClientMessageData.EmailContent = 'Hi Team, Please be advised job has been logged in our system and a tree professional had been assigned to contact insured and attend site. We will update you, as soon as we have the details. Enviro Trees has followed and communicated appropriate “social distancing” measures with the insured. We are monitoring this job and shall update you as it progresses. Please contact us on 1300 077 233 Opt 1, for any updates. Our Reference Number is TM ' + this.JobInfo.job_no + '. Regards, Customer Care Team - Enviro Trees';
      this.ClientMessageData.SMS = '';
      this.ContactMessageData.Subject = 'Tree Works on Your Property - Enviro Trees on Behalf of ' + this.WPName + ' ';
      this.ContactMessageData.EmailContent = 'Hi , we are assigned by ' + this.WPName + ' to organize tree works in the property. Our tree partner will contact you shortly to book an ETA to assess the works required. For any queries please dont hesitate to contact us on 1300077233 . The reference number for this job is TM ' + this.JobInfo.job_no + '. Thanks , Enviro Trees Team';
      this.ContactMessageData.ContactSMSMessage = 'Hi , we are assigned by ' + this.WPName + ' to organize tree works in the property. Our tree partner will contact you shortly to book an ETA to assess the works required. For any queries please dont hesitate to contact us on 1300077233 . The reference number for this job is TM ' + this.JobInfo.job_no + '. Thanks , Enviro Trees Team';
    }
  }
  clientEmailCheckBox(e) {
    //console.log(e.checked);
    if (e.checked == true) {
      this.clientEmail = true
    }
    else {
      this.clientEmail = false;
    }
  }
  clientSMSCheckBox(e) {
    if (e.checked == true) {
      this.clientSms = true
    }
    else {
      this.clientSms = false;
    }
  }

  SitetEmailCheckBox(e) {
    if (e.checked == true) {
      this.siteEmail = true
    }
    else {
      this.siteEmail = false;
    }

  }

  SiteSMSCheckBox(e) {
    if (e.checked == true) {
      this.siteSms = true
    }
    else {
      this.siteSms = false;
    }
  }
  saveNotificationDetails() {
    this.isContactNotification = true;
    this.jobReceviedialog = false;
  }


  filterCompliance(event, table) {
    this.checkOn = event.checked;
    this.complianceCheck();
    table.value = this.tpList;
  }

  complianceCheck() {
    if (this.checkOn) {
      this.tpList = this.allTPList.filter(val => val.compliance === 'Yes');
    } else {
      this.tpList = this.allTPList;
    }
    let updatedList = this.tpList.filter(val => (val.distance != 999999));
    this.progress = 'Loaded distance and duration for ' + updatedList.length + ' out of ' + this.tpList.length + ' Tree Professionals..';
    /* this.allTPList.sort(function (b, a) { return b.duration - a.duration });*/
    //this.allTPList.sort(function (a, b) { return b.duration - a.duration && 1 || -1 });
  }

  sendNotification() {
    this.getAllocatedJob();
    if (this.jobAllocationList.length > 0) {
      this.notifyJob(this.jobAllocationList[0]);
      this.messageService.add({ severity: 'success', summary: 'Successful', detail: 'Notification Sent', life: 1000 });
    }
  }

  sendFirstContactNotification() {
    let sch_notes = 'JOb added notification for client';
    this.ClientMessageData.ClientToEmail = this.JobInfo.job_contact_alt;
    //this.ClientMessageData.ClientToEmail = 'venkat@agileitexperts.com'
    let mainContacts = this.JobRiskContacts.filter(val => val.contact_type === '2');
    if (mainContacts.length > 0) {
      this.ContactMessageData.ContactToEmail = mainContacts[0].contact_email;
      this.ContactMessageData.ContactSMSNumber = mainContacts[0].contact_best;
    }
    if (!this.isContactNotification) {
      this.ClientMessageData.Subject = 'Enviro Trees Job Received Notification - Job No ' + this.JobInfo.job_no + ' - Claim Number Test Job';
      this.ClientMessageData.EmailContent = 'Hi Team, Please be advised job has been logged in our system and a tree professional had been assigned to contact insured and attend site. We will update you, as soon as we have the details. Enviro Trees has followed and communicated appropriate “social distancing” measures with the insured. We are monitoring this job and shall update you as it progresses. Please contact us on 1300 077 233 Opt 1, for any updates. Our Reference Number is TM ' + this.JobInfo.job_no + '. Regards, Customer Care Team - Enviro Trees';
      this.ClientMessageData.SMS = '';
      this.ContactMessageData.Subject = 'Tree Works on Your Property - Enviro Trees on Behalf of ' + this.WPName;
      this.ContactMessageData.EmailContent = 'Hi, we are assigned by ' + this.WPName + ' to organize tree works in the property. Our tree partner will contact you shortly to book an ETA to assess the works required. For any queries please dont hesitate to contact us on 1300077233 . The reference number for this job is TM ' + this.JobInfo.job_no + '. Thanks , Enviro Trees Team';
      this.ContactMessageData.ContactSMSMessage = 'Hi, we are assigned by ' + this.WPName + ' to organize tree works in the property. Our tree partner will contact you shortly to book an ETA to assess the works required. For any queries please dont hesitate to contact us on 1300077233 . The reference number for this job is TM ' + this.JobInfo.job_no + '. Thanks , Enviro Trees Team';
    }

    if (this.clientEmail) {
      this.sendEmail(1, this.ClientMessageData.ClientToEmail, this.ClientMessageData.Subject, this.ClientMessageData.EmailContent);
    }
    if (this.clientSms) {
      this.sendSms(1, null, null);
    }

    sch_notes = 'Job added notification for site contact';
    if (this.siteEmail) {
      this.sendEmail(2, this.ContactMessageData.ContactToEmail, this.ContactMessageData.Subject, this.ContactMessageData.EmailContent);
    }
    if (this.siteSms) {
      this.sendSms(2, this.ContactMessageData.ContactSMSNumber, this.ContactMessageData.ContactSMSMessage)
    }
    this.messageService.add({ severity: 'success', summary: 'Successful', detail: 'Notification Sent', life: 1000 });
  }

  sendEmail(type, toEmail, Subject, EmailContent) {
    this.loading = true;
    let store_data = {
      id: null,
      job_id: this.jobId,
      job_alloc_id: 1,
      process_id: 1,
      visit_type: null,
      status: null,
      sched_date: null,
      PMOnly: 2,
      sched_note: 'Job added notification for site contact',
      start_time: null,
      end_time: null,
      created_by: this.userDetails.userId,
      created_at: new Date(),
      phoned: null,
      sms: null,
      email: 2,
      callback: 1,
      sms_no: null,
      emailaddress: this.JobInfo.job_contact_alt,
      phone_no: null,
      source: 1,
      message_received: null,
      message_flow: null,
      comm_recipient: null,
      comm_recipient_subcatg: null

    }

    this.crudService.Create(store_data, 'JobSchedule').subscribe((data: any[]) => {
      //console.log(data);
    });

    this.sendMessageData = {
      email: {
        from: '',
        to: toEmail ? toEmail : this.JobInfo.job_contact_alt,
        cc: '',
        bcc: '',
        subject: Subject ? Subject : 'Notification for client ',
        msg: EmailContent
      },
      sms: {
        to: '',
        message: ''
      }
    }
    const dataObj = { sms: this.sendMessageData.sms, email: this.sendMessageData.email, type: 'email', selectedTpl: null, sufix: null, jobData: null };
    JSON.stringify(dataObj);
    this.crudService.sendTMV2Message(dataObj).subscribe((res2: any[]) => {
      //console.log(res2);
      this.loading = false;
    });
    //console.log(dataObj);
  }

  sendSms(type, smsNumber, message) {
    //store data in schedule
    let store_data = {
      id: null,
      job_id: this.jobId,
      job_alloc_id: 1,
      process_id: 1,
      visit_type: null,
      status: null,
      sched_date: null,
      PMOnly: 2, //            'sched_time' :$('.slider-time1').text() + " - " + $('.slider-time2').text(),
      sched_note: '',
      start_time: null,
      end_time: null,
      created_by: this.userDetails.userId,
      created_at: new Date(),
      phoned: null,
      sms: 2,
      email: null,
      callback: 1,
      sms_no: this.JobInfo.job_contact_best,
      emailaddress: null,
      phone_no: null,
      source: 1,
      message_received: null,
      message_flow: null,
      comm_recipient: null,
      comm_recipient_subcatg: null


    }
    this.crudService.Create(store_data, 'JobSchedule').subscribe((data: any[]) => {
      //console.log(data);
    });

    let sch_notes = 'Job added notification for site contact';
    this.sendSMSData = {
      email: {
        from: '',
        to: '',
        cc: '',
        bcc: '',
        subject: '',
        msg: ''
      },
      sms: {
        to: smsNumber ? smsNumber : this.JobInfo.job_contact_best,
        message: message ? message : sch_notes
      }
    }
    const dataObj = { sms: this.sendSMSData.sms, email: this.sendSMSData.email, type: 'sms', selectedTpl: null, sufix: null, jobData: null };
    //console.log(dataObj);
    this.crudService.sendTMV2Message(dataObj).subscribe((res2: any[]) => {
      //console.log(res2)
    });
  }


  jobEditWithJobId() {
    /* if (this.JobInfo.client_category === '1') {
       this.selectedCategory = '1';
     } else if (this.JobInfo.client_category === '2') {
       this.selectedCategory = '2';
     } else {
       this.selectedCategory = '2';
     }
     if (typeof this.JobInfo.client_category == undefined) {
       this.selectedCategory = '2';
     } else {
       if (this.JobInfo.client_category === '1') {
         this.selectedCategory = '1';
       } else {
         this.selectedCategory = '2';
       }
     } */
    this.crudService.getDataByField('jobInfo', 'id', this.jobId).subscribe((JobInfoData: any[]) => {
      if (JobInfoData.length > 0) {
        this.JobInfo = JobInfoData[0];
        this.JobInfo.job_firstname=this.firstname;
        this.JobInfo.job_lastname=this.lastname;
        this.JobInfo.phone=this.phone;
        this.JobInfo.job_act_dt = new Date(this.JobInfo.job_act_dt);
        this.JobInfo.job_recd_dt = new Date(this.JobInfo.job_recd_dt);
        if (this.JobInfo.job_company_id) {
          let companies = this.allCompaniesList.filter(val => val.id === this.JobInfo.job_company_id);
          this.companyList = this.allCompaniesList.filter(val => val.status !== '2');
          if (companies.length > 0) {
            this.selectedCompany = companies[0];
            this.WPName = this.selectedCompany.business_name.replace('&', 'and');
            this.JobInfo.WPName = this.WPName;
            this.crudService.getByDepotCID(this.selectedCompany.id).subscribe((depotData: any[]) => {
              depotData.sort((a, b) => a.depot_name > b.depot_name && 1 || -1);
              this.depots = depotData;
              if (this.JobInfo.job_depot_id) {
                let depot = this.depots.filter(val => val.id === this.JobInfo.job_depot_id);
                if (depot.length > 0) {
                  this.selectedDepots = depot[0];
                }
              }
            })
            this.crudService.getDataByField('jobriskcontacts', 'job_id', this.jobId).subscribe((riskContactdata: any[]) => {
              this.JobRiskContacts = riskContactdata;
              this.wizardMetaData[1].title = 'Insured Details (' + this.JobRiskContacts.length + ')';
            })

            this.getAllocatedJob();



            this.crudService.getContactsByCID(this.JobInfo.job_company_id).subscribe((users: any[]) => {
              users.sort((a, b) => a.position_name > b.position_name && 1 || -1);
              this.providerUsers = users;
              if (this.JobInfo.job_contact_id !== null && this.JobInfo.job_contact_id !== '') {
                let contacts = this.providerUsers.filter(val => val.id === this.JobInfo.job_contact_id);
                if (contacts.length > 0) {
                  this.selectedProviderUsers = contacts[0];
                }
              }
            })
          }
          let JobRequirement = this.JobRequirements.filter(val => val.id === this.JobInfo.job_req_id);
          if (JobRequirement.length > 0) {
            this.selectedJobRequirements = JobRequirement[0];
          }
          let action = this.jobActions.filter(val => val.id === this.JobInfo.job_req_action_id);
          if (action.length > 0) {
            this.selectedJobAction = action[0];
          }
          let jobPriority = this.JobPriories.filter(val => val.id === this.JobInfo.job_priority_id);
          if (action.length > 0) {
            this.selectedPriority = jobPriority[0];
          }
          let event = this.JobEvents.filter(val => val.id === this.JobInfo.job_event_id);
          if (action.length > 0) {
            this.selectedJobEvent = event[0];
          }

          let state = this.stateList.filter(val => val.id === this.JobInfo.risk_state_id);
          if (state.length > 0) {
            this.selectedState = state[0];
          }

          let country = this.countryList.filter(val => val.id === this.JobInfo.risk_country_id);
          if (country.length > 0) {
            this.selectedCountry = country[0];
          }
        }
        //this.getJobAddress();
        /* this.getAllocatedJob(); */
        //this.getWorkOrderInfo();
      }
      this.loading = false;
    })
    
  }

  getWorkOrderInfo() {
    this.staticDropDown();
    this.crudService.getDataByField('WorkOrderInfo', 'Job_id', this.jobId).subscribe((orderInfo: any[]) => {
      if (orderInfo.length > 0) {
        this.orderInfo = orderInfo[0];
        if (this.orderInfo.component_info !== null && this.orderInfo.component_info !== '') {
          for (const work of this.orderInfo.component_info.split(',')) {
            let works = this.tree_work.filter(val => work === val.id)[0];
            this.selectedTreeWork.push(works);
          }
        }
        if (this.orderInfo.component_condition !== null && this.orderInfo.component_condition !== '') {
          for (const condition of this.orderInfo.component_condition.split(',')) {
            let conditions = this.tree_condition.filter(val => condition === val.id)[0];
            this.selectedTreeCondition.push(conditions);
          }

        }
        if (this.orderInfo.JobDesc !== null && this.orderInfo.JobDesc !== '') {
          for (const desc of this.orderInfo.JobDesc.split(',')) {
            let descriptions = this.tree_description.filter(val => desc === val.id)[0];
            this.selectedTreeDescription.push(descriptions);
          }
        }
        if (this.orderInfo.council_approval === '2') {
          this.isCouncilAppr = true;
        } else {
          this.isCouncilAppr = false;
        }

      } else {
        this.orderInfo = {
          id: null,
          job_id: null,
          job_no: null,
          no_of_trees: null,
          component_info: null,
          component_condition: null,
          job_category: null,
          council_approval: null,
          tree_perc_on_insured: null,
          JobDesc: null
        }
      }
    })
  }

  //get the job address
  async getJobAddress() {
    this.loading = true;
    this.checkOn = true;
    await this.getTPlist();
    this.loading = false;
    //this.complianceCheck();

  };

  async refreshCompliance(table: Table) {
    table.clear();
    this.loading = true;
    this.checkOn = true;
    this.interStateSearch = false;
    await this.getTPlist();
    this.loading = false;
    table.reset();
    //this.complianceCheck();
  }

  searchAcrossBorder(event, table: Table) {
    table.clear();
    this.loading = true;
    //console.log(event);
    this.interStateSearch = event.checked;
    this.getTPlist();
    //this.complianceCheck();
    this.loading = false;
    table.reset();
  }



  //declare a service for company
  //since 3 is the tp create
  async getTPlist() {
    this.tpList = [];
    this.tempList = [];
    const jobAllocs = [];
    this.jobAllocations = [];
    const distanceResultArray = [];
    const jobId = this.JobInfo.id;
    if (this.complianceLevelId == null) {
      this.complianceLevelId = 43;
    }

    if (jobId) {
      this.totalListSize = 0;
      if (this.interStateSearch) {
        let res: any = await this.crudService.getAllByCompanyTypeAll(3, jobId, this.JobInfo.risk_state_id, this.complianceLevelId).toPromise();

        res.forEach(tp => {
          //  let chkCompliance = 'No';
          let chkCompliance = 'No';
          let APPUsageValue = 'No';
          if (tp.APPUsageValue === '1') {
            APPUsageValue = 'Yes';
          }
          if (tp.ComplianceCount < tp.TotalCompliance) {
            chkCompliance = 'No';
          } else {
            if (tp.StaffComplianceStatusNo === 'No') {
              chkCompliance = 'No, Staff Incomplete';
            } else if (tp.StaffComplianceStatusYes === 'YES') {
              chkCompliance = 'Yes';
            } else if (tp.StaffComplianceStatusYes == null && tp.StaffComplianceStatusNo == null && tp.StaffComplianceStatusNA == null) {
              chkCompliance = 'Yes';
            } else {
              chkCompliance = 'No, Staff not Compliant';
            }
          }
          const jobAllo = {
            selected: null,
            job_id: 1,
            depot_id: tp.depot_id,
            treeprofessional: tp.business_name,
            contractor_id: tp.companyId,
            distance: 999999,
            drivingTime: 999999,
            duration: null,
            feedback: 'good',
            kpi: 5.4,
            status: 'Active',
            contactPerson: tp.main_contact_name,
            email: tp.email,
            phone: tp.mobile_no,
            message: 'good',
            compliance: chkCompliance,
            //                                depot: tp.depot_name,
            suburb: tp.suburb,
            address: tp.street_address + ', ' + tp.suburb + ' ' + tp.state + ' ' + tp.postcode + ', ' + tp.country,
            Availability: tp.Availability,
            APPUsage: APPUsageValue
          }
          jobAllocs.push(jobAllo);
        });
      } else {
        let res: any = await this.crudService.getAllByCompanyTypeByState3(3, jobId, this.JobInfo.risk_state_id, this.complianceLevelId).toPromise();
        res.forEach(tp => {
          //  let chkCompliance = 'No';
          let chkCompliance = 'No';
          let APPUsageValue = 'No';
          if (tp.APPUsageValue == '1') {
            APPUsageValue = 'Yes';
          }
          let capacityFontValue = null;
          let capacityValue = null;
          if (tp.Crews == null) {
            capacityValue = 'NA';
            capacityFontValue = 'orange';
          } else {
            let cTemp = ((1 - ((tp.Crews - tp.Availability) / tp.Crews))) * 100;
            capacityValue = (cTemp).toFixed(2) + ' %';
            if (cTemp < 50) {
              capacityFontValue = 'green';
            } else if (cTemp < 70) {
              capacityFontValue = 'orange';
            } else if (cTemp > 69) {
              capacityFontValue = 'red';
            }
          }
          if (tp.ComplianceCount < tp.TotalCompliance) {
            chkCompliance = 'No';
          } else {
            if (tp.StaffComplianceStatusNo === 'No') {
              chkCompliance = 'No, Staff Incomplete';
            } else if (tp.StaffComplianceStatusYes === 'YES') {
              chkCompliance = 'Yes';
            } else if (tp.StaffComplianceStatusYes == null && tp.StaffComplianceStatusNo == null && tp.StaffComplianceStatusNA == null) {
              chkCompliance = 'Yes';
            } else {
              chkCompliance = 'No, Staff not Compliant';
            }
          }
          const jobAllo = {
            selected: null,
            job_id: 1,
            depot_id: tp.depot_id,
            treeprofessional: tp.business_name,
            contractor_id: tp.companyId,
            distance: 999999,
            drivingTime: 999999,
            duration: null,
            feedback: 'good',
            kpi: 5.4,
            status: 'Active',
            contactPerson: tp.main_contact_name,
            email: tp.email,
            phone: tp.mobile_no,
            message: 'good',
            compliance: chkCompliance,
            //                                depot: tp.depot_name,
            suburb: tp.suburb,
            address: tp.street_address + ', ' + tp.suburb + ' ' + tp.state + ' ' + tp.postcode + ', ' + tp.country,
            Availability: tp.Availability,
            APPUsage: APPUsageValue, Capacity: capacityValue, capacityFont: capacityFontValue

          }

          jobAllocs.push(jobAllo);
        });
      }
    } else {

      if (this.interStateSearch) {
        let res: any = await this.crudService.getAllByCompanyTypeAll(3, 1, this.JobInfo.risk_state_id, this.complianceLevelId).toPromise();
        res.forEach(tp => {
          let chkCompliance = 'No';
          let APPUsageValue = 'No';
          let capacityFontValue = null;
          let capacityValue = null;
          if (tp.Crews == null) {
            capacityValue = 'NA';
            capacityFontValue = 'orange';
          } else {
            let cTemp = ((1 - ((tp.Crews - tp.Availability) / tp.Crews))) * 100;
            capacityValue = (cTemp).toFixed(2) + ' %';
            if (cTemp < 50) {
              capacityFontValue = 'green';
            } else if (cTemp < 70) {
              capacityFontValue = 'orange';
            } else if (cTemp > 69) {
              capacityFontValue = 'red';
            }
          }
          if (tp.APPUsageValue == '1') {
            APPUsageValue = 'Yes';
          }
          if (tp.ComplianceCount < tp.TotalCompliance) {
            chkCompliance = 'No';
          } else {
            if (tp.StaffComplianceStatusNo === 'No') {
              chkCompliance = 'No, Staff Incomplete';
            } else if (tp.StaffComplianceStatusYes === 'YES') {
              chkCompliance = 'Yes';
            } else if (tp.StaffComplianceStatusYes == null && tp.StaffComplianceStatusNo == null && tp.StaffComplianceStatusNA == null) {
              chkCompliance = 'Yes';
            } else {
              chkCompliance = 'No, Staff not Compliant';
            }
          }

          const jobAllo = {
            depot_id: tp.depot_id,
            selected: null,
            job_id: 1,
            treeprofessional: tp.business_name,
            contractor_id: tp.companyId,
            distance: 999999,
            drivingTime: 999999,
            duration: null,
            feedback: 'good',
            kpi: 5.4,
            status: 'Active',
            contactPerson: tp.main_contact_name,
            email: tp.email,
            phone: tp.mobile_no,
            message: 'good',
            compliance: chkCompliance,
            //                                depot: tp.depot_name,
            suburb: tp.suburb,
            address: tp.street_address + ', ' + tp.suburb + ' ' + tp.state + ' ' + tp.postcode + ', ' + tp.country,
            Availability: tp.Availability,
            APPUsage: APPUsageValue, Capacity: capacityValue, capacityFont: capacityFontValue

          }
          jobAllocs.push(jobAllo);
        });
      } else {
        let res: any = await this.crudService.getAllDpsByState3(3, this.JobInfo.risk_state_id, this.complianceLevelId).toPromise();
        res.forEach(tp => {
          let chkCompliance = 'No';
          let APPUsageValue = 'No';
          let capacityFontValue = null;
          let capacityValue = null;
          if (tp.Crews == null) {
            capacityValue = 'NA';
            capacityFontValue = 'orange';
          } else {
            let cTemp = ((1 - ((tp.Crews - tp.Availability) / tp.Crews))) * 100;
            capacityValue = (cTemp).toFixed(2) + ' %';
            if (cTemp < 50) {
              capacityFontValue = 'green';
            } else if (cTemp < 70) {
              capacityFontValue = 'orange';
            } else if (cTemp > 69) {
              capacityFontValue = 'red';
            }
          }
          if (tp.APPUsageValue == '1') {
            APPUsageValue = 'Yes';
          }

          if (tp.ComplianceCount < tp.TotalCompliance) {
            chkCompliance = 'No';
          } else {
            if (tp.StaffComplianceStatusNo == 'No') {
              chkCompliance = 'No, Staff Incomplete';
            } else if (tp.StaffComplianceStatusYes == 'YES') {
              chkCompliance = 'Yes';
            } else if (tp.StaffComplianceStatusYes == null && tp.StaffComplianceStatusNo == null && tp.StaffComplianceStatusNA == null) {
              chkCompliance = 'Yes';
            } else {
              chkCompliance = 'No, Staff not Compliant';
            }
          }

          const jobAllo = {
            depot_id: tp.depot_id,
            selected: null,
            job_id: 1,
            treeprofessional: tp.business_name,
            contractor_id: tp.companyId,
            distance: 999999,
            drivingTime: 999999,
            duration: null,
            feedback: 'good',
            kpi: 5.4,
            status: 'Active',
            contactPerson: tp.main_contact_name,
            email: tp.email,
            phone: tp.mobile_no,
            message: 'good',
            compliance: chkCompliance,
            //                                depot: tp.depot_name,
            suburb: tp.suburb,
            address: tp.street_address + ', ' + tp.suburb + ' ' + tp.state + ' ' + tp.postcode + ', ' + tp.country,
            Availability: tp.Availability,
            APPUsage: APPUsageValue, Capacity: capacityValue, capacityFont: capacityFontValue

          }
          jobAllocs.push(jobAllo);
        });
      }
    }
    //console.log(jobAllocs.length);
    this.allTPList = jobAllocs;
    await this.getDistanceChecking(jobAllocs);

    if (this.failList.length > 0) {
      setTimeout(async () => {
        await this.loadFailList();
        this.allTPList.sort(function (a, b) { return b.duration - a.duration && 1 || -1 });
      }, 3000);
    }else{
      this.allTPList.sort(function (a, b) { return b.duration - a.duration && 1 || -1 });
    }
  }

  async getDistanceChecking(tempList) {
    this.progress = '';
    this.loadData = false;
    this.totalListSize = tempList.length;
    this.jobAddr = this.JobInfo.risk_street_address?.replace(/ /g, '+') + ',' +
      this.JobInfo.risk_suburb?.replace(' ', '+') + '+' +
      this.JobInfo.risk_postcode;
    this.jobAddr = this.jobAddr?.replace(/'+'/g, ' ');
    for (let i = 0; i < tempList.length; i += 25) {
      if (tempList.length > (i + 25)) {
        this.loadData = true;
      } else {
        this.loadData = false;
      }
      await this.getDistanceService(tempList.slice(i, i + 25));
    }
  }

  async loadFailList() {
    let failList = this.failList;
    this.failList = [];
    for (let i = 0; i < failList.length; i += 25) {
      if (failList.length > (i + 25)) {
        this.loadData = true;
      } else {
        this.loadData = false;
      }
      await this.getDistanceService(failList.slice(i, i + 25));
      
      setTimeout(async () => {
        if (i > this.failList.length) {
          await this.loadFailList();
        }
      }, 3000);
    }
  }

  async getDistanceService(tmpList) {
    var service = new google.maps.DistanceMatrixService();
    let tpAddrList: any[] = [];
    tmpList.forEach(tp => {
      let tpAddr = tp.address;//street_address.replace(/ /g, '+') + ',' + tp.suburb.replace(/ /g, '+') + '+' + tp.postcode;
      tpAddr = tpAddr.replace(/'+'/g, ' ');
      tpAddrList.push(tpAddr);
    });
    service.getDistanceMatrix(
      {
        origins: tpAddrList,
        destinations: [this.jobAddr],
        travelMode: google.maps.TravelMode.DRIVING,
      }, callback);
    let self = this;
    let tpList = tmpList;

    function callback(response, status) {
      //console.log('status : ' + status);
      self.progress = '';
      if (status === 'OK') {
        for (var i = 0; i < response.rows.length; i++) {
          var info = response.rows[i].elements[0];
          var originAddr = response.originAddresses[i];
          var obj = { 'distance': 99999999, 'distanceValue': 9999999, 'duration': null, 'originAddresses': originAddr, drivingTimeValue: 99999999 };
          if (info.distance) {
            obj.distanceValue = info.distance.value;
            obj.distance = (info.distance.value / 1000);
            obj.duration = info.duration.text;
            obj.drivingTimeValue = info.duration.value;
          }
          let index = self.allTPList.findIndex(val => (val.depot_id === tpList[i].depot_id));
          self.allTPList[index].distance = obj.distance;
          self.allTPList[index].distanceValue = obj.distanceValue;
          self.allTPList[index].duration = obj.drivingTimeValue;
          self.allTPList[index].drivingTime = obj.duration;
          self.allTPList[index].updated = true;
          //tpList[i].rowNum = self.tpList.length+1;
          //self.tempList.push(tpList[i]);
        }
        //self.allTPList = null;

        //self.allTPList = self.tempList;
        self.complianceCheck();
        /* if( self.totalListSize === self.tempList.length){
            self.allTPList = self.tempList; */
        //self.tpList = self.tempList;
        /* if (self.allTPList.length === 0) {
          self.allTPList = self.tpList;
        }
        if (self.checkOn) {
          self.tpList = self.tpList.filter(val => val.compliance === 'Yes');
      } else {
        self.tpList = self.allTPList;
      }
    } */
      } else {
        self.failList.push(...tmpList);
      }
    }
  }

  /*  callback(response, status) {
    for (let i = 0; i < response.rows.length; i++) {
      let info = response.rows[i].elements[0];
      let originAddr = response.originAddresses[i];
      this.$apply(function () {
        let obj = { 'distance': 99999999, 'distanceValue': 9999999, 'duration': null, 'originAddresses': originAddr };
        if (info.distance) {
          obj.distance = info.distance.value;
          obj.distanceValue = info.distance.value;
          obj.duration = info.duration.text;
        }
        distanceResultArray.push(obj);
      });
      //51 Mona Vale Road, Mona Vale NSW 2103, Australia
      //51 Mona Vale Rd, Mona Vale NSW 2103, Australia
    }
    if (distanceResultArray.length == jobAllocs.length) {
      //if all the data is matched so make broadcastIndex for preparing next time and also clean up the result array
      broadcastIndex = 0;
      result = [];
      usSpinnerService.stop('spinner-1');
      //iterate the joballocs and set distance and duration and distanceValue
      for (let k = 0; k < jobAllocs.length; k++) {
        //                        for(let j=0;j<distanceResultArray.length;j++){
        //                            if(jobAllocs[k].address===distanceResultArray[j].originAddresses){
        jobAllocs[k].distance = distanceResultArray[k].distance;
        jobAllocs[k].distanceValue = distanceResultArray[k].distanceValue;
        jobAllocs[k].duration = distanceResultArray[k].duration;
        //                            }
        //                        }
      }
      this.$apply(function () {
        this.jobAllocations = jobAllocs;
      });
    } else {
      this.$broadcast('calu_next');
    }
   }
  */
  allocateJob() {
    this.loading = true;
    this.selectedTPList.forEach(async tp => {
      let job_alloc = {
        id: null,
        job_id: this.jobId,
        depot_id: tp.depot_id,
        contractor_id: tp.contractor_id,
        allocation_status: 1,
        allocation_message: tp.message ? tp.message : 'good',
        accept_status: null,
        accept_message: null,
        driving_time: tp.drivingTime ? tp.drivingTime : null,
        tp_invoiced: null,
        wp_invoiced: null,
      };
      let res: any = await this.crudService.Create(job_alloc, 'JobAllocation').toPromise();
      if (res.success == 1) {
        this.messageService.add({ severity: 'success', summary: 'Successful', detail: 'Job Allocated', life: 1000 });
        job_alloc.id = res.id;
        this.tpList = this.tpList.filter(val => (val.contractor_id != tp.contractor_id));
        this.selectedTPList = this.selectedTPList.filter(val => (val.contractor_id != tp.contractor_id));
        if (this.adTpChecked) {
          await this.autoSendNotification(job_alloc, tp);
        }
      }
    });
    this.getAllocatedJob();
    this.loading = false;
  }

  getAllocatedJob() {
    this.crudService.getAllAllocatedJobByJobId(this.jobId).subscribe((res: any) => {
      let count: any = 0;
      res.forEach(alloc => {
        count = count + 1;
        alloc.driving_time = alloc.driving_time ? alloc.driving_time : 'N/A';
        if (alloc.accept_status) {
          if (alloc.accept_status == 1) {
            alloc.status_text = 'Accepted';
            alloc.inputDisable = true;
            alloc.reAlloc = false;
            alloc.accept = false;
            alloc.cancel = true;
          } else if (alloc.accept_status == 2) {
            alloc.status_text = 'Rejected';
            alloc.inputDisable = true;
            alloc.reAlloc = true;
            alloc.accept = false;
            alloc.cancel = false;
            count = count - 1;
          }
        } else {
          if (alloc.allocation_status == 1) {
            alloc.status_text = 'Allocated';
            alloc.inputDisable = false;
            alloc.reAlloc = false;
            alloc.accept = true;
            alloc.cancel = true;
          } else if (alloc.allocation_status == 2) {
            alloc.status_text = 'Cancelled';
            alloc.inputDisable = true;
            alloc.reAlloc = true;
            alloc.accept = false;
            alloc.cancel = false;
            count = count - 1;
          }
        }
      });
      this.jobAllocationList = res;
      this.wizardMetaData[2].title = 'Allocation (' + count + ')';
    });
  }

  async autoSendNotification(jal, tp) {
    /* let JobAllocData: any = await this.crudService.getDataByField('jobAllocation', 'id', jal.id).toPromise(); */
    const offerSubject = 'New job offer from Enviro Trees JobNo:' + this.JobInfo.job_no;
    const offerMsgSMS = 'Hi ' + tp.contactPerson + ', You have been offered a job from Enviro Trees at ' +
      this.JobInfo.risk_suburb + '. Your Job number for reference is ' + this.JobInfo.job_no +
      '. Please login to tree manager 2 at www.treemanager.com to accept the job and view details.Thanks and regards,Tree Manager Team';
    const offerEmailContent = '<h3>Hi ' + tp.contactPerson + '</h3><p>You have been offered a job from Enviro Trees at ' +
      this.JobInfo.risk_suburb +
      '. Please login to tree manager 2 at www.treemanager.com to accept the job and view details</p><p>Thanks and regards,'
      + '</p>' + '<p>Tree Manager Team</p>';

    const datobj = {
      sms: { to: tp.phone, message: offerMsgSMS },
      email: { to: tp.email, cc: null, bcc: null, subject: offerSubject, msg: offerEmailContent },
      type: 'sms-email',
      selectedTpl: null,
      sufix: null,
      routes: { path: 'remoteAccept', jobId: jal.job_id, jobAllocId: jal.id }
    }
    this.crudService.sendTMV2Message(datobj).subscribe((res2: any[]) => {
      this.storeInScheduleNotification(jal.job_id, jal.id);
      this.messageService.add({ severity: 'success', summary: 'Successful', detail: 'Notification Sent to the Tree Professional', life: 1000 });
    });
  }



  deleteAllocation() {
    this.confirmationService.confirm({
      message: 'Are you sure you want to delete the selected staff',
      header: 'Confirm',
      icon: 'pi pi-exclamation-triangle',
      accept: () => {
        // tslint:disable-next-line: prefer-for-of
        for (let i = 0; i < this.selectedTPAllocationList.length; i++) {
          this.crudService.Delete(this.selectedTPAllocationList[i].id, 'JobAllocation').subscribe((data: any[]) => { });
        }
        this.getJobAddress();
        this.getAllocatedJob();
      }
    });
  }

  addAllocation() {
    this.confirmationService.confirm({
      message: 'Are you sure you want to allocate the job to the selected Tree Professionals?',
      header: 'Confirm',
      icon: 'pi pi-exclamation-triangle',
      accept: () => {
        this.allocateJob();
        this.showConfirmDialog = false;
      }
    });
  }

  acceptJob(job_allocation) {
    this.confirmationService.confirm({
      message: 'Are you sure you want to Accept?',
      header: 'Confirm',
      icon: 'pi pi-exclamation-triangle',
      accept: async () => {
        // tslint:disable-next-line: prefer-for-of
        let allocData: any = await this.crudService.getDataByField('JobAllocation', 'id', job_allocation.id).toPromise();
        job_allocation.accesstoken = allocData[0].accesstoken;
        job_allocation.accept_status = 1;
        this.crudService.Update(job_allocation, 'JobAllocation').subscribe((data: any[]) => {
          this.JobService.recordJobActions(this.jobId, job_allocation.id, 'await acceptance', 'TP to accept', 'Job Accepted', 2, 'Job Accepted by ' + this.userDetails.user_id + ' on behalf of Contractor', null, null);
          this.getAllocatedJob();
          this.storeInScheduleAccept(job_allocation.job_id, job_allocation.id);
          this.messageService.add({ severity: 'success', summary: 'Successful', detail: 'Job Allocation Accepted', life: 1000 });
        });
      }
    });
  }

  rejectJob(job_allocation) {
    this.confirmationService.confirm({
      message: 'Are you sure you want to Reject?',
      header: 'Confirm',
      icon: 'pi pi-exclamation-triangle',
      accept: () => {
        // tslint:disable-next-line: prefer-for-of
        job_allocation.allocation_status = 2;
        job_allocation.accept_message = null;
        job_allocation.accept_status = null;
        this.crudService.Update(job_allocation, 'JobAllocation').subscribe((data: any[]) => {
          this.getAllocatedJob();
          this.storeInScheduleCancel(job_allocation.job_id, job_allocation.id);
          this.messageService.add({ severity: 'success', summary: 'Successful', detail: 'Job Allocation Rejected', life: 1000 });
        });
      }
    });
  }

  reallocateJob(job_allocation) {
    this.confirmationService.confirm({
      message: 'Are you sure you want to Re-allocate?',
      header: 'Confirm',
      icon: 'pi pi-exclamation-triangle',
      accept: () => {

        job_allocation.allocation_status = 1;
        job_allocation.accept_status = null;
        job_allocation.accept_message = null;
        this.crudService.Update(job_allocation, 'JobAllocation').subscribe((data: any[]) => {
          this.getAllocatedJob();
          this.storeInScheduleAccept(job_allocation.job_id, job_allocation.id);
          this.messageService.add({ severity: 'success', summary: 'Successful', detail: 'Job Allocation Reallocated', life: 1000 });
        });
      }
    });
  }


  notifyJob(job_allocation) {
    this.getEmailSmsData(job_allocation);
    this.crudService.sendTMV2Message(this.datobj).subscribe((data: any[]) => {
      this.storeInScheduleNotification(job_allocation.job_id, job_allocation.id);
      this.messageService.add({ severity: 'success', summary: 'Successful', detail: 'Notification Sent to the Tree Professional', life: 1000 });
    })
  }

  storeInScheduleAccept(job_id, job_alloc_id) {
    const store_data_accept = {
      id: null,
      job_id: job_id,
      job_alloc_id: job_alloc_id,
      process_id: 1,
      visit_type: null,
      status: null,
      sched_date: null,
      PMOnly: '2',
      sched_note: 'Job accepted on behalf of TP',
      start_time: null,
      end_time: null,
      created_by: this.userDetails.userId,
      created_at: new Date(),
      phoned: '1',
      phone_no: null,
      sms: '1',
      sms_no: null,
      email: '1',
      emailaddress: null,
      callback: '1',
      source: 1,
      message_received: null,
      message_flow: null,
      comm_recipient: null,
      comm_recipient_subcatg: null
    }

    this.crudService.Create(store_data_accept, 'JobSchedule').subscribe((res: any) => {
      if (res.success == 1) {
        //console.log(res.id);
      }
    });
  }

  storeInScheduleCancel(job_id, job_alloc_id) {
    let store_data_cancel = {
      id: null,
      job_id: job_id,
      job_alloc_id: job_alloc_id,
      process_id: 1,
      visit_type: null,
      status: null,
      sched_date: null,
      PMOnly: '2',
      sched_note: 'This job has been canceled',
      start_time: null,
      end_time: null,
      created_by: this.userDetails.userId,
      created_at: new Date(),
      phoned: '1',
      phone_no: null,
      sms: '1',
      sms_no: null,
      email: '1',
      emailaddress: null,
      callback: '1',
      source: 1,
      message_received: null,
      message_flow: null,
      comm_recipient: null,
      comm_recipient_subcatg: null
    }

    this.crudService.Create(store_data_cancel, 'JobSchedule').subscribe((res: any) => {
      if (res.success == 1) {
        //console.log(res.id);
      }
    });
  }


  statusCancel(id) {
    this.crudService.updateJobStatus(id, 3).subscribe((res: any) => {
      /* this.getJobSchedulingData(); */
      this.jobEditWithJobId();
    })
  }

  statusComplete(id) {
    this.crudService.updateJobStatus(id, 2).subscribe((res: any) => {
      /* this.getJobSchedulingData(); */
      this.jobEditWithJobId();
    })
  }

  showEmailDetail(job_allocation) {
    this.selectedTreePro = job_allocation;
    if (!this.isSaved) {
      this.offerData.offerSubject = 'New job offer from Enviro Trees JobNo:' + this.JobInfo.job_no;
      this.apprData.apprSubject = 'New job offer from Enviro Trees JobNo:' + this.JobInfo.job_no;
      this.offerData.offerMsgSMS = 'Hi ' + job_allocation.main_contact_name + ', You have been offered a job from Enviro Trees.Your Job number for reference is ' + this.JobInfo.job_no + '. Please login to tree manager 2 at www.treemanager.com to accept the job and view details.Thanks and regards,Tree Manager Team';
      this.apprData.apprMsgSMS = 'Hi ' + job_allocation.main_contact_name + ', You have been offered a job from Enviro Trees.Your Job number for reference is ' + this.JobInfo.job_no + '. Please login to tree manager 2 at www.treemanager.com to accept the job and view details.Thanks and regards,Tree Manager Team';
      this.offerData.offerEmailContent = '<h3>Hi ' + job_allocation.main_contact_name + '</h3><p>You have been offered a job from Enviro Trees. Please login to tree manager 2 at www.treemanager.com to accept the job and view details</p><p>Thanks and regards,' +
        '</p>' + '<p>Tree Manager Team</p>';
      this.apprData.apprEmailContent = '<h3>Hi ' + job_allocation.main_contact_name + '</h3><p>You have been offered a job from Enviro Trees. Please login to tree manager 2 at www.treemanager.com to accept the job and view details</p><p>Thanks and regards,' +
        '</p>' + '<p>Tree Manager Team</p>';
    }
    this.showEmailDialog = true;
  }

  getEmailSmsData(job_allocation) {
    if (!this.isSaved) {
      this.offerData.offerSubject = 'New job offer from Enviro Trees JobNo:' + this.JobInfo.job_no;
      this.offerData.offerMsgSMS = 'Hi ' + job_allocation.main_contact_name + ', You have been offered a job from Enviro Trees at ' + this.JobInfo.risk_suburb + '. Your Job number for reference is ' + this.JobInfo.job_no + '. Please login to tree manager 2 at www.treemanager.com to accept the job and view details.Thanks and regards,Tree Manager Team';
      this.offerData.offerEmailContent = '<h3>Hi ' + job_allocation.main_contact_name + '</h3><p>You have been offered a job from Enviro Trees at ' + this.JobInfo.risk_suburb + '. Please login to tree manager 2 at www.treemanager.com to accept the job and view details</p><p>Thanks and regards,' +
        '</p>' + '<p>Tree Manager Team</p>';
    }
    this.datobj = {
      sms: { to: job_allocation.mobile_no, message: this.offerData.offerMsgSMS },
      email: { to: job_allocation.email, cc: null, bcc: null, subject: this.offerData.offerSubject, msg: this.offerData.offerEmailContent },
      type: 'sms-email',
      selectedTpl: null,
      sufix: null,
      routes: { path: 'remoteAccept', jobId: job_allocation.job_id, jobAllocId: job_allocation.id }
    }
  }
  detailSave() {
    this.isSaved = true;
    let temEcontent = this.offerData.offerEmailContent;
    temEcontent = temEcontent.replace(/>[\n\t&nbsp;]+</g, '><');
    temEcontent = temEcontent.replace(/&nbsp;/g, '');
    this.offerData.offerEmailContent = temEcontent;

    this.datobj = {
      sms: {
        to: this.selectedTreePro.mobile_no + ';0404682215',
        message: this.offerData.offerMsgSMS
      },
      email: {
        to: this.selectedTreePro.email,
        cc: this.offerData.offerCcEmail,
        bcc: this.offerData.offerBccEmail,
        subject: this.offerData.offerSubject,
        msg: this.offerData.offerEmailContent
      },
      type: 'sms-email',
      selectedTpl: null,
      sufix: null
    }
    this.showEmailDialog = false;
  }

  storeInScheduleNotification(job_id, job_alloc_id) {
    let store_data_notify = {
      id: null,
      job_id: job_id,
      job_alloc_id: job_alloc_id,
      process_id: 1,
      visit_type: null,
      status: null,
      sched_date: null,
      PMOnly: '2',
      sched_note: 'Job offer notification has been sent to the Tree Partner',
      start_time: null,
      end_time: null,
      created_by: this.userDetails.userId,
      created_at: new Date(),
      phoned: '1',
      phone_no: null,
      sms: '1',
      sms_no: null,
      email: '1',
      emailaddress: null,
      callback: '1',
      source: 1,
      message_received: null,
      message_flow: null,
      comm_recipient: null,
      comm_recipient_subcatg: null
    }
    this.crudService.Create(store_data_notify, 'JobSchedule').subscribe((res: any) => {
      if (res.success == 1) {
        //console.log(res.id);
      }
    });
  }

  enableTabs() {
    this.initTab();

    this.crudService.getByValuesetName('JobCancelledReasonList').subscribe((data: any[]) => {
      this.JobCancelledReasonList = data;
    });
    this.crudService.getByValuesetName('JobHoldReasonList').subscribe((data: any[]) => {
      this.JobHoldReasonList = data;
    });
  }
  tagConfig;
  initTab() {
    const tabJob = document.getElementById('tab-job');
    const tabSchedule = document.getElementById('tab-schedule');
    const tabDocument = document.getElementById('tab-document');
    const tabImage = document.getElementById('tab-image');
    const tabContact = document.getElementById('tab-contact');
    const tabAllocation = document.getElementById('tab-allocation');
    const tabCanceljob = document.getElementById('tab-canceljob');

    let job = document.getElementsByClassName('job-info-tab-activate');
    let schedule = document.getElementsByClassName('schedule-info-tab-activate');
    let documents = document.getElementsByClassName('document-info-tab-activate');
    let image = document.getElementsByClassName('image-info-tab-activate');
    let contact = document.getElementsByClassName('contact-info-tab-activate');
    let allocation = document.getElementsByClassName('allocation-info-tab-activate');
    let canceljob = document.getElementsByClassName('canceljob-info-tab-activate');

    let outerContainer: any = document.getElementsByClassName('outer-container');

    function initializeTabs(config) {

      let l1 = [tabJob, tabSchedule, tabDocument, tabImage, tabContact, tabAllocation, tabCanceljob];
      let l2 = [job, schedule, documents, image, contact, allocation, canceljob];

      config.forEach(c => {
        document.getElementById(c.id).addEventListener('click', () => {
          l1.forEach((ele) => {
            ele?.classList?.remove('active');
          });
          c.activeTab?.classList?.add('active');

          l2.forEach((ele) => {
            if (ele && ele.length > 0) {
              for (let i = 0; i < ele.length; i++) {
                ele[i]?.classList?.add('hideme');
              }
            }
          });
          if (c.activeContent && c.activeContent.length > 0) {
            for (let i = 0; i < c.activeContent.length; i++) {
              c.activeContent[i]?.classList?.remove('hideme');
            }
          }

        });
      })
    }

    this.tagConfig = [{
      id: 'tab-job',
      activeTab: tabJob,
      activeContent: job,
      general: true
    }, {
      id: 'tab-schedule',
      activeTab: tabSchedule,
      activeContent: schedule
    }, {
      id: 'tab-document',
      activeTab: tabDocument,
      activeContent: documents
    }, {
      id: 'tab-image',
      activeTab: tabImage,
      activeContent: image
    }, {
      id: 'tab-contact',
      activeTab: tabContact,
      activeContent: contact
    }, {
      id: 'tab-allocation',
      activeTab: tabAllocation,
      activeContent: allocation
    }, {
      id: 'tab-canceljob',
      activeTab: tabCanceljob,
      activeContent: canceljob
    }];
    initializeTabs(this.tagConfig);
  }

  updateTab(v) {
    //console.log("###############")
    //console.log(this.JobInfo)
    if (!this.JobInfo.id) {
      this.messageService.add({ severity: 'error', summary: 'Error', detail: 'Job Not Saved. Please save to proceed with other actions.', life: 1000 });
      // v = 'tab-job';
      // this.initTab();
    }
    //console.log(this.JobInfo.risk_street_address)
    if (!this.JobInfo.risk_street_address && v === 'tab-allocation') {
      this.messageService.add({ severity: 'error', summary: 'Error', detail: 'Job Not Saved. Please enter address.', life: 1000 });
      // v = 'tab-job';
      this.allocationInfoActive = false;
      // this.initTab();
      // let v = 'tab-job';
      // this.updateTab(v)
      // this.jobInfoActive = true;
      //console.log(this.tagConfig)
      this.openModal();
    }
    this.tagConfig?.forEach(c => {
      if (c.content && c.id === v) {
        this[c.content] = true;
      }
    });

    this.jobReceviedialog = false;

    this.jobInfoActive = false;
    this.contactInfoActive = false;
    this.allocationInfoActive = false;
    this.showCancelJobDialog = false;
    this.scheduleInfoActive = false;
    this.documentInfoActive = false;
    this.imageInfoActive = false;
    switch (v) {
      case 'tab-job':
        this.jobInfoActive = true;
        break;
      case 'tab-schedule':
        this.scheduleInfoActive = true;
        break;
      case 'tab-document':
        this.documentInfoActive = true;
        break;
      case 'tab-image':
        this.imageInfoActive = true;
        break;
      case 'tab-contact':
        this.contactInfoActive = true;
        break;
      case 'tab-allocation':
        this.getAllocatedJob();
        this.getJobAddress();
        this.allocationInfoActive = true;
        break;
      case 'tab-canceljob':
        this.showCancelJobDialog = true;
        break;
      default:
        break;
    }
    this.wizardMetaData.forEach(w => {
      w.class = w.id == v ? 'active' : '';
    })
  }

  loadJobsInfoByID(id) {
    let query = 'select * from joboperationstempview where jobId = ' + id;
    this.crudService.ExecuteQuery(query).subscribe((data: any[]) => {
      data.forEach(jobInfo => {
        let job: any = {
          AllocationId: Number(jobInfo.AllocationId),
          ClientSubtype: jobInfo.ClientSubtype,
          FullAddress: jobInfo.FullAddress,
          InvoiceAmount: jobInfo.InvoiceAmount,
          JobId: Number(jobInfo.JobId),
          JobNo: Number(jobInfo.JobNo),
          JobStatus: jobInfo.JobStatus,
          SubStatus: jobInfo.SubStatus,
          TreeProfessional: jobInfo.TreeProfessional,
          accept_status: jobInfo.accept_status,
          job_claim_no: jobInfo.job_claim_no,
          JobDate: jobInfo.JobDate
        }
        if (jobInfo.JobDate) {
          //job.JobDate = stringToDate(jobInfo.JobDate,"dd/MM/yyyy","/");
        } else {
          job.JobDate = null;
        }
        this.ref.close(job);
      })
    })
  }

  setJobStatus(value) {
    this.jobStatus = value;
    if (value === 3) {
      this.selectedReason = this.JobCancelledReasonList[0];
    } else if (value === 4) {
      this.selectedReason = this.JobHoldReasonList[0];
    } else {
      this.selectedReason = null;
    }
    this.status_reopen_dt = null;
    this.status_reason_other = null;
  }
  saveCancelHold() {
    //console.log("@@@@@@@@@@@@@@@@@@@@@@@@@")
    let status_reason = 0;
    if (this.jobStatus === 3 || this.jobStatus === 4) {
      if (this.selectedReason.id) {
        status_reason = this.selectedReason.id;
      }
    } else {
      status_reason = 0;
    }
    this.status_reason_other = this.status_reason_other ? this.status_reason_other : '';
    if (this.jobStatus === 4) {
      this.crudService.updateHoldStatus(this.jobStatus, status_reason, this.status_reason_other, this.status_reopen_dt, this.jobId).subscribe((data: any[]) => {
        this.messageService.add({ severity: 'success', summary: 'Successful', detail: 'Job Put on Hold', life: 1000 });
        this.showCancelJobDialog = false;
      });
    } else {
      this.crudService.updateCancelStatus(this.jobStatus, status_reason, this.status_reason_other, this.jobId).subscribe((data: any[]) => {
        if (this.jobStatus === 1) {
          this.messageService.add({ severity: 'success', summary: 'Successful', detail: 'Job Activated', life: 1000 });
        } else if (this.jobStatus === 3) {
          this.messageService.add({ severity: 'success', summary: 'Successful', detail: 'Job Cancelled', life: 1000 });
        }
        this.showCancelJobDialog = false;
      });
    }
  }

  viewCompliance(tp) {
    /* const ref = this.dialogService.open(ComplianceComponent, {
      data: {
        staffId: '',
        company_id: tp.contractor_id,
        type: 'contractor',
        allowCreate: 'false'
      },
      header: 'Compliance',
      width: '100%',
      height: '100%'
    }); */
    this.crudService.getAllComplianceByCompany2(tp.contractor_id, this.complianceLevelId).subscribe((res: any) => {
      this.complianceInfoList = res;
      this.showComplianceInfo = true;
    });
  }

  GetContactsList(contacts) {
    this.JobRiskContacts = contacts;
    this.wizardMetaData[1].title = 'Insured Details (' + this.JobRiskContacts.length + ')';
  }
  openModal() {
    const ref = this.dialogService.open(ProceedConfirmationComponent, {
      data: {
        title: 'Street address is empty.Please fill with valid address to proceed with allocation',
        buttonFlag: true
      },
      width: '30%',
      height: 'max-content',
      header: 'Confirmation',
      // buttonflag:true
    })
    ref.onClose.subscribe(async (taskdetails: any) => {
      //console.log(taskdetails)
      if (taskdetails === undefined) {
        this.allocationInfoActive = false;
        let v = 'tab-job'
        this.updateTab(v)
        this.messageService.add({ severity: 'error', summary: 'Error', detail: 'Job Not Saved. Please enter address.', life: 1000 });

      } else if (taskdetails === 'no') {
        let v = 'tab-job'
        this.updateTab(v)
        // this.initTab()
      } else if (taskdetails === 'yes') {
        if (this.JobInfo.risk_street_address === null) {
          let v = 'tab-job'
          this.updateTab(v)
        } else {

        }
      }
      // this.filterData(null);
      // this.quickFilterTasks(null);
    });
  }

  chooseAddress(event)
  {     
    console.log("event",event);
        this.inputAddress = event.summaryline;
        this.JobInfo.risk_suburb = event.posttown
        if(event.addressline2 != undefined)
        {
          this.JobInfo.street_address = event.addressline1 + ',' + event.addressline2;
        }
        else
        {
          this.JobInfo.street_address = event.addressline1;
        }
        this.JobInfo.risk_postcode = event.postcode;
        this.JobInfo.risk_location_lat = event.latitude;
        this.JobInfo.risk_location_lng = event.longitude;
        this.stateList.forEach((stat) => {
            if (stat.id === '111') {
                this.JobInfo.risk_state_id = stat.id;
                this.selectedState = stat;
            }
        }); 
        this.countryList.forEach((ctry) => {
            if (ctry.id === '222') {
                this.JobInfo.risk_country_id = ctry.id;
                this.selectedCountry = ctry;
            }
        });
     
  }

  search(event) {
    this.crudService.getPostcoderAddress(event.query).subscribe((res:any)=>{  
        this.filteredPlaces = res.filter((val: any) => val.summaryline.toLowerCase().includes(event.query.toLowerCase()));
      });
  }
}