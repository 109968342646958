import { Component } from '@angular/core';
import { AppMainComponent } from './app.main.component';
import { CRUDService } from './utilities/crud.service';
import { DomSanitizer } from '@angular/platform-browser';
import { MessageService } from 'primeng/api';
import { FileUploader } from 'ng2-file-upload';
import { Subscription } from 'rxjs';
import { NotificationComponent } from './modules/notification/notification.component';
import { ComponentsComunicationService } from './components-comunication.service';
import { DialogService, DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { ProceedConfirmationComponent } from './modules/proceed-confirmation/proceed-confirmation.component';
/* import Pushy from 'pushy-sdk-web'; */
import { NotificationPermissionComponent } from './modules/notification-permission/notification-permission.component';
import { CropImageComponent } from './modules/crop-image/crop-image.component';

@Component({
  selector: 'app-topbar',
  templateUrl: './app.topbar.component.html',
  styleUrls: ['./app.topbar.component.scss', './style.css'],
  providers: [MessageService]
})
export class AppTopBarComponent {
  clientTypeFlag: Boolean = false;
  hideUserDetails: boolean = false;
  topbarcount: boolean = false;
  activeItem: number;
  tab2: boolean = true;
  tab3: boolean = false;
  tab4: boolean = false;
  files: any;
  disabled: boolean = true;
  statusInfo: string;
  logopath: string = 'assets/layout/images/logo-treeManager.png';
  companyId: any;
  newPwd: any;
  image_url: any;
  userProfile_url: any;
  jobSearchResults: any[];
  allJobsList: any[] = [];
  allocId: any;
  jobId: any;
  darkColors: any;
  lightColors: any;
  customColors: any;
  menuColors: any;
  componentThemes: any;
  selectedJob: any;
  uploader: FileUploader;
  messageCount: number = 5;
  fontsizeArray: any = [];
  fontsize: any;
  notificationCount: any
  subscription: Subscription;
  userDetails: any;
  is324Exempt: Boolean = false;
  custCareExpList_324: any;
  companyInfo: any;
  firstname = '';
  submitted: any;
  JobInfo: any;
  userFormDetails: any[];
  userform: Boolean = true;
  loading: Boolean = false
  messageList: any = [{ title: 'Message title', desc: 'Message in detail', unread: true, display: true },
  { title: 'Message title', desc: 'Message in detail', unread: true, display: true },
  { title: 'Message title', desc: 'Message in detail', unread: true, display: true },
  { title: 'Message title', desc: 'Message in detail', unread: true, display: true },
  { title: 'Message title', desc: 'Message in detail', unread: true, display: true }];

  constructor(public app: AppMainComponent, public dialogService: DialogService,public dialogService1: DialogService,
    private CRUDService: CRUDService, public sanitizer: DomSanitizer, private messageService: MessageService, private componentsComunicationService: ComponentsComunicationService, private dialofRef: DynamicDialogRef) {
    this.companyId = localStorage.getItem('company_id');
  }

  mobileMegaMenuItemClick(index) {
    this.app.megaMenuMobileClick = true;
    this.activeItem = this.activeItem === index ? null : index;
  }

  async ngOnInit() {
    //this.loading = true;
    this.companyId = localStorage.getItem('company_id');
    let compData = await this.CRUDService.getDataByField('Company', 'id', this.companyId).toPromise();
    this.companyInfo = compData[0];
    this.userDetails = JSON.parse(localStorage.getItem('loggedInUser'));
    if (this.companyInfo.client_type_id == '3') {
      this.clientTypeFlag = true;
    } else {
      this.clientTypeFlag = false;
    }
    this.notificationCount = sessionStorage.getItem('notification_count');
    this.subscription = this.componentsComunicationService.currentNotificationMessage.subscribe((message: any) => {
      this.notificationCount = sessionStorage.getItem('notification_count');
    })
    if (this.app.userSettings.status == 1) {
      this.statusInfo = 'Active';
    }
    else {
      this.statusInfo = 'Inactive'
    }
    this.fetchCompanyLogo();
    this.fetchUserImage();
    //this.getSearchList();
    this.lightColors = [
      { name: 'Blue', file: 'blue', color: '#5e81ac' },
      { name: 'Green', file: 'green', color: '#A3BE8C' },
      { name: 'Yellow', file: 'yellow', color: "#EBCB8B" },
      { name: 'Cyan', file: 'cyan', color: '#88C0D0' },
      { name: 'Purple', file: 'purple', color: '#B48EAD' },
      { name: 'Orange', file: 'orange', color: '#D08770' },
      { name: 'Teal', file: 'teal', color: '#88D0BD' },
      { name: 'Magenta', file: 'magenta', color: '#BD69AE' },
      { name: 'Lime', file: 'lime', color: '#B9BE7F' },
      { name: 'Brown', file: 'brown', color: '#BE9B7F' },
      { name: 'Red', file: 'red', color: '#F28686' },
      { name: 'Indigo', file: 'indigo', color: '#8886F2' },
    ];

    this.darkColors = [
      { name: 'Blue', file: 'blue', color: '#5e81ac' },
      { name: 'Green', file: 'green', color: '#A3BE8C' },
      { name: 'Yellow', file: 'yellow', color: '#EBCB8B' },
      { name: 'Cyan', file: 'cyan', color: '#88C0D0' },
      { name: 'Purple', file: 'purple', color: '#B48EAD' },
      { name: 'Orange', file: 'orange', color: '#D08770' },
      { name: 'Teal', file: 'teal', color: '#88D0BD' },
      { name: 'Magenta', file: 'magenta', color: '#BD69AE' },
      { name: 'Lime', file: 'lime', color: '#B9BE7F' },
      { name: 'Brown', file: 'brown', color: '#BE9B7F' },
      { name: 'Red', file: 'red', color: '#F28686' },
      { name: 'Indigo', file: 'indigo', color: '#8886F2' },
    ];

    this.customColors = [
      { name: 'Chile', file: 'chile', image: 'chile.png' },
      { name: 'Naples', file: 'naples', image: 'naples.png' },
      { name: 'Georgia', file: 'georgia', image: 'georgia.png' },
      { name: 'Infinity', file: 'infinity', image: 'infinity.png' },
      { name: 'Chicago', file: 'chicago', image: 'chicago.png' },
      { name: 'Majesty', file: 'majesty', image: 'majesty.png' },
      { name: 'Fish', file: 'fish', image: 'fish.png' },
      { name: 'Dawn', file: 'dawn', image: 'dawn.png' },
      { name: 'Record', file: 'record', image: 'record.png' },
      { name: 'Pool', file: 'pool', image: 'pool.png' },
      { name: 'Metal', file: 'metal', image: 'metal.png' },
      { name: 'China', file: 'china', image: 'china.png' },
      { name: 'Table', file: 'table', image: 'table.png' },
      { name: 'Panorama', file: 'panorama', image: 'panorama.png' },
      { name: 'Barcelona', file: 'barcelona', image: 'barcelona.png' },
      { name: 'Underwater', file: 'underwater', image: 'underwater.png' },
      { name: 'Symmetry', file: 'symmetry', image: 'symmetry.png' },
      { name: 'Rain', file: 'rain', image: 'rain.png' },
      { name: 'Utah', file: 'utah', image: 'utah.png' },
      { name: 'Wave', file: 'wave', image: 'wave.png' },
      { name: 'Flora', file: 'flora', image: 'flora.png' },
      { name: 'Speed', file: 'speed', image: 'speed.png' },
      { name: 'Canopy', file: 'canopy', image: 'canopy.png' },
      { name: 'SanPaolo', file: 'sanpaolo', image: 'sanpaolo.png' },
      { name: 'Basketball', file: 'basketball', image: 'basketball.png' },
      { name: 'Misty', file: 'misty', image: 'misty.png' },
      { name: 'Summit', file: 'summit', image: 'summit.png' },
      { name: 'Wall', file: 'wall', image: 'wall.png' },
      { name: 'Ferris', file: 'ferris', image: 'ferris.png' },
      { name: 'Ship', file: 'ship', image: 'ship.png' },
      { name: 'NY', file: 'ny', image: 'ny.png' },
      { name: 'Cyan', file: 'cyan', image: 'cyan.png' },
      { name: 'Violet', file: 'violet', image: 'violet.png' },
      { name: 'Red', file: 'red', image: 'red.png' },
      { name: 'Blue', file: 'blue', image: 'blue.png' },
      { name: 'Porsuk', file: 'porsuk', image: 'porsuk.png' },
      { name: 'Pink', file: 'pink', image: 'pink.png' },
      { name: 'Purple', file: 'purple', image: 'purple.png' },
      { name: 'Orange', file: 'orange', image: 'orange.png' },
    ];

    this.menuColors = this.lightColors;

    this.componentThemes = [
      { name: 'Blue', file: 'blue', color: '#5E81AC' },
      { name: 'Green', file: 'green', color: '#99CE6B' },
      { name: 'Yellow', file: 'yellow', color: '#EBCB8B' },
      { name: 'Cyan', file: 'cyan', color: '#88C0D0' },
      { name: 'Purple', file: 'purple', color: '#B48EAD' },
      { name: 'Orange', file: 'orange', color: '#D08770' },
      { name: 'Teal', file: 'teal', color: '#88D0BD' },
      { name: 'Magenta', file: 'magenta', color: '#BD69AE' },
      { name: 'Lime', file: 'lime', color: '#B9BE7F' },
      { name: 'Brown', file: 'brown', color: '#BE9B7F' },
      { name: 'Red', file: 'red', color: '#F28686' },
      { name: 'Indigo', file: 'indigo', color: '#8886F2' },
    ];

    this.uploader = new FileUploader({
      disableMultipart: true, // 'DisableMultipart' must be 'true' for formatDataFunction to be called.
      formatDataFunctionIsAsync: true,
      formatDataFunction: async (item) => {
        return new Promise((resolve, reject) => {
          resolve({
            name: item._file.name,
            length: item._file.size,
            contentType: item._file.type,
            date: new Date()
          });
        });
      }
    });
    const sizeArray = [
      {
        size: "Small",
        value: 'sm',
        fontSize: ['14px', '14px', '14px']
      },
      {
        size: "Medium",
        value: 'md',
        fontSize: ['14px', '14px', '16px']
      },
      {
        size: "Large",
        value: 'lg',
        fontSize: ['14px', '14px', '18px']
      }
    ]
    this.fontsizeArray = sizeArray;
    this.fontsize = this.fontsizeArray[1]
    this.changeFontSizes();
  //  this.checkPushNotification();
  // this.saveUser();
 this.loading = false;
  }

  fetchCompanyLogo() {
    if (this.app.userSettings.companyLogoPath == null) {
      this.CRUDService.getCompanyLogo('default', 'default/compliance_docs/company_logo.jpg').subscribe(blob => {
        let objectURL = URL.createObjectURL(blob);
        this.image_url = this.sanitizer.bypassSecurityTrustUrl(objectURL);
      });
    } else {
      this.CRUDService.getCompanyLogo(this.companyId, this.companyId + '/compliance_docs/company_logo.jpg').subscribe(blob => {
        let objectURL = URL.createObjectURL(blob);
        this.image_url = this.sanitizer.bypassSecurityTrustUrl(objectURL);
      });
    }
  }

  fetchUserImage() {
    if (this.app.userSettings.image_name == null) {
      this.CRUDService.getCompanyLogo('default', 'default/compliance_docs/default.png').subscribe(blob => {
        let objectURL = URL.createObjectURL(blob);
        this.userProfile_url = this.sanitizer.bypassSecurityTrustUrl(objectURL);
      });
    } else {
      /* this.CRUDService.getCompanyLogo(this.companyId, this.companyId + '/compliance_docs/company_logo.jpg').subscribe(blob => { */
      this.CRUDService.fetchStaffComplianceFile(this.companyId, this.app.userSettings.staff_id, this.companyId + '/compliance_docs/staff/' + this.app.userSettings.staff_id + '/userProfile.jpg').subscribe(blob => {
        let objectURL = URL.createObjectURL(blob);
        this.userProfile_url = this.sanitizer.bypassSecurityTrustUrl(objectURL);
      });
    }
  }

  async getSearchList() {
    let additionalCondition = '';
    this.custCareExpList_324 = await this.CRUDService.getByValuesetName('CUSTCARE_EXP_324').toPromise();
    let index = this.custCareExpList_324.findIndex(val => (val.valueset_detail_name === this.userDetails.id));
    if (index > -1) {
      this.is324Exempt = true;
    }
    if (this.is324Exempt) {
      additionalCondition = ' and CompanyId not in (324)';
    }
    this.CRUDService.getSearchList(additionalCondition).subscribe((data: any) => {
      this.allJobsList = data;
    });
  }
  searchJobs(event) {
    let searchStr = event.query;
    searchStr = searchStr?.toLowerCase()
    this.jobSearchResults = this.allJobsList.filter((val: any) => (
      (val.job_claim_no ? val.job_claim_no : '')?.toLowerCase().includes(searchStr) ||
      val.JobNo?.toLowerCase().includes(searchStr) ||
      (val.FullAddress ? val.FullAddress : '')?.toLowerCase().includes(searchStr) ||
      (val.JobStatus ? val.JobStatus : '')?.toLowerCase().includes(searchStr) ||
      (val.InvoiceNo ? val.InvoiceNo : '')?.toLowerCase().includes(searchStr)));
  }
  async openSelectedJob(event) {
    this.selectedJob = event;
    this.allocId = this.selectedJob.AllocationId;;
    this.jobId = this.selectedJob.JobId;
    // await this.loadJobSummary();
  }
  // async loadJobSummary() {
  //   //this.loading = true;
  //   let smryData: any = await this.CRUDService.getJobSummary(this.jobId, this.allocId).toPromise();
  //   this.jobSummary = await this.JobService.getJobInfo(smryData[0]);
  //   //console.log(this.jobSummary.JobAllocDetails);
  //   //console.log(this.jobSummary);
  //   if (this.jobSummary.JobAllocDetails.wf_status === '2' || this.jobSummary.JobAllocDetails.wf_status === 2) {
  //     this.isFlagged = true;
  //   } else {
  //     this.isFlagged = false;
  //   }
  //   this.Date_str = new Date(this.jobSummary.Date).toLocaleDateString();
  //   this.title = this.jobSummary.JobNo + ' - (' + this.jobSummary.JobStatus + ')';

  //   this.CRUDService.getDataByField('jobinfo', 'id', this.jobId).subscribe((data: any[]) => {
  //     this.jobDetails = data;
  //     this.jobInfo = data[0];
  //     this.date = this.jobDetails[0].job_recd_dt.split('T')[0];
  //     this.getInvStepsStatus();
  //     this.getOtherJobAllocationDetails();
  //     this.getSearchList();
  //     this.CRUDService.getCostingInfo(this.jobId, this.allocId).subscribe((costingInfo: any[]) => {
  //       if (costingInfo.length > 0) {
  //         if (this.jobSummary.wp_grand_total !== null && this.jobSummary.wp_grand_total !== '') {
  //           this.jobSummary.tp_grand_total = parseFloat(costingInfo[0].tp_grand_total).toFixed(2);
  //           this.jobSummary.wp_grand_total = parseFloat(costingInfo[0].wp_grand_total).toFixed(2);
  //           let variance = this.jobSummary.tp_grand_total / this.jobSummary.wp_grand_total;
  //           variance = 1 - variance;
  //           variance = variance * 100;
  //           this.jobSummary.margin = parseFloat(variance.toString()).toFixed(2);
  //         }
  //       }
  //     });
  //     this.CRUDService.getUploadDocumentsSummary(this.jobId, this.allocId).subscribe((uploadStatus: any[]) => {
  //       if (uploadStatus.length > 0) {
  //         this.docSummary = uploadStatus;
  //       }
  //     });
  //     this.showFixedCostMessage();
  //     this.loading = false;
  //   });
  // }


  processFileOld(imageInput: any) {
    let uploadedFile: File = imageInput.files[0];
    uploadedFile = new File([uploadedFile], "userProfile.jpg");
    var reader = new FileReader();
    this.userProfile_url = reader.readAsDataURL(uploadedFile);
    let res = this.CRUDService.uploadCompliance(uploadedFile, this.app.userSettings.company_id, this.app.userSettings.staff_id);
    let response = JSON.parse(res)
    if (response != null) {
      if (response.success === 1) {
        if (this.app.userSettings.user_group_id.length > 1) {
          this.app.userSettings.user_group_id = this.app.userSettings.user_group_id[0];
        }
        this.app.userSettings.image_name = this.app.userSettings.staff_id + '/userProfile.jpg';
        this.saveUser();
        //this.messageService.add({ severity: 'success', summary: 'Successful', detail: 'File Uploaded Successfully', life: 1000 });
      } else {
        //this.messageService.add({ severity: 'error', summary: 'Error', detail: 'File Not Uploaded', life: 1000 });
      }
    }
  }

  processFile(event) {
    if (this.userProfile_url !== null && this.userProfile_url !== '' && this.userProfile_url !== undefined) {
      this.openModal(event)
    } else {
      this.uploadFileApiCall(event)
      // if (this.userProfile_url !== null && this.userProfile_url !== '' && this.userProfile_url !== undefined) {
      //   this.openModal(event)
      // } else {
      //   this.uploadFileApiCall(event)
      // }
    }
  }
  openModal(event: any) {
    //this.loading = true;
    const ref = this.dialogService.open(ProceedConfirmationComponent, {
      data: {
        title: 'Are you sure want to change profile picture?'
      },
      width: '30%',
      height: 'max-content',
      header: 'Confirmation'
    })

    ref.onClose.subscribe(async (taskdetails: any) => {
      console.log('tssss',taskdetails)
      if (taskdetails === 'yes') {
        setTimeout(() => {
          this.uploadFileApiCall(event);
        },1000)
        this.loading = false;
      } else if (taskdetails === undefined) {
      }
    });
  }

  uploadFileApiCall(event: any) {
    const ref1 = this.dialogService1.open(CropImageComponent, {
      data: {
        fileData: event
      },
      /* header: 'Images', */
      width: '30%',
      height: 'max-content',
      styleClass:'crop-image'

    });
    ref1.onClose.subscribe(async (response1: any) => {
      console.log(response1);
      if (response1 !== undefined) {
        let uploadedFile: File = response1;
        uploadedFile = new File([uploadedFile], "userProfile.jpg");
        var reader = new FileReader();
        let res = this.CRUDService.uploadCompliance(uploadedFile, this.app.userSettings.company_id, this.app.userSettings.staff_id);
        let response = JSON.parse(res)
        if (response != null) {
          if (response.success === 1) {
            if (this.app.userSettings.user_group_id.length > 1) {
              this.app.userSettings.user_group_id = this.app.userSettings.user_group_id[0];
              this.loading = false;
            }
            this.app.userSettings.image_name = this.app.userSettings.staff_id + '/userProfile.jpg';
            this.saveUser();
            this.CRUDService.fetchStaffComplianceFile(this.companyId, this.app.userSettings.staff_id, this.companyId + '/compliance_docs/staff/' + this.app.userSettings.staff_id + '/userProfile.jpg').subscribe(blob => {
              let objectURL = URL.createObjectURL(blob);
              this.userProfile_url = this.sanitizer.bypassSecurityTrustUrl(objectURL);
            });
          } else {
          }
        }
      }
    });
  }

  changeMenuColorMode(event, mode) {
    this.app.menuColorMode = mode;
    if (mode !== 'custom') {
      if (mode === 'light') {
        this.menuColors = this.lightColors;
        this.changeMenuColor(event, this.lightColors[0].file);
      }
      else {
        this.menuColors = this.darkColors;
        this.changeMenuColor(event, this.darkColors[0].file);
      }
    }
    else {
      this.menuColors = this.customColors;
      this.changeMenuColor(event, this.customColors[0].file);
    }
  }

  changeMenuColor(event, color) {
    if (this.app.menuColorMode !== 'custom') {
      this.app.menuColor = 'layout-menu-' + this.app.menuColorMode;
      if (this.app.menuColorMode === 'dark') {
        this.app.layoutColor = color;
        this.app.changeStyleSheetsColor('layout-css', color);
      } else {
        this.app.layoutColor = color;
        this.app.changeStyleSheetsColor('layout-css', color);
      }
    }
    else {
      this.app.layoutColor = color;
      this.app.menuColor = 'layout-menu-' + color;
    }
    event.preventDefault();
  }


  changeComponentTheme(event, color) {
    this.app.themeColor = color;
    this.app.changeStyleSheetsColor('theme-css', color);
    event.preventDefault();
  }

  showUserDetails() {
    //this.loading = true;
    this.hideUserDetails = true
    this.CRUDService.getDataByField('Company', 'company_name', this.app.userSettings.company_id).subscribe((data2: any[]) => {
    });
  }
  info() {
    //this.loading = true;
    this.tab2 = false;
    this.tab3 = false

  }

  profileShow() {
    //this.loading = true;
    this.tab2 = true;
    this.tab3 = false

    this.removeActiveButton();
    document.getElementById('user-profile-user-info').classList.add('active');
  }

  security() {
    //this.loading = true;
    this.tab2 = false;
    this.tab3 = true;

    this.removeActiveButton();
    document.getElementById('user-profile-user-security').classList.add('active');
  }
  role() {
    this.tab2 = false;
    this.tab3 = false;
    this.tab4 = true;

    this.removeActiveButton();
    document.getElementById('user-profile-user-role').classList.add('active');
  }

  removeActiveButton() {
    let ele: any = document.querySelector('.p-button-info.active');
    if (ele) {
      ele.classList.remove('active');
    }
  }
  cancelUser() {
    //this.loading = true;
    this.hideUserDetails = false;
  }

  changePassword(event) {
    this.newPwd = event;
  }
  saveUser() {
    //this.loading = true;
    if (this.app.horizontalMenu) {
      this.app.userSettings.menu_type = '1';
    } else {
      this.app.userSettings.menu_type = '2';
    }
    if (this.app.darkMode) {
      this.app.userSettings.colour_scheme = 'dark';
    } else {
      this.app.userSettings.colour_scheme = 'light';
    }
    this.app.userSettings.colour = this.app.themeColor;
    this.app.userSettings.menu_colour = this.app.menuColor;
    this.app.userSettings.layout_colour = this.app.layoutColor;
    this.app.userSettings.process_id = this.app.userSettings.company_id;
    if (typeof this.newPwd !== 'undefined') {
      if (this.newPwd !== null && this.newPwd !== '') {
        this.app.userSettings.password = this.newPwd;
      }
    }
    this.CRUDService.Update(this.app.userSettings, 'user').subscribe((data: any) => {
      if (data.success == 1) {
        this.CRUDService.getUserDetails(this.app.userSettings.username, this.app.userSettings.password).subscribe((userData: any) => {
          let user = userData.data[0];
          sessionStorage.setItem('loggedInUser', JSON.stringify(user));
          sessionStorage.setItem('company_id', user.company_id);
          this.loading = false;
          this.messageService.add({ severity: 'success', summary: 'Successful', detail: 'User setting Updated', life: 1000 });
          this.hideUserDetails = false;
        })
      }
    })
    this.loading = true;
  }


  changeLayout(event, mode) {
    this.app.darkMode = mode;
    if (mode === true) {
      this.app.layoutColor = this.darkColors[0].file;
      this.app.menuColorMode = 'dark';
      this.app.menuColor = 'layout-menu-dark';
      this.app.layoutColor = this.darkColors[0].file;
      this.menuColors = this.darkColors;
      this.app.changeLightDarkLayout('layout-css', this.darkColors[0].file, 'layout-dark');
      this.app.changeLightDarkTheme('theme-css', 'theme-dark');
    }
    else {
      this.app.menuColorMode = 'light';
      this.app.menuColor = 'layout-menu-light';
      this.app.layoutColor = this.lightColors[0].file;
      this.menuColors = this.lightColors;
      this.app.changeLightDarkLayout('layout-css', this.lightColors[0].file, 'layout-light');
      this.app.changeLightDarkTheme('theme-css', 'theme-light');
    }

    event.preventDefault();
  }



  readAsItem(m, e) {
    m.unread = !m.unread;
    localStorage.setItem("update", 'false');
    this.hideMessage(m);
  }

  viewAll() {

  }

  readAll() {
    localStorage.setItem("update", 'false');
    this.messageList.forEach(m => {
      m.unread = false;
      this.hideMessage(m);
    })
  }

  hideMessage(m) {
    if (m.unread == false) {
      setTimeout(() => {
        if (m.unread == false) {
          m.display = false;
        }
      }, 3000);
    }
  }
  changeFontSizes() {
    this.changeFontSize(this.fontsize.fontSize[0], this.fontsize.fontSize[1], this.fontsize.fontSize[1]);

  }
  changeFontSize(smFontSize: any, mdFontSize: any, lgFontSize: any) {
    document.documentElement.style.setProperty('--sm-font-size', smFontSize);
    document.documentElement.style.setProperty('--md-font-size', mdFontSize);
    document.documentElement.style.setProperty('--lg-font-size', lgFontSize);
  }

  checkPushNotification() {
    let self = this;
    let subscriptionStatus = sessionStorage.getItem('NotifcationSubscription');
    if (subscriptionStatus == null || subscriptionStatus == '') {
      /*Pushy.register({ appId: '61b087a4c38574d11c59855f' }).then(async function (deviceToken: any) {
        let pushsubscribers: any = await self.CRUDService.getDataByField('PushSubscribers', 'device_id', deviceToken).toPromise();
                 if (pushsubscribers.length > 0) {
                  let userdata = pushsubscribers.filter(val => (val.user_id = self.currentUser.id));
                  if (userdata.length < 1) {
                    await self.openNotificationModal();
                  }
                } else {
                  await self.openNotificationModal();
                } 
      });*/
    }
  }

  viewNotification() {
    const ref: any = this.dialogService.open(NotificationComponent, {
      width: '70%',
      height: 'max-content',
    })
    localStorage.setItem("ref", ref)
    ref.onClose.subscribe((data: any) => {
      if (data) {
        // console.log(data.ref_id)
        // data.ref_id = 179
        // this.CrudService.getDataByField('task_head', 'id', data.ref_id).subscribe((checkListData: any[]) => {
        //     console.log(checkListData)
        // });
        // ref.unsubscribe
        // this.openModal(data.ref_id);
      }
      this.dialofRef.close();
    });
  }

  openNotificationModal() {
    const ref = this.dialogService.open(NotificationPermissionComponent, {
      data: {
      },
      header: 'Push Notification',
      width: '350px',
      height: 'auto',
      styleClass: 'centerHeader'
    })
  }
  // saveRecord(){
  //   let saveStatus = true;

  //     let changedRecords = this.userFormDetails.filter(val=> (val.changed === true));
  //     if(changedRecords.length!=0){
  //       changedRecords.forEach(async userValue=> {
  //         let status = await this.saveRecordNew(userValue);
  //         if (!status){
  //           saveStatus = false;
  //           exit;
  //         }
  //       });
  //       if(saveStatus){
  //         this.messageService.add({ severity: 'success', summary: 'Successful', detail: changedRecords.length+' Records Updated', life: 1000 });
  //       } 
  //     }
  // }
  // async saveRecordNew(userValue){
  //   let status: Boolean = true;
  //     if (userValue.first_name.trim()) {
  //       if(userValue.id){
  //         let data: any = await this.CRUDService.Update(userValue,'id').toPromise();
  //       }
  //       else{
  //         let data: any = await this.CRUDService.Create(userValue,'id').toPromise();
  //       }
  //     }
  //     else{
  //       status = false;
  //       // //console.log("This is not working");

  //     }
  //     return status;
  // }
}
