import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class JobScheduleCreateService {
  jobDetailsList:any=[];
  constructor() { }

  getJobDetailsList(list){
    this.jobDetailsList=[...list]
  }

  setJobDetailsList(){
  return this.jobDetailsList
  }
}
