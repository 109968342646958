import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-equipments',
  templateUrl: './equipments.component.html',
  styleUrls: ['./equipments.component.css']
})
export class EquipmentsComponent implements OnInit {
  @Input() equipment:any
  constructor() { }

  ngOnInit() {
    //console.log(this.equipment.equipments_name)
  }

}
