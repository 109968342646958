import { Injectable } from "@angular/core";
import * as CryptoTS from 'crypto-ts';
import * as CryptoJS from 'crypto-js';
import * as bcrypt from 'bcrypt';

@Injectable({
  providedIn: "root"
})
export class EncryptionService {
  //key: string = "z!!!!!!!1sdfadsf56adf456asdfasdf";
  appProperties = {
    VALUES: {
      KEY: "MTIzNDU2Nzg5MEFCQ0RFRkdISUpLTE1O",
      IV: "MTIzNDU2Nzg="
    }
  }
  key : string= 'treemanager'

  constructor() {}

  encryptionAES (msg) {
    // Encrypt
    const ciphertext = CryptoJS.AES.encrypt(msg, this.key);
    return ciphertext.toString();
  }

  decryptionAES (msg) {
    // Decrypt
    const bytes  = CryptoJS.AES.decrypt(msg, this.key);
    const plaintext = bytes.toString(CryptoJS.enc.Utf8);
    return plaintext;
  }
}