import { Component, Input, OnInit } from '@angular/core';


@Component({
  selector: 'app-history',
  templateUrl: './history.component.html',
  styleUrls: ['./history.component.scss'],
  providers: []
})
export class HistoryComponent implements OnInit {
  @Input() jobId: any
  @Input() jobAllocationId: any
  @Input() jobActivityLog: any;

  constructor() {
  }

  ngOnInit() {
  }
  
}