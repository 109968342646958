import { Component, Input, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ConfirmationService, MessageService } from 'primeng/api';
import { DialogService, DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { CRUDService } from 'src/app/utilities/crud.service';
import { FileUploader } from 'ng2-file-upload';
import { environment } from 'src/environments/environment';


@Component({
  selector: 'app-task-messages',
  templateUrl: './task-messages.component.html',
  styleUrls: ['./task-messages.component.scss'],
  providers: [DialogService, MessageService, ConfirmationService]
})
export class TaskMessagesComponent implements OnInit {
  taskMessages: any[];
  uploadedFiles: any[] = [];
  userDetails: any;
  companyId: any;
  isActive: any;
  uploader: FileUploader;
  newMessage: string ='';
  taskStatus: any;
  taskDetails: any;
  tabs:any;
  allow_delete = '1';
  shortdateformat: any= environment.dateFormat;
  disableSavebtn: boolean = false;
  constructor(
    private route: ActivatedRoute,
    private crudService: CRUDService,
    private messageService: MessageService,
    private confirmationService: ConfirmationService,
    private router: Router,
    public ref: DynamicDialogRef,
    public config: DynamicDialogConfig,
    public dialogService: DialogService,
  ) {
    this.companyId = sessionStorage.getItem('company_id');
    this.userDetails = JSON.parse(sessionStorage.getItem('loggedInUser'));
  }
  taskMessage: any;
  hideMessageCard: any;
  @Input() task_id: any;
  admin: Boolean = false;

  ngOnInit() {
    this.crudService.getDataByField('task_head', 'id', this.task_id).subscribe((
      res: any) => {
      this.taskDetails = res[0];
      if (this.taskDetails.status === '3' || this.taskDetails.status === '5') {
        this.taskStatus = 'In-Active';
      } else {
        this.taskStatus = 'Active';
      }
    });

    this.isActive = true;
    this.taskMessage = {
      id: null,
      task_id: this.task_id,
      message: null,
      file_type: null,
      file_path: null,
      file_name: null,
      status: '1',
      action: null,
      star_colour: null,
      process_id: this.companyId,
      created_by: this.userDetails.id,
      created_at: new Date(),
      updated_by: null,
      updated_at: null,
      priority: null,
    }
    this.loadMessages('active');

    this.uploader = new FileUploader({
      disableMultipart: true, // 'DisableMultipart' must be 'true' for formatDataFunction to be called.
      formatDataFunctionIsAsync: true,
      formatDataFunction: async (item) => {
        return new Promise((resolve, reject) => {
          resolve({
            name: item._file.name,
            length: item._file.size,
            contentType: item._file.type,
            date: new Date()
          });
        });
      }
    });
    setTimeout(() => {
        this.tabs = {
          'keys' : ['active', 'starred', 'events', 'all', 'archived'],
          'active' : document.getElementById('tab-c-messages'),
          'starred' : document.getElementById('tab-c-starred'),
          'events' : document.getElementById('tab-c-events'),
          'all' : document.getElementById('tab-c-all'),
          'archived' : document.getElementById('tab-c-archived'),
        }
        this.updateActiveTabs('active');
    }, 1);
  }

  loadMessages(type) {
    this.crudService.getAllTaskMessages(this.task_id).subscribe((res: any) => {
      if (type === 'starred') {
        this.taskMessages = res.filter((val: any) => ((val.star_colour !== '' && val.star_colour !== null) && val.status !== '3'));
      } else if (type === 'active') {
        this.taskMessages = res.filter((val: any) => ((val.action === null || val.action === '') && val.status !== '3'));
      } else if (type === 'events') {
        this.taskMessages = res.filter((val: any) => ((val.action !== null && val.action !== '') && val.status !== '3'));
      } else if (type === 'all') {
        this.taskMessages = res.filter((val: any) => val.status !== '3');
      } else if (type === 'archived') {
        this.taskMessages = res.filter((val: any) => val.status === '3');
      }
    });
    this.updateActiveTabs(type);
  }

  upload(event) {
    for (let file of event) {
      this.uploadedFiles.push(file);
    }
  }
  uploadFile(file: any) {
    let imgPath = null;
    var reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = (_event) => {
      if (reader.DONE) {
        imgPath = reader.result;
        let res = null;
        if (file.type === 'image/png' || file.type === 'image/jpeg' || file.type === 'image/jpg' || file.type === 'image/gif' || file.type === 'image/svg') {
          this.taskMessage.file_type = 1;
          res = this.crudService.uploadTaskImage(imgPath, this.companyId, this.task_id, file.name);
          let response = JSON.parse(res)
          if (response.status === 1) {
            this.taskMessage.file_name = file.name;
            this.taskMessage.created_at = new Date();
            this.taskMessage.file_path = response.filepath;
            if (this.newMessage === '' || this.newMessage == null) {
              this.newMessage = '_';
            }
            this.crudService.Create(this.taskMessage, 'TaskMessages').subscribe((res: any) => {
              //console.log(res);
              this.uploadedFiles = [];
              if (res.success === 1) {
                this.messageService.add({ severity: 'success', summary: 'Successful', detail: 'Task Message Created', life: 1000 });
                this.hideMessageCard = false;
                this.newMessage = null;
                this.loadMessages('active');
              } else {
                this.messageService.add({ severity: 'error', summary: 'Error', detail: 'Task  Message Not created', life: 1000 });
              }
            })
          } else {
            this.messageService.add({ severity: 'error', summary: 'Error', detail: 'File Not Uploaded', life: 1000 });
          }
        } else {
          this.taskMessage.file_type = 2;
          res = this.crudService.uploadTaskDocument(file, this.companyId, this.task_id);
          let response = JSON.parse(res)
          if (response.success === 1) {
            this.taskMessage.file_name = file.name;
            this.taskMessage.created_at = new Date();
            this.taskMessage.file_path = this.companyId + '/' + this.task_id + '/' + file.name;
            if (this.newMessage === '' || this.newMessage == null) {
              this.newMessage = '_';
            }
            this.crudService.Create(this.taskMessage, 'TaskMessages').subscribe((res: any) => {
              //console.log(res);
              this.uploadedFiles = [];
              if (res.success === 1) {
                this.messageService.add({ severity: 'success', summary: 'Successful', detail: 'Task Message Created', life: 1000 });
                this.hideMessageCard = false;
                this.newMessage = null;
                this.loadMessages('active');
              } else {
                this.messageService.add({ severity: 'error', summary: 'Error', detail: 'Task  Message Not created', life: 1000 });
              }
            })
          } else {
            this.messageService.add({ severity: 'error', summary: 'Error', detail: 'File Not Uploaded', life: 1000 });
          }
        }


      }
    }
    this.disableSavebtn = false
  }

  saveMessage() {
    this.disableSavebtn = true
    if (this.isActive) {
      this.taskMessage.status = '1';
    }
    if (!this.isActive) {
      this.taskMessage.status = '2';
    }
    this.taskMessage.id = null;
    this.taskMessage.message = this.newMessage;
    if (!this.taskMessage.id) {
      /* this.taskMessage.CreatedUserName = this.userDetails.first_name + ' ' + this.userDetails.last_name; */
      this.taskMessage.created_date = new Date().toLocaleString();
      /* this.taskMessage.created_date_str = this.getDateString(new Date()); */
      if (this.uploadedFiles.length > 0) {
        this.uploadedFiles.forEach(file => {
          this.uploadFile(file);
        });
      } else if(this.newMessage){
        this.taskMessage.file_name = null;
        this.taskMessage.created_at = new Date();
        this.taskMessage.file_path = null;
        this.crudService.Create(this.taskMessage, 'TaskMessages').subscribe((res: any) => {
          //console.log(res);
          if (res.success === 1) {
            this.messageService.add({ severity: 'success', summary: 'Successful', detail: 'Task Message Created', life: 1000 });
            this.hideMessageCard = false;
            this.newMessage = null;
            this.loadMessages('active');
            this.disableSavebtn = false
          } else {
            this.messageService.add({ severity: 'error', summary: 'Error', detail: 'Task  Message Not created', life: 1000 });
            this.disableSavebtn = false
          }
        })
      }
      else{
        this.disableSavebtn = false
      }
    }
    if (this.taskMessage.id) {
      this.crudService.Update(this.taskMessage, 'TaskMessages').subscribe((res: any) => {
        //console.log(res);
        this.disableSavebtn = false
        if (res.success === 1) {
          this.messageService.add({ severity: 'success', summary: 'Successful', detail: 'Task Message Updated', life: 1000 });
          this.hideMessageCard = false;
          this.newMessage = null;
          this.loadMessages('active');
        } else {
          this.messageService.add({ severity: 'error', summary: 'Error', detail: 'Task  Message Not Updated', life: 1000 });
        }
      })
    }

  }
  getDateString(date: Date): any {
    const year = date.getFullYear();
    const month = date.getMonth();
    const day = date.getDay();
    const formatted = `${month}, ${day} ${year} ${date.toLocaleTimeString()}`
    return formatted;
  }

  editMessage(message) {
    this.hideMessageCard = true;
    this.taskMessage = { ...message }
    if (this.taskMessage.status === '' || this.taskMessage.status === null || this.taskMessage.status === '1') {
      this.taskMessage.status = '1'
      this.isActive = true;
    }
    if (this.taskMessage.status === '2') {
      this.taskMessage.status = '2'
      this.isActive = false;
    }
  }

  deleteMessage(message) {
    this.confirmationService.confirm({
      message: 'Are you sure you want to delete Task Message : ' + message.message + '?',
      header: 'Confirm',
      icon: 'pi pi-exclamation-triangle',
      accept: () => {
        this.taskMessages = this.taskMessages.filter((val: any) => val.id !== message.id);
        message.status = '3';//Archive
        message.updated_at = new Date();
        message.updated_by = this.userDetails.id;
        this.crudService.Update(message, 'TaskMessages').subscribe(() => {
          this.messageService.add({ severity: 'success', summary: 'Successful', detail: 'Message Archived', life: 1000 });
        });
      }
    });
  }

  cancel() {
    this.hideMessageCard = false;
  }

  viewFile(message) {
    import('file-saver').then(FileSaver => {
      this.crudService.fetchTaskFile(message.process_id, message.task_id, message.file_path).subscribe(blob => {
        const a = document.createElement('a')
        const objectUrl = URL.createObjectURL(blob)
        a.href = objectUrl
        a.download = message.file_name;
        a.click();
        URL.revokeObjectURL(objectUrl);
      })
    })
  }

  starMessage(message) {
    message.star_colour = '#ff0066';
    this.crudService.Update(message, 'TaskMessages').subscribe((res: any) => {
      //console.log(res);
      if (res.success === 1) {
        this.messageService.add({ severity: 'success', summary: 'Successful', detail: 'Message Starred', life: 1000 });
      } else {
        this.messageService.add({ severity: 'error', summary: 'Error', detail: 'Message Not Starred', life: 1000 });
      }
    })
  }

  unStarMessage(message) {
    message.star_colour = null;
    this.crudService.Update(message, 'TaskMessages').subscribe((res: any) => {
      //console.log(res);
      if (res.success === 1) {
        this.messageService.add({ severity: 'success', summary: 'Successful', detail: 'Message  Starring Removed', life: 1000 });
      } else {
        this.messageService.add({ severity: 'error', summary: 'Error', detail: 'Message Starring Not Removed', life: 1000 });
      }
    })
  }
  updateActiveTabs(setActive?) {
    if(this.tabs && this.tabs.keys) {
      this.tabs.keys.forEach(k => {
        this.tabs[k].classList.remove('active');
      });
      if(setActive) {
        this.tabs[setActive].classList.add('active');
      }
    }
  }
}
