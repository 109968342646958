import { Component, Input, OnInit } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { ConfirmationService, MessageService } from 'primeng/api';
import { DialogService, DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { CRUDService } from 'src/app/utilities/crud.service';
import { JOBService } from 'src/app/utilities/job.service';

@Component({
  selector: 'app-job-allocation',
  templateUrl: './job-allocation.component.html',
  styleUrls: ['./job-allocation.component.css']
})
export class JobAllocationComponent implements OnInit {
  jobAllocationList:any[];
  selectedClients:any[];
  cols :any;
  clientList:any[];
  @Input() jobId :any
  constructor(    private route: ActivatedRoute,
    private crudService: CRUDService,
    private messageService: MessageService,
    private confirmationService: ConfirmationService,
    private router: Router,
    public ref: DynamicDialogRef,
    public config: DynamicDialogConfig,
    public JobService: JOBService,
    public sanitizer: DomSanitizer,) { }

  ngOnInit() {

  }

  getAllocatedJob () {

    this.crudService.getAllAllocatedJobByJobId(this.jobId).subscribe((res:any[])=> {
        //console.log('all the job allocation list');
        //console.log(res);
       res.forEach((alloc) => {
            alloc.driving_time = alloc.driving_time ? alloc.driving_time : 'N/A';
            if (alloc.accept_status) {
                if (alloc.accept_status == 1) {
                    alloc.status_text = 'Accepted';
                    alloc.inputDisable = true;
                    alloc.reAlloc = false;
                    alloc.accept = false;
                    alloc.cancel = true;
                } else if (alloc.accept_status == 2) {
                    alloc.status_text = 'Rejected';
                    alloc.inputDisable = true;
                    alloc.reAlloc = true;
                    alloc.accept = false;
                    alloc.cancel = false;
                }
            } else {
                if (alloc.allocation_status == 1) {
                    alloc.status_text = 'Allocated';
                    alloc.inputDisable = false;
                    alloc.reAlloc = false;
                    alloc.accept = true;
                    alloc.cancel = true;
                } else if (alloc.allocation_status == 2) {
                    alloc.status_text = 'Canceled';
                    alloc.inputDisable = true;
                    alloc.reAlloc = true;
                    alloc.accept = false;
                    alloc.cancel = false;
                }
            }
        });
        this.jobAllocationList = res;
      })
 }


}
