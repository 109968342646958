import { Component, OnInit, Input } from '@angular/core';
import { CRUDService } from 'src/app/utilities/crud.service';
import { ConfirmationService } from 'primeng/api';
import { MessageService } from 'primeng/api';
import { Table } from 'primeng/table';
import { CommonService } from 'src/app/utilities/common.service'
import { environment } from 'src/environments/environment';
import { DynamicDialogConfig } from 'primeng/dynamicdialog';

@Component({
  selector: 'app-defn-hooks',
  templateUrl: './defn-hooks.component.html',
  styleUrls: ['./defn-hooks.component.css' , '../admin.component.scss']
})
export class DefnHooksComponent implements OnInit {

  loading: boolean = false;
  paginationvalue: any;
  cols: any[];
  exportColumns: any[];
  defnDetailDialog: boolean = false;
  submitted: boolean;
  defnHookList: any[];
  defnDetail: any;
  selectedDefnDetail: any[];
  companyList: any[];
  isActive: boolean = true;
  selectedCompany: any;
  speedDial: boolean = false;
  isEditAll: boolean = false;
  exportdisplay: Boolean = false;
  @Input() defnHead: any;
  @Input() defnTitle: any;
  triggerAction: any[];
  triggerEvent: any[];
  fieldCodeList:any[];
  defnHooks:any;
  hookList:any;

  selectedFieldCode: any;
  selectedHook:any;
  selectedTriggerAction: any;
  selectedTriggerEvent: any;

  isExport: boolean=false;
  isDelete: boolean=false;
  selectedroute: string;
  userDetails: any;

  constructor(private crudService: CRUDService,
    private messageService: MessageService,
    private confirmationService: ConfirmationService,
    private commonservice: CommonService,
    public config: DynamicDialogConfig,) { 

      this.userDetails = JSON.parse(sessionStorage.getItem('loggedInUser'));
    }

  async ngOnInit(): Promise<void> {

    if (this.config && this.config.data) {
      this.defnHead = this.config.data.code;

    }
    this.loading = true;
    this.paginationvalue = environment.paginatorValue;

    this.selectedroute = sessionStorage.getItem('selectedRoute')
    let per_data :any=await this.crudService.getmenupermissions()
    this.isExport = per_data.isExport;
    this.isDelete = per_data.isDelete;

    this.crudService.getAllCompanies().subscribe((data: any[]) => {
      this.companyList = data;
    });
    this.cols = [
      { field: 'hook_cdh_code', header: 'Module' },
      { field: 'hook_cdd_code', header: 'Field Code' },
      { field: 'hook_code', header: 'Hook master' },
      { field: 'trigger_action', header: 'Trigger Action' },
      { field: 'trigger_event', header: 'Trigger Event' }
    ];
    this.triggerAction = [
      { id: 'Save', value: 'Save' },
      { id: 'Create', value: 'Create' },
      { id: 'Update', value: 'Update' },
      { id: 'Delete', value: 'Delete' },
      { id: 'Validation', value: 'Validation' }
    ];
    this.triggerEvent = [
      { id: 'Before', value: 'Before' },
      { id: 'After', value: 'After' }
    ];
   
    this.exportColumns = this.cols.map(col => ({ title: col.header, dataKey: col.field }));
    this.exportdisplay = await this.commonservice.exportCheck();
    this.loading = false;
    this.loadData();

  }

  loadData() {
    this.crudService.getDataByField('ComponentDefnHooks', 'hook_cdh_code', this.defnHead).subscribe((data: any[]) => {
      this.defnHookList= data.sort((a, b) => a.seq.localeCompare(b.seq));
    });
    this.crudService.getDataByField('ComponentDefnDetail','component_code',this.defnHead).subscribe((pData: any) => {
      this.fieldCodeList = pData;
    });
    this.crudService.getAllData('hooks').subscribe((hData: any) => {
      this.hookList = hData;
    });
  }


  openNew() {
    this.defnHooks = {
      id: null,
      hook_cdh_code: this.defnHead,
      hook_cdd_code: null,
      hook_code: null,
      trigger_action: null,
      trigger_event: null,
      status: null
    };
    this.defnDetailDialog = true;
  }

  editRecord(defnHooks: any) {
    this.defnHooks = { ...defnHooks };
    if (this.defnHooks.status === '' || this.defnHooks.status === null || this.defnHooks.status === '1') {
      this.defnHooks.status = '1'
      this.isActive = true;
    } else {
      this.defnHooks.status = '2'
      this.isActive = false;
    }

    let dataTemp = this.fieldCodeList.filter(val => val.field_name === this.defnHooks.hook_cdd_code);
    if (dataTemp.length > 0) {
      this.selectedFieldCode = dataTemp[0];
    }
    else{
      this.selectedFieldCode = null;
    }

    let dataTempHook = this.hookList.filter(val => val.code === this.defnHooks.hook_code);
    if (dataTempHook.length > 0) {
      this.selectedHook = dataTempHook[0];
    }
    else{
      this.selectedHook = null;
    }

    let trigger_action = this.triggerAction.filter(val => val.id === this.defnHooks.trigger_action.trim());
    if (trigger_action.length > 0) {
      this.selectedTriggerAction = trigger_action[0];
    }
    else{
      this.selectedTriggerAction = null;
    }

    let trigger_event = this.triggerEvent.filter(val => val.id === this.defnHooks.trigger_event.trim());
    if (trigger_event.length > 0) {
      this.selectedTriggerEvent = trigger_event[0];
    }
    else{
      this.selectedTriggerEvent = null;
    }
    console.log("Data save", this.defnDetail);
    this.defnDetailDialog = true;

  }
  // deleteSelectedJob(){
  //     this.confirmationService.confirm({
  //           message: 'Are you sure you want to delete the selected Job Priority?',
  //           header: 'Confirm',
  //           icon: 'pi pi-exclamation-triangle',
  //           accept: () => {
  //               // tslint:disable-next-line: prefer-for-of
  //               for (let i = 0; i < this.selectedJobPriority.length; i++) {
  //                   this.crudService.Delete(this.selectedJobPriority[i].id, 'JobPriority').subscribe((data: any[]) => { });
  //               }
  //               this.jobPriorityList = this.jobPriorityList.filter(val => !this.selectedJobPriority.includes(val));
  //               this.selectedJobPriority = null;
  //               this.messageService.add({ severity: 'success', summary: 'Successful', detail: 'Job Priority Deleted', life: 1000 });
  //           }
  //       });
  // }

  deleteRecord(defnHookList: any) {
    debugger
    this.confirmationService.confirm({
      message: 'Are you sure you want to delete  : ' + defnHookList.id + '?',
      header: 'Confirm',
      icon: 'pi pi-exclamation-triangle',
       accept: () => {
        this.crudService.deleteRecordByID('ComponentDefnHooks',defnHookList.id).subscribe((data: any[]) => {
          this.messageService.add({ severity: 'success', summary: 'Successful', detail: 'Hooks Deleted', life: 1000 });
          this.loadData();
        });
      }
    });
  }

  findIndexById(id: string): number {
    let index = -1;
    for (let i = 0; i < this.defnHookList.length; i++) {
      if (this.defnHookList[i].id === id) {
        index = i;
        break;
      }
    }

    return index;
  }

  async saveRecord() {   
    if (this.selectedFieldCode && typeof this.selectedFieldCode === 'object') {
      console.log("display_type object");
      const hook_cdd_code = this.selectedFieldCode.field_name
      this.defnHooks.hook_cdd_code = hook_cdd_code
    }
    if (this.selectedHook && typeof this.selectedHook === 'object') {
      console.log("required object");
      const hook_code = this.selectedHook.code
      this.defnHooks.hook_code = hook_code
    }
    if (this.selectedTriggerAction && typeof this.selectedTriggerAction === 'object') {
      console.log("field_type object");
      const trigger_action = this.selectedTriggerAction.id
      this.defnHooks.trigger_action = trigger_action
    }
    if (this.selectedTriggerEvent && typeof this.selectedTriggerEvent === 'object') {
      console.log("field_type object");
      const trigger_event = this.selectedTriggerEvent.id
      this.defnHooks.trigger_event = trigger_event
    }

    if (this.isActive) {
      this.defnHooks.status = '1';
    }
    else {
      this.defnHooks.status = '2';
    }


    if (this.defnHooks.id) {
      let response: any = await this.commonservice.saveRecord('ComponentDefnHooks', this.defnHooks);
      if (response.success == "1") {
        this.messageService.add({ severity: 'success', summary: 'Successful', detail: 'Definition Detail Created', life: 1000 });
        this.defnDetailDialog = false;
      }
      else {
        this.messageService.add({ severity: 'error', summary: 'Failed', detail: 'Failed', life: 1000 });
      }
    }
    else {
      console.log("Data else", this.defnHooks);
      let response: any = await this.commonservice.saveRecord('ComponentDefnHooks', this.defnHooks);
      if (response.success == "1") {
        this.messageService.add({ severity: 'success', summary: 'Successful', detail: 'Definition Detail Updated', life: 1000 });
        this.defnDetailDialog = false;
      }
      else {
        this.messageService.add({ severity: 'error', summary: 'Failed', detail: 'Failed', life: 1000 });
      }
    }
    this.loadData();
  }

  hideDialog() {
    this.defnDetailDialog = false;
    this.submitted = false;
  }

  exportPdf() {
    import('jspdf').then(jsPDF => {
      import('jspdf-autotable').then(x => {
        /* const doc = new jsPDF.default(0,0);
        doc.autoTable(this.exportColumns, this.selectedDepots);
        doc.save('products.pdf'); */
      })
    })
  }

  exportExcel(selectedJob) {
    import('xlsx').then(xlsx => {
      const worksheet = xlsx.utils.json_to_sheet(this.selectedDefnDetail);
      const workbook = { Sheets: { data: worksheet }, SheetNames: ['data'] };
      const excelBuffer: any = xlsx.write(workbook, { bookType: 'xlsx', type: 'array' });
      this.saveAsExcelFile(excelBuffer, 'ComponentDefnDetail');
    });
  }

  saveAsExcelFile(buffer: any, fileName: string): void {
    import('file-saver').then(FileSaver => {
      const EXCEL_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
      const EXCEL_EXTENSION = '.xlsx';
      const data: Blob = new Blob([buffer], {
        type: EXCEL_TYPE
      });
      FileSaver.saveAs(data, fileName + '_export_' + new Date().getTime() + EXCEL_EXTENSION);
    });
  }

  clear(table: Table) {
    table.clear();
  }

}

