import { Component, OnInit } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { ActivatedRoute } from '@angular/router';
import { ConfirmationService, MessageService } from 'primeng/api';
import { DynamicDialogConfig, DynamicDialogRef,DialogService } from 'primeng/dynamicdialog';
import { CRUDService } from 'src/app/utilities/crud.service';
import { environment } from 'src/environments/environment';
import { CommonService } from 'src/app/utilities/common.service';
import { Table } from 'primeng/table';

@Component({
  selector: 'app-training-list',
  templateUrl: './training-list.component.html',
  styleUrls: ['./training-list.component.scss'],
  providers: [DialogService, MessageService, ConfirmationService]
})
export class TrainingListComponent implements OnInit {

  paginationvalue:any;
  dateformat:any;
  cols: any;
  selectedRecords: any;
  dataList: any[];
  exportColumns: any;
  speedDial: Boolean = false;
  exportdisplay:Boolean = true;
  allStaff: any[];
  datax: any=[];
  data: any=[];
  eventList:any=[];
  companyData:any;

  constructor(

    private CrudService: CRUDService,
    private messageService: MessageService,
    private confirmationService: ConfirmationService,
    public ref: DynamicDialogRef,
    public config: DynamicDialogConfig,
    public dialogService: DialogService,
    public sanitizer: DomSanitizer,
    private route: ActivatedRoute,
    private commonservice: CommonService
  ) { }

  async ngOnInit() {
    
    this.dateformat=environment.dateFormat
    this.paginationvalue=environment.paginatorValue;
    this.selectedRecords = null;
    
    this.getEventList();
    
    this.CrudService.getAllData('company').subscribe((data: any[]) => {
      this.companyData = data;

     } );
  }


displayCompany(company_id){
  let companyName = '';
  for (let i = 0; i < this.companyData.length; i++) {
      if (this.companyData[i].id === company_id) {
        companyName = this.companyData[i].business_name;
          break;
      }
  }
  return companyName;
}

  async getEventList() {
    this.CrudService.getAllData('ScheduledTraining').subscribe((data: any[]) => {
       this.eventList = data.filter(val=>(val.status == '2'));
      //  this.eventList = data;
     } );
  }

  
  approve(eventListid) {
    this.confirmationService.confirm({
      message: 'Are you sure you want to Approve the Cancellation?',
      accept: async () => {
        eventListid.status = '3';
        debugger
       let response: any = await this.commonservice.saveRecord('scheduledtraining', eventListid);
       console.log(response);
          if(response.success==1)
          {
            this.messageService.add({ severity: 'success', summary: 'Successful', detail:'Approved', life: 1000 });
          }
        this.getEventList();
      }
  });   
  }
}
