import { Component, OnInit } from '@angular/core';
import { CRUDService } from 'src/app/utilities/crud.service';
import { DynamicDialogRef, DialogService } from 'primeng/dynamicdialog';
import { Table } from 'primeng/table';
import { ContactComponent } from '../../admin/contact/contact.component';
import { ComplianceComponent } from '../../admin/compliance/compliance.component';
import { ConfirmationService, MessageService } from 'primeng/api';
import { FormControl, FormGroup, Validators } from '@angular/forms';
@Component({
  selector: 'app-workflow',
  templateUrl: './workflow.component.html',
  styleUrls: ['./workflow.component.css'],
  providers: [DialogService, MessageService, ConfirmationService]
})
export class WorkflowComponent implements OnInit {
  workFlow: any
  workflows: any
  workFlowForm: FormGroup;
  userDetails: any
  workflowDialog: any = false;
  workFlowList: any[];
  cols: any;
  submitted: any;
  constructor(private crudService: CRUDService,
    private messageService: MessageService,
    private confirmationService: ConfirmationService,
    private dialogService: DialogService) { }

  ngOnInit() {
    this.userDetails = JSON.parse(sessionStorage.getItem('loggedInUser'));
    this.workFlowForm = new FormGroup({
      name: new FormControl('', Validators.required),
      description: new FormControl('', Validators.required),
      type: new FormControl('', Validators.required),

    })
    this.workFlow = {
      company_id: this.userDetails.company_id,
      created_at: null,
      created_by: "1",
      description: null,
      id: null,
      name: null,
      process_id: this.userDetails.company_id,
      type: null,
      updated_at: null,
      updated_by: this.userDetails.id
    }
    //console.log(this.workFlow)
    this.crudService.getDataByField('workflow_head', 'process_id', this.workFlow.process_id).subscribe((data: any) => {
      //console.log(data);
      this.workFlowList = data;
    })
  }
  openNew() {
    this.workflowDialog = true;
  }
  saveWorkFlow() {
    //console.log(this.workFlowForm.status);
    if (this.workFlowForm.status === 'VALID') {
      if (this.workFlow.id === null) {
        this.crudService.Create(this.workFlow, 'workflow').subscribe((res: any) => {
          if (res.sucess == 1) {
            this.messageService.add({ severity: 'success', summary: 'Successful', detail: 'Workflow Details Created', life: 1000 });
          }
          else {
            this.messageService.add({ severity: 'error', summary: 'Error', detail: 'Workflow Details Not Created', life: 1000 });
          }
        })
      }
      if (this.workFlow.id != null) {
        this.crudService.Update(this.workFlow, 'workflow').subscribe((res: any) => {
          if (res.sucess == 1) {
            this.messageService.add({ severity: 'success', summary: 'Successful', detail: 'Workflow Details Updated', life: 1000 });
          }
          else {
            this.messageService.add({ severity: 'error', summary: 'Error', detail: 'Workflow Details Not Updated', life: 1000 });
            this.workFlowList[this.findIndexById(this.workFlow.id)] = this.workFlow;
            this.workflowDialog = false;
          }
        })
      }
    }
    else {
      this.messageService.add({ severity: 'error', summary: 'Error', detail: 'Workflow Not Created/Updated. Need all Mandatory fields filled properly.', life: 1000 });
    }
  }
  findIndexById(id: string): number {
    let index = -1;
    for (let i = 0; i < this.workFlowList.length; i++) {
      if (this.workFlowList[i].id === id) {
        index = i;
        break;
      }
    }
    return index;
  }
  editSelectedRecord(workflow) {
    this.workFlow = workflow;
    this.workflowDialog = true;
  }
  deleteWorkflow(workflow) {
    this.workFlow = workflow;
    this.crudService.Delete(this.workFlow.id, 'workflow').subscribe((res: any) => {
      //console.log(res);
      if (res.success == 1) {
        this.messageService.add({ severity: 'success', summary: 'Successful', detail: 'Workflow Deleted', life: 1000 });
      }
    })
  }
  deleteSelectedWorkflow() {

  }
  cancel() {
    this.workflowDialog = false;
  }
}