import { Component, Input } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { environment } from 'src/environments/environment';

// text,email,tel,textarea,password, 
@Component({
    selector: 'datetime',
    template: `  
    <div [formGroup]="form">
    <input *ngIf="!field.multiline" type="datetime-local" class="form-control" 
    [id]="field.name" [name]="field.name" [formControlName]="field.name" [attr.required]="field.required">                                
  </div>
  <div class="alert alert-danger my-1 p-2 fadeInDown animated" *ngIf="!isValid && isDirty" > Enter valid {{field.label}} </div>
  `
})
export class DateTimeComponent {
    @Input() field:any = {};
    @Input() form:FormGroup;
    dateFormat: string;
    value: Date;   
    get isValid() { return this.form.controls[this.field.name].valid; }
    get isDirty() { return this.form.controls[this.field.name].dirty; }
    constructor() {
        this.value = new Date();
        console.log("this.value",this.value);
    }
    ngOnInit() {
        this.dateFormat = environment.dateFormat;
        console.log("dateFormat",this.dateFormat);
        // <input *ngIf="!field.multiline" type="datetime-local" class="form-control" 
        // [id]="field.name" [name]="field.name" [formControlName]="field.name" [attr.required]="field.required">
    
    //     <p-calendar [showIcon]="true" dateFormat="{{dateFormat}}" 
    //       title="Select Required By " 
    //       appendTo="body"   
    //       [minDate]="today"
    //       [id]="field.name"
    //       [(ngModel)]="value"    
    //       [formControlName]="field.name"
    //       [showTime]="true"
    //       [required]="(field.required == true)? true : false" autofocus aria-readonly="true">
    //  </p-calendar>

    // type="datetime-local"
    // <textarea *ngIf="!field.multiline" [attr.type]="field.type" class="form-control" [id]="field.name" [name]="field.name" [formControlName]="field.name">
    }
}