import { Component, OnInit, Input, OnDestroy } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { ConfirmationService, MessageService } from 'primeng/api';
import { DialogService, DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { CRUDService } from 'src/app/utilities/crud.service';
import { JOBService } from 'src/app/utilities/job.service';
import { delay } from 'rxjs/operators';

@Component({
  selector: 'app-app-mail',
  templateUrl: './app-mail.component.html',
  styleUrls: ['./app-mail.component.scss'],
  providers: [DialogService, ConfirmationService, MessageService ]
})
export class AppMailComponent implements OnInit {
  @Input() params

  ifCc: any = false;
  ifBCc: any = false;

  currentUser: any = [];
  attachmentList = [];
  promiseList: any = [];
  promiseListCompress: any = [];
  promiseListDelete: any = [];
  EmailAccount: any;
  replyTo: any;
  emailContent: any;
  emailSubject: any;
  contactNo: any;
  templ: any;
  ccEmail: any;
  BccEmail: any;
  toEmail: any;
  emailAttachment: any[];
  selectedEmailAttachment: any = [];
  attachments: any = '';
  emailArray: any;
  userDetails: any;
  companyId: any;
  apiRoot: any;
  uploadedFiles: any[] = [];

  constructor(
    private route: ActivatedRoute,
    private crudService: CRUDService,
    private messageService: MessageService,
    private confirmationService: ConfirmationService,
    private router: Router,
    public ref: DynamicDialogRef,
    public config: DynamicDialogConfig,
    public dialogService: DialogService,
    public JobService: JOBService
  ) { }

  ngOnInit() {
    this.companyId = sessionStorage.getItem('company_id');
    this.userDetails = JSON.parse(sessionStorage.getItem('loggedInUser'));
    this.currentUser = JSON.parse(sessionStorage.getItem('currentUser'));
    this.EmailAccount = 'AU';
  }

  onCheckCC(value) {
    //console.log(value)
    if (value === 1) {
      this.ifCc = true;
    }
  }

  onCheckBCC(value) {
    if (value === 1) {
      this.ifBCc = true;
    }
  }

  templateChange(value) {
    //console.log(value);
  }

  dataURLtoFile(dataurl, filename) {
    let arr = dataurl.split(','), mime = arr[0].match(/:(.*?);/)[1],
      bstr = atob(arr[1]), n = bstr.length, u8arr = new Uint8Array(n);
    while (n--) {
      u8arr[n] = bstr.charCodeAt(n);
    }
    return new File([u8arr], filename, { type: mime });
  }

  async sendEmail() {
    this.attachmentList = [];
    //console.log('sendEmail');
    this.templ = {};
    this.templ.template = [{ email: {} }];
    this.templ.template[0].email.to = this.toEmail;
    if (this.ifCc) {
      this.templ.template[0].email.cc = this.ccEmail;
    }
    if (this.ifBCc) {
      this.templ.template[0].email.bcc = this.BccEmail;
    }
    this.templ.template[0].email.subject = this.emailSubject ? this.emailSubject : null;
    /* if (this.itemType == '2') {
      this.templ.template[0].email.bcc = "operations@envirofrontier.com.au";
    }
    if (this.itemType == '1') {
      this.templ.template[0].email.bcc = "";
    } */
    this.templ.template[0].email.attachments = this.attachments;
    let temEcontent = this.emailContent;
    if (this.emailContent) {
      temEcontent = temEcontent.replace(/>[\n\t&nbsp;]+</g, "><");
      temEcontent = temEcontent.replace(/&nbsp;/g, '');
      temEcontent = temEcontent.replace(/&ndash;/g, '');
      temEcontent = temEcontent.replace(/&/g, String.fromCharCode(228));
      temEcontent = JSON.stringify(temEcontent);
    }
    temEcontent = temEcontent.replace(/"/g, "");
    this.templ.template[0].email.msg = temEcontent ? temEcontent : ' ';

    if (this.selectedEmailAttachment.length > 0) {

      let res = await Promise.all(this.attachmentList);
      //console.log('promise res', res)
      let atts = {}
      /* let atts = {
        jobId: this.jobId,
        allocId: this.jobAllocationId,
        imgList: this.imgDataList,
        imgPdf: imgPdfUrl,
        invPdfUrl: invPdfUrl,
        quoPdfUrl: quoPdfUrl,
        wpinvPdfUrl: wpinvPdfUrl,
        wpquoPdfUrl: wpquoPdfUrl,
        documentList: this.documentData
      }; */
      const params = {
        sms: null,
        email: this.templ.template[0].email,
        type: 'email',
        selectedTpl: null,
        sufix: null,
        attachments: atts,
        jobData: null,
        replyTo: this.replyTo,
        account: this.EmailAccount
      }
      this.crudService.sendMessage(params).subscribe((res2: any[]) => {
      });
    }
  }
  onUpload(event) {

  }
}