import { Component, Input, OnInit } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { DomSanitizer } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { ConfirmationService, MessageService } from 'primeng/api';
import { CommonService } from 'src/app/utilities/common.service';
import { DialogService, DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { CRUDService } from 'src/app/utilities/crud.service';
import { JOBService } from 'src/app/utilities/job.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-communication-admin',
  templateUrl: './communication-admin.component.html',
  styleUrls: ['./communication-admin.component.scss']
})
export class CommunicationAdminComponent implements OnInit {
  @Input() job_id: any;
  @Input() job_alloc_id: any;
  @Input() jobSummary: any;
  jobschedule: any
  notesChanged: boolean = false;
  showHeader: Boolean = false;
  Recipient: any[];
  communicationType: any[];
  loading: Boolean = false;
  Date_str: any;
  attachmentType: any[];
  selectedAttachmentType: any[] = [];
  selectedRecipient: any = null;
  isEmail: boolean = false;
  isSMS: boolean = false;
  display: boolean = false;
  title: any;
  jobDetails: any;
  jobInfo: any;
  date: any;
  isPhoned: boolean = false;
  isCallBack: boolean = false;
  isPMOnly: boolean = true;
  submitted: boolean = false;
  createSchedule: any = false
  scheduleJob: any;
  currentUser: any;
  menuData: any;
  viewType: any;
  messageCategory: any[];
  selectedmessageCategory: any = null;
  jobAllocationResults: any;
  type_of_visit: any[];
  emptySchedNote: any;
  userDetails: any;
  toggleCommSched: any = true;
  smsInvalid: any;
  smsEmpty: any;
  schedule_data: any;
  selectedVisitType: any;
  visitTypeList: any[];
  selectedStartTime: any;
  selectedEndTime: any;
  timeSlots: any[];
  filteredTimeSlots: any[];
  selectdEmailId: any[] = [];
  mailIdOptions: string[] = [];
  emailId: any;
  showError: boolean = false;
  minDate: Date;
  maxDate: Date;
  appProps: any;
  scheduleForm = new FormGroup({
      schednote: new FormControl(''),
      isEmail: new FormControl(''),
      isPhoned: new FormControl(''),
      isSMS: new FormControl(''),
      isCallBack: new FormControl(''),
      isPMOnly: new FormControl(''),
      phone_no: new FormControl(''),
      sms_no: new FormControl(''),
      email: new FormControl(''),
      atch: new FormControl('')
    })

  emailsList :any;

  /*   emailsList: any[] = [{ value: 'krishnanshridhar@gmail.com' },
    { value: 'tmsupport@agileitexperts.com' },
    { value: 'shri@envirofrontier.com.au' },
    { value: 'accounts@envirofrontier.com.au' },
    { value: 'mark@envirofrontier.com.au' },
    { value: 'peter@envirofrontier.com.au' },
    { value: 'teamleader@envirofrontier.com.au' },
    { value: 'documents@envirofrontier.com.au' },
    { value: 'support@envirofrontier.com.au' },
    { value: 'admin@envirofrontier.com.au' }];
   */
  constructor(
    private route: ActivatedRoute,
    private crudService: CRUDService,
    private messageService: MessageService,
    private confirmationService: ConfirmationService,
    private router: Router,
    private commonservice: CommonService,
    public ref: DynamicDialogRef,
    public config: DynamicDialogConfig,

    public JobService: JOBService,
    public sanitizer: DomSanitizer,
    public dialogRef: DynamicDialogRef,
  ) {
    this.userDetails = JSON.parse(sessionStorage.getItem('loggedInUser'));
    this.timeSlots = ['12:00am', '12:30am', '1:00am', '1:30am', '2:00am', '2:30am', '3:00am', '3:30am', '4:00am', '4:30am', '5:00am', '5:30am', '6:00am', '6:30am', '7:00am', '7:30am', '8:00am', '8:30am', '9:00am', '9:30am', '10:00am', '10:30am', '11:00am', '11:30am',
      '12:00pm', '12:30pm', '1:00pm', '1:30pm', '2:00pm', '2:30pm', '3:00pm', '3:30pm', '4:00pm', '4:30pm', '5:00pm', '5:30pm', '6:00pm', '6:30pm', '7:00pm', '7:30pm', '8:00pm', '8:30pm', '9:00pm', '9:30pm', '10:00pm', '10:30pm', '11:00pm', '11:30pm'];
    this.filteredTimeSlots = this.timeSlots;
  }
  async ngOnInit() {
    this.loading = true;
    this.appProps = this.commonservice.getAppProperties();
    this.showHeader = false;
    this.emailsList = await this.commonservice.getMailerlist();
    this.currentUser = JSON.parse(sessionStorage.getItem('currentUser'));
    /* if (typeof this.config.data == 'undefined' || typeof (this.config.data.jobId) == 'undefined') {
    } else {
      this.job_id = this.config.data.jobId;
      this.job_alloc_id = this.config.data.jobAllocationId;
      this.type = this.config.data.type;
      this.jobSummary = this.config.data.jobSummary; 
    }
    */
    this.crudService.getDataByField('jobInfo', 'id', this.job_id).subscribe((data: any[]) => {
      this.minDate = new Date(data[0].job_recd_dt)
    })
    this.showHeader = false;
    /* if (this.job_alloc_id == '1') {
      this.display = true;
    } else {
      this.display = false;
    } */
    this.display = false;
    this.scheduleJob = {
      PMOnly: null,
      action_required: null,
      callback: '1',
      created_at: new Date(),
      created_by: this.currentUser.userId,
      email: '1',
      emailaddress: null,
      end_time: null,
      id: null,
      job_alloc_id: this.job_alloc_id,
      job_id: this.job_id,
      last_modified_by: null,
      last_updated_at: null,
      owner: null,
      phone_no: null,
      phoned: '1',
      process_id: this.currentUser.company,
      sched_date: new Date(),
      sched_note: null,
      sms: '1',
      sms_no: null,
      start_time: null,
      status: '1',
      username: this.currentUser.username,
      visit_type: null,
      source: null,
      message_received: 1,
      message_flow: 1,
      comm_recipient: null,
      comm_recipient_subcatg: null
    }

    if (this.job_alloc_id === null || this.job_alloc_id === '') {
      this.job_alloc_id = '1';
    }

    if (this.job_alloc_id !== '1') {
      let smryData: any = await this.crudService.getJobSummary(this.job_id, this.job_alloc_id).toPromise();
      this.jobAllocationResults = await this.JobService.getJobInfo(smryData[0]);
      this.showHeader = true;
    } else {
      this.jobAllocationResults = this.job_alloc_id;
      this.jobSummary = this.crudService.getDataByField('JobInfo', 'id', this.job_id);
    }
    this.communicationType = [
      { id: '1', title: 'Phoned' },
      { id: '2', title: 'SMS' },
      { id: '3', title: 'Email' },
      { id: '4', title: 'Call Back' },
    ]
    this.crudService.getUploadDocumentsByJobIdAllocId(this.job_id, this.job_alloc_id).subscribe((dataList: any[]) => {
      this.attachmentType = dataList;
    });
    this.visitTypeList = [
      { id: '1', title: 'Site Inspection' },
      { id: '2', title: 'Make Safe' },
      { id: '3', title: 'Perform Works' }
    ]
    this.selectedVisitType = this.visitTypeList[0];
    this.selectedAttachmentType = [];

    this.switchToComm();
    this.scheduleJob.message_received = 2;
    this.minDate = new Date();
    this.maxDate = new Date(this.minDate.getTime() + ((1000 * 60 * 60 * 24) * 90));
    this.loading = false;
  }

  onChangeRecipient(selectedRecipient) {
    this.Recipient.forEach(item => {
      if (item.id === this.selectedRecipient.id) {
        item.selected = 'true';
      } else {
        item.selected = 'false';
      }
    });
    this.crudService.getByValuesetName(selectedRecipient.valueset_detail_desc).subscribe((details: any) => {
      this.messageCategory = details;
      this.selectedmessageCategory = this.messageCategory[0];
      this.onChangeMessageCategory(this.selectedmessageCategory);
      if (selectedRecipient.valueset_detail_name == 'TP') {
        this.isPMOnly = false;
        this.scheduleJob.PMOnly = '1';
      } else {
        this.isPMOnly = true;
        this.scheduleJob.PMOnly = '2';
      }
    });
  }
  onChangeMessageCategory(selectedMessage) {
    if (typeof this.scheduleJob.sched_note !== 'undefined') {
      if (this.notesChanged) {
        this.confirmationService.confirm({
          message: 'Do you want to override the Notes?',
          header: 'Confirm',
          icon: 'pi pi-exclamation-triangle',
          accept: () => {
            this.loadMessage();
            this.confirmationService.close();
          },
          reject: () => {
            this.confirmationService.close();
          }
        });
      } else {
        this.loadMessage();
      }
    } else {
      this.loadMessage();
    }
  }

  loadMessage() {
    if (this.job_alloc_id !== '1') {
      if (this.selectedRecipient.id != null) {
        this.scheduleJob.sched_note = '';
        let valueset = this.messageCategory.find(valueset => (this.selectedmessageCategory.id == valueset.id));
        if (valueset.valueset_detail_desc != null) {
          this.scheduleJob.sched_note = valueset.valueset_detail_desc;
        }

        if (this.selectedRecipient.id == 10135) {
          this.scheduleJob.sched_note = 'Hi ' + this.jobAllocationResults?.info?.SiteContactName?.trim() + ', this message is from Enviro Trees Team regarding tree works in your property. ' + this.scheduleJob.sched_note;
        } else if (this.selectedRecipient.id == 10136) {
          this.scheduleJob.sched_note = 'Hi ' + this.jobAllocationResults?.TPContactShortName?.trim() + ', this message is regarding  ' + this.jobAllocationResults.JobNo + ' @ ' + this.jobAllocationResults.info.AddressForComms + '. ' + this.scheduleJob.sched_note;
        } else if (this.selectedRecipient.id == 10137) {

          let claimNo = this.jobAllocationResults.info.job_claim_no;
          if(claimNo == '' || claimNo == 'null' || claimNo == null || claimNo == undefined)
          {
            claimNo = "N/A";
          }

          this.scheduleJob.sched_note = 'Hi ' + this.jobAllocationResults?.info?.ClientName?.trim() + ',  this message is from Enviro Trees Team regarding tree works @'
            + this.jobAllocationResults.info.AddressForComms + '. Claim Number for your reference is '
            + claimNo + '. ' + this.scheduleJob.sched_note +
            ' Our tracking number for reference ' + this.jobAllocationResults.JobNo;
        } else if (this.selectedRecipient.id == 10138) {
          this.scheduleJob.sched_note = 'Hi ' + 'Team, ' + this.scheduleJob.sched_note;
        }
      }
    }
    /* else {
      this.scheduleJob.sched_note = this.selectedmessageCategory.valueset_detail_desc;
    } */
  }

  saveRecord() {
    this.submitted = true;
    this.scheduleJob.visit_type = null;
    this.scheduleJob.phoned = '1';
    if (this.scheduleJob.phone_no !== null && this.scheduleJob.phone_no !== '') {
      this.scheduleJob.phoned = '2';
    }
    this.scheduleJob.email = '1';
    if (typeof this.selectdEmailId !== 'undefined') {

      if (this.selectdEmailId !== null && this.selectdEmailId.length > 0) {
        let emailid = '';
        this.selectdEmailId.forEach(mailid => {
          if (emailid !== '') {
            emailid = emailid + ', ' + mailid.value;
          } else {
            emailid = mailid.value;
          }
        });
        this.scheduleJob.emailaddress = emailid;
        this.scheduleJob.email = '2';
      }
    }
    this.scheduleJob.sms = '1';
    if (this.scheduleJob.sms_no !== null && this.scheduleJob.sms_no !== '') {
      this.scheduleJob.sms = '2';
    }
    if (this.isCallBack) {
      this.scheduleJob.callback = '2';
    } else {
      this.scheduleJob.callback = '1';
    }
    if (this.isPMOnly) {
      this.scheduleJob.PMOnly = '2';
    } else {
      this.scheduleJob.PMOnly = '1';
    }
    this.scheduleJob.status = '';
    if (typeof this.selectedVisitType !== 'undefined') {
      if (this.selectedVisitType != null && this.selectedVisitType != '') {
        this.scheduleJob.visit_type = this.selectedVisitType.id;
        this.scheduleJob.status = '1';
      }
    }

    this.scheduleJob.source = '1';
    /* if (this.scheduleJob.end_time!=null && this.scheduleJob.end_time!=='') {
      this.scheduleJob.end_time = this.scheduleJob.end_time).toTimeString();
    }
    if (this.scheduleJob.start_time!=null && this.scheduleJob.start_time!=='') {
      this.scheduleJob.end_time = new Date(this.scheduleJob.end_time).toTimeString();
      this.scheduleJob.start_time = this.scheduleJob.start_time.toString().split(' ')[4];
    } */
    if (typeof this.selectedRecipient !== 'undefined' && this.selectedRecipient != null) {
      this.scheduleJob.comm_recipient = this.selectedRecipient.id;
    }
    if (typeof this.selectedmessageCategory !== 'undefined' && this.selectedmessageCategory != null) {
      this.scheduleJob.comm_recipient_subcatg = this.selectedmessageCategory.id;
    }
    this.crudService.Create(this.scheduleJob, 'JobSchedule').subscribe((data: any) => {
      if (data.success === 1) {
        this.scheduleJob.id = data.id;
        if (typeof this.selectedVisitType !== 'undefined') {
          if (this.selectedVisitType !== null && this.selectedVisitType !== '') {
            if (this.job_alloc_id !== '1') {
              this.sendScheduleNotification(this.scheduleJob);
              this.messageService.add({ severity: 'success', summary: 'Success', detail: 'Schedule Saved. Notification Sent.', life: 1000 });
            } else {
              this.messageService.add({ severity: 'success', summary: 'Success', detail: 'Schedule Saved.', life: 1000 });
            }
          } else {
            if (this.job_alloc_id !== '1') {
              this.sendCommsNotification(this.scheduleJob);
              this.messageService.add({ severity: 'success', summary: 'Success', detail: 'Communication Saved. Notification Sent.', life: 1000 });
            } else {
              this.messageService.add({ severity: 'success', summary: 'Success', detail: 'Communication Saved.', life: 1000 });
            }
          }
        }
        this.dialogRef.close();
      } else {
        this.messageService.add({ severity: 'error', summary: 'Error', detail: 'Schedule/Communication not saved', life: 1000 });
      }
    })
  }

  sendCommsNotification(ScheduleFormData) {
    this.scheduleJob.message_flow = '2';
    this.scheduleJob.message_received = '2';
    this.scheduleJob.message_comm_type = '2';
    let emailSubject = "TM Job Number - " + this.jobAllocationResults.JobNo + ", Site Address - " + this.jobAllocationResults.info.location;
    let sch_notes = encodeURIComponent(this.scheduleJob.sched_note);
    //console.log('encode', sch_notes);
    let mailSubject = '';
    let EmailAccount = 'AU';
    if (this.jobAllocationResults.info.AccountType == 'AU') {
      EmailAccount = 'AU';
    } else if (this.jobAllocationResults.info.AccountType == 'NZ') {
      EmailAccount = 'NZ';
    }
    let documentList = [];
    this.selectedAttachmentType.forEach(file => {
      let docObj = { fileName: file.file_name, filePath: this.crudService.getAPIRoot() + file.file_path }
      documentList.push(docObj);
    });
    let data: any = {
      email: {
        from: '',
        to: this.scheduleJob.emailaddress,
        cc: '',
        bcc: '',
        subject: emailSubject,
        message: sch_notes,
        msg: sch_notes,
        ClientId: this.jobAllocationResults.WPCompanyId

      },
      sms: {
        to: this.scheduleJob.sms_no,
        message: sch_notes
      }
    }
    let temp_type = "email";
    if (this.scheduleJob.sms == '2' && this.scheduleJob.email == '2') {
      temp_type = "sms-email";
    } else if (this.scheduleJob.sms == '2') {
      temp_type = "sms";
    } else if (this.scheduleJob.email == '2') {
      temp_type = "email";
    }
    let atts = { jobId: this.jobAllocationResults.id, allocId: this.jobAllocationResults.job_alloc_id, documentList: documentList };
    let dataObj = {
      sms: data.sms,
      email: data.email,
      type: temp_type,
      selectedTpl: null,
      sufix: null,
      jobData: null,
      attachments: atts,
      account: EmailAccount,
      ClientId: this.jobAllocationResults.WPCompanyId
    }
    JSON.stringify(dataObj);
    //console.log(dataObj);
    this.crudService.sendTMV2Message(dataObj).subscribe((res2: any[]) => {
      //console.log(res2);
    });
    this.JobService.updateNotifications(this.userDetails.id, emailSubject, sch_notes, this.jobAllocationResults.job_alloc_id);
  }

  sendScheduleNotification(ScheduleFormData) {
    let claimNo = '';
    if (typeof this.jobAllocationResults.info.job_claim_no !== 'undefined') {
      claimNo = this.jobAllocationResults.info.job_claim_no;
    }
    let mailSubject = '';
    let visitDesc = this.selectedVisitType.title;
    let EmailAccount = null;
    if (this.jobAllocationResults.info.AccountType == 'AU') {
      EmailAccount = 'AU';
    } else if (this.jobAllocationResults.info.AccountType == 'NZ') {
      EmailAccount = 'NZ';
    }
    let documentList = [];
    this.selectedAttachmentType.forEach(file => {
      let docObj = { filename: file.file_name, url: this.crudService.getAPIRoot() + file.file_path }
      documentList.push(docObj);
    });
    //let atts = { jobId: this.jobAllocationResults.id, allocId: this.jobAllocationResults.job_alloc_id, documentList: documentList };

    if (this.jobAllocationResults.info.ClientCommType = 'Email Client') {
      if (this.jobAllocationResults.info.Email != 'NA') {
        let params = {
          email: {
            to: this.scheduleJob.emailaddress ? this.scheduleJob.emailaddress : this.jobAllocationResults.info.Email,
            cc: 'documents@envirofrontier.com.au',
            bcc: null,
            msg: 'This is a mail to notify that ' + visitDesc + ' has been scheduled on (' +
              new Date(ScheduleFormData.sched_date).toDateString() + ') between ' +
              ScheduleFormData.start_time + ' and ' +
              ScheduleFormData.end_time +
              ' for Job No : ' + this.jobAllocationResults.JobNo + '  The details are as below . \n  ' +
              'Risk Address : ' + this.jobAllocationResults.info.location + '. \n',
            subject: ' Claim Number : ' + claimNo +
              ' at ' + this.jobAllocationResults.ShortAddr + ' - ' +
              visitDesc + ' scheduled on (' + new Date(ScheduleFormData.sched_date).toDateString() + ') between ' +
              ScheduleFormData.start_time + ' and ' +
              ScheduleFormData.end_time + ', TM Job Number - ' +
              this.jobAllocationResults.JobNo + ', Site Address - ' + this.jobAllocationResults.info.location,
          },
          sms: {
            to: '',
            message: ''
          },
          type: 'email',
          selectedTpl: null,
          sufix: null,
          jobData: this.jobAllocationResults.JobNo,
          account: EmailAccount,
          attachments: documentList
        }
        if(environment.hosting == 'UK'){
          params.email.subject = claimNo +
          ' at ' + this.jobAllocationResults.ShortAddr + ' - ' +
          visitDesc + ' scheduled on (' + new Date(ScheduleFormData.sched_date).toDateString() + ') between ' +
          ScheduleFormData.start_time + ' and ' +
          ScheduleFormData.end_time + ', TM Job Number - ' +
          this.jobAllocationResults.JobNo + ', Site Address - ' + this.jobAllocationResults.info.location
        }
        this.JobService.updateNotifications(this.userDetails.id, params.email.subject, params.email.msg, this.jobAllocationResults.job_alloc_id);
        this.sendMail(params, 'JobSchedule', this.scheduleJob.id);
        /* this.crudService.sendTMV2Message(params).subscribe((res2: any[]) => {
          //console.log(res2);
        }); */
      } else {
        let params = {
          email: {
            to: 'documents@envirofrontier.com.au', cc: '', bcc: null,
            msg: 'Missing Client Email Details -- This is a mail to notify that ' + visitDesc + ' has been scheduled on '
              + (new Date(ScheduleFormData.sched_date).toDateString()) + ' between ' + ScheduleFormData.start_time + ' and ' +
              ScheduleFormData.end_time + ' for Job No : ' + this.jobAllocationResults.JobNo + '  The details are as below . \n  ' +
              'Risk Address : ' + this.jobAllocationResults.info.location + '. \n',
            subject: 'Missing Client Email Details --   ' + visitDesc + ' scheduled on ' + (new Date(ScheduleFormData.sched_date).toDateString()) +
              ' between ' + ScheduleFormData.start_time + ' and ' +
              ScheduleFormData.end_time + ', TM Job Number - '
              + this.jobAllocationResults.JobNo + ', Site Address - ' + this.jobAllocationResults.info.location,
          },
          sms: {
            to: '',
            message: ''
          },
          type: 'email', selectedTpl: null, sufix: null, jobData: this.jobAllocationResults.JobNo,
          account: EmailAccount, attachments: documentList
        }
        this.JobService.updateNotifications(this.userDetails.id, params.email.subject, params.email.msg, this.jobAllocationResults.job_alloc_id);
        this.sendMail(params, 'JobSchedule', this.scheduleJob.id);
        /* this.crudService.sendTMV2Message(params).subscribe((res2: any[]) => {
          //console.log(res2);
        }); */
      }

    } else if (this.jobAllocationResults.info.ClientCommType = 'Portal Client') {
      let params = {
        email: {
          to: 'documents@envirofrontier.com.au', cc: '', bcc: null,
          msg: ' To load in portal -- This is a mail to notify that ' + visitDesc + ' has been scheduled on ' + (new Date(ScheduleFormData.sched_date).toDateString()) + ' between ' + ScheduleFormData.start_time + ' and ' + ScheduleFormData.end_time + ' for Job No : ' + this.jobAllocationResults.JobNo + '  The details are as below . \n  ' +
            'Risk Address : ' + this.jobAllocationResults.info.location + '. \n',
          subject: 'To load in portal --  ' + visitDesc + ' scheduled on ' + (new Date(ScheduleFormData.sched_date).toDateString()) + ' between ' +
            ScheduleFormData.start_time + ' and ' +
            ScheduleFormData.end_time + ', TM Job Number - ' +
            this.jobAllocationResults.JobNo + ', Site Address - ' + this.jobAllocationResults.info.location,
        }, sms: {
          to: '',
          message: ''
        }, type: 'email', selectedTpl: null, sufix: null, jobData: this.jobAllocationResults.JobNo,
        account: EmailAccount, attachments: documentList
      }
      this.JobService.updateNotifications(this.userDetails.id, params.email.subject, params.email.msg, this.jobAllocationResults.job_alloc_id);
      this.sendMail(params, 'JobSchedule', this.scheduleJob.id);
      /* this.crudService.sendTMV2Message(params).subscribe((res2: any[]) => {
        //console.log(res2);
      }); */
    }
  }

  sendScheduleNotificationOld(ScheduleFormData) {
    let claimNo = '';
    if (typeof this.jobAllocationResults.info.job_claim_no !== 'undefined') {
      claimNo = this.jobAllocationResults.info.job_claim_no;
    }
    let mailSubject = '';
    let visitDesc = this.selectedVisitType.title;
    let EmailAccount = null;
    if (this.jobAllocationResults.info.AccountType == 'AU') {
      EmailAccount = 'AU';
    } else if (this.jobAllocationResults.info.AccountType == 'NZ') {
      EmailAccount = 'NZ';
    }
    let documentList = [];
    this.selectedAttachmentType.forEach(file => {
      let docObj = { fileName: file.file_name, filePath: this.crudService.getAPIRoot() + file.file_path }
      documentList.push(docObj);
    });
    let atts = { jobId: this.jobAllocationResults.id, allocId: this.jobAllocationResults.job_alloc_id, documentList: documentList };

    if (this.jobAllocationResults.info.ClientCommType = 'Email Client') {
      if (this.jobAllocationResults.info.Email != 'NA') {
        let params = {
          email: {
            to: this.scheduleJob.emailaddress ? this.scheduleJob.emailaddress : this.jobAllocationResults.info.Email,
            cc: 'documents@envirofrontier.com.au',
            bcc: null,
            msg: 'This is a mail to notify that ' + visitDesc + ' has been scheduled on (' +
              new Date(ScheduleFormData.sched_date).toDateString() + ') between ' +
              ScheduleFormData.start_time + ' and ' +
              ScheduleFormData.end_time +
              ' for Job No : ' + this.jobAllocationResults.JobNo + '  The details are as below . \n  ' +
              'Risk Address : ' + this.jobAllocationResults.info.location + '. \n',
            subject: ' Claim Number : ' + claimNo +
              ' at ' + this.jobAllocationResults.ShortAddr + ' - ' +
              visitDesc + ' scheduled on (' + new Date(ScheduleFormData.sched_date).toDateString() + ') between ' +
              ScheduleFormData.start_time + ' and ' +
              ScheduleFormData.end_time + ', TM Job Number - ' +
              this.jobAllocationResults.JobNo + ', Site Address - ' + this.jobAllocationResults.info.location,
          },
          sms: {
            to: '',
            message: ''
          },
          type: 'email',
          selectedTpl: null,
          sufix: null,
          jobData: this.jobAllocationResults.JobNo,
          account: EmailAccount,
          attachments: documentList
        }
if(environment.hosting == 'UK'){
  params.email.subject = claimNo +
  ' at ' + this.jobAllocationResults.ShortAddr + ' - ' +
  visitDesc + ' scheduled on (' + new Date(ScheduleFormData.sched_date).toDateString() + ') between ' +
  ScheduleFormData.start_time + ' and ' +
  ScheduleFormData.end_time + ', TM Job Number - ' +
  this.jobAllocationResults.JobNo + ', Site Address - ' + this.jobAllocationResults.info.location
}

        this.JobService.updateNotifications(this.userDetails.id, params.email.subject, params.email.msg, this.jobAllocationResults.job_alloc_id);
        this.crudService.sendTMV2Message(params).subscribe((res2: any[]) => {
          //console.log(res2);
        });
      } else {
        let params = {
          email: {
            to: 'documents@envirofrontier.com.au', cc: '', bcc: null,
            msg: 'Missing Client Email Details -- This is a mail to notify that ' + visitDesc + ' has been scheduled on '
              + (new Date(ScheduleFormData.sched_date).toDateString()) + ' between ' + ScheduleFormData.start_time + ' and ' +
              ScheduleFormData.end_time + ' for Job No : ' + this.jobAllocationResults.JobNo + '  The details are as below . \n  ' +
              'Risk Address : ' + this.jobAllocationResults.info.location + '. \n',
            subject: 'Missing Client Email Details --   ' + visitDesc + ' scheduled on ' + (new Date(ScheduleFormData.sched_date).toDateString()) +
              ' between ' + ScheduleFormData.start_time + ' and ' +
              ScheduleFormData.end_time + ', TM Job Number - '
              + this.jobAllocationResults.JobNo + ', Site Address - ' + this.jobAllocationResults.info.location,
          },
          sms: {
            to: '',
            message: ''
          },
          type: 'email', selectedTpl: null, sufix: null, jobData: null,
          account: EmailAccount, attachments: atts
        }
        this.JobService.updateNotifications(this.userDetails.id, params.email.subject, params.email.msg, this.jobAllocationResults.job_alloc_id);
        this.crudService.sendTMV2Message(params).subscribe((res2: any[]) => {
          //console.log(res2);
        });
      }

    } else if (this.jobAllocationResults.info.ClientCommType = 'Portal Client') {
      let params = {
        email: {
          to: 'documents@envirofrontier.com.au', cc: '', bcc: null,
          msg: ' To load in portal -- This is a mail to notify that ' + visitDesc + ' has been scheduled on ' + (new Date(ScheduleFormData.sched_date).toDateString()) + ' between ' + ScheduleFormData.start_time + ' and ' + ScheduleFormData.end_time + ' for Job No : ' + this.jobAllocationResults.JobNo + '  The details are as below . \n  ' +
            'Risk Address : ' + this.jobAllocationResults.info.location + '. \n',
          subject: 'To load in portal --  ' + visitDesc + ' scheduled on ' + (new Date(ScheduleFormData.sched_date).toDateString()) + ' between ' +
            ScheduleFormData.start_time + ' and ' +
            ScheduleFormData.end_time + ', TM Job Number - ' +
            this.jobAllocationResults.JobNo + ', Site Address - ' + this.jobAllocationResults.info.location,
        }, sms: {
          to: '',
          message: ''
        }, type: 'email', selectedTpl: null, sufix: null, jobData: null,
        account: EmailAccount, attachments: atts
      }
      this.JobService.updateNotifications(this.userDetails.id, params.email.subject, params.email.msg, this.jobAllocationResults.job_alloc_id);
      this.crudService.sendTMV2Message(params).subscribe((res2: any[]) => {
        //console.log(res2);
      });
    }
  }
  async switchToComm() {
    this.toggleCommSched = false;
    this.selectedVisitType = this.visitTypeList[0];
    let details: any = await this.crudService.getByValuesetName('CommsCatgType').toPromise();
    this.Recipient = details;
    this.toggleCommSched = true;
    this.selectedmessageCategory.id = null;
    this.selectedRecipient.id = null;//this.onChangeRecipient(this.selectedRecipient);
  }

  search(event) {
    this.filteredTimeSlots = this.timeSlots.filter((val: any) => val.includes(event.query));
  }


  resetInput() {
    if (this.job_alloc_id !== '1') {

      let SMSAddressforComms = null;
      this.selectdEmailId = [];
      if (this.selectedRecipient.id == '10135') {
        if (this.jobAllocationResults.info.SiteContactMobile !== 'undefined') {
          SMSAddressforComms = this.jobAllocationResults.info.SiteContactMobile;
        }
      } else if (this.selectedRecipient.id === '10136') {
        SMSAddressforComms = this.jobAllocationResults.info.TPMobile;
      } else if (this.selectedRecipient.id === '10137') {
        SMSAddressforComms = null;
      } else if (this.selectedRecipient.id === '10138') {

      }
      this.scheduleJob.sms_no = SMSAddressforComms;

      let emailAddressforComms = null;
      if (this.selectedRecipient.id === '10135') {
        emailAddressforComms = this.jobAllocationResults.info.SiteContactEmail;
      } else if (this.selectedRecipient.id === '10136') {
        emailAddressforComms = this.jobAllocationResults.info.TPEmail;
      } else if (this.selectedRecipient.id === '10137') {
        if (this.jobAllocationResults.info.Email !== 'NA') {
          emailAddressforComms = this.jobAllocationResults.info.Email;
        }
      } else if (this.selectedRecipient.id === '10138') {

      }
      if (emailAddressforComms !== null && emailAddressforComms !== '') {
        this.selectdEmailId.push({ value: emailAddressforComms });
      }
    }

  }

  removeToMailId(value) {
    this.selectdEmailId = this.selectdEmailId.filter(val => val.value !== value.value);
  }

  searchMail(event) {
    this.showError = true
    this.mailIdOptions = [];
    let value = event.query.toLowerCase();
    let option = this.emailsList.filter(val => val.toLowerCase().includes(value));
    if (option.length > 0) {
      this.mailIdOptions = option;
    } else {
      if ((value.includes(',') || value.includes(';') || value.includes('.co')) && (value.includes('.co'))) {
        let mailId = event.query.replaceAll(',', '').replaceAll(';', '').toLowerCase();
        this.mailIdOptions.push(mailId);
      }
    }
    this.emailId = '';
    this.emailId = event.query.toLowerCase();
    if (this.emailValidation(event.query.toLowerCase())) {
      this.showError = false
    }
    // this.dummy=[];
  }
  emailValidation(val: any) {
    var regex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/; // Uppercase character pattern

    if (regex.test(val)) return true;
    else return false;
  }
  addMailId(mailFieldValue, arrayObject) {
    this.showError = true
    if (typeof mailFieldValue !== 'undefined' && mailFieldValue !== null && mailFieldValue !== '') {
      if (this.emailsList.includes(mailFieldValue)) {
        if (arrayObject.length > 0) {
          let index =
            arrayObject.findIndex((res: any) => res.value == mailFieldValue);
          if (index < 0) {
            this.showError = false
            arrayObject.push({ value: mailFieldValue });
          }
        }
        else
          this.showError = false
        arrayObject.push({ value: mailFieldValue });
      }
      else {
        if (this.emailValidation(mailFieldValue)) {
          let index =
            arrayObject.findIndex((res: any) => res.value == mailFieldValue);
          if (index < 0) {
            this.showError = false
            arrayObject.push({ value: mailFieldValue });

          }
          mailFieldValue = null;
        }
      }
    }
    mailFieldValue = null;
  }

  selectId(value, arrayObject) {
    this.addMailId(value, arrayObject);
  }

  loadJobSummary() {
    //console.log('Job Notes', this.jobSummary);
    this.Date_str = new Date(this.jobSummary.Date).toLocaleDateString();
    this.title = this.jobSummary.JobNo + ' - (' + this.jobSummary.JobStatus + ')';
    this.crudService.getDataByField('jobinfo', 'id', this.job_id).subscribe((data: any[]) => {
      this.jobDetails = data;
      this.jobInfo = data[0];
      this.date = this.jobDetails[0].job_recd_dt.split('T')[0];
      this.crudService.getCostingInfo(this.job_id, this.job_alloc_id).subscribe((costingInfo: any[]) => {
        if (costingInfo.length > 0) {
          if (this.jobSummary.wp_grand_total !== null && this.jobSummary.wp_grand_total !== '') {
            this.jobSummary.tp_grand_total = parseFloat(costingInfo[0].tp_grand_total).toFixed(2);
            this.jobSummary.wp_grand_total = parseFloat(costingInfo[0].wp_grand_total).toFixed(2);
            let variance = this.jobSummary.tp_grand_total / this.jobSummary.wp_grand_total;
            variance = 1 - variance;
            variance = variance * 100;
            this.jobSummary.margin = parseFloat(variance.toString()).toFixed(2);
          }
        }
      });
    })
  }

  sendMail(params, ref_type, ref_id) {
    this.commonservice.sendNotificationMail(params.email.to,
      params.email.subject,
      'Hi,',
      '',
      params.email.msg,
      null,
      null,
      environment.base_url,
       'Login',
      params.attachments,
      params.email.bcc,
      params.email.cc,
      ref_type, 
      ref_id);
  }
}