import { CUSTOM_ELEMENTS_SCHEMA,NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { DropdownModule } from 'primeng/dropdown';
import { TableModule } from 'primeng/table';
import { ButtonModule } from 'primeng/button';
import { InputMaskModule } from 'primeng/inputmask';
import { InputNumberModule } from 'primeng/inputnumber';
import { InputSwitchModule } from 'primeng/inputswitch';
import { InputTextModule } from 'primeng/inputtext';
import { InputTextareaModule } from 'primeng/inputtextarea';
import { ConfirmDialogModule } from 'primeng/confirmdialog';
import { DialogModule } from 'primeng/dialog';
import { FileUploadModule } from 'ng2-file-upload';
import { DynamicDialogRef } from 'primeng/dynamicdialog';
import { DynamicDialogConfig } from 'primeng/dynamicdialog';
import { TooltipModule } from 'primeng/tooltip';
import { GooglePlaceModule } from "ngx-google-places-autocomplete";
import { CalendarModule } from 'primeng/calendar';
import { FullCalendarModule } from 'primeng/fullcalendar';
import { TabMenuModule } from 'primeng/tabmenu';
import { TabViewModule } from 'primeng/tabview';
import { Ng2ImgMaxModule } from 'ng2-img-max';
import { MultiSelectModule } from 'primeng/multiselect';
import { CheckboxModule } from 'primeng/checkbox';
import { CardModule } from 'primeng/card';
import { ToastModule } from 'primeng/toast';
import { EditorModule } from 'primeng/editor';
import { AutoCompleteModule } from 'primeng/autocomplete';
import { ProgressBarModule } from 'primeng/progressbar';
import { RadioButtonModule } from 'primeng/radiobutton';
import { SidebarModule } from 'primeng/sidebar';
import { SlideMenuModule } from 'primeng/slidemenu';

import { TpJobRoutingModule } from './tp-job-routing.module';
import { TpJobAdminComponent } from './tp-job-admin/tp-job-admin.component';
import { TpJobCostingComponent } from './tp-job-costing/tp-job-costing.component';
import { TpJobDetailHeader } from './tp-job-detail-header/tp-job-detail-header.component'
import { TpJobDetailsComponent } from './tp-job-details/tp-job-details.component';
import { TpJobDocumentsComponent } from './tp-job-documents/tp-job-documents.component'
import { TpJobImagesComponent } from './tp-job-images/tp-job-images.component';
import { TpJobInvoiceSendComponent } from './tp-job-invoice-send/tp-job-invoice-send.component';
import { TpJobOperationsComponent } from './tp-job-operations/tp-job-operations.component';
import { TpJobListComponent } from './tp-job-list/tp-job-list.component';
import { TpJobRiskcontactComponent } from './tp-job-riskcontact/tp-job-riskcontact.component';
import { TpJobScheduleComponent } from './tp-job-schedule/tp-job-schedule.component';
import { TpShfComponent } from './tp-shf/shf.component';
import { TpScheduleAdminComponent } from './tp-schedule-admin/tp-schedule-admin.component';
import { ChannelModule } from '../channel/channel.module';
import { SharedModule } from '../shared/shared.module';

@NgModule({
  declarations: [TpJobAdminComponent, TpScheduleAdminComponent, 
    TpJobCostingComponent,TpJobDetailHeader,TpJobDetailsComponent,TpJobDocumentsComponent,TpJobImagesComponent,
    TpJobInvoiceSendComponent,TpJobOperationsComponent,TpJobListComponent,TpJobRiskcontactComponent, TpJobScheduleComponent, TpShfComponent
  ],
  imports: [
    CommonModule, ChannelModule,SharedModule,
    TpJobRoutingModule,FormsModule, ReactiveFormsModule,ConfirmDialogModule,DialogModule,TableModule,InputMaskModule,InputNumberModule,
    InputSwitchModule,InputTextModule,InputTextareaModule, ButtonModule,FileUploadModule,DropdownModule,TooltipModule,GooglePlaceModule,
    CalendarModule,EditorModule,AutoCompleteModule, FullCalendarModule,TabMenuModule,TabViewModule,Ng2ImgMaxModule,MultiSelectModule,
    CheckboxModule,CardModule,ToastModule,ProgressBarModule,RadioButtonModule, SidebarModule, SlideMenuModule
  ],
  exports: [
    TpJobOperationsComponent, TpJobListComponent
  ],
  providers: [
    DynamicDialogRef, DynamicDialogConfig
],
  schemas: [
    CUSTOM_ELEMENTS_SCHEMA
],
})
export class TpJobModule { }