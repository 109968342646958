import { ChangeDetectorRef, Component, EventEmitter, Inject, OnInit, Output } from '@angular/core';
import { ImageCroppedEvent, ImageTransform } from 'ngx-image-cropper';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';

@Component({
  selector: 'app-crop-image',
  templateUrl: './crop-image.component.html',
  styleUrls: ['./crop-image.component.css']
})
export class CropImageComponent implements OnInit {
  imgChangeEvt: any = '';
  cropImgPreview: any = '';
  profileImageFile: any;
  imageSrc: any;
  scale = 1;
  transform: ImageTransform = {};
  mySliderValue: any;
  constructor(private dialogRef1: DynamicDialogRef, public config: DynamicDialogConfig, private cdr: ChangeDetectorRef) { }

  ngOnInit(): void {
    console.log(this.config.data)
    this.imgChangeEvt = this.config.data.fileData;
    // this.imgChangeEvt = this.fileData.fileData
    // this.cropImg(this.imgChangeEvt)
  }
  closeModal() {
    this.dialogRef1.close();
    // this.redirect(this.userInfo.user_type);
  }
  done(){
    this.dialogRef1.close(this.profileImageFile);
  }
  cropImg(e: ImageCroppedEvent) {
    console.log(e)
    const imageName = 'name.png';
    const imageBlob = this.dataURItoBlob(e.base64, imageName);
    this.profileImageFile = imageBlob;
    this.imageSrc = e.base64
  }

  dataURItoBlob(dataURI, imageName) {
    var byteString;
    if (dataURI?.split(',')[0].indexOf('base64') >= 0)
      byteString = atob(dataURI.split(',')[1]);
    else
      byteString = unescape(dataURI?.split(',')[1]);

    // separate out the mime component
    var mimeString = dataURI?.split(',')[0].split(':')[1].split(';')[0];

    // write the bytes of the string to a typed array
    var ia = new Uint8Array(byteString.length);
    for (var i = 0; i < byteString.length; i++) {
      ia[i] = byteString.charCodeAt(i);
    }

    return new File([ia], imageName, { type: mimeString });
  }
  imgLoad() {
    // display cropper tool
  }
  initCropper() {
    // init cropper
  }

  imgFailed() {
    // error msg
  }
  zoomOut() {
    this.scale -= .1;
    this.transform = {
      ...this.transform,
      scale: this.scale
    };
    this.cdr.detectChanges()
  }

  zoomIn() {
    this.scale += .1;
    console.log(this.scale)
    this.transform = {
      ...this.transform,
      scale: this.scale
    };
    this.cdr.detectChanges()
  }
  sliderOnChange(value: number) {
    if (this.mySliderValue !== value) {
      this.mySliderValue = value;
      console.log('changed: ', value);
    }
  }
  updateSetting(event: any) {
    this.mySliderValue = event.value
    this.scale = event.value;
    this.transform = {
      ...this.transform,
      scale: this.scale
    };
    this.cdr.detectChanges()
    console.log(this.mySliderValue);
  }
}
