import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { CRUDService } from 'src/app/utilities/crud.service';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { ConfirmationService, MessageService } from 'primeng/api';
import { DialogService, DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { AppMainComponent } from '../../app.main.component';
import { EncryptionService } from 'src/app/utilities/encryption.service';

@Component({
  selector: 'app-change-password',
  templateUrl: './change-password.component.html',
  styleUrls: ['./change-password.component.scss'],
  providers: [DialogService, MessageService, ConfirmationService]
})
export class ChangePasswordComponent implements OnInit {

  @Input() user: any;
  @Output() changePwdEvent = new EventEmitter<string>();
  companyId: any;
  userDetails: any;
  success: any;
  error: any;
  doNotMatch: any;
  mode: any = 'SELFUSER';
  show: Boolean = false;
  currentpassword: any;
  WrongCurrentPassword: any;
  strengthError: any;
  password: any;
  confirmPassword: any;
  userID: any;
  colors = ['#F00', '#F90', '#FF0', '#9F0', '#0F0'];
  newPwd: any;
  hideUserDetails: boolean = false;
  encryptedPass: any;
  passwordStrength: any;

  haslowercase: boolean;
  hasuppercase: boolean;
  hasspecialchar: boolean;
  hasnumbers: boolean;
  passwordMatch: boolean = false;
  passKeyTextType: boolean;
  passKeyTextType1: boolean;
  passKeyTextType2: boolean;

  buttonDisabled: boolean=true;
  buttonDisabled2: boolean=true;
  buttonDisabled3: boolean=true;
  buttonDisabled4: boolean=true;
  buttonDisabled5: boolean=true;
  buttonDisabled6: boolean=true;
  userDetail : any;

  constructor(
    public app: AppMainComponent,
    private route: ActivatedRoute,
    private crudService: CRUDService,
    private messageService: MessageService,
    private confirmationService: ConfirmationService,
    private router: Router,
    public ref: DynamicDialogRef,
    public config: DynamicDialogConfig,
    public dialogService: DialogService,
    private encryptService: EncryptionService) {
    this.companyId = sessionStorage.getItem('company_id');
    this.userDetail = JSON.parse(sessionStorage.getItem('loggedInUser'));
    this.mode = 'SELFUSER';
  }

  async ngOnInit(): Promise<void> {
    this.show = true;
    const userDetailsData: any = await this.crudService.getDataByField('Users', 'id', this.userDetail.id).toPromise();
    this.userDetails = userDetailsData[0];
    if (this.userDetails.client_type == '1') {
      this.mode = 'ADMINALL';
    } else {
      this.mode = 'TPALL';
    }
    
  }


  passwordMatchValidator(pass_key, confirm_pass_key) {
  
    console.log('passwordMatch1', pass_key);
    if (pass_key === confirm_pass_key) {
      this.passwordMatch = false;
      this.buttonDisabled2= false;
    }
    else {
      this.passwordMatch = true;
      this.buttonDisabled2= true;
    }

   const re = /[0-9]/;
    if(re.test(pass_key)) {
      this.hasnumbers=true 
      this.buttonDisabled= false;    
    }
    else{
      this.hasnumbers=false
      this.buttonDisabled= true;
    }

    const re1 = /[a-z]/;
    if(re1.test(pass_key)) {
      this.haslowercase=true  
      this.buttonDisabled3= false; 
    }
    else{
      this.haslowercase=false;
      this.buttonDisabled3= true;

    }

    const re2 = /[A-Z]/;
    if(re2.test(pass_key)) {
      this.hasuppercase=true   
      this.buttonDisabled4= false; 
    }
    else{
      this.hasuppercase=false
      this.buttonDisabled4= true;
    }
   
    const re3 =  /[-+_!@#$%^&*.,?]/;
    if(re3.test(pass_key)) {
      this.hasspecialchar=true;
      this.buttonDisabled5= false;   
    }
    else{
      this.hasspecialchar=false;
      this.buttonDisabled5= true;
    }
  }

  toggle_pass_key() {
    this.passKeyTextType = !this.passKeyTextType;
  }

  toggle_pass_key1() {
    this.passKeyTextType1 = !this.passKeyTextType1;
  }

  toggle_pass_key2() {
    this.passKeyTextType2 = !this.passKeyTextType2;
  }

  saveUser() {
    if (this.app.horizontalMenu) {
      this.app.userSettings.menu_type = '1';
    } else {
      this.app.userSettings.menu_type = '2';
    }
    if (this.app.darkMode) {
      this.app.userSettings.colour_scheme = 'dark';
    } else {
      this.app.userSettings.colour_scheme = 'light';
    }
    this.app.userSettings.colour = this.app.themeColor;
    this.app.userSettings.menu_colour = this.app.menuColor;
    this.app.userSettings.layout_colour = this.app.layoutColor;
    this.app.userSettings.process_id = this.app.userSettings.company_id;
    if (typeof this.newPwd !== 'undefined') {
      if (this.newPwd !== null && this.newPwd !== '') {
        this.app.userSettings.password = this.newPwd;
      }
    }
    this.crudService.Update(this.app.userSettings, 'user').subscribe((data: any) => {
      if (data.success == 1) {
        this.crudService.getUserDetails(this.app.userSettings.username, this.app.userSettings.password).subscribe((userData: any) => {
          let user = userData.data[0];
          sessionStorage.setItem('loggedInUser', JSON.stringify(user));
          sessionStorage.setItem('company_id', user.company_id);
          this.messageService.add({ severity: 'success', summary: 'Successful', detail: 'User setting Updated', life: 1000 });
          this.hideUserDetails = false;
        })
      }
    })
  }

  changePassword() {
    this.encryptedPass = this.encryptService.encryptionAES(this.password);

    if(!this.passwordMatch && this.haslowercase && this.hasuppercase  && this.hasspecialchar && this.hasnumbers)
   {
    //let password = this.encryptService.decryptionAES(this.userDetails.encrypted_password);
    // if (this.currentpassword !== password) {
    //   this.messageService.add({ severity: 'error', summary: 'Wrong Current Password Provided', detail: 'Current Password given does not match with the System.', life: 1000 });
    // } else {
      if (this.password !== this.confirmPassword) {
        this.messageService.add({ severity: 'error', summary: 'Passwords Mismatch', detail: 'Passwords Does Not Match', life: 1000 });
      } else {
        var result = this.measureStrength(this.password);
        if (result < 40) {
          this.messageService.add({ severity: 'error', summary: 'Can Not Update password', detail: 'New Password is too weak to be secure.', life: 1000 });
        } else {
          this.strengthError = null;
          this.WrongCurrentPassword = null;
          this.doNotMatch = null;

          this.crudService.changePassword(this.password, this.userDetails.username).subscribe((response: any) => {
            if (response.success) {
              this.changePwdEvent.emit(this.confirmPassword);
              this.messageService.add({ severity: 'success', summary: 'Password Changed', detail: 'Password Changed.', life: 1000 });
              var params = {
                email: {
                  to: this.userDetails.email,
                  cc: null,
                  bcc: null,
                  msg: 'This is a mail to notify that your password has been reset in Tree Manager. If you did not make the change , please contact Enviro Admin immediately. \n',
                  subject: 'Tree Manager - Password has been Reset',
                  sms: null,
                  type: 'email',
                  selectedTpl: null,
                  sufix: null
                }
              }
              this.crudService.sendMessage(params).subscribe((res2: any[]) => {
             });
            } else {
              this.messageService.add({ severity: 'error', summary: 'Can Not Update password', detail: 'Cannot Update the password. Please check with Admin.', life: 1000 });
            }
          });


          this.crudService.changeEncryptPassword(this.encryptedPass, this.userDetails.username).subscribe((response: any) => {
            if (response.success) {
              this.changePwdEvent.emit(this.confirmPassword);
              this.messageService.add({ severity: 'success', summary: 'Password Changed', detail: 'Password Changed.', life: 1000 });
             
            } else {
              this.messageService.add({ severity: 'error', summary: 'Can Not Update password', detail: 'Cannot Update the password. Please check with Admin.', life: 1000 });
            
            }
          });
        }
      }
   // }
  }
  else
  this.messageService.add({ severity: 'error', summary: 'Can Not Update password', detail: 'Cannot Update the password. Please check with Entered Password', life: 1000 });

  }

  measureStrength(pass: string) {
    let score = 0;
    // award every unique letter until 5 repetitions  
    let letters = {};
    for (let i = 0; i < pass.length; i++) {
      letters[pass[i]] = (letters[pass[i]] || 0) + 1;
      score += 5.0 / letters[pass[i]];
    }
    // bonus points for mixing it up  
    let variations = {
      digits: /\d/.test(pass),
      lower: /[a-z]/.test(pass),
      upper: /[A-Z]/.test(pass),
      nonWords: /\W/.test(pass),
    };

    let variationCount = 0;
    for (let check in variations) {
      variationCount += (variations[check]) ? 1 : 0;
    }
    score += (variationCount - 1) * 10;
    return Math.trunc(score);
  }

  private getColor(score: number) {
    let idx = 0;
    if (score > 90) {
      idx = 4;
    } else if (score > 70) {
      idx = 3;
    } else if (score >= 40) {
      idx = 2;
    } else if (score >= 20) {
      idx = 1;
    }
    return {
      idx: idx + 1,
      col: this.colors[idx]
    };
  }

  private setBarColors(count, col) {
    for (let _n = 0; _n < count; _n++) {
      this['bar' + _n] = col;
    }
  }
}