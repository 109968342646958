import { Component, OnInit } from '@angular/core';
import { BreadcrumbService } from 'src/app/breadcrumb.service';
import { Router } from '@angular/router';
import { CRUDService } from 'src/app/utilities/crud.service';
import { ConfirmationService } from 'primeng/api';
import { MessageService } from 'primeng/api';
import { Calendar } from '@fullcalendar/core'; // include this line
import dayGridPlugin from '@fullcalendar/daygrid';
import timeGridPlugin from '@fullcalendar/timegrid';
import { CommonService } from 'src/app/utilities/common.service';
import interactionPlugin, { Draggable } from '@fullcalendar/interaction';
import listPlugin from '@fullcalendar/list';

@Component({
    selector: 'app-scheduler2',
    templateUrl: './scheduler2.component.html',
    styleUrls: ['./scheduler2.component.css'],
    providers: [MessageService, ConfirmationService]
})

export class Scheduler2Component implements OnInit {
    options: any; 
    staffsList: any[];
    orgStaff: any[];
    orgEquipments: any[];
    equipmentsList: any[];
    jobsList: any[];
    selectedStaffs: any[];
    selectedEquipments: any[];
    selectedJob: any;
    companyId: any;
    scheduleHeadList: any[];
    event: any;
    events: any[];
    displayEventDialog: boolean;
    rangeDates: Date[];
    filteredJobs: any[];
    filteredStaffs: any[];
    filteredEquipments: any[];
    appProps: any;

    schedule = {
        id: null,
        created_by: null,
        job_id: null,
        job_alloc_id: null,
        schedule_start_time: null,
        schedule_end_time: null,
        event_type: null,
        notes: null,
        priority: null,
        updated_by: null,
        status: null,
        allday: null,
        schedule_start_date: null,
        schedule_end_date: null,
        created_at: null,
        updated_at: null,
        staff: null,
        equipment: null,
        title: null,
        background: null,
        start: null,
        end: null,
    }

    ScheduleHead = {
        id: null,
        created_by: null,
        job_id: null,
        job_alloc_id: null,
        schedule_start_time: null,
        schedule_end_time: null,
        event_type: null,
        notes: null,
        priority: null,
        updated_by: null,
        status: null,
        allday: null,
        schedule_start_date: null,
        schedule_end_date: null,
        created_at: null,
        updated_at: null,
        staff: null,
        equipments: null,
    }

    ScheduleDetailModel = {
        id: null,
        head_id: null,
        staff_id: null,
        status: null,
        access_type: null,
        created_by: null,
        updated_by: null,
        created_at: null,
        updated_at: null,
    }

    ScheduleEquipmentDetailModel = {
        id: null,
        head_id: null,
        staff_id: null,
        equipment_id: null,
        status: null,
        access_type: null,
        created_by: null,
        updated_by: null,
        created_at: null,
        updated_at: null,
    }

    redirectPage(module: string) {
        this.router.navigate([module]);
    }

    constructor(private breadcrumbService: BreadcrumbService, private router: Router, private CRUDService: CRUDService, private messageService: MessageService, private confirmationService: ConfirmationService,private commonservice: CommonService) {
        this.breadcrumbService.setItems([
        ]);
        const name = Calendar.name; // add this line in your constructor

    }

    dateClicked(e) {
        //console.log(e);
        //this.ScheduleHead.schedule_start_date = new Date(e.dateStr);
        //this.ScheduleHead.schedule_end_date = new Date(e.dateStr);
        this.displayEventDialog = true;
    };

    eventChanged(e) {
        alert(e.title + ' was dropped on ');
        if (!confirm('Are you sure about this change?')) {
            e.revert();
        } else {

        }

    }
    ngOnInit() {
        this.appProps = this.commonservice.getAppProperties();
        this.companyId = sessionStorage.getItem('company_id');
        this.CRUDService.getJobsList(this.companyId).subscribe((data: any[]) => {
            this.jobsList = data;
            this.filteredJobs = this.jobsList;
            //console.log('Jobs : ' + JSON.stringify(this.filteredJobs));
            this.CRUDService.getDataByField('CompanyStaffInfo', 'company_id', this.companyId).subscribe((staffData: any[]) => {
                //StaffsList(this.companyId).subscribe((staffData: any[]) => {
                this.staffsList = staffData;
                this.filteredStaffs = staffData;
                //console.log('Staffs : ' + JSON.stringify(staffData));
            });
            this.CRUDService.getDataByField('EquipmentList', 'process_id', this.companyId).subscribe((equipmentData: any[]) => {
                this.equipmentsList = equipmentData;
                this.filteredEquipments = equipmentData;
            });
            this.fetchEvents();
        });



        this.options = {
            initialView: 'dayGridMonth',
            plugins: [dayGridPlugin, timeGridPlugin, interactionPlugin, listPlugin],
            nowIndicator: true,
            minTime: '09:00:00',
            maxTime: '19:00:00',
            /* selectable: true,
            selectMirror: true,
            unSelectAuto: true, */
            //weekends: false,
            //hiddenDays: [7],
            dayHeaders: false,
            defaultView: 'dayGridMonth',
            header: {
                left: 'prev,today,next',
                center: 'title',
                right: 'dayGridMonth,timeGridWeek,timeGridDay,listMonth'
            },
            editable: true,
            nextDayThreshold: '09:00:00',
            droppable: true,
            drop: (e) => {
                this.dropped(e);
            },
            dateClick: (e) => {
                this.dateClicked(e);
            },
            eventClick: (e) => {
                this.showSelectedEvent(e);
            },
            eventDrop: (e) => {
                alert('eventChanged : ');
                this.eventChanged(e);
            },
            eventReceive: (eventReceiveEvent) => {
                this.eventReceived(eventReceiveEvent);
                ////console.log("eventReceiveEvent: ", eventReceiveEvent);
                ////console.log("events: ", this.events);


            }
            //events: this.events,
        };
        this.orgStaff = [];
    }

    eventReceived(eventReceiveEvent: any) {
        /* //console.log('Title : ' + eventReceiveEvent.event.title);
        //console.log(eventReceiveEvent);
        //console.log(eventReceiveEvent.event._def.extendedProps); */
        let eventProperties = eventReceiveEvent.event.extendedProps;
        if(eventProperties.type=='job'){
            this.ScheduleHead.notes = 'Selected Job : '+eventReceiveEvent.event.title;
        }
        if(eventProperties.type=='staff'){
            this.ScheduleHead.notes = 'Selected Staff : '+eventReceiveEvent.event.title;
        }
        if(eventProperties.type=='equipment'){
            this.ScheduleHead.notes = 'Selected Equipment : '+eventReceiveEvent.event.title;
        }
        alert('event start date : ' + eventReceiveEvent.event.start);
        if (this.events.length !== 0 && this.events.some(e => e.title === eventReceiveEvent.event.title) &&
            this.events.some(e => e.start === this.fromDateToString(eventReceiveEvent.event.start))) {
            //console.log('eventDetails : ' + eventReceiveEvent);
        }
        this.ScheduleHead.schedule_start_date = new Date(eventReceiveEvent.event.start);
        this.ScheduleHead.schedule_end_date = new Date(eventReceiveEvent.event.start);
        this.displayEventDialog = true;
    }

    fromDateToString(date: Date): String {
        date = new Date(+date);
        date.setTime(date.getTime() - date.getTimezoneOffset() * 60000);
        let dateAsString = date.toISOString().substr(0, 19);
        return dateAsString;
    }


    dropped(info) {
        //console.log('dropped');

        //console.log(info);
    }
    hideDialog() {
        //this.fetchEventDetails();
        this.displayEventDialog = false;
    }

    saveSchedule() {
        if (this.selectedJob !== null) {
            //console.log('selectedJob', this.selectedJob);
            this.ScheduleHead.job_id = this.selectedJob.JobId;
        }
        //  this.ScheduleHead.job_id = this.selectedJob.JobId;
        this.ScheduleHead.event_type = 'JOB';
        if (this.ScheduleHead.id !== '' && this.ScheduleHead.id !== null) {
            this.CRUDService.Update(this.ScheduleHead, 'ScheduleHead').subscribe((data: any) => {
                this.messageService.add({ severity: 'success', summary: 'Successful', detail: 'New Event Created', life: 1000 });
                this.saveStaffDetails();
            });
            this.fetchEvents();
        } else {
            this.CRUDService.Create(this.ScheduleHead, 'ScheduleHead').subscribe((data: any) => {
                if (data.success === 1) {
                    this.ScheduleHead.id = data.id;
                    //this.events = [...this.events, this.event];
                    this.fetchEvents();
                    this.messageService.add({ severity: 'success', summary: 'Successful', detail: 'New Event Created', life: 1000 });
                } else {
                    this.messageService.add({ severity: 'error', summary: 'Error', detail: 'Contractor Not Created', life: 1000 });
                }
                this.saveStaffDetails();
                this.fetchEvents();
            });
        }
        this.displayEventDialog = false;
    }


    saveEquipmentDetails() {
        if (this.orgEquipments.length === 0 && this.selectedEquipments.length > 0) {
            for (let i = 0; i < this.selectedEquipments.length; i++) {
                let equipmentsModel = {
                    id: null,
                    head_id: this.ScheduleHead.id,
                    staff_id: null,
                    equipment_id: this.selectedEquipments[i].id,
                    status: '1',
                    access_type: null,
                    created_by: null,
                    updated_by: null,
                    created_at: null,
                    updated_at: null,
                }
                this.CRUDService.Create(equipmentsModel, 'ScheduleEquipmentDetail').subscribe((data: any) => {
                });
            }
        } else {
            for (let i = 0; i < this.selectedEquipments.length; i++) {
                let EquipInfo = this.selectedEquipments[i];
                let orgEquipmentArr = new Map(this.orgEquipments.map(col => [col.id, col.value]));
                //console.log(orgEquipmentArr);
                if (orgEquipmentArr.has(EquipInfo.id)) {
                    orgEquipmentArr.delete(EquipInfo.id);
                } else {
                    let equipmentsModel = {
                    id: null,
                    head_id: this.ScheduleHead.id,
                    staff_id: null,
                    equipment_id: this.selectedEquipments[i].id,
                    status: '1',
                    access_type: null,
                    created_by: null,
                    updated_by: null,
                    created_at: null,
                    updated_at: null,
                }
                    this.CRUDService.Create(equipmentsModel, 'ScheduleEquipmentDetail').subscribe((data: any) => {
                    });
                }
            }
            for (let i = 0; i < this.orgEquipments.length; i++) {
                let EquipInfo = this.orgEquipments[i];
                let selEquipmentArr = new Map(this.selectedEquipments.map(col => [col.id, col.value]));
                if (!selEquipmentArr.has(EquipInfo.id)) {
                    //delete this.CRUDService.Create(staffInfo.id, 'ScheduleDetail').subscribe((data: any) => {});
                }
            }
        }
    }

    saveStaffDetails() {
        if (this.orgStaff.length === 0 && this.selectedStaffs.length > 0) {
            for (let i = 0; i < this.selectedStaffs.length; i++) {
                let staffModel = {
                    id: null,
                    head_id: this.ScheduleHead.id,
                    staff_id: this.selectedStaffs[i].id,
                    status: '1',
                    access_type: null,
                    created_by: null,
                    updated_by: null,
                    created_at: null,
                    updated_at: null,
                }
                this.CRUDService.Create(staffModel, 'ScheduleDetail').subscribe((data: any) => {
                });
            }
        } else {
            for (let i = 0; i < this.selectedStaffs.length; i++) {
                let staffInfo = this.selectedStaffs[i];
                let orgStaffArr = new Map(this.orgStaff.map(col => [col.id, col.id]));
                //console.log(orgStaffArr);
                if (orgStaffArr.has(staffInfo.id)) {
                    orgStaffArr.delete(staffInfo.id);
                } else {
                    let staffModel = {
                        id: null,
                        head_id: this.ScheduleHead.id,
                        staff_id: this.selectedStaffs[i].id,
                        status: '1',
                        access_type: null,
                        created_by: null,
                        updated_by: null,
                        created_at: null,
                        updated_at: null,
                    }
                    this.CRUDService.Create(staffModel, 'ScheduleDetail').subscribe((data: any) => {
                    });
                }
            }
            for (let i = 0; i < this.orgStaff.length; i++) {
                let staffInfo = this.orgStaff[i];
                let selStaffArr = new Map(this.selectedStaffs.map(col => [col.id, col.value]));
                if (!selStaffArr.has(staffInfo.id)) {
                    //delete this.CRUDService.Create(staffInfo.id, 'ScheduleDetail').subscribe((data: any) => {});
                }
            }
        }
    }

    fetchEvents() {
        this.events = [];
        this.CRUDService.getEventsList(this.companyId).subscribe((data: any[]) => {
            this.scheduleHeadList = data;
            for (let i = 0; i < this.scheduleHeadList.length; i++) {
                const event: any = {};
                if (this.scheduleHeadList[i].job_id !== '' && this.scheduleHeadList[i].job_id !== null) {
                    event.title = 'Job Id : ' + this.scheduleHeadList[i].job_id;
                    event.backgroundColor = 'blue';
                    event.borderColor = 'black';
                } else {
                    event.title = 'Non Job Schedule :' + this.scheduleHeadList[i].id;
                    event.backgroundColor = 'orange';
                    event.borderColor = 'black';
                }
                event.title = event.title + ' Staff : ' + this.scheduleHeadList[i].staff;
                event.title = event.title + ' Equipments : ' + this.scheduleHeadList[i].equipments;
                event.start = this.scheduleHeadList[i].schedule_start_date;
                event.end = this.scheduleHeadList[i].schedule_end_date;
                event.id = this.scheduleHeadList[i].id;
                this.events = [...this.events, event];
            }
        });
    }

    fetchEvents2() {
        this.events = [];
        this.CRUDService.getEventsList(this.companyId).subscribe((data: any[]) => {
            this.scheduleHeadList = data;
            for (let i = 0; i < this.scheduleHeadList.length; i++) {
                const event: any = {};
                this.CRUDService.getEventsStaffs(this.scheduleHeadList[i].id).subscribe((staffData: any[]) => {
                    this.scheduleHeadList[i].staff = staffData.map(function (el) { return el.StaffName; });
                    this.CRUDService.getEventsEquipments(this.scheduleHeadList[i].id).subscribe((equipmentData: any[]) => {
                        this.scheduleHeadList[i].equipments = equipmentData.map(function (el) { return el.equipment_name; });
                        if (this.scheduleHeadList[i].job_id !== '' && this.scheduleHeadList[i].job_id !== null) {
                            event.title = 'Job Id : ' + this.scheduleHeadList[i].job_id;
                            event.backgroundColor = 'blue';
                            event.borderColor = 'black';

                        } else {
                            event.title = 'Job Id : ' + this.scheduleHeadList[i].id;
                            // event.backgroundColor = 'red';
                            // event.borderColor = 'red';
                            event.backgroundColor = 'orange';
                            event.borderColor = 'black';

                        }
                        event.title = event.title + ' Staff : ' + this.scheduleHeadList[i].staff;
                        event.title = event.title + ' Equipments : ' + this.scheduleHeadList[i].equipments;
                        event.start = this.scheduleHeadList[i].schedule_start_date;
                        event.end = this.scheduleHeadList[i].schedule_end_date;
                        event.id = this.scheduleHeadList[i].id;
                        this.events = [...this.events, event];
                    });
                });
            }
        });
    }

    filterJobs(event) {
        this.filteredJobs = this.jobsList;
        //console.log('Jobs : ' + JSON.stringify(this.filteredJobs));
        //in a real application, make a request to a remote url with the query and return filtered results, for demo we filter at client side
        /* let filtered: any[] = this.jobsList;
        let query = event.query;
        if (query == null) {
        } else {
            for (let i = 0; i < this.jobsList.length; i++) {
                let job = this.jobsList[i];
                if (job.JobId.toLowerCase().indexOf(query.toLowerCase()) == 0) {
                    filtered.push(job);

                }
            }
            this.filteredJobs = filtered;
        }
 */

    }

    showSelectedEvent(e) {
        this.selectedStaffs = null;
        this.selectedEquipments = null;
        this.CRUDService.getDataByField('ScheduleHead', 'id', e.event.id).subscribe((data: any[]) => {
            this.ScheduleHead = data[0];
            this.ScheduleHead.schedule_start_date = e.event.start;
            this.ScheduleHead.schedule_end_date = e.event.end;
            this.CRUDService.getEventsStaffs(this.ScheduleHead.id).subscribe((staffData: any[]) => {
                this.selectedStaffs = staffData;
                this.orgStaff = staffData;
            });
            this.CRUDService.getEventsEquipments(this.ScheduleHead.id).subscribe((equipmentData: any[]) => {
                this.selectedEquipments = equipmentData;
                this.orgEquipments = equipmentData;

                this.displayEventDialog = true;
            });
            this.CRUDService.getDataByField('JobInfo', 'id', this.ScheduleHead.job_id).subscribe((jobData: any[]) => {
                this.selectedJob = jobData[0];
            });
        });
        this.displayEventDialog = false;

    }

    filterEquipment(event) {
        //in a real application, make a request to a remote url with the query and return filtered results, for demo we filter at client side
        let filtered: any[] = [];
        const query = event.query;
        for (let i = 0; i < this.equipmentsList.length; i++) {
            const equipment = this.equipmentsList[i];
            if (equipment.equipment_name.toLowerCase().indexOf(query.toLowerCase()) === 0) {
                filtered = [...filtered, equipment];
            }
        }
        this.filteredEquipments = filtered;
    }

    filterStaff(event) {
        //in a real application, make a request to a remote url with the query and return filtered results, for demo we filter at client side
        let filtered: any[] = [];
        const query = event.query;
        for (let i = 0; i < this.staffsList.length; i++) {
            const staff = this.staffsList[i];
            if (staff.first_name.toLowerCase().indexOf(query.toLowerCase()) === 0) {
                filtered = [...filtered, staff];
            }
        }
        this.filteredStaffs = filtered;
    }

    deleteEvent() {
        this.confirmationService.confirm({
            message: 'Are you sure you want to delete this schedule : ' + this.ScheduleHead.id + '?',
            header: 'Confirm',
            icon: 'pi pi-exclamation-triangle',
            accept: () => {
                this.CRUDService.Delete(this.ScheduleHead.id, 'ScheduleHead').subscribe((data: any[]) => {
                    this.messageService.add({ severity: 'success', summary: 'Successful', detail: 'Schedule Removed', life: 1000 });
                    this.fetchEvents();
                    this.displayEventDialog = false;
                });
            }
        });

    }
}