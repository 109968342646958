import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { CRUDService } from 'src/app/utilities/crud.service';
import { ConfirmationService } from 'primeng/api';
import { MessageService } from 'primeng/api';
import { Table } from 'primeng/table';
import { CommonService } from 'src/app/utilities/common.service';
import { environment } from 'src/environments/environment';
import { DynamicDialogConfig, DialogService } from 'primeng/dynamicdialog';
import { ActivatedRoute, Router } from '@angular/router';
import { MenuItem } from 'primeng/api';

@Component({
  selector: 'app-table',
  templateUrl: './table.component.html',
  styleUrls: ['./table.component.scss']
})
export class TableComponent implements OnInit {

  @Output() openNewNotify: EventEmitter<any> = new EventEmitter();
  @Output() editRecordNotify: EventEmitter<any> = new EventEmitter();
  @Output() deleteRecordNotify: EventEmitter<any> = new EventEmitter();
  @Output() viewChildNotify = new EventEmitter<{ para1: string, para2: string, para3: string }>();
  @Output() selectChildNotify: EventEmitter<any> = new EventEmitter();
  @Output() workFlowNotify: EventEmitter<any> = new EventEmitter();
  @Output() exportNotify: EventEmitter<any> = new EventEmitter();
  @Output() exportSubmit: EventEmitter<any> = new EventEmitter();
  @Input() appconfigList: any;
  @Input() detailData: any;
  @Input() childData: any;
  @Input() pageTitle: any;
  @Input() moduleName: any;
  @Input() cols: any;
  @Input() createMode: any;
  @Input() inputFields: any;
  @Input() isExport: boolean;
  @Input() isDelete: boolean;
  paginationvalue: any;
  // cols: any;
  globalFilterFields: any;
  searchValue: any;
  selectedRecords: any[] = [];
  items: MenuItem[];
  inlineAdd:Boolean=false;
  isEditAll: false;
  showWorkflow:Boolean=false;
  public form: FormGroup;
  unsubcribe: any
  selectedroute: string;
  userDetails: any;
  selectedmenu: string;
  constructor(private crudService: CRUDService,
    private messageService: MessageService,
    private confirmationService: ConfirmationService,
    private commonservice: CommonService,
    private dialogService: DialogService,
    public config: DynamicDialogConfig,
    private activatedroute: ActivatedRoute,
    private router: Router,) { 
      this.form = new FormGroup({
        fields: new FormControl(JSON.stringify(this.inputFields))
      })
      this.unsubcribe = this.form.valueChanges.subscribe((update) => {
        console.log(update);
        this.inputFields = JSON.parse(update.fields);
      });
    }


  ngOnInit(): void {
    this.paginationvalue = environment.paginatorValue;
      this.showWorkflow=true;
  }
  openNew() {
    this.openNewNotify.emit();
  }
  editRecord(appconfigList) {
    this.editRecordNotify.emit(appconfigList);
  }
  deleteRecord(appconfigList) {
    this.deleteRecordNotify.emit(appconfigList);
  }
  viewChild(p1, p2, p3) {
    this.viewChildNotify.emit({ para1: p1, para2: p2, para3: p3 });
  }
  workflowView(appconfigList)
  {
    this.workFlowNotify.emit(appconfigList);
  }
  selectchild1(id) {
    this.selectChildNotify.emit(id);
  }
  clear(dt){
    this.searchValue = null;
    dt.clear();
  }
  deleteSelectedJob(){

  }
  exportExcel(selectedRecords){
    this.exportNotify.emit(selectedRecords)
  }
  onSubmit(value)
  {
    debugger
    this.exportSubmit.emit(value);
  }
  ngDistroy() {
    this.unsubcribe();
  }

  showOptionValue(a,b){
    let outValue='';
    let option = JSON.parse(b);
    for(let j =0; j<option?.length; j++)
    {
      if(a === option[j].code)
      {
        outValue=option[j].description;
      }
  }
    return outValue;
  }
  showOptionClass(a,b){
    let outValue='';
    let option = JSON.parse(b);
    for(let j =0; j<option?.length; j++)
    {
      if(a === option[j].code)
      {
        outValue=option[j].class;
      }
  }
    return outValue;
  }
  
  

}
