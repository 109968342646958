import { Component } from '@angular/core';

@Component({
	selector: 'app-footer',
	template: `
        <div class="layout-footer">
			<div class="logo-text">
<!-- 				<img src="assets/layout/images/logo-mirage@2x.png" alt="mirage-layout" /> -->
				<div class="text">
					<h1>Tree Manager</h1>
					<span>Project Management Software</span>
				</div>
			</div>
			<div class="icons">

				<div class="facebook"><a href="https://www.facebook.com/EnviroF/" target="_blank"><i
                                class="fa fa-facebook" aria-hidden="true"></i></a></div>
                    <div class="linkedin"><a href="https://www.linkedin.com/company/enviro-frontier/" target="_blank"><i
                                class="fa fa-linkedin" aria-hidden="true"></i></a></div>
                    <!-- <li class="instagram"><a href="#" target="_blank"><i class="fa fa-instagram" aria-hidden="true"></i></a></li>-->
                    <div class="youtube"><a href="https://www.youtube.com/channel/UCaLKRgHOl9TfEufA0BzzLEQ/featured"
                            target="_blank"><i class="fa fa-youtube" aria-hidden="true"></i></a></div>
			</div>
<div class="footer-nav">
                <ul>
                    <li><a href="#/privacypolicy">Privacy Policy</a></li>
                    <li><a href="#/termsofuses">Terms of Use</a></li>
                    <li><a href="http://envirofrontier.com.au/" target="_blank">Website</a></li>
                    <li><a href="http://envirofrontier.com.au/contact" target="_blank">Support</a></li>
                    <!--<li><a href="#">Blog</a></li>-->
                </ul>
			</div>
        </div>
    `
})
export class AppFooterComponent {

}
