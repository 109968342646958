import { Component, Input } from '@angular/core';
import { FormGroup } from '@angular/forms';

@Component({
    selector: 'radio',
    template: `
      <div [formGroup]="form">
        <div class="form-check" *ngFor="let opt of field.options" >
          <input class="form-check-input" type="radio" [formControlName]="field.name" [name]="field.name" [value]="opt.code" [checked]="opt.code == field.value"  [attr.required]="field.required">
          <label class="form-check-label">
            {{opt.description}}
          </label>
        </div>
      </div> 
      <div class="alert alert-danger my-1 p-2 fadeInDown animated" *ngIf="!isValid" > Enter valid {{field.description}} </div>     
    `
})
export class RadioComponent {
    @Input() field:any = {};
    @Input() form:FormGroup;
    get isValid() { return this.form.controls[this.field.name].valid; }
    
}