import { Component, OnInit, Input, OnChanges, ViewChild } from '@angular/core';
import { ActivatedRoute, Router, NavigationStart } from '@angular/router';
import { CRUDService } from 'src/app/utilities/crud.service';
import { ConfirmationService } from 'primeng/api';
import { MessageService } from 'primeng/api';
import { Region } from './region';
import { Table } from 'primeng/table';
import { Location } from '@angular/common';
import { ConfirmDialogModule } from 'primeng/confirmdialog';
import { Validators, FormControl, FormGroup, FormBuilder } from '@angular/forms';
import { GooglePlaceDirective } from 'ngx-google-places-autocomplete';
import { CommonService } from 'src/app/utilities/common.service';
import { environment } from 'src/environments/environment';
import { DatePipe } from '@angular/common';


@Component({
  selector: 'app-admin-region',
  templateUrl: './region.component.html',
  styleUrls: ['./region.component.scss'],
  providers:[DatePipe]
})
export class RegionComponent implements OnInit {

  paginationvalue:any;
  @Input() contractor_id: any;
  companyId: any;
  viewType: any;
  menuData: any;
  userDetails: any;
  cols: any[];
  dataValue: any[];
  viewAllRegionData: any;
  regionCreateList: any;
  exportColumns: any[];
  countryList: any;
  stateList: any[];
  title = 'rou';
  optionsMap: any;
  public saveView = true;
  public editView = false;
  public listView = false;
  public createView = false;
  regionDialog: boolean;
  regions: Region[];
  region: Region;
  selectedRegions: Region[];
  submitted: boolean;
  companyList: any = [];
  invalid: boolean = false;
  statuses: any[];
  selectedState: any;
  selectedCountry: any;
  selectedCompany: any;
  formattedaddress = '';
  places: any = [];
  Loading:boolean=false;
  searchvalue: any;
  
  regionForm: FormGroup;
  filteredPlaces: any[] = [];
  inputAddress:any = [];

  options = {
      types: [],
      componentRestrictions: {
          country: ['AU']
      }
  }
  componentForm = {
      street_number: 'short_name',
      route: 'long_name',
      locality: 'long_name',
      sublocality_level_1: 'long_name',
      administrative_area_level_1: 'short_name',
      country: 'long_name',
      postal_code: 'short_name'
  };

  isActive: boolean;
  exportdisplay:Boolean = false;
  // tslint:disable-next-line: max-line-length
  customLabel = { restaurant: { label: 'R' }, bar: { label: 'B' } };
  map: google.maps.Map;
  iconBase = 'https://maps.google.com/mapfiles/kml/shapes/';
  icons: any;
  loading = false;
  overlays: any[] = [];
  setMap(event) {
      this.map = event.map;
  }
  companyData : any;
  // tslint:disable-next-line: max-line-length
  constructor(private fb: FormBuilder, private crudService: CRUDService, private messageService: MessageService, private confirmationService: ConfirmationService, private router: Router, private route: ActivatedRoute, private location: Location, private commonservice: CommonService,private datePipe: DatePipe) {
  }
  env: any;
  async ngOnInit(): Promise<void> {
    this.env = environment;
      this.Loading=true;
      this.paginationvalue=environment.paginatorValue;
      this.menuData = JSON.parse(sessionStorage.getItem('menuData'));
      this.viewType = this.menuData.para_1;
      this.userDetails = sessionStorage.getItem('loggedInUser');
      this.companyId = sessionStorage.getItem('company_id');
      this.regions = [];

      if (typeof this.contractor_id !== 'undefined') {
          this.companyId = this.contractor_id;
          this.viewType = 'TP';
          this.crudService.getByDepotCID(this.companyId).subscribe((data: any[]) => {
              this.regions = data;
          
              if(this.regions.length>0)
              {
                  localStorage.setItem('deportStrength','10');
              }
          });
      } else {
          if (this.viewType === 'TM') {
              this.crudService.getAllCompanies().subscribe((data: any[]) => {
                  this.companyList = data;
                  //console.log(this.companyList);
              });
              this.crudService.getAllJobDepotDetails().subscribe((regionData: any[]) => {
                  this.regions = regionData;
              });
          } else if (this.viewType === 'TL') {
              this.crudService.getByDepotCID(this.companyId).subscribe((data: any[]) => {
                  this.regions = data;
              });
          } else if (this.viewType === 'TP') {
              this.crudService.getByDepotCID(this.companyId).subscribe((data: any[]) => {
                  this.regions = data;
              });
          }
      }

      this.companyData = await this.crudService.getDataByField('Company', 'id', this.companyId).toPromise();

      this.getAllCountries();
      this.getAllStates();
       this.componentForm = {
          street_number: 'short_name',
          route: 'long_name',
          locality: 'long_name',
          sublocality_level_1: 'long_name',
          administrative_area_level_1: 'short_name',
          country: 'long_name',
          postal_code: 'short_name'
      };
      this.options = {
          types: [],
          componentRestrictions: {
              country: ['AU']
          }
      }
      this.cols = [
          { field: 'depot_name', header: 'Name' },
          { field: 'depot_desc', header: 'Description' },
          { field: 'CompanyName', header: 'Company Name' },
          { field: 'StatusDescription', header: 'Status' },
      ];
      this.exportColumns = this.cols.map(col => ({ title: col.header, dataKey: col.field }));
      this.regionForm = new FormGroup({
          depot_name: new FormControl('', Validators.required),
          depot_desc: new FormControl('', Validators.required),
          contact: new FormControl('', Validators.required),
          email: new FormControl('', Validators.required),
          bestContact: new FormControl('', Validators.required),
          altContact: new FormControl('', Validators.required),
          street_address: new FormControl('', Validators.required),
          suburb: new FormControl('', Validators.required),
          postcode: new FormControl('', Validators.required),
          state: new FormControl('', Validators.required),
          country: new FormControl('', Validators.required)
      });
      this.exportdisplay = await this.commonservice.exportCheck();
      this.loadMap();
      this.Loading=false;
  }

  async loadMap(){
      this.overlays = [];
      this.optionsMap = {
          center: { lat: environment.hostingConfigs.mapCenterLat, lng: environment.hostingConfigs.mapCenterLng },
          zoom: 4
      };

      this.icons = {
          parking: { icon: this.iconBase + 'parking_lot_maps.png' },
          library: { icon: this.iconBase + 'library_maps.png' },
          info: { icon: this.iconBase + 'info-i_maps.png' },
          compliant: { icon: 'http://maps.google.com/mapfiles/kml/paddle/grn-stars.png' },
          job: { icon: 'http://maps.google.com/mapfiles/kml/paddle/grn-stars.png' },
          noncompliant: { icon: 'http://maps.google.com/mapfiles/kml/paddle/stop.png' }
        };
    
        this.loading = true;
        let contentString = null;
          contentString = '<div id="content"><div id="siteNotice"></div><h3 id="firstHeading" class="firstHeading">'
            + this.region?.street_address;
            + this.region?.suburb
            + this.region?.postcode
            + this.region?.state_id
            + this.region?.country_id;
          let map = this.map;
          let lat = parseInt(this.region?.location_lat);
          let lng = parseInt(this.region?.location_lng);
          const marker = new google.maps.Marker(
            {
              position: new google.maps.LatLng(lat, lng),
              title: this.region?.street_address,
              map,
              icon: this.icons['compliant'].icon
            });
          this.overlays.push(marker)
    
          const infowindow = new google.maps.InfoWindow({
            content: contentString,
          });
    
          google.maps.event.addListener(marker, 'click', (function (marker) {
            return function () {
              infowindow.setContent(contentString);
              infowindow.open(map, marker);
            }
          })(marker));
          this.loading = false;
  }

  ngOnChanges() {
    this.ngOnInit()
      this.componentForm = {
          street_number: 'short_name',
          route: 'long_name',
          locality: 'long_name',
          sublocality_level_1: 'long_name',
          administrative_area_level_1: 'short_name',
          country: 'long_name',
          postal_code: 'short_name'
      };
      this.options = {
          types: [],
          componentRestrictions: {
              country: ['AU']
          }
      }
  }


  getAllCountries() {
      this.crudService.getAll('Country').subscribe((data: any[]) => {
          this.countryList = data;
      });
  }

  getAllStates() {
      this.crudService.getAll('State').subscribe((data: any[]) => {
          this.stateList = data;
      });
  }

  displayState(state_id)
  {
      let stateName = '';
      for (let i = 0; i < this.stateList?.length; i++) {
          if (this.stateList[i].id === state_id) {
              stateName = this.stateList[i].name;
              break;
          }
      }
      return stateName;
  }
  displayCountry(country_id)
  {
      let CountryName = '';
      for (let i = 0; i < this.countryList?.length; i++) {
          if (this.countryList[i].id === country_id) {
              CountryName = this.countryList[i].name;
              break;
          }
      }
      return CountryName;
  }


  onFocusEvent(event: any) {
      /*  this.appErrMsgJobPriorityName = "";
       this.appErrMsgJobPriorityDes = "";
       this.appErrMsgJobPriorityNo = ""; */
  }

  openNew() {
      this.region = {};
      this.selectedCountry = {};
      this.selectedState = {};
      this.selectedCompany = {};
      this.submitted = false;
      this.regionDialog = true;
      this.region.status = '1'
      this.isActive = true;
      if (this.viewType === 'TP') {
          this.region.company_id = this.companyId;
      }
      this.region.street_address = '';
      this.formattedaddress = '';
      this.invalid = true;
      this.region.contact_name = this.companyData[0].main_contact_name;
      this.region.email = this.companyData[0].email;
      this.region.contact_best = this.companyData[0].mobile_no;
      this.region.contact_alt = this.companyData[0].phone;
  }

  deleteSelectedRegions() {
      this.confirmationService.confirm({
          message: 'Are you sure you want to delete the selected regions?',
          header: 'Confirm',
          icon: 'pi pi-exclamation-triangle',
          accept: () => {
              // tslint:disable-next-line: prefer-for-of
              for (let i = 0; i < this.selectedRegions.length; i++) {
                  this.crudService.Delete(this.selectedRegions[i].id, 'depot').subscribe((data: any[]) => { });
              }
              this.regions = this.regions.filter(val => !this.selectedRegions.includes(val));
              this.selectedRegions = null;
              this.messageService.add({ severity: 'success', summary: 'Successful', detail: 'Region Deleted', life: 1000 });
          }
      });
  }

  editRegion(region: Region) {
      this.region = { ...region };
      this.regionDialog = true;
      console.log("region",region);
      if (this.region.status === '' || this.region.status === null || this.region.status === '1') {
          this.region.status = '1';
          this.isActive = true;
      } else {
          this.region.status = '2';
          this.isActive = false;
      }
      this.crudService.getDataByField('Country', 'id', this.region.country_id).subscribe((data: any[]) => {
          this.selectedCountry = data[0];
      });
      this.crudService.getDataByField('State', 'id', this.region.state_id).subscribe((data: any[]) => {
          this.selectedState = data[0];
      });
      this.formattedaddress = this.region.street_address;
      this.loadMap();
  }

  deleteRegion(region: Region) {
      this.confirmationService.confirm({
          message: 'Are you sure you want to delete region : ' + region.depot_name + '?',
          header: 'Confirm',
          icon: 'pi pi-exclamation-triangle',
          accept: () => {
              this.regions = this.regions.filter(val => val.id !== region.id);
              this.region = {};
              this.crudService.Delete(region.id, 'depot').subscribe((data: any[]) => {
                  this.messageService.add({ severity: 'success', summary: 'Successful', detail: 'Region Deleted', life: 1000 });
              });
          }
      });
  }

  onRowSelect(event) {
      this.region = event.data;
      this.regionDialog = true;
      //this.messageService.add({severity:'info', summary:'Product Selected', detail: event.data.name});
  }

  onRowUnselect(event) {
      this.messageService.add({ severity: 'info', summary: 'Product Unselected', detail: event.data.name });
  }

  hideDialog() {
      this.regionDialog = false;
      this.submitted = false;
  }
  editFormioRegion(deopt: Region){
   this.region={...deopt} ;
   this.router.navigate(['/formio-edit',this.region.id])
  }    
  saveRegion(address: any) {
      //console.log(this.regionForm);
      this.region.state_id = this.selectedState.id;
      this.region.country_id = this.selectedCountry.id;
      this.submitted = true;
    //   this.region.location_lat = '';
    //   this.region.location_lng = '';
      if (this.isActive) {
          this.region.status = '1';
          this.region.StatusDescription = 'Active';
      } else {
          this.region.status = '2';
          this.region.StatusDescription = 'Inactive';
      }
      this.regionDialog = true;
      if (this.regionForm.status === 'VALID') {
          if (this.region.id) {
              this.crudService.Update(this.region, 'depot').subscribe((data: any) => {
                  if (data.success === 1) {
                      this.regionDialog = false;
                      this.regions[this.findIndexById(this.region.id)] = this.region;
                      this.messageService.add({ severity: 'success', summary: 'Successful', detail: 'Region Updated', life: 1000 });
                  } else {
                      this.regionDialog = true;
                      this.messageService.add({ severity: 'error', summary: 'Error', detail: 'Region Not Updated', life: 1000 });
                  }
              });
          } else {
              this.region.make_default = '1';
              this.region.process_id = this.companyId
              this.region.created_by = this.userDetails.id;
              this.crudService.Create(this.region, 'depot').subscribe((data: any) => {
                  if (data.success === 1) {
                      this.regionDialog = false;
                      this.region.id = data.id;
                      this.regions = [...this.regions, this.region];
                      this.messageService.add({ severity: 'success', summary: 'Successful', detail: 'Region Created', life: 1000 });
                  } else {
                      this.regionDialog = true;
                      this.messageService.add({ severity: 'error', summary: 'Error', detail: 'Region Not Created', life: 1000 });
                  }
              });
          }
      } else {
          this.messageService.add({ severity: 'error', summary: 'Error', detail: 'Region Not Created. Need all Mandatory fields filled properly.', life: 1000 });
      }
      this.regionDialog = false;
      this.submitted = false;
  }

  findCompanyById(id: string): string {
      let companyName = '';
      for (let i = 0; i < this.companyList.length; i++) {
          if (this.companyList[i].id === id) {
              companyName = this.companyList[i].business_name;
              break;
          }
      }
      return companyName;
  }

  findIndexById(id: string): number {
      let index = -1;
      for (let i = 0; i < this.regions.length; i++) {
          if (this.regions[i].id === id) {
              index = i;
              break;
          }
      }
      return index;
  }

  exportPdf() {
      import('jspdf').then(jsPDF => {
          import('jspdf-autotable').then(x => {
              /* const doc = new jsPDF.default(0,0);
              if(typeof this.selectedRegions == 'undefined'){
                  doc.autoTable(this.exportColumns, this.regions);
                 }else{
                     doc.autoTable(this.exportColumns, this.selectedRegions);
              }
             doc.save('products.pdf'); */
          })
      })
  }

  exportExcel(selectedRegions,table) {
      let JsonData: any[] = [];
      if (this.selectedRegions != null && this.selectedRegions.length > 0) {
        JsonData = this.selectedRegions;
      } else {

        if (typeof table.filteredValue !== 'undefined') {
          if (table.filteredValue.length !== this.regions.length && table.filteredValue.length > 0) {
            JsonData = table.filteredValue;
          } else {
            JsonData = this.regions;
          }
        } else {
          JsonData = this.regions;
        }
      }
  
  
      let reformattedArray = JsonData.map(obj => {
  
        let rObj = {};
        this.cols.forEach(pair => {
          rObj[pair.header.toString()] = obj[pair.field];
        });
        return rObj;
      })
      //console.log(reformattedArray);
  
      import('xlsx').then(xlsx => {
        const worksheet = xlsx.utils.json_to_sheet(reformattedArray);
        const workbook = { Sheets: { data: worksheet }, SheetNames: ['data'] };
        const excelBuffer: any = xlsx.write(workbook, { bookType: 'xlsx', type: 'array' });
        this.saveAsExcelFile(excelBuffer, 'regions');
      });
    }
  
    saveAsExcelFile(buffer: any, fileName: string): void {
      import('file-saver').then(FileSaver => {
        const EXCEL_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
        const EXCEL_EXTENSION = '.xlsx';
        const data: Blob = new Blob([buffer], {
          type: EXCEL_TYPE
        });
        let timeStamp = this.datePipe.transform(new Date(), 'ddMMyy_HHmm');
        FileSaver.saveAs(data, fileName + '_' + timeStamp + EXCEL_EXTENSION);
      });
    }

  clear(table: Table) {
      table.clear();
      this.searchvalue = "";
  }

  public AddressChange(address: any) {
      const fullAddress = [];
      this.formattedaddress = address.formatted_address
      let lat = address.geometry.location.lat();
      let lng = address.geometry.location.lng();
      // tslint:disable-next-line: prefer-for-of
      for (let i = 0; i < address.address_components.length; i++) {
          const addressType = address.address_components[i].types[0];
          if (this.componentForm[addressType]) {
              const val = address.address_components[i][this.componentForm[addressType]];
              if (addressType === 'street_number') {
                  fullAddress[0] = val;
              } else if (addressType === 'route') {
                  fullAddress[1] = val;
              }
              if (addressType === 'sublocality_level_1') {//suburb
                  this.region.street_address = val;
              }
              if (addressType === 'locality') {//suburb
                  this.region.suburb = val;
              } else if (addressType === 'administrative_area_level_1') {//state
                  this.stateList.forEach((stat) => {
                      if (stat.name === val) {
                          this.region.state_id = stat.id;
                          this.selectedState = stat;
                      }
                  }); 
              } else if (addressType === 'administrative_area_level_2') {
              } else if (addressType === 'country') {
                  this.countryList.forEach((ctry) => {
                      if (ctry.name === val) {
                          this.region.country_id = ctry.id;
                          this.selectedCountry = ctry;
                      }
                  });
              } else if (addressType === 'postal_code') {
                  this.region.postcode = val;
              }
          }
      }
      const fullAdr = fullAddress.join(' ');
      this.region.street_address = fullAdr;
      //console.log(this.depot.street_address);
      this.region.location_lat = lat;
      this.region.location_lng = lng;
      this.loadMap();
  }

  chooseAddress(event)
  {     
        this.inputAddress = event.summaryline;
        this.formattedaddress = event.summaryline;
        this.region.suburb = event.posttown
        if(event.addressline2 != undefined)
        {
          this.region.street_address = event.addressline1 + ',' + event.addressline2;
        }
        else
        {
          this.region.street_address = event.addressline1;
        }
        this.region.postcode = event.postcode;
        this.region.location_lat = event.latitude;
        this.region.location_lng = event.longitude;
        this.stateList.forEach((stat) => {
            if (stat.id === '111') {
                this.region.state_id = stat.id;
                this.selectedState = stat;
            }
        }); 
        this.countryList.forEach((ctry) => {
            if (ctry.id === '222') {
                this.region.country_id = ctry.id;
                this.selectedCountry = ctry;
            }
        });
        this.loadMap();
  }

  search(event) {
    this.crudService.getPostcoderAddress(event.query).subscribe((res:any)=>{  
        this.filteredPlaces = res.filter((val: any) => val.summaryline.toLowerCase().includes(event.query.toLowerCase()));
      });
  }
}