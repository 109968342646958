import { Injectable, NgZone } from '@angular/core';
import { Subject, Observable } from 'rxjs';
import { CRUDService } from './crud.service';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';
import { Ng2ImgToolsService } from 'ng2-img-tools';

@Injectable()
export class ImageService {

    private ImageSource = new Subject<string>();
    private resetSource = new Subject();
    ImageSource$ = this.ImageSource.asObservable();
    resetSource$ = this.resetSource.asObservable();

    constructor(
        private crudService: CRUDService,
        private ng2ImgToolsService: Ng2ImgToolsService,
        private sanitizer: DomSanitizer,
        private zone: NgZone
    ) {
    }

    processFile(file: File) {
        let resizedImage: string = null;
        let compressedImage: string = null;
        let selectedImage: string = null;
        let croppedImage: string = null;
        let resizedExactCroppedImage: string = null;
        let resizedExactFilledImage: string = null;
        let selectedImageTrusted: SafeUrl = null;

        if (resizedImage !== null) {
            window.URL.revokeObjectURL(resizedImage);
        }
        if (compressedImage !== null) {
            window.URL.revokeObjectURL(compressedImage);
        }
        if (selectedImage !== null) {
            window.URL.revokeObjectURL(selectedImage);
        }
        if (croppedImage !== null) {
            window.URL.revokeObjectURL(croppedImage);
        }
        if (resizedExactCroppedImage !== null) {
            window.URL.revokeObjectURL(resizedExactCroppedImage);
        }
        if (resizedExactFilledImage !== null) {
            window.URL.revokeObjectURL(resizedExactFilledImage);
        }
        resizedImage = "processing";
        compressedImage = "processing";
        croppedImage = "processing";
        resizedExactCroppedImage = "processing";
        resizedExactFilledImage = "processing";
        selectedImage = window.URL.createObjectURL(file);
        selectedImageTrusted = this.sanitizer.bypassSecurityTrustUrl(selectedImage);
        this.compressImage(file);
        this.resizeImage(file);
        this.cropImage(file);
        this.resizeExactCropImage(file);
        this.resizeExactFillImage(file);
    }

    compressImage(file: File) {
        let compressedImage: string = null;
        let compressedImageTrusted: SafeUrl = null;
        console.info("Starting compression for file:", file);
        this.ng2ImgToolsService.compress([file], 0.3).subscribe(result => {
            //console.log("Compression result:", result);
            //all good
            compressedImage = window.URL.createObjectURL(result);
            compressedImageTrusted = this.sanitizer.bypassSecurityTrustUrl(compressedImage);
        }, error => {
            console.error("Compression error:", error);
        }
        );
    }

    resizeImage(file: File) {
        let resizedImage: string = null;
        let resizedImageTrusted: SafeUrl = null;
        console.info("Starting resize for file:", file);
        this.ng2ImgToolsService.resize([file], 400, 500).subscribe(result => {
            //console.log("Resize result:", result);
            //all good
            resizedImage = window.URL.createObjectURL(result);
            resizedImageTrusted = this.sanitizer.bypassSecurityTrustUrl(resizedImage);
        }, error => {
            console.error("Resize error:", error);
        }
        );
    }

    cropImage(file: File) {
        let croppedImage: string = null;
        let croppedImageTrusted: SafeUrl = null;
        console.info("Starting crop for file:", file);
        this.ng2ImgToolsService.crop([file], 400, 500).subscribe(result => {
            //console.log("Crop result:", result);
            //all good
            croppedImage = window.URL.createObjectURL(result);
            croppedImageTrusted = this.sanitizer.bypassSecurityTrustUrl(croppedImage);
        }, error => {
            console.error("Cropping error:", error);
        }
        );
    }

    resizeExactCropImage(file: File) {
        let resizedExactCroppedImage: string = null;
        let resizedExactCroppedImageTrusted: SafeUrl = null;

        console.info("Starting resize exact crop for file:", file);
        this.ng2ImgToolsService.resizeExactCrop([file], 180, 100).subscribe(result => {
            //console.log("Resize exact crop result:", result);
            //all good
            this.zone.run(() => {
                resizedExactCroppedImage = window.URL.createObjectURL(result);
                resizedExactCroppedImageTrusted = this.sanitizer.bypassSecurityTrustUrl(resizedExactCroppedImage);
            });
        }, error => {
            console.error("Resize exact crop error:", error);
        }
        );
    }

    resizeExactFillImage(file: File) {
        let resizedExactFilledImage: string = null;
        let resizedExactFilledImageTrusted: SafeUrl = null;

        console.info("Starting resize exact fill for file:", file);
        this.ng2ImgToolsService.resizeExactFill([file], 180, 100).subscribe(result => {
            //console.log("Resize exact fill result:", result);
            //all good
            this.zone.run(() => {
                resizedExactFilledImage = window.URL.createObjectURL(result);
                resizedExactFilledImageTrusted = this.sanitizer.bypassSecurityTrustUrl(resizedExactFilledImage);
            });
        }, error => {
            console.error("Resize exact fill error:", error);
        }
        );
    }
}
