import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { JobOperationsComponent } from './job-operations/job-operations.component';
import { JobDetailsComponent } from './job-details/job-details.component';
import { JobScheduleComponent } from './job-schedule/job-schedule.component';
import { JobDocumentsComponent } from './job-documents/job-documents.component';
import { JobCostingComponent } from './job-costing/job-costing.component';
import { ScheduleCreateComponent } from './schedule-create/schedule-create.component';
import { JobScheduleCreateComponent } from './job-schedule-create/job-schedule-create.component';
import { JobImagesComponent } from './job-images/job-images.component';
import { ApproveQuoteComponent } from './approve-quote/approve-quote.component';
import { RejectQuoteComponent } from './reject-quote/reject-quote.component';
import { SendQuoteComponent } from './send-quote/send-quote.component';
import { SubContractAgreementCreateComponent } from './sub-contract-agreement-create/sub-contract-agreement-create.component';
import { JobInvoiceSendComponent } from './job-invoice-send/job-invoice-send.component';
import { ShfComponent } from './shf/shf.component';
import { WpCostingComponent } from './wp-costing/wp-costing.component';
import { JobKpiComponent } from './job-kpi/job-kpi.component';
import { JobAdminComponent } from './job-admin/job-admin.component';
import { ScheduleAdminComponent } from './schedule-admin/schedule-admin.component';
import { CommunicationAdminComponent } from './communication-admin/communication-admin.component';
import { JobDetailsApprovalsComponent } from './job-details-approvals/job-details-approvals.component';
import { WorkflowParentComponent } from './workflow-parent/workflow-parent.component';
import { WorkflowChildComponent } from './workflow-child/workflow-child.component';
import { WorkflowTimelineComponent } from './workflow-timeline/workflow-timeline.component'

const routes: Routes = [
  { path: 'schedule', component: JobScheduleComponent },
  { path: 'costing/:jobId/:jobAllocationId', component: JobCostingComponent },
  { path: 'wp-costing', component: WpCostingComponent },
  { path: 'operations', component: JobOperationsComponent },
  { path: 'jobs', component: JobOperationsComponent },
  { path: 'details/:jobId/:AllocId', component: JobDetailsComponent },
  { path: 'job-details-approvals/:jobId/:AllocId', component: JobDetailsApprovalsComponent },
  { path: 'tp-job-details-approvals/:jobId/:AllocId', component: JobDetailsApprovalsComponent },
  { path: 'schedulenew', component: ScheduleCreateComponent },
  /* { path: 'schedulenew', component: JobScheduleCreateComponent }, */
  { path: 'document/:jobId/:jobAllocationId', component: JobDocumentsComponent },
  { path: 'images/:jobId/:jobAllocationId', component: JobImagesComponent },
  { path: 'approve/:jobId/:jobAllocationId', component: ApproveQuoteComponent },
  { path: 'reject/:jobId/:jobAllocationId', component: RejectQuoteComponent },
  { path: 'quote/:jobId/:jobAllocationId', component: SendQuoteComponent },
  { path: 'sca/:jobId/:jobAllocationId', component: SubContractAgreementCreateComponent },
  { path: 'invoice/:jobId/:jobAllocationId', component: JobInvoiceSendComponent },
  { path: 'shf/:jobId/:jobAllocationId', component: ShfComponent },
  { path: 'kpi/:jobId/:jobAllocationId', component: JobKpiComponent },
  { path: 'info', component: JobAdminComponent },
  { path: 'schedule/:job_id/:job_alloc_id', component: ScheduleAdminComponent },
  { path: 'communication/:job_id/:job_alloc_id', component: CommunicationAdminComponent },
  { path: 'workflow', component: WorkflowChildComponent },
  { path: 'job_workflow', component: WorkflowParentComponent },
  { path: 'jobwftimeline',component:WorkflowTimelineComponent}
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class JobRoutingModule { }
