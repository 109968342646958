import { Component, OnInit } from '@angular/core';
import { AppMainComponent } from './app.main.component';
import { CRUDService } from 'src/app/utilities/crud.service';
import { environment } from 'src/environments/environment';

@Component({
    selector: 'app-menu',
    styleUrls: ['./style.css'],
    templateUrl: './app.menu.component.html'
})
export class AppMenuComponent implements OnInit {

    model: any[] = [];
    staffInfo: any;
    companyId: any;
    companyInfo: any;
    userDetails: any;
    userMenus: any[];
    constructor(public app: AppMainComponent, private crudService: CRUDService) {
        if (localStorage.getItem('loggedInUser')) {
            this.companyId = localStorage.getItem('company_id');
            this.userDetails = JSON.parse(localStorage.getItem('loggedInUser'));
        }
    }

    loadmenus() {
        this.model = [];
        if (this.userDetails.homepage_url !== null && this.userDetails.homepage_url !== '') {
            this.model.push({ label: 'Home', icon: 'fa fa-fw fa-home', routerLink: [this.userDetails.homepage_url] });
        } else {
            this.model.push({ label: 'Home', icon: 'fa fa-fw fa-dashboard', routerLink: ['/home'] });
        }
        this.crudService.getMenusByRole(this.userDetails.user_group_id, this.companyId).subscribe((data: any[]) => {
            data = data.filter(val => val.status !== '2');
            let parentMenus = data.filter(val => val.menu_parent_id === '0');


            parentMenus.forEach(pmitem => {
                //console.log(pmitem.display_name);
                let childMenus = data.filter(val => val.menu_parent_id === pmitem.menu_id);
                if (childMenus.length > 0) {
                    let items: any[] = [];
                    childMenus.forEach(item => {
                        if (item.router_url != null) {
                            items.push({ label: item.display_name, icon: item.icon, routerLink: [item.router_url], menuData: item });
                        } else {
                            items.push({ label: item.display_name, icon: item.icon, routerLink: ['/coming-soon'], menuData: item });
                        }
                    });
                    this.model.push({ label: pmitem.display_name, icon: pmitem.icon, items });
                } else {
                    if (pmitem.router_url != null) {
                        this.model.push({
                            label: pmitem.display_name, icon: pmitem.icon, routerLink: [pmitem.router_url], menuData: pmitem
                        });
                    } else {
                        this.model.push({ label: pmitem.display_name, icon: pmitem.icon, routerLink: ['/Coming-soon'], menuData: pmitem });
                    }
                }
            });
            /* this.model.push({ label: 'WIP', icon: 'fa fa-fw fa-info-circle', items: [
                    { label: 'TP Onboarding Approval', icon: 'pi pi-fw pi-user', routerLink: ['/reports/onboarding-approval']},
                    { label: 'PowerBI Dashboard', icon: 'pi pi-fw pi-user', routerLink: ['/analytics/report1']},
                    { label: 'Training Cancellations', icon: 'pi pi-fw pi-user', routerLink: ['/training-list']},
                    { label: 'Menu Setup', icon: 'pi pi-fw pi-id-card', routerLink: ['/admin/menus'] },
                ]
            }); */
        });
    }
    async loadTPMenus() {
        if (this.userDetails.staff_id != null && this.userDetails.staff_id != '') {
            let userData: any = await this.crudService.getDataByField('CompanyStaffInfo', 'id', this.userDetails.staff_id).toPromise();
            this.staffInfo = userData[0];
            // this.loadTPAdminMenus();
                 if (this.staffInfo.user_role_id == '1') {
            this.loadTPAdminMenus();
        } else if (this.staffInfo.user_role_id == '2' || this.staffInfo.user_role_id == '3') {
            this.loadTPUserMenus();
        }
        } 
        else {
            this.loadTPUserMenus();
        }
    }
    loadTPUserMenus() {
        if (this.staffInfo.user_role_id == '3') {
            this.model = [
                { label: 'My Info', icon: 'pi pi-fw pi-user', routerLink: ['/tp/staff'], menuData: { id: 'staff', para_1: 'TP' } },
                { label: 'Job Operations', icon: 'pi pi-fw pi-id-card', routerLink: ['/tp-job/myjobs'], menuData: { id: 'job', para_1: 'TP' } }
            ]
        } else {
            this.model = [
                { label: 'Dashboard', icon: 'fa fa-fw fa-dashboard', routerLink: ['/home'] },
                { label: 'My Info', icon: 'pi pi-fw pi-user', routerLink: ['/tp/staff'], menuData: { id: 'staff', para_1: 'TP' } },
                { label: 'Job Operations', icon: 'pi pi-fw pi-id-card', routerLink: ['/tp-job/myjobs'], menuData: { id: 'job', para_1: 'TP' } }
            ]
        }

    }
    loadTPAdminMenus() {

//         if(environment.hosting == 'UK'){
// this.model = [
//             {
//                 label: 'Dashboard', icon: 'fa fa-fw fa-dashboard', routerLink: ['/home']
//             },
//             /* {
//                 label: 'Setup', icon: 'fa fa-fw fa-info-circle',
//                 items: [
//                     //{ label: 'Clients', icon: 'pi pi-fw pi-users', routerLink: ['/tp-clients'], state: { id: 'client', para_1: 'TP' } },
//                     { label: 'Depots', icon: 'pi pi-fw pi-user', routerLink: ['/tp-depots'], state: { id: 'depot', para_1: 'TP' } },
//                     // { label: 'Table', icon: 'pi pi-fw pi-table', routerLink: ['/uikit/table']              }
//                 ]
//             }, */
//             {
//                 label: 'Setup', icon: 'fa fa-fw fa-info-circle',
//                 items: [
//                     { label: 'My Business', icon: 'pi pi-fw pi-id-card', routerLink: ['/business-setup'] },
//                     { label: 'My Info', icon: 'pi pi-fw pi-user', routerLink: ['/tp/staff'], menuData: { id: 'staff', para_1: 'TP' } },
//                 ]
//             },
//             { label: 'Job Operations', icon: 'pi pi-fw pi-id-card', routerLink: ['/tp-job/myjobs'], menuData: { id: 'job', para_1: 'TP' } },
//             { label: 'Job List', icon: 'pi pi-fw pi-id-card', routerLink: ['/tp-job/joblist'], menuData: { id: 'job', para_1: 'TP' } },
//             {
//                 label: 'Reports', icon: 'fa fa-fw fa-info-circle',
//                 items: [
//                     { label: 'Expired Compliances', icon: 'pi pi-fw pi-user', routerLink: ['/tp-reports/expired-compliances'] },
//                     { label: 'Job Scheduling', icon: 'pi pi-fw pi-user', routerLink: ['/tp-reports/scheduling'] },

//                     { label: 'Staff Compliances', icon: 'pi pi-fw pi-user', routerLink: ['/tp-reports/staffcompliances'] },
//                 ]
//             },
//         ]
//         }
//         else{
            this.crudService.getMenusByUserRoleID(this.userDetails.user_group_id).subscribe((data: any[]) => {
                console.log("getusergrop",data);
                data.sort((a, b) => a.menu_order_id - b.menu_order_id);
                 this.model = []
                this.model.push({ label: 'Dashboard', icon: 'fa fa-fw fa-dashboard', routerLink: ['/home']});
                data = data.filter(val => val.status !== '2' );
                let parentMenus = data.filter(val => val.menu_parent_id === '0');
                if(parentMenus.length == 0){
                    parentMenus = data
                }
                    parentMenus.forEach(pmitem => {
                        let childMenus = data.filter(val => val.menu_parent_id === pmitem.menu_id);
                        if (childMenus.length > 0) {
                            let items: any[] = [];
                            childMenus.forEach(item => {
                                if (item.router_url != null) {
                                    items.push({ label: item.display_name, icon: item.icon, routerLink: [item.router_url], menuData: item });
                                } else {
                                    items.push({ label: item.display_name, icon: item.icon, routerLink: ['/coming-soon'], menuData: item });
                                }
                            });
                            this.model.push({menu_id: Number(pmitem.menu_id),menu_order_id:Number(pmitem.menu_order_id), label: pmitem.display_name, icon: pmitem.icon, items });
                        } else {
                            if (pmitem.router_url != null) {
                                this.model.push({
                                    menu_id:Number(pmitem.menu_id),menu_order_id:Number(pmitem.menu_order_id), label: pmitem.display_name, icon: pmitem.icon, routerLink: [pmitem.router_url], menuData: pmitem
                                });
                            } else {
                                this.model.push({  menu_id:Number(pmitem.menu_id),menu_order_id:Number(pmitem.menu_order_id), label: pmitem.display_name, icon: pmitem.icon, routerLink: ['/Coming-soon'], menuData: pmitem });
                            }
                        }
                    });
                    this.model.sort((a, b) => a.menu_order_id - b.menu_order_id); 
                
              
                console.log("this.model",this.model);
                
              })
        // }
    }
    async ngOnInit() {
        this.model = [];
        let data: any = await this.crudService.getDataByField('Company', 'id', this.companyId).toPromise();
        this.companyInfo = data[0];
        debugger
        if (this.companyInfo.client_type_id == '3') {
             this.loadTPMenus();
          //  this.loadNewRouteMenus();
        } else if (this.companyInfo.client_type_id == '2') {
             this.loadTPMenus();
           // this.loadNewRouteMenus();
        } else {
            if (this.crudService.isTest) {
                if(environment.hosting == 'UK'){
                    // this.loadNewRouteMenusUK();
                    this.loadNewRouteMenus();
                }else{
                    this.loadNewRouteMenus();
                }
              //  this.loadmenus();
            } else {
                this.model = [
                    {
                        label: 'Dashboard', icon: 'fa fa-fw fa-dashboard', routerLink: ['/home']
                    },
                    {
                        label: 'Setup', icon: 'fa fa-fw fa-info-circle',
                        items: [
                            /* { label: 'View All Companies', icon: 'pi pi-fw pi-id-card', routerLink: ['/allcompanies'] }, */
                            { label: 'View All Contractors', icon: 'pi pi-fw pi-check-square', routerLink: ['/allcontractors'], state: { id: 'contractor', para_1: 'TM' } },
                            { label: 'View All Clients', icon: 'pi pi-fw pi-users', routerLink: ['/allclients'], state: { id: 'client', para_1: 'TM' } },
                            { label: 'View All Staff', icon: 'pi pi-fw pi-user', routerLink: ['/allstaffs'], state: { id: 'staff', para_1: 'TM' } },
                            { label: 'View All Depots', icon: 'pi pi-fw pi-user', routerLink: ['/alldepots'], state: { id: 'depot', para_1: 'TM' } },
                        ]
                    },
                    {
                        label: 'Rate Configuration', icon: 'pi pi-fw pi-money-bill',
                        items: [
                            { label: 'Ratesets', icon: 'pi pi-fw pi-id-card', routerLink: ['/ratesets'] },
                            { label: 'Rate Classes', icon: 'pi pi-fw pi-check-square', routerLink: ['/rateclasses'] },
                            { label: 'Item Types', icon: 'pi pi-fw pi-file-o', routerLink: ['/item-types'] },
                            { label: 'Item Definitions', icon: 'pi pi-fw pi-check-square', routerLink: ['/item-definations'] },
                            { label: 'View Item Rates', icon: 'pi pi-fw pi-tags', routerLink: ['/item-rates'] },
                            { label: 'Assign Rates to Companies', icon: 'pi pi-fw pi-money-bill', routerLink: ['/assign-rateset'] },
                            { label: 'Rate Card', icon: 'pi pi-fw pi-money-bill', routerLink: ['/rate-card'] }]
                    },

                    {
                        label: 'Compliance', icon: 'pi pi-fw pi-file-o',
                        items: [
                            { label: 'Compliance Types', icon: 'pi pi-fw pi-id-card', routerLink: ['/compliancetypes'] },
                            { label: 'Compliance Definitions', icon: 'pi pi-fw pi-check-square', routerLink: ['/compliancedefn'] },
                            { label: 'Compliance Levels', icon: 'pi pi-fw pi-directions', routerLink: ['/compliance-level'] },
                            { label: 'TP Compliance', icon: 'pi pi-fw pi-check-square', routerLink: ['/compliance-level'] },
                            { label: 'Compliance Approvals', icon: 'pi pi-fw pi-check-square' },

                        ]
                    },


                    {
                        label: 'Masters', icon: 'pi pi-fw pi-star',
                        items: [
                            { label: 'Job Requirements', icon: 'pi pi-fw pi-id-card', routerLink: ['/job-requirements'] },
                            { label: 'Job Priorities', icon: 'pi pi-fw pi-check-square', routerLink: ['/job-priorities'] },
                            { label: 'Job Actions', icon: 'pi pi-fw pi-check-square', routerLink: ['/job-actions'] },
                            { label: 'Job Events', icon: 'pi pi-fw pi-check-square', routerLink: ['/job-events'] },
                            { label: 'Customer Feedback', icon: 'pi pi-fw pi-check-square', routerLink: ['/kpi-measures'] },
                              { label: 'Value Set', icon: 'pi pi-fw pi-check-square',routerLink: ['/admin/valueset'] },
                            /* { label: 'Crew', icon: 'pi pi-fw pi-id-card',routerLink: ['/crew']  },
                             { label: 'Job Operations', icon: 'pi pi-fw pi-id-card',routerLink:['/Job-operations'] },
                             { label: 'Email Config', icon: 'pi pi-fw pi-id-card',routerLink: ['/email-config'] },
                             { label: 'Send Email', icon: 'pi pi-fw pi-id-card',routerLink: ['/send-email']  }, */

                        ]
                    },

                    {
                        label: 'Reports', icon: 'pi pi-fw pi-file',
                        items: [
                            {
                                label: 'More', items: [
                                    { label: 'Project' },
                                    { label: 'Other' },
                                ]
                            },
                            { label: 'TP Requesting Action', icon: 'pi pi-fw pi-id-card', routerLink: ['/reports/tp-requesting'] },
                            { label: 'TP Compliance Details', icon: 'pi pi-fw pi-id-card', routerLink: ['/reports/compliance-details'] },
                            { label: 'TP Staff Compliance Details', icon: 'pi pi-fw pi-id-card', routerLink: ['/reports/tp-staff'] },
                            { label: 'Company Notes Report', icon: 'pi pi-fw pi-id-card', routerLink: ['/reports/company-reports'] },
                            { label: 'Job Scheduling Details', icon: 'pi pi-fw pi-id-card', routerLink: ['/reports/job-scheduling'] },
                            { label: 'JLG Ops Report', icon: 'pi pi-fw pi-id-card', routerLink: ['/reports/jlg-ops-report'] },
                            { label: 'Quote Aging Details', icon: 'pi pi-fw pi-id-card', routerLink: ['/reports/quote-aging'] },
                            { label: 'Expired Compliance', icon: 'pi pi-fw pi-id-card', routerLink: ['/reports/expired-compliance'] },
                            { label: 'Missing Job Info', icon: 'pi pi-fw pi-id-card', routerLink: ['/reports/missing-job'] },
                            { label: 'Comms Report', icon: 'pi pi-fw pi-id-card', routerLink: ['/reports/comms-report'] },
                            { label: 'Email Delivery Report', icon: 'pi pi-fw pi-id-card', routerLink: ['/reports/email-delivery'] },
                            { label: 'APP Usage Statistics', icon: 'pi pi-fw pi-id-card', routerLink: ['/reports/app-usage'] },
                            { label: 'Clients Report', icon: 'pi pi-fw pi-sitemap', routerLink: ['/reports/clients-reports'] },
                            { label: 'Map-TP Location', icon: 'pi pi-fw pi-map-marker', routerLink: ['/reports/map-tp-location'] },
                            { label: 'Map-Job Location', icon: 'pi pi-fw pi-map-marker', routerLink: ['/reports/map-job-location'] },

                        ]
                    },

                    /* {
                        label: 'Accounts', icon: 'pi pi-fw pi-user',
                        items: [
                            { label: 'MYOB Export- WP', icon: 'pi pi-fw pi-id-card' },
                            { label: 'MYOB Export- TP', icon: 'pi pi-fw pi-id-card' },
                            { label: 'Quick Books Export- WP', icon: 'pi pi-fw pi-id-card',routerLink: ['/quick-books-wp'] },
                            { label: 'Quick Books Export- TP', icon: 'pi pi-fw pi-id-card',routerLink: ['/quick-books-tp'] },
                            { label: 'Quick Books Report', icon: 'pi pi-fw pi-id-card',routerLink: ['/quick-books-report'] },
                            { label: 'Close Paid Jobs', icon: 'pi pi-fw pi-id-card',routerLink: ['/close-paid-jobs'] },
                            { label: 'Invoiced - Not Paid', icon: 'pi pi-fw pi-id-card',routerLink: ['/invoice-not-paid'] },
                            { label: 'SES Report', icon: 'pi pi-fw pi-id-card',routerLink: ['/ses-report'] },
                            { label: 'Invoiced Not Sent', icon: 'pi pi-fw pi-id-card',routerLink: ['/invoice-not-sent'] },
     
                        ]
                    }, */

                    /*  {
                         label: 'Executive Reports', icon: 'pi pi-fw pi-star',
                         items: [
                             { label: 'APP Data Dictionary' },
                             { label: 'Task List', icon: 'pi pi-fw pi-id-card' },
                             { label: 'Job Margin Report', icon: 'pi pi-fw pi-id-card',routerLink: ['/job-margin'] },
                         ]
                     },
                     {
                         label: 'Register', icon: 'pi pi-fw pi-star',routerLink: ['/register']
                     } */


                    /*
                    {label: 'Dashboard', icon: 'pi pi-fw pi-home', routerLink: ['/']},            
                    {
                        label: 'UI Kit', icon: 'pi pi-fw pi-star', routerLink: ['/uikit'],
                        items: [
                            {label: 'Form Layout', icon: 'pi pi-fw pi-id-card', routerLink: ['/uikit/formlayout']},
                            {label: 'Input', icon: 'pi pi-fw pi-check-square', routerLink: ['/uikit/input']},
                            {label: 'Button', icon: 'pi pi-fw pi-mobile', routerLink: ['/uikit/button'], class: 'rotated-icon'},
                            {label: 'Table', icon: 'pi pi-fw pi-table', routerLink: ['/uikit/table']},
                            {label: 'List', icon: 'pi pi-fw pi-list', routerLink: ['/uikit/list']},
                            {label: 'Tree', icon: 'pi pi-fw pi-share-alt', routerLink: ['/uikit/tree']},
                            {label: 'Panel', icon: 'pi pi-fw pi-tablet', routerLink: ['/uikit/panel']},
                            {label: 'Overlay', icon: 'pi pi-fw pi-clone', routerLink: ['/uikit/overlay']},
                            {label: 'Media', icon: 'pi pi-fw pi-image', routerLink: ['/uikit/media']},
                            {label: 'Menu', icon: 'pi pi-fw pi-bars', routerLink: ['/uikit/menu']},
                            {label: 'Message', icon: 'pi pi-fw pi-comment', routerLink: ['/uikit/message']},
                            {label: 'File', icon: 'pi pi-fw pi-file', routerLink: ['/uikit/file']},
                            {label: 'Chart', icon: 'pi pi-fw pi-chart-bar', routerLink: ['/uikit/charts']},
                            {label: 'Misc', icon: 'pi pi-fw pi-circle-off', routerLink: ['/uikit/misc']}
                        ]
                    },
                    {
                        label: 'Utilities', icon: 'pi pi-fw pi-compass', routerLink: ['utilities'],
                        items: [
                            {label: 'Display', icon: 'pi pi-fw pi-desktop', routerLink: ['utilities/display']},
                            {label: 'Elevation', icon: 'pi pi-fw pi-external-link', routerLink: ['utilities/elevation']},
                            {label: 'FlexBox', icon: 'pi pi-fw pi-directions', routerLink: ['utilities/flexbox']},
                            {label: 'Icons', icon: 'pi pi-fw pi-search', routerLink: ['utilities/icons']},
                            {label: 'Text', icon: 'pi pi-fw pi-pencil', routerLink: ['utilities/text']},
                            {label: 'Widgets', icon: 'pi pi-fw pi-star-o', routerLink: ['utilities/widgets']},
                            {label: 'Grid System', icon: 'pi pi-fw pi-th-large', routerLink: ['utilities/grid']},
                            {label: 'Spacing', icon: 'pi pi-fw pi-arrow-right', routerLink: ['utilities/spacing']},
                            {label: 'Typography', icon: 'pi pi-fw pi-align-center', routerLink: ['utilities/typography']}
                        ]
                    },
                    {
                        label: 'Pages', icon: 'pi pi-fw pi-copy', routerLink: ['/pages'],
                        items: [
                            {label: 'Crud', icon: 'pi pi-fw pi-pencil', routerLink: ['/pages/crud']},
                            {label: 'Calendar', icon: 'pi pi-fw pi-calendar-plus', routerLink: ['/pages/calendar']},
                            { label: 'Landing', icon: 'pi pi-fw pi-globe', url: 'assets/pages/landing.html', target: '_blank' },
                            { label: 'Login', icon: 'pi pi-fw pi-sign-in', routerLink: ['/login'], target: '_blank' },
                            { label: 'Error', icon: 'pi pi-fw pi-exclamation-triangle', routerLink: ['/error'], target: '_blank' },
                            { label: '404', icon: 'pi pi-fw pi-times', routerLink: ['/404'], target: '_blank' },
                            {label: 'Access Denied', icon: 'pi pi-fw pi-ban',routerLink: ['/accessdenied'], target: '_blank'},
                            { label: 'Empty', icon: 'pi pi-fw pi-clone', routerLink: ['/pages/empty'] },
                        ]
                    },
                    */
                ];
            }
        }
    }
    onMenuClick() {
        this.app.menuClick = true;
    }

    loadNewRouteMenusUK() {
        this.model = [
            {
                label: 'Home', icon: 'fa fa-fw fa-dashboard', routerLink: ['/home']
            },
            {
                label: 'Job Operations', icon: 'pi pi-fw pi-id-card', routerLink: ['job/operations'], menuData: { id: 'TM', para_1: 'TM' },
            },
            {
                label: 'Account Details', icon: 'fa fa-fw fa-info-circle',
                items: [

                    { label: 'View All Contractors', icon: 'pi pi-fw pi-check-square', routerLink: ['/admin/contractors'], menuData: { id: 'TM', para_1: 'TM' } },
                    { label: 'View All Clients', icon: 'pi pi-fw pi-users', routerLink: ['/admin/clients'], menuData: { id: 'client', para_1: 'TM' } },
                    { label: 'View All Staff', icon: 'pi pi-fw pi-user', routerLink: ['/admin/staff'], menuData: { id: 'staff', para_1: 'TM' } },
                    { label: 'View All Depots', icon: 'pi pi-fw pi-user', routerLink: ['/admin/depots'], menuData: { id: 'depot', para_1: 'TM' } },
                    { label: 'Manage TP`s', icon: 'pi pi-fw pi-check-square', routerLink: ['/reports/onboarding-approval'], menuData: { id: 'TM', para_1: 'TM' } },
                ]
            },
            {
                label: 'Rate Configuration', icon: 'pi pi-fw pi-money-bill',
                items: [
                    { label: 'Ratesets', icon: 'pi pi-fw pi-id-card', routerLink: ['/setup/ratesets'], menuData: { id: 'TM', para_1: 'TM' } },
                    { label: 'Rate Classes', icon: 'pi pi-fw pi-check-square', routerLink: ['/setup/rateclass'], menuData: { id: 'TM', para_1: 'TM' } },
                    { label: 'Item Types', icon: 'pi pi-fw pi-file-o', routerLink: ['/setup/itemtypes'], menuData: { id: 'TM', para_1: 'TM' } },
                    { label: 'Item Definitions', icon: 'pi pi-fw pi-check-square', routerLink: ['/setup/itemdefn'], menuData: { id: 'TM', para_1: 'TM' } },
                    { label: 'View Item Rates', icon: 'pi pi-fw pi-tags', routerLink: ['/setup/itemrates'], menuData: { id: 'TM', para_1: 'TM' } },
                    { label: 'Assign Rates to Companies', icon: 'pi pi-fw pi-money-bill', routerLink: ['/assign-rateset'], menuData: { id: 'TM', para_1: 'TM' } },
                    { label: 'Rate Card', icon: 'pi pi-fw pi-money-bill', routerLink: ['/rate-card'], menuData: { id: 'TM', para_1: 'TM' } }]
            },

            {
                label: 'Compliance', icon: 'pi pi-fw pi-file-o',
                items: [
                    { label: 'Compliance Types', icon: 'pi pi-fw pi-id-card', routerLink: ['/compliance/types'], menuData: { id: 'TM', para_1: 'TM' } },
                    { label: 'Compliance Definitions', icon: 'pi pi-fw pi-check-square', routerLink: ['/compliance/defn'], menuData: { id: 'TM', para_1: 'TM' } },
                    { label: 'Compliance Levels', icon: 'pi pi-fw pi-directions', routerLink: ['/compliance/level'], menuData: { id: 'TM', para_1: 'TM' } },
                    { label: 'TP Compliance', icon: 'pi pi-fw pi-check-square', routerLink: ['/tp-compliance'], menuData: { id: 'TM', para_1: 'TM' } },
                    { label: 'Compliance Approvals', icon: 'pi pi-fw pi-check-square', routerLink: ['/compliance/approval'], menuData: { id: 'TM', para_1: 'TM' } },

                ]
            },


            {
                label: 'Masters', icon: 'pi pi-fw pi-star',
                items: [
                    { label: 'Job Requirements', icon: 'pi pi-fw pi-id-card', routerLink: ['/job-setup/requirements'], menuData: { id: 'TM', para_1: 'TM' } },
                    { label: 'Job Priorities', icon: 'pi pi-fw pi-check-square', routerLink: ['/job-setup/priorities'], menuData: { id: 'TM', para_1: 'TM' } },
                    { label: 'Job Actions', icon: 'pi pi-fw pi-check-square', routerLink: ['/job-setup/actions'], menuData: { id: 'TM', para_1: 'TM' } },
                    { label: 'Job Events', icon: 'pi pi-fw pi-check-square', routerLink: ['/job-setup/events'], menuData: { id: 'TM', para_1: 'TM' } },
                    { label: 'Customer Feedback', icon: 'pi pi-fw pi-check-square', routerLink: ['/job-setup/kpi'], menuData: { id: 'TM', para_1: 'TM' } },
                     { label: 'Value Set', icon: 'pi pi-fw pi-check-square',routerLink: ['/admin/valueset'] },
                   /*   { label: 'Crew', icon: 'pi pi-fw pi-id-card',routerLink: ['/crew']  },
                     { label: 'Job Operations', icon: 'pi pi-fw pi-id-card',routerLink:['/Job-operations'] },
                     { label: 'Email Config', icon: 'pi pi-fw pi-id-card',routerLink: ['/email-config'] },
                     { label: 'Send Email', icon: 'pi pi-fw pi-id-card',routerLink: ['/send-email']  }, */

                ]
            },
            {
                label: 'Reports', icon: 'pi pi-fw pi-file',
                items: [
                    { label: 'TP Requesting Action', icon: 'pi pi-fw pi-id-card', routerLink: ['/reports/tp-requesting'], menuData: { id: 'TM', para_1: 'TM' } },
                    { label: 'Company Notes Report', icon: 'pi pi-fw pi-id-card', routerLink: ['/reports/company-reports'], menuData: { id: 'TM', para_1: 'TM' } },
                    { label: 'Quote Aging Details', icon: 'pi pi-fw pi-id-card', routerLink: ['/reports/quote-aging'], menuData: { id: 'TM', para_1: 'TM' } },
                    { label: 'Expired Compliance', icon: 'pi pi-fw pi-id-card', routerLink: ['/reports/expired-compliance'], menuData: { id: 'TM', para_1: 'TM' } },
                    { label: 'Missing Job Info', icon: 'pi pi-fw pi-id-card', routerLink: ['/reports/missing-job'], menuData: { id: 'TM', para_1: 'TM' } },
                    { label: 'Comms Report', icon: 'pi pi-fw pi-id-card', routerLink: ['/reports/comms-report'], menuData: { id: 'TM', para_1: 'TM' } },
                    { label: 'Email Delivery Report', icon: 'pi pi-fw pi-id-card', routerLink: ['/reports/email-delivery'],menuData: { id: 'TM', para_1: 'TM' }  },
                    { label: 'APP Usage Statistics', icon: 'pi pi-fw pi-id-card', routerLink: ['/reports/app-usage'], menuData: { id: 'TM', para_1: 'TM' } },
                    { label: 'Clients Report', icon: 'pi pi-fw pi-sitemap', routerLink: ['/reports/clients-reports'], menuData: { id: 'TM', para_1: 'TM' } },
                    { label: 'Map-TP Location', icon: 'pi pi-fw pi-map-marker', routerLink: ['/reports/map-tp-location'], menuData: { id: 'TM', para_1: 'TM' } },
                    { label: 'Map-Job Location', icon: 'pi pi-fw pi-map-marker', routerLink: ['/reports/map-job-location'], menuData: { id: 'TM', para_1: 'TM' } },

                ]
            },

            {
                label: 'Accounts', icon: 'pi pi-fw pi-user',
                items: [

                    { label: 'Quick Books Export- WP', icon: 'pi pi-fw pi-id-card', routerLink: ['/accounts/quick-books-wp'], menuData: { id: 'TM', para_1: 'TM' } },
                    { label: 'Quick Books Export- TP', icon: 'pi pi-fw pi-id-card', routerLink: ['/accounts/quick-books-tp'], menuData: { id: 'TM', para_1: 'TM' } },
                    { label: 'Quick Books Report', icon: 'pi pi-fw pi-id-card', routerLink: ['/accounts/quick-books-report'], menuData: { id: 'TM', para_1: 'TM' } },
                    { label: 'Close Paid Jobs', icon: 'pi pi-fw pi-id-card', routerLink: ['/accounts/close-paid-jobs'], menuData: { id: 'TM', para_1: 'TM' } },
                    { label: 'Invoiced - Not Paid', icon: 'pi pi-fw pi-id-card', routerLink: ['/accounts/invoice-not-paid'], menuData: { id: 'TM', para_1: 'TM' } },
                    { label: 'Invoiced Not Sent', icon: 'pi pi-fw pi-id-card', routerLink: ['/accounts/invoice-not-sent'], menuData: { id: 'TM', para_1: 'TM' } },

                ]
            },

            {
                label: 'Executive Reports', icon: 'pi pi-fw pi-star',
                items: [
                    { label: 'Job Margin Report', icon: 'pi pi-fw pi-id-card', routerLink: ['/reports/job-margin'], menuData: { id: 'TM', para_1: 'TM' } },
                    { label: 'Onboarding Approval', icon: 'pi pi-fw pi-id-card', routerLink: ['/reports/onboarding-approval'], menuData: { id: 'TM', para_1: 'TM' } },
                    { label: 'BI - Job Report(Count)', icon: 'pi pi-fw pi-map-marker', routerLink: ['/analytics/report1'], menuData: { id: 'TM', para_1: '49bc61da-ea25-4909-ba4a-997346a7f123' } },
                    { label: 'BI - Job Report(Value)', icon: 'pi pi-fw pi-map-marker', routerLink: ['/analytics/report1'], menuData: { id: 'TM', para_1: 'e8740ec3-e2e3-49dd-9a7f-e45e564fdb96' } },
                    { label: 'BI Reports Setup', icon: 'pi pi-fw pi-id-card', routerLink: ['/admin/master'], menuData: { para_1: 'bireports' } },
                 ]
            },
            {
                label: 'Tasks', icon: 'pi pi-fw pi-id-card', routerLink: ['allincidents'], menuData: { id: 'TM', para_1: '1', para_2: 'admin' },
            },
            {
                label: 'Job tasks', icon: 'pi pi-fw pi-id-card', routerLink: ['jobsincidents'], menuData: { id: 'TM', para_1: '7', para_2: 'admin' },
            },
        ];
    }
    loadNewRouteMenus() {

        //     this.model = [
        //     {
        //         label: 'Home', icon: 'fa fa-fw fa-dashboard', routerLink: ['/home']
        //     },
        //     {
        //         label: 'Job Operations', icon: 'pi pi-fw pi-id-card', routerLink: ['job/operations'], menuData: { id: 'TM', para_1: 'TM' },
        //     },
        //     {
        //         label: 'Account Details', icon: 'fa fa-fw fa-info-circle',
        //         items: [

        //             { label: 'View All Contractors', icon: 'pi pi-fw pi-check-square', routerLink: ['/admin/contractors'], menuData: { id: 'TM', para_1: 'TM' } },
        //             { label: 'View All Clients', icon: 'pi pi-fw pi-users', routerLink: ['/admin/clients'], menuData: { id: 'client', para_1: 'TM' } },
        //             { label: 'View All Staff', icon: 'pi pi-fw pi-user', routerLink: ['/admin/staff'], menuData: { id: 'staff', para_1: 'TM' } },
        //             { label: 'View All Depots', icon: 'pi pi-fw pi-user', routerLink: ['/admin/depots'], menuData: { id: 'depot', para_1: 'TM' } },
        //             { label: 'Manage TP`s', icon: 'pi pi-fw pi-check-square', routerLink: ['/reports/onboarding-approval'], menuData: { id: 'TM', para_1: 'TM' } },
        //         ]
        //     },
        //     {
        //         label: 'Rate Configuration', icon: 'pi pi-fw pi-money-bill',
        //         items: [
        //             { label: 'Ratesets', icon: 'pi pi-fw pi-id-card', routerLink: ['/setup/ratesets'], menuData: { id: 'TM', para_1: 'TM' } },
        //             { label: 'Rate Classes', icon: 'pi pi-fw pi-check-square', routerLink: ['/setup/rateclass'], menuData: { id: 'TM', para_1: 'TM' } },
        //             { label: 'Item Types', icon: 'pi pi-fw pi-file-o', routerLink: ['/setup/itemtypes'], menuData: { id: 'TM', para_1: 'TM' } },
        //             { label: 'Item Definitions', icon: 'pi pi-fw pi-check-square', routerLink: ['/setup/itemdefn'], menuData: { id: 'TM', para_1: 'TM' } },
        //             { label: 'View Item Rates', icon: 'pi pi-fw pi-tags', routerLink: ['/setup/itemrates'], menuData: { id: 'TM', para_1: 'TM' } },
        //             { label: 'Assign Rates to Companies', icon: 'pi pi-fw pi-money-bill', routerLink: ['/assign-rateset'], menuData: { id: 'TM', para_1: 'TM' } },
        //             { label: 'Rate Card', icon: 'pi pi-fw pi-money-bill', routerLink: ['/rate-card'], menuData: { id: 'TM', para_1: 'TM' } }]
        //     },

        //     {
        //         label: 'Compliance', icon: 'pi pi-fw pi-file-o',
        //         items: [
        //             { label: 'Compliance Types', icon: 'pi pi-fw pi-id-card', routerLink: ['/compliance/types'], menuData: { id: 'TM', para_1: 'TM' } },
        //             { label: 'Compliance Definitions', icon: 'pi pi-fw pi-check-square', routerLink: ['/compliance/defn'], menuData: { id: 'TM', para_1: 'TM' } },
        //             { label: 'Compliance Levels', icon: 'pi pi-fw pi-directions', routerLink: ['/compliance/level'], menuData: { id: 'TM', para_1: 'TM' } },
        //             { label: 'TP Compliance', icon: 'pi pi-fw pi-check-square', routerLink: ['/tp-compliance'], menuData: { id: 'TM', para_1: 'TM' } },
        //             { label: 'Compliance Approvals', icon: 'pi pi-fw pi-check-square', routerLink: ['/compliance/approval'], menuData: { id: 'TM', para_1: 'TM' } },
        //             { label: 'Approved Compliances', icon: 'pi pi-fw pi-check-square', routerLink: ['/compliance/all'], menuData: { id: 'TM', para_1: 'TM' } },

        //         ]
        //     },


        //     {
        //         label: 'Masters', icon: 'pi pi-fw pi-star',
        //         items: [
        //             { label: 'Job Requirements', icon: 'pi pi-fw pi-id-card', routerLink: ['/job-setup/requirements'], menuData: { id: 'TM', para_1: 'TM' } },
        //             { label: 'Job Priorities', icon: 'pi pi-fw pi-check-square', routerLink: ['/job-setup/priorities'], menuData: { id: 'TM', para_1: 'TM' } },
        //             { label: 'Job Actions', icon: 'pi pi-fw pi-check-square', routerLink: ['/job-setup/actions'], menuData: { id: 'TM', para_1: 'TM' } },
        //             { label: 'Job Events', icon: 'pi pi-fw pi-check-square', routerLink: ['/job-setup/events'], menuData: { id: 'TM', para_1: 'TM' } },
        //             { label: 'Customer Feedback', icon: 'pi pi-fw pi-check-square', routerLink: ['/job-setup/kpi'], menuData: { id: 'TM', para_1: 'TM' } },
        //             { label: 'Value Set', icon: 'pi pi-fw pi-check-square',routerLink: ['/admin/valueset'] },
        //           /*     { label: 'Crew', icon: 'pi pi-fw pi-id-card',routerLink: ['/crew']  },
        //              { label: 'Job Operations', icon: 'pi pi-fw pi-id-card',routerLink:['/Job-operations'] },
        //              { label: 'Email Config', icon: 'pi pi-fw pi-id-card',routerLink: ['/email-config'] },
        //              { label: 'Send Email', icon: 'pi pi-fw pi-id-card',routerLink: ['/send-email']  }, */

        //         ]
        //     },

        //     {
        //         label: 'Reports', icon: 'pi pi-fw pi-file',
        //         items: [
        //             { label: 'TP Requesting Action', icon: 'pi pi-fw pi-id-card', routerLink: ['/reports/tp-requesting'], menuData: { id: 'TM', para_1: 'TM' } },
        //             { label: 'TP Compliance Details', icon: 'pi pi-fw pi-id-card', routerLink: ['/reports/compliance-details'], menuData: { id: 'TM', para_1: 'TM' } },
        //             { label: 'TP Staff Compliance Details', icon: 'pi pi-fw pi-id-card', routerLink: ['/reports/tp-staff'], menuData: { id: 'TM', para_1: 'TM' } },
        //             { label: 'Company Notes Report', icon: 'pi pi-fw pi-id-card', routerLink: ['/reports/company'], menuData: { id: 'TM', para_1: 'TM' } },
        //             { label: 'Job Scheduling Details', icon: 'pi pi-fw pi-id-card', routerLink: ['/reports/job-scheduling'], menuData: { id: 'TM', para_1: 'TM' } },
        //             { label: 'JLG Ops Report', icon: 'pi pi-fw pi-id-card', routerLink: ['/reports/jlg-ops'], menuData: { id: 'TM', para_1: 'TM' } },
        //             { label: 'Quote Aging Details', icon: 'pi pi-fw pi-id-card', routerLink: ['/reports/quote-aging'], menuData: { id: 'TM', para_1: 'TM' } },
        //             { label: 'Expired Compliance', icon: 'pi pi-fw pi-id-card', routerLink: ['/reports/expired-compliance'], menuData: { id: 'TM', para_1: 'TM' } },
        //             { label: 'Missing Job Info', icon: 'pi pi-fw pi-id-card', routerLink: ['/reports/missing-job'], menuData: { id: 'TM', para_1: 'TM' } },
        //             { label: 'Comms Report', icon: 'pi pi-fw pi-id-card', routerLink: ['/reports/comms-report'], menuData: { id: 'TM', para_1: 'TM' } },
        //             { label: 'Email Delivery Report', icon: 'pi pi-fw pi-id-card', routerLink: ['/reports/email-delivery'], menuData: { id: 'TM', para_1: 'TM' } },
        //             { label: 'APP Usage Statistics', icon: 'pi pi-fw pi-id-card', routerLink: ['/reports/app-usage'], menuData: { id: 'TM', para_1: 'TM' } },
        //             { label: 'Clients Report', icon: 'pi pi-fw pi-sitemap', routerLink: ['/reports/clients-reports'], menuData: { id: 'TM', para_1: 'TM' } },
        //             { label: 'Map-TP Location', icon: 'pi pi-fw pi-map-marker', routerLink: ['/reports/map-tp-location'], menuData: { id: 'TM', para_1: 'TM' } },
        //             { label: 'Map-Job Location', icon: 'pi pi-fw pi-map-marker', routerLink: ['/reports/map-job-location'], menuData: { id: 'TM', para_1: 'TM' } },
        //             { label: 'Power BI Reports', icon: 'pi pi-fw pi-id-card', routerLink: ['/analytics/report1'], menuData: { id: 'TM', para_1: 'Analytics' } },

        //         ]
        //     },

        //     {
        //         label: 'Accounts', icon: 'pi pi-fw pi-user',
        //         items: [
        //             { label: 'MYOB Export- WP', icon: 'pi pi-fw pi-id-card' },
        //             { label: 'MYOB Export- TP', icon: 'pi pi-fw pi-id-card' },
        //             { label: 'Quick Books Export- WP', icon: 'pi pi-fw pi-id-card', routerLink: ['/accounts/quick-books-wp'], menuData: { id: 'TM', para_1: 'TM' } },
        //             { label: 'Quick Books Export- TP', icon: 'pi pi-fw pi-id-card', routerLink: ['/accounts/quick-books-tp'], menuData: { id: 'TM', para_1: 'TM' } },
        //             { label: 'Quick Books Report', icon: 'pi pi-fw pi-id-card', routerLink: ['/accounts/quick-books-report'], menuData: { id: 'TM', para_1: 'TM' } },
        //             { label: 'Close Paid Jobs', icon: 'pi pi-fw pi-id-card', routerLink: ['/accounts/close-paid-jobs'], menuData: { id: 'TM', para_1: 'TM' } },
        //             { label: 'Invoiced - Not Paid', icon: 'pi pi-fw pi-id-card', routerLink: ['/accounts/invoice-not-paid'], menuData: { id: 'TM', para_1: 'TM' } },
        //             { label: 'SES Report', icon: 'pi pi-fw pi-id-card', routerLink: ['/accounts/ses-report'], menuData: { id: 'TM', para_1: 'TM' } },
        //             { label: 'Invoiced Not Sent', icon: 'pi pi-fw pi-id-card', routerLink: ['/accounts/invoice-not-sent'], menuData: { id: 'TM', para_1: 'TM' } },

        //         ]
        //     },

        //     {
        //         label: 'Executive Reports', icon: 'pi pi-fw pi-star',
        //         items: [
        //             { label: 'Job Margin Report', icon: 'pi pi-fw pi-id-card', routerLink: ['/reports/job-margin'], menuData: { id: 'TM', para_1: 'TM' } },
        //             { label: 'Onboarding Approval', icon: 'pi pi-fw pi-id-card', routerLink: ['/reports/onboarding-approval'], menuData: { id: 'TM', para_1: 'TM' } },
        //             { label: 'BI - Job Report(Count)', icon: 'pi pi-fw pi-map-marker', routerLink: ['/analytics/report1'], menuData: { id: 'TM', para_1: '49bc61da-ea25-4909-ba4a-997346a7f123' } },
        //             { label: 'BI - Job Report(Value)', icon: 'pi pi-fw pi-map-marker', routerLink: ['/analytics/report1'], menuData: { id: 'TM', para_1: 'e8740ec3-e2e3-49dd-9a7f-e45e564fdb96' } },
        //         ]
        //     },
        //     {
        //         label: 'Tasks', icon: 'pi pi-fw pi-id-card', routerLink: ['allincidents'], menuData: { id: 'TM', para_1: '1', para_2: 'admin' },
        //     },
        //     {
        //         label: 'Job tasks', icon: 'pi pi-fw pi-id-card', routerLink: ['jobsincidents'], menuData: { id: 'TM', para_1: '7', para_2: 'admin' },
        //     },
        //     {
        //         label: 'New Masters', icon: 'pi pi-fw pi-star',
        //         items: [
        //             { label: 'Components Defn Head', icon: 'pi pi-fw pi-check-square', routerLink: ['admin/defnhead'], menuData: { para_1: 'ComponentDefnHead' } },
        //             { label: 'Hooks', icon: 'pi pi-fw pi-id-card', routerLink: ['/admin/master'], menuData: { para_1: 'hooks' } },
        //             { label: 'Steps', icon: 'pi pi-fw pi-id-card', routerLink: ['/admin/master'], menuData: { para_1: 'wfsteps' } },
        //             { label: 'Notification Preference', icon: 'pi pi-fw pi-id-card', routerLink: ['/admin/master'], menuData: { para_1: 'CompanyNotificationPreference' } },
        //             { label: 'Workflow Categories', icon: 'pi pi-fw pi-id-card', routerLink: ['/admin/master'], menuData: { para_1: 'wfcategories' } },
        //             { label: 'BI Reports Setup', icon: 'pi pi-fw pi-id-card', routerLink: ['/admin/master'], menuData: { para_1: 'bireports' } },
        //             { label: 'Configs', icon: 'pi pi-fw pi-check-square', routerLink: ['/admin/master'], menuData: { para_1: 'AppConfig' } },
        //             { label: 'Workflow Defenition', icon: 'pi pi-fw pi-check-square', routerLink: ['/admin/master'], menuData: { para_1: 'WFDefn' } },
        //             { label: 'Workflow Steps', icon: 'pi pi-fw pi-check-square', routerLink: ['/admin/master'], menuData: { para_1: 'WFDefnSteps' } },
        //             { label: 'Workflow Triggers', icon: 'pi pi-fw pi-check-square', routerLink: ['/admin/master'], menuData: { para_1: 'WFDefnTriggers' } },
        //             { label: 'New Map - TP Location', icon: 'pi pi-fw pi-map-marker', routerLink: ['/reports/tp-locations'], menuData: { id: 'TM', para_1: 'TM' } },
        //             { label: 'New Map - Job Location', icon: 'pi pi-fw pi-map-marker', routerLink: ['/reports/job-locations'], menuData: { id: 'TM', para_1: 'TM' } },
        //         ]
        //     },
        //     {
        //         label: 'Setup', icon: 'pi pi-fw pi-star',
        //         items: [
        //             { label: 'Valueset', icon: 'pi pi-fw pi-check-square', routerLink: ['/admin/master'], menuData: { para_1: 'Valueset' } },
        //             { label: 'Valueset Detail', icon: 'pi pi-fw pi-check-square', routerLink: ['/admin/master'], menuData: { para_1: 'Valuesetdetail' } },
        //             { label: 'Comms Categories', icon: 'pi pi-fw pi-check-square', routerLink: ['/admin/master'], menuData: { para_1: 'CommsCategories' } },
        //             { label: 'AppContact Text Matrix', icon: 'pi pi-fw pi-check-square', routerLink: ['/admin/master'], menuData: { para_1: 'AppContactTextMatrix' } },
        //             { label: 'Auto ApprovalMatrix', icon: 'pi pi-fw pi-check-square', routerLink: ['/admin/master'], menuData: { para_1: 'AutoApprovalMatrix' } },
        //             { label: 'Menus', icon: 'pi pi-fw pi-check-square', routerLink: ['/admin/master'], menuData: { para_1: 'menus' } },
        //             { label: 'MenuPermissions', icon: 'pi pi-fw pi-check-square', routerLink: ['/admin/master'], menuData: { para_1: 'MenuPermissions' } },
        //             { label: 'Company Notifcation', icon: 'pi pi-fw pi-check-square', routerLink: ['/admin/master'], menuData: { para_1: 'CompanyNotifcationPreference' } },
        //             { label: 'WF Notification Config', icon: 'pi pi-fw pi-check-square', routerLink: ['/admin/master'], menuData: { para_1: 'WFNotificationConfig' } },
        //             { label: 'Workflow_head', icon: 'pi pi-fw pi-check-square', routerLink: ['/admin/master'], menuData: { para_1: 'workflow_head' } },
        //             { label: 'Workflow_detail', icon: 'pi pi-fw pi-check-square', routerLink: ['/admin/master'], menuData: { para_1: 'workflow_detail' } },
        //             { label: 'Workflow_company_params', icon: 'pi pi-fw pi-check-square', routerLink: ['/admin/master'], menuData: { para_1: 'workflow_company_params' } },
        //             { label: 'JobChecklistConfig', icon: 'pi pi-fw pi-check-square', routerLink: ['/admin/master'], menuData: { para_1: 'JobChecklistConfig' } },
        //             { label: 'TPMailingListView', icon: 'pi pi-fw pi-check-square', routerLink: ['/admin/master'], menuData: { para_1: 'TPMailingListView' } },
        //             { label: 'Compliance Approval', icon: 'pi pi-fw pi-check-square', routerLink: ['/reports/onboarding-compliance-approval'], menuData: { id: 'TM', para_1: 'TM' } },
        //         ]
        //     },
        // ];
    
        this.crudService.getMenusByUserRoleID(this.userDetails.user_group_id).subscribe((data: any[]) => {
            console.log("getusergrop",data);
            data.sort((a, b) => a.menu_order_id - b.menu_order_id);
             this.model = []
             if (this.userDetails.homepage_url !== null && this.userDetails.homepage_url !== '') {
                this.model.push({ label: 'Home', icon: 'fa fa-fw fa-home', routerLink: [this.userDetails.homepage_url] });
            } else {
                this.model.push({ label: 'Home', icon: 'fa fa-fw fa-dashboard', routerLink: ['/home'] });
            }
            data = data.filter(val => val.status !== '2');
            let parentMenus = data.filter(val => val.menu_parent_id === '0');
            parentMenus.forEach(pmitem => {
                let childMenus = data.filter(val => val.menu_parent_id === pmitem.menu_id);
                if (childMenus.length > 0) {
                    let items: any[] = [];
                    childMenus.forEach(item => {
                        if (item.router_url != null) {
                            items.push({ label: item.display_name, icon: item.icon, routerLink: [item.router_url], menuData: item });
                        } else {
                            items.push({ label: item.display_name, icon: item.icon, routerLink: ['/coming-soon'], menuData: item });
                        }
                    });
                    this.model.push({menu_id: Number(pmitem.menu_id),menu_order_id:Number(pmitem.menu_order_id),  label: pmitem.display_name, icon: pmitem.icon, items });
                } else {
                    if (pmitem.router_url != null) {
                        this.model.push({
                            menu_id:Number(pmitem.menu_id),menu_order_id:Number(pmitem.menu_order_id),  label: pmitem.display_name, icon: pmitem.icon, routerLink: [pmitem.router_url], menuData: pmitem
                        });
                    } else {
                        this.model.push({  menu_id:Number(pmitem.menu_id),menu_order_id:Number(pmitem.menu_order_id),  label: pmitem.display_name, icon: pmitem.icon, routerLink: ['/Coming-soon'], menuData: pmitem });
                    }
                }
            });
            this.model.sort((a, b) => a.menu_order_id - b.menu_order_id); 
            console.log("this.model",this.model);
            
          })
     
    }
}
function sortBy(arg0: string, userMenus: any[]) {
    throw new Error('Function not implemented.');
}

