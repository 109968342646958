import { Component, OnInit, Input, OnDestroy } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { ConfirmationService, MessageService } from 'primeng/api';
import { DialogService, DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { CRUDService } from 'src/app/utilities/crud.service';
import { JOBService } from 'src/app/utilities/job.service';
import { delay } from 'rxjs/operators';
import { CompressImageService } from 'src/app/utilities/compress.image.service';
import { take } from 'rxjs/operators';
import { WorkFlowService } from 'src/app/utilities/workflow.service';
import { NgxImageCompressService } from 'ngx-image-compress';
import { environment } from 'src/environments/environment';
import { CommonService } from 'src/app/utilities/common.service';
import { DomSanitizer } from '@angular/platform-browser';
import { EncrDecrService } from 'src/app/utilities/encr-decr.service'

@Component({
  selector: 'app-send-quote',
  templateUrl: './send-quote.component.html',
  styleUrls: ['./send-quote.component.scss'],
  providers: [NgxImageCompressService]
})
export class SendQuoteComponent implements OnInit {
  @Input() jobId: any
  @Input() jobAllocationId: any;
  @Input() jobAllocationDetails: any;
  @Input() jobSummary: any;
  ifCc: any = false;
  ifBCc: any = false;
  displayText: any;
  currentUser: any = [];
  invreportId: any;
  quoreportId: any;
  attachmentList = [];
  promiseList: any = [];
  promiseListCompress: any[] = [];
  units = ['bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
  promiseListDelete: any = [];
  imgList: any;
  imgPdfUrl: any='';
  costingDetails: any;
  // this.display_exists=false;
  invPdfUrl: any='';
  quoPdfUrl: any='';
  wpquoPdfUrl: any='';
  wpinvPdfUrl: any='';
  documentList: any;
  dummy: any[] = [];
  documentsInQuote: any = '';
  invSelectAttach = [{ value: 'individual_images', name: 'Individual Images' },
  { value: 'image_report', name: 'Image Report' },
  { value: 'documents', name: 'Documents' },
  { value: 'wpInvoice', name: 'WP Invoice' }]
  quoSelectAttach = [{ value: 'individual_images', name: 'Individual Images' },
  { value: 'image_report', name: 'Image Report' },
  { value: 'documents', name: 'Documents' },
  { value: 'wpQuote', name: 'WP Cost/Quote' }]

  subinvSelectAttach = [{ value: 'documents', name: 'Documents' },
  { value: 'wpInvoice', name: 'WP Invoice' }]

  subquoSelectAttach = [
  { value: 'documents', name: 'Documents' },
  { value: 'wpQuote', name: 'WP Cost/Quote' }]
  
  EmailAccount: any;
  EnvAddressLine1: any;
  EnvAddressLine2: any;
  EnvState: any;
  EnvCountry: any;
  EnvPhone: any;
  EnvFax: any;
  EnvEmail: any;
  EnvZip: any;
  beforeImgSize: any = 0;
  afterImgSize: any = 0;
  pics: any = [];
  replyTo: any;
  emailContent: any;
  emailSubject: any;
  contactNo: any;
  templ: any;
  totalSize: any = 0;
  ccEmail: any[] = [];
  bccEmail: any[] = [];
  toEmail: any[] = [];
  itemType: any;
  emailAttachment: any[];
  selectedEmailAttachment: any = [];
  attachments: any = '';
  invReplyTo: any;
  QuotReplyTo: any;
  accounttype: any;
  job_claim_no: any;
  client_name: any;
  location: any;
  emailArray: any;
  userDetails: any;
  companyId: any;
  documentData: any[] = [];
  imgDataList: any[] = [];
  imgPdfUrlData: any;
  invPdfUrlData: any;
  quoPdfUrlData: any;
  wpinvPdfUrlData: any;
  wpquoPdfUrlData: any;
  apiRoot: any;
  EnvABNName: any;
  EnvWebsite: any;
  mailOptions: string[] = [];
  loading = false;
  mailFieldValue = '';
  ccMailFieldValue = '';
  bccMailFieldValue = '';
  invoiceStages: any[] = ['Approve Invoice', 'Invoice Processed', 'Account Processed'];
  jobInfo: any;
  ref_type : any;
  showHeader: Boolean = false;
  emailsList :any;
  testimglist: any;
  uploadimgDataList: any;
  uploadAllocationList: any;
  allocationdisable: boolean;
  imageDocuments: any=[];
  types = [
    { type: 'Before', id: '1' },
    { type: 'Hazard', id: '3' },
    { type: 'After', id: '2' }
  ];
  imagecontent: any='';
  scheduleId : any;
  params : any;
  selectedAttachmentformat: any;
  attachmentUrl: string;
  send_email_attachment: any = 0;
  jobActions: any;
  jobReqAction: any;

  constructor(
    private route: ActivatedRoute,
    private crudService: CRUDService,
    private messageService: MessageService,
    private confirmationService: ConfirmationService,
    private router: Router,
    public ref: DynamicDialogRef,
    public config: DynamicDialogConfig,
    public dialogService: DialogService,
    public JobService: JOBService,
    private compressImage: CompressImageService,
    private workFlowService: WorkFlowService,
    private imageCompress: NgxImageCompressService,
    private commonservice: CommonService,
    public sanitizer: DomSanitizer,
    private EncrDecrService : EncrDecrService,
    
  ) {
    this.companyId = sessionStorage.getItem('company_id');
    this.userDetails = JSON.parse(sessionStorage.getItem('loggedInUser'));
    this.currentUser = JSON.parse(sessionStorage.getItem('currentUser'));
    //console.log('userinfo', this.userDetails);
    this.emailAttachment = [
      { value: 'individual_images', name: 'Individual Images' },
      { value: 'image_report', name: 'Image Report' },
      { value: 'documents', name: 'Documents' },
      { value: 'wpQuote', name: 'WP Cost/Quote' },
      { value: 'wpInvoice', name: 'WP Invoice' },
      { value: 'N/A', name: 'N/A' },
    ]
  }

  env: any;

  async ngOnInit() {

    
    this.env = environment;
    this.emailsList = await this.commonservice.getMailerlist();
    if (typeof this.jobId === 'undefined') {
      if (typeof this.config.data == 'undefined' || typeof (this.config.data.jobId) == 'undefined') {
        if (this.jobId == null || this.jobId === '') {
          this.jobId = parseInt(this.route.snapshot.paramMap.get('jobId'));
          if (this.jobAllocationId === null || this.jobAllocationId === '') {
            this.jobAllocationId = '1';
          } else {
            this.jobAllocationId = parseInt(this.route.snapshot.paramMap.get('jobAllocationId'));
            this.jobAllocationDetails = await this.crudService.getDataByField('JobInfo', 'id', this.jobId);
            this.jobSummary = this.jobAllocationDetails;
            //console.log('this.jobSummary',this.jobSummary)
          }
        }
        this.showHeader = false;
      } else {
        this.jobId = this.config.data.jobId;
        this.jobAllocationId = this.config.data.jobAllocationId;
        this.jobAllocationDetails = this.config.data.jobSummary;
        this.jobSummary = this.config.data.jobSummary;
        //console.log('this.jobSummary22',this.jobSummary)
        this.showHeader = true;
      }
    }
     let allocData: any = await this.crudService.getDataByField('JobAllocation', 'id', this.jobAllocationId).toPromise();
     const access_token_temp = allocData[0].accesstoken;
      if(access_token_temp == null || access_token_temp == '' || access_token_temp == undefined)
      {
        const  access_token_new = await this.generateRandom(9)
          allocData[0].accesstoken = access_token_new;
        this.crudService.Update(allocData[0], 'JobAllocation').subscribe(async (data: any) => {   
          this.jobAllocationDetails.JobAllocDetails.accesstoken = access_token_new;
          });
      }
      else
      {
        this.jobAllocationDetails.JobAllocDetails.accesstoken = access_token_temp;
      }
    this.crudService.getDataByField('businessSettings', 'company_id', '108').subscribe((data: any[]) => {
      this.send_email_attachment = data[0].send_email_attachment;
      console.log("this.send_email_attachment",this.send_email_attachment);
      
      });
     
    let JobData: any = await this.crudService.getDataByField('JobInfo', 'id', this.jobId).toPromise();
    this.jobInfo = JobData[0];
   // console.log(' this.jobInfo222', this.jobInfo)
    this.job_claim_no = this.jobAllocationDetails.info.job_claim_no ? this.jobAllocationDetails.info.job_claim_no : 'N/A'
    this.client_name = this.jobAllocationDetails.info.ClientName ? this.jobAllocationDetails.info.ClientName : 'N/A'
    this.location = this.jobAllocationDetails.info.location ? this.jobAllocationDetails.info.location : 'N/A'
    this.contactNo = this.jobAllocationDetails.info.contact ? this.jobAllocationDetails.info.contact : 'N/A'
    this.emailArray = this.jobAllocationDetails.info.Email.split(';');
    if(this.env.hosting != 'UK'){
    	if (this.jobAllocationDetails.location.includes('New Zealand')) {
      		this.accounttype = true;
    	}
    }else{
    	this.accounttype = false;
    }
    if (this.accounttype) {
      this.EmailAccount = 'NZ';
    } else {
      this.EmailAccount = 'AU';
    }

    let QuotReplyTo = null;
    let invReplyTo = null;
    let EnvWebsite = null;
    let EnvABNName = null;
    let EnvAddressLine1 = null;
    let EnvAddressLine2 = null;
    let EnvState = null;
    let EnvCountry = null;
    let EnvPhone = null;
    let EnvFax = null;
    let EnvZip = null;

    if ((this.jobAllocationDetails.JobStatus == 'Invoice Processed')
      || (this.jobAllocationDetails.JobStatus == 'Accounts Processed')
      || (this.jobAllocationDetails.JobStatus == 'Approve Invoice')) {
      this.displayText = 'Quoted Value: $' + this.jobAllocationDetails.info.WPQuoted + ', Invoice Number: ' + this.jobAllocationDetails.info.WPInvoiced;
    }
    if ((this.jobAllocationDetails.JobStatus == 'Approve Quote') || (this.jobAllocationDetails.JobStatus == 'Await Invoice') || (this.jobAllocationDetails.JobStatus == 'Cost Saved')) {
      this.displayText = 'Quoted Value: $' + this.jobAllocationDetails.info.WPQuoted;
    }
    if(this.env.hosting != 'UK'){

    if (this.EmailAccount === 'AU') {
      invReplyTo = 'accounts@envirofrontier.com.au';
      if (this.jobAllocationDetails.WPCompanyId === '1356') {
        QuotReplyTo = 'iag@envirofrontier.com.au';
      } else {
        QuotReplyTo = 'makesafe@envirofrontier.com.au';
      }
      EnvWebsite = 'www.envirofrontier.com.au';
      // let EmailAccount=null;
      EnvAddressLine1 = 'P.O. Box 617';
      EnvAddressLine2 = 'Narrabeen';
      EnvState = 'NSW';
      EnvCountry = 'Australia';
      EnvPhone = '1300 077 233';
      EnvFax = '1300 077 446';
      EnvABNName = 'ABN';
      EnvZip = '2106';
    }
    else if (this.EmailAccount === 'NZ') {
      invReplyTo = 'accounts@envirofrontier.co.nz';
      QuotReplyTo = 'makesafe@envirofrontier.co.nz';
      EnvWebsite = 'www.envirofrontier.co.nz';
      EnvAddressLine1 = '68 Russell Street';
      EnvAddressLine2 = 'Westport';
      EnvState = 'NZ3';
      EnvCountry = 'New Zealand';
      EnvPhone = '+64 800004827';
      EnvFax = '+64 800004827';
      EnvABNName = 'NZBN';
      EnvZip = '7825';
    }
    else if (this.EmailAccount === 'DHA') {

      invReplyTo = 'DHA@envirofrontier.com.au';
      QuotReplyTo = 'DHA@envirofrontier.com.au';
      EnvWebsite = 'www.envirofrontier.com.au';
      // let EmailAccount=null;
      EnvAddressLine1 = 'P.O. Box 617';
      EnvAddressLine2 = 'Narrabeen';
      EnvState = 'NSW';
      EnvCountry = 'Australia';
      EnvPhone = '1300 077 233';
      EnvFax = '1300 077 446';
      EnvABNName = 'ABN';
      EnvZip = '2106';

    }
    if (this.jobInfo.job_company_id == '324') {
      QuotReplyTo = 'iag@envirofrontier.com.au';
    }
}else{

invReplyTo = 'accounts@envirotrees.uk';
    QuotReplyTo = 'makesafe@envirotrees.uk';
    EnvWebsite = 'www.envirotrees.uk';
    // let EmailAccount=null;
    EnvAddressLine1 = 'P.O. Box XXX';
    EnvAddressLine2 = 'Address Line';
    EnvState = 'State';
    EnvCountry = 'UK';
    EnvPhone = 'XXX XXXX XXX';
    EnvFax = 'XXX XXX XXX';
    EnvABNName = 'VAT';
    EnvZip = '1122';
}
    
    this.QuotReplyTo = QuotReplyTo;
    this.invReplyTo = invReplyTo;
    this.EnvWebsite = EnvWebsite;
    this.EnvABNName = EnvABNName;
    this.EnvAddressLine1 = EnvAddressLine1;
    this.EnvAddressLine2 = EnvAddressLine2;
    this.EnvState = EnvState;
    this.EnvCountry = EnvCountry;
    this.EnvPhone = EnvPhone;
    this.EnvFax = EnvFax;
    this.EnvZip = EnvZip;
    let reqActionData = await this.crudService.getDataByField('JobActions', 'id', this.jobInfo.job_req_action_id).toPromise();
    if(this.jobInfo.job_req_action_id == "0")
    {
      this.jobReqAction= "";
    }
    else
    {
      this.jobReqAction= reqActionData[0].job_action_name;
    }
   
    if(this.env.hosting == 'UK'){ 
       if(this.jobReqAction == 'Subsidence Claim'){
        this.emailSubject = this.job_claim_no + ', Invoice from Enviro Trees for Arborist Subsidence Report';}
        else{
        this.emailSubject = this.job_claim_no + ', Invoice from Enviro Trees for Tree Works in Site Address-' + this.location;}
        }
      else{ 
      this.emailSubject = 'Claim Number - ' + this.job_claim_no + ', Invoice from Enviro Trees for Tree Works in Site Address-' + this.location;
    }
    
    this.selectedAttachmentformat = []
    if(this.selectedAttachmentformat[0] == 1 ){
      var access_token = this.jobAllocationDetails.JobAllocDetails.accesstoken
      this.attachmentUrl =this.env.base_url+'clientdetails/'+ access_token

      this.emailContent = 'Hi ' + this.client_name?.trim() + ', <br/><br/>Please find attached invoice and supporting documents by clicking the below link<br/> '+ '<body> <a href="'+this.attachmentUrl +'">' + this.attachmentUrl + '</a></body>' +' <br/>' + 
      + 'Claim Number: ' + this.job_claim_no + '<br/>' + 'Contact Detail is ' + this.contactNo + '<br/>Site Address: ' + this.jobAllocationDetails.info.address + '<br/>' + 'Notes: ' + 'N/A';
    
    }
    else{
      this.emailContent = 'Hi ' + this.client_name?.trim() + ', <br/><br/>Please find attached invoice and supporting documents <br/> <br/>'
      + 'Claim Number: ' + this.job_claim_no + '<br/>' + 'Contact Detail is ' + this.contactNo + '<br/>Site Address: ' + this.jobAllocationDetails.info.address + '<br/>' + 'Notes: ' + 'N/A';
    
    }

    this.replyTo = invReplyTo;

    this.getUploadImages();
    this.getDocumentInfo();
    let index2 = this.invoiceStages.findIndex(val => (val === this.jobAllocationDetails.JobStatus));
    if (index2 > -1) {
      this.itemType = 1;
    } else {
      this.itemType = 2;
    }
    await this.templateChange(this.itemType);
    this.toEmail.push({ value: this.jobAllocationDetails.info.Email });
  }
  onCheckCC(value) {
    //console.log(value)
    if (value === 1) {
      this.ifCc = true;
    }
  }
  onCheckBCC(value) {
    if (value === 1) {
      this.ifBCc = true;
    }
  }
  templateChange(value) {
    //console.log(value);
    this.emailAttachment = [];
    this.selectedEmailAttachment = [];
    if (this.itemType == 1) {
      this.invoiceTem();
    } else if (this.itemType == 2) {
      this.quotationTem();
    }
  }
  changeattachmentformat(event){
    if(this.itemType == 1 ){
      this.invoiceTem();
    }
    else{
      this.quotationTem();
    }
  }
    
  invoiceTem() {
    this.emailAttachment = this.invSelectAttach;
    if(this.env.hosting == 'UK' && this.jobReqAction == 'Subsidence Claim'){ 
      this.selectedEmailAttachment = this.subinvSelectAttach;
    }
    else
    {
      this.selectedEmailAttachment = this.invSelectAttach;
    }
    if (this.selectedEmailAttachment.length > 0) {
      this.selectedEmailAttachment.forEach(async element => {
        const attachmentType = element.value + ' ';

        // if (attachmentType.includes('individual_images')) {
        //   await   this.sendIndividualImage();
        //   this.imagecontent = ''
        //   this.imgDataList.forEach(element => {
        //     var content ='<p>' + element.imgName + ': '+ '<a href="'+element.imgPath+'">' + element.imgPath + '</a> </p>'
        //     this.imagecontent=     this.imagecontent + content
            
        //   });
        //   console.log("imagecontent",   this.imagecontent)
        //   } 
        //   else if (attachmentType.includes('image_report')) {
        //     await this.sendImgeReport();
        //   } 
        //   else if (attachmentType.includes('invoice')) {
        //     await this.sendInvoiceReport();

        //   } 
        //   else if (attachmentType.includes('quaaaaaaote')) {
        //     await this.sendQuoteReport();

        //   } 
        //   else if (attachmentType.includes('wpInvoice')) {
        //     await this.sendWpInvoiceReport();

        //   } 
        //   else if (attachmentType.includes('wpQuote')) {
        //     await this.sendWpQuoteReport();
        //   } 
        //   else if (attachmentType.includes('documents')) {
        //     await this.sendDocuments();
        //   }

          this.replyTo = this.invReplyTo;
          if(this.env.hosting == 'UK'){
            //console.log('jobReqAction22',this.jobReqAction)
            if(this.jobReqAction == 'Subsidence Claim'){
              this.emailSubject = this.job_claim_no + ', Invoice from Enviro Trees for Arborist Subsidence Report';}
             
             else{
            this.emailSubject = this.job_claim_no + ', Invoice from Enviro Trees for Tree Works in Site Address-' + this.location;}
             
            
           }          
            else{
              this.emailSubject = 'Claim Number - '+this.job_claim_no + ', Invoice for Tree Works in Site Address-' + this.location;}
          
          if(this.selectedAttachmentformat[0] == 1 ){
            var access_token = this.jobAllocationDetails.JobAllocDetails.accesstoken
            this.attachmentUrl =this.env.base_url+'clientdetails/'+ access_token
            this.emailContent = 'Hi ' + this.client_name?.trim() + ', <br/><br/>Please find attached invoice and supporting documents by clicking the below link<br/> '+ '<body> <a href="'+this.attachmentUrl +'">' + this.attachmentUrl + '</a></body>' +'<br/>'
            + 'Claim Number: ' + this.job_claim_no + '<br/>' + 'Contact Detail is ' + this.contactNo
            + '<br/>Site Address: ' + this.location + '<br/>' + 'Notes: ' + 'N/A '
          }
          else{
            this.emailContent = 'Hi ' + this.client_name?.trim() + ' , <br/><br/>Please find attached invoice and supporting documents <br/><br/>'
            + 'Claim Number: ' + this.job_claim_no + '<br/>' + 'Contact Detail is ' + this.contactNo
            + '<br/>Site Address: ' + this.location + '<br/>' + 'Notes: ' + 'N/A '
          }
            
          //  + '<body><br/> <p>Images: </p>' +' <a>' + this.imagecontent  + '</a><br/>'
          //  + 'image pdf: ' +'<a href="'+this.imgPdfUrl+'">' + this.imgPdfUrl + '</a><br/>'
          //  + 'Wp invoice report: ' +  '<a href="'+this.wpinvPdfUrl +'">' + this.wpinvPdfUrl + '</a><br/>'
          //  + 'Invoice pdf: ' +  '<a href="'+this.invPdfUrl +'" >' + this.invPdfUrl + '</a><br/>'
          //  + 'Wp quote pdf: ' +  '<a   href="'+this.wpquoPdfUrl +'">' + this.wpquoPdfUrl + '</a><br/>' 
            // + 'Documents: ' +  '<a href="'+this.documentData[0] +'">' + this.documentData[0] + '</a> '
            // ' </body>'  

            // '<body><ul><li *ngFor = "let data of' + this.imgDataList+ '"> 1 </li></ul></body>'     
             '<br/>Regards,<br/>'
            + '<br/>Email: ' + this.invReplyTo;

      //   if (attachmentType.includes('individual_images')) {
      //  await this.getUploadImgsByJobIdAllocId();

      //     this.replyTo = this.invReplyTo;
      //     this.emailSubject = this.job_claim_no + ' - Invoice for Tree Works in Site Address-' + this.location;
      //     this.emailContent = 'Hi ' + this.client_name?.trim() + ' , <br/><br/>Please find attached invoice and supporting documents <br/><br/>'
      //       + 'Claim Number: ' + this.job_claim_no + '<br/>' + 'Contact Detail is ' + this.contactNo
      //       + '<br/>Site Address: ' + this.location + '<br/>' + 'Notes: ' + 'N/A <br/>'
      //       + '<body><p>' +this.imageDocuments[0].img_name + '</p>  <a href="{{this.imageDocuments[0].image_url.changingThisBreaksApplicationSecurity}}" >' + this.imageDocuments[0].image_url.changingThisBreaksApplicationSecurity
      //       +' </a></body>'        
      //        '<br/>Regards,<br/>'
      //       // + this.EnvPhone + ' Option 2'
      //       + '<br/>Email: ' + this.invReplyTo;
      //   } 
      
      });
  
    }
    else{
      this.replyTo = this.invReplyTo;
      if(this.env.hosting == 'UK'){
        //console.log('jobReqAction33',this.jobReqAction)
        if(this.jobReqAction == 'Subsidence Claim'){
          this.emailSubject = this.job_claim_no + ', Invoice from Enviro Trees for Arborist Subsidence Report';}
         
         else{
        this.emailSubject = this.job_claim_no + ', Invoice from Enviro Trees for Tree Works in Site Address-' + this.location;}
      }
      
        else{
          this.emailSubject =  'Claim Number - ' + this.job_claim_no + ', Invoice for Tree Works in Site Address-' + this.location;}
      
      if(this.selectedAttachmentformat[0] == 1 ){
        var access_token = this.jobAllocationDetails.JobAllocDetails.accesstoken
        this.attachmentUrl =this.env.base_url+'clientdetails/'+ access_token
        this.emailContent = 'Hi ' + this.client_name?.trim() + ', <br/><br/>Please find attached invoice and supporting documents by clicking the below link<br/> '+ '<body> <a href="'+this.attachmentUrl +'">' + this.attachmentUrl + '</a></body>' +' <br/>' + 
        + 'Claim Number: ' + this.job_claim_no + '<br/>' + 'Contact Detail is ' + this.contactNo
        + '<br/>Site Address: ' + this.location + '<br/>' + 'Notes: ' + 'N/A';
       
      }
      else{
        this.emailContent = 'Hi ' + this.client_name?.trim() + ' , <br/><br/>Please find attached invoice and supporting documents <br/><br/>'
        + 'Claim Number: ' + this.job_claim_no + '<br/>' + 'Contact Detail is ' + this.contactNo
        + '<br/>Site Address: ' + this.location + '<br/>' + 'Notes: ' + 'N/A';
      }
    }
    
 

    

  }

  quotationTem() {
    this.emailAttachment = this.quoSelectAttach;
    if(this.env.hosting == 'UK' && this.jobReqAction == 'Subsidence Claim'){ 
      this.selectedEmailAttachment = this.subquoSelectAttach;
    }
    else
    {
      this.selectedEmailAttachment = this.quoSelectAttach;
    }
    this.replyTo = this.QuotReplyTo;

    if (this.selectedEmailAttachment.length > 0) {
      this.selectedEmailAttachment.forEach(async element => {

        const attachmentType = element.value + ' ';
        // if (attachmentType.includes('individual_images')) {
        //   await   this.sendIndividualImage();   
        //   this.imagecontent = ''
        //   this.imgDataList.forEach(element => {
        //     var content ='<p>' + element.imgName + ': '+ '<a href="'+element.imgPath+'">' + element.imgPath + '</a> </p>'
        //     this.imagecontent=     this.imagecontent + content
            
        //   });      
        //   }    
        //   else if (attachmentType.includes('image_report')) {
        //     console.log("image_reportimage_report")
        //     await this.sendImgeReport();
        //   } 
        //   else if (attachmentType.includes('invoice')) {
        //     await this.sendInvoiceReport();

        //   } 
        //   else if (attachmentType.includes('quaaaaaaote')) {
        //     await this.sendQuoteReport();

        //   } 
        //   else if (attachmentType.includes('wpInvoice')) {
        //     await this.sendWpInvoiceReport();

        //   } 
        //   else if (attachmentType.includes('wpQuote')) {
        //     await this.sendWpQuoteReport();

        //   } 
        //   else if (attachmentType.includes('documents')) {
        //     await this.sendDocuments();

        //   }   
        if(this.env.hosting == 'UK'){
         // console.log('jobReqAction44',this.jobReqAction)
          if(this.jobReqAction == 'Subsidence Claim'){
            this.emailSubject = this.job_claim_no + ', Quotation from Enviro Trees for Arborist Subsidence Report';}
           
           else{
          this.emailSubject = this.job_claim_no + ', Quotation from Enviro Trees for Tree Works in Site Address-' + this.location;}
           }       
          else
          {
            this.emailSubject = 'Claim Number - ' +this.job_claim_no + ', Quotation from Enviro Trees for Tree Works in Site Address-' + this.location;
          }
          
          if(this.selectedAttachmentformat[0] == 1 ){
            var access_token = this.jobAllocationDetails.JobAllocDetails.accesstoken
            this.attachmentUrl =this.env.base_url+'clientdetails/'+ access_token 

            this.emailContent = 'Hi ' + this.client_name?.trim() + ' , <br/><br/>Please find attached quotation and supporting documents for your Approval by clicking the below link<br/> '+ '<body> <a href="'+this.attachmentUrl +'">' + this.attachmentUrl + '</a></body>' +'<br/>'
            + 'Claim Number: ' + this.job_claim_no + '<br/>' + 'Contact Detail is ' + this.contactNo
            + '<br/>Site Address: ' + this.location + '<br/>' + 'Notes: ' + 'N/A' + ''
         //   + '<body><br/><p>Images: </p>' +'<a >' + this.imagecontent  + '</a><br/>'
         //   + 'image pdf: ' +'<a href="'+this.imgPdfUrl+'">' + this.imgPdfUrl + '</a><br/>'
         //   + 'Wp invoice report: ' +  '<a href="'+this.wpinvPdfUrl +'">' + this.wpinvPdfUrl + '</a><br/>'
         //   + 'Invoice pdf: ' +  '<a href="'+this.invPdfUrl +'">' + this.invPdfUrl + '</a><br/>'
         //   + 'Wp quote pdf: ' +  '<a href="'+this.wpquoPdfUrl +'">' + this.wpquoPdfUrl + '</a><br/>'
          // + 'Documents: ' +  '<a href="'+this.documentData[0] +'">' + this.documentData[0] + '</a> '
          ' </body>' 
            // '<body><ul><li *ngFor = "let data of' +this.imgDataList+ '"> 1 </li></ul></body>'
             '<br/>Regards,<br/>'
             + '<br/>Email: ' + this.QuotReplyTo;

          }
          else{
            this.emailContent = 'Hi ' + this.client_name?.trim() + ' , <br/><br/>Please find attached quotation and supporting documents for your Approval<br/><br/>'
            + 'Claim Number: ' + this.job_claim_no + '<br/>' + 'Contact Detail is ' + this.contactNo
            + '<br/>Site Address: ' + this.location + '<br/>' + 'Notes: ' + 'N/A' + ''
         //   + '<body><br/><p>Images: </p>' +'<a >' + this.imagecontent  + '</a><br/>'
         //   + 'image pdf: ' +'<a href="'+this.imgPdfUrl+'">' + this.imgPdfUrl + '</a><br/>'
         //   + 'Wp invoice report: ' +  '<a href="'+this.wpinvPdfUrl +'">' + this.wpinvPdfUrl + '</a><br/>'
         //   + 'Invoice pdf: ' +  '<a href="'+this.invPdfUrl +'">' + this.invPdfUrl + '</a><br/>'
         //   + 'Wp quote pdf: ' +  '<a href="'+this.wpquoPdfUrl +'">' + this.wpquoPdfUrl + '</a><br/>'
          // + 'Documents: ' +  '<a href="'+this.documentData[0] +'">' + this.documentData[0] + '</a> '
          ' </body>' 
            // '<body><ul><li *ngFor = "let data of' +this.imgDataList+ '"> 1 </li></ul></body>'
             '<br/>Regards,<br/>'
             + '<br/>Email: ' + this.QuotReplyTo;
          }
   
      });
  
    }
    else{
      if(this.env.hosting == 'UK'){
        console.log('jobReqAction55',this.jobReqAction)
        if(this.jobReqAction == 'Subsidence Claim'){
          this.emailSubject = this.job_claim_no + ', Quotation from Enviro Trees for Arborist Subsidence Report';}
         
         else{
        this.emailSubject = this.job_claim_no + ', Quotation from Enviro Trees for Tree Works in Site Address-' + this.location;}
         }
       // this.emailSubject = this.job_claim_no + ' - Quotation from Enviro Trees for Tree Works in Site Address-' + this.location;}

      else
      {
        this.emailSubject = 'Claim Number - ' +this.job_claim_no + ', Quotation from Enviro Trees for Tree Works in Site Address-' + this.location;
      }
      
      if(this.selectedAttachmentformat[0] == 1 ){
        var access_token = this.jobAllocationDetails.JobAllocDetails.accesstoken
        this.attachmentUrl = this.env.base_url+'clientdetails/'+ access_token 
      this.emailContent = 'Hi ' + this.client_name?.trim() + ' , <br/><br/>Please find attached quotation and supporting documents for your Approval by clicking the below link<br/> '+ '<body> <a href="'+this.attachmentUrl +'">' + this.attachmentUrl + '</a></body>' +' <br/>'
        + 'Claim Number: ' + this.job_claim_no + '<br/>' + 'Contact Detail is ' + this.contactNo
        + '<br/>Site Address: ' + this.location + '<br/>' + 'Notes: ' + 'N/A';
      }
      else{
        this.emailContent = 'Hi ' + this.client_name?.trim() + ' , <br/><br/>Please find attached quotation and supporting documents for your Approval<br/><br/>'
        + 'Claim Number: ' + this.job_claim_no + '<br/>' + 'Contact Detail is ' + this.contactNo
        + '<br/>Site Address: ' + this.location + '<br/>' + 'Notes: ' + 'N/A';
      }
    }

  }

  async compressImagesOld() {
    this.loading = true;
    this.crudService.getUploadImgsByJobIdAllocId(this.jobId, this.jobAllocationId).subscribe((res: any) => {
      let totalimages = res.length;
      res.forEach(async imagedocument => {
        this.loading = true;
        if (imagedocument.img_path != null && imagedocument.img_path !== '') {
          const blob: any = await this.crudService.getImageFile(imagedocument.img_path).toPromise();

          imagedocument.blob = blob;
          const image = new File([blob], imagedocument.img_name, {
            type: blob.type,
          });
          const json = {
            filename: imagedocument.img_name,
            filepath: imagedocument.img_path
          }
          const backupResponse = await this.crudService.backupImage(imagedocument.img_path).toPromise();
          await this.crudService.compressImage(json).subscribe(async (response: any) => {
            this.loading = false;
            this.getUploadImages();
          })
          //const compressedImage = await this.compressImage.compress(image).pipe(take(1)).toPromise();
          /* if (deleteResponse.response === 'success') {
            const file = compressedImage;
            let imgPath = null;
            const reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onload = (_event) => {
              if (reader.DONE) {
                imgPath = reader.result;
                const result = this.crudService.uploadImage(imgPath, this.jobId, this.jobAllocationId, file.name);
                const response = JSON.parse(result)
                if (response.status === 1) {
                  totalimages = totalimages - 1;
                  if (totalimages == 0) {
                    this.getUploadImages();
                    this.loading = false;
                  }
                }
              }
            };
            //console.log(`Image size after compressed: ${compressedImage.size} bytes.`)
          } */
        }
      });
    });
  }


  async compressImages() {
    this.loading = true;
    this.afterImgSize = 0;
    this.beforeImgSize = 0;
    let res: any = await this.crudService.getUploadImgsByJobIdAllocId(this.jobId, this.jobAllocationId).toPromise();
      await this.compression(res);
    let totalimages = res.length;
    this.loading = false;
    this.messageService.add({ severity: 'success', summary: 'Images Compressed', detail: 'Images Compressed', life: 2000 });
  }

  async compression(res): Promise<any> {
    await res.forEach(async imagedocument => {
      if (imagedocument.img_path != null && imagedocument.img_path !== '') {
        let reader = new FileReader();
        const blob: any = await this.crudService.getImageFile(imagedocument.img_path).toPromise();
        reader.readAsDataURL(blob);
        const imageSize = blob.size / 1024;
        let currentSize = null;
        reader.onload = async (_event) => {
          if (reader.DONE) {
            let imgPath = null;
            imgPath = reader.result;
            let size = this.imageCompress.byteCount(imgPath);
            if (size > 200000) {
              currentSize = await this.backupCompress(imagedocument, imgPath);
            } else {
              currentSize = imageSize / 1024;
            }
            if (imagedocument.img_type == '1') {
              this.beforeImgSize = (Math.round((this.beforeImgSize + currentSize) * 100) / 100);
            } else if (imagedocument.img_type == '2') {
              this.afterImgSize = Math.round((this.afterImgSize + currentSize) * 100) / 100;
            }
            this.totalSize = (Math.round((this.beforeImgSize + this.afterImgSize) * 100) / 100);
          }
        };
      }
    })
  }


  async backupCompress(imagedocument, imgPath): Promise<any> {
    let currentSize = null;
    await this.crudService.backupImage(imagedocument.img_path).toPromise();
    await this.imageCompress.compressFile(imgPath, -2, 25, 50).then(
      async result => {
        let res = await this.crudService.uploadImage(result, this.jobId, this.jobAllocationId, imagedocument.img_name);
        currentSize = this.imageCompress.byteCount(result) / 1024 / 1024;
      }
    );
    return currentSize
  }
  loadImg(picFile) {

    const image = new Image();
    const blob = picFile;
    // this.pics[index].rotated=true;
    const reader = new FileReader();
    reader.readAsDataURL(blob);
    reader.onloadend = function (e) {
      const imageSize = e.total;

      // image.src = e.target.result;
      image.onload = function () {
        image.setAttribute('width', '100%');
        // show.html(image);
      }
    }
    return image;
  }

  async sendWpQuoteReport() {
    const date = new Date();
    const tempTime = date.getTime();
    /* let data = await this.JobService.getWPInvoiceData(this.jobId, this.jobAllocationId);
    await this.crudService.WPInvoiceReport(this.jobId, this.jobAllocationId, '12_tmv3', data, tempTime); */
    let reportId = '6';
    const filePrefix = 'WP_Quote/Cost';
    const fileName = filePrefix + '_' + this.jobAllocationDetails.JobNo + '.pdf';
    const filePath = this.jobId + '/' + this.jobAllocationId + '/' + 'report-template-' + reportId + '-' + tempTime + '.pdf'
    const data = await this.JobService.generateWpQuotePdf(this.jobId, this.jobAllocationId, this.jobAllocationDetails);
    console.log('generateWpQuotePdf  data',data)
    reportId = data[0].info.reportid;
    let pdfUrl = this.crudService.getAPIRoot() + '/' + this.jobId + '/' + this.jobAllocationId + '/' + 'report-template-' + reportId + '-' + tempTime + '.pdf';
    try {
      await this.crudService.generateReportTM3(data, reportId, this.jobId, this.jobAllocationId, tempTime, 1).toPromise();
    } catch (error) {
      pdfUrl = null;
      console.error(error);
    }
    this.JobService.deleteDoc(this.jobAllocationId, fileName);
    this.JobService.addUploadDoc(this.jobId, this.jobAllocationId, this.userDetails.id, fileName, filePath, '10066', reportId, tempTime, this.jobAllocationDetails.info.WPCompanyId);
    this.wpquoPdfUrl = pdfUrl;
    console.log(" this.wpquoPdfUrl", this.wpquoPdfUrl)
    return pdfUrl;
  }

  async sendEmail() {
    let treeInfo: any = await this.crudService.getWorkOrderInfo(this.jobId, this.jobAllocationId).toPromise();
    if (treeInfo.length === 0) {
      //console.log(treeInfo);
      this.messageService.add({ severity: 'error', summary: 'error', detail: 'No Work Information available. Please enter Work Information before proceeding.', life: 2000 });
    } else if (this.jobAllocationDetails.info.taxrate === 0.0) {
      this.messageService.add({ severity: 'error', summary: 'error', detail: 'No tax available for this costings - please contact shri to get this fixed', life: 1000 });
    } else {
      const data: any = await this.crudService.getCostFormHeadByJobIdAllocId(this.jobId, this.jobAllocationId).toPromise();
      if (data.length > 0) {
        const costFormData = data[0];
        if (costFormData.tp_grand_total.toFixed(2) > 0.0) {
          this.sendMail();
        } else {
          this.confirmationService.confirm({
            message: 'Are you sure you want to send Zero Dollar Quote?',
            header: 'Confirm',
            icon: 'pi pi-exclamation-triangle',
            accept: () => {
              this.sendMail();
              this.confirmationService.close();
            },
            reject: () => {
              this.confirmationService.close();
            }
          });
        }
      } else {
        let costingDoc: Boolean = false;
        this.selectedEmailAttachment.forEach(atch => {
          if (atch.value === 'wpQuote' || atch.value === 'wpInvoice') {
            costingDoc = true;
          }
        });
        if (costingDoc) {
          this.messageService.add({ severity: 'error', summary: 'error', detail: 'Costing not Done. Please remove Costing Related attachments and try Again.', life: 1000 });
        } else {
          this.sendMail();
        }
      }
    }
  }


  async sendMail() {
  
    this.imgPdfUrl = null;
    this.invPdfUrl = null;
    this.quoPdfUrl = null;
    this.wpinvPdfUrl = null;
    this.wpquoPdfUrl = null;
    this.documentData = [];
    this.attachmentUrl= null
    this.messageService.add({ severity: 'success', summary: 'Sending', detail: 'Sending Email...' , life: 5000});
    this.loading = true;
    this.attachmentList = [];

    this.templ = {};
    this.templ.template = [{ email: {} }];
    let emailid = '';
    this.toEmail.forEach(mailid => {
      if (emailid !== '') {
        emailid = emailid + ', ' + mailid.value;
      } else {
        emailid = mailid.value;
      }
    });
    this.templ.template[0].email.to = emailid;

    emailid = '';
    this.ccEmail.forEach(mailid => {
      if (emailid !== '') {
        emailid = emailid + ', ' + mailid.value;
      } else {
        emailid = mailid.value;
      }
    });
    if (emailid !== '' && emailid !== null) {
      this.ifCc = true;
    }
    this.templ.template[0].email.cc = emailid;
    emailid = '';
    this.bccEmail.forEach(mailid => {
      if (emailid !== '') {
        emailid = emailid + ', ' + mailid.value;
      } else {
        emailid = mailid.value;
      }
    });
    if (emailid !== '' && emailid !== null) {
      this.ifBCc = true;
    }

	if(this.env.hosting =='UK'){
	if (this.itemType == 1) {
      if (emailid !== null) {
        if (emailid.indexOf('accounts@envirotrees.uk') == -1) {
          emailid = emailid + ', ' + 'accounts@envirotrees.uk';
        }
      } else {
        emailid = 'accounts@envirotrees.uk';
      }
    }
    }else{
	
    if (this.itemType == 1) {
      if (emailid !== null) {
        if (emailid.indexOf('accounts@envirofrontier.com.au') == -1) {
          emailid = emailid + ', ' + 'accounts@envirofrontier.com.au';
        }
      } else {
        emailid = 'accounts@envirofrontier.com.au';
      }
    }
}
    this.templ.template[0].email.bcc = emailid;
    this.templ.template[0].email.subject = this.emailSubject ? this.emailSubject : null;
    /* if (this.itemType == '2') {
      this.templ.template[0].email.bcc = "operations@envirofrontier.com.au";
    }
    if (this.itemType == '1') {
      this.templ.template[0].email.bcc = "";
    } */
    this.templ.template[0].email.attachments = this.attachments;
    let temEcontent = this.emailContent;
    if (this.emailContent) {
      temEcontent = temEcontent.replaceAll(/>[\n\t&nbsp;]+</g, '><');
      temEcontent = temEcontent.replaceAll(/&nbsp;/g, '');
      temEcontent = temEcontent.replaceAll(/&ndash;/g, '');
      temEcontent = temEcontent.replaceAll(/&/g, String.fromCharCode(228));
      // temEcontent = JSON.stringify(temEcontent);
      temEcontent = temEcontent.replaceAll('class="ql-align-center"', 'align="center"');
      temEcontent = temEcontent.replaceAll('class="ql-align-left"', 'align="left"');
      temEcontent = temEcontent.replaceAll('class="ql-align-right"', 'align="right"');
      temEcontent = temEcontent.replaceAll('class="ql-align-justify"', 'align="justify"');
    }
    // temEcontent = temEcontent.replace(/"/g, "");
    this.templ.template[0].email.msg = temEcontent ? temEcontent : ' ';

    if(this.itemType == 1) 
    { 
      this.ref_type = 'Quote'; 
    } 
    else { 
      this.ref_type = 'Invoice'; 
    }
    
    let imgList: any;
    let imgPdfUrl: any;
    let invPdfUrl: any;
    let quoPdfUrl: any;
    let wpinvPdfUrl: any;
    let wpquoPdfUrl: any;
    let documentList: any =[];
    var atch_names = this.selectedEmailAttachment.map(function (item) {
      return item.value;
    });
    console.log("selectedEmailAttachment",this.selectedEmailAttachment);
    for(let element of this.selectedEmailAttachment) {
      const attachmentType = element.value + ' ';
      if (attachmentType.includes('individual_images')) {
        imgList = await this.sendIndividualImage();
        this.attachmentList.push(imgList);
      } 
      else if (attachmentType.includes('image_report')) {
        imgPdfUrl = await this.sendImgeReport();
        this.attachmentList.push(imgPdfUrl);
      } 
      else if (attachmentType.includes('invoice')) {
        invPdfUrl = this.sendInvoiceReport();
        this.attachmentList.push(invPdfUrl);
        console.log("sendInvoiceReport");
      } 
      else if (attachmentType.includes('quaaaaaaote')) {
        quoPdfUrl = this.sendQuoteReport();
        this.attachmentList.push(quoPdfUrl);
        console.log("sendQuoteReport");
      } 
      else if (attachmentType.includes('wpInvoice')) {
        wpinvPdfUrl = await this.sendWpInvoiceReport();
        this.attachmentList.push(wpinvPdfUrl);
        console.log("sendWpInvoiceReport");
      } 
      else if (attachmentType.includes('wpQuote')) {
        wpquoPdfUrl = await this.sendWpQuoteReport();
        this.attachmentList.push(wpquoPdfUrl);
        console.log("sendWpQuoteReport", wpquoPdfUrl);
      } 
      else if (attachmentType.includes('documents')) {
        documentList = await this.sendDocuments();
        this.attachmentList.push(documentList);
      }
    }

    const res = await Promise.all(this.attachmentList);
    if(this.selectedAttachmentformat[0] == 1){
      var atts = {
        jobId: this.jobId,
        allocId: this.jobAllocationId,
        imgList: [],
        imgPdf: null,
        invPdfUrl:null,
        quoPdfUrl: null,
        wpinvPdfUrl: null,
        wpquoPdfUrl: null,
        documentList: [],
        claimnumber:null
      };
    }
    else{
      var atts = {
        jobId: this.jobId,
        allocId: this.jobAllocationId,
        imgList: this.imgDataList,
        imgPdf: this.imgPdfUrl,
        invPdfUrl: this.invPdfUrl,
        quoPdfUrl: this.quoPdfUrl,
        wpinvPdfUrl: this.wpinvPdfUrl,
        wpquoPdfUrl: this.wpquoPdfUrl,
        documentList: this.documentData,
        claimnumber:  this.job_claim_no
      };
      console.log("atts",atts);
    }
      
      const params = {
        sms: null,
        email: this.templ.template[0].email,
        type: 'email',
        selectedTpl: null,
        sufix: null,
        attachments: atts,
        jobData: null,
        replyTo: this.replyTo,
        account: this.env.hosting,
        client_id: this.jobInfo.job_company_id,
        atch_names
      }
      let temp_type = 'email';
      let smsData  = ''
      let dataObj = {
        sms: smsData,
        email: this.templ.template[0].email,
        type: temp_type,
        selectedTpl: 'd-fa99d348216144cfb8d39f2a67111474',
        sufix: null,
        jobData: null,
        attachments: atts,
        account: this.env.hosting,
        ClientId: this.jobAllocationDetails.WPCompanyId,
        ref_type: this.ref_type,
        ref_id: this.jobAllocationDetails.id
      }
      await this.storeInSchedule(dataObj);
          

      this.attachmentList = [];
      this.imgDataList = [];
      this.imgPdfUrlData = [];
      this.invPdfUrlData = [];
      this.quoPdfUrlData = [];
      this.wpinvPdfUrlData = [];
      this.wpquoPdfUrlData = [];
      this.documentData = [];

    this.loading = false;
  }

  saveQuoteAmount() {

  }



  // send email sendQuote


  getImgFile(imgPath) {
    // let deferred = $q.defer();
    //  // $http.get(this.apiRoot + 'uploadimages/getpic', { params: { filePath: imgPath }, responseType: 'blob' }).then(function(res) {
    //       deferred.resolve(res);
    //   }, function(err) {
    //       deferred.reject(err);
    //   });
    //   return deferred.promise;
  }

  /* async sendIndividualImage() {
    let res = await this.JobService.getImgeData(this.jobId, this.jobAllocationId, this.itemType);
    //console.log(res);
    this.imgDataList = res;
    return res;
  } */

  async sendIndividualImage(): Promise<any> {
    const res = await this.JobService.getIndvImageData(this.jobId, this.jobAllocationId, this.itemType);
    this.imgDataList = res;
    console.log("this.imgDataList",this.imgDataList);   
    return res;
  }
 
  async getUploadImgsByJobIdAllocId(): Promise<any> { 
    const res = await this.crudService.getUploadImgsByJobIdAllocId(this.jobId, this.jobAllocationId,).toPromise();
       let data:any=res
       if (data) {
         this.loading = false
         data.forEach(imagedocument => {
           // //console.log(imagedocument.upload_time.split("T")[0] + imagedocument.upload_time.split("T")[1].split(".")[0]);
           if (imagedocument.img_inc === '2') {
             imagedocument.isIncluded = true;
           } else {
             imagedocument.isIncluded = false;
           }
           imagedocument.upload_time = imagedocument.upload_time.split("T")[0] + ' ' + imagedocument.upload_time.split("T")[1].split(".")[0];
           imagedocument.upload_time_str = new Date(imagedocument.upload_time).toLocaleString();
           if (imagedocument.img_path != null && imagedocument.img_path !== '') {
             this.crudService.getImageFile(imagedocument.img_path).subscribe(blob => {
               imagedocument.blob = blob;
               let objectURL = URL.createObjectURL(blob);
               imagedocument.objUrl = objectURL;
               let image_url = this.sanitizer.bypassSecurityTrustUrl(objectURL);
               imagedocument.image_url = image_url;
             });
           }
           this.types.forEach(type => {
             if (type.id === imagedocument.img_type) {
               imagedocument.selectedType = type;
             }
           });
           this.imageDocuments.push(imagedocument);
         });
         if (data.length) {
           let res: any = await this.crudService.getAllAllocatedJobByJobId(data[0].job_alloc_id).toPromise();
           this.uploadAllocationList = res;
           if (this.uploadAllocationList.length === 0) {
             this.allocationdisable = false;
           }
           else {
             this.allocationdisable = true;
           }
         }
       }
       console.log("this.getUploadImgsByJobIdAllocId",this.imageDocuments);
       //  this.uploadimgDataList = res;
       return this.imageDocuments;
  
    
 
 
   }


  async sendInvoiceReport(): Promise<any> {
    const date = new Date();
    const tempTime = date.getTime();
    const data = await this.JobService.getInvoiceData(this.jobId, this.jobAllocationId, tempTime);
    const pdfUrl = this.crudService.getAPIRoot() + '/' + this.jobId + '/' + this.jobAllocationId + '/' + 'report-template-11-' + tempTime + '.pdf';
    this.invPdfUrl = pdfUrl;
    console.log("this.invPdfUrl",this.invPdfUrl);    
    return pdfUrl;
  }

  async sendWpInvoiceReport(): Promise<any> {
    const date = new Date();
    const tempTime = date.getTime();
    let reportId = '12';
    const filePrefix = 'WP_Invoice';
    const fileName = filePrefix + '_' + this.jobAllocationDetails.JobNo + '.pdf';
    const filePath = this.jobId + '/' + this.jobAllocationId + '/' + 'report-template-' + reportId + '-' + tempTime + '.pdf'
    /* let data = await this.JobService.getWPInvoiceData(this.jobId, this.jobAllocationId);
    await this.crudService.WPInvoiceReport(this.jobId, this.jobAllocationId, '12_tmv3', data, tempTime).toPromise();
     */
    const data = await this.JobService.generateWpInvoicePdf(this.jobId, this.jobAllocationId, this.jobAllocationDetails).catch(err => []);
    reportId = data[0]?.info?.reportid;
    let pdfUrl = this.crudService.getAPIRoot() + '/' + this.jobId + '/' + this.jobAllocationId + '/' + 'report-template-' + reportId + '-' + tempTime + '.pdf';
    try {
      await this.crudService.generateReportTM3(data, reportId, this.jobId, this.jobAllocationId, tempTime, 1).toPromise();
    } catch (error) {
      pdfUrl = null;
      console.error(error);
    }
    this.JobService.deleteDoc(this.jobAllocationId, fileName);
    this.JobService.addUploadDoc(this.jobId, this.jobAllocationId, this.userDetails?.id, fileName, filePath, '10067', reportId, tempTime, this.jobAllocationDetails.info.WPCompanyId);
    this.wpinvPdfUrl = pdfUrl;
    console.log("this.wpinvPdfUrl",this.wpinvPdfUrl);
    return pdfUrl;
  }

  // generate pdf and get the url
  async sendImgeReport(): Promise<any> {
    let imgs: any;
    imgs = await this.JobService.getImgeData(this.jobId, this.jobAllocationId, this.itemType);
    const reportId = 4;
    const data = await this.JobService.setData(reportId, imgs, this.jobAllocationDetails, this.costingDetails);
    const date = new Date();
    const tempTime = date.getTime();
    let pdfurl = this.crudService.getAPIRoot() + '/' + this.jobId + '/' + this.jobAllocationId + '/' + 'report-template-' + reportId + '-' + tempTime + '.pdf';
    try {
      await this.crudService.generateReport(data, reportId, this.jobId, this.jobAllocationId, tempTime, data).toPromise();
    } catch (error) {
      console.error(error);
      pdfurl = null;
    }
    this.imgPdfUrl = pdfurl;
    console.log("this.imgPdfUrl",this.imgPdfUrl);
    return pdfurl;
  }

  sendQuoteReport() {
    // this.generateQuoPdf();
  }

  async sendDocuments() {
    const res:any = await this.JobService.getDocumentData(this.jobId, this.jobAllocationId);
    this.documentData = res.filter(val => val.file_description !== '10066' && val.file_description !== '10067');
    return this.documentData;
  }

  getUploadImages() {
    this.afterImgSize = 0;
    this.beforeImgSize = 0;
    this.totalSize = 0;
    this.crudService.getUploadImgsByJobIdAllocId(this.jobId, this.jobAllocationId).subscribe((data: any[]) => {
      if (data) {
        data.forEach(imagedocument => {
          imagedocument.upload_time = imagedocument.upload_time.split('T')[0] + ' ' + imagedocument.upload_time.split('T')[1].split('.')[0];
          imagedocument.upload_time_str = new Date(imagedocument.upload_time).toLocaleString();
          if (imagedocument.img_path != null && imagedocument.img_path !== '') {
            this.crudService.getImageFile(imagedocument.img_path).subscribe(blob => {
              imagedocument.blob = blob;
              const imageSize = blob.size;
              imagedocument.imgsize = imageSize;
              const imgsize = Math.round(((imageSize / 1024) / 1024) * 100) / 100;
              imagedocument.imgsizeinMB = imageSize;
              // imageSize = imgsize + ' MB';
              if (imagedocument.img_type == '1') {
                this.beforeImgSize = (Math.round((this.beforeImgSize + imgsize) * 100) / 100);
              } else if (imagedocument.img_type == '2') {
                this.afterImgSize = Math.round((this.afterImgSize + imgsize) * 100) / 100;
              }
              this.totalSize = (Math.round((this.beforeImgSize + this.afterImgSize) * 100) / 100);

            });
          }
        });
      }
    })
  }

  getDocumentInfo() {
    let desc = '';
    this.crudService.getDocumentDescriptionsQuote(this.jobAllocationId).subscribe((data: any[]) => {
      const fileData = data.length > 0 ? data : [];
      fileData.forEach(file => {
        if (desc !== null && desc !== '') {
          desc = desc + ',' + file.Details;
        } else {
          desc = file.Details;
        }
        this.documentsInQuote = desc;
      });
      if (this.documentsInQuote == null) {
        this.documentsInQuote = 'No Documents Included';
      }
    });
  }

  async storeInSchedule(obj) {
    
    const to = obj.email.to ? obj.email.to : 'N/A';
    const cc = obj.email.cc ? obj.email.cc : 'N/A';
    const bcc = obj.email.bcc ? obj.email.bcc : 'N/A';
    const subject = obj.email.subject ? obj.email.subject : 'N/A';
    const msg = obj.email.msg ? obj.email.msg : 'N/A';
    const attachments = obj.atch_names ? obj.atch_names : 'N/A';
    const message = 'Email sent to ' + to + ' and CC to ' + cc + ' and Bcc to ' + bcc + '.<br/>Subject: ' + subject + '.<br/>Message: ' + msg + '.<br/> List of attachments: ' + attachments;
    
    let mail = to.toString().split(',');
    let tempMail =mail[0] ;

    let store_data = {
      id: null,
      job_id: this.jobId,
      job_alloc_id: this.jobAllocationId,
      process_id: 1,
      visit_type: null,
      status: null,
      sched_date: null,
      PMOnly: '2',
      // sched_note: message.replace(/�/g, '&'),//message.replace(/�/g,'&'),encodeURIComponent(message)
      sched_note: message.replace(/�/g, '&'),// message.replace(/�/g,'&'),encodeURIComponent(message)
      start_time: null,
      end_time: null,
      created_by: this.userDetails.id,
      created_at: new Date(),
      phoned: '1',
      phone_no: null,
      sms: '1',
      sms_no: null,
      email: '2',
      emailaddress: tempMail,
      callback: '1',
      source: 1.
    }
    this.crudService.Create(store_data, 'JobSchedule').subscribe((data: any) => {
      this.scheduleId = data.id;
      obj.ref_id = this.scheduleId;
      obj.ref_type = "JobSchedule";
      let subj = obj.email.subject;
      obj.email.subject = subj.replace(/'/g, "`");
      //const dataString = JSON.stringify(obj);
      //const encryptedData = this.EncrDecrService.encrypt(dataString,'treemanager');
      this.crudService.sendmailSendgrid(obj,'commonTemplate').subscribe((res2: any) => {
        this.loading = false;
        console.log("ada",res2);
        if(res2.success == 1)
        {
          this.messageService.add({ severity: 'success', summary: 'Successful', detail: 'Email Sent', life: 3000 });
        }
        else
        {
          if(res2.Data.code == 413)
          {
            let message = res2.Data.message+".Attachments Should Not Exceed 25MB.";
            this.messageService.add({ severity: 'error', summary: 'Error', detail: message, life: 3000 });
          }
          else
          {
            let message = res2.Data.response.body.errors[0].message;
            this.messageService.add({ severity: 'error', summary: 'Error', detail: message, life: 3000 });
          }
          
        }
        
        });
    });
  }


  search(event) {
    this.mailOptions = [];
    const option = this.emailsList.filter(val => val.includes(event.query));
    if (option.length > 0) {
      this.mailOptions = option;
    } else {
      if ((event.query.includes(',') || event.query.includes(';') || event.query.includes('.co')) && (event.query.includes('.co'))) {
        const value = event.query.replaceAll(',', '').replaceAll(';', '');
        this.mailOptions.push(value);
      }
    }
    console.log("this.emailsList",this.emailsList);
    console.log("this.mailOptions",this.mailOptions);
    this.mailFieldValue = '';
    this.mailFieldValue = event.query;
    // this.dummy=[];
  }

  searchCC(event) {
    this.mailOptions = [];
    const option = this.emailsList.filter(val => val.includes(event.query));
    if (option.length > 0) {
      this.mailOptions = option;
    } else {
      if ((event.query.includes(',') || event.query.includes(';') || event.query.includes('.co')) && (event.query.includes('.co'))) {
        const value = event.query.replaceAll(',', '').replaceAll(';', '');
        this.mailOptions.push(value);
      }
    }
    this.ccMailFieldValue = '';
    this.ccMailFieldValue = event.query;
    // this.dummy=[];
  }


  searchBCC(event) {
    this.mailOptions = [];
    const option = this.emailsList.filter(val => val.includes(event.query));
    if (option.length > 0) {
      this.mailOptions = option;
    } else {
      if ((event.query.includes(',') || event.query.includes(';') || event.query.includes('.co')) && (event.query.includes('.co'))) {
        const value = event.query.replaceAll(',', '').replaceAll(';', '');
        this.mailOptions.push(value);
      }
    }
    this.bccMailFieldValue = '';
    this.bccMailFieldValue = event.query;
    // this.dummy=[];
  }

  addMailId(mailFieldValue, arrayObject) {
    // if (typeof mailFieldValue !== 'undefined') {
    //   if (mailFieldValue !== null && mailFieldValue !== '') {
    //     arrayObject.push({ value: mailFieldValue });
    //   }
    // }
    // mailFieldValue = null;

    if (typeof mailFieldValue !== 'undefined' && mailFieldValue !== null && mailFieldValue !== '') {
      if (arrayObject.includes(mailFieldValue)) {
        if (arrayObject.length > 0) {
          let index = arrayObject.findIndex((res: any) => res.value == mailFieldValue);
          if (index > -1) {
          }else{
            arrayObject.push({ value: mailFieldValue });
          }
        }else{
          
          arrayObject.push({ value: mailFieldValue });
        }
      }else {
        if (this.emailValidation(mailFieldValue)) {
          let index = arrayObject.findIndex((res: any) => res.value == mailFieldValue);
          if (index < 0) {
            arrayObject.push({ value: mailFieldValue });
          }
        }
      }
    }
    mailFieldValue = null;
  }
  emailValidation(val: any) {
    var regex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/; // Uppercase character pattern
    if (regex.test(val)){
      return true;
    }else{
      return false;
    } 
  }

  removeToMailId(value) {
    this.toEmail = this.toEmail.filter(val => val.value !== value.value);
  }

  removeCCMailId(value) {
    this.ccEmail = this.ccEmail.filter(val => val.value !== value.value);
  }

  removeBccMailId(value) {
    this.bccEmail = this.bccEmail.filter(val => val.value !== value.value);
  }

  selectId(value, arrayObject) {
    this.addMailId(value, arrayObject);
  }

  niceBytes(x) {
    let l = 0, n = parseInt(x, 10) || 0;

    while (n >= 1024 && ++l) {
      n = n / 1024;
    }
    return (n.toFixed(n < 10 && l > 0 ? 1 : 0) + ' ' + this.units[l]);
  }
  

  async generateRandom(length) {
    let result = '';
    const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    const charactersLength = characters.length;
    let counter = 0;
    while (counter < length) {
      result += characters.charAt(Math.floor(Math.random() * charactersLength));
      counter += 1;
    }
    return result;
}
}