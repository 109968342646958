import { CUSTOM_ELEMENTS_SCHEMA,NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { OverlayLoaderComponent } from './overlay-loader/overlay-loader.component';
import { VehicleDetailsComponent } from './vehicle-details/vehicle-details.component';

import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { DropdownModule } from 'primeng/dropdown';
import { TableModule } from 'primeng/table';
import { ButtonModule } from 'primeng/button';
import { InputMaskModule } from 'primeng/inputmask';
import { InputNumberModule } from 'primeng/inputnumber';
import { InputSwitchModule } from 'primeng/inputswitch';
import { InputTextModule } from 'primeng/inputtext';
import { InputTextareaModule } from 'primeng/inputtextarea';
import { ConfirmDialogModule } from 'primeng/confirmdialog';
import { DialogModule } from 'primeng/dialog';
import { FileUploadModule } from 'ng2-file-upload';
import { DynamicDialogRef } from 'primeng/dynamicdialog';
import { DynamicDialogConfig } from 'primeng/dynamicdialog';
import { TooltipModule } from 'primeng/tooltip';
import { GooglePlaceModule } from "ngx-google-places-autocomplete";
import { CalendarModule } from 'primeng/calendar';
import { FullCalendarModule } from 'primeng/fullcalendar';
import { TabMenuModule } from 'primeng/tabmenu';
import { TabViewModule } from 'primeng/tabview';
import { Ng2ImgMaxModule } from 'ng2-img-max';
import { MultiSelectModule } from 'primeng/multiselect';
import { CheckboxModule } from 'primeng/checkbox';
import { CardModule } from 'primeng/card';
import { ToastModule } from 'primeng/toast';
import { EditorModule } from 'primeng/editor';
import { AutoCompleteModule } from 'primeng/autocomplete';
import { ProgressBarModule } from 'primeng/progressbar';
import { RadioButtonModule } from 'primeng/radiobutton';
import { ToggleButtonModule } from 'primeng/togglebutton';
import { SelectButtonModule } from 'primeng/selectbutton';

@NgModule({
  declarations: [OverlayLoaderComponent,VehicleDetailsComponent],
  imports: [
    CommonModule,FormsModule, ReactiveFormsModule,ConfirmDialogModule,DialogModule,TableModule,InputMaskModule,InputNumberModule,InputSwitchModule,InputTextModule,InputTextareaModule,
    ButtonModule,FileUploadModule,DropdownModule,TooltipModule,GooglePlaceModule,CalendarModule,EditorModule,AutoCompleteModule, ToggleButtonModule, SelectButtonModule,
    FullCalendarModule,TabMenuModule,TabViewModule,Ng2ImgMaxModule,MultiSelectModule,CheckboxModule,CardModule,ToastModule,
    ProgressBarModule,RadioButtonModule
  ],
  providers: [
    DynamicDialogRef, DynamicDialogConfig,
],
  exports: [OverlayLoaderComponent,VehicleDetailsComponent],
})
export class SharedModule { }
