import { Component, OnInit, Input, OnDestroy } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { ConfirmationService, MessageService } from 'primeng/api';
import { CRUDService } from 'src/app/utilities/crud.service';
import { CommonService } from 'src/app/utilities/common.service';
import { DynamicDialogRef, DialogService, DynamicDialogConfig } from 'primeng/dynamicdialog';
import { JOBService } from 'src/app/utilities/job.service';
import { environment } from 'src/environments/environment';
import { formatDate } from 'ngx-bootstrap';
import * as moment from 'moment';
import { DatePipe } from '@angular/common';
import { parse } from '@fullcalendar/core/datelib/parsing';

@Component({
  selector: 'app-job-invoice-send',
  templateUrl: './job-invoice-send.component.html',
  styleUrls: ['./job-invoice-send.component.css']
})
export class JobInvoiceSendComponent implements OnInit {
  companyId: any;
  userDetails: any;
  cancel: any;
  clientList: any[];
  @Input() jobId: any
  @Input() jobAllocationId: any;
  @Input() jobAllocationResults: any;
  @Input() jobSummary: any;
  client_type_id: any;
  wp_inv_dt: any;
  wp_amount_invoice: any;
  tp_pay_dt: any;
  mark_completeUI: any = false;
  initialTPInvDate: any;
  costedHours: any;
  costedStaff: any;
  SHFStaff: any;
  SHFHours: any;
  SHFMatrix: any = '1';
  invoice_no: any;
  wp_invoice_no: any;
  amount_invoice: any;
  mark_complete: any;
  costedTotal: any;
  SHFTotal: any;
  isInvoiced = false;
  appProps: any;
  showHeader: Boolean = false;
  minDate: Date;
  maxDate: Date;
  contractorCostingUrl: any;
  loading = false;
  env: any;
  templ: any;
  reportRes:any;
  invFilepath: any;
  datechanged: boolean = false;

  constructor(
    private route: ActivatedRoute,
    private crudService: CRUDService,
    private messageService: MessageService,
    private confirmationService: ConfirmationService,
    private router: Router,
    private commonservice: CommonService,
    public ref: DynamicDialogRef,
    public config: DynamicDialogConfig,
    public dialogService: DialogService,
    public JobService: JOBService
  ) {
    this.companyId = sessionStorage.getItem('company_id');
    this.userDetails = JSON.parse(sessionStorage.getItem('loggedInUser'));
    this.client_type_id = this.userDetails.Client_Type;
  }

  async ngOnInit() {
    let date = new Date();
    let datem = new Date();
    this.env = environment;
    this.minDate = new Date(date.setDate(date.getDate() - 30));
    this.maxDate = new Date(datem.setDate(datem.getDate() + 30));
    this.appProps = this.commonservice.getAppProperties();
    if (typeof this.jobId === 'undefined') {
      if (typeof this.config.data == 'undefined' || typeof (this.config.data.jobId) == 'undefined') {
        if (this.jobId == null || this.jobId === '') {
          this.jobId = parseInt(this.route.snapshot.paramMap.get('jobId'));
          if (this.jobAllocationId === null || this.jobAllocationId === '') {
            this.jobAllocationId = '1';
          } else {
            this.jobAllocationId = parseInt(this.route.snapshot.paramMap.get('jobAllocationId'));
          }
        }
        let smryData: any = await this.crudService.getJobSummary(this.jobId, this.jobAllocationId).toPromise();
        this.jobAllocationResults = await this.JobService.getJobInfo(smryData[0]);
        this.showHeader = false;
      } else {
        this.jobId = this.config.data.jobId;
        this.jobAllocationId = this.config.data.jobAllocationId;
        this.jobAllocationResults = this.config.data.jobSummary;
        this.jobSummary = this.config.data.jobSummary;
        this.showHeader = true;
      }
    }
    this.invoice_no = this.jobId;
    this.reloadData();
    console.log("jobAllocationResults",this.jobAllocationResults)
  }

  reloadData() {
    if (this.client_type_id === '1') {
      this.getTpWpInvoiceNoAndAmount();
    } else {
      this.getTpInvoiceNoAndAmount();
    }
    this.getInvoiceStatus();
  }
  getSendInvoiceData() {
    if (this.client_type_id === 1) {
      this.crudService.getTpWpInvoiceNoAndAmount(this.jobId, this.jobAllocationId).subscribe((res: any[]) => {
        this.invoice_no = res[0].tp_invoice_no ? parseInt(res[0].tp_invoice_no) : parseInt(this.jobId);
        this.wp_invoice_no = res[0].wp_invoice_no ? parseInt(res[0].wp_invoice_no) : ((parseInt(res[0].WPMaxInvoice) + 1));
        this.wp_inv_dt = null;
        if (res[0].wp_inv_dt !== null && res[0].wp_inv_dt !== '') {
          this.wp_inv_dt =res[0].wp_inv_dt
        }
        this.amount_invoice = res[0].tp_grand_total ? parseFloat(res[0].tp_grand_total) : 0;
        this.wp_amount_invoice = res[0].wp_grand_total ? parseFloat(res[0].wp_grand_total) : 0;
        //alert (res[0].TPInvDate);
        this.initialTPInvDate = res[0].TPInvDate ? new Date(res[0].TPInvDate).toLocaleDateString() : null;
        if (res[0].tp_pay_dt != null) {
          res[0].tp_pay_dt = new Date(res[0].tp_pay_dt).toLocaleDateString();
        }
        this.tp_pay_dt = res[0].tp_pay_dt;
        this.mark_complete = res[0].mark_complete;

        if (this.mark_complete == '1') {
          this.mark_completeUI = true;
        }
        else {
          this.mark_completeUI = false;   //1=checked(true) and 2=unchecked(false)
        }
        //              this.mark_completeUI=res[0].mark_complete?parseUI(res[0].mark_complete):null;

        this.crudService.compareTimeOnSite(this.jobAllocationId).subscribe((res: any[]) => {
          if (res.length > 0) {
            this.costedHours = res[0].costedHours;
            this.costedStaff = res[0].costedStaff;
            this.costedTotal = this.costedHours;
            this.SHFHours = (res[0].SHFHours / 60).toFixed(2);
            this.SHFStaff = res[0].SHFStaff;
            this.SHFTotal = ((res[0].SHFHours / 60) * this.SHFStaff).toFixed(2);
            if (((this.costedTotal / this.SHFTotal) - 1) > .25) {
              this.SHFMatrix = '3';
            } else if (((this.costedTotal / this.SHFTotal) - 1) > 0) {
              this.SHFMatrix = '2';
            } else if (((this.costedTotal / this.SHFTotal) - 1) < 0) {
              this.SHFMatrix = '1';
            }
          }
        });


      });
    } else {
      this.crudService.getTpInvoiceNoAndAmount(this.jobId, this.jobAllocationId).subscribe((res: any[]) => {
        //console.log('tp invoice no');
        //console.log(res);
        this.invoice_no = res[0].tp_invoice_no ? parseInt(res[0].tp_invoice_no) : parseInt(this.jobId);
        //this.wp_invoice_no=res[0].wp_invoice_no?parseInt(res[0].wp_invoice_no):null;
        //this.wp_inv_dt=res[0].wp_inv_dt?utilService.DateToLocalTimeStringOnlyDate(res[0].wp_inv_dt):null;
        this.amount_invoice = res[0].tp_grand_total ? parseFloat(res[0].tp_grand_total) : 0;
        //this.wp_amount_invoice=res[0].wp_grand_total?parseFloat(res[0].wp_grand_total):0;
      });
    }
  }

  getTpWpInvoiceNoAndAmount() {
    this.crudService.getTpWpInvoiceNoAndAmount(this.jobId, this.jobAllocationId).subscribe((tpWpInvoice: any) => {
      //console.log(tpWpInvoice);
      if (tpWpInvoice) {
        this.invoice_no = tpWpInvoice[0].tp_invoice_no ? parseInt(tpWpInvoice[0].tp_invoice_no) : parseInt(this.jobId);
        this.wp_invoice_no = tpWpInvoice[0].wp_invoice_no ? parseInt(tpWpInvoice[0].wp_invoice_no) : ((parseInt(tpWpInvoice[0].WPMaxInvoice) + 1));
        this.wp_inv_dt = null;
        if (tpWpInvoice[0].wp_inv_dt !== null && tpWpInvoice[0].wp_inv_dt !== '') {
          this.wp_inv_dt = new Date(tpWpInvoice[0].wp_inv_dt);
        this.wp_inv_dt = new Date(this.wp_inv_dt); 
        }
        this.amount_invoice = tpWpInvoice[0].tp_grand_total ? parseFloat(tpWpInvoice[0].tp_grand_total) : 0;
        this.wp_amount_invoice = tpWpInvoice[0].wp_grand_total ? parseFloat(tpWpInvoice[0].wp_grand_total) : 0;
        this.initialTPInvDate = tpWpInvoice[0].TPInvDate ? (tpWpInvoice[0].TPInvDate) : null;
        if (tpWpInvoice[0].tp_pay_dt != null) {
          tpWpInvoice[0].tp_pay_dt = (tpWpInvoice[0].tp_pay_dt);
        }
        this.tp_pay_dt = tpWpInvoice[0].tp_pay_dt;
        this.mark_complete = tpWpInvoice[0].mark_complete;
        if (this.mark_complete == '1') {
          this.mark_completeUI = true;
        }
        else {
          this.mark_completeUI = false;   //1=checked(true) and 2=unchecked(false)
        }
        //              mark_completeUI=res[0].mark_complete?parseUI(res[0].mark_complete):null;

        this.crudService.compareTimeOnSite(this.jobAllocationId).subscribe((res: any) => {
          //console.log(res);
          if (res.length > 0) {
            this.costedHours = res[0].costedHours;
            this.costedStaff = res[0].costedStaff;
            this.costedTotal = this.costedHours;
            this.SHFHours = (res[0].SHFHours / 60).toFixed(2);
            this.SHFStaff = res[0].SHFStaff;
            this.SHFTotal = ((res[0].SHFHours / 60) * this.SHFStaff).toFixed(2);
            if (((this.costedTotal / this.SHFTotal) - 1) > .25) {
              this.SHFMatrix = '3';
            } else if (((this.costedTotal / this.SHFTotal) - 1) > 0) {
              this.SHFMatrix = '2';
            } else if (((this.costedTotal / this.SHFTotal) - 1) < 0) {
              this.SHFMatrix = '1';
            }
          }
        });
      }
    })
  }
  getTpInvoiceNoAndAmount() {
    this.crudService.getTpInvoiceNoAndAmount(this.jobId, this.jobAllocationId).subscribe((tpInvoice: any) => {
      this.getInvoiceStatus();
      //console.log(tpInvoice);
    })
  }

  sendInvoice2() {
    this.crudService.updateTpInvoiceNo(this.invoice_no, this.jobId, this.jobAllocationId).subscribe(res => {
      this.crudService.updateTpInvoiceStatus(this.jobId, this.jobAllocationId, '1', new Date()).subscribe(res2 => {
        this.sendEmail();
        this.getInvoiceStatus();
      });
    });
  }

  async resetAwaitInvoice() {
    let res: any = await this.crudService.updateTpInvoiceNo(null, this.jobId, this.jobAllocationId).toPromise()
    let res2: any = await this.crudService.resetToAwaitInvoice(this.jobId, this.jobAllocationId, null, null).toPromise();
    await this.sendEmail();
    await this.storeInSchedule();
    await this.getInvoiceStatus();
    this.messageService.add({ severity: 'success', summary: 'success', detail: 'Reset To Await Invoice.', life: 1000 });
    if (this.ref) {
      this.ref.close();
    }
  }


  async sendEmail() {
    let params = {
      email: {
        to: '',
        cc: '',
        bcc: '',
        msg: '',
        subject: '',
        sms: null,
        type: 'email',
        selectedTpl: null,
        sufix: null
      }
    }
    let toAddress = this.jobAllocationResults.info.Email
    let ccAddress = 'account@envirotrees.au';//'venkat@agileitexperts.com';
    let mailSubject = '';
    if (this.jobAllocationResults.info.ClientCommType == 'Email Client') {
      if (this.jobAllocationResults.info.Email !== 'NA') {
        params = {
          email: {
            to: toAddress, cc: ccAddress, bcc: 'shri@envirofrontier.com.au',
            msg: ' Hi Team, Claim Number ' + this.jobAllocationResults.info.job_claim_no + ' \n . Please be advised that job has been completed, as per our Tree Professional. We are in the process of obtaining insured\'s feedback. ' +
              ' Invoice and completion documents to follow in due course. If you have any queries or concerns for this job, please contact us on 1300 077 233. ' +
              ' Our Reference Number is TM ' + this.jobAllocationResults.JobNo + '. For any other tree works or quotation requests, please feel free to send them to makesafe@envirofrontier.com.au.  ' +
              '  We are more than happy to assist you.  \n' +
              '    Kind regards, Enviro Trees Customer Care Team \n',
            subject: 'Job Completed and Ready for Invoicing , TM Job Number - ' + this.jobAllocationResults.JobNo + ', Site Address - ' + this.jobAllocationResults.info.location,
            sms: null, type: 'email', selectedTpl: null, sufix: null
          }
        }
      } else {
        params = {
          email: {
            to: ccAddress, cc: '', bcc: 'shri@envirofrontier.com.au',
            msg: 'Missing Client Email Details --  Hi Team, Claim Number ' + this.jobAllocationResults.info.job_claim_no + ' \n . Please be advised that job has been completed, as per our Tree Professional. We are in the process of obtaining insured\'s feedback. ' +
              ' Invoice and completion documents to follow in due course. If you have any queries or concerns for this job, please contact us on 1300 077 233. ' +
              ' Our Reference Number is TM ' + this.jobAllocationResults.JobNo + '. For any other tree works or quotation requests, please feel free to send them to makesafe@envirofrontier.com.au.  ' +
              '  We are more than happy to assist you.  \n' +
              '    Kind regards, Enviro Trees Customer Care Team \n',
            subject: 'Missing Client Email Details -- Job Completed and Ready for Invoicing , TM Job Number - ' + this.jobAllocationResults.JobNo + ', Site Address - ' + this.jobAllocationResults.info.location,
            sms: null, type: 'email', selectedTpl: null, sufix: null
          }
        }
      }
    } else if (this.jobAllocationResults.info.ClientCommType == 'Portal Client') {
      params = {
        email: {
          to: ccAddress, cc: '', bcc: 'shri@envirofrontier.com.au',
          msg: 'To load in portal --  Hi Team, Claim Number ' + this.jobAllocationResults.info.job_claim_no + ' \n . Please be advised that job has been completed, as per our Tree Professional. We are in the process of obtaining insured\'s feedback. ' +
            ' Invoice and completion documents to follow in due course. If you have any queries or concerns for this job, please contact us on 1300 077 233. ' +
            ' Our Reference Number is TM ' + this.jobAllocationResults.JobNo + '. For any other tree works or quotation requests, please feel free to send them to makesafe@envirofrontier.com.au.  ' +
            '  We are more than happy to assist you.  \n' +
            '    Kind regards, Enviro Trees Customer Care Team \n',
          subject: 'To load in portal -- Job Completed and Ready for Invoicing , TM Job Number - ' + this.jobAllocationResults.JobNo + ', Site Address - ' + this.jobAllocationResults.info.location,
          sms: null, type: 'email', selectedTpl: null, sufix: null
        }
      }
    }
    await this.crudService.sendMessage(params).toPromise();
  }


  async storeInSchedule() {

    let message = 'TP Invoice details removed. Initial date of invoicing is - ' + new Date(this.initialTPInvDate).toLocaleString();
    let sms = '1';
    let email = '1';

    let store_data = {
      id: null,
      job_id: this.jobId,
      job_alloc_id: this.jobAllocationId,
      process_id: 1,
      visit_type: null,
      status: null,
      sched_date: null,
      PMOnly: '2',
      sched_note: message,
      start_time: null,
      end_time: null,
      created_by: this.userDetails.id,
      created_at: new Date(),
      phoned: '1',
      phone_no: null,
      sms: null,
      sms_no: null,
      email: null,
      emailaddress: null,
      callback: '1',
      contactUI: null
    }
    let res: any = await this.crudService.Create(store_data, 'JobSchedule').toPromise();

  }


  saveInvoiceDetailsOld() {
    this.wp_inv_dt = this.wp_inv_dt ? this.wp_inv_dt : null;
    this.tp_pay_dt = this.tp_pay_dt ? this.tp_pay_dt : null;
    // parseUI($this.mark_completeUI);
    if (this.wp_inv_dt == null) {
      this.wp_invoice_no = null;
    }
    this.crudService.updateWpInvoiceNo(this.wp_invoice_no, this.wp_inv_dt, this.tp_pay_dt, this.mark_complete, this.jobId, this.jobAllocationId, this.invoice_no).subscribe(res => {
      this.crudService.updateWpInvoiceStatus(this.jobId, this.jobAllocationId, '1', new Date()).subscribe(response => {
      });
    });
  }
  resetAwaitCost() { }
  // resetAwaitCost(){


  //   $scope.$emit('invoice_refresh');
  //   $('#sendInvoiceModal').modal('hide');
  //   $route.reload();
  // }


  onChangedate(){
    this.datechanged = true
    debugger
  }
  saveInvoiceDetails() {
    this.wp_inv_dt = this.wp_inv_dt ? new Date(this.wp_inv_dt) : null;
    if (this.wp_inv_dt !== null) {
      if(this.datechanged){
        const date =  this.wp_inv_dt;
        const time= moment().add({hours:0}).format('hh:mm a') // indian time
      debugger
       const yy = new Date(date).getFullYear();
       const mm = new Date(date).getMonth() ;
       const dd = new Date(date).getDate();
       const times :any =time.split(" ")[0].split(":");
       if(time.split(" ")[1]=="pm")times[0]=Number(times[0])+12;
       this.wp_inv_dt = new Date(yy, mm, dd, times[0], times[1]);
       debugger
       if(environment.hosting == 'AU'){
         let tempDate2:any  = new DatePipe('en-Us').transform(this.wp_inv_dt,'yyyy-MM-dd HH:mm:ss.SSS', 'GMT+10');
         this.wp_inv_dt = tempDate2
       }
       else if (environment.hosting == 'UK'){
         debugger
         let tempDate2:any  = new DatePipe('en-Us').transform(this.wp_inv_dt,'yyyy-MM-dd HH:mm:ss.SSS', 'GMT+1');
         this.wp_inv_dt = tempDate2
         // let tempDate2:any  = moment(this.wp_inv_dt).toDate();
         // this.wp_inv_dt = tempDate2
       }
      }
      this.tp_pay_dt = this.tp_pay_dt ? new Date(this.tp_pay_dt) : null;
      // if (this.mark_completeUI == true) {
      // this.mark_complete = 1;
      // } else {
      this.mark_complete = 2;
      // }
      if (this.wp_inv_dt == null) {
        this.wp_invoice_no = null;
      }
      console.log("this.wp_inv_dt",this.wp_inv_dt);
      debugger
      this.sendInvoiceMail();
      this.crudService.updateWpInvoiceNo(this.wp_invoice_no, this.wp_inv_dt, this.tp_pay_dt, this.mark_complete,
        this.jobId, this.jobAllocationId, this.invoice_no).subscribe((res2: any[]) => {
          console.log("res2",res2);
          this.crudService.updateWpInvoiceStatus(this.jobId, this.jobAllocationId, '1', new Date()).subscribe(async (res3: any[]) => {
            this.getInvoiceStatus();
            this.messageService.add({ severity: 'success', summary: 'success', detail: 'Invoice Submitted.', life: 1000 });
            let smryData: any = await this.crudService.getJobSummary(this.jobId, this.jobAllocationId).toPromise();
            this.jobAllocationResults = await this.JobService.getJobInfo(smryData[0]);
            this.reloadData();
            /* if (this.ref) {
              this.ref.close();
            } */
          });
        });
    } else {
      //this.sendInvoice2();
      this.messageService.add({ severity: 'error', summary: 'Invoice Date is Required', detail: 'Invoice Date must not be left blank.', life: 2000 });
    }
  }






  async generateReport(reportId) {
    debugger
    this.reportRes = {};
    this.loading = true;
    const date = new Date();
    const tempTime = date.getTime();
    const fileName = reportId + '_' + this.jobSummary.JobNo + '.pdf';
    const filePath = this.jobId + '/' + this.jobAllocationId + '/' + 'report-template-' + reportId + '-' + tempTime + '.pdf'
    const filePath2 = this.jobId + '/' + this.jobAllocationId + '/' + 'report-template-11-' + tempTime + '.pdf'
    let fileDesc = null;
     console.log("ressthis.jobAllocationIds",this.jobAllocationId);
    let res: any;
    if (reportId === 'TPI') {
      fileDesc = '10211';
      res = await this.JobService.getInvoiceData(this.jobId, this.jobAllocationId, tempTime);
    } 
    console.log("resss",res);
    this.reportRes.res = res;
    this.reportRes.fileName = fileName;
    this.reportRes.filePath = filePath;
    console.log("filePath",filePath);
    this.reportRes.fileDesc = fileDesc;
    this.reportRes.reportId = reportId;
    this.reportRes.tempTime = tempTime;
   // this.displayConfirmation = true;
   let blob = new Blob([this.reportRes.res], { type: "application/pdf" });
   const objectUrl = URL.createObjectURL(blob);
 //  window.open(objectUrl);
   console.log("objectUrl",objectUrl);
   this.invFilepath=filePath2;

  }

  async sendInvoiceMail(){

    debugger
    await this.generateReport('TPI');
  
    this.contractorCostingUrl =  environment.apiRoot+this.invFilepath;
    environment.apiRoot
    this.messageService.add({ severity: 'success', summary: 'Sending', detail: 'Sending Email...' , life: 5000});
    this.loading = true;
    const atts = {
      jobId: this.jobId,
      allocId: this.jobAllocationId,
      wpquoPdfUrl: this.contractorCostingUrl
    }
    console.log("atts",atts);
    this.templ = {};
    this.templ.template = [{ email: {} }];
    let temp_type = 'email';
    this.templ.template[0].email.to = this.jobAllocationResults.TreeProfessionalEmail;
    this.templ.template[0].email.bcc = '';
    this.templ.template[0].email.cc ='';
    this.templ.template[0].email.subject = 'Invoice Details for the Job';
    this.templ.template[0].email.msg ='Please check the attachments for invoice copy';
   
   
    let dataObj = {
    
      email: this.templ.template[0].email,
      type: temp_type,
      selectedTpl: 'd-fa99d348216144cfb8d39f2a67111474',
      sufix: null,
      jobData: null,
      attachments: atts,
      account: this.env.hosting,    
      ref_id: '2276',
      ref_type: "JobSchedule",
      ClientId:'745'
    }

    console.log('dataObjjj',dataObj)

   
   this.crudService.sendmailSendgrid(dataObj,'commonTemplate2').subscribe((res2: any[]) => {
    console.log('res2',res2)

    this.loading = false;
    this.messageService.add({ severity: 'success', summary: 'Successful', detail: 'Email Sent', life: 1000 });
    });
        
  
  }

  async getInvoiceStatus() {
    let data: any = await this.crudService.getDataByField('JobAllocation', 'id', this.jobAllocationId).toPromise();
    if (data[0].inv_status == null) {
      this.isInvoiced = false;
    } else if (data[0].inv_status == '1') {
      this.isInvoiced = true;
    }
  }
}