import { Component, OnInit, Input } from '@angular/core';

@Component({
    selector: 'app-overlay-loader',
    templateUrl: './overlay-loader.component.html',
    styleUrls: ['./overlay-loader.component.scss'],
    providers: []
  })

  
  export class OverlayLoaderComponent implements OnInit {
    @Input() fullscreen: any;

    ngOnInit() {
      if(typeof this.fullscreen=== 'undefined'){
        if(this.fullscreen===null || this.fullscreen===''){
          this.fullscreen = false;
        }
        this.fullscreen = false;
      }
    }
}