import { Injectable } from '@angular/core';
import { Subject, Observable } from 'rxjs';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { map, delay } from 'rxjs/operators';
import { CRUDService } from './crud.service';
import * as moment from 'moment';
import { EncrDecrService } from './encr-decr.service';
import { environment } from 'src/environments/environment';

@Injectable({ providedIn: 'root' })
export class CommonService {
    private CommonSource = new Subject<string>();
    private resetSource = new Subject();
    CommonSource$ = this.CommonSource.asObservable();
    resetSource$ = this.resetSource.asObservable();
    userDetails: any;

    constructor(private crudService: CRUDService, private EncrDecr: EncrDecrService) {
    }

    getAppProperties(): any {
        return { dateFormat: 'dd-mm-yy' }
    }
    getCurrentDateTime(): any {
        const date = new Date();

        const DateTime =
            date.getFullYear() +
            '-' +
            ('00' + (date.getMonth() + 1)).slice(-2) +
            '-' +
            ('00' + date.getDate()).slice(-2)
            + ' ' + ('00' + date.getHours()).slice(-2) + ':' + ('00' + date.getMinutes()).slice(-2) + ':00';
        return DateTime;
    }

    getDate_DDMMYYYY(): any {
        const event = new Date();
        const options = { weekday: 'long', year: 'numeric', month: 'numeric', day: 'numeric' };
        return event.toLocaleDateString('en-AU', options);
    }

    getDateTime_DDMMYYYY(): any {
        const event = new Date();
        const options = { weekday: 'long', year: 'numeric', month: 'numeric', day: 'numeric' };
        return event.toLocaleDateString('en-AU', options) + ' ' + new Date().toLocaleTimeString();
    }
    async updateRecord(moduleName: any, data: any): Promise<any> {
        const user_id = JSON.parse(sessionStorage.getItem('user_id'));
        //console.log('update', data);
        let response: any;
        if (typeof data.id !== 'undefined') {
            if (data.id !== null && data.id !== '') {
                data.updated_at = this.getCurrentDateTime();
                data.updated_by = user_id;
                response = await this.crudService.Update(data, moduleName).toPromise();
                response.id = data.id;
            } else {
                data.updated_at = null;
                data.updated_by = null;
                data.created_at = moment(this.getCurrentDateTime()).format('MM/DD/YYYY HH:mm:ss');
                data.created_by = Number(user_id);
                if (typeof data.status === 'undefined') {
                    data.status = 1;
                }
                if (data.status === '' || data.status == null) {
                    data.status = 1;
                }
                response = await this.crudService.Create(data, moduleName).toPromise();
            }
        } else {
            data.updated_at = null;
            data.updated_by = null;
            data.created_at = moment(this.getCurrentDateTime()).format('MM/DD/YYYY HH:mm:ss');
            data.created_by = Number(user_id);
            if (typeof data.status === 'undefined') {
                data.status = 1;
            }
            if (data.status === '' || data.status == null) {
                data.status = 1;
            }
            response = await this.crudService.Create(data, moduleName).toPromise();
        }
        return response;
    }

    async saveRecord(moduleName: any, data: any): Promise<any> {
        const user_id = JSON.parse(sessionStorage.getItem('user_id'));
        let response: any;
        if (typeof data.id !== 'undefined') {
            if (data.id !== null && data.id !== '') {
                data.updated_at = this.getCurrentDateTime();
                if (user_id == null) {
                    data.updated_by = '1';
                } else {
                    data.updated_by = user_id;
                }

                response = await this.crudService.updateData(moduleName, data).toPromise();
                response.id = data.id;
            } else {
                data.updated_at = null;
                data.updated_by = null;
                data.created_at = moment(this.getCurrentDateTime()).format('MM/DD/YYYY HH:mm:ss');
                if (user_id == null) {
                    data.updated_by = '1';
                } else {
                    data.updated_by = user_id;
                }

                if (typeof data.status === 'undefined') {
                    data.status = 1;
                }
                if (data.status === '' || data.status == null) {
                    data.status = 1;
                }
                response = await this.crudService.createData(moduleName, data).toPromise();
            }
        } else {
            data.updated_at = null;
            data.updated_by = null;
            data.created_at = moment(this.getCurrentDateTime()).format('MM/DD/YYYY HH:mm:ss');
            //data.created_by = Number(userInfo.id);
            if (user_id == null) {
                data.created_by = '1';
            } else {
                data.created_by = user_id;
            }
            if (typeof data.status === 'undefined') {
                data.status = 1;
            }
            if (data.status === '' || data.status == null) {
                data.status = 1;
            }
            response = await this.crudService.createData(moduleName, data).toPromise();
            // console.log('responseee444',response)
        }
        return response;
    }

    async exportCheck(): Promise<Boolean> {
        const userInfo = JSON.parse(sessionStorage.getItem('loggedInUser'));
        //console.log(userInfo);
        let adminUsersList: any = await this.crudService.getByValuesetName('ExcelUsers').toPromise();//['1', '939', '503'];
        let index = adminUsersList.findIndex(val => (val.valueset_detail_name == userInfo.id));
        if (index > -1) {
            return true;
        } else {
            return false;
        }
    }

    async deleteCheck(): Promise<Boolean> {
         const userInfo = JSON.parse(sessionStorage.getItem('loggedInUser'));
        //console.log(userInfo);
        let adminUsersList: any = await this.crudService.getByValuesetName('ExcelUsers').toPromise();//['1', '939', '503'];
        let index = adminUsersList.findIndex(val=>(val.valueset_detail_name == userInfo.id));
        if(index > -1){
            return true;
        }else{
            return false;
        } 
        // return false;
    }

    async sendAttchmentMail(params, ref_id, ref_type): Promise<Boolean> {
        let response = await this.crudService.sendAttchmentMail(params, ref_id, ref_type).toPromise();
        return true;
    }


    async executeHook(componentDefnHookInfo, params): Promise<any> {
        let data: any = await this.crudService.getDataByField('Hooks', 'code', componentDefnHookInfo.hook_code).toPromise();
        let hookInfo = data[0];
        let result: any;
        let response: any = await this.crudService.executeHook(hookInfo.endpoint, hookInfo.method_type, params).toPromise();
        if (response.success == 'true' || response.success == '1') {
            result.status = true;
            result.data = response?.data;
        } else {
            result.status = false;
            result.message = response?.message;
        }
        return response;
    }

    async approveTP(company) {
        const userInfo = JSON.parse(sessionStorage.getItem('loggedInUser'));
        company.status = '1';
        company.regn_status = '2';
        company.created_at = this.getCurrentDateTime();
        company.remarks = 'Appoved on ' + this.getDateTime_DDMMYYYY() + ' by ' + userInfo.first_name + ' ' + userInfo.last_name;
        let data = await this.saveRecord('Company', company);
        await this.createCompanyNotes(company, 'Approved by ' + userInfo.first_name + ' ' + userInfo.last_name);
        this.sendNotificationMail(company.email, 'Registration Accepted',
            'Enviro Trees has approved your Business Profile registration.',
            'Hi ' + company.main_contact_name + ',',
            'Your registration for your business ' + company.business_name + ', has been approved. Your business is now eligible for receiving Jobs from Enviro Trees.',
            'Please login to the application to access Jobs when allocated.', null, environment.base_url, 'Login', null, null, null, null, null);
    }

    async reactivateTP(company) {
        const userInfo = JSON.parse(sessionStorage.getItem('loggedInUser'));
        company.status = '0';
        company.regn_status = '0';
        company.remarks = 'Re-activated on ' + this.getDateTime_DDMMYYYY() + ' by ' + userInfo.first_name + ' ' + userInfo.last_name;
        await this.saveRecord('Company', company);
        await this.createCompanyNotes(company, 'Reactication Link sent to ' + company.email);
        this.sendNotificationMail(company.email, 'Reactivated',
            'Your account has been Reactivated',
            'Hi ' + company.main_contact_name + ',',
            'Your account has been reactivated for updates. You can update your business profile using the link below.',
            null, null, environment.base_url+'#/registration/' + company.token, 'Complete Registration', null, null, null, null, null);
    }
    async rejectTP(company, reject_reason) {
        const userInfo = JSON.parse(sessionStorage.getItem('loggedInUser'));
        if (reject_reason != null && reject_reason !== '') {
            company.status = '0';
            company.regn_status = '3';
            company.remarks = 'Registration Rejected on ' + this.getDateTime_DDMMYYYY() + ' by ' + userInfo.first_name + ' ' + userInfo.last_name;
            await this.saveRecord('Company', company);
            await this.createCompanyNotes(company, reject_reason);
            this.sendNotificationMail(company.email, 'Registration Rejected',
                'Enviro Trees could not approve your request at this moment.',
                'Hi ' + company.main_contact_name + ',',
                'Your registration for your business ' + company.business_name + ', couldnt not be approved at this moment due to the following reason(s).'
                + reject_reason + '. If you still wish to get registered as a Tree Partner, please feel free to reach out to us.',
                null, null, environment.base_url, 'Register', null, null, null, null, null);
        }
    }
    async sendNotificationMail(email, subject, preheader, salutation, body, signofftext, footer, link, link_text, attachments, bcc, cc, ref_type, ref_id) {
        let params = {
            email: email,
            emailBody: {
                subject,
                preheader,
                salutation,
                body,
                signofftext,
                footer,
                link,
                link_text,
                bcc, //: 'venkat@agileitexperts.com',
                cc,
                attachments
            }
        }
        let response = await this.crudService.sendAttchmentMail(params, ref_id, ref_type).toPromise();
    }

    resendActivationLink(company) {
        debugger
        const userInfo = JSON.parse(sessionStorage.getItem('loggedInUser'));
        this.crudService.confirmRegistration(company.id, company.email).subscribe(async (mailData: any) => {
            let remarks = 'Activation Link sent to ' + company.email + ' on ' + this.getDateTime_DDMMYYYY() + ' by ' + userInfo.first_name + ' ' + userInfo.last_name;
            company.remarks = remarks;
            await this.saveRecord('Company', company);
            await this.createCompanyNotes(company, remarks);
        });
    }

    async createCompanyNotes(company, message) {
        const userInfo = JSON.parse(sessionStorage.getItem('loggedInUser'));
        let companyNotes = {
            pmonly: 2,
            message_flow: 2,
            message_received: 2,
            comm_recipient_subcatg: null,
            phoned: 0,
            sms: 0,
            email: 1,
            callback: 0,
            status: 1,
            app_type: null,
            follow_up_date: null,
            trainRequired: null,
            company_id: company.id,
            created_by: userInfo.id,
            created_at: new Date(),
            message: message,
            sms_no: null,
            emailaddress: company.email,
            phone: null,
        };
        await this.saveRecord('CompanyNotes', companyNotes);
    }

    async updateCompanyToken(comp): Promise<string> {
        let encrypted = this.EncrDecr.set('123456$#@$^@1ERF', 'PID_' + comp.id + '_COMP_ID_' + comp.id);
        encrypted = encodeURIComponent(encrypted)
        let data: any = await this.crudService.updateCompanyToken(comp.id, encrypted).toPromise();
        return encrypted;
    }

    async updateCompaniesToken(): Promise<any> {
        let Companies: any = await this.crudService.getDataByField('Company', 'client_type_id', '3').toPromise();
        Companies = Companies.filter(val => ((val.token == '' || val.token == null)
            && (val.regn_status == '0' || val.regn_status == '' || val.regn_status == null)
            && (val.status == '0')));
        await Companies.forEach(async comp => {
            let encrypted = this.EncrDecr.set('123456$#@$^@1ERF', 'PID_' + comp.id + '_COMP_ID_' + comp.id);
            encrypted = encodeURIComponent(encrypted)
            let data: any = await this.crudService.updateCompanyToken(comp.id, encrypted).toPromise();
        });
        return true;
    }

    async getMailerlist(): Promise<any>
    {
        let data : any = await this.crudService.getByValuesetName('mailer_list').toPromise();
        let emailsList = [];
        data.forEach(item => {
            emailsList.push(item.valueset_detail_desc);
        });
        return emailsList;
    }

    DateToLocalTimeString(d) {
        let date = new Date(d);
        d = date.toLocaleDateString('en-GB');
        let t = date.toLocaleTimeString();
        return d + ' ' + t;
    }
}
