import { Component, OnInit,Input } from '@angular/core';
import { CRUDService } from 'src/app/utilities/crud.service';
import { ConfirmationService } from 'primeng/api';
import { MessageService } from 'primeng/api';
import { Table } from 'primeng/table';
import { CommonService } from 'src/app/utilities/common.service';
import { environment } from 'src/environments/environment';
import { DynamicDialogConfig } from 'primeng/dynamicdialog';

@Component({
  selector: 'app-defn-child',
  templateUrl: './defn-child.component.html',
  styleUrls: ['./defn-child.component.scss' , '../admin.component.scss']
})
export class DefnChildComponent implements OnInit {
  loading:Boolean=false;
  paginationvalue:any;
  cols: any[];
  exportColumns: any[];
  rateClassDialog = false;
  submitted: boolean;
  statuses: any[];
  rateSetClassList: any[] = [];
  rateSetClass: any;
  selectedRateSetClass: any[];
  rateSetList: any[] = [];
  selectedRateSet: any;
  isActive: boolean;
  companyId: any;
  speedDial: boolean = false;
  isEditAll: boolean = false;
  exportdisplay:Boolean = true;
  displayDelete:Boolean = true;
  disableNew:boolean = false;
  searchvalue: any;
  @Input() defnHead: any;
  defnTitle:any;
  childData:any[] = [];
  defnHeadList:any;
  viewFieldMappingDialog:Boolean=false;
  mappingFields:any;
  parentFields:any;
  childFields:any;
  parentKey:any;
  childKey:any;
  mappingList:any;
  cdmId:any;

  

  constructor(private crudService: CRUDService,
    private commonservice: CommonService,
    private messageService: MessageService,
    private confirmationService: ConfirmationService,
    public config: DynamicDialogConfig,) { }

  async ngOnInit() {
    if (this.config && this.config.data) {
      this.defnHead = this.config.data.code;
      this.defnTitle=this.config.data.title;
    }
    this.loading=true;
    this.paginationvalue=environment.paginatorValue;
    this.companyId = sessionStorage.getItem('company_id');
  
    await this.crudService.getAllData('ComponentDefnHead').subscribe(async (defnData: any[]) => {
      await this.crudService.getDataByField('ComponentDefnMapping','parent_code',this.defnHead).subscribe((childData: any[]) => {
        this.defnHeadList = defnData;
        console.log("This Child Data",this.childData);
        childData.forEach(data => {
          let result = this.defnHeadList.find(val => (val.code === data.child_code));
          if (result) {
            data.selecteddefnhead = result;
          } else {
            data.selecteddefnhead = null;
          }

          if(data.status == '2'){
            data.status_desc = 'IN-Active';
          }else{
            data.status_desc = 'Active';
          }

          if(data.map_type == '1' || data.map_type == null){
            data.map_type_desc = 'Multiple';
          }else{
            data.map_type_desc = 'Single';
          }

          if(data.cust_yn == '1'){
            data.cust_yn_desc = 'Yes';
          }else{
            data.cust_yn_desc = 'No';
          }
          this.childData.push(data);
        });
      });
      this.exportdisplay = await this.commonservice.exportCheck();
      this.displayDelete = await this.commonservice.deleteCheck();
    });

    this.cols = [
      { field: 'child_code', header: 'Child Code' },
      { field: 'seq', header: 'Sequence' },
      { field: 'map_type', header: 'Map Type' },
      { field: 'Status', header: 'Status' },
      { field: 'cust_yn', header: 'Customization' }
    ];

    this.exportColumns = this.cols.map(col => ({ title: col.header, dataKey: col.field }));
    this.loading=false;
  }

  findIndexById(id: string): number {
    let index = -1;
    for (let i = 0; i < this.rateSetClassList.length; i++) {
      if (this.rateSetClassList[i].id === id) {
        index = i;
        break;
      }
    }
    return index;
  }

  exportPdf() {
    import('jspdf').then(jsPDF => {
      import('jspdf-autotable').then(x => {
        /* const doc = new jsPDF.default(0,0);
        doc.autoTable(this.exportColumns, this.selectedDepots);
        doc.save('products.pdf'); */
      })
    })
  }

  exportExcel(selectedRateSet) {
    import('xlsx').then(xlsx => {
      const worksheet = xlsx.utils.json_to_sheet(this.selectedRateSetClass);
      const workbook = { Sheets: { data: worksheet }, SheetNames: ['data'] };
      const excelBuffer: any = xlsx.write(workbook, { bookType: 'xlsx', type: 'array' });
      this.saveAsExcelFile(excelBuffer, 'depots');
    });
  }

  saveAsExcelFile(buffer: any, fileName: string): void {
    import('file-saver').then(FileSaver => {
      const EXCEL_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
      const EXCEL_EXTENSION = '.xlsx';
      const data: Blob = new Blob([buffer], {
        type: EXCEL_TYPE
      });
      FileSaver.saveAs(data, fileName + '_export_' + new Date().getTime() + EXCEL_EXTENSION);
    });
  }

  clear(table: Table) {
    table.clear();
    this.searchvalue = "";
  }

 viewFieldMapping(childData)
  {
    this.loading=true;
    this.crudService.getDataByField('ComponentDefnDetail','component_code',childData.parent_code).subscribe((pData: any) => {
      this.parentFields = pData;
    });
    this.crudService.getDataByField('ComponentDefnDetail','component_code',childData.child_code).subscribe((cData: any) => {
      this.childFields = cData;
    });
   
    this.mappingFields={
      created_at: null,
      created_by: null,
      id: null,
      last_modified_by: null,
      last_updated_at: null,
      cdm_id:childData.id,
      ckey_field_name:null,
      pkey_field_name:null
    }
    this.viewFieldMappingDialog=true;
    this.cdmId=childData.id;
    this.loadMapList(this.cdmId);
    this.loading=false;
  }

  loadMapList(cdmId)
  {
    this.loading=true;
    this.crudService.getDataByField('ComponentDefnFieldMapping','cdm_id',cdmId).subscribe((mData: any) => {
      this.mappingList = mData;
    });
    this.loading=false;
  }

 async saveRecord()
  {
    this.loading=true;
    this.mappingFields.pkey_field_name=this.parentKey.field_name;
    this.mappingFields.ckey_field_name=this.childKey.field_name;
    debugger
    let response: any = await this.commonservice.saveRecord('ComponentDefnFieldMapping', this.mappingFields);
    if(response.success=="1")
    {
      this.messageService.add({ severity: 'success', summary: 'Successful', detail: 'Fields Mapped Successfully', life: 1000 });
      this.loadMapList(this.mappingFields.cdm_id);
    }
    else
    {
      this.messageService.add({ severity: 'error', summary: 'Failed', detail: 'Failed', life: 1000 });
    }
    this.loading=false;
  }

  deleteMappingData(id,cdm_id)
  {
    debugger
    this.crudService.deleteRecordByID('ComponentDefnFieldMapping',id).subscribe((response: any) => {
      if(response.success=="1")
    {
      this.messageService.add({ severity: 'success', summary: 'Successful', detail: 'Deleted Successfully', life: 1000 });
      this.loadMapList(cdm_id);
    }
    else
    {
      this.messageService.add({ severity: 'error', summary: 'Failed', detail: 'Failed', life: 1000 });
    }
    });
  }


  deleteRecord(childData: any) {
    this.confirmationService.confirm({
      message: 'Are you sure you want to Delete?',
      header: 'Confirm',
      icon: 'pi pi-exclamation-triangle',
      accept: () => {
        //console.log(rateclass.id);
        this.crudService.deleteRecordByID('ComponentDefnMapping',childData.id).subscribe((data: any) => {
          if (data.success == 1) {
            this.messageService.add({ severity: 'success', summary: 'Successful', detail: 'Rateset Class Deleted', life: 1000 });
            this.childData = this.childData.filter(val => val.id !== childData.id);
          }
        });
      }
    });
  }
  

  hideDialog() {
    this.viewFieldMappingDialog = false;
    this.submitted = false;
  }

  addNewRecord(){
  let childData = {
      created_at: null,
      created_by: null,
      id: null,
      last_modified_by: null,
      last_updated_at: null,
      parent_code:this.defnHead,
      map_type:1,
      child_code:null,
      seq:null,
      status: '1',
      isEdit:true
    };
    this.childData = [childData, ...this.childData]; 
    console.log(this.childData);
    this.disableNew = true;
  }


    async saveRecords(){
      debugger
      this.submitted = true;
      let saveStatus = true;
      let changedRecords = this.childData.filter(val=> (val.changed === true));
      if(changedRecords.length !=0){
        changedRecords.forEach(async childData=> {
          let status = await this.saveRecordNew(childData);
          if (!status){
            saveStatus = false;
            
          }
        });
        if(saveStatus){
          // SuccessMessage
          this.messageService.add({ severity: 'success', summary: 'Successful', detail: changedRecords.length+' Records Updated', life: 1000 });
        }
      }
      this.disableNew=false;
      this.isEditAll=false;
      let editedRecords = this.rateSetClassList.filter(val=> (val.isEdit === true));
      editedRecords.forEach(element => {
        element.isEdit = false;
      });
    }

    async saveRecordNew(childData){
      debugger
      let status: Boolean = true;
      if (childData.child_code.trim()) {
      if(childData.id){
        if(childData.isActive){
          childData.status='1';
        }
        if(!childData.isActive){
          childData.status='2';
        }

        if(childData.cust_ynisActive){
          childData.cust_yn='1';
        }
        if(!childData.cust_ynisActive){
          childData.cust_yn='2';
        }

        if(childData.map_typeisActive){
          childData.map_type='1';
        }
        if(!childData.map_typeisActive){
          childData.map_type='2';
        }
        
        let data: any = await this.commonservice.saveRecord('ComponentDefnMapping', childData);
        if(data.success == '1'){
          childData.changed = false;
        }else{
          status = false;
        }
      }else{
        childData.status='1'
        childData.isActive=true;
        childData.cust_yn='1'
        // //console.log("this is working",itemdefinitions);
        let data: any = await this.commonservice.saveRecord('ComponentDefnMapping', childData);
        if(data.success == '1'){
          childData.changed = false;
        }else{
          status = false;
        }
      }
      }else{
        status = false;
        // //console.log("This is not working");

      }
      return status;
      }

      onSelectedItemChange(event, childData){
        //console.log("This is working",rateclass);
        debugger
        childData.child_code = event.value?.code;
        childData.changed=true;
        console.log("ChangeD Data",this.childData);
      }
      resetLastCheckPoint(rateclass){
        //console.log(rateclass);
        rateclass = rateclass.backup; 
        //console.log(rateclass);
      }
      backup(rateclass){
        //console.log("backup",rateclass);
        rateclass=rateclass.backup;
      }

}