import { Component, Input, OnInit } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { ActivatedRoute } from '@angular/router';
import { ConfirmationService, MessageService } from 'primeng/api';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { CRUDService } from 'src/app/utilities/crud.service';
import { environment } from 'src/environments/environment';
import { DatePipe } from '@angular/common';
import { MenuItem } from 'primeng/api';

@Component({
  selector: 'app-view-audit-child',
  templateUrl: './view-audit-child.component.html',
  styleUrls: ['./view-audit-child.component.scss']
})
export class ViewAuditChildComponent implements OnInit {

  @Input() table_name: any;
  @Input() key_column: any;
  @Input() parent_table: any;
  @Input() pk_id: any;
  
  paginationvalue:any;
  selectedTypeList: any = [];
  cols: any[];
  exportColumns: any[];
  loading: Boolean = false;
  isExport: boolean=true;
  isDelete: boolean=true;
  showDataDialog : boolean=false;
  selectedroute: string;
  selectedAuditList : any = [];
  auditList : any=[];
  JSONauditList : any;
  changeTypeList : any;
  popUpData : any;
  dataTemp : any;
  selchange_type:any;
  key_name:any;
  key_namecol:any;
  items: MenuItem[];
  childList : any;
  selectedTablename : any;

  constructor(
    public DatePipe : DatePipe,
    private CrudService: CRUDService,
    private messageService: MessageService,
    private confirmationService: ConfirmationService,
    public ref: DynamicDialogRef,
    public config: DynamicDialogConfig,
    public sanitizer: DomSanitizer,
    private route: ActivatedRoute
  ) { 
    this.items = [];
  }

  async ngOnInit(): Promise<void> {
    this.paginationvalue=environment.paginatorValue;
    this.loading = true;
    //'JobNo','JobDate','InvoiceDt','TreeProfessional',
    //	'ClientSubtype','ClaimNumber','RiskAddress','JobStatus','TPCost','WPCost'
    this.selectedAuditList = null;
    this.cols = [
      { field: 'table_name', header: 'Table Name' },
      { field: 'key_name', header: 'Key Value' },
      { field: 'change_type', header: 'Change Type' },
      { field: 'change_date', header: 'Change Date' },
      { field: 'updated_byname', header: 'Updated By' },
      { field: 'data', header: 'Data' }
    ];
    this.exportColumns = this.cols.map(col => ({ title: col.header, dataKey: col.field }));
    this.changeTypeList = [
      { id: 'Insert', value: 'Insert' },
      { id: 'Update', value: 'Update' },
      { id: 'Delete', value: 'Delete' }
    ];


    let auditModulesData = await this.CrudService.getDataByField('AuditModules', 'table_name', this.table_name).toPromise();
    this.key_namecol = auditModulesData[0].key_name;

    let query = "SELECT * FROM Audit_Logs where table_name ='"+ this.table_name +"' AND pk_id IN (SELECT id from "+ this.table_name +" where "+ this.key_column +"="+ this.pk_id +")"
    console.log("query",query)
    console.log("key_namecol",this.key_namecol)
    await this.CrudService.ExecuteQuery(query).subscribe(async (data: any[]) => {
      this.auditList = data;
      this.auditList.forEach(async element => {
        let moduleData = await this.CrudService.getDataByField(this.table_name, 'id', element.pk_id).toPromise();
        let keyNameData = moduleData[0];
          element.key_name = keyNameData[this.key_namecol];
       await this.CrudService.getDataByField('users', 'id', element.updated_by).subscribe((userData: any[]) => {
          element.updated_byname = userData[0].first_name
        });
      });
      this.JSONauditList = this.auditList;
    });
    let per_data :any=await this.CrudService.getmenupermissions()
    // this.isExport = per_data.isExport;
    // this.isDelete = per_data.isDelete;
    this.loading = false;
  }

   exportExcel(table) {
    this.loading = true;
    let JsonData: any[] = [];
    if (this.selectedAuditList != null && this.selectedAuditList.length > 0) {
      JsonData = this.selectedAuditList;
    } else {
      JsonData = this.JSONauditList;
      if (typeof table.filteredValue !== 'undefined') {
        if (table.filteredValue !== null) {
          if (table.filteredValue.length !== this.JSONauditList.length && table.filteredValue.length > 0) {
            JsonData = table.filteredValue;
          }
        }
      }
    }

    let reformattedArray = JsonData.map(obj => {
      let rObj = {};
      this.cols.forEach(pair => {
        rObj[pair.header.toString()] = obj[pair.field];
      });
      return rObj;
    })

    import('xlsx').then(xlsx => {
      const worksheet = xlsx.utils.json_to_sheet(reformattedArray);
      const workbook = { Sheets: { data: worksheet }, SheetNames: ['data'] };
      const excelBuffer: any = xlsx.write(workbook, { bookType: 'xlsx', type: 'array' });
      this.saveAsExcelFile(excelBuffer, 'Job-margin');
    });
    this.loading = false;
  }

  saveAsExcelFile(buffer: any, fileName: string): void {
    import('file-saver').then(FileSaver => {
      const EXCEL_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
      const EXCEL_EXTENSION = '.xlsx';
      const data: Blob = new Blob([buffer], {
        type: EXCEL_TYPE
      });
      let timeStamp = this.DatePipe.transform(new Date(), 'ddMMyy_HHmm');
      FileSaver.saveAs(data, fileName + '_' + timeStamp + EXCEL_EXTENSION);
    });
  }

  exportPdf() {
    import('jspdf').then(jsPDF => {
      import('jspdf-autotable').then(x => {
        /* const doc = new jsPDF.default(0,0);
        if(typeof this.selectedDepots == 'undefined'){
            doc.autoTable(this.exportColumns, this.depots);
           }else{
               doc.autoTable(this.exportColumns, this.selectedDepots);
        }
       doc.save('products.pdf'); */
      })
    })
  }

  async filterByType(datas): Promise<any> {
    let list: any[] = [];
    this.selectedTypeList.forEach(async type => {
      let filteredList = datas.filter(val => val.change_type === type.value);
      if (list.length > 0) {
        list = list.concat(filteredList);
      } else {
        list = filteredList;
      }
    })
    return list;
  }

  async filterRecord(event) {
    this.loading = true;
    let list1: any[] = [];

    if (this.selectedTypeList.length > 0) {
      list1 = await this.filterByType(this.auditList);
    } else {
      list1 = this.auditList;
    }


    this.JSONauditList = list1;
    this.loading = false;
  }

  hideDialog() {
    this.showDataDialog = false;
  }
  showDialog(data,change_type)
  {
    this.selchange_type = change_type
    if(change_type == "Insert" || change_type == "Delete")
    {
      const jsonObject = JSON.parse(data);
      let jsonData: any = jsonObject[0];
      this.dataTemp = jsonData;
      let dats = this.getObjectKeys(jsonData);
      this.popUpData = dats;
    }
    else
    {
      const jsonObject = JSON.parse(data);
      let jsonData: any = jsonObject.Changes;
      this.dataTemp = jsonData;
     // let dats = this.getObjectKeys(jsonData);
      this.popUpData = jsonData;
    }
   
    this.showDataDialog = true;
  }
  getObjectKeys(jsonData: any): string[] {
    return Object.keys(jsonData);
  }
  extractValueByKey(key) {
        return this.dataTemp[key];
  }

  selectData(auditData){
   this.pk_id = auditData.pk_id
   this.selectedTablename = auditData.table_name
  }

  showChild(table_name,key_column){
    console.log("pk_id",this.pk_id);
    console.log("selectedTablename",this.selectedTablename);
    console.log("table_name",table_name);
    console.log("key_column",key_column);
  }

 
}


