import { Component, Input } from '@angular/core';
import { FormGroup } from '@angular/forms';

@Component({
    selector: 'dropdown',
    template: `
      <div [formGroup]="form">
      <select class="form-control" [id]="field.name" [formControlName]="field.name" [attr.required]="field.required">
          <option *ngFor="let opt of field.options" [value]="opt.code">{{opt.description}}</option>
        </select>
      </div> 
      <div class="alert alert-danger my-1 p-2 fadeInDown animated" *ngIf="!isValid && isDirty" > Select {{field.description}} </div>
    `
})
export class DropDownComponent {
    @Input() field:any = {};
    @Input() form:FormGroup;
    get isValid() { return this.form.controls[this.field.name].valid; }
    get isDirty() { return this.form.controls[this.field.name].dirty; }
    constructor() {

    }
}