import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import * as moment from 'moment';
import { WorkFlowService } from 'src/app/utilities/workflow.service';
import { CommonService } from 'src/app/utilities/common.service';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { MessageService } from 'primeng/api';
@Component({
  selector: 'app-app-reminder',
  templateUrl: './app-reminder.component.html',
  styleUrls: ['./app-reminder.component.css']
})
export class AppReminderComponent implements OnInit {
  showEvent: boolean = true;
  appNotificationForm: FormGroup;
  submitted: boolean = false;
  selectedItems: any = [];
  appProps: any;
  allocationFilterTypes = [
    { id: 1, title: 'Enviro', selected: false },
    { id: 2, title: 'Contractor', selected: false },
    { id: 3, title: 'Client', selected: false }
  ];
  appReminder: any = {};

  constructor(private fb: FormBuilder, private WorkFlowService: WorkFlowService, public ref: DynamicDialogRef, public config: DynamicDialogConfig, private messageService: MessageService,private commonservice: CommonService) { }

  ngOnInit(): void {
    //console.log('test');
    this.appProps = this.commonservice.getAppProperties();
    this.appReminder.job_id = this.config.data?.job_id;
    this.appReminder.alloc_id = this.config.data?.alloc_id;
    this.appReminder.wf_step = this.config.data?.wf_step;
    this.appReminder.task_id = this.config.data?.task_id;
    this.appNotificationForm = this.fb.group({
      followup_date: ['', Validators.required],
      remarks: ['', [Validators.required, Validators.maxLength(280)]]
    })
  }

  filterAllocation(item) {
    item.selected = !item.selected;
    if (item.selected) {
      this.selectedItems.push(item.title);
    } else {
      this.selectedItems.splice(this.selectedItems.indexOf(item.title), 1);
    }
  }

  async closeDialog() {
    this.ref.close({  });
  }

  submitData() {
    this.submitted = true;
    if (this.appNotificationForm.valid) {
      if (this.selectedItems.length > 0) {
        this.appReminder.category_type = null;
        this.appReminder.category_value = null;
        //console.log(this.appNotificationForm.controls.followup_date.value);
        this.appReminder.target_date = moment(this.appNotificationForm.controls.followup_date.value).format('MM/DD/YYYY HH:mm:ss');
        this.appReminder.status = '1'
        this.appReminder.message = this.appNotificationForm.controls.remarks.value;
        this.selectedItems.forEach(async target => {
          this.appReminder.reminder_target = target;

        //  console.log('innn',this.appReminder);
          let response = await this.WorkFlowService.updateRecord('AppReminders', this.appReminder);
          console.log('out',response);
          if (response.success === 1 || response.success === '1') {
            this.closeDialog();
          }
        });
      } else {
      this.messageService.add({ severity: 'error', summary: 'Details Required', detail: 'Details cant be left blank.', life: 2000 });
      }
    }
  }
}
