import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule,FormsModule } from '@angular/forms';

import { FormbuilderRoutingModule } from './formbuilder-routing.module';
import { FormbuilderComponent } from './formbuilder.component';
import { FieldBuilderComponent } from './field-builder/field-builder.component';
import { TextComponent } from './components/text';
import{NumberComponent}from './components/number'
import { TextBoxComponent } from './components/textbox';
import { DropDownComponent } from './components/dropdown';
import { CheckBoxComponent } from './components/checkbox';
import { RadioComponent } from './components/radio';
import{EmailComponent} from'./components/email';
import{AddressComponent} from'./components/address';
import {InlineFieldBuilderComponent } from './field-builder/inlinefield-builder';
import{DateComponent} from './components/date'
import { DateTimeComponent } from './components/datetime';
import { PhoneNumberComponent } from './components/phonenumber';
import { UrlComponent } from './components/url';
import { CalendarModule } from 'primeng/calendar';
import { TableComponent } from './table/table.component';
import {SlideMenuModule} from 'primeng/slidemenu';

import { TableModule } from 'primeng/table';
import { TooltipModule } from 'primeng/tooltip';
import { InputMaskModule } from 'primeng/inputmask';
import { InputNumberModule } from 'primeng/inputnumber';
import { InputSwitchModule } from 'primeng/inputswitch';
import { InputTextModule } from 'primeng/inputtext';

@NgModule({
  imports: [
    CommonModule,InputTextModule,SlideMenuModule,
    ReactiveFormsModule,CalendarModule,TableModule,FormsModule,TooltipModule,InputMaskModule,InputNumberModule,InputSwitchModule,
  ],
  declarations: [
    FormbuilderComponent,
    FieldBuilderComponent,
    TextBoxComponent,
    DropDownComponent,
    CheckBoxComponent,
    RadioComponent,InlineFieldBuilderComponent,
    TextComponent,
    NumberComponent,
    EmailComponent,
    AddressComponent,
    DateComponent,
    DateTimeComponent,
    PhoneNumberComponent,
    UrlComponent,
    TableComponent
  ],
  exports: [FormbuilderComponent,FieldBuilderComponent,InlineFieldBuilderComponent,TableComponent],
})
export class FormbuilderModule { }


