import { Component, OnInit } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { ActivatedRoute } from '@angular/router';
import { ConfirmationService, MessageService } from 'primeng/api';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { CRUDService } from 'src/app/utilities/crud.service';
import { CommonService } from 'src/app/utilities/common.service';
import { DatePipe } from '@angular/common';
import { environment } from 'src/environments/environment';
@Component({
  selector: 'app-ratecard-report',
  templateUrl: './ratecard-report.component.html',
  styleUrls: ['./ratecard-report.component.scss'],
  providers: [DatePipe]
})
export class RateCardReportComponent implements OnInit {
  cols: any;
  selectedRecords: any;
  dataList: any[];
  exportColumns: any;
  speedDial: Boolean = false;
  allStaff: any[];
  exportdisplay: Boolean = false;
  loading: Boolean = false;
  paginationvalue:any;
  selectedroute: string;
  isExport: boolean =true;
  isDelete: boolean =true;
  constructor(

    private CrudService: CRUDService,
    private messageService: MessageService,
    private confirmationService: ConfirmationService,
    public ref: DynamicDialogRef,
    public config: DynamicDialogConfig,
    public sanitizer: DomSanitizer,
    private route: ActivatedRoute,
    private datePipe: DatePipe,
    private commonservice: CommonService
  ) { }

  async ngOnInit() {
    this.paginationvalue=environment.paginatorValue;
    this.selectedRecords = null;
    this.cols = [
      { field: 'ItemName', header: 'Item Name' },
      { field: 'RatesetName', header: 'Rateset Name' },
      { field: 'RateClassName', header: 'Rate Class Name' },
      { field: 'invoice_rate', header: 'Invoice Rate' },
      { field: 'contractor_rate', header: 'Contractor Rate' }
    ];


    this.exportColumns = this.cols.map(col => ({ title: col.header, dataKey: col.field }));
    this.selectedroute = sessionStorage.getItem('selectedRoute')
    let per_data :any=await this.CrudService.getmenupermissions()
    this.isExport = per_data.isExport;
    this.isDelete = per_data.isDelete;
    this.CrudService.getAllItemRatesListing().subscribe((res: any) => {
      //console.log(res);
      this.dataList = [];
      this.dataList = res;
    });

    this.exportdisplay = await this.commonservice.exportCheck();
    this.loading = false;
  }
  exportExcel(selectedRecords) {
    if (this.selectedRecords != null && this.selectedRecords.length > 0) {
      import('xlsx').then(xlsx => {
        const worksheet = xlsx.utils.json_to_sheet(this.selectedRecords);
        const workbook = { Sheets: { data: worksheet }, SheetNames: ['data'] };
        const excelBuffer: any = xlsx.write(workbook, { bookType: 'xlsx', type: 'array' });
        this.saveAsExcelFile(excelBuffer, 'dataList');
      });
    } else {
      import('xlsx').then(xlsx => {
        const worksheet = xlsx.utils.json_to_sheet(this.dataList);
        const workbook = { Sheets: { data: worksheet }, SheetNames: ['data'] };
        const excelBuffer: any = xlsx.write(workbook, { bookType: 'xlsx', type: 'array' });
        this.saveAsExcelFile(excelBuffer, 'dataList');
      });
    }
  }

  saveAsExcelFile(buffer: any, fileName: string): void {
    import('file-saver').then(FileSaver => {
      const EXCEL_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
      const EXCEL_EXTENSION = '.xlsx';
      const data: Blob = new Blob([buffer], {
        type: EXCEL_TYPE
      });
      let timeStamp = this.datePipe.transform(new Date(), 'ddMMyy_HHmm');
      FileSaver.saveAs(data, fileName + '_' + timeStamp + EXCEL_EXTENSION);
    });
  }

  exportPdf() {
    import('jspdf').then(jsPDF => {
      import('jspdf-autotable').then(x => {
        /* const doc = new jsPDF.default(0,0);
        if(typeof this.selectedDepots == 'undefined'){
            doc.autoTable(this.exportColumns, this.depots);
           }else{
               doc.autoTable(this.exportColumns, this.selectedDepots);
        }
       doc.save('products.pdf'); */
      })
    })
  }



}