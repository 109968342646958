import { HttpClient } from '@angular/common/http';
import { Component, Input, OnInit, Output, EventEmitter,ChangeDetectorRef } from '@angular/core';
import { ConfirmationService, MessageService } from 'primeng/api';
import { DynamicDialogConfig, DynamicDialogRef, DialogService } from 'primeng/dynamicdialog';
import { CRUDService } from 'src/app/utilities/crud.service';
import { FileSaver } from 'file-saver';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { FileUploader } from 'ng2-file-upload';
import { DomSanitizer } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { JOBService } from 'src/app/utilities/job.service';
import { environment } from 'src/environments/environment';
import { S3Service } from 'src/app/utilities/s3.service';
import { CommonService } from 'src/app/utilities/common.service';

import { Dialog } from 'primeng/dialog';
@Component({
  selector: 'app-job-documents',
  templateUrl: './job-documents.component.html',
  styleUrls: ['./job-documents.component.scss'],

})
export class JobDocumentsComponent implements OnInit {

  @Output() refereshFlag: EventEmitter<any> = new EventEmitter();
  documentValue: boolean = false
  documents: any[];
  document: any;
  description: any[];
  uploadAllocationList: any[];
  units = ['bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
  currentUser: any;
  loggedInUser: any;
  addNewDocument: any;
  included_value: any = false;
  uploader: FileUploader;
  loading: any= true;
  submitted: any;
  isDocumentUploaded: any = false;
  @Input() jobId: any;
  @Input() jobAllocationId: any;
  @Input() jobSummary: any;
  @Input() selectedJobAllocId: any;
  showHeader = false;
  AllocationList: any[];
  allocationdisable: Boolean = false;
  acceptFileTypes: string;
  env:any;
  videoUploadCheck:Boolean =false;
  uploadVideos: any = [];
  visible: boolean;
  sendlinkvia: any;
  btnvisible: boolean;
  selectedVideo: any;
  message: string;
  Recipient: any[];
  selectedRecipient: any = null;

  selectdEmailId: any[] = [];
  jobAllocationResults: any;

  SMSAddressforComms: any;

  mailIdOptions: string[] = [];
  emailId: any;
  emailsList: any;
  showError: boolean = false;
  emailaddress: string;
  videoUrl: any;
  displayVideo : boolean = false;

  constructor(
    private CrudService: CRUDService,    private commonservice: CommonService,
    private messageService: MessageService,
    private confirmationService: ConfirmationService,
    public ref: DynamicDialogRef,
    public config: DynamicDialogConfig,
    private route: ActivatedRoute,
    public sanitizer: DomSanitizer,
    private router: Router,
    private dialogService: DialogService,
    private http: HttpClient,
    private JobService: JOBService,private s3Service: S3Service,
    private cdr: ChangeDetectorRef
  ) {
    this.currentUser = JSON.parse(sessionStorage.getItem('currentUser'));
    this.loggedInUser = JSON.parse(sessionStorage.getItem('loggedInUser'));
  }

  async ngOnInit() {
    this.env = environment;
    this.acceptFileTypes = environment.acceptFiletype;
    this.emailsList = await this.commonservice.getMailerlist();
    if (typeof this.jobId === 'undefined') {
      if (typeof this.config.data == 'undefined' || typeof (this.config.data.jobId) == 'undefined') {
        if (this.jobId == null || this.jobId === '') {
          this.jobId = parseInt(this.route.snapshot.paramMap.get('jobId'));
          if (this.jobAllocationId === null || this.jobAllocationId === '') {
            this.jobAllocationId = '1';
          } else {
            this.jobAllocationId = parseInt(this.route.snapshot.paramMap.get('jobAllocationId'));
            this.jobSummary = this.CrudService.getDataByField('JobInfo', 'id', this.jobId);
          }
        }
        this.showHeader = false;
      } else {
        this.jobId = this.config.data.jobId;
        this.jobAllocationId = this.config.data.jobAllocationId;
        this.jobSummary = this.config.data.jobSummary;
        this.showHeader = true;
      }
    }
    this.getUploadDocuments();
debugger;
    this.CrudService.getDataByField('businessSettings', 'company_id', '108').subscribe(async (data: any[]) => {
      let  videoOut = data[0].videoUpload;
    // console.log('videoOut',videoOut)
      if(videoOut == 1){
       this.videoUploadCheck =true;

       let videoData = await this.CrudService.getVideosData( this.jobId, this.jobAllocationId).toPromise();
       
       //console.log("videoData111111", videoData);
      // let videoData = await this.CrudService.getDataByField('uploadvideos', 'job_id', this.jobId).toPromise();
       this.uploadVideos = videoData;
       if(this.uploadVideos.length>0)
       {
        
        this.uploadVideos.forEach(element => {
          const url: string = element.file_name; // Replace this with your actual URL
          const filename: string = url.split('/').pop() || '';
          const decodedFileName = decodeURIComponent(filename);
          element.filename = decodedFileName
        });
       }
      //  else
      //  {
      //   this.videoUploadCheck = false;
      //  }
       
      }
      else{
       this.videoUploadCheck = false;
      }
    });

    let details: any = await this.CrudService.getByValuesetName('CommsCatgType').toPromise();
    this.Recipient = details;
console.log('this.Recipient',this.Recipient)
this.selectedRecipient = null;


    this.documentsTab();
    if(this.jobAllocationId == '1'){
      await this.fetchAllAllocation();
    }else{
      this.getUploadDocuments();
    }
    this.uploader = new FileUploader({
      disableMultipart: true, // 'DisableMultipart' must be 'true' for formatDataFunction to be called.
      formatDataFunctionIsAsync: true,
      formatDataFunction: async (item) => {
        return new Promise((resolve, reject) => {
          resolve({
            name: item._file.name,
            length: item._file.size,
            contentType: item._file.type,
            date: new Date()
          });
        });
      }
    });
  }


  addMailId(mailFieldValue, arrayObject) {
    if (typeof mailFieldValue !== 'undefined' && mailFieldValue !== null && mailFieldValue !== '') {
      if (this.emailsList.includes(mailFieldValue)) {
        if (arrayObject.length > 0) {
          let index = arrayObject.findIndex((res: any) => res.value == mailFieldValue);
          if (index > -1) {
          }else{
            arrayObject.push({ value: mailFieldValue });
          }
        }else{
          this.showError = false;
          arrayObject.push({ value: mailFieldValue });
        }
      }else {
        if (this.emailValidation(mailFieldValue)) {
          let index = arrayObject.findIndex((res: any) => res.value == mailFieldValue);
          if (index < 0) {
            arrayObject.push({ value: mailFieldValue });
          }
        }
      }
    }
    this.emailId = null;
    mailFieldValue = null;
  }


  emailValidation(val: any) {
    var regex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/; // Uppercase character pattern
    if (regex.test(val)){
      return true;
    }else{
      return false;
    }
  }


  selectId(value, arrayObject) {
    this.addMailId(value, arrayObject);
    value = null;
  }

  searchMail(event) {
    this.showError = true
    this.mailIdOptions = [];
    let value = event.query;
    let option = this.emailsList.filter(val => val.includes(value));
    if (option.length > 0) {
      this.mailIdOptions = option;
    } else {
      if (value.includes('@') && (value.includes('.co')) && (value.includes('.in'))) {
        let mailId = event.query.replaceAll(',', '').replaceAll(';', '');
        this.mailIdOptions.push(mailId);
      }
    }
    this.emailId = '';
    this.emailId = event.query;
    if (this.emailValidation(event.query.toLowerCase())) {
      this.showError = false
    }
    // this.dummy=[];
  }

  
  async resetInput() {
    //  if (this.job_alloc_id !== '1') {
      let smryData: any = await this.CrudService.getJobSummary(this.jobId, this.jobAllocationId).toPromise();
      console.log('smryData[0]',smryData[0])
      
      this.jobAllocationResults = await this.JobService.getJobInfo(smryData[0]);

      console.log('jobAllocationResultsss',this.jobAllocationResults);
      
        let SMSAddressforComms = null;
        this.selectdEmailId = [];
        if (this.selectedRecipient.id == '10135') {
          if (this.jobAllocationResults?.info?.SiteContactMobile !== 'undefined') {
            SMSAddressforComms = this.jobAllocationResults?.info?.SiteContactMobile;
          }
        } else if (this.selectedRecipient.id === '10136') {
          SMSAddressforComms = this.jobAllocationResults?.info?.TPMobile;
        } else if (this.selectedRecipient.id === '10137') {
           SMSAddressforComms = null;
           SMSAddressforComms = this.jobAllocationResults?.info?.ClientContact.split('-').pop().trim();
        } else if (this.selectedRecipient.id === '10138') {
  
        }
        this.SMSAddressforComms = SMSAddressforComms;

        console.log('SMSAddressforComms',SMSAddressforComms);
  
        let emailAddressforComms = null;
        if (this.selectedRecipient.id === '10135') {
          emailAddressforComms = this.jobAllocationResults?.info?.SiteContactEmail;
        } else if (this.selectedRecipient.id === '10136') {
          emailAddressforComms = this.jobAllocationResults?.info?.TPEmail;
        } else if (this.selectedRecipient.id === '10137') {
          if (this.jobAllocationResults.info.Email !== 'NA') {
            emailAddressforComms = this.jobAllocationResults?.info?.Email;
          }
        } else if (this.selectedRecipient.id === '10138') {
  
        }
        if (emailAddressforComms !== null && emailAddressforComms !== '') {
          this.selectdEmailId.push({ value: emailAddressforComms });
        }

        console.log('emailAddressforComms',emailAddressforComms);
    //  }
  
    }

  onChangeRecipient(selectedRecipient) {

    // console.log('selectedRecipient',selectedRecipient)
     this.Recipient.forEach(item => {
       if (item.id === this.selectedRecipient.id) {
         item.selected = 'true';
       } else {
         item.selected = 'false';
       }
     });
    
   }
  documentsTab() {
    this.documentValue = true;
  }
  hideDialog() {
    this.documentValue = false;
  }


  showDialog(video) {
    this.selectedVideo=video;
    this.visible = true;
     this.sendlinkvia = undefined
    this.btnvisible =false;
    this.message= 'Job No : '+ this.jobSummary.JobNo +', Link to access the video is ' + this.selectedVideo.file_path  +'. Please click the link to download the video. Do not share this link with anyone';
  }



  async sendVideoLink(){
    // this.sendMAil()  
   
   //console.log("random_OTP",random_OTP);
 
   let email;
   let mobile;
 
 
   if (typeof this.selectdEmailId !== 'undefined') {

    if (this.selectdEmailId !== null && this.selectdEmailId.length > 0) {
      let emailid = '';
      this.selectdEmailId.forEach(mailid => {
        if (emailid !== '') {
          emailid = emailid + ', ' + mailid.value;
        } else {
          emailid = mailid.value;
        }
      });
      this.emailaddress = emailid;
    //  this.scheduleJob.email = '2';

    let params = {
      email: {
        to: this.emailaddress, cc: '', bcc: null,
        msg: 'Your Link for access video is '+this.selectedVideo.file_path + '. Please use this link to access video. Do not share this link with anyone',
        subject: 'Link To Access Tree Video Files',
      },    type: 'sms-email',
      selectedTpl: null,
      sufix: null,
    }
 
    this.sendOTPMail(params);



    }
  }
 

  if(this.SMSAddressforComms.length>0)
    if(this.SMSAddressforComms)
    { 
      var firstChar = this.SMSAddressforComms;
      var firstChar1 = String(firstChar).substring(0,1);
      if(firstChar1!="0")
      {
        this.SMSAddressforComms = "0"+this.SMSAddressforComms;
      }
    }
     let params = {
 
     
       sms: {
         to:this.SMSAddressforComms,
         message: this.message,
       subject:'Link To Access Tree Video Files',
       },   type: 'sms-email',
       selectedTpl: null,
       sufix: null,
     }
 console.log('SMS params',params)
     this.CrudService.sendTMV2Message(params).subscribe((res2: any[]) => {
       console.log('SMS outtt',res2);
         this.visible = false;
         this.messageService.add({ severity: 'success', summary: 'Successful', detail: ' SMS Sent ', life: 1000 });
     });
    }
   

   removeToMailId(value) {
    this.selectdEmailId = this.selectdEmailId.filter(val => val.value !== value.value);
  }
 
   sendOTPMail(obj) {
    const datobj2 = {
      subject :obj.email.subject,
      body : obj.email.msg,
      toEmail : obj.email.to,
      ref_id:'1',
      ref_type:'accounts'
      }
      console.log("datobj2",datobj2)
      this.CrudService.sendmailSendgrid(datobj2,'commonMail').subscribe((res2: any) => { 
      this.loading = false;
      if(res2.success == 200)
      {
        this.visible = false;
        this.messageService.add({ severity: 'success', summary: 'Successful', detail: 'Video Link Email Sent', life: 3000 });
      }
      else
      {
        console.log("res2.Data.body.errors[0].message",res2);
        let message = res2.Data.response.body.errors[0].message;
        this.messageService.add({ severity: 'error', summary: 'Error', detail: message, life: 3000 });
      }
     });
  }
  async updateVideo() {

    // console.log('UploadVideos', this.uploadVideos)
     let successStatus = true;
     for (let video of this.uploadVideos) {
 
       if (video.id && (video.file_description !== null && video.file_description !== "" && video.file_description !== undefined)) {
       //  console.log('video11', video)
 
         this.CrudService.Update(video, 'UploadVideos').subscribe((data: any) => {
           //console.log('video desc out', data)
           if (data.success == 1) {
             this.loading = false;
 
           }
           else {
             successStatus = false;
 
           }
         })
       }
 
     }
     if (successStatus) {
       this.messageService.add({ severity: 'success', summary: 'Successful', detail: 'Description updated', life: 1000 });
     } else {
       this.messageService.add({ severity: 'error', summary: 'Error', detail: 'Description not updated', life: 1000 });
     }
 
   }
   openVideo(path)
  {
    //this.videoUrl = this.sanitizer.bypassSecurityTrustUrl(path);
    this.cdr.detectChanges();
    this.videoUrl = path;
    this.displayVideo = true;
  }

  onVideoDialogHide()
  {
   
    this.videoUrl = '';
    console.log("asd",this.videoUrl);
  }

  showDialogMaximized(event, dialog: Dialog) {
    dialog.maximized = true;
    // dialog.toggleMaximize(event);
  }
 

   onFileSelected(event: any) {
    const file: File = event[0];
    if (file) {
      this.s3Service.uploadFile(file, this.jobId, this.jobAllocationId)
        .then((url) => {
          console.log(`File uploaded to S3: ${url}`);
          this.loading = true;
          let document = {
            // Inv: null,
            company_id: this.currentUser.company,
            created_at: new Date(),
            created_by: null,
            file_description: null,
            file_name: url,
            file_path: url,
            file_size: null,
            hide: '1',
            id: null,
            inc_quote: null,
            job_alloc_id: this.jobAllocationId,
            job_id: this.jobId,
            last_modified_by: null,
            last_updated_at: null,
            owner: null,
            process_id: this.currentUser.company,
            status: '1',
            upload_at: new Date(),
            upload_by: this.currentUser.userId,
            username: this.currentUser.username,
            upload_type: null,
            upload_type_detail: null,
          }
          this.CrudService.Create(document, 'UploadVideos').subscribe((data: any) => {
            document.id = data.id
            if (data.success == 1) {
              this.loading = false;
              this.messageService.add({ severity: 'success', summary: 'Successful', detail: 'Video File Uploaded Successfully', life: 1000 });
              this.ngOnInit()
            }
          })
        })
        .catch(error => {
          console.error('File upload failed:', error);
        });
    }
  }
  
  getUploadDocuments() {
    this.loading = true;
    this.documents = [];
    this.CrudService.getUploadDocumentsByJobIdAllocId(this.jobId, this.jobAllocationId).subscribe(async (data: any) => {
      this.documents = data;
      this.loading=false
      if (this.documents) {
        this.CrudService.getByValuesetdocumentdesc().subscribe((data: any[]) => {
          data.sort((a, b) => a.valueset_detail_name > b.valueset_detail_name && 1 || -1);
          this.description = data;

          this.documents.forEach(document => {
            this.CrudService.getSelectedByValuesetdocumentdesc(document.file_description).subscribe((data: any) => {
              document.selectedDescription = data[0];
            })
            if (document.inc_quote == '2') {
              document.isIncluded = true;
            } else {
              document.isIncluded = false;
            }
            if (document.hide == '2') {
              document.isHidden = true;
            } else {
              document.isHidden = false;
            }
            document.sizeInKB = this.niceBytes(document.file_size);
            document.upload_time = new Date(document.upload_at).toLocaleString();
            document.upload_time_str = new Date(document.upload_at).toLocaleString();
            document.upload_time_str2 = new Date(document.upload_at);
          });
        })
       
      }
    })
   
  }

  onActionChange(id, description) {
    this.documents.forEach(element => {
      if (id === element.id) {
        element.file_description = description.id;
      }
    });
    this.refereshFlag.emit("Document Action Change");
  }
  downloadSelectedItem(SelectedDocument) {
    import('file-saver').then(FileSaver => {
      this.CrudService.downloadDocument(SelectedDocument.file_path).subscribe({next: (blob) => {
        const a = document.createElement('a')
        const objectUrl = URL.createObjectURL(blob)
        a.href = objectUrl
        a.download = SelectedDocument.file_name;
        a.click();
        URL.revokeObjectURL(objectUrl);
      }, error: (err) => {
        this.CrudService.handleFileDownloadError(err);
      }});
    });
  }

  hideNewDocumentDialog() {
    this.addNewDocument = false;
  }
    onUpload(event) {
    this.loading = true;
    for (let file of event) {
      let document = {
        Inv: null,
        company_id: this.currentUser.company,
        created_at: new Date(),
        created_by: null,
        file_description: null,
        file_name: null,
        file_path: null,
        file_size: null,
        hide: '2',
        id: null,
        inc_quote: '1',
        job_alloc_id: this.jobAllocationId,
        job_id: this.jobId,
        last_modified_by: null,
        last_updated_at: null,
        owner: null,
        process_id: this.currentUser.company,
        status: '1',
        upload_at: new Date(),
        upload_by: this.currentUser.userId,
        upload_type: null,
        upload_type_detail: null,
        username: this.loggedInUser.first_name + ' ' + this.loggedInUser.last_name,
        upload_time: null,
        upload_time_str: null,
        upload_time_str2: null,
        sizeInKB: null,
        isHidden: true,
        isIncluded: false,
        s3_url: null,
        sync_status: 0
      }
      this.loading = true;
      document.file_name = file.name
      document.file_size = file.size.toString();
      document.upload_time = new Date(document.upload_at).toLocaleString();
      document.upload_time_str = new Date(document.upload_at).toLocaleString();
      document.upload_time_str2 = new Date(document.upload_at);
      document.sizeInKB = this.niceBytes(document.file_size);
      //console.log(file)
      if (file.size <= environment.imageDocSize) {
        //this.document.sizeInMB = this.niceBytes(this.document.sizeInKB);
        let res =   this.CrudService.uploadDocument(file, this.jobId, this.jobAllocationId)
        let response = JSON.parse(res);
        //console.log(response);
        if (response.success === 1) {
          this.isDocumentUploaded = true;
          //console.log('image uploaded');
          document.file_path = this.jobId + '/' + this.jobAllocationId + '/' + file.name;
          document.file_name = file.name;
          document.s3_url = response.s3Url || null;
          document.sync_status = response.s3Url ? 1 : 0;
          this.saveNewDocumentRecord(document);
        }
        else {
          this.messageService.add({ severity: 'error', summary: 'Error', detail: response.message, life: 2500 });
          this.isDocumentUploaded = false
          this.loading = false;
        }
      }
      else {
        this.messageService.add({ severity: 'error', summary: 'Error', detail: 'File must be < 10mb', life: 1000 });
        this.isDocumentUploaded = false
      }
    }
    this.loading = false;
    this.refereshFlag.emit("Document Added");
  }

  saveNewDocumentRecord(document) {
    this.submitted = true;
    this.loading = true;
    if (this.isDocumentUploaded === true) {
      this.CrudService.Create(document, 'UploadDocuments').subscribe((data: any) => {
        document.id = data.id
        if (data.success == 1) {
          this.addNewDocument = false;
          this.loading = false;
          this.messageService.add({ severity: 'success', summary: 'Successful', detail: 'File Uploaded Successfully', life: 1000 });
          this.documents = [document, ...this.documents];
        }
      })
    }
  }

  saveRecord() {
    this.loading = true;
    let successStatus = true;
    this.documents.forEach(document => {
      if (document.isIncluded) {
        document.inc_quote = '2';
      } else {
        document.inc_quote = '1';
      }
      if (document.isHidden) {
        document.hide = '2';
      } else {
        document.hide = '1';
      }

      this.CrudService.Update(document, 'UploadDocuments').subscribe((data: any) => {
        if (data.success == 1) {
          this.documentValue = false;
          this.loading = false;
        } else {
          successStatus = false;
        }
      })
    });
    if (successStatus) {
      this.messageService.add({ severity: 'success', summary: 'Successful', detail: 'File Uploaded Successfully', life: 1000 });
    } else {
      this.messageService.add({ severity: 'error', summary: 'Error', detail: 'File Upload Unsuccessful', life: 1000 });
    }
  }

  onDescriptionChange(description) {
    this.document.file_description = description.id;
  }

  deleteDocument(document) {
    console.log("deleteDocument")
    this.confirmationService.confirm({
      message: 'Are you sure you want to delete the selected document?',
      header: 'Confirm',
      icon: 'pi pi-exclamation-triangle',
      accept: () => {
        // this.loading = true;
        this.documents = this.documents.filter((val: any) => val.id !== document.id);
        this.messageService.add({ severity: 'success', summary: 'Successful', detail: 'Document Deleted', life: 1000 });
        this.confirmationService.close();
        this.loading = false;
        this.CrudService.Delete(document.id, 'UploadDocuments').subscribe((data: any) => {
          //console.log(data)
          if (data.success == 1) {  
          }
          this.refereshFlag.emit("Document Deleted");
        })
      }
    })
  }

  compressDocument(document) {

    let file = this.CrudService.apiRoot + document.file_path;
    //console.log(file);
    this.CrudService.pdfCompression(file).subscribe((data: any) => {
      //console.log(data.url);
      window.open(data.url, '_blank').focus()
      // if (data.success == 1) {
      //   this.messageService.add({ severity: 'success', summary: 'Successful', detail: 'Document Deleted', life: 1000 });
      //   this.documents = this.documents.filter((val: any) => val.id !== document.id)
      // }
    })
  }



  DateToLocalTimeString(d) {
    let date = new Date(d);
    d = date.toLocaleDateString('en-GB');
    let t = date.toLocaleTimeString();
    return d + ' ' + t;
  }



  niceBytes(x) {
    let l = 0, n = parseInt(x, 10) || 0;
    while (n >= 1024 && ++l) {
      n = n / 1024;
    }
    return (n.toFixed(n < 10 && l > 0 ? 1 : 0) + ' ' + this.units[l]);
  }

  allocationList: any;
  showAllocationList = false;
  selectedJobAllocation: any;

  async fetchAllAllocation() {
    this.allocationList = await this.CrudService.getAllAllocatedJobByJobId(this.jobId).toPromise();
    if(this.config.data.jobAllocationId){
      this.selectedJobAllocation  = this.allocationList.find(val =>val.id == this.config.data.jobAllocationId)
    }
    else if (this.allocationList.length == 1) {
      this.selectedJobAllocation = this.allocationList[0]
    }
    if (this.allocationList.length > 0) {
      this.showAllocationList = true;
    } else {
      this.showAllocationList = false;
    }
    this.allocationList.push({ id: '1', business_name: 'Common' })
    if (this.jobAllocationId == '1') {
      if(this.selectedJobAllocId!='' && this.selectedJobAllocId!=null){
        this.jobAllocationId = this.selectedJobAllocId;
      }else{
        this.jobAllocationId = this.allocationList[0].id;
      }
    }
    if ( this.allocationList.length == 1 || (this.allocationList.length > 2 && !this.config.data.jobAllocationId ) ) {
      // this.selectedJobAllocation = this.allocationList.find(val => (val.id == '1'));
      this.selectedJobAllocation =this.allocationList[0]
    }
    if(this.config.data.jobAllocationId == '1'){
      this.selectedJobAllocation = this.allocationList.find(val => (val.id == '1'));
    }
    this.changeAllocation();
  }

  changeAllocation() {
    this.jobAllocationId = this.selectedJobAllocation.id;
    this.config.data.jobAllocationId = this.selectedJobAllocation.id;
    this.getUploadDocuments();
  }
}
