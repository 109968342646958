import { Component, OnInit, ViewChild, ChangeDetectorRef } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { CRUDService } from 'src/app/utilities/crud.service';
import { environment } from 'src/environments/environment';
import { DialogService, DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { ViewAuditComponent } from '../view-audit/view-audit.component';


@Component({
  selector: 'app-audit-report',
  templateUrl: './audit-report.component.html',
  styleUrls: ['./audit-report.component.scss']
})
export class AuditReportComponent implements OnInit {

  parentList      : any[];
  parentListShow  : Boolean = true;
  childList       : any[]
  childListShow   : Boolean = false;
  auditLogs : any;
  auditLogsShow : Boolean = false;
  parentName : any;
  childName : any;
  parent : any;
  child : any;

  pid : any;
  pmodule: any;
  parent_table : any;

  constructor(
    private crudService: CRUDService,
    public dialogService: DialogService,
    public ref: DynamicDialogRef,
    public config: DynamicDialogConfig,
  ) {
   }

  ngOnInit(): void {
    this.crudService.getDataByField('AuditModules', 'table_parent_id', '0').subscribe((data: any[]) => {
      this.parentList = data;
      console.log("this.parentList",this.parentList)
    });
  }

  async viewChild(item)
  {
    this.parentListShow = false;
    this.parentName = item.table_name;
    this.parent = item;
    this.childName = "->" + item.table_name;
    this.crudService.getDataByField('AuditModules', 'table_parent_id', item.id).subscribe((data: any[]) => {
      this.childList = data;
      this.childListShow = true;

      this.pid = item.id;
      this.pmodule= item.table_name;
      this.parent_table = item.table_name;
    });
    // this.crudService.getDataByField('Audit_logs', 'table_name', 'company').subscribe((data: any[]) => {
    //   this.auditLogs = data;
    // });
  }


  viewAudit(item) {
    const ref = this.dialogService.open(ViewAuditComponent, {
      data: {
        id: item.id,
        module: item.table_name,
        childList : this.childList,
        parent_table : this.parentName
      },
      header: this.parentName +'-> '+ item.table_name,
      width: '100%',
      height: '100%'
    })
  }

  goHome()
  {
    this.childName = '';
    this.childListShow = false;
    this.parentListShow = true;
  }


}
