import { Component, OnInit } from '@angular/core';
import { CRUDService } from 'src/app/utilities/crud.service';
import { ConfirmationService } from 'primeng/api';
import { MessageService } from 'primeng/api';
import { Table } from 'primeng/table';
import { DynamicDialogRef, DialogService } from 'primeng/dynamicdialog';
import { rateSets } from './users';
import { exit } from 'process';
import {CommonService} from 'src/app/utilities/common.service'
import { environment } from 'src/environments/environment';


@Component({
  selector: 'app-users',
  templateUrl: './users.component.html',
  styleUrls: ['./users.component.scss'],
})
export class UsersComponent implements OnInit {
  paginationvalue:any;
  toDisplay = true;
  cols: any[];
  exportColumns: any[];
  rateSetDialog=false;
  submitted: boolean;

  rateSetList: any[];
  usersList: any[];
  rateset: any;
  selectedRateSet: any[];
  rateClass: any;
  isActive: boolean;
  companyId: any;
  speedDial: boolean = true;
  isEditAll: boolean = false;
  exportdisplay:Boolean = true;
  displayDelete:Boolean = true;

  constructor(private crudService: CRUDService,
    private messageService: MessageService,
    private confirmationService: ConfirmationService,private commonService:CommonService,
    private dialogService: DialogService,
    ) { }

   async ngOnInit(): Promise<void> {
    this.paginationvalue=environment.paginatorValue;
    this.companyId = sessionStorage.getItem('company_id');
    this.crudService.getAll('rateset').subscribe((data: any[]) => {
      this.rateSetList = data;
      data.forEach(item => {
        if (item.status == '2') {
          item.status_desc = 'Inactive';
        } else {
          item.status_desc = 'Active';
        }
      });
    });

    this.crudService.getAllData('users').subscribe((data: any[]) => {
      this.usersList = data;
      //console.log("check users1",data)
    });
    //console.log("check users3",this.usersList)


    this.cols = [
            { field: 'username', header: 'User Name' },
            { field: 'password', header: 'Password' },
            { field: 'status', header: 'Status' }
        ];

    this.exportColumns = this.cols.map(col => ({ title: col.header, dataKey: col.field }));
    this.exportdisplay= await this.commonService.exportCheck();
    this.displayDelete= await this.commonService.exportCheck();

  }


  openNew(){
    this.rateset = {
      client_type_id:null,
      company_id:null,
      created_at:null,
      created_by:null,
      id:null,
      last_modified_by:null,
      last_updated_at:null,
      margin:null,
      owner:null,
      process_id:this.companyId,
      rateset_desc:null,
      rateset_name:null,
      status:'1'
    };
    this.rateSetDialog=true;
  }

  editRecord(rateset: any){
    this.rateset = {...rateset};
    if (this.rateset.status === '' || this.rateset.status === null || this.rateset.status === '1') {
            this.rateset.status = '1'
            this.isActive = true;
        } else {
            this.rateset.status = '2'
            this.isActive = false;
        }
    this.rateSetDialog=true;
  }
  

  deleteSelectedRateSet(){
      this.confirmationService.confirm({
            message: 'Are you sure you want to delete the selected RateSet?',
            header: 'Confirm',
            icon: 'pi pi-exclamation-triangle',
            accept: () => {
                // tslint:disable-next-line: prefer-for-of
                for (let i = 0; i < this.selectedRateSet.length; i++) {
                this.crudService.getDataByField('rateClass','id',this.rateset.id).subscribe((data: any[]) => {
                  this.rateClass = data;
                });
                if(this.rateClass.length>0)
                {
                  alert('Rateset assigned to a Rateclass. Cannot delete Rateset');
                  this.rateSetDialog=false;
                }
                else{
                  this.crudService.Delete(this.selectedRateSet[i].id, 'rateset').subscribe((data: any[]) => { });
                }
                this.rateSetList = this.rateSetList.filter(val => !this.selectedRateSet.includes(val));
                this.selectedRateSet = null;
                this.messageService.add({ severity: 'success', summary: 'Successful', detail: 'Rateset Deleted', life: 1000 });
                }
                    }
        });
  }

  deleteRateset(rateset: any){
    this.rateset=rateset;
    let resRateSet;
       this.confirmationService.confirm({
            message: 'Are you sure you want to delete RateSet : ' + rateset.rateset_name + '?',
            header: 'Confirm',
            icon: 'pi pi-exclamation-triangle',
            accept: () => {
              //console.log(this.rateset.id);
              this.crudService.getDataByField('rateClass','id',this.rateset.id).subscribe((data: any[]) => {
                
                resRateSet  = data;
                });
                if(resRateSet.length>0)
                {
                  this.messageService.add({ severity: 'info', summary: 'Rateset assigned to a Rateclass.', detail: ' Cannot delete Rateset', life: 4000 });
                  this.rateSetDialog=false;
                }
                else{

                  this.crudService.Delete(rateset.id, 'rateset').subscribe((data: any) => {
                    if(data.success==1){
                      this.messageService.add({ severity: 'success', summary: 'Successful', detail: 'Value Set Deleted', life: 1000 });
                      this.rateSetList = this.rateSetList.filter(val => val.id !== rateset.id);
                      this.rateset = {};
                    }
                  });
                }

            }
        });
  }

  saveRecord(){
      this.submitted = true;
      if (this.isActive) {
            this.rateset.status = '1';
        } else {
            this.rateset.status = '2';
        }
      if(this.rateset.rateset_name.trim()){
      if(this.rateset.id){
        this.crudService.Update(this.rateset, 'rateset').subscribe((data: any[]) => {
            this.messageService.add({ severity: 'success', summary: 'Successful', detail: 'Rateset Updated', life: 1000 });
            this.rateSetList[this.findIndexById(this.rateset.id)] = this.rateset;
        });
      }
      else{
        this.crudService.Create(this.rateset, 'rateset').subscribe((data: any[]) => {
        this.messageService.add({ severity: 'success', summary: 'Successful', detail: 'Rateset Created', life: 1000 });
      //  this.rateSetList = [...this.rateSetList, this.rateSet]
        this.rateSetList.push(this.rateset);
    });
    }
    //console.log(this.rateset);
    this.rateSetDialog=false;
    }
  }
  findIndexById(id: string): number {
    let index = -1;
    for (let i = 0; i < this.rateSetList.length; i++) {
      if (this.rateSetList[i].id === id) {
        index = i;
        break;
      }
    }
    return index;
  }

  hideDialog() {
        this.rateSetDialog = false;
        this.submitted = false;
    }

  exportPdf() {
        import('jspdf').then(jsPDF => {
            import('jspdf-autotable').then(x => {
                /* const doc = new jsPDF.default(0,0);
                doc.autoTable(this.exportColumns, this.selectedDepots);
                doc.save('products.pdf'); */
            })
        })
  }

  exportExcel(selectedRateSet) {
        import('xlsx').then(xlsx => {
            const worksheet = xlsx.utils.json_to_sheet(this.selectedRateSet);
            const workbook = { Sheets: { data: worksheet }, SheetNames: ['data'] };
            const excelBuffer: any = xlsx.write(workbook, { bookType: 'xlsx', type: 'array' });
            this.saveAsExcelFile(excelBuffer, 'depots');
        });
    }

    saveAsExcelFile(buffer: any, fileName: string): void {
        import('file-saver').then(FileSaver => {
            const EXCEL_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
            const EXCEL_EXTENSION = '.xlsx';
            const data: Blob = new Blob([buffer], {
                type: EXCEL_TYPE
            });
            FileSaver.saveAs(data, fileName + '_export_' + new Date().getTime() + EXCEL_EXTENSION);
        });
    }

    clear(table: Table) {
        table.clear();
    }

    addNewRecord(){

      let rateSet = {
        client_type_id:null,
        company_id:null,
        created_at:null,
        created_by:null,
        id:null,
        last_modified_by:null,
        last_updated_at:null,
        margin:null,
        owner:null,
        process_id:this.companyId,
        rateset_desc:null,
        rateset_name:null,
        status:'1'
      };
      this.rateSetList = [rateSet, ...this.rateSetList]; 
    }
    async saveRecords(){
      let saveStatus = true;
      
      let changedRecords = this.rateSetList.filter(val=> (val.changed === true));
      if(changedRecords.length!=0){
        changedRecords.forEach(async rateSet=> {
          let status = await this.saveRecordNew(rateSet);
          if (!status){
            saveStatus = false;
            exit;
          }
        });
        if(saveStatus){
          this.messageService.add({ severity: 'success', summary: 'Successful', detail: changedRecords.length+' Records Updated', life: 1000 });
        } 
      }
      this.isEditAll=false;
      let editedRecords = this.rateSetList.filter(val=> (val.isEdit === true));
      editedRecords.forEach(element => {
        element.isEdit = false;
      });
      
    }
    async saveRecordNew(rateSet){

      let status: Boolean = true;
      if (rateSet.rateset_name.trim()) {
        //console.log("this is working",rateSet);
      if(rateSet.id){
        if(rateSet.isActive){
          rateSet.status='1';
        }
        if(!rateSet.isActive){
          rateSet.status='2';
        }
        let data: any = await this.crudService.Update(rateSet,'rateset').toPromise();

        if(data.success == '1'){
          rateSet.changed = false;
        }else{
          status = false;
        }
      }else{
        rateSet.status='1'
        rateSet.isActive=true;
        // //console.log("this is working",itemdefinitions);
        let data: any = await this.crudService.Create(rateSet, 'rateset').toPromise();
        if(data.success == '1'){
          rateSet.changed = false;
        }else{
          status = false;
        }
      }
      }else{
        status = false;
        // //console.log("This is not working");

      }
      return status;
      }

      onSelectedItemChange(event, rateset){
        rateset.item_type_id = event.value?.id;
        rateset.changed=true;
      }
      toggleData() {
        this.toDisplay = !this.toDisplay;
        //console.log("This is working",this.toDisplay)

      }

      resetLastCheckPoint(rateset){
        //console.log(rateset);
        rateset = rateset.backup; 
        //console.log(rateset);
      }

      backup(rateset){
        //console.log("backup",rateset);
        rateset=rateset.backup;
      }

      reloadCurrentPage() {
        window.location.reload();
      }
      /* topBar(users) {
        const ref = this.dialogService.open(TpAppTopBarComponent, {
          data: {
            user: users,
            status: 1
          },
        
          width: '100%',
          height: '100%'
        });
        ref.onClose.subscribe(async (response: any) => {
          // await this.loadJobSummary();
        });
      } */

}
