import { Component, OnInit, Input, OnDestroy, OnChanges, EventEmitter, Output } from '@angular/core';
import { CRUDService } from 'src/app/utilities/crud.service';
import { MessageService, ConfirmationService } from 'primeng/api';
import { Compliance } from './compliance';
import { DynamicDialogRef } from 'primeng/dynamicdialog';
import { DynamicDialogConfig } from 'primeng/dynamicdialog';
import { fileURLToPath } from 'url';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { FileUploader } from 'ng2-file-upload';
import { ProceedConfirmationComponent } from '../../modules/proceed-confirmation/proceed-confirmation.component';
import { DialogService } from 'primeng/dynamicdialog';
import { environment } from 'src/environments/environment';
import { Observable, Subscription } from 'rxjs';
import { debug, log } from 'console';
import { EncrDecrService } from 'src/app/utilities/encr-decr.service';
import { CommonService } from 'src/app/utilities/common.service';
import { NgTinyUrlService } from 'ng-tiny-url';
@Component({
  selector: 'app-compliance',
  templateUrl: './compliance.component.html',
  styleUrls: ['./compliance.component.scss'],
  providers: [MessageService, ConfirmationService]
})
export class ComplianceComponent implements OnInit, OnDestroy, OnChanges {
  @Input() staffId: any;
  @Input() company_id: any;
  @Input() type: any;
  @Input() allowCreate: any;
  @Input() changed: any;
  @Input() SPAlink:any
  @Input() events: Observable<void>;
  @Output() newItemEvent = new EventEmitter<Boolean>();
  loading = false;
  dateFormat: any;
  DateFormat: any;
  enableCreation: Boolean = true;
  tablenewlayout: boolean = true
  newlayout: boolean = true
  compliance: any;
  compliances: any[];
  submitted: boolean
  complianceDialog: boolean;
  selectedComplianceType: any;
  complianceTypeList: any[];
  currentDate: Date;
  isActive: boolean;
  complianceForm: FormGroup;
  uploader: FileUploader;
  maxDate: any;
  file: File;
  fileChanged: Boolean = false;
  userDetails: any;
  rangeDate: any;
  DateExceeds: Boolean = false;
  savedisable: Boolean = false;
  isEditAll: Boolean = false;
  isEdits: Boolean = false;
  switchFlag: Boolean = true;
  viewFlag: any = 'Grid View'
  changeData: Boolean = false;
  eventsSubscription: Subscription;
  DateExceeds1: boolean = false;
  complainceView: Boolean = false;
  amountView: Boolean = false;
  disableButton: Boolean = true;
  changedRecCount: number = 0;
  enablebtn: boolean=true;
  env: any;
  efInduction: any;
  acceptFileTypes: string;
  sendlinkvia: boolean;
  emailSubject: string;
  spa_access_Url: string;
  emailContent: string;
  staff: any;
  slaData: any;
  displayPdf: boolean = true;
  companyCompliance: any;
  constructor(private crudService: CRUDService,
    private messageService: MessageService,
    private confirmationService: ConfirmationService,
    public ref: DynamicDialogRef,
    public dialogService: DialogService, private EncrDecr: EncrDecrService,private tinyUrl: NgTinyUrlService,
    public config: DynamicDialogConfig, private CommonService: CommonService,) {
    this.userDetails = JSON.parse(sessionStorage.getItem('loggedInUser'));
  }

  ngOnChanges() {
    // this.compliances = [];
    // this.getCompliance();
  }
  ngOnInit() {
    this.env =  environment;
    this.loadComplianceInfo()
    this.dateFormat = environment.DateFormat;
    this.DateFormat = environment.dateFormat;
    this.currentDate = new Date();
    //this.staffId = null;
    this.acceptFileTypes = environment.compFileType
    if(this.env.hosting == 'AU')
    {
      this.efInduction = '74';
    }
    else if(this.env.hosting == 'UK')
    {
      this.efInduction = '170';
    }

    this.compliances = [];
    if (typeof this.config.data == 'undefined' || typeof (this.config.data.company_id) == 'undefined') {
      //this.company_id = sessionStorage.getItem('company_id');
      this.compliances = [];
    } else {
      this.staffId = this.config.data.staffId;
      this.company_id = this.config.data.company_id;
      this.type = this.config.data.type;
      this.allowCreate = this.config.data.allowCreate;
      this.compliances = [];
    }
    if(this.type == 'staff'){
      this.amountView = false
    }
    else{
      this.amountView = true
    }
    this.enableCreation = true;
    this.complianceForm = new FormGroup({
      complianceType: new FormControl('', Validators.required),
      issued_by: new FormControl('', Validators.required),
      policy_no: new FormControl('', Validators.required),
    })

    this.uploader = new FileUploader({
      disableMultipart: true, // 'DisableMultipart' must be 'true' for formatDataFunction to be called.
      formatDataFunctionIsAsync: true,
      formatDataFunction: async (item) => {
        return new Promise((resolve, reject) => {
          resolve({
            name: item._file.name.replace(/[&\/\\#, +()$~%;'":*?<>{}@!~`^]/g, ''),
            length: item._file.size,
            contentType: item._file.type,
            date: new Date()
          });
        });
      }
    });
    this.getCompliance();
    this.eventsSubscription = this.events.subscribe(() => this.getCompliance());
  }

  onDateChoose(issued_date, expiry_date) {
    var fromDate = issued_date;
    var toDate = expiry_date;
    this.rangeDate = [];
    if (fromDate <= toDate) {
      this.DateExceeds = false;
      this.DateExceeds1 = false;
    }
    else {
      // this.compliance.expiry_date=null,
      this.DateExceeds = true;
    }
  }
  onDateChoose1(issued_date, expiry_date) {
    if (expiry_date){
      var fromDate = issued_date;
      var toDate = expiry_date;
      this.rangeDate = [];
      if (fromDate <= toDate) {
        this.DateExceeds1 = false;
        this.DateExceeds = false;
      } else {
        // this.compliance.expiry_date=null,
        this.DateExceeds1 = true;
      }
    }
  }

  ngOnDestroy() {
    this.config.data = {};
    //this.eventsSubscription.unsubscribe();
  }

  openNew() {
    this.savedisable = true;
    this.isActive = true;
    this.complianceDialog = true;
    this.enablebtn =true
    // if(environment.hosting == "UK")
    // {
    //   this.amountView = false;
    // }
    this.compliance = {
      amount: null,
      comment: null,
      company_id: this.company_id,
      staff_id: this.staffId,
      compliance_id: null,
      compliance_type_id: null,
      created_at: null,
      created_by: null,
      expiry_date: null,
      file_path: null,
      filename: '',
      id: null,
      issued_by: null,
      issued_date: null,
      last_modified_by: null,
      last_reminder_date: null,
      last_updated_at: null,
      owner: null,
      policy_no: null,
      reminder_count: null,
      reminder_status: null,
      status: null,
      status_desc : '',
      s3_url: null,
      sync_status: 0
    };
    this.selectedComplianceType = [];
  }

  async getCompliance() {
    this.loading = true;
    //console.log('Company id : ' + this.company_id);
    this.compliances = [];
    if (typeof this.staffId == 'undefined') {
      this.loadCompanyCompiances();
      this.enableCreation = false;
      this.complainceView = true;
    } else {
      if (this.staffId !== '' && this.staffId !== null) {
        let data: any = await this.crudService.getAllForStaff().toPromise();
        let data2 = data.filter(val => val.id !== this.efInduction);
        this.complianceTypeList = data2;
        this.loadStaffCompliance();
        this.complainceView = false;
        this.enableCreation = true;
      } else {
        this.complianceTypeList = [];
        this.loadCompanyCompiances();
        this.complainceView = true;
        this.enableCreation = true;
      }
    }
    this.loading = false;
     this.disableButton = true;
    this.compliances.forEach(element => {
      element.isEdit = false;
    });
    this.isEditAll = false
  }

  loadStaffCompliance() {
    this.compliances = [];
    this.crudService.getCompliancesByStaffId(this.staffId).subscribe((data: any[]) => {
      for (let i = 0; i < data.length; i++) {
        let compliance = data[i];
       
        if (compliance.expiry_date != null && compliance.expiry_date !== '') {
          compliance.expiry_date_str = new Date(compliance.expiry_date).toLocaleDateString();
          compliance.expiry_date = new Date(compliance.expiry_date);
        } else {
          compliance.expiry_date_str = '';
        }
        if (compliance.issued_date != null && compliance.issued_date !== '') {
          compliance.issued_date_str = new Date(compliance.issued_date).toLocaleDateString();
          compliance.issued_date = new Date(compliance.issued_date);
        } else {
          compliance.issued_date_str = '';
        }
        if (compliance.compliance_id != null && compliance.compliance_id !== '') {
          this.crudService.getDataByField('ComplianceDefn', 'id', compliance.compliance_id).subscribe((complianceDefn: any[]) => {
            compliance.name = complianceDefn[0]?.compliance_name;
            compliance.slectedName = complianceDefn[0]
          });
        }
        if(compliance.status == '1') {
          compliance.status = 'Submitted';
          compliance.status_desc  = 'Submitted';
        }
        if(compliance.status == '3') {
          compliance.status = 'Rejected';
          compliance.status_desc  = 'Rejected';
        }
        else {
          compliance.status = 'Approved';
          compliance.status_desc  = 'Approved';
          if (compliance.expiry_date < this.currentDate && compliance.expiry_date != null && compliance.expiry_date !== '') {
            compliance.status = 'Expired';
            compliance.status_desc  = 'Expired';
          }
        }
        if(compliance.compliance_id == this.efInduction)
        {
          compliance.readOnly = true;
        }
        else
        {
          compliance.readOnly = false;
        }
        this.compliances = [...this.compliances, compliance];
        //console.log('staff compliances',this.compliances)
      }
    });
  }
  loadCompanyCompiances() {
    this.compliances = [];
    this.crudService.getCompLevelDetailByIdForCompanyCompliance(42).subscribe((allComplianceData: any[]) => {
     // console.log('allComplianceData',allComplianceData);
      this.crudService.getAllByCompanyId(this.company_id).subscribe((companyCompianceData: any[]) => {
      //  console.log('companyCompianceData',companyCompianceData);
        for (let i = 0; i < allComplianceData.length; i++) {
          let company_compliance = {
            amount: null,
            name: allComplianceData[i].compliance_name,
            comment: null,
            company_id: this.company_id,
            staff_id: null,
            compliance_id: allComplianceData[i].compliance_id,
            compliance_type_id: null,
            created_at: null,
            created_by: null,
            expiry_date: null,
            file_path: null,
            filename: '',
            id: null,
            issued_by: null,
            issued_date: null,
            last_modified_by: null,
            last_reminder_date: null,
            last_updated_at: null,
            owner: null,
            policy_no: null,
            reminder_count: null,
            reminder_status: null,
            status: null,
            exists: false,
            expired: false,
            expiry_date_str: null,
            issued_date_str: null,
            s3_url: null,
            sync_status: 0
          };
          for (let j = 0; j < companyCompianceData.length; j++) {
            if (allComplianceData[i].compliance_id == companyCompianceData[j].compliance_id) {
              let expirydateexists = false;
              let date3 = null;
              let date4 = null;
              if (!(companyCompianceData[j].issued_date == null)) {
                date3 = new Date(companyCompianceData[j].issued_date);
              }
              if (!(companyCompianceData[j].expiry_date == null)) {
                date4 = new Date(companyCompianceData[j].expiry_date);
                expirydateexists = true;
              }
              company_compliance.compliance_id = companyCompianceData[j].compliance_id;
              company_compliance.id = companyCompianceData[j].id;
              company_compliance.issued_by = companyCompianceData[j].issued_by;
              company_compliance.policy_no = companyCompianceData[j].policy_no;
              company_compliance.amount = companyCompianceData[j].amount;
              company_compliance.filename = companyCompianceData[j].filename;
              company_compliance.file_path = companyCompianceData[j].file_path;
              company_compliance.status = companyCompianceData[j].status;
              company_compliance.company_id = companyCompianceData[j].company_id
              company_compliance.comment = companyCompianceData[j].comment;
              company_compliance.issued_date = date3;
              company_compliance.expiry_date = date4;
              company_compliance.created_at = companyCompianceData[j].created_at;
              company_compliance.created_by = companyCompianceData[j].created_by;
              company_compliance.owner = companyCompianceData[j].owner;
              //company_compliance.exist = true;
              if (company_compliance.expiry_date != null) {
                if (new Date(company_compliance.expiry_date) > new Date()) {
                  company_compliance.expired = true;
                }
              }

            } else {

            }
          }
          if (company_compliance.expiry_date != null && company_compliance.expiry_date !== '') {
            company_compliance.expiry_date_str = new Date(company_compliance.expiry_date).toLocaleDateString();
            company_compliance.expiry_date = new Date(company_compliance.expiry_date);
          } else {
            company_compliance.expiry_date_str = '';
          }
          if (company_compliance.issued_date != null && company_compliance.issued_date !== '') {
            company_compliance.issued_date_str = new Date(company_compliance.issued_date).toLocaleDateString();
            company_compliance.issued_date = new Date(company_compliance.issued_date);
          } else {
            company_compliance.issued_date_str = '';
          }
          if (company_compliance.compliance_id != null && company_compliance.compliance_id !== '') {
            this.crudService.getDataByField('ComplianceDefn', 'id', company_compliance.compliance_id).subscribe((complianceDefn: any[]) => {
              company_compliance.name = complianceDefn[0].compliance_name;
            });
          }
          if (company_compliance.id !== '' && company_compliance.id != null) {
            company_compliance.status = 'Active';
            if (company_compliance.expiry_date < this.currentDate && company_compliance.expiry_date != null && company_compliance.expiry_date !== '') {
              company_compliance.status = 'Expired';
            }
          } else {
            company_compliance.status = 'Pending';
          }

          this.compliances = [...this.compliances, company_compliance];
         // console.log('company compliances',this.compliances)
        }
      });
    });
  }

  saveCompliance() {
    this.loading = true;
    this.submitted = true;
    this.enablebtn =false
    //console.log(this.complianceForm);
    if (!(typeof this.staffId == 'undefined')) {
      if (this.staffId === '' || this.staffId == null) {
        this.compliance.staff_id = null;
      } else {
        this.compliance.staff_id = this.staffId;
      }
      if (this.selectedComplianceType !== null && this.selectedComplianceType !== '') {
        this.compliance.compliance_id = this.selectedComplianceType.id;
      }
    }
    if (this.isActive) {
      this.compliance.status = '1';
    }
    if (!this.isActive) {
      this.compliance.status = '2';
    }
    if (this.complianceForm.status === 'VALID') {
      if (this.fileChanged) {
        if (this.compliance.filename) {
          let res = this.crudService.uploadCompliance(this.file, this.company_id, this.staffId);
          let response = JSON.parse(res)
          if (response != null) {
            if (response.success === 1) {
              this.compliance.file_path = this.company_id + '/compliance_docs/' + this.file.name.replace(/[&\/\\#, +()$~%;'":*?<>{}@!~`^]/g, '');
              this.compliance.s3_url = response?.s3Url;
              this.compliance.sync_status = response?.s3Url ? 1 : 0;
              if (this.staffId !== '' && this.staffId != null) {
                this.compliance.file_path = this.company_id + '/compliance_docs/staff/' + this.staffId + '/' + this.file.name.replace(/[&\/\\#, +()$~%;'":*?<>{}@!~`^]/g, '');
              } else {
                this.compliance.file_path = this.company_id + '/compliance_docs/' + this.file.name.replace(/[&\/\\#, +()$~%;'":*?<>{}@!~`^]/g, '');
              }
              this.compliance.filename = this.file.name.replace(/[&\/\\#, +()$~%;'":*?<>{}@!~`^]/g, '');
              this.saveComplianceInfo();
            } else {
              this.enablebtn =true
              this.messageService.add({ severity: 'error', summary: 'Error', detail: 'This file type is not supported', life: 1000 });
            }
          }
        }
      } else {
        this.saveComplianceInfo();
      }
    } else {
      this.enablebtn =true
      this.messageService.add({ severity: 'error', summary: 'error', detail: 'Compliance Not Added. Clear Errors and try again.', life: 1000 });
    }
    this.isEditAll = false;
    this.loading = false;
  }

  saveComplianceInfo() {
    this.loading = true;
    if (this.compliance.filename !== '' && this.compliance.filename !== null) {

      if (this.compliance.id) {
        this.compliance.created_at = new Date().toDateString();
        this.compliance.created_by = this.userDetails.id;
        this.crudService.Update(this.compliance, 'CompanyCompliance').subscribe((data: any) => {
          if (data.success === 1) {
            this.fileChanged = false;
            this.compliances = [];
            this.getCompliance();
            this.messageService.add({ severity: 'success', summary: 'Successful', detail: 'Compliance Updated', life: 1000 });
            this.complianceDialog = false;
            this.enablebtn =true
   
          } else {
            this.enablebtn =true
            this.messageService.add({ severity: 'error', summary: 'Error', detail: 'Contact Details Not Updated', life: 1000 });
          }
        });
      } else {
        this.compliance.created_at = new Date().toDateString();
        this.compliance.created_by = this.userDetails.id;
        this.crudService.Create(this.compliance, 'CompanyCompliance').subscribe((data: any) => {
          //console.log(data);
          if (data.success == 1) {
            this.fileChanged = false;
            this.compliance.id = data.id;
            this.compliances = [];
            this.getCompliance();
            this.messageService.add({ severity: 'success', summary: 'Successful', detail: 'Compliance Added', life: 1000 });
            this.complianceDialog = false;
            this.enablebtn =true
            this.loading = false;
            this.disableButton = false;
          } else {
            this.enablebtn =true
            this.messageService.add({ severity: 'error', summary: 'Successful', detail: 'Compliance Not Added', life: 1000 });

            this.loading = false;
            this.disableButton = false;
          }
        });
      }
    } else {
      this.messageService.add({ severity: 'error', summary: 'error', detail: 'File must be uploaded to proceed with saving Compliance Info', life: 1000 });

      this.loading = false;
      this.disableButton = false;
      this.enablebtn =true
    }
    this.loading = false;
  }

  editCompliance(compliance: Compliance) {
    this.savedisable = false;
    this.changeData = false
    this.complianceDialog = true;
    this.compliance = { ...compliance };
    if (!this.compliance.filename) {
      this.compliance.filename = null;
    }
    else{
      this.compliance.filename = compliance.filename ;
    }
    if (this.compliance.status === '' || this.compliance.status === null || this.compliance.status === '1') {
      this.compliance.status = '1'
      this.isActive = true;
    } if (this.compliance.status === '2') {
      this.compliance.status = '2'
      this.isActive = false;
    }
    //console.log('edit compliance id and type', this.compliance.compliance_id);
    if (this.compliance.compliance_id !== '') {
      this.crudService.getDataByField('ComplianceDefn', 'id', this.compliance.compliance_id).subscribe((complianceDefn: any[]) => {
        this.selectedComplianceType = complianceDefn[0];
        if (this.staffId === '' || this.staffId == null) {
          this.complianceTypeList = complianceDefn;
          //console.log('selectedComplianceType: ' + this.selectedComplianceType);
        }
        //console.log('edit compliance', this.compliance);
      });
    } else {
      this.complianceTypeList = [];
    }
    //console.log('edit compliance', this.compliance);
    //console.log('selectedComplianceid : ' + JSON.stringify(this.compliances));
  }
  deleteCompliance(compliance: Compliance,index) {
    this.confirmationService.confirm({
      message: 'Are you sure you want to delete Compliance : ' + compliance.id + '?',
      header: 'Confirm',
      icon: 'pi pi-exclamation-triangle',
      accept: () => {
        debugger
        console.log("compliance.id",compliance.id);
        
        this.crudService.Delete(compliance.id, 'CompanyCompliance').subscribe(() => {
          this.messageService.add({ severity: 'success', summary: 'Successful', detail: 'compliance Deleted', life: 1000 });
          this.newItemEvent.emit(true);
         // this.getCompliance();
         this.compliances.splice(index, 1);
        });
      }
    });
  }
  onUpload(event1,compliance) {
    this.openModal(event1,compliance);
  }
  onUploadOld(event1, compliance) {
    //console.log(compliance, event1);
    if (compliance.file_path !== null || compliance.filename !== null) {
      this.openModal(event1,compliance)
    } else {
      const jsonFile = {}
      for (let file of event1) {
        //console.log(file)
        jsonFile[file] = file;
        jsonFile['fileChanged'] = true;
        jsonFile['filename'] = file.name;
      }
      this.getFile(jsonFile,compliance)
    }

    /* this.crudService.uploadCompliance(uploadedFile, this.company_id).subscribe((data: any[]) => {;
    }); */
  }
  openModal(event1: any,compliance) {
    debugger
    this.changeData = true;
    //console.log(event1);
    const data = event1;
    const self = this;
    const jsonFile = {}
    for (let file of event1) {
      //console.log(file)
      jsonFile['file'] = file;
      jsonFile['fileChanged'] = true;
      jsonFile['filename'] = file.name;
    }
    event1.files = null;
    this.confirmationService.confirm({
      message: 'Are you sure you want to upload file?',
      header: 'Confirm',
      icon: 'pi pi-exclamation-triangle',
      accept: () => {
        //console.log(data)
        self.getFile(jsonFile,compliance);
      }
    });
  }
  complianceopenModal(event1: any, compliance) {
    //console.log(event1);
    const data = event1;
    const self = this;
    const jsonFile = {}
    for (let file of event1) {
      //console.log(file)
      jsonFile['file'] = file;
      jsonFile['fileChanged'] = true;
      jsonFile['filename'] = file.name;
    }
    event1.files = null;
    this.confirmationService.confirm({
      message: 'Are you sure you want to upload file?',
      header: 'Confirm',
      icon: 'pi pi-exclamation-triangle',
      accept: () => {
        var allowedExtensions = environment.commonfiletype;
        if (allowedExtensions.exec( jsonFile['filename'])) {
          console.log("true");
          self.getFile1(jsonFile, compliance);
       
       } else {
          console.log("false");
          this.messageService.add({ severity: 'error', summary: 'Error', detail: 'This file type is not supported', life: 1000 });
       }

        //console.log(data)
        // self.getFile1(jsonFile, compliance);
      }
    });
  }

  getFile(event1,compliance) {
    debugger
    this.file = event1.file;
    compliance.file = event1.file;
    compliance.fileChanged = event1.fileChanged;
    this.fileChanged = event1.fileChanged;
    this.compliance.filename = event1.filename.replace(/[&\/\\#, +()$~%;'":*?<>{}@!~`^]/g, '');
  }
  getFile1(event1, compliance) {
    debugger
    this.file = event1.file;
    this.fileChanged = event1.fileChanged;
    compliance.file = event1.file;
    compliance.fileChanged = event1.fileChanged;

    compliance.filename = event1.filename.replace(/[&\/\\#, +()$~%;'":*?<>{}@!~`^]/g, '');
    compliance.fileChanged = true;
    compliance.tempname = event1.filename.replace(/[&\/\\#, +()$~%;'":*?<>{}@!~`^]/g, '');
    compliance.file_path = this.company_id + '/compliance_docs/' + this.file.name.replace(/[&\/\\#, +()$~%;'":*?<>{}@!~`^]/g, '');
    if (this.staffId !== '' && this.staffId != null) {
      compliance.file_path = this.company_id + '/compliance_docs/staff/' + this.staffId + '/' + this.file.name.replace(/[&\/\\#, +()$~%;'":*?<>{}@!~`^]/g, '');
    } else {
      compliance.file_path = this.company_id + '/compliance_docs/' + this.file.name.replace(/[&\/\\#, +()$~%;'":*?<>{}@!~`^]/g, '');
    }
    compliance.filename = this.file.name.replace(/[&\/\\#, +()$~%;'":*?<>{}@!~`^]/g, '');
  }
  hideDialog() {
    this.complianceDialog = false;
  }

  downloadFile(compliance: any) {
    //console.log('compliance', compliance);
    import('file-saver').then(FileSaver => {
      if (compliance.staff_id !== null && compliance.staff_id !== '') {
        this.crudService.fetchStaffComplianceFile(compliance.company_id, compliance.staff_id, compliance.file_path).subscribe({next: (blob) => {
          const a = document.createElement('a')
          const objectUrl = URL.createObjectURL(blob)
          a.href = objectUrl
          a.download = compliance.filename;
          a.click();
          URL.revokeObjectURL(objectUrl);
        }, error: (err) => {
          this.crudService.handleFileDownloadError(err);
        }})
      } else {
        this.crudService.fetchComplianceFile(compliance.company_id, compliance.file_path, compliance.filename).subscribe({next: (blob) => {
          const a = document.createElement('a')
          const objectUrl = URL.createObjectURL(blob)
          a.href = objectUrl
          a.download = compliance.filename;
          a.click();
          URL.revokeObjectURL(objectUrl);
        }, error: (err) => {
          this.crudService.handleFileDownloadError(err);
        }})
      }
    });
  }

  deleteFile() {
    this.confirmationService.confirm({
      message: 'Are you sure you want to delete file?',
      header: 'Confirm',
      icon: 'pi pi-exclamation-triangle',
      accept: () => {
        this.fileChanged = true;
        this.compliance.file_path = '';
        this.compliance.filename = '';
        this.file = null;
      }
    });
  }

  resetLastCheckPoint(compliance) {
    //console.log(compliance);
    compliance = compliance.backup;
    //console.log(compliance);
  }
  backup(compliance) {
    //console.log("backup",compliance);
    compliance = compliance.backup;
  }

  async saveRecords() {
    this.isEditAll = false;
    this.submitted = true
    //console.log("this is working",this.compliances);
    if (!(typeof this.staffId == 'undefined')) {
      if (this.staffId === '' || this.staffId == null) {
        this.compliance.staff_id = null;
      } else {
        this.compliance.staff_id = this.staffId;
      }
      if (this.selectedComplianceType !== null && this.selectedComplianceType !== '') {
        this.compliance.compliance_id = this.selectedComplianceType.id;
      }
    }
    if (this.isActive) {
      this.compliance.status = '1';
    }
    if (!this.isActive) {
      this.compliance.status = '2';
    }
    //console.log("form",this.complianceForm.status);
    if (this.complianceForm.status === 'VALID') {
      if (this.fileChanged) {
        if (this.compliance.filename) {
          let res = this.crudService.uploadCompliance(this.file, this.company_id, this.staffId);
          let response = JSON.parse(res)
          if (response != null) {
            if (response.success === 1) {
              this.compliance.file_path = this.company_id + '/compliance_docs/' + this.file.name.replace(/[&\/\\#, +()$~%;'":*?<>{}@!~`^]/g, '');
              this.compliance.s3_url = response?.s3Url;
              this.compliance.sync_status = response?.s3Url ? 1 : 0;
              if (this.staffId !== '' && this.staffId != null) {
                this.compliance.file_path = this.company_id + '/compliance_docs/staff/' + this.staffId + '/' + this.file.name.replace(/[&\/\\#, +()$~%;'":*?<>{}@!~`^]/g, '');
              } else {
                this.compliance.file_path = this.company_id + '/compliance_docs/' + this.file.name.replace(/[&\/\\#, +()$~%;'":*?<>{}@!~`^]/g, '');
              }
              this.compliance.filename = this.file.name.replace(/[&\/\\#, +()$~%;'":*?<>{}@!~`^]/g, '');
              this.saveComplianceInfo();
            } else {
              this.messageService.add({ severity: 'error', summary: 'Error', detail: 'This file type is not supported', life: 1000 });
            }
          }
        }
      } else {
        this.saveComplianceInfo();
      }
    } else {
      this.messageService.add({ severity: 'error', summary: 'error', detail: 'Compliance Not Added. Clear Errors and try again.', life: 1000 });
    }
  }

  async saveRecordNew(compliance) {
    let status: Boolean = true;
    if (compliance.item_name.trim()) {
      if (compliance.id) {
        //console.log("this is working",compliance);
        if (compliance.isActive) {
          compliance.status = '1';
        }
        if (!compliance.isActive) {
          compliance.status = '2';
        }
        let data: any = await this.crudService.Update(compliance, 'CompanyCompliance').toPromise();

        if (data.success == '1') {
          compliance.changed = false;
        } else {
          status = false;
        }
      } else {
        compliance.status = '1'
        compliance.isActive = true;
        let data: any = await this.crudService.Create(compliance, 'CompanyCompliance').toPromise();
        if (data.success == '1') {
          compliance.changed = false;
        } else {
          status = false;
        }
      }
      compliance.status_desc  = '';
    } else {
      status = false;
      compliance.status_desc  = '';
    }
    return status;
  }
  switchFun() {
    if (this.switchFlag == true) {
      this.switchFlag = false;
      this.viewFlag = 'Table View';
    }
    else {
      this.switchFlag = true;
      this.viewFlag = 'Grid View';
    }

  }
  addNewRecords() {
    this.isActive = true;

    let compliance = {
      amount: null,
      comment: null,
      company_id: this.company_id,
      staff_id: this.staffId,
      compliance_id: null,
      compliance_type_id: null,
      created_at: null,
      created_by: null,
      expiry_date: null,
      file_path: null,
      filename: '',
      id: null,
      issued_by: null,
      issued_date: null,
      last_modified_by: null,
      last_reminder_date: null,
      last_updated_at: null,
      owner: null,
      policy_no: null,
      reminder_count: null,
      reminder_status: null,
      status: '',
      statusFlag: 1,
      isEdit: true,
      slectedName: null,
      s3_url: null,
      sync_status: 0
    };
    this.selectedComplianceType = {};
    this.compliances = [compliance, ...this.compliances];

  }
  saveNewRecords() {
    this.loading = true;
    this.disableButton = true;
    let saveStatus = true;
    let changedRecords = this.compliances.filter(val => (val.changed === true));
    if (changedRecords.length != 0) {
      this.changedRecCount = changedRecords.length
      changedRecords.forEach(async (compliance, index) => {
        this.loading = true;
        await this.saveComplianceRecord(compliance, index);
      });

    }
    else {
      this.getCompliance();
    }
    // this.isEditAll = true;
    let editedRecords = this.compliances.filter(val => (val.isEdit === true));
    editedRecords.forEach(element => {
      element.isEdit = false;
    });
    this.loading = false;
  }

  saveComplianceRecord(compliance, index) {
    this.submitted = true;
    this.loading = true;
    if (!(typeof this.staffId == 'undefined')) {
      if (this.staffId === '' || this.staffId == null) {
        compliance.staff_id = null;
      } else {
        compliance.staff_id = this.staffId;
      }
      if (compliance.slectedName !== null && compliance.slectedName !== '') {
        compliance.compliance_id = compliance.slectedName.id;
      }
    }
    if(compliance.slectedName === undefined) {
      compliance.slectedName = null;
    }
    if (this.isActive) {
      compliance.status = '1';
    }
    if (!this.isActive) {
      compliance.status = '2';
    }
    compliance.status_desc  = '';
    console.log("compliance",compliance);
    if (compliance.issued_by != '' && compliance.issued_by != null && compliance.policy_no != '' && compliance.policy_no != null && compliance.issued_date != '' && compliance.issued_date != null && compliance.expiry_date != '' && compliance.expiry_date != null && compliance.filename != '' && compliance.filename != null) {
      if (compliance.fileChanged) {
        if (compliance.filename) {
          let res = this.crudService.uploadCompliance(compliance.file, this.company_id, this.staffId);
          let response = JSON.parse(res)
          if (response != null) {
            if (response.success === 1) {
              // compliance.file_path = this.company_id + '/compliance_docs/' + this.file.name;
              // if (this.staffId !== '' && this.staffId != null) {
              //   compliance.file_path = this.company_id + '/compliance_docs/staff/' + this.staffId + '/' + this.file.name;
              // } else {
              //   compliance.file_path = this.company_id + '/compliance_docs/' + this.file.name;
              // }
              // compliance.filename = this.file.name;
              compliance.s3_url = response?.s3Url;
              compliance.sync_status = response?.s3Url ? 1 : 0;
              this.saveComplianceInfoRecord(compliance, index);
              this.isEditAll = false;

              this.loading = false;
              this.disableButton = false;

            } else {
              this.messageService.add({ severity: 'error', summary: 'Error', detail: 'This file type is not supported', life: 1000 });
              this.isEditAll = false;

              this.loading = false;
              this.disableButton = false;
            }
          }
        }
       
      } else {
        this.saveComplianceInfoRecord(compliance, index);
        this.isEditAll = false;
        
      }
      
    } else {
      this.messageService.add({ severity: 'error', summary: 'error', detail: 'Please fill all the fields to save. Compliance not added.', life: 1000 });
      //this.getCompliance();
      this.isEditAll = true;
      this.loading = false;
      this.disableButton = false;
    }
  }
  saveComplianceInfoRecord(compliance, index) {
    this.loading = true;
    if (compliance.filename !== '' && compliance.filename !== null) {
      if (compliance.id) {
        compliance.created_at = new Date().toDateString();
        compliance.created_by = this.userDetails.id;
        this.crudService.Update(compliance, 'CompanyCompliance').subscribe((data: any) => {
          if (data.success === 1) {
            compliance.fileChanged = false;
            if (this.changedRecCount == index + 1) {
              // this.compliances = [];
              this.getCompliance();
            }

            this.messageService.add({ severity: 'success', summary: 'Successful', detail: 'Compliance Updated', life: 1000 });
            this.complianceDialog = false;
            compliance.fileChanged = false;
            this.loading = false;
            this.disableButton = false;

          } else {
            this.messageService.add({ severity: 'error', summary: 'Error', detail: 'Contact Details Not Updated', life: 1000 });
            this.loading = false;
            this.disableButton = false;
          }
        });
      } else {
        compliance.created_at = new Date().toDateString();
        compliance.created_by = this.userDetails.id;
        this.crudService.Create(compliance, 'CompanyCompliance').subscribe((data: any) => {
          //console.log(data);
          if (data.success == 1) {
            this.fileChanged = false;
            compliance.id = data.id;
            if (this.changedRecCount == index + 1) {
              // this.compliances = [];
              this.getCompliance();
            }
            this.messageService.add({ severity: 'success', summary: 'Successful', detail: 'Compliance Added', life: 1000 });
            this.complianceDialog = false;

            this.disableButton = false;

          } else {
            this.messageService.add({ severity: 'error', summary: 'Successful', detail: 'Compliance Not Added', life: 1000 });
            this.disableButton = false;
          }
        });
      }
    } else {
      this.messageService.add({ severity: 'error', summary: 'error', detail: 'File must be uploaded to proceed with saving Compliance Info', life: 1000 });

      this.loading = false;
      this.disableButton = false;
    }
  }
  onSelectedItemChange(event, compliance) {
    //console.log("This is working",rateclass);
    compliance.compliance_id = event.value?.id;
    compliance.changed = true;
  }

  saveConfirm() {
    this.confirmationService.confirm({
      message: 'Are you sure you want to Save the Complainces',
      header: 'Confirm',
      icon: 'pi pi-exclamation-triangle',
      accept: () => {
        this.loading = true;
        this.saveCompliance();
      }
    });
  }

  saveConfirmnewRecords() {
    this.confirmationService.confirm({
      message: 'Are you sure you want to Save the Complainces',
      header: 'Confirm',
      icon: 'pi pi-exclamation-triangle',
      accept: () => {
        this.loading = true;
        this.saveNewRecords();
      }
    });
  }

 async sendlink(){  
  
  if(this.displayPdf){
    this.companyCompliance.status='2';
    await this.CommonService.updateRecord('companycompliance', this.companyCompliance);
    this.loadComplianceInfo();
  }
     this.crudService.getStaffByCID(this.company_id).subscribe((data: any[]) => {
      let Adminstaffs = data.filter(val => (val.user_role_id == 1 ));
      this.staff = Adminstaffs[0];
      if(Adminstaffs[0]){
     this.send_SPA_MAil()
     this.sendOTP()
      }
      else{
        this.messageService.add({ severity: 'info', summary: 'Info', detail: 'Add admin staff details to send mail', life: 1000 });
      }

    });
  }
  async send_SPA_MAil(){
    this.sendlinkvia = false  // by default it will send only to mail
    let encrypted = this.EncrDecr.encrypt(  this.staff.company_id +' '+this.staff.id +' '+this.sendlinkvia ,'123456$#@$^@1ERF',);
    this.emailSubject = 'Service Provider Agreement(SPA) Access URL'
    this.spa_access_Url = this.env.base_url+'business/SPA/'+ encrypted 
      await this.tinyUrl.shorten(this.spa_access_Url).subscribe(async res => {
      this.spa_access_Url = res;
      console.log("this.spa_access_Url",this.spa_access_Url);
      if(res){
        this.emailContent = 'Hi ' + this.staff.first_name?.trim() + ' , <br/><br/>Please sign Service Provider Agreement by clicking the below link<br/> '+ '<body> <a href="'+this.spa_access_Url +'">' + this.spa_access_Url + '</a></body><br/>'
        let temp_type = 'sms-email';
        let smsData  = ''
        // this.templ = {};
        // this.templ.template = [{ email: {} }];
        // this.templ.template[0].email= this.staff.email
        let dataObj = {
          sms: smsData,
          email: this.staff.email,
          type: temp_type,
          selectedTpl: 'd-fa99d348216144cfb8d39f2a67111474',
          sufix: null,
          jobData: null,
          attachments: null,
          account: this.env.hosting,
        }
        await this.storeInSchedule(dataObj);
      }
      console.log( this.spa_access_Url); 
    });
  }
  storeInSchedule(obj) {
    const datobj2 = {
      subject : this. emailSubject,
      body : this.emailContent,
      toEmail : obj.email,
      ref_id:this.staff.id,
      ref_type:'accounts'
      }
      if(this.sendlinkvia == false){
        this.crudService.sendmailSendgrid(datobj2,'commonMail').subscribe((res2: any) => { 
          this.loading = false;
          if(res2.success == 200)
          {
             this.messageService.add({ severity: 'success', summary: 'Successful', detail: 'Email Sent', life: 3000 });
          }
          else
          {
            console.log("res2.Data.body.errors[0].message",res2);
            let message = res2.Data.response.body.errors[0].message;
            this.messageService.add({ severity: 'error', summary: 'Error', detail: message, life: 3000 });
          }

         });
      }
      else if(this.sendlinkvia == true){
        let params = {
           sms: {
            to: this.staff.mobile,
            message: 'Hi '+ this.staff.first_name?.trim() + ', Please sign SPA by clicking ' + this.spa_access_Url ,
           subject: this. emailSubject,
          },   type: 'sms-email',
          selectedTpl: null,
          sufix: null,
        }
        this.crudService.sendTMV2Message(params).subscribe((res2: any) => {
            // this.visible = false;
            this.messageService.add({ severity: 'success', summary: 'Successful', detail: 'SMS Sent ', life: 1000 });
        });
      }
   
  }
  async sendOTP(){
    this.sendlinkvia = false // by default it will send only to mail
    let otp_datas:any  = await this.crudService.getDataByField('StaffOTPTransact', 'staff_id', this.staff.id).toPromise();
    console.log("otp_datas",otp_datas);
    let filtered_otp = otp_datas.filter(val => (val.status == 0 && val.page == 1));
    filtered_otp?.forEach( val => {
      val.status= 2
      let ress: any =  this.CommonService.saveRecord('StaffOTPTransact', val);
    })

  var random_OTP : any = Math.floor(100000 + Math.random() * 900000);
  random_OTP = random_OTP.toString().substring(0, 4);
  random_OTP =  parseInt(random_OTP);
  console.log("random_OTP",random_OTP);
  let otp_details ={
    id : null,
    company_id : this.staff.company_id,
    staff_id : this.staff.id,
    otp :random_OTP,
    status  :0,
    email_to :null,
    phone_to  :null,
    page : 1,  // page = 1 for Spa
    created_at  :  new Date(), 
    created_by : this.staff.id,
  }
  if(this.sendlinkvia == false){
    otp_details.email_to = this.staff.email
  }
  else{
    otp_details.phone_to = this.staff.mobile
  }
  let res: any = await this.CommonService.saveRecord('StaffOTPTransact', otp_details);
  console.log("Res",res);

  let params = {
    email: {
      to: this.staff.email, cc: '', bcc: null,
      msg: 'Your OTP (One Time Password) for access SPA is ' + '<h4>'+random_OTP+'</h4>'
      + 'Please use this password to access and sign the document. Do not share this password with anyone',
      subject: 'Verification Code To Access SPA ',
    },    type: 'sms-email',
    selectedTpl: null,
    sufix: null,
  }
  if(this.sendlinkvia == false){
    this.sendOTPMail(params);
  }
  else if(this.sendlinkvia == true){
    let params = {
      sms: {
        to: this.staff.mobile,
        message: 'Your OTP (One Time Password) for access SPA is '+ random_OTP
        + '. Please use this password to access and sign the document. Do not share this password with anyone',
      subject:'Verification Code To Access SPA',
      },   type: 'sms-email',
      selectedTpl: null,
      sufix: null,
    }

    this.crudService.sendTMV2Message(params).subscribe((res2: any[]) => {
      console.log(res2);
        // this.visible = false;
        this.messageService.add({ severity: 'success', summary: 'Successful', detail: 'OTP SMS Sent ', life: 1000 });
    });
    }
  }
sendOTPMail(obj) {
const datobj2 = {
  subject :obj.email.subject,
  body : obj.email.msg,
  toEmail : obj.email.to,
  ref_id:this.staff.id,
  ref_type:'accounts'
  }
  console.log("datobj2",datobj2)
  this.crudService.sendmailSendgrid(datobj2,'commonMail').subscribe((res2: any) => { 
  this.loading = false;
  if(res2.success == 200)
  {
    // this.visible = false;
    this.messageService.add({ severity: 'success', summary: 'Successful', detail: 'OTP Email Sent', life: 3000 });
  }
  else
  {
    console.log("res2.Data.body.errors[0].message",res2);
    let message = res2.Data.response.body.errors[0].message;
    this.messageService.add({ severity: 'error', summary: 'Error', detail: message, life: 3000 });
  }
 });
}
  async loadComplianceInfo() {
  let data: any = await this.crudService.getDataByField('CompanyCompliance', 'company_id', this.company_id).toPromise();
  if(environment.hosting == 'AU')
  {
    this.slaData = data.filter(val => (val.compliance_id == '75' && val.status == '1'));
  }
  else if(environment.hosting == 'UK')
  {
    this.slaData = data.filter(val => (val.compliance_id == '165' && val.status == '1'));
  }
  
  if (this.slaData.length > 0) {
    this.companyCompliance = this.slaData[0];
    this.displayPdf = true;
  } else {
    
    this.displayPdf = false;
}
  }
}