import { Component, OnInit, Input } from '@angular/core';
import { ComponentsComunicationService } from '../../components-comunication.service';

@Component({
  selector: 'people-list-item',
  templateUrl: './people-list-item.component.html',
  styleUrls: ['./people-list-item.component.css']
})
export class PeopleListItemComponent implements OnInit {

  isFilterByPersonActive: boolean = false;

  @Input()
  person: any

  constructor(private componentsComunicationService: ComponentsComunicationService) { }

  ngOnInit(): void {
  }

  onClickFilter(person, event) {
    //console.log("onClickFilter START. person is: ", person);
    this.isFilterByPersonActive = !this.isFilterByPersonActive;

    var eventFilterByPerson = {
      name: person.name,
      filter: this.isFilterByPersonActive
    };

    this.componentsComunicationService.changeData(eventFilterByPerson);
  }



}
