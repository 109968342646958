import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ConfirmationService, MessageService, SelectItem } from 'primeng/api';
import { DialogService, DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { BreadcrumbService } from 'src/app/breadcrumb.service';
import { CRUDService } from 'src/app/utilities/crud.service';
import { JOBService } from 'src/app/utilities/job.service';
import { JobAdminComponent } from '../../../job/job-admin/job-admin.component';
import { JobOperationsComponent } from '../../../job/job-operations/job-operations.component';
import { JobsListComponent } from '../../jobs-list/jobs-list.component';
import { environment } from 'src/environments/environment';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';

import {
  ChartComponent,
  ApexAxisChartSeries,
  ApexChart,
  ApexXAxis,
  ApexDataLabels,
  ApexStroke,
  ApexMarkers,
  ApexYAxis,
  ApexGrid,
  ApexTitleSubtitle,
  ApexLegend,
  ApexPlotOptions,
  ApexFill,
  ApexTooltip
} from 'ng-apexcharts';
import { type } from 'os';
import { WorkFlowService } from 'src/app/utilities/workflow.service';
import { JobDetailsApprovalsComponent } from 'src/app/job/job-details-approvals/job-details-approvals.component';

export type ChartOptions = {
  series: ApexAxisChartSeries;
  chart: ApexChart;
  xaxis: ApexXAxis;
  stroke: ApexStroke;
  dataLabels: ApexDataLabels;
  markers: ApexMarkers;
  colors: string[];
  yaxis: ApexYAxis;
  grid: ApexGrid;
  legend: ApexLegend;
  title: ApexTitleSubtitle;
  plotOptions: ApexPlotOptions;
  fill: ApexFill;
  tooltip: ApexTooltip;
};
@Component({
  selector: 'app-tp-dashboard1',
  templateUrl: './tp.dashboard1.component.html',
  styleUrls: ['./tp.dashboard1.component.scss']
})
export class TpDashboard1Component implements OnInit {
  @ViewChild('chart') chart: ChartComponent;
  public chartOptions1: Partial<ChartOptions>;
  public chartOptions2: Partial<ChartOptions>;

  jobsList: any[];
  widgets: any[] = [];
  allMenus: any;
  companyId: any;
  userDetails: any;
  lineChartData: any;
  lineChartOptions: any;
  dropdownYears: SelectItem[];
  selectedYear: any;
  activeNews = 1;
  cars: any[];
  selectedCar: any;
  events: any[];
  jobWidgets: any;
  taskWidgets: any;
  jobList: any;
  displayDrillDown: Boolean = false;
  JobStatus: any;
  listOfJobs: any;
  loading: Boolean = false;
  selectedOverAllStatus: any = { id: 1, name: 'Active' };
  selectedJobStatus: any[] = [];
  selectedItem: any = [];
  jobKPITiles: any[] = [];
  buckets: any;
  viewType: any;
  welcomeVideo: any;
  test: Boolean = true;
  /* jobKPITiles: any =[
    {title: 'Active Jobs', subTitle: 'All Open Jobs', additionalInfo: null, JobStatus: '', total: null, red: 0, amber: 0, green: 0},
    {title: 'To be Assigned to TP', subTitle: 'Send Offer', additionalInfo: null, JobStatus: 'Send Offer', total: null, red: 0, amber: 0, green: 0},
    {title: 'Works to be Quoted', subTitle: 'Await Cost', additionalInfo: null, JobStatus: 'Await Cost, Cost Saved', total: null, red: 0, amber: 0, green: 0},
    {title: 'Quotes Pending Approval', subTitle: 'Approve Quote', additionalInfo: null, JobStatus: 'Approve Quote', total: null, red: 0, amber: 0, green: 0},
    {title: 'Approved Works to be Completed', subTitle: 'Await Invoice', additionalInfo: null, JobStatus: 'Await Invoice', total: null, red: 0, amber: 0, green: 0},
    {title: 'Completed Works to be Invoiced', subTitle: 'Approve Invoice', additionalInfo: null, JobStatus: 'Approve Invoice', total: null, red: 0, amber: 0, green: 0}
  ]; */

  allocationFilterTypes = [
    { id: 1, title: 'Insurance', selected: false, class: '' },
    { id: 2, title: 'SES', selected: false, class: '' },
    { id: 6, title: 'DHA', selected: false, class: '' },
    { id: 3, title: 'Facility Management', selected: false, class: '' },
    { id: 4, title: 'New Zealand', selected: false, class: '' },
    { id: 5, title: 'Enviro', selected: false, class: '' },
  ]

  redirectPage(module: string) {
    this.router.navigate([module]);
  }

  constructor(private breadcrumbService: BreadcrumbService,
    private route: ActivatedRoute,
    private crudService: CRUDService,
    private messageService: MessageService,
    private confirmationService: ConfirmationService,
    private router: Router,
    public ref: DynamicDialogRef,
    public config: DynamicDialogConfig,
    public dialogService: DialogService,
    private JobService: JOBService,
    public sanitizer: DomSanitizer,
    private workflowService: WorkFlowService) {
    this.companyId = sessionStorage.getItem('company_id');
    this.userDetails = JSON.parse(sessionStorage.getItem('loggedInUser'));
    //console.log('dashboard');
    this.breadcrumbService.setItems([
      // {label: 'Dashboard', routerLink: ['/']}
    ]);
    this.test = this.crudService.isTest;

    this.welcomeVideo = this.sanitizer.bypassSecurityTrustResourceUrl(environment.siteAssets.welcomeVideo);
    this.crudService.getMenusByRole(this.userDetails.user_group_id, this.companyId).subscribe((data: any[]) => {
      //console.log(data);
      this.allMenus = [];
      data = data.filter(val => val.status !== '2');
      data = data.filter(val => (val.menu_type === '2' || val.menu_type === '3'));
      data = data.filter(val => val.menu_parent_id !== '0');
      data.forEach(tile => {
        tile.randomNumber = Math.floor(Math.random() * 4) + 1;
        this.allMenus.push(tile);
      });

      /* let userItems = data.filter(val => val.menu_type === '1');
      this.widgets.push({label: 'My Issues & Requests', items: userItems});

      let execItems = data.filter(val => val.menu_type === '2');
      this.widgets.push({label: 'Pending For Action', items: execItems});

      let adminItems = data.filter(val => val.menu_type === '3');
      this.widgets.push({label: 'Manage', items: adminItems}); */
    });

    this.chartOptions1 = {
      series: [
        // {
        //   name: "High - 2013",
        //   data: [28, 29, 33, 36, 32, 32, 33]
        // },
        {
          name: 'Eduforbetterment Blog',
          type: 'column',
          data: [12, 13, 16, 14, 20, 23, 22]
        },
        {
          name: 'Facebook Engagement',
          type: 'line',
          data: [60, 35, 66, 27, 43, 40, 31]
        },
      ],
      chart: {
        height: 350,
        type: 'line'
      },
      stroke: {
        width: [0, 4]
      },
      dataLabels: {
        enabled: true,
        enabledOnSeries: [1, 2]
      },
      colors: ['#a4b1c2', '#788598'],

      title: {
        text: 'New Jobs : Insurance Vs SES',
        align: 'left',
        style: {
          fontSize: '14px',
          fontWeight: '600',
          fontFamily: undefined,
          color: '#263238'
        },
      },
      markers: {
        size: 1
      },
      xaxis: {
        categories: ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun'],
        // title: {
        //   text: "Day"
        // }
      },
      yaxis: {
        // title: {
        //   text: "Job"
        // },
        min: 5,
        max: 70
      },
    };
  }

  filterAllocation(item) {
    item.selected = !item.selected;
    if (item.selected) {
      this.selectedItem.push(item);
    } else {
      this.selectedItem.splice(this.selectedItem.indexOf(item), 1);
    }
    this.loadJobKPI();
  }

  loadTaskTiles() {
    this.crudService.DashTaskBoardView().subscribe((data: any[]) => {
      this.taskWidgets = data[0];
    });
  }
  loadJobTiles() {
    this.crudService.DashJobStatusView().subscribe((data: any[]) => {
      data.sort((a, b) => a.display_order - b.display_order);
      data.forEach(tile => {
        tile.color = 'linear-gradient(256deg, ' + tile.display_colour + ' 0%,black 165%)';
        tile.randomNumber = Math.floor(Math.random() * 4) + 1;
      });
      this.jobWidgets = data;
      //console.log(data);
    });
  }
  async ngOnInit() {
    let companyData = await this.crudService.getDataByField('Company', 'id', this.companyId).toPromise();
    if(companyData[0].client_type_id == '3' ){
      this.viewType = 'TP';
    }
    this.listOfJobs = await this.crudService.getAllData('JobDashboardDataDump').toPromise();
    this.buckets = [...new Set(this.listOfJobs.map(item => item.TT))];

    await this.loadJobKPI();
    this.loadJobTiles();
    this.loadTaskTiles();
    this.lineChartData = {
      labels: ['January', 'February', 'March', 'April', 'May', 'June', 'July'],
      datasets: [
        {
          label: 'Sapphire',
          data: [1, 2, 5, 3, 12, 7, 15],
          borderColor: [
            '#45b0d5'
          ],
          borderWidth: 3,
          fill: false
        },
        {
          label: 'Roma',
          data: [3, 7, 2, 17, 15, 13, 19],
          borderColor: [
            '#d08770'
          ],
          borderWidth: 3,
          fill: false
        }
      ]
    };
    this.lineChartOptions = {
      responsive: true,
      maintainAspectRatio: true,
      fontFamily: '\'Candara\', \'Calibri\', \'Courier\', \'serif\'',
      hover: {
        mode: 'index'
      },
      scales: {
        xAxes: [{
          gridLines: {
            display: false
          },
          ticks: {
            fontColor: '#9199a9'
          }
        }],
        yAxes: [{
          gridLines: {
            display: false
          },
          ticks: {
            fontColor: '#9199a9'
          }
        }]
      },
      legend: {
        display: true,
        labels: {
          fontColor: '#9199a9'
        }
      }
    };

    this.dropdownYears = [
      { label: '2019', value: 2019 },
      { label: '2018', value: 2018 },
      { label: '2017', value: 2017 },
      { label: '2016', value: 2016 },
      { label: '2015', value: 2015 },
      { label: '2014', value: 2014 }
    ];
  }

  async loadJobKPI() {
    //this.jobKPITiles = [];
    let listOfJobs: any[] = [];
    if (this.selectedItem.length > 0) {
      const clients: any[] = [];
      this.selectedItem.forEach(element => {
        const clientJobsList = this.listOfJobs.filter(val => (val.wptype == element.title));
        listOfJobs = [...listOfJobs, ...clientJobsList];
      });
    } else {
      listOfJobs = this.listOfJobs;
    }

    /* if (this.selectedItem.length > 0) {
      let clients: any[] = [];
      this.selectedItem.forEach(element => {
        clients.push("'" + element.title + "'");
      });
      //console.log(clients.toString());
      let list: any = await this.crudService.getDashboardInfoByClients(clients.toString()).toPromise();
    } else {
      let list: any = await this.crudService.getAllData('JobDashboardDataDump').toPromise();
    } */

    this.buckets.forEach(bucket => {
      const bucketList = listOfJobs.filter(val => (val.TT == bucket));
      const AmberCount = bucketList.filter(val => (val.TT == bucket && val.Cat == 'Amber'));
      const RedCount = bucketList.filter(val => (val.TT == bucket && val.Cat == 'Red'));
      const GreenCount = bucketList.filter(val => (val.TT == bucket && val.Cat == 'Green'));

      /*       let Approve_Quote = bucketList.filter(val => (val.TT == bucket && val.JobStatus == 'Approve Quote'));
            let Cost_Saved = bucketList.filter(val => (val.TT == bucket && val.JobStatus == 'Cost Saved'));
            let Await_Acceptance = bucketList.filter(val => (val.TT == bucket && val.JobStatus == 'Await Acceptance'));
            let Send_Offer = bucketList.filter(val => (val.TT == bucket && val.JobStatus == 'Send Offer'));
            let Await_Cost = bucketList.filter(val => (val.TT == bucket && val.JobStatus == 'Await Cost'));
            let Await_Invoice = bucketList.filter(val => (val.TT == bucket && val.JobStatus == 'Await Invoice'));
            let Approve_Invoice = bucketList.filter(val => (val.TT == bucket && val.JobStatus == 'Approve Invoice'));
       */
      let total_cost = bucketList.reduce(function (_this, val) {
        return _this + val.Total
      }, 0);

      this.jobKPITiles.push({
        TT: bucket,
        red: RedCount.length,
        green: GreenCount.length,
        amber: AmberCount.length,
        totalCost: total_cost.toFixed(2),
        count: bucketList.length,
        /* Approve_Quote: Approve_Quote.length,
        Cost_Saved: Cost_Saved.length,
        Await_Acceptance: Await_Acceptance.length,
        Send_Offer: Send_Offer.length,
        Await_Cost: Await_Cost.length,
        Await_Invoice: Await_Invoice.length,
        Approve_Invoice: Approve_Invoice.length */
      });
    });
    this.jobKPITiles = this.jobKPITiles.sort((a, b) => a.TT > b.TT ? 1 : -1);

    //console.log(this.jobKPITiles);
  }

  async loadJobKPIOld() {
    //this.jobList = await this.workflowService.searchNow('108', this.selectedOverAllStatus,  this.selectedItem, this.selectedJobStatus, null);
    const list: any = await this.crudService.getAllData('JobDashboardByBucketsView').toPromise();
    this.jobKPITiles = list.sort((a, b) => a.TT > b.TT ? 1 : -1);

    if (this.selectedItem.length > 0) {
      const clients: any[] = [];
      this.selectedItem.forEach(element => {
        clients.push('\'' + element.title + '\'');
      });
      //console.log(clients.toString());
      const list: any = await this.crudService.getDashboardInfoByClients(clients.toString()).toPromise();
      this.jobKPITiles = list.sort((a, b) => a.TT > b.TT ? 1 : -1);

    } else {
      const list: any = await this.crudService.getAllData('JobDashboardByBucketsView').toPromise();
      this.jobKPITiles = list.sort((a, b) => a.TT > b.TT ? 1 : -1);
    }
    //console.log(this.jobKPITiles);
    /* let bucket_1_list = this.jobList.filter(val=>(val.JobStatus === 'Send Offer'));
    this.jobKPITiles[1].total = bucket_1_list.length;
    this.jobKPITiles[1].amber = bucket_1_list.length;

    let bucket_2_list = this.jobList.filter(val=>((val.JobStatus === 'Await Cost')|| (val.JobStatus === 'Cost Saved')));
    this.jobKPITiles[2].total = bucket_2_list.length;
    this.jobKPITiles[2].amber = bucket_2_list.length;

    let bucket_3_list = this.jobList.filter(val=>(val.JobStatus === 'Approve Quote'));
    this.jobKPITiles[3].total = bucket_3_list.length;
    this.jobKPITiles[3].amber = bucket_3_list.length;

    let bucket_4_list = this.jobList.filter(val=>(val.JobStatus === 'Await Invoice'));
    this.jobKPITiles[4].total = bucket_4_list.length;
    this.jobKPITiles[4].amber = bucket_4_list.length;

    let bucket_5_list = this.jobList.filter(val=>(val.JobStatus === 'Approve Invoice'));
    this.jobKPITiles[5].total = bucket_5_list.length;
    this.jobKPITiles[5].amber = bucket_5_list.length;

    this.jobKPITiles[0].total = this.jobList.length;
    this.jobKPITiles[0].amber = this.jobList.length;
 */
  }

  redirect(item) {
    //console.log(item);
    sessionStorage.setItem('menuData', JSON.stringify(item));
    if (item.router_url != null) {
      this.router.navigateByUrl(item.router_url);
    } else {
      this.router.navigateByUrl('/coming-soon');
    }
  }

  viewJobsListByCatg(bucket, catg) {
    const jobsList = this.listOfJobs.filter(val => (val.TT == bucket.TT && val.Cat == catg));
    const ref = this.dialogService.open(JobsListComponent, {
      data: {
        list: jobsList
      },
      width: '100%',
      height: '100%',
      styleClass: '',
      header: 'Job List - ' + bucket.TT + ' ' + catg
    })
    ref.onClose.subscribe(async (response: any) => {
      this.listOfJobs = await this.crudService.getAllData('JobDashboardDataDump').toPromise();
      this.buckets = [...new Set(this.listOfJobs.map(item => item.TT))];
      await this.loadJobKPI();
    })
  }

  viewJobsListByBucket(bucket) {
    const jobsList = this.listOfJobs.filter(val => (val.TT == bucket.TT));
    const ref = this.dialogService.open(JobsListComponent, {
      data: {
        list: jobsList
      },
      width: '100%',
      height: '100%',
      styleClass: '',
      header: 'Job List - ' + bucket.TT
    })
    ref.onClose.subscribe(async (response: any) => {
      this.listOfJobs = await this.crudService.getAllData('JobDashboardDataDump').toPromise();
      this.buckets = [...new Set(this.listOfJobs.map(item => item.TT))];

      await this.loadJobKPI();
    })
  }

  viewDrillDown(JobStatus) {
    /* this.crudService.getDashJobStatusDrillDown(JobStatus).subscribe((data: any[]) => {
        this.jobsList = data;
        //console.log(data);
        this.JobStatus = JobStatus;
    }); */
    this.crudService.getDataByField('Menus', 'id', '1').subscribe((data: any[]) => {
      const menuData = data[0];
      //console.log(data);
      sessionStorage.setItem('menuData', JSON.stringify(menuData));

      const ref = this.dialogService.open(JobOperationsComponent, {
        data: {
          jobStatus: JobStatus,
          reqId: this.companyId,
          filter_by: null
        },
        width: '100%',
        height: '100%',
        styleClass: '',
        header: 'Job Operations - ' + JobStatus
      })
      ref.onClose.subscribe((response: any) => {
        //this.loadJobTiles();
      })
    });
  }

  viewTask() {
    this.crudService.getDataByField('Menus', 'id', '83').subscribe((data: any[]) => {
      const menuData = data[0];
      //console.log(data);
      sessionStorage.setItem('menuData', JSON.stringify(menuData));
      this.router.navigateByUrl(menuData.router_url)
    })
  }

  async viewJobAction(job) {
    this.crudService.getDataByField('Menus', 'id', '83').subscribe((data: any[]) => {
      const menuData = data[0];
      //console.log(data);
      sessionStorage.setItem('menuData', JSON.stringify(menuData));
    });
    this.loading = true;
    if (this.JobStatus === 'Cost Saved' || this.JobStatus === 'Approve Quote' || this.JobStatus === 'Await Cost' || this.JobStatus === 'Quote Rejected') {
      const smryData: any = await this.crudService.getJobSummary(job.jobid, job.AllocationId).toPromise();
      const jobAllocationResults = await this.JobService.getJobInfo(smryData[0]);
      const viewType = 'Quote';
      this.loading = false;
      const ref = this.dialogService.open(JobDetailsApprovalsComponent, {
        data: {
          jobId: job.jobid,
          allocId: job.AllocationId,
          jobSummary: jobAllocationResults,
          viewType,
          defaultTab: 'schedule'
        },
        width: '100%',
        height: '100%'
      })
    } else if (this.JobStatus === 'Approve Invoice' || this.JobStatus === 'Await Invoice' || this.JobStatus === 'Invoice Processed' || this.JobStatus === 'Accounts Proecessed' || this.JobStatus === 'Job Completed') {
      const smryData: any = await this.crudService.getJobSummary(job.jobid, job.AllocationId).toPromise();
      const jobAllocationResults = await this.JobService.getJobInfo(smryData[0]);
      const viewType = 'Approval';
      this.loading = false;
      const ref = this.dialogService.open(JobDetailsApprovalsComponent, {
        data: {
          jobId: job.jobid,
          allocId: job.AllocationId,
          jobSummary: jobAllocationResults,
          viewType,
          defaultTab: 'schedule'
        },
        width: '100%',
        height: '100%'
      })
    } else if (this.JobStatus === 'Await Acceptance' || this.JobStatus === 'Send Offer') {
      this.loading = false;
      this.editSelectedRecord(job);
    } else if (this.JobStatus === 'Quote Rejected' || this.JobStatus === 'Send Offer') {
      this.loading = false;
      this.editSelectedRecord(job);
    }
  }

  editSelectedRecord(job) {
    const ref = this.dialogService.open(JobAdminComponent, {
      data: {
        jobId: job.jobid,
      },
      width: '100%',
      height: '100%'
    })
  }

  viewJob(job) {
    this.router.navigate(['/job/job-details', job.jobid, job.AllocationId])
  }
  resetFilters() {
    this.allocationFilterTypes = [
      { id: 1, title: 'Insurance', selected: false, class: '' },
      { id: 2, title: 'SES', selected: false, class: '' },
      { id: 6, title: 'DHA', selected: false, class: '' },
      { id: 3, title: 'Facility Management', selected: false, class: '' },
      { id: 4, title: 'New Zealand', selected: false, class: '' },
      { id: 5, title: 'Enviro', selected: false, class: '' },
    ]
    this.selectedItem = []
  }
  addFilters() {
    const arrayjson = [
      { id: 1, title: 'Insurance', selected: false, class: '' },
      { id: 2, title: 'SES', selected: false, class: '' },
      { id: 6, title: 'DHA', selected: false, class: '' },
      { id: 3, title: 'Facility Management', selected: false, class: '' },
      { id: 4, title: 'New Zealand', selected: false, class: '' },
      { id: 5, title: 'Enviro', selected: false, class: '' },
    ]
    this.allocationFilterTypes = arrayjson;
    this.selectedItem.length = 6;
    // //console.log(this.selectedItem)
  }
}
