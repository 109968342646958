import { Component, OnInit, ViewChild, ChangeDetectorRef } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { DialogService, DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { BreadcrumbService } from 'src/app/breadcrumb.service';
import { CRUDService } from 'src/app/utilities/crud.service';
import { JOBService } from 'src/app/utilities/job.service';
import { JobAdminComponent } from '../../../job/job-admin/job-admin.component';
import { JobOperationsComponent } from '../../../job/job-operations/job-operations.component';
import { WorkFlowService } from 'src/app/utilities/workflow.service';
import { JobDetailsApprovalsComponent } from 'src/app/job/job-details-approvals/job-details-approvals.component';
import { environment } from 'src/environments/environment';


@Component({
  selector: 'app-dashboard1',
  templateUrl: './dashboard1.component.html',
  styleUrls: ['./dashboard1.component.css']
})
export class Dashboard1Component implements OnInit {

  jobsList: any[];
  widgets: any[] = [];

  companyId: any;
  userDetails: any; 
 
  lastRefresh = new Date(); 

  events: any[];
  jobWidgets: any;
  taskWidgets: any;
  jobList: any;
  displayDrillDown: Boolean = false;
  JobStatus: any;
  listOfJobs: any;
  loading: Boolean = false;
  selectedOverAllStatus: any = { id: 1, name: 'Active' };
  selectedJobStatus: any[] = [];
  selectedItem: any = [];
  jobKPITiles: any[] = [];
  buckets: any;
  openJobDetails : Boolean = false;
  jobDetailsData : any = [];
  categories: any[] = [
    { code: 'B1', description: 'Pending for Allocation', status: ['Send Offer'], count: 0, red: 0, amber: 0, green: 0, total: 0 },
    { code: 'B2', description: 'To be accepted by TP', status: ['Await Acceptance'], count: 0, red: 0, amber: 0, green: 0, total: 0 },
    { code: 'B3', description: 'Works to be quoted', status: ['Await Cost', 'Cost Saved'], count: 0, red: 0, amber: 0, green: 0, total: 0 },
    { code: 'B4', description: 'Quotes Pending For Approval', status: ['Approve Quote'], count: 0, red: 0, amber: 0, green: 0, total: 0 },
    { code: 'B5', description: 'Approved works to be Completed', status: ['Await Invoice'], count: 0, red: 0, amber: 0, green: 0, total: 0 },
    { code: 'B6', description: 'Works completed to be Invoiced', status: ['Approve Invoice'], count: 0, red: 0, amber: 0, green: 0, total: 0 },
    { code: 'B7', description: 'Jobs Watched by You', status: ['Await Cost', 'Approve Quote', 'Await Acceptance',  'Await Invoice', 'Approve Invoice', 'Cost Saved', 'Send Offer'], count: 0, red: 0, amber: 0, green: 0, total: 0 }
  ];
  viewType: any = '';
  test: boolean = true;
  companyList: any;

  usersList: any;

  jobsData: any;
  filterValue: any;
  filterBy: any;
  headerValue: any;
  catg: any;
  bucket: any;

  tpTaskJobs: any = [];
 
  allocationFilterTypes = [
    { id: 1, title: 'Insurance', selected: false, class: '' },
    { id: 2, title: 'SES', selected: false, class: '' },
    { id: 6, title: 'DHA', selected: false, class: '' },
    { id: 3, title: 'Facility Management', selected: false, class: '' },
    { id: 4, title: 'New Zealand', selected: false, class: '' },
    { id: 5, title: 'Enviro', selected: false, class: '' },
  ]
  disablejobSts: boolean=false;

  redirectPage(module: string) {
    this.router.navigate([module]);
  }

  constructor(private breadcrumbService: BreadcrumbService,
    private crudService: CRUDService,
    private router: Router,
    public ref: DynamicDialogRef,
    public config: DynamicDialogConfig,
    public dialogService: DialogService,
    private JobService: JOBService,
    public cdr: ChangeDetectorRef,
    private workflowService: WorkFlowService) {
    this.companyId = localStorage.getItem('company_id');
    this.userDetails = JSON.parse(localStorage.getItem('loggedInUser'));
  
    this.breadcrumbService.setItems([
    
    ]);

   
  }
 

 
  env: any;

  async ngOnInit() {
    this.loading = true;
    this.viewType = '';
    this.env = environment;
  
    this.test = this.crudService.isTest;
    let companyData = await this.crudService.getDataByField('Company', 'id', this.companyId).toPromise();
    if (companyData[0].client_type_id == '3') {
      this.viewType = 'TP';
      this.loading = false;
    } else {
      this.viewType = 'TM';
      await this.loadTiles();
    
    }
    
  }

  

  filterAllocation(item) {
    item.selected = !item.selected;
    if (item.selected) {
      this.selectedItem.push(item);
    } else {
      this.selectedItem.splice(this.selectedItem.indexOf(item), 1);
    }
    this.loadJobsTiles();
   
  }


 
  async loadTiles() {
    this.loading = true;
    this.lastRefresh = new Date();
    // this.listOfJobs = await this.crudService.getAllData('JobDashboardDataDump').toPromise();
    // console.log('listOfJobss', this.listOfJobs);
    // this.buckets = [...new Set(this.listOfJobs.map(item => item.TT))];
   
    await this.loadJobTiles();  
    await this.searchNow();    
    this.loading = false;
  }

  async loadJobTiles() {
    let data: any = await this.crudService.DashJobStatusView().toPromise();
    data.sort((a, b) => a.display_order - b.display_order);
    data.forEach(tile => {
      tile.color = 'linear-gradient(256deg, ' + tile.display_colour + ' 0%,black 165%)';
      tile.randomNumber = Math.floor(Math.random() * 4) + 1;
    });
    this.jobWidgets = data;
   // console.log('loadJobTiless',this.jobWidgets)
  }
  

  redirect(item) {
  
    sessionStorage.setItem('menuData', JSON.stringify(item));
    if (item.router_url != null) {
      this.router.navigateByUrl(item.router_url);
    } else {
      this.router.navigateByUrl('/coming-soon');
    }
  }


  viewJobsListByCatg(bucket, catg) {

    let jobsList: any;
    if (catg == 'Red') {
      jobsList = bucket.jobs.filter(val => (val.task_count > 0));
    } else if (catg == 'Amber') {
      jobsList = bucket.jobs.filter(val => (val.task_count < 1 && new Date() > val.dDay && val.daysToDday != null));
    } else {
      jobsList = bucket.jobs.filter(val => (val.task_count < 1 && (new Date() < val.dDay || val.daysToDday == null)));
    }
    this.jobsData = jobsList;
   // console.log('this.jobsDataaa',this.jobsData)
    this.filterValue = null;
    this.filterBy = 'Dashboard';
    this.bucket = bucket.code,
    this.catg = catg
    this.headerValue= 'Job List - ' + bucket.code + ' - ' + bucket.description + ' - ' + catg
    this.openJobDetails = true;

   
  }

  viewJobsListByBucket(bucket) {

   // console.log('viewJobsListByBucket',bucket)
    this.jobsData = bucket.jobs;
    this.filterValue = null;
    this.filterBy = 'Dashboard';
    this.bucket = 'All';
    this.catg = 'All';
    this.headerValue= 'Job List - ' + bucket.code + ' - ' + bucket.description;
    this.openJobDetails = true;
  
    
  }

  viewDrillDown(JobStatus) {
    this.disablejobSts=true
    console.log("viewDrillDown")
    this.crudService.getDataByField('Menus', 'id', '1').subscribe((data: any[]) => {
      const menuData = data[0];
     // console.log('viewDrillDown menuData',menuData);
      sessionStorage.setItem('menuData', JSON.stringify(menuData));
      this.disablejobSts=false
      const ref = this.dialogService.open(JobOperationsComponent, {
        data: {
          jobStatus: JobStatus,
          JobStatusLive: JobStatus,
          reqId: this.companyId,
          filter_by: null
        },
        width: '100%',
        height: '100%',
        styleClass: '',
        header: 'Job Operations - ' + JobStatus
      })
      ref.onClose.subscribe((response: any) => {
        this.disablejobSts=false
        //this.loadJobTiles();
      })
    });
  }

  ngOnDestroy() {
    if (this.ref) {
        this.ref.close();
    }
}


  async viewJobAction(job) {
    this.crudService.getDataByField('Menus', 'id', '83').subscribe((data: any[]) => {
      const menuData = data[0];
      //console.log(data);
      sessionStorage.setItem('menuData', JSON.stringify(menuData));
    });
    this.loading = true;
    if (this.JobStatus === 'Cost Saved' || this.JobStatus === 'Approve Quote' || this.JobStatus === 'Await Cost' || this.JobStatus === 'Quote Rejected') {
      const smryData: any = await this.crudService.getJobSummary(job.jobid, job.AllocationId).toPromise();
      const jobAllocationResults = await this.JobService.getJobInfo(smryData[0]);
      const viewType = 'Quote';
      this.loading = false;
      const ref = this.dialogService.open(JobDetailsApprovalsComponent, {
        data: {
          jobId: job.jobid,
          allocId: job.AllocationId,
          jobSummary: jobAllocationResults,
          viewType,
          defaultTab: 'schedule'
        },
        width: '100%',
        height: '100%'
      })
    } else if (this.JobStatus === 'Approve Invoice' || this.JobStatus === 'Await Invoice' || this.JobStatus === 'Invoice Processed' || this.JobStatus === 'Accounts Proecessed' || this.JobStatus === 'Job Completed') {
      const smryData: any = await this.crudService.getJobSummary(job.jobid, job.AllocationId).toPromise();
      const jobAllocationResults = await this.JobService.getJobInfo(smryData[0]);
      const viewType = 'Approval';
      this.loading = false;
      const ref = this.dialogService.open(JobDetailsApprovalsComponent, {
        data: {
          jobId: job.jobid,
          allocId: job.AllocationId,
          jobSummary: jobAllocationResults,
          viewType,
          defaultTab: 'schedule'
        },
        width: '100%',
        height: '100%'
      })
    } else if (this.JobStatus === 'Await Acceptance' || this.JobStatus === 'Send Offer') {
      this.loading = false;
      this.editSelectedRecord(job);
    } else if (this.JobStatus === 'Quote Rejected' || this.JobStatus === 'Send Offer') {
      this.loading = false;
      this.editSelectedRecord(job);
    }
  }

  editSelectedRecord(job) {
    const ref = this.dialogService.open(JobAdminComponent, {
      data: {
        jobId: job.jobid,
      },
      width: '100%',
      height: '100%'
    })
  }

  viewJob(job) {
    this.router.navigate(['/job/details', job.jobid, job.AllocationId])
  }
  resetFilters() {
    this.allocationFilterTypes = [
      { id: 1, title: 'Insurance', selected: false, class: '' },
      { id: 2, title: 'SES', selected: false, class: '' },
      { id: 6, title: 'DHA', selected: false, class: '' },
      { id: 3, title: 'Facility Management', selected: false, class: '' },
      { id: 4, title: 'New Zealand', selected: false, class: '' },
      { id: 5, title: 'Enviro', selected: false, class: '' },
    ];
    this.selectedItem = [];
    this.loadJobsTiles();
  }
  addFilters() {
    const arrayjson = [
      { id: 1, title: 'Insurance', selected: false, class: '' },
      { id: 2, title: 'SES', selected: false, class: '' },
      { id: 6, title: 'DHA', selected: false, class: '' },
      { id: 3, title: 'Facility Management', selected: false, class: '' },
      { id: 4, title: 'New Zealand', selected: false, class: '' },
      { id: 5, title: 'Enviro', selected: false, class: '' },
    ]
    this.allocationFilterTypes = arrayjson; 
    this.selectedItem.length = 6;
   
  }

  
  custCareExpList_324: any;
  is324Exempt: any = false;
  originalJobList: any[] = [];

  async searchNow() {
    this.jobList = [];
    this.loading = true;
    let finalQuery = null;
    const initialWhereConditionVal = await this.initialWhereCondition();
    const clientQuery = this.constructClientQuery();
    const statusQuery = this.constructJobStatusQuery();
    let whereAvail = false;
    finalQuery = initialWhereConditionVal;
    whereAvail = true;

    if (clientQuery === 'ALL') {
    } else {
      if (whereAvail) {
        finalQuery = finalQuery + ' and ' + clientQuery;
      } else {
        if (finalQuery === null) {
          finalQuery = ' where ' + clientQuery;
        } else {
          finalQuery = finalQuery + ' where ' + clientQuery;
        }

        whereAvail = true;
      }
    }
    if (statusQuery === 'ALL') {
    } else {
      if (whereAvail) {
        finalQuery = finalQuery + ' and ' + statusQuery;
      } else {
        if (finalQuery === null) {
          finalQuery = ' where ' + clientQuery;
        } else {
          finalQuery = finalQuery + ' where ' + statusQuery;
        }
        whereAvail = true;
      }
    }
    let appendSearchString = null;
    const respobj = { status: null, client: null, query: null };
    respobj.query = finalQuery;
    let data: any = await this.crudService.ListingPageOperationsActiveDashboard(respobj).toPromise();
    this.jobList = data;
   // console.log('this.jobList tt',this.jobList )
    await this.loadTaskInfo();
    this.jobList = this.jobList.sort((b, a) => a.JobId - b.JobId);
    this.loadJobsTiles();
    this.loading = false;
  }

  loadJobsTiles() {
    let listOfJobs: any[] = [];
    if (this.selectedItem.length > 0) {
      const clients: any[] = [];
      this.selectedItem.forEach(element => {
        const clientJobsList = this.jobList.filter(val => (val.WPType == element.title));
        listOfJobs = [...listOfJobs, ...clientJobsList];
      });
    } else {
      listOfJobs = this.jobList;
    }
    this.originalJobList = listOfJobs;

    this.categories.forEach(async category => {
      let jobsList = this.originalJobList.filter(val => (category.status.includes(val.JobStatus)));
      if(category.code == 'B7'){
        jobsList = jobsList.filter(val => (val.watched == true)); 
      }
     
      
      let redList = jobsList.filter(val => (val.task_count > 0));
      let amberList = jobsList.filter(val => (val.task_count < 1 && new Date() > val.dDay && val.daysToDday != null));
      let greenList = jobsList.filter(val => (val.task_count < 1 && (new Date() < val.dDay || val.daysToDday == null)));

      // var total = [0, 1, 2, 3].reduce(function(a, b){ return a + b; }); 
// console.log("total is : " + total );
console.log("jobsList is : " ,jobsList );

      let total_cost = jobsList.reduce(function (_this, val) {
        // console.log("_this",_this)
        //return _this + val.TPCost
        return _this + val.WP_Total
      }, 0);

      // let total_cost = jobsList.reduce(
      //   async (promise, wait) => {
      //     return promise.then(async last => {
      //         const ret = last + wait.WP_Total
          
      //          await new Promise((res, rej) => setTimeout(res, 500))
      //         return ret
      //     })

      // }, 0)
      // let total_cost = await jobsList.reduce(async (result, el) => {
      //   console.log("el",el);
      //   console.log("result",result);
        
      //   return result + el.WP_Total
      //   // const asyncResult = await someAsyncTask(el);
      //   // if (asyncResult) {
      //   //   (await result).push(newSavedFile);
      //   // }
      //   return result;
      // }, 0);

      category.total = total_cost;
      category.count = jobsList.length;

      category.red = redList.length;
      category.amber = amberList.length;
      category.green = greenList.length;

      category.jobs = jobsList;
    });
  }



  async initialWhereCondition() {
  
    let Active = ' where  ( contractor_id  is null or contractor_id not in (276,970,830,1008) ) and JobStatus not in (\'Invoice Processed\',\'Accounts Processed\',\'Offer Rejected,Re-issued\',\'Quote Rejected\',\'Job Completed\',\'Job Cancelled\', \'Allocation Cancelled\',\'On Hold\')';
    let Invoiced = ' where ( contractor_id  is null or contractor_id not in (276,970,830,1008) ) and  JobStatus in (\'Invoice Processed\',\'Accounts Processed\')';
    let Hold = ' where ( contractor_id  is null or contractor_id not in (276,970,830,1008) ) and JobStatus in (\'On Hold\') ';
    let Completed = ' where ( contractor_id  is null or contractor_id not in (276,970,830,1008) ) and JobStatus in (\'Job Completed\') ';
    let Cancelled = ' where ( contractor_id  is null or contractor_id not in (276,970,830,1008) ) and JobStatus in (\'Job Cancelled\', \'Allocation Cancelled\',\'Offer Rejected,Re-issued\') ';
    let ALL = '  where  ( contractor_id  is null or contractor_id not in (276,970,830,1008) ) and JobStatus not in (\'Invoice Processed\',\'Accounts Processed\',\'Offer Rejected,Re-issued\',\'Quote Rejected\',\'Job Completed\',\'Job Cancelled\', \'Allocation Cancelled\',\'On Hold\')'
    let selectionQuery = ' where ';

    let initialWhereCondition = null;

    if (this.selectedOverAllStatus.id === 1) {
      initialWhereCondition = Active;
    } if (this.selectedOverAllStatus.id === 2) {
      initialWhereCondition = Invoiced;
    } if (this.selectedOverAllStatus.id === 3) {
      initialWhereCondition = Completed;
    } if (this.selectedOverAllStatus.id === 4) {
      initialWhereCondition = Cancelled;
    } if (this.selectedOverAllStatus.id === 5) {
      initialWhereCondition = Hold;
    } if (this.selectedOverAllStatus.id === 6) {
      initialWhereCondition = ALL;
    } if (this.selectedOverAllStatus.id === 7) {
      initialWhereCondition = selectionQuery;
    }

    this.custCareExpList_324 = await this.crudService.getByValuesetName('CUSTCARE_EXP_324').toPromise();
    let index = this.custCareExpList_324.findIndex(val => (val.valueset_detail_name === this.userDetails.id));
    if (index > -1) {
      this.is324Exempt = true;
    }
    if (this.is324Exempt) {
      initialWhereCondition = initialWhereCondition + ' and CompanyId not in (324,1356)';
    }

   // console.log('initialWhereCondition',initialWhereCondition)
    return initialWhereCondition;
  }

  constructClientQuery() {
    let ClientQuery = null;
    let selectedClient: any[] = [];
    selectedClient.forEach(client => {
      if (ClientQuery === null) {
        ClientQuery = ' WPType in (\'' + client.name + '\'';
      } else {
        ClientQuery = ClientQuery + ',\'' + client.name + '\'';
      }
    });
    if (ClientQuery === null) {
      ClientQuery = 'ALL';
    } else {
      ClientQuery = ClientQuery + ')';
    }
   // console.log('ClientQuery',ClientQuery);
    return ClientQuery;
   
  }

  constructJobStatusQuery() {
    let JobStatusQuery = null;
    this.selectedJobStatus.forEach(status => {
      if (JobStatusQuery === null) {
        JobStatusQuery = ' JobStatus in (\'' + status.id + '\'';
      } else {
        JobStatusQuery = JobStatusQuery + ',\'' + status.id + '\'';
      }
    })
    if (JobStatusQuery === null) {
      JobStatusQuery = 'ALL';
    } else {
      JobStatusQuery = JobStatusQuery + ')';
    }
   // console.log('JobStatusQuery',JobStatusQuery);
    return JobStatusQuery;
  }

  async loadTaskInfo() {
     await this.JobService.fetchJobTaskInfo(this.jobList, this.userDetails);
    // await this.JobService.getTimeDifference(this.jobList);
   }

  async closeJobOperationsDialog(){
    this.openJobDetails = false;
    await this.searchNow();
  }
}
