import { Component, OnInit, Input } from '@angular/core';
import { CRUDService } from 'src/app/utilities/crud.service';


@Component({
  selector: 'app-tp-job-detail-header',
  templateUrl: './tp-job-detail-header.component.html',
  styleUrls: ['./tp-job-detail-header.component.scss'],
  providers: []
})

export class TpJobDetailHeader implements OnInit {

  @Input() jobInfo: any;
  @Input() allocId: any
  showColours: Boolean = false;
  JobAllocDetails: any;
  priLabel:Boolean = true;
  jobDelShow:Boolean = true;
  jobDelHide:Boolean = false;
  constructor(
    private crudService: CRUDService){}

  jobFlags = [
    { name: 'red', code: 'firebrick' },
    { name: 'blue', code: 'darkcyan' },
    { name: 'yellow', code: 'darkgoldenrod' },
    { name: 'orange', code: 'darkorange' },
    { name: 'purple', code: 'purple' },
    { name: 'deeppink', code: 'deeppink' },
    { name: '', code: '' }];
  async ngOnInit() {
    //console.log(this.jobInfo);
    let JobAllocData: any = await this.crudService.getDataByField('jobAllocation', 'id', this.allocId).toPromise();
    this.JobAllocDetails = JobAllocData[0];
  }

  updateJobFlag(colour) {
    this.JobAllocDetails.flag_details = colour.code;
    this.crudService.updateFlag(this.allocId, colour.code).toPromise();
  }

  jobDelShowFun() {
      this.jobDelHide = true;
      this.jobDelShow = false;
      this.priLabel = true;
  }
  
  jobDetail() {
      const jobno = document.getElementById("jobno");
      const jobSt = document.getElementById("job_status");
      const recDate = document.getElementById("revDate");
      const workProv = document.getElementById("workPro");
      if (jobno.className == "labelShow" 
        && jobSt.className == "labelShow" 
        && recDate.className == "labelShow" 
        && workProv.className == "labelShow" 
      ) {
        jobno.className = "priLabel";
        jobSt.className = "priLabel";
        recDate.className = "priLabel";
        workProv.className = "priLabel";

      } else {
        jobno.className = "labelShow";
        jobSt.className = "labelShow";
        recDate.className = "labelShow";
        workProv.className = "labelShow";
      }
  }

  jobDel() {
      const jobNo = document.getElementById("jobNo");
      const jobStatus = document.getElementById("jobSt");
      const joblocation = document.getElementById("jobloc");
      const jobContact = document.getElementById("jobContact");
      if (jobNo.className == "labelShow" && jobStatus.className == "labelShow" 
      && joblocation.className == "labelShow" 
      && jobContact.className == "labelShow"  
      ) {

        jobNo.className = "priLabel";
        jobStatus.className = "priLabel";
        joblocation.className = "priLabel";
        jobContact.className = "priLabel";
      } else {
        jobNo.className = "labelShow";
        jobStatus.className = "labelShow";
        joblocation.className = "labelShow";
        jobContact.className = "labelShow";
      }
  }
}