import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { CRUDService } from 'src/app/utilities/crud.service';
import { Router, ActivatedRoute } from '@angular/router';
import {  MessageService } from 'primeng/api';

@Component({
  selector: 'app-reset-password',
  templateUrl: './reset-password.component.html',
  styleUrls: ['./reset-password.component.scss'],
  providers: [MessageService]
})

export class ResetPasswordComponent implements OnInit {
  ResponseResetForm: FormGroup;
  errorMessage: string;
  successMessage: string;
  resetToken: null;
  CurrentState: any;
  IsResetFormValid = true;
  emailId: any;

  constructor(
    private messageService: MessageService,
    private crudService: CRUDService,
    private router: Router,
    private route: ActivatedRoute,
    private fb: FormBuilder ) {

    this.CurrentState = 'Wait';
    this.route.params.subscribe(params => {
      this.resetToken = params.token;
      //console.log(this.resetToken);
      this.VerifyToken();
    });
  }


  ngOnInit() {

    this.Init();
  }

  VerifyToken() {
    this.crudService.getDataByField('Users', 'auth_token', this.resetToken).subscribe((data: any[]) => {
      if(data.length>0){
        this.emailId = data[0].email;
        this.CurrentState = 'Verified';
      }else{
        this.CurrentState = 'NotVerified';
      }
    },
      err => {
        this.CurrentState = 'NotVerified';
      }
    );
  }

  Init() {
    //console.log('reset-password')
    this.ResponseResetForm = this.fb.group(
      {
        resettoken: [this.resetToken],
        newPassword: ['', [Validators.required, Validators.minLength(4)]],
        confirmPassword: ['', [Validators.required, Validators.minLength(4)]]
      }
    );
  }

  Validate(passwordFormGroup: FormGroup) {
    const new_password = passwordFormGroup.controls.newPassword.value;
    const confirm_password = passwordFormGroup.controls.confirmPassword.value;

    if (confirm_password.length <= 0) {
      return null;
    }

    if (confirm_password !== new_password) {
      return {
        doesNotMatch: true
      };
    }

    return null;
  }


  ResetPassword(form) {
    //console.log(form.get('confirmPassword'));
    if (form.valid) {
      this.IsResetFormValid = true;
      this.crudService.newPassword(this.ResponseResetForm.value.newPassword, this.emailId).subscribe((data: any) => {
          this.ResponseResetForm.reset();
          this.successMessage = data.message;
          this.messageService.add({ severity: 'success', summary: 'Successful', detail: 'Password Changed Successfully', life: 1000 });
          setTimeout(() => {
            this.successMessage = null;
            this.router.navigate(['login']);
          }, 3000);
        },
        err => {
          if (err.error.message) {
            this.errorMessage = err.error.message;
          }
        }
      );
    } else { this.IsResetFormValid = false; }
  }
}