import { Component, OnInit, Input } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { CRUDService } from 'src/app/utilities/crud.service';
import { ConfirmationService } from 'primeng/api';
import { MessageService } from 'primeng/api';
import { Table } from 'primeng/table';
import { CommonService } from 'src/app/utilities/common.service';
import { environment } from 'src/environments/environment';
import { DynamicDialogConfig, DialogService,DynamicDialogRef } from 'primeng/dynamicdialog';
import { ActivatedRoute, Router, NavigationEnd } from '@angular/router';
import { MenuItem } from 'primeng/api';
import { WorkflowChildComponent } from '../../job/workflow-child/workflow-child.component'
import { async } from '@angular/core/testing';
import { DatePipe } from '@angular/common';

@Component({
  selector: 'app-master',
  templateUrl: './master.component.html',
  styleUrls: ['./master.component.scss'],
  providers: [DatePipe]
})

export class AppMasterComponent implements OnInit {
  loading: boolean = false;
  paginationvalue: any;
  cols: any[]=[];
  exportColumns: any[];
  appConfigDialog: boolean = false;
  submitted: boolean;
  appconfigList: any[];
  jobPriority: any;
  appConfig: any;
  selectedJobPriority: any[];
  selectedAppConfig: any[];
  companyList: any[];
  isActive: boolean;
  selectedConfig: any;
  speedDial: boolean = false;
  isEditAll: boolean = false;
  exportdisplay: Boolean = false;
  detailData: any;
  public inputFields: any[] = [];
  public inputFieldsarray: any[] = [];
  public inputFieldsTemp: any[] = [];
  @Input() moduleName: any;
  @Input() ChildCode: any; //For Child View	
  @Input() mapId: any;
  @Input() parentCode: any;
  @Input() fieldsId: any;
  @Input() loadChildData: any;

  parentKey: any;
  childKey: any;
  parentkeyValue: any;
  childkeyValue: any;
  inputValue: any;
  showDialog:Boolean=false;
  selectedItems:any;

  workFlowDialog:Boolean; // WorkFlowDesign
  workFlowCode:any;

  viewType: any; // Master Or Child

  @Input() setupData: any;
  appconfigid: string;
  fieldId: any;
  edit: boolean;
  submitFlag: Boolean = false;
  inputtt: boolean = true;
  createMode: any;
  inlineAdd:Boolean=false;
  childData: any[];
  showChild: boolean;
  pageTitle: any;
  items: MenuItem[];
  fieldId1: any;
  childdata1: any;
  getchilddata1: any;
  editmasterfields: boolean = true;
  childeditDialog: any;
  formOptions: any;
  closeVideo: boolean = true;
  selectItem: any;
  navigationSubscription;
  endPointData:any;
  mapType:any;
  userDetails: any;
  selectedmenu: any;
  isExport: boolean=false;
  isDelete: boolean=false;
  constructor(private crudService: CRUDService,private datePipe: DatePipe,
    private messageService: MessageService,
    private confirmationService: ConfirmationService,
    private commonservice: CommonService,
    private dialogService: DialogService,
    private dialogRef: DynamicDialogRef, 
    public config: DynamicDialogConfig,
    private activatedroute: ActivatedRoute,
    private router: Router,) {
    this.navigationSubscription = this.router.events.subscribe((e: any) => {
      if (e instanceof NavigationEnd) {
        this.ngOnInit();
      }
    });
    this.form = new FormGroup({
      fields: new FormControl(JSON.stringify(this.inputFields))
    })
    this.unsubcribe = this.form.valueChanges.subscribe((update) => {
      console.log(update);
      this.inputFields = JSON.parse(update.fields);
    });
  }
  ngOnChanges() {
    this.ngOnInit();
  }
  ngOnDestroy() {
    if (this.navigationSubscription) {
      this.navigationSubscription.unsubscribe();
    }
  }
  async ngOnInit(): Promise<void> {

    this.childeditDialog = false;
    this.workFlowDialog = false;
    this.childeditDialog = false;

    if (this.config && this.config.data) {
      this.ChildCode = this.config.data.child_code;
      this.mapId = this.config.data.map_id;
      this.parentCode = this.config.data.parent_code;
      this.fieldsId = this.config.data.fieldId;
      this.moduleName = this.config.data.child_code;
      this.viewType = 'Child';
      this.showDialog=false;
    } else if (this.loadChildData) {
      this.ChildCode = this.loadChildData.child_code;
      this.mapId = this.loadChildData.map_id;
      this.parentCode = this.loadChildData.parent_code;
      this.fieldsId = this.loadChildData.fieldId;
      this.moduleName = this.loadChildData.child_code;
      this.viewType = 'Child';
      this.showDialog=false;
    } else {
      let menuData = JSON.parse(sessionStorage.getItem('menuData'));
      this.moduleName = menuData.para_1;
      this.viewType = 'Master';
      this.showDialog=true;
    }
    this.loading = true;
    this.paginationvalue = environment.paginatorValue;
    if (this.viewType == 'Master') {
      this.loadMaster();
    }
    else if (this.viewType == 'Child') {
      this.loadChild();
    }
    this.loading = false;
    this.selectedmenu = JSON.parse(sessionStorage.getItem('menuData'));
    let per_data :any=await this.crudService.getmenupermissions()
    this.isExport = per_data.isExport;
    this.isDelete = per_data.isDelete;
  }

  async loadMaster() {
    let mdata: any = await this.crudService.getDataByField('ComponentDefnHead', 'code', this.moduleName).toPromise();;
    this.pageTitle = mdata[0].title;
    this.createMode=mdata[0].create_mode;

    this.crudService.getDataByField('ComponentDefnMapping', 'parent_code', this.moduleName).subscribe((data: any[]) => {
      this.childData = data;
      this.childData.forEach(C => { C.label = C.child_code; C.command = (event) => { this.selectedchild(C); } });
    });
    this.crudService.getDataByField('ComponentDefnDetail', 'component_code', this.moduleName).subscribe((data: any[]) => {
      
      this.detailData = data.sort((a, b) => a.seq.localeCompare(b.seq));
      

      this.detailData.forEach(w => {
        this.cols.push(w.field_name);
        this.inputFields.push({
          type: w.field_type.trim(),
          name: w.field_name,
          label: w.helptext,
          value: '',
          required: w.required,
        });

        this.crudService.getAllData(this.moduleName).subscribe((data: any[]) => {
          this.appconfigList = data;
        });
       
      });
      let fieldsCtrls = {};
      for (let f of this.inputFields) {
        if (f.type != 'checkbox') {
          fieldsCtrls[f.name] = new FormControl(f.value || '', Validators.required)
        } else {
          let opts = {};
          for (let opt of f.options) {
            opts[opt.key] = new FormControl(opt.value);
          }
          fieldsCtrls[f.name] = new FormGroup(opts)
        }
      }
      this.form = new FormGroup(fieldsCtrls);
    });
  }

  async loadChild() {
    // this.dialogRef.close();
    this.crudService.getDataByField('ComponentDefnMapping', 'parent_code', this.moduleName).subscribe((data: any[]) => {
      this.childData = data;
    });

    this.crudService.getDataByField('ComponentDefnMapping', 'child_code', this.moduleName).subscribe((mapData: any[]) => {
      this.mapType = mapData[0].map_type;
    });

    let mdata: any = await this.crudService.getDataByField('ComponentDefnHead', 'code', this.moduleName).toPromise();;
    this.pageTitle = mdata[0].title;
    this.createMode=mdata[0].create_mode;

    let cdata: any = await this.crudService.getDataByField('ComponentDefnFieldMapping', 'cdm_id', this.mapId).toPromise();;
    this.parentKey = cdata[0].pkey_field_name;
    this.childKey = cdata[0].ckey_field_name;

    

    let data: any = await this.crudService.getDataByField(this.parentCode, 'id', this.fieldsId).toPromise();;
    this.parentkeyValue = data[0]['' + this.parentKey + ''];
    this.paginationvalue = environment.paginatorValue;
  
    
    this.crudService.getDataByField('ComponentDefnDetail', 'component_code', this.moduleName).subscribe((data: any[]) => {
      this.detailData = data.sort((a, b) => a.seq.localeCompare(b.seq));
      this.detailData.forEach(w => {
        this.cols.push(w.field_name);
        this.inputFields.push({
          type: w.field_type.trim(),
          name: w.field_name,
          label: w.helptext,
          value: '',
          required: w.required,
        })
      });
      let fieldsCtrls = {};
      for (let f of this.inputFields) {
        if (f.type != 'checkbox') {
          fieldsCtrls[f.name] = new FormControl(f.value || '', Validators.required)
        }
        else {
          let opts = {};
          for (let opt of f.options) {
            opts[opt.key] = new FormControl(opt.value);
          }
          fieldsCtrls[f.name] = new FormGroup(opts)
        }
      }
      this.form = new FormGroup(fieldsCtrls);
   

    this.crudService.getDataByField(this.moduleName, this.childKey, this.parentkeyValue).subscribe((data: any[]) => {
      this.appconfigList = data;
      if(this.mapType=="2")
      {
        debugger
        if(this.appconfigList.length>0)
        {
          this.editRecord(this.appconfigList[0]);
        }
        else
        {
          this.openNew();
        }
      }
    });
  });
  }
  selectedchild(data) {
    
    let ChildData = {
      child_code: data.child_code,
      map_id: data.id,
      parent_code: this.moduleName,
      fieldId: this.fieldId1
    };
    const ref = this.dialogService.open(AppMasterComponent, {
      
      data: ChildData,
      header: 'ViewChild',
      width: '100%',
      height: '100%',
    });
    ref.onClose.subscribe(async (response: any) => {
    });
  }
  viewChild2(child_code, map_id, fieldId, i) {
    this.childdata1 = {
      child_code: child_code,
      map_id: map_id,
      parent_code: this.moduleName,
      fieldId: this.fieldId
    };
    this.selectItem = i;
    // this.getchilddata1 = true
    this.editmasterfields = false;
   
  }
  getchilddata() {
    return this.childdata1;
  }

  selectchild1(fieldId) {
   
    this.fieldId1 = fieldId
    console.log('fieldId', fieldId);
  }
 

  viewWorkflow(data)
  {
    this.workFlowDialog=true;
    this.workFlowCode=data.code;
  }

  viewChild(child_code, map_id, fieldId) {
    let ChildData = {
      child_code: child_code,
      map_id: map_id,
      parent_code: this.moduleName,
      fieldId: fieldId
    };
    this.dialogRef.close();
    const ref = this.dialogService.open(AppMasterComponent, {
      data: ChildData,
      header: 'ViewChild',
      width: '100%',
      height: '100%',
    });
    ref.onClose.subscribe(async (response: any) => {

    });
  }
  async buildFields()
  {
    
  }
  async openNew() {
    debugger
    this.loading=true;
    this.edit = false
    this.inputFieldsTemp = [];
      for (const w of this.detailData) {
      if (this.viewType == 'Master') {
        this.inputValue = '';
      }
      else {
        if (w.field_name == this.childKey) {
          this.inputValue = this.parentkeyValue;
        }
        else {
          this.inputValue = '';
        }
      }

      if(w.field_type == "dropdown")
      {
        if(w.action_hook != '' && w.action_hook != null && w.action_hook != ""){
            let hookData: any = await this.crudService.getDataByField('hooks', 'code', w.action_hook).toPromise();

            let options:any[]=JSON.parse(w.options);
            let keyData = options.filter(val => val.value_field == 'Y');
            let idKey=keyData[0].field_name;

            let valueData = options.filter(val => val.display == 'Y');
            let valueKey=valueData[0].field_name;

            if(hookData[0].method_type === "CRUD")
            {
              var enpoint = hookData[0].endpoint.split('(')[0];
              var params = hookData[0].endpoint.substring(
                hookData[0].endpoint.lastIndexOf("(") + 1, 
                hookData[0].endpoint.lastIndexOf(")")
            );

            let paramInput = params.split(',');
            if(paramInput.length=="1")
            {
              this.endPointData = await this.crudService[enpoint]([params]).toPromise();
            }
            else if(paramInput.length=="2")
            {
              let p1=paramInput[0].replaceAll('"', '');
              let p2=paramInput[1].replaceAll('"', '');
              this.endPointData = await this.crudService[enpoint](p1,p2).toPromise();
            }
            else if(paramInput.length=="3")
            {
              let p1=paramInput[0].replaceAll('"', '');
              let p2=paramInput[1].replaceAll('"', '');
              let p3=paramInput[2].replaceAll('"', '');
              this.endPointData = await this.crudService[enpoint](p1,p2,p3).toPromise();
            }

              let lovOptions:any = this.endPointData.map(col => ({ code: col['' + idKey + ''], description: col['' + valueKey + ''] }));
              this.formOptions =  JSON.parse(JSON.stringify(lovOptions));
            }
            else if(hookData[0].method_type === "GET")
            {
              let Data: any = await this.crudService.lovGetMethod(hookData[0].endpoint).toPromise();
              let lovOptions:any = Data.map(col => ({ code: col['' + idKey + ''], description: col['' + valueKey + ''] }));
              this.formOptions =   JSON.parse(JSON.stringify(lovOptions));
            }
         }
         else
         {
          if (w.options?.length != '0' && w.options?.length !=null && w.options?.length != '') {
            this.formOptions = JSON.parse(w.options);
          } else {
            this.formOptions = '[]';
          }
         }
       
      }
      else
      {
        if (w.options?.length != '0' && w.options?.length !=null && w.options?.length != '') {
          this.formOptions = JSON.parse(w.options);
        } else {
          this.formOptions = '[]';
        }
      }
      
      this.inputFieldsTemp.push({
        type: w.field_type.trim(),
        name: w.field_name,
        label: w.helptext,
        value: this.inputValue,
        required: w.required,
        options: this.formOptions,
        show:true,
        display_type: w.display_type,
        component_style: w.component_style
      })
    }
   this.inputFields = this.inputFieldsTemp
   this.appConfigDialog = true;
    this.loading=false;
  }
  async editRecord(appConfig: any) {
    this.loading=true;
    this.edit = true
    this.inputFieldsTemp = [];
    
    for (const w of this.detailData) {
      if (this.viewType == 'Master') {
        this.inputValue = '';
      }
      else {
        if (w.field_name == this.childKey) {
          this.inputValue = this.parentkeyValue;
        }
        else {
          this.inputValue = '';
        }
      }

      if(w.field_type == "dropdown")
      {
        if(w.action_hook != '' && w.action_hook != null && w.action_hook != ""){
            let hookData: any = await this.crudService.getDataByField('hooks', 'code', w.action_hook).toPromise();

            let options:any[]=JSON.parse(w.options);
            let keyData = options.filter(val => val.value_field == 'Y');
            let idKey=keyData[0].field_name;

            let valueData = options.filter(val => val.display == 'Y');
            let valueKey=valueData[0].field_name;

            if(hookData[0].method_type === "CRUD")
            {
              var enpoint = hookData[0].endpoint.split('(')[0];
              var params = hookData[0].endpoint.substring(
                hookData[0].endpoint.lastIndexOf("(") + 1, 
                hookData[0].endpoint.lastIndexOf(")")
            );
            
            let paramInput = params.split(',');
            if(paramInput.length=="1")
            {
              this.endPointData = await this.crudService[enpoint]([params]).toPromise();
            }
            else if(paramInput.length=="2")
            {
              let p1=paramInput[0].replaceAll('"', '');
              let p2=paramInput[1].replaceAll('"', '');
              this.endPointData = await this.crudService[enpoint](p1,p2).toPromise();
            }
            else if(paramInput.length=="3")
            {
              let p1=paramInput[0].replaceAll('"', '');
              let p2=paramInput[1].replaceAll('"', '');
              let p3=paramInput[2].replaceAll('"', '');
              this.endPointData = await this.crudService[enpoint](p1,p2,p3).toPromise();
            }
              let lovOptions:any = this.endPointData.map(col => ({ code: col['' + idKey + ''], description: col['' + valueKey + ''] }));
              this.formOptions =  JSON.parse(JSON.stringify(lovOptions));
            }
            else if(hookData[0].method_type === "GET")
            { 
              let Data: any = await this.crudService.lovGetMethod(hookData[0].endpoint).toPromise();
              let lovOptions:any = Data.map(col => ({ code: col['' + idKey + ''], description: col['' + valueKey + ''] }));
              this.formOptions =   JSON.parse(JSON.stringify(lovOptions));
            }
         }
         else
         {
          if (w.options?.length != '0' && w.options?.length !=null && w.options?.length != '') {
            this.formOptions = JSON.parse(w.options);
          } else {
            this.formOptions = '[]';
          }
         }
       
      }
      else
      {
        if (w.options?.length != '0' && w.options?.length !=null && w.options?.length != '') {
          this.formOptions = JSON.parse(w.options);
        } else {
          this.formOptions = '[]';
        }
      }
      
      this.inputFieldsTemp.push({
        type: w.field_type.trim(),
        name: w.field_name,
        label: w.helptext,
        value: this.inputValue,
        required: w.required,
        options: this.formOptions,
        show:true,
        display_type: w.display_type,
        component_style: w.component_style
      })
    }

    for (const  [index , w] of this.childData.entries()) {
      
      this.crudService.getDataByField('ComponentDefnHead', 'code', w.child_code).subscribe((childCodeData: any[]) => {
        
        this.childData[index].module_name=childCodeData[0].title;
      });
    }
    

    this.inputFields = this.inputFieldsTemp;
   

    this.fieldId = appConfig.id;
    const data_array = Object.entries(appConfig);
    this.inputFields.forEach(w => {
      data_array.forEach(D => {
        if (w.name == D[0]) w.value = D[1];
      });
    });
    // this.appConfigDialog = true; 
    this.selectItem = -1;
    this.editmasterfields = true;
    this.childeditDialog = true;
    this.enableTabs();
    this.loading=false;
  }

  async onSubmit(evt) {
    this.loading=true;
    if (this.edit) {
      evt.id = this.fieldId;
      let response: any = await this.commonservice.saveRecord(this.moduleName, evt);
      if (response.success == true) {
        this.messageService.add({ severity: 'success', summary: 'Successful', detail: ' Updated successfully', life: 1000 });
        this.appconfigList[this.findIndexById(evt.id)] = evt;
        this.appConfigDialog = false;
      }
      else {
        this.messageService.add({ severity: 'info', summary: 'Error', detail: 'Something went wrong', life: 1000 });
      }
    }
    else {
      let response: any = await this.commonservice.saveRecord(this.moduleName, evt);
      if (response.success == true) {
        this.messageService.add({ severity: 'success', summary: 'Successful', detail: ' Created successfully', life: 1000 });
        this.appconfigList.unshift(evt);
        this.appConfigDialog = false;
      }
      else {
        this.messageService.add({ severity: 'info', summary: 'Error', detail: 'Something went wrong', life: 1000 });
      }
    }
    this.loading=false;
  }
  
  async saveData(evt) {
    this.loading=true;
    if (this.edit) {
      evt.id = this.fieldId;
      let response: any = await this.commonservice.saveRecord(this.moduleName, evt);
      if (response.success == true) {
        this.messageService.add({ severity: 'success', summary: 'Successful', detail: ' Updated successfully', life: 1000 });
        this.appconfigList[this.findIndexById(evt.id)] = evt;
        this.appConfigDialog = false;
      }
      else {
        this.messageService.add({ severity: 'info', summary: 'Error', detail: 'Something went wrong', life: 1000 });
      }
    }
    else {
      let response: any = await this.commonservice.saveRecord(this.moduleName, evt);
      if (response.success == true) {
        this.messageService.add({ severity: 'success', summary: 'Successful', detail: ' Created successfully', life: 1000 });
        this.appconfigList.push(evt);
        this.appConfigDialog = false;
        // this.ngOnInit()
      }
      else {
        this.messageService.add({ severity: 'info', summary: 'Error', detail: 'Something went wrong', life: 1000 });
      }
    }
    this.loading=false;
  }

  saveRecord1() {
    let changedRecords = this.appconfigList.filter(val => (val.changed === true));
    changedRecords.forEach(element => {
      this.commonservice.saveRecord(this.moduleName, element);
      element.changed = false;
    });
    this.isEditAll = false;
  }
  childNotifier(val) {
    this.viewChild(val.para1, val.para2, val.para3);
  }

 
  hidechild() {
    this.childeditDialog = false;
  }

  viewFields(defnHead) {
    const ref = this.dialogService.open(AppMasterComponent, {
      data: defnHead,
      header: 'ViewChild',
      width: '100%',
    });
    ref.onClose.subscribe(async (response: any) => {

    });
  }

  videoClose() {
    this.closeVideo = false;
    let close = document.getElementById('help-video');
    if (close.style.display === 'none') {
      close.style.display = 'block';
    } else {
      close.style.display = 'none';
    }
  }
  enableTabs() {
    setTimeout(() => {
      document.getElementById('help-video-trigger').addEventListener('click', function () {
        document.getElementById('help-video').style.display = 'block';
      })
      document.getElementById('vid-close')?.addEventListener('click', function () {
        document.getElementById('help-video').style.display = 'none';
      })
    }, 1000);
  }

  deleteRecord(appconfigList: any) {
    this.confirmationService.confirm({
      message: 'Are you sure you want to delete ?',
      header: 'Confirm',
      icon: 'pi pi-exclamation-triangle',
      accept: () => {
        this.crudService.deleteRecordByID(this.moduleName, appconfigList.id).subscribe((data: any[]) => { });
        this.appconfigList = this.appconfigList.filter(val => val.id !== appconfigList.id);
        this.messageService.add({ severity: 'success', summary: 'Successful', detail: 'Deleted', life: 1000 });
      }
    });
  }


  deleteSelectedJob() {
    // this.confirmationService.confirm({
    //   message: 'Are you sure you want to delete the selected Appconfig?',
    //   header: 'Confirm',
    //   icon: 'pi pi-exclamation-triangle',
    //   accept: () => {  
    //     for (let i = 0; i < this.selectedAppConfig.length; i++) {
    //       this.crudService.Delete(this.selectedAppConfig[i].id, 'AppConfig').subscribe((data: any[]) => { });
    //     }
    //     this.appconfigList = this.appconfigList.filter(val => !this.selectedAppConfig.includes(val));
    //     this.selectedAppConfig = null;
    //     this.messageService.add({ severity: 'success', summary: 'Successful', detail: 'Appconfig Deleted', life: 1000 });
    //   }
    // });
  }

  deleteJobPriority(appConfig: any) {
    // console.log("deleteJobPriority appConfig", appConfig);
    // this.confirmationService.confirm({
    //   message: 'Are you sure you want to delete Appconfig : ' + appConfig.code + '?',
    //   header: 'Confirm',
    //   icon: 'pi pi-exclamation-triangle',
    //   accept: () => {
    //     this.appconfigList = this.appconfigList.filter(val => val.id !== appConfig.id);
    //     this.appConfig = {};
    //     this.crudService.Delete(appConfig.id, 'AppConfig').subscribe((data: any[]) => {
    //       console.log("deleteresponse,", data);
    //       this.messageService.add({ severity: 'success', summary: 'Successful', detail: 'Appconfig Deleted', life: 1000 });
    //     });
    //   }
    // });
  }

  findIndexById(id: string): number {
    let index = -1;
    for (let i = 0; i < this.appconfigList.length; i++) {
      if (this.appconfigList[i].id === id) {
        index = i;
        break;
      }
    }
    return index;
  }

  hideDialog() {
    this.appConfigDialog = false;
    this.submitted = false;
    this.showChild = false;
  }

  exportPdf() {
    import('jspdf').then(jsPDF => {
      import('jspdf-autotable').then(x => {
        /* const doc = new jsPDF.default(0,0);
        doc.autoTable(this.exportColumns, this.selectedDepots);
        doc.save('products.pdf'); */
      })
    })
  }

  exportExcel(selectedItems) {
      import('xlsx').then(xlsx => {
      const worksheet = xlsx.utils.json_to_sheet(selectedItems);
      const workbook = { Sheets: { data: worksheet }, SheetNames: ['data'] };
      const excelBuffer: any = xlsx.write(workbook, { bookType: 'xlsx', type: 'array' });
      this.saveAsExcelFile(excelBuffer, this.pageTitle);
    });
  }

  saveAsExcelFile(buffer: any, fileName: string): void {
    import('file-saver').then(FileSaver => {
      const EXCEL_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
      const EXCEL_EXTENSION = '.xlsx';
      const data: Blob = new Blob([buffer], {
        type: EXCEL_TYPE
      });
      let timeStamp = this.datePipe.transform(new Date(), 'ddMMyy_HHmm');
      FileSaver.saveAs(data, fileName + '_' + timeStamp + EXCEL_EXTENSION);
    });
  }

  clear(table: Table) {
    table.clear();
  }



  public form: FormGroup;
  unsubcribe: any



  public fields: any[] = [
    {
      type: 'text',
      name: 'firstName',
      label: 'First Name',
      value: '',
      required: true,
    },
    {
      type: 'text',
      name: 'lastName',
      label: 'Last Name',
      value: '',
      required: true,
    },
    {
      type: 'text',
      name: 'email',
      label: 'Email',
      value: '',
      required: true,
    },
    {
      type: 'dropdown',
      name: 'country',
      label: 'Country',
      value: 'in',
      required: true,
      options: [
        { key: 'in', label: 'India' },
        { key: 'us', label: 'USA' }
      ]
    },
    {
      type: 'radio',
      name: 'country',
      label: 'Country',
      value: 'in',
      required: true,
      options: [
        { key: 'm', label: 'Male' },
        { key: 'f', label: 'Female' }
      ]
    },
    {
      type: 'checkbox',
      name: 'hobby',
      label: 'Hobby',
      required: true,
      options: [
        { key: 'f', label: 'Fishing' },
        { key: 'c', label: 'Cooking' }
      ]
    }
  ];



  onUpload(e) {
    console.log(e);
  }

  getFields() {
    return this.inputFields;
  }

  ngDistroy() {
    this.unsubcribe();
  }
}
function copy(obj: any): any {
  throw new Error('Function not implemented.');
}

