import { Component, OnInit } from '@angular/core';
import { ConfirmationService, MessageService } from 'primeng/api';
import { DialogService } from 'primeng/dynamicdialog';
import { CRUDService } from 'src/app/utilities/crud.service';

@Component({
  selector: 'app-all-users',
  templateUrl: './all-users.component.html',
  styleUrls: ['./all-users.component.scss'],
  providers: [DialogService, MessageService, ConfirmationService]
})
export class AllUsersComponent implements OnInit {
  user: any;
  userDialog:any=false;
  userList:any=[];
  selecteduserList:any=[];
  constructor(
    private crudService: CRUDService,
    private messageService: MessageService,
    private confirmationService: ConfirmationService,
    private dialogService: DialogService
  ) { }

  ngOnInit() {
    this.userDialog=true;
    this.user ={
   allow_create:"1",
  allow_delete: "1",
  allow_export: "1",
  allow_update: "1",
  created_at: null,
  created_by: null,
  id: null,
  last_modified_by: null,
  last_updated_at: null,
  user_id: "76",
  process_id: "108"
    }
    this.crudService.getDataByField('users','process_id','108').subscribe((res:any)=>{
      //console.log(res);
      this.userList=res;
    })

  }

  
  openNew(){
    this.userDialog=true;
  this.user ={
 allow_create:"1",
allow_delete: "1",
allow_export: "1",
allow_update: "1",
created_at: null,
created_by: null,
id: null,
last_modified_by: null,
last_updated_at: null,
user_id: "76",
process_id: "108"
  }

}
  saveRecord(){
    //console.log(this.user);
    if(this.user.id==null){
    this.crudService.Create(this.user,'user').subscribe((res:any)=>{
      //console.log(res);
    })
  }
  if(this.user.id!=null){
    this.crudService.Update(this.user,'user').subscribe((res:any)=>{
      //console.log(res);
    })
  }

  }
  deleteuser(user){
    //console.log(user);
    this.crudService.Delete(user.id,"user").subscribe((res:any)=>{
      //console.log(res);
    })
  }
  editSelecteduser(user){
    this.userDialog=true;
    this.user=user;
    //console.log(this.user);
  }
  hideDialog(){
    this.userDialog=false;
  }

}
