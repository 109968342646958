import { Component, OnInit } from '@angular/core';
import { DialogService, DynamicDialogRef } from 'primeng/dynamicdialog';
/* import Pushy from 'pushy-sdk-web'; */
import { CRUDService } from 'src/app/utilities/crud.service';
import 'babel-polyfill';
import { CommonService } from 'src/app/utilities/common.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-notification-permission',
  templateUrl: './notification-permission.component.html',
  styleUrls: ['./notification-permission.component.css']
})
export class NotificationPermissionComponent implements OnInit {
  pushSubscribersDetails: any;
  deviceToken: any;
  currentUser: any;
  constructor(private crudService: CRUDService, private commonService: CommonService, private dialogRef: DynamicDialogRef) { }

  ngOnInit() {
    this.deviceToken = ''
    const user = sessionStorage.getItem('loggedInUser');
    this.currentUser = JSON.parse(user)
    if (this.currentUser) {
      this.pushSubscribersDetails = {
        status: 1,
        user_id: this.currentUser.id,
        device_id: this.deviceToken,
        tag: 'Default',
        logged_in_yn: 'Y',
        user_consent_yn: 'Y'
      }
    }
  }
  reject(){
    const self = this;
    /* Pushy.register({ appId: '61b087a4c38574d11c59855f' }).then(async function (deviceToken: any) {
      self.pushSubscribersDetails.device_id = deviceToken;
      self.pushSubscribersDetails.user_consent_yn = 'N';
      sessionStorage.setItem('device_id', deviceToken)
      self.UpdatePushSubscribers(self.pushSubscribersDetails);
    }).catch(function (err: any) {
      console.error(err);
      self.dialogRef.close();
    }); */
  }
  confirm() {
    const self = this;
    /*
    Pushy.register({ appId: '61b087a4c38574d11c59855f' }).then(async function (deviceToken: any) {
      self.pushSubscribersDetails.device_id = deviceToken;
      self.pushSubscribersDetails.user_consent_yn = 'Y';
      sessionStorage.setItem('device_id', deviceToken)
      self.UpdatePushSubscribers(self.pushSubscribersDetails);
       const json = { to: deviceToken,
        message: 'Confirmation Message',
        title: 'Enviro - Workmanager',
        url: environment.base_url,
        image: 'https://images.unsplash.com/photo-1593642633279-1796119d5482?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=688&q=80' }
      const jsonStr = JSON.stringify(json)
      await self.crudService.sendPushNotification(json).subscribe((response) => {
        //console.log(response);
      }) 
      
    }).catch(function (err: any) {
      console.error(err);
      self.dialogRef.close();
    });
    */
  }
  async UpdatePushSubscribers(pushSubscribersDetails) {
    this.dialogRef.close();
    let response: any = await this.commonService.saveRecord('PushSubscribers', pushSubscribersDetails);
    sessionStorage.setItem('NotifcationSubscription', pushSubscribersDetails);
    //console.log(response);
  }
}
