import { Component, OnInit, Input, OnDestroy } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { ConfirmationService, MessageService } from 'primeng/api';
import { CRUDService } from 'src/app/utilities/crud.service';
import { DynamicDialogRef, DialogService, DynamicDialogConfig } from 'primeng/dynamicdialog';
import { JOBService } from 'src/app/utilities/job.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-approve-quote',
  templateUrl: './approve-quote.component.html',
  styleUrls: ['./approve-quote.component.css'],
  providers: [MessageService, ConfirmationService]
})
export class ApproveQuoteComponent implements OnInit {
  @Input() jobId: any
  @Input() jobAllocationId: any;
  @Input() jobSummary: any;
  invoice_no: any;
  amount_invoice: any;
  companyId: any;
  userDetails: any;
  adviseTP: any;
  toEmail: any;
  toSms: any;
  smryData: any;
  notifyOptions: any[];
  env : any;
  scheduleId : any;
  params: any;

  constructor(
    private route: ActivatedRoute,
    private crudService: CRUDService,
    private messageService: MessageService,
    private confirmationService: ConfirmationService,
    private router: Router,
    public ref: DynamicDialogRef,
    public config: DynamicDialogConfig,
    public dialogService: DialogService,
    public JobService: JOBService,
    public dialogRef: DynamicDialogRef,
  ) { 
    this.companyId = sessionStorage.getItem('company_id');
    this.userDetails = JSON.parse(sessionStorage.getItem('loggedInUser'));
    this.notifyOptions = [
      { value: '1', label: 'Yes' },
      { value: '2', label: 'No' }
    ];
    this.adviseTP = { value: '1', label: 'Yes' };
  }

  ngOnInit() {
    this.env = environment;
    if (typeof this.jobId === 'undefined') {
      if (typeof this.config.data == 'undefined' || typeof (this.config.data.jobId) == 'undefined') {
        if (this.jobId == null || this.jobId === '') {
          this.jobId = parseInt(this.route.snapshot.paramMap.get('jobId'));
          if (this.jobAllocationId === null || this.jobAllocationId === '') {
            this.jobAllocationId = '1';
          } else {
            this.jobAllocationId = parseInt(this.route.snapshot.paramMap.get('jobAllocationId'));
            this.jobSummary= this.crudService.getDataByField('JobInfo', 'id', this.jobId);
          }
        }
        //this.showHeader = false;
      } else {
        this.jobId = this.config.data.jobId;
        this.jobAllocationId = this.config.data.jobAllocationId;
        this.jobSummary= this.config.data.jobSummary;
        //this.showHeader = true;
      }
    }

    
    this.crudService.getDataByField('Company', 'id', this.companyId).subscribe((data: any[]) => {
      let companyDetails = data[0];
      if (companyDetails.client_type_id === '4') {
        this.adviseTP = { value: '2', label: 'No' };
      } else {
        this.adviseTP = { value: '1', label: 'Yes' };
      }
    });
  }

  approveQuote() {
    this.crudService.updateCostFormHeadStatus(this.jobId, this.jobAllocationId, 2).subscribe(async (res: any) => {
      if (res.success == '1') {
        this.messageService.add({ severity: 'success', summary: 'Successful', detail: ' Approve Quote', life: 1000 });
        if (this.adviseTP.value === '1') {
          this.toEmail = this.jobSummary.info.TPEmail;//'venkat@agileitexperts.com';
          this.toSms = this.jobSummary.info.TPMobile;//'0404682215';//this.jobSummary.info.TPMobile;
          const params = {
            email: {
              to: this.toEmail, cc: null, bcc: null,
              msg: 'This is a mail to notify that the quote for Job Number: ' + this.jobSummary.JobNo + ' has been approved. Please schedule with client and update in Tree Manager. \nThe details are as follows: \nSite address: ' + this.jobSummary.location + ' \nClient Contact: ' + this.jobSummary.client + '. \nRegards,  \nEnviro Trees Team ',
              message: 'This is a mail to notify that the quote for Job Number: ' + this.jobSummary.JobNo + ' has been approved. Please schedule with client and update in Tree Manager. \nThe details are as follows: \nSite address: ' + this.jobSummary.location + ' \nClient Contact: ' + this.jobSummary.client + '. \nRegards,  \nEnviro Trees Team ',
              subject: 'Tree Manager: Quote Approved for Job No ' + this.jobSummary.JobNo
            },
            sms: { to: this.toSms, message: 'This is a message to notify that the quote for Job Number: ' + this.jobSummary.JobNo + ' has been approved. Please schedule with client and update in Tree Manager. \nThe details are as follows: \nSite address: ' + this.jobSummary.location + ' \nClient Contact: ' + this.jobSummary.client + '. \nRegards,  \nEnviro Trees Team ' }, //0404682215 //0415404436
            type: 'sms-email',//sms //email//sms-email
            selectedTpl: null,
            sufix: null
          }
          this.params = params;
          await this.storeInSchedule(this.jobSummary); 

        } else {
          this.toEmail = '';
          this.toSms = '';
        }
        this.dialogRef.close();
      }
    })
  }


  storeInSchedule(jobAllocationResults) {
        let message = '';
        let sms = '';
        let email = '';
        let contactUI = '';
        if (jobAllocationResults.info.TPEmail === '' && jobAllocationResults.info.TPMobile === '') {
            message = 'Quote approved. Notification not sent to the TP';
            sms = '';
            email = '';
            if (sms === '1' && email === '1') {
                contactUI = 'N/A';
            } else {
                contactUI = '';
            }
        } else {
            message = 'Quote approval notification has been sent to the TP (Email/SMS)';
            sms = '2';
            email = '2';
        }
        let store_data = {
            id: null,
            job_id: this.jobId,
            job_alloc_id: this.jobAllocationId,
            process_id: 1,
            visit_type: null,
            status: null,
            sched_date: null,
            PMOnly: '2',
            sched_note: message,
            start_time: null,
            end_time: null,
            created_by: this.userDetails.id,
            created_at: new Date(),
            phoned: '1',
            phone_no: null,
            sms: sms,
            sms_no: jobAllocationResults.info.TPMobile,
            email: email,
            emailaddress: jobAllocationResults.info.TPEmail,
            callback: '1',
            contactUI: contactUI
        }
        this.crudService.Create(store_data, 'JobSchedule').subscribe((data: any) => {
          this.scheduleId = data.id;

          if(this.env.emailRoutes.common == 'sendgrid')
          {
            let to = this.toEmail;
            let subject = 'Enviro Trees : Quote Approved for Job No ' + this.jobSummary.JobNo;
            let message = 'This is a mail to notify that the quote for Job Number: ' + this.jobSummary.JobNo + ' has been approved. Please schedule with client and update in Enviro Trees. \nThe details are as follows: \nSite address: ' + this.jobSummary.location + ' \nClient Contact: ' + this.jobSummary.client;
            
             
             
              const datobj = {
              subject : subject,
              body : message,
              toEmail : to,
              ref_id : this.scheduleId,
              ref_type : 'JobSchedule'
              }

              this.crudService.sendmailSendgrid(datobj,'commonMail').subscribe((res2: any[]) => {
              
              });
              this.crudService.sendTMV2Message(this.params).subscribe((res2: any[]) => {
              });
          }
          else if(this.env.emailRoutes.common == 'nodemailer')
          {
            this.crudService.sendTMV2Message(this.params).subscribe((res2: any[]) => {
   
            });
          }
        });
    }
}
