import { Component, Input, OnInit } from '@angular/core';
import { ConfirmationService, MessageService } from 'primeng/api';
import { CommonService } from 'src/app/utilities/common.service';
import { CRUDService } from 'src/app/utilities/crud.service';
// import{Fielddata} from '../formbuilder.component';
@Component({
  selector: 'field-builder',
  template: `
  <div class="form-group row" [formGroup]="form">
  <div class="col-md-12" [ngSwitch]="field.type" >
  {{field.label}}
  <strong class="text-danger" *ngIf="field.required">*</strong>
    <text *ngSwitchCase="'text'" [field]="field" [form]="form" ></text>
    <number *ngSwitchCase="'number'" [field]="field" [form]="form" ></number>
    <phonenumber *ngSwitchCase="'phonenumber'" [field]="field" [form]="form" ></phonenumber>
    <textbox *ngSwitchCase="'textbox'" [field]="field" [form]="form"  ></textbox>
    <dropdown *ngSwitchCase="'dropdown'" [field]="field" [form]="form" ></dropdown>
    <checkbox *ngSwitchCase="'checkbox'" [field]="field" [form]="form"></checkbox>
    <email *ngSwitchCase="'email'" [field]="field" [form]="form"></email>
    <radio *ngSwitchCase="'radio'" [field]="field" [form]="form"></radio> 
    <address *ngSwitchCase="'address'" [field]="field" [form]="form"  ></address>
    <url *ngSwitchCase="'url'" [field]="field" [form]="form"  ></url>
    <date *ngSwitchCase="'date'" [field]="field" [form]="form"></date>
    <datetime *ngSwitchCase="'datetime'" [field]="field" [form]="form"></datetime>
  </div>
</div>
  `
})
export class FieldBuilderComponent implements OnInit {
  @Input() field: any;
  @Input() form: any;
  appconfigList: any;
  appConfigDialog: boolean;

  get isValid() { return this.form.controls[this.field.name]?.valid; }
  get isDirty() { return this.form.controls[this.field.name]?.valid; }

  constructor() { }

  ngOnInit() {
// type="datetime-local"
    // <text *ngSwitchCase="'text'" [field]="field" [form]="form" ></text>
    // <div class="alert alert-danger my-1 p-2 fadeInDown animated" *ngIf="!isValid && isDirty">{{field.label}} is required</div>

  }


}
