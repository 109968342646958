import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { FormGroup, FormControl, Validators, FormBuilder } from '@angular/forms';
import { IListBoxItem, IItemsMovedEvent } from '../workflow-child/dual-list-box';
import { moveItemInArray, CdkDragDrop, transferArrayItem, } from '@angular/cdk/drag-drop';
import { CRUDService } from 'src/app/utilities/crud.service';
import { CommonService } from 'src/app/utilities/common.service';
import { MessageService } from 'primeng/api';
import { DynamicDialogRef, DialogService, DynamicDialogConfig } from 'primeng/dynamicdialog';
import { WorkflowParentComponent } from '../workflow-parent/workflow-parent.component'
import * as moment from 'moment';

@Component({
  selector: 'app-workflow',
  templateUrl: './workflow.component.html',
  styleUrls: ['./workflow.component.scss']
})
export class WorkflowComponent implements OnInit {


  displayhookSuccess: Boolean;
  displayhookFailure: Boolean;
  displayGetDetail: Boolean
  selectedId: any;
  wfId: any;
  hookdata: any;
  loading:boolean = false;
  selectedhookValue: any;
  @Input() selects: any;
  hookfieldtype: boolean = false;
  hooksoptions = [
    { id: '1', name: 'Success' },
    { id: '2', name: 'Fail' },
  ];
  skipYn: Boolean = false;
  stepInput: any;
  selectedhook: any;
  choosenItem: any;
  showDetails: Boolean;
  wfDefnData: any;
  selectedWfDefn: any;
  availableHooks: any;
  wfStepsInput: any[] = [];
  hookItems: any;
  wfCode: any;
  @Input() inputTime: any;
  @Input() JobId: any;
  @Input() JobAllocIid: any;
  @Input() sourceType:any

  JobAllocIidTemp : any;
  valueField = 'id';
  textField = 'code';
  hookField = 'hooks';
  hooktype = 'type'
  availableText = 'Available items';
  selectedText = 'Selected items';
  jobData : any;
  jobCreatedAt;
  workfId : any;



  @Output() itemsMoved: EventEmitter<IItemsMovedEvent> = new EventEmitter<IItemsMovedEvent>();

  //availableItems: Array<IListBoxItem> = [];
  selectedItems: Array<IListBoxItem> = [];
  hooksItems: any = [];
  listBoxForm: FormGroup;
  availableItemsTemp: any[];
  selectedwfDefnData: any;
  availableItems: any;
  showList: Boolean = true;
  jobWorkflow: any;
  jobWorkflowSteps: any;
  jobWorkflowTriggers: any;
  initialItems: any = [];
  stepsItems: any;
  stepsData: any;
  jobWorkflowStatus:any;
  jobWorkflowEditStatus:Boolean = false;
  editData: any;


  constructor(public fb: FormBuilder,
    private crudService: CRUDService,
    private messageService: MessageService,
    private commonservice: CommonService,
    private dialogService: DialogService,
    public config: DynamicDialogConfig,) {

      this.jobCreatedAt = moment();

    this.listBoxForm = this.fb.group({
      
      availableSearchInput: [''],
      selectedSearchInput: [''],
    });
  }

  ngOnInit() {
    this.loading = true;
    if(this.sourceType=="channel")
    {
      this.showList = false;
      this.loadData();
    }
    else
    {
      this.showList = true;
    }
    
    this.crudService.getAllData('WFSteps').subscribe((data: any[]) => {
      this.stepsItems = data;
    });
    this.crudService.getAllData('hooks').subscribe((hdata: any[]) => {
      this.hooksItems = hdata;
    });
    this.crudService.getAllData('wfdefn').subscribe((wfdefndata: any[]) => {
      this.wfDefnData = wfdefndata;
    });
    this.crudService.getDataByField('JobInfo', 'id', this.JobId).subscribe((jData: any[]) => {
      this.jobCreatedAt = jData[0].created_at;
    });
    sessionStorage.setItem('availableItems', '');
    if(!this.JobAllocIid){ this.JobAllocIid = 1; }
    debugger
    this.crudService.getDataByField('JobWorkflow', 'job_id', this.JobId).subscribe((jobWorkflowData: any) => {
     this.jobWorkflow = jobWorkflowData.filter(val => (val.job_alloc_id == this.JobAllocIid));
     this.workfId = this.jobWorkflow[0].id;
     let seldData = this.wfDefnData.filter(val => (val.code == this.jobWorkflow[0].wf_code));
      this.selectedwfDefnData = seldData[0];
    });
    this.loadData();
    this.loading = false;
  }

 

  showHookCode(hook) {
    let outValue = '';
    for (let j = 0; j < this.hooksItems?.length; j++) {
      if (hook === this.hooksItems[j].code) {
        outValue = this.hooksItems[j].description;
      }
    }
    return outValue;
  }

  showStepCode(step) {
    let outValue = '';
    for (let j = 0; j < this.stepsItems?.length; j++) {
      if (step === this.stepsItems[j].code) {
        outValue = this.stepsItems[j].name;
      }
    }
    return outValue;
  }

  selectDefn() {
    this.availableItemsTemp = [];
    this.availableItems = [];
    this.initialItems = [];
    this.loading = true;
    console.log("availableItemsTemp",this.availableItemsTemp);
    console.log("availableItems",this.availableItems);
    this.wfCode = this.selectedwfDefnData.code;
    sessionStorage.setItem('availableItems', '');
    this.crudService.getDataByField('WFDefnSteps', 'wf_code', this.wfCode).subscribe((data: any[]) => {
      let listData = data.sort((a, b) => a.seq.localeCompare(b.seq));
      listData.forEach(async (w, index) => {
      console.log("listData",listData);
      this.jobCreatedAt = moment(this.jobCreatedAt).add(w.tat, 'hour').format('YYYY-MM-DD HH:mm:ss.SSS');
        Object.assign(listData[index], { name: w.description, exp_time : this.jobCreatedAt });
        this.selectedItems = this.selectedItems.filter(val => (val.code != w.code));
      });
       // dateds = moment(dateds.toISOString());
      
      this.availableItemsTemp = listData;
      console.log("availableItemsTemp",this.availableItemsTemp);
      this.crudService.getDataByField('WFDefnTriggers', 'wf_code', this.wfCode).subscribe((hookData: any[]) => {
        this.availableHooks = hookData;
        this.availableItemsTemp.forEach(async (w, i1) => {
          w.hooks = [];
          this.availableHooks.forEach(async (h, i2) => {
            Object.assign(this.availableHooks[i2], { type: h.check_point });
            if (h.step == w.code) {
              this.availableItemsTemp[i1].hooks.push(h);
              const FailList = this.availableItemsTemp[i1].hooks.filter(val => (val.type == 'Fail'));
              const successList = this.availableItemsTemp[i1].hooks.filter(val => (val.type == 'Success'));
              Object.assign(this.availableItemsTemp[i1], { fail_length: FailList.length, success_length: successList.length });
            }
          });
        });
        //this.availableItems=this.availableItemsTemp;
        sessionStorage.setItem('availableItems', JSON.stringify(this.availableItemsTemp));
        this.availableItems = this.availableItemsTemp;
        this.loading = false;
        // this.loadData();
      });
      this.showList = true;
    });



  }

  loadData() {
    this.loading = true;
    this.crudService.getDataByField('JobWorkflow', 'job_id', this.JobId).subscribe((jobWorkflowData: any) => {
     this.jobWorkflow = jobWorkflowData.filter(val => (val.job_alloc_id == this.JobAllocIid));
      let wf_id = this.jobWorkflow[0].id;
      this.jobWorkflowStatus=this.jobWorkflow[0].status;
      this.crudService.getDataByField('JobWorkflowSteps', 'wf_id', wf_id).subscribe((jobWorkflowStepsData: any[]) => {

        this.jobWorkflowSteps = jobWorkflowStepsData.sort((a, b) => a.seq.localeCompare(b.seq));
    
        this.jobWorkflowSteps.forEach(async (ws, wsIndex) => {

          this.crudService.getDataByField('JobWorkflowTriggers', 'ws_id', ws.id).subscribe((jobWorkflowTriggersData: any[]) => {
            this.jobWorkflowTriggers = jobWorkflowTriggersData.sort((a, b) => a.seq.localeCompare(b.seq));

            let stepName = this.showStepCode(this.jobWorkflowSteps[wsIndex].step);

            this.stepsData =
            {
              code: ws.step,
              name: stepName,
              seq : ws.seq,
              exp_time: ws.exp_time,
              hooks: []
            }
            this.jobWorkflowTriggers.forEach(async (hk, hkIndex) => {
              let HookName = this.showHookCode(this.jobWorkflowTriggers[hkIndex].hook);
              let hooksData = {
                hook: hk.hook,
                description: HookName,
                type: hk.check_point
              }
              this.stepsData.hooks.push(hooksData);
            });
            const FailList = this.stepsData.hooks.filter(val => (val.type == 'Fail'));
            const successList = this.stepsData.hooks.filter(val => (val.type == 'Success'));
            Object.assign(this.stepsData, { fail_length: FailList.length, success_length: successList.length });
            this.initialItems.push(this.stepsData);
            if (this.initialItems.length > 0) {
              this.showList = false;
              this.availableItems = this.initialItems.sort((a, b) => a.seq.localeCompare(b.seq));
              this.jobWorkflowEditStatus = true;
            }
            });
            
        });

      });
    });
    this.loading =  false;
  }


  async changeJobWorkflowStatus(status)
  {
    this.jobWorkflow[0].status=status;
    let response1: any = await this.commonservice.saveRecord('JobWorkflow', this.jobWorkflow[0]);
    this.crudService.getDataByField('JobWorkflow', 'id', this.jobWorkflow[0].id).subscribe((jobWorkflowData: any[]) => {
      this.jobWorkflow = jobWorkflowData;
      this.jobWorkflowStatus=this.jobWorkflow[0].status;
      console.log("Updated JWF",this.jobWorkflowStatus);
    });
  }

  displayEat(date ,tat) {
    //let Eat = this.inputTime.setHours( this.inputTime.getHours() + tat );
    //let Eat = this.inputTime.setHours(this.inputTime.getHours());
    // var Eat = new Date();
    var startdate = moment(date);
    return moment(startdate).add(tat, 'hours');
  }

  editSteps() {
    if(this.jobWorkflowEditStatus)
    {
      this.editData = [
        {
          'jobWfid' : this.jobWorkflow[0].id,
          'editType' : '2'
        }
      ];
    }
    else
    {
      this.editData = [
        {
          'wfCode' : this.wfCode,
          'editType' : '1'
        }
      ];
    }
    const ref = this.dialogService.open(WorkflowParentComponent, {
      data: this.editData,
      width: '100%',
      height: '100%'
    });
    ref.onClose.subscribe(async (response: any) => {
      this.availableItems = response;
      if(this.availableItems){
        this.availableItems.forEach(async (w, index) => {
          console.log("listData",this.jobCreatedAt);
          this.jobCreatedAt = moment(this.jobCreatedAt).add(w.tat, 'hour').format('YYYY-MM-DD HH:mm:ss.SSS');
            Object.assign(this.availableItems[index], { name: w.name, exp_time : this.jobCreatedAt });
            this.selectedItems = this.selectedItems.filter(val => (val.code != w.code));
          });
      }
      console.log("Final Lst",this.availableItems);
    });
  }
async deleteWf()
{
  this.crudService.getDataByField('JobWorkflowSteps','wf_id',this.workfId).subscribe((sdata: any[]) => {
    for (const [index,steps] of sdata.entries()) {
      console.log("this.workfId",steps.id);
      this.crudService.deleteRecordByID('JobWorkflowSteps',steps.id).subscribe((dsdata: any[]) => {
      });
    }
  });
  this.crudService.getDataByField('JobWorkflowTriggers','wf_id',this.workfId).subscribe((tdata: any[]) => {
    for (const [index,trig] of tdata.entries()) {
      console.log("this.workfId",trig.id);
      this.crudService.deleteRecordByID('JobWorkflowTriggers',trig.id).subscribe((dsdata: any[]) => {
      });
    }
  });
  this.loading = false;
}
  async saveSteps() {
    if(this.JobAllocIid) { this.JobAllocIidTemp = this.JobAllocIid; } else { this.JobAllocIidTemp = 1; }
    let JobWorkflowData = {
      job_id: this.JobId,
      job_alloc_id: this.JobAllocIidTemp,
      start_datetime: this.inputTime,
      wf_code: this.wfCode
    }
    this.loading = true;
    this.crudService.deleteRecordByID('JobWorkflow',this.workfId).subscribe((data: any[]) => {
    });
    // await this.deleteWf();
    let response1: any = await this.commonservice.saveRecord('JobWorkflow', JobWorkflowData);
    let wf_id = response1.data[0].id;
    console.log("Response1", response1);
      for (const [index,steps] of this.availableItems.entries()) {
      let JobWorkflowStepsData = {};
      JobWorkflowStepsData = {
        // id: steps.id,
        step: steps.code,
        seq: index+1,
        wf_id: wf_id,
        exp_time: steps.exp_time,
        // act_time: this.inputTime,
        tat:steps.tat,
        reminder_interval:steps.reminder_interval,
        tat_from:steps.tat_from,
        reminder_hook:steps.reminder_hook
      }
      let response2: any = await this.commonservice.saveRecord('JobWorkflowSteps', JobWorkflowStepsData);
      let wfs_id = response2.data[0].id;

      for (const  [hindex,x] of steps.hooks.entries()) {
        let JobWorkflowTriggersData = {}
        JobWorkflowTriggersData = {
          // id: x.id,
          wf_id: wf_id,
          ws_id: wfs_id,
          seq: hindex+1,
          hook: x.hook,
          check_point: x.check_point
        }
        let response3: any = await this.commonservice.saveRecord('JobWorkflowTriggers', JobWorkflowTriggersData);
      }
      }
    this.showList = false;
    this.loading = false;
    this.availableItemsTemp = [];
    this.availableItems = [];
    this.initialItems = [];
    this.loadData();

  }

  drop(event) { }
  openhooks_failure(item) { }
  openSteps(item) { }
  openhooks_success(item) { }


}