import { Component, OnInit, Input } from '@angular/core';
import { RadioButton } from 'primeng/radiobutton';
import { Company, BusinessDetails, Staffs, UserSetting } from './companies';
import { CRUDService } from 'src/app/utilities/crud.service';
import { ConfirmationService, MessageService } from 'primeng/api';
import { MenuItem } from 'primeng/api';
import { DomSanitizer } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { FileUploader } from 'ng2-file-upload';
import { EncrDecrService } from 'src/app/utilities/encr-decr.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-registration',
  templateUrl: './registration.component.html',
  styleUrls: ['./registration.component.scss'],
  providers: [MessageService, ConfirmationService]
})
export class RegistrationComponent implements OnInit {
  @Input() view;
  user: any;
  activeTab: any;
  companyToken: any;
  logoFile: any;
  staffs: any[];
  selectedStaff: any
  staffId: any;
  company: any;
  staffComplianceList: any[];
  contractorList: any[];
  companyId: any;
  companyComplianceList: any[];
  index: any;
  submitted: any;
  settingSubmitted: any;
  userSettingInfo: any;
  selectedCountry: any;
  selectedState: any;
  countryList: any[];
  stateList: any[];
  safetyCompliance: any[];
  isDepot: boolean = false;
  imageBlobUrl: any;
  selectedCompany: any;
  uploader: FileUploader;
  msg: any;
  url: any;

  options = {
    componentRestrictions: {
      country: ['AU']
    }
  }
  componentForm = {
    street_number: 'short_name',
    route: 'long_name',
    locality: 'long_name',
    sublocality_level_1: 'long_name',
    administrative_area_level_1: 'short_name',
    country: 'long_name',
    postal_code: 'short_name'
  };
  caption =
  {
    business_ABN: environment.hosting == 'UK'? 'Company Registration Number' : 'ABN',
  }

  wizardMetaData = [
    { id: 'tab-company', title: 'Company', class: 'active', iconClass: 'fa fa-home', approved: false, acceptanceCriteria: ['Lorem Ipsum is simply dummy text of the printing and typesetting industry', 'Lorem Ipsum is simply dummy text of the printing and typesetting industry', 'Lorem Ipsum is simply dummy text of the printing and typesetting industry'] },
    { id: 'tab-sla', title: 'Service Provider Agreement', iconClass: 'fa fa-phone', approved: false, acceptanceCriteria: ['Lorem Ipsum is simply dummy text of the printing and typesetting industry', 'Lorem Ipsum is simply dummy text of the printing and typesetting industry', 'Lorem Ipsum is simply dummy text of the printing and typesetting industry'] },
    { id: 'tab-settings', title: 'Settings', iconClass: 'fa fa-puzzle-piece', approved: false, acceptanceCriteria: ['Lorem Ipsum is simply dummy text of the printing and typesetting industry', 'Lorem Ipsum is simply dummy text of the printing and typesetting industry', 'Lorem Ipsum is simply dummy text of the printing and typesetting industry'] },
    { id: 'tab-compliance', title: 'Compliance', iconClass: 'fa fa-envelope', approved: false, acceptanceCriteria: ['Lorem Ipsum is simply dummy text of the printing and typesetting industry', 'Lorem Ipsum is simply dummy text of the printing and typesetting industry', 'Lorem Ipsum is simply dummy text of the printing and typesetting industry'] },
    { id: 'tab-staff', title: 'Staff & Compliance', iconClass: 'fa fa-file-text-o', approved: false, acceptanceCriteria: ['Lorem Ipsum is simply dummy text of the printing and typesetting industry', 'Lorem Ipsum is simply dummy text of the printing and typesetting industry', 'Lorem Ipsum is simply dummy text of the printing and typesetting industry'] },
    { id: 'tab-inductions', title: 'Inductions', iconClass: 'fa fa-picture-o', approved: false, acceptanceCriteria: ['Lorem Ipsum is simply dummy text of the printing and typesetting industry', 'Lorem Ipsum is simply dummy text of the printing and typesetting industry', 'Lorem Ipsum is simply dummy text of the printing and typesetting industry'] },
    // { id: 'tab-tmapp', title: 'Tree Manager & App', iconClass: 'fa fa-check-square', approved: false, acceptanceCriteria: ['Lorem Ipsum is simply dummy text of the printing and typesetting industry', 'Lorem Ipsum is simply dummy text of the printing and typesetting industry', 'Lorem Ipsum is simply dummy text of the printing and typesetting industry'] },
  ]


  constructor(private crudService: CRUDService,
    private messageService: MessageService,
    private route: ActivatedRoute,
    private confirmationService: ConfirmationService,
    public sanitizer: DomSanitizer,
    private EncrDecr: EncrDecrService,
    private router: Router) {
    this.companyToken = null;
    this.companyToken = this.route.snapshot.paramMap.get('token');
  }



  async ngOnInit() {

    //console.log('View Only' + this.view);
    if (this.view === 'settings') {
      this.index = 1;
    }
    if (this.companyToken !== '' && this.companyToken !== null) {
      let data: any = await this.crudService.getDataByField('company', 'token', this.companyToken).toPromise();
      if (data.length > 0) {
        this.selectedCompany = data[0];
        this.companyId = this.selectedCompany.id;
        this.crudService.getStaffByCID(this.selectedCompany.id).subscribe((data2: any[]) => {
          if (data2.length > 0) {
            data2.sort(function (b, a) { return b.id - a.id });
            this.user = data2[0];
            sessionStorage.setItem('adminUser', JSON.stringify(this.user));
          }
        });

        this.updateTab('tab-settings');
        /* //console.log(this.app.userSettings);
        this.companyId = this.app.userSettings.company_id; */
        this.crudService.getDataByField('Company', 'id', this.companyId).subscribe((data: any[]) => {
          this.company = data[0];
          //console.log(this.company);
          if (this.company.default_depot === '1') {
            this.isDepot = true;
          } else {
            this.isDepot = false;
          }

          this.crudService.getDataByField('Country', 'id', this.company.country_id).subscribe((data: any[]) => {
            this.selectedCountry = data[0];
          });
          this.crudService.getDataByField('State', 'id', this.company.state_id).subscribe((data: any[]) => {
            this.selectedState = data[0];
          });

        });

        this.selectedStaff = {};
        this.crudService.getStaffByCID(this.companyId).subscribe((data: any[]) => {
          this.staffs = data;
          this.selectedStaff = this.staffs[0];
        });
        this.getAllCountries();
        this.getAllStates();

        this.safetyCompliance = [{ id: 0, filePath: '001 - Operating a Chainsaw-2020.pdf', name: 'Operating a Chainsaw', updated: '2020' },
        { id: 1, filePath: '003 - Stump Grinding-2020.pdf', name: 'Stump Grinding', updated: '2020' },
        { id: 2, filePath: '004 - Wood Chipper-2020.pdf', name: 'Wood Chipper', updated: '2020' },
        { id: 3, filePath: '005-  Working under or Near power lines-2019.pdf', name: 'Working under or Near power lines', updated: '2019' },
        { id: 4, filePath: '006 - Tree Pruning -2019.pdf', name: 'Tree Pruning', updated: '2019' },
        { id: 5, filePath: '008 - Working on a roof-2019.pdf', name: 'Working on a roof', updated: '2019' },
        { id: 6, filePath: 'OnSite Tree Works_ ALL ITEMS SWMS_2020.pdf', name: 'OnSite Tree Works', updated: '2019' }];
      } else {
        this.messageService.add({ severity: 'error', summary: 'Error', detail: 'The Token has either expired or the intended action is already complete. Please check with Support for further action', life: 1000 });
        this.router.navigate(['/login']);
      }
    }
  }
  showClient() {
    this.company = {
      business_ABN: null,
      email: null,
      phone: null,
      website: null,
      emp_size: null,
      street_address: null,
      invalid_login_count: null,
      trading_name: null,
      mobile_no: null,
      main_contact_name: null,
      suburb: null,
      postcode: null,
      state_id: null,
      country_id: null,
      status: null,
      remarks: null,
      priority: null,
      regn_status: null,
      margin: null,
      client_type_id: 6, // 5 for client 6 for contractor
      account_name: null,
      account_BSB: null,
      account_number: null,
      default_depot: null,
      account_email: null,
      no_of_crew: null,
      company_inactive_from: null,
      company_inactive_to: null,
      rebate: null,
      account_id: null,
      client_subtype: null,
      compliancelevel_id: null,
      addl_address: null,
      operations_circle: null, payment_terms: null, contractor_type: null,
      fence_required: null, process_id: 830,
      client_category: null,
      client_comm_type: null,
    };
  }

  getAllJobStaffDetails() {


  }

  getAllCountries() {
    this.crudService.getAll('Country').subscribe((data: any[]) => {
      this.countryList = data;
      //console.log(this.countryList);
    });
  }

  getAllStates() {
    this.crudService.getAll('State').subscribe((data: any[]) => {
      this.stateList = data;
    });
  }

  saveSettings() {
    this.settingSubmitted = true;
    //console.log(this.userSettingInfo);
    if (typeof this.userSettingInfo.created_by == 'undefined') {
      this.userSettingInfo.created_by = null;
    }
    if (this.userSettingInfo.id) {
      this.crudService.Update(this.userSettingInfo, 'BusinessDetails').subscribe((data: any) => {
        if (data.success === 1) {
          this.messageService.add({ severity: 'success', summary: 'Successful', detail: 'Business Settings Updated', life: 1000 });
        } else {
          this.messageService.add({ severity: 'error', summary: 'Error', detail: 'Business Settings Not Updated', life: 1000 });
        }
      });
    } else {
      this.crudService.Update(this.userSettingInfo, 'BusinessDetails').subscribe((data: any) => {
        if (data.success === 1) {
          this.userSettingInfo.id = data.id;
          this.messageService.add({ severity: 'success', summary: 'Successful', detail: 'Business Settings Updated', life: 1000 });
        } else {
          this.messageService.add({ severity: 'error', summary: 'Error', detail: 'Business Settings Not Updated', life: 1000 });
        }
      });
    }
  }

  saveCompany() {

    //console.log(this.company);
    //console.log('state ', this.selectedState)
    this.submitted = true;
    if (this.selectedState.id !== null && this.selectedState.id !== '' && this.selectedState.id !== undefined) {
      this.company.state_id = this.selectedState.id;
    }
    if (this.isDepot) {
      this.company.default_depot = '1';
    } else {
      this.company.default_depot = '2';
    }
    if (this.selectedCountry.id !== null && this.selectedCountry.id !== '') {
      this.company.country_id = this.selectedCountry.id;
    }
    if (this.selectedState.id !== null && this.selectedState.id !== '') {
      this.company.state_id = this.selectedState.id;
    }
    if (this.company) {
      if (this.company.business_name.trim()) {
        if (this.company.id) {
          this.crudService.Update(this.company, 'company').subscribe((data: any) => {
            if (data.success === 1) {
              this.messageService.add({ severity: 'success', summary: 'Successful', detail: 'Contractor Updated', life: 1000 });
            } else {
              this.messageService.add({ severity: 'error', summary: 'Error', detail: 'Contractor Not Updated', life: 1000 });
            }
          });
        }
      } else {
        //console.log('in else save');
        this.messageService.add({ severity: 'error', summary: 'Error', detail: 'Please enter all mandatory field to proceed with saving', life: 1000 });
      }
    }
  }

  public AddressChange(address: any) {
    const fullAddress = [];

    // tslint:disable-next-line: prefer-for-of
    for (let i = 0; i < address.address_components.length; i++) {
      const addressType = address.address_components[i].types[0];
      if (this.componentForm[addressType]) {

        const val = address.address_components[i][this.componentForm[addressType]];

        if (addressType === 'street_number') {
          fullAddress[0] = val;
        } else if (addressType == 'route') {
          fullAddress[1] = val;
        }

        if (addressType === 'sublocality_level_1') {// suburb
          this.company.street_address = val;
        }
        if (addressType === 'locality') {// suburb
          this.company.suburb = val;
        } else if (addressType === 'administrative_area_level_1') {// state
          this.stateList.forEach((stat) => {
            if (stat.name === val) {
              this.company.state_id = stat.id;
              this.selectedState = stat;
            }
          });

        } else if (addressType === 'administrative_area_level_2') {// council
          //                                                $scope.suburb = val;
        } else if (addressType === 'country') {
          this.countryList.forEach((ctry) => {
            if (ctry.name === val) {
              this.company.country_id = ctry.id;
              this.selectedCountry = ctry;
            }
          });
        } else if (addressType === 'postal_code') {
          this.company.postcode = val;
        }

      }
    }
    const fullAdr = fullAddress.join(' ');
    this.company.street_address = fullAdr;
  }

  selectFile(event: any) { //Angular 11, for stricter type
    if (!event.target.files[0] || event.target.files[0].length == 0) {
      this.msg = 'You must select an image';
      return;
    }

    var mimeType = event.target.files[0].type;

    if (mimeType.match(/image\/*/) == null) {
      this.msg = "Only images are supported";
      return;
    }

    var reader = new FileReader();
    reader.readAsDataURL(event.target.files[0]);

    reader.onload = (_event) => {
      this.msg = "";
      this.url = reader.result;
    }
    let uploadedFile: File;
    for (let file of event.target.files) {
      uploadedFile = new File([file], "company_logo.jpg");
      this.logoFile = file;
    }
    let res = this.crudService.uploadCompanyLogo(uploadedFile, this.company.id);
    let response = JSON.parse(res)
    if (response != null) {
      if (response.success === 1) {
        this.userSettingInfo.companyLogoPath = this.companyId + '/compliance_docs/company_logo.jpg';
        this.saveSettings();
        this.messageService.add({ severity: 'success', summary: 'Successful', detail: 'File Uploaded Successfully', life: 1000 });
      } else {
        this.messageService.add({ severity: 'error', summary: 'Error', detail: 'File Not Uploaded', life: 1000 });
      }
    };
  }

  tagConfig;
  updateTab(v) {
    this.tagConfig?.forEach(c => {
      if (c.content && c.id === v) {
        this[c.content] = true;
      }
    });
    this.activeTab = v;
    this.wizardMetaData.forEach(w => {
      w.class = w.id == v ? 'active' : '';
    })
  }
}
