import { Component, Input, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import * as markerjs2 from "markerjs2";
import { ConfirmationService, MessageService } from 'primeng/api';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { element } from 'protractor';
import { CRUDService } from 'src/app/utilities/crud.service';
import { ImageDocument, Type } from './image-marking';
import { DomSanitizer } from '@angular/platform-browser';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-image-marking',
  templateUrl: './image-marking.component.html',
  styleUrls: ['./image-marking.component.css']
})
export class ImageMarkingComponent implements OnInit {
  @Input() image: any

  
  addNewImage:any;
  selectedType: Type;
  currentUser: any;
  imageTab: boolean = false;
  uploadedFiles: any[] = [];
  imageDocuments: any[];
  imageDocument: ImageDocument;
  imageForm: FormGroup;
  submitted: any;
  image_url:any;
  sourceImage: any;
  targetRoot: any;
  maState: any;

  constructor(
    private CrudService: CRUDService,
    private messageService: MessageService,
    private confirmationService: ConfirmationService,
    public ref: DynamicDialogRef,
    public config: DynamicDialogConfig,
    public sanitizer: DomSanitizer,
    private route: ActivatedRoute,
  ) { }


  ngOnInit() {
    if (typeof this.config.data === 'undefined' || typeof (this.config.data.image) === 'undefined') {
      //console.log('undefined');
    } else {
      this.image = this.config.data.image;
    }
    //console.log(this.image);
    //console.log(this.image.image_url);
    this.currentUser = JSON.parse(sessionStorage.getItem('currentUser'));
    //console.log(this.currentUser)
    this.image_url = this.image.image_url;
    //this.showMarkerArea(this.image);
  }

  showMarkerArea(img:any) {
    //console.log(img);
    const markerArea = new markerjs2.MarkerArea(img);
    markerArea.settings.displayMode = "popup";
    //console.log(markerArea);
    // //console.log(img.src)
    markerArea.addRenderEventListener((imgURL) => {
      img.src = imgURL
      this.updateImage(imgURL);
    });
    //console.log(markerArea.getState().markers);
    markerArea.show();
  }

  updateImage(imgURL){
    this.image.image_url = imgURL;
  }
   setSourceImage(source) {
      this.sourceImage = source;
      this.targetRoot = source.parentElement;
    }
  onChangeImageType(selecetdType) {
    //console.log(selecetdType)
    this.imageDocument.img_type = selecetdType.id
  }

}
