import { Component, OnInit, Input } from '@angular/core';
import { CRUDService } from 'src/app/utilities/crud.service';
import { environment } from 'src/environments/environment';


@Component({
  selector: 'app-job-detail-header',
  templateUrl: './job-detail-header.component.html',
  styleUrls: ['./job-detail-header.component.scss'],
  providers: []
})

export class JobDetailHeader implements OnInit {

  @Input() jobInfo: any;
  @Input() allocId: any
  @Input() Job_status_data:any
  showColours: Boolean = false;
  JobAllocDetails: any;
  priLabel:Boolean = true;
  jobDelShow:Boolean = true;
  jobDelHide:Boolean = false;
  dateFormat: any;
  constructor(
    private crudService: CRUDService){}

  jobFlags = [
    { name: 'red', code: 'firebrick' },
    { name: 'blue', code: 'darkcyan' },
    { name: 'yellow', code: 'darkgoldenrod' },
    { name: 'orange', code: 'darkorange' },
    { name: 'purple', code: 'purple' },
    { name: 'deeppink', code: 'deeppink' },
    { name: '', code: '' }];
  async ngOnInit() {
    console.log("jobInfo",this.jobInfo);
    console.log("this.Job_status_data",this.Job_status_data);
    debugger
    if(!this.jobInfo.JobStatus){
      this.jobInfo.JobStatus= this.Job_status_data
    }
  
    //this.dateFormat = environment.dateFormat;
    this.dateFormat = environment.DateFormat;
    console.log("Alloc",this.allocId);
    let JobAllocData: any = await this.crudService.getDataByField('jobAllocation', 'id', this.allocId).toPromise();
    this.JobAllocDetails = JobAllocData[0];
 


    // const smryData: any = await this.crudService.getJobSummaryByJobId(job.JobId).toPromise();
    
    // const jobAllocationResults = await this.JobService.getJobInfo(smryData[0]);
  }

  updateJobFlag(colour) {
    this.JobAllocDetails.flag_details = colour.code;
    this.crudService.updateFlag(this.allocId, colour.code).toPromise();
  }

  jobDetail() {
      const jobno = document.getElementById("jobno");
      const jobSt = document.getElementById("job_status");
      const recDate = document.getElementById("revDate");
      const workProv = document.getElementById("workPro");
      if (jobno.className == "labelShow" 
        && jobSt.className == "labelShow" 
        && recDate.className == "labelShow" 
        && workProv.className == "labelShow" 
      ) {
        jobno.className = "priLabel";
        jobSt.className = "priLabel";
        recDate.className = "priLabel";
        workProv.className = "priLabel";

      } else {
        jobno.className = "labelShow";
        jobSt.className = "labelShow";
        recDate.className = "labelShow";
        workProv.className = "labelShow";
      }
  }

  jobDel() {
      const jobNo = document.getElementById("jobNo");
      const TreePro = document.getElementById("treePro");
      const jobStatus = document.getElementById("jobSt");
      const joblocation = document.getElementById("jobloc");
      const jobContact = document.getElementById("jobContact");
      const Client = document.getElementById("client");
      if (jobNo.className == "labelShow" && TreePro.className == "labelShow" 
      && jobStatus.className == "labelShow" 
      && joblocation.className == "labelShow" 
      && jobContact.className == "labelShow"  
      && Client.className == "labelShow" 
      ) {

        jobNo.className = "priLabel";
        TreePro.className = "priLabel";
        jobStatus.className = "priLabel";
        joblocation.className = "priLabel";
        jobContact.className = "priLabel";
        Client.className = "priLabel";
      } else {
        jobNo.className = "labelShow";
        TreePro.className = "labelShow";
        jobStatus.className = "labelShow";
        joblocation.className = "labelShow";
        jobContact.className = "labelShow";
        Client.className = "labelShow";
      }
  }
}