import { Component, Input, OnInit } from '@angular/core';
import { CRUDService } from 'src/app/utilities/crud.service';
import { MessageService } from 'primeng/api';
import { DomSanitizer } from '@angular/platform-browser';
import { ActivatedRoute } from '@angular/router';
import { FileUploader } from 'ng2-file-upload';

@Component({
  selector: 'app-companies-settings',
  templateUrl: './companies-settings.component.html',
  styleUrls: ['./companies-settings.component.css']
})
export class CompaniesSettingsComponent implements OnInit {
  @Input() companyId;
  userSettingInfo: any;
  userDetails: any;
  submitted: any;
  url: any; //Angular 11, for stricter type
  logoFile: any;
  msg: any;
  uploader: FileUploader;
  companyLogoPath: any =null;

  constructor(private crudService: CRUDService,
    private messageService: MessageService,
    public sanitizer: DomSanitizer) { }

  async ngOnInit() {
    this.crudService.getDataByField('businessSettings', 'company_id', this.companyId).subscribe((businessSettingsData: any[]) => {
      if (businessSettingsData.length > 0) {
        this.userSettingInfo = businessSettingsData[0];
        //console.log('companylogo', this.userSettingInfo.companyLogoPath);
        if (this.userSettingInfo.companyLogoPath !== '' && this.userSettingInfo.companyLogoPath != null) {
          this.companyLogoPath =this.userSettingInfo.companyLogoPath;
          this.fetchCompanyLogo();
        }
      } else {
        this.userSettingInfo = {
          companyLogoPath: null,
          company_id: this.companyId,
          android_version: '1.0.4',
          client_id: null,
          client_secret: null,
          generalCommsFromEmail: null,
          generalCommsReplyToEmail: null,
          id: null,
          invoiceFooterText: null,
          invoiceReplyToEmail: null,
          paymentTerms: null,
          quoteFooterText: null,
          quoteReplyToEmail: null,
          realmid: null,
          refresh_token: null,
          invoiceFromEmail: null,
          created_by: null,
          quoteFromEmail: null
        }
      }
      //console.log('user company details', this.userSettingInfo);
    });
    this.uploader = new FileUploader({
      disableMultipart: true, // 'DisableMultipart' must be 'true' for formatDataFunction to be called.
      formatDataFunctionIsAsync: true,
      formatDataFunction: async (item) => {
        return new Promise((resolve, reject) => {
          resolve({
            name: item._file.name,
            length: item._file.size,
            contentType: item._file.type,
            date: new Date()
          });
        });
      }
    });
    this.userDetails = JSON.parse(sessionStorage.getItem('adminUser'));
  }


  saveSettings() {
    this.submitted = true;
    //console.log(this.userSettingInfo);
    if (typeof this.userSettingInfo.created_by == 'undefined') {
      this.userSettingInfo.created_by = null;
    }
    if (this.userSettingInfo.id) {
      this.userSettingInfo.last_modified_by = new Date();
      this.userSettingInfo.last_updated_at = this.userDetails.id;
      this.crudService.Update(this.userSettingInfo, 'BusinessDetails').subscribe((data: any) => {
        if (data.success === 1) {
          this.messageService.add({ severity: 'success', summary: 'Successful', detail: 'Business Settings Updated', life: 1000 });
        } else {
          this.messageService.add({ severity: 'error', summary: 'Error', detail: 'Business Settings Not Updated', life: 1000 });
        }
      });
    } else {
      this.userSettingInfo.created_by = this.userDetails.id,
        this.userSettingInfo.created_at = new Date();
      this.userSettingInfo.owner = 1,
        this.userSettingInfo.quote_email = null,
        this.userSettingInfo.invoice_email = null,
        this.userSettingInfo.last_modified_by = null;
      this.userSettingInfo.last_updated_at = null;
      this.crudService.Create(this.userSettingInfo, 'BusinessDetails').subscribe((data: any) => {
        if (data.success === 1) {
          this.userSettingInfo.id = data.id;
          this.messageService.add({ severity: 'success', summary: 'Successful', detail: 'Business Settings Updated', life: 1000 });
        } else {
          this.messageService.add({ severity: 'error', summary: 'Error', detail: 'Business Settings Not Updated', life: 1000 });
        }
      });
    }
  }

  downloadFile(safetyCompliance: any) {
    import('file-saver').then(FileSaver => {
      this.crudService.fetchSafetyComplianceFile(safetyCompliance.filePath).subscribe((data: any) => {
        var file = data;
        FileSaver.saveAs(file, safetyCompliance.name);
      });
    });
  }

  removeLogo() {
    this.crudService.deleteLogoFile(this.companyId + '/compliance_docs/company_logo.jpg').subscribe((response: any) => {
      //console.log(response);
      if (response.response == 'success') {
        //console.log("file upload res");
        //console.log(response);
        this.userSettingInfo.companyLogoPath = '';
        this.saveSettings();
        this.messageService.add({ severity: 'success', summary: 'Successful', detail: 'Logo removed successfully', life: 1000 });
      }
    });
  }

  onUpload(event) {
    //console.log(event.files);
    let uploadedFile: File;
    for (let file of event) {
      uploadedFile = new File([file], "company_logo.jpg");
      this.logoFile = file;
      var reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = (_event) => {
        this.msg = "";
        this.url = reader.result;
      }
    }
    //console.log(uploadedFile);
    let res = this.crudService.uploadCompanyLogo(uploadedFile, this.companyId);
    let response = JSON.parse(res)
    if (response != null) {
      if (response.success === 1) {
        this.userSettingInfo.companyLogoPath = this.companyId + '/compliance_docs/company_logo.jpg';
        this.companyLogoPath =this.userSettingInfo.companyLogoPath;
        this.saveSettings();
        this.messageService.add({ severity: 'success', summary: 'Successful', detail: 'File Uploaded Successfully', life: 1000 });
      } else {
        this.messageService.add({ severity: 'error', summary: 'Error', detail: 'This file type is not supported', life: 1000 });
      }
    };
  }

  fetchCompanyLogo() {
    if (this.userSettingInfo.companyLogoPath == null) {
      this.userSettingInfo.companyLogoPath = '';
    } else {
      this.crudService.getCompanyLogo(this.companyId, this.companyId + '/compliance_docs/company_logo.jpg').subscribe(blob => {
        let objectURL = URL.createObjectURL(blob);
        this.url = this.sanitizer.bypassSecurityTrustUrl(objectURL);
        //console.log('logo', this.logoFile);
      });
    }
  }
}
