import { Component, OnInit, Input } from '@angular/core';
import { CdkDragDrop, moveItemInArray, transferArrayItem } from '@angular/cdk/drag-drop';
import { Board } from 'src/app/models/board.model';
import { Column } from 'src/app/models/column.model';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { ActivatedRoute, Router } from '@angular/router';
import { ConfirmationService, MessageService } from 'primeng/api';
import { DialogService, DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { JOBService } from 'src/app/utilities/job.service';
import { WorkFlowService } from 'src/app/utilities/workflow.service';
import { CRUDService } from 'src/app/utilities/crud.service';

@Component({
  selector: 'app-task-board',
  templateUrl: './task-board.component.html',
  styleUrls: ['./task-board.component.scss'],
  providers: [DialogService, MessageService, ConfirmationService]
})
export class TaskBoardComponent implements OnInit {

  @Input() wf_id: any;
  @Input() user_id: any;
  boardDetails: any = { columns: null };
  groupsList: any[];
  items: any;
  socket: any;
  task_head: any;

  constructor(
    private route: ActivatedRoute,
    private wfService: WorkFlowService,
    private crudService: CRUDService,
    private messageService: MessageService,
    private confirmationService: ConfirmationService,
    private router: Router,
    public ref: DynamicDialogRef,
    public config: DynamicDialogConfig,
    public dialogService: DialogService,
    public JobService: JOBService
  ) { }


  /* board: Board = new Board('Test Board', [
    new Column('Ideas', '1', [
      { name: 'Some random idea', task_id: '1' },
      { name: 'This is another random idea', task_id: '2' },
      { name: 'build an awesome application', task_id: '3' },
    ]),
    new Column('Research', '2', [
      { name: 'Lorem ipsum', task_id: '4' },
      { name: 'foo', task_id: '3' },
      { name: 'This was in the Research column', task_id: '5' },
    ]),
    new Column('Todo', '3', [
      'Get to work',
      'Pick up groceries',
      'Go home',
      'Fall asleep'
    ]),
    new Column('Done', '4', [
      'Get up',
      'Brush teeth',
      'Take a shower',
      'Check e-mail',
      'Walk dog'
    ]),
    new Column('Closed', '5', [
      'Get up',
      'Brush teeth',
      'Take a shower',
      'Check e-mail',
      'Walk dog'
    ]),
    new Column('Archived', '6', [
      'Get up',
      'Brush teeth',
      'Take a shower',
      'Check e-mail',
      'Walk dog'
    ])
     {id: number,
wf_id: number,
wd_id: number,
name: string,
Description: string,
start_dt: Date,
end_dt: Date,
priority: string,
type: string,
category: string,
status: string,
tags: string,
next_followup: Date,
notes: string,
created_by: number,
created_at: Date,
updated_by: number,
updated_at: Date }

  ]); */

  columnsListbyID: any[] = [];
  companyId: any;
  userDetails: any;
  taskList: any;
  columns: any;

  ngOnInit() {
    this.companyId = sessionStorage.getItem('company_id');
    this.userDetails = JSON.parse(sessionStorage.getItem('loggedInUser'));
    //console.log('userinfo', this.userDetails);
    if (this.wf_id != null && typeof this.wf_id == 'undefined') {
      if (typeof this.config.data == 'undefined') {
        this.wf_id = parseInt(this.route.snapshot.paramMap.get('wf_id'));
        this.user_id = parseInt(this.route.snapshot.paramMap.get('user_id'));
      } else {
        this.wf_id = this.config.data.wf_id;
        this.user_id = this.config.data.user_id;
      }
    }
    this.loadKanban();

    this.items = [
      {
        label: 'Options',
        items: [{
          label: 'Update',
          icon: 'pi pi-refresh',
          command: () => {
            this.update();
          }
        },
        {
          label: 'Delete',
          icon: 'pi pi-times',
          command: () => {
            this.delete();
          }
        }
        ]
      },
      {
        label: 'Navigate',
        items: [{
          label: 'Angular',
          icon: 'pi pi-external-link',
          url: 'http://angular.io'
        },
        {
          label: 'Router',
          icon: 'pi pi-upload',
          routerLink: '/fileupload'
        }
        ]
      }
    ];
  }
  /* 
    loadListView(){
       this.wfService.fetchBoardDetails(this.wf_id).subscribe((boardData: any) => {
        this.wfService.fetchColumnsList(this.wf_id, this.user_id).subscribe((columnsData: any[]) => {
          this.wfService.fetchTasksList(this.wf_id, this.user_id).subscribe((taskData: any[]) => {
            this.wfService.fetchTasksCheckList(this.wf_id, this.user_id).subscribe((checkListData: any[]) => {
              columnsData.forEach(column => {
                let tasksList = taskData.filter(val => val.wd_id === column.id);
                column.tasks = tasksList;
                this.columnsListbyID.push(column.id); //Must for loading drag and drop feature
              });
              boardData[0].columns = columnsData;
              this.boardDetails = boardData[0];
            });
          });
        });
      });
    } */

  loadKanban() {
    this.wfService.fetchBoardDetails(this.wf_id).subscribe((boardData: any) => {
      this.wfService.fetchColumnsList(this.wf_id, this.user_id).subscribe((columnsData: any[]) => {
        this.wfService.fetchTasksList(this.wf_id, this.user_id).subscribe((taskData: any[]) => {
          this.wfService.fetchTasksCheckList(this.wf_id, this.user_id).subscribe((checkListData: any[]) => {
            taskData.forEach(task => {
              let checkList = checkListData.filter(val => val.task_id === task.id);
              if (checkList.length > 0) {
                checkList.forEach(item => {
                  if (item.checked == '2') {
                    item.isChecked = true;
                  } else {
                    item.isChecked = false;
                  }
                });
                task.checklist = checkList;
              }
            });
            columnsData.forEach(column => {
              let tasksList = taskData.filter(val => val.wd_id === column.id);
              column.tasks = tasksList;
              this.columnsListbyID.push(column.id); //Must for loading drag and drop feature
            });
            boardData[0].columns = columnsData;
            this.boardDetails = boardData[0];
          });
        });
      });
    });
  }

  addTask(stageDetails) {
    let taskDetails = {
      id: null,
      wf_id: stageDetails.head_id,
      wd_id: stageDetails.id,
      name: 'New task',
      description: null,
      start_dt: new Date(),
      end_dt: new Date(),
      priority: 'Normal',
      type: 'to_do',
      category: '1',
      status: 'Active',
      tags: null,
      next_followup: null,
      notes: null,
      created_by: this.userDetails.id,
      created_at: new Date(),
      updated_by: null,
      updated_at: null
    };
    this.crudService.Create(taskDetails, 'taskhead').subscribe((data: any) => {
      if (data.success === 1) {
        taskDetails.id = data.id
        stageDetails.tasks = [taskDetails, ...stageDetails.tasks];
        this.messageService.add({ severity: 'success', summary: 'Successful', detail: 'New Task Created', life: 1000 });
      } else {
        this.messageService.add({ severity: 'warning', summary: 'Error', detail: 'Task Not Created', life: 1000 });
      }
    });
  }

  saveTask(taskDetails) {
    this.crudService.Update(taskDetails, 'taskhead').subscribe((data: any) => {
    });
  }

  deleteTask(taskDetails) {
    this.crudService.Delete(taskDetails.id, 'taskhead').subscribe((data: any) => {

    });
  }

  update() {
    this.messageService.add({ severity: 'success', summary: 'Success', detail: 'Data Updated' });
  }

  delete() {
    this.messageService.add({ severity: 'warn', summary: 'Delete', detail: 'Data Deleted' });
  }

  public dropGrid(event: CdkDragDrop<string[]>): void {
    moveItemInArray(this.boardDetails.columns, event.previousIndex, event.currentIndex);
    //console.log('event info', event)
    this.crudService.sendNotification('notification', 'sample').subscribe((data2: any[]) => {
      alert(JSON.stringify(data2));
    });
  }

  public drop(event: CdkDragDrop<string[]>): void {
    //console.log('event info', event)
    //console.log('Moved Task', event.previousContainer.data[event.currentIndex]);
    if (event.previousContainer === event.container) {
      moveItemInArray(event.container.data, event.previousIndex, event.currentIndex);
    } else {
      transferArrayItem(event.previousContainer.data,
        event.container.data,
        event.previousIndex,
        event.currentIndex);

    }
  }

  groupChanged(event: CdkDragDrop<string[]>) {
    //console.log('event info', event)
    moveItemInArray(this.boardDetails.columns, event.previousIndex, event.currentIndex);
  }

  addCheckListItem(task) {
    let taskCheckListItem = {
      id: null,
      task_id: task.id,
      description: null,
      status: 'Active',
      checked: '1',
      isChecked: false,
      created_by: this.userDetails.id,
      created_at: new Date(),
      updated_by: null,
      updated_at: null
    };
    this.crudService.Create(taskCheckListItem, 'taskchecklist').subscribe((data: any) => {
      if (data.success === 1) {
        taskCheckListItem.id = data.id
        if (typeof task.checklist != 'undefined' && task.checklist.length > 0) {
        } else {
          task.checklist = [];
        }
        task.checklist.push(taskCheckListItem);
      } else {
        this.messageService.add({ severity: 'warning', summary: 'Error', detail: 'Checklist Item Not Created', life: 1000 });
      }
    });
  }

  removeItem(item, itemList, task) {
    this.crudService.Delete(item.id, 'taskchecklist').subscribe((data: any) => {
      if (data.success === 1) {
        let newList = itemList.filter((val: any) => val.id !== item.id)
        itemList = [];
        itemList = newList;
        task.checkList = itemList;
      } else {
        this.messageService.add({ severity: 'warning', summary: 'Error', detail: 'Checklist Item Not Removed', life: 1000 });
      }
    });
  }

  onStageNameChange(value, column) {
    //console.log(value, column);
  }

  onTaskEdit(taskDetails) {
    let taskEdit = document.getElementById('workflow-task-edit');
    if (taskEdit?.className.indexOf('show') === -1) {
      setTimeout(() => {
        taskEdit.classList.add('show');
      }, 1);
    }
  }
  onTaskEditClose() {
    let taskEdit = document.getElementById('workflow-task-edit');
    taskEdit.classList.remove('show');
  }

  onNameChange(val) {
    //console.log("Changed", val)
  }

}
