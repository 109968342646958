import { Component, Input, OnInit } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { ActivatedRoute } from '@angular/router';
import { ConfirmationService, MessageService } from 'primeng/api';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { CRUDService } from 'src/app/utilities/crud.service';
import { environment } from 'src/environments/environment';
import { DatePipe } from '@angular/common';
import { MenuItem } from 'primeng/api';
import { CommonService } from 'src/app/utilities/common.service';

@Component({
  selector: 'app-auditlog-master',
  templateUrl: './auditlog-master.component.html',
  styleUrls: ['./auditlog-master.component.scss']
})
export class AuditlogMasterComponent implements OnInit {

  @Input() table_name: any;
  @Input() key_column: any;
  @Input() parent_table: any;
  @Input() pk_id: any;
  
  paginationvalue:any;
  selectedTypeList: any = [];
  cols: any[];
  exportColumns: any[];
  loading: Boolean = false;
  isExport: boolean=true;
  isDelete: boolean=true;
  showDataDialog : boolean=false;
  selectedroute: string;
  selectedAuditList : any = [];
  auditList : any=[];
  JSONauditList : any;
  changeTypeList : any;
  popUpData : any;
  dataTemp : any;
  selchange_type:any;
  key_name:any;
  key_namecol:any;
  items: MenuItem[];
  childList : any;
  selectedTablename : any;
  auditModulesData : any;

  moduleName : any;
  parentModule : any = [];
  selectedParent : any;
  keyColumn : any;
  keyName : any;
  moduleData: any;

  constructor(
    public DatePipe : DatePipe,
    private CrudService: CRUDService,
    private messageService: MessageService,
    private confirmationService: ConfirmationService,
    public ref: DynamicDialogRef,
    public config: DynamicDialogConfig,
    public sanitizer: DomSanitizer,
    private route: ActivatedRoute,
    private commonservice: CommonService
  ) { 
    this.items = [];
  }

  async ngOnInit(): Promise<void> {
    this.paginationvalue=environment.paginatorValue;
    this.loading = true;
    this.selectedAuditList = null;
    this.cols = [
      { field: 'table_name', header: 'Table Name' },
      { field: 'parent_table_name', header: 'Parent Table' },
      { field: 'key_column', header: 'Key Column' },
      { field: 'key_name', header: 'Key Name' },
      { field: 'status', header: 'status' }
    ];
    this.exportColumns = this.cols.map(col => ({ title: col.header, dataKey: col.field }));
    this.changeTypeList = [
      { id: 'Insert', value: 'Insert' },
      { id: 'Update', value: 'Update' },
      { id: 'Delete', value: 'Delete' }
    ];

    this.table_name = 'CompanyStaffInfo';
    this.key_column = 'company_id';
    this.pk_id = '1867';
    this.parent_table = 'Company';

    this.auditModulesData = await this.CrudService.getAllData('AuditModules').toPromise();
    this.parentModule =  this.auditModulesData.filter(val => val.table_parent_id == 0);
    let nilData = {
      id : '0',
      table_name : "NA"
    }
    this.parentModule.push(nilData);
    this.auditModulesData.forEach(async element => {
        if(element.table_parent_id === "0")
        {
          element.parent_table_name = "NA";
        }
        else
        {
          let filterData =  this.auditModulesData.filter(val => val.id === element.table_parent_id);
          element.parent_table_name = filterData[0].table_name;
        }
    });
    
  
    let per_data :any=await this.CrudService.getmenupermissions()
    // this.isExport = per_data.isExport;
    // this.isDelete = per_data.isDelete;
    this.loading = false;
  }

   exportExcel(table) {
    this.loading = true;
    let JsonData: any[] = [];
    if (this.selectedAuditList != null && this.selectedAuditList.length > 0) {
      JsonData = this.selectedAuditList;
    } else {
      JsonData = this.JSONauditList;
      if (typeof table.filteredValue !== 'undefined') {
        if (table.filteredValue !== null) {
          if (table.filteredValue.length !== this.JSONauditList.length && table.filteredValue.length > 0) {
            JsonData = table.filteredValue;
          }
        }
      }
    }

    let reformattedArray = JsonData.map(obj => {
      let rObj = {};
      this.cols.forEach(pair => {
        rObj[pair.header.toString()] = obj[pair.field];
      });
      return rObj;
    })

    import('xlsx').then(xlsx => {
      const worksheet = xlsx.utils.json_to_sheet(reformattedArray);
      const workbook = { Sheets: { data: worksheet }, SheetNames: ['data'] };
      const excelBuffer: any = xlsx.write(workbook, { bookType: 'xlsx', type: 'array' });
      this.saveAsExcelFile(excelBuffer, 'Job-margin');
    });
    this.loading = false;
  }

  saveAsExcelFile(buffer: any, fileName: string): void {
    import('file-saver').then(FileSaver => {
      const EXCEL_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
      const EXCEL_EXTENSION = '.xlsx';
      const data: Blob = new Blob([buffer], {
        type: EXCEL_TYPE
      });
      let timeStamp = this.DatePipe.transform(new Date(), 'ddMMyy_HHmm');
      FileSaver.saveAs(data, fileName + '_' + timeStamp + EXCEL_EXTENSION);
    });
  }

  exportPdf() {
    import('jspdf').then(jsPDF => {
      import('jspdf-autotable').then(x => {
        /* const doc = new jsPDF.default(0,0);
        if(typeof this.selectedDepots == 'undefined'){
            doc.autoTable(this.exportColumns, this.depots);
           }else{
               doc.autoTable(this.exportColumns, this.selectedDepots);
        }
       doc.save('products.pdf'); */
      })
    })
  }

  async filterByType(datas): Promise<any> {
    let list: any[] = [];
    this.selectedTypeList.forEach(async type => {
      let filteredList = datas.filter(val => val.change_type === type.value);
      if (list.length > 0) {
        list = list.concat(filteredList);
      } else {
        list = filteredList;
      }
    })
    return list;
  }

  async filterRecord(event) {
    this.loading = true;
    let list1: any[] = [];

    if (this.selectedTypeList.length > 0) {
      list1 = await this.filterByType(this.auditList);
    } else {
      list1 = this.auditList;
    }


    this.JSONauditList = list1;
    this.loading = false;
  }

  hideDialog() {
    this.showDataDialog = false;
  }
  showDialog(data,change_type)
  {
    this.selchange_type = change_type
    if(change_type == "Insert" || change_type == "Delete")
    {
      const jsonObject = JSON.parse(data);
      let jsonData: any = jsonObject[0];
      this.dataTemp = jsonData;
      let dats = this.getObjectKeys(jsonData);
      this.popUpData = dats;
    }
    else
    {
      const jsonObject = JSON.parse(data);
      let jsonData: any = jsonObject.Changes;
      this.dataTemp = jsonData;
     // let dats = this.getObjectKeys(jsonData);
      this.popUpData = jsonData;
    }
   
    this.showDataDialog = true;
  }
  getObjectKeys(jsonData: any): string[] {
    return Object.keys(jsonData);
  }
  extractValueByKey(key) {
        return this.dataTemp[key];
  }

  selectData(auditData){
   this.pk_id = auditData.pk_id
   this.selectedTablename = auditData.table_name
  }

  showChild(table_name,key_column){
    console.log("pk_id",this.pk_id);
    console.log("selectedTablename",this.selectedTablename);
    console.log("table_name",table_name);
    console.log("key_column",key_column);
  }

  openNew()
  {
    this.moduleData = {
      id : null
    }
    this.showDataDialog = true;
  }

  async saveRecord()
  {
    if(this.moduleName == null || this.moduleName == '' || this.keyColumn == null || this.keyColumn == '' || this.keyName == null || this.keyName == '')
    {
      this.messageService.add({ severity: 'error', summary: 'Error', detail: 'Please Fill all the Fields', life: 1000 });
    }
    else
    {
      this.moduleData.table_name = this.moduleName;
      this.moduleData.table_parent_id = this.selectedParent.id;
      this.moduleData.status = '1';
      this.moduleData.last_updated_at = '';
      this.moduleData.table_order_id = '';
      this.moduleData.key_column = this.keyColumn;
      this.moduleData.key_name = this.keyName;
      let response : any= await this.commonservice.saveRecord('AuditModules',this.moduleData)
      this.showDataDialog = false;
      this.ngOnInit();
    }
    
  }

  editItem(data)
  {
    debugger
    this.moduleData = {
      id : data.id
    }
    this.moduleName = data.table_name;
    let parentData = this.parentModule.filter(val => val.id === data.table_parent_id)
    this.selectedParent =  parentData[0];
    this.keyColumn = data.key_column;
    this.keyName = data.key_name;
    this.showDataDialog = true;
  }

  deletItem(data)
  {
    this.confirmationService.confirm({
      message: 'Are you sure want to delete Item?',
      header: 'Confirm',
      icon: 'pi pi-exclamation-triangle',
      accept: () => {
        this.CrudService.Delete(data.id, 'AuditModules').subscribe((data: any) => {
          if (data.success == 1) {
            this.messageService.add({ severity: 'success', summary: 'Successful', detail: 'Modules Deleted', life: 1000 });
            this.auditModulesData = this.auditModulesData.filter(val => val.id !== data.id);
            this.ngOnInit();
          }
        });
      }
    });
  }
}


