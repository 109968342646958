import { Component, OnInit } from '@angular/core';
import { CRUDService } from 'src/app/utilities/crud.service';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { async } from '@angular/core/testing';
import { CommonService } from 'src/app/utilities/common.service';
import { ConfirmationService, MessageService } from 'primeng/api';

@Component({
  selector: 'app-menu-permissions',
  templateUrl: './menu-permissions.component.html',
  styleUrls: ['./menu-permissions.component.scss']
})
export class MenuPermissionsComponent implements OnInit {

  menuPermissions: any;
  menuPermissionsList: any;
  menuList: any;
  companyId: any;
  userDetails: any;
  cols: any[];
  loading: boolean = true;
  paginationvalue: any;
  selectedMenu: any;
  companyData: any;
  userRoleList: any;
  isEditAll: boolean = false;
  disabledeletebtn:boolean = false
  constructor(  private confirmationService: ConfirmationService,private messageService: MessageService,private crudService: CRUDService, private commonService: CommonService, public ref: DynamicDialogRef, public config: DynamicDialogConfig) {
    this.companyId = sessionStorage.getItem('company_id');
    this.userDetails = JSON.parse(sessionStorage.getItem('loggedInUser'));
  }

  async ngOnInit(): Promise<void> {
    this.userRoleList = [
    //   { id: '1', name: 'Admin' },
    //   { id: '2', name: 'User' },
    //   { id: '3', name: 'Only Jobs - View' },
    //   { id: '5', name: 'Common' },
    //   { id: '6', name: 'All' }
    // ];
    { id: '1', name: 'User' },
    { id: '2', name: 'Common' },
    { id: '3', name: 'Only Jobs - View' },
    { id: '5', name: 'Admin' },
    { id: '6', name: 'All' }
  ];
    this.cols = [
      { field: 'user_group_id', header: 'User Group Id' },
      { field: 'company_name', header: 'Company Name' },
      { field: 'allow_create', header: 'Allow Create' },
      { field: 'allow_update', header: 'Allow Update' },
      { field: 'allow_delete', header: 'Allow Delete' },
      { field: 'allow_export', header: 'Allow Export' }
    ];
   await  this.getusergrop()
    let compData: any = await this.crudService.getAllData('company').toPromise();
    this.companyData = compData;
    let data: any = await this.crudService.getDataByField('MenuPermissions', 'menu_id', this.config.data.id).toPromise();
    this.menuPermissionsList = data;
   // console.log(' this.menuPermissionsList', this.menuPermissionsList)
    this.menuPermissionsList.forEach(item => {
      let selComp = this.companyData.find(val => (val.id == item.process_id));
      // if (typeof selComp != 'undefined') {
      //   item.selectedCompany = selComp;
      // } else {
      //   item.selectedCompany = null;
      // }
      let selRole = this.userRoleList.find(val => (val.id == item.user_group_id));
      if (typeof selRole != 'undefined') {
        item.selectedUserGroup = selRole;
      } else {
        item.selectedUserGroup = null;
      }
      if (item.allow_update == '1') {
        item.isUpdate = true;
      } else {
        item.isUpdate = false;
      }

      if (item.allow_create == '1') {
        item.isCreate = true;
      } else {
        item.isCreate = false;
      }

      if (item.allow_delete == '1') {
        item.isDelete = true;
      } else {
        item.isDelete = false;
      }

      if (item.allow_export == '1') {
        item.isExport = true;
      } else {
        item.isExport = false;
      }
    });
    this.loading = false;
   
  }

  addNewRecord(name) {
    let menu = {
        menu_id: this.config.data.id,
        allow_create:null,
        allow_update:null,
        allow_delete:null,
        allow_export:null,
        user_group_id:null,
        process_id:this.userDetails.process_id,
        created_by:this.userDetails.id,
        last_modified_by:null,
        created_at:null,
        last_updated_at:null,
        isEdit: true,
        isExport : false,
        isDelete : false,
        isCreate : false,
        isUpdate : false,
    };
    this.menuPermissionsList = [menu, ...this.menuPermissionsList];
    name.focus();
  }

  saveRecord() {
    debugger
    let changedRecords = this.menuPermissionsList.filter(val => (val.changed === true));
    changedRecords.forEach(async item => {
      debugger
      if ( item.isUpdate == true) {
        item.allow_update = '1'
      } else {
        item.allow_update = '0'
      }
      if ( item.isCreate == true) {
        item.allow_create = '1'
      } else {
        item.allow_create = '0'
      }
      if ( item.isDelete == true) {
        item.allow_delete = '1'
      } else {
        item.allow_delete = '0'
      }
      if ( item.isExport == true) {
        item.allow_export = '1'
      } else {
        item.allow_export = '0'
      }
      if(!item.selectedUserGroup){
        this.messageService.add({ severity: 'error', summary: 'Error', detail: ' Record Not Updated, Please selete User group', life: 1000 });
        item.isEdit = true
      }
      else{
        item.user_group_id =  item.selectedUserGroup.id
        console.log("element,",item);
        debugger
        let data: any = await  this.commonService.saveRecord('MenuPermissions', item);
        console.log("dadata,",data);
        debugger
        if(data.success == '1'){
          debugger
          this.messageService.add({ severity: 'success', summary: 'Successful', detail:' Record Saved', life: 1000 });
          item.id =data.data[0].id 
          item.isEdit = false
          item.changed = false
        }else{
          this.messageService.add({ severity: 'error', summary: 'Error', detail: ' Record Not Updated, Please selete User group', life: 1000 });
          
        }
      }

      item.changed = false;
      
    });
    //this.isEditAll = false;
  }
  onUserGrpChange(event, menu){
    //console.log("This is working",rateclass);
   // menu.rateset_id = event.value?.id;
    menu.changed=true;
  }

  deleteMenu(menu: any){
    if(!menu.id){
      this.menuPermissionsList = this.menuPermissionsList.filter(val => val !== menu);
    }
    else{
      this.confirmationService.confirm({
        message: 'Are you sure you want to delete: ' + menu.selectedUserGroup?.role_name + ' ?',
        header: 'Confirm',
        icon: 'pi pi-exclamation-triangle',
        accept: async () => {
          this.confirmationService.close();
          if(!menu.id){
            this.menuPermissionsList = this.menuPermissionsList.filter(val => val !== menu);
          }
          else{
            this.crudService.deleteRecordByID('MenuPermissions', menu.id).subscribe((data: any) => {
              if (data.success == 1) {
                this.messageService.add({ severity: 'success', summary: 'Successful', detail: 'Deleted successfully', life: 3500 });
                this.menuPermissionsList = this.menuPermissionsList.filter(val => val.id !== menu.id);
              }
              else{
                this.messageService.add({ severity: 'error', summary: 'Error', detail:'Not Deleted,Something Went Wrong!', life: 3500 });
              }
            });
          }
    
        },
        reject:  async () => {
          this.confirmationService.close();
  
        }
      });
    }

  }
  getusergrop(){
    debugger
    this.crudService.getusergrouplist().subscribe((data: any[]) => {
      this.userRoleList = data
    })
     
     
  }

}
