import { Component, OnInit, Input } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ConfirmationService, MessageService } from 'primeng/api';
import { CRUDService } from 'src/app/utilities/crud.service';
import { DynamicDialogRef, DynamicDialogConfig, DialogService } from 'primeng/dynamicdialog';
import { JOBService } from 'src/app/utilities/job.service';
import { Job, Client } from './tp-job-list';
import { TpJobAdminComponent } from '../tp-job-admin/tp-job-admin.component';
import { JobUserComponent } from '../../modules/job-user/job-user.component';
import { SortEvent } from 'primeng/api';
import { JobDetailsApprovalsFlaggingComponent } from '../../modules/job-details-approvals-flagging/job-details-approvals-flagging.component';
import { environment } from 'src/environments/environment';
import { CommonService } from 'src/app/utilities/common.service';
import { JobDetailsApprovalsComponent } from 'src/app/job/job-details-approvals/job-details-approvals.component';
import { TpJobScheduleComponent } from '../tp-job-schedule/tp-job-schedule.component';
import { TpJobImagesComponent } from '../tp-job-images/tp-job-images.component';
import { TpJobDocumentsComponent } from '../tp-job-documents/tp-job-documents.component';
import { TreeInfoComponent } from 'src/app/modules/tree-info/tree-info.component';
import { TpJobCostingComponent } from '../tp-job-costing/tp-job-costing.component';
import { TpShfComponent } from '../tp-shf/shf.component';
import { TpJobInvoiceSendComponent } from '../tp-job-invoice-send/tp-job-invoice-send.component';
import { TpScheduleAdminComponent } from '../tp-schedule-admin/tp-schedule-admin.component';
import { data } from 'jquery';
import { TpJobDetailsComponent } from '../tp-job-details/tp-job-details.component';
import { ContractorchannelComponent } from 'src/app/channel/contractorchannel/contractorchannel.component';
import { ClientchannelComponent } from 'src/app/channel/clientchannel/clientchannel.component';
import { PasswordCheckComponent } from 'src/app/password-check/password-check.component';
import {NgTinyUrlService} from 'ng-tiny-url';
import { debug } from 'console';


@Component({
  selector: 'app-tp-job-list',
  templateUrl: './tp-job-list.component.html',
  styleUrls: ['./tp-job-list.component.scss'],
  providers: [DialogService, MessageService, ConfirmationService, JOBService]
})
export class TpJobListComponent implements OnInit {
  @Input() display_type: any;
  @Input() contractor_id: any;
  jobList: Job[] = [];
  tpJobList: any;
  //contactNumber = '+1-800-555-1234; +1-800-555-5689';
  //contactEmail = 'support@agileitexperts.com, admin@agileitexperts.com';
  jobSummary: any;
  jobId: any;
  allocId: any;
  isFilterFlag: Boolean = false;
  selectedjob: Job[] = [];
  cols: any[];
  exportColumns: any[];
  searchValue: any;
  query: any = null;
  companyId: any;
  userDetails: any;
  viewType: any;
  overAllStatus: any = [];
  toggleSearch: any = false;
  showMoreColumn: any = false;
  jobStatus: any = [];
  selectedOverAllStatus: any;
  selectedJobStatus: any = [];
  clientsList: any = [];
  selectedClient: any = []
  originalJobList: any = [];
  overallVal: any;
  selectStatus: any;
  speedDial: Boolean = false;
  loading: Boolean = false;
  showFilterColours: Boolean = false;
  allFlagInfo: any[];
  showFlagsHistory: Boolean = false;
  showColorBox = environment.showColorBox
  selectedColour: any = { name: '', code: 'silver' };
  is324Exempt: any = false;
  jobFlags = [
    { name: 'red', code: 'firebrick' },
    { name: 'blue', code: 'darkcyan' },
    { name: 'yellow', code: 'darkgoldenrod' },
    { name: 'orange', code: 'darkorange' },
    { name: 'purple', code: 'purple' },
    { name: 'deeppink', code: 'deeppink' },
    { name: '', code: '' }];
  exportdisplay: Boolean = false;
  shortenedUrl: any;

  constructor(
    private route: ActivatedRoute,
    private crudService: CRUDService,
    private messageService: MessageService,
    private confirmationService: ConfirmationService,
    private router: Router,
    public ref: DynamicDialogRef,
    public config: DynamicDialogConfig,
    private dialogService: DialogService,
    private JobService: JOBService,
    private commonservice: CommonService,
    private tinyUrl: NgTinyUrlService,
  ) {
    // this.companyId = sessionStorage.getItem('company_id');
    this.userDetails = JSON.parse(sessionStorage.getItem('loggedInUser'));
  }

  // async encode(jobId:any,AllocationId:any){
  //   this.shortenedUrl = await this.crudService.encodeJobURL('client', jobId, AllocationId).toPromise();
  //   console.log('test link' , this.shortenedUrl);
  // }

  openNew() {
    if (this.viewType === 'TM') {
      const ref = this.dialogService.open(TpJobAdminComponent, {
        data: {
          jobId: 0,
        },
        width: '100%',
        height: '100%'
      })
      ref.onClose.subscribe((response: any) => {
        this.loadAllJobsList();
        /* if(response){
          this.jobList = [response, ...this.jobList];
        } */
      });
    } else {
      const ref = this.dialogService.open(JobUserComponent, {
        data: {
          JobId: 0,
        },
        header: 'Job Info',
        width: '100%',
        height: '100%'
      })
      ref.onClose.subscribe((response: any) => {
        this.loadAllJobsList();
        /* if(response){
          this.jobList = [response, ...this.jobList];
        } */
      });
    }
  }

  async loadAllJobsList() {
    let data = await this.crudService.getDataByField('AutomatedReminderTPActionView', 'contractor_id', this.companyId).toPromise();
    this.tpJobList = data;
    console.log("this.tpJobList",this.tpJobList);
    
    this.tpJobList.forEach(job => {
      job.date = new Date();
      this.jobList.forEach(job_list => {
        if(job.JobNo == job_list.JobNo){
          job.ContactPhone = job_list.ContactPhone
        }

      })
    })
    console.log("this.tpJobList2",this.tpJobList);
    // this.tpJobList.forEach(job => {
    //   job.date = new Date();
    //   //job.email = this.contactEmail;
    //   if (job.Contact != null && job.Contact != '') {
    //     job.Contact = job.Contact.replaceAll(';', ',');
    //     job.contact_numbers = job.Contact.split(',');
    //   }
    //   /* if (job.email != null && job.email != '') {
    //     job.email = job.email.replaceAll(';', ',');
    //     job.contact_email = job.email.split(',');
    //   } */
    // });
  }

  viewDetails(job) {
    const ref = this.dialogService.open(TpJobDetailsComponent, {
      data: {
        JobId: 0,
      },
      header: 'Job Info',
      width: '100%',
      height: '100%'
    })
    ref.onClose.subscribe((response: any) => {
      this.loadAllJobsList();
    });
    //this.router.navigate(['tp-job/details', job.JobId, job.AllocationId])
  }

  async ngOnInit(): Promise<void> {
    this.loading = true;
    if(this.display_type == 'TM'){
      this.companyId = this.contractor_id;
    }else{
      this.companyId = this.userDetails.company_id;
    }
    
    const data: any = await this.crudService.ListingPageByCompany(this.companyId).toPromise();
    this.jobList = data;
    // console.log("this.jobList",this.jobList);
    

    await this.initialize();
    this.loading = false;
    /* if (typeof this.route.snapshot.paramMap.get('token') !== 'undefined') {
      let token = this.route.snapshot.paramMap.get('token');
      if (token != null && token !== '') {
        let data: any = await this.crudService.getDataByField('company', 'token', token).toPromise();
        if (data.length > 0) {
          let compInfo = data[0];
          this.companyId = compInfo.id;
        }
      }
      if (this.userDetails.id != '') {
        const ref = this.dialogService.open(PasswordCheckComponent, {
          data: {
            companyId: this.companyId,
          },
          width: '100%',
          height: '100%'
        })
        ref.onClose.subscribe((userDetails: any) => {
          if (userDetails.id) {
            let user = userDetails;
            this.userDetails = userDetails;
          }
          this.initialize();
        });
      } else {
        this.initialize();
      }
    } else {
     this.initialize(); 
    } */
  }


  async initialize() {
    this.overAllStatus = [
      { id: 1, name: 'Active' },
      { id: 2, name: 'Invoiced' },
      { id: 3, name: 'Completed' },
      { id: 4, name: 'Cancelled' },
      { id: 5, name: 'On Hold' },
      { id: 6, name: 'All' }
    ];
    this.selectedOverAllStatus = { id: 1, name: 'Active' };
    this.jobStatus = [
      { id: 'Send Offer', name: 'Send Offer' },
      { id: 'Await Acceptance', name: 'Await Acceptance' },
      { id: 'Await Cost', name: 'Await Cost' },
      { id: 'Cost Saved', name: 'Cost Saved' },
      { id: 'Approve Quote', name: 'Approve Quote' },
      { id: 'Await Invoice', name: 'Await Invoice' },
      { id: 'Approve Invoice', name: 'Approve Invoice' },
      { id: 'Invoice Submitted', name: 'Invoice Submitted' },
    ]
    this.clientsList = [
      { id: 1, name: 'Insurance' },
      { id: 2, name: 'SES' },
      { id: 6, name: 'DHA' },
      { id: 3, name: 'Facility Management' },
      { id: 4, name: 'New Zealand' },
      { id: 5, name: 'Enviro' },
    ]
    this.viewType = 'TP';
    /* if (typeof this.reqId == 'undefined') {
      if (typeof this.config.data !== 'undefined') {
        if (typeof this.config.data.reqId !== 'undefined') {
          this.reqId = this.config.data.reqId;
          let jobStatusArr: any = this.config.data.jobStatus.split(',');
          jobStatusArr.forEach(element => {
            this.selectedJobStatus.push({ id: element.trim(), name: element.trim() });
          });
        }
      }
    } else {
      if (this.reqId) {
        this.companyId = this.reqId;
      }
    } */
    await this.loadAllJobsList();
    this.cols = [
      { field: 'JobNo', header: 'Job Number' },
      { field: 'JobDate', header: 'Job Entry' },
      { field: 'SubStatus', header: 'Recent Update' },
      { field: 'FullAddress', header: 'Site Address' },
      { field: 'JobStatus', header: 'Job Status' },
    ];
    this.exportColumns = this.cols.map(col => ({ title: col.header, dataKey: col.field }));
    this.exportdisplay = await this.commonservice.exportCheck();
  }
  exportExcel(selectedDepots, table) {
    let JsonData: any[] = [];
    if (this.selectedjob != null && this.selectedjob.length > 0) {
      JsonData = this.selectedjob;
    } else {//console.log(table.filteredValue,'checkthis');
      if (typeof table.filteredValue !== 'undefined') {
        if (table.filteredValue.length !== this.jobList.length && table.filteredValue.length > 0) {
          JsonData = table.filteredValue;
        } else {
          JsonData = this.jobList;
        }
      } else {
        JsonData = this.jobList;
      }
    }


    let reformattedArray = JsonData.map(obj => {

      let rObj = {};
      this.cols.forEach(pair => {
        rObj[pair.header.toString()] = obj[pair.field];
      });
      return rObj;
    })
    //console.log(reformattedArray);

    import('xlsx').then(xlsx => {
      const worksheet = xlsx.utils.json_to_sheet(reformattedArray);
      const workbook = { Sheets: { data: worksheet }, SheetNames: ['data'] };
      const excelBuffer: any = xlsx.write(workbook, { bookType: 'xlsx', type: 'array' });
      this.saveAsExcelFile(excelBuffer, 'Joblist');
    });
  }

  saveAsExcelFile(buffer: any, fileName: string): void {
    import('file-saver').then(FileSaver => {
      const EXCEL_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
      const EXCEL_EXTENSION = '.xlsx';
      const data: Blob = new Blob([buffer], {
        type: EXCEL_TYPE
      });
      FileSaver.saveAs(data, fileName + '_export_' + new Date().getTime() + EXCEL_EXTENSION);
    });
  }
  exportPdf() {
    import('jspdf').then(jsPDF => {
      import('jspdf-autotable').then(x => {
        /* const doc = new jsPDF.default(0,0);
        if(typeof this.selectedDepots === 'undefined'){
            doc.autoTable(this.exportColumns, this.depots);
           }else{
               doc.autoTable(this.exportColumns, this.selectedDepots);
        }
       doc.save('products.pdf'); */
      })
    })
  }

  async viewJobAction(job) {
    this.loading = true;
    if (job.JobStatus === 'Cost Saved' || job.JobStatus === 'Approve Quote' || job.JobStatus === 'Await Cost' || job.JobStatus === 'Quote Rejected' || job.JobStatus === 'Invoice Submitted') {
      const smryData: any = await this.crudService.getJobSummary(job.JobId, job.AllocationId).toPromise();
      const jobAllocationResults = await this.JobService.getJobInfo(smryData[0]);
      const viewType = 'Quote';
      this.loading = false;
      const ref = this.dialogService.open(JobDetailsApprovalsComponent, {
        data: {
          jobId: job.JobId,
          allocId: job.AllocationId,
          jobSummary: jobAllocationResults,
          viewType: viewType
        },
        width: '100%',
        height: '100%'
      })
      ref.onClose.subscribe((response: any) => {
        this.loadAllJobsList();
      });
    } else if (job.JobStatus === 'Approve Invoice' || job.JobStatus === 'Await Invoice' || job.JobStatus === 'Accounts Processed' || job.JobStatus === 'Invoice Processed' || job.JobStatus === 'Accounts Proecessed' || job.JobStatus === 'Job Completed') {
      const smryData: any = await this.crudService.getJobSummary(job.JobId, job.AllocationId).toPromise();
      const jobAllocationResults = await this.JobService.getJobInfo(smryData[0]);
      const viewType = 'Approval';
      this.loading = false;
      const ref = this.dialogService.open(JobDetailsApprovalsComponent, {
        data: {
          jobId: job.JobId,
          allocId: job.AllocationId,
          jobSummary: jobAllocationResults,
          viewType: viewType
        },
        width: '100%',
        height: '100%'
      })
      ref.onClose.subscribe((response: any) => {
        this.loadAllJobsList();
      });

    } else if (job.JobStatus === 'Await Acceptance' || job.JobStatus === 'Send Offer') {
      this.loading = false;
      this.editSelectedRecord(job);
    } else if (job.JobStatus === 'Quote Rejected' || job.JobStatus === 'Send Offer') {
      this.loading = false;
      this.editSelectedRecord(job);
    }
  }
  async jobscheduleTab(job, type) {
    let data = await this.crudService.getJobSummary(job.JobId, job.AllocationId).toPromise();
    this.jobSummary = await this.JobService.getJobInfo(data[0]);

    const ref = this.dialogService.open(TpScheduleAdminComponent, {
      data: {
        jobAllocationId: job.AllocationId,
        jobId: job.JobId,
        type: type,
        jobSummary: this.jobSummary
      },
      header: 'Job Schedule',
      width: '100%',
      height: '100%'
    });
    ref.onClose.subscribe((response: any) => {
      this.loadAllJobsList();
    });
  }

  async contractor(job) {
    let data = await this.crudService.getJobSummary(job.JobId, job.AllocationId).toPromise();
    this.jobSummary = await this.JobService.getJobInfo(data[0]);

        const ref = this.dialogService.open(ContractorchannelComponent, {
          data: {
            jobId: job.JobId,
            jobAllocationId: job.AllocationId,
            jobSummary: this.jobSummary
          },
          width: '100%',
          height: '100%'
        })
        ref.onClose.subscribe(async(response: any) => {
          await this.loadAllJobsList();
        });
    }

  async loadImages(job) {
    let data = await this.crudService.getJobSummary(job.JobId, job.AllocationId).toPromise();
    this.jobSummary = await this.JobService.getJobInfo(data[0]);

    const ref = this.dialogService.open(TpJobImagesComponent, {
      data: {
        jobId: job.JobId,
        jobAllocationId: job.AllocationId,
        jobSummary: this.jobSummary
      },
      /* header: 'Images', */
      width: '100%',
      height: '100%'
    });
    ref.onClose.subscribe(async (response: any) => {
      await this.loadAllJobsList();
    });
  }

  async loadDocuments(job) {
    let data = await this.crudService.getJobSummary(job.JobId, job.AllocationId).toPromise();
    this.jobSummary = await this.JobService.getJobInfo(data[0]);

    const ref = this.dialogService.open(TpJobDocumentsComponent, {
      data: {
        jobId: job.JobId,
        jobAllocationId: job.AllocationId,
        jobSummary: this.jobSummary
      },
      /* header: 'Upload Documents', */
      width: '100%',
      height: '100%'
    })
    ref.onClose.subscribe(async (response: any) => {
      await this.loadAllJobsList();
    });
  }

  async loadTreeInfo(job) {
    let data = await this.crudService.getJobSummary(job.JobId, job.AllocationId).toPromise();
    this.jobSummary = await this.JobService.getJobInfo(data[0]);

    //console.log(this.allocId, this.jobId);
    let disableFields = false;
    if (this.jobSummary.JobStatus == 'Approve Invoice') {
      disableFields = true;
    }
    const ref = this.dialogService.open(TreeInfoComponent, {
      data: {
        jobId: job.JobId,
        jobAllocationId: job.AllocationId,
        jobSummary: this.jobSummary,
        disable: disableFields
      },
      /* header: 'Tree Info', */
      width: '100%',
      height: '100%'
    })
    ref.onClose.subscribe(async (response: any) => {
      await this.loadAllJobsList();
    });
  }

  async costingTab(job) {
    let data = await this.crudService.getJobSummary(job.JobId, job.AllocationId).toPromise();
    this.jobSummary = await this.JobService.getJobInfo(data[0]);

    const ref = this.dialogService.open(TpJobCostingComponent, {
      data: {
        jobId: job.JobId,
        jobAllocationId: job.AllocationId,
        jobSummary: this.jobSummary
      },
      /* header: 'Job Costing', */
      width: '100%',
      height: '100%'
    });
    ref.onClose.subscribe(async (response: any) => {
      await this.loadAllJobsList();
    });
  }

  async loadSiteHazard(job) {
    let data = await this.crudService.getJobSummary(job.JobId, job.AllocationId).toPromise();
    this.jobSummary = await this.JobService.getJobInfo(data[0]);

    //console.log(this.allocId, this.jobId);
    const ref = this.dialogService.open(TpShfComponent, {
      data: {
        jobId: job.JobId,
        jobAllocationId: job.AllocationId,
        jobSummary: this.jobSummary
      },
      /* header: 'Site Hazard Form', */
      width: '100%',
      height: '100%'
    })
    ref.onClose.subscribe(async (response: any) => {
      await this.loadAllJobsList();
    });
  }

  async jobInvoiceSend(job) {
    let data = await this.crudService.getJobSummary(job.JobId, job.AllocationId).toPromise();
    this.jobSummary = await this.JobService.getJobInfo(data[0]);

    //console.log(this.allocId, this.jobId);
    const ref = this.dialogService.open(TpJobInvoiceSendComponent, {
      data: {
        jobId: job.JobId,
        jobAllocationId: job.AllocationId,
        jobSummary: this.jobSummary
      },
      /* header: 'Job Invoice Send', */
      width: '100%',
      height: '100%'
    })
    ref.onClose.subscribe(async (response: any) => {
      await this.loadAllJobsList();
    });
  }

  viewJob(job) {
    this.router.navigate(['tp-job/detail', job.JobId, job.AllocationId])
  }
  editSelectedRecord(job) {
    if (this.viewType === 'TM') {
      const ref = this.dialogService.open(TpJobAdminComponent, {
        data: {
          jobId: job.JobId,
        },
        width: '100%',
        height: '100%'
      })
      ref.onClose.subscribe((response: any) => {
        this.loadAllJobsList();
      });
    } else {
      const ref = this.dialogService.open(JobUserComponent, {
        data: {
          JobId: job.JobId,
        },
        header: 'Job Info',
        width: '100%',
        height: '100%'
      })
    }
  }

  customSort(event: SortEvent) {
    
    
    event.data.sort((data1, data2) => {
      const value1 = data1[event.field];
      const value2 = data2[event.field];
      let result = null;

      if (value1 === null && value2 != null)
        result = -1;
      else if (value1 != null && value2 === null)
        result = 1;
      else if (value1 === null && value2 === null)
        result = 0;
      else if (typeof value1 === 'string' && typeof value2 === 'string')
        result = value1.localeCompare(value2);
      else
        result = (value1 < value2) ? -1 : (value1 > value2) ? 1 : 0;

      return (event.order * result);
    });
  }

  stringToDate(_date, _format, _delimiter) {
    const formatCancellederCase = _format.toCancellederCase();
    const formatItems = formatCancellederCase.split(_delimiter);
    const dateItems = _date.split(_delimiter);
    const monthIndex = formatItems.indexOf('mm');
    const dayIndex = formatItems.indexOf('dd');
    const yearIndex = formatItems.indexOf('yyyy');
    let month = parseInt(dateItems[monthIndex]);
    month -= 1;
    const formatedDate = new Date(dateItems[yearIndex], month, dateItems[dayIndex]);
    return formatedDate;
  }

  async acceptJob(job_allocation) {
    let jobAllocationData: any = await this.crudService.getDataByField('JobAllocation', 'id', job_allocation.AllocationId).toPromise();
    let jobAllocation = jobAllocationData[0];
    this.confirmationService.confirm({
      message: 'Are you sure you want to Accept?',
      header: 'Confirm',
      icon: 'pi pi-exclamation-triangle',
      accept: () => {
        // tslint:disable-next-line: prefer-for-of
        jobAllocation.accept_status = 1;
        this.crudService.Update(jobAllocation, 'JobAllocation').subscribe((data: any[]) => {
          this.JobService.recordJobActions(jobAllocation.job_Id, jobAllocation.id, 'Await acceptance', 'TP to accept', 'Job Accepted', 2, 'Job Accepted by ' + this.userDetails.user_id + ' on behalf of Contractor', null, null);
          this.messageService.add({ severity: 'success', summary: 'Successful', detail: 'Job Allocation Accepted', life: 1000 });
        });
      }
    });
  }
  onEdit() {
    window.scrollTo(0, 0);
  }

  async rejectJob(job_allocation) {
    let jobAllocationData: any = await this.crudService.getDataByField('JobAllocation', 'id', job_allocation.AllocationId).toPromise();
    let jobAllocation = jobAllocationData[0]; this.confirmationService.confirm({
      message: 'Are you sure you want to Reject?',
      header: 'Confirm',
      icon: 'pi pi-exclamation-triangle',
      accept: () => {
        // tslint:disable-next-line: prefer-for-of
        jobAllocation.allocation_status = 2;
        jobAllocation.accept_message = null;
        jobAllocation.accept_status = null;
        this.crudService.Update(jobAllocation, 'JobAllocation').subscribe((data: any[]) => {
          this.messageService.add({ severity: 'success', summary: 'Successful', detail: 'Job Allocation Rejected', life: 1000 });
        });
        let index = this.jobList.findIndex(element => element.JobId === job_allocation.JobId);
        this.jobList.splice(index, 1);
      }
    });
  }
}