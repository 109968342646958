import { Component, OnInit, Input, OnDestroy, Output, EventEmitter } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { ConfirmationService, MessageService } from 'primeng/api';
import { CRUDService } from 'src/app/utilities/crud.service';
import { DialogService, DynamicDialogConfig, DynamicDialogRef} from 'primeng/dynamicdialog';
import { JOBService } from 'src/app/utilities/job.service';
import { DomSanitizer } from '@angular/platform-browser';
import { CommonService } from 'src/app/utilities/common.service';
import {JobScheduleCreateService} from './job-schedule-create.service'
@Component({
  selector: 'app-job-schedule-create',
  templateUrl: './job-schedule-create.component.html',
  styleUrls: ['./job-schedule-create.component.css'],
  providers: [ MessageService, ConfirmationService]
})
export class JobScheduleCreateComponent implements OnInit, OnDestroy {
  
  @Input() job_id: any;
  @Input() job_alloc_id: any;
  jobschedule:any
  visitType:any[];
  communicationType: any[];
  attachmentType:any[];
  selectedAttachmentType:any[];
  scheduleForm: FormGroup;
  selectedVisitType: any;
  isEmail: boolean = false;
  isSMS: boolean = false;
  isPhoned:boolean=false;
  submitted: boolean = false;
  createSchedule: any = false
  scheduleJob: any;
  selectedcommunicationType: any[];
  currentUser: any;
  menuData: any;
  viewType: any;
  appProps: any;
  @Output() jobSchedule = new EventEmitter();
 
  constructor(

    private route: ActivatedRoute,
    private crudService: CRUDService,
    private messageService: MessageService,
    private confirmationService: ConfirmationService,
    private router: Router,
    public ref: DynamicDialogRef,
    public config: DynamicDialogConfig,
    public dialogService: DialogService,
    public JobService: JOBService,
    public sanitizer: DomSanitizer,
    public dialogRef: DynamicDialogRef,
    public JobScheduleCreateService:JobScheduleCreateService,
    private commonservice: CommonService
   ) 
   { }

  ngOnDestroy(){
    this.config.data = {};
  }
  ngOnInit() {
   // //console.log( JSON.parse(sessionStorage.getItem('menuData')))
    //console.log(this.job_alloc_id,this.job_id);
    //console.log(this.config.data.jobId);
    this.appProps = this.commonservice.getAppProperties();
    this.currentUser = JSON.parse(sessionStorage.getItem('currentUser'));
    if (typeof this.config.data == 'undefined' || typeof (this.config.data.job_id) == 'undefined') {
    } 
    else {
      this.job_id = this.config.data.job_id;
      this.job_alloc_id = this.config.data.job_alloc_id;
    }
    //console.log(this.job_alloc_id,this.job_id);
     this.visitType = [
      { id: '1', title: 'Site Inspection' },
      { id: '2', title: 'Make Safe' },
      { id: '3', title: 'Perform Works' }
    ]
    this.communicationType = [
      { id: '1', title: 'Phoned' },
      { id: '2', title: 'SMS' },
      { id: '3', title: 'Email' },
      { id: '4', title: 'Call Back' },
    ]
    this.attachmentType= [
      {id:'1', title:'Work Hazard Form'},
      {id:'2', title:'Purchase Order'}
    ]

    this.scheduleForm = new FormGroup({
      schednote: new FormControl(''),
    })
 
    this.scheduleJob = {
      PMOnly: null,
      action_required: null,
      callback: "1",
      created_at: new Date(),
      created_by: null,
      email: "1",
      emailaddress: null,
      end_time: null,
      id: null,
      job_alloc_id: this.job_alloc_id?this.job_alloc_id:'1',
      job_id: this.job_id,
      last_modified_by: null,
      last_updated_at: null,
      owner: null,
      phone_no: null,
      phoned: "1",
      process_id: this.currentUser.company,
      sched_date: null,
      sched_note: null,
      sms: "1",
      sms_no: null,
      start_time: null,
      status: "1",
      username: this.currentUser.username,
      visit_type: null,
      source: null,
      message_received: null,
      message_flow: null,
      comm_recipient: null,
      comm_recipient_subcatg: null
    }

  }

  saveRecord() {
    this.submitted = true;
    //console.log(this.scheduleForm);

    //console.log(this.scheduleJob)
    if (this.selectedcommunicationType === undefined) {
      //console.log('no communication');
      this.selectedcommunicationType = null;
    }
    this.selectedcommunicationType.forEach((type:any)=>{
      if (type !== null) {
        if (type.id === "1") {
          this.scheduleJob.phoned = "2"
        }
        if (type.id === "2") {
          this.scheduleJob.sms = "2"
        }
        if (type === "3") {
          this.scheduleJob.email = "2"
        }
        if (type === "4") {
          this.scheduleJob.callback = "2"
        }
      }
    })




    if (this.scheduleJob.end_time) {
      this.scheduleJob.end_time = this.scheduleJob.end_time.toString().split(" ")[4];
    }
    if (this.scheduleJob.start_time) {
      this.scheduleJob.start_time = this.scheduleJob.start_time.toString().split(" ")[4];

    }

    //console.log(this.scheduleJob.start_time);

    if (this.scheduleForm.status === "VALID") {
      this.crudService.Create(this.scheduleJob, 'JobSchedule').subscribe((data: any) => {
        //console.log(data);
        if (data.success == 1) {
          this.messageService.add({ severity: 'success', summary: 'Successful', detail: ' Job Scheduled', life: 1000 });
          //console.log(data);
          this.scheduleJob.id = data.id;
          this.messageService.add({ severity: 'success', summary: 'Successful', detail: 'Job Scheduled .', life: 1000 });
          this.JobScheduleCreateService.jobDetailsList(this.scheduleJob)
          this.isEmail = false;
          this.isSMS = false;
          this.scheduleJob = {}
          if (this.jobschedule === true) {
            this.scheduleJob = {
              PMOnly: null,
              action_required: null,
              callback: "1",
              created_at: new Date(),
              created_by: this.currentUser.id,
              email: "1",
              emailaddress: null,
              end_time: null,
              id: null,
              job_alloc_id: this.job_alloc_id?this.job_alloc_id:'1',
              job_id: this.job_id,
              last_modified_by: null,
              last_updated_at: null,
              owner: null,
              phone_no: null,
              phoned: "1",
              process_id: this.currentUser.company,
              sched_date: null,
              sched_note: null,
              sms: "1",
              sms_no: null,
              start_time: null,
              status: "1",
              username: this.currentUser.username,
              visit_type: null,
              source: null,
              message_received: null,
              message_flow: null,
              comm_recipient: null,
              comm_recipient_subcatg: null
            }
            //console.log(this.scheduleJob);
          }
          this.dialogRef.close()
        }
        else {
          this.messageService.add({ severity: 'error', summary: 'Error', detail: 'Job Not Scheduled  ', life: 1000 });
        }

      })
    }

  }

  onCommunicationChange(value) {
    //console.log(value);
    if (value == 2) {
      this.isSMS = true;
      this.isEmail = false;
      //console.log(this.isSMS)
    }
    if (value == 3) {
      this.isEmail = true;
      this.isSMS = false;
      //console.log(this.isEmail);
    }
    if( value == 1){
      this.isPhoned=true;
    }
  }

}
