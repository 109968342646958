import { Component, OnInit, Input, OnChanges } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ConfirmationService, MessageService } from 'primeng/api';
import { CRUDService } from 'src/app/utilities/crud.service';
import { job, Client, Contacts } from './job-user'
import { Validators, FormControl, FormGroup, FormBuilder } from '@angular/forms';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';

@Component({
  selector: 'app-job-user',
  templateUrl: './job-user.component.html',
  styleUrls: ['./job-user.component.css'],
  providers: [MessageService, ConfirmationService]
})
export class JobUserComponent implements OnInit {
  @Input() jobId: any;
  jobDetails: any;
  selectedCategory: any;
  companyId: any;
  jobDetailsDialog: boolean = false;
  clientsList: any[];
  selectedClient: any
  contactsList: any[];
  selectedContact: any;
  showContactBox: boolean;
  jobEditForm: FormGroup;
  currentUser: any;
  submitted: boolean;
  no_of_trees: any;
  isCouncilApprovalReqd: boolean = false;
  tree_perc_on_insured: any;
  component_info: any;
  component_condition: any;
  job_category: any;

  constructor(
    private route: ActivatedRoute,
    private crudService: CRUDService,
    private messageService: MessageService,
    private confirmationService: ConfirmationService,
    private router: Router,
    public ref: DynamicDialogRef,
    public config: DynamicDialogConfig
  ) {
    this.router.routeReuseStrategy.shouldReuseRoute = () => false;
  }


  componentForm = {
    street_number: 'short_name',
    route: 'long_name',
    locality: 'long_name',
    sublocality_level_1: 'long_name',
    administrative_area_level_1: 'short_name',
    country: 'long_name',
    postal_code: 'short_name'
  };
  options = {
    componentRestrictions: {
      country: ['AU']
    }
  }


  ngOnInit() {
    this.no_of_trees = 1;
    this.isCouncilApprovalReqd = false;
    this.tree_perc_on_insured = 10;
    this.component_info = '';
    this.component_condition = '';
    this.job_category = '';
    this.selectedClient = {};
    this.selectedContact = {};
    this.companyId = sessionStorage.getItem('company_id');
    this.currentUser = JSON.parse(sessionStorage.getItem('currentUser'));
    if (typeof this.config.data == 'undefined' || typeof (this.config.data.JobId) == 'undefined') {
      this.jobId = parseInt(this.route.snapshot.paramMap.get('JobId'));
    } else {
      this.jobId = this.config.data.JobId;
    }
    //console.log(this.jobId);
    if (this.jobId !== 0 && this.jobId != null) {

      this.jobEditWithJobId();

    } else if (this.jobId === 0) {
      this.showContactBox = false;
      this.selectedCategory = '2'
      this.jobId = null;
      this.jobDetailsDialog = true;
      this.jobDetails = {
        client_category: "2",
        contact_type_id: null,
        council_approval_reqd: null,
        created_at: null,
        created_by: null,
        id: null,
        job_act_dt: null,
        job_claim_no: null,
        job_company_id: null,
        job_contact_alt: null,
        job_contact_best: null,
        job_contact_id: null,
        job_contact_name: null,
        job_depot_id: null,
        job_desc: null,
        job_event_id: null,
        job_no: null,
        job_priority_id: null,
        job_provider_ref: null,
        job_recd_dt: null,
        job_ref_id: null,
        job_req_action_id: null,
        job_req_id: null,
        job_sp_desc: null,
        job_type: null,
        last_updated_at: null,
        old_job_no: null,
        process_id: this.companyId,
        risk_addl_address: null,
        risk_country_id: null,
        risk_lat: null,
        risk_lng: null,
        risk_postcode: null,
        risk_state_id: null,
        risk_street_address: null,
        risk_street_address_2: null,
        risk_suburb: null,
        status: null,
        status_reason: null,
        status_reason_other: null,
        status_reopen_dt: null,
        wf_status_id: "1",
      }

    }
    this.submitted = false;

    this.jobEditForm = new FormGroup({
      client: new FormControl(''),
      contact: new FormControl(''),
      job_contact_name: new FormControl('', Validators.required),
      job_contact_best: new FormControl('', Validators.required),
      job_contact_alt: new FormControl('', Validators.required),
      job_desc: new FormControl('', Validators.required),
      job_sp_desc: new FormControl('', Validators.required),
    })
    this.getClientsList();
  }

  saveRecord() {
    this.submitted = true;

    if (this.selectedCategory !== '') {
      this.jobDetails.client_category = this.selectedCategory;
    } else {
      this.jobDetails.client_category = '2';
    }
    //console.log(this.jobEditForm.status);
    if (this.jobEditForm.status === 'VALID') {
      if (this.jobDetails.id) {
        //console.log(this.jobDetails);
        this.crudService.Update(this.jobDetails, 'JobInfo').subscribe((data: any) => {
          //console.log(data);
          if (data.success === '1' || data.success === 1) {
            this.messageService.add({ severity: 'success', summary: 'Successful', detail: 'job Updated', life: 1000 });
            this.router.navigate(['/Job-operations']);
          }
        })
      } else {
        this.crudService.getLastJobNoTP(this.companyId).subscribe((data: any[]) => {
          //console.log(data);
          this.jobDetails.job_no = parseInt(data[0].job_no) + 1;
          this.jobDetails.status = 1;
          this.jobDetails.wf_status_id = 1;
          this.jobDetails.job_company_id = this.selectedClient.id;
          this.jobDetails.process_id = this.companyId;
          this.jobDetails.job_type = '2';
          this.jobDetails.job_recd_dt = new Date();
          this.jobDetails.job_act_dt = new Date();
          if (this.isCouncilApprovalReqd) {
            this.jobDetails.council_approval_reqd = '1';
          } else {
            this.jobDetails.council_approval_reqd = '2';
          }
          this.crudService.Create(this.jobDetails, 'JobInfo').subscribe((resData: any) => {
            if (resData.success === '1' || resData.success === 1) {
              if (resData.success == 1) {
                this.jobDetails.id = resData.id;
                let orderInfo = {
                  id: null,
                  job_id: this.jobDetails.id,
                  job_no: null,
                  no_of_trees: this.no_of_trees,
                  component_info: this.component_info,
                  component_condition: this.component_condition,
                  job_category: this.job_category,
                  council_approval: this.jobDetails.council_approval_reqd,
                  tree_perc_on_insured: this.tree_perc_on_insured
                }

                let job_alloc = {
                  id: null,
                  job_id: this.jobDetails.id,
                  depot_id: null, //this.depot_id2,
                  contractor_id: this.companyId,
                  allocation_status: 1,
                  allocation_message: "allocated",
                  accept_status: '1',
                  accept_message: null,
                  driving_time: null,
                  tp_invoiced: null,
                  wp_invoiced: null,
                  source: '1'
                };
                this.crudService.Create(orderInfo, 'WorkOrderInfo').subscribe((workOrderData: any) => {
                  //console.log('work order info saved');
                  //console.log(workOrderData);
                });
                this.crudService.Create(job_alloc, 'JobAllocation').subscribe((jobAllocData: any) => {
                  //console.log('jobAllocData info saved');
                  //console.log(jobAllocData);
                });
                this.messageService.add({ severity: 'success', summary: 'Successful', detail: 'Job Created', life: 1000 });
                //console.log(this.jobDetails)
                this.jobId = this.jobDetails.id;

                if (this.jobId !== 0 && this.jobId != null) {
                  //console.log(this.jobId);
                  //this.jobEditWithJobId();

                }

              } else {
                this.messageService.add({ severity: 'error', summary: 'Error', detail: 'Job Creation Failed', life: 1000 });
              }
            }
          });
        });
      }
    }
  }

  changeClient_Category() {
    //console.log(this.selectedCategory);
    if (this.selectedCategory === '2') {


      this.jobDetails.client_category = '2';
    } else {
      this.jobDetails.client_category = '1';

    }
  }


  getClientsList() {
    this.crudService.getByCompanyWPTP('6').subscribe((data: any[]) => {
      this.clientsList = data;
      if (this.selectedClient.id !== '' && this.selectedClient.id != null) {
        this.getContactsList(this.selectedClient.id);
      } else if (this.clientsList.length > 0) {
        this.getContactsList(this.clientsList[0].id);
      }
    });
  }

  getContactsList(clientID) {
    this.crudService.getContactsByCID(clientID).subscribe((data: any[]) => {
      this.contactsList = data;
    });
  }

  onClientChange(event) {
    this.selectedClient = event.value;
    this.getContactsList(this.selectedClient.id);
    this.selectedContact = {};
    this.jobDetails.job_company_id = this.selectedClient.id;
    this.jobDetails.job_contact_id = '';
    this.jobDetails.job_contact_name = '';
    this.jobDetails.job_contact_alt = '';
    this.jobDetails.job_contact_best = '';
  }

  cancel() {
    this.router.navigate(['/Job-operations'])
  }

  onContactChange(event) {
    this.selectedContact = event.value;
    this.jobDetails.job_contact_name = this.selectedContact.name;
    this.jobDetails.job_contact_alt = this.selectedContact.email;
    this.jobDetails.job_contact_best = this.selectedContact.phone;
    this.jobDetails.job_contact_id = this.selectedContact.id;
  }


  jobEditWithJobId() {

    this.showContactBox = true
    this.jobDetailsDialog = true
    this.crudService.getDataByField('jobInfo', 'id', this.jobId).subscribe((JobInfoData: any[]) => {
      //console.log(this.jobDetails);
      this.jobDetails = JobInfoData[0];
      //console.log(this.jobDetails);
      if (this.jobDetails.client_category === '1') {
        this.selectedCategory = '1';
      } else if (this.jobDetails.client_category === '2') {
        this.selectedCategory = '2';
      } else {
        this.selectedCategory = '2';
      }
      if (typeof this.jobDetails.client_category == undefined) {
        this.selectedCategory = '2';
      } else {
        if (this.jobDetails.client_category === '1') {
          this.selectedCategory = '1';
        } else {
          this.selectedCategory = '2';
        }
      }
      //console.log('jobDetails', this.jobDetails);
      if (this.jobDetails.job_company_id !== null && this.jobDetails.job_company_id !== '') {
        this.crudService.getClientTPByID(this.jobDetails.job_company_id).subscribe((clientData: any[]) => {
          this.selectedClient = clientData[0];
          this.getClientsList();
          if (this.jobDetails.job_contact_id !== null && this.jobDetails.job_contact_id !== '') {
            this.crudService.getContactInfoByID(this.jobDetails.job_contact_id).subscribe((contactData: any[]) => {
              this.selectedContact = contactData[0];
            });
          }
        });
      }
    });

  }

}
