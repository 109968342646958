import { Component, OnInit, Input } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { CRUDService } from 'src/app/utilities/crud.service';
import { ConfirmationService } from 'primeng/api';
import { MessageService } from 'primeng/api';
import { Table } from 'primeng/table';
import { CommonService } from 'src/app/utilities/common.service';
import { environment } from 'src/environments/environment';
import { DynamicDialogConfig, DialogService } from 'primeng/dynamicdialog';
import { ActivatedRoute, Router } from '@angular/router';
import { MenuItem } from 'primeng/api';
import { AppMasterComponent } from '../master/master.component'

@Component({
  selector: 'app-master-child',
  templateUrl: './master-child.component.html',
  styleUrls: ['./master-child.component.scss']
})
export class MasterChildComponent implements OnInit {
  loading: boolean = false;
  paginationvalue: any;
  cols: any[];
  exportColumns: any[];
  appConfigDialog: boolean = false;
  submitted: boolean;
  appconfigList: any[];
  jobPriority: any;
  appConfig: any;
  selectedJobPriority: any[];
  selectedAppConfig: any[];
  companyList: any[];
  isActive: boolean;
  selectedConfig: any;
  speedDial: boolean = false;
  isEditAll: boolean = false;
  exportdisplay: Boolean = false;
  detailData: any;
  public inputFields: any[] = [];
  public inputFieldsarray: any[] = [];
  public inputFieldsTemp: any[] = [];
  @Input() moduleName: any;

  @Input() ChildCode: any; //For Child View	
  @Input() mapId: any;
  @Input() parentCode: any;
  @Input() fieldsId: any;
  @Input() loadChildData: any;

  @Input() sample: any;

  parentKey: any;
  childKey: any;
  parentkeyValue: any;
  childkeyValue: any;
  inputValue: any;

  viewType: any; // Master Or Child

  @Input() setupData: any;
  appconfigid: string;
  fieldId: any;
  edit: boolean;
  submitFlag: Boolean = false;
  inputtt: boolean = true;
  createmode: any;
  childData: any[];
  showChild: boolean;
  pageTitle: any;
  items: MenuItem[];
  fieldId1: any;
  childdata1: any;
  getchilddata1: any;
  editmasterfields: boolean = true;
  childeditDialog: any;
  formOptions: any;
  closeVideo: boolean = true;
  selectItem: any;
  constructor(private crudService: CRUDService,
    private messageService: MessageService,
    private confirmationService: ConfirmationService,
    private commonservice: CommonService,
    private dialogService: DialogService,
    public config: DynamicDialogConfig,
    private activatedroute: ActivatedRoute,
    private router: Router,) {
    this.form = new FormGroup({
      fields: new FormControl(JSON.stringify(this.inputFields))
    })
    this.unsubcribe = this.form.valueChanges.subscribe((update) => {
      console.log(update);
      this.inputFields = JSON.parse(update.fields);
    });
    console.log('this.loadChildData', this.sample);
    if (this.config && this.config.data) {
      // this.moduleName = this.config.data.code;
      // this.createmode = this.config.data.create_mode;
      this.ChildCode = this.config.data.child_code;
      this.mapId = this.config.data.map_id;
      this.parentCode = this.config.data.parent_code;
      this.fieldsId = this.config.data.fieldId;
      this.moduleName = this.config.data.child_code;
      this.viewType = 'Child';
    }
    else if (this.loadChildData) {
      this.ChildCode = this.loadChildData.child_code;
      this.mapId = this.loadChildData.map_id;
      this.parentCode = this.loadChildData.parent_code;
      this.fieldsId = this.loadChildData.fieldId;
      this.moduleName = this.loadChildData.child_code;
      this.viewType = 'Child';
    }
    else {
      let menuData = JSON.parse(sessionStorage.getItem('menuData'));
      this.moduleName = menuData.para_1;
      //sessionStorage.setItem('menuData', '');
      //this.moduleName = sessionStorage.getItem('ModuleName');
      //this.router.getCurrentNavigation().extras.state.para_1;
      this.viewType = 'Master';
    }


  }
  ngOnChanges() {
    this.ngOnInit();
    console.log('new childdata', this.viewType);
  }

  async ngOnInit(): Promise<void> {
    this.loading = true;
    this.paginationvalue = environment.paginatorValue;
    if (this.viewType == 'Master') {
      this.loadMaster();
    }
    else if (this.viewType == 'Child') {
      this.loadChild();
    }
    this.loading = false;
  }
  async loadChild() {

    this.crudService.getDataByField('ComponentDefnMapping', 'parent_code', this.moduleName).subscribe((data: any[]) => {
      this.childData = data;
    });
    let mdata: any = await this.crudService.getDataByField('ComponentDefnHead', 'code', this.moduleName).toPromise();;
    this.pageTitle = mdata[0].title;
    let cdata: any = await this.crudService.getDataByField('ComponentDefnFieldMapping', 'cdm_id', this.mapId).toPromise();;
    this.parentKey = cdata[0].pkey_field_name;
    this.childKey = cdata[0].ckey_field_name;
    debugger
    console.log("Map Type",cdata[0].map_type);
    let data: any = await this.crudService.getDataByField(this.parentCode, 'id', this.fieldsId).toPromise();;
    this.parentkeyValue = data[0]['' + this.parentKey + ''];
    this.paginationvalue = environment.paginatorValue;
    this.crudService.getDataByField(this.moduleName, this.childKey, this.parentkeyValue).subscribe((data: any[]) => {
      this.appconfigList = data;
    });
    this.crudService.getDataByField('ComponentDefnDetail', 'component_code', this.moduleName).subscribe((data: any[]) => {
      this.detailData = data.sort((a, b) => a.seq.localeCompare(b.seq));
      this.detailData.forEach(w => {
        this.inputFields.push({
          type: w.field_type.trim(),
          name: w.field_name,
          label: w.helptext,
          value: '',
          required: true,
        })
      });
      let fieldsCtrls = {};
      for (let f of this.inputFields) {
        if (f.type != 'checkbox') {
          fieldsCtrls[f.name] = new FormControl(f.value || '', Validators.required)
        }
        else {
          let opts = {};
          for (let opt of f.options) {
            opts[opt.key] = new FormControl(opt.value);
          }
          fieldsCtrls[f.name] = new FormGroup(opts)
        }
      }
      this.form = new FormGroup(fieldsCtrls);
    });
  }
  async loadMaster() {
    let mdata: any = await this.crudService.getDataByField('ComponentDefnHead', 'code', this.moduleName).toPromise();;
    this.pageTitle = mdata[0].title;

    this.crudService.getDataByField('ComponentDefnMapping', 'parent_code', this.moduleName).subscribe((data: any[]) => {
      this.childData = data;
      this.childData.forEach(C => { C.label = C.child_code; C.command = (event) => { this.selectedchild(C); } });
    });
    this.crudService.getDataByField('ComponentDefnDetail', 'component_code', this.moduleName).subscribe((data: any[]) => {
      this.detailData = data.sort((a, b) => a.seq.localeCompare(b.seq));
      this.detailData.forEach(w => {
        this.inputFields.push({
          type: w.field_type.trim(),
          name: w.field_name,
          label: w.helptext,
          value: '',
          required: true,
        })
        this.crudService.getAllData(this.moduleName).subscribe((data: any[]) => {
          this.appconfigList = data;
        });
      });
      let fieldsCtrls = {};
      for (let f of this.inputFields) {
        if (f.type != 'checkbox') {
          fieldsCtrls[f.name] = new FormControl(f.value || '', Validators.required)
        } else {
          let opts = {};
          for (let opt of f.options) {
            opts[opt.key] = new FormControl(opt.value);
          }
          fieldsCtrls[f.name] = new FormGroup(opts)
        }
      }
      this.form = new FormGroup(fieldsCtrls);
    });
  }


  selectchild1(fieldId) {
    this.fieldId1 = fieldId
    console.log('fieldId', fieldId);
  }
  selectedchild(data) {
    console.log('event', data);
    let ChildData = {
      child_code: data.child_code,
      map_id: data.id,
      parent_code: this.moduleName,
      fieldId: this.fieldId1
    };
    console.log('ChildData', ChildData);
    const ref = this.dialogService.open(AppMasterComponent, {
      data: ChildData,
      header: 'ViewChild',
      width: '100%',
      height: '100%',
    });
    ref.onClose.subscribe(async (response: any) => {
    });
  }
  viewChild2(child_code, map_id, fieldId, i) {
    this.childdata1 = {
      child_code: child_code,
      map_id: map_id,
      parent_code: this.moduleName,
      fieldId: this.fieldId
    };
    this.selectItem = i
    this.getchilddata1 = true
    this.editmasterfields = false
    console.log('this.childdata1', this.childdata1);
  }
  getchilddata() {
    return this.childdata1;
  }

  viewChild(child_code, map_id, fieldId) {
    debugger
    let ChildData = {
      child_code: child_code,
      map_id: map_id,
      parent_code: this.moduleName,
      fieldId: fieldId
    };
    const ref = this.dialogService.open(AppMasterComponent, {
      data: ChildData,
      header: 'ViewChild',
      width: '100%',
      height: '100%',
    });
    ref.onClose.subscribe(async (response: any) => {

    });
  }

  openNew() {
    this.edit = false
    this.inputFieldsTemp = [];
    this.detailData.forEach(w => {
      if (this.viewType == 'Master') {
        this.inputValue = '';
      }
      else {
        if (w.field_name == this.childKey) {
          this.inputValue = this.parentkeyValue;
        }
        else {
          this.inputValue = '';
        }
      }

      if (w.options.length != '0') {
        this.formOptions = JSON.parse(w.options);
      }
      else {
        this.formOptions = '[]';
      }
      this.inputFieldsTemp.push({
        type: w.field_type.trim(),
        name: w.field_name,
        label: w.helptext,
        value: this.inputValue,
        required: true,
        show:true,
        options: this.formOptions,
        display_type: w.display_type,
        component_style: w.component_style
      })
    });
    this.inputFields = this.inputFieldsTemp
    this.appConfigDialog = true;
  }

  async onSubmit(evt) {
    console.log('evt,evt', evt);
    let response: any = await this.commonservice.saveRecord(this.moduleName, evt);
    if (response.success == true) {
      this.messageService.add({ severity: 'success', summary: 'Successful', detail: ' Created successfully', life: 1000 });
      this.appconfigList.unshift(evt);
    }
    else {
      this.messageService.add({ severity: 'info', summary: 'Error', detail: 'Something went wrong', life: 1000 });
    }
  }
  async saveData(evt) {
    debugger
    if (this.edit) {
      evt.id = this.fieldId;
      let response: any = await this.commonservice.saveRecord(this.moduleName, evt);
      if (response.success == true) {
        this.messageService.add({ severity: 'success', summary: 'Successful', detail: ' Updated successfully', life: 1000 });
        this.appconfigList[this.findIndexById(evt.id)] = evt;
        this.appConfigDialog = false;
      }
      else {
        this.messageService.add({ severity: 'info', summary: 'Error', detail: 'Something went wrong', life: 1000 });
      }
    }
    else {
      let response: any = await this.commonservice.saveRecord(this.moduleName, evt);
      if (response.success == true) {
        this.messageService.add({ severity: 'success', summary: 'Successful', detail: ' Created successfully', life: 1000 });
        this.appconfigList.push(evt);
        this.appConfigDialog = false;
        // this.ngOnInit()
      }
      else {
        this.messageService.add({ severity: 'info', summary: 'Error', detail: 'Something went wrong', life: 1000 });
      }
    }

  }

  saveRecord1() {
    let changedRecords = this.appconfigList.filter(val => (val.changed === true));
    changedRecords.forEach(element => {
      this.commonservice.saveRecord(this.moduleName, element);
      element.changed = false;
    });
    this.isEditAll = false;
  }
  childNotifier(val) {
    this.viewChild(val.para1, val.para2, val.para3);
  }

  editRecord(appConfig: any) {
    this.edit = true
    this.inputFieldsTemp = [];
    this.detailData.forEach(w => {
      if (this.viewType == 'Master') {
        this.inputValue = '';
      }
      else {
        if (w.field_name == this.childKey) {
          this.inputValue = this.parentkeyValue;
        }
        else {
          this.inputValue = '';
        }
      }

      if (w.options.length != '0') {
        this.formOptions = JSON.parse(w.options);
      }
      else {
        this.formOptions = '[]';
      }
      this.inputFieldsTemp.push({
        type: w.field_type.trim(),
        name: w.field_name,
        label: w.helptext,
        value: this.inputValue,
        required: true,
        show:true,
        options: this.formOptions,
        display_type: w.display_type,
        component_style: w.component_style
      })
    });
    this.inputFields = this.inputFieldsTemp

    this.fieldId = appConfig.id;
    const data_array = Object.entries(appConfig);
    this.inputFields.forEach(w => {
      data_array.forEach(D => {
        if (w.name == D[0]) w.value = D[1];
      });
    });
    // this.appConfigDialog = true; 
    this.selectItem = -1;
    this.editmasterfields = true;
    this.childeditDialog = true;
    this.enableTabs();
  }

  hidechild() {
    this.childeditDialog = false;
  }

  viewFields(defnHead) {
    const ref = this.dialogService.open(AppMasterComponent, {
      data: defnHead,
      header: 'ViewChild',
      width: '100%',
    });
    ref.onClose.subscribe(async (response: any) => {
    });
  }

  videoClose() {
    this.closeVideo = false;
    let close = document.getElementById('help-video');
    if (close.style.display === 'none') {
      close.style.display = 'block';
    } else {
      close.style.display = 'none';
    }
  }
  enableTabs() {
    setTimeout(() => {
      document.getElementById('help-video-trigger').addEventListener('click', function () {
        document.getElementById('help-video').style.display = 'block';
      })
      document.getElementById('vid-close')?.addEventListener('click', function () {
        document.getElementById('help-video').style.display = 'none';
      })
    }, 1000);
  }

  deleteRecord(appconfigList: any) {
    this.confirmationService.confirm({
      message: 'Are you sure you want to delete  : ' + appconfigList.code + '?',
      header: 'Confirm',
      icon: 'pi pi-exclamation-triangle',
      accept: () => {
        this.appconfigList = this.appconfigList.filter(val => val.id !== appconfigList.id);
        this.crudService.deleteRecordByID(this.moduleName, appconfigList.id).subscribe((data: any[]) => {
          this.messageService.add({ severity: 'success', summary: 'Successful', detail: 'Deleted', life: 1000 });
        });
      }
    });
  }


  deleteSelectedJob() {
    // this.confirmationService.confirm({
    //   message: 'Are you sure you want to delete the selected Appconfig?',
    //   header: 'Confirm',
    //   icon: 'pi pi-exclamation-triangle',
    //   accept: () => {  
    //     for (let i = 0; i < this.selectedAppConfig.length; i++) {
    //       this.crudService.Delete(this.selectedAppConfig[i].id, 'AppConfig').subscribe((data: any[]) => { });
    //     }
    //     this.appconfigList = this.appconfigList.filter(val => !this.selectedAppConfig.includes(val));
    //     this.selectedAppConfig = null;
    //     this.messageService.add({ severity: 'success', summary: 'Successful', detail: 'Appconfig Deleted', life: 1000 });
    //   }
    // });
  }

  deleteJobPriority(appConfig: any) {
    // console.log("deleteJobPriority appConfig", appConfig);
    // this.confirmationService.confirm({
    //   message: 'Are you sure you want to delete Appconfig : ' + appConfig.code + '?',
    //   header: 'Confirm',
    //   icon: 'pi pi-exclamation-triangle',
    //   accept: () => {
    //     this.appconfigList = this.appconfigList.filter(val => val.id !== appConfig.id);
    //     this.appConfig = {};
    //     this.crudService.Delete(appConfig.id, 'AppConfig').subscribe((data: any[]) => {
    //       console.log("deleteresponse,", data);
    //       this.messageService.add({ severity: 'success', summary: 'Successful', detail: 'Appconfig Deleted', life: 1000 });
    //     });
    //   }
    // });
  }

  findIndexById(id: string): number {
    let index = -1;
    for (let i = 0; i < this.appconfigList.length; i++) {
      if (this.appconfigList[i].id === id) {
        index = i;
        break;
      }
    }
    return index;
  }

  hideDialog() {
    this.appConfigDialog = false;
    this.submitted = false;
    this.showChild = false;
  }

  exportPdf() {
    import('jspdf').then(jsPDF => {
      import('jspdf-autotable').then(x => {
        /* const doc = new jsPDF.default(0,0);
        doc.autoTable(this.exportColumns, this.selectedDepots);
        doc.save('products.pdf'); */
      })
    })
  }

  exportExcel(selectedJob) {
    import('xlsx').then(xlsx => {
      const worksheet = xlsx.utils.json_to_sheet(this.selectedJobPriority);
      const workbook = { Sheets: { data: worksheet }, SheetNames: ['data'] };
      const excelBuffer: any = xlsx.write(workbook, { bookType: 'xlsx', type: 'array' });
      this.saveAsExcelFile(excelBuffer, 'JobPriority');
    });
  }

  saveAsExcelFile(buffer: any, fileName: string): void {
    import('file-saver').then(FileSaver => {
      const EXCEL_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
      const EXCEL_EXTENSION = '.xlsx';
      const data: Blob = new Blob([buffer], {
        type: EXCEL_TYPE
      });
      FileSaver.saveAs(data, fileName + '_export_' + new Date().getTime() + EXCEL_EXTENSION);
    });
  }

  clear(table: Table) {
    table.clear();
  }



  public form: FormGroup;
  unsubcribe: any



  public fields: any[] = [
    {
      type: 'text',
      name: 'firstName',
      label: 'First Name',
      value: '',
      required: true,
    },
    {
      type: 'text',
      name: 'lastName',
      label: 'Last Name',
      value: '',
      required: true,
    },
    {
      type: 'text',
      name: 'email',
      label: 'Email',
      value: '',
      required: true,
    },
    {
      type: 'dropdown',
      name: 'country',
      label: 'Country',
      value: 'in',
      required: true,
      options: [
        { key: 'in', label: 'India' },
        { key: 'us', label: 'USA' }
      ]
    },
    {
      type: 'radio',
      name: 'country',
      label: 'Country',
      value: 'in',
      required: true,
      options: [
        { key: 'm', label: 'Male' },
        { key: 'f', label: 'Female' }
      ]
    },
    {
      type: 'checkbox',
      name: 'hobby',
      label: 'Hobby',
      required: true,
      options: [
        { key: 'f', label: 'Fishing' },
        { key: 'c', label: 'Cooking' }
      ]
    }
  ];



  onUpload(e) {
    console.log(e);
  }

  getFields() {
    return this.inputFields;
  }

  ngDistroy() {
    this.unsubcribe();
  }
}
function copy(obj: any): any {
  throw new Error('Function not implemented.');
}

