import { Inject, Injectable } from '@angular/core';
import { Subject, Observable, BehaviorSubject, throwError } from 'rxjs';
import { HttpClient, HttpErrorResponse, HttpEvent, HttpHandler, HttpHeaders, HttpParams, HttpRequest, HttpResponse } from '@angular/common/http';
import { map, delay, catchError, tap } from 'rxjs/operators';
import { HttpInterceptor } from '@angular/common/http';
import * as CryptoJS from 'crypto-js';
import { environment } from 'src/environments/environment';
import { Router } from '@angular/router';
import { AppMainComponent } from '../app.main.component';
import { CommonService } from './common.service';
import { CookieService } from 'ngx-cookie';
import { EncryptionService } from 'src/app/utilities/encryption.service';
import { MessageService } from 'primeng/api';



@Injectable({ providedIn: 'root' })
/* Unccoment to enable encrypt descrypt
export class CRUDService implements HttpInterceptor {
    */
export class CRUDService {
    secretKey = '2a0ab05e';
    private CrudSource = new Subject<string>();
    private resetSource = new Subject();
    CrudSource$ = this.CrudSource.asObservable();
    resetSource$ = this.resetSource.asObservable();
    apiRoot = environment.apiRoot;
    isTest = true;
     token: any = localStorage.getItem('auth_token');
     UserId:any = localStorage.getItem('user_id');
    
    
     
    // token = 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpZCI6IjEiLCJpYXQiOjE1MDY1NjA5ODB9.LAGHEE6tyd9FmZXaK40yDeUkHVZkOl8MdXSrtEcGwB4';
    //pdfcoApikey='elangathir18@gmail.com_344fc7298afbeb901248a85475f0e910b696e590870a7030d16d4501b56b1368cb19db8d';
    pdfcoApikey = 'admin@agileitexperts.com_793e8f7335f11cd51ab1f26d009449541b070d7eba59bd82c1656cc3aa67fbc4e6017166';
    pdfcoEndpoint = 'https://api.pdf.co/v1/pdf/optimize';
    calendlyApiEndPoint = 'https://api.calendly.com/';
    calendlyToken = 'eyJhbGciOiJIUzI1NiJ9.eyJpc3MiOiJodHRwczovL2F1dGguY2FsZW5kbHkuY29tIiwiaWF0IjoxNjUzMDM1NDAxLCJqdGkiOiI4MzJkNDNhMi04MzZhLTRmMzctOTk0Yy01ZjI5NWI5MGI4NmYiLCJ1c2VyX3V1aWQiOiJGRkRGQ0c1UzY1SlFSM0szIn0.Z2XaniyuEc06AjSL9Rh-5o72ODDkLr2BsLeHSjgOZ4E';
    selectedroute : any;
    //calendlyToken = 'eyJraWQiOiIxY2UxZTEzNjE3ZGNmNzY2YjNjZWJjY2Y4ZGM1YmFmYThhNjVlNjg0MDIzZjdjMzJiZTgzNDliMjM4MDEzNWI0IiwidHlwIjoiUEFUIiwiYWxnIjoiRVMyNTYifQ.eyJpc3MiOiJodHRwczovL2F1dGguY2FsZW5kbHkuY29tIiwiaWF0IjoxNjY5Mjg1MTY3LCJqdGkiOiJjYzU0NzYwZS1kNzdiLTQzM2MtYTkyYS01YmVjYTVlMDkyYTkiLCJ1c2VyX3V1aWQiOiIyNGY0MzgxYS02M2JkLTRlM2ItOWUyYy1mOTA3YzU1M2EwNjkifQ.qymeeDrpHI1ElWqjpRaxiBsyBGE0gWwuIYvmYh0zcj7G9fXE5qZ1bU8LMlKdvZxECEsav9dVB16MoQC7Jix21g';
    public app: AppMainComponent
    constructor(
        private http: HttpClient, 
        private router: Router,
        private cookieService: CookieService, 
        private encryptService: EncryptionService,
        private messageService: MessageService
    ) { 

    }
    ngOnInit(): void {
  
    
    }
    /* Unccoment to enable encrypt descrypt
        intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
            if (request.body) {
                request = request.clone({
                    body: {
                        data: this.encrypt(JSON.stringify(request.body))
                    }
                });
            }
            const self = this;
            return next.handle(request).pipe(
                map((event: HttpEvent<any>) => {
                    let data;
                    if (event instanceof HttpResponse) {
                        data = JSON.parse(self.decrypt(event['body']['data']));
                    }
                    event['body'] = data;
                    return event;
                }));
        }
        encrypt(value: string): string {
            return CryptoJS.AES.encrypt(value, this.secretKey.trim()).toString();
        }
    
        decrypt(textToDecrypt: string) {
            //console.log(textToDecrypt)
            return CryptoJS.AES.decrypt(textToDecrypt, this.secretKey.trim()).toString(CryptoJS.enc.Utf8);
        }
        */
    // tslint:disable: max-line-length
    getAPIRoot() {
        return this.apiRoot;
    }
    getAll(moduleName) {
        const headers = new HttpHeaders().set('Authorization', this.token).set('UserId', this.UserId);
        return this.http.get(this.apiRoot + moduleName + '/getAll', { headers }).pipe( tap(() => {}, (err: any) => {
            this.errorHandler(err)
      }));
    }

    getDescByField(moduleName, descField, filterField, filterValue) {
        const headers = new HttpHeaders().set('Authorization', this.token).set('UserId', this.UserId);
        return this.http.get(this.apiRoot + 'Company/getDescriptionValue/', { headers, params: { table_name: moduleName, column_name: descField, field_name: filterField, value: filterValue } }).pipe( tap(() => {}, (err: any) => {
            this.errorHandler(err)
      }));
    }


   

    executeHook(endpoint, hookType, params) {
        const headers = new HttpHeaders().set('Authorization', this.token).set('UserId', this.UserId);
        if (hookType == 'POST') {
            return this.http.post(this.apiRoot + endpoint, { params, }, { headers }).pipe( tap(() => {}, (err: any) => {
                this.errorHandler(err)
          }));
        } else if (hookType == 'GET') {
            return this.http.get(this.apiRoot + endpoint, { headers, params: { params } }).pipe( tap(() => {}, (err: any) => {
                this.errorHandler(err)
          }));
        }
    }

    deleteRecordByID(table_name, value) {
        const headers = new HttpHeaders().set('Authorization', this.token).set('UserId', this.UserId);
        return this.http.post(this.apiRoot + 'crud/deleteRecordByField', { table_name: table_name, field_name: 'id', value: value }, { headers }).pipe( tap(() => {}, (err: any) => {
            this.errorHandler(err)
      }));
    }
    deleteAllRecords(table_name) {
        const headers = new HttpHeaders().set('Authorization', this.token).set('UserId', this.UserId);
        return this.http.post(this.apiRoot + 'crud/deleteAllRecords', { table_name, }, { headers }).pipe( tap(() => {}, (err: any) => {
            this.errorHandler(err)
      }));
    }

    getDataByField(moduleName, filterField, filterValue) {
        const headers = new HttpHeaders().set('Authorization', this.token).set('UserId', this.UserId); 
        return this.http.get(this.apiRoot + 'Company/getDescriptionObject/', { headers, params: { table_name: moduleName, field_name: filterField, value: filterValue } }).pipe( tap(() => {}, (err: any) => {
            this.errorHandler(err)
      }));;
    
      //   return this.http.get(this.apiRoot + 'Company/getDescriptionObject/', { params: { table_name: moduleName, field_name: filterField, value: filterValue } });
}

    getAllData(table_name) {
        const headers = new HttpHeaders().set('Authorization', this.token).set('UserId', this.UserId);
        return this.http.get(this.apiRoot + 'Company/getAllData/', { headers, params: { table_name } }).pipe( tap(() => {}, (err: any) => {
            this.errorHandler(err)
      }));
    }

    createData(moduleName, data) {
        const headers = new HttpHeaders().set('Authorization', this.token).set('UserId', this.UserId);
        return this.http.post(this.apiRoot + 'crud/newRecord', { tableName: moduleName, columns: data }, { headers }).pipe( tap(() => {}, (err: any) => {
            this.errorHandler(err)
      }));
    }

    updateData(moduleName, data) {
        const headers = new HttpHeaders().set('Authorization', this.token).set('UserId', this.UserId);
        return this.http.post(this.apiRoot + 'crud/updateRecord', { tableName: moduleName, columns: data }, { headers }).pipe( tap(() => {}, (err: any) => {
            this.errorHandler(err)
      }));
    }

    getDataByCID(moduleName, id) {
        const headers = new HttpHeaders().set('Authorization', this.token).set('UserId', this.UserId);
        return this.http.get(this.apiRoot + moduleName + '/getByCID/', { headers, params: { companyID: id } }).pipe( tap(() => {}, (err: any) => {
            this.errorHandler(err)
      }));
    }


    getVideosData(jobId, jobAllocationId){
        const headers = new HttpHeaders().set('Authorization', this.token).set('UserId', this.UserId);
        return this.http.get(this.apiRoot + 'uploadvideos/getValue', { headers, params: { job_id:jobId,job_alloc_id: jobAllocationId } }).pipe( tap(() => {}, (err: any) => {
            this.errorHandler(err)
    }));
}
    getBy(moduleName) {
        const headers = new HttpHeaders().set('Authorization', this.token).set('UserId', this.UserId);
        return this.http.get(this.apiRoot + moduleName + '/getBy' + moduleName + '/', { headers }).pipe( tap(() => {}, (err: any) => {
            this.errorHandler(err)
      }));
    }

    getLastJobNo() {
        const headers = new HttpHeaders().set('Authorization', this.token).set('UserId', this.UserId);
        return this.http.get(this.apiRoot + 'JobInfo/getLastJobNo', { headers }).pipe( tap(() => {}, (err: any) => {
            this.errorHandler(err)
      }));
    }

    getByUsernameAndPassword(username: string, password: string) {
        const headers = new HttpHeaders().set('Authorization', this.token).set('UserId', this.UserId);
        return this.http.post(this.apiRoot + 'User/GetByUsernameAndPassword', { params: { username, password } }, { headers }).pipe( tap(() => {}, (err: any) => {
            this.errorHandler(err)
      }));
    }

    /* TPInvoiceReport(job_alloc_id): Observable<Blob> {
        const headers = new HttpHeaders().set('Authorization', this.token).set('UserId', this.UserId);
        return this.http.get(this.apiRoot + 'generate/TPInvoiceReport', { headers, params: { 'job_alloc_id':job_alloc_id }, responseType: 'blob' }); 
    } */
    TPInvoiceReport(jobId, allocId, tempTime): Observable<ArrayBuffer> {
        const headers = new HttpHeaders().set('Authorization', this.token).set('UserId', this.UserId);
        headers.append('responseType', 'arraybuffer');
        return this.http.get(this.apiRoot + 'generate/TPInvoiceReport', { headers, params: { job_alloc_id: allocId, job_id: jobId, tempTime: tempTime }, responseType: 'arraybuffer' }).pipe( tap(() => {}, (err: any) => {
            this.errorHandler(err)
      }));
    };

    WPInvoiceReport(jobId, allocId, reportId, data, tempTime): Observable<ArrayBuffer> {
        const headers = new HttpHeaders().set('Authorization', this.token).set('UserId', this.UserId);
        headers.append('responseType', 'arraybuffer');
        return this.http.get(this.apiRoot + 'generate/WPReport', { headers, params: { job_alloc_id: allocId, job_id: jobId, reportid: reportId, data: data, tempTime: tempTime }, responseType: 'arraybuffer' }).pipe( tap(() => {}, (err: any) => {
            this.errorHandler(err)
      }));
    };


    setLogin(login) {
        const headers = new HttpHeaders().set('Authorization', this.token).set('UserId', this.UserId);
        return this.http.post(this.apiRoot + 'LoginDetail/createLoginDetails', login, { headers }).pipe( tap(() => {}, (err: any) => {
            this.errorHandler(err)
      }));
    }

    getCrewDetails(id) {
        const headers = new HttpHeaders().set('Authorization', this.token).set('UserId', this.UserId);
        return this.http.get(this.apiRoot + 'CrewDetailService/getSelectedCrewLevelDetailByHeadId', { headers, params: { head_id: id } }).pipe( tap(() => {}, (err: any) => {
            this.errorHandler(err)
      }));
    }


    getAllusers() {
        const headers = new HttpHeaders().set('Authorization', this.token).set('UserId', this.UserId);
        return this.http.get(this.apiRoot + 'CompanyStaffInfo/getAllusers', { headers }).pipe( tap(() => {}, (err: any) => {
            this.errorHandler(err)
      }));
    }

    getAllStaffbyCompName() {
        const headers = new HttpHeaders().set('Authorization', this.token).set('UserId', this.UserId);
        return this.http.get(this.apiRoot + 'CompanyStaffInfo/getAllStaffbyCompName', { headers }).pipe( tap(() => {}, (err: any) => {
            this.errorHandler(err)
      }));
    }
    getCompliancesByStaffId(staffId) {
        const headers = new HttpHeaders().set('Authorization', this.token).set('UserId', this.UserId);
        return this.http.get(this.apiRoot + 'CompanyCompliance/getCompliancesByStaffId', { headers, params: { staffId } }).pipe( tap(() => {}, (err: any) => {
            this.errorHandler(err)
      }));
    }

    getCompliancesByCId(company_id) {
        const headers = new HttpHeaders().set('Authorization', this.token).set('UserId', this.UserId);
        return this.http.get(this.apiRoot + 'ComplianceDefn/GetAllByCompanyId', { headers, params: { companyId: company_id } }).pipe( tap(() => {}, (err: any) => {
            this.errorHandler(err)
      }));
    }

    GetAllForStaffLicense() {
        const headers = new HttpHeaders().set('Authorization', this.token).set('UserId', this.UserId);
        return this.http.get(this.apiRoot + 'ComplianceDefn/GetAllForStaffLicense', { headers }).pipe( tap(() => {}, (err: any) => {
            this.errorHandler(err)
      }));
    }

    getAllForStaff() {
        const headers = new HttpHeaders().set('Authorization', this.token).set('UserId', this.UserId);
        return this.http.get(this.apiRoot + 'ComplianceDefn/getAllForStaff', { headers }).pipe( tap(() => {}, (err: any) => {
            this.errorHandler(err)
      }));
    }
    getAllJobRequirements() {
        const headers = new HttpHeaders().set('Authorization', this.token).set('UserId', this.UserId);
        return this.http.get(this.apiRoot + 'JobRequirements/getAll', { headers }).pipe( tap(() => {}, (err: any) => {
            this.errorHandler(err)
      }));

    }
    CreateJobRequirements(data) {
        const headers = new HttpHeaders().set('Authorization', this.token).set('UserId', this.UserId);
        return this.http.post(this.apiRoot + 'JobRequirements/CreateWithNewID', data, { headers }).pipe( tap(() => {}, (err: any) => {
            this.errorHandler(err)
      }));

    }

    EditJobRequirements(data) {
        const headers = new HttpHeaders().set('Authorization', this.token).set('UserId', this.UserId);
        return this.http.put(this.apiRoot + 'JobRequirements/Edit', data, { headers }).pipe( tap(() => {}, (err: any) => {
            this.errorHandler(err)
      }));
    }

    getAllJobPriorities() {
        const headers = new HttpHeaders().set('Authorization', this.token).set('UserId', this.UserId);
        return this.http.get(this.apiRoot + 'JobPriority/getAllPriorities', { headers }).pipe( tap(() => {}, (err: any) => {
            this.errorHandler(err)
      }));
    }
    CreatePriorities(data) {
        const headers = new HttpHeaders().set('Authorization', this.token).set('UserId', this.UserId);
        return this.http.post(this.apiRoot + 'JobPriority/CreateWithNewID', data, { headers }).pipe( tap(() => {}, (err: any) => {
            this.errorHandler(err)
      }));

    }
    EditJobPriorities(data) {
        const headers = new HttpHeaders().set('Authorization', this.token).set('UserId', this.UserId);
        return this.http.put(this.apiRoot + 'JobPriority/Edit', data, { headers }).pipe( tap(() => {}, (err: any) => {
            this.errorHandler(err)
      }));

    }
    getAllJobDepotDetails() {
        const headers = new HttpHeaders().set('Authorization', this.token).set('UserId', this.UserId);
        return this.http.get(this.apiRoot + 'Depot/getAllDepotbyCompName', { headers }).pipe( tap(() => {}, (err: any) => {
            this.errorHandler(err)
      }));
    }

    Create(data, moduleName) {
        if (environment.hosting =='UK' && (moduleName == "UploadImages" || moduleName == "UploadDocuments" )){
            data.source= "TMV3"
        }
        const headers = new HttpHeaders().set('Authorization', this.token).set('UserId', this.UserId);
        return this.http.post(this.apiRoot + moduleName + '/CreateWithNewID', data, { headers }).pipe( tap(() => {}, (err: any) => {
            this.errorHandler(err)
      }));
    }

    Update(data, moduleName) {
        const headers = new HttpHeaders().set('Authorization', this.token).set('UserId', this.UserId);
        return this.http.put(this.apiRoot + moduleName + '/Edit', data, { headers }).pipe( tap(() => {}, (err: any) => {
            this.errorHandler(err)
      }));
    }

    Delete(id, moduleName) {
        const headers = new HttpHeaders().set('Authorization', this.token).set('UserId', this.UserId);
        return this.http.post(this.apiRoot + moduleName + '/Delete', { params: { id } }, { headers }).pipe( tap(() => {}, (err: any) => {
            this.errorHandler(err)
      }));
    }

    getAllCompanies() {
        const headers = new HttpHeaders().set('Authorization', this.token).set('UserId', this.UserId);
        return this.http.get(this.apiRoot + 'Company/getAllCompanies', { headers }).pipe( tap(() => {}, (err: any) => {
            this.errorHandler(err)
      }));
    };

    getAllProviders() {
        const headers = new HttpHeaders().set('Authorization', this.token).set('UserId', this.UserId);
        return this.http.get(this.apiRoot + 'Company/getAllProviders', { headers }).pipe( tap(() => {}, (err: any) => {
            this.errorHandler(err)
      }));
    }
    fetchValueSet(valueSetID) {
        const headers = new HttpHeaders().set('Authorization', this.token).set('UserId', this.UserId);
        return this.http.get(this.apiRoot + 'ValueSetDetail/getByValuesetName', { headers, params: { valuesetName: valueSetID } }).pipe( tap(() => {}, (err: any) => {
            this.errorHandler(err)
      }));
    }

    getByValuesetstafftype() {
        const headers = new HttpHeaders().set('Authorization', this.token).set('UserId', this.UserId);
        return this.http.get(this.apiRoot + 'ValueSetDetail/getByValuesetstafftype', { headers, params: {} }).pipe( tap(() => {}, (err: any) => {
            this.errorHandler(err)
      }));
    }

    getForLicense(companyId, type) {
        const headers = new HttpHeaders().set('Authorization', this.token).set('UserId', this.UserId);
        return this.http.get(this.apiRoot + 'Company/getForLicense',
            { headers, params: { company_id: companyId, type } }).pipe( tap(() => {}, (err: any) => {
                this.errorHandler(err)
          }));
    };

    getAllLicence(moduleName, companyId, type) {
        const headers = new HttpHeaders().set('Authorization', this.token).set('UserId', this.UserId);
        return this.http.put(this.apiRoot + moduleName + '/getForLicense', { params: { company_id: companyId, type } }, { headers }).pipe( tap(() => {}, (err: any) => {
            this.errorHandler(err)
      }));
    }

    getByStaff(moduleName) {
        const headers = new HttpHeaders().set('Authorization', this.token).set('UserId', this.UserId);
        return this.http.get(this.apiRoot + moduleName + '/getByStaff', { headers }).pipe( tap(() => {}, (err: any) => {
            this.errorHandler(err)
      }));
    }

    getBySelectedStaffId(moduleName, roleId) {
        const headers = new HttpHeaders().set('Authorization', this.token).set('UserId', this.UserId);
        return this.http.get(this.apiRoot + moduleName + '/getBySelectedStaffId', { headers, params: { id: roleId } }).pipe( tap(() => {}, (err: any) => {
            this.errorHandler(err)
      }));
    }

    getValuesetDetailByVSName(vsdid) {
        const headers = new HttpHeaders().set('Authorization', this.token).set('UserId', this.UserId);
        return this.http.get(this.apiRoot + 'ValueSetDetail/getValuesetDetailByVSName', { headers, params: { id: vsdid } }).pipe( tap(() => {}, (err: any) => {
            this.errorHandler(err)
      }));
    }

    getValuesetDetailByID(vsdid) {
        const headers = new HttpHeaders().set('Authorization', this.token).set('UserId', this.UserId);
        return this.http.get(this.apiRoot + 'ValueSetDetail/getValuesetDetailByID', { headers, params: { id: vsdid } }).pipe( tap(() => {}, (err: any) => {
            this.errorHandler(err)
      }));
    }

    async getUserLogin(username: string, passwords: string) {
        const password = this.encryptService.encryptionAES(passwords);
        // const headers = new HttpHeaders().set('Authorization', this.token).set('UserId', this.UserId);
        return await this.http.post(this.apiRoot + 'User/userLogin', { params: { username, password } }).pipe( 
            map((user:any) => { 
                console.log('getUserLogin token',user);
              this.token = user.data[0].auth_token;
              this.UserId = user.data[0].id;
            //   var userDetails = { id:user.data[0].id };             
             // sessionStorage.setItem('loggedInUser', JSON.stringify(userDetails));
              sessionStorage.setItem('user_id', user.data[0].id);
              return user;
            })
          );
    }

    getUserDetailslogin(username: string, passwords: string) {
       const password = this.encryptService.encryptionAES(passwords);
       //const password = passwords;
        // const headers = new HttpHeaders().set('Authorization', this.token).set('UserId', this.UserId);
        return this.http.post(this.apiRoot + 'User/userLogin', { params: { username, password } }).pipe( 
            map((user:any) => { 
              sessionStorage.setItem('currentUser', JSON.stringify(user));
              sessionStorage.setItem('auth_token', user.data[0].auth_token);
              sessionStorage.setItem('user_id', user.data[0].id);
             // localStorage.setItem('currentUser', JSON.stringify(user));
              localStorage.setItem('auth_token', user.data[0].auth_token);
              localStorage.setItem('user_id', user.data[0].id);

              this.token = sessionStorage.getItem('auth_token');
              this.UserId = sessionStorage.getItem('user_id');
              return user;
            })
          );
    }
          

    getUserDetails(username: string, password: string) {
        const headers = new HttpHeaders().set('Authorization', this.token).set('UserId', this.UserId);
        return this.http.post(this.apiRoot + 'User/getUserDetailsWCompany', { params: { username, password } }, { headers }).pipe( tap(() => {}, (err: any) => {
            this.errorHandler(err)
      }));
    }

    getUserDetailsByCompany(username: string, password: string, companyId: string) {
        const headers = new HttpHeaders().set('Authorization', this.token).set('UserId', this.UserId);
        return this.http.post(this.apiRoot + 'User/getUserDetailsWCompany', { params: { username, password, companyId } }, { headers }).pipe( tap(() => {}, (err: any) => {
            this.errorHandler(err)
      }));
    }
    getJobsList(companyId: string) {
        const headers = new HttpHeaders().set('Authorization', this.token).set('UserId', this.UserId);
        return this.http.get(this.apiRoot + 'Jobinfo/ListingPageTPOperationsAllLicense2', { headers, params: { company_id: companyId } }).pipe( tap(() => {}, (err: any) => {
            this.errorHandler(err)
      }));
    }

    getStaffsList(companyId: string) {
        const headers = new HttpHeaders().set('Authorization', this.token).set('UserId', this.UserId);
        return this.http.get(this.apiRoot + 'CompanyStaffInfo/getStaffByCompany', { headers, params: { companyID: companyId } }).pipe( tap(() => {}, (err: any) => {
            this.errorHandler(err)
      }));
    }

    getEquipmentsList(companyId: string) {
        const headers = new HttpHeaders().set('Authorization', this.token).set('UserId', this.UserId);
        return this.http.get(this.apiRoot + 'ScheduleEquipmentDetail/getEquipmentByCompany', { headers, params: { company_id: companyId } }).pipe( tap(() => {}, (err: any) => {
            this.errorHandler(err)
      }));
    }

    getEventsList(companyId: string) {
        const headers = new HttpHeaders().set('Authorization', this.token).set('UserId', this.UserId);
        return this.http.get(this.apiRoot + 'ScheduleHead/getAllScheduleHead', { headers, params: { company_id: companyId } }).pipe( tap(() => {}, (err: any) => {
            this.errorHandler(err)
      }));
    }
    getEventsEquipments(id) {
        const headers = new HttpHeaders().set('Authorization', this.token).set('UserId', this.UserId);
        return this.http.get(this.apiRoot + 'ScheduleHead/getEquipmentListByScheduleHead', { headers, params: { head_id: id } }).pipe( tap(() => {}, (err: any) => {
            this.errorHandler(err)
      }));
    }

    getEventsStaffs(id) {
        const headers = new HttpHeaders().set('Authorization', this.token).set('UserId', this.UserId);
        return this.http.get(this.apiRoot + 'ScheduleHead/getStaffInfoByScheduleHead', { headers, params: { head_id: id } }).pipe( tap(() => {}, (err: any) => {
            this.errorHandler(err)
      }));
    }
    getAllItemtypes() {
        const headers = new HttpHeaders().set('Authorization', this.token).set('UserId', this.UserId);
        return this.http.get(this.apiRoot + 'itemDefn/getAllItemtypes', { headers }).pipe( tap(() => {}, (err: any) => {
            this.errorHandler(err)
      }));
    }
    getComplainceDetails(id) {
        const headers = new HttpHeaders().set('Authorization', this.token).set('UserId', this.UserId);
        return this.http.get(this.apiRoot + 'ComplianceLevelDetailService/getSelectedCompLevelDetailByHeadId', { headers, params: { head_id: id } }).pipe( tap(() => {}, (err: any) => {
            this.errorHandler(err)
      }));
    }
    getStaffByCID(id) {
        const headers = new HttpHeaders().set('Authorization', this.token).set('UserId', this.UserId);
        return this.http.get(this.apiRoot + 'CompanyStaffInfo/getByCID', { headers, params: { companyID: id } }).pipe( tap(() => {}, (err: any) => {
            this.errorHandler(err)
      }));
    }

    getStaffByCIDGeneral(id) {
        const headers = new HttpHeaders().set('Authorization', this.token).set('UserId', this.UserId);
        return this.http.get(this.apiRoot + 'CompanyStaffInfo/getByCIDGeneral', { headers, params: { companyID: id } }).pipe( tap(() => {}, (err: any) => {
            this.errorHandler(err)
      }));
    }

    getAllTP() {
        const headers = new HttpHeaders().set('Authorization', this.token).set('UserId', this.UserId);
        return this.http.get(this.apiRoot + 'Company/getAllTp', { headers }).pipe( tap(() => {}, (err: any) => {
            this.errorHandler(err)
      }));
    }

    getAllTpReports(client_type_id, company_id) {
        const headers = new HttpHeaders().set('Authorization', this.token).set('UserId', this.UserId);
        return this.http.get(this.apiRoot + 'Company/getAllTpReports', { headers, params: { client_type_id, company_id } }).pipe( tap(() => {}, (err: any) => {
            this.errorHandler(err)
      }));
    }

    getCompLevelDetailByIdForCompanyCompliance(id) {
        const headers = new HttpHeaders().set('Authorization', this.token).set('UserId', this.UserId);
        return this.http.get(this.apiRoot + 'complianceleveldetail/getCompLevelDetailByIdForCompanyCompliance', { headers, params: { id } }).pipe( tap(() => {}, (err: any) => {
            this.errorHandler(err)
      }));
    }
    getUniqueCompliancesByLevels(levels) {
        const headers = new HttpHeaders().set('Authorization', this.token).set('UserId', this.UserId);
        return this.http.get(this.apiRoot + 'complianceleveldetail/getUniqueCompliancesByLevels', { headers, params: { levels } }).pipe( tap(() => {}, (err: any) => {
            this.errorHandler(err)
      }));
    }

    getAllComplianceLevelHead() {
        const headers = new HttpHeaders().set('Authorization', this.token).set('UserId', this.UserId);
        return this.http.get(this.apiRoot + 'ComplianceLevelHead/getAllComplianceLevelHead', { headers, params: {} }).pipe( tap(() => {}, (err: any) => {
            this.errorHandler(err)
      }));
    }

    getFilteredComplianceLevelHead() {
        const headers = new HttpHeaders().set('Authorization', this.token).set('UserId', this.UserId);
        return this.http.get(this.apiRoot + 'ComplianceLevelHead/getFilteredComplianceLevelHead', { headers, params: {} }).pipe( tap(() => {}, (err: any) => {
            this.errorHandler(err)
      }));
    }
    getAllByCompanyId(id) {
        const headers = new HttpHeaders().set('Authorization', this.token).set('UserId', this.UserId);
        return this.http.get(this.apiRoot + 'CompanyCompliance/GetAllByCompanyId', { headers, params: { companyId: id } }).pipe( tap(() => {}, (err: any) => {
            this.errorHandler(err)
      }));
    }

    getJobPriorList(){
        const headers = new HttpHeaders().set('Authorization', this.token).set('UserId', this.UserId);
        return this.http.get(this.apiRoot + 'JobPriority/getJobPriorList', { headers }).pipe( tap(() => {}, (err: any) => {
            this.errorHandler(err)
      })); 
    }

    getByDepotCID(id) {
        const headers = new HttpHeaders().set('Authorization', this.token).set('UserId', this.UserId);
        return this.http.get(this.apiRoot + 'Depot/getByDepotCID', { headers, params: { companyID: id } }).pipe( tap(() => {}, (err: any) => {
            this.errorHandler(err)
      }));
    }
    getByCompanyWPTP(id) {
        const headers = new HttpHeaders().set('Authorization', this.token).set('UserId', this.UserId);
        return this.http.get(this.apiRoot + 'Company/getByCompanyWPTP', { headers, params: { clienttypeId: id } }).pipe( tap(() => {}, (err: any) => {
            this.errorHandler(err)
      }));
    }
    getClientTPByID(id) {
        const headers = new HttpHeaders().set('Authorization', this.token).set('UserId', this.UserId);
        return this.http.get(this.apiRoot + 'Company/getByCompanyWPTPandID', { headers, params: { clientID: id } }).pipe( tap(() => {}, (err: any) => {
            this.errorHandler(err)
      }));
    }
    getContactsByCID(id) {
        const headers = new HttpHeaders().set('Authorization', this.token).set('UserId', this.UserId);
        return this.http.get(this.apiRoot + 'CompanyContacts/getContactsByCID', { headers, params: { companyID: id } }).pipe( tap(() => {}, (err: any) => {
            this.errorHandler(err)
      }));
    }
    getContactInfoByID(id) {
        const headers = new HttpHeaders().set('Authorization', this.token).set('UserId', this.UserId);
        return this.http.get(this.apiRoot + 'CompanyContacts/getContactInfoByID', { headers, params: { contactID: id } }).pipe( tap(() => {}, (err: any) => {
            this.errorHandler(err)
      }));
    }

    getJobs(id) {
        const headers = new HttpHeaders().set('Authorization', this.token).set('UserId', this.UserId);
        return this.http.get(this.apiRoot + 'JobInfo/ListingPageTPOperationsAllLicense', { headers, params: { company_id: id } }).pipe( tap(() => {}, (err: any) => {
            this.errorHandler(err)
      }));
    }
    fetchSafetyComplianceFile(filePath) {
        const headers = new HttpHeaders().set('Authorization', this.token).set('UserId', this.UserId);
        return this.http.get(this.apiRoot + 'CompanyCompliance/downloadStaffComplianceDoc', { headers, params: { companyId: '830', filePath } }).pipe( tap(() => {}, (err: any) => {
            this.errorHandler(err);
            // this.handleFileDownloadError(err);
      }));
    }
    getMenusByRole(role, companyId) {
        const headers = new HttpHeaders().set('Authorization', this.token).set('UserId', this.UserId);
        return this.http.get(this.apiRoot + 'user/getMenus', { headers, params: { user_group_id: role, company_id: companyId } }).pipe( tap(() => {}, (err: any) => {
            this.errorHandler(err)
      }));
    }
    getMenusByUserRoleID(role) {
        const headers = new HttpHeaders().set('Authorization', this.token).set('UserId', this.UserId);
        return this.http.get(this.apiRoot + 'user/getMenusByUserGroupId', { headers, params: { user_group_id: role } }).pipe( tap(() => {}, (err: any) => {
            this.errorHandler(err)
      }));
    }
    
    getusergrouplist() {
        const headers = new HttpHeaders().set('Authorization', this.token).set('UserId', this.UserId);
        return this.http.get(this.apiRoot + 'user/getusergrouplist', { headers }).pipe( tap(() => {}, (err: any) => {
            this.errorHandler(err)
      }));
    }
    getJobCommunicationNotes(job_id, job_alloc_id) {
        const headers = new HttpHeaders().set('Authorization', this.token).set('UserId', this.UserId);
        return this.http.get(this.apiRoot + 'user/getMenus', { headers, params: { job_id, job_alloc_id } }).pipe( tap(() => {}, (err: any) => {
            this.errorHandler(err)
      }));
    }

    getUploadDocumentsByJobIdAllocId(job_id, job_alloc_id) {
        const headers = new HttpHeaders().set('Authorization', this.token).set('UserId', this.UserId);
        return this.http.get(this.apiRoot + 'uploaddocuments/getUploadDocumentsByJobIdAllocId', { headers, params: { job_id, job_alloc_id } }).pipe( tap(() => {}, (err: any) => {
            this.errorHandler(err)
      }));
    }

    getUploadDocumentsByJobIdAllocId_dup(job_id, job_alloc_id) {
        return this.http.get(this.apiRoot + 'clientdetails/getUploadDocumentsByJobIdAllocId', {params: { job_id, job_alloc_id } }).pipe( tap(() => {}, (err: any) => {
            this.errorHandler(err)
      }));
    }

    getUploadImgsByJobIdAllocId_dup(job_id, job_alloc_id) {
        return this.http.get(this.apiRoot + 'clientdetails/getUploadImgsByJobIdAllocId', { params: { job_id, job_alloc_id } }).pipe( tap(() => {}, (err: any) => {
            this.errorHandler(err)
      }));
    }

    getUploadImgsByJobIdAllocId(job_id, job_alloc_id) {
        const headers = new HttpHeaders().set('Authorization', this.token).set('UserId', this.UserId);
        return this.http.get(this.apiRoot + 'uploadimages/getUploadImgsByJobIdAllocId', { headers, params: { job_id, job_alloc_id } }).pipe( tap(() => {}, (err: any) => {
            this.errorHandler(err)
      }));
    }

    uploadCompliance2(uploadedFile, company_id) {
        const data = new FormData();
        data.append('file', uploadedFile);
        data.append('companyId', company_id);
        const headers = new HttpHeaders().set('Authorization', this.token).set('UserId', this.UserId);
        headers.append('withCredentials', 'false');
        headers.append('Access-Control-Allow-Origin', '*');
        return this.http.post(this.apiRoot + 'CompanyCompliance/upload', data, { headers }).pipe( tap(() => {}, (err: any) => {
            this.errorHandler(err)
      }));
    }

    uploadCompliance(uploadedFile, company_id, staff_id) {
        let response = null;
        const data = new FormData();
        console.log("uploadedFile",uploadedFile);
      //  uploadedFile.name = uploadedFile.name.replace(/[&\/\\#, +()$~%;'":*?<>{}]/g, '');
        data.append('file', uploadedFile);
        data.append('companyId', company_id);
        data.append('staffId', staff_id);
        const xhr = new XMLHttpRequest();
        let url = '';
        if (staff_id != null && staff_id != '') {
            url = this.apiRoot + 'CompanyCompliance/uploadStaffComplianceDoc?companyId=' + company_id + '?staffId=' + staff_id;;
        } else {
            url = this.apiRoot + 'CompanyCompliance/upload?companyId=' + company_id;
        }
        xhr.open('POST', url, false);
        xhr.setRequestHeader('Authorization', this.token);
        xhr.setRequestHeader('UserId', this.UserId);
        xhr.send(data);
        xhr.onload = function () {
            response = xhr.response;
        };
        if (xhr.readyState == 4 && xhr.status == 200) {
            response = xhr.response; // no parsing needed
        }
        return response;
    }

    uploadCompanyLogo(uploadedFile, company_id) {
        let response = null;
        const data = new FormData();
        data.append('file', uploadedFile);
        data.append('companyId', company_id);
        const xhr = new XMLHttpRequest();
        let url = '';
        url = this.apiRoot + 'CompanyCompliance/upload?companyId=' + company_id;
        xhr.open('POST', url, false);
        xhr.setRequestHeader('Authorization', this.token);
        xhr.setRequestHeader('UserId', this.UserId);
        xhr.send(data);
        xhr.onload = function () {
            response = xhr.response;
        };
        if (xhr.readyState == 4 && xhr.status == 200) {
            response = xhr.response; // no parsing needed
        }
        return response;
    }

    getScheduleHeadByAll(staffList, equipmentList) {
        const headers = new HttpHeaders().set('Authorization', this.token).set('UserId', this.UserId);
        return this.http.get(this.apiRoot + 'ScheduleHead/getScheduleHeadByAll', { headers, params: { staffList, equipmentList } }).pipe( tap(() => {}, (err: any) => {
            this.errorHandler(err)
      }));
    }

    getScheduleHeadByAny(staffList, equipmentList) {
        const headers = new HttpHeaders().set('Authorization', this.token).set('UserId', this.UserId);
        return this.http.get(this.apiRoot + 'ScheduleHead/getScheduleHeadByAny', { headers, params: { staffList, equipmentList } }).pipe( tap(() => {}, (err: any) => {
            this.errorHandler(err)
      }));
    }

    getByJobIdAllocId(job_id, job_alloc_id) {
        const headers = new HttpHeaders().set('Authorization', this.token).set('UserId', this.UserId);
        return this.http.get(this.apiRoot + 'JobSchedule/getByJobIdAllocId', { headers, params: { job_id, job_alloc_id } }).pipe( tap(() => {}, (err: any) => {
            this.errorHandler(err)
      }));
    }

    fetchStaffComplianceFile(company_id, Staff_id, filePath): Observable<Blob> {
        const headers = new HttpHeaders().set('Authorization', this.token).set('UserId', this.UserId);
        return this.http.get(this.apiRoot + 'CompanyCompliance/downloadStaffComplianceDoc', { headers, params: { companyId: company_id, filePath, staffId: Staff_id }, responseType: 'blob' }).pipe( tap(() => {}, (err: any) => {
            this.errorHandler(err);
            // if(globalErrorToastr) {
            //     this.handleFileDownloadError(err);
            // }
      }));
    }

    fetchTaskFile(company_id, task_id, filePath): Observable<Blob> {
        const headers = new HttpHeaders().set('Authorization', this.token).set('UserId', this.UserId);
        return this.http.get(this.apiRoot + 'uploaddocuments/downloadTaskFile', { headers, params: { companyId: company_id, filePath, task_id }, responseType: 'blob' }).pipe( tap(() => {}, (err: any) => {
            this.errorHandler(err);
            // this.handleFileDownloadError(err);
      }));
    }

    downlaodPolicyDocs(filename): Observable<Blob> {
        const headers = new HttpHeaders().set('Authorization', this.token).set('UserId', this.UserId);
        return this.http.get(this.apiRoot + 'uploaddocuments/downloadPolicyFiles', { headers, params: { filename }, responseType: 'blob' }).pipe( tap(() => {}, (err: any) => {
            this.errorHandler(err);
            // this.handleFileDownloadError(err);
      }));
    }

    getPolicyDocs(filename): Observable<ArrayBuffer> {
        const headers = new HttpHeaders().set('Authorization', this.token).set('UserId', this.UserId);
        headers.append('responseType', 'arraybuffer');
        return this.http.get(this.apiRoot + 'uploaddocuments/getPolicyFiles', { headers, params: { filename }, responseType: 'arraybuffer' }).pipe( tap(() => {}, (err: any) => {
            this.errorHandler(err)
      }));
    }

    fetchComplianceFile(company_id, filePath, filename): Observable<Blob> {
        const headers = new HttpHeaders().set('Authorization', this.token).set('UserId', this.UserId);
        headers.append('responseType', 'blob');
        return this.http.get(this.apiRoot + 'CompanyCompliance/download', { headers, params: { companyId: company_id, filePath }, responseType: 'blob' }).pipe( tap(() => {}, (err: any) => {
            this.errorHandler(err);
            // this.handleFileDownloadError(err);
      }));
    }


    getCompanyLogo(companyId, imagePath): Observable<Blob> {
        const headers = new HttpHeaders().set('Authorization', this.token).set('UserId', this.UserId);
        headers.append('responseType', 'blob');
        return this.http.get(this.apiRoot + 'CompanyCompliance/download', { headers, params: { companyId, filePath: imagePath }, responseType: 'blob' }).pipe( tap(() => {}, (err: any) => {
            this.errorHandler(err);
      }));
    }

    getImageFile(filePath): Observable<Blob> {
        const headers = new HttpHeaders().set('Authorization', this.token).set('UserId', this.UserId);
        headers.append('responseType', 'blob');
        return this.http.get(this.apiRoot + 'uploadimages/getpic', { headers, params: { filePath }, responseType: 'blob' }).pipe( tap(() => {}, (err: any) => {
            this.errorHandler(err);
            // this.handleFileDownloadError(err);
      }));
    }

    getImageFile_dup(filePath): Observable<Blob> {
        const headers = new HttpHeaders()
        headers.append('responseType', 'blob');
        return this.http.get(this.apiRoot + 'clientdetails/getpic', { headers, params: { filePath }, responseType: 'blob' }).pipe( tap(() => {}, (err: any) => {
            this.errorHandler(err)
      }));
    }

    generateNewKPIReport(jobId, allocId, data, reportId, tempTime, pdfData): Observable<ArrayBuffer> {
        const headers = new HttpHeaders().set('Authorization', this.token).set('UserId', this.UserId);
        headers.append('responseType', 'arraybuffer');
        return this.http.get(this.apiRoot + 'generate/DocPrintReport', { headers, params: { data: JSON.stringify(data), jobId, allocId, tempTime, pdfData }, responseType: 'arraybuffer' }).pipe( tap(() => {}, (err: any) => {
            this.errorHandler(err)
      }));
    };
    generateSLAReport(companyId, data, reportId, tempTime, pdfData): Observable<ArrayBuffer> {
        const headers = new HttpHeaders().set('Authorization', this.token).set('UserId', this.UserId);
        headers.append('responseType', 'arraybuffer');
        return this.http.get(this.apiRoot + 'generate/DocPrintReport', { headers, params: { data: JSON.stringify(data), jobId: companyId, allocId: 'compliance_docs', tempTime, pdfData }, responseType: 'arraybuffer' }).pipe( tap(() => {}, (err: any) => {
            this.errorHandler(err)
      }));
    };
    generateEFIReport(companyId, staffId, data, reportId, tempTime, pdfData): Observable<ArrayBuffer> {
        const headers = new HttpHeaders().set('Authorization', this.token).set('UserId', this.UserId);
        headers.append('responseType', 'arraybuffer');
        return this.http.get(this.apiRoot + 'generate/DocPrintReport', { headers, params: { data: JSON.stringify(data), jobId: companyId, allocId: 'compliance_docs/staff/' + staffId, tempTime, pdfData }, responseType: 'arraybuffer' }).pipe( tap(() => {}, (err: any) => {
            this.errorHandler(err)
      }));
    };
    generateReport(data, reportId, jobId, allocId, tempTime, pdfData): Observable<ArrayBuffer> {
        const headers = new HttpHeaders().set('Authorization', this.token).set('UserId', this.UserId);
        headers.append('responseType', 'arraybuffer');
        return this.http.get(this.apiRoot + 'generate/report', { headers, params: { data: JSON.stringify(data), jobId, allocId, tempTime, pdfData }, responseType: 'arraybuffer' }).pipe( tap(() => {}, (err: any) => {
            this.errorHandler(err)
      }));
    };
    generateReport_dup(data, reportId, jobId, allocId, tempTime, pdfData): Observable<ArrayBuffer> {
        const headers = new HttpHeaders()
        headers.append('responseType', 'arraybuffer');
        return this.http.get(this.apiRoot + 'clientdetails/report', { headers, params: { data: JSON.stringify(data), jobId, allocId, tempTime, pdfData }, responseType: 'arraybuffer' }).pipe( tap(() => {}, (err: any) => {
            this.errorHandler(err)
      }));
    };


    generateJasperReport(data, reportId, jobId, allocId, tempTime, pdfData): Observable<ArrayBuffer> {
        const headers = new HttpHeaders().set('Authorization', this.token).set('UserId', this.UserId);
        headers.append('responseType', 'arraybuffer');
        if (environment.hosting == 'UK') {
            return this.http.get('http://treemanager.uk:8080/' + 'report/getpdf', { headers, params: { data: JSON.stringify(data) }, responseType: 'arraybuffer' }).pipe( tap(() => {}, (err: any) => {
                this.errorHandler(err)
          }));
        } else {
            return this.http.get('localhost:8080/' + 'report/getpdf', { headers, params: { data: JSON.stringify(data) }, responseType: 'arraybuffer' }).pipe( tap(() => {}, (err: any) => {
                this.errorHandler(err)
          }));
        }
    };

    generateNewSHFReport(jobId, allocId, data, reportId, tempTime, pdfData): Observable<ArrayBuffer> {
        const headers = new HttpHeaders().set('Authorization', this.token).set('UserId', this.UserId);
        headers.append('responseType', 'arraybuffer');
        return this.http.get(this.apiRoot + 'generate/DocPrintReport', { headers, params: { data: JSON.stringify(data), jobId, allocId, tempTime, pdfData }, responseType: 'arraybuffer' }).pipe( tap(() => {}, (err: any) => {
            this.errorHandler(err)
      }));
    };

    generateNewInvoice324Report(jobId, allocId, data, reportId, tempTime, pdfData): Observable<ArrayBuffer> {
        const headers = new HttpHeaders().set('Authorization', this.token).set('UserId', this.UserId);
        headers.append('responseType', 'arraybuffer');
        return this.http.get(this.apiRoot + 'generate/DocPrintReport', { headers, params: { data: JSON.stringify(data), jobId, allocId, tempTime, pdfData }, responseType: 'arraybuffer' }).pipe( tap(() => {}, (err: any) => {
            this.errorHandler(err)
      }));
    };

    getSHFReportData(allocId) {
        const headers = new HttpHeaders().set('Authorization', this.token).set('UserId', this.UserId);
        return this.http.get(this.apiRoot + 'JobSiteHazard/getReportData', { headers, params: { job_alloc_id: allocId } }).pipe( tap(() => {}, (err: any) => {
            this.errorHandler(err)
      }));
    }

    getCSOImgFile(imgPath): Observable<Blob> {
        const headers = new HttpHeaders().set('Authorization', this.token).set('UserId', this.UserId);
        headers.append('responseType', 'blob');
        return this.http.get(this.apiRoot + 'uploadimages/getCSOpic', { headers, params: { filePath: imgPath }, responseType: 'blob' }).pipe( tap(() => {}, (err: any) => {
            this.errorHandler(err);
            // this.handleFileDownloadError(err);
      }));
    }

    getLastJobNoTP(contractor_id) {
        const headers = new HttpHeaders().set('Authorization', this.token).set('UserId', this.UserId);
        return this.http.get(this.apiRoot + 'JobInfo/getLastJobNoTP', { headers, params: { contractor_id } }).pipe( tap(() => {}, (err: any) => {
            this.errorHandler(err)
      }));
    }

    fetchImage(imagePath) {
        let response = null;
        const data = new FormData();
        data.append('filePath', imagePath);
        const xhr = new XMLHttpRequest();
        let url = this.apiRoot + 'uploadimages/getpic';
        xhr.open('GET', url, false);
        xhr.setRequestHeader('Authorization', this.token);
        xhr.setRequestHeader('UserId', this.UserId);
        xhr.send(data);
        xhr.onload = function () {
            response = xhr.response;
        };
        if (xhr.readyState == 4 && xhr.status == 200) {
            response = xhr.response; // no parsing needed
        }
        return response;
    }

    uploadImageV2(file: any, filepath: any, imageName) {
        var resp = this. ValidateImageInput(imageName)
        if(resp){
        const data = new FormData();
        data.append('myImage', file);
        data.append('filepath', filepath);
        data.append('filename', imageName);
        let response = null;
        const xhr = new XMLHttpRequest();
        let url = this.apiRoot + 'v2/uploadImage';
        xhr.open('POST', url, false);
        xhr.setRequestHeader('Authorization', this.token);
        xhr.setRequestHeader('UserId', this.UserId);
        xhr.send(data);
        xhr.onload = function () {
            response = xhr.response;
        };
        if (xhr.readyState == 4 && xhr.status == 200) {
            response = xhr.response; // no parsing needed
        }
        return response;
    }
    else{
        let  response = JSON.stringify({success: 0,message: "This file type is not supported" });
           return response;
      }
    }

    compressUploadImageV2(file: any, filepath: any, imageName) {
        var resp = this. ValidateImageInput(imageName) 
        if(resp){
        const data = new FormData();
        data.append('myImage', file);
        data.append('filepath', filepath);
        data.append('filename', imageName);
        let response = null;
        const xhr = new XMLHttpRequest();
        let url = this.apiRoot + 'v2/compress/upload';
        xhr.open('POST', url, false);
        xhr.setRequestHeader('Authorization', this.token);
        xhr.setRequestHeader('UserId', this.UserId);
        xhr.send(data);
        xhr.onload = function () {
            response = xhr.response;
        };
        if (xhr.readyState == 4 && xhr.status == 200) {
            response = xhr.response; // no parsing needed
        }
        return response;
    }
    else{
        let  response = JSON.stringify({success: 0,message: "This file type is not supported" });
           return response;
      }
    }


    uploadImage(imagePath, job_id, job_alloc_id, imageName, storage = null) {
        var resp = this. ValidateImageInput(imageName) 
        if(resp){
            const data = new FormData();
            console.log("imagePath",imagePath);
            data.append('imgPath', imagePath);
            let response = null;
            data.append('jobId', job_id);
            data.append('jobAllocId', job_alloc_id);
            data.append('imgName', imageName);
            if(storage) {
                data.append('storage', storage);
            }
            const xhr = new XMLHttpRequest();
            let url = this.apiRoot + 'uploadimages/uploadAppPic';
            //let url = this.apiRoot + 'v2/uploadAppPic';
            xhr.open('POST', url, false);
            xhr.setRequestHeader('Authorization', this.token);
            xhr.setRequestHeader('UserId', this.UserId);
            xhr.send(data);
            xhr.onload = function () {
                response = xhr.response;
            };
            if (xhr.readyState == 4 && xhr.status == 200) {
                response = xhr.response; // no parsing needed
            }
            return response;
        }
        else{
          let  response = JSON.stringify({success: 0,message: "This file type is not supported" });
             return response;
        }
    
    }
    ValidateImageInput(oInput) {
        const specialChars = /[.]/;
        if(specialChars.test(oInput)){
            var allowedExtensions = environment.imagefiletype;
            if (allowedExtensions.exec(oInput)) {
              console.log("true");
              return true
           } else {
              console.log("false");
              return false
           }
        }
        else{
            return true
        }  
    }

    compressUploadImage(imagePath, imageName) {
        var resp = this. ValidateImageInput(imageName)
        if(resp){
        const data = new FormData();
        data.append('filePath', imagePath);
        let response = null;
        data.append('fileName', imageName);
        const xhr = new XMLHttpRequest();
        let url = this.apiRoot + 'uploadimages/compress/uploadImage';
        xhr.open('POST', url, false);
        xhr.setRequestHeader('Authorization', this.token);
        xhr.setRequestHeader('UserId', this.UserId);
        xhr.send(data);
        xhr.onload = function () {
            response = xhr.response;
        };
        if (xhr.readyState == 4 && xhr.status == 200) {
            response = xhr.response; // no parsing needed
        }
        return response;
    }
    else{
        let  response = JSON.stringify({success: 0,message: "This file type is not supported" });
           return response;
      }
    }



    uploadTaskImage(imagePath, companyId, taskId, imageName) {
        var resp = this. ValidateImageInput(imageName)
        if(resp){
        const data = new FormData();
        data.append('imgPath', imagePath);
        let response = null;
        data.append('company_id', companyId);
        data.append('task_id', taskId);
        data.append('imgName', imageName);
        const xhr = new XMLHttpRequest();
        let url = this.apiRoot + 'uploadimages/uploadTaskImage';
        xhr.open('POST', url, false);
        xhr.setRequestHeader('Authorization', this.token);
        xhr.setRequestHeader('UserId', this.UserId);
        xhr.send(data);
        xhr.onload = function () {
            response = xhr.response;
        };
        if (xhr.readyState == 4 && xhr.status == 200) {
            response = xhr.response; // no parsing needed
        }
        return response;
    }
    else{
        let  response = JSON.stringify({success: 0,message: "This file type is not supported" });
           return response;
      }
    }

    uploadTaskDocument(file, companyId, taskId) {
        var resp = this. ValidateDocInput(file)
        if(resp){
        let response = null;
        const data = new FormData();
        data.append('file', file);
        data.append('company_id', companyId);
        data.append('task_id', taskId);
        const xhr = new XMLHttpRequest();
        let url = this.apiRoot + 'uploaddocuments/uploadTaskDocument';
        xhr.open('POST', url, false);
        xhr.setRequestHeader('Authorization', this.token);
        xhr.setRequestHeader('UserId', this.UserId);
        xhr.send(data);
        xhr.onload = function () {
            response = xhr.response;
        };
        if (xhr.readyState == 4 && xhr.status == 200) {
            response = xhr.response; // no parsing needed
        }
        return response;
    }
    else{
        let  response = JSON.stringify({success: 0,message: "This file type is not supported" });
           return response;
      }
    }

    deleteLogoFile(path) {
        const headers = new HttpHeaders().set('Authorization', this.token).set('UserId', this.UserId);
        return this.http.post(this.apiRoot + 'CompanyCompliance/deleteFile', { params: { filePath: path } }, { headers }).pipe( tap(() => {}, (err: any) => {
            this.errorHandler(err)
      }));
    }

    deleteUploadPdf(allocId, filename) {
        const headers = new HttpHeaders().set('Authorization', this.token).set('UserId', this.UserId);
        return this.http.post(this.apiRoot + 'uploaddocuments/deleteUploadPdf', { params: { job_alloc_id: allocId, fileName: filename } }, { headers }).pipe( tap(() => {}, (err: any) => {
            this.errorHandler(err)
      }));
    }

    deleteUploadPdf_dup(allocId, filename) {
        return this.http.post(this.apiRoot + 'clientdetails/deleteUploadPdf', { params: { job_alloc_id: allocId, fileName: filename } }).pipe( tap(() => {}, (err: any) => {
            this.errorHandler(err)
      }));
    }


    
    uploadDocumentImgs(file, job_id, job_alloc_id) {
        // var resp = this. ValidateDocInput(file)
        // if(resp){
            let response = null;
            const data = new FormData();
            data.append('file', file);
            data.append('job_id', job_id);
            data.append('job_alloc_id', job_alloc_id);
            const xhr = new XMLHttpRequest();
            let url = this.apiRoot + 'uploaddocuments/uploadImgs';
            xhr.open('POST', url, false);
            xhr.setRequestHeader('Authorization', this.token);
            xhr.setRequestHeader('UserId', this.UserId);
            xhr.send(data);
            xhr.onload = function () {
                response = xhr.response;
            };
            if (xhr.readyState == 4 && xhr.status == 200) {
                response = xhr.response; // no parsing needed
            }
            return response;
        // }
        // else{
        //   let  response = JSON.stringify({success: 0,message: "This file type is not supported" });
        //      return response;
        // }
      
    }


     uploadDocument(file, job_id, job_alloc_id) {
        var resp = this. ValidateDocInput(file)
        if(resp){
            let response = null;
            const data = new FormData();
            data.append('file', file);
            data.append('job_id', job_id);
            data.append('job_alloc_id', job_alloc_id);
            const xhr = new XMLHttpRequest();
            let url = this.apiRoot + 'uploaddocuments/upload';
            xhr.open('POST', url, false);
            xhr.setRequestHeader('Authorization', this.token);
            xhr.setRequestHeader('UserId', this.UserId);
            xhr.send(data);
            xhr.onload = function () {
                response = xhr.response;
            };
            if (xhr.readyState == 4 && xhr.status == 200) {
                response = xhr.response; // no parsing needed
            }
            return response;
        }
        else{
          let  response = JSON.stringify({success: 0,message: "This file type is not supported" });
             return response;
        }
      
    }
    
     ValidateDocInput(oInput) {
           var allowedExtensions = environment.documentfiletype 
          if (allowedExtensions.exec(oInput.name)) {
            console.log("true");
            return true
         } else {
            console.log("false");
            return false
         }
    }

    updateScheduleStatus(id: string, status: string, comment: string) {
        const headers = new HttpHeaders().set('Authorization', this.token).set('UserId', this.UserId);
        return this.http.get(this.apiRoot + 'JobSchedule/updatestatus', { headers, params: { status, jobschedId: id, comment } }).pipe( tap(() => {}, (err: any) => {
            this.errorHandler(err)
      }));
    }

    updatePMOnly(id: string, status: string) {
        const headers = new HttpHeaders().set('Authorization', this.token).set('UserId', this.UserId);
        return this.http.get(this.apiRoot + 'JobSchedule/updatePmOnly', { headers, params: { data: status, jobschedId: id } }).pipe( tap(() => {}, (err: any) => {
            this.errorHandler(err)
      }));
    }
    updatePinstateOnly(id: string, status: any) {
        const headers = new HttpHeaders().set('Authorization', this.token).set('UserId', this.UserId);
        return this.http.get(this.apiRoot + 'JobSchedule/updatePinstateOnly', { headers, params: { data: status, jobschedId: id } }).pipe( tap(() => {}, (err: any) => {
            this.errorHandler(err)
      }));
    }
    getByValuesetdocumentdesc() {
        const headers = new HttpHeaders().set('Authorization', this.token).set('UserId', this.UserId);
        return this.http.get(this.apiRoot + 'ValuesetDetail/getByValuesetdocumentdesc', { headers, params: {} }).pipe( tap(() => {}, (err: any) => {
            this.errorHandler(err)
      }));
    }

    getByValuesetdocumentdescHidefromTP() {
        const headers = new HttpHeaders().set('Authorization', this.token).set('UserId', this.UserId);
        return this.http.get(this.apiRoot + 'ValuesetDetail/getByValuesetdocumentdescHidefromTP', { headers, params: {} }).pipe( tap(() => {}, (err: any) => {
            this.errorHandler(err)
      }));
    }

    getSelectedByValuesetdocumentdesc(id) {
        const headers = new HttpHeaders().set('Authorization', this.token).set('UserId', this.UserId);
        return this.http.get(this.apiRoot + 'ValuesetDetail/getSelectedByValuesetdocumentdesc', { headers, params: { id } }).pipe( tap(() => {}, (err: any) => {
            this.errorHandler(err)
      }));
    }
    downloadDocument(file_path) {
        const headers = new HttpHeaders().set('Authorization', this.token).set('UserId', this.UserId);
        return this.http.get(this.apiRoot + 'uploaddocuments/download', { responseType: 'blob', headers, params: { filePath: file_path } }).pipe( tap(() => {}, (err: any) => {
            this.errorHandler(err);
            // this.handleFileDownloadError(err);
        }));
    }

    downloadDocument_dup(file_path) {
        return this.http.get(this.apiRoot + 'clientdetails/download', { responseType: 'blob', params: { filePath: file_path } }).pipe( tap(() => {}, (err: any) => {
            this.errorHandler(err)
      }));
    }

    getCostFormDetailByJobIdAllocId(job_id, job_alloc_id) {
        const headers = new HttpHeaders().set('Authorization', this.token).set('UserId', this.UserId);
        return this.http.get(this.apiRoot + 'CostFormDetail/getCostFormDetailByJobIdAllocId22/', { headers, params: { job_id, job_alloc_id } }).pipe( tap(() => {}, (err: any) => {
            this.errorHandler(err)
      }));
    };
    getCostFormDetailByJobIdAllocId2(job_id, job_alloc_id) {
        const headers = new HttpHeaders().set('Authorization', this.token).set('UserId', this.UserId);
        return this.http.get(this.apiRoot + 'CostFormDetail2/getCostFormDetailByJobIdAllocId2/', { headers, params: { job_id, job_alloc_id } }).pipe( tap(() => {}, (err: any) => {
            this.errorHandler(err)
      }));
    };
    getCostFormDetailByJobIdAllocIdInNewWpQuote2(job_id, job_alloc_id) {
        const headers = new HttpHeaders().set('Authorization', this.token).set('UserId', this.UserId);
        return this.http.get(this.apiRoot + 'CostFormDetail/getCostFormDetailByJobIdAllocIdInNewWpQuote2/', { headers, params: { job_id, job_alloc_id } }).pipe( tap(() => {}, (err: any) => {
            this.errorHandler(err)
      }));
    };
    getCostFormDetailByJobIdAllocIdNoOrder(job_id, job_alloc_id) {
        const headers = new HttpHeaders().set('Authorization', this.token).set('UserId', this.UserId);
        return this.http.get(this.apiRoot + 'CostFormDetail/getCostFormDetailByJobIdAllocId22/', { headers, params: { job_id, job_alloc_id } }).pipe( tap(() => {}, (err: any) => {
            this.errorHandler(err)
      }));
    };
    getByItemDefn(rateset_id) {
        const headers = new HttpHeaders().set('Authorization', this.token).set('UserId', this.UserId);
        return this.http.get(this.apiRoot + 'ItemDefn/getByItemDefn/', { headers, params: { rateset_id } }).pipe( tap(() => {}, (err: any) => {
            this.errorHandler(err)
      }));
    };
    getCostFormHeadByJobIdAllocId(job_id, job_alloc_id) {
        const headers = new HttpHeaders().set('Authorization', this.token).set('UserId', this.UserId);
        return this.http.get(this.apiRoot + 'CostFormHead/getCostFormHeadByJobIdAllocId/', { headers, params: { job_id, job_alloc_id } }).pipe( tap(() => {}, (err: any) => {
            this.errorHandler(err)
      }));
    };
    getRateClassByRSID(rateSetId) {
        const headers = new HttpHeaders().set('Authorization', this.token).set('UserId', this.UserId);
        return this.http.get(this.apiRoot + 'RateClass/getByRSID', { headers, params: { id2: rateSetId } }).pipe( tap(() => {}, (err: any) => {
            this.errorHandler(err)
      }));
    };
    getAllCostHeadTemplates() {
        const headers = new HttpHeaders().set('Authorization', this.token).set('UserId', this.UserId);
        return this.http.get(this.apiRoot + 'CostFormHead/getAllCostHeadTemplates/', { headers, params: {} }).pipe( tap(() => {}, (err: any) => {
            this.errorHandler(err)
      }));
    };

    async getJobData(jobId, jobAllocId) {
        const headers = new HttpHeaders().set('Authorization', this.token).set('UserId', this.UserId);
        return await this.http.get(this.apiRoot + 'JobAllocation/getJobNotes/', { headers, params: { job_id: jobId, job_alloc_id: jobAllocId } }).toPromise();
    };

    autoCalculateMargin(job_id, job_alloc_id,min_threshold,max_threshold,WPCompanyId,overWrite,sendEmail) {
        const headers = new HttpHeaders().set('Authorization', this.token).set('UserId', this.UserId);
        return this.http.get(this.apiRoot + 'CostFormHead/autoCalulateMargin/', { headers, params: { job_id, job_alloc_id, min_threshold, max_threshold, WPCompanyId, overWrite, sendEmail } }).pipe( tap(() => {}, (err: any) => {
            this.errorHandler(err)
      }));
    };

    getJobSummary(jobId, jobAllocId) {
        const headers = new HttpHeaders().set('Authorization', this.token).set('UserId', this.UserId);
        return this.http.get(this.apiRoot + 'JobAllocation/getJobNotes/', { headers, params: { job_id: jobId, job_alloc_id: jobAllocId } }).pipe( tap(() => {}, (err: any) => {
            this.errorHandler(err)
      }));
    };

    getJobSummaryByJobId(jobId) {
        const headers = new HttpHeaders().set('Authorization', this.token).set('UserId', this.UserId);
        return this.http.get(this.apiRoot + 'JobInfo/getJobInformation/', { headers, params: { job_id: jobId } }).pipe( tap(() => {}, (err: any) => {
            this.errorHandler(err)
      }));
    };

    getJobSummary_dup(jobId, jobAllocId) {
        return this.http.get(this.apiRoot + 'clientdetails/getJobNotes', { params: { job_id: jobId, job_alloc_id: jobAllocId } }).pipe( tap(() => {}, (err: any) => {
            this.errorHandler(err)
      }));
    };

    getCostFormDetailByJobIdAllocIdInWpQuote22(job_id, job_alloc_id) {
        const headers = new HttpHeaders().set('Authorization', this.token).set('UserId', this.UserId);
        return this.http.get(this.apiRoot + 'CostFormDetail/getCostFormDetailByJobIdAllocIdInWpQuote22/', { headers, params: { job_id, job_alloc_id } }).pipe( tap(() => {}, (err: any) => {
            this.errorHandler(err)
      }));
    };
    getAllRateSetsByCompanyId(companyId) {
        const headers = new HttpHeaders().set('Authorization', this.token).set('UserId', this.UserId);
        return this.http.get(this.apiRoot + 'RateSet/getAllRateSetsByCompanyId/', { headers, params: { companyId } }).pipe( tap(() => {}, (err: any) => {
            this.errorHandler(err)
      }));
    };
    getRateSetById(companyId, id) {
        const headers = new HttpHeaders().set('Authorization', this.token).set('UserId', this.UserId);
        return this.http.get(this.apiRoot + 'RateSet/getRateSetById/', { headers, params: { companyId, rate_set_id: id } }).pipe( tap(() => {}, (err: any) => {
            this.errorHandler(err)
      }));
    };
    getAdditionalDamageInfo(jobId, allocId) {
        const headers = new HttpHeaders().set('Authorization', this.token).set('UserId', this.UserId);
        return this.http.get(this.apiRoot + 'AdditionalDamageInfo/getByJobIdAllocId/', { headers, params: { jobId, allocId } }).pipe( tap(() => {}, (err: any) => {
            this.errorHandler(err)
      }));
    };
    getBeforeImgCount(jobId, allocId) {
        const headers = new HttpHeaders().set('Authorization', this.token).set('UserId', this.UserId);
        return this.http.get(this.apiRoot + 'uploadimages/getBeforeImgCount/', { headers, params: { job_id: jobId, job_alloc_id: allocId } }).pipe( tap(() => {}, (err: any) => {
            this.errorHandler(err)
      }));
    };
    getAfterImgCount(jobId, allocId) {
        const headers = new HttpHeaders().set('Authorization', this.token).set('UserId', this.UserId);
        return this.http.get(this.apiRoot + 'uploadimages/getAfterImgCount/', { headers, params: { job_id: jobId, job_alloc_id: allocId } }).pipe( tap(() => {}, (err: any) => {
            this.errorHandler(err)
      }));
    };
    getSHFStatus(jobId, allocId) {
        const headers = new HttpHeaders().set('Authorization', this.token).set('UserId', this.UserId);
        return this.http.get(this.apiRoot + 'JobSiteHazard/getSHFStatus/', { headers, params: { job_id: jobId, job_alloc_id: allocId } }).pipe( tap(() => {}, (err: any) => {
            this.errorHandler(err)
      }));
    };
    getApprovalLimitDetails(companyId, reqId) {
        const headers = new HttpHeaders().set('Authorization', this.token).set('UserId', this.UserId);
        return this.http.get(this.apiRoot + 'CostFormHead/getApprovalLimitDetails/', { headers, params: { companyId, reqId } }).pipe( tap(() => {}, (err: any) => {
            this.errorHandler(err)
      }));
    }


    getApprovalLimitList(companyId) {
        const headers = new HttpHeaders().set('Authorization', this.token).set('UserId', this.UserId);
        return this.http.get(this.apiRoot + 'CostFormHead/getApprovalLimitList/', { headers, params: { companyId } }).pipe( tap(() => {}, (err: any) => {
            this.errorHandler(err)
      }));
    }


    updateCostFormHeadStatus(jobId, allocId, status) {
        const headers = new HttpHeaders().set('Authorization', this.token).set('UserId', this.UserId);
        return this.http.post(this.apiRoot + 'CostFormHead/updateCostFormHeadStatus', { params: { job_id: jobId, job_alloc_id: allocId, status } }, { headers }).pipe( tap(() => {}, (err: any) => {
            this.errorHandler(err)
      }));
    };
    sendMessage(params) {
        const headers = new HttpHeaders().set('Authorization', this.token).set('UserId', this.UserId);
        return this.http.get(this.apiRoot + 'send/sendViaSENDGRID?data=' + JSON.stringify(params), { headers, params: {} }).pipe( tap(() => {}, (err: any) => {
            this.errorHandler(err)
      }));
    }

    // sendTMV2MessageEmail(params) {
    //     console.log("sendTMV2Message",params);
    //   //  var token = "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpZCI6IjEiLCJpYXQiOjE1MDY1NjA5ODB9.LAGHEE6tyd9FmZXaK40yDeUkHVZkOl8MdXSrtEcGwB4";
    //     const headers = new HttpHeaders().set('Authorization', this.token);
    //     if (environment.hosting == 'UK') {
    //         return this.http.get(environment.smsApiRoot + 'send/UK?data=' + JSON.stringify(params), { headers, params: {} });
    //     }
    //     else
    //     {
    //         return this.http.get(environment.smsApiRoot + 'send/2?data=' + JSON.stringify(params), { headers, params: {} });
    //     }
        
    // }
    sendTMV2Message(params) {
        console.log("sendTMV2Message",params);
        const headers = new HttpHeaders().set('Authorization', this.token).set('UserId', this.UserId);
        if (environment.hosting == 'UK') {
            return this.http.get(this.apiRoot + 'smsemail/UK?data=' + JSON.stringify(params), { headers, params: {} }).pipe( tap(() => {}, (err: any) => {
                this.errorHandler(err)
          }));
        }
        else
        {
            return this.http.get(this.apiRoot + 'smsemail/2?data=' + JSON.stringify(params), { headers, params: {} }).pipe( tap(() => {}, (err: any) => {
                this.errorHandler(err)
          }));
        }
        
    }

    getSendGridList(module) {
        const headers = new HttpHeaders().set('Authorization', this.token);
            return this.http.post(this.apiRoot + 'SystemReminders/'+module,  { headers, params: {} });
    }
    
    sendmailSendgrid(dataObj,module) {
        const headers = new HttpHeaders().set('Authorization', this.token);
            return this.http.post(this.apiRoot + 'SystemReminders/'+module, dataObj, { headers, params: {} });
    }

    sendMail(params) {
        const headers = new HttpHeaders().set('Authorization', this.token).set('UserId', this.UserId);
        return this.http.get(this.apiRoot + 'send/SendCommsSSP?data=' + JSON.stringify(params), { headers, params: {} }).pipe( tap(() => {}, (err: any) => {
            this.errorHandler(err)
      }));
    }
    getByValuesetName(valuesetName) {
        const headers = new HttpHeaders().set('Authorization', this.token).set('UserId', this.UserId);
        return this.http.get(this.apiRoot + 'ValueSetDetail/getByValuesetName', { headers, params: { valuesetName } }).pipe( tap(() => {}, (err: any) => {
            this.errorHandler(err)
      }));
    };
    getLoadedAgreement = function (job_id, job_alloc_id) {
        const headers = new HttpHeaders().set('Authorization', this.token).set('UserId', this.UserId);
        return this.http.get(this.apiRoot + 'SubContractAgreement/getLoadedAgreement/', { headers, params: { job_id, job_alloc_id } }).pipe( tap(() => {}, (err: any) => {
            this.errorHandler(err)
      }));
    };
    getTpInvoiceNoAndAmount(job_id, job_alloc_id) {
        const headers = new HttpHeaders().set('Authorization', this.token).set('UserId', this.UserId);
        return this.http.get(this.apiRoot + 'CostFormHead/getTpInvoiceNoAndAmount/', { headers, params: { job_id, job_alloc_id } }).pipe( tap(() => {}, (err: any) => {
            this.errorHandler(err)
      }));
    };
    compareTimeOnSite(job_alloc_id) {
        const headers = new HttpHeaders().set('Authorization', this.token).set('UserId', this.UserId);
        return this.http.get(this.apiRoot + 'CostFormHead/compareTimeOnSite/', { headers, params: { job_alloc_id } }).pipe( tap(() => {}, (err: any) => {
            this.errorHandler(err)
      }));
    };
    getTpWpInvoiceNoAndAmount(job_id, job_alloc_id) {
        const headers = new HttpHeaders().set('Authorization', this.token).set('UserId', this.UserId);
        return this.http.get(this.apiRoot + 'CostFormHead/getTpWpInvoiceNoAndAmount/', { headers, params: { job_id, job_alloc_id } }).pipe( tap(() => {}, (err: any) => {
            this.errorHandler(err)
      }));
    };
    updateTpInvoiceNo(tp_invoice_no, job_id, job_alloc_id) {
        const headers = new HttpHeaders().set('Authorization', this.token).set('UserId', this.UserId);
        return this.http.post(this.apiRoot + 'CostFormHead/updateTpInvoiceNo/', { params: { tp_invoice_no, job_id, job_alloc_id } }, { headers }).pipe( tap(() => {}, (err: any) => {
            this.errorHandler(err)
      }));
    };

    updateVideoDescription(id,description){

        const headers = new HttpHeaders().set('Authorization', this.token).set('UserId', this.UserId);
        return this.http.post(this.apiRoot + 'uploadvideos/updateVideoDescription/', { params: { id,description } }, { headers }).pipe( tap(() => {}, (err: any) => {
            this.errorHandler(err)
      }));

    }


    updateTpInvoiceStatus(job_id, job_alloc_id, inv_status, invDate) {
        const headers = new HttpHeaders().set('Authorization', this.token).set('UserId', this.UserId);
        return this.http.post(this.apiRoot + 'JobAllocation/updateTpInvoiceStatus/', { params: { job_id, job_alloc_id, inv_status, invDate } }, { headers }).pipe( tap(() => {}, (err: any) => {
            this.errorHandler(err)
      }));
    };
    resetToAwaitInvoice(job_id, job_alloc_id, inv_status, invDate) {
        const headers = new HttpHeaders().set('Authorization', this.token).set('UserId', this.UserId);
        return this.http.post(this.apiRoot + 'JobAllocation/resetToAwaitInvoice/', { params: { job_id, job_alloc_id } }, { headers }).pipe( tap(() => {}, (err: any) => {
            this.errorHandler(err)
      }));
    };
    updateWpInvoiceNo(wp_invoice_no, wp_inv_dt, tp_pay_dt, mark_completeUI, job_id, job_alloc_id, tp_invoice_no) {
        const headers = new HttpHeaders().set('Authorization', this.token).set('UserId', this.UserId);
        return this.http.post(this.apiRoot + 'CostFormHead/updateWpInvoiceNo/', { params: { tp_invoice_no, wp_invoice_no, wp_inv_dt, tp_pay_dt, mark_completeUI, job_id, job_alloc_id } }, { headers }).pipe( tap(() => {}, (err: any) => {
            this.errorHandler(err)
      }));
    };
    updateWpInvoiceStatus(job_id, job_alloc_id, inv_status, invDate) {
        const headers = new HttpHeaders().set('Authorization', this.token).set('UserId', this.UserId);
        return this.http.post(this.apiRoot + 'JobAllocation/updateWpInvoiceStatus/', { params: { job_id, job_alloc_id, inv_status, invDate } }, { headers }).pipe( tap(() => {}, (err: any) => {
            this.errorHandler(err)
      }));
    };
    getAllSHFData(job_id, job_alloc_id) {
        const headers = new HttpHeaders().set('Authorization', this.token).set('UserId', this.UserId);
        return this.http.get(this.apiRoot + 'JobSiteHazard/getAllSHFData/', { headers, params: { job_id, job_alloc_id } }).pipe( tap(() => {}, (err: any) => {
            this.errorHandler(err)
      }));
    };
    getLastInsertedId(jobId, allocId) {
        const headers = new HttpHeaders().set('Authorization', this.token).set('UserId', this.UserId);
        return this.http.get(this.apiRoot + 'JobSiteHazard/getLastInsertedId/', { headers, params: { job_id: jobId, job_alloc_id: allocId } }).pipe( tap(() => {}, (err: any) => {
            this.errorHandler(err)
      }));
    };
    getReportData(job_alloc_id) {
        const headers = new HttpHeaders().set('Authorization', this.token).set('UserId', this.UserId);
        return this.http.get(this.apiRoot + '/getReportData', { params: { job_alloc_id } }).pipe( tap(() => {}, (err: any) => {
            this.errorHandler(err)
      }));
    };
    getSignatureBySiteHazardId(site_hazard_id) {
        const headers = new HttpHeaders().set('Authorization', this.token).set('UserId', this.UserId);
        return this.http.get(this.apiRoot + 'JobSiteHazardSignature/getSignatureBySiteHazardId/', { headers, params: { site_hazard_id } }).pipe( tap(() => {}, (err: any) => {
            this.errorHandler(err)
      }));
    };
    getStaffByCompany = function (companyID) {
        const headers = new HttpHeaders().set('Authorization', this.token).set('UserId', this.UserId);
        return this.http.get(this.api + 'CompanyStaffInfo/getStaffByCompany', { headers, params: { companyID } }).pipe( tap(() => {}, (err: any) => {
            this.errorHandler(err)
      }));
    };
    sendNotification(channel, message) {
        const headers = new HttpHeaders().set('Authorization', this.token).set('UserId', this.UserId);
        return this.http.post(this.apiRoot + 'send-notification', { headers, params: { Message: message } }).pipe( tap(() => {}, (err: any) => {
            this.errorHandler(err)
      }));
    }
    getAllKPIs() {
        const headers = new HttpHeaders().set('Authorization', this.token).set('UserId', this.UserId);
        return this.http.get(this.apiRoot + 'KPIMeasures/getAllKPIs', { headers }).pipe( tap(() => {}, (err: any) => {
            this.errorHandler(err)
      }));
    };
    getJobKpiValueSet() {
        const headers = new HttpHeaders().set('Authorization', this.token).set('UserId', this.UserId);
        return this.http.get(this.apiRoot + 'JobKPI/getJobKpiValueSet', { headers }).pipe( tap(() => {}, (err: any) => {
            this.errorHandler(err)
      }));
    };
    getKpiByJobIdAllocId(job_id, job_alloc_id) {
        const headers = new HttpHeaders().set('Authorization', this.token).set('UserId', this.UserId);
        return this.http.get(this.apiRoot + 'JobKPI/getKpiByJobIdAllocId/', { headers, params: { job_id, job_alloc_id } }).pipe( tap(() => {}, (err: any) => {
            this.errorHandler(err)
      }));
    };
    getJobKpiTypes() {
        const headers = new HttpHeaders().set('Authorization', this.token).set('UserId', this.UserId);
        return this.http.get(this.apiRoot + 'JobKPI/getJobKpiTypes/', { headers }).pipe( tap(() => {}, (err: any) => {
            this.errorHandler(err)
      }));
    };

    getCSOReportKPIDetails(job_alloc_id) {
        const headers = new HttpHeaders().set('Authorization', this.token).set('UserId', this.UserId);
        return this.http.get(this.apiRoot + 'JobKPI/getCSOReportKPIDetails/', { headers, params: { job_alloc_id } }).pipe( tap(() => {}, (err: any) => {
            this.errorHandler(err)
      }));
    };

    GetAll() {
        const headers = new HttpHeaders().set('Authorization', this.token).set('UserId', this.UserId);
        return this.http.get(this.apiRoot + 'ValueSet/getAll', { headers }).pipe( tap(() => {}, (err: any) => {
            this.errorHandler(err)
      }));
    };
    getCostDetailStaffInfo() {
        const headers = new HttpHeaders().set('Authorization', this.token).set('UserId', this.UserId);
        return this.http.get(this.apiRoot + 'CostFormDetail/getCostDetailStaffInfo/', { headers }).pipe( tap(() => {}, (err: any) => {
            this.errorHandler(err)
      }));
    };
    getCSOData(allocId) {
        const headers = new HttpHeaders().set('Authorization', this.token).set('UserId', this.UserId);
        return this.http.get(this.apiRoot + 'JobKPI/getCSOData/', { headers, params: { job_alloc_id: allocId } }).pipe( tap(() => {}, (err: any) => {
            this.errorHandler(err)
      }));
    }

    GetById(id) {
        const headers = new HttpHeaders().set('Authorization', this.token).set('UserId', this.UserId);
        return this.http.get(this.apiRoot + 'company/getById', { headers, params: { id } }).pipe( tap(() => {}, (err: any) => {
            this.errorHandler(err)
      }));
    };
    getByCID(companyID) {
        const headers = new HttpHeaders().set('Authorization', this.token).set('UserId', this.UserId);
        return this.http.get(this.apiRoot + 'CompanyAssets/getByCID', { headers, params: { companyID } }).pipe( tap(() => {}, (err: any) => {
            this.errorHandler(err)
      }));
    };
    GetTPRequestingActionView() {
        const headers = new HttpHeaders().set('Authorization', this.token).set('UserId', this.UserId);
        return this.http.get(this.apiRoot + 'Reports/GetTPRequestingActionView', { headers }).pipe( tap(() => {}, (err: any) => {
            this.errorHandler(err)
      }));
    };
    GetAllCompanyCompliances = function () {
        const headers = new HttpHeaders().set('Authorization', this.token).set('UserId', this.UserId);
        return this.http.get(this.apiRoot + 'Reports/GetAllCompanyCompliances', { headers }).pipe( tap(() => {}, (err: any) => {
            this.errorHandler(err)
      }));
    };
    GetAllStaffCompanyCompliances = function () {
        const headers = new HttpHeaders().set('Authorization', this.token).set('UserId', this.UserId);
        return this.http.get(this.apiRoot + 'Reports/GetAllStaffCompanyCompliances', { headers }).pipe( tap(() => {}, (err: any) => {
            this.errorHandler(err)
      }));
    };
    GetAllStaffCompanyCompliancesTP(client_type_id, company_id) {
        const headers = new HttpHeaders().set('Authorization', this.token).set('UserId', this.UserId);
        return this.http.get(this.apiRoot + 'Reports/GetAllStaffCompanyCompliancesTP', { headers, params: { client_type_id, company_id } }).pipe( tap(() => {}, (err: any) => {
            this.errorHandler(err)
      }));
    };
    getCompanyNotesReportView() {
        const headers = new HttpHeaders().set('Authorization', this.token).set('UserId', this.UserId);
        return this.http.get(this.apiRoot + 'Reports/GetCompanyNotesReportView', { headers }).pipe( tap(() => {}, (err: any) => {
            this.errorHandler(err)
      }));
    };
    GetJobOpsScheduleDetailsReport() {
        const headers = new HttpHeaders().set('Authorization', this.token).set('UserId', this.UserId);
        return this.http.get(this.apiRoot + 'Reports/GetJobOpsScheduleDetailsReport', { headers }).pipe( tap(() => {}, (err: any) => {
            this.errorHandler(err)
      }));
    };
    GetJobOpsScheduleDetailsReportTp(client_type_id, company_id) {
        const headers = new HttpHeaders().set('Authorization', this.token).set('UserId', this.UserId);
        return this.http.get(this.apiRoot + 'Reports/GetJobOpsScheduleDetailsReportTp', { headers, params: { client_type_id, company_id } }).pipe( tap(() => {}, (err: any) => {
            this.errorHandler(err)
      }));
    };
    GetJLGOpsReport() {
        const headers = new HttpHeaders().set('Authorization', this.token).set('UserId', this.UserId);
        return this.http.get(this.apiRoot + 'Reports/GetJLGOpsReport', { headers }).pipe( tap(() => {}, (err: any) => {
            this.errorHandler(err)
      }));
    };

    GetJobOpsQuoteBreakdownDetailsReport() {
        const headers = new HttpHeaders().set('Authorization', this.token).set('UserId', this.UserId);
        return this.http.get(this.apiRoot + 'Reports/GetJobOpsQuoteBreakdownDetailsReport', { headers }).pipe( tap(() => {}, (err: any) => {
            this.errorHandler(err)
      }));
    };

    GetAllJobInfoMissingReport() {
        const headers = new HttpHeaders().set('Authorization', this.token).set('UserId', this.UserId);
        return this.http.get(this.apiRoot + 'Reports/GetAllJobInfoMissingReport', { headers }).pipe( tap(() => {}, (err: any) => {
            this.errorHandler(err)
      }));
    };
    getAPPUsageStatsReport() {
        const headers = new HttpHeaders().set('Authorization', this.token).set('UserId', this.UserId);
        return this.http.get(this.apiRoot + 'Reports/getAPPUsageStatsReport', { headers }).pipe( tap(() => {}, (err: any) => {
            this.errorHandler(err)
      }));
    };
    JobsClientsReportFiltered = function (category, from_date, to_date) {
        const headers = new HttpHeaders().set('Authorization', this.token).set('UserId', this.UserId);
        return this.http.get(this.apiRoot + 'Reports/JobsClientsReportFiltered', { headers, params: { category, from_date, to_date } }).pipe( tap(() => {}, (err: any) => {
            this.errorHandler(err)
      }));
    };
    GetAllExpiredCompliancesTP(client_type_id, company_id) {
        const headers = new HttpHeaders().set('Authorization', this.token).set('UserId', this.UserId);
        return this.http.get(this.apiRoot + 'Reports/GetAllExpiredCompliancesTP', { headers, params: { client_type_id, company_id } }).pipe( tap(() => {}, (err: any) => {
            this.errorHandler(err)
      }));
    };
    GetAllExpiredCompliances() {
        const headers = new HttpHeaders().set('Authorization', this.token).set('UserId', this.UserId);
        return this.http.get(this.apiRoot + 'Reports/GetAllExpiredCompliances', { headers }).pipe( tap(() => {}, (err: any) => {
            this.errorHandler(err)
      }));
    };
    getByJobIdNo(jobNo) {
        const headers = new HttpHeaders().set('Authorization', this.token).set('UserId', this.UserId);
        return this.http.get(this.apiRoot + 'JobSchedule/getByJobIdNo', { headers, params: { jobNo } }).pipe( tap(() => {}, (err: any) => {
            this.errorHandler(err)
      }));
    };
    SESReport() {
        const headers = new HttpHeaders().set('Authorization', this.token).set('UserId', this.UserId);
        return this.http.get(this.apiRoot + 'Reports/GetSESReportData', { headers }).pipe( tap(() => {}, (err: any) => {
            this.errorHandler(err)
      }));
    };

    getJobMarginReportView() {
        const headers = new HttpHeaders().set('Authorization', this.token).set('UserId', this.UserId);
        return this.http.get(this.apiRoot + 'Reports/getJobMarginReportView', { headers }).pipe( tap(() => {}, (err: any) => {
            this.errorHandler(err)
      }));
    };
    getJobMarginReportViewYearsFilter() {
        const headers = new HttpHeaders().set('Authorization', this.token).set('UserId', this.UserId);
        return this.http.get(this.apiRoot + 'Reports/getJobMarginReportViewYearsFilter', { headers }).pipe( tap(() => {}, (err: any) => {
            this.errorHandler(err)
      }));
    };
    getJobMarginReportViewFiltered(where_clause) {
        const headers = new HttpHeaders().set('Authorization', this.token).set('UserId', this.UserId);
        return this.http.post(this.apiRoot + 'Reports/getJobMarginReportViewFiltered', where_clause, { headers }).pipe( tap(() => {}, (err: any) => {
            this.errorHandler(err)
      }));
    };
    getTPQBImportSheetView() {
        const headers = new HttpHeaders().set('Authorization', this.token).set('UserId', this.UserId);
        return this.http.get(this.apiRoot + 'Reports/getTPQBImportSheetView', { headers }).pipe( tap(() => {}, (err: any) => {
            this.errorHandler(err)
      }));
    };
    getWPQuickbooksImportSheetView() {
        const headers = new HttpHeaders().set('Authorization', this.token).set('UserId', this.UserId);
        return this.http.get(this.apiRoot + 'Reports/getWPQBImportSheetView', { headers }).pipe( tap(() => {}, (err: any) => {
            this.errorHandler(err)
      }));
    };
    getWPQBImportSheetViewSearch(DateValue) {
        const headers = new HttpHeaders().set('Authorization', this.token).set('UserId', this.UserId);
        return this.http.get(this.apiRoot + 'Reports/getWPQBImportSheetViewSearch/', { headers, params: { DateValue } }).pipe( tap(() => {}, (err: any) => {
            this.errorHandler(err)
      }));
    };

    fetchBoardDetails(wf_id) {
        const headers = new HttpHeaders().set('Authorization', this.token).set('UserId', this.UserId);
        return this.http.get(this.apiRoot + 'workflow/fetchBoardDetails', { headers, params: { workflow_id: wf_id } }).pipe( tap(() => {}, (err: any) => {
            this.errorHandler(err)
      }));
    };

    fetchColumnsList(wf_id, user_id) {
        const headers = new HttpHeaders().set('Authorization', this.token).set('UserId', this.UserId);
        return this.http.get(this.apiRoot + 'workflow/fetchColumnsList', { headers, params: { workflow_id: wf_id, user_id } }).pipe( tap(() => {}, (err: any) => {
            this.errorHandler(err)
      }));
    };

    fetchTasksList(wf_id, user_id) {
        const headers = new HttpHeaders().set('Authorization', this.token).set('UserId', this.UserId);
        return this.http.get(this.apiRoot + 'workflow/fetchTasksList', { headers, params: { workflow_id: wf_id, user_id } }).pipe( tap(() => {}, (err: any) => {
            this.errorHandler(err)
      }));
    };
    getWorkOrderInfo(jobId, allocId) {
        const headers = new HttpHeaders().set('Authorization', this.token).set('UserId', this.UserId);
        return this.http.get(this.apiRoot + 'WorkOperationsInfo/GetAllByJobIdAllocId/', { headers, params: { job_id: jobId, job_alloc_id: allocId } }).pipe( tap(() => {}, (err: any) => {
            this.errorHandler(err)
      }));
    };
    getCostView(rateSetId) {
        const headers = new HttpHeaders().set('Authorization', this.token).set('UserId', this.UserId);
        return this.http.get(this.apiRoot + 'ItemRates/getCostView/', { headers, params: { rateSetId } }).pipe( tap(() => {}, (err: any) => {
            this.errorHandler(err)
      }));
    };
    getSelectedItemDetails(itemId, rateSetId, rateClassId) {
        const headers = new HttpHeaders().set('Authorization', this.token).set('UserId', this.UserId);
        return this.http.get(this.apiRoot + 'ItemRates/getSelectedItemDetails/', { headers, params: { item_id: itemId, rateset_id: rateSetId, rateclass_id: rateClassId } }).pipe( tap(() => {}, (err: any) => {
            this.errorHandler(err)
      }));
    };
    getAllItemDefn() {
        const headers = new HttpHeaders().set('Authorization', this.token).set('UserId', this.UserId);
        return this.http.get(this.apiRoot + 'ItemDefn/getAllItemDefn/', { headers }).pipe( tap(() => {}, (err: any) => {
            this.errorHandler(err)
      }));
    };
    getAllAllocatedJobByJobId(jobId) {
        const headers = new HttpHeaders().set('Authorization', this.token).set('UserId', this.UserId);
        return this.http.get(this.apiRoot + 'JobAllocation/getAllAllocatedJobByJobIdNew/', { headers, params: { jobId } }).pipe( tap(() => {}, (err: any) => {
            this.errorHandler(err)
      }));
    };
    getDefaultPriorities() {
        const headers = new HttpHeaders().set('Authorization', this.token).set('UserId', this.UserId);
        return this.http.get(this.apiRoot + 'JobPriority/getDefaultPriorities', { headers, params: {} }).pipe( tap(() => {}, (err: any) => {
            this.errorHandler(err)
      }));
    };
    getQuoteApprovalCheckData(height, trunk, work_type) {
        const headers = new HttpHeaders().set('Authorization', this.token).set('UserId', this.UserId);
        return this.http.get(this.apiRoot + 'CostFormHead/getQuoteApprovalCheckData', { headers, params: { height, trunk, work_type } }).pipe( tap(() => {}, (err: any) => {
            this.errorHandler(err)
      }));
    }
    ExecuteQuery(query) {
        const headers = new HttpHeaders().set('Authorization', this.token).set('UserId', this.UserId);
        return this.http.post(this.apiRoot + 'Reports/ExecuteQuery', { params: { query } }, { headers }).pipe( tap(() => {}, (err: any) => {
            this.errorHandler(err)
      }));
    }
    getTasksList(user_id, wf_id) {
        const headers = new HttpHeaders().set('Authorization', this.token).set('UserId', this.UserId);
        return this.http.get(this.apiRoot + 'taskHead/getallTaskByUser', { headers, params: { user_id, wf_id } }).pipe( tap(() => {}, (err: any) => {
            this.errorHandler(err)
      }));
    }
    getAllTaskMessages(task_id) {
        const headers = new HttpHeaders().set('Authorization', this.token).set('UserId', this.UserId);
        return this.http.get(this.apiRoot + 'TaskMessages/getMessages', { headers, params: { task_id } }).pipe( tap(() => {}, (err: any) => {
            this.errorHandler(err)
      }));
    }
    getSSPMenus(companyId, user_group_id) {
        const headers = new HttpHeaders().set('Authorization', this.token).set('UserId', this.UserId);
        return this.http.get(this.apiRoot + 'user/getSSPMenus', { headers, params: { company_id: companyId, user_group_id } }).pipe( tap(() => {}, (err: any) => {
            this.errorHandler(err)
      }));
    }
    getAllocatedUsers(taskId) {
        const headers = new HttpHeaders().set('Authorization', this.token).set('UserId', this.UserId);
        return this.http.get(this.apiRoot + 'taskusers/getAllocateeList', { headers, params: { task_id: taskId } }).pipe( tap(() => {}, (err: any) => {
            this.errorHandler(err)
      }));
    }
    getAllUsersByCompany(company_id) {
        const headers = new HttpHeaders().set('Authorization', this.token).set('UserId', this.UserId);
        return this.http.get(this.apiRoot + 'user/getAllUsersByCompany', { headers, params: { id: company_id } }).pipe( tap(() => {}, (err: any) => {
            this.errorHandler(err)
      }));
    }
    getAllSSPUsers(company_id) {
        const headers = new HttpHeaders().set('Authorization', this.token).set('UserId', this.UserId);
        return this.http.get(this.apiRoot + 'user/getAllSSPUsers', { headers, params: { company_id } }).pipe( tap(() => {}, (err: any) => {
            this.errorHandler(err)
      }));
    }
    getTaskUsers(task_id) {
        const headers = new HttpHeaders().set('Authorization', this.token).set('UserId', this.UserId);
        return this.http.get(this.apiRoot + 'user/getTaskUsers', { headers, params: { task_id } }).pipe( tap(() => {}, (err: any) => {
            this.errorHandler(err)
      }));
    }
    getAllTaskUsers(wf_id) {
        const headers = new HttpHeaders().set('Authorization', this.token).set('UserId', this.UserId);
        return this.http.get(this.apiRoot + 'user/getAllTaskUsers', { headers, params: { wf_id } }).pipe( tap(() => {}, (err: any) => {
            this.errorHandler(err)
      }));
    }
    getUserRoles() {
        const headers = new HttpHeaders().set('Authorization', this.token).set('UserId', this.UserId);
        return this.http.get(this.apiRoot + 'user/getUserRoles', { headers, params: {} }).pipe( tap(() => {}, (err: any) => {
            this.errorHandler(err)
      }));
    }

    resetPasswordInit(mailID) {
        const headers = new HttpHeaders().set('Authorization', this.token).set('UserId', this.UserId);
        return this.http.post(this.apiRoot + 'User/resetPassword/', { params: { email: mailID } }, { headers }).pipe( tap(() => {}, (err: any) => {
            this.errorHandler(err)
      }));
    }

    changePassword(password, username) {
        const headers = new HttpHeaders().set('Authorization', this.token).set('UserId', this.UserId);
        return this.http.post(this.apiRoot + 'User/changePassword', { params: { username, password } }, { headers }).pipe( tap(() => {}, (err: any) => {
            this.errorHandler(err)
      }));
    }
    changeEncryptPassword(password, username) {
        const headers = new HttpHeaders().set('Authorization', this.token).set('UserId', this.UserId);
        return this.http.post(this.apiRoot + 'User/changeEncryptPassword', { params: { username, password } }, { headers }).pipe( tap(() => {}, (err: any) => {
            this.errorHandler(err)
      }));
     }
    getAllByCompanyTypeByState(client_type_id, jobId, stateId, complianceLevelId) {
        const headers = new HttpHeaders().set('Authorization', this.token).set('UserId', this.UserId);
        return this.http.get(this.apiRoot + 'Company/getAllByCompanyTypeByState', { headers, params: { id: client_type_id, jobId, stateId, complianceLevelId } }).pipe( tap(() => {}, (err: any) => {
            this.errorHandler(err)
      }));
    };
    getAllByCompanyTypeByState2(client_type_id, jobId, stateId, complianceLevelId) {
        const headers = new HttpHeaders().set('Authorization', this.token).set('UserId', this.UserId);
        return this.http.get(this.apiRoot + 'Company/getAllByCompanyTypeByState2', { headers, params: { id: client_type_id, jobId, stateId, complianceLevelId } }).pipe( tap(() => {}, (err: any) => {
            this.errorHandler(err)
      }));
    };
    getAllByCompanyTypeByState3(client_type_id, jobId, stateId, complianceLevelId) {
        const headers = new HttpHeaders().set('Authorization', this.token).set('UserId', this.UserId);
        return this.http.get(this.apiRoot + 'Company/getAllByCompanyTypeByState3', { headers, params: { id: client_type_id, jobId, stateId, complianceLevelId } }).pipe( tap(() => {}, (err: any) => {
            this.errorHandler(err)
      }));
    };

    getAllByCompanyTypeAll(client_type_id, jobId, stateId, complianceLevelId) {
        const headers = new HttpHeaders().set('Authorization', this.token).set('UserId', this.UserId);
        return this.http.get(this.apiRoot + 'Company/getAllByCompanyTypeAll', { headers, params: { id: client_type_id, jobId, stateId, complianceLevelId } }).pipe( tap(() => {}, (err: any) => {
            this.errorHandler(err)
      }));
    };

    getAllDpsByState2(client_type_id, stateId, complianceId) {
        const headers = new HttpHeaders().set('Authorization', this.token).set('UserId', this.UserId);
        return this.http.get(this.apiRoot + 'Company/getAllDpsByState2', { headers, params: { id: client_type_id, stateId, complianceId } }).pipe( tap(() => {}, (err: any) => {
            this.errorHandler(err)
      }));
    };
    getAllDpsByState3(client_type_id, stateId, complianceLevelId) {
        const headers = new HttpHeaders().set('Authorization', this.token).set('UserId', this.UserId);
        return this.http.get(this.apiRoot + 'Company/getAllDpsByState3', { headers, params: { id: client_type_id, stateId, complianceLevelId } }).pipe( tap(() => {}, (err: any) => {
            this.errorHandler(err)
      }));
    };
    getAllDpsByState(client_type_id, stateId, complianceId) {
        const headers = new HttpHeaders().set('Authorization', this.token).set('UserId', this.UserId);
        return this.http.get(this.apiRoot + 'Company/getAllDpsByState', { headers, params: { id: client_type_id, stateId, complianceId } }).pipe( tap(() => {}, (err: any) => {
            this.errorHandler(err)
      }));
    };
    getAllComplianceByCompany2(companyId, complianceId) {
        const headers = new HttpHeaders().set('Authorization', this.token).set('UserId', this.UserId);
        return this.http.get(this.apiRoot + 'Company/getAllComplianceByCompany2', { headers, params: { companyId, complianceId } }).pipe( tap(() => {}, (err: any) => {
            this.errorHandler(err)
      }));
    };
    getDocumentDescriptionsQuote(job_alloc_id) {
        const headers = new HttpHeaders().set('Authorization', this.token).set('UserId', this.UserId);
        return this.http.get(this.apiRoot + 'uploaddocuments/getDocumentDescriptionsQuote', { headers, params: { job_alloc_id } }).pipe( tap(() => {}, (err: any) => {
            this.errorHandler(err)
      }));
    };
    registerUser(userDetails) {
        const headers = new HttpHeaders().set('Authorization', this.token).set('UserId', this.UserId);
        return this.http.get(this.apiRoot + 'security/registerUser', { headers, params: { username: userDetails.username, password: userDetails.password, email: userDetails.email } }).pipe( tap(() => {}, (err: any) => {
            this.errorHandler(err)
      }));
    }
    ValidPasswordToken(authToken, emailId) {
        const headers = new HttpHeaders().set('Authorization', this.token).set('UserId', this.UserId);
        return this.http.get(this.apiRoot + 'security/passwordTokenAuth', { headers, params: { email_id: emailId, auth_token: authToken } }).pipe( tap(() => {}, (err: any) => {
            this.errorHandler(err)
      }));
    }
    newPassword(password, emailId) {
        //const headers = new HttpHeaders().set('Authorization', this.token).set('UserId', this.UserId);
        return this.http.post(this.apiRoot + 'security/updatePassword', { params: { email_id: emailId, password } },).pipe( tap(() => {}, (err: any) => {
            this.errorHandler(err)
      }));
    }
    requestReset(userDetails, token) {
       // const headers = new HttpHeaders().set('Authorization', this.token).set('UserId', this.UserId);
        return this.http.post(this.apiRoot + 'security/resetPassword', { params: { token, email_id: userDetails.email } }).pipe( tap(() => {}, (err: any) => {
            this.errorHandler(err)
      }));
    }
    getByValuesetclienttypeWP() {
        const headers = new HttpHeaders().set('Authorization', this.token).set('UserId', this.UserId);
        return this.http.get(this.apiRoot + 'ValueSetDetail/getByValuesetclienttypeWP', { headers, params: {} }).pipe( tap(() => {}, (err: any) => {
            this.errorHandler(err)
      }));
    }
    companyNotes(comapnyId) {
        const headers = new HttpHeaders().set('Authorization', this.token).set('UserId', this.UserId);
        return this.http.get(this.apiRoot + 'Company/companynotes', { headers, params: { id: comapnyId } }).pipe( tap(() => {}, (err: any) => {
            this.errorHandler(err)
      }));
    }
    updateMenuRoles(user_group_id, new_company_id) {
        const headers = new HttpHeaders().set('Authorization', this.token).set('UserId', this.UserId);
        return this.http.post(this.apiRoot + 'User/insertMenuRoles', { params: { user_group_id, new_company_id } }, { headers }).pipe( tap(() => {}, (err: any) => {
            this.errorHandler(err)
      }));
    }
    updateJobStatus(status, id) {
        const headers = new HttpHeaders().set('Authorization', this.token).set('UserId', this.UserId);
        return this.http.get(this.apiRoot + 'JobScheduleService/updateStatus', { headers, params: { status, jobschedId: id } }).pipe( tap(() => {}, (err: any) => {
            this.errorHandler(err)
      }));
    }
    updateCancelStatus(status, status_reason, status_reason_other, id) {
        const headers = new HttpHeaders().set('Authorization', this.token).set('UserId', this.UserId);
        return this.http.post(this.apiRoot + 'JobInfo/updateStatusDetails2', { params: { status, status_reason, status_reason_other, id } }, { headers }).pipe( tap(() => {}, (err: any) => {
            this.errorHandler(err)
      }));
    }
    updateHoldStatus(status, status_reason, status_reason_other, status_reopen_dt, id) {
        const headers = new HttpHeaders().set('Authorization', this.token).set('UserId', this.UserId);
        return this.http.post(this.apiRoot + 'JobInfo/updateStatusDetails', { params: { status_reopen_dt, status, status_reason, status_reason_other, id } }, { headers }).pipe( tap(() => {}, (err: any) => {
            this.errorHandler(err)
      }));
    }
    fetchWFCompanyParams(wf_code, company_id) {
        const headers = new HttpHeaders().set('Authorization', this.token).set('UserId', this.UserId);
        return this.http.get(this.apiRoot + 'workflow/fetchCompanyParams', { headers, params: { workflow_code: wf_code, company_id: company_id } }).pipe( tap(() => {}, (err: any) => {
            this.errorHandler(err)
      }));
    }
    fetchValuesSetByID(valueset_id) {
        const headers = new HttpHeaders().set('Authorization', this.token).set('UserId', this.UserId);
        return this.http.get(this.apiRoot + 'workflow/fetchValuesSetByID', { headers, params: { vs_id: valueset_id } }).pipe( tap(() => {}, (err: any) => {
            this.errorHandler(err)
      }));
    }
    getUploadDocumentsSummary(jobId, allocId) {
        const headers = new HttpHeaders().set('Authorization', this.token).set('UserId', this.UserId);
        return this.http.get(this.apiRoot + 'uploaddocuments/getUploadDocumentsSummary/', { headers, params: { job_id: jobId, job_alloc_id: allocId } }).pipe( tap(() => {}, (err: any) => {
            this.errorHandler(err)
      }));
    };
    getCostingInfo(jobId, allocId) {
        const headers = new HttpHeaders().set('Authorization', this.token).set('UserId', this.UserId);
        return this.http.get(this.apiRoot + 'JobAllocation/getCostingInfo/', { headers, params: { job_id: jobId, job_alloc_id: allocId } }).pipe( tap(() => {}, (err: any) => {
            this.errorHandler(err)
      }));
    };
    getEmailEvents(job_id) {
        const headers = new HttpHeaders().set('Authorization', this.token).set('UserId', this.UserId);
        return this.http.get(this.apiRoot + 'JobAllocation/getEmailEvents/', { headers, params: { job_id: job_id } }).pipe( tap(() => {}, (err: any) => {
            this.errorHandler(err)
      }));
    };
    getCompanyEmailEvents(company_id) {
        const headers = new HttpHeaders().set('Authorization', this.token).set('UserId', this.UserId);
        return this.http.get(this.apiRoot + 'Company/getCompanyEmailEvents/', { headers, params: { company_id } }).pipe( tap(() => {}, (err: any) => {
            this.errorHandler(err)
      }));
    };
    getWPPdfBaseValues(allocId) {
        const headers = new HttpHeaders().set('Authorization', this.token).set('UserId', this.UserId);
        return this.http.get(this.apiRoot + 'generate/getWPPdfBaseValues/', { headers, params: { job_alloc_id: allocId } }).pipe( tap(() => {}, (err: any) => {
            this.errorHandler(err)
      }));
    };
    getWPPdfCostValues(allocId) {
        const headers = new HttpHeaders().set('Authorization', this.token).set('UserId', this.UserId);
        return this.http.get(this.apiRoot + 'generate/getWPPdfCostValues/', { headers, params: { job_alloc_id: allocId } }).pipe( tap(() => {}, (err: any) => {
            this.errorHandler(err)
      }));
    };
    ListingPageTPOperationsAll(company_id) {
        const headers = new HttpHeaders().set('Authorization', this.token).set('UserId', this.UserId);
        return this.http.get(this.apiRoot + 'JobInfo/ListingPageTPOperationsAll/', { headers, params: { company_id } }).pipe( tap(() => {}, (err: any) => {
            this.errorHandler(err)
      }));
    };
    ListingPageTPOperationsAllLicense(company_id) {
        const headers = new HttpHeaders().set('Authorization', this.token).set('UserId', this.UserId);
        return this.http.get(this.apiRoot + 'JobInfo/ListingPageTPOperationsAllLicense/', { headers, params: { company_id } }).pipe( tap(() => {}, (err: any) => {
            this.errorHandler(err)
      }));
    };
    ListingPageTPOperationsActive2(object) {
        const headers = new HttpHeaders().set('Authorization', this.token).set('UserId', this.UserId);
        return this.http.post(this.apiRoot + 'JobInfo/ListingPageTPOperationsActive2/', { params: { object } }, { headers }).pipe( tap(() => {}, (err: any) => {
            this.errorHandler(err)
      }));
    };
    ListingPageOperationsActive2(object) {
        const headers = new HttpHeaders().set('Authorization', this.token).set('UserId', this.UserId);
        return this.http.post(this.apiRoot + 'JobInfo/ListingPageOperationsActive2/', { params: { object } }, { headers }).pipe( tap(() => {}, (err: any) => {
            this.errorHandler(err)
      }));
    };
    ListingPageOperationsActiveDashboard(object) {
        const headers = new HttpHeaders().set('Authorization', this.token).set('UserId', this.UserId);
        return this.http.post(this.apiRoot + 'JobInfo/ListingPageOperationsActiveDashboard/', { params: { object } }, { headers }).pipe( tap(() => {}, (err: any) => {
            this.errorHandler(err)
      }));
    };
    ListingPageTPOperationsActive2License(object) {
        const headers = new HttpHeaders().set('Authorization', this.token).set('UserId', this.UserId);
        return this.http.post(this.apiRoot + 'JobInfo/ListingPageTPOperationsActive2License/', { params: { object } }, { headers }).pipe( tap(() => {}, (err: any) => {
            this.errorHandler(err)
      }));
    };
    getInvStepsStatus(allocId) {
        const headers = new HttpHeaders().set('Authorization', this.token).set('UserId', this.UserId);
        return this.http.get(this.apiRoot + 'JobInfo/getInvStepsStatus/', { headers, params: { job_alloc_id: allocId } }).pipe( tap(() => {}, (err: any) => {
            this.errorHandler(err)
      }));
    };
    getInvoiceDetails(tpID) {
        const headers = new HttpHeaders().set('Authorization', this.token).set('UserId', this.UserId);
        return this.http.get(this.apiRoot + 'Company/getInvoiceDetails/', { headers, params: { id: tpID } }).pipe( tap(() => {}, (err: any) => {
            this.errorHandler(err)
      }));
    };
    
    getSearchList(additionalCondition) {
        const headers = new HttpHeaders().set('Authorization', this.token).set('UserId', this.UserId);
        return this.http.post(this.apiRoot + 'JobInfo/ListingPageOperationsAllNew/', { params: { additionalCondition } }, { headers }).pipe( tap(() => {}, (err: any) => {
            this.errorHandler(err)
      }));
    }
    getOtherAllocationDetails(job_id) {
        const headers = new HttpHeaders().set('Authorization', this.token).set('UserId', this.UserId);
        return this.http.get(this.apiRoot + 'JobInfo/getOtherAllocationDetails/', { headers, params: { job_id } }).pipe( tap(() => {}, (err: any) => {
            this.errorHandler(err)
      }));
    };
    checkIfAdmin(userId) {
        const headers = new HttpHeaders().set('Authorization', this.token).set('UserId', this.UserId);
        return this.http.get(this.apiRoot + 'Company/checkIfAdmin/', { headers, params: { userId } }).pipe( tap(() => {}, (err: any) => {
            this.errorHandler(err)
      }));
    };
    getAllComplianceDefn() {
        const headers = new HttpHeaders().set('Authorization', this.token).set('UserId', this.UserId);
        return this.http.get(this.apiRoot + 'ComplianceDefn/getAllForCompany/', { headers, params: {} }).pipe( tap(() => {}, (err: any) => {
            this.errorHandler(err)
      }));
    };
    updateFlag(job_alloc_id, flag_detail) {
        const headers = new HttpHeaders().set('Authorization', this.token).set('UserId', this.UserId);
        return this.http.post(this.apiRoot + 'joballocation/flag/', { params: { job_alloc_id, flag_detail } }, { headers }).pipe( tap(() => {}, (err: any) => {
            this.errorHandler(err)
      }));
    };
    getCostFormDetailByJobIdAllocIdForAllWpQuote(jobId, allocId) {
        const headers = new HttpHeaders().set('Authorization', this.token).set('UserId', this.UserId);
        return this.http.get(this.apiRoot + 'CostFormDetail2/getCostFormDetailByJobIdAllocIdForAllWpQuote/', { headers, params: { job_id: jobId, job_alloc_id: allocId } }).pipe( tap(() => {}, (err: any) => {
            this.errorHandler(err)
      }));
    };
    getCostFormDetailByJobIdAllocIdForAllWpQuote2(jobId, allocId) {
        const headers = new HttpHeaders().set('Authorization', this.token).set('UserId', this.UserId);
        return this.http.get(this.apiRoot + 'CostFormDetail2/getCostFormDetailByJobIdAllocIdForAllWpQuote2/', { headers, params: { job_id: jobId, job_alloc_id: allocId } }).pipe( tap(() => {}, (err: any) => {
            this.errorHandler(err)
      }));
    };
    getBankDetail(job_alloc_id) {
        const headers = new HttpHeaders().set('Authorization', this.token).set('UserId', this.UserId);
        return this.http.get(this.apiRoot + 'JobInfo/getBankDetail/', { headers, params: { job_alloc_id } }).pipe( tap(() => {}, (err: any) => {
            this.errorHandler(err)
      }));
    };
    getSiteInfo(job_id) {
        const headers = new HttpHeaders().set('Authorization', this.token).set('UserId', this.UserId);
        return this.http.get(this.apiRoot + 'JobInfo/getSiteInfo/', { headers, params: { job_id } }).pipe( tap(() => {}, (err: any) => {
            this.errorHandler(err)
      }));
    };

    getEnviroBankDetail(id) {
        const headers = new HttpHeaders().set('Authorization', this.token).set('UserId', this.UserId);
        return this.http.get(this.apiRoot + 'JobInfo/getEnviroBankDetail/', { headers, params: { id } }).pipe( tap(() => {}, (err: any) => {
            this.errorHandler(err)
      }));
    }
    getTPLocationReport() {
        const headers = new HttpHeaders().set('Authorization', this.token).set('UserId', this.UserId);
        return this.http.get(this.apiRoot + 'Reports/getTPLocationReport/', { headers, params: {} }).pipe( tap(() => {}, (err: any) => {
            this.errorHandler(err)
      }));
    }
    getJobLocationReport() {
        const headers = new HttpHeaders().set('Authorization', this.token).set('UserId', this.UserId);
        return this.http.get(this.apiRoot + 'Reports/getJobLocationReport/', { headers, params: {} }).pipe( tap(() => {}, (err: any) => {
            this.errorHandler(err)
      }));
    }
    getJobLocationReportTp(client_type_id, company_id) {
        const headers = new HttpHeaders().set('Authorization', this.token).set('UserId', this.UserId);
        return this.http.get(this.apiRoot + 'Reports/getJobLocationReportTp/', { headers, params: { client_type_id, company_id } }).pipe( tap(() => {}, (err: any) => {
            this.errorHandler(err)
      }));
    }
    getJobInitialLocationReport() {
        const headers = new HttpHeaders().set('Authorization', this.token).set('UserId', this.UserId);
        return this.http.get(this.apiRoot + 'Reports/getJobInitialLocationReport/', { headers, params: {} }).pipe( tap(() => {}, (err: any) => {
            this.errorHandler(err)
      }));
    }
    backupImage(imgPath) {
        const headers = new HttpHeaders().set('Authorization', this.token).set('UserId', this.UserId);
        return this.http.get(this.apiRoot + 'uploadimages/backupImg', { headers, params: { imgPath } }).pipe( tap(() => {}, (err: any) => {
            this.errorHandler(err)
      }));
    }
    deleteImage(imgPath) {
        const headers = new HttpHeaders().set('Authorization', this.token).set('UserId', this.UserId);
        return this.http.post(this.apiRoot + 'uploadimages/deleteImg', { params: { imgPath } }, { headers }).pipe( tap(() => {}, (err: any) => {
            this.errorHandler(err)
      }));
    }
    updateAllocationWorkflowStatus(wf_status_id, wf_status, remarks, job_alloc_id) {
        const headers = new HttpHeaders().set('Authorization', this.token).set('UserId', this.UserId);
        return this.http.post(this.apiRoot + 'joballocation/UpdateAllocationWFStatus', { params: { wf_status_id, wf_status, remarks, job_alloc_id } }, { headers }).pipe( tap(() => {}, (err: any) => {
            this.errorHandler(err)
      }));
    }
    callTestReportIntegration(importList) {
        const headers = new HttpHeaders().set('Authorization', this.token).set('UserId', this.UserId);
        return this.http.post(this.apiRoot + 'Reports/callTestReportIntegration', { params: { importList } }, { headers }).pipe( tap(() => {}, (err: any) => {
            this.errorHandler(err)
      }));
    }
    QBIInvoices(AllocationID) {
        const headers = new HttpHeaders().set('Authorization', this.token).set('UserId', this.UserId);
        return this.http.post(environment.qbiApiRoot + 'QBIInvoices', { params: { AllocationID } }, { headers }).pipe( tap(() => {}, (err: any) => {
            this.errorHandler(err)
      }));
    }
    updateWPProcessed(queryString) {
        const headers = new HttpHeaders().set('Authorization', this.token).set('UserId', this.UserId);
        return this.http.post(this.apiRoot + 'Reports/updateWPProcessed', { params: { queryString } }, { headers }).pipe( tap(() => {}, (err: any) => {
            this.errorHandler(err)
      }));
    }
    updateQBWPProcessed(queryString, ProcessingDate) {
        const headers = new HttpHeaders().set('Authorization', this.token).set('UserId', this.UserId);
        return this.http.post(this.apiRoot + 'Reports/updateQBWPProcessed', { params: { queryString, ProcessingDate } }, { headers }).pipe( tap(() => {}, (err: any) => {
            this.errorHandler(err)
      }));
    }

    updateEmailDeliveryReport(queryString, ProcessingDate) {
        const headers = new HttpHeaders().set('Authorization', this.token).set('UserId', this.UserId);
        return this.http.post(this.apiRoot + 'Reports/updateEmailDeliveryReport', { params: { queryString, ProcessingDate } }, { headers }).pipe( tap(() => {}, (err: any) => {
            this.errorHandler(err)
      }));
    }

    updateTPProcessed(queryString) {
        const headers = new HttpHeaders().set('Authorization', this.token).set('UserId', this.UserId);
        return this.http.post(this.apiRoot + 'Reports/updateTPProcessed', { params: { queryString } }, { headers }).pipe( tap(() => {}, (err: any) => {
            this.errorHandler(err)
      }));
    }
    updateQBTPProcessed(queryString, ProcessingDate) {
        const headers = new HttpHeaders().set('Authorization', this.token).set('UserId', this.UserId);
        return this.http.post(this.apiRoot + 'Reports/updateQBTPProcessed', { params: { queryString, ProcessingDate } }, { headers }).pipe( tap(() => {}, (err: any) => {
            this.errorHandler(err)
      }));
    }
    complianceReminder(companyID, mailSubject, message, mailids) {
        const headers = new HttpHeaders().set('Authorization', this.token).set('UserId', this.UserId);
        return this.http.post(this.apiRoot + 'SystemReminders/SendComplianceReminderEmail/', { params: { companyID, mailSubject, message, mailids } }, { headers }).pipe( tap(() => {}, (err: any) => {
            this.errorHandler(err)
      }));
    }
    sendGenericMail(mailData) {
        const headers = new HttpHeaders().set('Authorization', this.token).set('UserId', this.UserId);
        return this.http.get(this.apiRoot + 'SystemReminders/SendGenericEmail/', { headers, params: { mailData: JSON.stringify(mailData) } }).pipe( tap(() => {}, (err: any) => {
            this.errorHandler(err)
      }));
    }
    DashTaskBoardView() {
        const headers = new HttpHeaders().set('Authorization', this.token).set('UserId', this.UserId);
        return this.http.get(this.apiRoot + 'dashboard/getDashTaskBoardView/', { headers, params: {} }).pipe( tap(() => {}, (err: any) => {
            this.errorHandler(err)
      }));
    }
    getDashJobStatusDrillDown(JobStatus) {
        const headers = new HttpHeaders().set('Authorization', this.token).set('UserId', this.UserId);
        return this.http.get(this.apiRoot + 'dashboard/getDashJobStatusDrillDown/', { headers, params: { JobStatus } }).pipe( tap(() => {}, (err: any) => {
            this.errorHandler(err)
      }));
    }
    DashJobStatusView() {
        const headers = new HttpHeaders().set('Authorization', this.token).set('UserId', this.UserId);
        return this.http.get(this.apiRoot + 'dashboard/getDashJobStatusView/', { headers, params: {} }).pipe( tap(() => {}, (err: any) => {
            this.errorHandler(err)
      }));
    }
    errorHandler(err: any) {
        if (err instanceof HttpErrorResponse) {
            if (err.status !== 401) {
                return;
            };
            this.logout()
            this.router.navigate(['/login']);  
        }
    }
    handleFileDownloadError(err: any) {
        if(typeof err == 'string') {
            this.messageService.add({ severity: 'error', summary: 'Error', detail: err, life: 2000 });
        } else {
            if (err?.status !== 401) {
                if (err.status == 404) {
                    this.messageService.add({ severity: 'error', summary: 'Error', detail: 'File not found', life: 2000 });
                } else if (err.status == 500) {
                    this.messageService.add({ severity: 'error', summary: 'Error', detail: 'Something went wrong, please try again later', life: 2000 });
                }
            }
        }
    }
      logout() {      
        window.sessionStorage.clear();
        localStorage.clear();
        this.cookieService.removeAll();
        sessionStorage.setItem('loggedInUser', null);
        sessionStorage.setItem('currentUser',null);
        sessionStorage.setItem('auth_token', null);
        sessionStorage.setItem('user_id',null);
       
      }

    GetAllInvoicedNotSentReport() {
        const headers = new HttpHeaders().set('Authorization', this.token).set('UserId', this.UserId);
        return this.http.get(this.apiRoot + 'Reports/GetAllInvoicedNotSentReport', { headers, params: {} }).pipe( tap(() => {}, (err: any) => {
            this.errorHandler(err)
      }));
    }
    GetAllInvoicedNotPaidReport() {
        const headers = new HttpHeaders().set('Authorization', this.token).set('UserId', this.UserId);
        return this.http.get(this.apiRoot + 'JobInfo/ListingPageOperationsInvoiced', { headers, params: {} }).pipe( tap(() => {}, (err: any) => {
            this.errorHandler(err)
      }));
    }
    updateCompanyToken(companyID, token) {
        const headers = new HttpHeaders().set('Authorization', this.token).set('UserId', this.UserId);
        return this.http.get(this.apiRoot + 'Company/updateCompanyToken', { headers, params: { id: companyID, token } }).pipe( tap(() => {}, (err: any) => {
            this.errorHandler(err)
      }));
    }
    confirmRegistration(companyID, email) {
        const headers = new HttpHeaders().set('Authorization', this.token).set('UserId', this.UserId);
        return this.http.post(this.apiRoot + 'SystemReminders/ConfirmRegistration', { companyID, email }, { headers }).pipe( tap(() => {}, (err: any) => {
            this.errorHandler(err)
      }));
    }
    forgetPassword(datobj) {
      //  const headers = new HttpHeaders().set('Authorization', this.token).set('UserId', this.UserId);
        return this.http.post(this.apiRoot + 'SystemReminders/forgetPassword', datobj ).pipe( tap(() => {}, (err: any) => {
            this.errorHandler(err)
      }));
    }

    getAllSubmittedCompliance() {
        const headers = new HttpHeaders().set('Authorization', this.token).set('UserId', this.UserId);
        return this.http.get(this.apiRoot + 'ComplianceLevelDetail/getAllSubmittedCompliance', { headers, params: {} }).pipe( tap(() => {}, (err: any) => {
            this.errorHandler(err)
      }));
    }
    getAllCompliance(status) {
        const headers = new HttpHeaders().set('Authorization', this.token).set('UserId', this.UserId);
        return this.http.get(this.apiRoot + 'ComplianceLevelDetail/getAllCompliance', { headers, params: {status} }).pipe( tap(() => {}, (err: any) => {
            this.errorHandler(err)
      }));
    }
    updateComplianceStatus(status, id, comment) {
        const headers = new HttpHeaders().set('Authorization', this.token).set('UserId', this.UserId);
        let userid = this.UserId;
        return this.http.post(this.apiRoot + 'CompanyCompliance/updateStatus', { params: { status, id, comment ,userid } }, { headers }).pipe( tap(() => {}, (err: any) => {
            this.errorHandler(err)
      }));
    }

    getAllItemRatesListing() {
        const headers = new HttpHeaders().set('Authorization', this.token).set('UserId', this.UserId);
        return this.http.get(this.apiRoot + 'ItemRates/getAllItemRatesListing', { headers, params: {} }).pipe( tap(() => {}, (err: any) => {
            this.errorHandler(err)
      }));
    }
    getAllRateSets() {
        const headers = new HttpHeaders().set('Authorization', this.token).set('UserId', this.UserId);
        return this.http.get(this.apiRoot + 'RateSet/getAll', { headers, params: {} }).pipe( tap(() => {}, (err: any) => {
            this.errorHandler(err)
      }));
    }
    getSelectedCompaniesByRateSetId(ratesetId) {
        const headers = new HttpHeaders().set('Authorization', this.token).set('UserId', this.UserId);
        return this.http.get(this.apiRoot + 'RatesetCompanies/getSelectedCompaniesByRateSetId', { headers, params: { ratesetId } }).pipe( tap(() => {}, (err: any) => {
            this.errorHandler(err)
      }));
    }
    getAllTPAdmin() {
        const headers = new HttpHeaders().set('Authorization', this.token).set('UserId', this.UserId);
        return this.http.get(this.apiRoot + 'Company/getAllTPAdmin', { headers, params: {} }).pipe( tap(() => {}, (err: any) => {
            this.errorHandler(err)
      }));
    }
    getUnreadCount() {
        const headers = new HttpHeaders().set('Authorization', this.token).set('UserId', this.UserId);
        return this.http.get(this.apiRoot + 'UserNotifications/getUnreadCount', { headers, params: {} }).pipe( tap(() => {}, (err: any) => {
            this.errorHandler(err)
      }));
    }
    sendPushNotification(data: any) {
        const headers = new HttpHeaders().set('Authorization', this.token).set('UserId', this.UserId);
        return this.http.post(this.apiRoot + 'notification/send', data, { headers }).pipe( tap(() => {}, (err: any) => {
            this.errorHandler(err)
      }));
    }
    compressImage(data: any) {
        const headers = new HttpHeaders().set('Authorization', this.token).set('UserId', this.UserId);
        return this.http.post(this.apiRoot + 'v2/filepath/compress', data, { headers }).pipe( tap(() => {}, (err: any) => {
            this.errorHandler(err)
      }));
    }
    createFile(data: any) {
        const headers = new HttpHeaders().set('Authorization', this.token).set('UserId', this.UserId);
        return this.http.post(this.apiRoot + 'file/createfile', data, { headers }).pipe( tap(() => {}, (err: any) => {
            this.errorHandler(err)
      }));
    }

    getFileData(data: any) {
        const headers = new HttpHeaders().set('Authorization', this.token).set('UserId', this.UserId);
        let url;
        if (data !== undefined) {
            url = this.apiRoot + 'crud/form?id=' + data;
        } else {
            url = this.apiRoot + 'crud/form';
        }
        return this.http.get(url, { headers }).pipe( tap(() => {}, (err: any) => {
            this.errorHandler(err)
      }));
    }
    getDashboardInfoByClients(clients: any) {
        const headers = new HttpHeaders().set('Authorization', this.token).set('UserId', this.UserId);
        return this.http.post(this.apiRoot + 'JobInfo/getDashboardInfoByClients', { params: { clients } }, { headers }).pipe( tap(() => {}, (err: any) => {
            this.errorHandler(err)
      }));
    }
    getJobsForDashboard(category, subCatg) {
        const headers = new HttpHeaders().set('Authorization', this.token).set('UserId', this.UserId);
        return this.http.post(this.apiRoot + 'JobInfo/getJobsForDashboard', { params: { category, subCatg } }, { headers }).pipe( tap(() => {}, (err: any) => {
            this.errorHandler(err)
      }));
    }
    pdfCompression(file) {
        const headers = new HttpHeaders().set('x-api-key', this.pdfcoApikey);
        return this.http.post(this.pdfcoEndpoint, { headers }, { params: { url: file, name: 'result.pdf', 'x-api-key': this.pdfcoApikey } }).pipe( tap(() => {}, (err: any) => {
            this.errorHandler(err)
      }));
    }
    getIpAddress() {
        return this.http.get('http://api.ipify.org/?format=json');
    }
    getCalendlyEvent(uuid) {
        const headers = new HttpHeaders().set('Authorization', 'Bearer ' + this.calendlyToken);
        return this.http.get(this.calendlyApiEndPoint + 'scheduled_events/' + uuid, { headers });
    }
    cancelCalendlyEvent(event_id, reason_res) {
        const headers = new HttpHeaders().set('Authorization', 'Bearer ' + this.calendlyToken);
        const body = { reason: reason_res };
        return this.http.post(this.calendlyApiEndPoint + 'scheduled_events/' + event_id + '/cancellation/', body, { headers });
    }
    getCalendylUsers(event_id) {
        const headers = new HttpHeaders().set('Authorization', 'Bearer ' + this.calendlyToken);
        return this.http.get(this.calendlyApiEndPoint + 'scheduled_events/' + event_id + '/invitees/', { headers });
    }
    ListingPageByCompany(companyID) {
        const headers = new HttpHeaders().set('Authorization', this.token).set('UserId', this.UserId);
        return this.http.get(this.apiRoot + 'JobInfo/JobOperationsTPView/', { headers, params: { companyID } }).pipe( tap(() => {}, (err: any) => {
            this.errorHandler(err)
      }));
    };
    generateReportTM3(data, reportId, jobId, allocId, tempTime, pdfData): Observable<ArrayBuffer> {
        const headers = new HttpHeaders().set('Authorization', this.token).set('UserId', this.UserId);
        headers.append('responseType', 'arraybuffer');
        return this.http.get(this.apiRoot + 'generate/reportTM3', { headers, params: { data: JSON.stringify(data), jobId, allocId, tempTime, pdfData }, responseType: 'arraybuffer' }).pipe( tap(() => {}, (err: any) => {
            this.errorHandler(err)
      }));
    };
    sendAttchmentMail(params, ref_id, ref_type) {
        const headers = new HttpHeaders().set('Authorization', this.token).set('UserId', this.UserId);
        return this.http.post(this.apiRoot + 'workflows/sendAttchmentMail/', { email: params.email, emailBody: params.emailBody, ref_id, ref_type }, { headers }).pipe( tap(() => {}, (err: any) => {
            this.errorHandler(err)
      }));
    }
    updatePowerBIToken() {
        const headers = new HttpHeaders().set('Authorization', this.token).set('UserId', this.UserId);
        return this.http.get(this.apiRoot + 'hooks/updatePowerBIToken/', { headers, params: {}, responseType: 'arraybuffer' }).pipe( tap(() => {}, (err: any) => {
            this.errorHandler(err)
      }));
    }
    encodeJobURL(key, job_id, job_alloc_id) {
        const headers = new HttpHeaders().set('Authorization', this.token).set('UserId', this.UserId);
        return this.http.get(this.apiRoot + 'SystemReminders/encodeJobURL/', { headers, params: { key, job_id, job_alloc_id } }).pipe( tap(() => {}, (err: any) => {
            this.errorHandler(err)
      }));
    }
    decodeJobURL(key, job_params) {
        const headers = new HttpHeaders().set('Authorization', this.token).set('UserId', this.UserId);
        return this.http.get(this.apiRoot + 'SystemReminders/decodeJobURL/', { headers, params: { key, job_params } }).pipe( tap(() => {}, (err: any) => {
            this.errorHandler(err)
      }));
    }

    lovGetMethod(url) {
        const headers = new HttpHeaders().set('Authorization', this.token).set('UserId', this.UserId);
        return this.http.get(url, { headers }).pipe( tap(() => {}, (err: any) => {
            this.errorHandler(err)
      }));
    }
    allocateTaskUsers(taskList, usersList, next_followup_date, remarks, created_by, redirect_url) {
        const headers = new HttpHeaders().set('Authorization', this.token).set('UserId', this.UserId);
        return this.http.get(this.apiRoot + 'taskusers/allocateTaskUsers', { headers, params: { taskList, usersList, next_followup_date, remarks, created_by, redirect_url } }).pipe( tap(() => {}, (err: any) => {
            this.errorHandler(err)
      }));
    }
    getPostcoderAddress(searchCode) {
        return this.http.get('https://ws.postcoder.com/pcw/'+ environment.postCoder.apiKey +'/address/uk/'+ searchCode +'?format=json&lines=2&addtags=latitude,longitude,country');
    }
    getEmailreportbyDate(from_date, to_date ) {
        const headers = new HttpHeaders().set('Authorization', this.token).set('UserId', this.UserId);
        return this.http.get(this.apiRoot + 'Reports/getEmailReportbyDate', { headers, params: { from_date, to_date } }).pipe( tap(() => {}, (err: any) => {
            this.errorHandler(err)
      }));
    };

    getLoginreportbyDate(from_date, to_date ) {
        const headers = new HttpHeaders().set('Authorization', this.token).set('UserId', this.UserId);
        return this.http.get(this.apiRoot + 'Reports/getLoginReportbyDate', { headers, params: { from_date, to_date } }).pipe( tap(() => {}, (err: any) => {
            this.errorHandler(err)
      }));
    };


    getOtherAllocationDetails_dup(job_id) {
        return this.http.get(this.apiRoot + 'clientdetails/getOtherAllocationDetails/', {params: { job_id } }).pipe( tap(() => {}, (err: any) => {
            this.errorHandler(err)
      }));
    };

    getStateMaxId(){
        const headers = new HttpHeaders().set('Authorization', this.token).set('UserId', this.UserId);
        return this.http.get(this.apiRoot + 'State/getMaxid', { headers }).pipe( tap(() => {}, (err: any) => {
            this.errorHandler(err)
      }));

    }
    CreateState(body){
        const headers = new HttpHeaders().set('Authorization', this.token).set('UserId', this.UserId);
        return this.http.post(this.apiRoot + 'State/CreatestatewithId',body ,{ headers}).pipe( tap(() => {}, (err: any) => {
            this.errorHandler(err)
      }));
    }
    UpdateState(body){
        const headers = new HttpHeaders().set('Authorization', this.token).set('UserId', this.UserId);
        return this.http.post(this.apiRoot + 'State/UpdatestatewithId',body ,{ headers}).pipe( tap(() => {}, (err: any) => {
            this.errorHandler(err)
      }));
    }
    getvaluesetforKPI(){
      const headers = new HttpHeaders().set('Authorization', this.token).set('UserId', this.UserId);
      return this.http.get(this.apiRoot + 'KPIMeasures/getvaluesetforKPI', { headers }).pipe( tap(() => {}, (err: any) => {
          this.errorHandler(err)
    }));
    }
    
    
async getmenupermissions(): Promise<{ isExport: boolean; isDelete: boolean }> {
    this.selectedroute = sessionStorage.getItem('selectedRoute');
  
    const userObject = JSON.parse(sessionStorage.getItem('loggedInUser'));
    const userGroupId: string = userObject.user_group_id;
  
    try {
      const data:any = await this.getMenusByUserRoleID(userGroupId).toPromise();
  
      const filteredItems = data.filter(item => item.router_url === this.selectedroute && item.allow_export === '1');
      const isExport = filteredItems.length > 0;
      const filteredItems2 = data.filter(item => item.router_url === this.selectedroute && item.allow_delete === '1');
      const isDelete = filteredItems2.length > 0;
      
      return { isExport, isDelete };
    } catch (error) {
      // Handle any errors here
      console.error('Error fetching menu permissions:', error);
      throw error; // Rethrow the error if needed
    }
  }
  getTaskdetail(taskid) {
    const headers = new HttpHeaders().set('Authorization', this.token).set('UserId', this.UserId);
    return this.http.get(this.apiRoot + 'taskHead/getTaskDetails', { headers, params: { taskid } }).pipe( tap(() => {}, (err: any) => {
        this.errorHandler(err)
  }));
}
getScopeOfWork(jobId,jobAllocId){
    const headers = new HttpHeaders().set('Authorization', this.token).set('UserId', this.UserId);
        return this.http.get(this.apiRoot + 'JobAllocation/UpdateCaseInfo/', { headers, params: { job_id: jobId, job_alloc_id: jobAllocId } }).pipe( tap(() => {}, (err: any) => {
            this.errorHandler(err)
      }));
}
saveScopeOfWork(jobId,jobAllocId,data){
    const headers = new HttpHeaders().set('Authorization', this.token).set('UserId', this.UserId);
        return this.http.post(this.apiRoot + 'JobAllocation/saveScopeOfWork/', data,{ headers, params: { job_id: jobId, job_alloc_id: jobAllocId }}).pipe( tap(() => {}, (err: any) => {
            this.errorHandler(err)
      }));
}
getIterationData(jobId,jobAllocId,userDetails){
    const headers = new HttpHeaders().set('Authorization', this.token).set('UserId', this.UserId);
    return this.http.post(this.apiRoot + 'JobAllocation/UpdateFiles/', { headers ,params: { job_id: jobId, job_alloc_id: jobAllocId,company: userDetails.company, userId: userDetails.userId , username: userDetails.username }}).pipe( tap(() => {}, (err: any) => {
        this.errorHandler(err)
  }));
}

getXeroToken() {
    const clientID = 'E4CDD15C2FB14FFC977DD555BF8192EB';
    const clientSecret = 'Ud3GWHN1XQ8RE7JYHlovdzw9SX9ITg4x0CIN15nHqqynqlbp';
    const headers = new HttpHeaders({
      'Content-Type': 'application/x-www-form-urlencoded',
      'Authorization': `Basic ${btoa(`${clientID}:${clientSecret}`)}`
    });
    const body = new URLSearchParams();
    body.set('grant_type', 'client_credentials');
    body.set('scope', 'accounting.transactions accounting.contacts accounting.settings');

    return this.http.post('https://identity.xero.com/connect/token', body.toString(), { headers: headers })
      .toPromise()
      .then((tokenData: any) => tokenData.access_token)
      .catch(error => {
        console.error('Error getting Xero token:', error);
        throw error; // rethrow the error to propagate it further
      });
  }
}


