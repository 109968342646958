import { Component, OnInit, Input, ViewChild } from '@angular/core';
import { CdkDragDrop, moveItemInArray, transferArrayItem } from '@angular/cdk/drag-drop';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { ConfirmationService, MessageService } from 'primeng/api';
import { DialogService, DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { CRUDService } from 'src/app/utilities/crud.service';
import { JOBService } from 'src/app/utilities/job.service';
import { TaskDetailsComponent } from '../task-details/task-details.component';
import { WorkFlowService } from 'src/app/utilities/workflow.service';
import { JobAdminComponent } from '../../job/job-admin/job-admin.component';
import { JobUserComponent } from '../job-user/job-user.component';
import { SortEvent } from 'primeng/api';
import { AppReminderComponent } from '../app-reminder/app-reminder.component';
import { CommonService } from 'src/app/utilities/common.service';
import { JobDetailsApprovalsComponent } from 'src/app/job/job-details-approvals/job-details-approvals.component';
import { TPRegistrationComponent } from '../tp-registration/tp-registration.component';
import { CompanyComplianceComponent } from '../company-compliance/company-compliance.component';
import { ContractorsComponent } from 'src/app/admin/contractors/contractors.component';
import { TaskUpdateComponent } from '../task-update/task-update.component';
import { environment } from 'src/environments/environment';
import { DatePipe } from '@angular/common';
import * as moment from 'moment';
// import { Table } from 'jspdf-autotable';
import { Table } from 'primeng/table';
import { Dropdown } from 'primeng/dropdown';
@Component({
  selector: 'app-task-list',
  templateUrl: './task-list.component.html',
  styleUrls: ['./task-list.component.scss'],
  providers: [DialogService, MessageService, ConfirmationService, TaskDetailsComponent,DatePipe]
})
export class TaskListComponent implements OnInit {
  @Input() wf_code: any;
  @Input() user_type: any;
  @Input() allow_edit: any;
  @Input() allow_create: any;
  @Input() allow_delete: any;
  @Input() title: any;
  dt: any;
  allocation_id: any;
  view_mode: any;
  taskList: any = [];
  originalTaskList: any = [];
  selectedtask: any[] = [];
  stagesList: any[];
  stagesListOrg: any[];
  workflowDetails: any;
  companyId: any;
  userDetails: any;
  priorities = [];
  selectedPriorities: any[] = [];
  selectedAllocatedTo: any[] = [];
  selectedStatus: any[] = [];
  menuData: any;
  showMoreColumn: any = false;
  toggleSearch: any = false;
  staffList: any[] = [];
  issueStatusList: any[] = [];
  selectedIssueStatus: any[] = [];
  issueTypeList: any[] = [];
  selectedIssueType: any[] = [];
  reportDateFrom: Date = null;
  reportDateTo: Date = null;
  reqByDateFrom: Date = null;
  listOfStaff: any[] = [];
  reqByDateTo: Date = null;
  dueStatusList: any[] = [];
  selectedDueStatus: any[] = [];
  exportColumns: any;
  cols: any;
  workflow_params: any;
  loading: Boolean = false;
  staffInfo: any;
  isFiltered: Boolean = false;
  columnsListbyID: any[] = [];
  viewKanban: Boolean = false;
  showInfoFieldsColumn: Boolean = false;
  info_fields: any;
  isEditAll: boolean = false;
  exportdisplay: Boolean = false;
  collapsed: boolean = true;
  appProps: any;
  categoryList: any;
  selectedCategories: any;
  companyList: any;
  filteredCompany: any;
  tpList: any = [];
  wpList: any = [];
  eventlist : any = [];
  selectedTp : any;
  selectedWp : any;
  selectedEvent : any;
  dateformat: any= environment.dateFormat;
  shortdateformat: any= environment.dateFormat;
  allocationFilterTypes = [
    { id: '1', title: 'All', selected: true },
    { id: '2', title: 'Allocated To Me', selected: false },
    { id: '3', title: 'Created By Me', selected: false }
  ];

  dueDateFilterTypes = [
    { id: '6', title: 'Overdue', selected: false, rangeFrom: -100, rangeTo: 0, name: 'Overdue', class: 'adjustLeft25' },
    { id: '4', title: 'Due in 7 Days', name: 'In 7 Days', rangeFrom: 0, rangeTo: 8, selected: false },
    { id: '5', title: 'Due Today', selected: false, rangeFrom: -1, rangeTo: 2, name: 'Due Today' },
    { id: '7', title: 'Due Later', selected: false, rangeFrom: 7, rangeTo: 100, name: 'Due Later' }];

  taskStatusFilterTypes = [{ id: '8', title: 'Open Tasks', name: 'Open', selected: true, class: 'adjustLeft25' },
  { id: '9', title: 'Closed Tasks', name: 'Closed', selected: false },
  { id: '10', title: 'View All', name: 'All', selected: false }]
  Type:any= [
    { name: 'Submitted', value: 'Submitted' },
    { name: 'Open', value: 'Open' },
    { name: 'Re-Opened', value: 'Re-Opened' },
    { name: 'Closed', value: 'Closed' },
  ];
  option :any
  speedDial: Boolean = false;
  menuOptions: any[] = [];

  timeSlots = ['7:30am', '8:00am', '8:30am', '9:00am', '9:30am', '10:00am', '10:30am', '11:00am', '11:30am', '12:00pm', '12:30pm', '1:00pm', '1:30pm', '2:00pm', '2:30pm',
    '3:00pm', '3:30pm', '4:00pm', '4:30pm', '5:00pm', '5:30pm', '6:00pm', '6:30pm', '7:00pm', '7:30pm'];
  filteredTimeSlots: any[];
  selectedTime: any;
  selectedTasksList: any[] = [];
  displayAllocationDialog: boolean = false;
  next_followup_date: any;
  event_remarks: any;
  selectedUser: any;
  tasklist_All: any;
  task: any[];
  reformattedArray: any[];
  @ViewChild("myDropDown", {static: false}) myDropDown: Dropdown;
  isExport: boolean=false;
  isDelete: boolean=false;
  selectedroute: string;
 
  constructor(
    private wfService: WorkFlowService,
    private route: ActivatedRoute,
    private crudService: CRUDService,
    private messageService: MessageService,
    private confirmationService: ConfirmationService,
    private commonservice: CommonService,
    private router: Router,
    public ref: DynamicDialogRef,
    public config: DynamicDialogConfig,
    public dialogService: DialogService,
    private JobService: JOBService,private datePipe: DatePipe,
    private workFlowService: WorkFlowService
  ) {
    this.priorities = [{ code: 'Critical', name: 'Critical' },
    { code: 'High', name: 'High' },
    { code: 'Normal', name: 'Normal' },
    { code: 'Low', name: 'Low' }];

    this.dueStatusList = [{ rangeFrom: 1, rangeTo: 8, name: 'In 7 Days' },
    { rangeFrom: 0, rangeTo: 2, name: 'Due Today' },
    { rangeFrom: -100, rangeTo: 0, name: 'Overdue' },
    { rangeFrom: 8, rangeTo: 100, name: 'Due Later' }];

    this.issueStatusList = [
      { code: '1', name: 'New' },
      { code: '2', name: 'Submitted' },
      { code: '3', name: 'Closed' },
      { code: '4', name: 'Reopened' },
      { code: '5', name: 'Archived' }
    ];

    this.userDetails = JSON.parse(sessionStorage.getItem('loggedInUser'));
  }

  async ngOnInit() {
    this.task=[]
    this.dateformat = environment.dateFormat;
    this.loading = true;
    this.companyId = sessionStorage.getItem('company_id');
    this.userDetails = JSON.parse(sessionStorage.getItem('loggedInUser'));
    this.appProps = this.commonservice.getAppProperties();


    
    this.selectedroute = sessionStorage.getItem('selectedRoute')
    let per_data :any=await this.crudService.getmenupermissions()
    this.isExport = per_data.isExport;
    this.isDelete = per_data.isDelete;

    try {
      if (this.config?.data?.wf_code != '' && this.config?.data?.wf_code != null) {
        this.wf_code = this.config?.data?.wf_code;
        this.view_mode = this.config?.data?.view_mode;
        this.allocation_id = this.config?.data?.allocation_id;
        this.user_type = 'admin';
        this.allow_edit = '1';
        this.allow_delete = '1';
        this.allow_create = '1';
        if (this.view_mode == 'TP') {
          this.title = 'TP Escalations';
        } else if (this.view_mode == 'JOB') {
          this.title = 'TP Job Escalations';
        }
        this.filteredCompany = this.config?.data?.TP;
      } else {
        if (this.wf_code == null || typeof this.wf_code == 'undefined') {
          if (sessionStorage.getItem('menuData') != null && sessionStorage.getItem('menuData') !== '') {
            this.menuData = JSON.parse(sessionStorage.getItem('menuData'));
          }
          this.wf_code = this.menuData.para_1;
          this.user_type = this.menuData.para_2;
          this.allow_edit = this.menuData.allow_edit;
          this.allow_delete = this.menuData.allow_delete;
          this.allow_create = this.menuData.allow_create;
          this.title = this.menuData.display_name;
        }
      }

    } catch (error) {
      if (this.userDetails.homepage_url !== null && this.userDetails.homepage_url !== '') {
        this.router.navigate([this.userDetails.homepage_url]);
      } else {
        this.router.navigate(['/']);
      }
    }
    this.companyList = await this.crudService.getDataByField('Company', 'client_type_id', '3').toPromise();
    if (this.wf_code === '7' || this.wf_code === 7) {
      this.showInfoFieldsColumn = true;
      this.info_fields = {
        field_1_caption: 'Job No',
        field_2_caption: 'TP Name',
        field_3_caption: 'Work Provider'
      }
    } else {
      this.showInfoFieldsColumn = false;
      this.info_fields = {
        field_1_caption: 'Field 1',
        field_2_caption: 'Field 2',
        field_3_caption: 'Field 3'
      }
    }
    this.workflow_params = {
      wf_code: this.wf_code,
      company_id: this.companyId,
      category_vs: '',
      issueType_vs: null,
      issueType_caption: 'Issue Type',
      start_dt_caption: 'Occurance Date',
      end_dt_caption: 'Required By',
    }
    let params: any = await this.crudService.fetchWFCompanyParams(this.wf_code, this.companyId).toPromise();
    if (params.length > 0) {
      this.workflow_params = params[0];
    }
    if (this.workflow_params.issueType_vs !== '' && this.workflow_params.issueType_vs !== null) {
      let issueTypeList: any = await this.crudService.getByValuesetName(this.workflow_params.issueType_vs).toPromise();
      issueTypeList.sort((a, b) => a.valueset_detail_name > b.valueset_detail_name && 1 || -1);
      if (issueTypeList.length > 0) {
        this.issueTypeList = issueTypeList;
      }
    } else {
      this.issueTypeList = [
        { code: '1', name: 'Job Related' },
        { code: '2', name: 'Support' },
        { code: '3', name: 'Network' },
        { code: '4', name: 'Hardware' },
        { code: '5', name: 'Software' },
        { code: '6', name: 'Workstation' },
        { code: '7', name: 'Other' }
      ];
      this.issueTypeList = this.issueTypeList.sort((a, b) => a.name > b.name && 1 || -1);
    }
    if (this.workflow_params.category_vs !== '' && this.workflow_params.category_vs !== null) {
      let categoryList: any = await this.crudService.getByValuesetName(this.workflow_params.category_vs).toPromise();
      categoryList.sort((a, b) => a.valueset_detail_name > b.valueset_detail_name && 1 || -1);
      if (categoryList.length > 0) {
        this.categoryList = categoryList;
      }
    }

    /*    if (typeof this.config.data == 'undefined') {
     this.wf_code = parseInt(this.route.snapshot.paramMap.get('wf_code'));
   } else {
     this.wf_code = this.config.data.wf_code;
   } */
    let data: any = await this.crudService.getAllSSPUsers(this.companyId).toPromise();
    data.sort((a, b) => a.full_name > b.full_name && 1 || -1);
    this.staffList = data;
    this.listOfStaff = data;
    let staffinfo = data.filter((val: any) => (val.id === this.userDetails.staff_id));
    if (staffinfo.length > 0) {
      this.staffInfo = staffinfo[0];
    }
    let dummydata = {
      Initials: 'UA', company_id: '0', create_login: '0', created_at: null,
      created_by: null,
      deleted: null,
      email: null,
      first_name: 'UnAllocated',
      full_name: 'UnAllocated',
      id: '0',
      inducted_date: null,
      induction: null,
      last_modified_by: null,
      last_name: null,
      last_updated_at: null,
      mobile: null,
      password: null,
      phone: null,
      process_id: null,
      role: null,
      stafftype: null,
      user_id: null,
      user_role_id: null,
      username: null
    }
    this.staffList = [dummydata, ...this.staffList];
    const self = this;
    this.wfService.fetchBoardDetails(this.wf_code).subscribe(async (boardData: any) => {
      this.workflowDetails = boardData;
      this.wfService.fetchColumnsList(this.wf_code, this.userDetails.id).subscribe(async (columnsData: any[]) => {
        this.stagesList = columnsData;
        await this.fetchTaskList();
        this.loadActiveTasks(this.originalTaskList);
        if (this.viewKanban) {
          this.loadKanban();
          await self.stagesList?.map(async (data: any) => {
            //console.log(data.tasks)
          })
        }
      });

    });

    this.cols = [
      { field: 'id', header: 'Ref No' },
      { field: 'name', header: 'Issue Type' },,
      { field: 'info_2', header: 'TP Name' },
      { field: 'last_comm', header: 'Last Comm' },
      { field: 'description', header: 'Description' },   
      { field: 'priority', header: 'Priority' },
      { field: 'occurance_date', header: 'Report Date' },
      { field: 'reqby_name', header: 'Raised By' },
      { field: 'reqby_date', header: 'Required By' },
      { field: 'AllocatedTo', header: 'Allocation' },
      { field: 'ageing', header: 'Due Count' },
      { field: 'support_status', header: 'Support Status' },
      { field: 'issue_status', header: 'Issue Status' }
    ];

    this.exportColumns = this.cols.map(col => ({ title: col.header, dataKey: col.field }));
    this.exportdisplay = await this.commonservice.exportCheck();
    this.loading = false;
  }

  async fetchTaskList() {
    this.loading = true;
    let checklist: any[] = [];
    let taskData: any = [];

    if (this.user_type == 'req') {
      taskData = await this.wfService.fetchRequesterTasks(this.wf_code, this.userDetails.id, this.companyId).toPromise();
    } else if (this.user_type == 'user') {
      taskData = await this.wfService.fetchAllocatedTasks(this.wf_code, this.userDetails.id, this.companyId).toPromise();
    } else if (this.user_type == 'admin') {
      taskData = await this.wfService.fetchAdminTasks(this.wf_code, this.userDetails.id, this.companyId).toPromise();
    this.tasklist_All =taskData   
    }
    else {
      this.taskList = [];
      this.originalTaskList = [];
    }
    if (this.wf_code == '7') {
      if (this.view_mode == 'TP') {
        if (this.filteredCompany != null && this.filteredCompany != '') {
          taskData = taskData.filter(val => (val.info_2 == this.filteredCompany.business_name));
        }
      } else if (this.view_mode == 'JOB') {
        taskData = taskData.filter(val => (val.job_alloc_id == this.allocation_id));
      }
    }
    taskData = taskData.filter(val => ( val.issue_status != 'Closed'));
    await this.updateTaskList(taskData);
    this.originalTaskList = taskData;
    this.originalTaskList.forEach(task => {
      let tpKey = { code: task.tp_company?.business_name }
      this.tpList.push(tpKey);
      let wpKey = { code: task.info_3 }
      this.wpList.push(wpKey);
      let eventKey = { code: task.name }
      this.eventlist.push(eventKey);
      var info_1: number = +task.info_1;
      task.info_1 = info_1
      var id: number = +task.id;
      task.id = id
    });
    this.tpList = [...new Map(this.tpList.map(item => [JSON.stringify(item), item])).values()];
    this.wpList = [...new Map(this.wpList.map(item => [JSON.stringify(item), item])).values()];
    this.eventlist = [...new Map(this.eventlist.map(item => [JSON.stringify(item), item])).values()];
    console.log("TP list", this.eventlist);
    // console.log("Wp list",this.priorities);
    this.loading = false;
  }

  loadActiveTasks(taskData) {
    this.taskList = taskData;

    this.taskList = this.taskList.map(t => { t.ageing = parseInt(t.ageing); return t; });
    this.taskList = this.taskList.filter((val: any) => (val.status !== '3' && val.status !== '5'));
  }

  loadClosedTasks(taskData) {

   // console.log('taskDataaa',taskData)
    this.taskList = taskData;
    this.taskList = this.taskList.map(t => { t.ageing = parseInt(t.ageing); return t; });
    this.taskList = this.tasklist_All.filter((val: any) => (val.status === '3' || val.status === '5'));
    this.taskList.sort(function (a, b) { return b.id - a.id });
  }

  async updateTaskList(tasksList) {
    let jobData: any;
    let taskUsersData: any = await this.crudService.getAllTaskUsers(this.wf_code).toPromise();
    let checkListData: any = await this.wfService.fetchTasksCheckList(this.wf_code, this.userDetails.id).toPromise();
    if (this.wf_code == '7') {
      jobData = await this.crudService.getDataByField('JobDashboardDataDump', 'status', '1').toPromise();
    }
    for (let task of tasksList) {
      let company_info: any = {}
      if (this.wf_code == '1') {
        if (task.tags !== null && task.tags !== '') {
          company_info = this.companyList.find(val => (val.id == task.tags));
        }
        task.tp_company = company_info;
      } else if (this.wf_code == '7') {
        let data = jobData.find(val => (val.allocationid == task.job_alloc_id));
        if (typeof data?.LastCommsInDays != 'undefined') {
          task.last_comm = data?.LastCommsInDays ? data?.LastCommsInDays : 0;
        } else {
          task.last_comm = 0;
        }
        task.bucket_name = data?.bucket_name;
        task.MoreDetails = data?.moreDetails;
        if (data?.TreeProfessional !== null && data?.TreeProfessional !== '') {
          company_info = this.companyList.find(val => (val.business_name == data?.TreeProfessional));
        }
        task.tp_company = company_info;
      } else {
        task.tp_company = null;
      }

      if (0 > task.ageing) {
        task.ageing_status = 'Overdue';
      } else if (1 >= task.ageing && task.ageing >= 0) {
        task.ageing_status = 'Due Today';
      } else if (task.ageing > 1 && 7 >= task.ageing) {
        task.ageing_status = 'Due in 7 Days';
      } else {
        task.ageing_status = 'Due Later';
      }
      task.selectedAllocatees = taskUsersData.filter(val => ((val.status === '1') && (val.task_id === task.id)));
      task.checkList = checkListData.filter(val => val.task_id === task.id);

      if ((this.allow_edit === '1' || task.created_by === this.userDetails.id) &&
        (task.status === '1' || task.status === '2' || task.status === '4')) {
        task.allowEditing = true;
      } else {
        task.allowEditing = false;
      }
    }
    if (this.wf_code == '7') {
      tasksList.sort(function (a, b) { return b.last_comm - a.last_comm });
    } else {
      tasksList.sort(function (a, b) { return b.id - a.id });
    }
  }

  loadKanbanOld() {
    this.columnsListbyID = [];
    this.stagesList.forEach(column => {
      let tasksList = this.taskList.filter(val => val.wd_code === column.code);
      tasksList.sort(function (a, b) { return b.id - a.id });
      this.wfService.fetchTasksCheckList(this.wf_code, this.userDetails.id).subscribe((checkListData: any[]) => {
        checkListData.forEach(item => {
          if (item.checked == '1') {
            item.isChecked = true;
          } else {
            item.isChecked = false;
          }
        });
        this.crudService.getAllTaskUsers(this.wf_code).subscribe((taskUsersData: any[]) => {
          tasksList.forEach(task => {
            if (0 > task.ageing) {
              task.ageing_status = 'Overdue';
            } else if (1 >= task.ageing && task.ageing >= 0) {
              task.ageing_status = 'Due Today';
            } else if (task.ageing > 1 && 7 >= task.ageing) {
              task.ageing_status = 'Due in 7 Days';
            } else {
              task.ageing_status = 'Due Later';
            }
            task.selectedAllocatees = taskUsersData.filter(val => ((val.status === '1') && (val.task_id === task.id)));
            task.checkList = checkListData.filter(val => val.task_id === task.id);

            if ((this.allow_edit === '1' || task.created_by === this.userDetails.id) &&
              (task.status === '1' || task.status === '2' || task.status === '4')) {
              task.allowEditing = true;
            } else {
              task.allowEditing = false;
            }
          });
          column.tasks = tasksList;
          let overduelist = tasksList.filter(val => (val.ageing_status === 'Overdue'));
          column.overDueCount = overduelist.length;
        });
      });
      this.columnsListbyID.push(column.id); //Must for loading drag and drop feature
    });
  }

  loadKanban() {
    this.columnsListbyID = [];
    this.stagesList.forEach(column => {
      let tasksList = this.taskList.filter(val => val.wd_code === column.id);
      tasksList.sort(function (a, b) { return b.id - a.id });
      column.tasks = tasksList;
      let overduelist = tasksList.filter(val => (val.ageing_status === 'Overdue'));
      column.overDueCount = overduelist.length;
      this.columnsListbyID.push(column.id); //Must for loading drag and drop feature
    });
  }

  openNew() {
    const ref = this.dialogService.open(TaskDetailsComponent, {
      data: {
        wf_code: this.wf_code,
        task_id: 0,
        redirectMenu: this.menuData
      },
      header: '',
      width: '100%',
      height: '100%',
      styleClass: 'dialog-class'
    })

    ref.onClose.subscribe(async (taskdetails: any) => {
      await this.fetchTaskList();
      this.filterData(null);
      this.quickFilterTasks(null);
    });
  }

  editSelectedRecord(task) {
    const ref = this.dialogService.open(TaskDetailsComponent, {
      data: {
        wf_code: task.wf_code,
        task_id: task.id,
        checkList: task.checkList,
        redirectMenu: this.menuData
      },
      header: '',
      width: '100%',
      height: '100%'
    })

    ref.onClose.subscribe(async (taskdetails: any) => {
      let checkListData: any = await this.crudService.getDataByField('SSPSearchView', 'id', task.id).toPromise();
      if(checkListData){
     this.taskList[this.findIndexById(checkListData[0].id)] = checkListData[0] ;
      }
      await this.fetchTaskList();
      this.filterMultiSelectTp();
      this.filterMultiSelectWp();
      this.filterMultiSelectEvent();
      this.filterData(null);
      this.quickFilterTasks(null);
      //   const target = this.taskList.find((obj: { id: any; }) => obj.id == checkListData[0].id);
      //  Object.assign(target, checkListData);
      //  console.log("      this.taskList",      this.taskList);

    });
  }

  editTask(task) {
    const ref = this.dialogService.open(TaskDetailsComponent, {
      data: {
        wf_code: task.wf_code,
        task_id: task.id,
        checkList: task.checkList,
        redirectMenu: this.menuData
      },
      header: '',
      width: '100%',
      height: '100%'
    })

    ref.onClose.subscribe(async (taskdetails: any) => {
      await this.fetchTaskList();
      this.filterData(null);
      this.quickFilterTasks(null);
    });
  }

  deleteTask(task) {
    this.confirmationService.confirm({
      message: 'Are you sure you want to delete the selected Job?',
      header: 'Confirm',
      icon: 'pi pi-exclamation-triangle',
      accept: async () => {
        task.status = '6';
        task.updated_at = new Date().toLocaleString();
        task.updated_by = this.userDetails.id;
        let data: any = await this.commonservice.saveRecord('task_head', task);
        if (data.success == 1) {
          this.messageService.add({ severity: 'success', summary: 'Successful', detail: 'Task Deleted', life: 1000 });
          this.taskList = this.taskList.filter((val: any) => val.id !== task.id);
          if (this.viewKanban) {
            this.loadKanban();
          }
        }
      }
    })
  }


  deleteSelectedtask() {
    let allowDelete = true;
    for (let i = 0; i < this.selectedtask.length; i++) {
      if (this.selectedtask[0] !== this.userDetails.id && this.allow_delete !== '1') {
        allowDelete = false;
        break;
      }
    }
    if (allowDelete) {
      this.confirmationService.confirm({
        message: 'Are you sure you want to delete the selected tasks?',
        header: 'Confirm',
        icon: 'pi pi-exclamation-triangle',
        accept: () => {
          for (let i = 0; i < this.selectedtask.length; i++) {
            this.selectedtask[i].status = '6';
            this.selectedtask[i].updated_at = new Date().toLocaleString();
            this.selectedtask[i].updated_by = this.userDetails.id;
            this.crudService.Update(this.selectedtask[i], 'taskHead').subscribe((data: any[]) => { });
          }
          this.taskList = this.taskList.filter(val => !this.selectedtask.includes(val));
          if (this.viewKanban) {
            this.loadKanban();
          }
          this.selectedtask = null;
          this.messageService.add({ severity: 'success', summary: 'Successful', detail: 'Selected Records Deleted', life: 1000 });
        }
      });
    } else {
      this.messageService.add({ severity: 'warning', summary: 'Can Not Delete Selected Records', detail: 'Some of the items selected are neither created by you nor deletable.', life: 1000 });
    }
  }

  findIndexById(id: string): number {
    let index = -1;
    for (let i = 0; i < this.taskList.length; i++) {
      if (this.taskList[i].id === id) {
        index = i;
        break;
      }
    }
    return index;
  }

  toggleFn(key) {
    switch (key) {
      case 'search':
        this.toggleSearch = !this.toggleSearch;
        break;
      case 'column':
        this.showMoreColumn = !this.showMoreColumn;
        break;
      case 'view':
        this.viewKanban = !this.viewKanban;
        if (this.viewKanban) {
          this.loadKanban()
        }
        break;
      default:
        break;
    }
  }

  resetFilters() {
    this.taskList = this.fetchTaskList();
    this.selectedIssueStatus = [];
    this.selectedIssueType = [];
    this.reportDateFrom = null
    this.reportDateTo = null;
    this.reqByDateFrom = null;
    this.reqByDateTo = null;
    this.selectedDueStatus = [];
    this.selectedPriorities = [];
    this.selectedAllocatedTo = [];
    this.selectedStatus = [];

    if (this.viewKanban) {
      this.loadKanban();
    }
  }

  loadList(list, filteredTasks) {
    list.forEach(item => {
      let index = filteredTasks.findIndex((val: any) => (val.id === item.id));
      if (index === -1) {
        filteredTasks.push(item);
      }
    });
    this.isFiltered = true;
    return filteredTasks;
  }

  filterData(dt) {
    this.toggleSearch = !this.toggleSearch;
    let unfilteredTasks = this.originalTaskList;
    let filteredTasks: any[] = [];
    this.isFiltered = false;


    if (this.reportDateFrom !== null) {
      if (this.isFiltered) {
        let list = filteredTasks.filter((val: any) => (new Date(val.occurance_date).getTime() >= new Date(this.reportDateFrom).getTime()));
        filteredTasks = this.loadList(list, filteredTasks);
      } else {
        let list = unfilteredTasks.filter((val: any) => (new Date(val.occurance_date).getTime() >= new Date(this.reportDateFrom).getTime()));
        filteredTasks = this.loadList(list, filteredTasks);
      }
    }

    if (this.reportDateTo !== null) {
      if (this.isFiltered) {
        let list = filteredTasks.filter((val: any) => (new Date(val.occurance_date).getTime() <= new Date(this.reportDateTo).getTime()));
        filteredTasks = this.loadList(list, filteredTasks);
      } else {
        let list = unfilteredTasks.filter((val: any) => (new Date(val.occurance_date).getTime() <= new Date(this.reportDateTo).getTime()));
        filteredTasks = this.loadList(list, filteredTasks);
      }
    }

    if (this.reqByDateFrom !== null) {
      console.log("this.reqByDateFrom",this.reqByDateFrom);
      if (this.isFiltered) {
        let list = filteredTasks.filter((val: any) => (new Date(val.reqby_date).getTime() >= new Date(this.reqByDateFrom).getTime()));
        filteredTasks = this.loadList(list, filteredTasks);
      } else {
        let list = unfilteredTasks.filter((val: any) => ( new Date(val.reqby_date).getTime() >= new Date(this.reqByDateFrom).getTime()));
        filteredTasks = this.loadList(list, filteredTasks);
      }
    }

    if (this.reqByDateTo !== null) {
      if (this.isFiltered) {
        let list = filteredTasks.filter((val: any) => (new Date(val.reqby_date).getTime() <= new Date(this.reqByDateTo).getTime()));
        filteredTasks = this.loadList(list, filteredTasks);
      } else {
        let list = unfilteredTasks.filter((val: any) => (new Date(val.reqby_date).getTime() <= new Date(this.reqByDateTo).getTime()));
        filteredTasks = this.loadList(list, filteredTasks);
      }
    }


    if (this.selectedPriorities.length > 0) {
      let localList: any[] = [];
      if (this.isFiltered) {
        localList = filteredTasks;
      } else {
        localList = unfilteredTasks;
      }
      this.selectedPriorities.forEach(priority => {
        let list = localList.filter((val: any) => val.priority === priority.code);
        filteredTasks = this.loadList(list, filteredTasks);
      })
    }
    if (this.selectedAllocatedTo.length > 0) {
      let filteredLocalList: any[] = [];
      let localList: any[] = [];
      if (this.isFiltered) {
        localList = filteredTasks;
      } else {
        localList = unfilteredTasks;
      }
      this.selectedAllocatedTo.forEach(staff => {
        let list = localList.filter((val: any) => (val.AllocatedTo.toLowerCase().includes(staff.full_name.toLowerCase())));
        filteredLocalList = this.loadList(list, filteredLocalList);
      })
      filteredTasks = filteredLocalList;
    }
    if (this.selectedIssueType.length > 0) {
      let filteredLocalList: any[] = [];
      let localList: any[] = [];
      if (this.isFiltered) {
        localList = filteredTasks;
      } else {
        localList = unfilteredTasks;
      }
      this.selectedIssueType.forEach(issueType => {
        let list = localList.filter((val: any) => val.issue_type === issueType.name);
        filteredLocalList = this.loadList(list, filteredLocalList);
      })
      filteredTasks = filteredLocalList;
    }

    if (this.selectedIssueStatus.length > 0) {
      let filteredLocalList: any[] = [];
      let localList: any[] = [];
      if (this.isFiltered) {
        localList = filteredTasks;
      } else {
        localList = unfilteredTasks;
      }
      this.selectedIssueStatus.forEach(issueStatus => {
        let list = localList.filter((val: any) => val.issue_status === issueStatus.name);
        filteredLocalList = this.loadList(list, filteredLocalList);
      })
      filteredTasks = filteredLocalList;
    }
    if (this.selectedStatus.length > 0) {
      let filteredLocalList: any[] = [];
      let localList: any[] = [];
      if (this.isFiltered) {
        localList = filteredTasks;
      } else {
        localList = unfilteredTasks;
      }
      this.selectedStatus.forEach(supportStatus => {
        let list = localList.filter((val: any) => val.support_status === supportStatus.name);
        filteredLocalList = this.loadList(list, filteredLocalList);
      })
      filteredTasks = filteredLocalList;
    }

    if (this.selectedDueStatus.length > 0) {
      let filteredLocalList: any[] = [];
      let localList: any[] = [];
      if (this.isFiltered) {
        localList = filteredTasks;
      } else {
        localList = unfilteredTasks;
      }
      this.selectedDueStatus.forEach(dueStatus => {
        let list = localList.filter((val: any) => (val.ageing > dueStatus.rangeFrom && val.ageing < dueStatus.rangeTo));
        filteredLocalList = this.loadList(list, filteredLocalList);
      })
      filteredTasks = filteredLocalList;
    }

    if (this.isFiltered) {
      this.taskList = filteredTasks;
    } else {
      this.taskList = unfilteredTasks;
    }

    if (this.viewKanban) {
      this.loadKanban();
    } else {
      dt.value = filteredTasks;
    }
  }

  exportPdf() {


    import('jspdf').then(jsPDF => {
      import('jspdf-autotable').then(x => {
        /*  const doc = new jsPDF.default(0,0);
         doc.autoTable(this.exportColumns, this.staffs); 
         doc.save('Issues.pdf'); */
      })
    })
  }

  exportExcel(selectedtask) {
if(this.showInfoFieldsColumn){
  this.cols.splice(5, 0,  { field: 'info_1', header: this.info_fields.field_1_caption },   );
  this.cols.splice(6, 0,  { field: 'info_3', header: this.info_fields.field_3_caption },   );
}
else if(this.showInfoFieldsColumn && this.wf_code!='7'){
  this.cols.splice(7, 0,  { field: 'info_2', header: this.info_fields.field_2_caption },   );
}
console.log(this.cols);
debugger
    if (selectedtask && selectedtask.length !== 0) {
      this.reformattedArray = this.selectedtask.map(obj => {
        let rObj = {};
        this.cols.forEach(pair => {
          rObj[pair.header.toString()] = obj[pair.field];
        });
        return rObj;
      })
    } else {
      let JsonData: any[] = [];
    if (this.task != null && this.task.length > 0) {
      JsonData = this.task;
    } else {
      JsonData = this.taskList;
      // if (typeof table.filteredValue !== 'undefined') {
      //   if (table.filteredValue.length !== this.taskList.length && table.filteredValue.length > 0) {
      //     JsonData = table.filteredValue;
      //   } else {
      //     JsonData = this.taskList;
      //   }
      // } else {
      //   JsonData = this.taskList;
      // }
    }
    this.reformattedArray = JsonData.map(obj => {

      let rObj = {};
      this.cols.forEach(pair => {
        rObj[pair.header.toString()] = obj[pair.field];
      });
      return rObj;
    })

    }

    import('xlsx').then(xlsx => {
      debugger
      const worksheet = xlsx.utils.json_to_sheet(this.reformattedArray);
      const workbook = { Sheets: { data: worksheet }, SheetNames: ['data'] };
      const excelBuffer: any = xlsx.write(workbook, { bookType: 'xlsx', type: 'array' });
      this.saveAsExcelFile(excelBuffer, 'taskList');
    });
  }

  saveAsExcelFile(buffer: any, fileName: string): void {
    import('file-saver').then(FileSaver => {
      const EXCEL_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
      const EXCEL_EXTENSION = '.xlsx';
      const data: Blob = new Blob([buffer], {
        type: EXCEL_TYPE
      });
      let timeStamp = this.datePipe.transform(new Date(), 'ddMMyy_HHmm');
      FileSaver.saveAs(data, fileName + '_' + timeStamp + EXCEL_EXTENSION);
    });
  }



  public dropGrid(event: CdkDragDrop<string[]>): void {
    moveItemInArray(this.workflowDetails.columns, event.previousIndex, event.currentIndex);
    this.crudService.sendNotification('notification', 'sample').subscribe((data2: any[]) => {
    });
  }

  async drop(event: CdkDragDrop<string[]>) {
    if (event.previousContainer === event.container) {
      moveItemInArray(event.container.data, event.previousIndex, event.currentIndex);
    } else {
      transferArrayItem(
        event.previousContainer.data,
        event.container.data,
        event.previousIndex,
        event.currentIndex
      );
      ////console.log(event);
      this.confirmationService.confirm({
        message: 'Are you sure you want to update the Status?',
        header: 'Confirm',
        icon: 'pi pi-exclamation-triangle',
        accept: async () => {
          let currentStage = this.stagesList.filter((val: any) => val.id == event.container.id);
          if (currentStage.length > 0) {
            let task: any = event.container.data[event.currentIndex];
            task.wd_code = currentStage[0].code;
            let data = await this.commonservice.saveRecord('task_head', task);
            if (data.success) {
              const self = this;
              this.wfService.fetchColumnsList(this.wf_code, this.userDetails.id).subscribe(async (columnsData: any[]) => {
                this.stagesList = columnsData;
                await this.fetchTaskList();
                this.loadActiveTasks(this.originalTaskList);
                if (this.viewKanban) {
                  this.loadKanban();
                  self.stagesList?.map(async (data: any) => {
                    //console.log(data.tasks)
                    const json: any = data.tasks?.filter((task: any) => {
                      if (task.id == 387) {
                        return true;
                      }
                    })
                  })
                }
                //this.stagesListOrg = Object.assign([], this.stagesList);
                //          this.fetchTaskList();
              });
              this.notifyUsers(task, task.id + '- Support Status Updated',
                'Please Review.',
                'Notification Sent for Review to Requester.',
                task.created_by);
              this.messageService.add({ severity: 'success', summary: 'Successful', detail: 'Record Updated', life: 4000 });
              //this.crudService.emitEvent(this.userDetails.id, task.id + '- Support Status Updated', "/")
            } else {
              this.messageService.add({ severity: 'error', summary: 'Error', detail: 'Record Not Updated', life: 4000 });
            }
          }
        },
        reject: () => {
          transferArrayItem(
            event.container.data,
            event.previousContainer.data,
            event.currentIndex,
            event.previousIndex
          );
        }
      });
    }
  }

  groupChanged(event: CdkDragDrop<string[]>) {
    //console.log('event info', event)
    moveItemInArray(this.workflowDetails.columns, event.previousIndex, event.currentIndex);
  }

  addCheckListItem(task) {
    let taskCheckListItem = {
      id: null,
      task_id: task.id,
      description: null,
      status: 'Active',
      checked: '1',
      isChecked: false,
      created_by: this.userDetails.id,
      created_at: new Date(),
      updated_by: null,
      updated_at: null
    };
    this.crudService.Create(taskCheckListItem, 'taskchecklist').subscribe((data: any) => {
      if (data.success === 1) {
        taskCheckListItem.id = data.id
        if (typeof task.checkList != 'undefined' && task.checkList.length > 0) {
        } else {
          task.checkList = [];
        }
        task.checkList.push(taskCheckListItem);
      } else {
        this.messageService.add({ severity: 'warning', summary: 'Error', detail: 'Checklist Item Not Created', life: 1000 });
      }
    });
  }

  notifyUsers(task, subject, message, notification, users) {
    let allocatees = null;
    let mail_ids = null;
    this.messageService.add({ severity: 'success', summary: subject, detail: notification, life: 4000 });
    this.crudService.getTaskUsers(task.id).subscribe((userData: any[]) => {
      let usersList = userData.filter(val => val.status === '1');
      let selectedAllocatees = usersList;
      let reqUser: any;
      let reqUserInfo = this.staffList.filter(val => val.user_id === task.created_by);
      if (reqUserInfo.length > 0) {
        reqUser = reqUserInfo[0];
      }
      if (selectedAllocatees.length > 0) {

        selectedAllocatees.forEach(allocatee => {
          if (allocatees === null) {
            allocatees = allocatee.first_name + ' ' + allocatee.last_name;
            mail_ids = allocatee.email ? allocatee.email : 'admin@agileitexperts.com';
          } else {
            allocatees = allocatees + ', ' + allocatee.first_name + ' ' + allocatee.last_name;
            mail_ids = mail_ids + ', ' + allocatee.email ? allocatee.email : 'admin@agileitexperts.com'
          }
          let AppNotifications: any = {
            status: '1',
            user_id: allocatee.user_id,
            message,
            title: subject,
            read_status: '0',
            ref_type: 'TASK',
            read_on: null,
            ref_id: task.id
          }
          AppNotifications.title = 'Task Notification';
          this.wfService.updateRecord('AppNotifications', AppNotifications);
        });
      } else {
        allocatees = 'Unallocated.'
      }
      /* let data={sms:null,email:{ cc:'', subject:'test ',bcc:'envirofrontiersk@gmail.com',to:'krishnanshridhar@gmail.com', attachments:'',msg:'This is to notify that a new ticket has been created . Please refer to below details.' }, issueDetails:{ id:'22', issue_description:'500 error on opening job page', priority:'Critical',created_by:'Shri',AllocatedTo:'Venkat' }, type:'email',selectedTpl:null,sufix:null,jobData:null,attachments:{},replyTo:'krishnanshridhar@gmail.com',account:'AU'} */

      let data = {
        sms: null,
        email: {
          cc: mail_ids,
          subject: subject,
          bcc: '',//'envirofrontiersk@gmail.com',
          to: 'venkat@agileitexperts.com, ' + reqUser.email,
          attachments: '',
          msg: message
        },
        issueDetails: {
          id: task.id,
          issue_description: task.description,
          priority: task.priority,
          created_by: reqUser.first_name + ' ' + reqUser.last_name,
          AllocatedTo: allocatees
        },
        type: 'email',
        selectedTpl: null,
        sufix: null,
        jobData: null,
        attachments: {},
        replyTo: 'krishnanshridhar@gmail.com',
        account: 'AU'
      };
      /*  this.crudService.sendMail(data).subscribe((response: any) => {
         //console.log(response)
       }); */
    });
  }

  removeUser(userinfo, task) {
    //console.log(userinfo);
    task.selectedAllocatees = task.selectedAllocatees.filter(val => val.user_id !== userinfo.user_id);
    this.saveTaskUsers(task);
  }

  saveTaskUsers(task) {
    this.crudService.getTaskUsers(task.id).subscribe((taskusersList: any) => {
      taskusersList.forEach(taskuser => {
        let userList = task.selectedAllocatees.filter(val => val.user_id === taskuser.user_id);
        taskuser.id = taskuser.task_user_id;
        taskuser.updated_at = new Date().toLocaleString();
        taskuser.updated_by = this.userDetails.id;
        taskuser.action_yn = 'Y';
        taskuser.notify_yn = 'Y';
        taskuser.task_id = task.id;
        //taskuser.wf_wd_code = null;
        taskuser.created_by = taskuser.task_created_by;
        taskuser.created_at = taskuser.task_created_at;
        if (userList.length > 0) {
          let changed = false;
          if (taskuser.status == '3') {
            changed = true;
          }
          taskuser.status = '1';
          this.crudService.Update(taskuser, 'taskUsers').subscribe((data: any) => {
            if (changed) {
              this.updateAction(task, 'Incident Allocated To ' + taskuser.full_name, 'Staff Re-Allocated');
              this.notifyUsers(task, 'Ticked Allocated : ' + task.id,
                'Please Login to the portal to view Additional Details',
                'Allocation Notification to ' + taskuser.user_id + ' - ' + taskuser.full_name + ' Sent.',
                taskuser.user_id);

            }
          });
        } else {

          taskuser.status = '3';
          this.updateAction(task, 'Incident Allocated To ' + taskuser.full_name, 'Staff De-Allocated');
          this.crudService.Update(taskuser, 'taskUsers').subscribe((data: any) => {
            this.notifyUsers(task, 'Ticked Un-Allocated : ' + task.id,
              'Please contact Admin/Requested Staff for details.',
              'Un - Allocation Notification to ' + taskuser.user_id + ' - ' + taskuser.full_name + ' Sent.',
              taskuser.user_id);
          });
        }
      });

      task.selectedAllocatees.forEach(allocatee => {
        let newuser: any = {};
        let userList = taskusersList.filter(val => val.user_id === allocatee.user_id);
        if (userList.length > 0) {
        } else {
          newuser.user_id = allocatee.user_id;
          newuser.task_id = task.id;
          newuser.action_yn = 'Y';
          newuser.notify_yn = 'Y';
          newuser.wf_wd_code = null;
          newuser.status = '1';
          newuser.created_at = new Date().toLocaleString();
          newuser.created_by = this.userDetails.id;
          newuser.updated_at = null;
          newuser.updated_by = null;
          this.crudService.Create(newuser, 'taskUsers').subscribe((data: any) => {
            if (data.success == 1) {
              this.notifyUsers(task, 'Incident Allocated : ' + task.id,
                'Please Login to the portal to view Additional Details',
                'Allocation Notification to ' + allocatee.user_id + ' - ' + allocatee.full_name + ' Sent.',
                allocatee.user_id);
              this.updateAction(task, 'Incident Allocated To ' + allocatee.full_name, 'Staff Allocated');
              newuser.id = data.id;
            }
          });
        }
      });
    });
  }

  updateAction(task, message, action) {
    let taskMessage: any = {
      id: null,
      task_id: task.id,
      message: message,
      file_type: null,
      file_path: null,
      file_name: null,
      status: '1',
      action: action,
      star_colour: null,
      process_id: this.companyId,
      created_by: this.userDetails.id,
      created_at: new Date().toLocaleString(),
      updated_by: null,
      updated_at: null
    };
    this.crudService.Create(taskMessage, 'TaskMessages').subscribe((res: any) => {
    })
  }

  searchBoard(value, dt) {
    this.quickFilterTasks(dt);
    value = value.toLowerCase() || '';
    if (value !== '') {
      this.stagesList.forEach(stage => {
        this.taskList = this.taskList.filter((val: any) => (
          val.description.toLowerCase().includes(value) ||
          val.id.includes(value) ||
          val.name.toLowerCase().includes(value) ||
          val.priority.toLowerCase().includes(value) ||
          val.AllocatedTo.toLowerCase().includes(value) ||
          val.created_date.toLowerCase().includes(value) ||
          val.issue_status.toLowerCase().includes(value) ||
          new Date(val.occurance_date).toLocaleString().includes(value) ||
          val.priority.toLowerCase().includes(value) ||
          new Date(val.reqby_date).toLocaleString().includes(value) ||
          val.reqby_name.toLowerCase().includes(value) ||
          val.ageing_status.toLowerCase().includes(value) ||
          val.support_status.toLowerCase().includes(value)));
      });
    }
    if (this.viewKanban) {
      this.loadKanban();
    } else {
      dt.value = this.taskList;
    }
  }

  filterOverdueTasks(item, dt) {
    item.selected = !item.selected;
    this.quickFilterTasks(dt);
  }

  filterAllocation(item, dt) {
    this.allocationFilterTypes.forEach(filterItem => {
      if (item.id === filterItem.id) {
        filterItem.selected = true;
      } else {
        filterItem.selected = false;
      }
    });
    this.quickFilterTasks(dt);
  }

  filterTaskStatus(item, dt) {
    //dt.clear()
    this.option=null
        this.myDropDown.clear(null);
    this.taskStatusFilterTypes.forEach(filterItem => {
      if (item.id === filterItem.id) {
        filterItem.selected = true;
      } else {
        filterItem.selected = false;
      }
    });
    this.quickFilterTasks(dt);
  }

  quickFilterTasks(dt) {

    let status = this.taskStatusFilterTypes.filter((val: any) => val.selected === true)[0].id;
    if (status === '9') {  ////Closed
      this.loadClosedTasks(this.originalTaskList);
    } else if (status === '10') { ////All
      this.taskList = this.tasklist_All;
    } else { ////Active
      this.loadActiveTasks(this.originalTaskList);
    }
    let unfilteredTasks = this.taskList;
    let filteredTasks = [];
    let isFiltered = false;

    let filterList = this.dueDateFilterTypes.filter((val: any) => (val.selected === true));

    if (filterList.length > 0) {
      filterList.forEach(filterItem => {
        if (isFiltered) {
          let list = unfilteredTasks.filter((val: any) => (val.ageing > filterItem.rangeFrom && val.ageing < filterItem.rangeTo));
          filteredTasks = filteredTasks.concat(list);
        } else {
          filteredTasks = unfilteredTasks.filter((val: any) => (val.ageing > filterItem.rangeFrom && val.ageing < filterItem.rangeTo));
        }
        isFiltered = true;
      })
    }
    if (!isFiltered) {
      filteredTasks = unfilteredTasks;
    }
    this.allocationFilterTypes.forEach(filterItem => {
      if (filterItem.selected) {
        if (filterItem.id === '2') {
          filteredTasks = filteredTasks.filter((val: any) => (val.AllocatedTo.includes(this.staffInfo.first_name + ' ' + this.staffInfo.last_name)));
        } else if (filterItem.id === '3') {
          filteredTasks = filteredTasks.filter((val: any) => (val.reqby_name.includes(this.staffInfo.first_name + ' ' + this.staffInfo.last_name)));
        }
      }
    });
    this.taskList = filteredTasks;
    if (this.viewKanban) {
      this.loadKanban();
    } else {
      dt.value = filteredTasks;
    }
  }

  async viewTaskAction(task) {
    if (task.wf_code == '1') {
      await this.loadCompanyAction(task);
    } else if (task.wf_code == '7') {
      await this.loadJobAction(task);
    }
  }

  async viewTPInfo(task) {
    let company = task.tp_company;
    const ref = this.dialogService.open(ContractorsComponent, {
      data: {
        contractor_id: company.id,
      },
      width: '100%',
      height: '100%'
    })
  }

  async loadCompanyAction(task) {
    this.loading = true;
    let data = await this.crudService.getDataByField('Company', 'id', task.tags).toPromise();
    let company = data[0];
    if (task.type == 'OB') {
      const ref = this.dialogService.open(TPRegistrationComponent, {
        data: {
          compToken: company.token,
        },
        width: '100%',
        height: '100%'
      })
      ref.onClose.subscribe((response: any) => {
        this.fetchTaskList();
      });
    } else if (task.type == 'CC' || task.type == 'SC') {
      const ref = this.dialogService.open(CompanyComplianceComponent, {
        header: company.business_name,
        footer: company.email + ', ' + company.phone,
        data: {
          compToken: company.token,
          compId: company.id,
        },
        width: '100%',
        height: '100%'
      })
    }
    this.loading = false;
  }
  async loadJobAction(task) {
    this.loading = true;
    let task_values: any;
    let taskValuesData: any = await this.crudService.getDataByField('task_values', 'task_id', task.id).toPromise();
    if (taskValuesData.length > 0) {
      task_values = taskValuesData[0];
      if (task_values.step !== null && task_values.step !== '') {
      }
    }
    if (task_values.job_alloc_id !== '1') {
      let smryData: any = await this.crudService.getJobSummary(task_values.job_id, task_values.job_alloc_id).toPromise();
      let jobAllocationResults = await this.JobService.getJobInfo(smryData[0]);
      this.loading = false;
      let JobStatus: any = 'Approve Quote';
      if (JobStatus === 'Cost Saved' || JobStatus === 'Approve Quote' || JobStatus === 'Await Cost' || JobStatus === 'Quote Rejected') {
        let viewType = 'Quote';
        const ref = this.dialogService.open(JobDetailsApprovalsComponent, {
          data: {
            jobId: task_values.job_id,
            allocId: task_values.job_alloc_id,
            jobSummary: jobAllocationResults,
            viewType: viewType,
            defaultTab: task_values.step
          },
          width: '100%',
          height: '100%'
        })
        ref.onClose.subscribe((response: any) => {
          this.fetchTaskList();
        });
      } else if (JobStatus === 'Approve Invoice' || JobStatus === 'Await Invoice' || JobStatus === 'Invoice Processed' || JobStatus === 'Accounts Proecessed' || JobStatus === 'Job Completed') {
        let viewType = 'Approval';
        const ref = this.dialogService.open(JobDetailsApprovalsComponent, {
          data: {
            jobId: task_values.job_id,
            allocId: task_values.job_alloc_id,
            jobSummary: jobAllocationResults,
            viewType: viewType,
            defaultTab: task_values.step
          },
          width: '100%',
          height: '100%'
        })
        ref.onClose.subscribe((response: any) => {
          this.fetchTaskList();
        });

      } else if (JobStatus === 'Await Acceptance' || JobStatus === 'Send Offer' || JobStatus === 'Quote Rejected') {
        const ref = this.dialogService.open(JobAdminComponent, {
          data: {
            jobId: task_values.job_id,
          },
          width: '100%',
          height: '100%'
        })
        ref.onClose.subscribe((response: any) => {
          this.fetchTaskList();
        });
      }
    } else {
      const ref = this.dialogService.open(JobAdminComponent, {
        data: {
          jobId: task_values.job_id,
        },
        width: '100%',
        height: '100%'
      })
      ref.onClose.subscribe((response: any) => {
        this.fetchTaskList();
      });
    }
    this.loading = false;
  }


  async openJobDetails(task) {
    this.loading = true;
    let task_values: any;
    let taskValuesData: any = await this.crudService.getDataByField('task_values', 'task_id', task.id).toPromise();
    if (taskValuesData.length > 0) {
      task_values = taskValuesData[0];
      const ref = this.dialogService.open(JobAdminComponent, {
        data: {
          jobId: task_values.job_id,
        },
        width: '100%',
        height: '100%'
      })
      ref.onClose.subscribe((response: any) => {
        this.fetchTaskList();
      });
    }
    this.loading = false;
  }
  expandCollapse() {
    this.collapsed = !this.collapsed;
  }
  openAppNotification() {
    const ref = this.dialogService.open(AppReminderComponent, {
      // data: {
      //   wf_code: this.wf_code,
      //   task_id: 0,
      //   redirectMenu: this.menuData
      // },
      width: 'auto',
      height: '100%',
      header: 'Event'
    })

    ref.onClose.subscribe(async (taskdetails: any) => {
      await this.fetchTaskList();
      // this.filterData(null);
      // this.quickFilterTasks(null);
    });
  }

  async filterByIssueType() {
    this.loadActiveTasks(this.originalTaskList);
    let list: any[] = [];
    if (this.selectedIssueType.length > 0) {
      if (this.wf_code == '7') {
        this.selectedIssueType.forEach(async type => {
          let data = this.taskList.filter(val => (val.step == type.valueset_detail_name));
          if (data.length > 0) {
            list = [...list, ...data];
          }
        });
      } else {
        this.selectedIssueType.forEach(async type => {
          let data = this.taskList.filter(val => (val.type == type.valueset_detail_name));
          if (data.length > 0) {
            list = [...list, ...data];
          }
        });
      }
      this.taskList = list;
    } else {
      this.loadActiveTasks(this.originalTaskList);
    }
    if (this.viewKanban) {
      this.loadKanban();
    }
  }

  updateSelectedTasks() {
    const ref = this.dialogService.open(TaskUpdateComponent, {
      data: {
        selectedTasks: this.selectedtask,
      },
      width: '100%',
      height: '100%'
    })
    ref.onClose.subscribe((response: any) => {
      this.fetchTaskList();
    });
  }

  allocateUsers() {
    this.selectedTasksList = [];
    this.selectedUser = [];
    this.next_followup_date = new Date();
    this.selectedTime = null;
    this.event_remarks = '';
    this.selectedtask.forEach(task => {
      this.selectedTasksList.push(task.id);
    });
    this.displayAllocationDialog = true;
    this.filteredTimeSlots = this.timeSlots;
  }

  async saveEventInfo() {
    this.loading = true;
    let data = await this.crudService.getDataByField('Company', 'id', this.companyId).toPromise();
    let companyInfo = data[0];
    let time: any;
    if (this.selectedTime.includes('am')) {
      time = this.selectedTime.replace('am', ' AM')
    } else if (this.selectedTime.includes('pm')) {
      time = this.selectedTime.replace('pm', ' PM')
    }

    let response = await this.crudService.allocateTaskUsers(this.selectedTasksList.toString(),
      this.selectedUser.user_id.toString(),
      this.next_followup_date.toISOString().split('T')[0] + ' ' + time,
      this.event_remarks, this.userDetails.id,
      environment.base_url + 'authredirect/TaskJobs/' + companyInfo.token).toPromise();
    this.loading = false;
    this.messageService.add({ severity: 'success', summary: 'Successful', detail: 'Selected Job(s) have been allocated to the Staff', life: 1000 });
    this.displayAllocationDialog = false;
    this.selectedtask = [];
    await this.fetchTaskList();
    this.loadActiveTasks(this.originalTaskList);
    this.filterByIssueType();
    this.loading = false;
  }

  search(event) {
    this.filteredTimeSlots = this.timeSlots.filter((val: any) => val.includes(event.query));
  }

  filterMultiSelectTp() {
    let filteredList: any[] = [];
    if (this.selectedTp.length > 0) {
      this.selectedTp.forEach(tp => {
        let list = this.originalTaskList.filter(val => (val.tp_company?.business_name == tp.code));
        filteredList = [...filteredList, ...list];
      });
      this.taskList = filteredList;
    }
    else {
      this.taskList = this.originalTaskList;
    }

  }

  filterMultiSelectWp() {
    let filteredList: any[] = [];
    if (this.selectedWp.length > 0) {
      this.selectedWp.forEach(tp => {
        let list = this.originalTaskList.filter(val => (val.info_3 == tp.code));
        filteredList = [...filteredList, ...list];
      });
      this.taskList = filteredList;
    }
    else {
      this.taskList = this.originalTaskList;
    }

  }
  filterMultiSelectEvent() {
    let filteredList: any[] = [];
    if (this.selectedEvent.length > 0) {
      this.selectedEvent.forEach(tp => {
        let list = this.originalTaskList.filter(val => (val.name == tp.code));
        filteredList = [...filteredList, ...list];
      });
      this.taskList = filteredList;
    }
    else {
      this.taskList = this.originalTaskList;
    }

  }
  changeDataType(){
    this.originalTaskList.forEach(val=>{
      var id: number = +val.id;
      val.id = id
    }) 
  }
  customSort(event: SortEvent) {
    event.data.sort((data1, data2) => {
      var value1 = data1[event.field];
      var value2 = data2[event.field];
     if(value1 == ""){
      value1 = null
     }
     if(value2 == ""){
      value2 = null
     }
      let result = null;
debugger
      if (value1 === null && value2 != null)
        result = -1;
      else if (value1 != null && value2 === null)
        result = 1;
      else if (value1 === null && value2 === null)
        result = 0;
      else if (typeof value1 === 'string' && typeof value2 === 'string') {
        let test = value1.substr(2,1)
        let date1 = moment(value1, 'DD-MM-YYYY')
        let date2 = moment(value2, 'DD-MM-YYYY')
        if(test == '-' || test == '/'){
          result = date1.diff(date2, 'days')
        }else{   
          result = value1.localeCompare(value2);
          // if(event.field == 'occurance_date'){
          // result= this. sortFn(value1,value2)
          // }
        //  else if(event.field == 'job_claim_no'){
        //     result= this. sortFn_2(value1,value2)
        //     }
        
        // else{
        //      result = value1.localeCompare(value2);
        //     }

     
        }
      }
      else
        result = (value1 < value2) ? -1 : (value1 > value2) ? 1 : 0;

      return (event.order * result);
    });
  }
   sortFn(a, b) {    
    a = a.toLowerCase( ).replace(/[&\/\\#, +()$~%.'":*?<>{}]/g, "");
    b= b.toLowerCase( ).replace(/[&\/\\#, +()$~%.'":*?<>{}]/g, "");
    var isNumber = function (v) { return (+v).toString() === v; },
        aa = a.match(/\d+|\D+/g),
        bb = b.match(/\d+|\D+/g),
        i = 0,
        l = Math.min(aa.length, bb.length);

    while (i < l && aa[i] === bb[i]) {
        i++;
    }
    if (i === l) {
        return aa.length - bb.length;
    }
    if (isNumber(aa[i]) && isNumber(bb[i])) {
        return aa[i] - bb[i];
    }
    return aa[i].localeCompare(bb[i]);   
}
changetype(event){
this.option = event
}
}
