import { Component, OnInit, ViewChild } from '@angular/core';
import { Client, position } from './client';
import { CRUDService } from 'src/app/utilities/crud.service';
import { DynamicDialogRef, DialogService } from 'primeng/dynamicdialog';
import { Table } from 'primeng/table';
import { ContactComponent } from '../contact/contact.component';
import { ComplianceComponent } from '../compliance/compliance.component';
import { ConfirmationService, MessageService, SortEvent } from 'primeng/api';
import { FormControl, FormGroup, Validators, FormBuilder } from '@angular/forms';
import { CommonService } from 'src/app/utilities/common.service';
import { environment } from 'src/environments/environment';
import { Location } from '@angular/common';
import { DatePipe } from '@angular/common';
import { GooglePlaceDirective } from 'ngx-google-places-autocomplete';
import { async } from '@angular/core/testing';

@Component({
  selector: 'app-clients',
  templateUrl: './clients.component.html',
  styleUrls: ['./clients.component.scss'],
  providers: [DatePipe]
})
export class ClientsComponent implements OnInit {

  paginationvalue: any;
  @ViewChild("dt") dt: Table
  viewType: any;
  menuData: any;
  userDetails: any;
  companyId: any;
  clientDialog: any = false;
  companyList: any[];
  clientList: any = [];
  selectedClients: any[];
  contactsDialog: boolean;
  stateList: any[];
  selectedState: any;
  selectedCountry: any;
  countryList: any[];
  cols: any[];
  exportColumns: any[];
  client: any;
  editClientObject: Client;
  dataValue: any[];
  submitted: any;
  clients: Client[];
  /* clientTypeList: any[]; */
  positionList: position[];
  selecetedPosition: any;
  clientId: any;
  ref: DynamicDialogRef;
  selectedCategory: any;
  paymentTermsList: any[];
  selectedPaymentTerm: any;
  isActive: boolean;
  selectedClientType: any;
  formStatus: boolean;
  ResidentialForm: FormGroup;
  CommercialForm: FormGroup;
  searchvalue: any;

  selectedCommType: any;
  communicationTypeList: any[];
  selectedSubType: any;
  clientSubTypeList: any[];
  selectedComplianceLevel: any;
  complianceLevelList: any[];
  employeSize: any[];
  treeCrew: any[];
  selectedemployeSize: any;
  selectedtreeCrew: any;
  isDepot: Boolean;
  loading: Boolean = true;
  clientType: any[] = [{ name: 'Work Provider', id: '2' }];//  { name: 'Admin', id: '1' }  , { name: 'Contractor', id: '3' }, { name: 'License Product TP', id: '4' }
  clientCategory: any[] = [{ name: 'Commercial', id: '2' }, { name: 'Residential', id: '1' }];

  options = {
    componentRestrictions: {
      country: [environment.hosting]
    }
  }
  componentForm = {
    street_number: 'short_name',
    route: 'long_name',
    locality: 'long_name',
    sublocality_level_1: 'long_name',
    administrative_area_level_1: 'short_name',
    country: 'long_name',
    postal_code: 'short_name'
  };

  Type = [
    { label: 'Commercial', value: "2" },
    { label: 'Residential', value: "1" },
  ]
  caption =
  {
    business_name: environment.hosting == 'UK'? 'Company Name' : 'Business Name',
    business_ABN: environment.hosting == 'UK'? 'Company Registration Number' : 'ABN',
  }

  showJob: boolean = false;
  respToggleMenu: boolean = false;
  exportdisplay: Boolean = false;
  appProps: any;
  //overlays: any;
  optionsMap: { center: { lat: number; lng: number; }; zoom: number; };
  //icons: { parking: { icon: string; }; library: { icon: string; }; info: { icon: string; }; compliant: { icon: string; }; job: { icon: string; }; noncompliant: { icon: string; }; };
  //iconBase: string;

  map: google.maps.Map;
  iconBase = 'https://maps.google.com/mapfiles/kml/shapes/';
  icons: any;
  overlays: any[] = [];
  disableSavebtn: boolean=false;

  userAccess: any;
  isExport: boolean=false;

  isDelete: boolean=false;
  selectedroute: string;
  setMap(event) {
    this.map = event.map;
  }
  formattedaddress = '';

  filteredPlaces: any[] = [];
  inputAddress:any = [];
  dpClientList: any;

  constructor(private crudService: CRUDService, private datePipe: DatePipe,
    private messageService: MessageService,
    private confirmationService: ConfirmationService,
    private dialogService: DialogService,
    private commonservice: CommonService,
    private location: Location,
    private fb: FormBuilder) { }


  env: any;

  async ngOnInit() {
    this.env = environment;
    this.appProps = this.commonservice.getAppProperties();
    this.paginationvalue = environment.paginatorValue;
    this.menuData = JSON.parse(sessionStorage.getItem('menuData'));
    this.selectedroute = sessionStorage.getItem('selectedRoute')
    this.viewType = this.menuData.para_1;
    this.companyId = sessionStorage.getItem('company_id');
    this.userDetails = sessionStorage.getItem('loggedInUser');
    this.selectedroute = sessionStorage.getItem('selectedRoute')
    if (this.viewType === 'TM') {
      // this.crudService.getAllCompanies().subscribe((data: any[]) => {
      //   this.companyList = data;
      //   //console.log(this.companyList);
      // });
      this.crudService.getByCompanyWPTP('2').subscribe(async (data: any[]) => {
        this.clientList = data;
       
        this.dpClientList = data;
       // await this.fetchType(this.clientList);
       // console.log("this.clientList", this.clientList);
      });
    } else {
      this.crudService.getForLicense(this.companyId, '6').subscribe(async (data: any[]) => {
        this.clientList = data;
      
        this.dpClientList = data;
       // await this.fetchType(this.clientList);
       // console.log("this.clientList", this.clientList);
      });
    }
  
  
  
    let per_data :any=await this.crudService.getmenupermissions()
    this.isExport = per_data.isExport;
    this.isDelete = per_data.isDelete;


    this.getAllStates();
    this.getAllCountries();
    this.crudService.fetchValueSet('PaymentTermsList').subscribe((data: any[]) => {
      this.paymentTermsList = data.sort((a, b) => a.id.localeCompare(b.id));
    });
    /* this.crudService.fetchValueSet('ClientTypeList').subscribe((data: any[]) => {
      this.clientTypeList = data;
    }); */
    this.crudService.fetchValueSet('ClientCommType').subscribe((data: any[]) => {
      this.communicationTypeList = data;
    });

    this.crudService.getByValuesetclienttypeWP().subscribe((data: any[]) => {
      this.clientSubTypeList = data;
    });

    this.crudService.getAllComplianceLevelHead().subscribe((data: any[]) => {
      this.complianceLevelList = data;
    });
    this.cols = [
      { field: 'business_name', header: 'Business Name' },
      { field: 'trading_name', header: 'trading name' },
      { field: 'email', header: 'email' },
      { field: 'phone', header: 'Phone' },
      { field: 'type', header: 'Type' },
      { field: 'StateName', header: 'state name' }
    ];
    this.exportColumns = this.cols.map(col => ({ title: col.header, dataKey: col.field }));

    this.CommercialForm = new FormGroup({
      //  depot_name: new FormControl('', Validators.required),
      phone: new FormControl('', [Validators.pattern('[- +()0-9]+')]),
      mobile_no: new FormControl('', [Validators.pattern('[- +()0-9]+')]),
      email: new FormControl(''),
      street_address: new FormControl(''),
      suburb: new FormControl(''),
      postcode: new FormControl(''),
      state: new FormControl(''),
      country: new FormControl(''),
      business_name: new FormControl('', Validators.required),
      trading_name: new FormControl('', Validators.required),
      business_ABN: new FormControl(''),
      paymentType: new FormControl(''),
      clientType: new FormControl('', Validators.required),
      main_contact_name: new FormControl(''),
      // employeSize: new FormControl(''),
      treeCrew: new FormControl(''),
      communication_type: new FormControl(''),
      client_sub_type: new FormControl(''),
      compliance_level: new FormControl('')
      // website: new FormControl('', Validators.required),
    });
    this.ResidentialForm = new FormGroup({
      main_contact_name: new FormControl('', Validators.required),
      phone: new FormControl('', Validators.required),
      mobile_no: new FormControl(''),
      email: new FormControl(''),
      street_address: new FormControl(''),
      suburb: new FormControl(''),
      postcode: new FormControl(''),
      state: new FormControl(''),
      country: new FormControl(''),
    })
    this.employeSize = [
      { name: '2-5', id: '0' },
      { name: '6-10', id: '1' },
      { name: '11-25', id: '2' },
      { name: '26-50', id: '3' },
      { name: '50-100', id: '4' },
    ];
    this.treeCrew = [
      { name: '1', id: '1' },
      { name: '2', id: '2' },
      { name: '3', id: '3' },
      { name: '4', id: '4' },
      { name: '5 or More', id: '5' },
    ];
    // this.options = {
    //   types: [],
    //     componentRestrictions: {
    //         country: ['AU']
    //     }
    // }
    this.exportdisplay = await this.commonservice.exportCheck();
    this.loading = false;
  }

  fetchType(clientList)
  {
     clientList.forEach(client => {
      let type = this.Type.filter(val => val.value === client.client_category);
      client.type = type[0].label;
    });
   // console.log("this.clientList4", this.clientList);
  }


  changeStatus(event) {
    this.clientList = this.dpClientList;
    if (event.value.name == "ACTIVE") {
      this.clientList = this.clientList.filter(val => val.status === '1');
    }
    if (event.value.name == "IN-ACTIVE") {
      this.clientList = this.clientList.filter(val => val.status === '2');
    }
  }

  exportPdf() {
    import('jspdf').then(jsPDF => {
      import('jspdf-autotable').then(x => {
        //  const doc = new jsPDF.default(0,0);
        //   doc.autoTable(this.exportColumns, this.selectedClients);
        //   doc.save('clients.pdf');
      })
    })
  }

  //   ngOnChanges() {
  //     this.ResidentialForm = {
  //       street_address: 'street_address',
  //       suburb: 'suburb',
  //       postcode: 'postcode',
  //         state: 'state',
  //         country: 'country',
  //     };
  //     this.options = {
  //         types: [],
  //         componentRestrictions: {
  //             country: ['AU']
  //         }
  //     }
  // }


  async resetFilters() {
    await this.ngOnInit();
  }

  exportExcel(selectedClients, table) {
    let JsonData: any[] = [];
    if (this.selectedClients != null && this.selectedClients.length > 0) {
      JsonData = this.selectedClients;
    } else {

      if (typeof table.filteredValue !== 'undefined') {
        if (table.filteredValue.length !== this.clientList.length && table.filteredValue.length > 0) {
          JsonData = table.filteredValue;
        } else {
          JsonData = this.clientList;
        }
      } else {
        JsonData = this.clientList;
      }
    }


    let reformattedArray = JsonData.map(obj => {

      let rObj = {};
      this.cols.forEach(pair => {
        rObj[pair.header.toString()] = obj[pair.field];
      });
      return rObj;
    })
    //console.log(reformattedArray);

    import('xlsx').then(xlsx => {
      const worksheet = xlsx.utils.json_to_sheet(reformattedArray);
      const workbook = { Sheets: { data: worksheet }, SheetNames: ['data'] };
      const excelBuffer: any = xlsx.write(workbook, { bookType: 'xlsx', type: 'array' });
      this.saveAsExcelFile(excelBuffer, 'client');
    });
  }

  saveAsExcelFile(buffer: any, fileName: string): void {
    import('file-saver').then(FileSaver => {
      const EXCEL_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
      const EXCEL_EXTENSION = '.xlsx';
      const data: Blob = new Blob([buffer], {
        type: EXCEL_TYPE
      });
      let timeStamp = this.datePipe.transform(new Date(), 'ddMMyy_HHmm');
      FileSaver.saveAs(data, fileName + '_' + timeStamp + EXCEL_EXTENSION);
    });
  }

  clear(table: Table) {
    table.clear();
    this.searchvalue = "";
  }


  getAllCountries() {
    this.crudService.getAll('Country').subscribe((data: any[]) => {
      this.countryList = data;
    });
  }

  getAllStates() {
    this.crudService.getAll('State').subscribe((data: any[]) => {
      this.stateList = data;
    //  console.log('stateListt',this.stateList)
    });
  }



  openNew() {
    this.CommercialForm.markAsUntouched();
    this.ResidentialForm.markAsUntouched();

    this.isActive = true;
    this.selectedClientType = {};
    this.selectedPaymentTerm = {};
    this.selectedCommType = {};
    this.selectedComplianceLevel = {};
    this.selectedCountry = {};
    this.selectedemployeSize = {};
    this.selectedSubType = {};
    this.selectedtreeCrew = {};
    this.selectedState = {};
    this.isDepot = false;

    this.isActive = true;
    this.clientId = null;
    this.selectedState = null;
    this.selectedCountry = null;
    this.selectedCategory = '2';
    this.selectedPaymentTerm = {};
    this.selectedClientType = {};
    ////console.log(this.clientId)
    this.selectedClients = [];
    this.formattedaddress = '';
    this.client = {
      business_ABN: null,
      email: null,
      phone: null,
      website: null,
      emp_size: null,
      street_address: null,
      invalid_login_count: null,
      trading_name: null,
      mobile_no: null,
      main_contact_name: null,
      suburb: null,
      postcode: null,
      state_id: null,
      country_id: null,
      status: null,
      remarks: null,
      priority: null,
      regn_status: null,
      margin: null,
      client_type_id: 6, // 5 for client 6 for contractor
      account_name: null,
      account_BSB: null,
      account_number: null,
      default_depot: null,
      account_email: null,
      no_of_crew: null,
      company_inactive_from: null,
      company_inactive_to: null,
      rebate: null,
      account_id: null,
      client_subtype: null,
      compliancelevel_id: null,
      addl_address: null,
      operations_circle: null, payment_terms: null, contractor_type: null,
      fence_required: null,
      process_id: this.companyId,
      client_category: '2',
      client_comm_type: null,
    };
    this.options = {
      componentRestrictions: {
        country: [environment.hosting]
      }
    }
    this.CommercialForm.clearValidators();
    this.ResidentialForm.clearValidators();
    if (this.viewType === 'TM') {
      this.client.process_id = '1';
    } else {
      this.client.process_id = this.companyId;
    }
    this.clientDialog = true;
    this.loadMap();
  }
  async hideDialog() {
    this.clientDialog = false;
    await this.ngOnInit();
  }

  checkName(value: any) {
    //let val = value.toLowerCase()
    this.client.trading_name = value;
  }

  reset() {
    this.confirmationService.confirm({
      message: 'Are you sure you want to cancel the changes made?',
      header: 'Confirm',
      icon: 'pi pi-exclamation-triangle',
      accept: () => {
        this.crudService.getDataByField('Company', 'id', this.client.id).subscribe((data: any[]) => {
          if (data.length > 0) {
            this.editSelectedItem(data[0]);
          }
        });
      }
    });
  }


  findIndexById(id: string): number {
    let index = -1;
    for (let i = 0; i < this.selectedClients.length; i++) {
      if (this.selectedClients[i].id === id) {
        index = i;
        break;
      }
    }

    return index;
  }

  async loadMap() {
    this.overlays = [];
    this.optionsMap = {
      center: { lat: environment.hostingConfigs.mapCenterLat, lng: environment.hostingConfigs.mapCenterLng },
      zoom: 4
    };

    this.icons = {
      parking: { icon: this.iconBase + 'parking_lot_maps.png' },
      library: { icon: this.iconBase + 'library_maps.png' },
      info: { icon: this.iconBase + 'info-i_maps.png' },
      compliant: { icon: 'http://maps.google.com/mapfiles/kml/paddle/grn-stars.png' },
      job: { icon: 'http://maps.google.com/mapfiles/kml/paddle/grn-stars.png' },
      noncompliant: { icon: 'http://maps.google.com/mapfiles/kml/paddle/stop.png' }
    };

    //this.loading = true;
    let contentString = null;
    contentString = '<div id="content"><div id="siteNotice"></div><h3 id="firstHeading" class="firstHeading">'
      + this.client.street_address;
    + this.client.suburb
      + this.client.postcode
      + this.client.state_id
      + this.client.country_id;
    let map = this.map;
    let lat = parseInt(this.client.location_lat);
    let lng = parseInt(this.client.location_lng);
    const marker = new google.maps.Marker(
      {
        position: new google.maps.LatLng(lat, lng),
        title: this.client.street_address,
        map,
        icon: this.icons['compliant'].icon
      });
    this.overlays.push(marker)

    const infowindow = new google.maps.InfoWindow({
      content: contentString,
    });

    google.maps.event.addListener(marker, 'click', (function (marker) {
      return function () {
        infowindow.setContent(contentString);
        infowindow.open(map, marker);
      }
    })(marker));
    //this.loading = false;
  }

  saveRecord() {
    //console.log("saveRecord");
    this.disableSavebtn =true
    this.submitted = true;
    this.client.process_id = this.companyId;
    this.client.client_subtype = null;
    this.client.emp_size = null;
    this.client.payment_terms = null;
    this.client.no_of_crew = null;
    this.client.compliancelevel_id = null;
    this.client.client_comm_type = null;
    if (this.selectedCategory === '1') {
      this.client.client_category = '1';
      // this.client.business_name = this.client.main_contact_name;
      if (this.ResidentialForm.status === 'VALID') {
        this.formStatus = true;
      } else {
        this.formStatus = false;
      }
    } else {
      this.client.client_category = '2';
      if (this.CommercialForm.status === 'VALID') {
        this.formStatus = true;
      } else {
        this.formStatus = false;
      }
      if (this.selectedSubType.id !== null && this.selectedSubType.id !== '') {
        this.client.client_subtype = this.selectedSubType.id;
      }
      if (this.selectedPaymentTerm.id !== null && this.selectedPaymentTerm.id !== '') {
        this.client.payment_terms = this.selectedPaymentTerm.id;
      }
      if (typeof this.selectedemployeSize.id !== 'undefined') {
        this.client.emp_size = this.selectedemployeSize.id;
      } else {
        this.client.emp_size = null;
      }
      if (typeof this.selectedtreeCrew.id !== 'undefined') {
        this.client.no_of_crew = this.selectedtreeCrew.id;
      } else {
        this.client.no_of_crew = null;
      }
      if (typeof this.selectedComplianceLevel.id !== 'undefined') {
        this.client.compliancelevel_id = this.selectedComplianceLevel.id;
      } else {
        this.client.compliancelevel_id = null;
      }
      if (typeof this.selectedCommType.id !== 'undefined') {
        this.client.client_comm_type = this.selectedCommType.id;
      } else {
        this.client.client_comm_type = null;
      }

    }

    if (this.viewType === 'TM') {
      if (typeof this.selectedClientType.id !== 'undefined') {
        this.client.client_type_id = this.selectedClientType.id;
      }
    }
    if (typeof this.selectedPaymentTerm.id !== 'undefined') {
      this.client.payment_terms = this.selectedPaymentTerm.id;
    } else {
      this.client.payment_terms = null;
    }
    if (typeof this.selectedCountry.id !== 'undefined') {
      this.client.country_id = this.selectedCountry.id;
    } else {
      this.client.country_id = null;
    }
    if (typeof this.selectedState?.id !== 'undefined') {
      this.client.state_id = this.selectedState.id;
    } else {
      this.client.state_id = null
    }

    if (this.isDepot) {
      this.client.default_depot = '2';
    } else {
      this.client.default_depot = '1';
    }

    if (this.client.deleted === undefined) {
      this.client.deleted = null;
    }
    if (this.client.last_modified_by === undefined) {
      this.client.last_modified_by = null;
    }
    if (this.client.last_updated_at === undefined) {
      this.client.last_updated_at = null;
    }
    if (this.client.owner === undefined) {
      this.client.owner = null;
    }
    if (this.client.site_address === undefined) {
      this.client.site_address = null;
    }
    if (this.isActive) {
      this.client.status = '1';
    } else {
      this.client.status = '2';
      this.formStatus = true;
    }
    if (this.formStatus) {
      if (this.client.id) {
        this.client.updated_at = new Date();
        this.client.updated_by = this.userDetails.user_id;
        if (this.client.updated_by === undefined) {
          this.client.updated_by = null;
        }
        //this.client.business_name = this.client.business_name.charAt(0).toUpperCase() + this.client.business_name.substr(1).toLowerCase();
        this.crudService.Update(this.client, 'company').subscribe((data: any) => {
          if (data.success === 1) {
            this.disableSavebtn =false
            this.messageService.add({ severity: 'success', summary: 'Successful', detail: 'Client Information Updated', life: 1000 });
            this.clientList[this.findIndexById(this.client.id)] = this.client;
          } else {
            this.disableSavebtn =false
            this.messageService.add({ severity: 'error', summary: 'Error', detail: 'Client Information Not Updated', life: 1000 });
          }
        });
      } else {
        this.client.created_at = new Date();
        this.client.created_by = this.userDetails.user_id;
        if (this.client.created_by === undefined) {
          this.client.created_by = null;
        }
        
        //this.client.business_name = this.client.business_name.charAt(0).toUpperCase() + this.client.business_name.substr(1).toLowerCase();
        this.crudService.Create(this.client, 'company').subscribe(async (data: any) => {
         
          if (data.success === 1) {
            //console.log("companyData",data);
             this.client.id = data.id;
             this.clientId = data.id;
            this.disableSavebtn =false
            setTimeout(() => {
              
              this.initTab();
        
              document.getElementById('help-video-trigger').addEventListener('click', function () {
                document.getElementById('help-video').style.display = 'block';
              })
        
              document.getElementById('vid-close')?.addEventListener('click', function () {
                document.getElementById('help-video').style.display = 'none';
              })
              document.getElementById('walkthrough-trigger').addEventListener('click', function () {
        
                if (addEle) {
                  document.body.appendChild(mainEle);
                  document.body.appendChild(mainEleNote);
                }
                count = 0;
                walkthroughScreen(walkthrough[count]);
              })
              const walkthrough = [
                {
                  element: document.getElementById('d-1'),
                  purpose: 'Enter your Business here'
                },
                {
                  element: document.getElementById('d-2'),
                  purpose: 'Enter your Trading Name'
                },
                {
                  element: document.getElementById('d-3'),
                  purpose: 'Enter your ABN/ACN'
                },
                {
                  element: document.getElementById('d-4'),
                  purpose: 'Enter Payment Terms'
                },
                {
                  element: document.getElementById('d-5'),
                  purpose: 'Enter Client Type'
                },
                {
                  element: document.getElementById('d-6'),
                  purpose: 'Enter your Email'
                },
                {
                  element: document.getElementById('d-7'),
                  purpose: 'Enter your Email'
                },
                {
                  element: document.getElementById('d-8'),
                  purpose: 'Enter your Email'
                },
                {
                  element: document.getElementById('d-9'),
                  purpose: 'Enter your Email'
                }
              ]
        
              let mainEle = document.getElementById('walkthrough-ele');
              let mainEleNote = document.getElementById('walkthrough-ele-note');
              let addEle = false;
              let count = 0;
              if (!mainEle) {
                mainEle = document.createElement('div');
                mainEle.id = 'walkthrough-ele';
                mainEleNote = document.createElement('div');
                let nextElement = document.createElement('div');
                let closeElement = document.createElement('div');
                let imgElement = document.createElement('img');
                imgElement.src = 'thinking.png';
                mainEleNote.id = 'walkthrough-ele-note';
                nextElement.id = 'nextWalkthrough';
                nextElement.innerHTML = 'Next';
                closeElement.id = 'closeWalkthrough';
                closeElement.innerHTML = 'x';
                mainEleNote.appendChild(nextElement);
                mainEleNote.appendChild(closeElement);
                mainEleNote.appendChild(imgElement);
                addEle = true;
                closeElement.addEventListener('click', function () {
                  closeWalkthrough();
                });
                nextElement.addEventListener('click', function () {
                  document.getElementById('wlk-info')?.remove();
                  if (count < walkthrough.length - 1) {
                    count++;
                    walkthroughScreen(walkthrough[count]);
                    if (count === walkthrough.length - 1) {
                      nextElement.innerHTML = 'Close';
                    }
                  } else {
                    closeWalkthrough();
                  }
                  if (count > 0) {
                    enableBack();
                  }
                });
              }
              function enableBack() {
                if (!document.getElementById('backWalkthrough')) {
                  let backElement = document.createElement('div');
                  backElement.id = 'backWalkthrough';
                  backElement.innerHTML = 'Back';
                  mainEleNote.appendChild(backElement);
                  backElement.addEventListener('click', function () {
                    document.getElementById('wlk-info')?.remove();
                    if (count > 0) {
                      count--;
                      walkthroughScreen(walkthrough[count]);
                      if (count === 0) {
                        backElement.remove();
                      }
                    }
                  });
                }
              }
        
              function walkthroughScreen(deet) {
        
                let o = deet;
                let x = o.element.offsetLeft;
                let y = o.element.offsetTop;
                let w = o.element.offsetWidth;
                let h = o.element.offsetHeight;
                //console.log('left-' + x);
                //console.log('top-' + y);
                //console.log('w-' + w);
                //console.log('h-' + h);
                mainEle.style.width = w + 'px';
                mainEle.style.height = h + 'px';
                mainEle.style.top = y + 'px';
                mainEle.style.left = x + 'px';
                mainEleNote.style.width = '500px';
                mainEleNote.style.height = '100px';
                mainEleNote.style.top = (y + h + 5) + 'px';
                mainEleNote.style.left = x + 'px';
                let ol = document.getElementById('wlk-info');
                if (ol) {
                  ol.remove();
                }
                let l = document.createElement('div');
                l.id = 'wlk-info';
                l.innerHTML = o.purpose;
                mainEleNote.appendChild(l)
              }
        
        
              function closeWalkthrough() {
                mainEle.remove();
                mainEleNote.remove();
              }
            }, 100);
            
            this.messageService.add({ severity: 'success', summary: 'Successful', detail: 'Client Created. Please Proceed With Entering Compliance and Additional Contact Details', life: 1000 });
            if (this.isDepot) {
            await this.saveDepot();
            }
            this.clientList = [...this.clients, this.client];
            // this.ngOnInit();
          } else {
            this.disableSavebtn =false
            this.clientDialog = true;
            this.messageService.add({ severity: 'error', summary: 'Error', detail: 'Client  Not Created', life: 1000 });
          }
        })
      }
    } else {
      this.disableSavebtn =false
      this.messageService.add({ severity: 'error', summary: 'Error', detail: 'Invalid Details Entered', life: 1000 });
    }
  }

  async saveDepot() {
    
    let depot = {
      id: null,
      process_id: this.client.id,
      depot_name: 'Default Depot',
      company_id: this.client.id,
      status: '1',
      make_default: '1',
      created_by: this.userDetails.id,
      last_modified_by: null,
      created_at: new Date(),
      last_updated_at: null,
      street_address: this.client.street_address,
      suburb: this.client.suburb,
      postcode: this.client.postcode,
      state_id: this.client.state_id,
      country_id: this.client.country_id,
      depot_desc: this.client.business_name,
      contact_name: this.client.first_name,
      email: this.client.email,
      contact_best: this.client.phone,
      contact_alt: null,
      lat: null,
      long: null,
      location_lat: this.client.location_lat,
      location_lng: this.client.location_lng,
      depot_short_code: null,
      CompanyName: this.client.business_name,
      TypeName: null,
      displayStatus: null,
      rownumber: null,
      company_name: this.client.business_name,
      StatusDescription: null,
      deleted : null
    }
    await this.crudService.Create(depot, 'Depot').toPromise();
}


  changeClient_Category() {
    //console.log(this.selectedCategory);
    if (this.selectedCategory === '2') {
      this.client.client_category = '2';
    } else {
      this.client.client_category = '1';
    }
  }




  public AddressChange(address: any) {
    const fullAddress = [];
    
    this.formattedaddress = address.formatted_address
    let lat = address.geometry.location.lat();
    let lng = address.geometry.location.lng();
    for (let i = 0; i < address.address_components.length; i++) {
      const addressType = address.address_components[i].types[0];
      // }
      if (this.componentForm[addressType]) {

        const val = address.address_components[i][this.componentForm[addressType]];

        if (addressType === 'street_number') {
          fullAddress[0] = val;
        } else if (addressType === 'route') {
          fullAddress[1] = val;
        }

        if (addressType === 'sublocality_level_1') {// suburb
          this.client.street_address = val;
        }
        if (addressType === 'locality') {// suburb
          this.client.suburb = val;
        } else if (addressType === 'administrative_area_level_1') {// state
          this.stateList.forEach((stat) => {
            if (environment.hosting == 'UK') {
              if (stat.name === 'NSW') {
                this.client.state_id = stat.id;
                this.selectedState = stat;
              }
            } else {
              if (stat.name === val) {
                this.client.state_id = stat.id;
                this.selectedState = stat;
              }

            }
          });

        } else if (addressType === 'administrative_area_level_2') {//council
          //                                                $scope.suburb = val;
        } else if (addressType === 'country') {
          this.countryList.forEach((ctry) => {
            if (ctry.name === val) {
              this.client.country_id = ctry.id;
              this.selectedCountry = ctry;
            }
          });
        } else if (addressType === 'postal_code') {
          this.client.postcode = val;
        }

      }
    }
    const fullAdr = fullAddress.join(' ');
    this.client.street_address = fullAdr;
    //console.log(this.client.street_address);
    this.client.location_lat = lat;
    this.client.location_lng = lng;
    this.loadMap();


  }

  editSelectedItem(client: Client) {
    this.CommercialForm.markAsUntouched();
    this.ResidentialForm.markAsUntouched();
    this.isActive = true;
    this.selectedClients = [];
    this.selectedClientType = {};
    this.selectedPaymentTerm = {};
    this.selectedClientType = {}
    this.selectedCommType = {};
    this.selectedComplianceLevel = {};
    this.selectedCountry = {};
    this.selectedemployeSize = {};
    this.selectedSubType = {};
    this.selectedtreeCrew = {};
    this.selectedState = {};
    this.isDepot = false;

    this.CommercialForm.clearValidators();
    this.ResidentialForm.clearValidators();
    this.client = { ...client }
    this.clientId = this.client.id;
    if (this.client.client_category === '1') {
      this.selectedCategory = '1';
    } else if (this.client.client_category === '2') {
      this.selectedCategory = '2';
    } else {
      this.selectedCategory = '2';
    }
    if (this.client.emp_size != null) {
      this.employeSize.forEach((emp) => {
        if (this.client.emp_size == emp.id) {
          this.selectedemployeSize = emp;
          this.client.emp_size = emp.id;
        }
      })
    }
    if (this.client.client_comm_type != null) {
      this.communicationTypeList.forEach((commtype) => {
        if (this.client.client_comm_type == commtype.id) {
          this.selectedCommType = commtype;
        }
      })
    }

    if (this.client.compliancelevel_id != null) {
      this.complianceLevelList.forEach((complianceLevel) => {
        if (this.client.compliancelevel_id == complianceLevel.id) {
          this.selectedComplianceLevel = complianceLevel;
        }
      })
    }

    if (this.client.client_subtype != null) {
      this.clientSubTypeList.forEach((subType) => {
        if (this.client.client_subtype == subType.id) {
          this.selectedSubType = subType;
        }
      })
    }

    if (this.client.no_of_crew != null) {
      this.treeCrew.forEach((crew) => {
        if (this.client.no_of_crew == crew.id) {
          this.selectedtreeCrew = crew;
          this.client.no_of_crew = crew.id
        }
      })
    }
    if (this.client.client_type_id != null) {
      this.clientType.forEach((client) => {
        if (this.client.client_type_id == client.id) {
          this.selectedClientType = client;
        }
      })
    }

    if (this.client.payment_terms !== null && this.client.payment_terms !== '') {
      this.crudService.getDataByField('ValueSetDetail', 'id', this.client.payment_terms).subscribe((data: any[]) => {
        this.selectedPaymentTerm = data[0];
      });
    }


    if (this.client.status === '' || this.client.status === null || this.client.status === '1') {
      this.client.status = '1'
      this.isActive = true;
    } else {
      this.client.status = '2'
      this.isActive = false;
    }
    if (this.client.default_depot === '1') {
      this.isDepot = true;
    } else {
      this.isDepot = false;
    }

    if (this.client.company_inactive_from != null && this.client.company_inactive_from !== '') {
      this.client.company_inactive_from = new Date(this.client.company_inactive_from);
    }
    if (this.client.company_inactive_to != null && this.client.company_inactive_to !== '') {
      this.client.company_inactive_to = new Date(this.client.company_inactive_to);
    }
    this.crudService.getDataByField('Country', 'id', this.client.country_id).subscribe((data: any[]) => {
      this.selectedCountry = data[0];
    });
    this.crudService.getDataByField('State', 'id', this.client.state_id).subscribe((data: any[]) => {
      this.selectedState = data[0];
    });
    this.clientDialog = true;
    this.loadMap();
    setTimeout(() => {

      this.initTab();

      document.getElementById('help-video-trigger').addEventListener('click', function () {
        document.getElementById('help-video').style.display = 'block';
      })

      document.getElementById('vid-close')?.addEventListener('click', function () {
        document.getElementById('help-video').style.display = 'none';
      })
      document.getElementById('walkthrough-trigger').addEventListener('click', function () {

        if (addEle) {
          document.body.appendChild(mainEle);
          document.body.appendChild(mainEleNote);
        }
        count = 0;
        walkthroughScreen(walkthrough[count]);
      })
      const walkthrough = [
        {
          element: document.getElementById('d-1'),
          purpose: 'Enter your Business here'
        },
        {
          element: document.getElementById('d-2'),
          purpose: 'Enter your Trading Name'
        },
        {
          element: document.getElementById('d-3'),
          purpose: 'Enter your ABN/ACN'
        },
        {
          element: document.getElementById('d-4'),
          purpose: 'Enter Payment Terms'
        },
        {
          element: document.getElementById('d-5'),
          purpose: 'Enter Client Type'
        },
        {
          element: document.getElementById('d-6'),
          purpose: 'Enter your Email'
        },
        {
          element: document.getElementById('d-7'),
          purpose: 'Enter your Email'
        },
        {
          element: document.getElementById('d-8'),
          purpose: 'Enter your Email'
        },
        {
          element: document.getElementById('d-9'),
          purpose: 'Enter your Email'
        }
      ]

      let mainEle = document.getElementById('walkthrough-ele');
      let mainEleNote = document.getElementById('walkthrough-ele-note');
      let addEle = false;
      let count = 0;
      if (!mainEle) {
        mainEle = document.createElement('div');
        mainEle.id = 'walkthrough-ele';
        mainEleNote = document.createElement('div');
        let nextElement = document.createElement('div');
        let closeElement = document.createElement('div');
        let imgElement = document.createElement('img');
        imgElement.src = 'thinking.png';
        mainEleNote.id = 'walkthrough-ele-note';
        nextElement.id = 'nextWalkthrough';
        nextElement.innerHTML = 'Next';
        closeElement.id = 'closeWalkthrough';
        closeElement.innerHTML = 'x';
        mainEleNote.appendChild(nextElement);
        mainEleNote.appendChild(closeElement);
        mainEleNote.appendChild(imgElement);
        addEle = true;
        closeElement.addEventListener('click', function () {
          closeWalkthrough();
        });
        nextElement.addEventListener('click', function () {
          document.getElementById('wlk-info')?.remove();
          if (count < walkthrough.length - 1) {
            count++;
            walkthroughScreen(walkthrough[count]);
            if (count === walkthrough.length - 1) {
              nextElement.innerHTML = 'Close';
            }
          } else {
            closeWalkthrough();
          }
          if (count > 0) {
            enableBack();
          }
        });
      }
      function enableBack() {
        if (!document.getElementById('backWalkthrough')) {
          let backElement = document.createElement('div');
          backElement.id = 'backWalkthrough';
          backElement.innerHTML = 'Back';
          mainEleNote.appendChild(backElement);
          backElement.addEventListener('click', function () {
            document.getElementById('wlk-info')?.remove();
            if (count > 0) {
              count--;
              walkthroughScreen(walkthrough[count]);
              if (count === 0) {
                backElement.remove();
              }
            }
          });
        }
      }

      function walkthroughScreen(deet) {

        let o = deet;
        let x = o.element.offsetLeft;
        let y = o.element.offsetTop;
        let w = o.element.offsetWidth;
        let h = o.element.offsetHeight;
        //console.log('left-' + x);
        //console.log('top-' + y);
        //console.log('w-' + w);
        //console.log('h-' + h);
        mainEle.style.width = w + 'px';
        mainEle.style.height = h + 'px';
        mainEle.style.top = y + 'px';
        mainEle.style.left = x + 'px';
        mainEleNote.style.width = '500px';
        mainEleNote.style.height = '100px';
        mainEleNote.style.top = (y + h + 5) + 'px';
        mainEleNote.style.left = x + 'px';
        let ol = document.getElementById('wlk-info');
        if (ol) {
          ol.remove();
        }
        let l = document.createElement('div');
        l.id = 'wlk-info';
        l.innerHTML = o.purpose;
        mainEleNote.appendChild(l)
      }


      function closeWalkthrough() {
        mainEle.remove();
        mainEleNote.remove();
      }
    }, 1000);

    this.crudService.getDataByField('Country', 'id', this.client.country_id).subscribe((data: any[]) => {
      this.selectedCountry = data[0];
    });
    this.crudService.getDataByField('State', 'id', this.client.state_id).subscribe((data: any[]) => {
      this.selectedState = data[0];
    });
    this.formattedaddress = this.client.street_address;
    this.loadMap();
  }

  tagConfig;
  initTab() {
    const tabGeneral = document.getElementById('tab-client');
    const tabContact = document.getElementById('tab-contact');
    // const tabCompliance = document.getElementById('tab-compliance');
    // const tabVehicle = document.getElementById('tab-vehicle');
    const tabJob = document.getElementById('tab-job');
    const tabPayments = document.getElementById('tab-payments');
    const tabNotes = document.getElementById('tab-notes');
    const tabRegion = document.getElementById('tab-region');
    const tabApproval = document.getElementById('tab-approval');
    let general = document.getElementsByClassName('g-info-tab-activate');
    let contact = document.getElementsByClassName('contact-info-tab-activate');
    // let compliance = document.getElementsByClassName('compliance-info-tab-activate');
    // let vehicle = document.getElementsByClassName('v-info-tab-activate');
    let job = document.getElementsByClassName('job-info-tab-activate');
    let payments = document.getElementsByClassName('payments-info-tab-activate');
    let notes = document.getElementsByClassName('notes-info-tab-activate');
    let region = document.getElementsByClassName('region-info-tab-activate');
    let approval = document.getElementsByClassName('approval-info-tab-activate');
    function initializeTabs(config) {

      // let l1 = [tabGeneral, tabContact, tabCompliance, tabVehicle, tabJob, tabPayments, tabNotes, tabRegion];
      // let l2 = [general, contact, compliance, vehicle, job, payments, notes, region];

      let l1 = [tabGeneral, tabContact, tabJob, tabPayments, tabNotes, tabRegion, tabApproval];
      let l2 = [general, contact, job, payments, notes, region, approval];


      config.forEach(c => {
        document.getElementById(c.id).addEventListener('click', function () {
          l1.forEach((ele) => {
            ele.classList.remove('active');
          });
          c.activeTab.classList.add('active');

          l2.forEach((ele) => {
            if (ele && ele.length > 0) {
              for (let i = 0; i < ele.length; i++) {
                ele[i].classList.add('hideme');
              }
            }
          });
          if (c.activeContent && c.activeContent.length > 0) {
            for (let i = 0; i < c.activeContent.length; i++) {
              c.activeContent[i].classList.remove('hideme');
            }
          }
        });
      })
    }
    this.tagConfig = [{
      id: 'tab-client',
      activeTab: tabGeneral,
      activeContent: general
    }, {
      id: 'tab-contact',
      activeTab: tabContact,
      activeContent: contact
    },
    //  {
    //   id: 'tab-compliance',
    //   activeTab: tabCompliance,
    //   activeContent: compliance
    // }, {
    //   id: 'tab-vehicle',
    //   activeTab: tabVehicle,
    //   activeContent: vehicle
    // },
    {
      id: 'tab-job',
      activeTab: tabJob,
      activeContent: job,
      content: 'showJob'
    }, {
      id: 'tab-payments',
      activeTab: tabPayments,
      activeContent: payments
    }, {
      id: 'tab-notes',
      activeTab: tabNotes,
      activeContent: notes
    }, {
      id: 'tab-region',
      activeTab: tabRegion,
      activeContent: region
    },
    {
      id: 'tab-approval',
      activeTab: tabApproval,
      activeContent: approval
    },];
    initializeTabs(this.tagConfig);
  }

  updateTab(v) {
    this.showJob = false;
    this.tagConfig.forEach(c => {
      if (c.content && c.id === v) {
        this[c.content] = true;
      }
    });
    this.respToggleMenu = false;
  }

  deleteSelectedClient() {
    this.confirmationService.confirm({
      message: 'Are you sure you want to delete the selected clients?',
      header: 'Confirm',
      icon: 'pi pi-exclamation-triangle',
      accept: () => {
        for (let i = 0; i < this.selectedClients.length; i++) {
          this.crudService.Delete(this.selectedClients[i].id, 'Company').subscribe((data: any[]) => { });
        }
        this.clientList = this.clientList.filter(val => !this.selectedClients.includes(val));
        this.selectedClients = null;
        this.messageService.add({ severity: 'success', summary: 'Successful', detail: 'clients Deleted', life: 1000 });
      }
    });
  }

  deleteClient(client: any) {
    this.confirmationService.confirm({
      message: 'Are you sure you want to delete Client : ' + client.business_name + '?',
      header: 'Confirm',
      icon: 'pi pi-exclamation-triangle',
      accept: () => {
        client.deleted = "1";
        this.crudService.Update(client, 'company').subscribe(async (data: any) => {
            if (data.success === 1) {
              this.clientList = this.clientList.filter((val: any) => val.id !== client.id);
              this.client = {};
                this.messageService.add({ severity: 'success', summary: 'Successful', detail: 'Company Deleted', life: 1000 });
            } else {
                this.messageService.add({ severity: 'error', summary: 'Error', detail: 'Company not Deleted', life: 1000 });
            }
        });
      }
    });
  }

  viewContact(client: Client) {
    this.client = { ...client }
    this.clientId = this.client.id;
    //console.log('client ID : ' + this.clientId);
    const ref = this.dialogService.open(ContactComponent, {
      data: {
        id: this.clientId
      },
      header: 'Client Contacts',
      width: '100%',
      height: '100%'
    });
  }

  viewCompliance(client: Client) {
    this.client = { ...client }
    this.clientId = this.client.id;
    const ref = this.dialogService.open(ComplianceComponent, {
      data: {
        staffId: '',
        company_id: this.clientId
      },
      header: 'Client Compliance',
      width: '100%',
      height: '100%'
    });
  }

  videoClose() {
    let close = document.getElementById("help-video");
    if (close.style.display === "none") {
      close.style.display = "block";
    } else {
      close.style.display = "none";
    }
  }
  isABNInvalid: Boolean = false;

  checkABN(value) {
    if (value != null && value != '') {
      value = value.replaceAll(' ', '');
      value = value.replaceAll('_', '');
      value = value.replaceAll('-', '');
      this.client.business_ABN = value;
      if (this.client.business_ABN.length == 11) {
        this.isABNInvalid = false;
      } else {
        this.isABNInvalid = true;
      }
    }
  }

  checkCn(value) {
    if (value.length >= 6 && value.length <= 12) {
      this.isABNInvalid = false;
    } else {
      this.isABNInvalid = true;
    }
  }

  chooseAddress(event)
  {     
        this.inputAddress = event.summaryline;
        this.formattedaddress = event.summaryline;
        this.client.suburb = event.posttown
        if(event.addressline2 != undefined)
        {
          this.client.street_address = event.addressline1 + ',' + event.addressline2;
        }
        else
        {
          this.client.street_address = event.addressline1;
        }
        this.client.postcode = event.postcode;
        this.client.location_lat = event.latitude;
        this.client.location_lng = event.longitude;
        this.stateList.forEach((stat) => {
            if (stat.id === '111') {
                this.client.state_id = stat.id;
                this.selectedState = stat;
            }
        }); 
        this.countryList.forEach((ctry) => {
            if (ctry.id === '222') {
                this.client.country_id = ctry.id;
                this.selectedCountry = ctry;
            }
        });
        this.loadMap();
  }

  search(event) {
    this.crudService.getPostcoderAddress(event.query).subscribe((res:any)=>{  
        this.filteredPlaces = res.filter((val: any) => val.summaryline.toLowerCase().includes(event.query.toLowerCase()));
      });
  }
}
