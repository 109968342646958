import { Component, Input, OnInit, ViewChild, Output, EventEmitter } from '@angular/core';
import { ActivatedRoute, Router, NavigationStart } from '@angular/router';
import { CRUDService } from 'src/app/utilities/crud.service';
import { ConfirmationService, MessageService } from 'primeng/api';
import { DomSanitizer } from '@angular/platform-browser';
import { SignaturePadModule, SignaturePad } from 'angular2-signaturepad';
import { JOBService } from 'src/app/utilities/job.service';
import { skipLast } from 'rxjs/operators';
import { CommonService } from 'src/app/utilities/common.service';
import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { DeviceDetectorService } from 'ngx-device-detector';
import { environment } from 'src/environments/environment';
import { debug } from 'console';
import { EncrDecrService } from 'src/app/utilities/encr-decr.service';
import { NgTinyUrlService } from 'ng-tiny-url';
import { DatePipe } from '@angular/common';

@Component({
  selector: 'app-tp-service-level-agreement',
  templateUrl: './tp-service-level-agreement.component.html',
  styleUrls: ['./tp-service-level-agreement.component.scss'],
  providers: [MessageService, ConfirmationService,DatePipe]
})
export class TPServiceLevelAgreementComponent implements OnInit {
  @ViewChild(SignaturePad, { static: true }) public signaturePad: SignaturePad;
  @Input() companyId: any;
  @Output() updateEvent = new EventEmitter<Boolean>();
  signature: any;
  userDetails: any;
  pdfUrl: any = '';
  displayPdf = false;
  signedBy: any;
  ip: any;
  slaData:any;
  public lat: any;
  public lng: any;
  sendlinkvia: boolean;
  emailSubject: string;
  staff: any;
  spa_access_Url: string;
  emailContent: string;
  env: { production: boolean; imageDocSize: number; audioVideoSize: number; showColorBox: boolean; paginatorValue: number[]; dateFormat: string; DateFormat: string; dateTime: string; dateTimeFormat: string; shortdateformat: string; fileErrorMessage: string; acceptFiletype: string; compFileType: string; documentfiletype: RegExp; imagefiletype: RegExp; commonfiletype: RegExp; base_url: string; apiRoot: string; imageCompressionMinSize: string; calendlyUrl: string; hosting: string; defaultCurrency: string; defaultCurrencyIcon: string; smsApiRoot: string; qbiApiRoot: string; emailRoutes: { common: string; sendQuote: string; }; postCoder: { apiKey: string; }; hostingConfigs: { mapCenterLat: number; mapCenterLng: number; }; siteAssets: { welcomeVideo: string; welcomeName: string; accountsEmail: string; website: string; logo: string; supportWebsite: string; blog: string; privacyPolicyurl: string; termsOfUse: string; playStore: string; appStore: string; socialMedia: { facebook: string; linkedin: string; instagram: string; youtube: string; }; }; };



  constructor(private crudService: CRUDService,
    private messageService: MessageService,
    private JobService: JOBService,
    private CommonService: CommonService,
    private confirmationService: ConfirmationService,
    public sanitizer: DomSanitizer,
    private deviceService: DeviceDetectorService,
    private http: HttpClient, private EncrDecr: EncrDecrService,private tinyUrl: NgTinyUrlService,private datePipe: DatePipe) {
  }

  signaturePadOptions: Object = { // passed through to szimek/signature_pad constructor
    minWidth: 2,
    canvasWidth: 500,
    canvasHeight: 200
  };

  isSigned: Boolean = false;
  loading: Boolean = false;
  isChecked: Boolean = false;
  companyCompliance: any;
  isAccepted: Boolean = false;
  acceptedDatetime: any;
  signaturePath: any;
  deviceInfo: any;
  ipAddress: any;
  staffInfo: any;
  slaId: any;

  async ngOnInit() {
    // this.deviceInfo = this.deviceDetectorService.getDeviceInfo();
    // //console.log(this.deviceInfo);
    this.env = environment;
    this.userDetails = JSON.parse(sessionStorage.getItem('loggedInUser'));
    let data = await this.crudService.getDataByField('CompanyStaffInfo', 'id',this.userDetails.staff_id).toPromise();
    this.staffInfo = data[0];
    this.staff = this.staffInfo
    this.loading = true;
    await this.loadComplianceInfo();
    this.getLocation();
    //this.signaturePad.set('minWidth', 5);
    this.signaturePad.clear();
    this.deviceInfo = this.deviceService.getDeviceInfo();
    this.getIP();
    this.loading = false;

    if(environment.hosting == 'AU')
    {
      this.slaId = '75';
    }
    else if(environment.hosting == 'UK')
    {
      this.slaId = '165';
    }
  }

  getIP() {
    this.crudService.getIpAddress().subscribe((res: any) => {
      this.ipAddress = res.ip;
    });
  }

  getLocation() {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition((position) => {
        if (position) {
          this.lat = position.coords.latitude;
          this.lng = position.coords.longitude;
          //console.log(this.lat);
          //console.log(this.lng);
        }
      });
    }
  }
  /* async loadComplianceInfo(){
    let data: any = await this.crudService.getDataByField('CompanyCompliance', 'company_id', this.companyId).toPromise();
    let slaData = data.filter(val => (val.compliance_id == '75'));
    if (slaData.length > 0) {
      this.companyCompliance = slaData[0];
      this.signaturePath = 'C://uploadImgs//SLA//' + this.companyId + '//' + this.companyId + '.png';
      this.acceptedDatetime = this.companyCompliance.created_at;
      this.isAccepted = true;
    } else {
      this.signaturePath = null;
      this.acceptedDatetime = null;
      this.isAccepted = false;
      this.signaturePad.clear();
    }
  } */
  async loadComplianceInfo() {
    let data: any = await this.crudService.getDataByField('CompanyCompliance', 'company_id', this.companyId).toPromise();
    if(environment.hosting == 'AU')
    {
      this.slaData = data.filter(val => (val.compliance_id == '75' && val.status == '1'));
    }
    else if(environment.hosting == 'UK')
    {
      this.slaData = data.filter(val => (val.compliance_id == '165' && val.status == '1'));
    }
    
    if (this.slaData.length > 0) {
      this.companyCompliance = this.slaData[0];
      this.signaturePath = 'C://uploadImgs//SLA//' + this.companyId + '//' + this.companyId + '.png';
      this.acceptedDatetime = this.companyCompliance.issued_date;
      this.isAccepted = true;
      this.pdfUrl = null;
      this.displayPdf = true;
      this.signedBy = this.companyCompliance.comment;
      /* this.pdfUrl = this.crudService.getAPIRoot() + '/policy_documents/SLA - Template 2021.docx'; */
      this.crudService.fetchComplianceFile(this.companyId, this.companyCompliance.file_path, this.companyCompliance.filename).subscribe({next: (blob) => {
        const objectUrl = URL.createObjectURL(blob);
        this.pdfUrl = this.sanitizer.bypassSecurityTrustResourceUrl(objectUrl);
        /* a.href = objectUrl */
        /* a.download = this.companyCompliance.filename;
        a.click(); */
        /* URL.revokeObjectURL(objectUrl); */
      }, error: (err) => {
        this.crudService.handleFileDownloadError(err);
      }})
    } else {
      this.signaturePath = null;
      this.acceptedDatetime = null;
      this.isAccepted = false;
      this.displayPdf = false;
      this.signaturePad.clear();
      this.crudService.fetchComplianceFile('1', '1/compliance_docs/SLA.pdf', 'SLA.pdf').subscribe({next: (blob) => {
        const objectUrl = URL.createObjectURL(blob);
        this.pdfUrl = this.sanitizer.bypassSecurityTrustResourceUrl(objectUrl);
      }, error: (err) => {
        this.crudService.handleFileDownloadError(err);
      }})
      this.signedBy = this.staffInfo.first_name + ' ' + this.staffInfo.last_name;
      //this.pdfUrl="http://docs.google.com/gview?url="+this.crudService.getAPIRoot() + "/policy_documents/SLA - Template 2021.docx&embedded=true";
      //this.pdfUrl = this.sanitizer.bypassSecurityTrustResourceUrl(this.crudService.getAPIRoot() + '/policy_documents/SLA - Template 2021.docx');
      //  this.pdfUrl = this.crudService.getAPIRoot() + '/policy_documents/SLA - Template 2021.docx';
    }
    this.loading = false;
  }

  drawComplete() {
    this.isSigned = true;
  }


  async saveSLACompliance(filePath, fileName) {
    let expiryDate = new Date(new Date().getTime() + (1000 * 60 * 60 * 24 * 365 * 2));
    let company_compliance = {
      amount: null,
      comment: this.signedBy,
      company_id: this.companyId,
      staff_id: null,
      compliance_id: this.slaId,
      compliance_type_id: null,
      created_at: new Date(),
      created_by: '1',
      expiry_date: expiryDate,
      file_path: filePath,
      filename: fileName,
      id: null,
      issued_by: null,
      issued_date: this.CommonService.getCurrentDateTime(),
      last_modified_by: null,
      last_reminder_date: null,
      last_updated_at: null,
      owner: null,
      policy_no: null,
      reminder_count: null,
      reminder_status: null,
      status: null,
      exists: false,
      expired: false,
      expiry_date_str: null,
      issued_date_str: null,
      s3_url: null,
      sync_status: 0,
    };
    let res: any = await this.CommonService.updateRecord('CompanyCompliance', company_compliance);
    this.crudService.fetchComplianceFile(this.companyId, company_compliance.file_path, company_compliance.filename).subscribe({next: (blob) => {
      const objectUrl = URL.createObjectURL(blob);
      this.pdfUrl = this.sanitizer.bypassSecurityTrustResourceUrl(objectUrl);
      this.displayPdf = true;
    }, error: (err) => {
      this.crudService.handleFileDownloadError('SLA Compliance Not Found');
    }})
  }

  async acceptTerms() {
    this.loading = true;
    console.log("this.signaturePad.",this.signaturePad);
    debugger
    let resp = this.crudService.uploadImage(this.signaturePad.toDataURL(), 'SLA', this.companyId, this.companyId, 'LOCAL');
    let response = JSON.parse(resp)
    let reportId = 'SLA';
    let date = new Date();
    let tempTime = date.getTime();
  //let tempTime = this.datePipe.transform(date,"dd/MM/yyyy hh:mm a");
    let fileDesc = 'SLA';
    let data: any = await this.loadData();
    const fileName = fileDesc + '-' + tempTime + '.pdf';;
    const filePath = 'C:/companyCompliances/' + this.companyId + '/compliance_docs/' + fileName;
    data[0].newReportData.filename = fileName;
    data[0].newReportData.filepath = filePath;
    data[0].newReportData.AuthSign = "C:/uploadImgs/SPA/AuthSign.png";
    let res = await this.crudService.generateSLAReport(this.companyId, data, reportId, tempTime, 1).toPromise();
    await this.saveSLACompliance(this.companyId + '/compliance_docs/' + fileName, fileName);
    await this.loadComplianceInfo();
    this.loading = false;
    this.updateEvent.emit(true);
    this.messageService.add({ severity: 'success', summary: 'Successful', detail: 'SLA Signed and Submitted Successfully', life: 1000 });
    /* this.confirmationService.confirm({
      message: 'Do you want to view the report in separate Window?',
      header: 'Confirm',
      icon: 'pi pi-exclamation-triangle',
      acceptLabel: 'Yes, Open in new Tab',
      rejectLabel: 'No, Download the File',
      accept: () => {
        let blob = new Blob([res], { type: "application/pdf" });
        const objectUrl = URL.createObjectURL(blob);
        window.open(objectUrl);
        this.loading = false;
      },
      reject: () => {
        import('file-saver').then(FileSaver => {
          let blob = new Blob([res], { type: "application/pdf" });
          const a = document.createElement('a')
          const objectUrl = URL.createObjectURL(blob)
          a.href = objectUrl
          a.download = 'SLA (' + this.companyId + ')';
          a.click();
          URL.revokeObjectURL(objectUrl);
          this.loading = false;
        })
      }
    }) */
  }

  async loadData() {
    let companyInfo = await this.crudService.getDataByField('Company', 'id', this.companyId).toPromise();
    companyInfo[0].signed_by = this.staffInfo.first_name + ' ' + this.staffInfo.last_name;
    companyInfo[0].agreement_date = new Date().toLocaleDateString("en-GB");
    companyInfo[0].commencement_date = new Date().toLocaleDateString("en-GB");
    let reportData = {
      TEMPLATE_ID: 'C://Source//Report_Templates//SLA.docx',
      ImageURL: 'C://uploadImgs//SLA//' + this.companyId + '//' + this.companyId + '.png',
      CompanyDetails: companyInfo[0]
    };
    let res: any;
    let data = [
      {
        info: {
          id: '67993bdd-f7d9-48a6-93d9-8026b657041a',
          CompanyID: this.companyId,
          reportid: 'SLA',
        },
        newReportData: reportData,
      }
    ];
    return data;



  }
  fileLoaded() {
    this.loading = false;
    //console.log('file Loaded');
  }

  async sendlink(){
    if(!this.staff){
      this.messageService.add({ severity: 'info', summary: 'Info', detail: 'Main contact details missing, Please add a staff', life: 1000 });
    }
    else{
      this.send_SPA_MAil()
      this.sendOTP()
    }
  }
  async resendlink(){
    if(!this.staff){
      this.messageService.add({ severity: 'info', summary: 'Info', detail: 'Main contact details missing, Please add a staff', life: 1000 });
    }
    else{
      this.send_SPA_MAil()
     // this.sendOTP()
    }
  }
  async send_SPA_MAil(){
    this.sendlinkvia = false  // by default it will send only to mail
    let encrypted = this.EncrDecr.encrypt(  this.staff.company_id +' '+this.staff.id +' '+this.sendlinkvia ,'123456$#@$^@1ERF',);
    this.emailSubject = 'Service Provider Agreement(SPA) Access URL'
    this.spa_access_Url = this.env.base_url+'business/SPA/'+ encrypted 
      await this.tinyUrl.shorten(this.spa_access_Url).subscribe(async res => {
      this.spa_access_Url = res;
      if(res){
        this.emailContent = 'Hi ' + this.staff.first_name?.trim() + ' , <br/><br/>Please sign Service Provider Agreement by clicking the below link<br/> '+ '<body> <a href="'+this.spa_access_Url +'">' + this.spa_access_Url + '</a></body><br/>'
        let temp_type = 'sms-email';
        let smsData  = ''
        // this.templ = {};
        // this.templ.template = [{ email: {} }];
        // this.templ.template[0].email= this.staff.email
        let dataObj = {
          sms: smsData,
          email: this.staff.email,
          type: temp_type,
          selectedTpl: 'd-fa99d348216144cfb8d39f2a67111474',
          sufix: null,
          jobData: null,
          attachments: null,
          account: this.env.hosting,
        }
        await this.storeInSchedule(dataObj);
      }
      console.log( this.spa_access_Url); 
    });
  }
  storeInSchedule(obj) {
    const datobj2 = {
      subject : this. emailSubject,
      body : this.emailContent,
      toEmail : obj.email,
      ref_id:this.staff.id,
      ref_type:'accounts'
      }
      if(this.sendlinkvia == false){
        this.crudService.sendmailSendgrid(datobj2,'commonMail').subscribe((res2: any) => { 
          this.loading = false;
          if(res2.success == 200)
          {
             this.messageService.add({ severity: 'success', summary: 'Successful', detail: 'Email Sent', life: 3000 });
          }
          else
          {
            let message = res2.Data.response.body.errors[0].message;
            this.messageService.add({ severity: 'error', summary: 'Error', detail: message, life: 3000 });
          }

         });
      }
      else if(this.sendlinkvia == true){
        let params = {
           sms: {
            to: this.staff.mobile,
            message: 'Hi '+ this.staff.first_name?.trim() + ', Please sign SPA by clicking ' + this.spa_access_Url ,
           subject: this. emailSubject,
          },   type: 'sms-email',
          selectedTpl: null,
          sufix: null,
        }
        this.crudService.sendTMV2Message(params).subscribe((res2: any) => {
            // this.visible = false;
            this.messageService.add({ severity: 'success', summary: 'Successful', detail: 'SMS Sent ', life: 1000 });
        });
      }
   
  }
  async sendOTP(){
    this.sendlinkvia = false // by default it will send only to mail
    let otp_datas:any  = await this.crudService.getDataByField('StaffOTPTransact', 'staff_id', this.staff.id).toPromise();
    console.log("otp_datas",otp_datas);
    let filtered_otp = otp_datas.filter(val => (val.status == 0 && val.page == 1));
    filtered_otp?.forEach( val => {
      val.status= 2
      let ress: any =  this.CommonService.saveRecord('StaffOTPTransact', val);
    })

  var random_OTP : any = Math.floor(100000 + Math.random() * 900000);
  random_OTP = random_OTP.toString().substring(0, 4);
  random_OTP =  parseInt(random_OTP);
  let otp_details ={
    id : null,
    company_id : this.staff.company_id,
    staff_id : this.staff.id,
    otp :random_OTP,
    status  :0,
    email_to :null,
    phone_to  :null,
    page : 1,  // page = 1 for Spa
    created_at  :  new Date(), 
    created_by : this.staff.id,
  }
  if(this.sendlinkvia == false){
    otp_details.email_to = this.staff.email
  }
  else{
    otp_details.phone_to = this.staff.mobile
  }
  let res: any = await this.CommonService.saveRecord('StaffOTPTransact', otp_details);
  let params = {
    email: {
      to: this.staff.email, cc: '', bcc: null,
      msg: 'Your OTP (One Time Password) for access SPA is ' + '<h4>'+random_OTP+'</h4>'
      + 'Please use this password to access and sign the document. Do not share this password with anyone',
      subject: 'Verification Code To Access SPA ',
    },    type: 'sms-email',
    selectedTpl: null,
    sufix: null,
  }
  if(this.sendlinkvia == false){
    this.sendOTPMail(params);
  }
  else if(this.sendlinkvia == true){
    let params = {
      sms: {
        to: this.staff.mobile,
        message: 'Your OTP (One Time Password) for access SPA is '+ random_OTP
        + '. Please use this password to access and sign the document. Do not share this password with anyone',
      subject:'Verification Code To Access SPA',
      },   type: 'sms-email',
      selectedTpl: null,
      sufix: null,
    }

    this.crudService.sendTMV2Message(params).subscribe((res2: any[]) => {
        // this.visible = false;
        this.messageService.add({ severity: 'success', summary: 'Successful', detail: 'OTP SMS Sent ', life: 1000 });
    });
    }
  }
sendOTPMail(obj) {
const datobj2 = {
  subject :obj.email.subject,
  body : obj.email.msg,
  toEmail : obj.email.to,
  ref_id:this.staff.id,
  ref_type:'accounts'
  }
  this.crudService.sendmailSendgrid(datobj2,'commonMail').subscribe((res2: any) => { 
  this.loading = false;
  if(res2.success == 200)
  {
    // this.visible = false;
    this.messageService.add({ severity: 'success', summary: 'Successful', detail: 'OTP Email Sent', life: 3000 });
  }
  else
  {
    let message = res2.Data.response.body.errors[0].message;
    this.messageService.add({ severity: 'error', summary: 'Error', detail: message, life: 3000 });
  }
 });
}
  async resign(){
    this.loading = true;
    this.companyCompliance.status='2';
    await this.CommonService.updateRecord('companycompliance', this.companyCompliance);
    await this.loadComplianceInfo();
    this.loading = false;
  }
}