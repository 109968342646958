import { Component, Input, OnInit } from '@angular/core';
import { ConfirmationService, MessageService } from 'primeng/api';
import { CRUDService } from 'src/app/utilities/crud.service';
import { Table } from 'primeng/table';

import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-auto-approval',
  templateUrl: './auto-approval.component.html',
  styleUrls: ['./auto-approval.component.css']
})
export class AutoApprovalComponent implements OnInit {

  paginationvalue: any;

  autoApprovalList: any[];
  approvalData: any;

  newApprovalLimit: any;

  submitted: any;
  selectedApproval: any[];
  cols: any[];

  approvalDetailDialog: any = false;
  itemDefnList: any;
  limitNameList: any;
  companyId: any;

  companyList: any;

  loading: Boolean = false;
  isEditAll: boolean = false;
  exportdisplay: Boolean = true;
  displayDelete: Boolean = true;
  searchvalue: any;
  status: any = '1';
  selectedCompany: any;
  rateset: any;

  selectedApprovalLimit: any;
  isActive: Boolean = false;
  JobRequirements: any[];
  jobreqin: any[];
  JobReqList: any[];
  selectedJobRequirements: any;
  selectedApprovalAmt: any;
  disableSavebtn: boolean = false;
  approvalLimitId: any;
  reqList: any[];
  selectedJobreq: any;
  selectedLimitId: any;
  selectedJobReqId: any;
  appovalList: any[];
  @Input() client_id: any;
  isEdit: Boolean = false;
  isAdd: Boolean = true;data: any;
;
  constructor(private CrudService: CRUDService,
    private messageService: MessageService,
    private confirmationService: ConfirmationService,
  ) { }

  async ngOnInit() {
    this.paginationvalue = environment.paginatorValue;
    this.loading = true;
    this.companyId = this.client_id;
    console.log('this.companyId ',this.companyId);
    // console.log("this.idneww", this.client_id);

    this.getData();

    //this.companyList = await this.CrudService.getAll('company').toPromise();

    //this.selectedCompany = this.companyList.filter(val => val.id == this.companyId),
      //console\.log("selectedCompany",this.selectedCompany);

      this.cols = [
        { field: 'limit_name', header: 'Limit Name' },
        { field: 'approval_limit', header: 'Approval Limit' },
        { field: 'job_req', header: 'Job Requirement ' },


      ];

    this.loading = false;

    this.isEdit = false;
    this.isAdd = true;

  }

  getData() {
    this.CrudService.getAllJobRequirements().subscribe(async (JobRequirementsData: any) => {
      await JobRequirementsData.sort((a, b) => a.job_req_name > b.job_req_name && 1 || -1);
      this.jobreqin = JobRequirementsData;
      if (this.jobreqin) {
        this.CrudService.getAll('AutoApprovalMatrix').subscribe(async (data: any) => {
          //  console.log('AutoApprovalMatrix ',data);
          this.appovalList = data;
          this.autoApprovalList = this.appovalList.filter(val => val.company_id == this.companyId);
          // console.log('autoApprovalList ', this.autoApprovalList)

          this.autoApprovalList.forEach(value => {
            // console.log(value);
            this.jobreqin.forEach(element => {
              if (value.requirement_id === element.id) {
                value.requirement_name = element.job_req_name
              }
            });
          });
        });
      }

      //    console.log('this.JobRequirements',this.JobRequirements);
    })
  }


  onChange() {


    this.CrudService.getDataByField('AutoApprovalMatrix', 'company_id', this.companyId).subscribe(async (res2: any[]) => {

      if (res2.length > 0) {

        this.limitNameList = res2;

        // console.log('limitNameList', this.limitNameList)

        // console.log('this.newApprovalLimit.job_req', this.newApprovalLimit.job_req);



        this.selectedJobreq = this.limitNameList.filter(val => val.requirement_id == this.newApprovalLimit.job_req.id);


        // console.log("this.selectedJobreq", this.selectedJobreq);

        if (this.selectedJobreq.length > 0) {

          this.newApprovalLimit.limit_name = this.selectedJobreq[0].limit_name;
          this.newApprovalLimit.approval_limit = this.selectedJobreq[0].approval_limit;

          //this.addToList();

        }
      }

    });

  }

  async addToList() {

    this.disableSavebtn =true;

    if (this.isAdd == true) {

      if (this.newApprovalLimit.job_req !== 'undefined' && typeof this.newApprovalLimit.limit_name !== 'undefined' && this.newApprovalLimit.approval_limit != undefined &&
        this.newApprovalLimit.job_req !== null && typeof this.newApprovalLimit.limit_name !== null && this.newApprovalLimit.approval_limit != null &&
        this.newApprovalLimit?.limit_name !== '' && this.newApprovalLimit.approval_limit != '') {

        // console.log('innn',this.newApprovalLimit)
        this.newApprovalLimit.company_id = this.companyId;
        //  this.newApprovalLimit.id=this.approvalLimitId;

        this.newApprovalLimit.limit_name = this.newApprovalLimit.limit_name;
        this.newApprovalLimit.approval_limit = this.newApprovalLimit.approval_limit;

        //  console.log("newApprovalLimit job req",this.newApprovalLimit.job_req);
        // this.newApprovalLimit. = this.newApprovalLimit.job_req.id;
        if (!this.newApprovalLimit.job_req) {
          this.newApprovalLimit.requirement_id = this.JobRequirements[0].id;
        }
        else {
          for (let i = 0; i < this.newApprovalLimit.job_req.length; i++) {
            this.newApprovalLimit.requirement_id = this.newApprovalLimit.job_req[i].id;
            //  console.log("this.newApprovalLimit.requirement_id",this.newApprovalLimit.requirement_id);

            this.data = await this.CrudService.Create(this.newApprovalLimit, 'AutoApprovalMatrix').toPromise();
           
           } if (this.data.success == '1') {
            this.disableSavebtn =false;
              this.messageService.add({ severity: 'success', summary: 'Successful', detail: 'Auto Approval Limit added', life: 1000 });
              //console\.log('sucessss22')
              this.approvalDetailDialog = false;
              this.getData();
              this.newApprovalLimit = null;
            } else {

              this.disableSavebtn =false;
              this.messageService.add({ severity: 'error', summary: 'Error', detail: 'Auto Approval Limit Not Created', life: 1000 });

              //console\.log('fail22')
            }

          

          // console.log("this.newApprovalLimit22",this.newApprovalLimit);


        }

        // this.saveRecordNew(this.newApprovalLimit);

      }
      else {
        this.messageService.add({ severity: 'error', summary: 'Error', detail: 'Please Enter Mandatory Fields', life: 1000 });
      }

    }

    else if (this.isEdit == true) {

      if (typeof this.newApprovalLimit.limit_name !== 'undefined' && this.newApprovalLimit.approval_limit != undefined
        && typeof this.newApprovalLimit.limit_name !== null && this.newApprovalLimit.approval_limit != null
        && this.newApprovalLimit.approval_limit != '' && this.newApprovalLimit.limit_name !== '') {

        // console.log('innn',this.newApprovalLimit)
        this.newApprovalLimit.company_id = this.companyId;
        //  this.newApprovalLimit.id=this.approvalLimitId;

        this.newApprovalLimit.limit_name = this.newApprovalLimit.limit_name;
        this.newApprovalLimit.approval_limit = this.newApprovalLimit.approval_limit;



        if (this.newApprovalLimit.id) {
          this.newApprovalLimit.requirement_id = this.newApprovalLimit.requirement_id;
          // console.log('this.newApprovalLimit11',this.newApprovalLimit);
          let data: any = await this.CrudService.Update(this.newApprovalLimit, 'AutoApprovalMatrix').toPromise();
          if (data.success == '1') {
            this.disableSavebtn =false;
            this.messageService.add({ severity: 'success', summary: 'Successful', detail: 'Auto Approval Limit updated', life: 1000 });
            //console\.log('sucessss')
            this.approvalDetailDialog = false;
            this.getData();
            
          } else {

            this.disableSavebtn =false;
            this.messageService.add({ severity: 'error', summary: 'Error', detail: 'Auto Approval Limit Not Updated', life: 1000 });

            //console\.log('fail')
          }
        }
        this.newApprovalLimit = null;
        // this.saveRecordNew(this.newApprovalLimit);

      }
      else {
        this.messageService.add({ severity: 'error', summary: 'Error', detail: 'Please Enter Mandatory Fields', life: 1000 });
      }
    }

  }


  openNew() {
    this.isEdit = false;
    this.isAdd = true;

    this.newApprovalLimit = {
      limit_name: null,
      company_id: null,
      approval_limit: null,
      job_req: null,
      id: null
    };
    this.submitted = false;

    this.CrudService.getAllJobRequirements().subscribe(async (JobRequirementsData: any) => {
      await JobRequirementsData.sort((a, b) => a.job_req_name > b.job_req_name && 1 || -1);
      this.jobreqin = JobRequirementsData;
    });
    //  console.log(' this.JobRequirements inn', this.jobreqin);
    debugger;

    this.CrudService.getDataByField('AutoApprovalMatrix', 'company_id', this.companyId).subscribe(async (res2: any[]) => {
      //  console.log('res2', res2)
      if (res2.length > 0) {

        for (var i = this.jobreqin.length - 1; i >= 0; i--) {
          for (var j = 0; j < res2.length; j++) {
            if (this.jobreqin[i] && (this.jobreqin[i].id === res2[j].requirement_id)) {
              this.jobreqin.splice(i, 1);
            }
          }
        }

        this.JobRequirements = this.jobreqin;
        //  console.log(' this.JobRequirements out', this.JobRequirements);

      }

      else {

        this.CrudService.getAllJobRequirements().subscribe(async (JobRequirementsData: any) => {
          await JobRequirementsData.sort((a, b) => a.job_req_name > b.job_req_name && 1 || -1);
          this.JobRequirements = JobRequirementsData;
        });

      }
    });



    this.approvalDetailDialog = true;

  }

  editRecord(data: any) {

    this.isEdit = true;
    this.isAdd = false;
    this.approvalDetailDialog = true;
    //console\.log('edit data11',data)
    this.newApprovalLimit = { ...data };

    this.approvalLimitId = data.id;
    //console\.log('approvalLimitId',this.approvalLimitId)
    if (this.newApprovalLimit.requirement_id != null) {
      this.CrudService.getAllJobRequirements().subscribe((JobRequirementsData: any) => {
        JobRequirementsData.sort((a, b) => a.job_req_name > b.job_req_name && 1 || -1);
        this.JobRequirements = JobRequirementsData;

        //console\.log('this.JobRequirements',this.JobRequirements);

        let reqid = data.requirement_id;

        this.JobRequirements = this.JobRequirements.filter(val => val.id == reqid);


      })


    };

  }


  clear(table: Table) {
    table.clear();
    this.searchvalue = "";
  }


  hideDialog() {
    this.approvalDetailDialog = false;

  }
  deleteApproval(data: any) {
    this.newApprovalLimit = { ...data }
    this.confirmationService.confirm({
      message: 'Are you sure want to delete : ' + this.newApprovalLimit.limit_name + '?',
      header: 'Confirm',
      icon: 'pi pi-exclamation-triangle',
      accept: () => {
        this.confirmationService.close();
        this.CrudService.Delete(this.newApprovalLimit.id, 'AutoApprovalMatrix').subscribe((data: any) => {
          if (data.success == 1) {
            this.messageService.add({ severity: 'success', summary: 'Successful', detail: 'Deleted', life: 1000 });
            this.autoApprovalList = this.autoApprovalList.filter(val => val.id !== this.newApprovalLimit.id);
            this.newApprovalLimit = {};
          }
        });

      },
      reject: () => {
        this.confirmationService.close()
      }
    });
  }


}
