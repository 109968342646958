export const environment = {
  production: false,
  imageDocSize:10000000,
    audioVideoSize:12000000,
  showColorBox:false,
  paginatorValue:[10,25,50],
  dateFormat:'dd-MM-yyyy',
  DateFormat:'dd-mm-yy',
  dateTime :'dd-mm-yy hh:mm a',
  dateTimeFormat :'dd-mm-yy hh:mm a',
  shortdateformat:'shortDate',
  fileErrorMessage:'Uploaded image and or documents must be below 2mb and videos must be below 12mb',
  acceptFiletype:'application/msword, application/vnd.openxmlformats-officedocument.wordprocessingml.document, application/vnd.ms-powerpoint,application/vnd.openxmlformats-officedocument.presentationml.slideshow,application/vnd.openxmlformats-officedocument.presentationml.presentation,application/pdf,image/png, image/jpeg,.csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel',
  compFileType:'.heic,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document,application/vnd.ms-powerpoint,application/vnd.openxmlformats-officedocument.presentationml.slideshow,application/vnd.openxmlformats-officedocument.presentationml.presentation,application/pdf,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet,application/vnd.ms-excel,image/jpg, .doc,.docx,.xml,image/png,image/webp,image/tiff,image/bmp,image/jpeg,.csv,.txt',
  documentfiletype : /(\.jpg|\.jpeg|\.png|\.pdf|\.doc|\.docx|\.webp|\.xml|\.txt|\.csv|\.pot|\.potm|\.potx|\.ppam|\.pps|\.ppsx|\.ppt|\.pptm|\.pptx|\.xls|\.xlm|\.xlsm|\.xlsx|\.xltm|\.xltx)$/i,
  imagefiletype:  /(\.jpg|\.jpeg|\.png|\.heic)$/i,
  commonfiletype :/(\.jpg|\.jpeg|\.png|\.heic\.jpg|\.jpeg|\.png|\.pdf|\.doc|\.docx|\.webp|\.xml|\.txt|\.csv|\.pot|\.potm|\.potx|\.ppam|\.pps|\.ppsx|\.ppt|\.pptm|\.pptx|\.xls|\.xlm|\.xlsm|\.xlsx|\.xltm|\.xltx)$/i,
  base_url:'http://3.26.213.50:82/',
  /* apiRoot : 'http://13.210.69.29:7248/', */
  apiRoot : 'http://platform.treemanager.com:7248/',
  imageCompressionMinSize:'512000',
  calendlyUrl:'https://calendly.com/envirofrontier/tree-manager-2-training',
  hosting: 'AU',
  defaultCurrency: '$', // '£', //£  //$
  defaultCurrencyIcon : 'pi pi-dollar', // 'pi pi-pound', //
  smsApiRoot : 'https://treemanager.com:3002/',
  qbiApiRoot : 'https://quickbi.ngrok.io/QBIMailer/QBI/',
  emailRoutes : {
    common : 'sendgrid',
    sendQuote: 'nodemailer' // nodemailer,sendgrid
  },
  postCoder :
  {
    apiKey : 'PCWZ9-MNMMX-VBA2U-BGCUD'
  },
  hostingConfigs : {
    mapCenterLat : -25.91722,          // AU
    mapCenterLng : 130.23064           // AU
  },
  decisionbutton:{
    refresh:'Refresh',
    save:'Save'
  },
  siteAssets : {
      welcomeVideo : 'https://www.youtube.com/embed/AeoDcbGzpjY?rel=0&loop=1',
      welcomeName : 'Welcome to Work Manager - Powered by Enviro Trees',
      accountsEmail:'admin@agileitexperts.com',
      website : 'https://www.envirofrontier.com.au/',
      logo : 'assets/layout/images/logo-treeManager.png',
      supportWebsite : 'http://envirofrontier.com.au/contact',
      blog : '',
      privacyPolicyurl  : '',
      termsOfUse : '',
      playStore : 'https://play.google.com/store/apps/details?id=io.ionic.TreeManager',
      appStore : 'https://apps.apple.com/au/app/treemanager2/id1500781878?mt=8',
      socialMedia : {
        facebook : 'https://www.facebook.com/EnviroF/',
        linkedin : 'https://www.linkedin.com/company/enviro-frontier/',
        instagram : '',
        youtube : 'https://www.youtube.com/channel/UCaLKRgHOl9TfEufA0BzzLEQ/featured'
      }

  }
};
