import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { CRUDService } from 'src/app/utilities/crud.service';
import { Router, ActivatedRoute } from '@angular/router';
import { MessageService } from 'primeng/api';
import {DialogService, DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { RequestResetComponent } from "../request-reset/request-reset.component";

@Component({
  selector: 'app-password-check',
  templateUrl: './password-check.component.html',
  styleUrls: ['./password-check.component.scss']
})

export class PasswordCheckComponent implements OnInit {
  PasswordCheckForm: FormGroup;
  errorMessage: string;
  successMessage: string;
  username:String;
  password: null;
  companyId: any = null;
  userresponse:any;
  rememberme: any;
  constructor(
    private crudService: CRUDService,
    private fb: FormBuilder,
    public config: DynamicDialogConfig,
    public ref: DynamicDialogRef,
    public dialogService: DialogService ) {
  }


  ngOnInit() {
    this.userresponse = {
      success:null,
      username:null,
      password:null
    }
    this.companyId = this.config.data.companyId;
    console.log(this.companyId);
    this.PasswordCheckForm = this.fb.group(
      {
        username: ['', [Validators.required]],
        password: ['', [Validators.required]],
      }
    );
  }

  Validate(passwordFormGroup: FormGroup) {
    const password = passwordFormGroup.controls.password.value;
    return null;
  }


  async passwordCheck() {
    const username = this.PasswordCheckForm.controls.username.value;
    const password = this.PasswordCheckForm.controls.password.value;
    if (this.PasswordCheckForm.valid) {
      let userDetails: any = await this.crudService.getUserDetailsByCompany(username, password, this.companyId).toPromise();
        if (userDetails.success) {
          if(this.companyId != userDetails.data[0].company_id){
            this.errorMessage = 'Please login with valid Credentials associated with this Business Account.';
            sessionStorage.setItem('loggedInUser', '');
          }else{
            this.ref.close(userDetails.data[0]);
          }
        } else {
          this.errorMessage = 'Invalid Credentials Entered.';
          sessionStorage.setItem('loggedInUser', '');
        }
    }
  }
  forgotPassword(){
    const ref = this.dialogService.open(RequestResetComponent, {
      data: {
      },
      header: 'Forgot Password',
      width: '350px',
      height: 'auto',
      styleClass: 'centerHeader'
    })
  }
}