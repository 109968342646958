import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { CRUDService } from '../utilities/crud.service';
import { DialogService } from 'primeng/dynamicdialog';
import { PasswordCheckComponent } from '../password-check/password-check.component';

@Component({
  selector: 'app-authreditect',
  templateUrl: './authreditect.component.html',
  styleUrls: ['./authreditect.component.css', './authreditect.component.scss']
})
export class AuthreditectComponent implements OnInit {
  loading = false;
  panel = true;
  message = 'Your request is being processed. Please wait..'

  constructor(private route: ActivatedRoute, private crudService: CRUDService, private dialogService: DialogService, private router: Router) { }

  async ngOnInit(): Promise<void> {
    this.loading = true;
    let type = this.route.snapshot.paramMap.get('type');
    let token = this.route.snapshot.paramMap.get('token');
    let data: any = await this.crudService.getDataByField('company', 'token', encodeURIComponent(token)).toPromise();
    const ref = this.dialogService.open(PasswordCheckComponent, {
      data: {
        companyId: data[0].id,
      },
      width: '100%',
      height: '100%'
    })
    this.loading = false;
    ref.onClose.subscribe(async (userDetails: any) => {
      if (userDetails.id) {
        let user = userDetails;
        delete userDetails['password'];
        sessionStorage.setItem('loggedInUser', JSON.stringify(userDetails));
        sessionStorage.setItem('company_id', userDetails.company_id);
        await this.redirect(type);
      }else{
        this.loading = false;
        this.message = 'Account with the provided credentials not found.';
      }
    });
  }

  async redirect(type){
    if(type=='Job'){
      this.router.navigateByUrl('/tp-job/joblist');
    }else if(type=='TaskJobs'){
      this.router.navigateByUrl('/job/operations');
    }
  }
}
