import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ConfirmationService, MessageService } from 'primeng/api';
import { DialogService, DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { CRUDService } from 'src/app/utilities/crud.service';
import { TaskDetailsComponent } from '../task-details/task-details.component';
import { TaskListComponent } from '../task-list/task-list.component';

@Component({
    selector: 'app-ssp-homepage',
    templateUrl: './ssp-homepage.component.html',
    styleUrls: ['./ssp-homepage.component.scss'],
    providers: [DialogService, MessageService, ConfirmationService]
})
export class SSPHomepageComponent implements OnInit {

    widgets: any[] = [];
    allMenus: any;
    activeNews: any;
    companyId: any;
    userDetails: any;
    lineChartData: any;

    lineChartOptions: any;

    constructor(private route: ActivatedRoute,
        private crudService: CRUDService,
        private messageService: MessageService,
        private confirmationService: ConfirmationService,
        private router: Router,
        public ref: DynamicDialogRef,
        public config: DynamicDialogConfig,
        public dialogService: DialogService) {
        this.companyId = sessionStorage.getItem('company_id');
        this.userDetails = JSON.parse(sessionStorage.getItem('loggedInUser'));
    }

    ngOnInit(): void {
        this.crudService.getSSPMenus(this.companyId, this.userDetails.user_group_id).subscribe((data: any[]) => {
            //console.log(data);
            this.allMenus = [];
            data = data.filter(val => val.status !== '2');
            data = data.filter(val => (val.menu_type === '2' || val.menu_type === '3'));
            data = data.filter(val => val.menu_parent_id !== '0');
            data.forEach(tile => {
                tile.randomNumber = Math.floor(Math.random() * 4) + 1;
                this.allMenus.push(tile);
            });

            /* let userItems = data.filter(val => val.menu_type === '1');
            this.widgets.push({label: 'My Issues & Requests', items: userItems});
      
            let execItems = data.filter(val => val.menu_type === '2');
            this.widgets.push({label: 'Pending For Action', items: execItems});
      
            let adminItems = data.filter(val => val.menu_type === '3');
            this.widgets.push({label: 'Manage', items: adminItems}); */
        });


        this.lineChartData = {
            labels: ['January', 'February', 'March', 'April', 'May', 'June', 'July'],
            datasets: [
                {
                    label: 'Reported Issues',
                    data: [3, 7, 2, 17, 15, 13, 19],
                    borderColor: [
                        '#45b0d5'
                    ],
                    borderWidth: 3,
                    fill: false
                },
                {
                    label: 'Within Target',
                    data: [1, 2, 5, 3, 12, 7, 15],
                    borderColor: [
                        '#d08770'
                    ],
                    borderWidth: 3,
                    fill: false
                }
            ]
        };
        this.lineChartOptions = {
            responsive: true,
            maintainAspectRatio: true,
            fontFamily: '\'Candara\', \'Calibri\', \'Courier\', \'serif\'',
            hover: {
                mode: 'index'
            },
            scales: {
                xAxes: [{
                    gridLines: {
                        display: false
                    },
                    ticks: {
                        fontColor: '#9199a9'
                    }
                }],
                yAxes: [{
                    gridLines: {
                        display: false
                    },
                    ticks: {
                        fontColor: '#9199a9'
                    }
                }]
            },
            legend: {
                display: true,
                labels: {
                    fontColor: '#9199a9'
                }
            }
        };
    }

    redirect(item) {
        //console.log(item);
        sessionStorage.setItem('menuData', JSON.stringify(item));
        if (item.router_url != null) {
            this.router.navigateByUrl(item.router_url);
        } else {
            this.router.navigateByUrl('/coming-soon');
        }
    }
}
