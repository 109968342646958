import { Component, Input, OnInit, APP_INITIALIZER } from '@angular/core';
import { CRUDService } from 'src/app/utilities/crud.service';
import { Router, ActivatedRoute } from '@angular/router';
import { MessageService, ConfirmationService } from 'primeng/api';
import { DynamicDialogRef, DialogService } from 'primeng/dynamicdialog';
import { DynamicDialogConfig } from 'primeng/dynamicdialog';
import { FileUploader } from 'ng2-file-upload';
import { CommonService } from 'src/app/utilities/common.service';
import { CompanyNotesComponent } from '../../admin/company-notes/company-notes.component';
import { SendEmailComponent } from '../send-email/send-email.component';
import { PasswordCheckComponent } from '../../password-check/password-check.component';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-tp-company-compliance',
  templateUrl: './tp-company-compliance.component.html',
  styleUrls: ['./tp-company-compliance.component.scss'],
  providers: [DialogService, MessageService, ConfirmationService, CompanyNotesComponent, SendEmailComponent, PasswordCheckComponent]
})

export class TpCompanyComplianceComponent implements OnInit {
  uploader: FileUploader;
  @Input() contractor_id: any;
  @Input() type: any;
  @Input() showStaff: any;
  isPasswordCheck: Boolean = false;
  isValidPassword: Boolean = false;
  companyToken: any;
  suggestions: any[];
  tps: any[] = [];
  compComplianceLevelsObj = [];
  genComplianceLevelsObj = [];
  insComplianceLevelsObj = [];
  facilityComplianceLevelsObj = [];
  selectedCompany: any;
  selectedStaff: any;
  staffList: any[] = [];
  selectedComplianceObj = [];
  companyId = null;
  compliances = [];
  companyComplianceList = [];
  isSubmited = false;
  selectedTab: any = 1;
  staffCompliances: any[] = [];
  userDetails: any;
  userDetailsId: any;
  staffComplianceTypeList: any[] = [];
  toDoComplianceList: any[] = [];
  filesList: any[] = [];
  selectedComplianceLevel: any;
  nextWeekDate: any;
  appProps: any;
  constructor(private crudService: CRUDService,
    private router: Router,
    private route: ActivatedRoute,
    private messageService: MessageService,
    private confirmationService: ConfirmationService,
    public ref: DynamicDialogRef,
    private dialogService: DialogService,
    public config: DynamicDialogConfig,
    private commonservice: CommonService) {
    this.userDetails = JSON.parse(sessionStorage.getItem('loggedInUser'));
    if (typeof this.contractor_id === 'undefined') {
      this.contractor_id = null;
      this.type = 'TP';
    }
    this.companyToken = null;
    if (typeof this.showStaff === 'undefined') {
      this.showStaff = true;
    }
  }

  async ngOnInit() {
    //console.log('contractor : ' + this.contractor_id);
    this.appProps = this.commonservice.getAppProperties();
    this.nextWeekDate = new Date(Date.now() + 7 * 24 * 60 * 60 * 1000);
    let res: any = await this.crudService.getAllTP().toPromise();
    this.tps = res.filter(val => (val.business_name !== null));
    if (this.contractor_id !== null && this.contractor_id !== '') {
      //console.log("IF working");
      let data: any = await this.crudService.getDataByField('company', 'id', this.contractor_id).toPromise();
      if (data.length > 0) {
        this.companyToken = data[0].token;
      }
      this.userDetails = JSON.parse(sessionStorage.getItem('adminUser'));
    } else {
      if (typeof this.route.snapshot.paramMap.get('token') !== 'undefined') {
        let token = this.route.snapshot.paramMap.get('token');
        //console.log("Else working", token);
        if (token != null && token !== '') {
          this.companyToken = encodeURIComponent(token)
          this.isPasswordCheck = true;
          this.isValidPassword = false;
        }
      }
    }

    if (this.companyToken !== '' && this.companyToken !== null) {
      let data: any = await this.crudService.getDataByField('company', 'token', this.companyToken).toPromise();
      if (data.length > 0) {
        let compInfo = data[0];
        //let comp: any = this.tps.filter(val => (val.id === compInfo.id));
        this.selectedCompany = compInfo;
      }
    } else {
      this.selectedCompany = res[0];
    }

    let data2: any = await this.crudService.getStaffByCID(this.selectedCompany.id).toPromise();
    if (data2.length > 0) {
      data2.sort(function (b, a) { return b.id - a.id });
      let staff = data2[0];
      if (this.userDetails !== null) {
        this.userDetailsId = this.userDetails.id;
      } else {
        let users: any = await this.crudService.getDataByField('Users', 'staff_id', staff.id).toPromise();
        if (users.length > 0) {
          let user = users[0];
          this.userDetails = user;
          this.userDetailsId = user.user_id;
          sessionStorage.setItem('adminUser', JSON.stringify(user));
        }
      }
    }
    if (this.isPasswordCheck) {
      const ref = this.dialogService.open(PasswordCheckComponent, {
        data: {
          companyId: this.selectedCompany.id,
        },
        width: '100%',
        height: '100%'
      })
      ref.onClose.subscribe((userDetails: any) => {
        if (userDetails.id) {
          let user = userDetails;
          this.userDetails = userDetails;
          this.userDetailsId = userDetails.id;
          this.initialize();
        }
      });
    } else {
      this.initialize();
    }
  }

  initialize() {
    this.crudService.getAllForStaff().subscribe((data: any[]) => {
      this.staffComplianceTypeList = data;
    });

    this.crudService.getStaffByCID(this.selectedCompany.id).subscribe((data: any[]) => {
      this.staffList = data;
      this.selectedStaff = data[0];
    });

    this.crudService.getAllComplianceLevelHead().subscribe((res: any[]) => {
      this.compComplianceLevelsObj = res;
      this.compComplianceLevelsObj.forEach(compDetail => {
        if (compDetail.comp_type == 10215) {
          this.genComplianceLevelsObj.push(compDetail);
        } else if (compDetail.comp_type == 10216) {
          this.genComplianceLevelsObj.push(compDetail);
        } else if (compDetail.comp_type == 10217) {
          this.facilityComplianceLevelsObj.push(compDetail);
        }
      })
      let compls = this.compComplianceLevelsObj.filter(val => (val.id === '42'));
      this.selectedComplianceLevel = compls[0];
      this.crudService.getAllComplianceDefn().subscribe(async (res: any[]) => {
        //console.log('compliance definitions data from db');
        //console.log(res);
        this.compliances = res;
        await this.getCompliace(42);
      })
      this.crudService.getAllByCompanyId(this.selectedCompany.id).subscribe((res: any[]) => {
        this.companyComplianceList = res;
      });
    });

    this.uploader = new FileUploader({
      disableMultipart: true, // 'DisableMultipart' must be 'true' for formatDataFunction to be called.
      formatDataFunctionIsAsync: true,
      formatDataFunction: async (item) => {
        return new Promise((resolve, reject) => {
          resolve({
            name: item._file.name,
            length: item._file.size,
            contentType: item._file.type,
            date: new Date()
          });
        });
      }
    });
  }
  reloadData() {
    this.crudService.getStaffByCID(this.selectedCompany.id).subscribe((data: any[]) => {
      if (data.length > 0) {

        data.sort((a, b) => a.first_name > b.first_name && 1 || -1);
        this.staffList = data;
        this.selectedStaff = data[0];
        // this.loadStaffCompliance();
      } else {
        this.selectedStaff = null;
        this.staffList = null;
        // this.staffCompliances = null;
      }
    });

    let compls = this.compComplianceLevelsObj.filter(val => (val.id === '42'));
    this.selectedComplianceLevel = compls[0];
    this.crudService.getAllComplianceDefn().subscribe(async (res: any[]) => {
      //console.log('compliance definitions data from db');
      //console.log(res);
      this.compliances = res;
      await this.getCompliace(42);
    })
    this.crudService.getAllByCompanyId(this.selectedCompany.id).subscribe((res: any[]) => {
      this.companyComplianceList = res;
    });
  }

  async onChangeTab(tab) {
    //console.log("selectedtab",tab);
    this.selectedTab = tab;
    if (this.selectedTab === 1) {
      await this.reloadData();
    } else if (this.selectedTab === 2) {
      this.crudService.getStaffByCID(this.selectedCompany.id).subscribe((data: any[]) => {
        if (data.length > 0) {
          data.sort((a, b) => a.first_name > b.first_name && 1 || -1);
          this.staffList = data;
          this.selectedStaff = data[0];
          this.loadStaffCompliance();
        } else {
          this.selectedStaff = null;
          this.staffList = null;
          this.staffCompliances = null;
        }
      });
    } else if (this.selectedTab === 3) {
      this.loadToActCompliances();
    }
  }

  async loadToActCompliances() {
    let toDoComplianceList = [];
    let emptyDate: Date = null;
    /* await this.getCompliace(42);
    this.selectedComplianceObj.forEach(compliance => {
      if (typeof compliance.id !== 'undefined') {
        if (compliance.expiry_date != null && compliance.expiry_date !== '' && compliance.id !== '' && compliance.id !== null) {
          compliance.expiry_date_str = new Date(compliance.expiry_date).toLocaleDateString();
          compliance.expiry_date = new Date(compliance.expiry_date);
          if (new Date(compliance.expiry_date) < new Date()) {
            compliance.expiry_status = 'Expired';
            compliance.status_desc = 'Compliance Expired';
          } else if (new Date(compliance.expiry_date) < new Date(this.nextWeekDate)) {
            compliance.expiry_status = 'Soon';
            compliance.status_desc = 'Compliance Expiring this week.';
          } else if (new Date(compliance.expiry_date) > new Date()) {
            compliance.expiry_status = 'Active';
            compliance.status_desc = 'Active Compliance';
          }
        } else {
          compliance.expiry_date_str = '';
          compliance.expiry_status = 'XXX';
          compliance.status_desc = 'Compliance Expiry Unknown.';
          compliance.expiry_date = emptyDate;
        }
        if (compliance.issued_date !== null && compliance.issued_date !== '') {
          compliance.issued_date = new Date(compliance.issued_date);
        } else {
          compliance.issued_date = emptyDate;
        }
        compliance.compliance_for = this.selectedCompany.business_name;
        if (compliance.expiry_status !== 'Active') {
          toDoComplianceList.push(compliance);
        }
      }
    }); */

    let list: any = await this.crudService.getDataByField('CompanyComplianceView', 'Company_id', this.selectedCompany.id).toPromise();
    //list = list.filter(val => (val.staff_id !== '' || val.staff_id !== null));
    if (list.length > 0) {
      let expiryUnknownlist = list.filter(val => (val.expiry_date === '' || val.expiry_date === null));
      expiryUnknownlist.forEach(compl => {
        if (compl.staff_id !== '' && compl.staff_id !== null) {
          let staff = this.staffList.find(val => (val.id = compl.staff_id));
          if (staff.length > 0) {
            compl.compliance_for = staff.first_name + ' ' + staff.last_name;
          }
          if (compl.compliance_id != null && compl.compliance_id !== '') {
            let complianceDefn: any = this.staffComplianceTypeList.find(val => (compl.compliance_id === val.id));
            if (complianceDefn.length > 0) {
              compl.selectedComplianceType = complianceDefn;
              compl.name = complianceDefn.compliance_name;
            } else {
              compl.selectedComplianceType = null;
            }
          }
        } else {
          compl.staff_id = null;
          compl.compliance_for = this.selectedCompany.business_name;
          if (compl.compliance_id != null && compl.compliance_id !== '') {
            let complianceDefn: any = this.compliances.find(val => (compl.compliance_id === val.id));
            if (complianceDefn.length > 0) {
              compl.selectedComplianceType = complianceDefn;
              compl.name = complianceDefn.compliance_name;
            } else {
              compl.selectedComplianceType = null;
            }
          }
        }

        if (compl.expiry_date !== null && compl.expiry_date !== '') {
          compl.expiry_date = new Date(compl.expiry_date);
        } else {
          compl.expiry_date = emptyDate;
        }
        if (compl.issued_date !== null && compl.issued_date !== '') {
          compl.issued_date = new Date(compl.issued_date);
        } else {
          compl.issued_date = emptyDate;
        }
        compl.expiry_status = 'XXX';
        compl.status_desc = 'Compliance Status Unknown.';

        compl.fileInfo = null;
        compl.changed = false;
        compl.fileChanged = false;
        compl.id = compl.ComCompId;
        compl.submit_status = this.parseUI(compl);
        toDoComplianceList.push(compl);
      });

      let expiredList = list.filter(val => (new Date(val.expiry_date) < new Date(this.nextWeekDate)));
      expiredList.forEach(compl => {
        if (compl.staff_id !== '' && compl.staff_id !== null) {
          let staff = this.staffList.filter(val => (val.id = compl.staff_id));
          if (staff.length > 0) {
            compl.compliance_for = staff[0].first_name + ' ' + staff[0].last_name;
          }
          if (compl.compliance_id != null && compl.compliance_id !== '') {
            let complianceDefn: any = this.staffComplianceTypeList.filter(val => (compl.compliance_id === val.id));
            if (complianceDefn.length > 0) {
              compl.selectedComplianceType = complianceDefn[0];
              compl.name = complianceDefn[0].compliance_name;
            } else {
              compl.selectedComplianceType = null;
            }
          }

        } else {
          compl.staff_id = null;
          compl.compliance_for = this.selectedCompany.business_name;
          if (compl.compliance_id != null && compl.compliance_id !== '') {
            let complianceDefn: any = this.compliances.filter(val => (compl.compliance_id === val.id));
            if (complianceDefn.length > 0) {
              compl.selectedComplianceType = complianceDefn[0];
              compl.name = complianceDefn[0].compliance_name;
            } else {
              compl.selectedComplianceType = null;
            }
          }
        }
        if (compl.expiry_date !== null && compl.expiry_date !== '') {
          compl.expiry_date = new Date(compl.expiry_date);
        } else {
          compl.expiry_date = emptyDate;
        }
        if (compl.issued_date !== null && compl.issued_date !== '') {
          compl.issued_date = new Date(compl.issued_date);
        } else {
          compl.issued_date = emptyDate;
        }

        if (new Date(compl.expiry_date) < new Date()) {
          compl.expiry_status = 'Expired';
          compl.status_desc = 'Compliance Expired';
        } else {
          compl.expiry_status = 'Soon';
          compl.status_desc = 'Compliance Expiring this week.';
        }

        compl.fileInfo = null;
        compl.changed = false;
        compl.fileChanged = false;
        compl.id = compl.ComCompId;
        compl.submit_status = this.parseUI(compl);
        toDoComplianceList.push(compl);
      });
    }
    this.toDoComplianceList = toDoComplianceList;
  }

  async getCompliace(compliaceID) {
    let res: any = await this.crudService.getCompLevelDetailByIdForCompanyCompliance(compliaceID).toPromise();
    this.selectedComplianceObj = [];
    //console.log('level detail data from db');
    //console.log(res);
    res.forEach((compDetail) => {
      var company_compliance: any = {
        id: null,
        company_id: this.companyId,
        compliance_id: compDetail.compliance_id,
        staff_id: null,
        compliance_type_id: parseInt(compDetail.compliance_type_id),
        issued_by: null,
        policy_no: null,
        amount: null,
        issued_date: null,
        expiry_date: null,
        filename: null,
        file_path: null,
        status: null,
        file: null,
        exist: false,
        comment: '',
        styleCSS: 'border:solid red',
        category_id: '',
        changed: false,
        expiry_status: '',
        status_desc: 'Compliance Not Updated'
      }
      this.compliances.forEach((compl) => {
        if (compl.id === compDetail.compliance_id) {
          company_compliance.name = compl.compliance_name;
          company_compliance.category_id = compl.category_id;
          if (compl.category_id === '2') {
            company_compliance.styleCSS = '';
          }
        }
      });


      this.companyComplianceList.forEach((companyCompli) => {
        if (compDetail.compliance_id === companyCompli.compliance_id) {

          let expirydateexists = false;
          let date3 = null;
          let date4 = null;
          if (companyCompli.issued_date !== '' && companyCompli.issued_date !== null) {
            date3 = new Date(companyCompli.issued_date);
          }
          if (companyCompli.expiry_date !== null && companyCompli.expiry_date !== '') {
            date4 = new Date(companyCompli.expiry_date);
            expirydateexists = true;
          }
          company_compliance.id = companyCompli.id;
          company_compliance.issued_by = companyCompli.issued_by;
          company_compliance.policy_no = companyCompli.policy_no;
          company_compliance.amount = companyCompli.amount;
          company_compliance.filename = companyCompli.filename;
          company_compliance.file_path = companyCompli.file_path;
          company_compliance.status = companyCompli.status;
          company_compliance.submit_status = this.parseUI(company_compliance);
          company_compliance.company_id = companyCompli.company_id
          company_compliance.comment = companyCompli.comment;
          company_compliance.issued_date = date3;
          company_compliance.expiry_date = date4;
          company_compliance.created_at = companyCompli.created_at;
          company_compliance.created_by = companyCompli.created_by;
          company_compliance.owner = companyCompli.owner;
          company_compliance.exist = true;


          if (company_compliance.expiry_date !== null && company_compliance.expiry_date !== '') {
            if (new Date(company_compliance.expiry_date) > this.nextWeekDate) {
              company_compliance.expiry_status = 'Active';
              company_compliance.status_desc = 'Active Compliance';
            } else if (new Date(company_compliance.expiry_date) < new Date()) {
              company_compliance.expiry_status = 'Expired';
              company_compliance.status_desc = 'Compliance Expired';

            } else if (new Date(company_compliance.expiry_date) < this.nextWeekDate) {
              company_compliance.expiry_status = 'Soon';
              company_compliance.status_desc = 'Compliance Expiring this week.';
            }
          } else {
            company_compliance.expiry_status = 'XXX';
            company_compliance.status_desc = 'Compliance Expiry Unknown.';
          }
          if (company_compliance.category_id == '2') {
            company_compliance.expiry_status = '';
          }
        }
        /* this.crudService.getDataByField('ComplianceDefn', 'id', compliance.compliance_id).subscribe((complianceDefn: any[]) => { */
        /* }); */
      });
      let complianceDefn: any = this.compliances.filter(val => (company_compliance.compliance_id === val.id));
      if (complianceDefn.length > 0) {
        company_compliance.selectedComplianceType = complianceDefn[0];
        company_compliance.name = complianceDefn[0].compliance_name;
      } else {
        company_compliance.selectedComplianceType = null;
      }
      company_compliance.submit_status = this.parseUI(company_compliance);
      this.selectedComplianceObj.push(company_compliance);
    });
    this.isSubmited = false;
  }

  fromDateToString(date: Date): String {
    date = new Date(date);
    date.setTime(date.getTime() - date.getTimezoneOffset() * 60000);
    let dateAsString = date.toISOString().substr(0, 19);
    return dateAsString;
  }
  parseUI(companyCompli): String {
    let returnValue = 'Pending';
    if (companyCompli.filename) {

      if (companyCompli.filename) {
        if (companyCompli.status == '1') {
          returnValue = 'Submitted';
        } else if (companyCompli.status == '2') {
          returnValue = 'Approved';
        } else if (companyCompli.status == '3') {
          returnValue = 'Rejected';
        } else if (companyCompli.status == '4') {
          returnValue = 'Canceled';
        }
      }
    } else {
      returnValue = 'N/A';
    }
    return returnValue;
  }
  StringToDate(stringDate) {
    let t = stringDate.split("/");
    return new Date(t[2], t[1] - 1, t[0])
  }
  onSelectCompany(company) {
    this.selectedCompany = company;
    this.onChangeTab(1);
  }
  onChangeCompany(company) {
    this.selectedCompany = company.value;
    this.onChangeTab(1);
  }

  onChangeStaff(staff) {
    this.selectedStaff = staff;
    this.loadStaffCompliance();
  }

  async loadStaffCompliance() {
    let staffCompliances: any = [];
    let data: any = await this.crudService.getCompliancesByStaffId(this.selectedStaff.id).toPromise();
    //console.log('data : ' + data.length);
    data.forEach(compliance => {
      compliance.expiry_status = '';
      if (compliance.expiry_date !== null && compliance.expiry_date !== '') {
        compliance.expiry_date_str = new Date(compliance.expiry_date).toLocaleDateString();
        compliance.expiry_date = new Date(compliance.expiry_date);
        if (new Date(compliance.expiry_date) < new Date()) {
          compliance.expiry_status = 'Expired';
          compliance.status_desc = 'Compliance Expired';
        } else if (new Date(compliance.expiry_date) < this.nextWeekDate) {
          compliance.expiry_status = 'Soon';
          compliance.status_desc = 'Compliance Expiring this week.';
        } else if (new Date(compliance.expiry_date) > this.nextWeekDate) {
          compliance.expiry_status = 'Active';
          compliance.status_desc = 'Active Compliance';
        }
      } else {
        compliance.expiry_date_str = '';
        compliance.expiry_status = 'XXX';
        compliance.status_desc = 'Compliance Expiry Unknown.';
      }
      if (compliance.issued_date != null && compliance.issued_date !== '') {
        compliance.issued_date_str = new Date(compliance.issued_date).toLocaleDateString();
        compliance.issued_date = new Date(compliance.issued_date);
      } else {
        compliance.issued_date_str = '';
      }
      if (compliance.compliance_id != null && compliance.compliance_id !== '') {
        let complianceDefn: any = this.staffComplianceTypeList.filter(val => (compliance.compliance_id === val.id));
        /* this.crudService.getDataByField('ComplianceDefn', 'id', compliance.compliance_id).subscribe((complianceDefn: any[]) => { */
        if (complianceDefn.length > 0) {
          compliance.selectedComplianceType = complianceDefn[0];
          compliance.name = complianceDefn[0].compliance_name;
        } else {
          compliance.selectedComplianceType = null;
        }
        /* }); */
      }
      compliance.fileInfo = null;
      compliance.changed = false;
      compliance.fileChanged = false;
      //compliance.submit_status = this.parseUI(compliance.status);
      if (compliance.expiry_date < new Date() && compliance.expiry_date != null && compliance.expiry_date !== '') {
        compliance.expiry_status = 'Expired';
        compliance.status_desc = 'Compliance Expired';
      }
      compliance.submit_status = this.parseUI(compliance);
      staffCompliances.push(compliance);
    });
    this.staffCompliances = staffCompliances;
  }

  addNew() {
    let compliance: any = {};
    compliance.expiry_date = new Date();
    compliance.issued_date = new Date();
    compliance.status = 'New';
    compliance.id = null;
    compliance.selectedComplianceType = null;
    compliance.issued_by = null;
    compliance.policy_no = null;
    compliance.amount = null;
    compliance.filename = null;
    compliance.file_path = null;
    compliance.comment = null;
    compliance.created_at = new Date();
    compliance.created_by = this.userDetailsId;
    compliance.owner = this.userDetailsId;
    compliance.changed = true;
    compliance.fileInfo = null;
    this.staffCompliances = [compliance, ...this.staffCompliances];
  }


  uploadStaffCompliance(event, compliance) {
    for (let file of event) {
      let fileInfo: any = {};
      fileInfo.file = file;
      fileInfo.fileChanged = true;
      fileInfo.filename = file.name;
      compliance.company_id = this.selectedCompany.id;
      compliance.staff_id = this.selectedStaff.id;
      compliance.fileInfo = fileInfo;
      compliance.filename = file.name;
      compliance.fileChanged = true;
      this.messageService.add({ severity: 'success', summary: 'Successful', detail: 'File Uploaded', life: 1000 });
      this.filesList.push(fileInfo);
    }
    event.files = null;
  }


  downloadFile(compliance) {
    //console.log('compliance', compliance);
    import('file-saver').then(FileSaver => {
      if (compliance.staff_id !== null && compliance.staff_id !== '') {
        this.crudService.fetchStaffComplianceFile(compliance.company_id, compliance.staff_id, compliance.file_path).subscribe(blob => {
          const a = document.createElement('a')
          const objectUrl = URL.createObjectURL(blob)
          a.href = objectUrl
          a.download = compliance.filename;
          a.click();
          URL.revokeObjectURL(objectUrl);
        })
      } else {
        this.crudService.fetchComplianceFile(compliance.company_id, compliance.file_path, compliance.filename).subscribe(blob => {
          const a = document.createElement('a')
          const objectUrl = URL.createObjectURL(blob)
          a.href = objectUrl
          a.download = compliance.filename;
          a.click();
          URL.revokeObjectURL(objectUrl);
        })
      }
    });
  }

  uploadCompCompliance(event, compliance) {
    for (let file of event) {
      let fileInfo: any = {};
      fileInfo.file = file;
      fileInfo.fileChanged = true;
      fileInfo.filename = file.name;
      compliance.company_id = this.selectedCompany.id;
      compliance.staff_id = null;
      compliance.fileInfo = fileInfo;
      compliance.filename = file.name;
      compliance.fileChanged = true;
      this.messageService.add({ severity: 'success', summary: 'Successful', detail: 'File Uploaded', life: 1000 });
      this.filesList.push(fileInfo);
      compliance.submit_status = 'Pending for Submittion';
    }
    event.files = null;
  }

  deleteFile(compliance) {
    compliance.file_path = null;
    compliance.filename = null;
    compliance.file = null;
  }

  save() {
    let complianceForSave: any;
    /* this.confirmationService.confirm({
      message: 'Compliance File must be uploaded.',
      header: 'Confirm',
      icon: 'pi pi-exclamation-triangle',
      accept: () => {
       */
    if (this.selectedTab === 1) {
      complianceForSave = this.selectedComplianceObj.filter(val => (val.changed === true));
    } else if (this.selectedTab === 2) {
      complianceForSave = this.staffCompliances.filter(val => (val.changed === true));
    } else if (this.selectedTab === 3) {
      complianceForSave = this.toDoComplianceList.filter(val => (val.changed === true));
    }
    complianceForSave.forEach(compl => {
      this.saveRecord(compl);
    });
    //});
  }

  onChangeComplianceType(compliance) {
    compliance.compliance_id = compliance.selectedComplianceType.id;
    compliance.compliance_type_id = null;
    compliance.changed = true;
  }

  saveRecord(compliance) {
    if (compliance.filename !== '' && compliance.filename !== null) {
      if (compliance.fileChanged) {
        compliance.status = '1';
        compliance.submit_status = this.parseUI(compliance);
        let res = this.crudService.uploadCompliance(compliance.fileInfo.file, compliance.company_id, compliance.staff_id);
        let response = JSON.parse(res)
        if (response != null) {
          if (response.success === 1) {
            compliance.file_path = compliance.company_id + '/compliance_docs/' + compliance.fileInfo.file.name;
            compliance.s3_url = response?.s3Url;
            compliance.sync_status = response?.s3Url ? 1 : 0;
            if (compliance.staff_id !== '' && compliance.staff_id != null) {
              compliance.file_path = compliance.company_id + '/compliance_docs/staff/' + compliance.staff_id + '/' + compliance.fileInfo.file.name;
            } else {
              compliance.file_path = compliance.company_id + '/compliance_docs/' + compliance.fileInfo.file.name;
            }
            compliance.filename = compliance.fileInfo.file.name;
          }
        }
      }
      let error: Boolean = false;
      if (compliance.staff_id === null || compliance.staff_id === '') {
        if (compliance.expiry_date === null || compliance.expiry_date === '') {
          error = true;
        }
        if (typeof compliance.expiry_date === 'undefined') {
          error = true;
        }
      }
      if (compliance.created_at === null || compliance.created_at === '') {
        compliance.created_at = new Date().toDateString();
      }
      if (compliance.created_by === null || compliance.created_by === '') {
        compliance.created_by = this.userDetailsId;
      }
      if (error) {
        this.messageService.add({
          severity: 'error', summary: 'error',
          detail: 'Expiry Date Cant be left Empty for Company Compliances', life: 1000
        });
      } else {
        if (compliance.id) {
          compliance.updated_at = new Date().toDateString();
          compliance.updated_by = this.userDetailsId;
          if (typeof compliance.owner === 'undefined') {
            compliance.owner = this.userDetailsId;
          }

          this.crudService.Update(compliance, 'CompanyCompliance').subscribe((data: any) => {
            if (data.success === 1) {
              compliance.changed = false;
              compliance.fileChanged = false;
              this.messageService.add({ severity: 'success', summary: 'Successful', detail: 'Compliance Updated', life: 1000 });
            } else {
              this.messageService.add({ severity: 'error', summary: 'Error', detail: 'Contact Details Not Updated', life: 1000 });
            }
          });
        } else {
          compliance.compliance_id = compliance.selectedComplianceType.id;
          compliance.compliance_type_id = null;
          compliance.created_at = new Date().toDateString();
          compliance.created_by = this.userDetailsId;
          compliance.comment = null;
          if (typeof compliance.owner === 'undefined') {
            compliance.owner = this.userDetailsId;
          }
          this.crudService.Create(compliance, 'CompanyCompliance').subscribe((data: any) => {
            //console.log(data);
            if (data.success == 1) {
              compliance.changed = false;
              compliance.fileChanged = false;
              compliance.id = data.id;
              this.messageService.add({ severity: 'success', summary: 'Successful', detail: 'Compliance Added', life: 1000 });
            } else {
              this.messageService.add({ severity: 'error', summary: 'Successful', detail: 'Compliance Not Added', life: 1000 });
            }
          });
        }
      }
    } else {
      this.messageService.add({
        severity: 'error', summary: 'error',
        detail: 'File must be uploaded to proceed with saving Compliance Info', life: 1000
      });
    }
  }

  archive(compliance) {
    this.confirmationService.confirm({
      message: 'Are you sure you want to delete selected Compliance?',
      header: 'Confirm',
      icon: 'pi pi-exclamation-triangle',
      accept: () => {
        if (compliance.id) {
          compliance.updated_at = new Date().toDateString();
          compliance.updated_by = this.userDetailsId;
          compliance.status = 3;
          this.crudService.Update(compliance, 'CompanyCompliance').subscribe((data: any) => {
            if (data.success === 1) {
              compliance.changed = false;
              compliance.fileChanged = false;
              this.staffCompliances = this.staffCompliances.filter(val => (val.status !== 3));
              this.messageService.add({ severity: 'success', summary: 'Successful', detail: 'Compliance Updated', life: 1000 });
            } else {
              this.messageService.add({ severity: 'error', summary: 'Error', detail: 'Contact Details Not Updated', life: 1000 });
            }
          });
        } else {
          this.staffCompliances = this.staffCompliances.filter(val => (val.status !== 3));
        }
      }
    });
  }

  async remindCompany() {
    let staffArray: any[] = [];
    let complianceInfo: any = await this.crudService.getDataByField('complianceThresholdViewUpdatedUnFiltered', 'id', this.selectedCompany.id).toPromise();
    complianceInfo.forEach(element => {
      let staff = {
        "staffname": element.staffname, "staffFirstAid": element.staffFirstAid, "IAGStats": element.IAGStats,
        "EFStats": element.EFStats, "OHSStats": element.OHSStats
      };
      staffArray.push(staff);
    });
    let toEmail: any[] = [];
    let ccEmail: any[] = [];
    let bccEmail: any[] = [];
    //toEmail.push(this.selectedCompany.email);
    toEmail = this.getArrayFromString(this.selectedCompany.email);
    /* ccEmail.push('venkat@agileitexperts.com');
    bccEmail.push('shri@agileitexperts.com'); */
    let dynamic_template_data = {
      token: environment.base_url+'#/compliance/' + this.selectedCompany.token,
      compCompliance: [
        {
          business_name: this.selectedCompany.business_name, plstats: this.selectedCompany.plstats, wcstats: this.selectedCompany.wcstats, mvcstats: this.selectedCompany.mvcstats,
          slastats: this.selectedCompany.slastats, firstaidstats: this.selectedCompany.firstaidstats
        }],
      staffCompliance: staffArray,
      business_name: this.selectedCompany.business_name,
      companyId: this.selectedCompany.id,
      type: 'Company Compliance Reminder'

    }

    let companyNotes = {
      pmonly: 2,
      message_flow: 2,
      message_received: 2,
      comm_recipient_subcatg: null,
      phoned: 1,
      sms: 1,
      email: 1,
      callback: 1,
      status: 1,
      app_type: null,
      follow_up_date: null,
      trainRequired: null,
      company_id: this.selectedCompany.id,
      created_by: this.userDetails.id,
      created_at: new Date(),
      message: null,
      sms_no: null,
      emailaddress: null,
      phone: null,
    };
    const ref = this.dialogService.open(SendEmailComponent, {

      data: {
        mail_to_ids: toEmail,
        mail_cc_ids: ccEmail,
        mail_bcc_ids: bccEmail,
        mail_subject: 'Compliance Update Reminder',
        mail_message: 'Please note that the below compliances need your attention as they have either expired or expiring soon. If you have any queries, please contact us at 1300077233. This is a set reminder. To stop notifications, compliance needs to be updated.'
          + ' Please note once they are expired, depending on the client you may be inactive and no longer eligible to receive jobs.',
        template_id: 'd-0312f8c8b7104e67bb9ddd16c7e05056',
        dynamic_template_data: dynamic_template_data,
        type: 'Company',
        notes: companyNotes

      },
      header: 'Expiring Complaince Reminder',
      width: '100%',
      height: '100%'
    });

  }
  /* remindCompany2() {
    //console.log('send Mail');
    this.confirmationService.confirm({
      message: 'Are you sure you want to Remind the Contractor to update their compliance?',
      header: 'Confirm',
      icon: 'pi pi-exclamation-triangle',
      accept: () => {
        this.crudService.complianceReminder(this.selectedCompany.id).subscribe((data: any) => {
          //console.log(data);
        });
      }
    });
  } */

  openNotes() {
    const ref = this.dialogService.open(CompanyNotesComponent, {
      data: {
        id: this.selectedCompany.id
      },
      /* header: 'Site Hazard Form', */
      width: '100%',
      height: '100%'
    })
  }

  search(event) {
    this.suggestions = [];
    this.suggestions = this.tps.filter(val => (val.business_name.toString().toUpperCase().startsWith(event.query.toUpperCase())));
    //console.log(this.suggestions);
  }


  getArrayFromString(input: any) {
    let stringArr: any[] = [];
    let returnArray: any[] = [];
    if (input !== null && input !== '') {
      stringArr = input.split(';');
    }
    stringArr.forEach(element => {
      returnArray.push(element.trim());
    });
    return returnArray;
  }
}