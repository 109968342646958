import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { FormGroup, FormControl, Validators, FormBuilder } from '@angular/forms';
import { IListBoxItem, IItemsMovedEvent } from './dual-list-box';
import { moveItemInArray, CdkDragDrop, transferArrayItem, } from '@angular/cdk/drag-drop';
import { CRUDService } from 'src/app/utilities/crud.service';
import { CommonService } from 'src/app/utilities/common.service';
import { MessageService } from 'primeng/api';
const delay = ms => new Promise(resolve => setTimeout(resolve, ms));
@Component({
  selector: 'app-workflow-child',
  templateUrl: './workflow-child.component.html',
  styleUrls: ['./workflow-child.component.scss']
})
export class WorkflowChildComponent implements OnInit {

  displayhookSuccess: Boolean;
  displayhookFailure: Boolean;
  displayGetDetail: Boolean
  selectedId: any;
  wfId: any;
  hookdata: any;
  loading: Boolean = false;
  selectedhookValue: any;
  // @Input() selects: any;
  hookfieldtype: boolean = false;
  hooksoptions = [
    { id: '1', name: 'Success' },
    { id: '2', name: 'Fail' },
  ];
  skipYn: Boolean = false;
  stepInput: any;
  selectedhook: any;
  choosenItem: any;
  showDetails: Boolean;
  wfDefnData: any;
  selectedWfDefn: any;
  availableHooks: any;
  wfStepsInput: any[] = [];
  hookItems: any;
  @Input() wfCode;
  tempId: any;
  tat_from: any;
  selectedTatFrom: any;
  submitted: Boolean = false;
  checkValue: Boolean = false;
  reminder_hook:any;
  selectedReminderHook:any;
  hookMaster:any;
  // @Input() valueField = 'id';
  valueField = 'id';
  // @Input() textField = 'code';
  textField = 'code';
  // @Input() hookField = 'hooks';
  hookField = 'hooks';
  // @Input() hooktype = 'type'
  hooktype = 'type'
  // @Input() availableText = 'Available items';
  availableText = 'Available items';
  // @Input() selectedText = 'Selected items';
  selectedText = 'Selected items';


  // event called when items are moved between boxes, returns state of both boxes and item moved
  @Output() itemsMoved: EventEmitter<IItemsMovedEvent> = new EventEmitter<IItemsMovedEvent>();

  availableItems: Array<IListBoxItem> = [];
  selectedItems: Array<IListBoxItem> = [];
  hooksItems: any = [];
  listBoxForm: FormGroup;
  availableItemsTemp: Array<IListBoxItem> = [];
  reminder_hookcode:any;

  constructor(public fb: FormBuilder,
    private crudService: CRUDService,
    private messageService: MessageService,
    private commonservice: CommonService,) {
    this.listBoxForm = this.fb.group({
      availableSearchInput: [''],
      selectedSearchInput: [''],
    });
  }
  ngOnInit() {
    this.loadData();
    this.stepInput = {
      skipYn: false,
      tat: null,
      reminder_interval: null,
      tat_from: null,
      reminderHook:null
    }
    this.tat_from = [{ key: '1', description: 'Allocation' }, { key: '2', description: 'Completion of Previous Steps' }];
    this.selectedTatFrom = this.tat_from[1];
    this.selectedReminderHook =  null;
  }

  async loadData() {
    this.loading = true;
    this.hookMaster=await this.crudService.getAllData('hooks').toPromise();
    this.crudService.getAllData('WFSteps').subscribe((data: any[]) => {
      data.forEach(x =>
        x.hooks = [],
      )
      this.selectedItems = data;
      
      

      this.crudService.getAllData('hooks').subscribe((hdata: any[]) => {
      this.hooksItems = hdata;


        this.crudService.getAllData('wfdefn').subscribe((wfdefndata: any[]) => {
          this.wfDefnData = wfdefndata;


          this.crudService.getDataByField('WFDefnSteps', 'wf_code', this.wfCode).subscribe((data: any[]) => {
            let listData = data.sort((a, b) => a.seq.localeCompare(b.seq));
            listData.forEach(async (w, index) => {
              Object.assign(listData[index], { name: w.description });
              this.selectedItems = this.selectedItems.filter(val => (val.code != w.code));
            });
            this.availableItemsTemp = listData;


            this.crudService.getDataByField('WFDefnTriggers', 'wf_code', this.wfCode).subscribe((hookData: any[]) => {
              this.availableHooks = hookData;
              this.availableItemsTemp.forEach(async (w, i1) => {
                w.hooks = [];
                this.availableHooks.forEach(async (h, i2) => {
                  Object.assign(this.availableHooks[i2], { type: h.check_point });
                  if (h.step == w.code) {
                    this.availableItemsTemp[i1].hooks.push(h);
                    const FailList = this.availableItemsTemp[i1].hooks.filter(val => (val.type == 'Fail'));
                    const successList = this.availableItemsTemp[i1].hooks.filter(val => (val.type == 'Success'));
                    Object.assign(this.availableItemsTemp[i1], { fail_length: FailList.length, success_length: successList.length });
                  }
                });
              });
              this.availableItems = this.availableItemsTemp;
            });
          });
        });
      });
    });
    this.loading = false;
  }

  addInputData() {
    debugger
    this.submitted = true;
    if (this.stepInput.tat) {
      if (this.stepInput.reminder_interval < this.stepInput.tat) {
        const index2 = this.availableItems.findIndex(H => H.code == this.selectedId);
        if(index2 == -1){ var index = 0; }else {  var index = index2; }
        // delete this.availableItems[index].id;
        let skipYnInput;
        if (this.skipYn) {
          skipYnInput = '1';
        } else {
          skipYnInput = '0';
        }
        if(this.stepInput.reminder_hook)
        {
          this.reminder_hookcode = this.stepInput.reminder_hook.code;
        }
        else
        {
          this.reminder_hookcode = null;
        }
        Object.assign(this.availableItems[index], {
          skipYn: skipYnInput,
          tat: this.stepInput.tat,
          reminder_interval: this.stepInput.reminder_interval,
          tat_from: this.selectedTatFrom.key,
          reminder_hook:this.reminder_hookcode
        });
        this.displayGetDetail = false;
        this.stepInput = {
          skipYn: null,
          tat: null,
        }
        this.checkValue = false;
      }
      else {
        this.checkValue = true;
      }
    }
    else {

    }
    this.submitted = true;
  }

  drop(event: CdkDragDrop<IListBoxItem[]>) {
    this.submitted = false;
    if (event.previousContainer === event.container) {
      moveItemInArray(
        event.container.data,
        event.previousIndex,
        event.currentIndex
      );
    } else {
      transferArrayItem(
        event.previousContainer.data,
        event.container.data,
        event.previousIndex,
        event.currentIndex
      );
      this.displayGetDetail = true;
      this.stepInput = {
        skipYn: false,
        tat: null,
        reminder_interval: null,
        tat_from: null,
      }
      this.selectedTatFrom = this.tat_from[1];
    }
    // clear marked available items and emit event
    this.itemsMoved.emit({
      available: this.availableItems,
      selected: this.selectedItems,
      movedItems: event.container.data.filter(
        (v, i) => i === event.currentIndex
      ),
      from: 'available',
      to: 'selected',
    });
  }


  openhooks_failure(data: any) {
    this.hookdata = [];
    this.selectedhookValue = '';
    this.selectedhook = '';
    this.displayhookFailure = true;
    this.wfId = data.code;
  }
  openhooks_success(data: any) {
    this.hookdata = [];
    this.selectedhookValue = '';
    this.selectedhook = '';
    this.displayhookSuccess = true;
    this.wfId = data.code;
  }

  openSteps(item) 
  {
    this.submitted = false;
    this.wfId = item.code;
    this.choosenItem = item;
    this.hookItems = item.hooks;
    const tat_fromIndex = this.tat_from.findIndex(H => H.key == item.tat_from);
    if(item.reminder_hook!="")
    {
      const reminder_hookindex =  this.hookMaster.findIndex(H => H.code == item.reminder_hook);
      this.selectedTatFrom = this.tat_from[tat_fromIndex];
      this.selectedReminderHook=this.hookMaster[reminder_hookindex];
    }
    else
    {
      this.selectedReminderHook=null
    }
    this.stepInput = {
      skipYn: this.choosenItem.skipYn,
      tat: this.choosenItem.tat,
      reminder_interval: this.choosenItem.reminder_interval,
      reminder_hook : this.selectedReminderHook
    }
    if (this.choosenItem.skipYn == '1') {
      this.skipYn = true;
    }
    else {
      this.skipYn = false
    }
    this.showDetails = true;
  }

  saveSteps() 
  {
    this.submitted = true;
    if (this.stepInput.tat) {
      if (this.stepInput.reminder_interval < this.stepInput.tat) {
        const index = this.availableItems.findIndex(H => H.code == this.wfId);
        let skipYnInput;
        if (this.skipYn) {
          skipYnInput = '1';
        } else {
          skipYnInput = '0';
        }

        if (this.selectedReminderHook && typeof this.selectedReminderHook === 'object')
        {
          this.reminder_hookcode = this.selectedReminderHook.code;
        }
        else
        {
          this.reminder_hookcode = null;
        }

        this.availableItems[index].skipYn = skipYnInput;
        this.availableItems[index].tat = this.stepInput.tat;
        this.availableItems[index].reminder_interval = this.stepInput.reminder_interval;
        if(this.selectedTatFrom.key)
        {
          this.availableItems[index].tat_from = this.selectedTatFrom.key;
        }
        else
        {
          this.availableItems[index].tat_from = 2;
        }
        this.availableItems[index].reminder_hook=this.reminder_hookcode;
          this.showDetails = false;
        this.checkValue = false;
      }
      else {
        this.checkValue = true;
      }
    }
    else {

    }
  }

  deleteSteps() 
  {
    const index = this.availableItems.findIndex(H => H.code == this.wfId);
    if(this.availableItems[index].hooks.length >= 1)
    {
      this.availableItems[index].hooks.forEach(async (x) => {
        this.crudService.deleteRecordByID('WFDefnTriggers', x.id).subscribe((data: any[]) => { });
      });
    }
    this.crudService.deleteRecordByID('WFDefnSteps', this.availableItems[index].id).subscribe((data: any[]) => { });
    this.availableItems.splice(index, 1);
    this.messageService.add({ severity: 'success', summary: 'Deleted', detail: 'Deleted Successfully', life: 1000 });
    this.showDetails = false;
    this.loadData();
  }

  deleteHook(hooks) {
    const avIndex = this.availableItems.findIndex(H => H.code == this.wfId);
    const hooKindex = this.availableItems[avIndex].hooks.findIndex(H => H.hook == hooks.hook);
    if(this.availableItems[avIndex].hooks[hooKindex].id)
    {
      this.crudService.deleteRecordByID('WFDefnTriggers', this.availableItems[avIndex].hooks[hooKindex].id).subscribe((data: any[]) => { });
    }
    this.availableItems[avIndex].hooks.splice(hooKindex, 1);
    this.messageService.add({ severity: 'success', summary: 'Deleted', detail: 'Deleted Successfully', life: 1000 });
  }

  selectehook() {
    this.hookdata = [];
    this.hookdata = this.selectedhook;
  }

  addhookFailure() {
    if (this.hookdata) {
      const index = this.availableItems.findIndex(H => H.code == this.wfId);
      // Object.assign(this.hookdata, { type: 'Fail' });
      let hookData: any;
      hookData = {
        hook: this.hookdata.code,
        check_point: 'Fail',
        type: 'Fail',
        description: this.hookdata.description,
      }
      this.availableItems[index].hooks.push(hookData);
      const FailList = this.availableItems[index].hooks.filter(val => (val.type == 'Fail'));
      Object.assign(this.availableItems[index], { fail_length: FailList.length });
      this.displayhookFailure = false;
    }
  }


  addhookSuccess() {
    if (this.hookdata) {
      const index = this.availableItems.findIndex(H => H.code == this.wfId);
      // Object.assign(this.hookdata, { type: 'Success' });
      let hookData: any;
      hookData = {
        hook: this.hookdata.code,
        check_point: 'Success',
        type: 'Success',
        description: this.hookdata.description,
      }
      this.availableItems[index].hooks.push(hookData);
      const successList = this.availableItems[index].hooks.filter(val => (val.type == 'Success'));
      Object.assign(this.availableItems[index], { success_length: successList.length });
 
      this.displayhookSuccess = false;
    }
  }
  dragStart(event, item) {
    this.selectedId = item.code;
  }

  dragEnd(event) {

  }

  async addwfsteps()
    {
        for (const [index , w] of this.availableItems.entries()) {
        let inputData:any;
        inputData={
          id:w.id,
          wf_code: this.wfCode,
          code: w.code,
          description:w.name,
          seq:index+1,
          tat:w.tat,
          skip_yn:w.skipYn,
          status:1,
          reminder_interval:w.reminder_interval,
          tat_from:w.tat_from,
          reminder_hook:w.reminder_hook

        }
        
          for (const [index2 , x] of w.hooks.entries()) {
          let hookData:any;
          hookData={
            id:x.id,
            wf_code: this.wfCode,
            hook: x.hook,
            check_point:x.type,
            seq:index2+1,
            step:w.code,
            description:x.description,
            status:1,
          }
         let response: any = await this.commonservice.saveRecord('WFDefnTriggers', hookData);
        }
       let response2: any = await this.commonservice.saveRecord('WFDefnSteps', inputData);
      }
        this.messageService.add({ severity: 'success', summary: 'Successful', detail: 'WorkFlow Steps Added', life: 1000 });
        this.loadData();
      }
}

