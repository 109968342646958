import { Component, OnInit } from '@angular/core';
import { ConfirmationService, MessageService } from 'primeng/api';
import { DialogService } from 'primeng/dynamicdialog';
import { CRUDService } from 'src/app/utilities/crud.service';

@Component({
  selector: 'app-menu-access',
  templateUrl: './menu-access.component.html',
  styleUrls: ['./menu-access.component.scss'],
  providers: [DialogService, MessageService, ConfirmationService]
})
export class MenuAccessComponent implements OnInit {
  companyList:any=[];
  selectedCompany: any;
  menuData: any;
  viewType: any;
  staffRoleList: any[];
  selectedStaffRole: any;
  menus:any
  menuList:any=[];
  menuPermissions:any=[]
  menu:any;
  selectedCreate:any;
  selectedDelete:any;
  selectedExport:any;
  selectedUpdate:any;
  menuDialog:any=false;
  pickListSource:any=[];
  constructor(
    private crudService: CRUDService,
    private messageService: MessageService,
    private confirmationService: ConfirmationService,
    private dialogService: DialogService
  ) {
   
   }

  ngOnInit() {
    this.menu ={
      allow_create:"1",
     allow_delete: "1",
     allow_export: "1",
     allow_update: "1",
     created_at: null,
     created_by: null,
     id: null,
     last_modified_by: null,
     last_updated_at: null,
     menu_id: "76",
     process_id: "108"
    }  
//     this.menus ={
//       created_at: null
// created_by: "1"
// description: "Tasks"
// display_name: "Tasks"
// icon: "pi pi-fw pi-id-card"
// id: "100"
// last_modified_by: null
// last_updated_at: null
// menu_parent_id: "0"
// menu_type: "1"
// para_1: "1"
// para_2: "admin"
// process_id: "108"
// router_url: "/allincidents"

//     }
    this.crudService.getByStaff('ItemDefn').subscribe((data: any[]) => {
      this.staffRoleList = data;
      //console.log(data);
    });
     this.crudService.getAllCompanies().subscribe((data: any[]) => {
      this.companyList = data;
      //console.log(data);
    });
    this.crudService.getDataByField('menus', ' process_id', '108').subscribe((data:any)=>{
     this.menuList=data;
      //console.log(data);
    })
 
  }
  getMenu(){
    this.crudService.getDataByField('menupermissions','process_id','108').subscribe((res:any)=>{
      //console.log(res);
      this.menuList=res;
    })
    this.crudService.getDataByField('menupermissions','menu_id','76').subscribe((res:any)=>{
      //console.log(res);
      this.pickListSource=res;
    })
  }
  
  openNew(){
    this.menuDialog=true;
  this.menu ={
 allow_create:"1",
allow_delete: "1",
allow_export: "1",
allow_update: "1",
created_at: null,
created_by: null,
id: null,
last_modified_by: null,
last_updated_at: null,
menu_id: "76",
process_id: "108"
  }

}
  saveRecord(){
    if(this.selectedCreate==='2'){
      this.menu.allow_create='2'
    }
    if(this.selectedDelete==='2'){
      this.menu.allow_delete='2'
    }
    if(this.selectedUpdate==='2'){
      this.menu.allow_update='2'
    }
    if(this.selectedExport==='2'){
      this.menu.allow_export='2'
    }
    //console.log(this.menu);
    if(this.menu.id==null){
    this.crudService.Create(this.menu,'menupermissions').subscribe((res:any)=>{
      //console.log(res);
    })
  }
  if(this.menu.id!=null){
    this.crudService.Update(this.menu,'menupermissions').subscribe((res:any)=>{
      //console.log(res);
    })
  }

  }
  deleteMenu(menu){
    //console.log(menu);
    this.crudService.Delete(menu.id,"menupermissions").subscribe((res:any)=>{
      //console.log(res);
    })
  }
  editSelectedMenu(menu){
    this.menuDialog=true;
    this.menu=menu;
    //console.log(this.menu);
    this.selectedCreate=this.menu.allow_create;
    this.selectedDelete=this.menu.allow_delete;
    this.selectedExport=this.menu.allow_export;
    this.selectedUpdate=this.menu.allow_update;
  }
  hideDialog(){
    this.menuDialog=false;
  }
}
