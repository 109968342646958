import { Component, OnInit } from '@angular/core';
import { WorkFlowService } from 'src/app/utilities/workflow.service';
import { CommonService } from 'src/app/utilities/common.service';
import { JOBService } from 'src/app/utilities/job.service';
import { MessageService } from 'primeng/api';
import { CRUDService } from 'src/app/utilities/crud.service';
import { DynamicDialogConfig } from 'primeng/dynamicdialog';

@Component({
  selector: 'app-task-update',
  templateUrl: './task-update.component.html',
  styleUrls: ['./task-update.component.scss']
})
export class TaskUpdateComponent implements OnInit {

  eventTypeList: any;
  next_followup_date: any;
  event_remarks: any;
  next_followup: any;
  companyId: any;
  userDetails: any;
  selectedEvent: any;
  allowEditing = true;
  selectedTasks: any;

  constructor(private crudService: CRUDService, public config: DynamicDialogConfig, private commonService: CommonService, private wfService: WorkFlowService, private JobService: JOBService, private messageService: MessageService) { }

  ngOnInit(): void {
    this.companyId = sessionStorage.getItem('company_id');
    this.userDetails = JSON.parse(sessionStorage.getItem('loggedInUser'));
    this.eventTypeList = [{ code: 'communication', name: 'New Communication' },
    { code: 'followup', name: 'Set Reminder/Follow Up' },
    { code: 'notify', name: 'Notify All Allocated Users' },
    { code: 'action', name: 'Action Info' },
    ];
    if (this.config?.data?.selectedTasks != '' && this.config?.data?.selectedTasks != null) {
      this.selectedTasks = this.config?.data?.selectedTasks;
    }
    this.next_followup_date = new Date();
  }

  async saveEventInfo() {
    for (let taskDetails of this.selectedTasks) {
      let data: any = await this.crudService.getDataByField('Task_values', 'task_id', taskDetails.id).toPromise();
      let taskValues = data[0];
      this.save(taskDetails, taskValues);
    }
  }
  save(taskDetails, taskValues) {
    if (this.event_remarks !== null) {
      this.next_followup = this.next_followup_date ? this.next_followup_date : null;
      taskDetails.notes = 'Remarks : ' + this.event_remarks + '. Date :' + new Date().toLocaleString();
      if (this.selectedEvent.code === 'communication') {
        this.wfService.updateAction(taskDetails.id, this.event_remarks, 'Communication', this.companyId, this.userDetails.id);
        this.JobService.updateJobSchedule(taskValues.job_id, taskValues.job_alloc_id, this.event_remarks, this.userDetails);
        this.saveRecord(taskDetails);
      } else if (this.selectedEvent.code === 'followup') {
        if (this.next_followup_date !== null) {
          this.wfService.updateAction(taskDetails.id, this.event_remarks, 'Follow-Up', this.companyId, this.userDetails.id);
          this.JobService.updateJobSchedule(taskValues.job_id, taskValues.job_alloc_id, this.event_remarks, this.userDetails);
          this.saveRecord(taskDetails);
        } else {
          this.messageService.add({ severity: 'error', summary: 'Follow-Up Date cant be left empty for the Selected Event Type', detail: 'Follow-Up Date cant be left empty for the Selected Event Type', life: 4000 });
        }
      } else if (this.selectedEvent.code === 'notify') {
        this.notifyUsers('Task Notification : ' + taskDetails.id + ', Job No : ' + taskValues.job_alloc_id,
          this.event_remarks,
          'Task Updated. Notification to Allocated Users Sent.',
          this.userDetails.id);
        this.wfService.updateAction(taskDetails.id, this.event_remarks, 'Notification', this.companyId, this.userDetails.id);
        this.saveRecord(taskDetails);
      } else if (this.selectedEvent.code === 'action') {
        this.notifyUsers('Task Update : ' + taskDetails.id + ', Job No : ' + taskValues.job_alloc_id,
          this.event_remarks,
          'Task Updated. Notification to Allocated Users Sent.',
          this.userDetails.id);
        this.wfService.updateAction(taskDetails.id, this.event_remarks, 'Action', this.companyId, this.userDetails.id);
        this.saveRecord(taskDetails);
      }
      this.messageService.add({ severity: 'success', summary: 'Event Details Updated', detail: 'Event Details Updated', life: 4000 });
    } else {
      this.messageService.add({ severity: 'error', summary: 'Remarks cant be left Blank', detail: 'Remarks cant be left Blank', life: 4000 });
    }
  }

  async saveRecord(taskDetails) {
    taskDetails.next_followup = this.next_followup ? this.next_followup : null;
    taskDetails.updated_at = new Date();
    let data = await this.commonService.saveRecord('task_head', taskDetails);
  }

  notifyUsers(subject, message, notification, users) {
    /* let allocatees = null;
    let mail_ids = null;
    this.messageService.add({ severity: 'success', summary: subject, detail: notification, life: 4000 });
    if (this.selectedAllocatees.length > 0) {

      this.selectedAllocatees.forEach(allocatee => {
        if (allocatees === null) {
          allocatees = allocatee.first_name + ' ' + allocatee.last_name;
          mail_ids = allocatee.email ? allocatee.email : 'admin@agileitexperts.com';
        } else {
          allocatees = allocatees + ', ' + allocatee.first_name + ' ' + allocatee.last_name;
          mail_ids = mail_ids + ', ' + allocatee.email ? allocatee.email : 'admin@agileitexperts.com'
        }
        let AppNotifications: any = {
          status: '1',
          user_id: allocatee.user_id,
          message,
          read_status: '0',
          title: subject,
          ref_type: 'TASK',
          read_on: null,
          ref_id: taskDetails.id
        }
        AppNotifications.title = 'Task Notification';
        this.wfService.updateRecord('AppNotifications', AppNotifications);
      });
    } else {
      allocatees = 'Unallocated.'
    }
    let data = {
      sms: null,
      email: {
        cc: mail_ids,
        subject: subject,
        bcc: 'envirofrontiersk@gmail.com',
        to: 'venkat@agileitexperts.com, ' + this.reqUser.email,
        attachments: '',
        msg: message,
        message: message
      },
      issueDetails: {
        id: taskDetails.id,
        issue_description: taskDetails.description,
        priority: taskDetails.priority,
        created_by: this.topbardata.reqby,
        AllocatedTo: allocatees
      },
      type: 'email',
      selectedTpl: null,
      sufix: null,
      jobData: null,
      attachments: {},
      replyTo: 'krishnanshridhar@gmail.com',
      account: 'AU'
    };
    this.crudService.sendMail(data).subscribe((response: any) => {
      //console.log(response)
    });
 */  }

}
