import { Component, OnInit, Input, OnDestroy } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { ConfirmationService, MessageService } from 'primeng/api';
import { DynamicDialogRef, DialogService, DynamicDialogConfig } from 'primeng/dynamicdialog';
import { CRUDService } from 'src/app/utilities/crud.service';
import { JOBService } from 'src/app/utilities/job.service';
import { delay } from 'rxjs/operators';
import { take } from 'rxjs/operators';


@Component({
  selector: 'app-send-email',
  templateUrl: './send-email.component.html',
  styleUrls: ['./send-email.component.scss'],
  providers: [DialogService]
})
export class SendEmailComponent implements OnInit {

  @Input() mail_to_ids: any;
  @Input() mail_cc_ids: any;
  @Input() mail_bcc_ids: any;
  @Input() mail_subject: any;
  @Input() mail_message: any;
  @Input() dynamic_template_data: any;
  @Input() template_id: any;
  @Input() type: any;
  @Input() notes: any;

  displayText: any;
  currentUser: any = [];
  attachmentList = [];
  documentList: any;
  dummy: any[] = [];
  replyTo: any;
  emailContent: any;
  emailSubject: any;
  ccEmail: any[] = [];
  bccEmail: any[] = [];
  toEmail: any[] = [];
  userDetails: any;
  companyId: any;
  loading = false;
  mailFieldValue = '';
  ccMailFieldValue = '';
  bccMailFieldValue = '';
  mailOptions: any = '';

  emailsList: any[] = ['krishnanshridhar@gmail.com',
    'tmsupport@agileitexperts.com',
    'shri@envirofrontier.com.au',
    'accounts@envirofrontier.com.au',
    'mark@envirofrontier.com.au',
    'peter@envirofrontier.com.au',
    'teamleader@envirofrontier.com.au',
    'documents@envirofrontier.com.au',
    'support@envirofrontier.com.au',
    'admin@envirofrontier.com.au'];

  constructor(
    private route: ActivatedRoute,
    private crudService: CRUDService,
    private messageService: MessageService,
    private confirmationService: ConfirmationService,
    private router: Router,
    public dialogRef: DynamicDialogRef,
    public config: DynamicDialogConfig,
    public dialogService: DialogService,
    public JobService: JOBService
  ) {
    this.companyId = sessionStorage.getItem('company_id');
    this.userDetails = JSON.parse(sessionStorage.getItem('loggedInUser'));
    this.currentUser = JSON.parse(sessionStorage.getItem('currentUser'));
    //console.log('userinfo', this.userDetails);
  }

  async ngOnInit() {
    this.emailSubject = null;
    this.emailContent = null;

    if (typeof this.config.data === 'undefined') {
      if (typeof this.mail_to_ids !== 'undefined') {
        if (this.mail_to_ids !== null && this.mail_to_ids !== '') {
          this.mail_to_ids.forEach(element => {
            this.toEmail.push({ value: element });
          });
        }
      }
      if (typeof this.mail_cc_ids !== 'undefined') {
        if (this.mail_cc_ids !== null && this.mail_cc_ids !== '') {
          this.mail_cc_ids.forEach(element => {
            this.ccEmail.push({ value: element });
          });
        }
      }
      if (typeof this.mail_bcc_ids !== 'undefined') {
        if (this.mail_bcc_ids !== null && this.mail_bcc_ids !== '') {
          this.mail_bcc_ids.forEach(element => {
            this.bccEmail.push({ value: element });
          });
        }
      }
      if (typeof this.mail_subject !== 'undefined') {
        if (this.emailSubject !== null && this.emailSubject !== '') {
          this.emailSubject = this.mail_subject;
        }
      }
      if (typeof this.mail_message !== 'undefined') {
        if (this.mail_message !== null && this.mail_message !== '') {
          this.emailContent = this.mail_message;
        }
      }
    } else {
      if (typeof this.config.data.mail_to_ids !== 'undefined') {
        if (this.config.data.mail_to_ids !== null && this.config.data.mail_to_ids !== '') {
          this.config.data.mail_to_ids.forEach(element => {
            this.toEmail.push({ value: element });
          });
        }
      }
      if (typeof this.config.data.mail_cc_ids !== 'undefined') {
        if (this.config.data.mail_cc_ids !== null && this.config.data.mail_cc_ids !== '') {
          this.config.data.mail_cc_ids.forEach(element => {
            this.ccEmail.push({ value: element });
          });
        }
      }
      if (typeof this.config.data.mail_bcc_ids !== 'undefined') {
        if (this.config.data.mail_bcc_ids !== null && this.config.data.mail_bcc_ids !== '') {
          this.config.data.mail_bcc_ids.forEach(element => {
            this.bccEmail.push({ value: element });
          });
        }
      }
      if (typeof this.config.data.mail_subject !== 'undefined') {
        if (this.config.data.mail_subject !== null && this.config.data.mail_subject !== '') {
          this.emailSubject = this.config.data.mail_subject;
        }
      }
      if (typeof this.config.data.mail_message !== 'undefined') {
        if (this.config.data.mail_message !== null && this.config.data.mail_message !== '') {
          this.emailContent = this.config.data.mail_message;
        }
      }
      if (typeof this.config.data.dynamic_template_data !== 'undefined') {
        if (this.config.data.dynamic_template_data !== null && this.config.data.dynamic_template_data !== '') {
          this.dynamic_template_data = this.config.data.dynamic_template_data;
        }
      }
      if (typeof this.config.data.template_id !== 'undefined') {
        if (this.config.data.template_id !== null && this.config.data.template_id !== '') {
          this.template_id = this.config.data.template_id;
        }
      }
      if (typeof this.config.data.type !== 'undefined') {
        if (this.config.data.type !== null && this.config.data.type !== '') {
          this.type = this.config.data.type;
        }
      }
      if (typeof this.config.data.notes !== 'undefined') {
        if (this.config.data.notes !== null && this.config.data.notes !== '') {
          this.notes = this.config.data.notes;
        }
      }
    }
  }

  search(event) {
    this.mailOptions = [];
    const option = this.emailsList.filter(val => val.includes(event.query));
    if (option.length > 0) {
      this.mailOptions = option;
    } else {
      if ((event.query.includes(',') || event.query.includes(';') || event.query.includes('.co'))) {
        const value = event.query.replaceAll(',', '').replaceAll(';', '');
        this.mailOptions.push(value);
      }
    }
    this.mailFieldValue = '';
    this.mailFieldValue = event.query;
    // this.dummy=[];
  }

  searchCC(event) {
    this.mailOptions = [];
    const option = this.emailsList.filter(val => val.includes(event.query));
    if (option.length > 0) {
      this.mailOptions = option;
    } else {
      if ((event.query.includes(',') || event.query.includes(';') || event.query.includes('.co'))) {
        const value = event.query.replaceAll(',', '').replaceAll(';', '');
        this.mailOptions.push(value);
      }
    }
    this.ccMailFieldValue = '';
    this.ccMailFieldValue = event.query;
    // this.dummy=[];
  }


  searchBCC(event) {
    this.mailOptions = [];
    const option = this.emailsList.filter(val => val.includes(event.query));
    if (option.length > 0) {
      this.mailOptions = option;
    } else {
      if ((event.query.includes(',') || event.query.includes(';') || event.query.includes('.co'))) {
        const value = event.query.replaceAll(',', '').replaceAll(';', '');
        this.mailOptions.push(value);
      }
    }
    this.bccMailFieldValue = '';
    this.bccMailFieldValue = event.query;
    // this.dummy=[];
  }

  addMailId(mailFieldValue, arrayObject) {
    if (typeof mailFieldValue !== 'undefined') {
      if (mailFieldValue !== null && mailFieldValue !== '') {
        arrayObject.push({ value: mailFieldValue });
      }
    }
    mailFieldValue = null;
  }

  removeToMailId(value) {
    this.toEmail = this.toEmail.filter(val => val.value !== value.value);
  }

  removeCCMailId(value) {
    this.ccEmail = this.ccEmail.filter(val => val.value !== value.value);
  }

  removeBccMailId(value) {
    this.bccEmail = this.bccEmail.filter(val => val.value !== value.value);
  }

  selectId(value, arrayObject) {
    this.addMailId(value, arrayObject);
  }

  async sendEmail() {
    this.loading = true;
    if (this.template_id == null || this.template_id == '') {
      this.template_id = 'd-0312f8c8b7104e67bb9ddd16c7e05056';//de5e0b1974bb4cb2a1f4e9cf6a3aada8';
    }
    const to_email_ids = this.getCSV(this.toEmail);
    const bcc_email_ids = this.getCSV(this.bccEmail);
    const cc_email_ids = this.getCSV(this.ccEmail);

    this.notes.message = this.emailContent;
    this.notes.emailaddress = this.getArrayAsString(to_email_ids);
    this.dynamic_template_data.mail_body = this.emailContent;
    this.dynamic_template_data.subject = this.emailSubject;

    let mailData: any = {
      template_id: this.template_id, // "d-de5e0b1974bb4cb2a1f4e9cf6a3aada8",
      from: { email: 'office@envirofrontier.com.au', name: 'Enviro Frontier' },
      personalizations: {
        to: to_email_ids,
        cc: cc_email_ids,
        bcc: bcc_email_ids,
        dynamic_template_data: this.dynamic_template_data
      },
    }
    this.confirmationService.confirm({
      message: 'Are you sure you want to send the mail?',
      header: 'Confirm',
      icon: 'pi pi-exclamation-triangle',
      accept: () => {
        if (this.dynamic_template_data.type == 'Company Compliance Reminder') {
          this.messageService.add({ severity: 'success', summary: 'Sending', detail: 'Sending Email...' , life: 1000});
          this.crudService.complianceReminder(this.dynamic_template_data.companyId, this.emailSubject, this.emailContent, mailData.personalizations).subscribe((data: any) => {
           // console.log('out111',data)
            if (data.success == 0 && this.type === 'Company') {
              this.messageService.add({ severity: 'success', summary: 'Successful', detail: 'Mail Sent', life: 1000 });
              this.crudService.Create(this.notes, 'CompanyNotes').subscribe((notesRes: any) => {
              });
            }
          });
        } else {
          this.crudService.sendGenericMail(mailData).subscribe((data: any) => {
            //console.log(data);
            this.messageService.add({ severity: 'success', summary: 'Sending', detail: 'Sending Email...' , life: 1000});
           // console.log('out222',data)
            if (data.success === 200) {
              this.messageService.add({ severity: 'success', summary: 'Successful', detail: 'Mail Sent', life: 1000 });
            }
          });
        }
        this.dialogRef.close('Mail Sent');
      }
    });
    this.loading = false;
  }

  getCSV(array) {
    const value: any[] = [];
    if (array.length > 0) {
      array.forEach(element => {
        value.push({ email: element.value });
      })
      return value;
    } else {
      return null;
    }
  }
  getCSV2(array) {
    let value: any = '';
    if (array.length > 0) {
      array.forEach(element => {
        if (value !== null && value !== '') {
          value = value + ',{"email" : "' + element.value + '"}';
        } else {
          value = '[{"email" : "' + element.value + '"}';
        }
      })
      value = value + ']';
      return value;
    } else {
      return '';
    }
  }
  getArrayAsString(array) {
    let value: any = '';
    if (array.length > 0) {
      array.forEach(element => {
        if (value !== null && value !== '') {
          value = value + ', ' + element.value;
        } else {
          value = element.value;
        }
      })
      return value;
    } else {
      return '';
    }
  }
}