import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';

@Component({
  selector: 'dynamic-form-builder',
  templateUrl:'./formbuilder.component.html',
  styleUrls:['./formbuilder.component.scss']
})
export class FormbuilderComponent implements OnInit {
  @Output() notifyParent: EventEmitter<any> = new EventEmitter();
  @Input() fields: any[] = [];
  @Input() type:any;
  showSave:Boolean=true;
  form: FormGroup;
  colvalue=4
  constructor() { }
  ngOnInit() {
    let fieldsCtrls = {};
    if(this.type=="2"){
      this.showSave=false;
    }
    for (let f of this.fields) {
      if (f.type != 'checkbox') {
        fieldsCtrls[f.name] = new FormControl(f.value || '', Validators.required)
      } else {
        let opts = {};
        for (let opt of f.options) {
          opts[opt.code] = new FormControl(opt.description);
        }
        fieldsCtrls[f.name] = new FormGroup(opts)
      }
    }
    this.form = new FormGroup(fieldsCtrls);
  }

  onSubmit(val) {
    this.notifyParent.emit(val);
  }
}

