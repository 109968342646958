import { Component, OnInit, Input } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ConfirmationService, MessageService } from 'primeng/api';
import { CRUDService } from 'src/app/utilities/crud.service';
import { DynamicDialogRef, DynamicDialogConfig, DialogService } from 'primeng/dynamicdialog';
import { JOBService } from 'src/app/utilities/job.service';
import { Job, Client } from './tp-job-operations';
import { TpJobAdminComponent } from '../tp-job-admin/tp-job-admin.component';
import { JobUserComponent } from '../../modules/job-user/job-user.component';
import { SortEvent } from 'primeng/api';
import { JobDetailsApprovalsFlaggingComponent } from '../../modules/job-details-approvals-flagging/job-details-approvals-flagging.component';
import { environment } from 'src/environments/environment';
import { CommonService } from 'src/app/utilities/common.service';
import { JobDetailsApprovalsComponent } from 'src/app/job/job-details-approvals/job-details-approvals.component';

@Component({
  selector: 'app-tp-job-operations',
  templateUrl: './tp-job-operations.component.html',
  styleUrls: ['./tp-job-operations.component.scss'],
  providers: [DialogService, MessageService, ConfirmationService, JOBService]
})
export class TpJobOperationsComponent implements OnInit {
  @Input() reqId: any;
  jobList: Job[] = [];
  isFilterFlag: Boolean = false;
  selectedjob: Job[] = [];
  cols: any[];
  exportColumns: any[];
  searchValue: any;
  query: any = null;
  companyId: any;
  userDetails: any;
  viewType: any;
  overAllStatus: any = [];
  toggleSearch: any = false;
  showMoreColumn: any = false;
  jobStatus: any = [];
  selectedOverAllStatus: any;
  selectedJobStatus: any = [];
  clientsList: any = [];
  selectedClient: any = []
  originalJobList: any = [];
  overallVal: any;
  selectStatus: any;
  client_type_id: any;
  speedDial: Boolean = false;
  loading: Boolean = false;
  showFilterColours: Boolean = false;
  allFlagInfo: any[];
  showFlagsHistory: Boolean = false;
  showColorBox = environment.showColorBox
  selectedColour: any = { name: '', code: 'silver' };
  custCareExpList_324: any;
  is324Exempt: any = false;
  jobFlags = [
    { name: 'red', code: 'firebrick' },
    { name: 'blue', code: 'darkcyan' },
    { name: 'yellow', code: 'darkgoldenrod' },
    { name: 'orange', code: 'darkorange' },
    { name: 'purple', code: 'purple' },
    { name: 'deeppink', code: 'deeppink' },
    { name: '', code: '' }];
  exportdisplay: Boolean = false;

  constructor(

    private route: ActivatedRoute,
    private crudService: CRUDService,
    private messageService: MessageService,
    private confirmationService: ConfirmationService,
    private router: Router,
    public ref: DynamicDialogRef,
    public config: DynamicDialogConfig,
    private dialogService: DialogService,
    private JobService: JOBService,
    private commonservice: CommonService
  ) {
    this.companyId = sessionStorage.getItem('company_id');
    this.userDetails = JSON.parse(sessionStorage.getItem('loggedInUser'));
  }



  openNew() {
    if (this.viewType === 'TM') {
      const ref = this.dialogService.open(TpJobAdminComponent, {
        data: {
          jobId: 0,
        },
        width: '100%',
        height: '100%'
      })
      ref.onClose.subscribe((response: any) => {
        this.loadAllJobsList();
        /* if(response){
          this.jobList = [response, ...this.jobList];
        } */
      });
    } else {
      const ref = this.dialogService.open(JobUserComponent, {
        data: {
          JobId: 0,
        },
        header: 'Job Info',
        width: '100%',
        height: '100%'
      })
      ref.onClose.subscribe((response: any) => {
        this.loadAllJobsList();
        /* if(response){
          this.jobList = [response, ...this.jobList];
        } */
      });
    }
  }

  loadAllJobsList() {
    this.searchNow();
    /* this.searchValue = this.searchValue ? this.searchValue : '';
    this.query = 'select * from joboperationstempview order by JobId desc';
    this.crudService.ExecuteQuery(this.query).subscribe((data: Job[]) => {
      data.forEach(jobInfo => {
        let job: Job = {
          AllocationId: Number(jobInfo.AllocationId),
          ClientSubtype: jobInfo.ClientSubtype,
          FullAddress: jobInfo.FullAddress,
          InvoiceAmount: jobInfo.InvoiceAmount,
          JobId: Number(jobInfo.JobId),
          JobNo: Number(jobInfo.JobNo),
          JobStatus: jobInfo.JobStatus,
          SubStatus: jobInfo.SubStatus,
          TreeProfessional: jobInfo.TreeProfessional,
          accept_status: jobInfo.accept_status,
          job_claim_no: jobInfo.job_claim_no,
          JobDate: jobInfo.JobDate
        }
        if (jobInfo.JobDate) {
          //job.JobDate = stringToDate(jobInfo.JobDate,"dd/MM/yyyy","/");
        } else {
          job.JobDate = null;
        }
        this.jobList.push(job);
      })
    }) */
  }

  loadJobListByClient(id) {
    this.searchValue = this.searchValue ? this.searchValue : '';
    this.query = 'select * from joboperationstempview where companyId = ' + id + ' order by JobId desc';
    this.crudService.ExecuteQuery(this.query).subscribe((data: Job[]) => {
      data.forEach(jobInfo => {
        const job: Job = {
          AllocationId: Number(jobInfo.AllocationId),
          ClientSubtype: jobInfo.ClientSubtype,
          FullAddress: jobInfo.FullAddress,
          InvoiceAmount: jobInfo.InvoiceAmount,
          JobId: Number(jobInfo.JobId),
          JobNo: Number(jobInfo.JobNo),
          JobStatus: jobInfo.JobStatus,
          SubStatus: jobInfo.SubStatus,
          TreeProfessional: jobInfo.TreeProfessional,
          accept_status: jobInfo.accept_status,
          job_claim_no: jobInfo.job_claim_no
        }
        if (jobInfo.JobDate) {
          //job.JobDate = stringToDate(jobInfo.JobDate,"dd/MM/yyyy","/");
        } else {
          job.JobDate = null;
        }
        this.jobList.push(job);
      })
    })
  }

  loadJobListByContractor(id) {
    this.searchValue = this.searchValue ? this.searchValue : '';
    this.query = 'select * from joboperationstempview where contractor_id = ' + id + ' order by JobId desc';
    this.crudService.ExecuteQuery(this.query).subscribe((data: Job[]) => {
      data.forEach(jobInfo => {
        //console.log("mycheck2",data)
        const job: Job = {
          AllocationId: Number(jobInfo.AllocationId),
          ClientSubtype: jobInfo.ClientSubtype,
          FullAddress: jobInfo.FullAddress,
          InvoiceAmount: jobInfo.InvoiceAmount,
          JobId: Number(jobInfo.JobId),
          JobNo: Number(jobInfo.JobNo),
          JobStatus: jobInfo.JobStatus,
          SubStatus: jobInfo.SubStatus,
          TreeProfessional: jobInfo.TreeProfessional,
          accept_status: jobInfo.accept_status,
          job_claim_no: jobInfo.job_claim_no
        }
        if (jobInfo.JobDate) {
          //job.JobDate = stringToDate(jobInfo.JobDate,"dd/MM/yyyy","/");
        } else {
          job.JobDate = null;
        }
        this.jobList.push(job);
      })
    })
  }

  async ngOnInit(): Promise<void> {
    this.overAllStatus =
      [{ id: 1, name: 'Active' },
      { id: 2, name: 'Invoiced' },
      { id: 3, name: 'Completed' },
      { id: 4, name: 'Cancelled' },
      { id: 5, name: 'On Hold' },
      { id: 6, name: 'All' }
      ];
    this.selectedOverAllStatus = { id: 1, name: 'Active' };
    this.jobStatus = [
      { id: 'Send Offer', name: 'Send Offer' },
      { id: 'Await Acceptance', name: 'Await Acceptance' },
      { id: 'Await Cost', name: 'Await Cost' },
      { id: 'Cost Saved', name: 'Cost Saved' },
      { id: 'Approve Quote', name: 'Approve Quote' },
      { id: 'Await Invoice', name: 'Await Invoice' },
      { id: 'Approve Invoice', name: 'Approve Invoice' },
      { id: 'Invoice Submitted', name: 'Invoice Submitted' },
    ]
    this.clientsList = [
      { id: 1, name: 'Insurance' },
      { id: 2, name: 'SES' },
      { id: 6, name: 'DHA' },
      { id: 3, name: 'Facility Management' },
      { id: 4, name: 'New Zealand' },
      { id: 5, name: 'Enviro' },
    ]
    this.viewType = 'TP';
    if (typeof this.reqId == 'undefined') {
      if (typeof this.config.data !== 'undefined') {
        if (typeof this.config.data.reqId !== 'undefined') {
          this.reqId = this.config.data.reqId;
          let jobStatusArr: any = this.config.data.jobStatus.split(',');
          jobStatusArr.forEach(element => {
            this.selectedJobStatus.push({ id: element.trim(), name: element.trim() });
          });
        }
      }
    } else {
      if (this.reqId) {
        this.companyId = this.reqId;
      }
    }

    await this.resetFilters();
    this.cols = [
      { field: 'JobNo', header: 'Job Number' },
      { field: 'JobDate', header: 'Job Entry' },
      { field: 'SubStatus', header: 'Recent Update' },
      { field: 'FullAddress', header: 'Site Address' },
      { field: 'JobStatus', header: 'Job Status' },
    ];
    this.exportColumns = this.cols.map(col => ({ title: col.header, dataKey: col.field }));
    this.exportdisplay = await this.commonservice.exportCheck();
    //console.log(this.exportColumns);
  }

  async resetFilters() {

    this.searchValue = null;
    this.searchNow();
  }

  toggleFn(key) {
    switch (key) {
      case 'search':
        this.toggleSearch = !this.toggleSearch;
        break;
      case 'column':
        this.showMoreColumn = !this.showMoreColumn;
        break;
      default:
        break;
    }
  }

  filterDatatableByflag() {
    if (this.isFilterFlag) {
      this.jobList = this.jobList.filter((val: any) => (val.wf_status === '2' || val.wf_status === 2));
    } else {
      this.jobList = this.originalJobList;
    }

  }
  exportExcel(selectedDepots, table) {
    let JsonData: any[] = [];
    if (this.selectedjob != null && this.selectedjob.length > 0) {
      JsonData = this.selectedjob;
    } else {//console.log(table.filteredValue,'checkthis');
      if (typeof table.filteredValue !== 'undefined') {
        if (table.filteredValue.length !== this.jobList.length && table.filteredValue.length > 0) {
          JsonData = table.filteredValue;
        } else {
          JsonData = this.jobList;
        }
      } else {
        JsonData = this.jobList;
      }
    }


    let reformattedArray = JsonData.map(obj => {

      let rObj = {};
      this.cols.forEach(pair => {
        rObj[pair.header.toString()] = obj[pair.field];
      });
      return rObj;
    })
    //console.log(reformattedArray);

    import('xlsx').then(xlsx => {
      const worksheet = xlsx.utils.json_to_sheet(reformattedArray);
      const workbook = { Sheets: { data: worksheet }, SheetNames: ['data'] };
      const excelBuffer: any = xlsx.write(workbook, { bookType: 'xlsx', type: 'array' });
      this.saveAsExcelFile(excelBuffer, 'Joblist');
    });
  }

  saveAsExcelFile(buffer: any, fileName: string): void {
    import('file-saver').then(FileSaver => {
      const EXCEL_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
      const EXCEL_EXTENSION = '.xlsx';
      const data: Blob = new Blob([buffer], {
        type: EXCEL_TYPE
      });
      FileSaver.saveAs(data, fileName + '_export_' + new Date().getTime() + EXCEL_EXTENSION);
    });
  }
  exportPdf() {
    import('jspdf').then(jsPDF => {
      import('jspdf-autotable').then(x => {
        /* const doc = new jsPDF.default(0,0);
        if(typeof this.selectedDepots === 'undefined'){
            doc.autoTable(this.exportColumns, this.depots);
           }else{
               doc.autoTable(this.exportColumns, this.selectedDepots);
        }
       doc.save('products.pdf'); */
      })
    })
  }
  getJoblsit() {
    this.crudService.getJobs(this.companyId).subscribe((data: any) => {
      this.jobList = data;
      this.jobList = this.jobList.sort((b, a) => a.JobId - b.JobId);
      this.changeDataType()
    })
  }
  changeDataType(){
    this.jobList.forEach(val=>{
      var Jobnumber: number = +val.JobNo;
      // var Jobnumber = Number(val.JobNo);
      val.JobNo = Jobnumber
    }) 
  }
  async viewJobAction(job) {
    this.loading = true;
    if (job.JobStatus === 'Cost Saved' || job.JobStatus === 'Approve Quote' || job.JobStatus === 'Await Cost' || job.JobStatus === 'Quote Rejected' || job.JobStatus === 'Invoice Submitted') {
      const smryData: any = await this.crudService.getJobSummary(job.JobId, job.AllocationId).toPromise();
      const jobAllocationResults = await this.JobService.getJobInfo(smryData[0]);
      const viewType = 'Quote';
      this.loading = false;
      const ref = this.dialogService.open(JobDetailsApprovalsComponent, {
        data: {
          jobId: job.JobId,
          allocId: job.AllocationId,
          jobSummary: jobAllocationResults,
          viewType: viewType
        },
        width: '100%',
        height: '100%'
      })
      ref.onClose.subscribe((response: any) => {
        this.loadAllJobsList();
      });
    } else if (job.JobStatus === 'Approve Invoice' || job.JobStatus === 'Await Invoice' || job.JobStatus === 'Accounts Processed' || job.JobStatus === 'Invoice Processed' || job.JobStatus === 'Accounts Proecessed' || job.JobStatus === 'Job Completed') {
      const smryData: any = await this.crudService.getJobSummary(job.JobId, job.AllocationId).toPromise();
      const jobAllocationResults = await this.JobService.getJobInfo(smryData[0]);
      const viewType = 'Approval';
      this.loading = false;
      const ref = this.dialogService.open(JobDetailsApprovalsComponent, {
        data: {
          jobId: job.JobId,
          allocId: job.AllocationId,
          jobSummary: jobAllocationResults,
          viewType: viewType
        },
        width: '100%',
        height: '100%'
      })
      ref.onClose.subscribe((response: any) => {
        this.loadAllJobsList();
      });

    } else if (job.JobStatus === 'Await Acceptance' || job.JobStatus === 'Send Offer') {
      this.loading = false;
      this.editSelectedRecord(job);
    } else if (job.JobStatus === 'Quote Rejected' || job.JobStatus === 'Send Offer') {
      this.loading = false;
      this.editSelectedRecord(job);
    }
  }
  viewJob(job) {
    this.router.navigate(['tp-job/detail', job.JobId, job.AllocationId])
  }
  editSelectedRecord(job) {
    if (this.viewType === 'TM') {
      const ref = this.dialogService.open(TpJobAdminComponent, {
        data: {
          jobId: job.JobId,
        },
        width: '100%',
        height: '100%'
      })
      ref.onClose.subscribe((response: any) => {
        this.loadAllJobsList();
      });
    } else {
      const ref = this.dialogService.open(JobUserComponent, {
        data: {
          JobId: job.JobId,
        },
        header: 'Job Info',
        width: '100%',
        height: '100%'
      })
    }
  }

  deleteJob(job) {
    //console.log(job.JobId)
    this.confirmationService.confirm({
      message: 'Are you sure you want to delete job : ' + job.JobId + '?',
      header: 'Confirm',
      icon: 'pi pi-exclamation-triangle',
      accept: () => {
        this.jobList = this.jobList.filter((val: any) => val.id !== job.JobId);
        this.crudService.Delete(job.JobId, 'Jobinfo').subscribe(() => {
          this.messageService.add({ severity: 'success', summary: 'Successful', detail: 'Job Deleted', life: 1000 });
        });
        this.loadAllJobsList();
      }
    });
  }
  deleteSelectedjob() {
    this.confirmationService.confirm({
      message: 'Are you sure you want to delete the selected Job?',
      header: 'Confirm',
      icon: 'pi pi-exclamation-triangle',
      accept: () => {
        // tslint:disable-next-line: prefer-for-of
        for (let i = 0; i < this.selectedjob.length; i++) {
          this.crudService.Delete(this.selectedjob[i].JobId, 'Jobinfo').toPromise();
        }
        this.jobList = this.jobList.filter(val => !this.selectedjob.includes(val));
        this.selectedjob = null;
        this.messageService.add({ severity: 'success', summary: 'Successful', detail: 'Jobs Deleted', life: 1000 });
      }
    });
  }

  async fetchSearchRecords() {
    this.searchValue = this.searchValue ? this.searchValue.trim() : '';
    /* this.query = 'select top(600) ROW_NUMBER() OVER( ORDER BY jobid) AS RowNum,* from joboperationstempview where ( ( FullAddress like \'%' + this.searchValue + '%\') or ( TreeProfessional like \'%' + this.searchValue + '%\') or ( ClientSubType like \'%' + this.searchValue + '%\') or (job_claim_no like \'%' + this.searchValue + '%\' ) or (FullAddress like \'%' + this.searchValue + '%\' ) or ( JobNo like \'%' + this.searchValue + '%\') )';

    let data: any = await this.crudService.ExecuteQuery(this.query).toPromise();
    this.jobList = data; */
    this.searchNow();
    this.jobList = this.jobList.sort((b, a) => a.JobId - b.JobId);
  }
  async fetchSearchRecords2() {
    this.loading = true;
    this.searchValue = this.searchValue ? this.searchValue.trim() : '';
    this.query = 'select ROW_NUMBER() OVER( ORDER BY jobid) AS RowNum,* from joboperationstempview where ( ( FullAddress like \'%' + this.searchValue + '%\') or ( TreeProfessional like \'%' + this.searchValue + '%\') or ( ClientSubType like \'%' + this.searchValue + '%\') or (job_claim_no like \'%' + this.searchValue + '%\' ) or (FullAddress like \'%' + this.searchValue + '%\' ) or ( JobNo like \'%' + this.searchValue + '%\') )';
    this.searchValue = this.searchValue ? this.searchValue : '';
    this.custCareExpList_324 = await this.crudService.getByValuesetName('CUSTCARE_EXP_324').toPromise();
    let index = this.custCareExpList_324.findIndex(val => (val.valueset_detail_name === this.userDetails.id));
    if (index > -1) {
      this.is324Exempt = true;
    }
    if (this.is324Exempt) {
      this.query = 'select ROW_NUMBER() OVER( ORDER BY jobid) AS RowNum,* from joboperationstempview where  CompanyId not in (324) and ( ( FullAddress like \'%' + this.searchValue + '%\') or ( TreeProfessional like \'%' + this.searchValue + '%\') or ( ClientSubType like \'%' + this.searchValue + '%\') or (job_claim_no like \'%' + this.searchValue + '%\' ) or (FullAddress like \'%' + this.searchValue + '%\' ) or ( JobNo like \'%' + this.searchValue + '%\') )';;
    }

    const data: any = await this.crudService.ExecuteQuery(this.query).toPromise();
    this.jobList = data;
    //  this.searchNow();
    this.loading = false;
    this.jobList = this.jobList.sort((b, a) => a.JobId - b.JobId);
    this.changeDataType()
  }
  customSort(event: SortEvent) {
    event.data.sort((data1, data2) => {
      const value1 = data1[event.field];
      const value2 = data2[event.field];
      let result = null;

      if (value1 === null && value2 != null)
        result = -1;
      else if (value1 != null && value2 === null)
        result = 1;
      else if (value1 === null && value2 === null)
        result = 0;
      else if (typeof value1 === 'string' && typeof value2 === 'string')
        result = value1.localeCompare(value2);
      else
        result = (value1 < value2) ? -1 : (value1 > value2) ? 1 : 0;

      return (event.order * result);
    });
  }

  stringToDate(_date, _format, _delimiter) {
    const formatCancellederCase = _format.toCancellederCase();
    const formatItems = formatCancellederCase.split(_delimiter);
    const dateItems = _date.split(_delimiter);
    const monthIndex = formatItems.indexOf('mm');
    const dayIndex = formatItems.indexOf('dd');
    const yearIndex = formatItems.indexOf('yyyy');
    let month = parseInt(dateItems[monthIndex]);
    month -= 1;
    const formatedDate = new Date(dateItems[yearIndex], month, dateItems[dayIndex]);
    return formatedDate;
  }

  async initialWhereCondition() {
    /*     let Active = " where  ( contractor_id  = " + this.companyId + " ) and JobStatus not in ('Invoice Processed','Accounts Processed','Offer Rejected,Re-issued','Quote Rejected','Job Completed','Job Cancelled', 'Allocation Cancelled','On Hold')";
        let Invoiced = " where ( contractor_id  =" + this.companyId + " )   and  JobStatus in ('Invoice Processed','Accounts Processed')";
        let Hold = " where ( contractor_id  =" + this.companyId + " )   and JobStatus in ('On Hold') ";
        let Completed = " where ( contractor_id  =" + this.companyId + " ) and JobStatus in ('Job Completed') ";
        let Cancelled = " where ( contractor_id  =" + this.companyId + " ) and JobStatus in ('Job Cancelled', 'Allocation Cancelled','Offer Rejected,Re-issued') ";
        let selectionQuery = " where ";
     */
    let Active = ' where  ( contractor_id  is null or contractor_id not in (276,970,830,1008) ) and JobStatus not in (\'Invoice Processed\',\'Accounts Processed\',\'Offer Rejected,Re-issued\',\'Quote Rejected\',\'Job Completed\',\'Job Cancelled\', \'Allocation Cancelled\',\'On Hold\')';
    let Invoiced = ' where ( contractor_id  is null or contractor_id not in (276,970,830,1008) ) and  JobStatus in (\'Invoice Processed\',\'Accounts Processed\')';
    let Hold = ' where ( contractor_id  is null or contractor_id not in (276,970,830,1008) ) and JobStatus in (\'On Hold\') ';
    let Completed = ' where ( contractor_id  is null or contractor_id not in (276,970,830,1008) ) and JobStatus in (\'Job Completed\') ';
    let Cancelled = ' where ( contractor_id  is null or contractor_id not in (276,970,830,1008) ) and JobStatus in (\'Job Cancelled\', \'Allocation Cancelled\',\'Offer Rejected,Re-issued\') ';
    let ALL = '  where  ( contractor_id  is null or contractor_id not in (276,970,830,1008) ) and JobStatus not in (\'Invoice Processed\',\'Accounts Processed\',\'Offer Rejected,Re-issued\',\'Quote Rejected\',\'Job Completed\',\'Job Cancelled\', \'Allocation Cancelled\',\'On Hold\')'
    let selectionQuery = ' where ';

    let initialWhereCondition = null;

    if (this.selectedOverAllStatus.id === 1) {
      initialWhereCondition = Active;
    } if (this.selectedOverAllStatus.id === 2) {
      initialWhereCondition = Invoiced;
    } if (this.selectedOverAllStatus.id === 3) {
      initialWhereCondition = Completed;
    } if (this.selectedOverAllStatus.id === 4) {
      initialWhereCondition = Cancelled;
    } if (this.selectedOverAllStatus.id === 5) {
      initialWhereCondition = Hold;
    } if (this.selectedOverAllStatus.id === 6) {
      initialWhereCondition = ALL;
    } if (this.selectedOverAllStatus.id === 7) {
      initialWhereCondition = selectionQuery;
    }


    this.custCareExpList_324 = await this.crudService.getByValuesetName('CUSTCARE_EXP_324').toPromise();
    let index = this.custCareExpList_324.findIndex(val => (val.valueset_detail_name === this.userDetails.id));
    if (index > -1) {
      this.is324Exempt = true;
    }
    if (this.is324Exempt) {
      initialWhereCondition = initialWhereCondition + ' and CompanyId not in (324)';
    }
    return initialWhereCondition;
  }

  constructClientQuery() {
    let ClientQuery = null;
    this.selectedClient.forEach(client => {
      if (ClientQuery === null) {
        ClientQuery = ' WPType in (\'' + client.name + '\'';
      } else {
        ClientQuery = ClientQuery + ',\'' + client.name + '\'';
      }
    });
    if (ClientQuery === null) {
      ClientQuery = 'ALL';
    } else {
      ClientQuery = ClientQuery + ')';
    }
    return ClientQuery;
  }

  constructJobStatusQuery() {
    let JobStatusQuery = null;
    this.selectedJobStatus.forEach(status => {
      if (JobStatusQuery === null) {
        JobStatusQuery = ' JobStatus in (\'' + status.id + '\'';
      } else {
        JobStatusQuery = JobStatusQuery + ',\'' + status.id + '\'';
      }
    })
    if (JobStatusQuery === null) {
      JobStatusQuery = 'ALL';
    } else {
      JobStatusQuery = JobStatusQuery + ')';
    }
    return JobStatusQuery;
  }

  async searchNow() {
    this.jobList = [];
    this.loading = true;
    let finalQuery = null;
    const initialWhereConditionVal = await this.initialWhereCondition();
    const clientQuery = this.constructClientQuery();
    const statusQuery = this.constructJobStatusQuery();
    let whereAvail = false;
    finalQuery = initialWhereConditionVal;
    whereAvail = true;

    if (clientQuery === 'ALL') {
    } else {
      if (whereAvail) {
        finalQuery = finalQuery + ' and ' + clientQuery;
      } else {
        if (finalQuery === null) {
          finalQuery = ' where ' + clientQuery;
        } else {
          finalQuery = finalQuery + ' where ' + clientQuery;
        }

        whereAvail = true;
      }
    }
    if (statusQuery === 'ALL') {
    } else {
      if (whereAvail) {
        finalQuery = finalQuery + ' and ' + statusQuery;
      } else {
        if (finalQuery === null) {
          finalQuery = ' where ' + clientQuery;
        } else {
          finalQuery = finalQuery + ' where ' + statusQuery;
        }
        whereAvail = true;
      }
    }
    let appendSearchString = null;
    if ((this.searchValue != null) && (this.searchValue != '')) {
      appendSearchString = ' and ( ( FullAddress like \'%' + this.searchValue + '%\') or (job_claim_no like \'%' + this.searchValue + '%\' ) or  ( JobNo like \'%' + this.searchValue + '%\') )';
      if (finalQuery === null) {
        finalQuery = ' where ' + appendSearchString;
      } else {
        finalQuery = finalQuery + appendSearchString;
      }
    }



    const respobj = { status: null, client: null, query: null };
    respobj.query = finalQuery;
    //respobj.company_id=this.companyId;
    if (finalQuery === null) {
      if (this.client_type_id === '3') {
        const data: any = await this.crudService.ListingPageByCompany(this.companyId).toPromise();
        this.jobList = data;
        this.loading = false;
      } else {
        const data: any = await this.crudService.ListingPageByCompany(this.companyId).toPromise();
        this.jobList = data;
        this.loading = false;
      }
    } else {
      //respobj.query= respobj.query + " and contractor_id=" +this.companyId +"" ;
      //console.log(respobj.query);
      if (this.client_type_id === '3') {
        const data: any = await this.crudService.ListingPageByCompany(this.companyId).toPromise();
        this.jobList = data;
        this.loading = false;
      } else {
        const data: any = await this.crudService.ListingPageByCompany(this.companyId).toPromise();
        this.jobList = data;
        this.loading = false;
      }
    }
    await this.jobList.forEach(element => {
      if (element.JobStatus == 'Await Acceptance' || element.JobStatus == 'Accept Offer') {
        element.jobFlag = true;
      }
      else {
        element.jobFlag = false;
      }
    });
    this.jobList = this.jobList.sort((b, a) => a.JobId - b.JobId);
    this.changeDataType()
    this.originalJobList = this.jobList;
  }

  updateJobFlag(job, colour) {
    job.flag_details = colour.code;
    this.crudService.updateFlag(job.AllocationId, colour.code).toPromise();
  }

  filterByFlag(colour) {
    //  this.resetFilters();
    this.selectedColour = colour;
    if (this.selectedColour.code !== null && this.selectedColour.code !== '') {
      this.jobList = this.originalJobList.filter((val: any) => val.flag_details === colour.code);
    } else {
      this.jobList = this.originalJobList;
    }
    this.jobList = this.jobList.sort((b, a) => a.JobId - b.JobId);
  }

  async flagUnflagJob(job) {
    const data: any = await this.crudService.getDataByField('JobAllocation', 'id', job.AllocationId).toPromise();
    let FlagUnflag = 'FLAG';
    if (job.isFlagged) {
      FlagUnflag = 'UNFLAG';
      job.isFlagged = true;
    } else {
      job.isFlagged = false;
      FlagUnflag = 'FLAG';
    }
    const ref = this.dialogService.open(JobDetailsApprovalsFlaggingComponent, {
      data: {
        jobId: job.JobId,
        allocId: job.AllocationId,
        FlagUnflag: FlagUnflag,
        job_status: job.JobStatus,
        step: 'operations',
        wf_status_id: job.wf_status_id
      },
      width: '75%',
      height: '75%',
      styleClass: ''
    })
    ref.onClose.subscribe((response: any) => {
      if (response.success === 1) {
        if (job.isFlagged) {
          job.job_wf_id = response.jobWfStatus.id;
          this.messageService.add({ severity: 'success', summary: 'Successful', detail: 'Job Allocation Un Flagged', life: 1000 });
        } else {
          this.messageService.add({ severity: 'success', summary: 'Successful', detail: 'Job Allocation Flagged', life: 1000 });
        }
      }
      this.searchNow();
    });
  }

  async acceptJob(job_allocation) {
    debugger
    let jobAllocationData: any = await this.crudService.getDataByField('JobAllocation', 'id', job_allocation.AllocationId).toPromise();
    let jobAllocation = jobAllocationData[0];
    this.confirmationService.confirm({
      message: 'Are you sure you want to Accept.?',
      header: 'Confirm',
      icon: 'pi pi-exclamation-triangle',
      accept: () => {
        // tslint:disable-next-line: prefer-for-of
        jobAllocation.accept_status = 1;
        this.crudService.Update(jobAllocation, 'JobAllocation').subscribe((data: any[]) => {
          this.JobService.recordJobActions(jobAllocation.job_id, jobAllocation.id, 'Await acceptance', 'TP to accept', 'Job Accepted', 2, 'Job Accepted by ' + this.userDetails.user_id + ' on behalf of Contractor', null, null);
          this.storeInScheduleAccept(jobAllocation.job_id, jobAllocation.id);
          this.messageService.add({ severity: 'success', summary: 'Successful', detail: 'Job Allocation Accepted', life: 1000 });
        });
        this.searchNow();
      }
    });
  }
  onEdit() {
    window.scrollTo(0, 0);
  }

  storeInScheduleAccept(job_id, job_alloc_id) {
    debugger
    const store_data_accept = {
      id: null,
      job_id: job_id,
      job_alloc_id: job_alloc_id,
      process_id: 1,
      visit_type: null,
      status: null,
      sched_date: null,
      PMOnly: '2',
      sched_note: 'Job accepted on behalf of TP',
      start_time: null,
      end_time: null,
      created_by: this.userDetails.id,
      created_at: new Date(),
      phoned: '1',
      phone_no: null,
      sms: '1',
      sms_no: null,
      email: '1',
      emailaddress: null,
      callback: '1',
      source: 1,
      message_received: null,
      message_flow: null,
      comm_recipient: null,
      comm_recipient_subcatg: null
    }

    this.crudService.Create(store_data_accept, 'JobSchedule').subscribe((res: any) => {
      if (res.success == 1) {
      
      }
      console.log("Sched",res);
    });
  }

  async rejectJob(job_allocation) {
    let jobAllocationData: any = await this.crudService.getDataByField('JobAllocation', 'id', job_allocation.AllocationId).toPromise();
    let jobAllocation = jobAllocationData[0]; this.confirmationService.confirm({
      message: 'Are you sure you want to Reject?',
      header: 'Confirm',
      icon: 'pi pi-exclamation-triangle',
      accept: () => {
        // tslint:disable-next-line: prefer-for-of
        jobAllocation.allocation_status = 2;
        jobAllocation.accept_message = null;
        jobAllocation.accept_status = null;
        this.crudService.Update(jobAllocation, 'JobAllocation').subscribe((data: any[]) => {
          this.messageService.add({ severity: 'success', summary: 'Successful', detail: 'Job Allocation Rejected', life: 1000 });
        });
        let index = this.jobList.findIndex(element => element.JobId === job_allocation.JobId);
        this.jobList.splice(index, 1);
        this.searchNow();
      }
    });
  }
}