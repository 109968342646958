import { Component, OnInit, Input, OnChanges, ViewChild } from '@angular/core';
import { ActivatedRoute, Router, NavigationStart } from '@angular/router';
import { CRUDService } from 'src/app/utilities/crud.service';
import { ConfirmationService } from 'primeng/api';
import { MessageService } from 'primeng/api';
import { Depot } from './depot';
import { Table } from 'primeng/table';
import { Location } from '@angular/common';
import { ConfirmDialogModule } from 'primeng/confirmdialog';
import { Validators, FormControl, FormGroup, FormBuilder } from '@angular/forms';
import { GooglePlaceDirective } from 'ngx-google-places-autocomplete';
import { CommonService } from 'src/app/utilities/common.service';
import { environment } from 'src/environments/environment';



@Component({
    selector: 'app-depots',
    templateUrl: './depots.component.html',
    styleUrls: ['./depots.component.scss', '../../style.css'],
    providers: [MessageService, ConfirmationService]
})
export class DepotsComponent implements OnInit, OnChanges {
    paginationvalue: any;
    @Input() contractor_id: any;
    companyId: any;
    viewType: any;
    userDetails: any;
    cols: any[];
    dataValue: any[];
    viewAllDepotData: any;
    depotCreateList: any;
    exportColumns: any[];
    countryList: any;
    stateList: any[];
    title = 'rou';
    optionsMap: any;
    public saveView = true;
    public editView = false;
    public listView = false;
    public createView = false;
    depotDialog: boolean;
    depots: Depot[];
    depot: Depot;
    selectedDepots: Depot[];
    submitted: boolean;
    companyList: any = [];
    invalid: boolean = false;
    statuses: any[];
    selectedState: any;
    selectedCountry: any;
    selectedCompany: any;
    formattedaddress = '';
    places: any = [];
    searchvalue: any;
    companyData: any;
    depotForm: FormGroup;

    filteredPlaces: any[] = [];
    inputAddress:any = [];

    options = {
        types: [],
        componentRestrictions: {
            country: ['AU']
        }
    }
    componentForm = {
        street_number: 'short_name',
        route: 'long_name',
        locality: 'long_name',
        sublocality_level_1: 'long_name',
        administrative_area_level_1: 'short_name',
        country: 'long_name',
        postal_code: 'short_name'
    };

    isActive: boolean;
    exportdisplay: Boolean = false;
    // tslint:disable-next-line: max-line-length
    customLabel = { restaurant: { label: 'R' }, bar: { label: 'B' } };
    map: google.maps.Map;
    iconBase = 'https://maps.google.com/mapfiles/kml/shapes/';
    icons: any;
    loading = false;
    overlays: any[] = [];
    setMap(event) {
        this.map = event.map;
    }
    Status = [
        { label: 'Active', value: "Active" },
        { label: 'Inactive', value: "Inactive" },
    ]
    // tslint:disable-next-line: max-line-length
    constructor(private fb: FormBuilder, private crudService: CRUDService, private messageService: MessageService, private confirmationService: ConfirmationService, private router: Router, private route: ActivatedRoute, private location: Location, private commonservice: CommonService) {
    }
    env: any;

    async ngOnInit(): Promise<void> {
        this.env = environment;
        this.options = {
            types: [],
            componentRestrictions: {
                country: [this.env.hosting]
            }
        }
        this.paginationvalue = environment.paginatorValue;
        this.viewType = 'TP';
        this.userDetails = sessionStorage.getItem('loggedInUser');
        this.companyId = sessionStorage.getItem('company_id');
        this.depots = [];

        if (typeof this.contractor_id !== 'undefined') {
            this.companyId = this.contractor_id;
            this.viewType = 'TP';
            this.crudService.getByDepotCID(this.companyId).subscribe((data: any[]) => {
                this.depots = data;
                this.depots = this.depots.filter(val => val.deleted != '1');
                console.log("this.depot", this.depots);
                
                if (this.depots?.length > 0) {
                    localStorage.setItem('deportStrength', '10');
                }
            });
        } else {
            if (this.viewType === 'TM') {
                this.crudService.getAllCompanies().subscribe((data: any[]) => {
                    this.companyList = data;
                    this.depots = this.depots.filter(val => val.deleted != '1');
                    //console.log(this.companyList);
                });
                this.crudService.getAllJobDepotDetails().subscribe((depotData: any[]) => {
                    this.depots = depotData;
                    this.depots = this.depots.filter(val => val.deleted != '1');
                });
            } else if (this.viewType === 'TL') {
                this.crudService.getByDepotCID(this.companyId).subscribe((data: any[]) => {
                    this.depots = data;
                    this.depots = this.depots.filter(val => val.deleted != '1');
                });
            } else if (this.viewType === 'TP') {
                this.crudService.getByDepotCID(this.companyId).subscribe((data: any[]) => {
                    this.depots = data;
                    this.depots = this.depots.filter(val => val.deleted != '1');
                });
            }


        }
        this.companyData = await this.crudService.getDataByField('Company', 'id', this.companyId).toPromise();
        this.getAllCountries();
        this.getAllStates();
        this.componentForm = {
            street_number: 'short_name',
            route: 'long_name',
            locality: 'long_name',
            sublocality_level_1: 'long_name',
            administrative_area_level_1: 'short_name',
            country: 'long_name',
            postal_code: 'short_name'
        };
        this.options = {
            types: [],
            componentRestrictions: {
                country: [this.env.hosting]
            }
        }
        this.cols = [
            { field: 'depot_name', header: 'Name' },
            { field: 'depot_desc', header: 'Description' },
            { field: 'CompanyName', header: 'Company Name' },
            { field: 'StatusDescription', header: 'Status' },
        ];
        this.exportColumns = this.cols.map(col => ({ title: col.header, dataKey: col.field }));
        if (this.env.hosting == 'AU') {
            this.depotForm = new FormGroup({
                depot_name: new FormControl('', Validators.required),
                depot_desc: new FormControl('', Validators.required),
                contact: new FormControl('', Validators.required),
                email: new FormControl('', Validators.required),
                bestContact: new FormControl('', Validators.required),
                altContact: new FormControl(''),
                street_address: new FormControl('', Validators.required),
                suburb: new FormControl('', Validators.required),
                postcode: new FormControl('', Validators.required),
                state: new FormControl('', Validators.required),
                country: new FormControl('', Validators.required),
                location_lat: new FormControl('', Validators.required),
                location_lng: new FormControl('', Validators.required),
            });
        }
        else
        {
            this.depotForm = new FormGroup({
                depot_name: new FormControl('', Validators.required),
                depot_desc: new FormControl('', Validators.required),
                contact: new FormControl('', Validators.required),
                email: new FormControl('', Validators.required),
                bestContact: new FormControl('', Validators.required),
                altContact: new FormControl(''),
                street_address: new FormControl('', Validators.required),
                suburb: new FormControl('', Validators.required),
                postcode: new FormControl('', Validators.required),
                location_lat: new FormControl('', Validators.required),
                location_lng: new FormControl('', Validators.required),
            });
        }
        this.loadMap();
        this.exportdisplay = await this.commonservice.exportCheck();
    }

    async loadMap() {
        this.overlays = [];
        this.optionsMap = {
            center: { lat: environment.hostingConfigs.mapCenterLat, lng: environment.hostingConfigs.mapCenterLng },
            zoom: 4
        };

        this.icons = {
            parking: { icon: this.iconBase + 'parking_lot_maps.png' },
            library: { icon: this.iconBase + 'library_maps.png' },
            info: { icon: this.iconBase + 'info-i_maps.png' },
            compliant: { icon: 'http://maps.google.com/mapfiles/kml/paddle/grn-stars.png' },
            job: { icon: 'http://maps.google.com/mapfiles/kml/paddle/grn-stars.png' },
            noncompliant: { icon: 'http://maps.google.com/mapfiles/kml/paddle/stop.png' }
        };

        this.loading = true;
        let contentString = null;
        contentString = '<div id="content"><div id="siteNotice"></div><h3 id="firstHeading" class="firstHeading">'
            + this.depot?.street_address;
        + this.depot.suburb
            + this.depot.postcode
            + this.depot.state_id
            + this.depot.country_id;
        let map = this.map;
        let lat = parseInt(this.depot.location_lat);
        let lng = parseInt(this.depot.location_lng);
        const marker = new google.maps.Marker(
            {
                position: new google.maps.LatLng(lat, lng),
                title: this.depot?.street_address,
                map,
                icon: this.icons['compliant'].icon
            });
        this.overlays.push(marker)

        const infowindow = new google.maps.InfoWindow({
            content: contentString,
        });

        google.maps.event.addListener(marker, 'click', (function (marker) {
            return function () {
                infowindow.setContent(contentString);
                infowindow.open(map, marker);
            }
        })(marker));
        this.loading = false;
    }

    deleteDepots(depot: Depot) {
        this.confirmationService.confirm({
            message: 'Are you sure you want to delete the selected depots?',
            header: 'Confirm',
            icon: 'pi pi-exclamation-triangle',
            accept: () => {
                depot.deleted = "1";
                this.crudService.Update(depot, 'depot').subscribe(async (data: any) => {
                    if (data.success === 1) {
                        this.depots = this.depots.filter(val => val.deleted != '1');
                        this.messageService.add({ severity: 'success', summary: 'Successful', detail: 'Depot Deleted', life: 1000 });
                    } else {
                        this.messageService.add({ severity: 'error', summary: 'Error', detail: 'Depot Not Updated', life: 1000 });
                    }
                });
            }
            });
    }

    ngOnChanges() {
        this.componentForm = {
            street_number: 'short_name',
            route: 'long_name',
            locality: 'long_name',
            sublocality_level_1: 'long_name',
            administrative_area_level_1: 'short_name',
            country: 'long_name',
            postal_code: 'short_name'
        };
        this.options = {
            types: [],
            componentRestrictions: {
                country: ['AU']
            }
        }
    }
    onEdit() {
        window.scrollTo(0, 0);
    }


    getAllCountries() {
        this.crudService.getAll('Country').subscribe((data: any[]) => {
            this.countryList = data;
        });
    }

    getAllStates() {
        this.crudService.getAll('State').subscribe((data: any[]) => {
            this.stateList = data;
        });
    }

    displayState(state_id) {
        let stateName = '';
        for (let i = 0; i < this.stateList?.length; i++) {
            if (this.stateList[i].id === state_id) {
                stateName = this.stateList[i].name;
                break;
            }
        }
        return stateName;
    }
    displayCountry(country_id) {
        let CountryName = '';
        for (let i = 0; i < this.countryList?.length; i++) {
            if (this.countryList[i].id === country_id) {
                CountryName = this.countryList[i].name;
                break;
            }
        }
        return CountryName;
    }


    onFocusEvent(event: any) {
        /*  this.appErrMsgJobPriorityName = "";
         this.appErrMsgJobPriorityDes = "";
         this.appErrMsgJobPriorityNo = ""; */
    }

    openNew() {
        this.depot = {};
        this.selectedCountry = {};
        this.selectedState = {};
        this.selectedCompany = {};
        this.submitted = false;
        this.depotDialog = true;
        this.depot.status = '1'
        this.depot.contact_name = this.companyData[0].main_contact_name;
        this.depot.email = this.companyData[0].email;
        this.depot.contact_best = this.companyData[0].mobile_no;
        this.depot.contact_alt = this.companyData[0].phone;
        this.isActive = true;
        if (this.viewType === 'TP') {
            this.depot.company_id = this.companyId;
        }
        this.depot.street_address = '';
        this.formattedaddress = '';
        this.invalid = true;
        this.depot = { ...this.depot };
        console.log("openNew", this.depot);
    }

    deleteSelectedDepots() {
        this.confirmationService.confirm({
            message: 'Are you sure you want to delete the selected depots?',
            header: 'Confirm',
            icon: 'pi pi-exclamation-triangle',
            accept: () => {
                // tslint:disable-next-line: prefer-for-of
                for (let i = 0; i < this.selectedDepots?.length; i++) {
                    this.crudService.Delete(this.selectedDepots[i].id, 'depot').subscribe((data: any[]) => { });
                }
                this.depots = this.depots.filter(val => !this.selectedDepots.includes(val));
                this.selectedDepots = null;
                this.messageService.add({ severity: 'success', summary: 'Successful', detail: 'Depots Deleted', life: 1000 });
            }
        });
    }

    editDepot(depot: Depot) {
        this.loading = true;
        this.depot = { ...depot };
        this.depotDialog = true;
        if (this.depot.status === '' || this.depot.status === null || this.depot.status === '1') {
            this.depot.status = '1';
            this.isActive = true;
        } else {
            this.depot.status = '2';
            this.isActive = false;
        }
        this.crudService.getDataByField('Country', 'id', this.depot.country_id).subscribe((data: any[]) => {
            this.selectedCountry = data[0];
        });
        this.crudService.getDataByField('State', 'id', this.depot.state_id).subscribe((data: any[]) => {
            this.selectedState = data[0];
        });
        this.formattedaddress = this.depot?.street_address;
        this.loading = false;
        this.loadMap();
    }

    deleteDepot(depot: Depot) {
        this.confirmationService.confirm({
            message: 'Are you sure you want to delete depot : ' + depot.depot_name + '?',
            header: 'Confirm',
            icon: 'pi pi-exclamation-triangle',
            accept: () => {
                this.depots = this.depots.filter(val => val.id !== depot.id);
                this.depot = {};
                this.crudService.Delete(depot.id, 'depot').subscribe((data: any[]) => {
                    this.messageService.add({ severity: 'success', summary: 'Successful', detail: 'Depot Deleted', life: 1000 });
                });
            }
        });
    }

    onRowSelect(event) {
        this.depot = event.data;
        this.depotDialog = true;
        //this.messageService.add({severity:'info', summary:'Product Selected', detail: event.data.name});
    }

    onRowUnselect(event) {
        this.messageService.add({ severity: 'info', summary: 'Product Unselected', detail: event.data.name });
    }

    hideDialog() {
        this.depotDialog = false;
        this.submitted = false;
    }
    editFormioDepot(deopt: Depot) {
        this.depot = { ...deopt };
        this.router.navigate(['/formio-edit', this.depot.id])
    }
    saveDepot(address: any) {
        this.loading =true;
        if (this.env.hosting == 'UK') {
            this.depot.state_id = '111';
            this.depot.country_id = '1';
        } else {
            this.depot.state_id = this.selectedState.id;
            this.depot.country_id = this.selectedCountry.id;
        }
        this.submitted = true;
        // this.depot.location_lat = '';
        // this.depot.location_lng = '';
        if (this.isActive) {
            this.depot.status = '1';
            this.depot.StatusDescription = 'Active';
        } else {
            this.depot.status = '2';
            this.depot.StatusDescription = 'Inactive';
        }
        this.depotDialog = true;
        this.depot.deleted = null;
        if (this.depotForm.status === 'VALID') {
            if (this.depot.id) {
                this.crudService.Update(this.depot, 'depot').subscribe((data: any) => {
                    if (data.success === 1) {
                        this.depotDialog = false;
                        this.loading = false;
                        this.depots[this.findIndexById(this.depot.id)] = this.depot;
                        this.messageService.add({ severity: 'success', summary: 'Successful', detail: 'Depot Updated', life: 1000 });
                    } else {
                        this.depotDialog = true;
                        this.loading = false;
                        this.messageService.add({ severity: 'error', summary: 'Error', detail: 'Depot Not Updated', life: 1000 });
                    }
                });
            } else {
                this.depot.make_default = '1';
                this.depot.process_id = this.companyId
                this.depot.created_by = this.userDetails.id;
                this.crudService.Create(this.depot, 'depot').subscribe((data: any) => {
                    if (data.success === 1) {
                        this.depotDialog = false;
                        this.depot.id = data.id;
                        this.depots = [...this.depots, this.depot];
                        this.loading = false;
                        this.messageService.add({ severity: 'success', summary: 'Successful', detail: 'Depot Created', life: 1000 });
                    } else {
                        this.depotDialog = true;
                        this.loading = false;
                        this.messageService.add({ severity: 'error', summary: 'Error', detail: 'Depot Not Created', life: 1000 });
                    }
                });
            }
        } else {
            this.loading = false;
            this.messageService.add({ severity: 'error', summary: 'Error', detail: 'Depot Not Created. Need all Mandatory fields filled properly.', life: 1000 });
        }
       
    }

    findCompanyById(id: string): string {
        let companyName = '';
        for (let i = 0; i < this.companyList?.length; i++) {
            if (this.companyList[i].id === id) {
                companyName = this.companyList[i].business_name;
                break;
            }
        }
        return companyName;
    }

    findIndexById(id: string): number {
        let index = -1;
        for (let i = 0; i < this.depots?.length; i++) {
            if (this.depots[i].id === id) {
                index = i;
                break;
            }
        }
        return index;
    }

    exportPdf() {
        import('jspdf').then(jsPDF => {
            import('jspdf-autotable').then(x => {
                /* const doc = new jsPDF.default(0,0);
                if(typeof this.selectedDepots == 'undefined'){
                    doc.autoTable(this.exportColumns, this.depots);
                   }else{
                       doc.autoTable(this.exportColumns, this.selectedDepots);
                }
               doc.save('products.pdf'); */
            })
        })
    }

    exportExcel(selectedDepots, table) {
        let JsonData: any[] = [];
        if (this.selectedDepots != null && this.selectedDepots?.length > 0) {
            JsonData = this.selectedDepots;
        } else {

            if (typeof table.filteredValue !== 'undefined') {
                if (table.filteredValue.length !== this.depots?.length && table.filteredValue.length > 0) {
                    JsonData = table.filteredValue;
                } else {
                    JsonData = this.depots;
                }
            } else {
                JsonData = this.depots;
            }
        }


        let reformattedArray = JsonData.map(obj => {

            let rObj = {};
            this.cols.forEach(pair => {
                rObj[pair.header.toString()] = obj[pair.field];
            });
            return rObj;
        })
   
        

        import('xlsx').then(xlsx => {
            const worksheet = xlsx.utils.json_to_sheet(reformattedArray);
            const workbook = { Sheets: { data: worksheet }, SheetNames: ['data'] };
            const excelBuffer: any = xlsx.write(workbook, { bookType: 'xlsx', type: 'array' });
            this.saveAsExcelFile(excelBuffer, 'depots');
        });
    }

    saveAsExcelFile(buffer: any, fileName: string): void {
        import('file-saver').then(FileSaver => {
            const EXCEL_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
            const EXCEL_EXTENSION = '.xlsx';
            const data: Blob = new Blob([buffer], {
                type: EXCEL_TYPE
            });
            FileSaver.saveAs(data, fileName + '_export_' + new Date().getTime() + EXCEL_EXTENSION);
        });
    }

    clear(table: Table) {
        table.clear();
        this.searchvalue = "";
    }

    public AddressChange(address: any) {
        const fullAddress = [];
        this.formattedaddress = address.formatted_address
        let lat = address.geometry.location.lat();
        let lng = address.geometry.location.lng();
        // tslint:disable-next-line: prefer-for-of
        for (let i = 0; i < address.address_components?.length; i++) {
            const addressType = address.address_components[i].types[0];
            if (this.componentForm[addressType]) {
                const val = address.address_components[i][this.componentForm[addressType]];
                if (addressType === 'street_number') {
                    fullAddress[0] = val;
                } else if (addressType === 'route') {
                    fullAddress[1] = val;
                }
                if (addressType === 'sublocality_level_1') {//suburb
                    this.depot.street_address = val;
                }
                if (addressType === 'locality') {//suburb
                    this.depot.suburb = val;
                } else if (addressType === 'administrative_area_level_1') {//state
                    this.stateList.forEach((stat) => {
                        if (stat.name === val) {
                            this.depot.state_id = stat.id;
                            this.selectedState = stat;
                        }
                    });
                } else if (addressType === 'administrative_area_level_2') {
                } else if (addressType === 'country') {
                    this.countryList.forEach((ctry) => {
                        if (ctry.name === val) {
                            this.depot.country_id = ctry.id;
                            this.selectedCountry = ctry;
                        }
                    });
                } else if (addressType === 'postal_code') {
                    this.depot.postcode = val;
                }
            }
        }
        const fullAdr = fullAddress.join(' ');
        this.depot.street_address = fullAdr;


        
        this.depot.location_lat = lat;
        this.depot.location_lng = lng;
        this.loadMap();
    }

    chooseAddress(event)
    {     
          this.inputAddress = event.summaryline;
          this.formattedaddress = event.summaryline;
          this.depot.suburb = event.posttown
          if(event.addressline2 != undefined)
          {
            this.depot.street_address = event.addressline1 + ',' + event.addressline2;
          }
          else
          {
            this.depot.street_address = event.addressline1;
          }
          this.depot.postcode = event.postcode;
          this.depot.location_lat = event.latitude;
          this.depot.location_lng = event.longitude;
          this.stateList.forEach((stat) => {
              if (stat.id === '111') {
                  this.depot.state_id = stat.id;
                  this.selectedState = stat;
              }
          }); 
          this.countryList.forEach((ctry) => {
              if (ctry.id === '222') {
                  this.depot.country_id = ctry.id;
                  this.selectedCountry = ctry;
              }
          });
          this.loadMap();
    }
  
    search(event) {
      this.crudService.getPostcoderAddress(event.query).subscribe((res:any)=>{  
          this.filteredPlaces = res.filter((val: any) => val.summaryline.toLowerCase().includes(event.query.toLowerCase()));
        });
    }
}
