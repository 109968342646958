import { Component, OnInit, Input,Output,EventEmitter } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { CRUDService } from 'src/app/utilities/crud.service';
import { ConfirmationService } from 'primeng/api';
import { MessageService } from 'primeng/api';
import { Table } from 'primeng/table';
import { CommonService } from 'src/app/utilities/common.service'
import { environment } from 'src/environments/environment';
import { DynamicDialogConfig } from 'primeng/dynamicdialog';

@Component({
  selector: 'app-dynamicform',
  templateUrl: './dynamicform.component.html',
  styleUrls: ['./dynamicform.component.scss']
})
export class DynamicformComponent implements OnInit {

  public form: FormGroup;
  unsubcribe: any

  fieldtypes:any;
  selectedInput:any;
  getInputDialog: boolean = false;
  showForm:boolean=true;
  Displaytypes:any;
  Isrequire:any;
  fieldList:any;
  droped:any;
  startIndex:any;
  dradStart:boolean=false;
  componentStyle: any;
  inputFields:any[]=[];
  selecteddisplaytypes: any;
  selectedrequiretypes:any;
  selectedcstyle:any;
  editRecord:boolean=false;
  editRecordIndex:any;
  @Input() defnHead:any;
  @Input() defnTitle:any;
  @Input() defnDetailList:any;
  @Output() closeDialog: EventEmitter<any> = new EventEmitter();
  @Output() exportRecord: EventEmitter<any> = new EventEmitter();

  loading: boolean = false;
  formOptions:any;;

  constructor() { 
    this.form = new FormGroup({
      fields: new FormControl(JSON.stringify(this.inputFields))
    })
    this.unsubcribe = this.form.valueChanges.subscribe((update) => {
      this.inputFields = JSON.parse(update.fields);
    });
  }

  async ngOnInit(): Promise<void> {
    this.fieldtypes = [
      { id: 'number', value: 'Number' , icon: 'pi pi-sort-numeric-down'},
      { id: 'text', value: 'Text' , icon: 'pi pi-pencil'},
      { id: 'textbox', value: 'Textbox' , icon: 'fa fa-file-text-o' },
      { id: 'phonenumber', value: 'Phone Number' , icon: 'pi pi-mobile' },
      { id: 'checkbox', value: 'Checkbox' , icon: 'pi pi-check-square' },
      { id: 'email', value: 'Email' , icon: 'pi pi-envelope' },
      { id: 'dropdown', value: 'Dropdown' , icon: 'pi pi-solid pi-caret-down' },
      { id: 'address', value: 'Address' , icon: 'pi pi-map-marker' },
      { id: 'radio', value: 'Radio' , icon: 'pi pi-circle-off' },
      { id: 'url', value: 'Url' , icon: 'pi pi-external-link' },
      { id: 'date', value: 'Date' , icon: 'pi pi-calendar' },
      { id: 'datetime', value: 'Date&time', icon: 'pi pi-clock' },
    ];
    this.droped = [];
    
    this.Displaytypes = [
      { id: '1', value: 'Table view' },
      { id: '2', value: 'All' },
      { id: '3', value: 'Hide At Forms' },
    ];

    this.componentStyle=[
      { id: 'col-md-12', value: 'col-md-12' },
      { id: 'col-md-6', value: 'col-md-6' },
      { id: 'col-md-4', value: 'col-md-4' },
      { id: 'col-md-3', value: 'col-md-3' },
      { id: 'col-md-2', value: 'col-md-2' }
    ]

    this.Isrequire = [
      { id: '0', value: 'No' },
      { id: '1', value: 'Yes' },
    ];
    this.defnDetailList.forEach(item => {

      if (item.options?.length != '0' && item.options?.length !=null && item.options?.length != '') 
      {
        this.formOptions = JSON.parse(item.options);
      } else {
        this.formOptions = '[]';
      }

      this.inputFields.push({
          id: item.id,
          name: item.field_name,
          label: item.caption,
          display_type: item.display_type,
          helptext: item.helptext,
          field_length: item.field_length,
          seq: item.seq,
          field_type: item.required,
          component_style: 'col-md-12',
          cs_style:item.component_style,
          container_style: item.container_style,
          options: this.formOptions,
          show:true,
          value: '',
          type: item.field_type,
          status: item.status
      })
    });
    console.log("inputFields",this.inputFields);
  }


onDragStart(index: number) {
  debugger
    this.startIndex = index;
}

onDrop(dropIndex: number) {
  debugger
  if(this.dradStart)
  {
    this.getInputDialog = true;
  }
  else
  {
    if(this.startIndex){
      const general = this.inputFields[this.startIndex]; // get element
      this.inputFields.splice(this.startIndex, 1);       // delete from old position
      this.inputFields.splice(dropIndex, 0, general);  
    }
     // add to new position
  }
    
}

  dragStart(e, c) {
    this.selectedInput = c;
    this.dradStart=true;
  }

  dragEnd(e) {
    this.fieldList = {
      id: null,
      name: null,
      label: null,
      display_type: null,
      helptext: null,
      field_length: null,
      seq: null,
      required: null,
      field_type: null,
      component_style: null,
      cs_style:null,
      container_style: null,
      options: null,
      options2:null,
      status: null
    };
    this.selecteddisplaytypes=null;
    this.selectedrequiretypes=null;
    this.selectedcstyle=null;
  }

  async saveRecord() {
        if (this.selecteddisplaytypes && typeof this.selecteddisplaytypes === 'object') {
          const displaytype1 = this.selecteddisplaytypes.id
          this.fieldList.display_type = displaytype1
        }
        if (this.selectedrequiretypes && typeof this.selectedrequiretypes === 'object') {
          const isrequire1 = this.selectedrequiretypes.id
          this.fieldList.required = isrequire1
        }
        if (this.selectedcstyle && typeof this.selectedcstyle === 'object') {
          const cStyle = this.selectedcstyle.id
          this.fieldList.component_style = cStyle
        }

        if(this.editRecord)
        {
          this.inputFields[this.editRecordIndex].name=this.fieldList.name;
          this.inputFields[this.editRecordIndex].label=this.fieldList.label;
          this.inputFields[this.editRecordIndex].display_type=this.fieldList.display_type;
          this.inputFields[this.editRecordIndex].helptext=this.fieldList.helptext;
          this.inputFields[this.editRecordIndex].field_length=this.fieldList.field_length;
          this.inputFields[this.editRecordIndex].seq=this.fieldList.seq;
          this.inputFields[this.editRecordIndex].cs_style=this.fieldList.component_style;
          this.inputFields[this.editRecordIndex].container_style=this.fieldList.container_style;
          this.inputFields[this.editRecordIndex].component_style='col-md-12';
          this.inputFields[this.editRecordIndex].options=this.fieldList.options2;
          console.log("Inputed Field",this.inputFields)
          this.getInputDialog = false;
          this.editRecord=false;
        }
        else
        {
            if (this.selectedInput) {
              this.inputFields.push(
                {
                  type: this.selectedInput.id,
                  name: this.fieldList.name,
                  label: this.fieldList.label,
                  helptext: this.fieldList.helptext,
                  value: '',
                  field_length:this.fieldList.field_length,
                  seq:this.fieldList.seq,
                  container_style:this.fieldList.container_style,
                  required: this.fieldList.required,
                  options: this.fieldList.options,
                  display_type: this.fieldList.display_type,
                  show:true,
                  component_style: 'col-md-12',
                  cs_style:this.fieldList.component_style
                  }
                );
              this.selectedInput = null;
            }
            console.log("Inputed Field",this.inputFields)
            this.getInputDialog = false;
            this.dradStart=false;
            this.editRecord=false;
          }
  }

  drop(e) {
    this.getInputDialog = true;
  }
  removeFields(i)
  {
    this.inputFields.splice(i, 1);
  }
  editFields(editField,i){
    this.fieldList = editField;
    let isrequire = this.Isrequire.filter(val => val.id === this.fieldList.required);
    if (isrequire.length > 0) {
      this.selectedrequiretypes = isrequire[0];
    }
    else{
      this.selectedrequiretypes = null;
    }
    
    let display_type = this.Displaytypes.filter(val => val.id === this.fieldList.display_type);
    if (display_type.length > 0) {
      this.selecteddisplaytypes = display_type[0];
    }
    else{
      this.selecteddisplaytypes = null;
    }
    let cStyle = this.componentStyle.filter(val => val.id === this.fieldList.cs_style);
    if (cStyle.length > 0) {
      this.selectedcstyle = cStyle[0];
    }
    else{
      this.selecteddisplaytypes = null;
    }
    this.fieldList.options2=JSON.stringify(editField.options); 
    this.editRecord=true;
    this.editRecordIndex=i;
    this.getInputDialog = true;
  }
  
  getFields(i) {
   let  fields=
      [{
        "type": "text",
        "name": "text",
        "label": "text",
        "value": "text",
        "required": true,
        "options": [],
        "display_type": 0,
        "show":false,
        "component_style": "col-md-12",
        "cs_style": "col-md-12",
    }];
    fields.push(this.inputFields[i]);
    return fields;
  }
  saveData()
  {
    
  }

  ngDistroy() {
    this.unsubcribe();
  }

  hideDialog() {
    this.getInputDialog = false;
  }

  hideDialogMain() {
    this.closeDialog.emit();
  }

  saveRecords() {
    this.exportRecord.emit(this.inputFields);
  }


  clear(table: Table) {
    table.clear();
  }

}