import { Component, Input, OnInit } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { DomSanitizer } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { ConfirmationService, MessageService } from 'primeng/api';
import { CommonService } from 'src/app/utilities/common.service';
import { DialogService, DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { CRUDService } from 'src/app/utilities/crud.service';
import { JOBService } from 'src/app/utilities/job.service';
import { environment } from 'src/environments/environment';
import { DatePipe, formatDate, Time } from '@angular/common';
import * as moment from 'moment';

@Component({
  selector: 'app-schedule-admin',
  templateUrl: './schedule-admin.component.html',
  styleUrls: ['./schedule-admin.component.scss']
})
export class ScheduleAdminComponent implements OnInit {
  scheduleForm = new FormGroup({
    schednote: new FormControl(''),
    isEmail: new FormControl(''),
    isPhoned: new FormControl(''),
    isSMS: new FormControl(''),
    isCallBack: new FormControl(''),
    isPMOnly: new FormControl(''),
    phone_no: new FormControl(''),
    sms_no: new FormControl(''),
    email: new FormControl(''),
    atch: new FormControl('')
  });
  city: string;
  notesChanged: boolean = false;
  @Input() job_id: any;
  @Input() job_alloc_id: any;
  @Input() type: any;
  @Input() jobSummary: any;
  jobschedule: any
  showHeader: Boolean = false;
  Recipient: any[];
  communicationType: any[];
  loading: Boolean = false;
  Date_str: any;
  attachmentType: any[];
  selectedAttachmentType: any[] = [];
  selectedRecipient: any = null;
  isEmail: boolean = false;
  isSMS: boolean = false;
  display: boolean = false;
  title: any;
  jobDetails: any;
  jobInfo: any;
  date: any;
  isPhoned: boolean = false;
  isCallBack: boolean = false;
  isPMOnly: boolean = false;
  submitted: boolean = false;
  createSchedule: any = false
  scheduleJob: any;
  currentUser: any;
  menuData: any;
  viewType: any;
  messageCategory: any[];
  selectedmessageCategory: any = null;
  jobAllocationResults: any;
  type_of_visit: any[];
  emptySchedNote: any;
  userDetails: any;
  toggleCommSched: any = true;
  smsInvalid: any;
  smsEmpty: any;
  schedule_data: any;
  selectedVisitType: any;
  visitTypeList: any[];
  selectedStartTime: any;
  selectedEndTime: any;
  timeSlots: any[];
  filteredTimeSlots: any[];
  selectdEmailId: any[] = [];
  mailIdOptions: string[] = [];
  emailId: any;
  showError: boolean = false;
  minDate: Date;
  maxDate: Date;
  appProps: any;
  filteredEndTimeSlots: any[];
  endTimeSlots: any[];
  emailsList :any;
  env : any;
  saveState : Boolean = false
  startTime : any;
  endTime: any;
  jobAllocTemp : any;
  pipe = new DatePipe('en-US');
  newTimeSlot: any=[];
  datePipe: DatePipe = new DatePipe('en-US');
  today= new Date();
jstoday = '';
  current_min: any='';
  final_today ='';
  ClientName: any;
  claimNo: any;

  /*   emailsList: any[] = [{ value: 'krishnanshridhar@gmail.com' },
    { value: 'tmsupport@agileitexperts.com' },
    { value: 'shri@envirofrontier.com.au' },
    { value: 'accounts@envirofrontier.com.au' },
    { value: 'mark@envirofrontier.com.au' },
    { value: 'peter@envirofrontier.com.au' },
    { value: 'teamleader@envirofrontier.com.au' },
    { value: 'documents@envirofrontier.com.au' },
    { value: 'support@envirofrontier.com.au' },
    { value: 'admin@envirofrontier.com.au' }];
   */
  constructor(
    private route: ActivatedRoute,
    private crudService: CRUDService,
    private messageService: MessageService,
    private confirmationService: ConfirmationService,
    private router: Router,
    private commonservice: CommonService,
    public ref: DynamicDialogRef,
    public config: DynamicDialogConfig,

    public JobService: JOBService,
    public sanitizer: DomSanitizer,
    public dialogRef: DynamicDialogRef,
  ) {
    this.userDetails = JSON.parse(sessionStorage.getItem('loggedInUser'));
    this.timeSlots = ['12:00 AM', '12:30 AM', '01:00 AM', '01:30 AM', '02:00 AM', '02:30 AM', '03:00 AM', '03:30 AM', '04:00 AM', '04:30 AM', '05:00 AM', '05:30 AM', '06:00 AM', '06:30 AM', '07:00 AM', '07:30 AM', '08:00 AM', '08:30 AM', '09:00 AM', '09:30 AM', '10:00 AM', '10:30 AM', '11:00 AM', '11:30 AM',
      '12:00 PM', '12:30 PM', '01:00 PM', '01:30 PM', '02:00 PM', '02:30 PM', '03:00 PM', '03:30 PM', '04:00 PM', '04:30 PM', '05:00 PM', '05:30 PM', '06:00 PM', '06:30 PM', '07:00 PM', '07:30 PM', '08:00 PM', '08:30 PM', '09:00 PM', '09:30 PM', '10:00 PM', '10:30 PM', '11:00 PM', '11:30 PM'];
      this.filteredTimeSlots = this.timeSlots;
      this.endTimeSlots = this.timeSlots;
   
    
  }
  async ngOnInit() {
    this.loading = true;
    this.env = environment;
    debugger
    this.appProps = this.commonservice.getAppProperties();
    this.emailsList = await this.commonservice.getMailerlist();
    this.showHeader = false;
    this.currentUser = JSON.parse(sessionStorage.getItem('currentUser'));

//console.log('this.config.dataaa',this.config.data)



    if (typeof this.config.data == 'undefined' || typeof (this.config.data.jobId) == 'undefined') {
    } else {

     
      this.job_id = this.config.data.jobId;
      this.job_alloc_id = this.config.data.jobAllocationId;
      this.type = this.config.data.type;
      this.jobSummary=this.config.data.jobSummary;

    //  console.log('job sumoo', this.jobSummary)

      if(this.job_alloc_id!=='1')
      {
        let smryData: any = await this.crudService.getJobSummary(this.job_id, this.job_alloc_id).toPromise();
        this.jobAllocTemp = await this.JobService.getJobInfo(smryData[0]);
      }
     
      this.crudService.getDataByField('jobInfo', 'id', this.job_id).subscribe((data: any[]) => {

       // console.log('jobInfoooo',data)
        this.minDate = new Date(data[0].job_recd_dt)
      })
      this.showHeader = true;
    }

    this.crudService.getDataByField('jobInfo', 'id', this.job_id).subscribe((data: any[]) => {
      this.minDate = new Date(data[0].job_recd_dt)
    })
    this.showHeader = false;
    /* if (this.job_alloc_id == '1') {
      this.display = true;
    } else {
      this.display = false;
    } */
    this.display = false;
    this.scheduleJob = {
      PMOnly: null,
      action_required: null,
      callback: '1',
      created_at: new Date(),
      created_by: this.currentUser.userId,
      email: '1',
      emailaddress: null,
      end_time: null,
      id: null,
      job_alloc_id: this.job_alloc_id,
      job_id: this.job_id,
      last_modified_by: null,
      last_updated_at: null,
      owner: null,
      phone_no: null,
      phoned: '1',
      process_id: this.currentUser.company,
      sched_date: new Date(),
      sched_note: null,
      sms: '1',
      sms_no: null,
      start_time: null,
      status: '1',
      username: this.currentUser.username,
      visit_type: null,
      source: null,
      message_received: 1,
      message_flow: 1,
      comm_recipient: null,
      comm_recipient_subcatg: null
    }

    //this.settimelist();
    if (this.job_alloc_id === null || this.job_alloc_id === '') {
      this.job_alloc_id = '1';


    }

    if (this.job_alloc_id !== '1') {
      let smryData: any = await this.crudService.getJobSummary(this.job_id, this.job_alloc_id).toPromise();
      //console.log('smryData[0]',smryData[0])
      
      this.jobAllocationResults = await this.JobService.getJobInfo(smryData[0]);
     // console.log('jobAllocation  Resultss',this.jobAllocationResults)
      this.showHeader = true;
    } else {
      this.jobAllocationResults = this.job_alloc_id;
      let smryData: any = await this.crudService.getJobSummaryByJobId(this.job_id).toPromise();

     // console.log('job ID  Resultss',smryData)

      this.jobAllocationResults = await this.JobService.getJobInfo(smryData[0]);
      console.log('jobAllocation  Resultss22',this.jobAllocationResults)

     // this.jobSummary = this.crudService.getDataByField('JobInfo', 'id', this.job_id);


    }
    this.communicationType = [
      { id: '1', title: 'Phoned' },
      { id: '2', title: 'SMS' },
      { id: '3', title: 'Email' },
      { id: '4', title: 'Call Back' },
    ]
    this.crudService.getUploadDocumentsByJobIdAllocId(this.job_id, this.job_alloc_id).subscribe((dataList: any[]) => {
      this.attachmentType = dataList;
    });
    if(this.env.hosting == 'UK'){
      this.visitTypeList = [
        { id: '1', title: 'Arb Site Inspection' },
        { id: '2', title: 'Quote Inspection' },
        { id: '3', title: 'Perform Works' }
      ]
    }
    else{
      this.visitTypeList = [
        { id: '1', title: 'Site Inspection' },
        { id: '2', title: 'Make Safe' },
        { id: '3', title: 'Perform Works' }
      ]
    }   
    this.selectedVisitType = null;
    this.selectedAttachmentType = [];

    if (this.type === 'Schedule') {
      this.isPMOnly = false;
      this.switchToSchedule();
    } else {
      this.isPMOnly = true;
      this.switchToComm();
    }
    this.scheduleJob.message_received = 2;
    //this.minDate = new Date();
    this.maxDate = new Date(this.minDate.getTime() + ((1000 * 60 * 60 * 24) * 180));
    this.loading = false;
  }

  settimelist(event){
    this.filteredTimeSlots = this.timeSlots;
    this.endTimeSlots = this.timeSlots;

    if(this.env.hosting == 'UK'){
      this.filteredTimeSlots = this.timeSlots.filter((val: any) => val.includes(event.query));
    }
    else{
      const now = moment();
      var date1= this.pipe.transform(now, 'shortDate');
      var date2= this.pipe.transform(this.scheduleJob.sched_date,'shortDate');
      if(date1  === date2){
        const date = new Date();
        this.jstoday = formatDate(this.today, 'hh:mm a', 'en-US', '+0530');
        this.current_min =formatDate(this.today, 'mm', 'en-US', '+0530');
             
            if(this.current_min < 30 ){
              date.setMinutes(30);
            }
            else{
              date.setMinutes(60);   
            }
            this.final_today = formatDate(date, 'hh:mm a', 'en-US', '+0530');
           let index = this.timeSlots.indexOf(this.final_today)
            this.filteredTimeSlots = this.timeSlots.slice(index);
  
      }
      else{
        this.filteredTimeSlots = this.timeSlots.filter((val: any) => val.includes(event.query));   
      }
    }
  
   
  }
  onChangeRecipient(selectedRecipient) {

   // console.log('selectedRecipient',selectedRecipient)
    this.Recipient.forEach(item => {
      if (item.id === this.selectedRecipient.id) {
        item.selected = 'true';
      } else {
        item.selected = 'false';
      }
    });
    this.crudService.getByValuesetName(selectedRecipient.valueset_detail_desc).subscribe((details: any) => {
      this.messageCategory = details;
      this.selectedmessageCategory = this.messageCategory[0];

     // console.log('selectedmessageCategory',this.selectedmessageCategory)
      this.onChangeMessageCategory(selectedRecipient.valueset_detail_desc,this.selectedmessageCategory.valueset_detail_name);
      if (selectedRecipient.valueset_detail_name == 'TP') {
        this.isPMOnly = false;
        this.scheduleJob.PMOnly = '1';
      } else {
        this.isPMOnly = true;
        this.scheduleJob.PMOnly = '2';
      }
    });
  }
  onChangeMessageCategory(selectRecipient, selectedMessage) {

  //  console.log('selectRecipient',selectRecipient)

   //console.log('selectedMessage',selectedMessage)

    if (typeof this.scheduleJob.sched_note !== 'undefined') {
      if (this.notesChanged) {
        this.confirmationService.confirm({
          message: 'Do you want to override the Notes?',
          header: 'Confirm',
          icon: 'pi pi-exclamation-triangle',
          accept: () => {
            this.loadMessage(selectRecipient,selectedMessage);
            this.confirmationService.close();
          },
          reject: () => {
            this.confirmationService.close();
          }
        });
      } else {
        this.loadMessage(selectRecipient,selectedMessage);
      }
    } else {
      this.loadMessage(selectRecipient,selectedMessage);
    }
  }

  loadMessage(selectRecipient,selectedMessage) {
    //if (this.job_alloc_id !== '1') {
      if (this.selectedRecipient.id != null) {
        this.scheduleJob.sched_note = '';
       // console.log('messageCategory',this.messageCategory);
       // console.log('msg cat id',this.selectedmessageCategory.id);
    
        let valueset = this.messageCategory.find(valueset => (this.selectedmessageCategory.id == valueset.id));
        if (valueset.valueset_detail_desc != null) {
          this.scheduleJob.sched_note = valueset.valueset_detail_desc;
        }

      //  console.log('recpt iddd',this.selectedRecipient.id);
        this.ClientName = this.jobAllocationResults?.info?.client?.trim();
        if(this.ClientName == null || this.ClientName == 'null' || this.ClientName == 'undefined' || this.ClientName == undefined || this.ClientName == '')
        {
          this.ClientName = '';
        }
        this.claimNo = this.jobAllocationResults?.info?.job_claim_no;
        if(this.claimNo == '' || this.claimNo == 'null' || this.claimNo == null || this.claimNo == undefined)
        {
          this.claimNo = "N/A";
        }


        if (this.selectedRecipient.valueset_detail_desc == 'TenantCommType' && this.selectedmessageCategory.valueset_detail_name == 'Initial Notification Subsidence') {
          this.scheduleJob.sched_note = 'Hi ' + this.jobAllocationResults?.SiteContactShortName?.trim() + ', This is Enviro Trees UK contacting you on behalf of '+ this.ClientName + '. We have been requested to provide a Subsidence Arborist Report at your property ' + this.jobAllocationResults?.info?.AddressForComms + '. Our consultant will be in contact to schedule a suitable day/time. Your Ref. No. is ' + this.jobAllocationResults.JobNo + '. ' + this.scheduleJob.sched_note;
        } 


        else if (this.selectedRecipient.valueset_detail_desc == 'TenantCommType' && this.selectedmessageCategory.valueset_detail_name == 'Initial Notification Tree Quote') {
          this.scheduleJob.sched_note = 'Hi ' + this.jobAllocationResults?.SiteContactShortName?.trim() + ', This is Enviro Trees UK contacting you on behalf of '+ this.ClientName + '. We have been requested to provide a tree quote at your property ' + this.jobAllocationResults?.info?.AddressForComms + '. Our tree partner will be in contact to schedule a suitable day/time. Your Ref. No. is ' + this.jobAllocationResults.JobNo + '. ' + this.scheduleJob.sched_note;
        } 

        else if (this.selectedRecipient.valueset_detail_desc == 'TenantCommType' && this.selectedmessageCategory.valueset_detail_name == 'Initial Notification Tree Work') {
          this.scheduleJob.sched_note = 'Hi ' + this.jobAllocationResults?.SiteContactShortName?.trim() + ', This is Enviro Trees UK contacting you on behalf of '+ this.ClientName + '. We have been requested to  undertake the tree works at your property ' + this.jobAllocationResults?.info?.AddressForComms + '. Our tree partner will be in contact to schedule a suitable day/time. Your Ref. No. is ' + this.jobAllocationResults.JobNo + '. ' + this.scheduleJob.sched_note;
        } 

        else if (this.selectedRecipient.valueset_detail_desc == 'TenantCommType' && this.selectedmessageCategory.valueset_detail_name == 'Completed Works') {
          this.scheduleJob.sched_note = 'Hi ' + this.jobAllocationResults?.SiteContactShortName?.trim() + ','+' This is Enviro Trees UK following up on the works undertaken at your property ' + this.jobAllocationResults?.info?.AddressForComms+'. Confirming the works undertaken were acceptable. If you would like to provide any feedback, that is appreciated. Your Ref. No. is ' + this.jobAllocationResults.JobNo + '. ' + this.scheduleJob.sched_note;
        } 

        else if (this.selectedRecipient.valueset_detail_desc == 'TenantCommType' && this.selectedmessageCategory.valueset_detail_name == 'Update') {
          this.scheduleJob.sched_note = 'Hi ' + this.jobAllocationResults?.SiteContactShortName?.trim() + ','+' This is Enviro Trees UK updating on the tree at your property ' + this.jobAllocationResults?.info?.AddressForComms + '.                                     Your Ref. No. is ' + this.jobAllocationResults.JobNo + '. ' + this.scheduleJob.sched_note;
        } 
        
        
        
        else if (this.selectedRecipient.valueset_detail_desc == "TPCommType" && this.selectedmessageCategory.valueset_detail_name == "Contact Enviro Trees UK") {
          this.scheduleJob.sched_note = 'Hi ' + this.jobAllocationResults?.TPContactShortName?.trim() + ', may you please contact Enviro Trees UK in relation to ' + this.jobAllocationResults.JobNo + ' at ' + this.jobAllocationResults?.info?.AddressForComms + '. ' + this.scheduleJob.sched_note;
                
        } 
        else if (this.selectedRecipient.valueset_detail_desc == "TPCommType" && this.selectedmessageCategory.valueset_detail_name == "Contact Site Person") {
          this.scheduleJob.sched_note = 'Hi ' + this.jobAllocationResults?.TPContactShortName?.trim() + ', may you please contact the site person ' + this.jobAllocationResults?.SiteContactShortName?.trim() + ', ' + this.jobAllocationResults?.info?.SiteContactMobile + ' at '+ this.jobAllocationResults.JobNo + ' at ' + this.jobAllocationResults?.info?.AddressForComms + '. The site contact wants to know                   . ' + this.scheduleJob.sched_note;
                
        }
        else if (this.selectedRecipient.valueset_detail_desc == "TPCommType" && this.selectedmessageCategory.valueset_detail_name == "Other") {
          this.scheduleJob.sched_note = 'Hi ' + this.jobAllocationResults?.TPContactShortName?.trim() + ', regarding ' + this.jobAllocationResults.JobNo + ' at ' + this.jobAllocationResults?.info?.AddressForComms + '                   . ' + this.scheduleJob.sched_note;
                
        }
        else if (this.selectedRecipient.valueset_detail_desc == "TPCommType" && this.selectedmessageCategory.valueset_detail_name == 'Request Cost') {
          this.scheduleJob.sched_note = 'Hi ' + this.jobAllocationResults?.TPContactShortName?.trim() + ', may you please provide costing for job ' + this.jobAllocationResults.JobNo + ' at ' + this.jobAllocationResults?.info?.AddressForComms + '. ' + this.scheduleJob.sched_note;
                
        }
        else if (this.selectedRecipient.valueset_detail_desc == "TPCommType" && this.selectedmessageCategory.valueset_detail_name == 'Request Photos') {
          this.scheduleJob.sched_note = 'Hi ' + this.jobAllocationResults?.TPContactShortName?.trim() + ', may you please update photos for job ' + this.jobAllocationResults.JobNo + ' at ' + this.jobAllocationResults?.info?.AddressForComms + '. ' + this.scheduleJob.sched_note;
                
        }
        else if (this.selectedRecipient.valueset_detail_desc == "TPCommType" && this.selectedmessageCategory.valueset_detail_name == 'Request Schedule Update') {
          this.scheduleJob.sched_note = 'Hi ' + this.jobAllocationResults?.TPContactShortName?.trim() + ', may you please provide an ETA for site visit for ' + this.jobAllocationResults.JobNo + ' at ' + this.jobAllocationResults?.info?.AddressForComms + '. ' + this.scheduleJob.sched_note;
                
        }
        else if (this.selectedRecipient.valueset_detail_desc == "TPCommType" && this.selectedmessageCategory.valueset_detail_name == 'Secondary Quote') {
          this.scheduleJob.sched_note = 'Hi ' + this.jobAllocationResults?.TPContactShortName?.trim() + ', ' + this.scheduleJob.sched_note;
                
        }
        else if (this.selectedRecipient.valueset_detail_desc == "TPCommType" && this.selectedmessageCategory.valueset_detail_name == 'Tree Information') {
          this.scheduleJob.sched_note = 'Hi ' + this.jobAllocationResults?.TPContactShortName?.trim() + ', may you please review the Tree Information tab for this job as appears to have not been filled out correctly. For job ' + this.jobAllocationResults.JobNo + ' at ' + this.jobAllocationResults?.info?.AddressForComms + '. ' + this.scheduleJob.sched_note;
                
        }
        
       

        else if (this.selectedRecipient.valueset_detail_desc == 'WPCommType' && this.selectedmessageCategory.valueset_detail_name == 'Confirmation of PO') {
         
          this.scheduleJob.sched_note = 'Hi ' + this.ClientName + ', thank you for your PO ' + this.claimNo + ' at '
            + this.jobAllocationResults?.info?.AddressForComms + '. Confirming receipt of the PO and we have reached out to make contact with '+  this.jobAllocationResults?.SiteContactShortName?.trim() + ' to schedule attendance. We will update you of progress as it occurs. Your Ref. No. is '
            + this.jobAllocationResults.JobNo + '. ' + this.scheduleJob.sched_note ;
        } 

        else if (this.selectedRecipient.valueset_detail_desc == 'WPCommType' && this.selectedmessageCategory.valueset_detail_name == 'Follow up on Quote Approval') {
         
          this.scheduleJob.sched_note = 'Hi ' + this.ClientName + ', regarding PO ' + this.claimNo + ' at '
          + this.jobAllocationResults?.info?.AddressForComms + '. ' + this.scheduleJob.sched_note ;
        } 

        else if (this.selectedRecipient.valueset_detail_desc == 'WPCommType' && this.selectedmessageCategory.valueset_detail_name == 'Informing of site schedule') {
         
          this.scheduleJob.sched_note = 'Hi ' + this.ClientName + ', regarding PO ' + this.claimNo + ' at '
          + this.jobAllocationResults?.info?.AddressForComms + '. Enviro Trees UK has confirmed a schedule attendance with the site contact at                . Your Ref. No. is '
          + this.jobAllocationResults.JobNo + '. ' + this.scheduleJob.sched_note ;
        } 

        else if (this.selectedRecipient.valueset_detail_desc == 'WPCommType' && this.selectedmessageCategory.valueset_detail_name == 'Other') {
         
          this.scheduleJob.sched_note = 'Hi ' + this.ClientName + ', regarding PO ' + this.claimNo + ' at '
          + this.jobAllocationResults?.info?.AddressForComms + '.                  '  + ' Your Ref. No. is '
          + this.jobAllocationResults.JobNo + '. ' + this.scheduleJob.sched_note ;
        } 

        else if (this.selectedRecipient.valueset_detail_desc == 'WPCommType' && this.selectedmessageCategory.valueset_detail_name == 'Tree Job Completion Notification') {
         
          this.scheduleJob.sched_note = 'Hi ' + this.ClientName + ', regarding PO ' + this.claimNo + ' at '
          + this.jobAllocationResults?.info?.AddressForComms + '. Confirming tree works have been completed as per job order. We are finalising the invoice package and it will be sent at completion of our audit. Your Ref. No. is '
          + this.jobAllocationResults.JobNo + '. ' + this.scheduleJob.sched_note ;
        } 

        else if (this.selectedRecipient.valueset_detail_desc == 'WPCommType' && this.selectedmessageCategory.valueset_detail_name == 'Unable to Reach Tenant') {
         
          this.scheduleJob.sched_note = 'Hi ' + this.ClientName + ', regarding PO ' + this.claimNo + ' at '
          + this.jobAllocationResults?.info?.AddressForComms + '. We have not been able to connect with '+ this.jobAllocationResults?.SiteContactShortName?.trim() +' on the contact details provided. Do you have an alternate contact? Your Ref. No. is '
          + this.jobAllocationResults.JobNo + '. ' + this.scheduleJob.sched_note ;
        } 
        
        
        else if (this.selectedRecipient.valueset_detail_desc == 'EnviroCommType' && this.selectedmessageCategory.valueset_detail_name == 'Ready for Invoicing') {
          this.scheduleJob.sched_note = 'Our Ref. No. is ' + this.jobAllocationResults.JobNo+ ','  + this.scheduleJob.sched_note;
        }
      }
    //}
    //  else {
    //   this.scheduleJob.sched_note = this.selectedmessageCategory.valueset_detail_desc;
    // } 
  }

  saveRecord() {
    this.saveState = true;
    this.loading = true;
    if(  !this.toggleCommSched && (!this.scheduleJob.start_time ||!this.scheduleJob.end_time)){
      if(!this.scheduleJob.start_time){
        this.messageService.add({ severity: 'error', summary: 'Error', detail: 'Enter start time', life: 1000 });
        this.loading = false;
      }
      else if(!this.scheduleJob.end_time){
        this.messageService.add({ severity: 'error', summary: 'Error', detail: 'Enter end time', life: 1000 });
        this.loading = false;
      }
      // else if(!(this.selectdEmailId.length > 0)){
      //   this.messageService.add({ severity: 'error', summary: 'Error', detail: 'Enter atleast one email', life: 1000 });
      //   this.loading = false;
      // }
      this.saveState = false;
    }

    else{
      this.submitted = true;
      this.scheduleJob.visit_type = null;
      this.scheduleJob.phoned = '1';
      if (this.scheduleJob.phone_no !== null && this.scheduleJob.phone_no !== '') {
        this.scheduleJob.phoned = '2';
      }
      this.scheduleJob.email = '1';
      if (typeof this.selectdEmailId !== 'undefined') {

        if (this.selectdEmailId !== null && this.selectdEmailId.length > 0) {
          let emailid = '';
          this.selectdEmailId.forEach(mailid => {
            if (emailid !== '') {
              emailid = emailid + ', ' + mailid.value;
            } else {
              emailid = mailid.value;
            }
          });
          this.scheduleJob.emailaddress = emailid;
          this.scheduleJob.email = '2';
        }
      }
      this.scheduleJob.sms = '1';
      if (this.scheduleJob.sms_no !== null && this.scheduleJob.sms_no !== '') {
        this.scheduleJob.sms = '2';
      }
      if (this.isCallBack) {
        this.scheduleJob.callback = '2';
      } else {
        this.scheduleJob.callback = '1';
      }
      debugger
      if (this.isPMOnly) {
        this.scheduleJob.PMOnly = '2';
      } else {
        this.scheduleJob.PMOnly = '1';
      }
      this.scheduleJob.status = '';
      if (typeof this.selectedVisitType !== 'undefined') {
        if (this.selectedVisitType != null && this.selectedVisitType != '') {
          this.scheduleJob.visit_type = this.selectedVisitType.id;
          this.scheduleJob.status = '1';
        }
      }

      this.scheduleJob.source = '1';
      /* if (this.scheduleJob.end_time!=null && this.scheduleJob.end_time!=='') {
        this.scheduleJob.end_time = this.scheduleJob.end_time).toTimeString();
      }
      if (this.scheduleJob.start_time!=null && this.scheduleJob.start_time!=='') {
        this.scheduleJob.end_time = new Date(this.scheduleJob.end_time).toTimeString();
        this.scheduleJob.start_time = this.scheduleJob.start_time.toString().split(' ')[4];
      } */
      if (typeof this.selectedRecipient !== 'undefined' && this.selectedRecipient != null) {
        this.scheduleJob.comm_recipient = this.selectedRecipient.id;
      }
      if (typeof this.selectedmessageCategory !== 'undefined' && this.selectedmessageCategory != null) {
        this.scheduleJob.comm_recipient_subcatg = this.selectedmessageCategory.id;
      }
      this.scheduleJob.sched_date = moment(this.scheduleJob.sched_date).format('YYYY-MM-DD').toString();
      this.scheduleJob.pin_state = 0
      if(this.scheduleJob.sched_note!='' || this.scheduleJob.sched_note!=null)
      {
          this.crudService.Create(this.scheduleJob, 'JobSchedule').subscribe((data: any) => {
            if (data.success === 1) {
              this.scheduleJob.id = data.id;
              if (typeof this.selectedVisitType !== 'undefined') {
                if (this.selectedVisitType !== null && this.selectedVisitType !== '') {
                  this.saveState = false;
                  this.loading = false;
                //  if (this.job_alloc_id !== '1') {
                    this.sendScheduleNotification(this.scheduleJob);
                    this.messageService.add({ severity: 'success', summary: 'Success', detail: 'Schedule Saved. Notification Sent.', life: 1000 });
                  // } else {
                  //   this.messageService.add({ severity: 'success', summary: 'Success', detail: 'Schedule Saved.', life: 1000 });
                  // }
                } else {
                  this.saveState = false;
                  this.loading = false;
                // if (this.job_alloc_id !== '1') {
                  //  console.log('this.scheduleJob',this.scheduleJob)
                    this.sendCommsNotification(this.scheduleJob);
                    this.messageService.add({ severity: 'success', summary: 'Success', detail: 'Communication Saved. Notification Sent.', life: 1000 });
                  // this.loading = false;
                  //} else {
                  // this.messageService.add({ severity: 'success', summary: 'Success', detail: 'Communication Saved.', life: 1000 });
                  // this.loading = false;
                // }
                }
              }
              this.dialogRef.close();
            } else {
              this.saveState = false;
              this.loading = false;
              this.messageService.add({ severity: 'error', summary: 'Error', detail: 'Schedule/Communication not saved', life: 1000 });
            }
          });
      }
      else
      {
        this.messageService.add({ severity: 'error', summary: 'Error', detail: 'Pleas Enter the note', life: 1000 });
      }
    }

  }

  sendCommsNotification(ScheduleFormData) {
    this.loading = true;
    this.scheduleJob.message_flow = '2';
    this.scheduleJob.message_received = '2';
    this.scheduleJob.message_comm_type = '2';

    //console.log('this.jobAllocationResults?.info?.job_claim_no',this.jobAllocationResults?.info?.job_claim_no)
    if(this.jobAllocationResults?.info?.job_claim_no != '' || this.jobAllocationResults?.info?.job_claim_no != null)
    {
      var claimNo :any = this.jobAllocationResults?.info?.job_claim_no;
    }
    if(this.jobAllocationResults?.info?.job_claim_no == '' || this.jobAllocationResults?.info?.job_claim_no == null)
    {
      var claimNo :any = "N/A";
    }

    if(this.env.hosting == 'UK'){
      var emailSubject = claimNo + ', Site Address - ' + this.jobAllocationResults?.info?.location;
    }
    else{
      var emailSubject = 'Claim Number - ' + claimNo + ', Site Address - ' + this.jobAllocationResults?.info?.location;
    }
    
    
    // let sch_notes = encodeURIComponent(this.scheduleJob.sched_note);
    let sch_notes = this.scheduleJob.sched_note;
    // console.log('encode', sch_notes);
    let mailSubject = '';
    let EmailAccount = 'AU';
    if (this.jobAllocationResults?.info?.AccountType == 'AU') {
      EmailAccount = 'AU';
    } else if (this.jobAllocationResults?.info?.AccountType == 'NZ') {
      EmailAccount = 'NZ';
    }
    let documentList = [];
    this.selectedAttachmentType.forEach(file => {
      let docObj = { filename: file.file_name, url: this.crudService.getAPIRoot() + file.file_path }
      documentList.push(docObj);
    });
    let data: any = {
      email: {
        from: '',
        to: this.scheduleJob.emailaddress,
        cc: '',
        bcc: '',
        subject: emailSubject,
        message: sch_notes,
        msg: sch_notes,
        ClientId: this.jobAllocationResults?.WPCompanyId,
        ref_type: 'JobSchedule',
        ref_id: this.scheduleJob.id
      },
      sms: {
        to: this.scheduleJob.sms_no,
        message: sch_notes
      }
    }
    let temp_type = 'email';
    if (this.scheduleJob.sms == '2' && this.scheduleJob.email == '2') {
      temp_type = 'sms-email';
    } else if (this.scheduleJob.sms == '2') {
      temp_type = 'sms';
    } else if (this.scheduleJob.email == '2') {
      temp_type = 'email';
    }
    let atts = { jobId: this.jobAllocationResults.id, allocId: this.jobAllocationResults.job_alloc_id, documentList };
    let dataObj = {
      sms: data.sms,
      email: data.email,
      type: temp_type,
      selectedTpl: null,
      sufix: null,
      jobData: null,
      attachments: atts,
      account: this.env.hosting,
      ClientId: this.jobAllocationResults?.WPCompanyId,
      ref_type: 'JobSchedule',
      ref_id: this.scheduleJob.id
    }

    if(this.env.emailRoutes.common == 'sendgrid')
    {
      const datobj2 = {
        subject : data.email.subject,
        body : data.email.message,
        toEmail : data.email.to,
        ref_id : this.scheduleJob.id,
        ref_type : 'JobSchedule',
        attachments: atts
        }

        if(dataObj.sms.to)
      { 
        var firstChar = dataObj.sms.to;
        var firstChar1 = String(firstChar).substring(0,1);
        if(firstChar1!="0")
        {
          dataObj.sms.to = "0"+dataObj.sms.to;
        }
      }
      this.crudService.sendTMV2Message(dataObj).subscribe((res2: any[]) => {
      });
      if(datobj2.toEmail?.length>0)
      {

        console.log('datobjjj11',datobj2);
        this.crudService.sendmailSendgrid(datobj2,'commonMail2').subscribe((res2: any[]) => { });
      }

    }
    else if(this.env.emailRoutes.common == 'nodemailer')
    {
      this.crudService.sendTMV2Message(dataObj).subscribe((res2: any[]) => {
      });
    }

    // if(this.env.hosting == 'UK')
    // {
    //   this.crudService.sendTMV2Message(dataObj).subscribe((res2: any[]) => {
    //   });
    // }
    // else if(this.env.hosting == 'AU')
    // {
    //   //  this.sendMail(dataObj);
    //     this.crudService.sendTMV2Message(dataObj).subscribe((res2: any[]) => {
    //     });
    // }
    this.loading = false;
    //this.sendMail(dataObj);
    //this.JobService.updateNotifications(this.userDetails.id, emailSubject, sch_notes, this.jobAllocationResults.job_alloc_id);
    /* this.crudService.sendMessage(dataObj).subscribe((res2: any[]) => {
      this.JobService.updateNotifications(this.userDetails.id, emailSubject, sch_notes, this.jobAllocationResults.job_alloc_id);
    }); */
  }

  sendScheduleNotification(ScheduleFormData) {
    debugger
    this.loading = true;
    let claimNo = '';
    if (typeof this.jobAllocationResults?.info?.job_claim_no !== 'undefined') {
      claimNo = this.jobAllocationResults?.info?.job_claim_no;
    }
    let mailSubject = '';
    let visitDesc = this.selectedVisitType.title;
    let EmailAccount = null;
    if (this.jobAllocationResults?.info?.AccountType == 'AU') {
      EmailAccount = 'AU';
    } else if (this.jobAllocationResults?.info?.AccountType == 'NZ') {
      EmailAccount = 'NZ';
    }
    let documentList = [];
    this.selectedAttachmentType.forEach(file => {
      let docObj = { filename: file.file_name, url: this.crudService.getAPIRoot() + file.file_path }
      documentList.push(docObj);
    });
    let atts = { jobId: this.jobAllocationResults.id, allocId: this.jobAllocationResults.job_alloc_id, documentList: documentList };
    if(claimNo == '' || claimNo == 'null' || claimNo == null || claimNo == undefined)
    {
      claimNo = "N/A";
    }
    if (this.jobAllocationResults.info.ClientCommType = 'Email Client') {
      if (this.jobAllocationResults?.info?.Email != 'NA') {

        if(ScheduleFormData.start_time === null || ScheduleFormData.start_time === 'null' || ScheduleFormData.start_time === '' || ScheduleFormData.start_time === undefined || ScheduleFormData.start_time === 'undefined')
        {
          this.startTime = "N/A";
        }
        else
        {
          this.startTime = ScheduleFormData.start_time;
        }

        if(ScheduleFormData.end_time === null || ScheduleFormData.end_time === 'null' || ScheduleFormData.end_time === '' || ScheduleFormData.end_time === undefined || ScheduleFormData.end_time === 'undefined')
        {
          this.endTime = "N/A";
        }
        else
        {
          this.endTime = ScheduleFormData.end_time;
        }
        let params = {
          email: {
           // to: this.scheduleJob.emailaddress ? this.scheduleJob.emailaddress : this.jobAllocationResults.info.Email,
            to: this.scheduleJob.emailaddress,
            cc: 'documents@envirofrontier.com.au',
            bcc: null,
            msg: 'This is a mail to notify that ' + visitDesc + ' has been scheduled on (' +
              new Date(ScheduleFormData.sched_date).toDateString() + ') between ' +
              this.startTime + ' and ' +
              ScheduleFormData.end_time +
              ' for Job No : ' + this.jobAllocationResults.JobNo + '  The details are as below . \n  ' +
              'Risk Address : ' + this.jobAllocationResults?.info?.location + '. \n',
            subject: 'Claim Number - ' + claimNo +
              ', ' + this.jobAllocationResults?.ShortAddr + ' - ' +
              visitDesc + ' scheduled on (' + new Date(ScheduleFormData.sched_date).toDateString() + ') between ' +
              this.startTime + ' and ' +
              this.endTime + ', TM Job Number - ' +
              this.jobAllocationResults.JobNo + ', Site Address - ' + this.jobAllocationResults?.info?.location,
          },
          sms: {
            to: '',
            message: ''
          },
          type: 'email',
          selectedTpl: null,
          sufix: null,
          jobData: this.jobAllocationResults.JobNo,
          account: EmailAccount,
          attachments: documentList
        }

        if(this.env.hosting == 'UK'){
          params.email.subject =   claimNo +
          ', ' + this.jobAllocationResults?.ShortAddr + ' - ' +
          visitDesc + ' scheduled on (' + new Date(ScheduleFormData.sched_date).toDateString() + ') between ' +
          this.startTime + ' and ' +
          this.endTime + ', TM Job Number - ' +
          this.jobAllocationResults.JobNo + ', Site Address - ' + this.jobAllocationResults?.info?.location
        }
        else{

          params.email.subject =  'Claim Number - '+ claimNo +
          ', ' + this.jobAllocationResults?.ShortAddr + ' - ' +
          visitDesc + ' scheduled on (' + new Date(ScheduleFormData.sched_date).toDateString() + ') between ' +
          this.startTime + ' and ' +
          this.endTime + ', TM Job Number - ' +
          this.jobAllocationResults.JobNo + ', Site Address - ' + this.jobAllocationResults?.info?.location  

        }

        if(this.env.emailRoutes.common == 'sendgrid')
        {
          const datobj = {
            subject : params.email.subject,
            body : params.email.msg,
            toEmail : params.email.to,
            ref_id : this.scheduleJob.id,
            ref_type : 'JobSchedule',
            attachments: atts
            }

          //  console.log('datobjjj33',datobj);
            
          this.crudService.sendmailSendgrid(datobj,'commonMail2').subscribe((res2: any[]) => { 
           
           });
          // this.crudService.sendTMV2Message(params).subscribe((res2: any[]) => {
          //});
        }
        else if(this.env.emailRoutes.common == 'nodemailer')
        {
          // this.sendMail(params);
          this.crudService.sendTMV2Message(params).subscribe((res2: any[]) => {
          });
        }

        //this.sendMail(params);
        // this.crudService.sendTMV2Message(params).subscribe((res2: any[]) => {
        // });
        this.JobService.updateNotifications(this.userDetails.id, params.email.subject, params.email.msg, this.jobAllocationResults.job_alloc_id);
        /* this.crudService.sendTMV2Message(params).subscribe((res2: any[]) => {
          //console.log(res2);
        }); */
      } else {
        let params = {
          email: {
            to: 'documents@envirofrontier.com.au', cc: '', bcc: null,
            msg: 'Missing Client Email Details -- This is a mail to notify that ' + visitDesc + ' has been scheduled on '
              + (new Date(ScheduleFormData.sched_date).toDateString()) + ' between ' + ScheduleFormData.start_time + ' and ' +
              ScheduleFormData.end_time + ' for Job No : ' + this.jobAllocationResults.JobNo + '  The details are as below . \n  ' +
              'Risk Address : ' + this.jobAllocationResults?.info?.location + '. \n',
            subject: 'Missing Client Email Details --   ' + visitDesc + ' scheduled on ' + (new Date(ScheduleFormData.sched_date).toDateString()) +
              ' between ' + ScheduleFormData.start_time + ' and ' +
              ScheduleFormData.end_time + ', TM Job Number - '
              + this.jobAllocationResults.JobNo + ', Site Address - ' + this.jobAllocationResults?.info?.location,
          },
          sms: {
            to: '',
            message: ''
          },
          type: 'email', selectedTpl: null, sufix: null, jobData: this.jobAllocationResults.JobNo,
          account: EmailAccount, attachments: documentList
        }

          if(this.env.emailRoutes.common == 'sendgrid')
          {
          const datobj = {
              subject : params.email.subject,
              body : params.email.msg,
              toEmail : params.email.to,
              ref_id : this.scheduleJob.id,
              ref_type : 'JobSchedule',
              attachments: atts
              }
              if(datobj.toEmail?.length>0)
              {
               // console.log('datobjjj22',datobj);
                this.crudService.sendmailSendgrid(datobj,'commonMail2').subscribe((res2: any[]) => { });
              }
             this.crudService.sendTMV2Message(params).subscribe((res2: any[]) => {
            });
          }
          else if(this.env.emailRoutes.common == 'nodemailer')
          {
          this.crudService.sendTMV2Message(params).subscribe((res2: any[]) => {
          });
         }
         this.JobService.updateNotifications(this.userDetails.id, params.email.subject, params.email.msg, this.jobAllocationResults.job_alloc_id);
         
        }
    } 
    else if (this.jobAllocationResults.info.ClientCommType = 'Portal Client') {
      let params = {
        email: {
          to: 'documents@envirofrontier.com.au', cc: '', bcc: null,
          msg: ' To load in portal -- This is a mail to notify that ' + visitDesc + ' has been scheduled on ' + (new Date(ScheduleFormData.sched_date).toDateString()) + ' between ' + ScheduleFormData.start_time + ' and ' + ScheduleFormData.end_time + ' for Job No : ' + this.jobAllocationResults.JobNo + '  The details are as below . \n  ' +
            'Risk Address : ' + this.jobAllocationResults?.info?.location + '. \n',
          subject: 'To load in portal --  ' + visitDesc + ' scheduled on ' + (new Date(ScheduleFormData.sched_date).toDateString()) + ' between ' +
            ScheduleFormData.start_time + ' and ' +
            ScheduleFormData.end_time + ', TM Job Number - ' +
            this.jobAllocationResults.JobNo + ', Site Address - ' + this.jobAllocationResults?.info?.location,
        }, sms: {
          to: '',
          message: ''
        }, type: 'email', selectedTpl: null, sufix: null, jobData: this.jobAllocationResults.JobNo,
        account: EmailAccount, attachments: documentList
      }

      
     // this.sendMail(params);

      // this.sendMail(params);
      this.JobService.updateNotifications(this.userDetails.id, params.email.subject, params.email.msg, this.jobAllocationResults.job_alloc_id);
      this.loading = false;
      /* this.crudService.sendTMV2Message(params).subscribe((res2: any[]) => {
        //console.log(res2);
      }); */
    }
  }

 
  async switchToSchedule() {
    this.toggleCommSched = false;
    this.isPMOnly = false;
    this.selectedVisitType = this.visitTypeList[0];
  }
  async switchToComm() {
    let details: any = await this.crudService.getByValuesetName('CommsCatgType').toPromise();
    this.Recipient = details;
console.log('this.Recipient',this.Recipient)
    if (this.job_alloc_id == '1') {

    }
    this.isPMOnly = true;
    this.toggleCommSched = true;
    this.selectedmessageCategory = null;
    this.selectedRecipient = null;
    //this.onChangeRecipient(this.selectedRecipient);
  }

  search(event) {
    //console.log("search",);
    // this.filteredTimeSlots = this.timeSlots.filter((val: any) => val.includes(event.query));
    this.settimelist(event)
  }

  searchEndTime(event) {
   // console.log("searchEndTime",);
    
    if(!this.scheduleJob.start_time) {
      this.filteredEndTimeSlots = this.timeSlots.filter((val: any) => val.includes(event.query));
    }else {
      this.filteredEndTimeSlots = this.endTimeSlots.filter((val: any) => val.includes(event.query));
    }
  }


  resetInput() {
  //  if (this.job_alloc_id !== '1') {

      let SMSAddressforComms = null;
      this.selectdEmailId = [];
      if (this.selectedRecipient.id == '10135') {
        if (this.jobAllocationResults?.info?.SiteContactMobile !== 'undefined') {
          SMSAddressforComms = this.jobAllocationResults?.info?.SiteContactMobile;
        }
      } else if (this.selectedRecipient.id === '10136') {
        SMSAddressforComms = this.jobAllocationResults?.info?.TPMobile;
      } else if (this.selectedRecipient.id === '10137') {
         SMSAddressforComms = null;
        //  SMSAddressforComms = this.jobAllocationResults?.info?.ClientContact.split('-').pop().trim();
      } else if (this.selectedRecipient.id === '10138') {

      }
      this.scheduleJob.sms_no = SMSAddressforComms;

      let emailAddressforComms = null;
      if (this.selectedRecipient.id === '10135') {
        emailAddressforComms = this.jobAllocationResults?.info?.SiteContactEmail;
      } else if (this.selectedRecipient.id === '10136') {
        emailAddressforComms = this.jobAllocationResults?.info?.TPEmail;
      } else if (this.selectedRecipient.id === '10137') {
        if (this.jobAllocationResults.info.Email !== 'NA') {
          emailAddressforComms = this.jobAllocationResults?.info?.Email;
        }
      } else if (this.selectedRecipient.id === '10138') {

      }
      if (emailAddressforComms !== null && emailAddressforComms !== '') {
        this.selectdEmailId.push({ value: emailAddressforComms });
      }
  //  }

  }

  removeToMailId(value) {
    this.selectdEmailId = this.selectdEmailId.filter(val => val.value !== value.value);
  }

  searchMail(event) {
    this.showError = true
    this.mailIdOptions = [];
    let value = event.query;
    let option = this.emailsList.filter(val => val.includes(value));
    if (option.length > 0) {
      this.mailIdOptions = option;
    } else {
      if (value.includes('@') && (value.includes('.co')) && (value.includes('.in'))) {
        let mailId = event.query.replaceAll(',', '').replaceAll(';', '');
        this.mailIdOptions.push(mailId);
      }
    }
    this.emailId = '';
    this.emailId = event.query;
    if (this.emailValidation(event.query.toLowerCase())) {
      this.showError = false
    }
    // this.dummy=[];
  }
  emailValidation(val: any) {
    var regex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/; // Uppercase character pattern
    if (regex.test(val)){
      return true;
    }else{
      return false;
    }
  }


  addMailId(mailFieldValue, arrayObject) {
    if (typeof mailFieldValue !== 'undefined' && mailFieldValue !== null && mailFieldValue !== '') {
      if (this.emailsList.includes(mailFieldValue)) {
        if (arrayObject.length > 0) {
          let index = arrayObject.findIndex((res: any) => res.value == mailFieldValue);
          if (index > -1) {
          }else{
            arrayObject.push({ value: mailFieldValue });
          }
        }else{
          this.showError = false;
          arrayObject.push({ value: mailFieldValue });
        }
      }else {
        if (this.emailValidation(mailFieldValue)) {
          let index = arrayObject.findIndex((res: any) => res.value == mailFieldValue);
          if (index < 0) {
            arrayObject.push({ value: mailFieldValue });
          }
        }
      }
    }
    this.emailId = null;
    mailFieldValue = null;
  }

  selectId(value, arrayObject) {
    this.addMailId(value, arrayObject);
    value = null;
  }

  loadJobSummary() {
   // console.log('Job Summary33', this.jobSummary);
    this.Date_str = new Date(this.jobSummary.Date).toLocaleDateString();
    this.title = this.jobSummary.JobNo + ' - (' + this.jobSummary.JobStatus + ')';
    this.crudService.getDataByField('jobinfo', 'id', this.job_id).subscribe((data: any[]) => {
      this.jobDetails = data;
      this.jobInfo = data[0];
      this.date = this.jobDetails[0].job_recd_dt.split('T')[0];
      this.crudService.getCostingInfo(this.job_id, this.job_alloc_id).subscribe((costingInfo: any[]) => {
        if (costingInfo.length > 0) {
          if (this.jobSummary.wp_grand_total !== null && this.jobSummary.wp_grand_total !== '') {
            this.jobSummary.tp_grand_total = parseFloat(costingInfo[0].tp_grand_total).toFixed(2);
            this.jobSummary.wp_grand_total = parseFloat(costingInfo[0].wp_grand_total).toFixed(2);
            let variance = this.jobSummary.tp_grand_total / this.jobSummary.wp_grand_total;
            variance = 1 - variance;
            variance = variance * 100;
            this.jobSummary.margin = parseFloat(variance.toString()).toFixed(2);
          }
        }
      });
    })
  }

  sendMail(params) {
   // console.log("sendparams",params);
    this.commonservice.sendNotificationMail(params.email.to,
      params.email.subject,
      'Hi,',
      '',
      params.email.msg,
      null,
      null,
      environment.base_url, 'Login',
      params.attachments,
      params.email.bcc,
      params.email.cc,
      'JobSchedule',
      this.scheduleJob.id);
  }

  changeTimeslot() {
    this.scheduleJob.end_time = '';
    this.endTimeSlots = this.timeSlots
    let index = this.timeSlots.indexOf(this.scheduleJob.start_time)
    index = index+1;
    this.endTimeSlots = this.timeSlots.slice(index);
  }
  onSelectdate(event){
    this.scheduleJob.start_time ='';
    this.scheduleJob.end_time = '';
  }
}