import { Component, OnInit } from '@angular/core';
import { CRUDService } from 'src/app/utilities/crud.service';
import { ConfirmationService } from 'primeng/api';
import { MessageService } from 'primeng/api';
import { Table } from 'primeng/table';
import { CommonService } from 'src/app/utilities/common.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-report-master',
  templateUrl: './report-master.component.html',
  styleUrls: ['./report-master.component.css']
})
export class ReportMasterComponent implements OnInit {

  loading: boolean = false;
  paginationvalue: any;
  cols: any[];
  exportColumns: any[];
  registerReportDialog: boolean = false;
  submitted: boolean;
  reportList: any[];
  registerReport: any;
  selectedregisterReport: any[];

  isActive: boolean;
  selectedCompany: any;
  speedDial: boolean = false;
  isEditAll: boolean = false;
  exportdisplay: Boolean = false;
  submitFlag: Boolean = false;
  clearsearch: string;
  searchText
  searchvalue: any;


  constructor(private crudService: CRUDService,
    private messageService: MessageService,
    private confirmationService: ConfirmationService,
    private commonservice: CommonService) { }

  async ngOnInit(): Promise<void> {
    this.loading = true;
    this.paginationvalue = environment.paginatorValue;
    this.crudService.getAllData('bireports').subscribe((data: any[]) => {
      this.reportList = data;
      // console.log('this.reportList', this.reportList)
    });
    this.cols = [
      { field: 'name', header: 'Report Name' },
      { field: 'report_id', header: 'Report Id' },
      { field: 'embedUrl', header: 'Embed Url' },
      { field: 'description', header: 'Description' },
      { field: 'status', header: 'Status' }
    ];

    this.exportColumns = this.cols.map(col => ({ title: col.header, dataKey: col.field }));

    this.exportdisplay = await this.commonservice.exportCheck();
    this.loading = false;
  }
  onKeypressEvent(event: any) {
    //console.log(event.target.value);
    let searchh = event.target.value.toLowerCase()
    if (searchh == "a" || searchh == "ac" || searchh == 'active') {
      this.searchText = '1'
      this.searchvalue = event.target.value
    }
    else if (searchh == "i" || searchh == "in" || searchh == "inactive") {
      this.searchText = '2',
        this.searchvalue = event.target.value
    }
    else {
      this.searchText = ''
    }

  }

  openNew() {
    this.registerReport = {
      id: null,
      created_by: null,
      created_at: null,
      updated_by: null,
      updated_at: null,
      name: null,
      report_id: null,
      embedUrl: null,
      description: null,
      status: null
    };
    this.registerReportDialog = true;
  }

  addNewRecord(name) {
    if (!this.submitFlag) {
      this.registerReport = {
        id: null,
        created_by: null,
        created_at: null,
        updated_by: null,
        updated_at: null,
        name: null,
        report_id: null,
        embedUrl: null,
        description: null,
        status: null,
        isEdit: true
      };
      this.reportList = [this.registerReport, ...this.reportList];
    }
    this.submitFlag = true;
    name.focus();
  }

  editRecord(registerReport: any) {
    this.registerReport = { ...registerReport };
    if (this.registerReport.status === '' || this.registerReport.status === null || this.registerReport.status === '1') {
      this.registerReport.status = '1'
      this.isActive = true;
    } else {
      this.registerReport.status = '2'
      this.isActive = false;
    }
    //console.log(this.registerReport.company_id);

    this.registerReportDialog = true;
  }

  deleteSelectedReport() {
    this.confirmationService.confirm({
      message: 'Are you sure you want to delete the selected Report?',
      header: 'Confirm',
      icon: 'pi pi-exclamation-triangle',
      accept: () => {
        // tslint:disable-next-line: prefer-for-of
        for (let i = 0; i < this.selectedregisterReport.length; i++) {
          this.crudService.Delete(this.selectedregisterReport[i].id, 'bireports').subscribe((data: any[]) => { });
        }
        this.reportList = this.reportList.filter(val => !this.selectedregisterReport.includes(val));
        this.selectedregisterReport = null;
        this.messageService.add({ severity: 'success', summary: 'Successful', detail: 'Report Detail Deleted', life: 1000 });
      }
    });
  }

  deleteregisterReport(registerReport: any) {
    this.confirmationService.confirm({
      message: 'Are you sure you want to delete Report : ' + registerReport.name + '?',
      header: 'Confirm',
      icon: 'pi pi-exclamation-triangle',
      accept: () => {
        this.reportList = this.reportList.filter(val => val.id !== registerReport.id);
        this.registerReport = {};
        this.crudService.Delete(registerReport.id, 'bireports').subscribe((data: any[]) => {
          this.messageService.add({ severity: 'success', summary: 'Successful', detail: 'Report Detail Deleted', life: 1000 });
        });
      }
    });
  }
  findIndexById(id: string): number {
    let index = -1;
    for (let i = 0; i < this.reportList.length; i++) {
      if (this.reportList[i].id === id) {
        index = i;
        break;
      }
    }

    return index;
  }

  saveRecord1() {
    let changedRecords = this.reportList.filter(val => (val.changed === true));
    changedRecords.forEach(element => {
      this.commonservice.saveRecord('bireports', element);
      element.changed = false;
    });
    this.isEditAll = false;
  }

  async saveRecord() {
    if (this.isActive) {
      this.registerReport.status = '1';
    } else {
      this.registerReport.status = '2';
    }
    if (this.registerReport.name.trim()) {
      if (this.registerReport.id) {
        let response: any = this.commonservice.saveRecord('bireports', this.registerReport);

        if (response.success == true) {
          this.messageService.add({ severity: 'success', summary: 'Successful', detail: 'Report  Details Updated', life: 1000 });
          this.reportList[this.findIndexById(this.registerReport.id)] = this.registerReport;
        }
        else {
          this.messageService.add({ severity: 'info', summary: 'Error', detail: 'Something Went Wrong', life: 1000 });

        }
      }
      else {
        let response: any = this.commonservice.saveRecord('bireports', this.registerReport);

        if (response.success == true) {
          this.messageService.add({ severity: 'success', summary: 'Successful', detail: 'Report  Details Created', life: 1000 });
          this.reportList[this.findIndexById(this.registerReport.id)] = this.registerReport;
        }
        else {
          this.messageService.add({ severity: 'info', summary: 'Error', detail: 'Something Went Wrong', life: 1000 });

        }
      }

      this.registerReportDialog = false;
    }
  }

  hideDialog() {
    this.registerReportDialog = false;
    this.submitted = false;
  }

  exportPdf() {
    import('jspdf').then(jsPDF => {
      import('jspdf-autotable').then(x => {
        /* const doc = new jsPDF.default(0,0);
        doc.autoTable(this.exportColumns, this.selectedDepots);
        doc.save('products.pdf'); */
      })
    })
  }

  exportExcel(selectedregisterReport) {
    import('xlsx').then(xlsx => {
      const worksheet = xlsx.utils.json_to_sheet(this.selectedregisterReport);
      const workbook = { Sheets: { data: worksheet }, SheetNames: ['data'] };
      const excelBuffer: any = xlsx.write(workbook, { bookType: 'xlsx', type: 'array' });
      this.saveAsExcelFile(excelBuffer, 'ReportDetails');
    });
  }

  saveAsExcelFile(buffer: any, fileName: string): void {
    import('file-saver').then(FileSaver => {
      const EXCEL_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
      const EXCEL_EXTENSION = '.xlsx';
      const data: Blob = new Blob([buffer], {
        type: EXCEL_TYPE
      });
      FileSaver.saveAs(data, fileName + '_export_' + new Date().getTime() + EXCEL_EXTENSION);
    });
  }

  clear(table: Table) {
    table.clear();
  }

}

