import { Component, Input } from '@angular/core';
import { FormGroup } from '@angular/forms';

// text,email,tel,textarea,password, 
@Component({
    selector: 'text',
    template: ` 
      <div *ngIf="field.display_type!=3" [formGroup]="form">
        <input *ngIf="!field.multiline" [attr.type]="field.type" class="form-control"
        [attr.required]="field.required"  [id]="field.name" [name]="field.name" [formControlName]="field.name">
        <textarea *ngIf="field.multiline" [class.is-invalid]="isDirty && !isValid" [formControlName]="field.name" [id]="field.name"
        rows="9" class="form-control" [placeholder]="field.placeholder"></textarea>
      </div> 
      
      <div *ngIf="field.display_type==3" [formGroup]="form">
        <input *ngIf="!field.multiline" readonly [attr.type]="field.type" class="form-control"
        [attr.required]="field.required"  [id]="field.name" [name]="field.name" [formControlName]="field.name">
        <textarea *ngIf="field.multiline" [class.is-invalid]="isDirty && !isValid" [formControlName]="field.name" [id]="field.name"
        rows="9" class="form-control" [placeholder]="field.placeholder"></textarea>
      </div>
      <div class="alert alert-danger my-1 p-2 fadeInDown animated" *ngIf="!isValid && isDirty" > Enter valid {{field.label}} </div> 
  `
})
export class TextComponent {
    @Input() field:any = {};
    @Input() form:FormGroup;
    get isValid() { return this.form.controls[this.field.name].valid; }
    get isDirty() { return this.form.controls[this.field.name].dirty; }
  
    constructor() {
    
    }
    ngOnInit() {
    }
}