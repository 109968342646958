import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router, NavigationStart } from '@angular/router';
import { CRUDService } from 'src/app/utilities/crud.service';
import { ConfirmationService, MessageService } from 'primeng/api';
import { DomSanitizer } from '@angular/platform-browser';
import { SignaturePadModule, SignaturePad } from 'angular2-signaturepad';
import { JOBService } from 'src/app/utilities/job.service';
import { CommonService } from 'src/app/utilities/common.service';
import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { DeviceDetectorService } from 'ngx-device-detector';

@Component({
  selector: 'app-staff-training',
  templateUrl: './staff-training.component.html',
  styleUrls: ['./staff-training.component.scss'],
  providers: [MessageService, ConfirmationService]
})
export class StaffTrainingComponent implements OnInit {
  @ViewChild(SignaturePad, { static: true }) public signaturePad: SignaturePad;
  @Input() companyId: any;
  signature: any;
  userDetails: any;
  pdfUrl: any = '';
  displayPdf = false;
  signedBy: any;
  ip: any;
  public lat: any;
  public lng: any;


  constructor(private crudService: CRUDService,
    private messageService: MessageService,
    private JobService: JOBService,
    private CommonService: CommonService,
    private confirmationService: ConfirmationService,
    public sanitizer: DomSanitizer,
    private deviceService: DeviceDetectorService,
    private http: HttpClient) {
  }

  signaturePadOptions: Object = { // passed through to szimek/signature_pad constructor
    minWidth: 2,
    canvasWidth: 500,
    canvasHeight: 200
  };

  isSigned: Boolean = false;
  loading: Boolean = false;
  isChecked: Boolean = false;
  companyCompliance: any;
  isAccepted: Boolean = false;
  acceptedDatetime: any;
  signaturePath: any;
  deviceInfo: any;
  ipAddress: any;

  async ngOnInit() {
    // this.deviceInfo = this.deviceDetectorService.getDeviceInfo();
    // //console.log(this.deviceInfo);
    this.userDetails = JSON.parse(sessionStorage.getItem('loggedInUser'));
    this.loading = true;
    await this.loadComplianceInfo();
    this.getLocation();
    //this.signaturePad.set('minWidth', 5);
    this.signaturePad.clear();
    this.deviceInfo=this.deviceService.getDeviceInfo();
    this.getIP();
  }

  getIP()  
  {  
    this.crudService.getIpAddress().subscribe((res:any)=>{  
      this.ipAddress=res.ip;  
    });  
  }
  
  getLocation() {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition((position) => {
        if (position) {
          this.lat = position.coords.latitude;
          this.lng = position.coords.longitude;
          //console.log(this.lat);
          //console.log(this.lng);
        }
      });
    }
  }

  async loadComplianceInfo() {
    let data: any = await this.crudService.getDataByField('CompanyCompliance', 'company_id', this.companyId).toPromise();
    let slaData = data.filter(val => (val.compliance_id == '75'));
    if (slaData.length > 0) {
      this.companyCompliance = slaData[0];
      this.signaturePath = 'C://uploadImgs//SLA//' + this.companyId + '//' + this.companyId + '.png';
      this.acceptedDatetime = this.companyCompliance.created_at;
      this.isAccepted = true;
      this.pdfUrl = null;
      this.displayPdf = true;
      this.signedBy = this.companyCompliance.comment;
      this.crudService.fetchComplianceFile(this.companyId, this.companyCompliance.file_path, this.companyCompliance.filename).subscribe(blob => {
        const objectUrl = URL.createObjectURL(blob);
        this.pdfUrl = this.sanitizer.bypassSecurityTrustResourceUrl(objectUrl);
        
      })
    } else {
      this.signaturePath = null;
      this.acceptedDatetime = null;
      this.isAccepted = false;
      this.displayPdf = false;
      this.signaturePad.clear();
      this.crudService.fetchComplianceFile('1', '1/compliance_docs/SLA.pdf', 'SLA.pdf').subscribe(blob => {
        const objectUrl = URL.createObjectURL(blob);
        this.pdfUrl = this.sanitizer.bypassSecurityTrustResourceUrl(objectUrl);
      })
      this.signedBy = this.userDetails.first_name + ' ' +this.userDetails.last_name;
      //this.pdfUrl="http://docs.google.com/gview?url="+this.crudService.getAPIRoot() + "/policy_documents/SLA - Template 2021.docx&embedded=true";
      //this.pdfUrl = this.sanitizer.bypassSecurityTrustResourceUrl(this.crudService.getAPIRoot() + '/policy_documents/SLA - Template 2021.docx');
      //  this.pdfUrl = this.crudService.getAPIRoot() + '/policy_documents/SLA - Template 2021.docx';
    }
  }

  drawComplete() {
    this.isSigned = true;
  }


  async saveSLACompliance(filePath, fileName) {
    let expiryDate = new Date(new Date().getTime() + (1000 * 60 * 60 * 24 * 365 * 2));
    let company_compliance = {
      amount: null,
      comment: this.signedBy,
      company_id: this.companyId,
      staff_id: null,
      compliance_id: '75',
      compliance_type_id: null,
      created_at: new Date(),
      created_by: '1',
      expiry_date: expiryDate,
      file_path: filePath,
      filename: fileName,
      id: null,
      issued_by: null,
      issued_date: new Date().toLocaleDateString(),
      last_modified_by: null,
      last_reminder_date: null,
      last_updated_at: null,
      owner: null,
      policy_no: null,
      reminder_count: null,
      reminder_status: null,
      status: null,
      exists: false,
      expired: false,
      expiry_date_str: null,
      issued_date_str: null,
      s3_url: null,
      sync_status: 0,
    };
    let res: any = await this.CommonService.updateRecord('CompanyCompliance', company_compliance);
    this.crudService.fetchComplianceFile(this.companyId, company_compliance.file_path, company_compliance.filename).subscribe(blob => {
      const objectUrl = URL.createObjectURL(blob);
      this.pdfUrl = this.sanitizer.bypassSecurityTrustResourceUrl(objectUrl);
      this.displayPdf = true;
    })
  }

  async acceptTerms() {
    this.loading = true;
    let resp = this.crudService.uploadImage(this.signaturePad.toDataURL(), 'SLA', this.companyId, this.companyId, 'LOCAL');
    let response = JSON.parse(resp)
    let reportId = 'SLA';
    let date = new Date();
    let tempTime = date.getTime();
    let fileDesc = 'SLA';
    let data: any = await this.loadData();
    const fileName = fileDesc + '-' + tempTime + '.pdf';;
    const filePath = 'C:/companyCompliances/' + this.companyId + '/compliance_docs/' + fileName;
    data[0].newReportData.filename = fileName;
    data[0].newReportData.filepath = filePath;
    let res = await this.crudService.generateSLAReport(this.companyId, data, reportId, tempTime, 1).toPromise();
    this.saveSLACompliance(this.companyId + '/compliance_docs/' + fileName, fileName);
    //this.companyId + '/' + 'report-template-' + reportId + '-' + tempTime + '.pdf';
    let blob = new Blob([res], { type: "application/pdf" });
    const objectUrl = URL.createObjectURL(blob);
    await this.loadComplianceInfo();
    this.loading = false;
  }

  async loadData() {
    let companyInfo = await this.crudService.getDataByField('Company', 'id', this.companyId).toPromise();
    let reportData = {
      TEMPLATE_ID: 'C://Source//Report_Templates//SLA.docx',
      ImageURL: 'C://uploadImgs//SLA//' + this.companyId + '//' + this.companyId + '.png',
      CompanyDetails: companyInfo[0]
    };
    let res: any;
    let data = [
      {
        info: {
          id: '67993bdd-f7d9-48a6-93d9-8026b657041a',
          CompanyID: this.companyId,
          reportid: 'SLA',
        },
        newReportData: reportData,
      }
    ];
    return data;



  }
  fileLoaded() {
    this.loading = false;
    //console.log('file Loaded');
  }
}