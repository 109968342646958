import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AppconfigComponent } from './appconfig/appconfig.component';
import { ClientsComponent } from './clients/clients.component';
import { CompaniesComponent } from './companies/companies.component';
import { ContractorsComponent } from './contractors/contractors.component';
import { DepotsComponent } from './depots/depots.component';
import { StaffsComponent } from './staffs/staffs.component';
import { ValuesetComponent } from './valueset/valueset.component';
import { ComponentDefnHeadComponent } from './component-defn-head/component-defn-head.component';
import { ComponentDefnDetailComponent } from './component-defn-detail/component-defn-detail.component'
import { ReportMasterComponent } from './report-master/report-master.component';
import { MenuComponent } from './menu/menu.component';
import { RegionComponent } from './region/region.component';
import { AppMasterComponent } from './master/master.component'
import { AppconfigurationComponent } from './appconfiguration/appconfiguration.component';
import { DynamicformComponent } from './dynamicform/dynamicform.component';
import { MenuPermissionsComponent  } from './menu-permissions/menu-permissions.component';
import { StateComponent } from '../modules/state/state.component';
import { AuditReportComponent } from './audit-report/audit-report.component';
import { ViewAuditComponent } from './view-audit/view-audit.component';
import { ViewAuditChildComponent } from './view-audit-child/view-audit-child.component';
import { AuditlogMasterComponent } from './auditlog-master/auditlog-master.component';

const routes: Routes = [
  {path:'clients',component:ClientsComponent},
  {path:'companies',component:CompaniesComponent},
  {path:'contractors',component:ContractorsComponent},
  {path:'depots',component:DepotsComponent},
  {path:'region',component:RegionComponent},
  {path:'staff',component:StaffsComponent},
  {path:'menus',component: MenuComponent},
  {path:'valueset',component:ValuesetComponent},
  {path:'defnhead',component:ComponentDefnHeadComponent},
  {path:'defndetail',component:ComponentDefnDetailComponent},
  {path:'appconfig',component:AppconfigComponent},
  {path:'report-register',component:ReportMasterComponent},
  {path:'master',component:AppMasterComponent},
  {path:'appconfiguration',component:AppconfigurationComponent},
  {path:'dynamicform',component:DynamicformComponent},
  {path:'menuper',component:MenuPermissionsComponent},
  {path:'state',component:StateComponent},
  {path:'audit',component:AuditReportComponent},
  {path:'viewaudit',component:ViewAuditComponent},
  {path:'viewauditchild',component:ViewAuditChildComponent},
  {path:'auditlogmaster',component:AuditlogMasterComponent},
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class AdminRoutingModule { }
