import { Component, OnInit, Input,Output } from '@angular/core';
import { CRUDService } from 'src/app/utilities/crud.service';
import { ConfirmationService } from 'primeng/api';
import { MessageService } from 'primeng/api';
import { Table } from 'primeng/table';
import { exit } from 'process';
import { CommonService } from 'src/app/utilities/common.service'
import { environment } from 'src/environments/environment';
import { ComponentDefnDetailComponent } from '../component-defn-detail/component-defn-detail.component';
import { DynamicDialogRef, DialogService } from 'primeng/dynamicdialog';
import { AppMasterComponent } from '../master/master.component'
import { AppconfigurationComponent } from '../appconfiguration/appconfiguration.component';
import { DefnChildComponent } from '../defn-child/defn-child.component';
import { DynamicDialogConfig } from 'primeng/dynamicdialog';
import { DynamicformComponent } from '../dynamicform/dynamicform.component'
import { DatePipe } from '@angular/common';
 
@Component({
  selector: 'app-component-defn-head',
  templateUrl: './component-defn-head.component.html',
  styleUrls: ['./component-defn-head.component.css' , '../admin.component.scss'],
  providers: [DatePipe]
})
export class ComponentDefnHeadComponent implements OnInit {
  defnhead:any[];
  loading: boolean = false;
  paginationvalue: any;
  cols: any[];
  exportColumns: any[];
  defnHeadDialog: boolean = false;
  submitted: boolean;
  defnHeadList: any[];
  //defnHead: any;
  defnDetail: any;
  defnDetailList: any[];
  selectedDefnHead: any[];
  companyList: any[];
  isActive: boolean;
  selectedCompany: any;
  speedDial: boolean = false;
  isEditAll: boolean = false;
  exportdisplay: Boolean = false;
  openDefnDetailComponent: boolean = false;
  openHook:boolean=false;
  defnheaddata: any;
  @Input() defnHead: any;
  @Input() defnTitle:any;
  formBuilderDialog:Boolean;
  defnDetailDialog: boolean = false;
  Displaytypes: any[];

  isExport: boolean=false;
  isDelete: boolean=false;
  selectedroute: string;
  userDetails: any;

  constructor(private crudService: CRUDService,private datePipe: DatePipe,
    private messageService: MessageService,
    private confirmationService: ConfirmationService,
    private commonservice: CommonService,
    private dialogService: DialogService,
    public config: DynamicDialogConfig,) { 
      this.userDetails = JSON.parse(sessionStorage.getItem('loggedInUser'));
    }

  async ngOnInit(): Promise<void> {
    this.defnhead=[];
    if (this.config && this.config.data) {
      this.defnHead = this.config.data.code;
      this.defnTitle= this.config.data.title;
    }

    this.selectedroute = sessionStorage.getItem('selectedRoute')
    let per_data :any=await this.crudService.getmenupermissions()
    this.isExport = per_data.isExport;
    this.isDelete = per_data.isDelete;



    this.loading = true;
    this.paginationvalue = environment.paginatorValue;
    this.crudService.getAllCompanies().subscribe((data: any[]) => {
      this.companyList = data;
    });
    this.cols = [
      { field: 'code', header: 'Code' },
      { field: 'title', header: 'Title' },
      { field: 'description', header: 'Description' },
      { field: 'status', header: 'Status' }
    ];
    this.exportColumns = this.cols.map(col => ({ title: col.header, dataKey: col.field }));
    this.exportdisplay = await this.commonservice.exportCheck();
    this.loading = false;
    this.loadData();
  }

  loadData() {
    this.crudService.getAllData('ComponentDefnHead').subscribe((data: any[]) => {
      this.defnHeadList = data;
      console.log("Data:", this.defnHeadList);
    });

    this.crudService.getDataByField('ComponentDefnDetail', 'component_code', this.defnHead).subscribe((data: any[]) => {
      this.defnDetailList= data.sort((a, b) => a.seq.localeCompare(b.seq));
      // this.defnDetailList= data;
      console.log("Data List",this.defnDetailList);
    });
  }
  openNew() {
    this.defnHead = {
      id: null,
      code: null,
      description: null,
      title: null,
      create_mode: null,
      screen_layout: null,
      after_create_hook: null,
      after_update_hook: null,
      after_delete_hook: null,
      before_create_hook: null,
      before_update_hook: null,
      before_delete_hook: null,
      status: null
    };
    this.defnHeadDialog = true;
  }

  editRecord(defnHead: any) {
    this.defnHead = { ...defnHead };
    if (this.defnHead.status === '' || this.defnHead.status === null || this.defnHead.status === '1') {
      this.defnHead.status = '1'
      this.isActive = true;
    } else {
      this.defnHead.status = '2'
      this.isActive = false;
    }
    this.defnHeadDialog = true;
  }

  showDisplaytypes(state_id)
  {
      let value = '';
      for (let i = 0; i < this.Displaytypes?.length; i++) {
          if (this.Displaytypes[i].id === state_id) {
            value = this.Displaytypes[i].value;
              break;
          }
      }
      return value;
  }

  viewDetails(defnHead) {
    const ref = this.dialogService.open(ComponentDefnDetailComponent, {
      data: defnHead,
      width: '100%',
      height: '100%'
    });
    ref.onClose.subscribe(async (response: any) => {
      
    });
  }
  viewChild(defnHead){
    const ref = this.dialogService.open(DefnChildComponent, {
      data: defnHead,
      width: '100%',
      height: '100%'
    });
    ref.onClose.subscribe(async (response: any) => {
    });
  }
  viewDetails2(defnHead) {
    this.defnheaddata = defnHead;
    this.defnTitle=defnHead.title;
    this.openDefnDetailComponent = true;
  }

  viewHooks(defnHead) {
    this.defnheaddata = defnHead;
    this.defnTitle=defnHead.title;
    this.openHook = true
  }

  getFields() {
    return this.defnheaddata.code;
  }

  viewFields(defnHead) {
    const ref = this.dialogService.open(AppMasterComponent, {
      data: defnHead,
      width: '100%',
      height: '100%'
    });
    ref.onClose.subscribe(async (response: any) => {
    });
  }

  openFormDesigner(defnHead)
  {
    this.defnheaddata = defnHead;
    this.defnTitle=defnHead.title;
    this.formBuilderDialog=true;
  }

  closeDialog()
  {
    this.formBuilderDialog = false;
  }

  async exportRecord(event)
  {
      console.log("Input Fields",event);
      for (const w of event) {
        let inputData:any;
        inputData={
          id:w.id,
          component_code: this.defnHead,
          field_name: w.name,
          caption: w.label,
          display_type: w.display_type,
          helptext: w.helptext,
          field_length: w.field_length,
          seq: 1,
          required: w.required,
          field_type: w.type,
          component_style: w.cs_style,
          container_style: w.container_style,
          options: w.options,
          status: 1
        }
        console.log("Input Data",inputData);
        let response: any = await this.commonservice.saveRecord('ComponentDefnDetail', inputData);
       }
      this.formBuilderDialog = false;
      this.loadData();
  }

  // deleteSelectedJob(){
  //     this.confirmationService.confirm({
  //           message: 'Are you sure you want to delete the selected Job Priority?',
  //           header: 'Confirm',
  //           icon: 'pi pi-exclamation-triangle',
  //           accept: () => {
  //               // tslint:disable-next-line: prefer-for-of
  //               for (let i = 0; i < this.selectedJobPriority.length; i++) {
  //                   this.crudService.Delete(this.selectedJobPriority[i].id, 'JobPriority').subscribe((data: any[]) => { });
  //               }
  //               this.jobPriorityList = this.jobPriorityList.filter(val => !this.selectedJobPriority.includes(val));
  //               this.selectedJobPriority = null;
  //               this.messageService.add({ severity: 'success', summary: 'Successful', detail: 'Job Priority Deleted', life: 1000 });
  //           }
  //       });
  // }

  deleteDefnHead(defnHead: any) {
    this.confirmationService.confirm({
      message: 'Are you sure you want to delete  : ' + defnHead.code + '?',
      header: 'Confirm',
      icon: 'pi pi-exclamation-triangle',
      accept: () => {
        this.defnHead = this.defnHead.filter(val => val.id !== defnHead.id);
        this.defnHead = {};
        this.crudService.Delete(defnHead.id, 'ComponentDefnHead').subscribe((data: any[]) => {
          this.messageService.add({ severity: 'success', summary: 'Successful', detail: 'Definition Head Deleted', life: 1000 });
        });
      }
    });
  }

  findIndexById(id: string): number {
    let index = -1;
    for (let i = 0; i < this.defnHeadList.length; i++) {
      if (this.defnHeadList[i].id === id) {
        index = i;
        break;
      }
    }

    for (let i = 0; i < this.defnDetailList.length; i++) {
      if (this.defnDetailList[i].id === id) {
        index = i;
        break;
      }
    }

    return index;
  }

  // findIndexById(id: string): number {
  //   let index = -1;
  //   for (let i = 0; i < this.defnDetailList.length; i++) {
  //     if (this.defnDetailList[i].id === id) {
  //       index = i;
  //       break;
  //     }
  //   }

  //   return index;
  // }

  async saveRecord() {
    if (this.isActive) {
      this.defnHead.status = '1';
    }
    else {
      this.defnHead.status = '2';
    }
    if (this.defnHead.id) {
      let response: any = await this.commonservice.saveRecord('ComponentDefnHead', this.defnHead);
      console.log("Response", response);
      if (response.success == "1") {
        this.messageService.add({ severity: 'success', summary: 'Successful', detail: 'Definition Head Created', life: 1000 });
        this.defnHeadDialog = false;
      }
      else {
        this.messageService.add({ severity: 'error', summary: 'Failed', detail: 'Failed', life: 1000 });
      }
    }
    else {
      let response: any = await this.commonservice.saveRecord('ComponentDefnHead', this.defnHead);
      console.log("Response", response);
      if (response.success == "1") {
        this.messageService.add({ severity: 'success', summary: 'Successful', detail: 'Definition Head Updated', life: 1000 });
        this.defnHeadDialog = false;
      }
      else {
        this.messageService.add({ severity: 'error', summary: 'Failed', detail: 'Failed', life: 1000 });
      }
    }
    this.loadData();
  }

  hideDialog() {
    this.defnHeadDialog = false;
    this.openDefnDetailComponent = false;
    this.openHook = false;
    this.submitted = false;
  }

  exportPdf() {
    import('jspdf').then(jsPDF => {
      import('jspdf-autotable').then(x => {
        /* const doc = new jsPDF.default(0,0);
        doc.autoTable(this.exportColumns, this.selectedDepots);
        doc.save('products.pdf'); */
      })
    })
  }

  exportExcel(selectedJob,table) {
    if(selectedJob && selectedJob.length !==0){
      import('xlsx').then(xlsx => {
        const worksheet = xlsx.utils.json_to_sheet(this.selectedDefnHead);
        const workbook = { Sheets: { data: worksheet }, SheetNames: ['data'] };
        const excelBuffer: any = xlsx.write(workbook, { bookType: 'xlsx', type: 'array' });
        this.saveAsExcelFile(excelBuffer, 'ComponentDefnHead');
      });
    }else{
      this.exportExcelFile(table);
    }
    
  }

  exportExcelFile(table) {
    let JsonData: any[] = [];
    if (this.defnhead != null && this.defnhead.length > 0) {
      JsonData = this.defnhead;
    } else {

      if (typeof table.filteredValue !== 'undefined') {
        if (table.filteredValue.length !== this.defnHeadList.length && table.filteredValue.length > 0) {
          JsonData = table.filteredValue;
        } else {
          JsonData = this.defnHeadList;
        }
      } else {
        JsonData = this.defnHeadList;
      }
    }

    let reformattedArray = JsonData.map(obj => {

      let rObj = {};
      this.cols.forEach(pair => {
        rObj[pair.header.toString()] = obj[pair.field];
      });
      return rObj;
    })
   
    import('xlsx').then(xlsx => {
      const worksheet = xlsx.utils.json_to_sheet(reformattedArray);
      const workbook = { Sheets: { data: worksheet }, SheetNames: ['data'] };
      const excelBuffer: any = xlsx.write(workbook, { bookType: 'xlsx', type: 'array' });
      this.saveAsExcelFile(excelBuffer, 'ComponentDefnHead');
    });
  }

  saveAsExcelFile(buffer: any, fileName: string): void {
    import('file-saver').then(FileSaver => {
      const EXCEL_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
      const EXCEL_EXTENSION = '.xlsx';
      const data: Blob = new Blob([buffer], {
        type: EXCEL_TYPE
      });
      let timeStamp = this.datePipe.transform(new Date(), 'ddMMyy_HHmm');
      FileSaver.saveAs(data, fileName + '_' + timeStamp + EXCEL_EXTENSION);
    });
  }

  clear(table: Table) {
    table.clear();
  }

}
