import { Injectable } from '@angular/core';
import * as AWS from 'aws-sdk';

@Injectable({
  providedIn: 'root',
})
export class S3Service {
  private s3: AWS.S3;
  private bucketName = 'treemanager';
  private region = 'ap-south-1';

  constructor() {
    AWS.config.update({
      region: this.region,
      credentials: {
        accessKeyId: 'AKIA5F2S2C7FRYJLTHNO',
        secretAccessKey: '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',
      },
    });
    this.s3 = new AWS.S3();
  }

  uploadFile(file: File, jobid, job_alloc_id): Promise<string> {

    const folderPath = `uploadVideos/${jobid}/${job_alloc_id}/`;
    const objectKey = folderPath + file.name;

    // const objectKey = 'uploads/' + file.name; 

    const params: AWS.S3.PutObjectRequest = {
      Bucket: this.bucketName,
      Key: objectKey,
      Body: file,
    };

    return new Promise<string>((resolve, reject) => {
      // Check if the folder already exists
      this.s3.headObject({ Bucket: this.bucketName, Key: folderPath }, (folderErr, folderData) => {
        if (folderErr) {
          if (folderErr.code === 'NotFound') {
            // Folder doesn't exist, so create it
            this.s3.putObject({ Bucket: this.bucketName, Key: folderPath }, (createFolderErr, createFolderData) => {
              if (createFolderErr) {
                console.error('Error creating folder in S3:', createFolderErr);
                reject(createFolderErr);
              } else {
                console.log('Folder created in S3:', createFolderData);
                // Upload the file after creating the folder
                this.s3.upload(params, (uploadErr, uploadData) => {
                  if (uploadErr) {
                    console.error('Error uploading file to S3:', uploadErr);
                    reject(uploadErr);
                  } else {
                    console.log('File uploaded to S3 successfully:', uploadData);
                    resolve(uploadData.Location); // Returns the S3 object URL
                  }
                });
              }
            });
          } else {
            console.error('Error checking folder in S3:', folderErr);
            reject(folderErr);
          }
        } else {
          // Folder already exists, so just upload the file
          this.s3.upload(params, (uploadErr, uploadData) => {
            if (uploadErr) {
              console.error('Error uploading file to S3:', uploadErr);
              reject(uploadErr);
            } else {
              console.log('File uploaded to S3 successfully:', uploadData);
              resolve(uploadData.Location); // Returns the S3 object URL
            }
          });
        }
      });
    });
  }

  downloadFile(objectKey: string): Promise<Blob> {
    const params: AWS.S3.GetObjectRequest = {
      Bucket: this.bucketName,
      Key: objectKey,
    };

    return new Promise<Blob>((resolve, reject) => {
      this.s3.getObject(params, (err, data) => {
        if (err) {
          console.error('Error downloading file from S3:', err);
          reject(err);
        } else {
          console.log('File downloaded from S3 successfully:', data);
          resolve(data.Body as Blob);
        }
      });
    });
  }
}
