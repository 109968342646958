import { Component, Input, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { ConfirmationService, MessageService } from 'primeng/api';
import { DialogService } from 'primeng/dynamicdialog';
import { CommonService } from 'src/app/utilities/common.service'
import { CRUDService } from 'src/app/utilities/crud.service';

@Component({
  selector: 'app-vehicle-details',
  templateUrl: './vehicle-details.component.html',
  styleUrls: ['./vehicle-details.component.scss'],
  providers: [DialogService, MessageService, ConfirmationService]
})
export class VehicleDetailsComponent implements OnInit {
  assetsList: any = [];
  AssetVehicleList: any = [] = [];
  AssetMakeList: any = [] = [];
  AssetModelList: any = [] = [];
  hideVehicleCard: any = false;
  vehicleDetailsList: any[] = [];
  selectedList: any;
  selectedmakeList: any;
  vehicleDetails: any;
  selectedmodelList: any;
  selectedvehicle: any;
  Vehicle: any;
  isEditAll: boolean = false;
  displayDelete: Boolean = true;
  asset_characteristics_1: any;
  asset_characteristics_2: any;
  asset_characteristics_3: any;
  submitted: any;
  userDetails: any;
  @Input() id: any;
  minYear: any;
  maxYear: any;
  yearList: any[] = [];

  vehicleForm = new FormGroup({
    vehicleType: new FormControl('', Validators.required),
    make: new FormControl('', Validators.required),
    model: new FormControl('', Validators.required),
    year: new FormControl('', Validators.required),
    cost: new FormControl('', Validators.required),
  })
  cols: { field: string; header: string; }[];
  selectedCharacteristics_1: any;

  constructor(
    private crudService: CRUDService,
    private messageService: MessageService,
    private confirmationService: ConfirmationService,
    private dialogService: DialogService,
    private commonService: CommonService,
    private router: Router,
    private route: ActivatedRoute
  ) {
    this.userDetails = JSON.parse(sessionStorage.getItem('loggedInUser'));
  }

  async ngOnInit() {

    this.AssetVehicleList = await this.crudService.getByValuesetName('AssetVehicleList').toPromise();
    let index = this.AssetVehicleList.findIndex(x => x.valueset_detail_name === "Other");
    this.AssetVehicleList.splice(index,1);
    this.AssetVehicleList.push({created_at: null,created_by: null,id: "10128",last_modified_by: null,last_updated_at: null,owner: null,process_id: "1",status: "1",valueset_detail_desc: "Other",valueset_detail_name: "Other",valueset_id: "10026"});
    this.AssetMakeList = await this.crudService.getByValuesetName('AssetMakeList').toPromise();
    let index1 = this.AssetMakeList.findIndex(x => x.valueset_detail_name === "Other");
    this.AssetMakeList.splice(index1,1);
    this.AssetMakeList.push({created_at: null,created_by: null,id: "10161",last_modified_by: null,last_updated_at: null,owner: null,process_id: "1",status: "1",valueset_detail_desc: "Other",valueset_detail_name: "Other",valueset_id: "10027"});
    this.AssetModelList = await this.crudService.getByValuesetName('AssetModelList').toPromise();
    for(let i =new Date().getFullYear()-15; i<= new Date().getFullYear(); i++){
      this.yearList.push({label: + i });
   }
    
    console.log("check the list11",this.yearList)
    
    this.cols = [
      { field: 'asset_characteristics_1', header: 'Vehicle Type' },
      { field: 'asset_characteristics_2', header: 'Make' },
      { field: 'asset_characteristics_3', header: 'Model' },
      { field: 'asset_characteristics_4', header: 'Year' },
      { field: 'asset_characteristics_5', header: 'Cost' }
    ];
    this.displayDelete = await this.commonService.exportCheck();
    console.log("this.id",this.id);
    debugger
    this.loadAllAssets();
    // if(this.id!==0 && this.id !==null){
    //   this.loadAllAssets();
    // }  
    this.maxYear = new Date().getFullYear();
    this.minYear = new Date().getFullYear() - 15;
    
  }

  fetchYears(){
    for(let i =new Date().getFullYear()-15; i<= new Date().getFullYear(); i++){
      this.yearList.push(i);
    }
  }
  edit(currentItem) {
    this.submitted = false;
    this.vehicleDetails = currentItem;
    this.hideVehicleCard = true;
    if (this.vehicleDetails.asset_characteristics_1) {
      this.selectedList = this.AssetVehicleList.filter(val => val.id === this.vehicleDetails.asset_characteristics_1);
      if (this.selectedList.length > 0) {
        this.selectedList = this.selectedList[0];
      }
    }
    if (this.vehicleDetails.asset_characteristics_2) {
      this.selectedmakeList = this.AssetMakeList.filter(val => val.id === this.vehicleDetails.asset_characteristics_2);
      if (this.selectedmakeList.length > 0) {
        this.selectedmakeList = this.selectedmakeList[0];
      }
    }
    if (this.vehicleDetails.asset_characteristics_3) {
      this.selectedmodelList = this.AssetModelList.filter(val => val.id === this.vehicleDetails.asset_characteristics_3);
      if (this.selectedmodelList.length > 0) {
        this.selectedmodelList = this.selectedmodelList[0];
      }
    }
  }

  addNew() {
    this.vehicleForm.markAsPristine();
    this.vehicleForm.markAsUntouched();
    this.vehicleForm.clearValidators();
    this.submitted = false;
    this.selectedList = this.AssetVehicleList[0];
    this.selectedmodelList = this.AssetModelList[0];
    this.selectedmakeList = this.AssetMakeList[0]
    this.vehicleDetails = {
      asset_characteristics_1: null,
      asset_characteristics_2: null,
      asset_characteristics_3: null,
      asset_characteristics_4: null,
      asset_characteristics_5: null,
      company_id: this.id,
      created_at: null,
      created_by: null,
      last_modified_by: null,
      last_updated_at: null,
      owner: null,
      process_id: this.id,
      status: null
    }
    this.hideVehicleCard = true;

  }

  addNewRecord() {
    // this.vehicleForm.markAsPristine();
    // this.vehicleForm.markAsUntouched();
    // this.vehicleForm.clearValidators();
    // this.assetsList.selectedCharacteristics_1= null,
    // this.assetsList.selectedCharacteristics_2= null,
    // this.assetsList.selectedCharacteristics_3=null,
    // this.assetsList.selectedCharacteristics_4=null
    // this.submitted = false;
  //   this.selectedList = null;
  //   this.selectedmodelList = null;
  //   this.selectedmakeList = null

  // this.temp_AssetVehicleList=this.AssetVehicleList
  // this.temp_AssetMakeList=this.AssetMakeList
  // this.temp_AssetModelList=this.AssetModelList
  // this.AssetVehicleList=[]
  // this.AssetMakeList=[]
  // this.AssetModelList=[]

    // let vehicleDetails = {
    //   asset_characteristics_1: null,
    //   asset_characteristics_1_desc:null,
    //   asset_characteristics_2: null,
    //   asset_characteristics_2_desc:null,
    //   asset_characteristics_3: null,
    //   asset_characteristics_3_desc:null,
    //   asset_characteristics_4: null,
    //   asset_characteristics_5: null,
    //   selectedCharacteristics_1: null,
    //   selectedCharacteristics_2: null,
    //   selectedCharacteristics_3: null,
    //   selectedCharacteristics_4: null,
    //   company_id: this.id,
    //   created_at: null,
    //   created_by: null,
    //   last_modified_by: null,
    //   last_updated_at: null,
    //   owner: null,
    //   process_id: this.id,
    //   status: null,
    //   isEdit: true
    // };
  //  vehicleDetails.asset_characteristics_1 = null;
  //   vehicleDetails.asset_characteristics_2 =null;
  //   vehicleDetails.asset_characteristics_3 = null;

  //   console.log("vehicleDetails",vehicleDetails);
  
  //     this.assetsList = [vehicleDetails, ...this.assetsList];
  //     console.log("vehicleDetails",this.assetsList);

      // if (this.assetsList.asset_characteristics_1) {
       
      //     this.selectedList = null;
      //     console.log("vehicleDetails3",this.assetsList);
      // }
      // if (this.assetsList.asset_characteristics_2) {
        
      //     this.selectedmakeList = null;
       
      // }
      // if (this.assetsList.asset_characteristics_3) {
       
      //     this.selectedmodelList = null;
        
      // }



      // old 
      let vehicleDetails = {
        asset_characteristics_1: null,
        // selectedCharacteristics_1:
        selectedCharacteristics_1:null,
        selectedCharacteristics_2: null,
        selectedCharacteristics_3:null,
        selectedCharacteristics_4: null,
        asset_characteristics_2: null,
        asset_characteristics_3: null,
        asset_characteristics_4: null,
        asset_characteristics_5: null,
        company_id: this.id,
        created_at: null,
        created_by: null,
        last_modified_by: null,
        last_updated_at: null,
        owner: null,
        process_id: this.id,
        status: null,
        isEdit: true
      };

      //this.assetsList = vehicleDetails
       this.assetsList = [vehicleDetails, ...this.assetsList];
  }


  
 


  delete(currentItem) {
   // console.log('delete item',currentItem)
    this.vehicleDetails = currentItem;
    if (currentItem.id) {
      if (this.assetsList.length > 0) {
        this.confirmationService.confirm({
          message: 'Are you sure you want to delete ?',
          header: 'Confirm',
          icon: 'pi pi-exclamation-triangle',
          accept: () => {
            this.assetsList = this.assetsList.filter((val: any) => val.id !== currentItem.id);
            this.crudService.Delete(currentItem.id, 'CompanyAssets').subscribe((data: any) => {
              if (data.success == 1) {
                this.messageService.add({ severity: 'success', summary: 'Successful', detail: 'Vehicle Information Removed', life: 1000 });
              }
            });
          }
        });
      }
    }
  };
  saveVehicleDetails() {
    this.vehicleDetails.asset_characteristics_1 = this.selectedList.id;
    this.vehicleDetails.asset_characteristics_2 = this.selectedmakeList.id;
    this.vehicleDetails.asset_characteristics_3 = this.selectedmodelList.id;
    this.vehicleDetails.process_id = this.id;
    if (this.vehicleForm.status === 'VALID') {
      if (!this.vehicleDetails.id) {
        this.vehicleDetails.created_at = new Date().toDateString();
        this.vehicleDetails.created_by = this.userDetails.id;
        this.vehicleDetails.status = null;
        this.crudService.Create(this.vehicleDetails, 'CompanyAssets').subscribe((data: any) => {
          if (data.success === 1) {
            //  this.assetsList[this.findIndexById(this.vehicleDetails.id)] = this.vehicleDetails;
            this.messageService.add({ severity: 'success', summary: 'Successful', detail: 'Vehicle Details Information added', life: 1000 });
            this.ngOnInit();
            this.hideVehicleCard = false;
          } else {
            this.messageService.add({ severity: 'error', summary: 'Error', detail: 'Vehicle Details Information Not added', life: 1000 });
          }
        });
      }
      else {
        this.vehicleDetails.last_updated_at = new Date().toDateString();
        this.vehicleDetails.last_modified_by = this.userDetails.id;
        this.crudService.Update(this.vehicleDetails, 'CompanyAssets').subscribe((data: any) => {
          if (data.success === 1) {
            this.ngOnInit();
            this.hideVehicleCard = false;
            this.messageService.add({ severity: 'success', summary: 'Successful', detail: 'Vehicle Details Information Updated', life: 1000 });
          } else {
            this.messageService.add({ severity: 'error', summary: 'Error', detail: 'Vehicle Details Information Not Updated', life: 1000 });
          }
        });
      }
    }
  }

  loadAllAssets() {
    this.crudService.getDataByField('CompanyAssets', 'company_id', this.id).subscribe((data: any[]) => {
      this.assetsList = data;
      //console.log('loadAllAssets',this.assetsList)
      if (this.assetsList?.length > 0) {
          localStorage.setItem('vehicleStrength', '10');
      }
      this.assetsList.forEach(asset => {
        let assetlistvalue = this.AssetVehicleList.filter(val => val.id === asset.asset_characteristics_1);
        if (assetlistvalue.length > 0) {
          asset.selectedCharacteristics_1 = assetlistvalue[0];
          asset.asset_characteristics_1_desc = assetlistvalue[0].valueset_detail_name;
        }
        let makelistvalue = this.AssetMakeList.filter(val => val.id === asset.asset_characteristics_2);
        if (makelistvalue.length > 0) {
          asset.selectedCharacteristics_2 = makelistvalue[0];
          asset.asset_characteristics_2_desc = makelistvalue[0].valueset_detail_name;
        }
        let assetmodelvalue = this.AssetModelList.filter(val => val.id === asset.asset_characteristics_3);
        if (assetmodelvalue.length > 0) {
          asset.selectedCharacteristics_3 = assetmodelvalue[0];
          asset.asset_characteristics_3_desc = assetmodelvalue[0].valueset_detail_name;
        }
        asset.selectedCharacteristics_4 = null;
        if(asset.asset_characteristics_4!==''){
          asset.selectedCharacteristics_4 = {label: + asset.asset_characteristics_4};
        }
      });
    })
  }

  cancel() {
    this.submitted = false;
    this.hideVehicleCard = false;
  }
  onSelectedItemChange(event, Vehicle) {
    Vehicle.changed = true;
  }
  resetLastCheckPoint(vehicle) {
   // let Vehicle = this.assetsList[index];
  // console.log('last vehicle',vehicle)
    if(vehicle.id ==null || vehicle.id =='' || vehicle.id ==undefined){
  
      this.assetsList = this.assetsList.filter(val => val !== vehicle);
    }
    vehicle = vehicle.backup;
  }




  backup(index) {
    const Vehicle = this.assetsList[index];
    if(Vehicle.id != '' || Vehicle.id != null) {
      Vehicle.backup = Vehicle;
    }
  }
  onEdit() {
    window.scrollTo(0, 0);
  }

  async saveRecords() {
    this.submitted = true;
    let saveStatus = true;
    console.log("")
    let changedRecords = this.assetsList.filter(val => (val.changed === true));
    if (changedRecords.length != 0) {
      changedRecords.forEach(async vehicle => {
        let status = await this.saveNewRecords(vehicle);
        saveStatus = false;
      });
    }
    this.ngOnInit();
    this.isEditAll = false;
    let editedRecords = this.assetsList.filter(val => (val.isEdit === true || val.changed === true));
    editedRecords.forEach(element => {
      element.isEdit = false;
      element.changed = false;
    });
    //this.loadAllAssets();
  }
  async saveNewRecords(vehicle) {
    vehicle.asset_characteristics_1 = vehicle.selectedCharacteristics_1?.id;
    vehicle.asset_characteristics_2 = vehicle.selectedCharacteristics_2?.id;
    vehicle.asset_characteristics_3 = vehicle.selectedCharacteristics_3?.id;
    //vehicle.asset_characteristics_4 = vehicle.selectedCharacteristics_4?.label;
    vehicle.process_id = this.id;
    if (vehicle.asset_characteristics_1 != '' && vehicle.asset_characteristics_1 != null && vehicle.asset_characteristics_2 != '' && vehicle.asset_characteristics_2 != null && vehicle.asset_characteristics_3 != '' && vehicle.asset_characteristics_3 != null && vehicle.asset_characteristics_4 != '' && vehicle.asset_characteristics_4 != null && vehicle.asset_characteristics_5 != '' && vehicle.asset_characteristics_5 != null) 
    {
      let data: any = await this.commonService.saveRecord('CompanyAssets', vehicle);
      if (data.success) {
        this.hideVehicleCard = false;
        this.messageService.add({ severity: 'success', summary: 'Successful', detail: 'Vehicle Details Information Updated', life: 1000 });
        return true;
      } else {
        this.messageService.add({ severity: 'error', summary: 'Error', detail: 'Vehicle Details Information Not Updated', life: 1000 });
        return false;
      }
    }
    else
    {
      this.messageService.add({ severity: 'error', summary: 'Error', detail: 'Please fill all the fields', life: 1000 });
      return true;
    }
   
  }

}
