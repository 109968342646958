import { Component, OnInit, Input,Output } from '@angular/core';
import { CRUDService } from 'src/app/utilities/crud.service';
import { ConfirmationService } from 'primeng/api';
import { MessageService } from 'primeng/api';
import { Table } from 'primeng/table';
import { CommonService } from 'src/app/utilities/common.service'
import { environment } from 'src/environments/environment';
import { DynamicDialogConfig,DialogService } from 'primeng/dynamicdialog';
import { DynamicformComponent } from '../dynamicform/dynamicform.component'
import { DatePipe } from '@angular/common';
 
@Component({
  selector: 'app-component-defn-detail',
  templateUrl: './component-defn-detail.component.html',
  styleUrls: ['./component-defn-detail.component.css' , '../admin.component.scss'],
  providers: [DatePipe]
})
export class ComponentDefnDetailComponent implements OnInit {

  loading: boolean = false;
  paginationvalue: any;
  cols: any[];
  exportColumns: any[];
  defnDetailDialog: boolean = false;
  submitted: boolean;
  defnDetailList: any[];
  defnDetail: any;
  selectedDefnDetail: any[];
  companyList: any[];
  isActive: boolean = true;
  selectedCompany: any;
  speedDial: boolean = false; 
  isEditAll: boolean = false;
  exportdisplay: Boolean = false;
  @Input() defnHead: any;
  @Input() defnTitle:any;
  Displaytypes: any[];
  Isrequire: any[];
  Fieldtypes: any[];

  selecteddisplaytypes: any;
  selectedrequiretypes:any;
  selectedfieldtypes: any;
  formBuilderDialog:Boolean;
  hooksItems:any;
  selectedActionHook:any;
  selectedValidationHook:any;
  selectedComponentStyle:any
  selectedContainerStyle:any;
  componetStyle:any;

  constructor(private crudService: CRUDService,private datePipe: DatePipe,
    private messageService: MessageService,
    private confirmationServices: ConfirmationService,
    private commonservice: CommonService,
    private dialogService: DialogService,
    public config: DynamicDialogConfig,) { }

  async ngOnInit(): Promise<void> {

    if (this.config && this.config.data) {
      this.defnHead = this.config.data.code;
      this.defnTitle= this.config.data.title;
    }
    
    this.loading = true;
    this.paginationvalue = environment.paginatorValue;
    this.crudService.getAllCompanies().subscribe((data: any[]) => {
      this.companyList = data;
    });
    this.cols = [
      { field: 'code', header: 'Code' },
      { field: 'title', header: 'Title' },
      { field: 'description', header: 'Description' },
      { field: 'display_type', header: 'Display Type' },
      { field: 'status', header: 'Status' }
    ];
    this.Fieldtypes = [
      { id: 'text', value: 'Text' },
      { id: 'number', value: 'Number' },
      { id: 'phonenumber', value: 'Phone Number' },
      { id: 'textbox', value: 'Textbox' },
      { id: 'dropdown', value: 'Dropdown' },
      { id: 'checkbox', value: 'Checkbox' },
      { id: 'email', value: 'Email' },
      { id: 'radio', value: 'Radio' },
      { id: 'address', value: 'Address' },
      { id: 'url', value: 'Url' },
      { id: 'date', value: 'Date' },
      { id: 'datetime', value: 'Date&time' },
    ];
    this.Displaytypes = [
      { id: '1', value: 'Table view' },
      { id: '2', value: 'All' },
      { id: '3', value: 'Hide At Forms' },
    ];
    this.Isrequire = [
      { id: '0', value: 'No' },
      { id: '1', value: 'Yes' },
    ];

    this.componetStyle=[
      { id: 'col-md-12', value: 'col-md-12' },
      { id: 'col-md-6', value: 'col-md-6' },
      { id: 'col-md-4', value: 'col-md-4' },
      { id: 'col-md-3', value: 'col-md-3' },
      { id: 'col-md-2', value: 'col-md-2' }
    ]

    this.exportColumns = this.cols.map(col => ({ title: col.header, dataKey: col.field }));
    this.exportdisplay = await this.commonservice.exportCheck();
    this.loading = false;

    this.hooksItems = await this.crudService.getAllData('hooks').toPromise();
    console.log("Hook",this.hooksItems);
    this.loadData();

  }

  showDisplaytypes(state_id)
  {
      let value = '';
      for (let i = 0; i < this.Displaytypes?.length; i++) {
          if (this.Displaytypes[i].id === state_id) {
            value = this.Displaytypes[i].value;
              break;
          }
      }
      return value;
  }

  loadData() {
    this.crudService.getDataByField('ComponentDefnDetail', 'component_code', this.defnHead).subscribe((data: any[]) => {
     this.defnDetailList= data.sort((a, b) => a.seq.localeCompare(b.seq));
      //  this.defnDetailList= data;
      console.log("Detail Data",data);
    });
  }

  openFormDesigner()
  {
   this.formBuilderDialog=true;
  }


  openNew() {
    this.defnDetail = {
      id: null,
      component_code: this.defnHead,
      field_name: null,
      caption: null,
      display_type: null,
      helptext: null,
      field_length: null,
      seq: null,
      required: null,
      field_type: null,
      component_style: null,
      container_style: null,
      action_hook: null,
      validation_hook: null,
      options: null,
      status: null
    };
    this.selecteddisplaytypes=null;
    this.selectedrequiretypes=null;
    this.selectedfieldtypes=null;
    this.selectedActionHook=null;
    this.selectedValidationHook=null;
    this.selectedComponentStyle=this.componetStyle[0];
    this.selectedContainerStyle=this.componetStyle[0];
    this.defnDetailDialog = true;
  }

  editRecord(defnDetail: any) {
    this.defnDetail = { ...defnDetail };
    if (this.defnDetail.status === '' || this.defnDetail.status === null || this.defnDetail.status === '1') {
      this.defnDetail.status = '1'
      this.isActive = true;
    } else {
      this.defnDetail.status = '2'
      this.isActive = false;
    }

    let isrequire = this.Isrequire.filter(val => val.id === this.defnDetail.required);
    if (isrequire.length > 0) {
      this.selectedrequiretypes = isrequire[0];
    }
    else{
      this.selectedrequiretypes = null;
    }
    let field_type = this.Fieldtypes.filter(val => val.id === this.defnDetail.field_type.trim());
    if (field_type.length > 0) {
      this.selectedfieldtypes = field_type[0];
    }
    else{
      this.selectedfieldtypes = null;
    }

    let display_type = this.Displaytypes.filter(val => val.id === this.defnDetail.display_type);
    if (display_type.length > 0) {
      this.selecteddisplaytypes = display_type[0];
    }
    else{
      this.selecteddisplaytypes = null;
    }

    let component_style = this.componetStyle.filter(val => val.value === this.defnDetail.component_style);
    if (component_style.length > 0) {
      this.selectedComponentStyle = component_style[0];
    }
    else{
      this.selectedComponentStyle = null;
    }

    let container_style = this.componetStyle.filter(val => val.value === this.defnDetail.container_style);
    if (container_style.length > 0) {
      this.selectedContainerStyle = container_style[0];
    }
    else{
      this.selectedContainerStyle = null;
    }

    let action_hook = this.hooksItems.filter(val => val.code === this.defnDetail.action_hook);
    if (action_hook.length > 0) {
      this.selectedActionHook = action_hook[0];
    }
    else{
      this.selectedActionHook = null;
    }

    let validation_hook = this.hooksItems.filter(val => val.code === this.defnDetail.validation_hook);
    if (validation_hook.length > 0) {
      this.selectedValidationHook = validation_hook[0];
    }
    else{
      this.selectedValidationHook = null;
    }

    console.log("Data save", this.defnDetail);
    this.defnDetailDialog = true;

  }

  deleteDefnDetail(defnDetail: any) {
    this.confirmationServices.confirm({
      message: 'Are you sure you want to delete  : ' + defnDetail.id + '?',
      header: 'Confirm',
      icon: 'pi pi-exclamation-triangle',
       accept: () => {
        // this.defnDetail = this.defnDetail.filter(val => val.id !== defnDetail.id);
        // this.defnDetail = {};
        
        this.crudService.deleteRecordByID('ComponentDefnDetail',defnDetail.id).subscribe((data: any[]) => {
          this.messageService.add({ severity: 'success', summary: 'Successful', detail: 'Definition Detail Deleted', life: 1000 });
          this.defnDetail = this.defnDetail.filter(val => val.id !== defnDetail.id);
          
        });
      }
      
    });
    this.loadData();
  }

  findIndexById(id: string): number {
    let index = -1;
    for (let i = 0; i < this.defnDetailList.length; i++) {
      if (this.defnDetailList[i].id === id) {
        index = i;
        break;
      }
    }

    return index;
  }

  async saveRecord() {   

    this.submitted=true
    if(this.defnDetail.field_name && this.defnDetail.caption && this.defnDetail.helptext && this.selectedfieldtypes!=null && this.selecteddisplaytypes!=null && this.defnDetail.seq)
    {
            this.defnDetail.component_code=this.defnHead;
            if (this.selecteddisplaytypes && typeof this.selecteddisplaytypes === 'object') {
              console.log("display_type object");
              const displaytype1 = this.selecteddisplaytypes.id
              this.defnDetail.display_type = displaytype1;
            }

            if (this.selectedrequiretypes && typeof this.selectedrequiretypes === 'object') {
              console.log("required object");
              const isrequire1 = this.selectedrequiretypes.id
              this.defnDetail.required = isrequire1;
            }

            if (this.selectedfieldtypes && typeof this.selectedfieldtypes === 'object') {
              console.log("field_type object");
              const field_type1 = this.selectedfieldtypes.id
              this.defnDetail.field_type = field_type1;
            }

            if (this.selectedActionHook && typeof this.selectedActionHook === 'object') {
              const field_type1 = this.selectedActionHook.code
              this.defnDetail.action_hook = field_type1;
            }

            if (this.selectedValidationHook && typeof this.selectedValidationHook === 'object') {
              const field_type1 = this.selectedValidationHook.code
              this.defnDetail.validation_hook = field_type1;
            }

            if (this.selectedComponentStyle && typeof this.selectedComponentStyle === 'object') {
              const field_type1 = this.selectedComponentStyle.value
              this.defnDetail.component_style = field_type1;
            }
            
            if (this.selectedContainerStyle && typeof this.selectedContainerStyle === 'object') {
              const field_type1 = this.selectedContainerStyle.value
              this.defnDetail.container_style = field_type1;
            }

            if (this.isActive) {
              this.defnDetail.status = '1';
            }
            else {
              this.defnDetail.status = '2';
            }

            console.log("Input Data",this.defnDetail);
            if (this.defnDetail.id) {
              let response: any = await this.commonservice.saveRecord('ComponentDefnDetail', this.defnDetail);
              if (response.success == "1") {
                this.messageService.add({ severity: 'success', summary: 'Successful', detail: 'Definition Detail Created', life: 1000 });
                this.defnDetailDialog = false;
              }
              else {
                this.messageService.add({ severity: 'error', summary: 'Failed', detail: 'Failed', life: 1000 });
              }
            }
            else {
              console.log("Data else", this.defnDetail);
              let response: any = await this.commonservice.saveRecord('ComponentDefnDetail', this.defnDetail);
              if (response.success == "1") {
                this.messageService.add({ severity: 'success', summary: 'Successful', detail: 'Definition Detail Updated', life: 1000 });
                this.defnDetailDialog = false;
              }
              else {
                this.messageService.add({ severity: 'error', summary: 'Failed', detail: 'Failed', life: 1000 });
              }
            }
            this.loadData();
          }
          else
          {
            this.defnDetailDialog = true;
          }
    
  }

  hideDialog() {
    this.defnDetailDialog = false;
    this.submitted = false;
  }

  exportPdf() {
    import('jspdf').then(jsPDF => {
      import('jspdf-autotable').then(x => {
        /* const doc = new jsPDF.default(0,0);
        doc.autoTable(this.exportColumns, this.selectedDepots);
        doc.save('products.pdf'); */
      })
    })
  }

  exportExcel(selectedJob) {
    import('xlsx').then(xlsx => {
      const worksheet = xlsx.utils.json_to_sheet(this.selectedDefnDetail);
      const workbook = { Sheets: { data: worksheet }, SheetNames: ['data'] };
      const excelBuffer: any = xlsx.write(workbook, { bookType: 'xlsx', type: 'array' });
      this.saveAsExcelFile(excelBuffer, 'ComponentDefnDetail');
    });
  }

  saveAsExcelFile(buffer: any, fileName: string): void {
    import('file-saver').then(FileSaver => {
      const EXCEL_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
      const EXCEL_EXTENSION = '.xlsx';
      const data: Blob = new Blob([buffer], {
        type: EXCEL_TYPE
      });
      let timeStamp = this.datePipe.transform(new Date(), 'ddMMyy_HHmm');
      FileSaver.saveAs(data, fileName + '_' + timeStamp + EXCEL_EXTENSION);
    });
  }

  clear(table: Table) {
    table.clear();
  }
  closeDialog()
  {
    this.formBuilderDialog = false;
  }

  async exportRecord(event)
  {
      console.log("Input Fields",event);
      for (const w of event) {
        let inputData:any;
        inputData={
          id:w.id,
          component_code: this.defnHead,
          field_name: w.name,
          caption: w.label,
          display_type: w.display_type,
          helptext: w.helptext,
          field_length: w.field_length,
          seq: 1,
          required: w.required,
          field_type: w.type,
          component_style: w.cs_style,
          container_style: w.container_style,
          options: w.options,
          status: 1
        }
        console.log("Input Data",inputData);
        let response: any = await this.commonservice.saveRecord('ComponentDefnDetail', inputData);
       }
      this.formBuilderDialog = false;
      this.loadData();
  }

}
