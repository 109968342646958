import { Component, OnInit, Input } from '@angular/core';
import { ComponentsComunicationService } from '../../components-comunication.service';

@Component({
  selector: 'jobs-list-item',
  templateUrl: './jobs-list-item.component.html',
  styleUrls: ['./jobs-list-item.component.css']
})
export class JobsListItemComponent implements OnInit {

  isFilterByPersonActive: boolean = false;

  @Input()
  job: any

  constructor(private componentsComunicationService: ComponentsComunicationService) { }

  ngOnInit(): void {
  }

  onClickFilter(job, event) {
    //console.log("onClickFilter START. person is: ", job);
    this.isFilterByPersonActive = !this.isFilterByPersonActive;

    var eventFilterByPerson = {
      name: job.id,
      filter: this.isFilterByPersonActive
    };

    this.componentsComunicationService.changeData(eventFilterByPerson);
  }



}
