import { Component, OnInit } from '@angular/core';
import { CRUDService } from 'src/app/utilities/crud.service';
import { ConfirmationService } from 'primeng/api';
import { MessageService } from 'primeng/api';
import { Table } from 'primeng/table';
import { CommonService } from 'src/app/utilities/common.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-appconfig',
  templateUrl: './appconfig.component.html',
  styleUrls: ['./appconfig.component.css']
})
export class AppconfigComponent implements OnInit {
  loading: boolean = false;
  paginationvalue: any;
  cols: any[];
  exportColumns: any[];
  appConfigDialog: boolean = false;
  submitted: boolean;
  appconfigList: any[];
  jobPriority: any;
  appConfig: any;
  selectedJobPriority: any[];
  selectedAppConfig: any[];
  companyList: any[];
  isActive: boolean;
  selectedConfig: any;
  speedDial: boolean = false;
  isEditAll: boolean = false;
  exportdisplay: Boolean = false;
  submitFlag: Boolean = false;
  clearsearch: string;
  searchText
  searchvalue: any;


  constructor(private crudService: CRUDService,
    private messageService: MessageService,
    private confirmationService: ConfirmationService,
    private commonservice: CommonService) { }

  async ngOnInit(): Promise<void> {
    this.loading = true;
    this.paginationvalue = environment.paginatorValue;
    this.crudService.getAllData('AppConfig').subscribe((data: any[]) => {
      this.appconfigList = data;
    });

    this.crudService.getAllCompanies().subscribe((data: any[]) => {
      this.companyList = data;
    });

    this.cols = [
      { field: 'code', header: 'Code' },
      { field: 'description', header: 'Description' },
      { field: 'category', header: 'Category' },
      { field: 'config_value', header: 'Config value' },
      { field: 'status', header: 'Status' }
    ];

    this.exportColumns = this.cols.map(col => ({ title: col.header, dataKey: col.field }));
    this.exportdisplay = await this.commonservice.exportCheck();
    this.loading = false;
  }

  onKeypressEvent(event: any) {
    console.log(event.target.value);
    let searchh = event.target.value.toLowerCase()
    if (searchh == "a" || searchh == "ac" || searchh == 'active') {
      this.searchText = '1'
      this.searchvalue= event.target.value 
    }
    else if (searchh == "i" || searchh == "in" || searchh == "inactive") {
      this.searchText = '2',
      this.searchvalue= event.target.value
    }
    else{
      this.searchText = ''
    }

  }
  openNew() {
    this.appConfig = {
      id: null,
      created_by: null,
      created_at: null,
      updated_by: null,
      updated_at: null,
      code: null,
      description: null,
      category: null,
      config_value: null,
      status: null,

    };
    this.appConfigDialog = true;
  }
  addNewRecord(name) {
    if (!this.submitFlag) {
      this.appConfig = {
        id: null,
        created_by: null,
        created_at: null,
        updated_by: null,
        updated_at: null,
        code: null,
        description: null,
        category: null,
        config_value: null,
        status: null,
        isEdit: true
      };
      this.appconfigList = [this.appConfig, ...this.appconfigList];
    }
    this.submitFlag = true;
    name.focus();
  }

  editRecord(appConfig: any) {
    this.appConfig = { ...appConfig };
    if (this.appConfig.status === '' || this.appConfig.status === null || this.appConfig.status === '1') {
      this.appConfig.status = '1'
      this.isActive = true;
    } else {
      this.appConfig.status = '2'
      this.isActive = false;
    }

    this.appConfigDialog = true;
  }

  deleteSelectedJob() {
    // this.confirmationService.confirm({
    //   message: 'Are you sure you want to delete the selected Appconfig?',
    //   header: 'Confirm',
    //   icon: 'pi pi-exclamation-triangle',
    //   accept: () => {  
    //     for (let i = 0; i < this.selectedAppConfig.length; i++) {
    //       this.crudService.Delete(this.selectedAppConfig[i].id, 'AppConfig').subscribe((data: any[]) => { });
    //     }
    //     this.appconfigList = this.appconfigList.filter(val => !this.selectedAppConfig.includes(val));
    //     this.selectedAppConfig = null;
    //     this.messageService.add({ severity: 'success', summary: 'Successful', detail: 'Appconfig Deleted', life: 1000 });
    //   }
    // });
  }

  deleteJobPriority(appConfig: any) {
    // console.log("deleteJobPriority appConfig", appConfig);
    // this.confirmationService.confirm({
    //   message: 'Are you sure you want to delete Appconfig : ' + appConfig.code + '?',
    //   header: 'Confirm',
    //   icon: 'pi pi-exclamation-triangle',
    //   accept: () => {
    //     this.appconfigList = this.appconfigList.filter(val => val.id !== appConfig.id);
    //     this.appConfig = {};
    //     this.crudService.Delete(appConfig.id, 'AppConfig').subscribe((data: any[]) => {
    //       console.log("deleteresponse,", data);
    //       this.messageService.add({ severity: 'success', summary: 'Successful', detail: 'Appconfig Deleted', life: 1000 });
    //     });
    //   }
    // });
  }

  findIndexById(id: string): number {
    let index = -1;
    for (let i = 0; i < this.appconfigList.length; i++) {
      if (this.appconfigList[i].id === id) {
        index = i;
        break;
      }
    }
    return index;
  }

  saveRecord1() {
    let changedRecords = this.appconfigList.filter(val => (val.changed === true));
    
    changedRecords.forEach(element => {
      this.commonservice.saveRecord('AppConfig', element);
      element.changed = false;
    });
    this.isEditAll = false;
  }

  async saveRecord() {
    if (this.isActive) {
      this.appConfig.status = '1';
    } else {
      this.appConfig.status = '2';
    }
    if (this.appConfig.code.trim()) {
      if (this.appConfig?.id) {
        let response: any = await this.commonservice.saveRecord('AppConfig', this.appConfig);
        if (response.success == true) {
          this.messageService.add({ severity: 'success', summary: 'Successful', detail: 'AppConfig Updated', life: 1000 });
          this.appconfigList[this.findIndexById(this.appConfig.id)] = this.appConfig;
          this.appConfigDialog = false;
        }
        else {
          this.messageService.add({ severity: 'info', summary: 'Error', detail: 'Something went wrong', life: 1000 });
        }

      }
      else {
        let response: any = await this.commonservice.saveRecord('AppConfig', this.appConfig);
        if (response.success == true) {
          this.messageService.add({ severity: 'success', summary: 'Successful', detail: 'AppConfig Created', life: 1000 });
          this.appconfigList.push(this.appConfig);
          this.appConfigDialog = false;
        }
        else {
          this.messageService.add({ severity: 'info', summary: 'Error', detail: 'Something went wrong', life: 1000 });
        }
      }
      this.appConfig.id = this.selectedConfig.id;
      this.appConfigDialog = false;
    }
  }

  hideDialog() {
    this.appConfigDialog = false;
    this.submitted = false;
  }

  exportPdf() {
    import('jspdf').then(jsPDF => {
      import('jspdf-autotable').then(x => {
        /* const doc = new jsPDF.default(0,0);
        doc.autoTable(this.exportColumns, this.selectedDepots);
        doc.save('products.pdf'); */
      })
    })
  }

  exportExcel(selectedJob) {
    import('xlsx').then(xlsx => {
      const worksheet = xlsx.utils.json_to_sheet(this.selectedJobPriority);
      const workbook = { Sheets: { data: worksheet }, SheetNames: ['data'] };
      const excelBuffer: any = xlsx.write(workbook, { bookType: 'xlsx', type: 'array' });
      this.saveAsExcelFile(excelBuffer, 'JobPriority');
    });
  }

  saveAsExcelFile(buffer: any, fileName: string): void {
    import('file-saver').then(FileSaver => {
      const EXCEL_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
      const EXCEL_EXTENSION = '.xlsx';
      const data: Blob = new Blob([buffer], {
        type: EXCEL_TYPE
      });
      FileSaver.saveAs(data, fileName + '_export_' + new Date().getTime() + EXCEL_EXTENSION);
    });
  }

  clear(table: Table) {
    table.clear();
    this.clearsearch = ''

  }

}

