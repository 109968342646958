import { Injectable } from '@angular/core';
import { BehaviorSubject, Subject, Subscription, Observable } from 'rxjs';
import { CRUDService } from './utilities/crud.service';
import { DialogService } from 'primeng/dynamicdialog';

@Injectable({
  providedIn: 'root'
})
export class ComponentsComunicationService {
  private messageSource = new BehaviorSubject(Object);
  currentMessage = this.messageSource.asObservable();
  private messageSource1 = new BehaviorSubject(Object);
  currentNotificationMessage = this.messageSource1.asObservable();
  private messageSrc = new BehaviorSubject(Object);
  currentEvent = this.messageSrc.asObservable();
  subscription: Subscription;
  pushSubscriber: any;
  currentUser: any;
  sub: any;

  socket: any;
  constructor(private crudService: CRUDService, private dialogService: DialogService) {
    if(sessionStorage.getItem('loggedInUser')){
      this.currentUser = JSON.parse(sessionStorage.getItem('loggedInUser'));
      this.checkUnreadCount();
    }
  }

  dataToBePassed = new Subject<any>();
  dataToBePassedObs = this.dataToBePassed.asObservable();

  changeData(newData) {
    this.dataToBePassed.next(newData);
  }
  initializeSocket() {
    return this.socket;
  }
  changeMessage(message: any) {
    //console.log(message);
    this.messageSource.next(message)
  }
  changeNotificationMessage(message: any) {
    //console.log(message);
    this.messageSource1.next(message)
  }
  eventEmit(message: any) {
    //console.log(message);
    this.messageSrc.next(message)
  }

  emitEvent(user_id, message, url) {
    this.socket = this.initializeSocket();
    this.socket.emit('notification', { id: user_id, message });
  }

  async checkUnreadCount() {
    let userDetails = JSON.parse(sessionStorage.getItem('loggedInUser'));
    //const data: any = await this.crudService.getUnreadCount().toPromise();
    const data = [];
    const notificationCount: any = data.filter((val: any) => (val.user_id === userDetails?.id));
    if (notificationCount.length > 0) {
      await sessionStorage.setItem('notification_count', notificationCount[0].unread_count);
      await this.changeNotificationMessage('Notification Arrived')
    } else {
      await sessionStorage.setItem('notification_count', '0');
      await this.changeNotificationMessage('Notification Arrived')
    }
    this.socket?.on('getNotification', (data) => {
      /* let snackBarRef = this._snackBar.open(data.room.message, 'open', {
        horizontalPosition: 'end',
        verticalPosition: 'top',
        duration: 3000
      });
      snackBarRef.onAction().subscribe(() => {
        //console.log('The snackbar action was triggered!');
      }); */
    });

    this.sub = Observable.interval(100000 * 600)
      .subscribe((val) => {
        this.checkUnreadCount();
      });

    this.subscription = this.currentMessage.subscribe(async (message: any) => {
      this.currentUser = JSON.parse(sessionStorage.getItem('loggedInUser'));
      //console.log(this.currentUser);
      //console.log(message);
      if (this.currentUser != null) {
        //this.checkUnreadCount();
      }
    });
  }
}
