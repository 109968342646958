import { Component, OnInit } from '@angular/core';
import * as moment from 'moment';
import { DialogService, DynamicDialogRef } from 'primeng/dynamicdialog';
import { Subscription } from 'rxjs';
import { ActivatedRoute, Router } from '@angular/router';
import { MessageService } from 'primeng/api';
import { ComponentsComunicationService } from 'src/app/components-comunication.service';
import { CRUDService } from 'src/app/utilities/crud.service';
import { WorkFlowService } from 'src/app/utilities/workflow.service';
import { NotificationPermissionComponent } from '../notification-permission/notification-permission.component';
import { TaskDetailsComponent } from '../task-details/task-details.component';
import { JobAdminComponent } from '../../job/job-admin/job-admin.component';
import { JOBService } from 'src/app/utilities/job.service';
import { JobDetailsApprovalsComponent } from 'src/app/job/job-details-approvals/job-details-approvals.component';
@Component({
  selector: 'app-notification',
  templateUrl: './notification.component.html',
  styleUrls: ['./notification.component.css'],
  providers: [MessageService]
})
export class NotificationComponent implements OnInit {
  unreadMessages: any[] = []
  readMessages: any[] = []
  notifications: any[] = []
  currentUser: any;
  loading: Boolean = false;
  subscription: Subscription;
  menuData: any;
  constructor(private crudService: CRUDService,
    private JobService: JOBService, private workFlowService: WorkFlowService, 
    private dialogRef: DynamicDialogRef, 
    private dialogService: DialogService, 
    private router: Router,
    private messageService: MessageService,
    private componentsComunicationService: ComponentsComunicationService) {
  }
  async ngOnInit() {
    let user = sessionStorage.getItem('loggedInUser')
    this.currentUser = JSON.parse(user)
    this.getNotifications();
  }

  async getNotifications() {
    await this.crudService.getDataByField('AppNotifications', 'user_id', this.currentUser.id).subscribe((data: any[]) => {
      //console.log(data);
      this.notifications = data.filter((val: any) => (val.status !== '3'));
      //console.log(this.notifications);
      this.notifications?.map((notification: any) => {
        notification.created = moment(notification.created_at).fromNow();
      })
      this.unreadMessages = this.notifications.filter((val: any) => (val.read_status === '0'));
      //console.log(this.unreadMessages);
      this.readMessages = this.notifications.filter((val: any) => (val.read_status === '1'));
      //console.log(this.readMessages);
    });
  }
  // clearNotification(notification: any) {
  //   this.updateReadStatus(notification);
  // }
  openNotification(notification: any) {
    if (notification.ref_id !== null && notification.ref_type !== '') {
      this.updateReadStatus(notification);
      this.openModal(notification);
    }
  }
  openModal(notification: any) {
    console.log(notification);
    this.menuData = JSON.parse(sessionStorage.getItem('menuData'));
    if (notification.ref_type === 'TASK') {
       const ref: any = this.dialogService.open(TaskDetailsComponent, {
        data: {
          taskId: notification.ref_id,
          wf_id: '7',
          checkList: null,
          redirectMenu: this.menuData
        },
        header: '',
        width: '100%',
        height: '100%'
      })
      ref.onClose.subscribe((response: any) => {
        // this.app.openNotificationModal()
      });
    } else if (notification.ref_type === 'WORKFLOW') {
      this.dialogRef.close();
      this.jobWorkflow(notification);
      
    }
  }
  onClose() {
  }
  async updateReadStatus(notification: any) {
    notification.read_status = 1
    notification.read_on = new Date();
    notification.status = 2
    let response: any = await this.updateNotification(notification);
    //console.log(response);
    if (response.success) {
      await this.getNotifications();
      await this.componentsComunicationService.checkUnreadCount();
    }
  }
  async deleteNotification(notificartion: any, index) {
    this.unreadMessages.splice(index, 1)
    //console.log(this.unreadMessages)
    //console.log(notificartion);
    notificartion.status = 3;
    let response: any = await this.updateNotification(notificartion);
    this.getNotifications();
  }
  
  async updateNotification(json: any) {
    let response: any = await this.workFlowService.updateRecord('AppNotifications', json);
    return response;
  }

  async jobWorkflow(notification) {
    let data: any = await this.crudService.getDataByField('JobOperationsLiveQueryView', 'AllocationId', notification.ref_id).toPromise();
    let jobInfo = data[0];
    if (jobInfo.AllocationId !== '1') {
      let smryData: any = await this.crudService.getJobSummary(jobInfo.JobId, jobInfo.AllocationId).toPromise();
      let jobAllocationResults = await this.JobService.getJobInfo(smryData[0]);
      let JobStatus: any = 'Approve Quote';
      if (JobStatus === 'Cost Saved' || JobStatus === 'Approve Quote' || JobStatus === 'Await Cost' || JobStatus === 'Quote Rejected') {
        let viewType = 'Quote';
        const ref = this.dialogService.open(JobDetailsApprovalsComponent, {
          data: {
            jobId: jobInfo.JobId,
            allocId: jobInfo.AllocationId,
            jobSummary: jobAllocationResults,
            viewType: viewType,
            defaultTab: 'Schedule'
          },
          width: '100%',
          height: '100%'
        })
        ref.onClose.subscribe((response: any) => {
        });
      } else if (JobStatus === 'Approve Invoice' || JobStatus === 'Await Invoice' || JobStatus === 'Invoice Processed' || JobStatus === 'Accounts Proecessed' || JobStatus === 'Job Completed') {
        let viewType = 'Approval';
        const ref = this.dialogService.open(JobDetailsApprovalsComponent, {
          data: {
            jobId: jobInfo.JobId,
            allocId: jobInfo.AllocationId,
            jobSummary: jobAllocationResults,
            viewType: viewType,
            defaultTab: 'Schedule'
          },
          width: '100%',
          height: '100%'
        })
        ref.onClose.subscribe((response: any) => {
        });

      } else if (JobStatus === 'Await Acceptance' || JobStatus === 'Send Offer' || JobStatus === 'Quote Rejected') {
        const ref = this.dialogService.open(JobAdminComponent, {
          data: {
            jobId: jobInfo.JobId,
          },
          width: '100%',
          height: '100%'
        })
        ref.onClose.subscribe((response: any) => {
        });
      }
    } else {
      const ref = this.dialogService.open(JobAdminComponent, {
        data: {
          jobId: jobInfo.JobId,
        },
        width: '100%',
        height: '100%'
      })
      ref.onClose.subscribe((response: any) => {
      });
    }
  }
  onNoClick() {

  }
}
