import { Component, OnInit, Input } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ConfirmationService, MessageService } from 'primeng/api';
import { CRUDService } from 'src/app/utilities/crud.service';
import { WorkFlowService } from 'src/app/utilities/workflow.service';
import { DynamicDialogRef, DialogService, DynamicDialogConfig } from 'primeng/dynamicdialog';
import { JOBService } from 'src/app/utilities/job.service';
import { CommonService } from 'src/app/utilities/common.service';
import { TaskDetailsComponent } from '../task-details/task-details.component';
// import { exception } from 'console';


@Component({
  selector: 'app-workflowautomationexceptions',
  templateUrl: './workflowautomationexceptions.component.html',
  styleUrls: ['./workflowautomationexceptions.component.scss'],
  providers: [JOBService, WorkFlowService, DialogService]
})

export class WorkflowAutomationExceptionsComponent implements OnInit {

  categoryList = [
    { code: 'Offline', desc: 'Communications done offline.' },
    { code: 'Client On Hold', desc: 'Client Requested to put on hold.' },
    { code: 'Contractor On Hold', desc: 'Contractor Requested to put on hold.' },
    { code: 'Others', desc: 'Others' }
  ];

  selectedStep: any;
  stepsList: any[] = [];
  companyId: any;
  userDetails: any;
  timeSlots: any[];
  jobId: any;
  jobAllocId: any;
  step: any;
  isFlagging: Boolean = true;
  selectedAllocatees: any[] = [];
  selectedReason;
  filteredTimeSlots: any[];
  time: any;
  reason: any;
  remarks: any;
  followUpDT: Date;
  staffList: any[] = [];
  wfAutoException: any;
  exceptionID: any;
  JobStatus: any;
  allExceptionsInfo: any[] = [];
  menuData: any;
  showHistory: Boolean = false;
  appProps: any;
  loading: boolean = false;

  allStepsList = [
    { code: 'operations', desc: 'General' },
    { code: 'images', desc: 'Images' },
    { code: 'documents', desc: 'Documents' },
    { code: 'schedule', desc: 'Schedule & Communications' },
    { code: 'treeinfo', desc: 'Tree Info' },
    { code: 'costing', desc: 'Contractor Costing' },
    { code: 'wpcosting', desc: 'Client Costing' },
    { code: 'sca', desc: 'Sub Contractor Agreement' },
    { code: 'shf', desc: 'Site Hazard Form' },
    { code: 'kpi', desc: 'Key Performance Indicators' },
    { code: 'sendemail', desc: 'Send Email' }
  ];



  constructor(
    private route: ActivatedRoute,
    private crudService: CRUDService,
    private messageService: MessageService,
    private confirmationService: ConfirmationService,
    private router: Router,
    private dialogService: DialogService,
    private JobService: JOBService,
    private wfService: WorkFlowService,
    public config: DynamicDialogConfig,
    public ref: DynamicDialogRef,
    private commonservice: CommonService) {
    this.companyId = sessionStorage.getItem('company_id');
    this.userDetails = JSON.parse(sessionStorage.getItem('loggedInUser'));
    this.timeSlots = ['6:00am', '6:30am', '7:00am', '7:30am', '8:00am', '8:30am', '9:00am', '9:30am', '10:00am', '10:30am', '11:00am', '11:30am',
      '12:00pm', '12:30pm', '1:00pm', '1:30pm', '2:00pm', '2:30pm', '3:00pm', '3:30pm', '4:00pm', '4:30pm', '5:00pm', '5:30pm', '6:00pm', '6:30pm'];
    //'12:00am', '12:30am', '1:00am', '1:30am', '2:00am', '2:30am', '3:00am', '3:30am', '4:00am', '4:30am', '5:00am', '5:30am','7:00pm', '7:30pm', '8:00pm', '8:30pm', '9:00pm', '9:30pm', '10:00pm', '10:30pm', '11:00pm', '11:30pm'
    this.filteredTimeSlots = this.timeSlots;
  }

  async ngOnInit() {
    this.appProps = this.commonservice.getAppProperties();
    this.jobId = this.config.data.jobId;
    this.jobAllocId = this.config.data.allocId;
    this.step = this.config.data.step;
    this.JobStatus = this.config.data.job_status;
    this.menuData = sessionStorage.getItem('menuData');
    /*     this.wfAutoException = {
          id: null,
          job_id: this.jobId,
          job_alloc_id: this.jobAllocId,
          stage: this.step,
          pause_category: null,
          pause_remarks: null,
          pause_till: null,
          created_by: this.userDetails.id,
          created_at: new Date(),
          updated_by: null,
          updated_at: null,
        };
     */
    let data: any = await this.crudService.getAllSSPUsers(this.companyId).toPromise();
    this.staffList = data;

    if (this.JobStatus === 'Cost Saved' || this.JobStatus === 'Approve Quote' || this.JobStatus === 'Await Cost' || this.JobStatus === 'Quote Rejected') {
      this.stepsList = [
        { code: 'operations', desc: 'General' },
        { code: 'images', desc: 'Images' },
        { code: 'documents', desc: 'Documents' },
        { code: 'schedule', desc: 'Schedule & Communications' },
        { code: 'treeinfo', desc: 'Tree Info' },
        { code: 'costing', desc: 'Contractor Costing' },
        { code: 'wpcosting', desc: 'Client Costing' },
        { code: 'sendemail', desc: 'Send Email' }
      ]
    } else if (this.JobStatus === 'Approve Invoice' || this.JobStatus === 'Await Invoice' || this.JobStatus === 'Invoice Processed' || this.JobStatus === 'Accounts Proecessed' || this.JobStatus === 'Job Completed') {
      this.stepsList = [
        { code: 'operations', desc: 'General' },
        { code: 'images', desc: 'Images' },
        { code: 'documents', desc: 'Documents' },
        { code: 'schedule', desc: 'Schedule & Communications' },
        { code: 'treeinfo', desc: 'Tree Info' },
        { code: 'costing', desc: 'Contractor Costing' },
        { code: 'wpcosting', desc: 'Client Costing' },
        { code: 'sca', desc: 'Sub Contractor Agreement' },
        { code: 'shf', desc: 'Site Hazard Form' },
        { code: 'kpi', desc: 'Key Performance Indicators' },
        { code: 'sendemail', desc: 'Send Email' }
      ]
    } else {
      this.stepsList = [
        { code: 'operations', desc: 'General' },
        { code: 'admin', desc: 'Job Info' }
      ]
    }

    this.loadExceptionList();
    if (this.step !== null && this.step !== '' && this.step !== 'undefined') {
      this.selectedStep = this.allStepsList.find(val => (val.code === this.step));
    }
  }

  loadExceptionList() {
    this.loading = true;
    this.crudService.getDataByField('workflowautomationexceptions', 'job_alloc_id', this.jobAllocId).subscribe((exceptionsData: any) => {
      exceptionsData.forEach(exception => {
        if (exception.stage !== null && exception.stage !== '') {
          exception.selectedStage = this.allStepsList.filter(val => (val.code === exception.stage));
        } else {
          exception.selectedStage = { code: '', desc: '' };
        }
        if (exception.pause_category !== null && exception.pause_category !== '') {
          exception.selectedCategory = this.categoryList.find(val => (val.code === exception.pause_category));
        } else {
          exception.selectedCategory = { code: '', desc: '' };
        }
        let dateTime = new Date(exception.pause_till);
        const localDate = new Date(dateTime.getFullYear(), dateTime.getMonth(), dateTime.getDate());
        exception.date = localDate;
        let time: any;
        if (dateTime.getHours() > 12) {
          time = (dateTime.getHours() - 12) + ':' + ('00' + dateTime.getMinutes()).slice(-2) + 'pm';
        } else {
          time = dateTime.getHours() + ':' + ('00' + dateTime.getMinutes()).slice(-2) + 'am';
        }
        exception.time = time;
        exception.changed = false;
      });
      this.allExceptionsInfo = exceptionsData;
    });this.loading = false;
  }

  removeUser(user) {
    this.selectedAllocatees = this.selectedAllocatees.filter(val => val.user_id !== user.user_id);
  }

  search(event) {
    this.filteredTimeSlots = this.timeSlots.filter((val: any) => val.includes(event.query));
  }

  async closeDialog() {
    this.ref.close({ success: 3 });
  }

  async updateWFAExceptionStatus(wfAutoException) {
    let desc: any = null;
    // wfAutoException.pause_remarks = null
    // wfAutoException.pause_till = null;
    if(wfAutoException.id == undefined || wfAutoException.id == null) {
      wfAutoException.id = null; 
    }
    wfAutoException.job_id = this.jobId;
    wfAutoException.job_alloc_id = this.jobAllocId;
    wfAutoException.pause_category = wfAutoException.selectedCategory.code;
    wfAutoException.stage = { code: 'operations', desc: 'General' };
    /* wfAutoException.stage = null;
    //console.log(this.selectedStep);
    if (typeof this.selectedStep !== 'undefined') {
      if (this.selectedStep !== null && this.selectedStep !== '') {
        wfAutoException.stage= this.selectedStep.code;
      }
    } else {
      if (this.step !== null && this.step !== '') {
        wfAutoException.stage = this.step;
      }
    } */

    if (typeof wfAutoException.selectedReason !== 'undefined') {
      if (wfAutoException.selectedReason !== null && wfAutoException.selectedReason !== '') {
        wfAutoException.pause_category = wfAutoException.selectedReason.code;
        desc = 'Reason : ' + wfAutoException.selectedReason.desc;
      }
    }

    let followup_dateTime: any;
    // if (typeof this.followUpDT !== 'undefined') {
    //   if (this.followUpDT !== null && this.time !== '' && this.time !== null) {
      if (typeof wfAutoException.date !== 'undefined' && wfAutoException.time !== 'undefined') {
        if (wfAutoException.date !== null && wfAutoException.time !== '' && wfAutoException.time !== null) {
          const dateFromPicker = wfAutoException.date.toLocaleString().replace(', 12:00:00 AM', '');
        const timeFromPicker = wfAutoException.time;
        const dateParts: any[] = dateFromPicker.split("/");
        const timeParts: any[] = timeFromPicker.split(":");
        let minutes: any;
        let hours: any;
        if (timeParts[1].includes('pm')) {
          hours = parseInt(timeParts[0]) + 12;
          minutes = timeParts[1].replace('pm', '');
        } else {
          hours = parseInt(timeParts[0]);
          minutes = timeParts[1].replace('am', '');

        }
        const localDate = new Date(dateParts[2], dateParts[0] - 1, dateParts[1], hours, minutes);
        followup_dateTime = localDate;
        wfAutoException.pause_till = followup_dateTime;
      }
    } else {
      wfAutoException.pause_till = null;
    }

    let res: any = await this.JobService.updatewfAutoException(wfAutoException);
    if (res.success === 1 || res.success === '1') {
      // if (res.id) {
        wfAutoException.id = res.id;
        this.messageService.add({ severity: 'success', summary: 'Successful', detail: 'Status Updated', life: 1000 });
        this.closeDialog();
      // }
    }
  }


  loadTaskDetails(task_id) {
    const ref = this.dialogService.open(TaskDetailsComponent, {
      data: {
        wf_id: '7',
        task_id: task_id,
        checkList: null,
        redirectMenu: this.menuData
      },
      header: '',
      width: '100%',
      height: '100%'
    })

    ref.onClose.subscribe((taskdetails: any) => {
    });
  }

  editRecord(exception) {
    this.wfAutoException = exception;
  }

  saveRecords(){
    this.allExceptionsInfo.forEach(exception => {
      if(exception.changed){
        this.updateWFAExceptionStatus(exception);
      }
    });
    this.loadExceptionList();
  }
  deleteRecord(exception) {
    //console.log(exception);
    this.confirmationService.confirm({
      message: 'Are you sure you want to delete the selected Records?',
      header: 'Confirm',
      icon: 'pi pi-exclamation-triangle',
      accept: async () => {
        let response: any = await this.crudService.Delete(exception.id, 'workflowautomationexceptions').toPromise();
        this.loadExceptionList();
        if (response.success === 1) {
          this.messageService.add({ severity: 'success', summary: 'Successful', detail: 'Records Deleted', life: 1000 });
        }
      }
    })
  }


  addNew() {
    let wfAutoException = {
      id: null,
      job_id: this.jobId,
      job_alloc_id: this.jobAllocId,
      selectedStage: { code: 'operations', desc: 'General' },
      stage: 'operations',
      pause_category: null,
      pause_remarks: null,
      pause_till: null,
      date: null,
      time: null,
      changed: true,
      created_by: this.userDetails.id,
      created_at: new Date(),
      updated_by: null,
      updated_at: null,
    };
    this.allExceptionsInfo.push(wfAutoException);
  }
}