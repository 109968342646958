import { Component, OnInit } from '@angular/core';
import { environment } from 'src/environments/environment';
import { CRUDService } from 'src/app/utilities/crud.service';
import { CommonService } from 'src/app/utilities/common.service';
import { ConfirmationService, MessageService } from 'primeng/api';
import { Table } from 'primeng/table';
import { element } from 'protractor';
import { async } from '@angular/core/testing';
import { DynamicDialogRef, DialogService } from 'primeng/dynamicdialog';
import { MenuPermissionsComponent } from '../menu-permissions/menu-permissions.component';



@Component({
  selector: 'app-menu',
  templateUrl: './menu.component.html',
  styleUrls: ['./menu.component.scss']
})
export class MenuComponent implements OnInit {
  commonFlag: Boolean = false;
  clearsearch: any = ''
  paginationvalue: any;
  companyId: any;
  menuList: any[];
  menuidlist: any[];
  menuParentList: any[];
  parentMenus: any[];
  cols: any[];
  exportColumns: any[];
  exportdisplay: Boolean = true;
  displayDelete: Boolean = true;
  isEditAll: boolean = false;
  submitFlag: Boolean = false;
  userDetails: any;
  selectedMenu: any;
  menuDialog: any;
  temp_menulist: any;
  maxId: any;

  constructor(private crudService: CRUDService, private commonService: CommonService, private messageService: MessageService, 
    private confirmationService: ConfirmationService,private dialogService: DialogService,) { }

  async ngOnInit(): Promise<void> {
    this.userDetails = JSON.parse(sessionStorage.getItem('loggedInUser'));
    this.paginationvalue = environment.paginatorValue;
    this.companyId = sessionStorage.getItem('company_id');
    this.cols = [
      { field: 'display_name', header: 'Menu Name' },
      { field: 'description', header: 'Menu Description' },
      { field: 'menu_type', header: 'Menu type' },
      { field: 'parent_menu', header: 'Parent Menu' },
      { field: 'para_1', header: 'Menu Para 1' },
      { field: 'para_2', header: 'Menu Para 2' },
      { field: 'router_url', header: 'router_url' },
      { field: 'status', header: 'Status' }
    ];
    /* { field: 'process_id', header: 'Menu Process Id' }, */

    this.exportColumns = this.cols.map(col => ({ title: col.header, dataKey: col.field }));
    this.exportdisplay = await this.commonService.exportCheck();
    this.displayDelete = await this.commonService.exportCheck();
    this.temp_menulist = await this.crudService.getAllData('Menus').toPromise();
  await  this.temp_menulist.push({ id:"0",display_name:"Parent Menu"},{ id:null,display_name:"NA"})
    this.loadMenuData();
  }


  async loadMenuData() {
    let data: any = await this.crudService.getAllData('Menus').toPromise();
    console.log('all menu data', data)
    this.menuList = data;
    this.menuList.forEach(menu => {
      if (menu.menu_parent_id == '0') {
        menu.selectedparentmenu = { id:"0",display_name:"Parent Menu"}
        menu.parent_display_name = "Parent Menu";

      } else if (menu.menu_parent_id !== '' && menu.menu_parent_id !== null) {
        let parentMenu = this.temp_menulist.find(val => (val.id == menu.menu_parent_id));
        if (typeof parentMenu !== 'undefined') {
          menu.selectedparentmenu = parentMenu
          menu.parent_display_name = parentMenu.display_name;
        } else {
          menu.selectedparentmenu = {}
          menu.parent_display_name = 'Not Defined';
        }
      } else {
        menu.selectedparentmenu = { id:null,display_name:"NA"}
        menu.parent_display_name = "NA";
      }
      if(menu.status == '1'){
        menu.status_desc = 'Active';
      }else{
        menu.status_desc = 'Inctive';
      }
    });
  }

  addNewRecord(name) {
    this.maxId = Math.max(...this.menuList.map(o => o.menu_order_id), 0);
    if (!this.submitFlag) {
      let menu = {
        created_at: null,
        created_by: null,
        description: null,
        display_name: null,
        icon: null,
        last_modified_by: null,
        last_updated_at: null,
        menu_parent_id: "0",
        menu_type: "1",
        menu_order_id: this.maxId+1,
        para_1: null,
        para_2: null,
        process_id: null,
        router_url: null,
        status: '1',
        isEdit: true,
        selectedparentmenu: {id: "0", display_name: "Parent Menu"}
      };
      this.menuList = [menu, ...this.menuList];
    }
    this.submitFlag = true;
    name.focus();
  }


  async saveRecord() {
    debugger
    let changedRecords = this.menuList.filter(val => (val.changed === true));
    for (const player of changedRecords) {
      await this.saveRecordNew(player);
      this.temp_menulist = await this.crudService.getAllData('Menus').toPromise();
      await  this.temp_menulist.push({ id:"0",display_name:"Parent Menu"},{ id:null,display_name:"NA"})
    }
  }
     
  async saveRecordNew(element){
    try{
      element. menu_parent_id = element.selectedparentmenu.id
       element.process_id = Number( element.process_id)
       element.menu_parent_id = Number( element.menu_parent_id)
        let data: any = await this.commonService.saveRecord('menus', element).then();  
        if(data.success == '1'){
                     debugger
                     this.messageService.add({ severity: 'success', summary: 'Successful', detail:' Record Saved', life: 1000 });
                     element.id =data.data[0].id 
                     element.changed = false;
                     element. isEdit=false;
                     this.submitFlag = false;
                   
                   }else{
                     this.messageService.add({ severity: 'error', summary: 'Error', detail: ' Record Not Updated, Please Enter Required Values', life: 1000 });
                     element.changed = false;
                     element. isEdit=true
                   }
        return data;        
    }
    catch{
    }    
    }

  clear(table: Table) {
    table.clear();
    this.clearsearch = '';
  }

  viewDetails(menu) {
    const ref = this.dialogService.open(MenuPermissionsComponent, {
      data: menu,
      width: '100%',
      height: '100%'
    });
    ref.onClose.subscribe(async (response: any) => {
      if (response.changed) {
        this.loadMenuData();
      }
    });
  }

  deleteSelectedMenus(){
    this.selectedMenu.forEach(menu => {
      this.deleteMenu(menu);
    });
  }

  deleteMenu(menu){
    debugger
    if(!menu.id){
      this.menuList = this.menuList.filter(val => val !== menu);
      this.submitFlag = false;
    }
    else{
    this.confirmationService.confirm({
      message: 'Are you sure you want to delete: ' + menu.display_name + ' ?',
      header: 'Confirm',
      icon: 'pi pi-exclamation-triangle',
      accept: () => {
         this.confirmationService.close();
         this.crudService.deleteRecordByID('menus', menu.id).subscribe((data: any) => {
          console.log("dataaaa",data);
          
          if (data.success == 1) {
            this.messageService.add({ severity: 'success', summary: 'Successful', detail: 'Deleted successfully', life: 3500 });
            this.menuList = this.menuList.filter(val => val.id !== menu.id);
            // this.rateset = {};
            // this.disableNew=false;
          }
          else{
            this.messageService.add({ severity: 'error', summary: 'Error', detail:'Not Deleted,Something Went Wrong!', life: 3500 });
          }
        });
      },
      reject:  async () => {
        this.confirmationService.close();
      }
    });
  }
}
onmenuChange(event,menu){
  menu.changed=true
}

}
