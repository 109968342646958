import { Component, OnInit,Input } from '@angular/core';
import * as moment from 'moment';
import { DialogService, DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { CRUDService } from 'src/app/utilities/crud.service';
import { JOBService } from 'src/app/utilities/job.service';

@Component({
  selector: 'app-workflow-timeline',
  templateUrl: './workflow-timeline.component.html',
  styleUrls: ['./workflow-timeline.component.scss']
})
export class WorkflowTimelineComponent implements OnInit {

  constructor(
    private crudService: CRUDService,
    public ref: DynamicDialogRef,
    public JobService: JOBService,
    public config: DynamicDialogConfig,
    public dialogService: DialogService,
  ) { }
  @Input() JobId :any;
  @Input() allocationId :any;
  availableItems: any;
  jobWorkflow: any;
  jobWorkflowSteps: any;
  jobWorkflowTriggers: any;
  initialItems: any = [];
  stepsItems: any;
  hooksItems: any;
  stepsData: any;
  jobWorkflowStatus:any;
  jobWorkflowEditStatus:Boolean = false;
  loading : Boolean = false;
  diffhours : any;
  showHeader : Boolean = false
  jobSummary : any;
  checkListData : any;
  checkListItem : any;
  totalChecklist : any;
  inActivecheckList : any;
  taskPercent : any;

  async ngOnInit() {
    this.loading = true;
    if (this.config.data) {
      this.JobId = this.config.data.jobId;
      if(this.config.data.allocationId)
      {
        this.allocationId = this.config.data.allocationId;
      }
      else
      {
        this.allocationId = 1;
      }
    }

      this.crudService.getAllData('WFSteps').subscribe((data: any[]) => {
        this.stepsItems = data;
      });
      this.crudService.getAllData('hooks').subscribe((hdata: any[]) => {
        this.hooksItems = hdata;
      });
      if(this.allocationId != '1' && this.allocationId != null)
      {
        const smryData: any = await this.crudService.getJobSummary(this.JobId,this.allocationId).toPromise();
        this.jobSummary = await this.JobService.getJobInfo(smryData[0]);
        this.showHeader = true;
      }
      else
      {
        this.showHeader = false;
      }
      this.loadData();
      

    this.loading = false;
  }

  loadData()
  {
   
    this.crudService.getDataByField('JobWorkflow', 'job_id', this.JobId).subscribe((jobWorkflowData: any[]) => {
      this.loading = true;
      this.jobWorkflow = jobWorkflowData.filter(val => (val.job_alloc_id == this.allocationId));
      let wf_id = this.jobWorkflow[0].id;
      this.jobWorkflowStatus=this.jobWorkflow[0].status;
      this.crudService.getDataByField('JobWorkflowSteps', 'wf_id', wf_id).subscribe((jobWorkflowStepsData: any[]) => {

        this.jobWorkflowSteps = jobWorkflowStepsData.sort((a, b) => a.seq.localeCompare(b.seq));
    
        this.jobWorkflowSteps.forEach(async (ws, wsIndex) => {

          this.crudService.getDataByField('JobWorkflowTriggers', 'ws_id', ws.id).subscribe((jobWorkflowTriggersData: any[]) => {
            this.jobWorkflowTriggers = jobWorkflowTriggersData.sort((a, b) => a.seq.localeCompare(b.seq));
            // console.log("Steps",ws);
            let stepName = this.showStepCode(this.jobWorkflowSteps[wsIndex].step);
            if(ws.act_time)
            {
              var a = moment(ws.exp_time);//now
              var b = moment(ws.act_time);
              this.diffhours = a.diff(b, 'hours');
            }
            else
            {
              this.diffhours = 0;
            }
           

            this.stepsData =
            {
              code: ws.step,
              name: stepName,
              seq : ws.seq,
              exp_time: ws.exp_time,
              act_time: ws.act_time,
              status : ws.status,
              diffhours : this.diffhours,
              hooks: []
            }
            this.jobWorkflowTriggers.forEach(async (hk, hkIndex) => {
              let HookName = this.showHookCode(this.jobWorkflowTriggers[hkIndex].hook);
              let hooksData = {
                hook: hk.hook,
                description: HookName,
                type: hk.check_point
              }
              this.stepsData.hooks.push(hooksData);
            });
            const FailList = this.stepsData.hooks.filter(val => (val.type == 'Fail'));
            const successList = this.stepsData.hooks.filter(val => (val.type == 'Success'));
            Object.assign(this.stepsData, { fail_length: FailList.length, success_length: successList.length });
            this.initialItems.push(this.stepsData);
            if (this.initialItems.length > 0) {
              this.availableItems = this.initialItems.sort((a, b) => a.seq.localeCompare(b.seq));
              this.jobWorkflowEditStatus = true;
            }
            this.loading = false;
            });
            
        });
        this.loading = false;
      });
    });

    this.crudService.getDataByField('JobAllocationChecklist', 'job_id', this.JobId).subscribe(async (data:any[]) => {
      this.checkListData = data.sort((a, b) => a.id.localeCompare(b.id));
      if(this.checkListData.length>0)
      {
          this.checkListData = this.checkListData.filter(val => val.job_alloc_id === this.allocationId);
          if(this.checkListData.length>0)
          {
            this.checkListData.forEach(item => {
              if (item.status == '2') {
                item.isChecked = true;
              } else {
                item.isChecked = false;
              }
            });
            this.convertStringToDate(this.checkListData);
            this.totalChecklist = this.checkListData.length
            this.inActivecheckList = this.checkListData.filter(val => val.status === '2').length;
            this.taskPercent = this.numberRoundDecimal((this.inActivecheckList*100)/this.totalChecklist,0);
            
          }
        }
    });
  }

  convertStringToDate(list){
    if(list && list.length > 0) {
      list.forEach(l => {
        if(l.completed_at) {
          l.completed_at = new Date(l.completed_at);
        }
      });
    }
  }

  
  numberRoundDecimal(v,n) {
    if(v == "NaN")
    {
      return "0";
    }
    else
    {
      return Math.round((v+Number.EPSILON)*Math.pow(10,n))/Math.pow(10,n)
    }
  }

  showHookCode(hook) {
    let outValue = '';
    for (let j = 0; j < this.hooksItems?.length; j++) {
      if (hook === this.hooksItems[j].code) {
        outValue = this.hooksItems[j].description;
      }
    }
    return outValue;
  }

  showStepCode(step) {
    let outValue = '';
    for (let j = 0; j < this.stepsItems?.length; j++) {
      if (step === this.stepsItems[j].code) {
        outValue = this.stepsItems[j].name;
      }
    }
    return outValue;
  }

}
