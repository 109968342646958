import { Component, Input } from '@angular/core';
import { FormControl, FormGroup, Validators,FormBuilder } from '@angular/forms';

// text,email,tel,textarea,password, 
@Component({
    selector: 'phonenumber',
    template: `  
      <div [formGroup]="form">
        <input *ngIf="!field.multiline" [attr.type]="field.type" class="form-control" pattern="[7-9]{1}[0-9]{9}"
         [id]="field.name" [name]="field.name" [formControlName]="field.name" [attr.required]="field.required">     
      </div> 
      <div class="alert alert-danger my-1 p-2 fadeInDown animated" *ngIf="!isValid && isDirty" > Enter correct {{field.label}} </div>
      `
})
export class PhoneNumberComponent {
    @Input() field:any = {};
    @Input() form:FormGroup;
    get isValid() { return this.form.controls[this.field.name].valid; }
    get isDirty() { return this.form.controls[this.field.name].dirty; }
    // get isValid() {
    //     return  this.form.controls[this.field.name].setValidators.length.valueOf();       
    //     }
     
    
    
    constructor(private formBuilder: FormBuilder) {

        // const emailEl = document.querySelector('#email');
        // let isEmailValid = (email) => {
        //     const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        //     return re.test(email);
        // };

    }
    ngOnInit() {

       
    }
    
}