import { Component, OnInit } from '@angular/core';
import { CRUDService } from 'src/app/utilities/crud.service';
import { ConfirmationService } from 'primeng/api';
import { MessageService } from 'primeng/api';
import { Table } from 'primeng/table';
import { CommonService } from 'src/app/utilities/common.service';
import { environment } from 'src/environments/environment';
import { DatePipe } from '@angular/common';
import { log } from 'console';
import { element } from 'protractor';

@Component({
  selector: 'app-state',
  templateUrl: './state.component.html',
  styleUrls: ['./state.component.scss'],
  providers:[DatePipe]
})
export class StateComponent implements OnInit {

  loading: boolean = false;
  paginationvalue: any;
  cols: any[];
  exportColumns: any[];
  StateDialog: boolean = false;
  submitted: boolean;
  jobPriorityList: any[];
  jobPriority: any;
  priority: any[];
  reformattedArray: any[];
  selectedState: any[];
  countryList: any[];
  isActive: boolean;
  selectedCountry: any;
  speedDial: boolean = false;
  isEditAll: boolean = false;
  exportdisplay: Boolean = false;
  stateList: any[];
  State: any;
  Status = [
    {label: 'Active', value: "1"},
    {label: 'IN-Active', value: "2"}, 
]

  constructor(private crudService: CRUDService,private datePipe: DatePipe,
    private messageService: MessageService,
    private confirmationService: ConfirmationService,
    private commonservice: CommonService) { }

  async ngOnInit(): Promise<void> {
    this.loading = true;
    this.priority = [];
    this.paginationvalue = environment.paginatorValue;

   await this.crudService.getAllData('Country').subscribe((data: any[]) => {
      this.countryList = data;
    });

    this.crudService.getAllData('State').subscribe(async (data: any[]) => {
      this.stateList = data;
   await   this.stateList.forEach(value => {
      this.countryList.forEach(element =>{
          if(value.country_id == element.id){
            value.country_name = element.name
          }
        })
      });
    });


    this.cols = [
      { field: 'name', header: 'State' },
      { field: 'state_full_name', header: 'State Full Name' },
      { field: 'country_id', header: 'Country Id' },
      { field: 'status', header: 'Status' },
    ];
    this.countryList = [
      { field: 'name', header: 'State' },
      { field: 'state_full_name', header: 'State Full Name' },
      { field: 'country_id', header: 'Country Id' },
      { field: 'status', header: 'Status' },
    ];

    this.exportColumns = this.cols.map(col => ({ title: col.header, dataKey: col.field }));
    this.exportdisplay = await this.commonservice.exportCheck();
    this.loading = false;
  }


  openNew() {
    this.State = {
      id:null,
      name: null,
      state_cr_dt: null,
      state_cr_uid: null,
      country_id: null,
      owner: null,
      created_by: null,
      last_modified_by: null,
      created_at: null,
      last_updated_at: null,
      status: null,
      state_full_name: null
    };
    // this.selectedCountry = ''
    this.StateDialog = true;
  }

  async editRecord(State: any) {
    this.State = { ...State };
    if (this.State.status === '' || this.State.status === null || this.State.status === '1') {
      this.State.status = '1'
      this.isActive = true;
    } else {
      this.State.status = '2'
      this.isActive = false;
    }
    let current_country =await this.countryList.filter(val => val.id == State.country_id);
    this.selectedCountry = current_country[0]
    //console.log(this.jobPriority.company_id);
    // let res: any = await this.commonservice.updateRecord('State', this.State);
    // console.log("res",res);
    // debugger
    // this.selectedCountry = res[0];
    this.StateDialog = true;
  }

  // deleteSelectedJob() {
  //   this.confirmationService.confirm({
  //     message: 'Are you sure you want to delete the selected state ?',
  //     header: 'Confirm',
  //     icon: 'pi pi-exclamation-triangle',
  //     accept: () => {
  //       // tslint:disable-next-line: prefer-for-of
  //       for (let i = 0; i < this.selectedState.length; i++) {
  //         this.crudService.Delete(this.selectedState[i].id, 'JobPriority').subscribe((data: any[]) => { });
  //       }
  //       this.jobPriorityList = this.jobPriorityList.filter(val => !this.selectedState.includes(val));
  //       this.selectedState = null;
  //       this.messageService.add({ severity: 'success', summary: 'Successful', detail: 'Job Priority Deleted', life: 1000 });
  //     }
  //   });
  // }

  deleteState(State: any) {
    this.confirmationService.confirm({
      message: 'Are you sure you want to delete : ' + State.name + '?',
      header: 'Confirm',
      icon: 'pi pi-exclamation-triangle',
      accept: () => {
        this.crudService.Delete(State.id, 'State').subscribe((data: any) => {
          if(data.success ==1){
            this.stateList = this.stateList.filter(val => val.id !== State.id);
            this.State = {};
            this.messageService.add({ severity: 'success', summary: 'Successful', detail: 'State Deleted', life: 1000 });
          }
          else{
            this.messageService.add({ severity: 'error', summary: 'Error', detail: 'State is not Deleted', life: 1000 });
          }
        });
      }
    });
  }

  findIndexById(id: string): number {
    let index = -1;
    for (let i = 0; i < this.stateList.length; i++) {
      if (this.stateList[i].id === id) {
        index = i;
        break;
      }
    }
    return index;
  }

  async saveRecord() {
    if (this.isActive) {
      this.State.status = '1';
    } else {
      this.State.status = '2';
    }
    this.State.country_id = this.selectedCountry.id;
    this.State.country_name = this.selectedCountry.name;
      if (this.State.id) {
          this.crudService.UpdateState(this.State).subscribe((data: any) => {
          if(data.success == 1){
            this.messageService.add({ severity: 'success', summary: 'Successful', detail: 'State Updated', life: 1000 });
            this.stateList[this.findIndexById(this.State.id)] = this.State;
          }
          else{
            this.messageService.add({ severity: 'error', summary: 'Error', detail: 'State is not Updated', life: 1000 });
          }
          //this.ngOnInit()
        });
      }
      else {
        await this.crudService.getStateMaxId().subscribe((data:any[])=>{
          if(data){
            this.State.id = Object.values(data[0])[0];
            this.crudService.CreateState(this.State).subscribe((data: any) => {
              console.log("data",data);
              if(data.success == 1){
                this.messageService.add({ severity: 'success', summary: 'Successful', detail: 'State Updated', life: 1000 });
                this.ngOnInit()
              }
              else{
                this.messageService.add({ severity: 'error', summary: 'Error', detail: 'State is Saved', life: 1000 });
              }   
              //  this.stateList[this.findIndexById(this.State.id)] = this.State;
             
            });
          }
       
        })

        // let res: any =  this.commonservice.saveRecord('State', this.State);
        // console.log("res",res);

    
      }
      //console.log(this.jobPriority);
      this.StateDialog = false;
 
  }

  hideDialog() {
    this.StateDialog = false;
    this.submitted = false;
  }

  exportPdf() {
    import('jspdf').then(jsPDF => {
      import('jspdf-autotable').then(x => {
        /* const doc = new jsPDF.default(0,0);
        doc.autoTable(this.exportColumns, this.selectedDepots);
        doc.save('products.pdf'); */
      })
    })
  }

  exportExcel(selectedJob, table) {
    if (selectedJob && selectedJob.length !== 0) {
      this.reformattedArray = this.selectedState.map(obj => {
        let rObj = {};
        this.cols.forEach(pair => {
          rObj[pair.header.toString()] = obj[pair.field];
        });
        return rObj;
      })

    } else {
      let JsonData: any[] = [];
      if (this.priority != null && this.priority.length > 0) {
        JsonData = this.priority;
      } else {

        if (typeof table.filteredValue !== 'undefined') {
          if (table.filteredValue.length !== this.stateList.length && table.filteredValue.length > 0) {
            JsonData = table.filteredValue;
          } else {
            JsonData = this.stateList;
          }
        } else {
          JsonData = this.stateList;
        }
      }
      this.reformattedArray = JsonData.map(obj => {
        let rObj = {};
        this.cols.forEach(pair => {
          rObj[pair.header.toString()] = obj[pair.field];
        });
        return rObj;
      })
    }
    import('xlsx').then(xlsx => {
      const worksheet = xlsx.utils.json_to_sheet(this.reformattedArray);
      const workbook = { Sheets: { data: worksheet }, SheetNames: ['data'] };
      const excelBuffer: any = xlsx.write(workbook, { bookType: 'xlsx', type: 'array' });
      this.saveAsExcelFile(excelBuffer, 'JobPriority');
    });

  }
  saveAsExcelFile(buffer: any, fileName: string): void {
    import('file-saver').then(FileSaver => {
      const EXCEL_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
      const EXCEL_EXTENSION = '.xlsx';
      const data: Blob = new Blob([buffer], {
        type: EXCEL_TYPE
      });
      let timeStamp = this.datePipe.transform(new Date(), 'ddMMyy_HHmm');
      FileSaver.saveAs(data, fileName + '_' + timeStamp+ EXCEL_EXTENSION);
    });
  }

  clear(table: Table) {
    table.clear();
  }

}
