import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ConfirmationService, MessageService } from 'primeng/api';
import { CRUDService } from 'src/app/utilities/crud.service';
import { jobscheduleArray, Visit } from './tp-job-details';
import { DynamicDialogRef, DialogService } from 'primeng/dynamicdialog';
import { TpJobScheduleComponent } from '../tp-job-schedule/tp-job-schedule.component';
import { TpJobCostingComponent } from '../tp-job-costing/tp-job-costing.component'
import { WpCostingComponent } from '../../job/wp-costing/wp-costing.component';
import { TreeInfoComponent } from '../../modules/tree-info/tree-info.component';
import { TpJobDocumentsComponent } from '../tp-job-documents/tp-job-documents.component';
import { TpJobImagesComponent } from '../tp-job-images/tp-job-images.component';
import { ApproveQuoteComponent } from '../../job/approve-quote/approve-quote.component';
import { RejectQuoteComponent } from '../../job/reject-quote/reject-quote.component';
import { SendQuoteComponent } from '../../job/send-quote/send-quote.component';
import { JOBService } from 'src/app/utilities/job.service';
import { SubContractAgreementCreateComponent } from '../../job/sub-contract-agreement-create/sub-contract-agreement-create.component';
import { JobInvoiceSendComponent } from '../../job/job-invoice-send/job-invoice-send.component';
import { JobKpiComponent } from '../../job/job-kpi/job-kpi.component';
import { JobAdminComponent } from '../../job/job-admin/job-admin.component';
import { JobUserComponent } from '../../modules/job-user/job-user.component';
import { DomSanitizer } from '@angular/platform-browser';
import { delay } from 'rxjs/operators';
import { TaskDetailsComponent } from '../../modules/task-details/task-details.component';
import { JobDetailsApprovalsFlaggingComponent } from '../../modules/job-details-approvals-flagging/job-details-approvals-flagging.component';
import { JobDetailsInvoiceComponent } from '../../modules/job-details-invoice/job-details-invoice.component';
import { TpJobInvoiceSendComponent } from '../tp-job-invoice-send/tp-job-invoice-send.component';
import { JobDetailsApprovalsComponent } from 'src/app/job/job-details-approvals/job-details-approvals.component';
import { TpShfComponent } from '../tp-shf/shf.component';

@Component({
  selector: 'app-tp-job-details',
  templateUrl: './tp-job-details.component.html',
  styleUrls: ['./tp-job-details.component.scss'],
  providers: [DialogService, MessageService, ConfirmationService, JOBService,
    TpJobScheduleComponent, TpJobCostingComponent, WpCostingComponent, TreeInfoComponent,
    TpJobDocumentsComponent, TpJobImagesComponent, ApproveQuoteComponent, RejectQuoteComponent,
    SendQuoteComponent, SubContractAgreementCreateComponent, JobInvoiceSendComponent, TpShfComponent, JobKpiComponent]
})
export class TpJobDetailsComponent implements OnInit {
  totalTree: any;
  displayInvoiceData: any;
  jobId: any;
  allocId: any;
  jobDetails: any[];
  jobInfo: any;
  title: any;
  date: any;
  address: any;
  communication: boolean = false;
  jobschedule: boolean = false;
  jobscheduleArray: jobscheduleArray;
  schedNote: any;
  costing: boolean = false;
  documents: boolean = false;
  documentsData : any;
  jobSummary: any;
  companyId: any;
  userDetails: any;
  companyInfo: any;
  Date_str: any;
  viewType: any;
  docSummary: any[];
  pdfUrl: any;
  showPDF: Boolean = false;
  jobAllocationList: any[] = [];
  scaExists;
  kpiExists;
  SHFExists;
  selectedJob: any;
  jobSearchResults: any[];
  allJobsList: any[] = [];
  loading: Boolean = false;
  showAllocations: Boolean = false;
  showFlagsHistory: Boolean = false;
  displayConfirmation: Boolean = false;
  reportRes: any = {};
  showColours: Boolean = false;
  fullscreen: Boolean = false;
  allFlagInfo: any[];
  isFlagged: Boolean = false;
  TreeData: any
  costMatrix: any;
  deviation: any;
  includesStump: any;
  costFormHead: any;
  costEstimate: any;
  extrasAvailable: any;
  jobTrackerList: any;
  custCareExpList_324: any;
  is324Exempt: Boolean = false;
  isInvoiced: Boolean = false;
  fullList: any[] = [];
  contactUI: any;
  jobScheduleDetails: any[];
  jobschd: any[];
  jobcomms: any[];
  jobFlags = [
    { name: 'red', code: 'firebrick' },
    { name: 'blue', code: 'darkcyan' },
    { name: 'yellow', code: 'darkgoldenrod' },
    { name: 'orange', code: 'darkorange' },
    { name: 'purple', code: 'purple' },
    { name: 'deeppink', code: 'deeppink' },
    { name: '', code: '' }];
  jobActivityLog = [{
    action_name: 'Ezhil',
    action_desc: 'Allocated to Shri tree services',
    action_datetime: new Date(),
    action_user: 'Shri',
    action_colour: '#fff',
    reaction_desc: 'Accepted by Shri tree services',
    reaction_datetime: new Date(),
    reaction_user: 'Shri Tree Services',
    reaction_colour: '#8bc34a'
  }, {
    action_name: 'Job Allocation',
    action_desc: 'Allocated to Shri tree services',
    action_datetime: new Date(),
    action_user: 'Shri',
    action_colour: '#fff',
    reaction_desc: 'Accepted by Shri tree services',
    reaction_datetime: new Date(),
    reaction_user: 'Shri Tree Services',
    reaction_colour: '#fff'
  }, {
    action_name: 'Job Allocation',
    action_desc: 'Allocated to Shri tree services',
    action_datetime: new Date(),
    action_user: 'Shri',
    action_colour: '#fff',
    reaction_desc: 'Accepted by Shri tree services',
    reaction_datetime: new Date(),
    reaction_user: 'Shri Tree Services',
    reaction_colour: '#ff9800'
  }]

  constructor(
    private route: ActivatedRoute,
    private crudService: CRUDService,
    private messageService: MessageService,
    private confirmationService: ConfirmationService,
    public sanitizer: DomSanitizer,
    private router: Router,
    private dialogService: DialogService,
    private JobService: JOBService
  ) {
    this.companyId = sessionStorage.getItem('company_id');
    this.userDetails = JSON.parse(sessionStorage?.getItem('loggedInUser'));
  }

  async ngOnInit() {
    this.fullscreen = true;
    //console.log('job-details');
    this.viewType = 'TP';
    // this.getSearchList();
    this.crudService.getDataByField('Company', 'id', this.companyId).subscribe(async (companyData: any[]) => {
      this.companyInfo = companyData[0];
      this.jobId = parseInt(this.route.snapshot.paramMap.get('jobId'));
      this.allocId = parseInt(this.route.snapshot.paramMap.get('AllocId'));
      this.getSearchList();
      //console.log(this.jobId, this.allocId);
      await this.loadJobSummary();
      this.jobTrackerList = await this.crudService.getDataByField('JobTracker', 'job_id', this.jobId).toPromise();
      this.jobTrackerList = this.jobTrackerList.sort((b, a) => a.id - b.id);
    });
    

    this.fullscreen = false;
  }

  async loadJobSummary() {
    this.loading = true;
    let smryData: any = await this.crudService.getJobSummary(this.jobId, this.allocId).toPromise();
    this.jobSummary = await this.JobService.getJobInfo(smryData[0]);
    console.log(this.jobSummary?.JobAllocDetails);
    console.log(this.jobSummary);
    if(this.jobSummary?.JobAllocDetails.inv_status == null){
      this.isInvoiced = false;
    }else if(this.jobSummary?.JobAllocDetails.inv_status == '1'){
      this.isInvoiced = true;
    }
    if (this.jobSummary?.JobAllocDetails.wf_status === '2' || this.jobSummary?.JobAllocDetails.wf_status === 2) {
      this.isFlagged = true;
    } else {
      this.isFlagged = false;
    }
    this.Date_str = new Date(this.jobSummary?.Date).toLocaleDateString();
    this.title = this.jobSummary?.JobNo + ' - (' + this.jobSummary?.JobStatus + ')';
    if ((this.jobSummary?.JobStatus == 'Approve Invoice') || (this.jobSummary?.JobStatus == 'Await Invoice') || (this.jobSummary?.JobStatus == 'Invoice Processed') || (this.jobSummary?.JobStatus == 'Accounts Processed')) {
      this.displayInvoiceData = true;
    } else {
      this.displayInvoiceData = false;
    }

    this.crudService.getDataByField('jobinfo', 'id', this.jobId).subscribe((data: any[]) => {
      this.jobDetails = data;
      this.jobInfo = data[0];
      this.date = this.jobDetails[0]?.job_recd_dt.split('T')[0];
      this.getInvStepsStatus();
      this.getOtherJobAllocationDetails();
      this.getSearchList();
      this.crudService.getCostingInfo(this.jobId, this.allocId).subscribe((costingInfo: any[]) => {
        if (costingInfo.length > 0) {
          if (this.jobSummary?.wp_grand_total !== null && this.jobSummary?.wp_grand_total !== '') {
            this.jobSummary.tp_grand_total = parseFloat(costingInfo[0]?.tp_grand_total).toFixed(2);
            this.jobSummary.wp_grand_total = parseFloat(costingInfo[0]?.wp_grand_total).toFixed(2);
            let variance = this.jobSummary?.tp_grand_total / this.jobSummary?.wp_grand_total;
            variance = 1 - variance;
            variance = variance * 100;
            this.jobSummary.margin = parseFloat(variance.toString()).toFixed(2);
          }
        }
      });
      this.crudService.getUploadDocumentsSummary(this.jobId, this.allocId).subscribe((uploadStatus: any[]) => {
        if (uploadStatus.length > 0) {
          this.docSummary = uploadStatus;
          console.log("docSummary",this.docSummary);
        }
      });

      this.crudService.getUploadDocumentsByJobIdAllocId(this.jobId, this.allocId).subscribe(async (data: any) => {
        this.documentsData = data;
        this.documentsData = await this.documentsData.filter(val => val.hide === "1");
      });
      this.showFixedCostMessage();
      this.loading = false;
    });

    this.crudService.getByJobIdAllocId(this.jobId, this.allocId).subscribe((data: any[]) => {

      this.fullList= data;
      this.jobScheduleDetails = data;
      this.jobScheduleDetails = this.jobScheduleDetails.filter((val: any) => val.PMOnly != '2');
      this.jobScheduleDetails.forEach(element => {
        if(element.sched_note?.length >300){
          element.short_sched_note = element.sched_note?.slice(0,300);
          element.showFlag = false;
        }else{
          element.short_sched_note = element.sched_note;
          element.showFlag = true;
        }
        if (element.phoned == '2') {
          this.contactUI = "Phoned";
        }
        if (element.phone_no) {
          this.contactUI + '-' + element.phone_no;
        }
        if (element.email == '2') {
          this.contactUI + " Email";
        }
        if (element.emailaddress) { this.contactUI + '-' + element.emailaddress; }
        if (element.sms == '2') {
          this.contactUI + " SMS";
        }
        if (element.sms_no) {
          this.contactUI + '-' + element.sms_no;
        }
        if (element.callback == '2') {
          this.contactUI + " Callback";
        }
        if (!this.contactUI) {
          this.contactUI = 'N/A';
        }
        if(element.PMOnly==='2'){
          element.status_title = 'PM Only';
        }else{
          element.status_title = '';
        }

        if(element.visit_type=='1'){
          element.visit_type_title = 'Site Inspection';
        }else if(element.visit_type=='2'){
          element.visit_type_title = 'Make Safe';
        }else if(element.visit_type=='3'){
          element.visit_type_title = 'Perform Works';
        }else{
          element.visit_type = '';
          element.visit_type_title = 'N/A'
        }
        element.sched_date = new Date(element.sched_date).toDateString();
      });
      this.jobschd = this.jobScheduleDetails.filter((val: any) => val.visit_type === '1' || val.visit_type === '2' || val.visit_type === '3');
      this.jobcomms = this.jobScheduleDetails.filter((val: any) => val.visit_type != '1' && val.visit_type != '2' && val.visit_type != '3');
      this.jobschd = this.jobschd[0];
      this.jobcomms = this.jobcomms[0];
      
      console.log("jobScheduleDetails",this.jobScheduleDetails);
      console.log("jobschd",this.jobschd);
      console.log("jobcomms",this.jobcomms);
    });

  }
  //
  editSelectedRecord() {
    if (this.viewType === 'TM') {
      const ref = this.dialogService.open(JobAdminComponent, {
        data: {
          jobId: this.jobId,
        },
        /* header: 'Job Info', */
        width: '100%',
        height: '100%'
      })
      ref.onClose.subscribe(async (response: any) => {
        await this.loadJobSummary();
      });

    } else {
      const ref = this.dialogService.open(JobUserComponent, {
        data: {
          JobId: this.jobId,
          jobAllocationId: this.allocId,
          jobSummary: this.jobSummary
        },
        /* header: 'Job Info', */
        width: '100%',
        height: '100%'
      })
      ref.onClose.subscribe(async (response: any) => {
        await this.loadJobSummary();
      });
    }
    /* this.router.navigate(['/job-edit', job.id]) */
  }

  async approvalProcess() {
    let job = this.jobSummary;
    this.loading = true;
    if (job.JobStatus === 'Cost Saved' || job.JobStatus === 'Approve Quote' || job.JobStatus === 'Await Cost' || job.JobStatus === 'Quote Rejected') {
      let viewType = 'Quote';
      this.loading = false;
      const ref = this.dialogService.open(JobDetailsApprovalsComponent, {
        data: {
          jobId: this.jobId,
          allocId: this.allocId,
          jobSummary: this.jobSummary,
          viewType: viewType
        },
        width: '100%',
        height: '100%'
      })
      ref.onClose.subscribe(async (response: any) => {
        await this.loadJobSummary();
      });
    } else if (job.JobStatus === 'Approve Invoice' || job.JobStatus === 'Await Invoice' || job.JobStatus === 'Accounts Processed' || job.JobStatus === 'Invoice Processed' || job.JobStatus === 'Accounts Proecessed' || job.JobStatus === 'Job Completed') {
      let viewType = 'Approval';
      this.loading = false;
      const ref = this.dialogService.open(JobDetailsApprovalsComponent, {
        data: {
          jobId: this.jobId,
          allocId: this.allocId,
          jobSummary: this.jobSummary,
          viewType: viewType
        },
        width: '100%',
        height: '100%'
      })
      ref.onClose.subscribe(async (response: any) => {
        await this.loadJobSummary();
      });

    } else if (job.JobStatus === 'Await Acceptance' || job.JobStatus === 'Send Offer') {
      this.loading = false;
      this.editSelectedRecord();
    } else if (job.JobStatus === 'Quote Rejected' || job.JobStatus === 'Send Offer') {
      this.loading = false;
      this.editSelectedRecord();
    }
    this.loading = false;
  }

  communicationTab() {
    this.communication = true;
    // this.crudService.getDataByField('Communications','id',this.jobId).subscribe((data:any)=>{
    //   //console.log(data);
    // })

  }


  async jobscheduleTab(showType) {
    const ref = this.dialogService.open(TpJobScheduleComponent, {
      data: {
        jobAllocationId: this.allocId,
        jobId: this.jobId,
        jobSummary: this.jobSummary,
        showType : showType
      },
      /* header: 'Job Schedule', */
      width: '100%',
      height: '100%'
    });
    ref.onClose.subscribe(async (response: any) => {
      await this.loadJobSummary();
    });
  }

  async costingTab() {
    const ref = this.dialogService.open(TpJobCostingComponent, {
      data: {
        jobAllocationId: this.allocId,
        jobId: this.jobId,
        jobSummary: this.jobSummary
      },
      /* header: 'Job Costing', */
      width: '100%',
      height: '100%'
    });
    ref.onClose.subscribe(async (response: any) => {
      await this.loadJobSummary();
    });
  }

  async TPInvoice() {
    let date = new Date();
    let tempTime = date.getTime();
    let data = await this.JobService.getInvoiceData(this.jobId, this.allocId, tempTime);
    this.pdfUrl = this.crudService.getAPIRoot() + '/' + this.jobId + '/' + this.allocId + '/' + 'report-template-11-' + tempTime + '.pdf';
    this.showPDF = true;
  }

  async WPInvoice() {
    let date = new Date();
    let tempTime = date.getTime();
    //let data = await this.JobService.getWPInvoiceData(this.jobId, this.allocId);
    let data = await this.JobService.generateWpInvoicePdf(this.jobId, this.allocId, this.jobSummary);
    let res = this.crudService.WPInvoiceReport(this.jobId, this.allocId, '12_tmv3', data, tempTime).toPromise();
    //this.pdfUrl = this.crudService.getAPIRoot() + '/' + this.jobId + '/' + this.allocId + '/' + 'report-template-12_TMV3-' + tempTime + '.pdf';
    //this.showPDF = true;
    return res
  }

  async generateReport(reportId) {
    this.reportRes = {};
    this.loading = true;
    const date = new Date();
    const tempTime = date.getTime();
    const fileName = reportId + '_' + this.jobSummary?.JobNo + '.pdf';
    const filePath = this.jobId + '/' + this.allocId + '/' + 'report-template-' + reportId + '-' + tempTime + '.pdf'
    let fileDesc = null;
    let res: any;
    if (reportId === 'SHF') {
      fileDesc = '10035';
      let data = await this.JobService.setSHFData(this.jobId, this.allocId, reportId, this.jobSummary)
      res = await this.crudService.generateNewSHFReport(this.jobId, this.allocId, data, reportId, tempTime, 1).toPromise();
    } else if (reportId === 'TPI') {
      fileDesc = '10211';
      res = await this.JobService.getInvoiceData(this.jobId, this.allocId, tempTime);
    } else if (reportId === 'SCA') {
      fileDesc = '10040';
      reportId = '5';
      let data = await this.JobService.setSCAData(this.jobId, this.allocId, reportId, this.jobSummary);
      res = await this.crudService.generateReport(data, reportId, this.jobId, this.allocId, tempTime, 1).toPromise();
    } else if (reportId === 'WPI') {
      fileDesc = '10067';
      reportId = '12';
      //let data = await this.JobService.getWPInvoiceData(this.jobId, this.allocId);
      let data = await this.JobService.generateWpInvoicePdf(this.jobId, this.allocId, this.jobSummary);
      //res = await this.crudService.WPInvoiceReport(this.jobId, this.allocId, '12_tmv3', data, tempTime).toPromise();
      res = await this.crudService.generateReport(data, reportId, this.jobId, this.allocId, tempTime, 1).toPromise();
    } else if (reportId === 'WPQ') {
      fileDesc = '10066';
      reportId = '6';
      let data = await this.JobService.generateWpQuotePdf(this.jobId, this.allocId, this.jobSummary);
      res = await this.crudService.generateReport(data, reportId, this.jobId, this.allocId, tempTime, 1).toPromise();
      //let data = await this.JobService.getWPInvoiceData(this.jobId, this.allocId);
      //res = await this.crudService.WPInvoiceReport(this.jobId, this.allocId, '12_tmv3', data, tempTime).toPromise();
    } else if (reportId === 'KPI') {
      fileDesc = '10065';
      let data = await this.JobService.loadKPIReportData(this.jobId, this.allocId, this.jobSummary, reportId);
      res = await this.crudService.generateNewKPIReport(this.jobId, this.allocId, data, reportId, tempTime, 1).toPromise();
    }

    this.reportRes.res = res;
    this.reportRes.fileName = fileName;
    this.reportRes.filePath = filePath;
    this.reportRes.fileDesc = fileDesc;
    this.reportRes.reportId = reportId;
    this.reportRes.tempTime = tempTime;
    this.displayConfirmation = true;
    /*  this.confirmationService.confirm({
       message: 'Do you want to view the report in separate Window?',
       header: 'Confirm',
       icon: 'pi pi-exclamation-triangle',
       acceptLabel: 'Yes, Open in new Tab',
       rejectLabel: 'No, Download the File',
       accept: () => {
         let blob = new Blob([res], { type: "application/pdf" });
         const objectUrl = URL.createObjectURL(blob);
         window.open(objectUrl);
         this.JobService.deleteDoc(this.allocId, fileName);
         this.JobService.addUploadDoc(this.jobId, this.allocId, this.userDetails.id, fileName, filePath, fileDesc);
         
         this.loadJobSummary();
       },
       reject: () => {
         import('file-saver').then(FileSaver => {
           let blob = new Blob([res], { type: "application/pdf" });
           const a = document.createElement('a')
           const objectUrl = URL.createObjectURL(blob)
           a.href = objectUrl
           a.download = fileName;
           a.click();
           URL.revokeObjectURL(objectUrl);
           this.JobService.deleteDoc(this.allocId, fileName);
           this.JobService.addUploadDoc(this.jobId, this.allocId, this.userDetails.id, fileName, filePath, fileDesc);
           
           this.loadJobSummary();
         })
       }
     }); */

  }


  async viewReport(type) {
    if (type === 'Tab') {
      let blob = new Blob([this.reportRes.res], { type: "application/pdf" });
      const objectUrl = URL.createObjectURL(blob);
      window.open(objectUrl);
      this.JobService.deleteDoc(this.allocId, this.reportRes.fileName);
      //this.JobService.addUploadDoc(this.jobId, this.allocId, this.userDetails.id, this.reportRes.fileName, this.reportRes.filePath, this.reportRes.fileDesc, this.reportRes.reportId, this.reportRes.tempTime, this.jobSummary?.info.WPCompanyId);
      this.loading = false;
      //await this.loadJobSummary();
      this.displayConfirmation = false;
      this.reportRes = {}
    } else {
      import('file-saver').then(async FileSaver => {
        let blob = new Blob([this.reportRes.res], { type: "application/pdf" });
        const a = document.createElement('a')
        const objectUrl = URL.createObjectURL(blob)
        a.href = objectUrl
        a.download = this.reportRes.fileName;
        a.click();
        URL.revokeObjectURL(objectUrl);
        this.JobService.deleteDoc(this.allocId, this.reportRes.fileName);
        //this.JobService.addUploadDoc(this.jobId, this.allocId, this.userDetails.id, this.reportRes.fileName, this.reportRes.filePath, this.reportRes.fileDesc, this.reportRes.reportId, this.reportRes.tempTime, this.jobSummary?.info.WPCompanyId);
        this.loading = false;
        //await this.loadJobSummary();
        this.reportRes = {}
        this.displayConfirmation = false;
      })
    }
  }
  async loadDocuments() {
    const ref = this.dialogService.open(TpJobDocumentsComponent, {
      data: {
        jobAllocationId: this.allocId,
        jobId: this.jobId,
        jobSummary: this.jobSummary
      },
      /* header: 'Upload Documents', */
      width: '100%',
      height: '100%'
    })
    ref.onClose.subscribe(async (response: any) => {
      await this.loadJobSummary();
    });
  }

  async loadImages() {
    const ref = this.dialogService.open(TpJobImagesComponent, {
      data: {
        jobAllocationId: this.allocId,
        jobId: this.jobId,
        jobSummary: this.jobSummary
      },
      /* header: 'Images', */
      width: '100%',
      height: '100%'
    });
    ref.onClose.subscribe(async (response: any) => {
      await this.loadJobSummary();
    });
  }

  async loadApproveQuote() {
    const ref = this.dialogService.open(ApproveQuoteComponent, {
      data: {
        jobAllocationId: this.allocId,
        jobId: this.jobId,
        jobSummary: this.jobSummary
      },
      /* header: 'Approve Quote', */
      width: '100%',
      height: '100%'
    })
    ref.onClose.subscribe(async (response: any) => {
      await this.loadJobSummary();
    });
  }

  async loadRejectQuote() {
    const ref = this.dialogService.open(RejectQuoteComponent, {
      data: {
        jobAllocationId: this.allocId,
        jobId: this.jobId,
        jobSummary: this.jobSummary
      },
      /* header: 'Reject Quote', */
      width: '100%',
      height: '100%'
    })
    ref.onClose.subscribe(async (response: any) => {
      await this.loadJobSummary();
    });
  }

  async loadSubContract() {
    const ref = this.dialogService.open(SubContractAgreementCreateComponent, {
      data: {
        jobAllocationId: this.allocId,
        jobId: this.jobId,
        jobSummary: this.jobSummary
      },
      /* header: 'Sub Contract Agreement', */
      width: '100%',
      height: '100%'
    })
    ref.onClose.subscribe(async (response: any) => {
      await this.loadJobSummary();
    });
  }

  async loadKPI() {
    //console.log(this.allocId, this.jobId);
    const ref = this.dialogService.open(JobKpiComponent, {
      data: {
        jobAllocationId: this.allocId,
        jobId: this.jobId,
        jobSummary: this.jobSummary
      },
      /* header: 'Job KPI', */
      width: '100%',
      height: '100%'
    });
    ref.onClose.subscribe(async (response: any) => {
      await this.loadJobSummary();
    });
  }

  async loadWpCosting() {
    //console.log(this.allocId, this.jobId);
    const ref = this.dialogService.open(WpCostingComponent, {
      data: {
        jobAllocationId: this.allocId,
        jobId: this.jobId,
        jobSummary: this.jobSummary
      },
      /* header: 'WP Costing', */
      width: '100%',
      height: '100%'
    });
    ref.onClose.subscribe(async (response: any) => {
      await this.loadJobSummary();
    });
    ref.onClose.subscribe(async (response: any) => {
      await this.loadJobSummary();
    });
  }

  async loadSendEmail() {
    //console.log(this.allocId, this.jobId);
    const ref = this.dialogService.open(SendQuoteComponent, {
      data: {
        jobAllocationId: this.allocId,
        jobId: this.jobId,
        jobSummary: this.jobSummary
      },
      /* header: 'Send Email', */
      width: '100%',
      height: '100%'
    });
    ref.onClose.subscribe(async (response: any) => {
      await this.loadJobSummary();
    });
  }

  async loadTreeInfo() {
    //console.log(this.allocId, this.jobId);
    let disableFields = false;
    if(this.jobSummary.JobStatus== 'Approve Invoice'){
      disableFields = true;
    }
    const ref = this.dialogService.open(TreeInfoComponent, {
      data: {
        jobAllocationId: this.allocId,
        jobId: this.jobId,
        jobSummary: this.jobSummary,
        disable: disableFields
      },
      /* header: 'Tree Info', */
      width: '100%',
      height: '100%'
    })
    ref.onClose.subscribe(async (response: any) => {
      await this.loadJobSummary();
    });
  }

  async jobInvoiceSend() {
    //console.log(this.allocId, this.jobId);
    const ref = this.dialogService.open(TpJobInvoiceSendComponent, {
      data: {
        jobAllocationId: this.allocId,
        jobId: this.jobId,
        jobSummary: this.jobSummary
      },
      /* header: 'Job Invoice Send', */
      width: '100%',
      height: '100%'
    })
    ref.onClose.subscribe(async (response: any) => {
      await this.loadJobSummary();
    });
  }

  async loadSiteHazard() {
    //console.log(this.allocId, this.jobId);
    const ref = this.dialogService.open(TpShfComponent, {
      data: {
        jobAllocationId: this.allocId,
        jobId: this.jobId,
        jobSummary: this.jobSummary
      },
      /* header: 'Site Hazard Form', */
      width: '100%',
      height: '100%'
    })
    ref.onClose.subscribe(async (response: any) => {
      await this.loadJobSummary();
    });
  }

  deletejob(joDetails) {

  }
  toggleTimeline() {
    let t = document.getElementById('toggle-timeline');
    if (t) {
      t.classList.toggle('hidden');
    }
  }
  onEdit() {
    window.scrollTo(0, 0);
  }

  async getInvStepsStatus() {
    this.crudService.getInvStepsStatus(await this.allocId).subscribe((InvData: any) => {
      if (InvData.length > 0) {
        if (InvData[0]?.SHFExists == '1') {
          this.SHFExists = true;
        } else {
          this.SHFExists = false;
        }
        if (InvData[0]?.KPIExists == '1') {
          this.kpiExists = true;
        } else {
          this.kpiExists = false;
        }
        if (InvData[0]?.SCAExists == '1') {
          this.scaExists = true;
        } else {
          this.scaExists = false;
        }
      }
    });
  }

  async getOtherJobAllocationDetails() {
    this.jobAllocationList = [];
    // this.jobAllocationList.push({ id: 12701, TreeProfessional: 'Joeys Tree Services', Status: 'Approve Quote', Amount: '$ 20120' });
    //  this.jobAllocationList.push({ id: 12786, TreeProfessional: 'MSV Trees', Status: 'Approve Quote', Amount: '$ 9569' });


    this.crudService.getOtherAllocationDetails(await this.jobId).subscribe((res: any) => {
      this.jobAllocationList = res;
      //console.log('alloc list : ' + res);
    })
  }

  async getSearchList() {
    let additionalCondition = '';
    this.custCareExpList_324 = await this.crudService.getByValuesetName('CUSTCARE_EXP_324').toPromise();
    let index = this.custCareExpList_324.findIndex(val => (val.valueset_detail_name === this.userDetails.id));
    if (index > -1) {
      this.is324Exempt = true;
    }
    if (this.is324Exempt) {
      additionalCondition = ' and CompanyId not in (324)';
    }
    this.crudService.getSearchList(additionalCondition).subscribe((data: any) => {
      this.allJobsList = data;
    });
    this.crudService.getDataByField('Job_wf_status', 'job_alloc_id', this.allocId).subscribe((flagdata: any) => {
      this.allFlagInfo = flagdata;
    });
    await this.crudService.getWorkOrderInfo(this.jobId, this.allocId).subscribe((res: any[]) => {
      this.totalTree = res[0]?.no_tree;
    });
  }
  async loadJobDetails(TPInfo) {
    this.loading = true;
    this.fullscreen = true;
    this.allocId = TPInfo.AllocationId;
    await this.loadJobSummary();
    this.loading = false;
    this.fullscreen = false;
  }

  async openSelectedJob(event) {
    this.selectedJob = event;
    this.allocId = this.selectedJob.AllocationId;;
    this.jobId = this.selectedJob.JobId;
    await this.loadJobSummary();
  }
  async searchJobs(event) {
    let searchStr = event.query;
    searchStr = searchStr?.toLowerCase()
    this.jobSearchResults = await this.allJobsList.filter((val: any) => (
      (val.job_claim_no ? val.job_claim_no : '')?.toLowerCase().includes(searchStr) ||
      val.JobNo?.toLowerCase().includes(searchStr) ||
      (val.FullAddress ? val.FullAddress : '')?.toLowerCase().includes(searchStr) ||
      (val.JobStatus ? val.JobStatus : '')?.toLowerCase().includes(searchStr) ||
      (val.InvoiceNo ? val.InvoiceNo : '')?.toLowerCase().includes(searchStr)));
    //console.log(this.jobSearchResults)
  }

  updateJobFlag(colour) {
    this.jobSummary.JobAllocDetails.flag_details = colour.code;
    this.crudService.updateFlag(this.allocId, colour.code).toPromise();
  }


  async loadTaskDetails(task_id) {
    const ref = this.dialogService.open(TaskDetailsComponent, {
      data: {
        wf_id: '7',
        task_id: task_id,
        checkList: null,
        redirectMenu: {para_1: 'TP'}
      },
      header: '',
      width: '100%',
      height: '100%'
    })

    ref.onClose.subscribe((taskdetails: any) => {
    });
  }

  async flagUnflagJob() {
    let data: any = await this.crudService.getDataByField('JobAllocation', 'id', this.allocId).toPromise();
    let FlagUnflag = 'FLAG';
    if (this.isFlagged) {
      FlagUnflag = 'UNFLAG';
      this.isFlagged = true;
    } else {
      this.isFlagged = false;
      FlagUnflag = 'FLAG';
    }
    const ref = this.dialogService.open(JobDetailsApprovalsFlaggingComponent, {
      data: {
        jobId: this.jobId,
        allocId: this.allocId,
        FlagUnflag: FlagUnflag,
        job_status: this.jobSummary?.JobStatus,
        step: 'operations',
        wf_status_id: data.wf_status_id
      },
      width: '75%',
      height: 'max-content',
      styleClass: ''
    })
    ref.onClose.subscribe((response: any) => {
      if (response.success === 1) {
        if (this.isFlagged) {
          data.job_wf_id = response.jobWfStatus.id;
          this.messageService.add({ severity: 'success', summary: 'Successful', detail: 'Job Allocation Un Flagged', life: 1000 });
        } else {
          this.messageService.add({ severity: 'success', summary: 'Successful', detail: 'Job Allocation Flagged', life: 1000 });
        }
        this.isFlagged = !this.isFlagged;
        this.crudService.getDataByField('Job_wf_status', 'job_alloc_id', this.allocId).subscribe((flagdata: any) => {
          this.allFlagInfo = flagdata;
        });
      }
    });
  }


  async showFixedCostMessage() {
    this.crudService.getCostFormHeadByJobIdAllocId(await this.jobId, await this.allocId).subscribe((data: any[]) => {
      if (data.length > 0) {
        this.costFormHead = data[0];
        this.crudService.getWorkOrderInfo(this.jobId, this.allocId).subscribe((res: any[]) => {
          if (res.length > 0) {
            this.TreeData = res[0];
            let workCategoryData = null;
            this.includesStump = false;
            this.extrasAvailable = false;
            if (this.TreeData.work_category.includes('1')) {
              workCategoryData = 1;
              if (this.TreeData.work_category.includes('3')) { this.includesStump = true; }
              if ((this.TreeData.work_category.includes('4')) || (this.TreeData.work_category.includes('5'))) { this.extrasAvailable = true; }
            } else if (this.TreeData.work_category.includes('2')) {
              workCategoryData = 2;
              if (this.TreeData.work_category.includes('3')) { this.includesStump = true; }
              if ((this.TreeData.work_category.includes('4')) || (this.TreeData.work_category.includes('5'))) { this.extrasAvailable = true; }
            }
            this.crudService.getQuoteApprovalCheckData(this.TreeData.height, this.TreeData.trunk, workCategoryData).subscribe((res2: any[]) => {
              //console.log('work operation info data');
              if (res2.length > 0) {
                this.costEstimate = (res2[0]?.cost * .80).toFixed(2); // parseFloat((1-(this.sub_total/this.wp_sub_total))*100).toFixed(2);
                var matrixCalc = this.costFormHead.sub_total / this.costEstimate;
                this.deviation = ((1 - matrixCalc) * 100).toFixed(2);
                // var matrixCalc=parseFloat((1-(this.sub_total/this.costEstimate))*100).toFixed(2);this.deviation=parseFloat((1-(this.sub_total/this.costEstimate))*100).toFixed(2);

                if (this.TreeData.no_tree > 1) {
                  this.costMatrix = '4';
                } else if (matrixCalc < 1.00) {
                  this.costMatrix = '1';
                } else if (matrixCalc < 1.25) {
                  this.costMatrix = '2';
                } else if (matrixCalc > 1.25) {
                  this.costMatrix = '3';
                } else {
                  this.costMatrix = '5';
                }
                /* this.TPEstimateDisplay = this.formatMoney(this.sub_total);
                this.costEstimateDisplay = this.formatMoney(this.costEstimate); */
              } else {
                this.costMatrix = '5';
              }
            });
          }
        });

      } else {

        this.costMatrix = '5';
      }
    })
  }

  async editFlag(flagInfo) {
    let jobWFStatus = flagInfo;
    const ref = this.dialogService.open(JobDetailsApprovalsFlaggingComponent, {
      data: {
        jobId: jobWFStatus.job_id,
        allocId: this.allocId,
        FlagUnflag: 'FLAG',
        job_status: this.jobSummary?.JobStatus,
        step: jobWFStatus.step,
        wf_status_id: jobWFStatus.id
      },
      width: '75%',
      height: '75%',
      styleClass: ''
    })
    ref.onClose.subscribe((response: any) => {
      if (response.success === 1) {
      }
    })
  }

  async jobDetailsInvoice() {
    const ref = this.dialogService.open(JobDetailsInvoiceComponent, {
      data: {
        jobAllocationId: this.allocId,
        jobId: this.jobId,
        jobSummary: this.jobSummary
      },
      /* header: 'Job Schedule', */
      width: '100%',
      height: '100%'
    });
    ref.onClose.subscribe(async (response: any) => {
      await this.loadJobSummary();
    });
  }

}