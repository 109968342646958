import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { ClientchannelComponent } from './clientchannel/clientchannel.component';
import { ContractorchannelComponent } from './contractorchannel/contractorchannel.component';
const routes: Routes = [
  { path: 'client/:jobparam', component: ClientchannelComponent},
  { path: 'tp-contractor/:jobparam', component: ContractorchannelComponent},
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class ChannelRoutingModule { }
