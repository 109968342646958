import { Component, OnInit, ViewChild, Input } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { Steps } from 'primeng/steps';
import { CRUDService } from 'src/app/utilities/crud.service';
import { DomSanitizer } from '@angular/platform-browser';
import * as moment from 'moment';
import { CommonService } from 'src/app/utilities/common.service';
import { DynamicDialogRef, DynamicDialogConfig, DialogService } from 'primeng/dynamicdialog';
import { MessageService, ConfirmationService } from 'primeng/api';
import { WorkFlowService } from 'src/app/utilities/workflow.service';
import { JOBService } from 'src/app/utilities/job.service';
@Component({
  selector: 'app-job-details-invoice',
  templateUrl: './job-details-invoice.component.html',
  styleUrls: ['./job-details-invoice.component.scss']
})
export class JobDetailsInvoiceComponent implements OnInit {
  @Input() jobId: any
  @Input() jobAllocationId: any;
  @Input() jobSummary: any;
  loading: Boolean = false;
  showHeader: Boolean = false;
  wizardMetaData: any[];
  personalInformation: any;
  activeTab: any;
  submitted: boolean = false;
  jobDetailInvoiceForm: FormGroup;
  imageDocuments: any = [];
  jobImageSelection: FormGroup;
  additionalInfoForm: FormGroup;
  companyId: any;
  userDetails: any;
  defaultTab: any;
  property_safe: any;
  safety_concern: any;
  invoiceInfo: any;
  safeProperty: Boolean = false;
  unsafe_reason: any;
  timeSlots: any[];
  filteredTimeSlots: any[];
  appProps: any;

  constructor(private fb: FormBuilder, private CrudService: CRUDService, private ref: DynamicDialogRef, private messageService: MessageService,
    private route: ActivatedRoute, private sanitizer: DomSanitizer, private confirmationService: ConfirmationService,private commonservice: CommonService,
    public config: DynamicDialogConfig, private router: Router, private dialogService: DialogService, private wfService: WorkFlowService, private JobService: JOBService) {
    this.companyId = sessionStorage.getItem('company_id');
    this.userDetails = JSON.parse(sessionStorage.getItem('loggedInUser'));
    this.timeSlots = ['12:00am', '12:30am', '1:00am', '1:30am', '2:00am', '2:30am', '3:00am', '3:30am', '4:00am', '4:30am', '5:00am', '5:30am', '6:00am', '6:30am', '7:00am', '7:30am', '8:00am', '8:30am', '9:00am', '9:30am', '10:00am', '10:30am', '11:00am', '11:30am',
      '12:00pm', '12:30pm', '1:00pm', '1:30pm', '2:00pm', '2:30pm', '3:00pm', '3:30pm', '4:00pm', '4:30pm', '5:00pm', '5:30pm', '6:00pm', '6:30pm', '7:00pm', '7:30pm', '8:00pm', '8:30pm', '9:00pm', '9:30pm', '10:00pm', '10:30pm', '11:00pm', '11:30pm'];
    this.filteredTimeSlots = this.timeSlots;
  }

  async ngOnInit() {
    this.appProps = this.commonservice.getAppProperties();
    this.jobDetailInvoiceForm = this.fb.group({
      to_name: ['', Validators.maxLength(60)],
      to_line_1: ['', Validators.maxLength(30)],
      to_line_2: ['', Validators.maxLength(30)],
      to_line_3: ['', Validators.maxLength(30)],
      to_line_4: ['', Validators.maxLength(30)],
      to_line_5: ['', Validators.maxLength(30)],
      insured_name: ['', Validators.maxLength(60)],
      insured_line_1: ['', Validators.maxLength(30)],
      insured_line_2: ['', Validators.maxLength(30)],
      insured_line_3: ['', Validators.maxLength(30)],
      insured_line_4: ['', Validators.maxLength(30)],
      insured_line_5: ['', Validators.maxLength(30)],
      invoice_date: [''],
      invoice_no: ['', Validators.maxLength(30)],
      claim_ref: ['', Validators.maxLength(30)],
      client_ref: ['', Validators.maxLength(30)],
      client: ['', Validators.maxLength(60)],
      member_name: ['', Validators.maxLength(60)],
      abn: ['', Validators.maxLength(30)],
      licence_no: ['', Validators.maxLength(30)]
    })
    this.jobImageSelection = this.fb.group({
      selectedImage: [null]
    })
    this.additionalInfoForm = this.fb.group({
      works_info: ['', Validators.maxLength(4000)],
      incident_info: ['', Validators.maxLength(4000)],
      authority_person: ['', Validators.maxLength(60)],
      inspection_date: [''],
      inspection_time: [''],
      inspection_by: ['', Validators.maxLength(60)],
      safe_yn: [''],
      concerns_yn: [''],
      unsafe_reason: ['', Validators.maxLength(2000)],
      works_completed: ['', Validators.maxLength(4000)],
      next_steps: ['', Validators.maxLength(4000)]
    })
    if (typeof this.jobId === 'undefined') {
      if (typeof this.config.data == 'undefined' || typeof (this.config.data.jobId) == 'undefined') {
        if (this.jobId == null || this.jobId === '') {
          this.jobId = parseInt(this.route.snapshot.paramMap.get('jobId'));
          if (this.jobAllocationId === null || this.jobAllocationId === '') {
            this.jobAllocationId = '1';
          } else {
            this.jobAllocationId = parseInt(this.route.snapshot.paramMap.get('jobAllocationId'));
            this.jobSummary = this.CrudService.getDataByField('JobInfo', 'id', this.jobId);
          }
        }
        this.showHeader = false;
      } else {
        this.jobId = this.config.data.jobId;
        this.jobAllocationId = this.config.data.jobAllocationId;
        this.jobSummary = this.config.data.jobSummary;
        this.showHeader = true;
      }
    }

    let data: any = await this.CrudService.getDataByField('ClientInvoiceInfo', 'job_alloc_id', this.jobAllocationId).toPromise();
    if (data.length > 0) {
      this.invoiceInfo = data[0];
      this.setupData(data[0])
    } else {
      this.invoiceInfo = {};
    }
    this.getUploadImages();
    this.wizardMetaData = [
      { id: '1', code: 'invoice', title: 'Invoice Details', class: 'active', iconClass: 'fa fa-calendar', flagged: false, approved: false, acceptanceCriteria: ['Schedule should match this criteria - Lorem Ipsum is simply dummy text of the printing and typesetting industry'] },
      // { id: '2', code: 'images', title: 'Image Selection', iconClass: 'fa fa-picture-o', flagged: false, approved: false, acceptanceCriteria: ['All images are clear', 'Images show Details of Damages', 'Images show details of access if special equipment required'] },
      { id: '3', code: 'additional', title: 'Work Details', iconClass: 'fa fa-book', flagged: false, approved: false, acceptanceCriteria: ['Document should match this criteria', 'Document should match this criteria', 'Document should match this criteria'] },
    ];
    //console.log('this');
    this.activeTab = this.wizardMetaData[0];
    this.initTab();

    this.wizardMetaData[0].active = true;
    if (this.defaultTab !== null && this.defaultTab !== '') {
      this.wizardMetaData.forEach(wiz => {
        if (wiz.code === this.defaultTab) {
          wiz.active = true;
          this.updateTab(wiz)
        } else {
          wiz.active = false;
        }
      })
    }
  }

  search(event) {
    this.filteredTimeSlots = this.timeSlots.filter((val: any) => val.includes(event.query));
  }

  setupData(data) {
    this.jobDetailInvoiceForm.controls.to_name.setValue(this.invoiceInfo.to_name);
    this.jobDetailInvoiceForm.controls.to_line_1.setValue(this.invoiceInfo.to_line_1);
    this.jobDetailInvoiceForm.controls.to_line_2.setValue(this.invoiceInfo.to_line_2);
    this.jobDetailInvoiceForm.controls.to_line_3.setValue(this.invoiceInfo.to_line_3);
    this.jobDetailInvoiceForm.controls.to_line_4.setValue(this.invoiceInfo.to_line_4);
    this.jobDetailInvoiceForm.controls.to_line_5.setValue(this.invoiceInfo.to_line_5);
    this.jobDetailInvoiceForm.controls.insured_name.setValue(this.invoiceInfo.insured_name);
    this.jobDetailInvoiceForm.controls.insured_line_1.setValue(this.invoiceInfo.insured_line_1);
    this.jobDetailInvoiceForm.controls.insured_line_2.setValue(this.invoiceInfo.insured_line_2);
    this.jobDetailInvoiceForm.controls.insured_line_3.setValue(this.invoiceInfo.insured_line_3);
    this.jobDetailInvoiceForm.controls.insured_line_4.setValue(this.invoiceInfo.insured_line_4);
    this.jobDetailInvoiceForm.controls.insured_line_5.setValue(this.invoiceInfo.insured_line_5);
    this.jobDetailInvoiceForm.controls.invoice_date.setValue(new Date(this.invoiceInfo.invoice_date));
    this.jobDetailInvoiceForm.controls.invoice_no.setValue(this.invoiceInfo.invoice_no);
    this.jobDetailInvoiceForm.controls.claim_ref.setValue(this.invoiceInfo.claim_ref);
    this.jobDetailInvoiceForm.controls.client_ref.setValue(this.invoiceInfo.client_ref);
    this.jobDetailInvoiceForm.controls.client.setValue(this.invoiceInfo.client);
    this.jobDetailInvoiceForm.controls.member_name.setValue(this.invoiceInfo.member_name);
    this.jobDetailInvoiceForm.controls.abn.setValue(this.invoiceInfo.abn);
    this.jobDetailInvoiceForm.controls.licence_no.setValue(this.invoiceInfo.licence_no);
    //this.jobImageSelection.controls.selectedImage.setValue(this.invoiceInfo.invoice_img);
    this.additionalInfoForm.controls.works_info.setValue(this.invoiceInfo.works_info);
    this.additionalInfoForm.controls.incident_info.setValue(this.invoiceInfo.incident_info);
    this.additionalInfoForm.controls.inspection_date.setValue(new Date(this.invoiceInfo.inspection_date));
    this.additionalInfoForm.controls.inspection_time.setValue(this.invoiceInfo.inspection_time);
    this.additionalInfoForm.controls.inspection_by.setValue(this.invoiceInfo.inspection_by);
    this.additionalInfoForm.controls.authority_person.setValue(this.invoiceInfo.authority_person);
    this.additionalInfoForm.controls.safe_yn.setValue(parseInt(this.invoiceInfo.safe_yn));
    this.property_safe = parseInt(this.invoiceInfo.safe_yn);
    this.unsafe_reason = this.invoiceInfo.unsafe_reason ? this.invoiceInfo.unsafe_reason : null;
    this.additionalInfoForm.controls.concerns_yn.setValue(parseInt(this.invoiceInfo.concerns_yn));
    this.safety_concern = parseInt(this.invoiceInfo.concerns_yn);
    this.additionalInfoForm.controls.works_completed.setValue(this.invoiceInfo.works_completed);
    this.additionalInfoForm.controls.next_steps.setValue(this.invoiceInfo.next_steps);
  }


  changeTab() {
    //console.log('p')
  }
  saveInvoiceDetails() {

  }
  async saveInvoiceFinal() {
    //console.log("workthis");
    if (this.jobDetailInvoiceForm.valid && this.additionalInfoForm.valid) {
      let json = {
        id: this.invoiceInfo.id ? this.invoiceInfo.id : null,
        job_id: this.jobId,
        job_alloc_id: this.jobAllocationId,
        invoice_date: this.jobDetailInvoiceForm.controls.invoice_date.value ? moment(this.jobDetailInvoiceForm.controls.invoice_date.value).format('YYYY-MM-DDTHH:mm:ss') : null,
        invoice_no: this.jobDetailInvoiceForm.controls.invoice_no.value,
        claim_ref: this.jobDetailInvoiceForm.controls.claim_ref.value,
        client_ref: this.jobDetailInvoiceForm.controls.client_ref.value,
        client: this.jobDetailInvoiceForm.controls.client.value,
        member_name: this.jobDetailInvoiceForm.controls.member_name.value,
        abn: this.jobDetailInvoiceForm.controls.abn.value,
        licence_no: this.jobDetailInvoiceForm.controls.licence_no.value,
        to_name: this.jobDetailInvoiceForm.controls.to_name.value,
        to_line_1: this.jobDetailInvoiceForm.controls.to_line_1.value,
        to_line_2: this.jobDetailInvoiceForm.controls.to_line_2.value,
        to_line_3: this.jobDetailInvoiceForm.controls.to_line_3.value,
        to_line_4: this.jobDetailInvoiceForm.controls.to_line_4.value,
        to_line_5: this.jobDetailInvoiceForm.controls.to_line_5.value,
        insured_name: this.jobDetailInvoiceForm.controls.insured_name.value,
        insured_line_1: this.jobDetailInvoiceForm.controls.insured_line_1.value,
        insured_line_2: this.jobDetailInvoiceForm.controls.insured_line_2.value,
        insured_line_3: this.jobDetailInvoiceForm.controls.insured_line_3.value,
        insured_line_4: this.jobDetailInvoiceForm.controls.insured_line_4.value,
        insured_line_5: this.jobDetailInvoiceForm.controls.insured_line_5.value,
        invoice_img: null,//this.jobImageSelection.controls.selectedImage.value,
        works_info: this.additionalInfoForm.controls.works_info.value,
        incident_info: this.additionalInfoForm.controls.incident_info.value,
        inspection_date: this.additionalInfoForm.controls.inspection_date.value ? moment(this.additionalInfoForm.controls.inspection_date.value).format('YYYY-MM-DDTHH:mm:ss') : null,
        inspection_time: this.additionalInfoForm.controls.inspection_time.value,
        inspection_by: this.additionalInfoForm.controls.inspection_by.value,
        authority_person: this.additionalInfoForm.controls.authority_person.value,
        safe_yn: this.additionalInfoForm.controls.safe_yn.value ? Number(this.additionalInfoForm.controls.safe_yn.value) : 0,
        unsafe_reason: this.unsafe_reason,
        concerns_yn: this.additionalInfoForm.controls.concerns_yn.value ? Number(this.additionalInfoForm.controls.concerns_yn.value) : 1,
        works_completed: this.additionalInfoForm.controls.works_completed.value,
        next_steps: this.additionalInfoForm.controls.next_steps.value,
      }
      /* let selectedImage = this.imageDocuments.find(val=>(val.isDefault == true));
      if(selectedImage){
        json.invoice_img = selectedImage.id;
      } */
      let response = await this.wfService.updateRecord('clientinvoice', json);
      if (response) {
        this.messageService.add({ severity: 'success', summary: 'Successful', detail: 'Saved Successfully', life: 1000 });
      }
    }
  }
  getUploadImages() {
    this.imageDocuments = [];
    this.CrudService.getUploadImgsByJobIdAllocId(this.jobId, this.jobAllocationId).subscribe((data: any[]) => {
      if (data) {
        data.forEach(imagedocument => {
          if (imagedocument.img_path != null && imagedocument.img_path !== '') {
            this.CrudService.getImageFile(imagedocument.img_path).subscribe(blob => {
              imagedocument.blob = blob;
              let objectURL = URL.createObjectURL(blob);
              imagedocument.objUrl = objectURL;
              let image_url = this.sanitizer.bypassSecurityTrustUrl(objectURL);
              imagedocument.image_url = image_url;
              imagedocument.isDefault = false;
              if (imagedocument.id === this.invoiceInfo.invoice_img) {
                imagedocument.isDefault = true;
              }
              this.imageDocuments.push(imagedocument);
            });
          }
        });
      }
    })
  }

  tagConfig;
  initTab() {
    this.tagConfig = [];
    let l1 = [];
    let l2 = [];
    this.wizardMetaData.forEach(w => {
      l1.push(document.getElementById(w.id));
      l2.push(document.getElementById(w.id + '-info-tab-activate'));
      this.tagConfig.push({
        id: w.id,
        activeTab: document.getElementById(w.id),
        activeContent: document.getElementById(w.id + '-info-tab-activate')
      })
    })
    function initializeTabs(config) {
      config.forEach(c => {
        document.getElementById(c.id)?.addEventListener('click', () => {
          l1.forEach((ele) => {
            ele?.classList?.remove('active');
          });
          c.activeTab?.classList?.add('active');

          l2.forEach((ele) => {
            if (ele && ele.length > 0) {
              for (let i = 0; i < ele.length; i++) {
                ele[i]?.classList?.add('hideme');
              }
            }
          });
          if (c.activeContent && c.activeContent.length > 0) {
            for (let i = 0; i < c.activeContent.length; i++) {
              c.activeContent[i]?.classList?.remove('hideme');
            }
          }

        });
      })
    }
    initializeTabs(this.tagConfig);
  }


  updateTab(wiz) {
    this.tagConfig?.forEach(c => {
      if (c.content && c.id === wiz.id) {
        this[c.content] = true;
      }
    });
    this.wizardMetaData.forEach((w) => {
      w.active = false;
      if (wiz.code === w.code) {
        w.active = true;
        w.class = 'active';
      } else {
        w.class = '';
      }
    })
    let activeTabs = this.wizardMetaData.filter(val => (val.active === true));
    this.activeTab = activeTabs[0];
  }

  imageSelected(image) {
    let index = this.imageDocuments.findIndex(val => (val.isDefault == true && val.id != image.id));
    if (index > -1) {
      this.imageDocuments[index].isDefault = false;
    }
    image.isDefault = true;
  }
  setSafety(value) {
    //console.log(value);
    this.safeProperty = value;
  }

  async printInvoice() {
    this.loading = true;

    let reportId = 'INV_324';
    let date = new Date();
    let tempTime = date.getTime();
    let fileDesc = '10065';
    /* let data = await this.JobService.loadKPIReportData(this.jobId, this.jobAllocationId, this.jobSummary, reportId);
    let res = await this.CrudService.generateNewKPIReport(this.jobId, this.jobAllocationId, data, reportId, tempTime, 1).toPromise(); */
    
    /* let imgs = await this.JobService.getImgeData(this.jobId, this.jobAllocationId, '1');
    let data = await this.JobService.loadInvoice324ReportData(this.jobId, this.jobAllocationId, imgs, this.jobSummary, reportId);
    data[0].imageList = data[0].imgs;
    //console.log(data);
    let res = await this.CrudService.generateNewKPIReport(this.jobId, this.jobAllocationId, data, reportId, tempTime, 1).toPromise();
     */
   
    let data =  await this.JobService.setINV_324_data(this.jobId, this.jobAllocationId);
    let res = await this.CrudService.generateJasperReport(this.jobId, this.jobAllocationId, data, reportId, tempTime, 1).toPromise();

    this.confirmationService.confirm({
      message: 'Do you want to view the report in separate Window?',
      header: 'Confirm',
      icon: 'pi pi-exclamation-triangle',
      acceptLabel: 'Yes, Open in new Tab',
      rejectLabel: 'No, Download the File',
      accept: () => {
        let blob = new Blob([res], { type: "application/pdf" });
        //this.pdfUrl = this.crudService.getAPIRoot() + '/' + this.jobId + '/' + this.jobAllocationId + '/' + 'report-template-' + reportId + '-' + tempTime + '.pdf';
        const objectUrl = URL.createObjectURL(blob);
        window.open(objectUrl);
        this.loading = false;
      },
      reject: () => {
        import('file-saver').then(FileSaver => {
          let blob = new Blob([res], { type: "application/pdf" });
          const a = document.createElement('a')
          const objectUrl = URL.createObjectURL(blob)
          a.href = objectUrl
          a.download = 'KPI Report (' + this.jobAllocationId + ')';
          a.click();
          URL.revokeObjectURL(objectUrl);
          this.loading = false;
        })
      }
    })
  }
}
