import { Component, OnInit, Input } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { ConfirmationService, MessageService } from 'primeng/api';
import { CRUDService } from 'src/app/utilities/crud.service';
import { jobSchedule, VisitType, communicationType, Status } from './job-schedule';
import { JobScheduleCreateComponent } from '../job-schedule-create/job-schedule-create.component';
import { DynamicDialogRef, DialogService, DynamicDialogConfig } from 'primeng/dynamicdialog';
import { ScheduleAdminComponent } from '../schedule-admin/schedule-admin.component';
import { JobScheduleCreateService } from '../job-schedule-create/job-schedule-create.service'
import { JOBService } from 'src/app/utilities/job.service';
import { environment } from 'src/environments/environment';
import { async } from '@angular/core/testing';
import { debug } from 'console';


@Component({
  selector: 'app-job-schedule',
  templateUrl: './job-schedule.component.html',
  styleUrls: ['./job-schedule.component.scss'],
  providers: [DialogService, MessageService, JOBService, ConfirmationService, JobScheduleCreateComponent, ScheduleAdminComponent]
})
export class JobScheduleComponent implements OnInit {
  paginatorFlag: boolean = true;
  paginationvalue: any;
  communication: boolean = false;
  @Input() jobId: any
  @Input() jobAllocationId: any;
  @Input() jobSummary: any;
  @Input() selectedJobAllocId: any;
  showHeader: Boolean = false;
  visitType: VisitType[];
  jobschedule: any
  contactUI: any;
  date: Date;
  currentUser: any;
  jobScheduleDetails: any[]
  scheduleJob: any;
  viewType: any;
  menuData: any;
  createNew: any;
  fullList: any[] = [];
  AllocationList: any[];
  type: 'Schedule';
  emailEventData : any;
  mailDetailsDialog : Boolean = false;
  loading : Boolean = false;
  selectedMailData : any;
  dateFormat : any;
  showtype : any;
  filterTypes = [
    { id: '1', title: 'Schedule', selected: false }, 
    { id: '11', title: 'Comms', selected: false }, 
    { id: '2', title: 'Client', selected: false },
    { id: '3', title: 'Enviro', selected: false }, 
    { id: '4', title: 'Site Contact', selected: false }, 
    { id: '5', title: 'TP', selected: false },
    { id: '55', title: 'TM Internal/TP From APP', selected: false }, 
    { id: '6', title: 'All', selected: true },]
  /*       { id: '4', title: 'Client', selected:false },{ id: '5', title: 'Enviro', selected:false }, { id: '6', title: 'Site Contact', selected:false }, { id: '7', title: 'TP', selected:false } */

  constructor(
    private route: ActivatedRoute,
    private crudService: CRUDService,
    private messageService: MessageService,
    private confirmationService: ConfirmationService,
    private router: Router,
    public ref: DynamicDialogRef,
    public config: DynamicDialogConfig,
    public dialogService: DialogService,
    public JobScheduleCreateService: JobScheduleCreateService,
    private JobService: JOBService
  ) {
    this.viewType = 'TM';
    this.currentUser = JSON.parse(sessionStorage.getItem('currentUser'));
  }

  async ngOnInit() {
    this.paginationvalue = environment.paginatorValue;
    this.dateFormat = environment.DateFormat;

    this.jobSummary = this.config.data.jobSummary;
   // console.log('this.jobSummary111',this.jobSummary)
    if (typeof this.jobId === 'undefined') {
      if (typeof this.config.data == 'undefined' || typeof (this.config.data.jobId) == 'undefined') {
        if (this.jobId == null || this.jobId === '') {
          this.jobId = parseInt(this.route.snapshot.paramMap.get('jobId'));
          if (this.jobAllocationId === null || this.jobAllocationId === '') {
            this.jobAllocationId = '1';
          } else {
            this.jobAllocationId = parseInt(this.route.snapshot.paramMap.get('jobAllocationId'));
            this.jobSummary = this.crudService.getDataByField('JobInfo', 'id', this.jobId);
          }
        }
        this.showHeader = false;
      } else {
        this.jobId = this.config.data.jobId;
        this.jobAllocationId = this.config.data.jobAllocationId;
        this.jobSummary = this.config.data.jobSummary;

       // console.log('this.jobSummary111',this.jobSummary)
        this.showtype = this.config.data.type;
        this.showHeader = true;
        this.selectedJobAllocId = this.config.data.selectedJobAllocId;
      }
    }
    this.emailEventData = await this.crudService.getEmailEvents(this.jobId).toPromise();
    if(this.jobAllocationId == '1'){
      await this.fetchAllAllocation();
    }else{
      this.jobSchedule();
    }
  }

  async jobSchedule() {
    this.jobschedule = true;
    this.crudService.getByJobIdAllocId(this.jobId, this.jobAllocationId).subscribe(async (data: any[]) => {
      this.fullList = data;
      this.jobScheduleDetails = data;
      this.jobScheduleDetails.sort((a, b) => a.pin_state > b.pin_state && -1 || 1);
      // let filterTypes = this.filterTypes.filter((val) => val.id == this.showtype);
      // await this.filter(filterTypes[0]);

      if (this.jobScheduleDetails.length == 0) {
        this.paginatorFlag = false;
      }
      else {
        this.paginatorFlag = true;
      }
      this.jobScheduleDetails.forEach(element => {
        if (element.sched_note?.length > 300) {
          element.short_sched_note = element.sched_note?.slice(0, 300);
          element.showFlag = false;
        } else {
          element.showFlag = true;
        }
        const maildelivery = this.emailEventData.filter(val => val.ref_id === element.id);
        element.mailDeliveryData = maildelivery;
        if(maildelivery.length>0){
          element.mailReceipt = true;
        }else{
          element.mailReceipt = false;
        }
        // element.sched_date= element.sched_date.split("T")[0]+' '+element.sched_date.split("T")[1].split(".")[0]
        // element.created_at= element.created_at.split("T")[0]+' '+element.created_at.split("T")[1].split(".")[0]
        // element.created_at=this.DateToLocalTimeString(element.created_at);
        if (element.phoned == '2') {
          this.contactUI = "Phoned";
        }
        if (element.phone_no) {
          this.contactUI + '-' + element.phone_no;
        }
        if (element.email == '2') {
          this.contactUI + " Email";
        }
        if (element.emailaddress) { this.contactUI + '-' + element.emailaddress; }
        if (element.sms == '2') {
          this.contactUI + " SMS";
        }
        if (element.sms_no) {
          this.contactUI + '-' + element.sms_no;
        }
        if (element.callback == '2') {
          this.contactUI + " Callback";
        }
        if (!this.contactUI) {
          this.contactUI = 'N/A';
        }

        /* if(element.status=='1'){
          element.status_title = 'Scheduled';
        }else if(element.status=='2'){
          element.status_title = 'Completed';
        }else if(element.status=='3'){
          element.status_title = 'Cancelled';
        }else{
          element.status_title = 'N/A'
        } */

        if (element.PMOnly === '2') {
          element.status_title = 'PM Only';
        } else {
          element.status_title = '';
        }

        if (element.visit_type == '1') {
          if(environment.hosting == 'UK'){
            element.visit_type_title = 'Arb Site Inspection';
          }
          else{
            element.visit_type_title = 'Site Inspection';
          }
        } else if (element.visit_type == '2') {
          if(environment.hosting == 'UK'){
            element.visit_type_title = 'Quote Inspection';
          }
          else{
            element.visit_type_title = 'Make Safe';
          }
        } else if (element.visit_type == '3') {
          element.visit_type_title = 'Perform Works';
        } else {
          element.visit_type = '';
          element.visit_type_title = 'N/A'
        }
        element.sched_date = new Date(element.sched_date).toDateString();
      });
    })
    this.communication = true;
   
    /* this.crudService.getDataByField('Communications', 'id', this.jobId).subscribe((data: any) => {
      //console.log(data);
    }) */
  }


  findIndexById(id: string): number {
    let index = -1;
    for (let i = 0; i < this.jobScheduleDetails.length; i++) {
      if (this.jobScheduleDetails[i].id === id) {
        index = i;
        break;
      }
    }

    return index;
  }

  complete(detail) {
    detail.status = "2";
    //console.log(detail);
    this.crudService.updateScheduleStatus(detail.id, '3', 'Jobschedule').subscribe((data: any) => {
      //console.log(data);
      if (data.success === '1') {
        this.jobScheduleDetails[this.findIndexById(detail.id)] = detail
      }
    })
  }

  cancel(detail) {
    detail.status = "3";
    //console.log(detail);
    this.crudService.updateScheduleStatus(detail.id, '2', 'Jobschedule').subscribe((data: any) => {
      //console.log(data);
    })
  }


  updatePMOnly(detail, status) {
    this.confirmationService.confirm({
      message: 'Are you sure you want to update the selected record?',
      header: 'Confirm',
      icon: 'pi pi-exclamation-triangle',
      accept: () => {
        detail.PMOnly = status;
        this.crudService.updatePMOnly(detail.id, status).subscribe((data: any) => {
        })
      }
    })
  }

  deleteSchedule(detail) {
    this.confirmationService.confirm({
      message: 'Are you sure you want to delete the selected Schedule?',
      header: 'Confirm',
      icon: 'pi pi-exclamation-triangle',
      accept: () => {
        this.crudService.Delete(detail.id, 'JobSchedule').subscribe((data: any) => {
          //console.log(data)
          if (data.success == 1) {
            this.messageService.add({ severity: 'success', summary: 'Successful', detail: 'Schedule Deleted', life: 1000 });
            this.jobScheduleDetails = this.jobScheduleDetails.filter((val: any) => val.id !== detail.id)
          }
        })
      }
    })
  }

  DateToLocalTimeString(d) {
    let date = new Date(d);
    d = date.toLocaleDateString('en-GB');
    let t = date.toLocaleTimeString();
    return d + ' ' + t;
  }

  openNew(type) {
   // console.log('this.jobSummary neww',this.jobSummary)
    this.type = type;
    this.createNew = true;
    const ref = this.dialogService.open(ScheduleAdminComponent, {
      data: {
        jobAllocationId: this.jobAllocationId,
        jobId: this.jobId,
        type: type,
        jobSummary: this.jobSummary
      },
      header: 'Job Schedule',
      width: '100%',
      height: '100%'
    });
    ref.onClose.subscribe((response: any) => {
      this.jobSchedule();
    });
  }

  hideNew() {
    if (this.viewType === 'TM') {
      this.createNew = false;
    }
  }

  filter(item) {
   
    this.jobScheduleDetails = this.fullList;
    this.filterTypes.forEach(val => {
      if (val.title === item.title) {
        val.selected = true;
        if (val.id === '6') {
        } 
        else if (val.id === '11') { 
          this.jobScheduleDetails = this.jobScheduleDetails.filter((val: any) => val.visit_type == '' || null);
        }
        else if (val.id === '1') {
          this.jobScheduleDetails = this.jobScheduleDetails.filter((val: any) => val.visit_type != '');
        } 
        else if (val.id === '2') {
          this.jobScheduleDetails = this.jobScheduleDetails.filter((val: any) => val.comm_recipient == '10137');
        } else if (val.id === '3') {
          this.jobScheduleDetails = this.jobScheduleDetails.filter((val: any) => val.comm_recipient == '10138');
        } else if (val.id === '4') {
          this.jobScheduleDetails = this.jobScheduleDetails.filter((val: any) => val.comm_recipient == '10135');
        } else if (val.id === '5') {
          this.jobScheduleDetails = this.jobScheduleDetails.filter((val: any) => val.comm_recipient == '10136');
        } else if (val.id === '55') {
          this.jobScheduleDetails = this.jobScheduleDetails.filter((val: any) => val.comm_recipient == 'undefined');
        }
      } else {
        val.selected = false;
      }
    });
  }

  allocationList: any;
  showAllocationList = false;
  selectedJobAllocation: any;

  async fetchAllAllocation() {
    this.allocationList = await this.crudService.getAllAllocatedJobByJobId(this.jobId).toPromise();    
    console.log("this.allocationList",this.allocationList);  
    console.log("this.this.config.data.jobAllocationId.id;",this.config.data.jobAllocationId);  
    debugger
    if(this.config.data.jobAllocationId){
      this.selectedJobAllocation  = this.allocationList.find(val =>val.id == this.config.data.jobAllocationId)
    }
    else if (this.allocationList.length == 1) {
      this.selectedJobAllocation = this.allocationList[0]
    }
    if (this.allocationList.length > 0) {
      this.showAllocationList = true;
    } else {
      this.showAllocationList = false;
    }
    this.allocationList.push({ id: '1', business_name: 'Common' })
    if (this.jobAllocationId == '1') {
      if(this.selectedJobAllocId!='' && this.selectedJobAllocId!=null){
        this.jobAllocationId = this.selectedJobAllocId;
      }
      // else{
      //   this.jobAllocationId = this.allocationList[0].id;
      //   this.selectedJobAllocation =this.allocationList[0]
      // }
    }
    if ( this.allocationList.length == 1 || (this.allocationList.length > 2 && !this.config.data.jobAllocationId ) ) {
      this.selectedJobAllocation =this.allocationList[0]
    }
    if(this.config.data.jobAllocationId == '1'){
      this.selectedJobAllocation = this.allocationList.find(val => (val.id == '1'));
    }
    this.changeAllocation();
  }

  changeAllocation() {
    console.log("this.selectedJobAllocation.id;",this.selectedJobAllocation);
    
    debugger
    this.jobAllocationId = this.selectedJobAllocation.id;
    this.config.data.jobAllocationId = this.selectedJobAllocation.id;
    this.jobSchedule();
  }
  viewMailDetails(mailData)
  {
    this.selectedMailData = mailData;
    this.mailDetailsDialog = true;
  }
  changepinstate(detail){
    if(detail.pin_state == 0){
      this.confirmationService.confirm({
        message: 'Do you want to pin this conversation ?',
        header: 'Confirm',
        icon: 'pi pi-exclamation-triangle',
        accept: () => {
         
          this.crudService.updatePinstateOnly(detail.id, 1).subscribe((data: any) => {
            detail.pin_state = 1
            this.jobScheduleDetails.sort((a, b) => a.pin_state > b.pin_state && -1 || 1);
          })
        }
      })  
    }
    else if(detail.pin_state == 1){
      this.confirmationService.confirm({
        message: 'Do you want to unpin this conversation ?',
        header: 'Confirm',
        icon: 'pi pi-exclamation-triangle',
        accept: () => {
          this.crudService.updatePinstateOnly(detail.id, 0).subscribe((data: any) => {
            detail.pin_state = 0
            this.jobSchedule()
          })
        }
      })
     
    }
 
  }
}

/*
{ id: '1', title: 'Schedule', selected:false },{ id: '2', title: 'Client', selected:false  },{ id: '3', title: 'Enviro', selected:false  },
                 { id: '4', title: 'Site Contact', selected:false  },{ id: '5', title: 'TP', selected:false},{ id: '5', title: 'All', selected:true  }, */