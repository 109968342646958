import { Component, Renderer2 } from '@angular/core';
import { MenuService } from './app.menu.service';
import { CRUDService } from './utilities/crud.service';
import { trigger, state, style, transition, animate } from '@angular/animations';
import { PrimeNGConfig } from 'primeng/api';
import { Router } from '@angular/router';
import { NotificationComponent } from './modules/notification/notification.component';
import { DialogService, DynamicDialogRef } from 'primeng/dynamicdialog';
import { ComponentsComunicationService } from './components-comunication.service';
import { OpenNotificationComponent } from './modules/open-notification/open-notification.component';
import { CookieService } from 'ngx-cookie';
import { CommonService } from './utilities/common.service';
import { Subscription } from 'rxjs';



@Component({
    selector: 'app-main',
    templateUrl: './app.main.component.html',
    styleUrls: ['./style.css'],
    animations: [
        trigger('mask-anim', [
            state('void', style({
                opacity: 0
            })),
            state('visible', style({
                opacity: 0.8
            })),
            transition('* => *', animate('250ms cubic-bezier(0, 0, 0.2, 1)'))
        ])
    ]
})
export class AppMainComponent {

    // horizontalMenu: boolean;
    horizontalMenu: boolean = false;

    darkMode = false;

    // menuColorMode = 'light';

    menuColorMode = 'dark';

    menuColor = 'layout-menu-dark';

    themeColor = 'blue';

    layoutColor = 'blue';

    rightPanelClick: boolean;

    rightPanelActive: boolean;

    menuClick: boolean;

    staticMenuActive: boolean = false;

    menuMobileActive: boolean;

    megaMenuClick: boolean;

    megaMenuActive: boolean;

    megaMenuMobileClick: boolean;

    megaMenuMobileActive: boolean;

    topbarItemClick: boolean;

    topbarMobileMenuClick: boolean;

    topbarMobileMenuActive: boolean;

    sidebarActive: boolean;

    activeTopbarItem: any;

    topbarMenuActive: boolean;

    menuHoverActive: boolean;

    configActive: boolean;

    configClick: boolean;

    ripple: boolean = true;

    inputStyle = 'outlined';

    userSettings: any;
    socket: any;
    menuData: any;
    constructor(public renderer: Renderer2, private menuService: MenuService, private primengConfig: PrimeNGConfig,private commonservice: CommonService,
        private cookieService: CookieService, private router: Router, private CrudService: CRUDService, private dialogService: DialogService, private componentsComunicationService: ComponentsComunicationService, private dialofRef: DynamicDialogRef) {
    }

    ngOnInit() {
        this.socket = this.componentsComunicationService.initializeSocket();
        this.primengConfig.ripple = true;
        let loggedInUser = localStorage.getItem('loggedInUser');
        let company_id = localStorage.getItem('company_id');
       let auth_token = localStorage.getItem('auth_token');
       let user_id = localStorage.getItem('user_id');
                if (loggedInUser != '') {
                    sessionStorage.setItem('loggedInUser', loggedInUser);  
                    sessionStorage.setItem('company_id',company_id);
                    sessionStorage.setItem('auth_token', auth_token);
                    sessionStorage.setItem('user_id',user_id);
                } 
                this.userSettings = JSON.parse(localStorage.getItem('loggedInUser'));


                if (this.userSettings == 'null' || this.userSettings == null || this.userSettings == '') {
                   // this.router.navigate(['/login']);
                } else {
                    if (sessionStorage.getItem('company_id') !== null && sessionStorage.getItem('company_id') !== '') {
                        if (this.userSettings.menu_colour) {
                            this.menuColor = this.userSettings.menu_colour;
                        }
                        if (this.userSettings.layout_colour) {
                            this.layoutColor = this.userSettings.layout_colour;
                        }
                        if (this.userSettings.colour) {
                            this.themeColor = this.userSettings.colour;
                        }
                        if (this.userSettings.menu_type === '2') {
                            this.horizontalMenu = false;
                        } else {
                            this.horizontalMenu = true;
                        }
                        if (this.userSettings.colour_scheme === 'dark') {
                            this.darkMode = true;
                            this.menuColorMode = 'light';
                            this.changeLightDarkLayout('layout-css', this.userSettings.layout_colour, 'layout-dark');
                            this.changeLightDarkTheme('theme-css', 'theme-dark');
                        } else {
                            this.darkMode = false;
                            this.menuColorMode = 'dark';
                            this.changeLightDarkLayout('layout-css', this.userSettings.layout_colour, 'layout-light');
                            this.changeLightDarkTheme('theme-css', 'theme-light');
                        }
        
        
                        setTimeout(() => {
                        }, 5000);
                    } else {
                        this.router.navigate(['/login']);
                    }
                    this.socket?.emit('join', this.userSettings.id)
                }

       
    }
    logDates() {
        //console.log('New Date', new Date());
        //console.log('toLocaleDateString', new Date().toLocaleDateString());
        //console.log('toLocaleString', new Date().toLocaleString());
        //console.log('toLocaleTimeString', new Date().toLocaleTimeString());
        //console.log('ISO', new Date().toISOString());
        //console.log('DateString', new Date().toDateString());
        //console.log('TimeString', new Date().toTimeString());
        //console.log('UTC', new Date().toUTCString());
        //console.log('String', new Date().toString());
        //console.log('UTCDate', new Date().getUTCDate());
        //console.log('UTCDate', new Date().getUTCDate());

    }

    onLayoutClick() {
        let update = localStorage.getItem("update");
        if (!this.topbarItemClick) {
            if (update !== 'false') {
                this.activeTopbarItem = null;
            } else {
                localStorage.setItem("update", '');
            }

            this.topbarMenuActive = false;
        }

        if (!this.rightPanelClick) {
            this.rightPanelActive = false;
        }

        if (!this.megaMenuClick) {
            this.megaMenuActive = false;
        }

        if (!this.megaMenuMobileClick) {
            this.megaMenuMobileActive = false;
        }

        if (!this.menuClick) {
            if (this.isHorizontal()) {
                this.menuService.reset();
            }

            if (this.menuMobileActive) {
                this.menuMobileActive = false;
            }

            this.menuHoverActive = false;
        }

        if (this.configActive && !this.configClick) {
            this.configActive = false;
        }

        this.configClick = false;
        this.menuClick = false;
        this.topbarItemClick = false;
        this.megaMenuClick = false;
        this.megaMenuMobileClick = false;
        this.rightPanelClick = false;
    }

    onMegaMenuButtonClick(event) {
        this.megaMenuClick = true;
        this.megaMenuActive = !this.megaMenuActive;
        event.preventDefault();
    }

    onMegaMenuClick(event) {
        this.megaMenuClick = true;
        event.preventDefault();
    }

    onTopbarItemClick(event, item) {
        this.topbarItemClick = true;

        if (this.activeTopbarItem === item) {
            this.activeTopbarItem = null;
        } else {
            this.activeTopbarItem = item;
        }

        event.preventDefault();
    }

    /* openNotificationModal() {
        const config = {
            width: '70%',
            height: 'max-content',
            panelClass: 'notification-modal'
        }
        const ref: any = this.dialog.open(NotificationComponent, config)
        //console.log(ref)
        localStorage.setItem("ref", ref)
        ref.onClose.subscribe((data: any) => {
            if (data) {
                // //console.log(data.ref_id)
                // data.ref_id = 179
                // this.CrudService.getDataByField('task_head', 'id', data.ref_id).subscribe((checkListData: any[]) => {
                //     //console.log(checkListData)
                // });
                // ref.unsubscribe
                // this.openModal(data.ref_id);
            }
            this.dialofRef.close();
        });
    }
    openModal(id: any) {
        const dialogRef = this.dialog.open(OpenNotificationComponent, {
            width: '250px',
        });

        dialogRef.afterClosed().subscribe(result => {
            //console.log('The dialog was closed');
            this.openNotificationModal();
        });

    } */
    onRightPanelButtonClick(event) {
        this.rightPanelClick = true;
        this.rightPanelActive = !this.rightPanelActive;

        event.preventDefault();
    }


    signOut(event) {
        sessionStorage.clear();
        localStorage.clear();
        this.cookieService.removeAll();
        sessionStorage.setItem('loggedInUser', null);
        sessionStorage.setItem('currentUser',null);
        sessionStorage.setItem('auth_token', null);
        sessionStorage.setItem('user_id',null);
        this.router.navigate(['/login']);
        event.preventDefault();
    }


    onRightPanelClose(event) {
        this.rightPanelActive = false;
        this.rightPanelClick = false;

        event.preventDefault();
    }

    onRightPanelClick(event) {
        this.rightPanelClick = true;

        event.preventDefault();
    }

    onTopbarMobileMenuButtonClick(event) {
        this.topbarMobileMenuClick = true;
        this.topbarMobileMenuActive = !this.topbarMobileMenuActive;

        event.preventDefault();
    }

    onMegaMenuMobileButtonClick(event) {
        this.megaMenuMobileClick = true;
        this.megaMenuMobileActive = !this.megaMenuMobileActive;

        event.preventDefault();
    }

    onMenuButtonClick(event) {
        this.menuClick = true;
        this.topbarMenuActive = false;

        if (this.isMobile()) {
            this.menuMobileActive = !this.menuMobileActive;
        }

        event.preventDefault();
    }

    onSidebarClick(event: Event) {
        this.menuClick = true;
    }

    onToggleMenuClick(event: Event) {
        this.staticMenuActive = !this.staticMenuActive;
        event.preventDefault();
    }

    onConfigClick(event) {
        this.configClick = true;
    }

    onRippleChange(event) {
        this.ripple = event.checked;
    }

    isDesktop() {
        return window.innerWidth > 991;
    }

    isMobile() {
        return window.innerWidth <= 991;
    }

    isHorizontal() {
        return this.horizontalMenu === true;
    }


    changeLightDarkTheme(id, value) {
        const element = document.getElementById(id);
        const urlTokens = element.getAttribute('href').split('/');
        urlTokens[urlTokens.length - 1] = value + '.css';
        const newURL = urlTokens.join('/');

        this.replaceLink(element, newURL);
    }

    changeLightDarkLayout(id, color, mode) {
        const element = document.getElementById(id);
        const urlTokens = element.getAttribute('href').split('/');
        urlTokens[urlTokens.length - 2] = color;
        urlTokens[urlTokens.length - 1] = mode + '.css';
        const newURL = urlTokens.join('/');

        this.replaceLink(element, newURL);
    }

    changeComponentTheme(event, color) {
        this.themeColor = color;
        this.changeStyleSheetsColor('theme-css', color);

        event.preventDefault();
    }

    changeStyleSheetsColor(id, value) {
        const element = document.getElementById(id);
        const urlTokens = element.getAttribute('href').split('/');
        urlTokens[urlTokens.length - 2] = value;
        const newURL = urlTokens.join('/');

        this.replaceLink(element, newURL);
    }

    onConfigButtonClick(event) {
        this.configActive = !this.configActive;
        event.preventDefault();
    }

    onConfigCloseClick(event) {
        this.configActive = false;
        event.preventDefault();
    }

    isIE() {
        return /(MSIE|Trident\/|Edge\/)/i.test(window.navigator.userAgent);
    }

    replaceLink(linkElement, href) {
        if (this.isIE()) {
            linkElement.setAttribute('href', href);
        }
        else {
            const id = linkElement.getAttribute('id');
            const cloneLinkElement = linkElement.cloneNode(true);

            cloneLinkElement.setAttribute('href', href);
            cloneLinkElement.setAttribute('id', id + '-clone');

            linkElement.parentNode.insertBefore(cloneLinkElement, linkElement.nextSibling);

            cloneLinkElement.addEventListener('load', () => {
                linkElement.remove();
                cloneLinkElement.setAttribute('id', id);
            });
        }
    }

}
