import { Injectable } from '@angular/core';
import * as CryptoJS from 'crypto-js';

@Injectable({
  providedIn: 'root'
})

export class EncrDecrService {
  constructor() { }
  //The set method is use for encrypt the value.
  set(keys, value){
    var key = CryptoJS.enc.Utf8.parse(keys);
    var iv = CryptoJS.enc.Utf8.parse(keys);
    var encrypted = CryptoJS.AES.encrypt(CryptoJS.enc.Utf8.parse(value.toString()), key,
    {
        keySize: 128 / 8,
        iv: iv,
        mode: CryptoJS.mode.CBC,
        padding: CryptoJS.pad.Pkcs7
    });

    return encrypted.toString();
  }

  //The get method is use for decrypt the value.
  get(keys, value){
    var key = CryptoJS.enc.Utf8.parse(keys);
    var iv = CryptoJS.enc.Utf8.parse(keys);
    var decrypted = CryptoJS.AES.decrypt(value, key, {
        keySize: 128 / 8,
        iv: iv,
        mode: CryptoJS.mode.CBC,
        padding: CryptoJS.pad.Pkcs7
    });

    return decrypted.toString(CryptoJS.enc.Utf8);
  }

  encrypt(str:string, key) {
    let encrypted = CryptoJS.AES.encrypt(str,key).toString()
    var encoded = CryptoJS.enc.Base64.parse(encrypted).toString(CryptoJS.enc.Hex);
    return encoded
  }
  
  decrypt (encrypted:string,key) {
    var decoded = CryptoJS.enc.Hex.parse(encrypted).toString(CryptoJS.enc.Base64);
    var decrypted = CryptoJS.AES.decrypt(decoded, key).toString(CryptoJS.enc.Utf8);
    return decrypted
  }

  jsEncode(param: string){
    return encodeURIComponent(param);
  }

  jsDecode(param: string){
    return decodeURIComponent(param);
  }
}