import { Component, OnInit } from '@angular/core';
import { CRUDService } from 'src/app/utilities/crud.service';
import { Router } from '@angular/router';
import { MessageService } from 'primeng/api';
import { CookieService } from 'ngx-cookie';
import { HttpClientModule, HttpClient, HttpHeaders, HttpParams } from "@angular/common/http";
import { stringify } from 'querystring';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { DialogService, DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { RequestResetComponent } from "../request-reset/request-reset.component";
import io from 'socket.io-client';
import { ComponentsComunicationService } from '../components-comunication.service';
import { environment } from 'src/environments/environment';
import { switchMap } from 'rxjs/operators';
import { CommonService } from 'src/app/utilities/common.service';
import * as moment from 'moment';
@Component({
  selector: 'app-login',
  templateUrl: './app.login.component.html',
  styleUrls: ['./app.login.component.css'],

  providers: [DialogService, MessageService]
})
export class AppLoginComponent implements OnInit {

  socket: any;
  dark: boolean;
  checked: boolean;
  username: string;
  password: string;
  response: string;
  userData: any;
  rememberme: boolean;
  currentUser: any;
  displayModal: boolean;
  companyInfo: any;
  loading: Boolean = false;
  forgotPasswordPanel: boolean = false;
  email: any = null;
  requestReset: Boolean = false;
  mailStatus: any;
  form = new FormGroup({
    username: new FormControl(''),
    password: new FormControl(''),
    email: new FormControl('')
  });
  environment: any;
  checkActivationStatus: boolean = false;
  showcontent: boolean = false;
  ipAddress:any;
  userIP: any;
  private apiUrl = 'http://ip-api.com/json';
  address: any = {};
  latitude: any;
  longitude: any;

  constructor(private router: Router,
    private CrudService: CRUDService, private messageService: MessageService,
    private cookieService: CookieService, private http: HttpClient,
    public ref: DynamicDialogRef,
    public config: DynamicDialogConfig,
    public dialogService: DialogService, private commonservice: CommonService,
    private componentsComunicationService: ComponentsComunicationService) {
    this.http.get<{ any: any }>('https://jsonip.com')
      .subscribe(data => {
      //  console.log('th data', data);
        this.ipAddress = data
      })
  }

  ngOnInit(): void {
    // this.username = 'shri';
    // this.password = 'Infotech@1';
    this.environment = environment;
    if (this.environment.hosting !== 'UK') {
      this.showcontent = true
    }
    let userInfo: any = localStorage.getItem('rememberUser');
    if (userInfo) {
      userInfo = userInfo.split('/');
      this.form.controls.username.setValue(userInfo[0]);
      this.form.controls.password.setValue(userInfo[1]);
    }
  }
  checkActivation(username) {
    if (username == "test") {
      this.checkActivationStatus = true;
      this.displayModal = true;
    }
    else {
      this.checkActivationStatus = false;
    }
  }
  sendActivationLink(username) {
    this.CrudService.confirmRegistration(this.companyInfo.id, this.companyInfo.email).subscribe((mailData: any) => {
      if (mailData.success === 200 || mailData.success === '200') {
        this.checkActivationStatus = true;
        this.form.reset();
      }
    });
    this.displayModal = false;
  }
  async register() {
    (await this.CrudService.getUserLogin('login', 'Admin@123')).subscribe((data: any) => {
        this.router.navigate(['/register']);
      });
    }

  login() {
    this.username = this.form.value.username;
    this.password = this.form.value.password;
    //this.CrudService.getByUsernameAndPassword(this.username, this.password).subscribe((data: any) => {
    this.CrudService.getUserDetailslogin(this.username, this.password).subscribe((data: any) => {
      if (data.success === false) {
        sessionStorage.setItem('loggedInUser', '');
        this.messageService.add({ severity: 'error', summary: 'Username or password is incorrect', detail: 'Username or password is incorrect' });
      } else {
        /* if (data.data.length > 1) {
          this.messageService.add({ severity: 'Success', summary: 'Password Reset Suggested', detail: 'Kindly change password once you login to stay secured.', life: 4000 });
        } */
        let company_id = data.data[0].company_id;
        this.CrudService.getDataByField('company', 'id', company_id).subscribe((company_data: any) => {
          this.companyInfo = company_data[0];
          if (this.companyInfo.client_type_id == '3' && (this.companyInfo.regn_status == '1231' || this.companyInfo.regn_status == '2321')) {
            this.displayModal = true;
          } else {
            if (this.companyInfo.client_type_id == '2') {

            } else {
              this.userData = data.data[0];
              sessionStorage.setItem('loggedInUser', JSON.stringify(this.userData));
              sessionStorage.setItem('company_id', this.userData.company_id);
              sessionStorage.setItem('auth_token', this.userData.auth_token);
              sessionStorage.setItem('user_id', this.userData.id);
              localStorage.setItem('loggedInUser', JSON.stringify(this.userData));
              localStorage.setItem('company_id', this.userData.company_id);
              localStorage.setItem('auth_token', this.userData.auth_token);
              localStorage.setItem('user_id', this.userData.id);
              this.SetCredentials(this.username, this.password, parseInt(this.userData.id),
                parseInt(this.userData.company_id), this.userData.user_group_id, this.userData.Client_Type,
                this.userData.staff_id);
              if (this.userData.homepage_url !== null && this.userData.homepage_url !== '') {
                this.router.navigate([this.userData.homepage_url]);
              } else {
                this.router.navigate(['/']);
              }
              if (this.rememberme) {
                localStorage.setItem('rememberUser', `${this.username}/${this.password}`);
              }
            }
          }
        });
        //this.connectToSocket(true);
      }
    });
  }


  connectToSocket(k: any) {
    // this.socket = io('http://54.206.66.198:4003/');

    let socket_client = io('http://54.206.66.198:4003', {
      withCredentials: false
    });

    // unread count
    socket_client.on('Ezhil', (data: any) => {
      console.log(data);
      // Clear unread count - This should be moved when user click the notification icon
      socket_client.emit('Ezhil', 'Ezhil');
    });
  }

  login2() {
    /* this.CrudService.GetByUsernameAndPassword(this.username,this.password).subscribe(function (userList) {
                                
     
                                    if (userList.success == true) {
                                       var user    = userList.data[0];
                                         if (user !== null && user.user_locked ==0) {
                                           this.response = { companyId: user.company_id,success:true};
                                    
                                       
                                           //  this.SetCredentials("",user.password,user.id,user.company_id,user.user_group_id,user.Client_Type,user.staff_id);
                                  //     UserService.LoginAttempt(0, this.username);
                                    }
                                       else if (user !== null &&  user.user_locked==1) {
                                       this.response = { success: false, message: 'Account is locked. Contact Administrator' };
                                       }
                                        
                                       else if (user !== null && user.password !== this.password && user.user_locked ==0){
                                           this.response = { success: false, message: 'Username or password is incorrect' };
                                    //       UserService.LoginAttempt(user.login_attempt+1, this.username);
                                       }
                                       }
                                  //     callback(response);

                                       if (user==undefined||user.login_attempt+1 >= user.Invalid_count && response.success == false){ //if login is unsuccessful
                                       user.user_locked=1;
                                       this.response = { success: false, message: 'Username or password is incorrect' };
                                       }
                                       else if (user.login_attempt+1 < user.Invalid_count){
                                       user.user_locked=0;
                                       }

                                       if (user.login_attempt == 3 && user.user_locked==0){
                                       this.response = {success: false, message: 'Username or password is incorrect.One last attempt'};
                                       }
                                      // callback(response);
                                       }); 
         
        event.preventDefault(); */
  }
    // getLocationDetailsByIp(): Promise<any> {
      
    //   return this.http.get(this.apiUrl).toPromise();
      
    // }

    getLocation() {
      let lat;
      let lng;
      if (navigator.geolocation) {
        navigator.geolocation.getCurrentPosition((position: Position) => {
          if (position) {
            console.log(position);
            console.log("Latitude: " + position.coords.latitude +
              "Longitude: " + position.coords.longitude);
           lat = position.coords.latitude;
            lng = position.coords.longitude;
      }  }  ) } }

  // getAddressDetails() {

  //   console.log('this.latitude',this.latitude);
  //   const apiUrl = `https://nominatim.openstreetmap.org/reverse?format=json&lat=${this.latitude}&lon=${this.longitude}`;

  //   this.http.get(apiUrl).subscribe((response: any) => {
  //     console.log('add response', response)
  //     if (response.address) {
  //       this.address = {
  //         country: response.address.country || '',
  //         state: response.address.state || '',
  //         city: response.address.city || response.address.town || response.address.village || '',
  //         // Add more address components as needed
  //       };
  //     }
  //   });
  // }
 

  async SetCredentials(username, password, id, company, role, Client_Type, staffId) {
  
    let lat;
    let lng;
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition((position: Position) => {
        if (position) {
          // console.log(position);
          // console.log("Latitude: " + position.coords.latitude +
            //"Longitude: " + position.coords.longitude);
          lat = position.coords.latitude;
          lng = position.coords.longitude;
          this.latitude = lat;
          this.longitude = lng;

          var currentDate = moment(this.commonservice.getCurrentDateTime()).format('YYYY-MM-DD HH:mm:ss');
          var login = {
            // id: null,
            user_name: username,
            user_id: id,
            company_id: company,
            type: 'HTTPS',
            created_at: currentDate,
            ip_address: this.ipAddress.ip,
            location: this.ipAddress.country,
            location_lat: this.latitude,
            location_lng: this.longitude
          };
          console.log('login inn', login)
          this.CrudService.setLogin(login).subscribe((response: any) => {
            console.log('login inn data', response)
          });

          //this.getAddressDetails();
          //  var GEOCODING = 'https://maps.googleapis.com/maps/api/geocode/json?latlng=' + position.coords.latitude + '%2C' + position.coords.longitude + '&language=en';
          // var GEOCODING ='https://maps.googleapis.com/maps/api/geocode/json?latlng=11.0168445,76.9558321&key=PCWZ9-MNMMX-VBA2U-BGCUD&enable_address_descriptor=true'
          // $.getJSON(GEOCODING).done(function(location) {
          //     console.log(location)
          // })

        }
        // var url = this.apiUrl+"?latlng=" + lat + "," + lng + "&sensor=true";
        //   var url =  'http://ipinfo.io/json'
        //   this.http.get(url).subscribe((res:any)=>{

        //    console.log(res)

        //  });

      },
        (error: PositionError) => console.log(error));
    } else {
      alert("Geolocation is not supported by this browser.");
    }
   
    var authdata = btoa(username + ':' + password);
    var canExport = false;
    var margin = false;
    var showCompanyAccounts = false;
    if ((id == 9) || (id == 68) || (id == 1)) {
      canExport = true;
      margin = true;
    }
    if ((id == 509) || (id == 62) || (id == 564)) {
      margin = true;
    }
    if ((id == 62) || (id == 564) || (id == 68) || (id == 1) || (id == 608)) {
      showCompanyAccounts = true;
    }
    if (!showCompanyAccounts) {
      if (Client_Type == '3') {
        showCompanyAccounts = true;
      }
    }

    this.currentUser = {
      username: username,
      company: company,
      role: role,
      authdata: authdata,
      userId: id,
      Client_Type: Client_Type,
      staff_id: staffId,
      export: canExport,
      margin: margin,
      showCompanyAccounts: showCompanyAccounts,
    }


    sessionStorage.setItem('currentUser', JSON.stringify(this.currentUser));
    localStorage.setItem('currentUser', JSON.stringify(this.currentUser));
    this.cookieService.put('globals', this.currentUser);
    //$window.location.reload();

    /*put data in cookie*/
    let future = new Date(new Date().getTime() + (1000 * 60 * 60 * 24 * 30));
    //put it in cookie
    this.cookieService.put('company', company);
    this.cookieService.put('userId', id);
    this.cookieService.put('role', role);
    this.cookieService.put('Client_Type', Client_Type);
    this.cookieService.put('staffId', staffId);
    this.cookieService.put('globals', this.currentUser);
    this.cookieService.putObject('expires', future);
    //set default auth header for http requests
    //$http.defaults.headers.common['Authorization'] = 'Basic ' + authdata;

    // store user details in globals cookie that keeps user logged in for 1 week (or until they logout)

    /* var cookieExp = new Date();
    // cookieExp.setDate(cookieExp.getDate() + 1);
      cookieExp.setDate(cookieExp.getDate().add(30, 'm').toDate() );
     $cookies.putObject('globals', $rootScope.globals, { expires: cookieExp });*/
  }

  mailCredentials() {
    this.CrudService.resetPasswordInit(this.email).subscribe((response: any) => {
      if (response.success) {
        this.messageService.add({ severity: 'success', summary: 'Credentials Mailed', detail: 'Kindly change password once you login to stay secured.', life: 4000 });
      } else {
        this.messageService.add({ severity: 'success', summary: 'Mail Id/Account not found', detail: 'Mail ID given is not registered. Kindly register if you want an account with this mail ID.', life: 4000 });
      }
    });
  }

  viewPolicyDocument(filename) {
    import('file-saver').then(FileSaver => {
      this.CrudService.downlaodPolicyDocs(filename).subscribe(blob => {
        const a = document.createElement('a')
        const objectUrl = URL.createObjectURL(blob)
        a.href = objectUrl
        a.download = filename;
        a.click();
        URL.revokeObjectURL(objectUrl);
      })
    })
  }

  forgotPassword() {
    const ref = this.dialogService.open(RequestResetComponent, {
      data: {
      },
      header: 'Forgot Password',
      width: '525px',
      height: '50%',
      styleClass: 'centerHeader card'
    })
  }
}
