import { HttpClient } from '@angular/common/http';
import { Component, Input, OnInit } from '@angular/core';
import { ConfirmationService, MessageService } from 'primeng/api';
import { DynamicDialogConfig, DynamicDialogRef, DialogService } from 'primeng/dynamicdialog';
import { CRUDService } from 'src/app/utilities/crud.service';
import { FileSaver } from 'file-saver';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { FileUploader } from 'ng2-file-upload';

import { ActivatedRoute, Router } from '@angular/router';
import { JOBService } from 'src/app/utilities/job.service';
import { environment } from 'src/environments/environment';
import { S3Service } from 'src/app/utilities/s3.service';
import { Dialog } from 'primeng/dialog';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';

@Component({
  selector: 'app-tp-job-documents',
  templateUrl: './tp-job-documents.component.html',
  styleUrls: ['./tp-job-documents.component.scss'],

})
export class TpJobDocumentsComponent implements OnInit {
  documentValue: boolean = false
  documents: any[];
  document: any;
  description: any[];
  units = ['bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
  currentUser: any;
  addNewDocument: any;
  included_value: any = false;
  uploader: FileUploader;
  hideTP: Boolean = false;
  submitted: any;
  isDocumentUploaded: any = false;
  @Input() jobId: any;
  @Input() jobAllocationId: any;
  @Input() jobSummary: any;
  documentList: any;
  showHeader = false;
  userDetails: any;
  documentDisabled: Boolean = false;
  isEditAll: boolean = false;
  acceptFileTypes: string;
  env: any; loading: Boolean = true;
  uploadVideos: any = [];

  displayVideo: boolean = false;
  videoUrl: string = '';
  videoUploadCheck:Boolean =false;
  // displayDelete:Boolean = true;
  // hideBtn1: boolean = true;
  // hideBtn2: boolean = false;
  constructor(
    private CrudService: CRUDService,
    private messageService: MessageService,
    private confirmationService: ConfirmationService,
    public ref: DynamicDialogRef,
    public config: DynamicDialogConfig,
    private route: ActivatedRoute,
    public sanitizer: DomSanitizer,
    private router: Router, private s3Service: S3Service,
    private dialogService: DialogService,
    private http: HttpClient,
    private JobService: JOBService,
  ) {

    this.currentUser = JSON.parse(sessionStorage.getItem('currentUser'));
    this.userDetails = JSON.parse(sessionStorage.getItem('loggedInUser'));
    //console.log('data user',this.userDetails)
  }


  showDialogMaximized(event, dialog: Dialog) {
    dialog.maximized = true;
    // dialog.toggleMaximize(event);
  }

  async ngOnInit() {
    this.env = environment;
    this.acceptFileTypes = environment.acceptFiletype
    if (typeof this.jobId === 'undefined') {
      if (typeof this.config.data == 'undefined' || typeof (this.config.data.jobId) == 'undefined') {
        if (this.jobId == null || this.jobId === '') {
          this.jobId = parseInt(this.route.snapshot.paramMap.get('jobId'));
          if (this.jobAllocationId === null || this.jobAllocationId === '') {
            this.jobAllocationId = '1';
          } else {
            this.jobAllocationId = parseInt(this.route.snapshot.paramMap.get('jobAllocationId'));
            this.jobSummary = this.CrudService.getDataByField('JobInfo', 'id', this.jobId);
          }
        }
        this.showHeader = false;
      } else {
        this.jobId = this.config.data.jobId;
        this.jobAllocationId = this.config.data.jobAllocationId;
        this.jobSummary = this.config.data.jobSummary;
        this.showHeader = true;
      }
    }
    this.documentsTab();
    this.getUploadDocuments();


     this.CrudService.getDataByField('businessSettings', 'company_id', '108').subscribe(async (data: any[]) => {
       let  videoOut = data[0].videoUpload;
      // console.log('videoOut',videoOut)
       if(videoOut == 1){
        this.videoUploadCheck =true;

        let videoData = await this.CrudService.getVideosData( this.jobId, this.jobAllocationId).toPromise();
        //console.log("videoData111111", videoData);
       // let videoData = await this.CrudService.getDataByField('uploadvideos', 'job_id', this.jobId).toPromise();
        this.uploadVideos = videoData;
        this.uploadVideos.forEach(element => {
          const url: string = element.file_name; // Replace this with your actual URL
          const filename: string = url.split('/').pop() || '';
          const decodedFileName = decodeURIComponent(filename);
          element.filename = decodedFileName
        });
       }
       else{
        this.videoUploadCheck =false;
       }
     });

//console.log("uploadVideos", this.uploadVideos);

    this.uploader = new FileUploader({
      disableMultipart: true, // 'DisableMultipart' must be 'true' for formatDataFunction to be called.
      formatDataFunctionIsAsync: true,
      formatDataFunction: async (item) => {
        return new Promise((resolve, reject) => {
          resolve({
            name: item._file.name,
            length: item._file.size,
            contentType: item._file.type,
            date: new Date()
          });
        });
      }
    });
  }


  openVideo(path) {
    console.log('pathhh', path);
    this.videoUrl = path; // Set the video URL dynamically
    this.displayVideo = true; // Show the dialog
  }
  handleVideoError() {
    console.log('Error loading the video.');
    // You can add additional error handling logic here, such as displaying an error message.
  }






  documentsTab() {
    this.documentValue = true;
  }
  hideDialog() {
    this.documentValue = false;
  }

  getUploadDocuments() {
    this.documents = [];
    this.documentList = []
    this.CrudService.getUploadDocumentsByJobIdAllocId(this.jobId, this.jobAllocationId).subscribe(async (data: any) => {
      this.documents = data;

      this.documents.forEach(element => {
        if (element.company_id !== this.userDetails.user_id) {
          element.isEdit = false;
          this.documentList.push(element)
        }
      });
      console.log('documents', this.documents);
      this.documentDisabled = true;
      this.documents = await this.documents.filter(val => val.hide === "1");

      if (this.documents) {
        this.CrudService.getByValuesetdocumentdescHidefromTP().subscribe((data: any[]) => {
          data.sort((a, b) => a.valueset_detail_name > b.valueset_detail_name && 1 || -1);

          this.description = data;

          this.documents.forEach(document => {
            // //console.log(element.upload_at.split("T")[0] + element.upload_at.split("T")[1].split(".")[0]);
            // element.upload_at= element.upload_at.split("T")[0]+' '+element.upload_at.split("T")[1].split(".")[0];
            this.CrudService.getSelectedByValuesetdocumentdesc(document.file_description).subscribe((data: any) => {
              document.selectedDescription = data[0];
            })
            if (document.inc_quote == '2') {
              document.isIncluded = true;
            } else {
              document.isIncluded = false;
            }
            if (document.hide == '2') {
              document.isHidden = true;
            } else {
              document.isHidden = false;
            }
            document.sizeInKB = this.niceBytes(document.file_size);
            //document.sizeInMB = this.niceBytes(document.sizeInKB);
            document.upload_time = new Date(document.upload_at).toLocaleString();
            document.upload_time_str = new Date(document.upload_at).toLocaleString();
            document.upload_time_str2 = new Date(document.upload_at);
          });

        })
      }
      console.log('documents', this.documents);
    })
  }
  onActionChange(id, description) {
    //console.log(id, description);
    this.documents.forEach(element => {
      if (id === element.id) {
        element.file_description = description.id;
      }
    });

  }
  downloadSelectedItem(SelectedDocument) {
    import('file-saver').then(FileSaver => {
      this.CrudService.downloadDocument(SelectedDocument.file_path).subscribe({next: (blob) => {
        const a = document.createElement('a')
        const objectUrl = URL.createObjectURL(blob)
        a.href = objectUrl
        a.download = SelectedDocument.file_name;
        a.click();
        URL.revokeObjectURL(objectUrl);
      }, error: (err) => {
        this.CrudService.handleFileDownloadError(err);
      }});
    });
  }


  onFileSelected(event: any) {
    const file: File = event[0];
    const fileType: string = file.type; 
    if (!fileType.startsWith('video/')) {
      this.messageService.add({ severity: 'error', summary: 'Error', detail: 'This file type is not supported', life: 1000 });
      return;
    }
    if (file) {
      this.s3Service.uploadFile(file, this.jobId, this.jobAllocationId)
        .then((url) => {
          console.log(`File uploaded to S3: ${url}`);
          this.loading = true;
          let document = {
            // Inv: null,
            company_id: this.currentUser.company,
            created_at: new Date(),
            created_by: null,
            file_description: null,
            file_name: url,
            file_path: url,
            file_size: null,
            hide: '1',
            id: null,
            inc_quote: null,
            job_alloc_id: this.jobAllocationId,
            job_id: this.jobId,
            last_modified_by: null,
            last_updated_at: null,
            owner: null,
            process_id: this.currentUser.company,
            status: '1',
            upload_at: new Date(),
            upload_by: this.currentUser.userId,
            username: this.currentUser.username,
            upload_type: null,
            upload_type_detail: null,
          }
          this.CrudService.Create(document, 'UploadVideos').subscribe((data: any) => {
            document.id = data.id
            if (data.success == 1) {
              this.loading = false;
              this.messageService.add({ severity: 'success', summary: 'Successful', detail: 'Video File Uploaded Successfully', life: 1000 });
              this.ngOnInit()
            }
          })
        })
        .catch(error => {
          console.error('File upload failed:', error);
        });
    }
  }


  async updateVideo() {

   // console.log('UploadVideos', this.uploadVideos)
    let successStatus = true;
    for (let video of this.uploadVideos) {

      if (video.id && (video.file_description !== null && video.file_description !== "" && video.file_description !== undefined)) {
      //  console.log('video11', video)

        this.CrudService.Update(video, 'UploadVideos').subscribe((data: any) => {
          //console.log('video desc out', data)
          if (data.success == 1) {
            this.loading = false;

          }
          else {
            successStatus = false;

          }
        })
      }

    }
    if (successStatus) {
      this.messageService.add({ severity: 'success', summary: 'Successful', detail: 'Description updated', life: 1000 });
    } else {
      this.messageService.add({ severity: 'error', summary: 'Error', detail: 'Description not updated', life: 1000 });
    }

  }

  hideNewDocumentDialog() {
    this.addNewDocument = false;
  }
  onUpload(event) {
    //console.log('dataList', document)
    this.document = this.description[0];
    //this.documentDisabled = true;
    //this.hideBtn1 = false;
    //this.hideBtn2 = true;
    for (let file of event) {
      let document = {
        Inv: null,
        company_id: this.currentUser.company,
        created_at: new Date(),
        created_by: null,
        file_description: null,
        file_name: null,
        file_path: null,
        file_size: null,
        hide: '1',
        id: null,
        inc_quote: null,
        job_alloc_id: this.jobAllocationId,
        job_id: this.jobId,
        last_modified_by: null,
        last_updated_at: null,
        owner: null,
        process_id: this.currentUser.company,
        status: "1",
        upload_at: new Date(),
        upload_by: this.currentUser.userId,
        upload_type: null,
        upload_type_detail: null,
        username: this.currentUser.username,
        upload_time: null,
        upload_time_str: null,
        upload_time_str2: null,
        sizeInKB: null,
        isHidden: null,
        isEdit: true,
        // hideBtn2:true
        documentDisabled: true,
        s3_url: null,
        sync_status: 0
        // hideBtn1: false,
        // hideBtn2: true,
      }

      document.file_name = file.name
      document.isHidden = false;
      document.file_size = file.size.toString();
      document.upload_time = new Date(document.upload_at).toLocaleString();
      document.upload_time_str = new Date(document.upload_at).toLocaleString();
      document.upload_time_str2 = new Date(document.upload_at);
      document.sizeInKB = this.niceBytes(document.file_size);
      //console.log(file.size)
      if (file.size <= environment.imageDocSize) {
        //this.document.sizeInMB = this.niceBytes(this.document.sizeInKB);
        let res = this.CrudService.uploadDocument(file, this.jobId, this.jobAllocationId)
        let response = JSON.parse(res);
        //console.log(response);
        if (response.success === 1) {
          this.isDocumentUploaded = true;
          //console.log('image uploaded');
          document.file_path = this.jobId + '/' + this.jobAllocationId + '/' + file.name;
          document.file_name = file.name;
          document.s3_url = response?.s3Url;
          document.sync_status = response?.s3Url ? 1 : 0;
          this.saveNewDocumentRecord(document);
        }
        else {
          this.messageService.add({ severity: 'error', summary: 'Error', detail: 'This file type is not supported', life: 1000 });
          this.isDocumentUploaded = false
        }
      }
      else {
        this.messageService.add({ severity: 'error', summary: 'Error', detail: 'File must be < 10mb', life: 1000 });
        this.isDocumentUploaded = false
      }
    }
    //console.log('document',document)
  }

  saveNewDocumentRecord(document) {

    //console.log(document);
    this.submitted = true;
    //console.log(this.submitted, this.isDocumentUploaded);


    if (this.isDocumentUploaded === true) {
      //console.log(this.isDocumentUploaded);
      this.CrudService.Create(document, 'UploadDocuments').subscribe((data: any) => {
        document.id = data.id
        if (data.success == 1) {
          //this.getUploadDocuments();
          this.addNewDocument = false;
          this.messageService.add({ severity: 'success', summary: 'Successful', detail: 'File Uploaded Successfully', life: 1000 });
          this.documents = [document, ...this.documents];
        }
      })
    }
  }

  saveRecord() {
    let successStatus = true;
    this.isEditAll = false;
    this.documentDisabled = true;
    this.documents.forEach(document => {
      if (document.isIncluded) {
        document.inc_quote = '2';
      } else {
        document.inc_quote = '1';
      }
      if (document.isHidden) {
        document.hide = '2';
      } else {
        document.hide = '1';
      }

      this.CrudService.Update(document, 'UploadDocuments').subscribe((data: any) => {
        if (data.success == 1) {
          this.documentValue = false;
        } else {
          successStatus = false;
        }
      })
    });
    if (successStatus) {
      this.messageService.add({ severity: 'success', summary: 'Successful', detail: 'File Uploaded Successfully', life: 1000 });
    } else {
      this.messageService.add({ severity: 'error', summary: 'Error', detail: 'File Upload Unsuccessful', life: 1000 });
    }
  }

  onDescriptionChange(description) {
    this.document.file_description = description.id;
  }
  deleteDocument(document) {
    this.confirmationService.confirm({
      message: 'Are you sure you want to delete the selected document?',
      header: 'Confirm',
      icon: 'pi pi-exclamation-triangle',
      accept: () => {
        this.CrudService.Delete(document.id, 'UploadDocuments').subscribe((data: any) => {
          //console.log(data)
          if (data.success == 1) {
            this.messageService.add({ severity: 'success', summary: 'Successful', detail: 'Document Deleted', life: 1000 });
            this.documents = this.documents.filter((val: any) => val.id !== document.id)
          }
        })
      }
    })
  }

  DateToLocalTimeString(d) {
    let date = new Date(d);
    d = date.toLocaleDateString('en-GB');
    let t = date.toLocaleTimeString();
    return d + ' ' + t;
  }



  niceBytes(x) {
    let l = 0, n = parseInt(x, 10) || 0;

    while (n >= 1024 && ++l) {
      n = n / 1024;
    }
    //include a decimal point and a tenths-place digit if presenting 
    //less than ten of KB or greater units
    return (n.toFixed(n < 10 && l > 0 ? 1 : 0) + ' ' + this.units[l]);
  }
}


