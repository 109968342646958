import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { Dashboard1Component } from './dashboard1/dashboard1.component';
import { TpDashboard1Component } from './tp.dashboard1/tp.dashboard1.component';

const routes: Routes = [
  {
    path: '',
    component: Dashboard1Component
  },
  {
    path: 'tp',
    component: TpDashboard1Component
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class DashboardsRoutingModule { }
