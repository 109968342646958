import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { CommonModule, HashLocationStrategy, LocationStrategy } from '@angular/common';
import {HttpClientModule} from '@angular/common/http';
import { DashboardsRoutingModule } from './dashboards-routing.module';
import { Dashboard1Component } from './dashboard1/dashboard1.component';
import { TpDashboard1Component } from './tp.dashboard1/tp.dashboard1.component';
import { AccordionModule } from 'primeng/accordion';
import { DialogService } from 'primeng/dynamicdialog';
import { NgApexchartsModule } from 'ng-apexcharts';
import { JobModule } from '../../job/job.module';
import { ConfirmDialogModule } from 'primeng/confirmdialog';
import { DialogModule } from 'primeng/dialog';
import { DynamicDialogModule } from 'primeng/dynamicdialog';
import { DropdownModule } from 'primeng/dropdown';
import { MultiSelectModule } from 'primeng/multiselect';
import { SliderModule } from 'primeng/slider';
import { SharedModule } from '../../shared/shared.module';
import { TableModule } from 'primeng/table';
import { ToolbarModule } from 'primeng/toolbar';
import { TooltipModule } from 'primeng/tooltip';
@NgModule({
  imports: [
    CommonModule,
    DashboardsRoutingModule,
    HttpClientModule,
    AccordionModule,
    NgApexchartsModule,
    MultiSelectModule,
    DropdownModule,
    SliderModule,
    SharedModule,
    JobModule,
    ConfirmDialogModule,
    DialogModule,
    DynamicDialogModule,
    TableModule,
    ToolbarModule,
    TooltipModule,
  ],
  declarations: [Dashboard1Component,TpDashboard1Component],
  providers: [ DialogService],
  // // // providers: [
  // // //   FormManagerService, { provide: LocationStrategy, useClass: HashLocationStrategy, },
  // // //   MenuService, BreadcrumbService, CRUDService, WorkFlowService, CompressImageService, EncrDecrService,
  // // //   ImageService, JOBService, MessageService, ConfirmationService, FormManagerConfig,
  // // //   {
  // // //     provide: HTTP_INTERCEPTORS, useClass: HttpConfigInterceptor, multi: true, useValue: {
  // // //       tag: 'common'
  // // //     }
  // // //   },
  //   DynamicDialogRef, DynamicDialogConfig,
  // // // ],
  schemas: [
    CUSTOM_ELEMENTS_SCHEMA
],
})
export class DashboardsModule { }
