import { Component, OnInit, Input, ViewChild } from '@angular/core';
import { ActivatedRoute, Router, NavigationStart } from '@angular/router';
import { CRUDService } from 'src/app/utilities/crud.service';
import { ConfirmationService, MessageService } from 'primeng/api';
import { Table } from 'primeng/table';
import { DynamicDialogRef, DialogService } from 'primeng/dynamicdialog';
import { ComplianceComponent } from '../../admin/compliance/compliance.component';
import { ContactComponent } from '../../admin/contact/contact.component';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { EncrDecrService } from 'src/app/utilities/encr-decr.service';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { CommonService } from 'src/app/utilities/common.service';
import { environment } from 'src/environments/environment';

export { }; declare global { interface Window { Calendly: any; eventUrl: any; uri: any } }

@Component({
  selector: 'app-training',
  templateUrl: './training.component.html',
  styleUrls: ['./training.component.scss']
})
export class TrainingComponent implements OnInit {
  @Input() contractor_id: any;
  @Input() staffEmail: any;
  @Input() staffFirstName: any;
  @Input() staffLastName: any;
  @Input() businessName: any;
  showTraining: boolean = true;
  eventUrl: any;
  staffs: any;
  staff: any;
  company_id: any;
  eventData: any;
  eventList: any;
  showList: boolean = true;
  showCalendly: boolean = true;
  slot: any;
  createdAt: any;
  eventId: any;
  listId: any;
  cancelDialog: boolean = false;
  resDialog: boolean = false;
  reasonForm: FormGroup;
  loading: Boolean = false;
  userDetails: any;

  constructor(private crudService: CRUDService,
    private messageService: MessageService,
    private confirmationService: ConfirmationService,
    private dialogService: DialogService,
    private router: Router,
    private route: ActivatedRoute,
    public sanitizer: DomSanitizer,
    private commonservice: CommonService,
    private EncrDecr: EncrDecrService,
    private fb: FormBuilder) {

    this.reasonForm = new FormGroup({
      reason: new FormControl(''),
      reason_res: new FormControl(''),
    });
  }

  
  ngOnInit() {
    this.loadScheduleDetails();
  }

  initiated = false;
  async loadScheduleDetails() {
    console.log("contractor_id",this.contractor_id);
    this.userDetails = JSON.parse(sessionStorage.getItem('loggedInUser'));
    if (this.userDetails === null) {
      this.userDetails = JSON.parse(sessionStorage.getItem('adminUser'));
    }
    this.staffs = await this.crudService.getDataByField('CompanyStaffInfo', 'id', this.userDetails.staff_id).toPromise();
    this.staff = this.staffs[0];

    this.loading = true;
    let data: any = await this.crudService.getDataByField('ScheduledTraining', 'company_id', this.contractor_id).toPromise();
    this.eventList = data.filter(val => (val.status == '1'));
    if (this.eventList.length > 0) {
      localStorage.setItem('trainingStrength', '10');
      this.eventData = this.eventList[0];
      let evnt: any = await this.crudService.getCalendlyEvent(this.eventData.event_id).toPromise();
      if (evnt.resource.cancellation) {
        this.eventData.remarks = 'Cancelled';
        this.eventData.status = '3';
        let response: any = await this.commonservice.saveRecord('scheduledtraining', this.eventData);
        this.loading = false;
        this.loadScheduleDetails();
        this.showList = true;
        this.showCalendly = false;
      }
      else {
        this.showList = true;
        this.showCalendly = false;
      }
      this.loading = false;
    } else {

      this.showList = false;
      this.showCalendly = true;

      if(!this.initiated){

        window.Calendly.initInlineWidget({
          url: environment.calendlyUrl,
          prefill: {
            name: this.staff.first_name + ' ' + this.staff.last_name,
            email: this.staff.email,
            customAnswers: {
              a1: this.businessName
            }
          },
          parentElement: document.getElementById('calendlyinlinewidget'),
          utm: {}
        });
      }
      this.initiated = true;
      function isCalendlyEvent(e) {
        return e.origin === 'https://calendly.com' && e.data.event && e.data.event.indexOf('calendly.') === 0;
      };
      let self = this;
      window.addEventListener('message', async function (e) {
        if (isCalendlyEvent(e)) {
          if (typeof e.data.payload.event != 'undefined') {
            self.eventUrl = e.data.payload.event.uri;
            await self.saveEvent(self.eventUrl);
            await self.loadEventInfo();
          }
        }
      });
      this.loading = false;
    }
  }

  async loadEventInfo() {
    let data: any = await this.crudService.getDataByField('ScheduledTraining', 'company_id', this.contractor_id).toPromise();
    this.eventList = data.filter(val => (val.status == '1'));
    if (this.eventList.length > 0) {
      localStorage.setItem('trainingStrength', '10');
      this.eventData = this.eventList[0];
      let evnt: any = await this.crudService.getCalendlyEvent(this.eventData.event_id).toPromise();
      this.showList = true;
      this.showCalendly = false;
      this.loading = false;
    }
  }
  showDialog() {
    this.cancelDialog = true;
  }

  async cancelEvent(event_id) {
    this.loading = true;
    const reason_res = this.reasonForm.get('reason_res').value;
    this.eventData.remarks = reason_res;
    this.eventData.status = '3';
    let response: any = await this.commonservice.saveRecord('scheduledtraining', this.eventData);
    this.cancelDialog = false;
    let cancelData: any = await this.crudService.cancelCalendlyEvent(event_id, reason_res).toPromise();
    this.showCalendly = false;
    this.loading = false;
    this.showList = false;
    this.showCalendly = false;
    this.loadScheduleDetails();
  }

  async saveEvent(url) {
    debugger
    const event_id = url.split('/').pop();
    let data: any = await this.crudService.getCalendlyEvent(event_id).toPromise();
    console.log("Data", data);
    let start_time = data.resource.start_time;
    let userdata: any = await this.crudService.getCalendylUsers(event_id).toPromise();
    let userEmail = userdata.collection[0].email;
    let userName = userdata.collection[0].name;
    let startTime = new Date(start_time);
    this.eventData = { email_id: userEmail, company_id: this.contractor_id, uri: url, event_id: event_id, remarks: userName, slot: startTime, schedule_type: 'Training' };
    let response: any = await this.commonservice.saveRecord('scheduledtraining', this.eventData);
    var element = document.getElementById("calendlyinlinewidget");
    element.parentNode.removeChild(element);
    this.showCalendly = false;
    //this.loadScheduleDetails();
  }

}
