import { Component, OnInit, ViewChild } from '@angular/core';
import { Contractor, Contacts, contractorCompliance } from './contractors';
import { ActivatedRoute, Router, NavigationStart } from '@angular/router';
import { CRUDService } from 'src/app/utilities/crud.service';
import { ConfirmationService, MessageService } from 'primeng/api';
import { Table } from 'primeng/table';
import { DynamicDialogRef, DialogService, DynamicDialogConfig } from 'primeng/dynamicdialog';
import { ComplianceComponent } from '../compliance/compliance.component';
import { ContactComponent } from '../contact/contact.component';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import View from '@fullcalendar/core/View';
import { ToggleButton } from 'primeng/togglebutton';
import { CommonService } from 'src/app/utilities/common.service';
import { environment } from 'src/environments/environment';
import { DatePipe } from '@angular/common';


@Component({
  selector: 'app-contractors',
  templateUrl: './contractors.component.html',
  styleUrls: ['./contractors.component.scss'],
  providers:[DatePipe]
})
export class ContractorsComponent implements OnInit {
  paginationvalue: any;
  @ViewChild('dt') dt: Table
  @ViewChild('toggle') toggle: ToggleButton
  viewType: any;
  menuData: any;
  userDetails: any;
  companyId: any;
  status: boolean;
  isActive: boolean;
  submitted: boolean;
  contractorDialog = false;
  closeVideo: boolean = true;
  contractorCategory: string
  contractorList: any;
  stateList: any;
  cols: any[];
  exportColumns: any[];
  contractor: any;
  contractors: any[];
  paymentTermsList: any[];
  contractorTypeList: any[];
  countryList: any;
  companyList: any;
  selectedPaymentTerm: any;
  selectedContractorType: any;
  selectedCountry: any;
  selectedState: any;
  selectedContractor: any[];
  selectedCompany: any;
  companyCompliance: any;
  companyComplianceList: any;
  complianceDialog = false;
  mobNumberPattern = `^((\\+61-?)|0)?[0-9]{10}$`;
  ref: DynamicDialogRef;
  isDepot: boolean = true;
  enablActive: any = false;
  inatctiveFrom: any;
  employeSize: any[];
  selectedemployeSize: any;
  treeCrew: any[];
  selectedtreeCrew: any;
  inatctiveTo: any;
  assetsList: any[] = [];
  AssetVehicleList: any[];
  selectedAssetVehicleList: any;
  contractorId: any;
  city: string;
  selectedClient: any = null;
  clientType: any[] = [{ name: 'Contractor', id: '3' }];//{ name: 'Admin', id: '1' } , { name: 'License Product TP', id: '4' }
  selectedClientType: any;
  contractorForm: FormGroup;
  dateFormat: any;
  selectedCommType: any;
  communicationTypeList: any[];
  selectedSubType: any;
  clientSubTypeList: any[];
  selectedComplianceLevel: any;
  complianceLevelList: any[];
  exportdisplay: Boolean = false;
  loading: Boolean = false;
  searchvalue: any;
  isABNInvalid: Boolean = false;
  isBSBInvalid: Boolean = false;
  tagConfig;
  id: any;
  addNewComplianceButton: boolean;
  showDepot: Boolean = false;
  showStaff: Boolean = false;
  showJob: Boolean = false;
  showSetting: Boolean = false;
  showCompCompliance: Boolean = false;
  showEscalations: Boolean = false;
  respToggleMenu: Boolean = false;
  appProps: any;
  statusList: any;
  selectedStatus: any;
  dpContractorList: any;
  userAccess: any;
  isExport: boolean=false;
  isDelete: boolean=false;
  selectedroute: string;

  filteredPlaces: any[] = [];
  inputAddress:any = [];

  options = {
    componentRestrictions: {
      country: [environment.hosting]
    }
  }
  caption =
    {
      registered_contractors: 'Registered Contractors',
      business_name: environment.hosting == 'UK'? 'Company Name' : 'Business Name',
      trading_name: 'Trading Name',
      email: 'Email',
      phone: 'Phone',
      stateName:  environment.hosting == 'UK'? 'Suburb' : 'Region',
      status: 'Status',
      action: 'Action',
      business_ABN: environment.hosting == 'UK'? 'Company Registration Number' : 'ABN',
      client_type: 'Client Type',
      contractor_type: 'Contractor Type',
      communication_type: 'Client Communication Type',
      payment_terms: 'Payment Terms',
      client_subType: 'Client Sub-Type',
      compliance_level: 'Compliance Level',
      employee_size: 'Employee Size',
      tree_crews: 'How many Tree crews',
      street_address: 'Street Address',
      suburb: 'Suburb',
      postcode: 'Postcode',
      state: 'Select State',
      country: 'Country',
      bsb: environment.hosting == 'UK'? 'Sort Code' : 'BSB Code',
    }
    
  componentForm = {
    street_number: 'short_name',
    route: 'long_name',
    locality: 'long_name',
    sublocality_level_1: 'long_name',
    administrative_area_level_1: 'short_name',
    country: 'long_name',
    postal_code: 'short_name'
  };
  disableSavebtn: boolean = false;
  pre_account_number: any;
  pre_account_BSB: any;
  pre_account_name: any;
  vatRegisterFlag:boolean = false;

  constructor(private crudService: CRUDService,private datePipe: DatePipe,
    private messageService: MessageService,
    private confirmationService: ConfirmationService,
    private dialogService: DialogService,
    private router: Router,
    private route: ActivatedRoute,
    public config: DynamicDialogConfig,
    private commonservice: CommonService) {
    this.companyId = sessionStorage.getItem('company_id');
    this.userDetails = JSON.parse(sessionStorage.getItem('loggedInUser'));
    const navigation = router.getCurrentNavigation();

  }

  env: any;
  contactEmailIds: any[] = [];
  emailIds: any;
  dupEmail: boolean = false;
  emailsList: any;
  showError: boolean = false;
  accountsEmail: any;

  async ngOnInit() {
    this.env = environment;
    this.loading = true;
    this.dateFormat = environment.DateFormat;

   //const currentUrl = this.router.url;
   //console.log('currentUrl',currentUrl)
    this.crudService.getDataByField('businessSettings', 'company_id', '108').subscribe((data: any[]) => {
      this.accountsEmail = data[0].accountsEmail;
    });
    this.appProps = this.commonservice.getAppProperties();
    this.paginationvalue = environment.paginatorValue;
    this.selectedroute = sessionStorage.getItem('selectedRoute')

    this.menuData = JSON.parse(sessionStorage.getItem('menuData'));
    this.viewType = this.menuData.para_1;

    let per_data :any=await this.crudService.getmenupermissions()
    this.isExport = per_data.isExport;
    this.isDelete = per_data.isDelete;

    this.contractors = [];
    this.contractor = {};
    this.contractor.id = '';
    if (this.config?.data?.contractor_id != '' && this.config?.data?.contractor_id != null) {
      this.viewType = 'TM';
      this.companyId = this.config?.data?.contractor_id;
      let data: any = await this.crudService.getDataByField('Company', 'id', this.companyId).toPromise();
      if (data.length > 0) {
        this.contractorList = data[0];
        this.dpContractorList = data[0];
        this.editSelectedRecord(data[0]);
      }
    } else {
     
      if (this.viewType === 'TM') {
        this.companyList = await this.crudService.getAllCompanies().toPromise();
        let compData: any = await this.crudService.getAllTP().toPromise();
        this.contractorList = compData;
        this.dpContractorList = compData;
      } else if (this.viewType === 'TL') {
        let compData: any = await this.crudService.getForLicense(this.companyId, '5').toPromise();
        this.contractorList = compData;
        this.dpContractorList = compData;
      } else if (this.viewType === 'TP') {
        let compData: any = await this.crudService.getDataByField('Company', 'id', this.companyId).toPromise()
        if (compData.length > 0) {
          this.contractorList = compData[0];
          this.dpContractorList = compData[0];
          this.editSelectedRecord(compData[0]);
        }
      }
    }


    this.crudService.fetchValueSet('PaymentTermsList').subscribe((data: any[]) => {
      this.paymentTermsList = data;
    });
    this.crudService.fetchValueSet('ContractorTypeList').subscribe((data: any[]) => {
      this.contractorTypeList = data;
    });
    this.crudService.fetchValueSet('ClientCommType').subscribe((data: any[]) => {
      this.communicationTypeList = data;
    });

    this.crudService.getByValuesetclienttypeWP().subscribe((data: any[]) => {
      this.clientSubTypeList = data;
    });

    this.crudService.getAllComplianceLevelHead().subscribe((data: any[]) => {
      this.complianceLevelList = data;
    });

    this.statusList = [
      { name: 'ACTIVE', id: '1' },
      { name: 'IN-ACTIVE', id: '2' },
      { name: 'IN-PROGRESS', id: '3' },
      { name: 'ARCHIVED', id: '4' },
    ];

    this.stateList = await this.crudService.getAll('State').toPromise();
    this.countryList = await this.crudService.getAll('Country').toPromise();

    this.contractorList.forEach(contractor => {
      if(contractor.status == "1")
      {
        contractor.StatusName = "ACTIVE";
      }
      else if(contractor.status == "2")
      {
        contractor.StatusName == "IN-ACTIVE";
      }
      else if(contractor.status == "3")
      {
        contractor.StatusName == "IN-PROGRESS";
      }
      else if(contractor.status == "4")
      {
        contractor.StatusName == "ARCHIVED";
      }
      if(this.env.hosting == 'UK'){
      contractor.StateName= contractor.suburb;
      }else{
        contractor.StateName = this.stateList.find((val) => (val.id == contractor.state_id))?.name;
      }
     
      contractor.CountryName = this.countryList.find((val) => (val.id == contractor.country_id))?.name;
    });
    console.log("contractor",this.contractorList)
    this.cols = [
      { field: 'business_name', header: 'Business Name' },
      { field: 'trading_name', header: 'Trading Name' },
      { field: 'email', header: 'Email' },
      { field: 'phone', header: 'Phone' },
      { field: 'StateName', header: 'State Name' },
      { field: 'StatusName', header: 'Status' }
    ];
    this.exportColumns = this.cols.map(col => ({ code: col["id"], desc: col["business_name"] }));
    this.employeSize = [
      { name: '2-5', id: '0' },
      { name: '6-10', id: '1' },
      { name: '11-25', id: '2' },
      { name: '26-50', id: '3' },
      { name: '50-100', id: '4' },
    ];
    this.treeCrew = [
      { name: '1', id: '1' },
      { name: '2', id: '2' },
      { name: '3', id: '3' },
      { name: '4', id: '4' },
      { name: '5 or More', id: '5' },
    ];
    this.contractorForm = new FormGroup({
      business_name: new FormControl('', Validators.required),
      trading_name: new FormControl(''),
      business_ABN: new FormControl(''),
      type_list: new FormControl(''),
      main_contact_name: new FormControl(''),
      // email: new FormControl('', Validators.required),
       email: new FormControl(''),
      phone: new FormControl('',[Validators.pattern('[- +()0-9]+')]),
      mobile_no: new FormControl('',[Validators.pattern('[- +()0-9]+')]),
      website:new FormControl('',[ Validators.pattern('(https?://)?([\\da-z.-]+)\\.([a-z.]{2,6})[/\\w .-]*/?')]),
      account_name: new FormControl(''),
      account_BSB: new FormControl(''),
      account_number: new FormControl(''),
      account_email: new FormControl(''),
      street_address: new FormControl(''),
      suburb: new FormControl(''),
      postcode: new FormControl(''),
      state: new FormControl(''),
      country: new FormControl(''),
      payment_terms: new FormControl(''),
      employeSize: new FormControl(''),
      treeCrew: new FormControl(''),
      client_list: new FormControl(''),
      // account_id: new FormControl(''),
      // rebate: new FormControl(''),
      communication_type: new FormControl(''),
      client_sub_type: new FormControl(''),
      compliance_level: new FormControl(''),
      vat_no: new FormControl()
    })
    this.exportdisplay = await this.commonservice.exportCheck();
    this.loading = false;
  }

  ngAfterViewInit(): void {
    if (this.contractorList?.length == 0)
      this.dt.currentPageReportTemplate = 'No Records Available'
  }

  changeStatus(event) {
    this.contractorList = this.dpContractorList;
    this.contractorList = this.contractorList.filter(val => val.status === event.value.id);
    // if (event.value.name == "ACTIVE") {
    //   this.contractorList = this.contractorList.filter(val => val.status === '1');
    // }
    // if (event.value.name == "IN-ACTIVE") {
    //   this.contractorList = this.contractorList.filter(val => val.status === '2');
    // }
  }

  removeToMailId(value) {
    this.contactEmailIds = this.contactEmailIds.filter(val => val !== value);
    if(this.contactEmailIds.length == 0){
      this.dupEmail = false
    }
    if(this.contactEmailIds.length == 0){
      this.dupEmail = false
    }
  }
  fetchEmailList(emails) {
    if (emails.includes(',')) {
      this.contactEmailIds = emails.split(',');
    } else if (emails.includes(';')) {
      this.contactEmailIds = emails.split(';');
    } else {
      this.contactEmailIds.push(emails);
    }
  }

  checkDuplicateEmail(value: any,field:any, table: any,mailFieldValue) { 

    this.crudService.getDataByField(table, field, value).subscribe((data: any) => {
      if (data.length == 0) { this.dupEmail = false; }
      else {
        if (this.companyId == data[0].id) { this.dupEmail = false; } else { this.dupEmail = true; }
      }
    })
    if (typeof value !== 'undefined' && value !== null && value !== '') {
      if (this.contactEmailIds.includes(value)) {
        this.emailIds = null
      }else {
        this.dupEmail = false;
        let valid = this.emailValidation(value);
        if(valid)
        {
          this.contactEmailIds.push(value);
          this.emailIds = null
        }
        else
        {
          this.emailIds = null
        }
      }
    }
    this.emailIds = null
    mailFieldValue = null
  }

  emailValidation(val: any) {
    var regex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/; // Uppercase character pattern
    if (regex.test(val)) {
      return true;
    } else {
      return false;
    }
  }

  exportExcel(contractors, table) {
    let JsonData: any[] = [];
    if (this.contractors != null && this.contractors.length > 0) {
      JsonData = this.contractors;
    } else {

      if (typeof table.filteredValue !== 'undefined') {
        if (table.filteredValue.length !== this.contractorList.length && table.filteredValue.length > 0) {
          JsonData = table.filteredValue;
        } else {
          JsonData = this.contractorList;
        }
      } else {
        JsonData = this.contractorList;
      }
    }

    let reformattedArray = JsonData.map(obj => {

      let rObj = {};
      this.cols.forEach(pair => {
        rObj[pair.header.toString()] = obj[pair.field];
      });
      return rObj;
    })

    import('xlsx').then(xlsx => {
      const worksheet = xlsx.utils.json_to_sheet(reformattedArray);
      const workbook = { Sheets: { data: worksheet }, SheetNames: ['data'] };
      const excelBuffer: any = xlsx.write(workbook, { bookType: 'xlsx', type: 'array' });
      this.saveAsExcelFile(excelBuffer, 'contractor');
    });
  }

  saveAsExcelFile(buffer: any, fileName: string): void {
    import('file-saver').then(FileSaver => {
      const EXCEL_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
      const EXCEL_EXTENSION = '.xlsx';
      const data: Blob = new Blob([buffer], {
        type: EXCEL_TYPE
      });
      let timeStamp = this.datePipe.transform(new Date(), 'ddMMyy_HHmm');
      FileSaver.saveAs(data, fileName + '_' + timeStamp + EXCEL_EXTENSION);
    });
  }


  clear(table: Table) {
    table.clear();
    this.searchvalue = '';
  }

  enableActiveInactive(value) {
    if (value.checked === 'false') {
      this.enablActive = false;
    }
    if (value.checked === 'true') {
      this.enablActive = true;
    }
  }

  openNew() {
    debugger
    this.contactEmailIds = [];
    this.submitted=false
    this.contractorForm.markAsUntouched();
    this.isActive = true;
    this.selectedContractor = [];
    this.selectedClientType = {};
    this.selectedPaymentTerm = {};
    this.selectedContractorType = {}
    this.selectedAssetVehicleList = [];
    this.selectedCommType = {};
    this.selectedComplianceLevel = {};
    this.selectedCountry = {};
    this.selectedemployeSize = {};
    this.selectedSubType = {};
    this.selectedtreeCrew = {};
    this.selectedState = {};
    this.isDepot = true;
    this.contractor = {
      vat_avail:false,
      vat_no:null,
      StateName: null,
      account_BSB: null,
      account_email: null,
      account_id: null,
      account_name: null,
      account_number: null,
      addl_address: null,
      business_ABN: null,
      business_name: null,
      client_category: null,
      client_comm_type: null,
      client_subtype: null,
      client_type_id: '3',
      company_inactive_from: null,
      company_inactive_to: null,
      compliancelevel_id: null,
      country_id: null,
      created_at: null,
      created_by: null,
      default_depot: null,
      deleted: null,
      email: null,
      emp_size: null,
      fence_required: null,
      id: null,
      invalid_login_count: null,
      last_modified_by: null,
      last_updated_at: null,
      location_lat: null,
      location_lng: null,
      main_contact_name: null,
      margin: null,
      mobile_no: null,
      no_of_crew: null,
      operations_circle: null,
      owner: null,
      payment_terms: null,
      phone: null,
      postcode: null,
      priority: null,
      process_id: null,
      rebate: null,
      regn_status: null,
      remarks: null,
      site_address: null,
      state_id: null,
      state_name: null,
      status: '1',
      street_address: null,
      suburb: null,
      trading_name: null,
      website: null
    };
this.dupEmail =false
    this.pre_account_BSB=null;
    this.pre_account_name=null;
    this.pre_account_number=null;

    if (this.viewType === 'TM') {
      this.contractor.process_id = '1';
      this.contractorForm.get('treeCrew').clearValidators();
      this.contractorForm.get('employeSize').clearValidators();
      this.contractorForm.get('type_list').clearValidators();
    } else {
      this.contractor.process_id = this.companyId;
      this.contractorForm.get('account_id').clearValidators();
      this.contractorForm.get('client_list').clearValidators();
      this.contractorForm.get('rebate').clearValidators();
    }
    this.companyCompliance = { ...this.companyComplianceList };
    this.companyCompliance = {
      amount: null,
      comment: null,
      company_id: null,
      compliance_id: null,
      compliance_type_id: null,
      created_at: null,
      created_by: null,
      expiry_date: null,
      file_path: null,
      filename: null,
      id: null,
      issued_by: null,
      issued_date: null,
      last_modified_by: null,
      last_reminder_date: null,
      last_updated_at: null,
      owner: null,
      policy_no: null,
      reminder_count: null,
      reminder_status: null,
      staff_id: null,
      status: null
    };
    this.contractorDialog = true
  }

  hideDialog() {
    this.contractorDialog = false;
  }

  reset() {
    this.confirmationService.confirm({
      message: 'Do you want to Refresh the Page?',
      header: 'Confirm',
      icon: 'pi pi-exclamation-triangle',
      accept: () => {
        this.crudService.getDataByField('Company', 'id', this.contractor.id).subscribe((data: any[]) => {
          if (data.length > 0) {
            this.contractorList = data[0];
            this.editSelectedRecord(data[0]);
          }
        });
        this.ngOnInit();
      }
    });
  }

  changeActiveStatus() {
    this.confirmationService.confirm({
      message: 'Are you sure you want to change status?',
      header: 'Confirm',
      icon: 'pi pi-exclamation-triangle',
      accept: () => {
      },
      reject: () => {
        if (this.contractor.status == 1) {
          this.toggle.onIcon = 'pi pi-thumbs-up'
          this.toggle.onLabel = 'Active'
          this.isActive = true
        }
        if (this.contractor.status == 2) {
          this.toggle.offIcon = 'pi pi-thumbs-down'
          this.toggle.offLabel = 'Inactive'
          this.isActive = false
        }
      }
    });
  }

  saveRecord() {
    debugger
    this.disableSavebtn =true
    this.submitted = true;
    this.contractor.process_id = this.companyId;
    this.contractor.client_subtype = null;
    if (this.contractorForm.valid) {      
      if (this.viewType === 'TM') {
        if (typeof this.selectedSubType.id !== 'undefined') {
          this.contractor.client_subtype = this.selectedSubType.id;
        }
        if (typeof this.selectedClientType.id !== 'undefined') {
          this.contractor.client_type_id = this.selectedClientType.id;
        }
      } else {
        if (typeof this.selectedContractorType.id != 'undefined') {
          this.contractor.client_subtype = this.selectedContractorType.id;
        }
      }
      if (typeof this.selectedemployeSize.id !== 'undefined') {
        this.contractor.emp_size = this.selectedemployeSize.id;
      } else {
        this.contractor.emp_size = null;
      }
      if (typeof this.selectedtreeCrew.id !== 'undefined') {
        this.contractor.no_of_crew = this.selectedtreeCrew.id;
      } else {
        this.contractor.no_of_crew = null;
      }
      if (typeof this.selectedComplianceLevel.id !== 'undefined') {
        this.contractor.compliancelevel_id = this.selectedComplianceLevel.id;
      } else {
        this.contractor.compliancelevel_id = null;
      }
      if (typeof this.selectedCommType.id !== 'undefined') {
        this.contractor.client_comm_type = this.selectedCommType.id;
      } else {
        this.contractor.client_comm_type = null;
      }

      if (typeof this.selectedPaymentTerm.id !== 'undefined') {
        this.contractor.payment_terms = this.selectedPaymentTerm.id;
      } else {
        this.contractor.payment_terms = null;
      }
      if (typeof this.selectedCountry?.id !== 'undefined') {
        this.contractor.country_id = this.selectedCountry.id;
      } else {
        this.contractor.country_id = null;
      }
      if (typeof this.selectedState?.id !== 'undefined') {
        this.contractor.state_id = this.selectedState.id;
      } else {
        this.contractor.state_id = null
      }

      if (this.isActive) {
        this.contractor.status = '1';
      }
      if (!this.isActive) {
        this.contractor.status = '2';
      }
      if (this.isDepot) {
        this.contractor.default_depot = '1';
      } else {
        this.contractor.default_depot = '2';
      }
      if (this.contractor.company_inactive_from > this.contractor.company_inactive_to) {
        return;
      }
      if (this.contactEmailIds.length > 0) {
        this.contractor.email = this.contactEmailIds.toString();
      } else {
        this.contractor.email = null;
      }
      this.callAPI()
    } else {
          this.callAPI();
    }
  }

  callAPI() {
    if (this.contractor) {
      if(this.vatRegisterFlag)
      this.contractor.vat_avail=1;
      else
      this.contractor.vat_avail=0;
      if (this.contractor.id) {
        this.contractor.last_modified_at = new Date().toDateString();
        this.contractor.last_modified_by = this.userDetails.id;
      //  this.contractor.business_name = this.contractor.business_name.charAt(0).toUpperCase() + this.contractor.business_name.substr(1).toLowerCase();
        this.crudService.Update(this.contractor, 'company').subscribe((data: any) => {
          if (data.success === 1) {
            this.enableTabs();
            this.disableSavebtn =false
            this.messageService.add({ severity: 'success', summary: 'Successful', detail: 'Contractor Updated', life: 1000 });
            if((this.pre_account_number !==null && this.pre_account_number !== this.contractorForm.get('account_number').value) ||
            (this.pre_account_BSB !==null && this.pre_account_BSB !== this.contractorForm.get('account_BSB').value) || 
            (this.pre_account_name !==null && this.pre_account_name !== this.contractorForm.get('account_name').value)){
            console.log("pass");

            this.sendmail()

            }
            else{
             console.log("fail");
            }
            this.pre_account_number=this.contractorForm.get('account_number').value
            this.pre_account_BSB=this.contractorForm.get('account_BSB').value
            this.pre_account_name= this.contractorForm.get('account_name').value
            this.contractorList[this.findIndexById(this.contractor.id)] = this.contractor;
          } else {
            this.disableSavebtn =false
            this.messageService.add({ severity: 'error', summary: 'Error', detail: 'Contractor Not Updated', life: 1000 });
          }
        });

      } else {
        this.contractor.created_at = new Date().toDateString();
        this.contractor.created_by = this.userDetails.id;
        //this.contractor.business_name = this.contractor.business_name.charAt(0).toUpperCase() + this.contractor.business_name.substr(1).toLowerCase();
        this.crudService.Create(this.contractor, 'company').subscribe(async (data: any) => {
          if (data.success === 1) {
            this.contractor.id = data.id;
            this.contractorId = this.contractor.id;
            this.messageService.add({ severity: 'success', summary: 'Successful', detail: 'Contractor Created. Please Proceed With Entering Compliance and Additional Contact Details', life: 1000 });
            this.enableTabs();
            this.contractor.StateName = this.stateList.find((val) => (val.id == this.contractor.state_id))?.name;
            this.contractor.CountryName = this.countryList.find((val) => (val.id == this.contractor.country_id))?.name;
            await this.saveDepot();
            this.disableSavebtn =false;
            this.contractorList = [...this.contractorList, this.contractor];

          } else {
            this.disableSavebtn =false;
            this.contractorDialog = true;
            this.messageService.add({ severity: 'error', summary: 'Error', detail: 'Contractor Not Created', life: 1000 });
          }
        });
      }
    }
  }
  sendmail(){
    if(this.env.emailRoutes.common == 'sendgrid')
    {
      let bsb_name=''
      if(this.env.hosting=='AU'){
        bsb_name ='BSB Code'
      }
      else if(this.env.hosting=='UK'){
        bsb_name ='Sort Code'
      }
      debugger
      let acc_number = this.contractorForm.get('account_number').value
      let acc_BSB = this.contractorForm.get('account_BSB').value
      let acc_name = this.contractorForm.get('account_name').value
      let acc_email = this.contractorForm.get('account_email').value


      if(this.contractorForm.get('account_number').value == (null || '' || undefined) ){
        acc_number ='NA'
      }
      if(this.contractorForm.get('account_BSB').value == (null || '' || undefined) ){
        acc_BSB='NA'
      }
      if(this.contractorForm.get('account_name').value == (null || '' || undefined) ){
        acc_name='NA'
      }
      if(this.contractorForm.get('account_email').value == (null || '' || undefined) ){
        acc_email='NA'
      }
      
      const datobj2 = {
        subject :  'Enviro Trees: Company Account information modified- '+ this.contractorForm.get('business_name').value,
        body :'This is a mail to notify that account information of company : '+ this.contractorForm.get('business_name').value +' has been modified in Enviro Trees. The details are as below .'
        + '<br><br><span>Account Number : </span>'+acc_number
        + '<br>'+bsb_name+' : '+acc_BSB
        + '<br><span>Account Name : </span>'+acc_name
        + '<br><span>Account Email : </span>'+acc_email,
       
        toEmail : this.accountsEmail,
        ref_id:1,
        ref_type:'accounts'
        }
      this.crudService.sendmailSendgrid(datobj2,'commonMail').subscribe((res2: any[]) => { 
        console.log("mailsent res",res2);     
       });
    }
  }
  async saveDepot() {
    let mail = this.contractor.email.toString().split(',');
    let tempMail =mail[0] ;
    let depot = {
      id: null,
      process_id: this.contractor.id,
      depot_name: 'Default Depot',
      company_id: this.contractor.id,
      status: '1',
      make_default: '1',
      created_by: this.userDetails.id,
      last_modified_by: null,
      created_at: new Date(),
      last_updated_at: null,
      street_address: this.contractor.street_address,
      suburb: this.contractor.suburb,
      postcode: this.contractor.postcode,
      state_id: this.contractor.state_id,
      country_id: this.contractor.country_id,
      depot_desc: this.contractor.business_name,
      contact_name: this.contractor.first_name,
      email: tempMail,
      contact_best: this.contractor.phone,
      contact_alt: null,
      lat: null,
      long: null,
      location_lat: this.contractor.location_lat,
      location_lng: this.contractor.location_lng,
      depot_short_code: null,
      CompanyName: this.contractor.business_name,
      TypeName: null,
      displayStatus: null,
      rownumber: null,
      company_name: this.contractor.business_name,
      StatusDescription: null,
      deleted : null
    }
    await this.crudService.Create(depot, 'Depot').toPromise();
}

  public AddressChange(address: any) {
    const fullAddress = [];
    for (let i = 0; i < address.address_components.length; i++) {
      const addressType = address.address_components[i].types[0];
      if (this.componentForm[addressType]) {

        const val = address.address_components[i][this.componentForm[addressType]];

        if (addressType === 'street_number') {
          fullAddress[0] = val;
        } else if (addressType == 'route') {
          fullAddress[1] = val;
        }

        if (addressType === 'sublocality_level_1') {
          this.contractor.street_address = val;
        }
        if(environment.hosting == 'UK'){
        if (addressType === 'postal_town') {
          this.contractor.suburb = val;
        } else if (addressType === 'administrative_area_level_1') {
          this.stateList.forEach((stat) => {
            if (stat.name === 'NSW') {
              this.contractor.state_id = stat.id;
              this.selectedState = stat;
            }
          });

        } else if (addressType === 'administrative_area_level_2') {
        } else if (addressType === 'country') {
          this.countryList.forEach((ctry) => {
            if (ctry.name === val) {
              this.contractor.country_id = ctry.id;
              this.selectedCountry = ctry;
            }
          });
        } else if (addressType === 'postal_code') {
          this.contractor.postcode = val;
        }
        
        }else{
        if (addressType === 'locality') {
          this.contractor.suburb = val;
        } else if (addressType === 'administrative_area_level_1') {
          this.stateList.forEach((stat) => {
            if (stat.name === val) {
              this.contractor.state_id = stat.id;
              this.selectedState = stat;
            }
          });

        } else if (addressType === 'administrative_area_level_2') {
        } else if (addressType === 'country') {
          this.countryList.forEach((ctry) => {
            if (ctry.name === val) {
              this.contractor.country_id = ctry.id;
              this.selectedCountry = ctry;
            }
          });
        } else if (addressType === 'postal_code') {
          this.contractor.postcode = val;
        }
        
        }
        
      }
    }
    const fullAdr = fullAddress.join(' ');
    this.contractor.street_address = fullAdr;
    this.contractor.location_lat = address.geometry.location.lat();
    this.contractor.location_lng = address.geometry.location.lng();
  }

  editSelectedRecord(contractor: Contractor) {
    this.contactEmailIds = [];
    this.contractorForm.markAsUntouched();
    this.isActive = true;
    this.selectedContractor = [];
    this.selectedClientType = {};
    this.selectedPaymentTerm = {};
    this.selectedContractorType = {}
    this.selectedAssetVehicleList = [];
    this.selectedCommType = {};
    this.selectedComplianceLevel = {};
    this.selectedCountry = {};
    this.selectedemployeSize = {};
    this.selectedSubType = {};
    this.selectedtreeCrew = {};
    this.selectedState = {};
    this.isDepot = true;

    this.contractor = { ...contractor }
    if (this.viewType === 'TM') {
      this.contractorForm.get('type_list').clearValidators();
    }
    if (this.viewType === 'TP') {

    }
    if (this.contractor.emp_size != null) {
      this.employeSize.forEach((emp) => {
        if (this.contractor.emp_size == emp.id) {
          this.selectedemployeSize = emp;
          this.contractor.emp_size = emp.id;
        }
      })
    }
    if (this.contractor.client_comm_type != null) {
      this.communicationTypeList.forEach((commtype) => {
        if (this.contractor.client_comm_type == commtype.id) {
          this.selectedCommType = commtype;
        }
      })
    }

    if (this.contractor.compliancelevel_id != null) {
      this.complianceLevelList.forEach((complianceLevel) => {
        if (this.contractor.compliancelevel_id == complianceLevel.id) {
          this.selectedComplianceLevel = complianceLevel;
        }
      })
    }

    if (this.contractor.client_subtype != null) {
      this.clientSubTypeList.forEach((subType) => {
        if (this.contractor.client_subtype == subType.id) {
          this.selectedSubType = subType;
        }
      })
    }

    if (this.contractor.no_of_crew != null) {
      this.treeCrew.forEach((crew) => {
        if (this.contractor.no_of_crew == crew.id) {
          this.selectedtreeCrew = crew;
          this.contractor.no_of_crew = crew.id
        }
      })
    }
    if (this.contractor.client_type_id != null) {
      this.clientType.forEach((client) => {
        if (this.contractor.client_type_id == client.id) {
          this.selectedClientType = client;
        }
      })
    }
    this.contractorId = this.contractor.id;
    this.crudService.getByValuesetName('AssetVehicleList').subscribe((res: any[]) => {
      this.AssetVehicleList = res;
    })

    if (this.contractor.payment_terms !== null && this.contractor.payment_terms !== '') {
      this.crudService.getDataByField('ValueSetDetail', 'id', this.contractor.payment_terms).subscribe((data: any[]) => {
        this.selectedPaymentTerm = data[0];
      });
    }
    if (this.contractor.client_subtype !== null && this.contractor.client_subtype !== '') {
      this.crudService.getDataByField('ValueSetDetail', 'id', this.contractor.client_subtype).subscribe((data: any[]) => {
        this.selectedContractorType = data[0];
      });
    }
    if (this.contractor.status === '' || this.contractor.status === null || this.contractor.status === '1') {
      this.contractor.status = '1'
      this.isActive = true;
    } else {
      this.contractor.status = '2'
      this.isActive = false;
    }
    if (this.contractor.default_depot === '1') {
      this.isDepot = true;
    } else {
      this.isDepot = false;
    }

    if (this.contractor.company_inactive_from != null && this.contractor.company_inactive_from !== '') {
      this.contractor.company_inactive_from = new Date(this.contractor.company_inactive_from);
    }
    if (this.contractor.company_inactive_to != null && this.contractor.company_inactive_to !== '') {
      this.contractor.company_inactive_to = new Date(this.contractor.company_inactive_to);
    }
    this.crudService.getDataByField('Country', 'id', this.contractor.country_id).subscribe((data: any[]) => {
      this.selectedCountry = data[0];
    });
    this.crudService.getDataByField('State', 'id', this.contractor.state_id).subscribe((data: any[]) => {
      this.selectedState = data[0];
    });
    this.fetchEmailList(this.contractor.email);
    this.contractorDialog = true;
    this.pre_account_BSB=this.contractor.account_BSB;
    this.pre_account_name=this.contractor.account_name;
    this.pre_account_number=this.contractor.account_number;
    if(this.contractor.vat_avail)
      this.vatRegisterFlag=true;
    else
      this.vatRegisterFlag=false;
    this.enableTabs();
  }

  enableTabs() {
    setTimeout(() => {
      this.initTab();

      document.getElementById('help-video-trigger').addEventListener('click', function () {
        document.getElementById('help-video').style.display = 'block';
      })

      document.getElementById('vid-close')?.addEventListener('click', function () {
        document.getElementById('help-video').style.display = 'none';
      })
      document.getElementById('walkthrough-trigger').addEventListener('click', function () {

        if (addEle) {
          document.body.appendChild(mainEle);
          document.body.appendChild(mainEleNote);
        }
        count = 0;
        walkthroughScreen(walkthrough[count]);
      })
      const walkthrough = [
        {
          element: document.getElementById('d-1'),
          purpose: 'Enter your Business here'
        },
        {
          element: document.getElementById('d-2'),
          purpose: 'Enter your Trading Name'
        },
        {
          element: document.getElementById('d-3'),
          purpose: 'Enter your ABN/ACN'
        },
        {
          element: document.getElementById('d-4'),
          purpose: 'Enter Payment Terms'
        },
        {
          element: document.getElementById('d-5'),
          purpose: 'Enter Client Type'
        },
        {
          element: document.getElementById('d-6'),
          purpose: 'Enter your Email'
        },
        {
          element: document.getElementById('d-7'),
          purpose: 'Enter your Email'
        },
        {
          element: document.getElementById('d-8'),
          purpose: 'Enter your Email'
        },
        {
          element: document.getElementById('d-9'),
          purpose: 'Enter your Email'
        }
      ]

      let mainEle = document.getElementById('walkthrough-ele');
      let mainEleNote = document.getElementById('walkthrough-ele-note');
      let addEle = false;
      let count = 0;
      if (!mainEle) {
        mainEle = document.createElement('div');
        mainEle.id = 'walkthrough-ele';
        mainEleNote = document.createElement('div');
        let nextElement = document.createElement('div');
        let closeElement = document.createElement('div');
        let imgElement = document.createElement('img');
        imgElement.src = 'thinking.png';
        mainEleNote.id = 'walkthrough-ele-note';
        nextElement.id = 'nextWalkthrough';
        nextElement.innerHTML = 'Next';
        closeElement.id = 'closeWalkthrough';
        closeElement.innerHTML = 'x';
        mainEleNote.appendChild(nextElement);
        mainEleNote.appendChild(closeElement);
        mainEleNote.appendChild(imgElement);
        addEle = true;
        closeElement.addEventListener('click', function () {
          closeWalkthrough();
        });
        nextElement.addEventListener('click', function () {
          document.getElementById('wlk-info')?.remove();
          if (count < walkthrough.length - 1) {
            count++;
            walkthroughScreen(walkthrough[count]);
            if (count === walkthrough.length - 1) {
              nextElement.innerHTML = 'Close';
            }
          } else {
            closeWalkthrough();
          }
          if (count > 0) {
            enableBack();
          }
        });
      }
      function enableBack() {
        if (!document.getElementById('backWalkthrough')) {
          let backElement = document.createElement('div');
          backElement.id = 'backWalkthrough';
          backElement.innerHTML = 'Back';
          mainEleNote.appendChild(backElement);
          backElement.addEventListener('click', function () {
            document.getElementById('wlk-info')?.remove();
            if (count > 0) {
              count--;
              walkthroughScreen(walkthrough[count]);
              if (count === 0) {
                backElement.remove();
              }
            }
          });
        }
      }

      function walkthroughScreen(deet) {

        let o = deet;
        let x = o.element.offsetLeft;
        let y = o.element.offsetTop;
        let w = o.element.offsetWidth;
        let h = o.element.offsetHeight;
        //console.log('left-' + x);
        //console.log('top-' + y);
        //console.log('w-' + w);
        //console.log('h-' + h);
        mainEle.style.width = w + 'px';
        mainEle.style.height = h + 'px';
        mainEle.style.top = y + 'px';
        mainEle.style.left = x + 'px';
        mainEleNote.style.width = '500px';
        mainEleNote.style.height = '100px';
        mainEleNote.style.top = (y + h + 5) + 'px';
        mainEleNote.style.left = x + 'px';
        let ol = document.getElementById('wlk-info');
        if (ol) {
          ol.remove();
        }
        let l = document.createElement('div');
        l.id = 'wlk-info';
        l.innerHTML = o.purpose;
        mainEleNote.appendChild(l)
      }


      function closeWalkthrough() {
        mainEle.remove();
        mainEleNote.remove();
      }
    }, 1000);
  }

  initTab() {
    const tabContractor = document.getElementById('tab-contractor');
    const tabCompliance = document.getElementById('tab-compliance');
    const tabVehicle = document.getElementById('tab-vehicle');
    // const tabContact = document.getElementById('tab-contact');
    const tabContact = document.getElementById('tab-vehicle');
    const tabDepot = document.getElementById('tab-depot');
    const tabStaff = document.getElementById('tab-staff');
    const tabJob = document.getElementById('tab-job');
    const tabSetting = document.getElementById('tab-setting');
    const tabPayments = document.getElementById('tab-payments');
    const tabNotes = document.getElementById('tab-notes');
    const tabEscalations = document.getElementById('tab-escalations');
    const tabCompCompliance = document.getElementById('tab-compcompliance');


    // const tabGeneral = document.getElementById('tab-client');
    // const tabContact = document.getElementById('tab-contact');
    // const tabCompliance = document.getElementById('tab-compliance');
    // const tabVehicle = document.getElementById('tab-vehicle');

    let contractor = document.getElementsByClassName('contractor-info-tab-activate');
    let contact = document.getElementsByClassName('contact-info-tab-activate');
    let compliance = document.getElementsByClassName('compliance-info-tab-activate');
    let vehicle = document.getElementsByClassName('vehicle-info-tab-activate');
    let depot = document.getElementsByClassName('depot-info-tab-activate');
    let staff = document.getElementsByClassName('staff-info-tab-activate');
    let job = document.getElementsByClassName('job-info-tab-activate');
    let setting = document.getElementsByClassName('setting-info-tab-activate');
    let payments = document.getElementsByClassName('payments-info-tab-activate');
    let notes = document.getElementsByClassName('notes-info-tab-activate');
    let escalations = document.getElementsByClassName('escalations-info-tab-activate');
    let compcompliance = document.getElementsByClassName('compcompliance-info-tab-activate');
    let outerContainer: any = document.getElementsByClassName('outer-container');

    function initializeTabs(config) {

      let l1 = [tabContractor, tabContact, tabCompliance, tabVehicle, tabDepot, tabStaff, tabJob, tabSetting, tabPayments, tabNotes, tabEscalations, tabCompCompliance];
      let l2 = [contractor, contact, compliance, vehicle, depot, staff, job, setting, payments, notes, escalations, compcompliance];

      config.forEach(c => {
        document.getElementById(c.id).addEventListener('click', () => {
          l1.forEach((ele) => {
            ele?.classList?.remove('active');
          });
          c.activeTab?.classList?.add('active');

          l2.forEach((ele) => {
            if (ele && ele.length > 0) {
              for (let i = 0; i < ele.length; i++) {
                ele[i]?.classList?.add('hideme');
              }
            }
          });
          if (c.activeContent && c.activeContent.length > 0) {
            for (let i = 0; i < c.activeContent.length; i++) {
              c.activeContent[i]?.classList?.remove('hideme');
            }
          }
          // if(c.general) {
          //   outerContainer.forEach((ele) => {
          //     ele.classList.add('reduceHeight');
          //   });
          // } else {
          //   outerContainer.forEach((ele) => {
          //     ele.classList.remove('reduceHeight');
          //   });
          // }

        });
      })
    }
    this.tagConfig = [{
      id: 'tab-contractor',
      activeTab: tabContractor,
      activeContent: contractor,
      general: true
    }, 
    // {
    //   id: 'tab-contact',
    //   activeTab: tabContact,
    //   activeContent: contact
    // }, 
    {
      id: 'tab-compliance',
      activeTab: tabCompliance,
      activeContent: compliance
    }, {
      id: 'tab-vehicle',
      activeTab: tabVehicle,
      activeContent: vehicle
    }, {
      id: 'tab-depot',
      activeTab: tabDepot,
      activeContent: depot,
      content: 'showDepot'
    }, {
      id: 'tab-staff',
      activeTab: tabStaff,
      activeContent: staff,
      content: 'showStaff'
    }, {
      id: 'tab-job',
      activeTab: tabJob,
      activeContent: job,
      content: 'showJob'
    }, {
      id: 'tab-setting',
      activeTab: tabSetting,
      activeContent: setting,
      content: 'showSetting'
    }, {
      id: 'tab-payments',
      activeTab: tabPayments,
      activeContent: payments
    }, {
      id: 'tab-notes',
      activeTab: tabNotes,
      activeContent: notes
    }, {
      id: 'tab-escalations',
      activeTab: tabEscalations,
      activeContent: escalations,
      content: 'showEscalations'
    },];
    initializeTabs(this.tagConfig);
  }

  updateTab(v) {
    this.showDepot = false;
    this.showStaff = false;
    this.showJob = false;
    this.showSetting = false;
    this.showEscalations = false;
    this.showCompCompliance = false
    this.tagConfig.forEach(c => {
      if (c.content && c.id === v) {
        this[c.content] = true;
      }
    });
    this.respToggleMenu = false;
  }

  deleteSelectedContractor() {
    this.confirmationService.confirm({
      message: 'Are you sure you want to delete the selected contractors?',
      header: 'Confirm',
      icon: 'pi pi-exclamation-triangle',
      accept: () => {
        for (let i = 0; i < this.contractors.length; i++) {
          this.crudService.Delete(this.contractors[i].id, 'company').subscribe((data: any) => {
            //console.log(data)
          });
        }
        this.contractorList = this.contractorList.filter(val => !this.contractors.includes(val));
        this.contractors = null;
        this.messageService.add({ severity: 'success', summary: 'Successful', detail: 'Contractor Deleted', life: 1000 });
      }
    });
  }

  deleteContractor(contractor: Contractor) {
    this.contractor = { ...contractor }
    //console.log(this.contractor)
    this.confirmationService.confirm({
      message: 'Are you sure you want to delete contractor :' + this.contractor.business_name + '?',
      header: 'Confirm',
      icon: 'pi pi-exclamation-triangle',
      accept: () => {
        contractor.deleted = "1";
        this.crudService.Update(contractor, 'company').subscribe(async (data: any) => {
            if (data.success === 1) {
                this.contractorList = this.contractorList.filter(val => val.deleted != '1');
                this.messageService.add({ severity: 'success', summary: 'Successful', detail: 'Contractor Deleted', life: 1000 });
            } else {
                this.messageService.add({ severity: 'error', summary: 'Error', detail: 'Contractor not Deleted', life: 1000 });
            }
        });
      }
    });
  }

  viewCompliance(contractor: Contractor) {
    this.contractor = { ...contractor }
    const ref = this.dialogService.open(ComplianceComponent, {
      data: {
        staffId: '',
        company_id: this.contractor.id
      },
      header: 'Supplier Compliance',
      width: '100%',
      height: '100%'
    });
  }

  viewContact(contractor: Contractor) {
    this.contractor = { ...contractor }
    const ref = this.dialogService.open(ContactComponent, {
      data: {
        id: this.contractor.id
      },
      header: 'Supplier Contacts',
      width: '100%',
      height: '100%'
    });
  }

  exportPdf() {
    import('jspdf').then(jsPDF => {
      import('jspdf-autotable').then(x => {
        /* const doc = new jsPDF.default(0, 0);
        if (typeof this.selectedContractor == 'undefined') {
          doc.autoTable(this.exportColumns, this.contractors);
        } else {
          doc.autoTable(this.exportColumns, this.selectedContractor);
        }
        doc.save('Contractors.pdf'); */
      })
    })
  }

  findIndexById(id: string): number {
    let index = -1;
    for (let i = 0; i < this.contractorList.length; i++) {
      if (this.contractorList[i].id === id) {
        index = i;
        break;
      }
    }
    return index;
  }

  videoClose() {
    this.closeVideo = false;
    let close = document.getElementById('help-video');
    if (close.style.display === 'none') {
      close.style.display = 'block';
    } else {
      close.style.display = 'none';
    }
  }

  checkABN(value) {
    if (value != null && value != '') {
      value = value.replaceAll(' ', '');
      value = value.replaceAll('_', '');
      value = value.replaceAll('-', '');
      this.contractor.business_ABN = value;
      if (this.contractor.business_ABN.length == 11) {
        this.isABNInvalid = false;
      } else {
        this.isABNInvalid = true;
      }
    }
  }


  checkCn(value) {
    if (value.length >= 6 && value.length <= 12) {
      this.isABNInvalid = false;
    } else {
      this.isABNInvalid = true;
    }
  }


  checkBSB(value) {
    if (value != null && value != '') {
      value = value.replaceAll(' ', '');
      value = value.replaceAll('_', '');
      value = value.replaceAll('-', '');
      this.contractor.account_BSB = value;
      if (this.contractor.account_BSB.length == 6) {
        this.isBSBInvalid = false;
      } else {
        this.isBSBInvalid = true;
      }
    }
  }

  chooseAddress(event)
  {     
        this.inputAddress = event.summaryline;
        this.contractor.suburb = event.posttown
        if(event.addressline2 != undefined)
        {
          this.contractor.street_address = event.addressline1 + ',' + event.addressline2;
        }
        else
        {
          this.contractor.street_address = event.addressline1;
        }
        this.contractor.postcode = event.postcode;
        this.contractor.location_lat = event.latitude;
        this.contractor.location_lng = event.longitude;
        this.stateList.forEach((stat) => {
            if (stat.id === '111') {
                this.contractor.state_id = stat.id;
                this.selectedState = stat;
            }
        }); 
        this.countryList.forEach((ctry) => {
            if (ctry.id === '222') {
                this.contractor.country_id = ctry.id;
                this.selectedCountry = ctry;
            }
        });
  }

  search(event) {
    this.crudService.getPostcoderAddress(event.query).subscribe((res:any)=>{  
        this.filteredPlaces = res.filter((val: any) => val.summaryline.toLowerCase().includes(event.query.toLowerCase()));
      });
  }


  checkDuplicateName(value: any, field: any, table: any) {
    // let val = value.toLowerCase()
    this.contractor.trading_name = value;
  }

  checkValue(eve){
    this.vatRegisterFlag = eve.checked
    if(eve.checked)
      this.contractor.vat_avail = 1;
    else
      this.contractor.vat_avail = 0;
  }
}
