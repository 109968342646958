import { Component, OnInit } from '@angular/core';
import { animate, state, style, transition, trigger } from '@angular/animations';
import { CRUDService } from './utilities/crud.service';
import { AppMainComponent } from './app.main.component';

@Component({
    selector: 'app-config',
    styleUrls: ['./style.css'],
    templateUrl: './user-profile.component.html',
    animations: [
        trigger('children', [
            state('hiddenAnimated', style({
                opacity: 0,
                transform: ' translateX(-50%) translateY(-50%)'
            })),
            state('visibleAnimated', style({
                opacity: 1,
                transform: 'translateX(-50%) translateY(-50%) scale(1)',
            })),
            transition('visibleAnimated => hiddenAnimated', animate('150ms cubic-bezier(0, 0, 0.2, 1)')),
            transition('hiddenAnimated => visibleAnimated', animate('150ms cubic-bezier(0, 0, 0.2, 1)'))
        ])
    ]
})
export class AppConfigComponent implements OnInit {

    constructor(public app: AppMainComponent, private CRUDService: CRUDService) {
    }

    ngOnInit() {
    }
}
