import { Component, OnInit, Input } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ConfirmationService, MessageService } from 'primeng/api';
import { CRUDService } from 'src/app/utilities/crud.service';
import { WorkFlowService } from 'src/app/utilities/workflow.service';
import { DynamicDialogRef, DialogService, DynamicDialogConfig } from 'primeng/dynamicdialog';
import { JOBService } from 'src/app/utilities/job.service';
import { TaskDetailsComponent } from '../task-details/task-details.component';
import { environment } from 'src/environments/environment';
import { CommonService } from 'src/app/utilities/common.service';
import { formatDate } from 'ngx-bootstrap';
import { log } from 'console';
import { DatePipe } from '@angular/common';


@Component({
  selector: 'app-job-details-approvals-flagging',
  templateUrl: './job-details-approvals-flagging.component.html',
  styleUrls: ['./job-details-approvals-flagging.component.scss'],
  providers: [JOBService, WorkFlowService, DialogService]
})

export class JobDetailsApprovalsFlaggingComponent implements OnInit {
  dateFormat: any;
  flagReasonList = [
    { code: 'Wrong Info', desc: 'Wrong Info Entered. Corrections to be made.' },
    { code: 'Client On Hold', desc: 'Client Requested to put on hold.' },
    { code: 'Contractor On Hold', desc: 'Contractor Requested to put on hold.' },
    { code: 'No Update', desc: 'Details not Updated.' },
    { code: 'Others', desc: 'Others' }
  ];

  revokeReasonList = [
    { code: 'Data Corrected', desc: 'Corrections made.' },
    { code: 'Client Acted', desc: 'Client updated required Info. Responded to Follow-up.' },
    { code: 'Contractor Acted', desc: 'Contractor updated required Info. Responded to Follow-up.' },
    { code: 'Updated', desc: 'Details Updated.' },
    { code: 'Others', desc: 'Others' }
  ]
  priorityOptions = [
  { code: 'Critical', name: 'Critical' },
  { code: 'High', name: 'High' },
  { code: 'Normal', name: 'Normal' },
  { code: 'Low', name: 'Low' }
  ];

  FlagUnflag: any;
  unflag_remarks: any
  selectedStep: any;
  stepsList: any[] = [];
  reasonsList: any[] = [];
  companyId: any;
  userDetails: any;
  timeSlots: any[];
  jobId: any;
  jobAllocId: any;
  step: any;
  isFlagging: Boolean = false;
  isUnFlagging:Boolean = false;
  selectedAllocatees: any[] = [];
  selectedReason;
  filteredTimeSlots: any[];
  time: any;
  reason: any;
  remarks: any;
  followUpDT: Date;
  staffList: any[] = [];
  jobWFInfo: any;
  wfStatusID: any;
  jobWfStatus: any={};
  JobStatus: any;
  allFlagInfo: any[] = [];
  menuData: any;
  showFlagsHistory: Boolean = false;
  submitted: Boolean = false;
  loading: Boolean = false;
  flagType: any;
  selectedJob: any = [];
  flagResponse: any = [];
  editFlagId: any;
  editTaskId: any;
  editCreatedby : any;
  editCreatedAt : any;
  minDate: Date;
  Un_timeSlots: string[];
  selectedPriority: any;
  jobAllocationResults: any;
  env: any;
  selectedAllocatees_to_unflag: any[];

  constructor(
    private route: ActivatedRoute,
    private crudService: CRUDService,
    private commonService: CommonService,
    private messageService: MessageService,
    private confirmationService: ConfirmationService,
    private router: Router,
    private dialogService: DialogService,
    private JobService: JOBService,
    private wfService: WorkFlowService,
    public config: DynamicDialogConfig,
    public ref: DynamicDialogRef,private datepipe: DatePipe,) {
    this.companyId = sessionStorage.getItem('company_id');
    this.userDetails = JSON.parse(sessionStorage.getItem('loggedInUser'));
    this.timeSlots = ['6:00am', '6:30am', '7:00am', '7:30am', '8:00am', '8:30am', '9:00am', '9:30am', '10:00am', '10:30am', '11:00am', '11:30am',
      '12:00pm', '12:30pm', '1:00pm', '1:30pm', '2:00pm', '2:30pm', '3:00pm', '3:30pm', '4:00pm', '4:30pm', '5:00pm', '5:30pm', '6:00pm', '6:30pm'];
    //'12:00am', '12:30am', '1:00am', '1:30am', '2:00am', '2:30am', '3:00am', '3:30am', '4:00am', '4:30am', '5:00am', '5:30am','7:00pm', '7:30pm', '8:00pm', '8:30pm', '9:00pm', '9:30pm', '10:00pm', '10:30pm', '11:00pm', '11:30pm'
    this.Un_timeSlots = ['7:00pm', '7:30pm', '8:00pm', '8:30pm', '9:00pm', '9:30pm', '10:00pm', '10:30pm','11:00pm',  '11:30pm'];
    this.filteredTimeSlots = this.timeSlots;
  }
 
  async ngOnInit() {
    this.loading = true;
    this.dateFormat = environment.DateFormat;
    this.env = environment;
    let data: any = await this.crudService.getAllSSPUsers(this.companyId).toPromise();
    this.staffList = data;

    this.flagType = this.config.data.flagType;


    if (this.flagType == '1') {

      this.FlagUnflag = this.config.data.FlagUnflag;
      this.jobId = this.config.data.jobId;
      this.jobAllocId = this.config.data.allocId;
      this.step = this.config.data.step;
      this.JobStatus = this.config.data.job_status;
      this.wfStatusID = this.config.data.wf_status_id;
      //this.menuData = JSON.parse(sessionStorage.getItem('menuData'));

      if (this.JobStatus === 'Cost Saved' || this.JobStatus === 'Approve Quote' || this.JobStatus === 'Await Cost' || this.JobStatus === 'Quote Rejected') {
        this.stepsList = [
          { code: 'operations', desc: 'General' },
          { code: 'images', desc: 'Images' },
          { code: 'documents', desc: 'Documents' },
          { code: 'schedule', desc: 'Schedule & Communications' },
          { code: 'treeinfo', desc: 'Tree Info' },
          { code: 'costing', desc: 'Contractor Costing' },
          { code: 'wpcosting', desc: 'Client Costing' },
          { code: 'sendemail', desc: 'Send Email' }
        ]
      } else if (this.JobStatus === 'Approve Invoice' || this.JobStatus === 'Await Invoice' || this.JobStatus === 'Invoice Processed' || this.JobStatus === 'Accounts Proecessed' || this.JobStatus === 'Job Completed') {
        this.stepsList = [
          { code: 'operations', desc: 'General' },
          { code: 'images', desc: 'Images' },
          { code: 'documents', desc: 'Documents' },
          { code: 'schedule', desc: 'Schedule & Communications' },
          { code: 'treeinfo', desc: 'Tree Info' },
          { code: 'costing', desc: 'Contractor Costing' },
          { code: 'wpcosting', desc: 'Client Costing' },
          { code: 'sca', desc: 'Sub Contractor Agreement' },
          { code: 'shf', desc: 'Site Hazard Form' },
          { code: 'kpi', desc: 'Key Performance Indicators' },
          { code: 'sendemail', desc: 'Send Email' }
        ]
      } else {
        this.stepsList = [
          { code: 'operations', desc: 'General' },
          { code: 'admin', desc: 'Job Info' }
        ]
      }

      
      this.crudService.getDataByField('Job_wf_status', 'Job_alloc_id', this.jobAllocId).subscribe((flagdata: any) => {
       console.log("allFlagInfo",flagdata);
        this.allFlagInfo = flagdata;
        this.allFlagInfo.forEach(async info => {
          let usersArr: any[] = [];
          let usersData: any = await this.crudService.getDataByField('task_users', 'task_id', info.task_id).toPromise();
          if(usersData.length >0){
            usersData?.forEach(user => {
              let userInfo = this.staffList.find(val => (val.user_id == user.user_id && user.status == 1));
              if(userInfo){
              usersArr.push(userInfo.full_name);
              }
            });
            info.users = usersArr.toString();
          }
        
        });
      });

      this.loadJobWFFlagStatus(this.wfStatusID, this.FlagUnflag.toUpperCase());
    }
    else {
      this.selectedJob = this.config.data.selectedJob;
      this.stepsList = [
        { code: 'operations', desc: 'General' },
        { code: 'images', desc: 'Images' },
        { code: 'documents', desc: 'Documents' },
        { code: 'schedule', desc: 'Schedule & Communications' },
        { code: 'treeinfo', desc: 'Tree Info' },
        { code: 'costing', desc: 'Contractor Costing' },
        { code: 'wpcosting', desc: 'Client Costing' },
        { code: 'sca', desc: 'Sub Contractor Agreement' },
        { code: 'shf', desc: 'Site Hazard Form' },
        { code: 'kpi', desc: 'Key Performance Indicators' },
        { code: 'sendemail', desc: 'Send Email' }
      ];
      this.reasonsList = this.flagReasonList;
      this.selectedReason = {};
      this.selectedStep = {};
      this.time = null;
      this.remarks = null;
      this.followUpDT = null;
    }
    this.loading = false;
debugger
    var currentTime = new Date();
    const cValue = formatDate(currentTime, 'h:00a', 'en-US');
    if(this.Un_timeSlots.includes(cValue)){
      this.minDate = new Date() ;
      this.minDate.setDate(this.minDate.getDate() + 1);
    }
    else{
      this.minDate = new Date();
    }
  }

  removeUser(user) {
    this.selectedAllocatees = this.selectedAllocatees.filter(val => val.user_id !== user.user_id);
  }

  // search(event) {
  //   this.filteredTimeSlots = this.timeSlots.filter((val: any) => val.includes(event.query));
  // }

  async closeDialog() {
    this.ref.close({ success: 3 });
  }

  addNew() {
    this.loadJobWFFlagStatus(null, 'FLAG');   
    this.isUnFlagging = false;
    this.isFlagging =true

  }
  async loadJobWFFlagStatus(id, action) {
    debugger
    this.loading = true;
    this.selectedAllocatees = [];
    if (action === 'FLAG') {
      this.isFlagging = true;
      this.isUnFlagging=false;
      this.reasonsList = this.flagReasonList;
    } else {
      this.isFlagging = false;
      this.isUnFlagging=true;
      this.reasonsList = this.revokeReasonList;
    }

    if (id !== null && id !== '') {
      let wfStatusData: any = await this.crudService.getDataByField('job_wf_status', 'id', id).toPromise();
      if (wfStatusData.length > 0) {
        this.jobWfStatus = wfStatusData[0];
        this.selectedReason = this.flagReasonList.find(val => (val.code === this.jobWfStatus.remarks));

        let usersData: any = await this.crudService.getDataByField('task_users', 'task_id', this.jobWfStatus.task_id).toPromise();
        if(usersData.length > 0)
        {
          usersData.forEach(user => {
            let userInfo = this.staffList.find(val => (val.user_id == user.user_id));
            this.selectedAllocatees.push(userInfo);
          });
        }
        this.crudService.getDataByField('task_head', 'id', this.jobWfStatus.task_id).subscribe(async (res: any) => {
         let taskDetails = res[0];
          this.selectedPriority = this.priorityOptions.find(val => val.code === taskDetails.priority);
        })


        this.selectedStep = this.stepsList.find(val => (val.code === this.jobWfStatus.step));
        let dateTime = new Date(this.jobWfStatus.follow_up_dt);
        const localDate = new Date(dateTime.getFullYear(), dateTime.getMonth(), dateTime.getDate());
        this.followUpDT = localDate;
        let time: any;
        if (dateTime.getHours() > 12) {
          time = (dateTime.getHours() - 12) + ':' + ('00' + dateTime.getMinutes()).slice(-2) + 'pm';
        } else {
          time = dateTime.getHours() + ':' + ('00' + dateTime.getMinutes()).slice(-2) + 'am';
        }
        this.time = time;
        this.remarks = this.jobWfStatus.additional_remarks;

      }
    } else {
      this.selectedReason = {};
      this.selectedStep = {};
      this.time = null;
      this.remarks = null;
      this.followUpDT = null;
      this.jobWfStatus = {
        id: null,
        job_id: this.jobId,
        Job_alloc_id: this.jobAllocId,
        step: this.step,
        flag: null,
        status: null,
        remarks: null,
        created_by: this.userDetails.id,
        created_at: new Date(),
        updated_by: null,
        updated_at: null,
        follow_up_dt: null,
        additional_remarks: null,
        task_id: null
      };
    }
    //console.log(this.jobWfStatus);
    this.loading = false;
  }


  async flagAllocation() {
    this.loading=true
    let desc: any = null;
    if (this.flagType == '1') {
      this.selectedJob = [];
      this.selectedJob.push({ JobId: this.jobId, AllocationId: this.jobAllocId });
    }
    for (let allocation of this.selectedJob) {
      let allocationData: any = await this.crudService.getDataByField('JobAllocation', 'id', allocation.AllocationId).toPromise();
      this.jobWfStatus = {};
      this.jobWfStatus.status = '1';
      this.jobWfStatus.flag = '2';

      if (typeof this.selectedStep !== 'undefined') {
        if (this.selectedStep !== null && this.selectedStep !== '') {
          this.jobWfStatus.step = this.selectedStep.code;
        }
      } else {
        if (this.step !== null && this.step !== '') {
          this.jobWfStatus.step = this.step;
        }
      }
      if (typeof this.selectedReason !== 'undefined') {
        if (this.selectedReason !== null && this.selectedReason !== '') {
          this.jobWfStatus.remarks = this.selectedReason.code;
          desc = 'Reason : ' + this.selectedReason.desc;
        }
      } if (typeof this.remarks !== 'undefined') {
        if (this.remarks !== null && this.remarks !== '') {
          this.jobWfStatus.additional_remarks = this.remarks;
          desc = desc + ', Remarks : ' + this.remarks;
        }
      }

      let followup_dateTime: any;
      if (typeof this.followUpDT !== 'undefined') {
        if (this.followUpDT !== null && this.time !== '' && this.time !== null) {
          // const dateFromPickerTemp = this.followUpDT.toLocaleString().replace(', 12:00:00 AM', '');
          const dateFromPickerTemp = formatDate(this.followUpDT, 'M/DD/YYYY', 'en-US')
          const dateFromPicker = dateFromPickerTemp.toLocaleString().replace(', 00:00:00', '');
          const timeFromPicker = this.time;
          const dateParts: any[] = dateFromPicker.split('/');
          const timeParts: any[] = timeFromPicker.split(':');
          let minutes: any;
          let hours: any;
          if (timeParts[1].includes('pm')) {
            if(parseInt(timeParts[0]) == 12){
              hours = parseInt(timeParts[0]) ;
            minutes = timeParts[1].replace('pm', '');
            }
            else{
              hours = parseInt(timeParts[0]) +12 ;
            minutes = timeParts[1].replace('pm', '');
            }          
          } else {
            hours = parseInt(timeParts[0]);
            minutes = timeParts[1].replace('am', '');
          }
          const localDate = new Date(dateParts[2],dateParts[0]-1,dateParts[1], hours, minutes);
          followup_dateTime = localDate;
          this.jobWfStatus.follow_up_dt = followup_dateTime;
        }
      } else {
        this.jobWfStatus.follow_up_dt = null;
      }
      this.jobWfStatus.unflag_remarks = null;
      this.jobWfStatus.unflag_date = null;
      this.jobId = allocation.JobId;
      this.jobAllocId = allocation.AllocationId;
      this.jobWfStatus.job_id = allocation.JobId;
      this.jobWfStatus.job_alloc_id = allocation.AllocationId;
      if(!this.selectedPriority || this.selectedPriority == undefined){
        this.jobWfStatus.priority = 'Normal'
       }
       else{
        this.jobWfStatus.priority =  this.selectedPriority.code;
       }
      if(this.editFlagId) { this.jobWfStatus.id = this.editFlagId; this.jobWfStatus.task_id =  this.editTaskId; this.jobWfStatus.created_at =  this.editCreatedAt; this.jobWfStatus.created_by =  this.editCreatedby;}
      let res: any = await this.JobService.updateJobWFStatus(this.jobWfStatus);
      console.log("res",res);
      if (res.success === 1 || res.success === '1') {
        if (res.id) {
          this.jobWfStatus.id = res.id;
        }
        this.wfStatusID = this.jobWfStatus.id;
        let taskResponse = await this.wfService.updateTask(null,
          this.jobId,
          this.jobAllocId,
          this.jobWfStatus.step,
          desc,
          this.jobWfStatus.follow_up_dt,
          '7',
          this.selectedAllocatees, this.jobWfStatus.priority);
        if (taskResponse.id) {
         await this.selectedAllocatees.forEach( async user =>{
            debugger
         await   this.sendallocationNotification(taskResponse,allocationData,user)
          })
          this.messageService.add({ severity: 'success', detail: 'Notification to Allocated Users Sent', life: 4000 });
          allocationData.wf_status = '2';
          this.jobWfStatus.task_id = taskResponse.id;
          await this.commonService.saveRecord('Job_WF_Status', this.jobWfStatus);/* Updating jobworkflow status with the task id */
          let data: any = await this.crudService.getDataByField('task_head', 'id', this.jobWfStatus.task_id).toPromise();
          if (data.length > 0) {
            let taskDetails = data[0];
            this.wfService.updateAction(taskDetails.id, 'Flagged', this.remarks, this.companyId, this.userDetails.id);
            taskDetails.status = '1';
            this.commonService.saveRecord('task_head', taskDetails);
          }
          allocationData.remarks = this.remarks;
          this.commonService.saveRecord('JobAllocation', allocationData);
          await this.JobService.updateJobSchedule(this.jobWfStatus.job_id, this.jobWfStatus.job_alloc_id, desc, this.userDetails);
          this.loading = false;
        }
      }
      this.editFlagId = null;
      this.editTaskId = null;
      this.editCreatedAt = null;
      this.editCreatedby = null;

      // this.crudService.getDataByField('Job_wf_status', 'job_alloc_id', this.jobAllocId).subscribe((flagdata: any) => {
      //   this.allFlagInfo = flagdata;
      // });

      //this.isFlagging=false;
    }
  }

  async unflagAllocation() {
    this.jobWfStatus.status = '2';
    this.jobWfStatus.unflag_remarks = this.unflag_remarks;
    this.jobWfStatus.unflag_date = new Date();
    this.jobWfStatus.flag = '1';
    await this.commonService.saveRecord('Job_WF_Status', this.jobWfStatus);/* Updating jobworkflow status with the task id */
    let data: any = await this.crudService.getDataByField('task_head', 'id', this.jobWfStatus.task_id).toPromise();
    if (data.length > 0) {
      let taskDetails = data[0];
    await  this.crudService.getTaskUsers(taskDetails.id).subscribe((data: any[]) => {
        let usersList = data.filter(val => val.status === '1');
        this.selectedAllocatees_to_unflag = usersList;
      });
      debugger
      await this.wfService.updateAction(taskDetails.id, 'Unflagged', this.unflag_remarks, this.companyId, this.userDetails.id);
      taskDetails.status = '2';
      await this.commonService.saveRecord('task_head', taskDetails);
      // this.crudService.getTaskUsers(this.taskId).subscribe((data: any[]) => {
      //   let usersList = data.filter(val => val.status === '1');
      //   this.selectedAllocatees = usersList;
      // });
      var allocationData: any = await this.crudService.getDataByField('JobAllocation', 'id', this.jobAllocId).toPromise();

     await this.selectedAllocatees_to_unflag.forEach( async user =>{
        debugger
       await this.sendallocationNotification(taskDetails,allocationData,user)
      })
      this.messageService.add({ severity: 'success', detail: 'Notification to Allocated Users Sent', life: 4000 });
      this.selectedAllocatees_to_unflag =[]
    }
    let wfStatusData: any = await this.crudService.getDataByField('Job_wf_status', 'job_alloc_id', this.jobAllocId).toPromise();
    let list = wfStatusData.filter(val => val.flag == '2');
    if (allocationData?.length >0) {
      let allocation = allocationData[0]
      if (list.length == 0) {
        allocation.wf_status = '1';
      } else {
        allocation.wf_status = '2';
      }
      // allocation.wf_status = '2';
      allocation.remarks = this.unflag_remarks;
      await this.commonService.saveRecord('JobAllocation', allocation);
    }
    await this.JobService.updateJobSchedule(this.jobWfStatus.job_id, this.jobWfStatus.Job_alloc_id, this.jobWfStatus.step + ' unflagged, Reason : ' + this.unflag_remarks, this.userDetails);
    this.submitted = true;

    // let allocationData: any = await this.crudService.getDataByField('JobAllocation', 'id', this.allocId).toPromise();
    // wfStatusData = wfStatusData.filter(val => (val.step !== jobStepStatus.step));
    // wfStatusData = wfStatusData.filter(val => (val.flag === '2'));
    // if (allocationData.length > 0) {
    //   let allocDtls: any = allocationData[0];
    //   if (wfStatusData.length < 1) {
    //     allocDtls.wf_status = '1';
    //   } else {
    //     allocDtls.wf_status = '2';
    //   }
    //   let response: any = await this.crudService.updateAllocationWorkflowStatus(jobStepStatus.wf_status_id, allocDtls.wf_status, this.remarks, allocDtls.id).toPromise();

    // }
  }

  async updateFlagStatus() {
    let wfStatusData: any = await this.crudService.getTaskdetail( this.jobWfStatus?.task_id).toPromise();
    this.loading = true;
    this.submitted = true;
    if (this.isFlagging) {
      if (this.selectedStep.code != null && this.selectedReason.code != null && this.remarks != null && this.followUpDT != null && this.time != null && this.selectedAllocatees.length != 0) {
        await this.flagAllocation();
        this.submitted = false;
        this.loading = false;
        this.ref.close({ success: 1 });
      } else {
        this.loading = false;
         this.submitted = true;
         return false;
      }
    } else {
      if(this.unflag_remarks != null && this.unflag_remarks!= ""){
        await this.unflagAllocation();
        this.submitted = false;
        this.loading = false;
        this.ref.close({ success: 2 });
      }
      else{
        this.loading = false;
        this.submitted = true;
      }

    }
    //let response: any = await this.crudService.updateAllocationWorkflowStatus(this.wfStatusID, status, this.remarks, this.jobAllocId).toPromise();

  }
  async sendallocationNotification(taskResponse,ScheduleFormData,user) {
    let jobtaskData: any = await this.crudService.getTaskdetail( taskResponse.id).toPromise();
    var job_task_Data =jobtaskData[0]
    this.loading = true;
    this.jobAllocationResults = ScheduleFormData
    //console.log('this.jobAllocationResults?.info?.job_claim_no',this.jobAllocationResults?.info?.job_claim_no)
    if(job_task_Data.Job_Claim_No != '' || job_task_Data.Job_Claim_No != null)
    {
      var claimNo :any = job_task_Data.Job_Claim_No;
    }
    if(job_task_Data.Job_Claim_No == '' || job_task_Data.Job_Claim_No == null)
    {
      var claimNo :any = "N/A";
    }
    if(this.env.hosting == 'UK'){
      var emailSubject =  claimNo + ', Site Address - ' + job_task_Data.Client_Address +','+job_task_Data.Client_Suburb+','+job_task_Data.Client_Post_Code;
    }
    else{
      var emailSubject = 'Claim Number - ' + claimNo + ', Site Address - ' + job_task_Data.Client_Address +','+job_task_Data.Client_Suburb+','+job_task_Data.Client_Post_Code;
    } 
    var time = this.datepipe.transform( new Date(job_task_Data.Followup_Date), "dd/MM/yyyy h:mma" ).toLowerCase();
if(this.isFlagging){
  var message_content = 'Hi ' +user.full_name  + ' , <br/><br/>You have been allocated a task for job number '+ job_task_Data.Job_No+'.' 
  + '<p style="margin-left:30px"><br/><b>Job No:</b> ' + job_task_Data.Job_No
  + '<br/><b>Task ID: </b>' + job_task_Data.Task_ID  
  + '<br/><b>Task Details: </b>' + job_task_Data.Task_Name +', '+job_task_Data.Task_Description+ '<br/>' 
  + '<b>Follow Up Date: </b>' + time + '<br/>' 
  + '<b>Priority: </b>' +job_task_Data.Priority + '<br/>' 
  + '<br/><b>Client Name: </b>' +job_task_Data.Client_Name + '<br/>' + '<b>Address: </b>' +job_task_Data.Client_Address+', '+job_task_Data.Client_Suburb+', '+job_task_Data.Client_Post_Code
  + '<br/><b>Contact: </b>' +job_task_Data.Client_Mobile +', '+job_task_Data.Client_Mail + '<br/>' 
  + '<br/><b>TP Name: </b>' +job_task_Data.TP_Name + '<br/>' + '<b>Address: </b>' +job_task_Data.TP_Address+', '+job_task_Data.TP_Suburb
  + '<br/><b>Contact: </b>' +job_task_Data.TP_Contact_Name +', '+job_task_Data.TP_Phone+', '+job_task_Data.TP_Email
  +'</p>'
  }
  else{
    var message_content = 'Hi ' +user.full_name  + ' , <br/><br/>You have been unallocated from a task for job number '+ job_task_Data.Job_No +'.'
    + '<p style="margin-left:30px"><br/><b>Job No:</b> ' + job_task_Data.Job_No
    + '<br/><b>Task ID: </b>' + job_task_Data.Task_ID  
    + '<br/><b>Task Details: </b>' + job_task_Data.Task_Name +', '+job_task_Data.Task_Description+ '<br/>' 
    + '<b>Follow Up Date: </b>' + time + '<br/>' 
    + '<b>Priority: </b>' +job_task_Data.Priority + '<br/>' 
    + '<br/><b>Client Name: </b>' +job_task_Data.Client_Name + '<br/>' + '<b>Address: </b>' +job_task_Data.Client_Address+', '+job_task_Data.Client_Suburb+', '+job_task_Data.Client_Post_Code
    + '<br/><b>Contact: </b>' +job_task_Data.Client_Mobile +', '+job_task_Data.Client_Mail + '<br/>' 
    + '<br/><b>TP Name: </b>' +job_task_Data.TP_Name + '<br/>' + '<b>Address: </b>' +job_task_Data.TP_Address+', '+job_task_Data.TP_Suburb
    + '<br/><b>Contact: </b>' +job_task_Data.TP_Contact_Name +', '+job_task_Data.TP_Phone+', '+job_task_Data.TP_Email
    +'</p>'
  }

  
   let mailSubject = '';

    let documentList = [];
    let data: any = {
      email: {
        from: '',
        //need to configure user mails (to:user.email) while moving to live
        to: user.email,
        cc: '',
        bcc: '',
        subject: emailSubject,
        message: message_content,
        msg: message_content,
        ClientId: this.jobAllocationResults?.WPCompanyId,
        ref_type: 'Flagging',
        ref_id: 1   // doubt
      },
      
    }
    let temp_type = 'email';

    let atts = { jobId: this.jobAllocationResults.id, allocId: this.jobAllocationResults.job_alloc_id, documentList };
    if(this.env.emailRoutes.common == 'sendgrid')
    {
      const datobj2 = {
        subject : data.email.subject,
        body : data.email.message,
        toEmail : data.email.to,
        ref_id : 1,
        ref_type : 'Flagging',
        attachments: atts
        }
  
      if(datobj2.toEmail?.length>0)
      {
        this.crudService.sendmailSendgrid(datobj2,'commonMail2').subscribe((res2: any[]) => {
          console.log("res2",res2);        
         });
      }

    }
  
    this.loading = false;
  }

  async editFlag(flagInfo) {
    let FlagUnflag = '';
    if (flagInfo.status === '1') {
      FlagUnflag = 'FLAG';
    } else {
      FlagUnflag = 'UNFLAG';
    }
    this.editFlagId = flagInfo.id;
    this.editTaskId = flagInfo.task_id;
    this.editCreatedAt = flagInfo.created_at;
    this.editCreatedby = flagInfo.created_by;
    this.loadJobWFFlagStatus(flagInfo.id, FlagUnflag);
  }

  loadTaskDetails(task_id) {
    console.log("task_id",task_id);
    const ref = this.dialogService.open(TaskDetailsComponent, {
      data: {
        wf_code: '7',
        task_id: task_id,
        checkList: null,
        redirectMenu: this.menuData
      },
      header: '',
      width: '100%',
      height: '100%'
    })

    ref.onClose.subscribe((taskdetails: any) => {
    });
  }
  deleteRecord(jobwfstatus) {
    this.confirmationService.confirm({
      message: 'Are you sure you want to delete the selected Records?',
      header: 'Confirm',
      icon: 'pi pi-exclamation-triangle',
      accept: async () => {
        let response: any = await this.crudService.Delete(jobwfstatus.id, 'job_wf_status').toPromise();
        if (response.success === 1) {
          this.crudService.getDataByField('Job_wf_status', 'job_alloc_id', this.jobAllocId).subscribe((flagdata: any) => {
            this.allFlagInfo = flagdata;
          });

          this.messageService.add({ severity: 'success', summary: 'Successful', detail: 'Records Deleted', life: 1000 });
        }
      }
    })
  }

  unflag(jobwfstatus) {
    this.loadJobWFFlagStatus(jobwfstatus.id, 'UNFLAG');
    this.unflag_remarks = null;
    this.isUnFlagging = true;
    this.isFlagging =false
    this.selectedAllocatees_to_unflag =[]
  }

  watchJob(JobInfo) {
    if (JobInfo.watched == true) {
      let WatchedItems: any = {};
      WatchedItems.status = '2';
      WatchedItems.user_id = this.userDetails.id;
      WatchedItems.ref_type = 'Job';
      WatchedItems.ref_id = JobInfo.job_id

    } else {
      let WatchedItems: any = {};
      WatchedItems.status = '2';
      WatchedItems.user_id = this.userDetails.id;
      WatchedItems.ref_type = 'Job';
      WatchedItems.ref_id = JobInfo.job_id

    }
  }

  changeTimeslot() {
   var currentTime = new Date();
   const cValue = formatDate(currentTime, 'h:00a', 'en-US');
   var currentdate = new Date();
    if(formatDate(this.followUpDT, 'M/d/yy', 'en-US') == formatDate(currentdate, 'M/d/yy', 'en-US')){
     let index = this.timeSlots.indexOf(cValue)
     index = index+1;
     this.filteredTimeSlots = this.timeSlots.slice(index);
     if(this.filteredTimeSlots.includes(this.time)){
    }
    else{
      this.time = '';
    }
    }
  }
    search(event) {
      var currentTime = new Date();
      if(formatDate(this.followUpDT, 'M/d/yy', 'en-US') == formatDate(currentTime, 'M/d/yy', 'en-US')){
      this.filteredTimeSlots = this.filteredTimeSlots.filter((val: any) => val.includes(event.query));
    }else {
      this.filteredTimeSlots = this.timeSlots.filter((val: any) => val.includes(event.query));
    }
  }

}
