import { Component, OnInit, ElementRef, ViewChild, AfterViewInit } from '@angular/core';
import interactionPlugin, { Draggable } from '@fullcalendar/interaction';
import { CommonModule } from '@angular/common';
import { CRUDService } from 'src/app/utilities/crud.service';
import { BrowserModule } from '@angular/platform-browser';
import { EventHandlerVars } from '@angular/compiler/src/compiler_util/expression_converter';

interface WorkShiftTypes {
  name: string;
  value: string;
}


@Component({
  selector: 'app-source-list',
  templateUrl: './source-list.component.html',
  styleUrls: ['./source-list.component.css']
})
export class SourceListComponent implements OnInit, AfterViewInit {

  people: any[];
  jobs: any[];
  equipments: any[];
  workShiftTypes: WorkShiftTypes[];
  selectedShift: WorkShiftTypes;
  companyId: any;
  customEventStartingDate;
  customEventDuration;

  isFilterByPersonActive = false;


  @ViewChild('draggable_people') draggablePeopleExternalElement: ElementRef;
  @ViewChild('draggable_jobs') draggableJobsExternalElement: ElementRef;
  @ViewChild('draggable_equipments') draggableEquipmentsExternalElement: ElementRef
  
  constructor(private crudService: CRUDService) {
  }
  
  ngOnInit(): void {
    this.companyId = sessionStorage.getItem('company_id');
    this.people = [{ name: 'venkat' }, { name: 'shridar' }];
    this.crudService.getStaffsList(this.companyId).subscribe((staffData: any[]) => {
      this.people = staffData;
      //console.log(this.people);
    });

    this.crudService.getEquipmentsList(this.companyId).subscribe((equipmentData: any[]) => {
      this.equipments = equipmentData;
      //console.log(this.equipments);
    });

    this.crudService.getJobsList(this.companyId).subscribe((data: any[]) => {
      //console.log('jobs', data);
      this.jobs = data;
    });
    
    this.selectedShift = { name: 'Mattina', value: 'morning' };

    this.workShiftTypes = [
      { name: 'Mattina', value: 'morning' },
      { name: 'Pomeriggio', value: 'afternoon' },
      { name: 'Notte', value: 'night' },
      { name: 'Custom', value: 'custom' }
    ];
  }

  ngAfterViewInit() {
    //console.log('PEOPLE LIST ngAfterViewInit() START !!!')
    let self = this
  }


  /**
   * Create a valid event to be inserted into the calendar using the chosen information:
   * @param selectedShift: it reprsent the work shift with start and end time.
   * @param title : it is the name of the person working in the selected time shift.
   */
  createEventObject(selectedShift, title, type) {
    //console.log('createEventObject() START');

    let start_event = null;
    let end_event = null;
    let event_duration = 8;

    if (selectedShift == 'morning')
      start_event = '07:00';
    else if (selectedShift == 'afternoon')
      start_event = '15:00';
    else if (selectedShift == 'night')
      start_event = '23:00';
    else if (selectedShift == 'custom') {
      start_event = this.customEventStartingDate;
      event_duration = this.customEventDuration;
    }

    let event = {
      title,
      startTime: start_event,
      duration: { hours: event_duration },
      type: type
      // color: '#7FFF00'
    };

    return event;
  }


  /**
   * Retrieve the start and end date fired by the event inside the child component used to choose the
   * custom shift starting\ending time. Check if these values are valid and in case calculate the
   * event duration.
   */
  onChangeCustomShiftDate(event) {
    //console.log('onChangeCustomShiftDate() START');
    //console.log('START DATE: ' + event.startDate);
    //console.log('END DATE: ' + event.endDate);

    // If the event start date is < of the event end date the work shift is ok:
    if (event.startDate.getHours() < event.endDate.getHours()) {

      const workShiftDuration = event.endDate.getHours() - event.startDate.getHours();
      //console.log('workShiftDuration: ' + workShiftDuration);

      this.customEventStartingDate = event.startDate.getHours();
      this.customEventDuration = workShiftDuration;
    }
    else {

    }

  }


}
