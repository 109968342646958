import { Component, Input } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { environment } from 'src/environments/environment';

// text,email,tel,textarea,password, 
@Component({
    selector: 'date',
    template: ` 
    <div [formGroup]="form">
    <input *ngIf="!field.multiline" [attr.type]="field.type" class="form-control"  [attr.required]="field.required"
    [id]="field.name" [name]="field.name" [formControlName]="field.name">
    </div>
    <div class="alert alert-danger my-1 p-2 fadeInDown animated" *ngIf="!isValid && isDirty" > Enter valid {{field.label}} </div>
  `
})
export class DateComponent {
    @Input() field:any = {};
    @Input() form:FormGroup;
    dateFormat: string;
    get isValid() { return this.form.controls[this.field.name].valid; }
    get isDirty() { return this.form.controls[this.field.name].dirty; }
    constructor() {
    
    }
    ngOnInit() {
        this.dateFormat = environment.dateFormat;        
    // <textarea *ngIf="!field.multiline" [attr.type]="field.type" class="form-control" [id]="field.name" [name]="field.name" [formControlName]="field.name">
    }
}