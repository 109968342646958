import { Component, Input, OnInit } from '@angular/core';
import { ConfirmationService, MessageService } from 'primeng/api';
import { DialogService, DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { CRUDService } from '../../utilities/crud.service';
import { Table } from 'primeng/table';
import { environment } from 'src/environments/environment';
import { WorkFlowService } from 'src/app/utilities/workflow.service';

@Component({
  selector: 'app-company-notes',
  templateUrl: './company-notes.component.html',
  styleUrls: ['./company-notes.component.scss'],
  providers: [DialogService, MessageService, ConfirmationService]
})
export class CompanyNotesComponent implements OnInit {
  @Input() id: any;
  dateFormat:any
  hideNoteCard: any;
  companyNotes: any;
  userDetails: any;
  loading:Boolean = false;
  selectedmessageCategory: any;
  selectedRecipient: any;
  Recipient: any[];
  messageCategory: any[];
  selectedAppType: any;
  appType: any[];
  noteList: any[] = [];
  trainingRequired: any;
  minimumDate = new Date();
  company: any;
  companynotetype:any;
  searchvalue: any;
  staffList: any[];
  assignedStaff: any[] = [];
  selectedStaff: any;
  users: any;

  constructor(private crudService: CRUDService,
    private messageService: MessageService,
    private confirmationService: ConfirmationService,
    public ref: DynamicDialogRef,
    public config: DynamicDialogConfig,
    private dialogService: DialogService,
    private wfService: WorkFlowService,
    ) { }

  async ngOnInit() {
    this.loading = true;
    this.dateFormat = environment.DateFormat;
    this.userDetails = JSON.parse(sessionStorage.getItem('loggedInUser'));
    if (typeof this.config.data !== 'undefined' && typeof (this.config.data.id) !== 'undefined') {
                    this.id = this.config.data.id;
                    this.config = null;
    }
    //console.log(this.userDetails);
    this.crudService.getByValuesetName('CompanyNotes').subscribe((details: any) => {
      this.messageCategory = details;
      this.messageCategory.splice(0,1);
    });
    this.crudService.getByValuesetName('APPTypeList').subscribe((details: any) => {
      this.appType = details;
    });
    let users: any = await this.crudService.getDataByField('Users', 'status', '1').toPromise();
    this.users = users;
    this.crudService.getDataByField('companynotes', 'company_id', this.id).subscribe((data: any) => {
      this.noteList = data;
      this.noteList?.forEach(note => {
        let user = users.find(val=>(val.id === note.created_by));
        note.username = user.first_name +' '+user.last_name;
      });
    });
    this.crudService.getDataByField('company', 'id', this.id).subscribe((data: any) => {
      this.company = data[0];
      if(this.company?.client_type_id == 3 || this.company?.client_type_id == 5){
        this.companynotetype = 'Contractor';
      }else{
        this.companynotetype='Client';
      }
    });
    let data: any = await this.crudService.getAllSSPUsers(this.userDetails.company_id).toPromise();
    data.sort((a, b) => a.full_name > b.full_name && 1 || -1);
    this.staffList = data;
    this.staffList = this.staffList.filter(val=>(val.id !== '13325' && val.id !== '12135'))
    let trimList = {id: "13325", process_id: "108", first_name: "shri", last_name: "krishnan", company_id: "108", Initials: "SK", app_YN: null, create_login: "1", created_at: null, created_by: null, deleted: null, email: "l1dev@agileitexperts.com", email_yn: null, full_name: "shri krishnan", inducted_date: null, induction: null, last_modified_by: null, last_updated_at: null, mobile: "0404682215", password: null, phone: "0404682215", role: "10022", sms_yn: null, stafftype: "10034", user_id: "1", user_role_id: null, user_status: 1, username: null}
    let trimList1 ={id: "12135", process_id: "1", first_name: "Kylie", last_name: "Dubiez", company_id: "108", Initials: "KD", app_YN: null, create_login: "2", created_at: null, created_by: null, deleted: null, email: "l1dev@agileitexperts.com", email_yn: null, full_name: "Kylie Dubiez", inducted_date: null, induction: null, last_modified_by: null, last_updated_at: null, mobile: "(02) 9982 8284", password: "Kylie1@", phone: "(02) 99828284", role: "10095", sms_yn: null, stafftype: "10033", user_id: "835", user_role_id: "1", user_status: 1, username: "kylie"}
    this.staffList.push(trimList1)
    this.staffList.push(trimList)
    this.loading = false;
  }
  openNewNote() {
    this.hideNoteCard = true;
    this.companyNotes = {
      pmonly: 2,
      message_flow: 2,
      message_received: 2,
      comm_recipient_subcatg: null,
      phoned: 1,
      sms: 1,
      email: 1,
      callback: 1,
      status: 1,
      app_type: null,
      follow_up_date: null,
      trainRequired: null,
      company_id: this.id,
      created_by: this.userDetails.id,
      created_at: new Date(),
      message: null,
      sms_no: null,
      emailaddress: null,
      phone: null,
    };
  }

  cancel() {
    this.hideNoteCard = false;
  }
  saveNote() {
    this.loading = true;
    if (typeof this.companyNotes.message !== 'undefined' && typeof this.selectedmessageCategory !== 'undefined' && this.companyNotes.message != null) {
      if (typeof this.selectedAppType !== 'undefined' && this.selectedAppType != null) {
        this.companyNotes.app_type = this.selectedAppType.id;
      }
      if (this.trainingRequired) {
        this.companyNotes.trainRequired = '1';
      }else{
        this.companyNotes.trainRequired = '2';
      }

      if (this.companyNotes.id) {
        this.confirmationService.confirm({
          message: 'Are you sure you want to update note?',
          header: 'Confirm',
          icon: 'pi pi-exclamation-triangle',
          accept: () => {
            this.crudService.Update(this.companyNotes, 'companynotes').subscribe((res: any) => {
              if (res.success == 1) {
                this.crudService.getDataByField('companynotes', 'company_id', this.id).subscribe((data: any) => {
                  this.noteList = data;
                  this.noteList.forEach(note => {
                    let user = this.users.find(val=>(val.id === note.created_by));
                    note.username = user.first_name +' '+user.last_name;
                  });
                });
                this.hideNoteCard = false;
              }
            })
          },
        });
      
      }else{
        this.confirmationService.confirm({
          message: 'Are you sure you want to save note?',
          header: 'Confirm',
          icon: 'pi pi-exclamation-triangle',
          accept: () => {
            this.crudService.Create(this.companyNotes, 'companynotes').subscribe((res: any) => {
              if (res.success == 1) {
                this.hideNoteCard = false;
                 this.crudService.getDataByField('companynotes', 'company_id', this.id).subscribe(async (data: any) => {
                  this.crudService.getDataByField('CompanyStaffInfo', 'id', this.userDetails.staff_id).subscribe(async (staffdata: any) => {
                   let loggedInStaff = staffdata[0];
                  this.noteList = data;
                  this.noteList.forEach(note => {
                    let user = this.users.find(val=>(val.id === note.created_by));
                    note.username = user.first_name +' '+user.last_name;
                  });
                  let taskResponse = await this.wfService.createTaskValue(null, this.id,
                    null,
                    this.companynotetype,
                    this.companyNotes.message,
                    this.companyNotes.follow_up_date,
                    this.userDetails.id,
                    loggedInStaff);
                  if (taskResponse.id) {
                      // let res2: any = await this.JobService.updateJobWFStatus(taskResponse);
                  }
                });
              });
              }
            })
          },
        });

      
      }
    }
    else{
      this.messageService.add({ severity: 'error', summary: 'Error', detail:'Please Enter Mandatory Fields', life: 1000 });
    }
    this.loading = false;
  }

  createTask(task) {

  }
  
  deleteNote(note) {
    this.confirmationService.confirm({
      message: 'Are you sure you want to delete note?',
      header: 'Confirm',
      icon: 'pi pi-exclamation-triangle',
      accept: () => {
        this.crudService.Delete(note.id, 'companynotes').subscribe((data: any) => {
          if (data.success == 1) {
            this.noteList = [];
            this.crudService.getDataByField('companynotes', 'company_id', this.id).subscribe((data: any) => {
              this.noteList = data;
            });
            this.messageService.add({ severity: 'success', summary: 'Successful', detail: 'Note Removed.', life: 1000 });
          }
        });
      },
    });
  }

  clear(table: Table) {
    table.clear();
    this.searchvalue = '';
  }
  
   findIndexById(id: string) {
    let index = -1;
    for (let i = 0; i < this.noteList.length; i++) {
        if (this.noteList[i].id === id) {
            index = i;
            break;
        }
    }
    return index;
   }

   saveTaskUsers(event) {
    debugger
    console.log(event)
    let data = this.assignedStaff.filter(val=>(val.id === event.itemValue.id))
    if(data.length == 0) {
    this.assignedStaff.push(event.itemValue)
    }else {
    let data = this.assignedStaff;
    this.assignedStaff = data.filter(val=> (val.id !== event.itemValue.id))
    }
 }

 removeUser(staff) {
  this.assignedStaff = this.assignedStaff.filter((staffData) => (staffData !== staff))
  this.selectedStaff = this.assignedStaff;
}

}
