import { Component, OnInit, Input, EventEmitter, Output } from '@angular/core';
import { CRUDService } from 'src/app/utilities/crud.service';

import { ConfirmationService } from 'primeng/api';
import { MessageService } from 'primeng/api';

@Component({
  selector: 'app-staffcompliance',
  templateUrl: './staffcompliance.component.html',
  styleUrls: ['./staffcompliance.component.css']
})
export class StaffcomplianceComponent implements OnInit {

  sortOrder: number;
  sortField: string;
  sortOptions: any[];

  @Input()
  staffId:any;

  @Output()
  editComplianceEvent = new EventEmitter();
  deleteComplianceEvent = new EventEmitter();

  staffComplianceList:any[];
  
  constructor(private crudService: CRUDService, private messageService: MessageService,
    private confirmationService: ConfirmationService) {
  }

  ngOnInit(): void {
    //console.log('staff complinace component');
    this.sortOptions = [
      { label: 'Ascending', value: '!name' },
      { label: 'Descending', value: 'name' }
    ];
    this.getCompliance();
  }

  getCompliance(){
    this.crudService.getCompliancesByStaffId(this.staffId).subscribe((data: any[]) => {
          this.staffComplianceList = data;
          //console.log(this.staffComplianceList);
        });
  }

  editCompliance(complianceId:any){
    //console.log("in child edit"+complianceId);
    this.editComplianceEvent.emit(complianceId);
  }

  deleteCompliance(complianceId:any){
    //console.log("in child delete"+complianceId);
    this.deleteComplianceEvent.emit(complianceId);
  }

  onSortChange(event) {
    const value = event.value;

    if (value.indexOf('!') === 0) {
      this.sortOrder = -1;
      this.sortField = value.substring(1, value.length);
    }
    else {
      this.sortOrder = 1;
      this.sortField = value;
    }
  }


}
