import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { SocketIoModule, SocketIoConfig } from 'ngx-socket-io';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HttpConfigInterceptor } from './interceptor/httpconfig.interceptor';
import { CookieModule } from 'ngx-cookie';
import { StrengthMeterModule } from "ngx-strength-meter";
// PrimeNG Components for demos
import { AccordionModule } from 'primeng/accordion';
import { AutoCompleteModule } from 'primeng/autocomplete';
import { BreadcrumbModule } from 'primeng/breadcrumb';
import { ButtonModule } from 'primeng/button';
import { CalendarModule } from 'primeng/calendar';
import { CardModule } from 'primeng/card';
import { CarouselModule } from 'primeng/carousel';
import { ChartModule } from 'primeng/chart';
import { CheckboxModule } from 'primeng/checkbox';
import { ChipsModule } from 'primeng/chips';
import { CodeHighlighterModule } from 'primeng/codehighlighter';
import { ConfirmDialogModule } from 'primeng/confirmdialog';
import { ColorPickerModule } from 'primeng/colorpicker';
import { ContextMenuModule } from 'primeng/contextmenu';
import { DataViewModule } from 'primeng/dataview';
import { DialogModule } from 'primeng/dialog';
import { DynamicDialogModule } from 'primeng/dynamicdialog';
import { DynamicDialogRef } from 'primeng/dynamicdialog';
import { DynamicDialogConfig } from 'primeng/dynamicdialog';
import { DropdownModule } from 'primeng/dropdown';
import { FieldsetModule } from 'primeng/fieldset';
// import { FileUploadModule } from 'primeng/fileupload';                                                                                                                                                                                                                                                                                                                                               
import { FullCalendarModule } from 'primeng/fullcalendar';
import { GalleriaModule } from 'primeng/galleria';
import { GMapModule } from 'primeng/gmap';
import { InplaceModule } from 'primeng/inplace';
import { InputMaskModule } from 'primeng/inputmask';
import { InputNumberModule } from 'primeng/inputnumber';
import { InputSwitchModule } from 'primeng/inputswitch';
import { InputTextModule } from 'primeng/inputtext';
import { InputTextareaModule } from 'primeng/inputtextarea';
import { LightboxModule } from 'primeng/lightbox';
import { ListboxModule } from 'primeng/listbox';
import { MegaMenuModule } from 'primeng/megamenu';
import { MenuModule } from 'primeng/menu';
import { MenubarModule } from 'primeng/menubar';
import { MessagesModule } from 'primeng/messages';
import { MessageModule } from 'primeng/message';
import { MultiSelectModule } from 'primeng/multiselect';
import { OrderListModule } from 'primeng/orderlist';
import { OrganizationChartModule } from 'primeng/organizationchart';
import { OverlayPanelModule } from 'primeng/overlaypanel';
import { PaginatorModule } from 'primeng/paginator';
import { PanelModule } from 'primeng/panel';
import { PanelMenuModule } from 'primeng/panelmenu';
import { PasswordModule } from 'primeng/password';
import { PickListModule } from 'primeng/picklist';
import { ProgressBarModule } from 'primeng/progressbar';
import { RadioButtonModule } from 'primeng/radiobutton';
import { RatingModule } from 'primeng/rating';
import { RippleModule } from 'primeng/ripple';
import { ScrollPanelModule } from 'primeng/scrollpanel';
import { SelectButtonModule } from 'primeng/selectbutton';
import { SidebarModule } from 'primeng/sidebar';
import { SlideMenuModule } from 'primeng/slidemenu';
import { SliderModule } from 'primeng/slider';
import { SpinnerModule } from 'primeng/spinner';
import { SplitButtonModule } from 'primeng/splitbutton';
import { StepsModule } from 'primeng/steps';
import { TabMenuModule } from 'primeng/tabmenu';
import { TableModule } from 'primeng/table';
import { TabViewModule } from 'primeng/tabview';
import { TerminalModule } from 'primeng/terminal';
import { TieredMenuModule } from 'primeng/tieredmenu';
import { ToastModule } from 'primeng/toast';
import { ToggleButtonModule } from 'primeng/togglebutton';
import { ToolbarModule } from 'primeng/toolbar';
import { TooltipModule } from 'primeng/tooltip';
import { TreeModule } from 'primeng/tree';
import { TreeTableModule } from 'primeng/treetable';
import { VirtualScrollerModule } from 'primeng/virtualscroller';
import { EditorModule } from 'primeng/editor';

// Application Components
import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import { AppMainComponent } from './app.main.component';
import { AppNotfoundComponent } from './pages/app.notfound.component';
import { AppErrorComponent } from './pages/app.error.component';
import { AppAccessdeniedComponent } from './pages/app.accessdenied.component';
import { AppLoginComponent } from './pages/app.login.component';
import { AppCalendarComponent } from './pages/app.calendar.component';
import { AppMenuComponent } from './app.menu.component';
import { AppMenuitemComponent } from './app.menuitem.component';
import { AppBreadcrumbComponent } from './app.breadcrumb.component';
import { AppConfigComponent } from './app.config.component';
import { AppRightPanelComponent } from './app.rightpanel.component';
import { AppTopBarComponent } from './app.topbar.component';
import { AppFooterComponent } from './app.footer.component';
import { PrivacyPolicyComponent } from '././privacypolicy.component';
import { TermsOfUseComponent } from '././termsofuse.component';

// Demo pages
import { DocumentationComponent } from './demo/view/documentation.component';

// Application services
import { BreadcrumbService } from './breadcrumb.service';
import { MenuService } from "./app.menu.service";
import { CRUDService } from './utilities/crud.service';
import { EncrDecrService } from './utilities/encr-decr.service';
import { WorkFlowService } from './utilities/workflow.service';
import { ImageService } from './utilities/image.service';
import { JOBService } from './utilities/job.service';
import { AppCodeModule } from './app.code.component';
import { DashboardComponent } from '././modules/dashboard/dashboard.component';
import { SchedulerComponent } from '././modules/scheduler/scheduler.component';
import { ConfirmationService, MessageService } from 'primeng/api';
import { DepotsComponent } from '././modules/depots/depots.component';
import { StaffsComponent } from '././modules/staffs/staffs.component';
import { ChangePasswordComponent } from '././modules/change-password/change-password.component';


//Additional Imports
import { NgxDocViewerModule } from 'ngx-doc-viewer';
import { SignaturePadModule } from 'angular2-signaturepad';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { GooglePlaceModule } from "ngx-google-places-autocomplete";
import { Ng2ImgMaxModule } from 'ng2-img-max';
import { FileUploadModule } from 'ng2-file-upload';
import { RegistrationComponent } from './modules/registration/registration.component';
import { TPRegistrationComponent } from './modules/tp-registration/tp-registration.component';
import { TPServiceLevelAgreementComponent } from './modules/tp-service-level-agreement/tp-service-level-agreement.component';
import { StaffcomplianceComponent } from './modules/staffcompliance/staffcompliance.component';
import { UsersComponent } from './modules/users/users.component';
import { SourceListComponent } from './modules/source-list/source-list.component';
import { PeopleListItemComponent } from './modules/people-list-item/people-list-item.component';
import { JobsListItemComponent } from './modules/jobs-list-item/jobs-list-item.component';
import { EquipmentsComponent } from './modules/equipments/equipments.component';
import { ImageMarkingComponent } from './modules/image-marking/image-marking.component';
import { TpComplianceComponent } from './modules/tp-compliance/tp-compliance.component';
import { EmailConfigComponent } from './modules/email-config/email-config.component';
import { SendEmailComponent } from './modules/send-email/send-email.component';
import { ContractorListingComponent } from './modules/contractor-listing/contractor-listing.component';
import { RegisterComponent } from './modules/register/register.component';
import { DocumentComponent } from './modules/document/document.component';
import { DocumentListComponent } from './modules/document-list/document-list.component';
import { TaskBoardComponent } from './modules/task-board/task-board.component';
import { JobAllocationComponent } from './modules/job-allocation/job-allocation.component';
import { JobUserComponent } from './modules/job-user/job-user.component';
import { ScheduleUserComponent } from './modules/schedule-user/schedule-user.component';
import { TaskListComponent } from './modules/task-list/task-list.component';
import { TaskDetailsComponent } from './modules/task-details/task-details.component';
import { TaskMessagesComponent } from './modules/task-messages/task-messages.component';
import { SSPHomepageComponent } from './modules/ssp-homepage/ssp-homepage.component';
import { WorkflowComponent } from './modules/workflow/workflow.component';
import { TaskCheckListComponent } from './modules/task-checklist/task-checklist.component';
import { DateTimeCompComponent } from './date-time-comp/date-time-comp.component';
import { ResetPasswordComponent } from './reset-password/reset-password.component';
import { PasswordCheckComponent } from './password-check/password-check.component';
import { SignUpComponent } from './sign-up/sign-up.component';
import { RequestResetComponent } from './request-reset/request-reset.component';
import { AppMailComponent } from './modules/app-mail/app-mail.component';
import { CompaniesSettingsComponent } from './modules/companies-settings/companies-settings.component';
import { TileComponent } from './modules/tile/tile.component';
import { AppChartComponent } from './modules/app-chart/app-chart.component';
import { Scheduler2Component } from './modules/scheduler2/scheduler2.component';
import { DisplayComponent } from './utilities/display.component';
import { ElevationComponent } from './utilities/elevation.component';
import { FlexboxComponent } from './utilities/flexbox.component';
import { GridComponent } from './utilities/grid.component';
import { IconsComponent } from './utilities/icons.component';
import { SpacingComponent } from './utilities/spacing.component';
import { TextComponent } from './utilities/text.component';
import { TypographyComponent } from './utilities/typography.component';
import { WidgetsComponent } from './utilities/widgets.component';
import { MenuAccessComponent } from './modules/menu-access/menu-access.component';
import { AllUsersComponent } from './modules/all-users/all-users.component';
import { HistoryComponent } from './modules/history/history.component';
import { CompanyComplianceComponent } from './modules/company-compliance/company-compliance.component'
import { CompressImageService } from './utilities/compress.image.service';
import { JobDetailsApprovalsFlaggingComponent } from './modules/job-details-approvals-flagging/job-details-approvals-flagging.component';
import { WorkflowAutomationExceptionsComponent } from './modules/workflowautomationexceptions/workflowautomationexceptions.component';
import { RateCardReportComponent } from './modules/ratecard-report/ratecard-report.component';
import { AssignRateSetComponent } from './modules/assign-rateset/assign-rateset.component';
import { DashboardsModule } from './modules/dashboards/dashboards.module';
import { AppReminderComponent } from './modules/app-reminder/app-reminder.component';
import { NotificationComponent } from './modules/notification/notification.component';
import { JobsListComponent } from './modules/jobs-list/jobs-list.component';
import { ServiceWorkerModule, SwRegistrationOptions } from '@angular/service-worker';

import { NotificationPermissionComponent } from './modules/notification-permission/notification-permission.component';
import { OpenNotificationComponent } from './modules/open-notification/open-notification.component';

import { ProceedConfirmationComponent } from './modules/proceed-confirmation/proceed-confirmation.component';
import { JobDetailsInvoiceComponent } from './modules/job-details-invoice/job-details-invoice.component';
import { TpCompanyComplianceComponent } from './modules/tp-company-compliance/tp-company-compliance.component';
import { TrainingComponent } from './modules/training/training.component';
const config: SocketIoConfig = { url: 'https://c12f7d26c588.ngrok.io', options: {} };
import { WorkflowDefinitionComponent } from '././modules/workflow-definition/workflow-definition.component';
import { WorkflowStepsComponent } from '././modules/workflow-steps/workflow-steps.component';
import { StaffTrainingComponent } from './modules/staff-training/staff-training.component';
import { TrainingListComponent } from './modules/training-list/training-list.component';
// import { JobModule } from './job/job.module';
import { SharedModule } from './shared/shared.module';
import { AdminModule } from './admin/admin.module';
import { ImageCropperModule } from 'ngx-image-cropper';
import { CropImageComponent } from './modules/crop-image/crop-image.component';
 import { CompanyNotesComponent } from './admin/company-notes/company-notes.component';
import { ChatbotComponent } from './chat/chatbot/chatbot.component';
import { AuthreditectComponent } from './authreditect/authreditect.component'
import { PathLocationStrategy, LocationStrategy } from '@angular/common';
import { TaskUpdateComponent } from './modules/task-update/task-update.component';
import { StaffPublicComponent } from './modules/staff-public/staff-public.component';

@NgModule({
    imports: [
        SharedModule,
        BrowserModule,
        FormsModule,
        SocketIoModule.forRoot(config),
        ReactiveFormsModule,
        AppRoutingModule,
        AppCodeModule,
        HttpClientModule,
        BrowserAnimationsModule,
        AccordionModule,
        AutoCompleteModule,
        BreadcrumbModule,
        ButtonModule,
        CalendarModule,
        CardModule,
        CarouselModule,
        ChartModule,
        CheckboxModule,
        ChipsModule,
        CodeHighlighterModule,
        ConfirmDialogModule,
        ColorPickerModule,
        ContextMenuModule,
        DataViewModule,
        DialogModule,
        DashboardsModule,
        DynamicDialogModule,
        DropdownModule,
        FieldsetModule,
        FileUploadModule,
        FullCalendarModule,
        GalleriaModule,
        GMapModule,
        InplaceModule,
        InputMaskModule,
        InputNumberModule,
        InputSwitchModule,
        InputTextModule,
        InputTextareaModule,
        LightboxModule,
        ListboxModule,
        MegaMenuModule,
        MenuModule,
        MenubarModule,
        MessageModule,
        MessagesModule,
        MultiSelectModule,
        OrderListModule,
        OrganizationChartModule,
        OverlayPanelModule,
        PaginatorModule,
        PanelModule,
        PanelMenuModule,
        PasswordModule,
        PickListModule,
        ProgressBarModule,
        RadioButtonModule,
        RatingModule,
        RippleModule,
        ScrollPanelModule,
        SelectButtonModule,
        SidebarModule,
        SlideMenuModule,
        SliderModule,
        SpinnerModule,
        SplitButtonModule,
        StepsModule,
        TableModule,
        TabMenuModule,
        TabViewModule,
        TerminalModule,
        TieredMenuModule,
        ToastModule,
        ToggleButtonModule,
        ToolbarModule,
        TooltipModule,
        TreeModule,
        TreeTableModule,
        VirtualScrollerModule,
        EditorModule,
        GooglePlaceModule,
        Ng2ImgMaxModule,
        DragDropModule,
        HttpClientModule,
        SignaturePadModule,
        NgxDocViewerModule,
        CookieModule.forRoot(),
        StrengthMeterModule,
        ServiceWorkerModule.register('ngsw-worker.js'),
        ImageCropperModule,
        // JobModule,
        AdminModule
    ],
    declarations: [
        AppComponent,
        AppMainComponent,
        AppMenuComponent,
        AppMenuitemComponent,
        AppTopBarComponent,
        AppFooterComponent,
        AppRightPanelComponent,
        AppConfigComponent,
        AppBreadcrumbComponent,
        AppNotfoundComponent,
        AppErrorComponent,
        AppAccessdeniedComponent,
        AppLoginComponent,
        AppCalendarComponent,
        DocumentationComponent,
        DashboardComponent,
        SchedulerComponent,
        DepotsComponent,
        StaffsComponent,
        ChangePasswordComponent,
        RegistrationComponent,
        TPRegistrationComponent,
        TPServiceLevelAgreementComponent,
        StaffcomplianceComponent,
        UsersComponent,
        SourceListComponent,
        PeopleListItemComponent,
        JobsListItemComponent,
        EquipmentsComponent,
        ImageMarkingComponent,
        TpComplianceComponent,
        EmailConfigComponent,
        SendEmailComponent,
        RegisterComponent,
        JobDetailsApprovalsFlaggingComponent,
        WorkflowAutomationExceptionsComponent,
        TaskBoardComponent,
        DocumentComponent,
        DocumentListComponent,
        PrivacyPolicyComponent,
        TermsOfUseComponent,
        JobAllocationComponent,
        JobUserComponent,
        ScheduleUserComponent,
        TaskListComponent,
        TaskDetailsComponent,
        TaskMessagesComponent,
        SSPHomepageComponent,
        WorkflowComponent,
        TaskCheckListComponent,
        DateTimeCompComponent,
        ResetPasswordComponent,
        PasswordCheckComponent,
        SignUpComponent,
        RequestResetComponent,
        AppMailComponent,
        CompaniesSettingsComponent,
        TileComponent,
        AppChartComponent,
        Scheduler2Component,
        DisplayComponent,
        ElevationComponent,
        FlexboxComponent,
        GridComponent,
        IconsComponent,
        SpacingComponent,
        TextComponent,
        TypographyComponent,
        WidgetsComponent,
        MenuAccessComponent,
        AllUsersComponent,
        HistoryComponent,
        CompanyComplianceComponent,
        RateCardReportComponent,
        AssignRateSetComponent,
        ContractorListingComponent,
        AppReminderComponent,
        NotificationComponent,
        JobsListComponent,
        NotificationPermissionComponent,
        OpenNotificationComponent,
        ProceedConfirmationComponent,
        JobDetailsInvoiceComponent,
        TpCompanyComplianceComponent,
        WorkflowDefinitionComponent,
        WorkflowStepsComponent,
        TrainingComponent,
        StaffTrainingComponent,
        TrainingListComponent,
        CropImageComponent,
        ChatbotComponent,
        AuthreditectComponent,
        TaskUpdateComponent,
        StaffPublicComponent,
       
    ],
    entryComponents: [NotificationComponent, JobDetailsApprovalsFlaggingComponent,
          CompanyNotesComponent,
          ChatbotComponent,
        WorkflowAutomationExceptionsComponent, AppReminderComponent, NotificationPermissionComponent, OpenNotificationComponent,
        TaskDetailsComponent, ProceedConfirmationComponent, JobDetailsInvoiceComponent, PasswordCheckComponent, CropImageComponent],
    providers: [
        MenuService, BreadcrumbService, CRUDService, WorkFlowService, CompressImageService, EncrDecrService,
        ImageService, JOBService, MessageService, ConfirmationService, Location, { provide: LocationStrategy, useClass: PathLocationStrategy },
        // {
        //     provide: HTTP_INTERCEPTORS, useClass: HttpConfigInterceptor, multi: true, useValue: {
        //         tag: 'common'
        //     }
        // },
        /* Unccoment to enable encrypt descrypt
         {
            provide: HTTP_INTERCEPTORS,
            useClass: CRUDService,
            multi: true,
            useValue: {
                tag: 'common'
            }
        }, */
        DynamicDialogRef, DynamicDialogConfig,
        {
            provide: SwRegistrationOptions,
            useFactory: () => ({ enabled: false }),
        },
    ],
    exports: [NotificationComponent, CompanyComplianceComponent],
    schemas: [
        CUSTOM_ELEMENTS_SCHEMA
    ],
    bootstrap: [AppComponent]
})
export class AppModule { }