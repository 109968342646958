import { Component, OnInit, Input } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ConfirmationService, MessageService } from 'primeng/api';
import { CRUDService } from 'src/app/utilities/crud.service';
import { DynamicDialogRef, DynamicDialogConfig, DialogService } from 'primeng/dynamicdialog';
import { JOBService } from 'src/app/utilities/job.service';
import { JobAdminComponent } from '../job-admin/job-admin.component';
import { SortEvent } from 'primeng/api';
import { JobDetailsApprovalsFlaggingComponent } from '../../modules/job-details-approvals-flagging/job-details-approvals-flagging.component';
import { environment } from 'src/environments/environment';
import { CommonService } from 'src/app/utilities/common.service';
import { JobDetailsApprovalsComponent } from 'src/app/job/job-details-approvals/job-details-approvals.component';
import { EncrDecrService } from '../../utilities/encr-decr.service';
import { NgTinyUrlService } from 'ng-tiny-url';
import { TaskListComponent } from 'src/app/modules/task-list/task-list.component';
import { interval, Subscription } from 'rxjs';
import { Job } from './job-operations';
import { WorkflowTimelineComponent } from '../workflow-timeline/workflow-timeline.component'
import * as moment from 'moment';

@Component({
  selector: 'app-job-operations',
  templateUrl: './job-operations.component.html',
  styleUrls: ['./job-operations.component.scss'],
  providers: [DialogService, MessageService, ConfirmationService, JOBService]
})
export class JobOperationsComponent implements OnInit {
  @Input() filterValue: any;
  @Input() filterBy: any;
  @Input() jobsData: any;
  @Input() bucket: any;
  @Input() JobStatusLive: any;
  @Input() catg: any;
  @Input() reqId:any;
  jobList: any[] = [];
  isFilterFlag: Boolean = false;
  isFilterWatch: Boolean = false;
  selectedJob: any = [];
  viewTaskInfo: Boolean = false;
  cols: any[];
  exportColumns: any[];
  searchValue: any;
  query: any = null;
  companyId: any;
  userDetails: any;
  viewType: any;
  overAllStatus: any = [];
  toggleSearch: any = false;
  showMoreColumn: any = false;
  jobStatus: any = [];
  selectedOverAllStatus: any;
  selectedJobStatus: any = [];
  clientsList: any = [];
  selectedClient: any = []
  originalJobList: any = [];
  overallVal: any;
  selectStatus: any;
  client_type_id: any;
  speedDial: Boolean = false;
  loading: Boolean = false;
  showFilterColours: Boolean = false;
  allFlagInfo: any[];
  showFlagsHistory: Boolean = false;
  showColorBox = environment.showColorBox
  selectedColour: any = { name: '', code: 'silver' };
  custCareExpList_324: any;
  is324Exempt: any = false;
  shortenedUrl: any;

  reasonList: any;
  selectedReasons: any;
  selectedPercent: any;

  selectedItem: any;
  viewDueIn = 0;

  next_followup_date: any;
  event_remarks: any;
  selectedUser: any;
  staffList: any;
  reasonsFiltered: any = [];
  jobCheckListDialog: Boolean = false;
  selectedJobAllocationId: any;
  selectJobId: any;
  checkListData: any;
  workFlowData : any;
  workFlowDataId : any;
  timeLineDialog : boolean = false;
  showchecklist: boolean = false;
  disableSavebtn: boolean = false;
  percentList: any[] = [
    { code: '0', min: '0', max: '0' },
    { code: '1-25', min: '1', max: '25' },
    { code: '26-50', min: '26', max: '50' },
    { code: '51-75', min: '51', max: '75' },
    { code: '76-100', min: '76', max: '100' },
  ]
  categories: any[] = [
    { code: 'B1', description: 'Pending for Allocation', status: ['Send Offer'], count: 0, red: 0, amber: 0, green: 0, total: 0 },
    { code: 'B2', description: 'To be accepted by TP', status: ['Await Acceptance'], count: 0, red: 0, amber: 0, green: 0, total: 0 },
    { code: 'B3', description: 'Works to be quoted', status: ['Await Cost', 'Cost Saved'], count: 0, red: 0, amber: 0, green: 0, total: 0 },
    { code: 'B4', description: 'Quotes Pending For Approval', status: ['Approve Quote'], count: 0, red: 0, amber: 0, green: 0, total: 0 },
    { code: 'B5', description: 'Approved works to be completed', status: ['Await Invoice'], count: 0, red: 0, amber: 0, green: 0, total: 0 },
    { code: 'B6', description: 'Completed works to be Invoiced', status: ['Approve Invoice'], count: 0, red: 0, amber: 0, green: 0, total: 0 }
  ];

  timeSlots = ['7:30am', '8:00am', '8:30am', '9:00am', '9:30am', '10:00am', '10:30am', '11:00am', '11:30am', '12:00pm', '12:30pm', '1:00pm', '1:30pm', '2:00pm', '2:30pm',
    '3:00pm', '3:30pm', '4:00pm', '4:30pm', '5:00pm', '5:30pm', '6:00pm', '6:30pm', '7:00pm', '7:30pm'];
  filteredTimeSlots: any[];
  selectedTime: any;
  selectedTasksList: any[] = [];

  displayAllocationDialog: boolean = false;
  jobFlags = [
    { name: 'red', code: 'firebrick' },
    { name: 'blue', code: 'darkcyan' },
    { name: 'yellow', code: 'darkgoldenrod' },
    { name: 'orange', code: 'darkorange' },
    { name: 'purple', code: 'purple' },
    { name: 'deeppink', code: 'deeppink' },
    { name: '', code: '' }];
  exportdisplay: Boolean = false;

  private subscription: Subscription;
  istableLoading: boolean;
  displayRowCount: any;
  isExport: boolean=false;
  isDelete: boolean=false;
  selectedroute: string;
  staffInfo: any=[];
  selectedJobOwner: any;
  videoUploadCheck: boolean=false;

  constructor(
    private route: ActivatedRoute,
    private crudService: CRUDService,
    private messageService: MessageService,
    private confirmationService: ConfirmationService,
    private router: Router,
    public ref: DynamicDialogRef,
    public config: DynamicDialogConfig,
    private dialogService: DialogService,
    private JobService: JOBService,
    private commonservice: CommonService,
    private encrypt: EncrDecrService,
    private tinyUrl: NgTinyUrlService,

  ) {
    this.companyId = localStorage.getItem('company_id');
    this.userDetails = JSON.parse(localStorage.getItem('loggedInUser'));
  }




  openNew() {
    const ref = this.dialogService.open(JobAdminComponent, {
      data: {
        jobId: 0,
      },
      width: '100%',
      height: '100%'
    })
    ref.onClose.subscribe((response: any) => {
      this.loadAllJobsList();
      /* if(response){
        this.jobList = [response, ...this.jobList];
      } */
    });
  }

  async loadAllJobsList() {
    //let data: any = await this.crudService.getAllData('JobAllocationChecklist').toPromise();
    // let data = [];
    // this.checkListData = data;
    // let wfData: any = await this.crudService.getDataByField('JobWorkflow','status',1).toPromise();
    // this.workFlowData = wfData;

    await this.searchNow();
    /* this.searchValue = this.searchValue ? this.searchValue : '';
    this.query = 'select * from joboperationstempview order by JobId desc';
    this.crudService.ExecuteQuery(this.query).subscribe((data: Job[]) => {
      data.forEach(jobInfo => {
        let job: Job = {
          AllocationId: Number(jobInfo.AllocationId),
          ClientSubtype: jobInfo.ClientSubtype,
          FullAddress: jobInfo.FullAddress,
          InvoiceAmount: jobInfo.InvoiceAmount,
          JobId: Number(jobInfo.JobId),
          JobNo: Number(jobInfo.JobNo),
          JobStatus: jobInfo.JobStatus,
          SubStatus: jobInfo.SubStatus,
          TreeProfessional: jobInfo.TreeProfessional,
          accept_status: jobInfo.accept_status,
          job_claim_no: jobInfo.job_claim_no,
          JobDate: jobInfo.JobDate
        }
        if (jobInfo.JobDate) {
          //job.JobDate = stringToDate(jobInfo.JobDate,"dd/MM/yyyy","/");
        } else {
          job.JobDate = null;
        }
        this.jobList.push(job);
      })
    }) */
  }

  loadJobListByClient(id) {
    this.searchValue = this.searchValue ? this.searchValue : '';
    this.query = 'select * from joboperationstempview where companyId = ' + id + ' order by JobId desc';
    this.crudService.ExecuteQuery(this.query).subscribe((data: Job[]) => {
      data.forEach(jobInfo => {
        const job: Job = {
          AllocationId: Number(jobInfo.AllocationId),
          ClientSubtype: jobInfo.ClientSubtype,
          FullAddress: jobInfo.FullAddress,
          InvoiceAmount: jobInfo.InvoiceAmount,
          JobId: Number(jobInfo.JobId),
          JobNo: Number(jobInfo.JobNo),
          JobStatus: jobInfo.JobStatus,
          SubStatus: jobInfo.SubStatus,
          TreeProfessional: jobInfo.TreeProfessional,
          accept_status: jobInfo.accept_status,
          job_claim_no: jobInfo.job_claim_no
        }


        if (jobInfo.JobDate) {
          //job.JobDate = stringToDate(jobInfo.JobDate,"dd/MM/yyyy","/");
        } else {
          job.JobDate = null;
        }
        this.jobList.push(job);

       // console.log('list111',this.jobList)
      })
    })
  }

  loadJobListByContractor(id) {
    this.searchValue = this.searchValue ? this.searchValue : '';
    this.query = 'select * from joboperationstempview where contractor_id = ' + id + ' order by JobId desc';
    this.crudService.ExecuteQuery(this.query).subscribe((data: Job[]) => {
      data.forEach(jobInfo => {
        const job: any = {
          AllocationId: Number(jobInfo.AllocationId),
          ClientSubtype: jobInfo.ClientSubtype,
          FullAddress: jobInfo.FullAddress,
          InvoiceAmount: jobInfo.InvoiceAmount,
          JobId: Number(jobInfo.JobId),
          JobNo: Number(jobInfo.JobNo),
          JobStatus: jobInfo.JobStatus,
          SubStatus: jobInfo.SubStatus,
          TreeProfessional: jobInfo.TreeProfessional,
          accept_status: jobInfo.accept_status,
          job_claim_no: jobInfo.job_claim_no,
        }
        if (jobInfo.JobDate) {
          //job.JobDate = stringToDate(jobInfo.JobDate,"dd/MM/yyyy","/");
        } else {
          job.JobDate = null;
        }
        this.jobList.push(job);
       // console.log('list222',this.jobList)
      })
    })
  }

  env: any;
  async ngOnInit(): Promise<void> {
    this.env = environment;
    this.overAllStatus =
      [{ id: 1, name: 'Active' },
      { id: 2, name: 'Invoiced' },
      { id: 3, name: 'Completed' },
      { id: 4, name: 'Cancelled' },
      { id: 5, name: 'On Hold' },
      { id: 6, name: 'All' }
      ];
    this.selectedOverAllStatus = { id: 1, name: 'Active' };
    this.jobStatus = [
      { id: 'Send Offer', name: 'Send Offer' },
      { id: 'Await Acceptance', name: 'Await Acceptance' },
      { id: 'Await Cost', name: 'Await Cost' },
      { id: 'Cost Saved', name: 'Cost Saved' },
      { id: 'Approve Quote', name: 'Approve Quote' },
      { id: 'Await Invoice', name: 'Await Invoice' },
      { id: 'Approve Invoice', name: 'Approve Invoice' },
    ]
    this.clientsList = [
      { id: 1, name: 'Insurance' },
      { id: 2, name: 'SES' },
      { id: 6, name: 'DHA' },
      { id: 3, name: 'Facility Management' },
      { id: 4, name: 'New Zealand' },
      { id: 5, name: 'Enviro' },
    ]
    console.log("this.config.data",this.config.data);
    this.selectedroute = sessionStorage.getItem('selectedRoute')
    let per_data :any=await this.crudService.getmenupermissions()
    this.isExport = per_data.isExport;
    this.isDelete = per_data.isDelete;
    if (typeof this.config.data !== 'undefined') {
      this.filterBy = this.config.data.filterBy;
      this.filterValue = this.config.data.filterValue;
      this.jobsData = this.config.data.jobs;
      this.bucket = this.config.data.bucket;
      this.catg = this.config.data.catg;
      this.JobStatusLive = this.config.data.JobStatusLive;
    } else {
      this.filterBy = this.filterBy ? this.filterBy : null;
      this.filterValue = this.filterValue ? this.filterValue : null;
      this.jobsData = this.jobsData ? this.jobsData : null;
      this.bucket = this.bucket ? this.bucket : 'All';
      this.catg = this.catg ? this.catg : null;
    }
    //let checklistData: any = await this.crudService.getAllData('JobAllocationChecklist').toPromise();
    let checklistData = [];
    this.checkListData = checklistData;

    this.crudService.getDataByField('JobWorkflow','status',1).subscribe(async (data) => {
      this.workFlowData = data;
    });

    
    this.crudService.getDataByField('businessSettings', 'company_id', '108').subscribe(async (data: any[]) => {
      let  videoOut = data[0].videoUpload;
     // console.log('videoOut',videoOut)
      if(videoOut == 1){
       this.videoUploadCheck =true;


    await this.crudService.getDataByField('CompanyStaffInfo', 'company_id', '108').subscribe((data: any[]) => {
      this.staffInfo = data;
     // console.log('staffInfo',this.staffInfo)
  


    const formattedData = this.staffInfo.map(item => ({

      user_id: item.id,
      first_name: item.first_name + ' ' + item.last_name

    }));

    this.staffList = formattedData;

   // console.log('staffList',this.staffList)
  });
}
else{
 this.videoUploadCheck=false;
}

});
  
    // this.loading = true;

    // let data: any = await this.crudService.getAllSSPUsers(this.companyId).toPromise();
    // data.sort((a, b) => a.full_name > b.full_name && 1 || -1);
    // this.staffList = data;

    /* if (typeof this.filterBy !== 'undefined') {
      this.companyId = this.reqId;
      this.viewType = 'TM';
      if (this.filterBy === 'contractor') {
        this.client_type_id = '3';
        //this.loadJobListByContractor(this.companyId);
      } else if (this.filterBy === 'client') {
        this.loadJobListByClient(this.companyId);
      }
    } else {
      this.client_type_id = '3';
       if (this.viewType === 'TM') {
        this.loadAllJobsList();
      } 
    } */

    // this.reasonList = await this.crudService.getByValuesetName('job_wf_sub_catg').toPromise();
    // this.reasonList.sort((a, b) => a.valueset_detail_name > b.valueset_detail_name && 1 || -1);
    await this.searchNow();
    this.cols = [
      { field: 'JobNo', header: 'Job Number' },
      { field: 'JobDate', header: 'Job Entry' },
      { field: 'ageing_status', header: 'Action Due Status' },
      { field: 'SubStatus', header: 'Recent Update' },
      { field: 'priority_name', header: 'Priority' },
      { field: 'ClientSubtype', header: 'Client' },
      { field: 'TreeProfessional', header: 'Tree Professional' },
      { field: 'FullAddress', header: 'Site Address' },
      { field: 'job_claim_no', header: 'Claim No' },
      { field: 'JobOwnerName', header: 'Job Owner Name' },
      { field: 'JobStatus', header: 'Job Status' },
      { field: 'WP_Total', header: 'WP $' },
      { field: 'TPCost', header: 'Costing $' },
      { field: 'Margin', header: 'Margin' }

    ];
    this.exportColumns = this.cols.map(col => ({ title: col.header, dataKey: col.field }));
    //this.exportdisplay = await this.commonservice.exportCheck();
    //console.log(this.exportColumns);
  }

  async encode(jobId: any, AllocationId: any) {
    this.shortenedUrl = await this.crudService.encodeJobURL('client', jobId, AllocationId).toPromise();
    console.log(this.shortenedUrl);
    /* const encryptedId = this.encrypt.encrypt(jobId, 'client');
    const encoded = this.encrypt.jsEncode(encryptedId);

    const encryptedAllocationId = this.encrypt.encrypt(AllocationId, 'client');
    const encodedAllocationId = this.encrypt.jsEncode(encryptedAllocationId);

    let url=environment.local_url+'/channel/client/'+encoded+'/'+encodedAllocationId+'/1'; */
    //let url=environment.local_url+'/chat';
    /* console.log(url); */
    /* this.tinyUrl.shorten(url).subscribe(res => {
      this.shortenedUrl = res;
      this.messageService.add({ severity: 'success', summary: 'Successful', detail: this.shortenedUrl, life: 4000 });
      console.log(this.shortenedUrl); 
    }); */
  }

  async resetFilters() {
    // this.jobList = this.originalJobList;
    // this.searchValue = null;
    // this.selectedJobStatus = [];
    // this.selectedOverAllStatus = [];
    // this.selectedClient = [];
    // this.selectedColour = {};
    this.istableLoading=true
    this.searchValue = null;
    await this.searchNow();
    this.istableLoading =false;
    this.loading = false;
  }

  /*  filterData(dt) {
     //console.log('dt : ', dt);
     let unfilteredJobs = this.jobList;
     let filteredJobs: any = [];
     let isFiltered: Boolean = false;
 
     if (this.selectedOverAllStatus.length > 0) {
       this.selectedOverAllStatus.forEach(status => {
         if (isFiltered) {
           let list = unfilteredJobs.filter((val: any) => val.status === status.code);
           isFiltered = true;
           filteredJobs = filteredJobs.concat(list);
         } else {
           let list = unfilteredJobs.filter((val: any) => val.status === status.code);
           isFiltered = true;
           filteredJobs = list;
         }
       })
     }
     if (this.selectedJobStatus.length > 0) {
       this.selectedJobStatus.forEach(status => {
         if (isFiltered) {
           let list = unfilteredJobs.filter((val: any) => val.JobStatus === status.code);
           isFiltered = true;
           filteredJobs = filteredJobs.concat(list);
         } else {
           let list = unfilteredJobs.filter((val: any) => val.JobStatus === status.code);
           isFiltered = true;
           filteredJobs = list;
         }
       })
     }
     //ClientSubtype
     if (this.selectedClient.length > 0) {
       this.selectedClient.forEach(status => {
         if (isFiltered) {
           let list = unfilteredJobs.filter((val: any) => val.ClientSubtype === status.business_name);
           isFiltered = true;
           filteredJobs = filteredJobs.concat(list);
         } else {
           let list = unfilteredJobs.filter((val: any) => val.ClientSubtype === status.business_name);
           isFiltered = true;
           filteredJobs = list;
         }
       })
     }
     this.jobList = filteredJobs;
     dt.value = filteredJobs;
   }
  */
  toggleFn(key) {
    switch (key) {
      case 'search':
        this.toggleSearch = !this.toggleSearch;
        break;
      case 'column':
        this.showMoreColumn = !this.showMoreColumn;
        break;
      default:
        break;
    }
  }

  filterDatatableByflag() {
    if (this.isFilterFlag) {
      this.jobList = this.jobList.filter((val: any) => (val.wf_status == '2' || val.wf_status == 2));
    } else {
      this.jobList = this.originalJobList;
    }
  }

  filterDatatableByWatch() {
    if (this.isFilterWatch) {
      this.jobList = this.jobList.filter((val: any) => (val.watched == true));
    } else {
      this.jobList = this.originalJobList;
    }
  }



  exportExcel(selectedDepots, table) {
    let JsonData: any[] = [];
    if (this.selectedJob != null && this.selectedJob.length > 0) {
      JsonData = this.selectedJob;
    } else {//console.log(table.filteredValue,'checkthis');
      if (typeof table.filteredValue !== 'undefined') {
        if (table.filteredValue.length !== this.jobList.length && table.filteredValue.length > 0) {
          JsonData = table.filteredValue;
        } else {
          JsonData = this.jobList;
        }
      } else {
        JsonData = this.jobList;
      }
    }


    let reformattedArray = JsonData.map(obj => {
      let rObj = {};
      this.cols.forEach(pair => {
        rObj[pair.header.toString()] = obj[pair.field];
      });
      return rObj;
    });
    //console.log(reformattedArray);

    import('xlsx').then(xlsx => {
      const worksheet = xlsx.utils.json_to_sheet(reformattedArray);
      const workbook = { Sheets: { data: worksheet }, SheetNames: ['data'] };
      const excelBuffer: any = xlsx.write(workbook, { bookType: 'xlsx', type: 'array' });
      this.saveAsExcelFile(excelBuffer, 'Joblist');
    });
  }

  saveAsExcelFile(buffer: any, fileName: string): void {
    import('file-saver').then(FileSaver => {
      const EXCEL_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
      const EXCEL_EXTENSION = '.xlsx';
      const data: Blob = new Blob([buffer], {
        type: EXCEL_TYPE
      });
      FileSaver.saveAs(data, fileName + '_export_' + new Date().getTime() + EXCEL_EXTENSION);
    });
  }
  exportPdf() {
    import('jspdf').then(jsPDF => {
      import('jspdf-autotable').then(x => {
        /* const doc = new jsPDF.default(0,0);
        if(typeof this.selectedDepots === 'undefined'){
            doc.autoTable(this.exportColumns, this.depots);
           }else{
               doc.autoTable(this.exportColumns, this.selectedDepots);
        }
       doc.save('products.pdf'); */
      })
    })
  }
  getJoblsit() {
    this.crudService.getJobs(this.companyId).subscribe((data: any) => {
      this.jobList = data;
      console.log(' this.jobList', this.jobList)
      this.jobList = this.jobList.sort((b, a) => a.JobId - b.JobId);
      this.changeDataType()
    })
  }
  changeDataType(){
    this.jobList.forEach(val=>{
      var Jobnumber: number = +val.JobNo;
      // var Jobnumber = Number(val.JobNo);
      val.JobNo = Jobnumber
    }) 
  }

  async viewJobAction(job) {
    this.loading = true;
    this.disableSavebtn =true
    if (job.JobStatus === 'Cost Saved' || job.JobStatus === 'Approve Quote' || job.JobStatus === 'Await Cost' || job.JobStatus === 'Quote Rejected') {
      const smryData: any = await this.crudService.getJobSummary(job.JobId, job.AllocationId).toPromise();
      const jobAllocationResults = await this.JobService.getJobInfo(smryData[0]);
      const viewType = 'Quote';
      this.loading = false;
      const ref = this.dialogService.open(JobDetailsApprovalsComponent, {
        data: {
          jobId: job.JobId,
          allocId: job.AllocationId,
          jobSummary: jobAllocationResults,
          viewType: viewType,
          selectedJobAllocId: job.AllocationId,
          jobStatus:job.JobStatus
        },
        width: '100%',
        height: '100%'
      })
      ref.onClose.subscribe((response: any) => {
        this.disableSavebtn= false
        this.loadAllJobsList();
      });
    } else if (job.JobStatus === 'Approve Invoice' || job.JobStatus === 'Await Invoice' || job.JobStatus === 'Accounts Processed'
      || job.JobStatus === 'Invoice Processed' || job.JobStatus === 'Accounts Proecessed' || job.JobStatus === 'Job Completed'
      || job.JobStatus === 'Job Cancelled' || job.JobStatus === 'Allocation Cancelled') {
      const smryData: any = await this.crudService.getJobSummary(job.JobId, job.AllocationId).toPromise();
      const jobAllocationResults = await this.JobService.getJobInfo(smryData[0]);
      const viewType = 'Approval';
      this.loading = false;
      const ref = this.dialogService.open(JobDetailsApprovalsComponent, {
        data: {
          jobId: job.JobId,
          allocId: job.AllocationId,
          jobSummary: jobAllocationResults,
          viewType: viewType,
          selectedJobAllocId: job.AllocationId,
          jobStatus:job.JobStatus
        },
        width: '100%',
        height: '100%'
      })
      ref.onClose.subscribe((response: any) => {
        this.disableSavebtn= false
        this.loadAllJobsList();
      });

    } else if (job.JobStatus === 'Await Acceptance' || job.JobStatus === 'Send Offer') {
      this.loading = false;
      this.editSelectedRecord(job);
    } else if (job.JobStatus === 'Quote Rejected' || job.JobStatus === 'Send Offer') {
      this.loading = false;
      this.editSelectedRecord(job);
    }
  }
  viewJob(job) {
    this.router.navigate(['job/details', job.JobId, job.AllocationId])
  }




  async editSelectedRecord(job) {

    this.disableSavebtn= true
    const smryData: any = await this.crudService.getJobSummaryByJobId(job.JobId).toPromise();
    
    const jobAllocationResults = await this.JobService.getJobInfo(smryData[0]);
    
    const ref = this.dialogService.open(JobAdminComponent, {
      data: {
        jobId: job.JobId,
        jobSummary: jobAllocationResults,
        selectedJobAllocId: job.AllocationId,
        jobAllocationId:job.AllocationId,
        jobStatus:job.JobStatus
      },
      width: '100%',
      height: '100%'
    });
    ref.onClose.subscribe((response: any) => {
      this.disableSavebtn= false
      this.loadAllJobsList();
    });
  }

  deleteJob(job) {
    //console.log(job.JobId)
    this.confirmationService.confirm({
      message: 'Are you sure you want to delete job : ' + job.JobId + '?',
      header: 'Confirm',
      icon: 'pi pi-exclamation-triangle',
      accept: () => {
        this.jobList = this.jobList.filter((val: any) => val.id !== job.JobId);
        this.crudService.Delete(job.JobId, 'Jobinfo').subscribe(() => {
          this.messageService.add({ severity: 'success', summary: 'Successful', detail: 'Job Deleted', life: 1000 });
        });
        this.loadAllJobsList();
      }
    });
  }
  deleteSelectedjob() {
    this.confirmationService.confirm({
      message: 'Are you sure you want to delete the selected Job?',
      header: 'Confirm',
      icon: 'pi pi-exclamation-triangle',
      accept: () => {
        // tslint:disable-next-line: prefer-for-of
        for (let i = 0; i < this.selectedJob.length; i++) {
          this.crudService.Delete(this.selectedJob[i].JobId, 'Jobinfo').toPromise();
        }
        this.jobList = this.jobList.filter(val => !this.selectedJob.includes(val));
        this.selectedJob = null;
        this.messageService.add({ severity: 'success', summary: 'Successful', detail: 'Jobs Deleted', life: 1000 });
      }
    });
  }

  async fetchSearchRecords() {
    this.searchValue = this.searchValue ? this.searchValue.trim() : '';
    /* this.query = 'select top(600) ROW_NUMBER() OVER( ORDER BY jobid) AS RowNum,* from joboperationstempview where ( ( FullAddress like \'%' + this.searchValue + '%\') or ( TreeProfessional like \'%' + this.searchValue + '%\') or ( ClientSubType like \'%' + this.searchValue + '%\') or (job_claim_no like \'%' + this.searchValue + '%\' ) or (FullAddress like \'%' + this.searchValue + '%\' ) or ( JobNo like \'%' + this.searchValue + '%\') )';

    let data: any = await this.crudService.ExecuteQuery(this.query).toPromise();
    this.jobList = data; */
    await this.searchNow();
    this.jobList = this.jobList.sort((b, a) => a.JobId - b.JobId);
  }
  async fetchSearchRecords2() {
    this.loading = true;
    this.searchValue = this.searchValue ? this.searchValue.trim() : '';
    this.query = 'select ROW_NUMBER() OVER( ORDER BY jobid) AS RowNum,* from joboperationstempview where ( ( FullAddress like \'%' + this.searchValue + '%\') or ( TreeProfessional like \'%' + this.searchValue + '%\') or ( ClientSubType like \'%' + this.searchValue + '%\') or (job_claim_no like \'%' + this.searchValue + '%\' ) or (FullAddress like \'%' + this.searchValue + '%\' ) or ( JobNo like \'%' + this.searchValue + '%\') )';
    this.searchValue = this.searchValue ? this.searchValue : '';
    this.custCareExpList_324 = await this.crudService.getByValuesetName('CUSTCARE_EXP_324').toPromise();
    let index = this.custCareExpList_324.findIndex(val => (val.valueset_detail_name === this.userDetails.id));
    if (index > -1) {
      this.is324Exempt = true;
    }
    if (this.is324Exempt) {
      this.query = 'select ROW_NUMBER() OVER( ORDER BY jobid) AS RowNum,* from joboperationstempview where  CompanyId not in (324,1356) and ( ( FullAddress like \'%' + this.searchValue + '%\') or ( TreeProfessional like \'%' + this.searchValue + '%\') or ( ClientSubType like \'%' + this.searchValue + '%\') or (job_claim_no like \'%' + this.searchValue + '%\' ) or (FullAddress like \'%' + this.searchValue + '%\' ) or ( JobNo like \'%' + this.searchValue + '%\') )';;
    }

    const data: any = await this.crudService.ExecuteQuery(this.query).toPromise();
    this.jobList = data;
   
    //  this.searchNow();
    this.loading = false;
    this.jobList = this.jobList.sort((b, a) => a.JobId - b.JobId);
   // console.log('fetch list',data)
    this.changeDataType()
  }
  customSort(event: SortEvent) {
    event.data.sort((data1, data2) => {
      var value1 = data1[event.field];
      var value2 = data2[event.field];
     if(value1 == ""){
      value1 = null
     }
     if(value2 == ""){
      value2 = null
     }
      let result = null;
debugger
      if (value1 === null && value2 != null)
        result = -1;
      else if (value1 != null && value2 === null)
        result = 1;
      else if (value1 === null && value2 === null)
        result = 0;
      else if (typeof value1 === 'string' && typeof value2 === 'string') {
        let test = value1.substr(2,1)
        let date1 = moment(value1, 'DD-MM-YYYY')
        let date2 = moment(value2, 'DD-MM-YYYY')
        if(test == '-' || test == '/'){
          result = date1.diff(date2, 'days')
        }else{   
          if(event.field == 'FullAddress' || event.field =='InvoiceAmount'){
          result= this. sortFn(value1,value2)
          }
         else if(event.field == 'job_claim_no'){
            result= this. sortFn_2(value1,value2)
            }
        
        else{
             result = value1.localeCompare(value2);
            }

     
        }
      }
      else
        result = (value1 < value2) ? -1 : (value1 > value2) ? 1 : 0;

      return (event.order * result);
    });
  }
   sortFn(a, b) {    
    a = a.toLowerCase( ).replace(/[&\/\\#, +()$~%.'":*?<>{}]/g, "");
    b= b.toLowerCase( ).replace(/[&\/\\#, +()$~%.'":*?<>{}]/g, "");
    var isNumber = function (v) { return (+v).toString() === v; },
        aa = a.match(/\d+|\D+/g),
        bb = b.match(/\d+|\D+/g),
        i = 0,
        l = Math.min(aa.length, bb.length);

    while (i < l && aa[i] === bb[i]) {
        i++;
    }
    if (i === l) {
        return aa.length - bb.length;
    }
    if (isNumber(aa[i]) && isNumber(bb[i])) {
        return aa[i] - bb[i];
    }
    return aa[i].localeCompare(bb[i]);   
}
sortFn_2(a, b) {    
  debugger
// if item is a number
if (Number(a) && Number(b)) {
  return a - b;
}

// if item is a string
if (!Number(a) && !Number(b)) {
  return a.toLowerCase().replace(/[&\/\\#, -+()$~%.'":*?<>{}]/g, '').replace(/\s/g, "") > b.toLowerCase().replace(/[&\/\\#, " "-+()$~%.'":*?<>{}]/g, '').replace(/\s/g, "");
}

// numbers before strings
return Number(a) ? -1 : 1;

}


  stringToDate(_date, _format, _delimiter) {
    const formatCancellederCase = _format.toCancellederCase();
    const formatItems = formatCancellederCase.split(_delimiter);
    const dateItems = _date.split(_delimiter);
    const monthIndex = formatItems.indexOf('mm');
    const dayIndex = formatItems.indexOf('dd');
    const yearIndex = formatItems.indexOf('yyyy');
    let month = parseInt(dateItems[monthIndex]);
    month -= 1;
    const formatedDate = new Date(dateItems[yearIndex], month, dateItems[dayIndex]);
    return formatedDate;
  }

  async initialWhereCondition() {
    /*     let Active = " where  ( contractor_id  = " + this.companyId + " ) and JobStatus not in ('Invoice Processed','Accounts Processed','Offer Rejected,Re-issued','Quote Rejected','Job Completed','Job Cancelled', 'Allocation Cancelled','On Hold')";
        let Invoiced = " where ( contractor_id  =" + this.companyId + " )   and  JobStatus in ('Invoice Processed','Accounts Processed')";
        let Hold = " where ( contractor_id  =" + this.companyId + " )   and JobStatus in ('On Hold') ";
        let Completed = " where ( contractor_id  =" + this.companyId + " ) and JobStatus in ('Job Completed') ";
        let Cancelled = " where ( contractor_id  =" + this.companyId + " ) and JobStatus in ('Job Cancelled', 'Allocation Cancelled','Offer Rejected,Re-issued') ";
        let selectionQuery = " where ";
     */
    let Active = ' where  ( contractor_id  is null or contractor_id not in (276,970,830,1008) ) and JobStatus not in (\'Invoice Processed\',\'Accounts Processed\',\'Offer Rejected,Re-issued\',\'Quote Rejected\',\'Job Completed\',\'Job Cancelled\', \'Allocation Cancelled\',\'On Hold\')';
    let Invoiced = ' where ( contractor_id  is null or contractor_id not in (276,970,830,1008) ) and  JobStatus in (\'Invoice Processed\',\'Accounts Processed\')';
    let Hold = ' where ( contractor_id  is null or contractor_id not in (276,970,830,1008) ) and JobStatus in (\'On Hold\') ';
    let Completed = ' where ( contractor_id  is null or contractor_id not in (276,970,830,1008) ) and JobStatus in (\'Job Completed\') ';
    let Cancelled = ' where ( contractor_id  is null or contractor_id not in (276,970,830,1008) ) and JobStatus in (\'Job Cancelled\', \'Allocation Cancelled\',\'Offer Rejected,Re-issued\') ';
    let ALL = '  where  ( contractor_id  is null or contractor_id not in (276,970,830,1008) ) and JobStatus not in (\'Invoice Processed\',\'Accounts Processed\',\'Offer Rejected,Re-issued\',\'Quote Rejected\',\'Job Completed\',\'Job Cancelled\', \'Allocation Cancelled\',\'On Hold\')'
    let selectionQuery = ' where ';

    let initialWhereCondition = null;

    if (this.selectedOverAllStatus.id === 1) {
      initialWhereCondition = Active;
    } if (this.selectedOverAllStatus.id === 2) {
      initialWhereCondition = Invoiced;
    } if (this.selectedOverAllStatus.id === 3) {
      initialWhereCondition = Completed;
    } if (this.selectedOverAllStatus.id === 4) {
      initialWhereCondition = Cancelled;
    } if (this.selectedOverAllStatus.id === 5) {
      initialWhereCondition = Hold;
    } if (this.selectedOverAllStatus.id === 6) {
      initialWhereCondition = ALL;
    } if (this.selectedOverAllStatus.id === 7) {
      initialWhereCondition = selectionQuery;
    }

    if (this.filterBy == 'TP') {
      initialWhereCondition = initialWhereCondition + " and contractor_id  = '" + this.filterValue + "' ";
    }

    this.custCareExpList_324 = await this.crudService.getByValuesetName('CUSTCARE_EXP_324').toPromise();
    let index = this.custCareExpList_324.findIndex(val => (val.valueset_detail_name === this.userDetails.id));
    if (index > -1) {
      this.is324Exempt = true;
    }
    if (this.is324Exempt) {
      initialWhereCondition = initialWhereCondition + ' and CompanyId not in (324,1356)';
    }
    return initialWhereCondition;
  }

  constructClientQuery() {
    let ClientQuery = null;
    this.selectedClient.forEach(client => {
      if (ClientQuery === null) {
        ClientQuery = ' WPType in (\'' + client.name + '\'';
      } else {
        ClientQuery = ClientQuery + ',\'' + client.name + '\'';
      }
    });
    if (ClientQuery === null) {
      ClientQuery = 'ALL';
    } else {
      ClientQuery = ClientQuery + ')';
    }
    return ClientQuery;
  }

  constructJobStatusQuery() {
    let JobStatusQuery = null;
    this.selectedJobStatus.forEach(status => {
      if (JobStatusQuery === null) {
        JobStatusQuery = ' JobStatus in (\'' + status.id + '\'';
      } else {
        JobStatusQuery = JobStatusQuery + ',\'' + status.id + '\'';
      }
    })
    if (JobStatusQuery === null) {
      JobStatusQuery = 'ALL';
    } else {
      JobStatusQuery = JobStatusQuery + ')';
    }
    return JobStatusQuery;
  }

  async loadData() {
    this.jobList = [];
    let jobsList: any[] = [];
    this.loading = true;
    let finalQuery = null;
    const initialWhereConditionVal = await this.initialWhereCondition();
    const clientQuery = this.constructClientQuery();
    const statusQuery = this.constructJobStatusQuery();
    let whereAvail = false;
    finalQuery = initialWhereConditionVal;
    whereAvail = true;

    if (clientQuery === 'ALL') {
    } else {
      if (whereAvail) {
        finalQuery = finalQuery + ' and ' + clientQuery;
      } else {
        if (finalQuery === null) {
          finalQuery = ' where ' + clientQuery;
        } else {
          finalQuery = finalQuery + ' where ' + clientQuery;
        }

        whereAvail = true;
      }
    }
    if (statusQuery === 'ALL') {
    } else {
      if (whereAvail) {
        finalQuery = finalQuery + ' and ' + statusQuery;
      } else {
        if (finalQuery === null) {
          finalQuery = ' where ' + clientQuery;
        } else {
          finalQuery = finalQuery + ' where ' + statusQuery;
        }
        whereAvail = true;
      }
    }
    let appendSearchString = null;
    if ((this.searchValue != null) && (this.searchValue != '')) {
      appendSearchString = ' and ( ( FullAddress like \'%' + this.searchValue + '%\') or (job_claim_no like \'%' + this.searchValue + '%\' ) or  ( JobNo like \'%' + this.searchValue + '%\') )';
      if (finalQuery === null) {
        finalQuery = ' where ' + appendSearchString;
      } else {
        finalQuery = finalQuery + appendSearchString;
      }
    }
    const respobj = { status: null, client: null, query: null };
    respobj.query = finalQuery;

    if (finalQuery === null) {
      const data: any = await this.crudService.ListingPageOperationsActive2(respobj).toPromise();
      jobsList = data;
    } else {
      const data: any = await this.crudService.ListingPageOperationsActive2(respobj).toPromise();
      jobsList = data;
    }
    jobsList.forEach(jobdata => {
      let checkList = this.checkListData.filter(val => val.job_alloc_id === jobdata.AllocationId);
      if (checkList.length > 0) {
        let activecheckList = checkList.filter(val => val.status === '1');
        let inActivecheckList = checkList.filter(val => val.status === '2');
        jobdata.checkList_count = checkList.length;
        jobdata.activecheckList_count = activecheckList.length;
        jobdata.inActivecheckList_count = inActivecheckList.length;
        let taskPercent = (inActivecheckList.length * 100) / checkList.length;
        jobdata.taskPercent = this.numberRoundDecimal(taskPercent, 0);
      }
      else {
        jobdata.checkList_count = 0;
        jobdata.activecheckList_count = 0;
        jobdata.inActivecheckList_count = 0;
        jobdata.taskPercent = 0;
      }
    });
    jobsList = await this.loadTaskInfo(jobsList);

    this.loading = false;
  }

  numberRoundDecimal(v, n) {
    if (v == "NaN") {
      return "0";
    }
    else {
      return Math.round((v + Number.EPSILON) * Math.pow(10, n)) / Math.pow(10, n)
    }

  }

  async searchNow() {
    this.jobList = [];
    if (this.filterBy == 'Dashboard') {
      this.jobList = this.jobsData;
      this.jobList = this.jobList.sort((b, a) => a.JobId - b.JobId);
     // console.log('this.jobList', this.jobList)
      this.originalJobList = this.jobList;
      if (this.catg == 'Red') {
        this.viewTaskInfo = true;
      }
    } else {
      await this.loadData();
    }
    await this.toggleJobsView();
  }

  updateJobFlag(job, colour) {
    job.flag_details = colour.code;
    this.crudService.updateFlag(job.AllocationId, colour.code).toPromise();
  }

  filterByFlag(colour) {
    this.selectedColour = colour;
    if (this.selectedColour.code !== null && this.selectedColour.code !== '') {
      this.jobList = this.originalJobList.filter((val: any) => val.flag_details === colour.code);
    } else {
      this.jobList = this.originalJobList;
    }
    this.jobList = this.jobList.sort((b, a) => a.JobId - b.JobId);
  }


  async flagUnflagJob(job) {
    const data: any = await this.crudService.getDataByField('JobAllocation', 'id', job.AllocationId).toPromise();
    let FlagUnflag = 'FLAG';
    if (job.isFlagged) {
      FlagUnflag = 'UNFLAG';
      job.isFlagged = true;
    } else {
      job.isFlagged = false;
      FlagUnflag = 'FLAG';
    }
    const ref = this.dialogService.open(JobDetailsApprovalsFlaggingComponent, {
      data: {
        jobId: job.JobId,
        allocId: job.AllocationId,
        FlagUnflag: FlagUnflag,
        job_status: job.JobStatus,
        step: 'operations',
        wf_status_id: job.wf_status_id,
        flagType: '1'
      },
      width: '100%',
      height: '100%',
      styleClass: ''
    })
    ref.onClose.subscribe(async (response: any) => {
      if (response.success == '1') {
        this.messageService.add({ severity: 'success', summary: 'Successful', detail: 'Job Allocation Flagged', life: 1000 });
        await this.loadData();
      } else if (response.success == '2') {
        this.messageService.add({ severity: 'success', summary: 'Successful', detail: 'Job Allocation Un Flagged', life: 1000 });
        await this.loadData();
      }
    });
  }

  async toggleJobsView() {
    if (this.viewTaskInfo) {
      this.jobList = this.jobList.filter(val => (val.task_count > 0));
      this.jobList = this.jobList.sort((a, b) => a.ageing - b.ageing);
    } else {
      this.jobList = this.originalJobList;
      if(this.reqId){
        this.jobList = this.jobList.filter(val => (val.CompanyId === this.reqId));
      }
      this.jobList = this.jobList.sort((b, a) => a.JobId - b.JobId);
      this.jobList.forEach(async (w,index) => {
        this.workFlowDataId = 0;
        if(w.AllocationId)
        {
          this.workFlowDataId = this.workFlowData.filter(val => (val.job_alloc_id == w.AllocationId));
          this.workFlowDataId = this.workFlowDataId[0]?.id;
        }
        else
        {
          this.workFlowDataId = this.workFlowData.filter(val => (val.job_id == w.JobId && val.job_alloc_id == 1));
          this.workFlowDataId = this.workFlowDataId[0]?.id;
        }

        Object.assign(this.jobList[index], { workFlowStatus: this.workFlowDataId });
      });

    }
    if(this.JobStatusLive)
    {
      this.jobList = this.jobList.filter(val => val.JobStatus === this.JobStatusLive);
    }
  }

  async loadTaskInfo(jobsList): Promise<any> {
    let list: any = await this.JobService.fetchJobTaskInfo(jobsList, this.userDetails);
    if (this.filterBy == 'Dashboard') {
      await this.loadJobsTiles(jobsList);
      if (this.bucket != null && this.bucket !== '') {
        let category: any = this.categories.find(val => (val.code == this.bucket));
        jobsList = category.jobs;
        if (this.catg != null && this.catg != '') {
          if (this.catg == 'Red') {
            jobsList = jobsList.filter(val => (val.task_count > 0));
          } else if (this.catg == 'Amber') {
            jobsList = jobsList.filter(val => (val.task_count < 1 && new Date() > val.dDay && val.daysToDday != null));
          } else {
            jobsList = jobsList.filter(val => (val.task_count < 1 && (new Date() < val.dDay || val.daysToDday == null)));
          }
        }
        this.jobList = jobsList.sort((b, a) => a.JobId - b.JobId);
        this.changeDataType();
        this.originalJobList = this.jobList;
      }
    }
    this.jobList = list.sort((b, a) => a.JobId - b.JobId);
   // console.log('job listttt',this.jobList)
    this.changeDataType();
    this.originalJobList = this.jobList;
    this.subscription = interval(1000).subscribe(async x => { await this.JobService.getTimeDifference(this.jobList); });
    await this.toggleJobsView();
  }

  viewTasks(job) {
    const ref = this.dialogService.open(TaskListComponent, {
      data: {
        wf_code: '7',
        view_mode: 'JOB',
        allocation_id: job.AllocationId,
      },
      width: '100%',
      height: '100%'
    })
  }

  allocateUsers() {
    this.selectedTasksList = [];
    this.selectedUser = [];
    this.next_followup_date = new Date();
    this.selectedTime = null;
    this.event_remarks = '';
    for (let jobAlloc of this.selectedJob) {
      if (jobAlloc.tasksList !== null && jobAlloc.tasksList != '') {
        for (let task of jobAlloc?.tasksList) {
          this.selectedTasksList.push(task.id);
        }
      }
    }
    if (this.selectedTasksList.length > 0) {
      this.displayAllocationDialog = true;
    } else {
      this.messageService.add({ severity: 'error', summary: 'error', detail: 'Selected Job(s) have not been actively Flagged. Allocation can be done only on Flagged Jobs', life: 2000 });
    }
    this.filteredTimeSlots = this.timeSlots;
  }

  ngOnDestroy() {
    this.subscription?.unsubscribe();
  }

  async saveEventInfo() {
    this.loading = true;
    let data = await this.crudService.getDataByField('Company', 'id', this.companyId).toPromise();
    let companyInfo = data[0];
    let time: any;
    if (this.selectedTime.includes('am')) {
      time = this.selectedTime.replace('am', ' AM')
    } else if (this.selectedTime.includes('pm')) {
      time = this.selectedTime.replace('pm', ' PM')
    }

    let response = await this.crudService.allocateTaskUsers(this.selectedTasksList.toString(),
      this.selectedUser.user_id.toString(),
      this.next_followup_date.toISOString().split('T')[0] + ' ' + time,
      this.event_remarks, this.userDetails.id,
      environment.base_url + 'authredirect/TaskJobs/' + companyInfo.token).toPromise();
    this.loading = false;
    this.messageService.add({ severity: 'success', summary: 'Successful', detail: 'Selected Job(s) have been allocated to the Staff', life: 1000 });
    this.displayAllocationDialog = false;
    await this.loadData();
    this.loading = false;
  }

  async loadJobsTiles(listOfJobs) {
    this.originalJobList = listOfJobs;
    this.categories.forEach(category => {
      let jobsList = this.originalJobList.filter(val => (category.status.includes(val.JobStatus)));
      let redList = jobsList.filter(val => (val.task_count > 0));
      let amberList = jobsList.filter(val => (val.task_count < 1 && new Date() > val.dDay && val.daysToDday != null));
      let greenList = jobsList.filter(val => (val.task_count < 1 && (new Date() < val.dDay || val.daysToDday == null)));

      let total_cost = jobsList.reduce(function (_this, val) {
        return _this + val.TPCost
      }, 0);

      category.total = total_cost;
      category.count = jobsList.length;
      category.red = redList.length;
      category.amber = amberList.length;
      category.green = greenList.length;
      category.jobs = jobsList;
    });
    this.loading = false;
  }



  switchView() {
    if (this.viewDueIn == 1) {
      this.viewDueIn = 0;
    } else {
      this.viewDueIn = 1;
    }
    /* if(this.viewDueIn == 4){
      this.viewDueIn = 0;
    }else{
      this.viewDueIn = this.viewDueIn+1;
    } */
  }
  search(event) {
    this.filteredTimeSlots = this.timeSlots.filter((val: any) => val.includes(event.query));
  }

  /* selectJob(job){
    let availindex = this.selectedJob.findIndex(val => (val.AllocationId == job.AllocationId));
    if(availindex == -1){
      this.selectedJob.push(job);
    }else{
      this.selectedJob.splice(availindex, 1);
    }
    //  console.log("Selected Job",this.selectedJob);
  } */


  async flagSelectedjob() {
    const refMulti = this.dialogService.open(JobDetailsApprovalsFlaggingComponent, {
      data: {
        selectedJob: this.selectedJob,
        flagType: '2'
      },
      width: '100%',
      height: '100%',
      styleClass: ''
    });
    refMulti.onClose.subscribe(async (response: any) => {
      if (response.success == '1') {
        this.messageService.add({ severity: 'success', summary: 'Successful', detail: 'Job Allocation Flagged', life: 1000 });
      } else if (response.success == '2') {
        this.messageService.add({ severity: 'success', summary: 'Successful', detail: 'Unfagged Successfully', life: 1000 });
      }
      await this.loadData();
    });
  }

  filterReasons() {
    let filteredList: any[] = [];
    if (this.selectedReasons.length > 0) {
      this.selectedReasons.forEach(reason => {
        let list = this.originalJobList.filter(val => (val.reasons == reason.valueset_detail_name));
        filteredList = [...filteredList, ...list];
      });
      this.jobList = filteredList;
    }
    else {
      this.jobList = this.originalJobList;
    }
  }

  filterJobOwner()
  {
    let filteredList: any[] = [];
    if (this.selectedJobOwner.length > 0) {
      this.selectedJobOwner.forEach(listData => {
        let list = this.originalJobList.filter(val => (val.JobOwnerName == listData.first_name));
        filteredList = [...filteredList, ...list];
      });
      this.jobList = filteredList;
    }
    else {
      this.jobList = this.originalJobList;
    }
  }

  filterPercent() {
    let filteredList: any[] = [];
    if (this.selectedPercent.length > 0) {
      this.selectedPercent.forEach(percent => {
        let list = this.originalJobList.filter(val => (val.taskPercent >= percent.min && val.taskPercent <= percent.max));
        filteredList = [...filteredList, ...list];
      });
      this.jobList = filteredList;
    }
    else {
      this.jobList = this.originalJobList;
    }
  }

  async watchedItemsUpdate(job) {
    let inputData: any;
    let response: any;
    if (job.watchInfo?.id != null) {
      if (job.watched) {
        job.watchInfo.status = '2';
      } else {
        job.watchInfo.status = '1';
      }
      job.watched = !job.watched;
      response = await this.commonservice.saveRecord('WatchedItems', job.watchInfo);
    } else {
      if (job.AllocationId != null) {
        inputData = {
          status: '1',
          user_id: this.userDetails.id,
          ref_type: 'Allocation',
          ref_id: job.AllocationId
        }
      } else {
        inputData = {
          status: '1',
          user_id: this.userDetails.id,
          ref_type: 'Job',
          ref_id: job.JobId
        }
      }
      response = await this.commonservice.saveRecord('WatchedItems', inputData);
      job.watched = true;
      inputData.id = response.id;
      job.watchInfo = inputData;
    }
    if (response.success == '1') {
      this.messageService.add({ severity: 'success', summary: 'Successful', detail: 'Added To WatchList', life: 1000 });
    } else {
      this.messageService.add({ severity: 'error', summary: 'Successful', detail: 'Failed', life: 1000 });
    }

  }

  async viewChecklist(job) {
    this.selectJobId = job.JobId;
    if (job.AllocationId == null) {
      this.selectedJobAllocationId = '1';
    }
    else {
      this.selectedJobAllocationId = job.AllocationId;
    }
    this.jobCheckListDialog = true;
  }

  viewTimeline(job) {
    this.selectJobId = job.JobId;
    this.selectedJobAllocationId = job.AllocationId;
    this.timeLineDialog = true;
    // const ref = this.dialogService.open(WorkflowTimelineComponent, {
    //   data: {
    //     jobId: job.JobId,
    //     allocationId: job.AllocationId
    //   },
    //   header : "Job Workflow Timeline",
    //   width: '100%',
    //   height: '100%'
    // });
  }
}