import { Component, OnInit, Input } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { CRUDService } from 'src/app/utilities/crud.service';
import { ConfirmationService } from 'primeng/api';
import { MessageService } from 'primeng/api';
import { Table } from 'primeng/table';
import { CommonService } from 'src/app/utilities/common.service';
import { environment } from 'src/environments/environment';
import { DialogService, DynamicDialogConfig } from 'primeng/dynamicdialog';
@Component({
  selector: 'app-appconfiguration',
  templateUrl: './appconfiguration.component.html',
  styleUrls: ['./appconfiguration.component.css']
})
export class AppconfigurationComponent implements OnInit {

  loading: boolean = false;
  paginationvalue: any;
  cols: any[];
  exportColumns: any[];
  appConfigDialog: boolean = false;
  submitted: boolean;
  appconfigList: any[];
  jobPriority: any;
  appConfig: any;
  selectedJobPriority: any[];
  selectedAppConfig: any[];
  companyList: any[];
  isActive: boolean;
  selectedConfig: any;
  speedDial: boolean = false;
  isEditAll: boolean = false;
  exportdisplay: Boolean = false;
  detailData: any;
  public inputFields: any[] = [];
  public inputFieldsarray: any[] = [];
  public inputFieldss: any[] = [];
  @Input() moduleName: any;
  appconfigid: string;
  fieldId: any;
  edit: boolean;
  submitFlag: Boolean = false;
  inputtt: boolean = true;
  createmode: any;

  constructor(private crudService: CRUDService,
    private messageService: MessageService,
    private confirmationService: ConfirmationService,
    private commonservice: CommonService,
    private dialogService: DialogService,
    public config: DynamicDialogConfig,) {
    this.form = new FormGroup({
      fields: new FormControl(JSON.stringify(this.inputFields))
    })
    this.unsubcribe = this.form.valueChanges.subscribe((update) => {
      console.log(update);
      this.inputFields = JSON.parse(update.fields);
    });
  }

  async ngOnInit(): Promise<void> {
    this.loading = true;

    if (this.config && this.config.data) {
      this.moduleName = this.config.data.code;
      this.createmode = this.config.data.create_mode;
    }
    else
    {
      this.moduleName="CompanyNotifcationPreference";
      this.createmode="1"
    }

    this.paginationvalue = environment.paginatorValue;
    this.crudService.getAllData(this.moduleName).subscribe((data: any[]) => {
      this.appconfigList = data;
    });

    this.crudService.getDataByField('ComponentDefnDetail', 'component_code', 'CompanyNotifcationPreference').subscribe((data: any[]) => {
      this.detailData = data;
      this.detailData.forEach(w => {
        this.inputFields.push({
          type: w.field_type.trim(),
          name: w.field_name,
          label: w.helptext,
          value: '',
          required: true,

        })
      });
      let fieldsCtrls = {};
      for (let f of this.inputFields) {
        if (f.type != 'checkbox') {
          fieldsCtrls[f.name] = new FormControl(f.value || '', Validators.required)
        } else {
          let opts = {};
          for (let opt of f.options) {
            opts[opt.key] = new FormControl(opt.value);
          }
          fieldsCtrls[f.name] = new FormGroup(opts)
        }
      }
      this.form = new FormGroup(fieldsCtrls);

    });

    this.loading = false;
  }

  openNew() {
    this.edit = false
    this.detailData.forEach(w => {
      this.inputFieldss.push({
        type: w.field_type.trim(),
        name: w.field_name,
        label: w.helptext,
        value: '',
        required: true,
      })
    });
    this.inputFields = this.inputFieldss
    this.appConfigDialog = true;
  }
  async onSubmit(evt) {
    console.log("saveData",evt);
    if (this.edit) {
      evt.id = this.fieldId;
      let response: any = await this.commonservice.saveRecord(this.moduleName, evt);
      if (response.success == true) {
        this.messageService.add({ severity: 'success', summary: 'Successful', detail: ' Updated successfully', life: 1000 });
        this.appconfigList[this.findIndexById(evt.id)] = evt;
        this.appConfigDialog = false;
      }
      else {
        this.messageService.add({ severity: 'info', summary: 'Error', detail: 'Something went wrong', life: 1000 });
      }
    }
    else {
      let response: any = await this.commonservice.saveRecord(this.moduleName, evt);
      if (response.success == true) {
        this.messageService.add({ severity: 'success', summary: 'Successful', detail: ' Created successfully', life: 1000 });
        this.appconfigList.unshift(evt);
        this.appConfigDialog = false;
      }
      else {
        this.messageService.add({ severity: 'info', summary: 'Error', detail: 'Something went wrong', life: 1000 });
      }
    }
  }

  async saveData(evt) {
    console.log("saveData",evt);    
    if (this.edit) {
      evt.id = this.fieldId;
      let response: any = await this.commonservice.saveRecord(this.moduleName, evt);
      if (response.success == true) {
        this.messageService.add({ severity: 'success', summary: 'Successful', detail: ' Updated successfully', life: 1000 });
        this.appconfigList[this.findIndexById(evt.id)] = evt;
        this.appConfigDialog = false;
      }
      else {
        this.messageService.add({ severity: 'info', summary: 'Error', detail: 'Something went wrong', life: 1000 });
      }
    }
    else {
      let response: any = await this.commonservice.saveRecord(this.moduleName, evt);
      if (response.success == true) {
        this.messageService.add({ severity: 'success', summary: 'Successful', detail: ' Created successfully', life: 1000 });
        this.appconfigList.unshift(evt);
        this.appConfigDialog = false;
      }
      else {
        this.messageService.add({ severity: 'info', summary: 'Error', detail: 'Something went wrong', life: 1000 });
      }
    }

  }

  editRecord(appConfig: any) {
    // appconfigList.isEdit=true;
    console.log("editRecordappConfig",appConfig);  
    this.edit = true
    this.fieldId = appConfig.id
    const data_array = Object.entries(appConfig);
    this.inputFields.forEach(w => {
      data_array.forEach(D => {
        if (w.name == D[0]) w.value = D[1];
      });
    }); 

    this.inputFields = [...this.inputFields];
    console.log("this.inputFields",this.inputFields);
    //  this.appConfigDialog = true;
    //  this.isEditAll=true;
    // this. getFields();
  }
  
  saveRecord1() {
    // let changedRecords = this.appconfigList.filter(val => (val.changed === true));
    // console.log("saveRecord1", changedRecords);
    // changedRecords.forEach(element => {
    //   console.log("element",element);

    //   this.commonservice.saveRecord(this.moduleName, element);
    //   element.changed = false;
    // });
    // this.isEditAll = false;
  }

  deleteSelectedJob() {
    // this.confirmationService.confirm({
    //   message: 'Are you sure you want to delete the selected Appconfig?',
    //   header: 'Confirm',
    //   icon: 'pi pi-exclamation-triangle',
    //   accept: () => {  
    //     for (let i = 0; i < this.selectedAppConfig.length; i++) {
    //       this.crudService.Delete(this.selectedAppConfig[i].id, 'AppConfig').subscribe((data: any[]) => { });
    //     }
    //     this.appconfigList = this.appconfigList.filter(val => !this.selectedAppConfig.includes(val));
    //     this.selectedAppConfig = null;
    //     this.messageService.add({ severity: 'success', summary: 'Successful', detail: 'Appconfig Deleted', life: 1000 });
    //   }
    // });
  }

  deleteJobPriority(appConfig: any) {
    // console.log("deleteJobPriority appConfig", appConfig);
    // this.confirmationService.confirm({
    //   message: 'Are you sure you want to delete Appconfig : ' + appConfig.code + '?',
    //   header: 'Confirm',
    //   icon: 'pi pi-exclamation-triangle',
    //   accept: () => {
    //     this.appconfigList = this.appconfigList.filter(val => val.id !== appConfig.id);
    //     this.appConfig = {};
    //     this.crudService.Delete(appConfig.id, 'AppConfig').subscribe((data: any[]) => {
    //       console.log("deleteresponse,", data);
    //       this.messageService.add({ severity: 'success', summary: 'Successful', detail: 'Appconfig Deleted', life: 1000 });
    //     });
    //   }
    // });
  }

  findIndexById(id: string): number {
    let index = -1;
    for (let i = 0; i < this.appconfigList.length; i++) {
      if (this.appconfigList[i].id === id) {
        index = i;
        break;
      }
    }
    return index;
  }

  hideDialog() {
    this.appConfigDialog = false;
    this.submitted = false;
  }

  exportPdf() {
    import('jspdf').then(jsPDF => {
      import('jspdf-autotable').then(x => {
        /* const doc = new jsPDF.default(0,0);
        doc.autoTable(this.exportColumns, this.selectedDepots);
        doc.save('products.pdf'); */
      })
    })
  }

  exportExcel(selectedJob) {
    import('xlsx').then(xlsx => {
      const worksheet = xlsx.utils.json_to_sheet(this.selectedJobPriority);
      const workbook = { Sheets: { data: worksheet }, SheetNames: ['data'] };
      const excelBuffer: any = xlsx.write(workbook, { bookType: 'xlsx', type: 'array' });
      this.saveAsExcelFile(excelBuffer, 'JobPriority');
    });
  }

  saveAsExcelFile(buffer: any, fileName: string): void {
    import('file-saver').then(FileSaver => {
      const EXCEL_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
      const EXCEL_EXTENSION = '.xlsx';
      const data: Blob = new Blob([buffer], {
        type: EXCEL_TYPE
      });
      FileSaver.saveAs(data, fileName + '_export_' + new Date().getTime() + EXCEL_EXTENSION);
    });
  }

  clear(table: Table) {
    table.clear();
  }

  public form: FormGroup;
  unsubcribe: any

  onUpload(e) {
    console.log(e);
  }

  getFields() { 
    return this.inputFields;
  }

  ngDistroy() {
    this.unsubcribe();
  }
}
function copy(obj: any): any {
  throw new Error('Function not implemented.');
}

