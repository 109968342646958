import { Component, OnInit } from '@angular/core';
import { FormGroup, Validators, FormControl } from '@angular/forms';
import { CRUDService } from 'src/app/utilities/crud.service';
import { Router } from '@angular/router';
import { debug } from 'console';


@Component({
  selector: 'app-request-reset',
  templateUrl: './request-reset.component.html',
  styleUrls: ['./request-reset.component.scss']
})

export class RequestResetComponent implements OnInit {
  RequestResetForm: FormGroup;
  forbiddenEmails: any;
  errorMessage: string;
  successMessage: string;
  IsvalidForm = true;
  emailId : any;

  constructor(
    private crudService: CRUDService,
    private router: Router,
  ) {

  }


  ngOnInit() {

    this.RequestResetForm = new FormGroup({
      'email': new FormControl(null, [Validators.required, Validators.email], this.forbiddenEmails),
    });
  }


  RequestResetUser(form) {
    this.successMessage =null
    debugger
    if (form.valid) {
      this.IsvalidForm = true;
      this.emailId = this.RequestResetForm.value.email
      this.crudService.getDataByField('Users', 'email', this.emailId).subscribe((data: any[]) => {
        console.log("email",data);
        if (data.length > 0) {
          let token = this.randomString();
          this.crudService.requestReset(this.RequestResetForm.value, token).subscribe((data: any) => {
            this.RequestResetForm.reset();
            this.sendMail(this.emailId, token);
            // this.errorMessage =null
            // this.successMessage = "Reset password link sent to the email sucessfully.";
            this.successMessage = "If there is a registered email account, you will receive an email with reset instructions.";
            setTimeout(() => {
              this.successMessage = null;
              this.router.navigate(['sign-in']);
            }, 3000);
          },
            err => {
              if (err.error.message) {
                this.successMessage = "If there is a registered email account, you will receive an email with reset instructions.";
                // this.errorMessage = err.error.message;
              }
            }
          );
          
        } else {
          this.successMessage = "If there is a registered email account, you will receive an email with reset instructions.";
          // this.successMessage =null
          // this.errorMessage = 'Mail ID not registered. Please enter a valid registered EmailID';
          // this.IsvalidForm = false;
        }
      });
    } else {
      this.IsvalidForm = false;
    }
  }

  sendMail(email: any, token: string) {
    console.log("email",email);
    debugger
    const datobj = {
      token : token,
      email_id : email
      }
    this.crudService.forgetPassword(datobj).subscribe((res2: any[]) => {
       console.log("sendMail",res2);
  });

    // let params = {
    //   email: {
    //     to: email,//'venkat@agileitexperts.com', //'operations@envirofrontier.com.au',
    //     cc: '', //'admin@envirofrontier.com.au',
    //     bcc: 'shri@agileitexperts.com', token, msg_type:'resetPassword',
    //     msg: 'You are receiving this because you (or someone else) have requested the reset of the password for your account.\n\n' +
    //       'Please click on the following link, or paste this into your browser to complete the process:\n\n'  ,
    //     subject: 'Password Reset',
    //     sms: null, type: 'email', selectedTpl: null, sufix: null
    //   }
    // }
    // this.crudService.sendMessage(params).subscribe((res2: any[]) => {
    //   //console.log(res2);
    // });
  }

  randomString() {
    //define a variable consisting alphabets in small and capital letter  
    const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXTZabcdefghiklmnopqrstuvwxyz01923456789';
    const lenString = 15;
    let randomstring = '';
    //loop to select a new character in each iteration  
    for (let i = 0; i < lenString; i++) {
      const rnum = Math.floor(Math.random() * characters.length);
      randomstring += characters.substring(rnum, rnum + 1);
    }
    return randomstring;
  }
}