import {CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { ChannelRoutingModule } from './channel-routing.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';


import { ToggleButtonModule } from 'primeng/togglebutton';
import { TableModule } from 'primeng/table';
import { ButtonModule } from 'primeng/button';
import { InputMaskModule } from 'primeng/inputmask';
import { InputNumberModule } from 'primeng/inputnumber';
import { InputSwitchModule } from 'primeng/inputswitch';
import { InputTextModule } from 'primeng/inputtext';
import { InputTextareaModule } from 'primeng/inputtextarea';
import { ConfirmDialogModule } from 'primeng/confirmdialog';
import { DialogModule } from 'primeng/dialog';
import { DynamicDialogRef } from 'primeng/dynamicdialog';
import { DynamicDialogConfig } from 'primeng/dynamicdialog';
import { DropdownModule } from 'primeng/dropdown';
import { CheckboxModule } from 'primeng/checkbox';
import { ToastModule } from 'primeng/toast';
import { GooglePlaceModule } from "ngx-google-places-autocomplete";
import { TooltipModule } from 'primeng/tooltip';
import { AccordionModule } from 'primeng/accordion';
import { GMapModule } from 'primeng/gmap';
import { StrengthMeterModule } from "ngx-strength-meter";
import { TabMenuModule } from 'primeng/tabmenu';
import { TabViewModule } from 'primeng/tabview';
import { SharedModule } from '../shared/shared.module';
import { JobModule } from '../job/job.module'
import { FileUploadModule } from 'ng2-file-upload';
import {SlideMenuModule} from 'primeng/slidemenu';
import { RadioButtonModule } from 'primeng/radiobutton';
import { SelectButtonModule } from 'primeng/selectbutton';
import { CalendarModule } from 'primeng/calendar';
import { FullCalendarModule } from 'primeng/fullcalendar';
import { AutoCompleteModule } from 'primeng/autocomplete';
import { RippleModule } from 'primeng/ripple';
import { MenuModule } from 'primeng/menu';
import {NgTinyUrlModule} from 'ng-tiny-url';
import { ClientchannelComponent } from './clientchannel/clientchannel.component';
import { ContractorchannelComponent } from './contractorchannel/contractorchannel.component';


@NgModule({
  declarations: [
   ClientchannelComponent,ContractorchannelComponent],
  imports: [
    CommonModule,NgTinyUrlModule,
    ChannelRoutingModule,SlideMenuModule,CalendarModule,FullCalendarModule,AutoCompleteModule,RippleModule,MenuModule,
    CommonModule, FormsModule, ReactiveFormsModule, ConfirmDialogModule, DialogModule,
        TableModule, InputMaskModule, InputNumberModule, InputSwitchModule, InputTextModule, InputTextareaModule,
        ButtonModule, DropdownModule, CheckboxModule, ToastModule, GooglePlaceModule, TooltipModule,
        AccordionModule, GMapModule, StrengthMeterModule, TabMenuModule, TabViewModule, SharedModule, JobModule, FileUploadModule,
        ToggleButtonModule, StrengthMeterModule, TabMenuModule, TabViewModule, SharedModule, JobModule, FileUploadModule,
      ToggleButtonModule, RadioButtonModule, SelectButtonModule
  ],
  exports: [
    ClientchannelComponent,ContractorchannelComponent
    // ChatbotComponent
],
providers: [
    DynamicDialogRef, DynamicDialogConfig
],
schemas: [
    CUSTOM_ELEMENTS_SCHEMA
]
})
export class ChannelModule { }
