import { Component, OnInit,Output,EventEmitter, Input, OnChanges } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ConfirmationService, MessageService } from 'primeng/api';
import { Validators, FormControl, FormGroup, FormBuilder } from '@angular/forms';
import { DynamicDialogConfig, DynamicDialogRef, DialogService } from 'primeng/dynamicdialog';
import { CRUDService } from 'src/app/utilities/crud.service';
import { Contacts } from './tp-job-riskcontact';
import { ElementRef, ViewChild } from '@angular/core';
import { element } from 'protractor';


@Component({
  selector: 'app-tp-job-riskcontact',
  templateUrl: './tp-job-riskcontact.component.html',
  styleUrls: ['./tp-job-riskcontact.component.scss'],
  providers: [DialogService, MessageService, ConfirmationService]
})
export class TpJobRiskcontactComponent implements OnInit {

  @ViewChild('addressInput') private addressInput: ElementRef;
  @Input() id: any;
  @Input() jobInfo: any;
  @Input() newlayout: boolean = true;
  @Output() myOutput:EventEmitter<any>= new EventEmitter();

  hideContactCard: any = false;
  contacts: any[] = [];
  selectedContact: any[];
  submitted: any;
  isActive: boolean;
  contact: any;
  selectedCountry: any;
  selectedState: any;
  countryList: any[];
  stateList: any[];
  selectedPosition: any;
  positionList: any[];
  contactForm: FormGroup;
  isMainContact: Boolean;
  isRiskAddress: Boolean;
  options = {
    componentRestrictions: {
      country: ['AU']
    }
  }
  componentForm = {
    street_number: 'short_name',
    route: 'long_name',
    locality: 'long_name',
    sublocality_level_1: 'long_name',
    administrative_area_level_1: 'short_name',
    country: 'long_name',
    postal_code: 'short_name'
  };

  constructor(private CrudService: CRUDService,
    private messageService: MessageService,
    private confirmationService: ConfirmationService,
    public ref: DynamicDialogRef,
    public config: DynamicDialogConfig) {

    this.componentForm = {
      street_number: 'short_name',
      route: 'long_name',
      locality: 'long_name',
      sublocality_level_1: 'long_name',
      administrative_area_level_1: 'short_name',
      country: 'long_name',
      postal_code: 'short_name'
    };
  }

  newContact() {
    this.contactForm.markAsUntouched();
    this.contactForm.markAsPristine();
    this.isActive = true;
    this.contact = {
      addl_address: null,
      contact_alt: null,
      contact_best: this.jobInfo.phone,
      contact_email: null,
      contact_type: null,
      country_id: null,
      first_name: this.jobInfo.job_firstname,
      id: null,
      job_id: this.id,
      job_no: null,
      last_name: this.jobInfo.job_lastname,
      postcode: this.jobInfo.risk_postcode,
      state_id: null,
      street_address: this.jobInfo.risk_street_address,
      suburb: this.jobInfo.risk_suburb,
    }
    this.isMainContact = true;
    if (this.jobInfo.risk_country_id !== '' && this.jobInfo.risk_country_id != null) {
      this.CrudService.getDataByField('Country', 'id', this.jobInfo.risk_country_id).subscribe((data: any[]) => {
        this.selectedCountry = data[0];
      });
    }
    if (this.jobInfo.risk_state_id !== '' && this.jobInfo.risk_state_id != null) {
      this.CrudService.getDataByField('State', 'id', this.jobInfo.risk_state_id).subscribe((data: any[]) => {
        this.selectedState = data[0];
      });
    }
    this.hideContactCard = true;
  }

  ngOnChanges() {
    this.loadRiskContacts();
  }

  loadRiskContacts() {
    this.CrudService.getDataByField('jobriskcontacts', 'job_id', this.id).subscribe((data: any[]) => {
      this.contacts = data;
      this.myOutput.emit(this.contacts);
    })
  }

  ngOnInit() {
    this.CrudService.getAll('Country').subscribe((countryData: any[]) => {
      this.countryList = countryData;

      this.CrudService.getAll('State').subscribe((stateData: any[]) => {
        this.stateList = stateData;

        this.selectedCountry = {};
        this.selectedState = {};
       // this.loadRiskContacts();

      });
    });
    this.contactForm = new FormGroup({
      first_name: new FormControl('', Validators.required),
      last_name: new FormControl(''),
      contact_best: new FormControl(''),
      email: new FormControl('', [Validators.pattern("[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$")]),
      street_address: new FormControl(''),
      suburb: new FormControl(''),
      postcode: new FormControl(''),
      state: new FormControl(''),
      country: new FormControl('') 
    })
  }

  saveContact() {
    this.submitted = true;
    if(this.contact.first_name == '' || this.contact.last_name == '' || this.contact.contact_email == '' || this.contact.street_address == '' || this.contact.suburb == '' || this.contact.postcode == '') 
    {
      this.messageService.add({ severity: 'error', summary: '', detail: 'Please give the inputs', life: 1000 });
    }else{
      if(this.contact.first_name !== '' || this.contact.last_name !== '')
      {
       // this.contact.first_name = this.contact.first_name.charAt(0).toUpperCase()+this.contact.first_name.substr(1).toLowerCase();
        //this.contact.last_name = this.contact.last_name.charAt(0).toUpperCase()+this.contact.last_name.substr(1).toLowerCase();
      }
      if (this.selectedState !== null) { 
        this.contact.state_id = this.selectedState.id;
      }
      if (this.selectedCountry !== null) {
        this.contact.country_id = this.selectedCountry.id;
      }

      if(this.isMainContact){
        this.contact.contact_type ='2';
      }else{
        this.contact.contact_type ='1';
      }
      if (this.contacts.length === 0) {
        let jobInfo: any = this.jobInfo;
        jobInfo.risk_street_address = this.contact.street_address;
        jobInfo.risk_suburb = this.contact.suburb;
        jobInfo.risk_postcode = this.contact.postcode;
        jobInfo.risk_state_id = this.contact.state_id;
        jobInfo.risk_country_id = this.contact.country_id;
        jobInfo.client_category = '2';
        this.CrudService.Update(jobInfo, 'JobInfo').subscribe((data: any) => {
          //console.log('save response', data);
        });
      }

      if (this.contact.first_name !== '') {
        if (this.contact.id) {
          //console.log('contact id', this.contact.id);
          this.CrudService.Update(this.contact, 'jobriskcontacts').subscribe((data: any) => {
            ////console.log(JSON.stringify(data));
            if (data.success === 1) {
              this.messageService.add({ severity: 'success', summary: 'Successful', detail: 'Contact Details Updated', life: 1000 });
              //console.log('called');
              this.contacts[this.findIndexById(this.contact.id)] = this.contact;
              this.contacts = [...this.contacts];
              this.hideContactCard = false;
            }
          });
        } else {
          this.CrudService.Create(this.contact, 'jobriskcontacts').subscribe((data: any) => {
            if (data.success === 1) {
              this.messageService.add({ severity: 'success', summary: 'Successful', detail: 'Contact Created', life: 1000 });
              this.contact.id = data.id
              this.contacts = [...this.contacts, this.contact];
              this.myOutput.emit(this.contacts);
              this.hideContactCard = false;
            }
          })
        }
      }
    }
  }

  editContact(contact: Contacts) {
    this.contact = { ...contact }
    if(this.contact.first_name == '' || this.contact.last_name == '' || this.contact.contact_email == '' || this.contact.street_address == '' || this.contact.suburb == '' || this.contact.postcode == '') 
    {
      this.messageService.add({ severity: 'error', summary: '', detail: 'Please give the inputs', life: 1000 });
     } else{
      if (this.contact.country_id !== '' && this.contact.country_id != null) {
        this.CrudService.getDataByField('Country', 'id', this.contact.country_id).subscribe((data: any[]) => {
          this.selectedCountry = data[0];
          //console.log(this.contact.country_id);
        });
      }
      if (this.contact.state_id !== '' && this.contact.state_id != null) {
        this.CrudService.getDataByField('State', 'id', this.contact.state_id).subscribe((data: any[]) => {
          this.selectedState = data[0];
        });
      }
      if(this.contact.contact_type ==='2'){
        this.isMainContact = true;
      }else{
        this.isMainContact = false;
      }
      this.hideContactCard = true;
    }
  }

  cancel() {
    this.hideContactCard = false;
  }

  ngOnDestroy() {
    this.config.data = {};
  }

  deleteContact(contact: Contacts) {
    this.confirmationService.confirm({
      message: 'Are you sure you want to delete contact : ' + contact.first_name + '?',
      header: 'Confirm',
      icon: 'pi pi-exclamation-triangle',
      accept: () => {
        this.contacts = this.contacts.filter((val: any) => val.id !== contact.id);
        this.myOutput.emit(this.contacts);
        this.CrudService.Delete(contact.id, 'jobriskcontacts').subscribe(() => {
          this.messageService.add({ severity: 'success', summary: 'Successful', detail: 'Contact Deleted', life: 1000 });
        });
      }
    });
  }

  findIndexById(id: string,): number {
    let index = -1;
    for (let i = 0; i < this.contacts.length; i++) {
      if (this.contacts[i].id === id) {
        index = i;
        break;
      }
    }

    return index;
  }

  public AddressChange(address: any) {
    const fullAddress = [];
    for (let i = 0; i < address.address_components.length; i++) {
      const addressType = address.address_components[i].types[0];
      // }
      if (this.componentForm[addressType]) {

        const val = address.address_components[i][this.componentForm[addressType]];

        if (addressType === 'street_number') {
          fullAddress[0] = val;
        } else if (addressType === 'route') {
          fullAddress[1] = val;
        }

        if (addressType === 'sublocality_level_1') {// suburb
          this.contact.street_address = val;
        }
        if (addressType === 'locality') {// suburb
          this.contact.suburb = val;
        } else if (addressType === 'administrative_area_level_1') {// state
          this.stateList.forEach((stat) => {
            if (stat.name === val) {
              this.contact.state_id = stat.id;
              this.selectedState = stat;
            }
          });

        } else if (addressType === 'administrative_area_level_2') {//council
          //                                                $scope.suburb = val;
        } else if (addressType === 'country') {
          this.countryList.forEach((ctry) => {
            if (ctry.name === val) {
              this.contact.country_id = ctry.id;
              this.selectedCountry = ctry;
            }
          });
        } else if (addressType === 'postal_code') {
          this.contact.postcode = val;
        }

      }
    }
    const fullAdr = fullAddress.join(' ');
    this.contact.street_address = fullAdr;
    //console.log(this.contact.street_address);
  }

  markMain(event) {
    this.isMainContact = event.checked;
  }

  loadRiskInfo(event) {
    this.isRiskAddress = event.checked;
    if (event.checked) {
      this.contact.street_address = this.jobInfo.risk_street_address;
      this.contact.suburb = this.jobInfo.risk_suburb;
      this.stateList.forEach((stat) => {
        if (stat.id === this.jobInfo.risk_state_id) {
          this.contact.state_id = stat.id;
          this.selectedState = stat;
        }
      });
      this.countryList.forEach((ctry) => {
        if (ctry.id === this.jobInfo.risk_country_id) {
          this.contact.country_id = ctry.id;
          this.selectedCountry = ctry;
        }
      });
      this.contact.postcode = this.jobInfo.risk_postcode;
      this.contact.street_address = this.jobInfo.risk_street_address;
    }
  }
}