import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { Contractor, Contacts, contractorCompliance } from './tp-registration';
import { ActivatedRoute, Router, NavigationStart } from '@angular/router';
import { CRUDService } from 'src/app/utilities/crud.service';
import { ConfirmationService, MessageService } from 'primeng/api';
import { Table } from 'primeng/table';
import { DynamicDialogConfig, DynamicDialogRef, DialogService, } from 'primeng/dynamicdialog';
import { ComplianceComponent } from '../../admin/compliance/compliance.component';
import { ContactComponent } from '../../admin/contact/contact.component';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { EncrDecrService } from 'src/app/utilities/encr-decr.service';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { CommonService } from 'src/app/utilities/common.service';
import { debug } from 'console';
import { PasswordCheckComponent } from 'src/app/password-check/password-check.component';
export { }; declare global { interface Window { Calendly: any; eventUrl: any; saveEvent: any } }
import { environment } from 'src/environments/environment';
import { EncryptionService } from 'src/app/utilities/encryption.service';
import { NgTinyUrlService } from 'ng-tiny-url';
@Component({
  selector: 'app-tp-registration',
  templateUrl: './tp-registration.component.html',
  styleUrls: ['./tp-registration.component.scss'],
  providers: [DialogService, MessageService, ConfirmationService]
})
export class TPRegistrationComponent implements OnInit {
  @Input() compToken: any;
  loaded: Boolean = false;
  displayLogin: Boolean = true;
  appProps: any;
  viewAs: any = 'TP';
  id: any;
  type: any = 'TP';
  addNewComplianceButton: Boolean;
  respToggleMenu: Boolean = false;
  companyToken: any = null;
  status_desc: any;
  viewType: any;
  userDetails: any;
  companyId: any;
  tagConfig: any;
  activeTab: any;
  isActive: boolean;
  submitted: boolean;
  contractorCategory: string
  stateList: any[];
  signature: any;
  loading: Boolean = false;
  showHeader: Boolean = false;
  cols: any[];
  exportColumns: any[];
  contractors: any[];
  paymentTermsList: any;
  contractorTypeList: any;
  countryList: any[];
  companyList: any[];
  selectedPaymentTerm: any;
  selectedContractorType: any;
  staff: any;
  user: any;
  selectedCountry: any;
  selectedState: any;
  selectedContractor: any[];
  selectedCompany: any;
  companyCompliance: any;
  companyComplianceList: any;
  complianceDialog = false;
  mobNumberPattern = '`^((\\+61-?)|0)?[0-9]{10}$`';
  ref: DynamicDialogRef;
  isDepot: boolean = false;
  enablActive: any = false;
  inatctiveFrom: any;
  employeSize: any[];
  selectedemployeSize: any;
  treeCrew: any[];
  selectedtreeCrew: any;
  inatctiveTo: any;
  assetsList: any[] = [];
  AssetVehicleList: any[];
  selectedAssetVehicleList: any;
  contractorId: any;
  city: null;
  selectedClient: any = null;
  clientType: any[] = [{ name: 'Work Provider', id: '2' }, { name: 'Contractor', id: '3' }, { name: 'License Product TP', id: '4' }];//{ name: 'Admin', id: '1' }
  selectedClientType = { name: 'Contractor', id: '3' };
  contractorForm: FormGroup;
  reject_reason: any = '';
  isPasswordCheck = false;
  selectedCommType: any;
  communicationTypeList: any;
  selectedSubType: any;
  clientSubTypeList: any;
  selectedComplianceLevel: any;
  complianceLevelList: any;
  showWizard: Boolean = false;
  videoUrl: SafeResourceUrl;
  pdfUrl: SafeResourceUrl;
  closeVideo: boolean = true;
  closepdf: boolean = true;
  passKeyTextType: boolean;
  confirmpassKeyTextType: boolean;
  form: FormGroup;
  optionsMap: any;
  dupName: boolean = false;
  dupTradeName: boolean = false;
  dupUserId: boolean = false;
  dupAccNo: boolean = false;
  dupEmail: boolean = false;
  valueFromChild: string;

  displayRegnModal: Boolean = false;

  tabcompanyApproved: boolean = false;
  tabcomplainceApproved: boolean = false;
  tabstaffApproved: boolean = false;
  tabdepotApproved: boolean = false;
  tabvehicleApproved: boolean = false;
  tabslaApproved: boolean = false;
  tabinductionsApproved: boolean = false;
  tabtmappApproved: boolean = false;
  complainceApproved: boolean = false;
  empSize: any;
  minEmpSize: number;
  complainceData: number;
  displayRejectionModal = false;
  passwordMatch: boolean = false;

  wizardMetaData: any = [];
  depotLat : any;
  depotLng : any;

  initialPercent: number;
  complaincePercent: number;
  depotPercent: number;
  staffPercent: number;
  slaPercent: number;
  vehiclePercent: number;
  trainingPercent: number;
  totalPercent: number;
  completionPercent: number;


  showWelcome: boolean = false;
  displaySuccess: boolean = false;
  eventsList: any;
  eventUri: any;
  eventId: any;
  eventData: any;
  eventUrl: any;
  showTraining: boolean = true;
  websiteFlag: boolean = true;
  saveFlag: boolean = false;
  saveVisible: boolean = true;
  isABNInvalid: Boolean = false;
  filteredPlaces: any[] = [];
  inputAddress: any = [];
  welcomeVideo: any;
  markDepotView: boolean = false;
  options = {
    componentRestrictions: {
      country: [environment.hosting]
    },
    fields: ['address_components', 'geometry', 'icon', 'name'],
    strictBounds: false,
  }
  caption =
    {
      business_ABN: environment.hosting == 'UK' ? 'Company Registration Number' : 'ABN',
      bsb: environment.hosting == 'UK'? 'Sort Code' : 'BSB Code',
    }

  componentForm = {
    street_number: 'short_name',
    route: 'long_name',
    locality: 'long_name',
    sublocality_level_1: 'long_name',
    administrative_area_level_1: 'short_name',
    country: 'long_name',
    postal_code: 'short_name'
  };

  exportdisplay: Boolean = false;
  // tslint:disable-next-line: max-line-length
  customLabel = { restaurant: { label: 'R' }, bar: { label: 'B' } };
  map: google.maps.Map;
  iconBase = 'https://maps.google.com/mapfiles/kml/shapes/';
  icons: any;
  overlays: any[] = [];
  nextPage: any;
  hasnumbers: boolean;
  haslowercase: boolean;
  hasuppercase: boolean;
  hasspecialchar: boolean;
  userloggedin: boolean = false;
  pre_account_number: any=null;
  pre_account_BSB: any=null;
  pre_account_name: any=null;
  emailSubject: any;
  sendlinkvia: boolean;
  emailContent: any;
  spa_access_Url: string;
  setMap(event) {
    this.map = event.map;
  }

  disablejobSts: boolean=false;

  contractor: any = {
    StateName: null,
    account_BSB: null,
    account_email: null,
    account_id: null,
    account_name: null,
    account_number: null,
    addl_address: null,
    business_ABN: null,
    business_name: null,
    client_category: null,
    client_comm_type: null,
    client_subtype: null,
    client_type_id: '3',
    first_name: null,
    last_name: null,
    company_inactive_from: null,
    company_inactive_to: null,
    compliancelevel_id: null,
    country_id: null,
    created_at: null,
    created_by: null,
    default_depot: null,
    deleted: null,
    email: null,
    emp_size: null,
    fence_required: null,
    id: null,
    invalid_login_count: null,
    last_modified_by: null,
    last_updated_at: null,
    location_lat: null,
    location_lng: null,
    main_contact_name: null,
    margin: null,
    mobile_no: null,
    no_of_crew: null,
    operations_circle: null,
    owner: null,
    payment_terms: null,
    phone: null,
    postcode: null,
    priority: null,
    process_id: 1,
    rebate: null,
    regn_status: null,
    remarks: null,
    site_address: null,
    state_id: null,
    state_name: null,
    status: '1',
    street_address: null,
    suburb: null,
    trading_name: null,
    website: null,
    vat_no:null,
    vat_avail:false
  };

  emailsList: any;

  showError: boolean = false;
  contactEmailIds: any[] = [];
  emailIds: any;
  accountsEmail : any;
  // wizardMetaData = [
  //   { id: 'tab-company', title: 'Company', class: 'active', iconClass: 'fa fa-home', videoUrl:'https://www.youtube.com/embed/yXIS-c9HsXc',pdfUrl:'https://treemanager.com:5003/policy_documents/help/EF_Help_Document.pdf',approved: this.tabcompanyApproved, acceptanceCriteria: ['Lorem Ipsum is simply dummy text of the printing and typesetting industry', 'Lorem Ipsum is simply dummy text of the printing and typesetting industry', 'Lorem Ipsum is simply dummy text of the printing and typesetting industry'] },
  //   { id: 'tab-compliance', title: 'Compliance', iconClass: 'fa fa-envelope', videoUrl:'https://www.youtube.com/embed/muZQuBmLF5s',pdfUrl:'http://treemanabger.com:5003/policy/EF_Help_Document',approved: false, acceptanceCriteria: ['Lorem Ipsum is simply dummy text of the printing and typesetting industry', 'Lorem Ipsum is simply dummy text of the printing and typesetting industry', 'Lorem Ipsum is simply dummy text of the printing and typesetting industry'] },
  //   { id: 'tab-staff', title: 'Staff & Compliance', iconClass: 'fa fa-file-text-o',videoUrl:'https://www.youtube.com/embed/-Kc70U62d-E',pdfUrl:'https://treemanager.com:5003/policy_documents/help/EF_Help_Document.pdf', approved: false, acceptanceCriteria: ['Lorem Ipsum is simply dummy text of the printing and typesetting industry', 'Lorem Ipsum is simply dummy text of the printing and typesetting industry', 'Lorem Ipsum is simply dummy text of the printing and typesetting industry'] },
  //   { id: 'tab-depot', title: 'Depot', iconClass: 'fa fa-map', videoUrl:'https://www.youtube.com/embed/yXIS-c9HsXc',pdfUrl:'https://treemanager.com:5003/policy_documents/help/EF_Help_Document.pdf',approved: false, acceptanceCriteria: ['Lorem Ipsum is simply dummy text of the printing and typesetting industry', 'Lorem Ipsum is simply dummy text of the printing and typesetting industry', 'Lorem Ipsum is simply dummy text of the printing and typesetting industry'] },
  //   { id: 'tab-vehicle', title: 'Vehicle Details', iconClass: 'fa fa-vehicle',videoUrl:'https://www.youtube.com/embed/yXIS-c9HsXc', pdfUrl:'https://treemanager.com:5003/policy_documents/help/EF_Help_Document.pdf',approved: true, acceptanceCriteria: ['Lorem Ipsum is simply dummy text of the printing and typesetting industry', 'Lorem Ipsum is simply dummy text of the printing and typesetting industry', 'Lorem Ipsum is simply dummy text of the printing and typesetting industry'] },
  //   { id: 'tab-sla', title: 'Service Level Agreement', iconClass: 'fa fa-phone', videoUrl:'https://www.youtube.com/embed/yXIS-c9HsXc',pdfUrl:'https://treemanager.com:5003/policy_documents/help/EF_Help_Document.pdf',approved: false, acceptanceCriteria: ['Lorem Ipsum is simply dummy text of the printing and typesetting industry', 'Lorem Ipsum is simply dummy text of the printing and typesetting industry', 'Lorem Ipsum is simply dummy text of the printing and typesetting industry'] },
  //   { id: 'tab-inductions', title: 'Inductions', iconClass: 'fa fa-picture-o', videoUrl:'https://www.youtube.com/embed/yXIS-c9HsXc',pdfUrl:'https://treemanager.com:5003/policy_documents/help/EF_Help_Document.pdf',approved: false, acceptanceCriteria: ['Lorem Ipsum is simply dummy text of the printing and typesetting industry', 'Lorem Ipsum is simply dummy text of the printing and typesetting industry', 'Lorem Ipsum is simply dummy text of the printing and typesetting industry'] },
  //   { id: 'tab-tmapp', title: 'Tree Manager & App', iconClass: 'fa fa-check-square',videoUrl:'https://www.youtube.com/embed/yXIS-c9HsXc', pdfUrl:'https://treemanager.com:5003/policy_documents/help/EF_Help_Document.pdf',approved: false, acceptanceCriteria: ['Lorem Ipsum is simply dummy text of the printing and typesetting industry', 'Lorem Ipsum is simply dummy text of the printing and typesetting industry', 'Lorem Ipsum is simply dummy text of the printing and typesetting industry'] },
  // ]



  // tslint:disable-next-line: max-line-length
  constructor(private crudService: CRUDService,
    private messageService: MessageService,
    private confirmationService: ConfirmationService,
    private dialogService: DialogService,
    public config: DynamicDialogConfig,
    public dialofRef: DynamicDialogRef,
    private router: Router,
    private route: ActivatedRoute,
    public sanitizer: DomSanitizer,
    private commonservice: CommonService,
    private EncrDecr: EncrDecrService,
    private encryptService: EncryptionService,
    private fb: FormBuilder,private tinyUrl: NgTinyUrlService) {
    this.companyId = null;//sessionStorage.getItem('company_id');
    //this.userDetails = JSON.parse(sessionStorage.getItem('loggedInUser'));

    var userisloggedin = JSON.parse(sessionStorage.getItem('loggedInUser'));
    if (userisloggedin) {
      this.userloggedin = true
    }



    this.cols = [
      { field: 'business_name', header: 'Business Name' },
      { field: 'trading_name', header: 'Trading Name' },
      { field: 'email', header: 'Email' },
      { field: 'phone', header: 'Phone' },
      { field: 'state_name', header: 'State Name' }
    ];
    this.exportColumns = this.cols.map(col => ({ title: col.header, dataKey: col.field }));
    this.employeSize = [
      { name: '2-5', id: '0' },
      { name: '6-10', id: '1' },
      { name: '11-25', id: '2' },
      { name: '26-50', id: '3' },
      { name: '50-100', id: '4' },
    ];
    this.treeCrew = [
      { name: '1', id: '1' },
      { name: '2', id: '2' },
      { name: '3', id: '3' },
      { name: '4', id: '4' },
      { name: '5 or More', id: '5' },
    ];

    this.welcomeVideo = this.sanitizer.bypassSecurityTrustResourceUrl(environment.siteAssets.welcomeVideo);
    this.contractorForm = new FormGroup({
      business_name: new FormControl('', Validators.required),
      trading_name: new FormControl(''),
      business_ABN: new FormControl('', [Validators.maxLength(11), Validators.minLength(11)]),
      type_list: new FormControl(''),
      first_name: new FormControl(''),
      last_name: new FormControl(''),
      email: new FormControl(''),
      phone: new FormControl(''),
      mobile_no: new FormControl(''),
      account_name: new FormControl(''),
      account_BSB: new FormControl(''),
      account_number: new FormControl(''),
      account_email: new FormControl(''),
      street_address: new FormControl(''),
      suburb: new FormControl(''),
      postcode: new FormControl(''),
      state: new FormControl(''),
      country: new FormControl(''),
      payment_terms: new FormControl(''),
      employeSize: new FormControl(''),
      treeCrew: new FormControl(''),
      client_list: new FormControl(''),
      account_id: new FormControl(''),
      rebate: new FormControl(''),
      communication_type: new FormControl(''),
      client_sub_type: new FormControl(''),
      compliance_level: new FormControl(''),
      user_name: new FormControl(''),
      pass_key: new FormControl('', [Validators.required, Validators.pattern(/^(?=.*[A-Z])(?=.*[a-z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})/), Validators.minLength(8)]),
      confirm_pass_key: new FormControl('', [Validators.required, Validators.minLength(8)]),
      vat_no: new FormControl()
    })

    this.user = {
      alternate_phone: null,
      colour: null,
      colour_scheme: null,
      company_id: null,
      created_at: null,
      created_by: '1',
      description: null,
      dob: null,
      email: null,
      first_name: null,
      id: null,
      image_name: null,
      invalid_passwd_count: '5',
      last_modified_by: null,
      last_name: null,
      last_updated_at: null,
      login_attempt: null,
      menu_type: null,
      mobile: null,
      owner: null,
      password: null,
      person_id: null,
      position_id: null,
      process_id: null,
      staff_id: null,
      status: '1',
      title: 'Mr.',
      user_group_id: '5',
      user_locked: null,
      username: null,
      work_phone: null
    };


    this.staff = {
      id: null,
      first_name: null,
      last_name: null,
      phone: null,
      company_id: null,
      mobile: null,
      email: null,
      role: null,
      stafftype: null,
      induction: null,
      client_type_id: null,
      inducted_date: null,
      username: null,
      password: null,
      create_login: null,
      process_id: 1,
      user_role_id: null,
      company_inactive_from: null,
      company_inactive_to: null,
    };
  }

  env: any;

  async ngOnInit() {
    this.loading = true;
    this.env = environment;

   
    this.isDepot = true;
    this.markDepotView = true;
    
    localStorage.removeItem('deportStrength');
    localStorage.removeItem('complainceStrength');
    localStorage.removeItem('submitStrength');
    localStorage.removeItem('staffStrength');
    localStorage.removeItem('trainingStrength');

    this.contractor.id = '';
    this.activeTab = 'tab-company';
    if (typeof this.route.snapshot.paramMap.get('token') !== 'undefined') {
      let token = this.route.snapshot.paramMap.get('token');
      if (token != null && token !== '') {
        (await this.crudService.getUserLogin('login', 'Admin@123')).subscribe(async (data: any) => {
          //this.router.navigate(['/register']);
          if(data){
        this.displayLogin = false;
        this.showHeader = true;
        this.showWelcome = false;
        this.companyToken = encodeURIComponent(token);
        let data: any = await this.crudService.getDataByField('company', 'token', this.companyToken).toPromise();
        let business_name = data[0].business_name;
        let trading_name = data[0].trading_name;
        let business_ABN = data[0].business_ABN;
        let account_number = data[0].account_number;
        let user_name = data[0].user_name;
        let email = data[0].email;
        let mobile_no = data[0].mobile_no;
        let first_name = data[0].first_name;
        let last_name = data[0].last_name;
        this.empSize = data[0].emp_size;
        if (business_name != '' && trading_name != '' && business_ABN != '' && account_number != '' && user_name != '' &&
          email != '' && mobile_no != '' && first_name != '' && last_name != '') {
          this.tabcompanyApproved = true;
          this.initialPercent = 20;
        }

        if (data.length > 0) {
          this.selectedCompany = data[0];
          this.companyId = this.selectedCompany.id;
          if (this.selectedCompany.regn_status == '' || this.selectedCompany.regn_status == null) {
            this.displayRegnModal = true;
          }
          localStorage.setItem('businessName2', this.selectedCompany.business_name);
        }
        let empData: any = await this.crudService.getDataByField('Users', 'company_id', this.companyId).toPromise();
        let userDetails = empData[0];
        delete userDetails['password'];
        sessionStorage.setItem('loggedInUser', JSON.stringify(userDetails));
          }
        });
        /* const ref = this.dialogService.open(PasswordCheckComponent, {
          data: {
            companyId: data[0].id,
          },
          width: '100%',
          height: '100%'
        })
        ref.onClose.subscribe((userDetails: any) => {
          
        }) */

      }
    }
    if (typeof this.config.data != 'undefined') {
      let token = this.config.data.compToken;
      //this.viewAs = 'TM';
      this.displayLogin = false;
      if (token != null && token !== '') {
        this.showHeader = true;
        this.showWelcome = false;
        this.companyToken = token;
        let data: any = await this.crudService.getDataByField('company', 'token', this.companyToken).toPromise();
        let business_name = data[0].business_name;
        let trading_name = data[0].trading_name;
        let business_ABN = data[0].business_ABN;
        let account_number = data[0].account_number;
        let user_name = data[0].user_name;
        let email = data[0].email;
        let mobile_no = data[0].mobile_no;
        let first_name = data[0].first_name;
        let last_name = data[0].last_name;
        this.empSize = data[0].emp_size;
        if (business_name != '' && trading_name != '' && business_ABN != '' && account_number != '' && user_name != '' &&
          email != '' && mobile_no != '' && first_name != '' && last_name != '') {
          this.tabcompanyApproved = true;
          this.initialPercent = 20;
        }

        if (data.length > 0) {
          this.selectedCompany = data[0];
          this.companyId = this.selectedCompany.id;
          localStorage.setItem('businessName2', this.selectedCompany.business_name);
          this.loadStrength(this.selectedCompany.id);
        }
      }
      else{
            localStorage.clear();
      }
    }
    (await this.crudService.getUserLogin('login', 'Admin@123')).subscribe(async (data: any) => {
      if(data){
        this.appProps = this.commonservice.getAppProperties();
        this.emailsList = await this.commonservice.getMailerlist();
        this.userDetails = JSON.parse(sessionStorage.getItem('loggedInUser'));
        this.paymentTermsList = this.crudService.fetchValueSet('PaymentTermsList').toPromise();
        //this.contractorTypeList = await this.crudService.fetchValueSet('ContractorTypeList').toPromise();
        //this.communicationTypeList = await this.crudService.fetchValueSet('ClientCommType').toPromise();
        //this.clientSubTypeList = await this.crudService.getByValuesetclienttypeWP().toPromise();
        // this.complianceLevelList = await this.crudService.getAllComplianceLevelHead().toPromise();
        if (this.userDetails != null) {
          this.saveFlag = false;
        } else {
          this.saveFlag = true;
        }
        this.crudService.getDataByField('businessSettings', 'company_id', '108').subscribe((data: any[]) => {
          this.accountsEmail = data[0].accountsEmail;
        });
     
        this.loadtab();
        this.videoUrl = this.sanitizer.bypassSecurityTrustResourceUrl(this.wizardMetaData[0].videoUrl);
        this.pdfUrl = this.sanitizer.bypassSecurityTrustResourceUrl(this.wizardMetaData[0].pdfUrl);
        this.updateAllCompanies();
        this.viewType = 'TP';
        this.optionsMap = {
          center: { lat: environment.hostingConfigs.mapCenterLat, lng: environment.hostingConfigs.mapCenterLng },
          zoom: 4
        };
    
        if (this.companyToken == null || this.companyToken == '') {
          this.userDetails = JSON.parse(sessionStorage.getItem('loggedInUser'));
          console.log("Data", this.userDetails);
          if (this.userDetails !== null) {
            let data: any = await this.crudService.getDataByField('company', 'id', this.userDetails.company_id).toPromise();
            if (data.length > 0) {
              this.selectedCompany = data[0];
              this.companyId = this.selectedCompany.id;
              this.displayLogin = false;
              this.companyToken = this.selectedCompany.token;
              this.empSize = this.selectedCompany.emp_size;
              this.loadtab();
              this.loadStrength(this.selectedCompany.id);
            }
          }
        }
    
        if ((this.companyToken !== null && this.companyToken !== '') || this.companyId) {
          await this.editSelectedRecord(this.selectedCompany);
          this.showWelcome = false;
          await this.loadtab();
          this.displayLogin = false;
          if (this.viewAs == 'TP') {
            this.userDetails = JSON.parse(sessionStorage.getItem('loggedInUser'));
            if (this.userDetails?.id == null) {
    
              const ref = this.dialogService.open(PasswordCheckComponent, {
                data: {
                  companyId: this.selectedCompany.id,
                },
                width: '100%',
                height: '100%'
              })
              ref.onClose.subscribe((userDetails: any) => {
                if (userDetails.id) {
                  this.userDetails = userDetails;
                  delete userDetails['password'];
                  sessionStorage.setItem('loggedInUser', JSON.stringify(userDetails));
    
                  this.loadStrength(this.selectedCompany.id);
                  this.empSize = this.selectedCompany.emp_size;
                  this.contractor.user_name = this.userDetails.username;
                  this.contractor.pass_key = this.userDetails.password;
                  this.contractor.confirm_pass_key = this.userDetails.password;
                  this.passwordMatchValidator(this.contractor.pass_key, this.contractor.pass_key);
                  this.loadStrength(this.selectedCompany.id);
                  this.empSize = this.selectedCompany.emp_size;
                  this.crudService.getStaffByCID(this.selectedCompany.id).subscribe(async (data2: any[]) => {
                    if (data2.length > 0) {
                      data2.sort(function (b, a) { return b.id - a.id });
                      //this.staff = data2[0];
                      // this.contractor.first_name = data2[0].first_name;
                      //  this.contractor.last_name = data2[0].last_name;
                      this.contractor.main_contact_name = data2[0].first_name + ' ' + data2[0].last_name;
                    }
    
                    this.showWelcome = false;
                  });
                }
              });
            } else {
              this.crudService.getStaffByCID(this.selectedCompany.id).subscribe(async (data2: any[]) => {
                if (data2.length > 0) {
                  data2.sort(function (b, a) { return b.id - a.id });
                  //this.staff = data2[0];
                  this.contractor.first_name = data2[0].first_name;
                  this.contractor.last_name = data2[0].last_name;
                  this.contractor.main_contact_name = data2[0].first_name + ' ' + data2[0].last_name;
                }
              })
            }
            this.showWizard = true;
          }
    
        } else {
          this.showHeader = true;
          this.showWizard = false;
          this.companyToken = null;
          this.userDetails = { id: '1' };
          this.showWelcome = true;
          localStorage.clear();
          this.openNew();
          this.status_desc = 'Please Provide all details to register with us.';
        }
        this.icons = {
          parking: { icon: this.iconBase + 'parking_lot_maps.png' },
          library: { icon: this.iconBase + 'library_maps.png' },
          info: { icon: this.iconBase + 'info-i_maps.png' },
          compliant: { icon: 'http://maps.google.com/mapfiles/kml/paddle/grn-stars.png' },
          job: { icon: 'http://maps.google.com/mapfiles/kml/paddle/grn-stars.png' },
          noncompliant: { icon: 'http://maps.google.com/mapfiles/kml/paddle/stop.png' }
        };
    
        this.loadMap();
        this.loadtab();
        if (this.env.hosting === "AU") {
          this.contractorForm.controls.business_ABN.valueChanges.subscribe((data: any) => {
            if (data?.length === 2) {
              this.contractorForm.controls.business_ABN.setValue(data + ' ')
            } else if (data?.length === 6) {
              this.contractorForm.controls.business_ABN.setValue(data + ' ')
            } else if (data?.length === 10) {
              this.contractorForm.controls.business_ABN.setValue(data + ' ')
            } else if (data?.length > 2 && data?.charAt(2) !== ' ') {
              this.contractorForm.controls.business_ABN.setValue([data.slice(0, 2), ' ', data.slice(2)].join(''))
            }
          });
        }
    
        if (this.userDetails != null) {
          this.contractor.first_name = this.userDetails.first_name;
          this.contractor.last_name = this.userDetails.last_name;
          this.contractor.main_contact_name = this.userDetails.first_name + ' ' + this.userDetails.last_name;
        }
        else {
          let empData: any = await this.crudService.getDataByField('Users', 'company_id', this.companyId).toPromise();
          this.contractor.first_name = empData[0].first_name;
          this.contractor.last_name = empData[0].last_name;
          this.contractor.main_contact_name = empData[0].first_name; + ' ' + empData[0].last_name;;
        }
        this.pre_account_number=this.contractor.account_number;
        this.pre_account_BSB=this.contractor.account_BSB;
        this.pre_account_name=this.contractor.account_name;
        this.loaded = true;
        this.loading = false;
        // this.contractor.account_number=null
        // this.contractor.account_BSB=null
        // this.contractor.account_name=null
        this.pre_account_number=this.contractor.account_number
        this.pre_account_BSB=this.contractor.account_BSB
        this.pre_account_name=this.contractor.account_name
      }
    });
    
  }

  clearLocalStorage() {
    localStorage.removeItem('slaStrength');
    localStorage.removeItem('complainceStrength');
    localStorage.removeItem('staffStrength');
    localStorage.removeItem('deportStrength');
    localStorage.removeItem('vehicleStrength');
    localStorage.removeItem('trainingStrength');
  }

  async updateSLA() {
    await this.loadStrength(this.selectedCompany.id)
    let index = this.wizardMetaData.findIndex(val => (val.id === 'tab-sla'));
    this.wizardMetaData[index].class = 'active';
  }
  async loadStrength(companyId) {

    this.clearLocalStorage();
    let data2: any = await this.crudService.getDataByField('company', 'id', companyId).toPromise();
    if (data2.length > 0) {
      this.initialPercent = 20;
      this.tabcompanyApproved = true;
    }
    let data: any = await this.crudService.getDataByField('CompanyCompliance', 'company_id', companyId).toPromise();
    if (this.env.hosting == "AU") {
      let index = data.findIndex(val => (val.compliance_id == '75'));
      if (index > -1) {
        localStorage.setItem('slaStrength', '10');
        this.tabslaApproved = true;
      }
    }
    else if (this.env.hosting == "UK") {
      let index = data.findIndex(val => (val.compliance_id == '165'));
      if (index > -1) {
        localStorage.setItem('slaStrength', '10');
        this.tabslaApproved = true;
      }
    }
    if (this.env.hosting == "AU") {
      let complainceData = data.filter(val => (val.compliance_id == '46' || val.compliance_id == '47' || val.compliance_id == '48' && val.status <= '2'));
      if (complainceData.length >= 3) {
        localStorage.setItem('complainceStrength', '20');
      }
    }
    else if (this.env.hosting == "UK") {
      let complainceData = data.filter(val => (val.compliance_id == '157' || val.compliance_id == '162' || val.compliance_id == '164' || val.compliance_id == '165' && val.status <= '2'));
      if (complainceData.length >= 3) {
        localStorage.setItem('complainceStrength', '20');
      }
    }
    let data6: any = await this.crudService.getStaffByCID(companyId).toPromise();
    let staffData = data6.filter(val => (val.InductionStatus == 'Yes'));
    for (let i = 0; i < this.employeSize.length; i++) {
      if (this.employeSize[i].id === this.empSize) {
        let minSizeall = this.employeSize[i].name;
        let minSize = minSizeall.split('-', 1)
        this.minEmpSize = minSize[0];
        break;
      }
    }
    if (staffData.length >= this.minEmpSize) {
      localStorage.setItem('staffStrength', '20');
    }
    let data3: any = await this.crudService.getByDepotCID(companyId).toPromise();
    if (data3.length > 0) {
      localStorage.setItem('deportStrength', '10');
    }
    let data4: any = await this.crudService.getDataByField('CompanyAssets', 'company_id', companyId).toPromise();
    if (data4.length > 0) {
      localStorage.setItem('vehicleStrength', '10');
      this.tabvehicleApproved = true;
    }
    let data7: any = await this.crudService.getDataByField('ScheduledTraining', 'company_id', companyId).toPromise();
    let Eventdata = data7.filter(val => (val.status == '1'));
    if (Eventdata.length > 0) {
      localStorage.setItem('trainingStrength', '10');
    }
    this.loadtab();
  }

  loadtab() {
    this.getAllStates();
    this.getAllCountries();
    if (this.showWelcome) {
      localStorage.clear();
    }

    if (localStorage.getItem('complainceStrength')) {
      let value = parseInt(localStorage.getItem('complainceStrength'));
      this.complaincePercent = value;
      this.tabcomplainceApproved = true;
    } else {
      this.complaincePercent = 0;
      this.tabcomplainceApproved = false;
    }

    if (localStorage.getItem('deportStrength')) {
      let value = parseInt(localStorage.getItem('deportStrength'));
      this.depotPercent = value;
      this.tabdepotApproved = true;
    } else {
      this.depotPercent = 0;
      this.tabdepotApproved = false;
    }

    if (localStorage.getItem('slaStrength')) {
      let value = parseInt(localStorage.getItem('slaStrength'));
      this.tabslaApproved = true;
      this.slaPercent = value;
    } else {
      this.slaPercent = 0;
      this.tabslaApproved = false;
    }

    if (localStorage.getItem('vehicleStrength')) {
      let value = parseInt(localStorage.getItem('vehicleStrength'));
      this.vehiclePercent = value;
      this.tabvehicleApproved = true;
      //this.vehiclePercent = 0;
    } else {
      this.vehiclePercent = 0;
      this.tabvehicleApproved = false;
    }

    if (localStorage.getItem('trainingStrength')) {
      let value = parseInt(localStorage.getItem('trainingStrength'));
      this.trainingPercent = value;
      this.tabtmappApproved = true;
    } else {
      this.trainingPercent = 0;
      this.tabtmappApproved = false;
    }

    if (localStorage.getItem('staffStrength')) {
      let value = parseInt(localStorage.getItem('staffStrength'));
      this.staffPercent = value;
      this.tabstaffApproved = true;
    } else {
      this.staffPercent = 0;
      this.tabstaffApproved = false;
    }
    this.wizardMetaData = [
      { id: 'tab-company', title: 'Company', class: 'active', iconClass: 'fa fa-book', videoUrl: 'https://www.youtube.com/embed/yXIS-c9HsXc', pdfUrl: 'https://treemanager.com:5003/policy_documents/help/EF_Help_Document.pdf', approved: this.tabcompanyApproved, acceptanceCriteria: ['Lorem Ipsum is simply dummy text of the printing and typesetting industry', 'Lorem Ipsum is simply dummy text of the printing and typesetting industry', 'Lorem Ipsum is simply dummy text of the printing and typesetting industry'] },
      { id: 'tab-compliance', title: 'Compliance', iconClass: 'fa fa-envelope', videoUrl: 'https://www.youtube.com/embed/muZQuBmLF5s', pdfUrl: 'https://treemanager.com:5003/policy_documents/help/EF_Help_Document.pdf', approved: this.tabcomplainceApproved, acceptanceCriteria: ['Lorem Ipsum is simply dummy text of the printing and typesetting industry', 'Lorem Ipsum is simply dummy text of the printing and typesetting industry', 'Lorem Ipsum is simply dummy text of the printing and typesetting industry'] },
      { id: 'tab-staff', title: environment.hosting == 'UK' ? 'Staff & Tickets' : 'Staff & Compliance', iconClass: 'fa fa-file-text-o', videoUrl: 'https://www.youtube.com/embed/-Kc70U62d-E', pdfUrl: 'https://treemanager.com:5003/policy_documents/help/EF_Help_Document.pdf', approved: this.tabstaffApproved, acceptanceCriteria: ['Lorem Ipsum is simply dummy text of the printing and typesetting industry', 'Lorem Ipsum is simply dummy text of the printing and typesetting industry', 'Lorem Ipsum is simply dummy text of the printing and typesetting industry'] },
      { id: 'tab-depot', title: 'Depot', iconClass: 'fa fa-map', videoUrl: 'https://www.youtube.com/embed/yXIS-c9HsXc', pdfUrl: 'https://treemanager.com:5003/policy_documents/help/EF_Help_Document.pdf', approved: this.tabdepotApproved, acceptanceCriteria: ['Lorem Ipsum is simply dummy text of the printing and typesetting industry', 'Lorem Ipsum is simply dummy text of the printing and typesetting industry', 'Lorem Ipsum is simply dummy text of the printing and typesetting industry'] },
      { id: 'tab-vehicle', title: 'Asset Details', iconClass: 'fa fa-cog', videoUrl: 'https://www.youtube.com/embed/yXIS-c9HsXc', pdfUrl: 'https://treemanager.com:5003/policy_documents/help/EF_Help_Document.pdf', approved: this.tabvehicleApproved, acceptanceCriteria: ['Lorem Ipsum is simply dummy text of the printing and typesetting industry', 'Lorem Ipsum is simply dummy text of the printing and typesetting industry', 'Lorem Ipsum is simply dummy text of the printing and typesetting industry'] },
      { id: 'tab-sla', title: 'Service Provider Agreement', iconClass: 'fa fa-phone', videoUrl: 'https://www.youtube.com/embed/yXIS-c9HsXc', pdfUrl: 'https://treemanager.com:5003/policy_documents/help/EF_Help_Document.pdf', approved: this.tabslaApproved, acceptanceCriteria: ['Lorem Ipsum is simply dummy text of the printing and typesetting industry', 'Lorem Ipsum is simply dummy text of the printing and typesetting industry', 'Lorem Ipsum is simply dummy text of the printing and typesetting industry'] },
      // { id: 'tab-inductions', title: 'Inductions', iconClass: 'fa fa-picture-o', videoUrl: 'https://www.youtube.com/embed/yXIS-c9HsXc', pdfUrl: 'https://treemanager.com:5003/policy_documents/help/EF_Help_Document.pdf', approved: this.tabinductionsApproved, acceptanceCriteria: ['Lorem Ipsum is simply dummy text of the printing and typesetting industry', 'Lorem Ipsum is simply dummy text of the printing and typesetting industry', 'Lorem Ipsum is simply dummy text of the printing and typesetting industry'] },
      // { id: 'tab-tmapp', title: environment.hosting == 'UK' ? 'Tree Manager APP Training' : 'Tree Manager & App', iconClass: 'fa fa-check-square', videoUrl: 'https://www.youtube.com/embed/yXIS-c9HsXc', pdfUrl: 'https://treemanager.com:5003/policy_documents/help/EF_Help_Document.pdf', approved: this.tabtmappApproved, acceptanceCriteria: ['Lorem Ipsum is simply dummy text of the printing and typesetting industry', 'Lorem Ipsum is simply dummy text of the printing and typesetting industry', 'Lorem Ipsum is simply dummy text of the printing and typesetting industry'] },
    ]
    this.status_desc = '';
    if (typeof this.contractor != 'undefined') {
      if (this.contractor.id != null) {
        let totalPercent = this.initialPercent + this.depotPercent + this.slaPercent + this.vehiclePercent + this.staffPercent + this.complaincePercent + this.trainingPercent;
        this.status_desc = totalPercent + '% Completed';
        this.contractor.comp_perc = totalPercent;
        if (this.contractor.id) {
          this.commonservice.saveRecord('Company', this.contractor);
        }
      }
    }

  }
  async loadMap() {
    this.overlays = [];
    this.loading = true;
    let contentString = null;
    contentString = '<div id="content"><div id="siteNotice"></div><h3 id="firstHeading" class="firstHeading">'
      + this.contractor.street_address;
    + this.contractor.suburb
      + this.contractor.postcode
      + this.contractor.state_id
      + this.contractor.country_id;
    let map = this.map;
    let lat = parseInt(this.contractor.location_lat);
    let lng = parseInt(this.contractor.location_lng);
    const marker = new google.maps.Marker(
      {
        position: new google.maps.LatLng(lat, lng),
        title: this.contractor.street_address,
        map,
        icon: this.icons['compliant'].icon
      });
    this.overlays.push(marker)

    const infowindow = new google.maps.InfoWindow({
      content: contentString,
    });

    google.maps.event.addListener(marker, 'click', (function (marker) {
      return function () {
        infowindow.setContent(contentString);
        infowindow.open(map, marker);
      }
    })(marker));
    this.loading = false;
  }


  passwordMatchValidator(pass_key, confirm_pass_key) {

    console.log('passwordMatch1', pass_key);
    if (pass_key === confirm_pass_key) {
      this.passwordMatch = false;
    }
    else {
      this.passwordMatch = true;
    }

    const re = /[0-9]/;
    if (re.test(pass_key)) {
      this.hasnumbers = true
    }
    else {
      this.hasnumbers = false
    }

    const re1 = /[a-z]/;
    if (re1.test(pass_key)) {
      this.haslowercase = true
    }
    else {
      this.haslowercase = false
    }

    const re2 = /[A-Z]/;
    if (re2.test(pass_key)) {
      this.hasuppercase = true
    }
    else {
      this.hasuppercase = false
    }

    const re3 = /[-+_!@#$%^&*.,?]/;
    if (re3.test(pass_key)) {
      this.hasspecialchar = true
    }
    else {
      this.hasspecialchar = false
    }
  }

  checkDuplicateName(value: any, field: any, table: any) {
    this.contractor.trading_name = value;
    this.crudService.getDataByField(table, field, value).subscribe((data: any) => {
      if (data.length == 0) { this.dupName = false; }
      else {
        if (this.companyId == data[0].id) { this.dupName = false; } else { this.dupName = true; }
      }
    })
  }

  checkDuplicateTradeName(value: any, field: any, table: any) {
    this.crudService.getDataByField(table, field, value).subscribe((data: any) => {
      if (data.length == 0) { this.dupTradeName = false; }
      else {
        if (this.companyId == data[0].id) { this.dupTradeName = false; } else { this.dupTradeName = true; }
      }
    })
  }
  checkDuplicateAccountNo(value: any, field: any, table: any) {
    this.crudService.getDataByField(table, field, value).subscribe((data: any) => {
      if (data.length == 0) { this.dupAccNo = false; }
      else {
        if (this.companyId == data[0].id) { this.dupAccNo = false; } else { this.dupAccNo = true; }
      }
    })
  }
  checkDuplicateUserId(value: any, field: any, table: any) {

    this.crudService.getDataByField(table, field, value).subscribe((data: any) => {
      //console.log("User Data", data)
      if (data.length == 0) { this.dupUserId = false; }
      else {
        if (this.companyId == data[0].company_id || value == '') { this.dupUserId = false; } else { this.dupUserId = true; }
      }
    })
  }
  emailValidation(val: any) {
    var regex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/; // Uppercase character pattern
    if (regex.test(val)) {
      return true;
    } else {
      return false;
    }
  }

  removeToMailId(value) {
    this.contactEmailIds = this.contactEmailIds.filter(val => val !== value);
  }

  checkDuplicateEmail(value: any, field: any, table: any, mailFieldValue, arrayObject) {
   
    //console.log('inputt',value,field,table,mailFieldValue,arrayObject);

    this.crudService.getDataByField(table, field, value).subscribe((data: any) => {
      if (data.length == 0) { this.dupEmail = false; }
      else {
        if (this.companyId == data[0].id) { this.dupEmail = false; } else { this.dupEmail = true; }
      }

      if (typeof mailFieldValue !== 'undefined' && mailFieldValue !== null && mailFieldValue !== '') {
        if (this.emailsList.includes(mailFieldValue)) {
          if (arrayObject.length > 0) {
            let index = arrayObject.findIndex((res: any) => res == mailFieldValue);
            if (index > -1) {
            } else {
              arrayObject.push(mailFieldValue);
            }
          } else {
            this.showError = false;
            arrayObject.push(mailFieldValue);
          }
        } else {
          if (this.emailValidation(mailFieldValue)) {
            let index = arrayObject.findIndex((res: any) => res == mailFieldValue);
            if (index < 0) {
              arrayObject.push(mailFieldValue);
            }
          }
        }
      }

     // console.log('arrayObjecttt',arrayObject)
      this.emailIds = null;
      mailFieldValue = null;
    })

  }


  exportExcel() {
    import('xlsx').then(xlsx => {
      const worksheet = xlsx.utils.json_to_sheet(this.contractors);
      const workbook = { Sheets: { data: worksheet }, SheetNames: ['data'] };
      const excelBuffer: any = xlsx.write(workbook, { bookType: 'xlsx', type: 'array' });
      this.saveAsExcelFile(excelBuffer, 'contractors');
    });
  }

  saveAsExcelFile(buffer: any, fileName: string): void {
    import('file-saver').then(FileSaver => {
      const EXCEL_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
      const EXCEL_EXTENSION = '.xlsx';
      const data: Blob = new Blob([buffer], {
        type: EXCEL_TYPE
      });
      FileSaver.saveAs(data, fileName + '_export_' + new Date().getTime() + EXCEL_EXTENSION);
    });
  }

  clear(table: Table) {
    table.clear();
  }

  getAllCountries() {
    this.crudService.getAll('Country').subscribe((data: any[]) => {
      this.countryList = data;
    });
  }

  enableActiveInactive(value) {
    //console.log(value);
    if (value.checked === 'false') {
      this.enablActive = false;
    }
    if (value.checked === 'true') {
      this.enablActive = true;
    }
  }

  getAllStates() {
    this.crudService.getAll('State').subscribe((data: any[]) => {
      this.stateList = data;
    });
  }
  onStrengthChange(score) {
    // console.log('new score', score);
  }

  toggle_pass_key() {
    this.passKeyTextType = !this.passKeyTextType;
  }
  toggle_confirm_pass_key() {
    this.confirmpassKeyTextType = !this.confirmpassKeyTextType;
  }


  loadHelpvideo() {
    this.closeVideo = true;
    this.closepdf = false;
    document.getElementById('help-video').style.display = 'block';
  }
  loadHelppdf() {
    this.closeVideo = false;
    this.closepdf = true;
    document.getElementById('help-video').style.display = 'block';
  }
  videoClose() {
    this.closeVideo = false;
    this.closepdf = false;
    document.getElementById('help-video').style.display = 'none';

  }

  openNew() {
    this.contractorForm.markAsUntouched();
    this.isActive = true;
    this.selectedContractor = [];
    this.selectedPaymentTerm = {};
    this.selectedContractorType = {}
    this.selectedAssetVehicleList = [];
    this.selectedCommType = {};
    this.selectedComplianceLevel = {};
    this.selectedCountry = {};
    this.selectedemployeSize = {};
    this.selectedSubType = {};
    this.selectedtreeCrew = {};
    this.selectedState = {};
    // this.isDepot = false;
    this.contractor = {
      StateName: null,
      account_BSB: null,
      account_email: null,
      account_id: null,
      account_name: null,
      account_number: null,
      addl_address: null,
      business_ABN: null,
      business_name: null,
      client_category: null,
      client_comm_type: null,
      client_subtype: null,
      client_type_id: '3',
      company_inactive_from: null,
      company_inactive_to: null,
      compliancelevel_id: null,
      country_id: null,
      created_at: null,
      created_by: null,
      default_depot: null,
      deleted: null,
      email: null,
      emp_size: null,
      fence_required: null,
      id: null,
      invalid_login_count: null,
      last_modified_by: null,
      last_updated_at: null,
      location_lat: null,
      location_lng: null,
      main_contact_name: null,
      margin: null,
      mobile_no: null,
      no_of_crew: null,
      operations_circle: null,
      owner: null,
      payment_terms: null,
      phone: null,
      postcode: null,
      priority: null,
      process_id: 1,
      rebate: null,
      regn_status: null,
      remarks: null,
      site_address: null,
      state_id: null,
      state_name: null,
      status: '1',
      street_address: null,
      suburb: null,
      trading_name: null,
      website: null,
      vat_avail:false,
      vat_no:null,
    };
    this.contractor.process_id = this.companyId;
    this.contractorForm.get('account_id').clearValidators();
    this.contractorForm.get('client_list').clearValidators();
    this.contractorForm.get('rebate').clearValidators();
    this.companyCompliance = { ...this.companyComplianceList };
    this.companyCompliance = {
      amount: null,
      comment: null,
      company_id: null,
      compliance_id: null,
      compliance_type_id: null,
      created_at: null,
      created_by: null,
      expiry_date: null,
      file_path: null,
      filename: null,
      id: null,
      issued_by: null,
      issued_date: null,
      last_modified_by: null,
      last_reminder_date: null,
      last_updated_at: null,
      owner: null,
      policy_no: null,
      reminder_count: null,
      reminder_status: null,
      staff_id: null,
      status: null
    };
    
    this.pre_account_number = null;
    this.pre_account_BSB=null;
    this.pre_account_name=null;
  }

  hideDialog() {
     window.localStorage.clear();
    window.sessionStorage.clear();
    /* this.confirmationService.confirm({
      message: 'Are you sure you want to return to Login Screen?',
      header: 'Confirm',
      icon: 'pi pi-exclamation-triangle',
      accept: () => {
      }
    }); */
    this.router.navigate(['/login']);
  }

  reset() {
    this.confirmationService.confirm({
      message: 'Are you sure you want to cancel the changes made?',
      header: 'Confirm',
      icon: 'pi pi-exclamation-triangle',
      accept: () => {
        this.crudService.getDataByField('Company', 'id', this.contractor.id).subscribe((data: any[]) => {
          if (data.length > 0) {
            this.editSelectedRecord(data[0]);
          }
        });
      }
    });
  }

  async saveRecord() {
  
    this.disablejobSts=true;
    if (this.contractor.first_name !== '' && this.contractor.last_name !== '' && this.contractor.business_name !== ''
      && typeof this.contractor.mobile_no !== 'undefined' && this.contractor.mobile_no !== '' && this.contractor.mobile_no != null
      && typeof this.contactEmailIds != 'undefined' && this.contactEmailIds.length > 0 && this.contactEmailIds != null
      && typeof this.contractor.street_address !== 'undefined' && this.contractor.street_address !== '' && this.contractor.street_address != null
    ) {
      if (this.contactEmailIds.length > 0) {
        this.contractor.email = this.contactEmailIds.toString();
      } else {
        this.contractor.email = null;
      }
      this.submitted = true;
      this.contractor.process_id = this.companyId;
      this.contractor.client_subtype = null;
      // if(this.contractor.account_number == 'undefined')
      // {
      //   this.contractor.account_number = this.contractor.account_number?.toString();
      // }
      // else
      // {
      //   this.contractor.account_number = null;
      // }
      this.contractor.client_subtype = null;
      if (typeof this.selectedemployeSize.id !== 'undefined') {
        this.contractor.emp_size = this.selectedemployeSize.id;
      } else {
        this.contractor.emp_size = null;
      }
      if (typeof this.selectedtreeCrew.id !== 'undefined') {
        this.contractor.no_of_crew = this.selectedtreeCrew.id;
      } else {
        this.contractor.no_of_crew = null;
      }
      /* if (typeof this.selectedComplianceLevel.id !== 'undefined') {
        this.contractor.compliancelevel_id = this.selectedComplianceLevel.id;
      } else {
        this.contractor.compliancelevel_id = null;
      }
      if (typeof this.selectedCommType.id !== 'undefined') {
        this.contractor.client_comm_type = this.selectedCommType.id;
      } else {
        this.contractor.client_comm_type = null;
      } */

      /* if (typeof this.selectedPaymentTerm.id !== 'undefined') {
        this.contractor.payment_terms = this.selectedPaymentTerm.id;
      } else {
        this.contractor.payment_terms = null;
      } */
      if (typeof this.selectedCountry.id !== 'undefined') {
        this.contractor.country_id = this.selectedCountry.id;
      } else {
        this.contractor.country_id = null;
      }
      if (typeof this.selectedState.id !== 'undefined') {
        this.contractor.state_id = this.selectedState.id;
      } else {
        this.contractor.state_id = null
      }

      if (this.isDepot) {
        this.contractor.default_depot = '1';

      } else {
        this.contractor.default_depot = '2';
      }
      this.contractor.main_contact_name = this.contractor.first_name + ' ' + this.contractor.last_name;
    
      if (this.contractor) {
        if(this.contractor.vat_avail)
          this.contractor.vat_avail=1;
        else
          this.contractor.vat_avail=0;
        if (this.contractor.id) {
          this.contractor.last_modified_at = new Date().toDateString();
          this.contractor.last_modified_by = this.userDetails?.id;
          //let data: any = await this.commonservice.updateRecord('company', this.contractor);
          //this.crudService.Update(this.contractor, 'company').subscribe((data: any) => {
          console.log("company", this.contractor);
          let data: any = await this.commonservice.saveRecord('company', this.contractor);
          //this.crudService.Update(this.contractor, 'company').subscribe((data: any) => {
          if (data.success === 1 || data.success) {
            this.disablejobSts=true;
            if (this.isDepot) {
             // this.saveDepot();
            }
            this.messageService.add({ severity: 'success', summary: 'Successful', detail: 'Business Info Updated', life: 1000 });
            if((this.pre_account_number !==null && this.pre_account_number !== this.contractorForm.get('account_number').value) ||
               (this.pre_account_BSB !==null && this.pre_account_BSB !== this.contractorForm.get('account_BSB').value) || 
               (this.pre_account_name !==null && this.pre_account_name !== this.contractorForm.get('account_name').value)){
               this.sendmail()
               }
               else{
                console.log("fail");
               }
               this.pre_account_number=this.contractorForm.get('account_number').value
               this.pre_account_BSB=this.contractorForm.get('account_BSB').value
               this.pre_account_name= this.contractorForm.get('account_name').value

            this.disablejobSts=false;
          } else {
            this.messageService.add({ severity: 'error', summary: 'Error', detail: 'Business Info Not Updated', life: 1000 });
            this.disablejobSts=false;
          }
        } else {
          this.contractor.created_at = new Date().toDateString();
          this.contractor.created_by = '1';
          this.contractor.status = '0';
          this.crudService.Create(this.contractor, 'company').subscribe(async (data: any) => {
            if (data.success === 1) {
              this.contractor.id = data.id;
              let user = JSON.stringify(sessionStorage.getItem('adminUser'));
              sessionStorage.setItem('loggedInUser', JSON.stringify(user));
              this.contractor.location_lat = this.contractor.location_lat.toString();
              this.contractor.location_lng = this.contractor.location_lng.toString();
              let data2: any = await this.commonservice.saveRecord('company', this.contractor);
              //await this.commonservice.updateRecord('company', this.contractor);
              this.contractorId = this.contractor.id;
              if (this.isDepot) {
               await this.saveDepot();
              }
              this.saveUser();
              this.displaySuccess = true;
              this.disablejobSts=false;
              this.displayLogin = true;
            } else {
              this.messageService.add({ severity: 'error', summary: 'Error', detail: 'Error Registering Business', life: 1000 });
              this.disablejobSts=false;
            }
          });
        }
      } else {
        this.messageService.add({ severity: 'error', summary: 'Error', detail: 'Please enter all mandatory field to proceed with saving', life: 3000 });
        this.disablejobSts=false;
      }
    } else {
      this.messageService.add({ severity: 'error', summary: 'Error', detail: 'Please enter all mandatory field to proceed with saving', life: 3000 });
      this.disablejobSts=false;
    }
    
  }

  sendmail(){
    if(this.env.emailRoutes.common == 'sendgrid')
    {
      let bsb_name=''
      if(this.env.hosting=='AU'){
        bsb_name ='BSB Code'
      }
      else if(this.env.hosting=='UK'){
        bsb_name ='Sort Code'
      }
      let acc_number = this.contractorForm.get('account_number').value
      let acc_BSB = this.contractorForm.get('account_BSB').value
      let acc_name = this.contractorForm.get('account_name').value
      let acc_email = this.contractorForm.get('account_email').value
      

      if(this.contractorForm.get('account_number').value == (null || '' || undefined) ){
        acc_number ='NA'
      }
      if(this.contractorForm.get('account_BSB').value == (null || '' || undefined) ){
        acc_BSB='NA'
      }
      if(this.contractorForm.get('account_name').value == (null || '' || undefined) ){
        acc_name='NA'
      }
      if(this.contractorForm.get('account_email').value == (null || '' || undefined) ){
        acc_email='NA'
      }
      
      const datobj2 = {
        subject :  'Enviro Trees: Company Account information modified- '+ this.contractorForm.get('business_name').value,
        body :'This is a mail to notify that account information of company : '+ this.contractorForm.get('business_name').value +' has been modified in Enviro Trees. The details are as below .'
        + '<br><br><span>Account Number : </span>'+acc_number
        + '<br>'+bsb_name+' : '+acc_BSB
        + '<br><span>Account Name : </span>'+acc_name
        + '<br><span>Account Email : </span>'+acc_email,
       
        toEmail : this.accountsEmail,
        ref_id:1,
        ref_type:'accounts'
        }
      this.crudService.sendmailSendgrid(datobj2,'commonMail').subscribe((res2: any[]) => { 
        console.log("mailsent res",res2);     
       });
    }
  }
  async saveDepot() {
    this.disablejobSts=true;
    let create: Boolean = false;
    let depotList: any = await this.crudService.getDataByField('Depot', 'company_id', this.contractor.id).toPromise();
    if (depotList.length > 0) {
      let filteredDepot = depotList.filter(val => (val.street_address === this.contractor.street_address && val.suburb === this.contractor.suburb));
      if (filteredDepot.length > 0) {
      } else {
        create = true;
      }
    } else {
      create = true;
    }

    if (create) {

      let mail = this.contractor.email.toString().split(',');
      let tempMail =mail[0] ;
      let depot = {

        id: null,
        process_id: this.contractor.id,
        depot_name: 'Default Depot',
        company_id: this.contractor.id,
        status: '1',
        make_default: '1',
        created_by: this.userDetails?.id,
        last_modified_by: null,
        created_at: new Date(),
        last_updated_at: null,
        street_address: this.contractor.street_address,
        suburb: this.contractor.suburb,
        postcode: this.contractor.postcode,
        state_id: this.contractor.state_id,
        country_id: this.contractor.country_id,
        depot_desc: this.contractor.business_name,
        contact_name: this.contractor.first_name,
        email: tempMail,
        contact_best: this.contractor.phone,
        contact_alt: null,
        lat: this.depotLat,
        long: this.depotLng,
        location_lat: this.contractor.location_lat,
        location_lng: this.contractor.location_lng,
        depot_short_code: null,
        CompanyName: this.contractor.business_name,
        TypeName: null,
        displayStatus: null,
        rownumber: null,
        company_name: this.contractor.business_name,
        StatusDescription: null,
        deleted: null
      }

      await this.crudService.Create(depot, 'Depot').toPromise();
    }
  }

  public AddressChange(address: any) {
    const fullAddress = [];
    // tslint:disable-next-line: prefer-for-of
    let lat = address.geometry.location.lat();
    let lng = address.geometry.location.lng();
    for (let i = 0; i < address.address_components.length; i++) {
      const addressType = address.address_components[i].types[0];
      if (this.componentForm[addressType]) {

        const val = address.address_components[i][this.componentForm[addressType]];

        if (addressType === 'street_number') {
          fullAddress[0] = val;
        } else if (addressType === 'route') {
          fullAddress[1] = val;
        }

        if (addressType === 'sublocality_level_1') {// suburb
          this.contractor.street_address = val;
        }
        if ((addressType == 'locality' && environment.hosting == 'AU') || (environment.hosting == 'UK' && addressType == 'postal_town')) {// suburb
          this.contractor.suburb = val;
        } else if (addressType === 'administrative_area_level_1') {// state
          this.stateList.forEach((stat) => {
            if (stat.name === val) {
              this.contractor.state_id = stat.id;
              this.selectedState = stat;
            }
          });

        } else if (addressType === 'administrative_area_level_2') {// council
          //                                                $scope.suburb = val;
        } else if (addressType === 'country') {
          this.countryList.forEach((ctry) => {
            if (ctry.name === val) {
              this.contractor.country_id = ctry.id;
              this.selectedCountry = ctry;
            }
          });
        } else if (addressType === 'postal_code') {
          this.contractor.postcode = val;
        }

      }
    }
    const fullAdr = fullAddress.join(' ');
    this.contractor.street_address = fullAdr;
    this.contractor.location_lat = lat;
    this.contractor.location_lng = lng;
    this.depotLat = lat;
    this.depotLng = lng;
    this.loadMap();
    this.selectedState.changed = true;
    this.selectedCountry.changed = true;
  }

  editSelectedRecord(contractor: Contractor) {
    this.contractorForm.markAsUntouched();
    this.isActive = true;
    this.selectedContractor = [];
    this.selectedPaymentTerm = {};
    this.selectedContractorType = {}
    this.selectedAssetVehicleList = [];
    this.selectedCommType = {};
    this.selectedComplianceLevel = {};
    this.selectedCountry = {};
    this.selectedemployeSize = {};
    this.selectedSubType = {};
    this.selectedtreeCrew = {};
    this.selectedState = {};
    this.isDepot = false;

    this.contractor = { ...contractor }
    if (this.contractor.emp_size != null) {
      this.employeSize.forEach((emp) => {
        if (this.contractor.emp_size == emp.id) {
          this.selectedemployeSize = emp;
          this.contractor.emp_size = emp.id;
        }
      })
    }
    /* if (this.contractor.client_comm_type != null) {
      this.communicationTypeList.forEach((commtype) => {
        if (this.contractor.client_comm_type == commtype.id) {
          this.selectedCommType = commtype;
        }
      })
    } */

    // if (this.contractor.compliancelevel_id != null) {
    //   this.complianceLevelList.forEach((complianceLevel) => {
    //     if (this.contractor.compliancelevel_id == complianceLevel.id) {
    //       this.selectedComplianceLevel = complianceLevel;
    //     }
    //   })
    // }

    /* if (this.contractor.client_subtype != null) {
      this.clientSubTypeList.forEach((subType) => {
        if (this.contractor.client_subtype == subType.id) {
          this.selectedSubType = subType;
        }
      })
    } */

    if (this.contractor.no_of_crew != null) {
      this.treeCrew.forEach((crew) => {
        if (this.contractor.no_of_crew == crew.id) {
          this.selectedtreeCrew = crew;
          this.contractor.no_of_crew = crew.id
        }
      })
    }
    if (this.contractor.client_type_id != null) {
      this.clientType.forEach((client) => {
        if (this.contractor.client_type_id == client.id) {
          this.selectedClientType = client;
        }
      })
    }
    this.contractorId = this.contractor.id;
    this.crudService.getByValuesetName('AssetVehicleList').subscribe((res: any[]) => {
      //console.log(res);
      this.AssetVehicleList = res;
    })

    if (this.contractor.payment_terms !== null && this.contractor.payment_terms !== '') {
      this.crudService.getDataByField('ValueSetDetail', 'id', this.contractor.payment_terms).subscribe((data: any[]) => {
        this.selectedPaymentTerm = data[0];
      });
    }
    if (this.contractor.client_subtype !== null && this.contractor.client_subtype !== '') {
      this.crudService.getDataByField('ValueSetDetail', 'id', this.contractor.client_subtype).subscribe((data: any[]) => {
        this.selectedContractorType = data[0];
      });
    }
    if (this.contractor.status === '' || this.contractor.status === null || this.contractor.status === '1') {
      this.contractor.status = '1'
      this.isActive = true;
    } else {
      this.contractor.status = '2'
      this.isActive = false;
    }
    if (this.contractor.default_depot === '1') {
      this.isDepot = true;
    } else {
      this.isDepot = false;
    }

    if (this.contractor.company_inactive_from != null && this.contractor.company_inactive_from !== '') {
      this.contractor.company_inactive_from = new Date(this.contractor.company_inactive_from);
    }
    if (this.contractor.company_inactive_to != null && this.contractor.company_inactive_to !== '') {
      this.contractor.company_inactive_to = new Date(this.contractor.company_inactive_to);
    }
    this.crudService.getDataByField('Country', 'id', this.contractor.country_id).subscribe((data: any[]) => {
      this.selectedCountry = data[0];
    });
    this.crudService.getDataByField('State', 'id', this.contractor.state_id).subscribe((data: any[]) => {
      this.selectedState = data[0];
    });
    this.checkABN(this.contractor.business_ABN);
    this.checkACNO(this.contractor.account_number);
    this.checkBSB(this.contractor.account_BSB);
    this.fetchEmailList(this.contractor.email);
  }
  initTab() {
    const tabContractor = document.getElementById('tab-contractor');
    const tabCompliance = document.getElementById('tab-compliance');
    const tabVehicle = document.getElementById('tab-vehicle');
    const tabContact = document.getElementById('tab-contact');
    const tabDepot = document.getElementById('tab-depot');
    const tabStaff = document.getElementById('tab-staff');
    const tabJob = document.getElementById('tab-job');
    const tabSetting = document.getElementById('tab-setting');
    const tabPayments = document.getElementById('tab-payments');
    const tabNotes = document.getElementById('tab-notes');

    let contractor = document.getElementsByClassName('contractor-info-tab-activate');
    let contact = document.getElementsByClassName('contact-info-tab-activate');
    let compliance = document.getElementsByClassName('compliance-info-tab-activate');
    let vehicle = document.getElementsByClassName('vehicle-info-tab-activate');
    let depot = document.getElementsByClassName('depot-info-tab-activate');
    let staff = document.getElementsByClassName('staff-info-tab-activate');
    let job = document.getElementsByClassName('job-info-tab-activate');
    let setting = document.getElementsByClassName('setting-info-tab-activate');
    let payments = document.getElementsByClassName('payments-info-tab-activate');
    let notes = document.getElementsByClassName('notes-info-tab-activate');
    let outerContainer: any = document.getElementsByClassName('outer-container');

    function initializeTabs(config) {

      let l1 = [tabContractor, tabContact, tabCompliance, tabVehicle, tabDepot, tabStaff, tabJob, tabSetting, tabPayments, tabNotes];
      let l2 = [contractor, contact, compliance, vehicle, depot, staff, job, setting, payments, notes];

      config.forEach(c => {
        document.getElementById(c.id).addEventListener('click', () => {
          l1.forEach((ele) => {
            ele?.classList?.remove('active');
          });
          c.activeTab?.classList?.add('active');

          l2.forEach((ele) => {
            if (ele && ele.length > 0) {
              for (let i = 0; i < ele.length; i++) {
                ele[i]?.classList?.add('hideme');
              }
            }
          });
          if (c.activeContent && c.activeContent.length > 0) {
            for (let i = 0; i < c.activeContent.length; i++) {
              c.activeContent[i]?.classList?.remove('hideme');
            }
          }
        });
      })
    }
    this.tagConfig = [{
      id: 'tab-contractor',
      activeTab: tabContractor,
      activeContent: contractor,
      general: true
    }, {
      id: 'tab-contact',
      activeTab: tabContact,
      activeContent: contact
    }, {
      id: 'tab-compliance',
      activeTab: tabCompliance,
      activeContent: compliance
    }, {
      id: 'tab-vehicle',
      activeTab: tabVehicle,
      activeContent: vehicle
    }, {
      id: 'tab-depot',
      activeTab: tabDepot,
      activeContent: depot,
      content: 'showDepot'
    }, {
      id: 'tab-staff',
      activeTab: tabStaff,
      activeContent: staff,
      content: 'showStaff'
    }, {
      id: 'tab-job',
      activeTab: tabJob,
      activeContent: job,
      content: 'showJob'
    }, {
      id: 'tab-setting',
      activeTab: tabSetting,
      activeContent: setting,
      content: 'showSetting'
    }, {
      id: 'tab-payments',
      activeTab: tabPayments,
      activeContent: payments
    }, {
      id: 'tab-notes',
      activeTab: tabNotes,
      activeContent: notes
    },];
    initializeTabs(this.tagConfig);
  }

  deleteSelectedContractor() {
    this.confirmationService.confirm({
      message: 'Are you sure you want to delete the selected contractors?',
      header: 'Confirm',
      icon: 'pi pi-exclamation-triangle',
      accept: () => {
        for (let i = 0; i < this.contractors.length; i++) {
          this.crudService.Delete(this.contractors[i].id, 'company').subscribe((data: any) => {
            //console.log(data)
          });
        }
        this.contractors = null;
        this.messageService.add({ severity: 'success', summary: 'Successful', detail: 'Contractor Deleted', life: 1000 });
      }
    });
  }

  viewCompliance(contractor: Contractor) {
    this.contractor = { ...contractor }
    const ref = this.dialogService.open(ComplianceComponent, {
      data: {
        staffId: '',
        company_id: this.contractor.id
      },
      header: 'Supplier Compliance',
      width: '100%',
      height: '100%'
    });
  }

  viewContact(contractor: Contractor) {
    this.contractor = { ...contractor }
    const ref = this.dialogService.open(ContactComponent, {
      data: {
        id: this.contractor.id
      },
      header: 'Supplier Contacts',
      width: '100%',
      height: '100%'
    });
  }

  exportPdf() {
    import('jspdf').then(jsPDF => {
      import('jspdf-autotable').then(x => {
        /* const doc = new jsPDF.default(0, 0);
        if (typeof this.selectedContractor == 'undefined') {
          doc.autoTable(this.exportColumns, this.contractors);
        } else {
          doc.autoTable(this.exportColumns, this.selectedContractor);
        }
        doc.save('Contractors.pdf'); */
      })
    })
  }


  updateTab(v, videoUrl, pdfUrl) {
    this.loadtab();
    this.showWelcome = false;
    this.videoUrl = this.sanitizer.bypassSecurityTrustResourceUrl(videoUrl);
    this.pdfUrl = this.sanitizer.bypassSecurityTrustResourceUrl(pdfUrl);

    this.tagConfig?.forEach(c => {
      if (c.content && c.id === v) {
        this[c.content] = true;
      }
    });
    this.activeTab = v;
    this.wizardMetaData.forEach(w => {
      w.class = w.id == v ? 'active' : '';
    })
    if (v === 'tab-sla') {
      this.loading = false;
    }
    if (v === 'tab-tmapp') {

    }
  }



  saveUser() {

    let mail = this.contractor.email.toString().split(',');
    let tempMail =mail[0] ;
      
    this.disablejobSts=true;

    this.staff.id = null;
    this.staff.first_name = this.contractor.first_name;
    this.staff.last_name = this.contractor.last_name;
    this.staff.phone = this.contractor.phone;
    this.staff.company_id = this.contractor.id;
    this.staff.mobile = this.contractor.mobile_no;
    this.staff.email = tempMail;
    this.staff.role = 10021;
    this.staff.stafftype = 10038;
    this.staff.induction = null;
    this.staff.client_type_id = null;
    this.staff.inducted_date = null;
    this.staff.username = this.contractor.user_name;
    this.staff.password = this.contractor.pass_key;
    this.staff.create_login = '1';
    this.staff.process_id = 1,
      this.staff.user_role_id = 1;
    this.staff.company_inactive_from = null;
    this.staff.company_inactive_to = null;
    this.staff.created_at = new Date().toDateString();
    this.staff.created_by = 1;
    this.staff.deleted = null;
    this.staff.encrypted_password = this.encryptService.encryptionAES(this.contractor.pass_key);
    this.crudService.Create(this.staff, 'CompanyStaffInfo').subscribe((CompanyStaffInfoData: any) => {
      console.log("CompanyStaffInfoData", CompanyStaffInfoData);
      if (CompanyStaffInfoData.success === 1) {
        this.staff.id = CompanyStaffInfoData.id;

        this.user.company_id = this.contractor.id;
        this.user.dob = null;
        this.user.person_id = null;
        this.user.status = '1';
        this.user.owner = '1';
        this.user.created_at = new Date();
        this.user.position_id = null;
        this.user.invalid_passwd_count = null;
        this.user.title = null;
        this.user.mobile = this.contractor.mobile_no;
        this.user.alternate_phone = this.contractor.phone;
        this.user.work_phone = this.contractor.phone;
        this.user.user_locked = '0';
        this.user.user_group_id = 1;
        this.user.created_at = new Date().toDateString();
        this.user.created_by = 1;
        this.user.menu_colour = 'layout-menu-dark';
        this.user.layout_colour = 'blue';
        this.user.colour_scheme = 'light';
        this.user.colour = 'green';
        this.user.menu_type = '1';
        this.user.homepage_url = '/home';
        this.user.first_name = this.contractor.first_name;
        this.user.last_name = this.contractor.last_name;
        this.user.email = tempMail;
        this.user.staff_id = this.staff.id;
        this.user.login_attempt = '0';
        this.user.process_id = this.contractor.id;
        this.user.last_updated_at = new Date().toDateString();
        this.user.last_modified_by = null;
        this.user.username = this.contractor.user_name;
        this.user.password = this.contractor.pass_key;
        this.user.encrypted_password = this.encryptService.encryptionAES(this.contractor.pass_key);
        this.user.deleted = null;
        this.crudService.Create(this.user, 'User').subscribe(async (UserData: any) => {
          //console.log(UserData);
          let encrypted = this.EncrDecr.set('123456$#@$^@1ERF', 'PID_' + this.contractor.process_id + '_COMP_ID_' + this.contractor.id);
          let decrypted = this.EncrDecr.get('123456$#@$^@1ERF', decodeURIComponent(encrypted));
          encrypted = encodeURIComponent(encrypted)
          //console.log('encrypted : ' + encrypted);
          //console.log('encrypted : ' + decrypted);
          let data: any = await this.crudService.updateCompanyToken(this.contractor.id, encrypted).toPromise();
          this.crudService.confirmRegistration(this.contractor.id, this.contractor.email).subscribe(async (mailData: any) => {
            //console.log(mailData);
            if (mailData.success === 200 || mailData.success === '200') {
              // if(this.env.hosting =='AU'){
              //   await this.send_SPA_MAil()
              //   await this.sendOTP()
              // }
              this.messageService.add({ severity: 'success', summary: 'Successful', detail: 'Your registration is successful. Please check your mailbox for next steps.', life: 2000 });
              this.disablejobSts=false;
              /* setTimeout(() => {
                this.router.navigate(['/login']);
              }, 2000); */
            } else {
              this.messageService.add({ severity: 'error', summary: 'Error', detail: 'Registration Unsuccessful.', life: 1000 });
            }
          });
        });
      }
    });
  }
  async send_SPA_MAil(){
    this.sendlinkvia = false  // by default it will send only to mail
    let encrypted = this.EncrDecr.encrypt(  this.staff.company_id +' '+this.staff.id +' '+this.sendlinkvia ,'123456$#@$^@1ERF',);
    this.emailSubject = 'Service Provider Agreement(SPA) Access URL'
    this.spa_access_Url = this.env.base_url+'business/SPA/'+ encrypted 
    console.log("this.spa_access_Url",this.spa_access_Url);
      await this.tinyUrl.shorten(this.spa_access_Url).subscribe(async res => {
      this.spa_access_Url = res;
      console.log("this.spa_access_Url",this.spa_access_Url);
      if(res){
        this.emailContent = 'Hi ' + this.staff.first_name?.trim() + ' , <br/><br/>Please sign Service Provider Agreement by clicking the below link<br/> '+ '<body> <a href="'+this.spa_access_Url +'">' + this.spa_access_Url + '</a></body><br/>'
        let temp_type = 'sms-email';
        let smsData  = ''
        // this.templ = {};
        // this.templ.template = [{ email: {} }];
        // this.templ.template[0].email= this.staff.email
        let dataObj = {
          sms: smsData,
          email: this.staff.email,
          type: temp_type,
          selectedTpl: 'd-fa99d348216144cfb8d39f2a67111474',
          sufix: null,
          jobData: null,
          attachments: null,
          account: this.env.hosting,
        }
        await this.storeInSchedule(dataObj);
      }
      console.log( this.spa_access_Url); 
    });
  }
  storeInSchedule(obj) {
    const datobj2 = {
      subject : this. emailSubject,
      body : this.emailContent,
      toEmail : obj.email,
      ref_id:this.staff.id,
      ref_type:'accounts'
      }
      if(this.sendlinkvia == false){
        this.crudService.sendmailSendgrid(datobj2,'commonMail').subscribe((res2: any) => { 
          this.loading = false;
          if(res2.success == 200)
          {
             this.messageService.add({ severity: 'success', summary: 'Successful', detail: 'Email Sent', life: 3000 });
          }
          else
          {
            let message = res2.Data.response.body.errors[0].message;
            this.messageService.add({ severity: 'error', summary: 'Error', detail: message, life: 3000 });
          }

         });
      }
      else if(this.sendlinkvia == true){
        let params = {
           sms: {
            to: this.staff.mobile,
            message: 'Hi '+ this.staff.first_name?.trim() + ', Please sign SPA by clicking ' + this.spa_access_Url ,
           subject: this. emailSubject,
          },   type: 'sms-email',
          selectedTpl: null,
          sufix: null,
        }
        this.crudService.sendTMV2Message(params).subscribe((res2: any) => {
            // this.visible = false;
            this.messageService.add({ severity: 'success', summary: 'Successful', detail: 'SMS Sent ', life: 1000 });
        });
      }
   
  }
  async sendOTP(){
    this.sendlinkvia = false // by default it will send only to mail
    let otp_datas:any  = await this.crudService.getDataByField('StaffOTPTransact', 'staff_id', this.staff.id).toPromise();
    console.log("otp_datas",otp_datas);
    let filtered_otp = otp_datas.filter(val => (val.status == 0 && val.page == 1));
    filtered_otp?.forEach( val => {
      val.status= 2
      let ress: any =  this.commonservice.saveRecord('StaffOTPTransact', val);
    })

  var random_OTP : any = Math.floor(100000 + Math.random() * 900000);
  random_OTP = random_OTP.toString().substring(0, 4);
  random_OTP =  parseInt(random_OTP);
  console.log("random_OTP",random_OTP);
  let otp_details ={
    id : null,
    company_id : this.staff.company_id,
    staff_id : this.staff.id,
    otp :random_OTP,
    status  :0,
    email_to :null,
    phone_to  :null,
    page : 1,  // page = 1 for Spa
    created_at  :  new Date(), 
    created_by : this.staff.id,
  }
  if(this.sendlinkvia == false){
    otp_details.email_to = this.staff.email
  }
  else{
    otp_details.phone_to = this.staff.mobile
  }
  let res: any = await this.commonservice.saveRecord('StaffOTPTransact', otp_details);
  console.log("Res",res);

  let params = {
    email: {
      to: this.staff.email, cc: '', bcc: null,
      msg: 'Your OTP (One Time Password) for access SPA is ' + '<h4>'+random_OTP+'</h4>'
      + 'Please use this password to access and sign the document. Do not share this password with anyone',
      subject: 'Verification Code To Access SPA ',
    },    type: 'sms-email',
    selectedTpl: null,
    sufix: null,
  }
  if(this.sendlinkvia == false){
    this.sendOTPMail(params);
  }
  else if(this.sendlinkvia == true){
    let params = {
      sms: {
        to: this.staff.mobile,
        message: 'Your OTP (One Time Password) for access SPA is '+ random_OTP
        + '. Please use this password to access and sign the document. Do not share this password with anyone',
      subject:'Verification Code To Access SPA',
      },   type: 'sms-email',
      selectedTpl: null,
      sufix: null,
    }

    this.crudService.sendTMV2Message(params).subscribe((res2: any[]) => {
      console.log(res2);
        // this.visible = false;
        this.messageService.add({ severity: 'success', summary: 'Successful', detail: 'OTP SMS Sent ', life: 1000 });
    });
    }
  }
sendOTPMail(obj) {
const datobj2 = {
  subject :obj.email.subject,
  body : obj.email.msg,
  toEmail : obj.email.to,
  ref_id:this.staff.id,
  ref_type:'accounts'
  }
  this.crudService.sendmailSendgrid(datobj2,'commonMail').subscribe((res2: any) => { 
  this.loading = false;
  if(res2.success == 200)
  {
    // this.visible = false;
    this.messageService.add({ severity: 'success', summary: 'Successful', detail: 'OTP Email Sent', life: 3000 });
  }
  else
  {
    let message = res2.Data.response.body.errors[0].message;
    this.messageService.add({ severity: 'error', summary: 'Error', detail: message, life: 3000 });
  }
 });
}
  
  showDialog() {
    // this.visible = true;
    //  this.sendlinkvia = undefined
    // this.btnvisible =false
  }
  async updateAllCompanies() {
    //this.commonservice.updateCompaniesToken();
  }


  displayStepWriteup() {
    this.confirmationService.confirm({
      message: 'To Complete Registration, you are required to fill in the details in the current Screen. <br>Upon submission, you will receive a verification mail to the Email Id Provided. <br> Once the Email Id is verified, you will be able to proceed with other steps of On-Boarding process.',
      header: 'Note!',
      icon: 'pi pi-exclamation-triangle',
      acceptLabel: 'OK',
      acceptVisible: false,
      rejectVisible: false,
      accept: () => {
      }
    });
  }
  isValidWebsite(value: any) {
    //console.log(value);
    if (value !== '' && value !== undefined && value !== null) {
      var res = value.match(/(http(s)?:\/\/.)?(www\.)?[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/g);
      //console.log(res)
      if (res == null) {
        this.websiteFlag = false;
        return false;
      }
      else {
        this.websiteFlag = true;
        return true;
      }
    } else {
      this.websiteFlag = true;
    }
  }


  async updateRegnStatus() {
    this.selectedCompany.regn_status = '1';
    let res: any = await this.commonservice.saveRecord('Company', this.selectedCompany);
    this.displayRegnModal = false;
  }



  checkABN(value) {
    if (value != null && value != '') {
      value = value.replaceAll(' ', '');
      value = value.replaceAll('_', '');
      value = value.replaceAll('-', '');
      this.contractor.business_ABN = value;
      if (this.env.hosting == 'AU') {
        if (this.contractor.business_ABN.length == 11) {
          this.isABNInvalid = false;
        } else {
          this.isABNInvalid = true;
        }
      }
      else if (this.env.hosting == 'UK') {
        if (this.contractor.business_ABN.length >= 6 && this.contractor.business_ABN.length <= 12) {
          this.isABNInvalid = false;
        } else {
          this.isABNInvalid = true;
        }
      }

    }
  }

  checkCn(value) {
    if (value.length >= 6 && value.length <= 12) {
      this.isABNInvalid = false;
    } else {
      this.isABNInvalid = true;
    }
  }


  onEdit() {
    window.scrollTo(0, 0);
  }


  isACNOInvalid: Boolean = false;

  checkACNO(value) {
    this.isACNOInvalid = false;
    /* if (value != null && value != '') {
      value = value.replaceAll(' ', '');
      value = value.replaceAll('_', '');
      value = value.replaceAll('-', '');
      this.contractor.account_number = value;
      if (this.contractor.account_number.length == 15) {
        this.isACNOInvalid = false;
      } else {
        this.isACNOInvalid = true;
      }
    } */
  }
  isBSBInvalid: Boolean = false;

  checkBSB(value) {
    if (value !== '' && value !== null) {
      value = value.replaceAll(' ', '');
      value = value.replaceAll('_', '');
      value = value.replaceAll('-', '');
      this.contractor.account_BSB = value;
      if (this.contractor.account_BSB.length == 6) {
        this.isBSBInvalid = false;
      } else {
        this.isBSBInvalid = true;
      }
    }
  }

  approveTP() {
    this.loading = true;
    this.confirmationService.confirm({
      message: 'Are you sure you want to Approve ' + this.contractor.trading_name + '?',
      accept: async () => {
        this.commonservice.approveTP(this.contractor);
        this.loading = false;
        this.dialofRef.close();
      }
    })
  }
  async rejectTP() {
    this.loading = true;
    if (this.reject_reason != null && this.reject_reason !== '') {
      this.commonservice.rejectTP(this.contractor, this.reject_reason);
      this.loading = false;
      this.dialofRef.close();
    }
  }

  fetchEmailList(emails) {
    if (emails.includes(',')) {
      this.contactEmailIds = emails.split(',');
    } else if (emails.includes(';')) {
      this.contactEmailIds = emails.split(';');
    } else {
      this.contactEmailIds.push(emails);
    }
  }

  chooseAddress(event) {
    this.inputAddress = event.summaryline;
    this.contractor.suburb = event.posttown
    if (event.addressline2 != undefined) {
      this.contractor.street_address = event.addressline1 + ',' + event.addressline2;
    }
    else {
      this.contractor.street_address = event.addressline1;
    }
    this.contractor.postcode = event.postcode;
    this.contractor.location_lat = event.latitude;
    this.contractor.location_lng = event.longitude;
    this.depotLat = event.latitude;
    this.depotLng = event.longitude;
    this.stateList.forEach((stat) => {
      if (stat.id === '111') {
        this.contractor.state_id = stat.id;
        this.selectedState = stat;
      }
    });
    this.countryList.forEach((ctry) => {
      if (ctry.id === '222') {
        this.contractor.country_id = ctry.id;
        this.selectedCountry = ctry;
      }
    });
    this.loadMap();
  }

  search(event) {
    this.crudService.getPostcoderAddress(event.query).subscribe((res: any) => {
      this.filteredPlaces = res.filter((val: any) => val.summaryline.toLowerCase().includes(event.query.toLowerCase()));
    });
  }

  checkValue(eve){
    this.contractor.vat_avail = eve.checked;
  }
}