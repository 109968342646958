import { RouterModule } from '@angular/router';
import { NgModule } from '@angular/core';

import { DocumentationComponent } from './demo/view/documentation.component';
import { AppMainComponent } from './app.main.component';
import { AppNotfoundComponent } from './pages/app.notfound.component';
import { AppErrorComponent } from './pages/app.error.component';
import { AppAccessdeniedComponent } from './pages/app.accessdenied.component';
import { AppLoginComponent } from './pages/app.login.component';
import { AppCalendarComponent } from './pages/app.calendar.component';
import { DashboardComponent } from '././modules/dashboard/dashboard.component';
import { SchedulerComponent } from '././modules/scheduler/scheduler.component';
import { TableModule } from 'primeng/table';
import { PrivacyPolicyComponent } from '././privacypolicy.component';
import { TermsOfUseComponent } from '././termsofuse.component';
import 'jspdf-autotable';
import { ChangePasswordComponent } from '././modules/change-password/change-password.component';
import { UsersComponent } from './modules/users/users.component';
// import { ContactComponent } from './admin/contact/contact.component';
import { ComplianceComponent } from './admin/compliance/compliance.component';
import { ImageMarkingComponent } from './modules/image-marking/image-marking.component';
import { TpComplianceComponent } from './modules/tp-compliance/tp-compliance.component';
import { EmailConfigComponent } from './modules/email-config/email-config.component';
import { SendEmailComponent } from './modules/send-email/send-email.component';
import { JobsListComponent } from './modules/jobs-list/jobs-list.component';
import { RegisterComponent } from './modules/register/register.component';
import { from } from 'rxjs';
import { TreeInfoComponent } from './modules/tree-info/tree-info.component';
import { DocumentComponent } from './modules/document/document.component';
import { DocumentListComponent } from './modules/document-list/document-list.component';
import { TaskBoardComponent } from './modules/task-board/task-board.component';
import { JobUserComponent } from './modules/job-user/job-user.component';
import { TaskListComponent } from './modules/task-list/task-list.component';
import { TaskDetailsComponent } from './modules/task-details/task-details.component';
import { SSPHomepageComponent } from './modules/ssp-homepage/ssp-homepage.component';
import { WorkflowComponent } from './modules/workflow/workflow.component';
import { TaskCheckListComponent } from './modules/task-checklist/task-checklist.component';
import { ResetPasswordComponent } from './reset-password/reset-password.component';
import { SignUpComponent } from './sign-up/sign-up.component';
import { RequestResetComponent } from './request-reset/request-reset.component';
import { AppMailComponent } from './modules/app-mail/app-mail.component';
import { MenuAccessComponent } from './modules/menu-access/menu-access.component';
import { AllUsersComponent } from './modules/all-users/all-users.component';
import { RegistrationComponent } from './modules/registration/registration.component';
import { CompanyComplianceComponent } from './modules/company-compliance/company-compliance.component';
import { TPRegistrationComponent } from './modules/tp-registration/tp-registration.component';
import { RateCardReportComponent } from './modules/ratecard-report/ratecard-report.component';
import { AssignRateSetComponent } from './modules/assign-rateset/assign-rateset.component';
import { ContractorListingComponent } from './modules/contractor-listing/contractor-listing.component';
import { TpCompanyComplianceComponent } from './modules/tp-company-compliance/tp-company-compliance.component';
import { WorkflowDefinitionComponent } from '././modules/workflow-definition/workflow-definition.component';

import { StaffTrainingComponent } from './modules/staff-training/staff-training.component';
import { TrainingListComponent } from './modules/training-list/training-list.component';
import { ChatbotComponent } from './chat/chatbot/chatbot.component';
import { AuthreditectComponent } from './authreditect/authreditect.component';
import { TaskUpdateComponent } from './modules/task-update/task-update.component';
import { AuthGuard } 
from '../app/services/auth.guard';
import { MailDocumentComponent } from './job/mail-document/mail-document.component';
import { StaffPublicComponent } from './modules/staff-public/staff-public.component';

@NgModule({
    imports: [
        TableModule,
        RouterModule.forRoot([
            {
                path: '', component: AppMainComponent,
                children: [
                    {
                        path: '',
                        loadChildren: () => import('./modules/dashboards/dashboards.module').then((m) => m.DashboardsModule),canActivate: [AuthGuard],
                    },
                    {
                        path: 'home',
                        loadChildren: () => import('./modules/dashboards/dashboards.module').then((m) => m.DashboardsModule),canActivate: [AuthGuard],
                    },
                    {
                        path: 'compliance',
                        loadChildren: () => import('./compliance/compliance.module').then((m) => m.ComplianceModule),canActivate: [AuthGuard],
                    },
                    {
                        path: 'setup',
                        loadChildren: () => import('./setup/setup.module').then((m) => m.SetupModule),canActivate: [AuthGuard],
                    },
                    {
                        path: 'job-setup',
                        loadChildren: () => import('./job-setup/job.module').then((m) => m.JobSetupModule),canActivate: [AuthGuard],
                    },
                    {
                        path: 'admin',
                        loadChildren: () => import('./admin/admin.module').then((m) => m.AdminModule),canActivate: [AuthGuard],
                    },
                    {
                        path: 'tp',
                        loadChildren: () => import('./tp/tp.module').then((m) => m.TpModule),canActivate: [AuthGuard],
                    },
                    {
                        path: 'reports',
                        loadChildren: () => import('./reports/reports.module').then((m) => m.ReportsModule),canActivate: [AuthGuard],
                    },
                    {
                        path: 'tp-reports',
                        loadChildren: () => import('./tp-reports/tp-reports.module').then((m) => m.TpReportsModule),canActivate: [AuthGuard],
                    },
                    {
                        path: 'accounts',
                        loadChildren: () => import('./accounts/accounts.module').then((m) => m.AccountsModule),canActivate: [AuthGuard],
                    },
                    {
                        path: 'job',
                        loadChildren: () => import('./job/job.module').then((m) => m.JobModule),canActivate: [AuthGuard],
                    },
                    {
                        path: 'tp-job',
                        loadChildren: () => import('./tp-job/tp-job.module').then((m) => m.TpJobModule),canActivate: [AuthGuard],
                    },
                    {
                        path: 'analytics',
                        loadChildren: () => import('./analytics/analytics.module').then((m) => m.AnalyticsModule),canActivate: [AuthGuard],
                    },
                   
                    { path: 'pages/calendar', component: AppCalendarComponent,canActivate: [AuthGuard] },
                    { path: 'documentation', component: DocumentationComponent,canActivate: [AuthGuard] },
                    { path: 'scheduler', component: SchedulerComponent ,canActivate: [AuthGuard]},
                    { path: 'changePassword', component: ChangePasswordComponent,canActivate: [AuthGuard] },
                    { path: 'users', component: UsersComponent,canActivate: [AuthGuard] },
                    // { path: 'contact', component: ContactComponent },
                    { path: 'compliance', component: ComplianceComponent,canActivate: [AuthGuard] },
                    { path: 'image-marking', component: ImageMarkingComponent,canActivate: [AuthGuard] },
                    { path: 'tp-compliance', component: CompanyComplianceComponent ,canActivate: [AuthGuard]},
                    { path: 'tp-compliance-details', component: TpCompanyComplianceComponent,canActivate: [AuthGuard] },
                    { path: 'email-config', component: EmailConfigComponent,canActivate: [AuthGuard]},
                    { path: 'send-email', component: SendEmailComponent ,canActivate: [AuthGuard]},
                    { path: 'job-user/:jobId', component: JobUserComponent ,canActivate: [AuthGuard]},
                    { path: 'path-info', component: TreeInfoComponent ,canActivate: [AuthGuard]},
                    { path: 'task-board', component: TaskBoardComponent,canActivate: [AuthGuard] },
                    { path: 'document', component: DocumentComponent ,canActivate: [AuthGuard]},
                    { path: 'document-list', component: DocumentListComponent ,canActivate: [AuthGuard]},
                    { path: 'allincidents', component: TaskListComponent ,canActivate: [AuthGuard]},
                    { path: 'jobsincidents', component: TaskListComponent,canActivate: [AuthGuard] },
                    { path: 'support', component: TaskListComponent ,canActivate: [AuthGuard]},
                    { path: 'myincidents', component: TaskListComponent,canActivate: [AuthGuard] },
                    { path: 'incident', component: TaskDetailsComponent ,canActivate: [AuthGuard]},
                    { path: 'ssp-homepage', component: SSPHomepageComponent ,canActivate: [AuthGuard]},
                    { path: 'workflow', component: WorkflowComponent ,canActivate: [AuthGuard]},
                    { path: 'checklist', component: TaskCheckListComponent,canActivate: [AuthGuard] },
                    { path: 'mail', component: AppMailComponent ,canActivate: [AuthGuard]},
                    { path: 'menu-access', component: MenuAccessComponent ,canActivate: [AuthGuard]},
                    { path: 'all-users', component: AllUsersComponent,canActivate: [AuthGuard] },
                    { path: 'rate-card', component: RateCardReportComponent ,canActivate: [AuthGuard]},
                    { path: 'assign-rateset', component: AssignRateSetComponent,canActivate: [AuthGuard] },
                    { path: 'contractors-list', component: ContractorListingComponent,canActivate: [AuthGuard] },
                    { path: 'jobs-list/:bucket/:catg', component: JobsListComponent,canActivate: [AuthGuard] },
                    { path: 'business-setup', component: TPRegistrationComponent ,canActivate: [AuthGuard]},
                    { path: 'workflow-definition', component: WorkflowDefinitionComponent ,canActivate: [AuthGuard]},
                    { path: 'tp-company-compliance-details', component: TpCompanyComplianceComponent ,canActivate: [AuthGuard]},
                    { path: 'staff-training', component: StaffTrainingComponent ,canActivate: [AuthGuard]},
                    { path: 'training-list', component: TrainingListComponent,canActivate: [AuthGuard] },
                    { path: 'task-update', component: TaskUpdateComponent ,canActivate: [AuthGuard]},
                ]
            },
            {
                path: 'channel',
                loadChildren: () => import('./channel/channel.module').then((m) => m.ChannelModule),canActivate: [AuthGuard]
            },
            { path: 'error', component: AppErrorComponent ,canActivate: [AuthGuard]},
            { path: 'accessdenied', component: AppAccessdeniedComponent ,canActivate: [AuthGuard]},
            { path: 'notfound', component: AppNotfoundComponent, canActivate: [AuthGuard] },
            { path: 'login', component: AppLoginComponent },
            { path: 'register', component: TPRegistrationComponent },
            { path: 'tpcompliance/:token', component: CompanyComplianceComponent , canActivate: [AuthGuard]},
            { path: 'registration/:token', component: TPRegistrationComponent },
            { path: 'clientdetails/:accesstoken', component: MailDocumentComponent},
            { path: 'business/:page/:com_staff_id', component: StaffPublicComponent},
            { path: 'reset-password/:token', component: ResetPasswordComponent },
            { path: 'request-reset', component: RequestResetComponent, canActivate: [AuthGuard] },
            { path: 'dashboard', component: AppMainComponent, canActivate: [AuthGuard] },
            { path: 'privacypolicy', component: PrivacyPolicyComponent, canActivate: [AuthGuard] },
            { path: 'termsofuse', component: TermsOfUseComponent, canActivate: [AuthGuard] },
            { path: 'chat/:id', component: ChatbotComponent, canActivate: [AuthGuard] },
            { path: 'authredirect/:type/:token', component: AuthreditectComponent, canActivate: [AuthGuard] },
            { path: '**', redirectTo: '/notfound'},
        ], { onSameUrlNavigation: 'reload', scrollPositionRestoration: 'enabled' })
    ],
    exports: [RouterModule]
})
export class AppRoutingModule {
}
