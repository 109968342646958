import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { ActivatedRoute } from '@angular/router';
import { ConfirmationService, MessageService } from 'primeng/api';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { CRUDService } from 'src/app/utilities/crud.service';
import { JOBService } from 'src/app/utilities/job.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-wp-costing',
  templateUrl: './wp-costing.component.html',
  styleUrls: ['./wp-costing.component.scss']
})
export class WpCostingComponent implements OnInit {
  loading: boolean = true
  @Input() jobId: any;
  @Input() jobAllocationId: any
  @Input() jobAllocationResults: any;
  @Input() jobSummary: any;
  @Output() refereshFlag: EventEmitter<any> = new EventEmitter();
  dateFormat: any;
  wpQuoteList: any = [];
  itemsList: any = [];
  tpItemsList: any = [];
  companyId: any;
  allowEditing: Boolean = true;
  selectedItem: any;
  date: any;
  wpQuote: any;
  selectedRecords: any[];
  userDetails: any;
  statusDesc: any;
  costFormHead: any;
  taxrate: number;
  invoiceDate: any;
  rateSetList: any[];
  selectedRateSet: any;
  rateClassList: any[];
  tpRateClassList: any[];
  selectedRateClass: any;
  wp_sub_total: number = 0;
  tp_grand_total: any = 0;
  crewList: any[];
  selectedCrew: any;
  additionalDamageInfo: any;
  tp_sub_total: number;
  wp_tax_total: any;
  wp_grand_total: any;
  margin: any;
  cost_items: any[] = [];
  SESRemoveDebris: Boolean;
  SESEWPInvolved: Boolean;
  prefillDisable: Boolean = false;
  pmSubstantiation: any = '';
  rebate: any;
  standingTree: any;
  InscleanUpMakeSafe: any;
  InscleanUpBasic: any;
  notesInsuranceBasic: any;
  InsPoorAccessExtraNote: any;
  BaseText: any;
  dismantleText: any;
  SESCleanUp: any;
  SESEWPNotes: any;
  SESnotesBasic: any;
  SESMakeSafe: any;
  TreeData: any;
  includesStump: any;
  extrasAvailable: any;
  costEstimate: any;
  costMatrix: any;
  costEstimateDisplay: any;
  TPEstimateDisplay: any;
  deviation: any;
  isEdit: any;
  newDetails: any[] = [];
  newWpQuoteList: any[] = [];
  getDataFlag: boolean = false;
  consWpQuoteList: any[] = [];
  selectAll: any;
  rebatePerc: any;
  showHeader: Boolean = false;
  isAdmin: Boolean = false;
  adminUser: any[] = ['1', '68', '62', '608'];//['1', '68','62','608'];
  isEditingAllowed: Boolean = false;
  invoicedStages: any[] = ['Invoice Processed', 'Account Processed'];
  minThreshold: any;
  maxThreshold: any;
  WPCompanyId: any;
  env: any;
  req_error: boolean = false;
  req_error1: boolean;
  displayException: Boolean = false;
  exceptionMaster: any;
  selectedException: any = [];
  exceptionComment: any;
  exceptionError: Boolean = false;
  jobReqAction: string;
  disableSave: Boolean = false;
  pmSubstantiationtemp: any;

  constructor(
    private CrudService: CRUDService,
    private messageService: MessageService,
    private confirmationService: ConfirmationService,
    public ref: DynamicDialogRef,
    public config: DynamicDialogConfig,
    public sanitizer: DomSanitizer,
    private route: ActivatedRoute,
    public JobService: JOBService) {
    this.companyId = sessionStorage.getItem('company_id');
    this.userDetails = sessionStorage.getItem('loggedInUser');
  }

  async ngOnInit() {
    this.env = environment;
    this.dateFormat = environment.DateFormat;
    let data: any = await this.CrudService.getDataByField('businessSettings', 'company_id', '108').toPromise();
    this.taxrate = data[0].TaxPerc;

    let exceptionMaster: any = await this.CrudService.getByValuesetName('CostExceptions').toPromise();
    this.exceptionMaster = exceptionMaster;
    console.log("exceptionMaster", this.exceptionMaster);
    if (typeof this.jobId === 'undefined') {
      if (typeof this.config.data == 'undefined' || typeof (this.config.data.jobId) == 'undefined') {
        if (this.jobId == null || this.jobId === '') {
          this.jobId = parseInt(this.route.snapshot.paramMap.get('jobId'));
          if (this.jobAllocationId === null || this.jobAllocationId === '') {
            this.jobAllocationId = '1';
          } else {
            this.jobAllocationId = parseInt(this.route.snapshot.paramMap.get('jobAllocationId'));
          }
        }
        let smryData: any = await this.CrudService.getJobSummary(this.jobId, this.jobAllocationId).toPromise();
        this.jobAllocationResults = await this.JobService.getJobInfo(smryData[0]);
        this.showHeader = false;
      } else {
        this.jobId = this.config.data.jobId;
        this.jobAllocationId = this.config.data.jobAllocationId;
        this.jobAllocationResults = this.config.data.jobSummary;
        this.jobSummary = this.config.data.jobSummary;
        this.showHeader = true;
      }
    }
    this.CrudService.getByValuesetName('Margin_Threshold').subscribe((data: any[]) => {
      let minData = data.find(val => (val.valueset_detail_name == 'min_threshold'));
      this.minThreshold = minData?.valueset_detail_desc;
      let maxData = data.find(val => (val.valueset_detail_name == 'max_threshold'));
      this.maxThreshold = maxData?.valueset_detail_desc;
    });

    let jobData = await this.CrudService.getDataByField('jobInfo', 'id', this.jobAllocationResults.id).toPromise();
    if (jobData[0].job_req_action_id == "0") {
      this.jobReqAction = "";
    }
    else {
      let reqActionData = await this.CrudService.getDataByField('JobActions', 'id', jobData[0].job_req_action_id).toPromise();
      this.jobReqAction = reqActionData[0].job_action_name;
    }


    let treeInfo: any = await this.CrudService.getWorkOrderInfo(this.jobId, this.jobAllocationId).toPromise();
    if (treeInfo.length === 0) {
      this.allowEditing = false;
    } else {
      this.allowEditing = true;
    }
    let index = this.adminUser.findIndex(val => (val === this.userDetails.id));
    if (index > -1) {
      this.isAdmin = true;
    }
    let index2 = this.invoicedStages.findIndex(val => (val === this.jobAllocationResults.JobStatus));
    if (index2 > -1) {
      this.isEditingAllowed = this.isAdmin;
    } else {
      this.isEditingAllowed = true;
    }

    await this.loadData();
  }

  async loadData() {
    this.CrudService.getAllRateSetsByCompanyId(this.companyId).subscribe((rateSetData: any[]) => {
      this.rateSetList = rateSetData;
      //console.log(this.rateSetList);
      this.date = new Date();

      this.CrudService.getAllItemDefn().subscribe((data: any[]) => {

        for (let itemDefn of data) {
          let costItem = {
            id: itemDefn.id,
            item: itemDefn.item_name,
            desc: itemDefn.item_desc,
            price: 1,
            itemTypeId: itemDefn.item_type_id,
            defaultRate: itemDefn.default_rate,
            status: itemDefn.status,
            FixedRate: itemDefn.FixedRate
          }
          this.cost_items.push(costItem);
        }


        this.CrudService.getCostFormHeadByJobIdAllocId(this.jobId, this.jobAllocationId).subscribe(async (data: any[]) => {
          if (data.length > 0) {
            this.costFormHead = data[0];
            this.exceptionComment = this.costFormHead.exceptionComment;
            this.costFormHead.Upload_by = this.costFormHead.Upload_by ? this.costFormHead.Upload_by : this.userDetails.id;
            let exceptionMaster = this.exceptionMaster.filter(val => (val.valueset_detail_name == this.costFormHead.exception_reason));

            this.selectedException = exceptionMaster[0];
            console.log("selectedException", this.selectedException);
            console.log("costFormHead", this.costFormHead);
          } else {
            this.costFormHead = {
              id: null,
              rate_set_id: null,
              wp_rate_set_id: null,
              job_id: this.jobId,
              job_alloc_id: this.jobAllocationId,
              cost_refno: null,
              date: new Date(),
              quote_date: null,
              quote_no: null,
              sub_total: null,
              tax_rate: this.taxrate,
              tp_tax_total: null,
              tp_grand_total: null,
              job_manager: this.jobAllocationResults.info.TPContactName,
              job_contact: this.jobAllocationResults.info.TPMobile,
              job_notes: null,
              wp_pm_notes: null,
              wp_pm_substantiation: null,
              wp_grand_total: null,
              wp_sub_total: null,
              wp_tax_total: null,
              tp_job_substantiation: null,
              process_id: this.companyId,
              tp_invoice_no: null,
              rebate_total: null,
              Upload_by: null,
              source: null,
            }
          }
          this.costFormHead.job_manager = this.costFormHead.job_manager ? this.costFormHead.job_manager : this.jobAllocationResults.info.TPContactName;
          this.costFormHead.job_contact = this.costFormHead.job_contact ? this.costFormHead.job_contact : this.jobAllocationResults.info.TPMobile;
          this.costFormHead.Upload_by = this.costFormHead.Upload_by ? this.costFormHead.Upload_by : this.userDetails.id;
          //this.costFormHead.wp_pm_notes = decodeURIComponent(this.costFormHead.jobNotes ? this.costFormHead.job_notes : this.costFormHead.tp_job_substantiation);
          this.costFormHead.wp_pm_notes = this.costFormHead.jobNotes ? this.costFormHead.job_notes : this.costFormHead.tp_job_substantiation;
          this.pmSubstantiationtemp = this.costFormHead.wp_pm_substantiation;
          this.showStatus(this.costFormHead.status);
          this.rebatePerc = '';
          if (this.jobAllocationResults.info.client_rebate == null) {
            this.rebate = 'NA';
            this.rebatePerc = "NA";
          } else {
            this.rebate = this.jobAllocationResults.info.client_rebate;
            this.rebatePerc = this.jobAllocationResults.info.client_rebate;
          }
          this.invoiceDate = this.costFormHead.date;
          //this.pmSubstantiation = decodeURIComponent(this.costFormHead.wp_pm_substantiation);

          this.pmSubstantiation = this.costFormHead.wp_pm_substantiation;
          // await this.checkCostDetailsList();

          this.CrudService.getWorkOrderInfo(this.jobId, this.jobAllocationId).subscribe((res: any[]) => {
            if (res.length > 0) {
              this.TreeData = res[0];
              let workCategoryData = null;
              this.includesStump = false;
              this.extrasAvailable = false;
              if (this.TreeData.work_category.includes('1')) {
                workCategoryData = 1;
                if (this.TreeData.work_category.includes('3')) { this.includesStump = true; }
                if ((this.TreeData.work_category.includes('4')) || (this.TreeData.work_category.includes('5'))) { this.extrasAvailable = true; }
              } else if (this.TreeData.work_category.includes('2')) {
                workCategoryData = 2;
                if (this.TreeData.work_category.includes('3')) { this.includesStump = true; }
                if ((this.TreeData.work_category.includes('4')) || (this.TreeData.work_category.includes('5'))) { this.extrasAvailable = true; }
              }
              this.CrudService.getQuoteApprovalCheckData(this.TreeData.height, this.TreeData.trunk, workCategoryData).subscribe((res2: any[]) => {
                //console.log('work operation info data');
                if (res2.length > 0) {
                  this.costEstimate = (res2[0].cost * .80).toFixed(2); // parseFloat((1-(this.sub_total/this.wp_sub_total))*100).toFixed(2);
                  var matrixCalc = this.costFormHead.sub_total / this.costEstimate;
                  this.deviation = ((1 - matrixCalc) * 100).toFixed(2);
                  // var matrixCalc=parseFloat((1-(this.sub_total/this.costEstimate))*100).toFixed(2);this.deviation=parseFloat((1-(this.sub_total/this.costEstimate))*100).toFixed(2);

                  if (this.TreeData.no_tree > 1) {
                    this.costMatrix = '4';          // BackEnd Dont Process - Raise A Flag - msg= unable to decide
                  } else if (matrixCalc < 1.00) {
                    this.costMatrix = '1';          //Backend Steps Process
                  } else if (matrixCalc < 1.25) {
                    this.costMatrix = '2';          //Backend Steps Process
                  } else if (matrixCalc > 1.25) {
                    this.costMatrix = '3';          // BackEnd Dont Process - Raise A Flag - TP Cost is over estimate
                  } else {
                    this.costMatrix = '5';         // BackEnd Dont Process - Raise A Flag - msg= unable to decide
                  }
                  /* this.TPEstimateDisplay = this.formatMoney(this.sub_total);
                  this.costEstimateDisplay = this.formatMoney(this.costEstimate); */
                } else {
                  this.costMatrix = '5';
                }
              });
            }
          });

          if (this.costFormHead.wp_rate_set_id == null) {
            this.costFormHead.wp_rate_set_id = this.costFormHead.rate_set_id;
          }
          if (this.costFormHead.wp_rate_set_id) {
            this.selectedRateSet = this.rateSetList.find(val => (val.id == this.costFormHead.wp_rate_set_id));
            this.CrudService.getByItemDefn(this.costFormHead.rate_set_id).subscribe((itemListdata: any) => {
              this.tpItemsList = itemListdata;
              this.CrudService.getRateClassByRSID(this.costFormHead.rate_set_id).subscribe((rateClassdata: any) => {
                this.tpRateClassList = rateClassdata;
                this.CrudService.getByItemDefn(this.selectedRateSet.id).subscribe((data: any) => {
                  this.itemsList = data;
                  this.CrudService.getRateClassByRSID(this.selectedRateSet.id).subscribe(async (data: any) => {
                    this.rateClassList = data;
                    this.getWpQuoteList();
                    // await this.checkCostDetailsList();
                  })
                })
              })
              //this.getOrder();
              //this.getJobCosting();
            });
          } else {
            let defaultRateSet = this.rateSetList.filter(val => val.id === '10055');
            this.onChangeRateSet(defaultRateSet[0]);
          }
        })
      });
    });
  }


  async checkCostDetailsList() {
    debugger;
    //console.log('this.consWpQuoteListtt',this.consWpQuoteList)
    if (this.consWpQuoteList.length === 0 && this.consWpQuoteList.some(item => item.quote_inc_ui === true)) {
      this.pmSubstantiationtemp = this.costFormHead.wp_pm_substantiation;
      this.pmSubstantiation = this.costFormHead.wp_pm_substantiation;
      this.prefillDisable = false;
    }
    if (this.consWpQuoteList.some(wpCost => wpCost.selectedItem.item_name == 'Arborist' && this.jobReqAction == 'Subsidence Claim' && wpCost.quote_inc_ui == true)) {
      this.pmSubstantiation = 'Arborist Subsidence Report as requested.';
      this.prefillDisable = true;
    }
    else {
      this.pmSubstantiation = this.pmSubstantiationtemp;
      this.prefillDisable = false;
    }


  }


  /*   formatMoney(number, decPlaces, decSep, thouSep) {
      decPlaces = isNaN(decPlaces = Math.abs(decPlaces)) ? 2 : decPlaces,
        decSep = typeof decSep === 'undefined' ? '.' : decSep;
      thouSep = typeof thouSep === 'undefined' ? ',' : thouSep;
      var sign = number < 0 ? '-' : '';
      var i = String(parseInt(number = Math.abs(Number(number) || 0).toFixed(decPlaces)));
      var j = (j = i.length) > 3 ? j % 3 : 0;
  
      return '$ ' + sign +
        (j ? i.substr(0, j) + thouSep : '') +
        i.substr(j).replace(/(\decSep{3})(?=\decSep)/g, '$1' + thouSep) +
        (decPlaces ? decSep + Math.abs(number - i).toFixed(decPlaces).slice(2) : '');
    } */


  getOrder() {
    this.CrudService.getCostFormDetailByJobIdAllocIdNoOrder(this.jobId, this.jobAllocationId).subscribe((data: any) => {
    })
  }

  getWpQuoteList() {
    let fixedRate = null;
    let tempTotal = 0;
    this.SESRemoveDebris = false;
    this.SESEWPInvolved = false;
    //console.log(this.jobAllocationId, this.jobId);

    this.CrudService.getCostFormDetailByJobIdAllocIdInWpQuote22(this.jobId, this.jobAllocationId).subscribe(async (data: any) => {
      //console.log(data);
      this.loading = false
      data.sort(function (a, b) { return a.display_no - b.display_no });
      this.wpQuoteList = data;
      let wp_sub_total: number = 0;

      for (let details of this.wpQuoteList) {
        if (details.quote_inc === '2') {
          details.quote_inc_ui = true;
        } else {
          details.quote_inc_ui = false;
        }
        wp_sub_total = Number(wp_sub_total) + Number(details.item_total);
        if ((details.wp_sub_total == null) && (details.wp_desc == null)) {
          details.wp_desc = details.item_desc;
        }
        if ((details.item_rate == '10090') || (details.wp_rate == '10090')) {
          details.conditionalCostUpdate = 'conditionalCostUpdate';
        } else {
          details.conditionalCostUpdate = '';
        }
        if ((details.wp_desc == 'undefined') || (details.wp_desc == 'null')) {
          details.wp_desc = null;
        }

        if (details.item_id) {
          let item = this.tpItemsList.filter(val => details.item_id === val.id);
          if (item.length > 0) {
            details.selectedItem = item[0];
          } else {
            details.selectedItem = {};
          }
        }
        if (details.item_rate) {
          let rateClass = this.tpRateClassList.filter(val => details.item_rate === val.id);
          if (rateClass.length > 0) {
            details.selectedRateClass = rateClass[0];
          } else {
            details.selectedRateClass = {};
          }
        }

        if (this.jobAllocationResults.info.client.includes('Armidale')) {
          details.wp_rate = (details.item_price * 1.11).toFixed(2);
          details.wp_total = (details.wp_rate * details.wp_qty * details.wp_hrs).toFixed(2);
          tempTotal = tempTotal + details.wp_total;
        }

        let coItem = this.cost_items.filter(val => details.item_id === val.id);
        if (coItem.length > 0) {
          let itemInfo = coItem[0];
          details.item_title = itemInfo.item;
          if (!this.SESRemoveDebris) {
            if ((itemInfo.id == '10029') || (itemInfo.id == '10030')) {
              this.SESRemoveDebris = true;
            }
          }
          if (!this.SESEWPInvolved) {
            if ((itemInfo.id == '10078') || (itemInfo.id == '10026') || (itemInfo.id == '10028')) {
              this.SESEWPInvolved = true;
            }
          }
          if (itemInfo.FixedRate === '2') {
            fixedRate = '2';
          } else {
            fixedRate = '1';
          }
        }
        details.entry_point = '1';

        details.wp_qty = details.wp_qty ? parseFloat(details.wp_qty) : parseFloat(details.item_qty);
        details.wp_hrs = details.wp_hrs ? parseFloat(details.wp_hrs) : parseFloat(details.item_hrs);
        details.wp_rate = details.wp_rate ? parseFloat(details.wp_rate) : parseFloat(details.InvoiceRate);
        details.wp_total = details.wp_total ? parseFloat(details.wp_total) : (details.InvoiceRate * details.wp_hrs * details.wp_qty);
        //wpQuote.wp_desc=wpQuote.wp_desc?wpQuote.wp_desc:wpQuote.item_desc;
        details.selected = false;
        details.FixedRate = fixedRate;
        details.allowEdit = false;
        if (this.costFormHead.rate_set_id === '10076') {
          details.allowEdit = true;
        }
        if (details.FixedRate.toString() === '2') {
          details.allowEdit = true;
        }
        //details.wp_desc = decodeURIComponent(details.wp_desc);
        this.consWpQuoteList = [...this.consWpQuoteList, details];
        this.consWpQuoteList.sort(function (a, b) { return a.display_no - b.display_no });
      }
      if (this.env.hosting == 'UK') {
        await this.checkCostDetailsList();
      }
      this.calcResultTotal();
      /* this.wp_sub_total = Number(parseFloat(wp_sub_total.toString()).toFixed(2));
      this.taxamount = this.wp_sub_total * (this.taxrate / 100);
      this.taxamount = parseFloat(this.taxamount).toFixed(2);
      this.tp_grand_total = parseFloat(this.wp_sub_total) + parseFloat(this.taxamount);
      this.tp_grand_total = parseFloat(this.tp_grand_total).toFixed(2); */

    })

    this.CrudService.getCostFormDetailByJobIdAllocIdInNewWpQuote2(this.jobId, this.jobAllocationId).subscribe((newData: any[]) => {
      //console.log('cost new head detail data from back end ');
      //console.log(newData);
      this.isEdit = true;
      this.newDetails = newData;
      this.newWpQuoteList = newData;
      let newFixRate = null
      let loopIndex = 0;
      for (let newWpQuote of this.newWpQuoteList) {
        if ((newWpQuote.item_rate == '10090') || (newWpQuote.wp_rate == '10090')) {
          newWpQuote.conditionalCostUpdate = 'conditionalCostUpdate';
        } else {
          newWpQuote.conditionalCostUpdate = '';
        }
        if ((newWpQuote.wp_desc == 'undefined') || (newWpQuote.wp_desc == 'null')) {
          newWpQuote.wp_desc = null;
          this.newWpQuoteList[loopIndex].wp_desc = null;
        }
        loopIndex = loopIndex + 1;
        if (newWpQuote.item_id) {
          let item = this.itemsList.filter(val => newWpQuote.item_id === val.id);
          if (item.length > 0) {
            newWpQuote.selectedItem = item[0];
            newWpQuote.FixedRate = newWpQuote.selectedItem.FixedRate ? newWpQuote.selectedItem.FixedRate : '1';
          } else {
            newWpQuote.selectedItem = {};
            newWpQuote.FixedRate = null;
          }
          newWpQuote.entry_point = '2';
          /* if (newFixRate == '2') {
              fixedRate = '2';
            } else {
              fixedRate = '1';
            }
            newWpQuote.FixedRate = fixedRate */
        }
        if (newWpQuote.item_rate) {
          let rateClass = this.rateClassList.filter(val => newWpQuote.item_rate === val.id);
          if (rateClass.length > 0) {
            newWpQuote.selectedRateClass = rateClass[0];
          } else {
            newWpQuote.selectedRateClass = {};
          }
        }

        newWpQuote.wp_qty = newWpQuote.wp_qty ? parseFloat(newWpQuote.wp_qty) : parseFloat(newWpQuote.item_qty);
        newWpQuote.wp_hrs = newWpQuote.wp_hrs ? parseFloat(newWpQuote.wp_hrs) : parseFloat(newWpQuote.item_hrs);
        newWpQuote.wp_rate = newWpQuote.wp_rate ? parseFloat(newWpQuote.wp_rate) : parseFloat(newWpQuote.item_price);
        newWpQuote.wp_total = newWpQuote.wp_total ? parseFloat(newWpQuote.wp_total) : parseFloat(newWpQuote.wp_rate);
        //newWpQuote.wp_desc=newWpQuote.wp_desc;
        newWpQuote.selected = false;

        if (newWpQuote.quote_inc === '2') {
          newWpQuote.quote_inc_ui = true;
        } else {
          newWpQuote.quote_inc_ui = false;
        }

        newWpQuote.allowEdit = false;
        if (newWpQuote.entry_point === '2') {
          if (this.selectedRateSet.id === '10076') {
            newWpQuote.allowEdit = true;
          }
        }
        newWpQuote.FixedRate = newWpQuote.FixedRate ? newWpQuote.FixedRate : '1';
        if (newWpQuote.FixedRate.toString() === '2') {
          newWpQuote.allowEdit = true;
        }

        this.consWpQuoteList = [...this.consWpQuoteList, newWpQuote];
      }


      this.calcResultTotal();
      this.getDataFlag = false;
      //            usSpinnerService.stop('spinner-1');
    });



  }

  preFill() {

    let currentJobSubstantiation = '';
    if (this.jobAllocationResults.info.TreeCondition !== null && this.jobAllocationResults.info.TreeCondition !== '') {

      if (this.jobAllocationResults.info.TreeCondition.includes('1')) {
        this.standingTree = true;
      } else {
        this.standingTree = false;
      }
    } else {
      this.standingTree = false;
    }
    if (this.jobAllocationResults.info.client.includes('Armidale')) {
      // currentJobSubstantiation="Attend site, carefully dismantle mature pine tree in decline marked with yellow X by council, grind stump 300 ml below ground level, remove all cut material and debris from site, leave site safe, clean and tidy";
      currentJobSubstantiation = this.tree_WorkTypeTextCouncil(currentJobSubstantiation);
      currentJobSubstantiation = this.damagesTextCouncil(currentJobSubstantiation);
      currentJobSubstantiation = this.removalAccessTextCouncil(currentJobSubstantiation);
    } else if ((this.jobAllocationResults.info.WPType.includes('Insurance'))) {
      currentJobSubstantiation = this.tree_WorkTypeText(currentJobSubstantiation);
      currentJobSubstantiation = this.damagesText(currentJobSubstantiation);
      currentJobSubstantiation = this.removalAccessText(currentJobSubstantiation);
    } else if ((this.jobAllocationResults.info.WPType.includes('SES'))) {
      currentJobSubstantiation = this.tree_WorkTypeTextSES(currentJobSubstantiation);
      currentJobSubstantiation = this.damagesText(currentJobSubstantiation);
      currentJobSubstantiation = this.removalAccessTextSES(currentJobSubstantiation);
    } else {
      currentJobSubstantiation = this.tree_WorkTypeText(currentJobSubstantiation);
      currentJobSubstantiation = this.damagesText(currentJobSubstantiation);
      currentJobSubstantiation = this.removalAccessText(currentJobSubstantiation);
    }
    //console.log('currentJobSubstantiationnn',currentJobSubstantiation)
    this.pmSubstantiation = currentJobSubstantiation;
  }

  copySubstantiation() {
    this.pmSubstantiation = this.costFormHead.wp_pm_notes;
  }

  onChangeRateSet(value) {
    this.selectedRateSet = value;
    this.CrudService.getByItemDefn(this.selectedRateSet?.id).subscribe((data: any) => {
      this.itemsList = data;
      this.CrudService.getRateClassByRSID(this.selectedRateSet.id).subscribe((data: any) => {
        this.rateClassList = data;
      })
    })
    for (let costdetail of this.consWpQuoteList) {
      costdetail.allowEdit = false;
      if (costdetail.entry_point === '2') {
        if (this.selectedRateSet.id === '10076') {
          costdetail.allowEdit = true;
        }
      }
      if (costdetail.entry_point === '1') {
        if (this.costFormHead.rate_set_id === '10076') {
          costdetail.allowEdit = true;
        }
      }
      costdetail.FixedRate = costdetail.FixedRate ? costdetail.FixedRate : '1';
      if (costdetail.FixedRate?.toString() === '2') {
        costdetail.allowEdit = true;
      }
    }
    this.loading = false
  }

  async addNew() {
    if (this.selectedRateSet) {
      const wpQuote = {
        created_at: null,
        created_by: this.userDetails.id,
        display_no: (this.wpQuoteList.length + 1) + (this.newWpQuoteList.length + 1),
        display_order: null,
        entry_point: '2',
        head_id: null,
        id: null,
        item_desc: null,
        item_hrs: null,
        item_id: null,
        item_name: null,
        item_price: null,
        item_qty: null,
        item_rate: null,
        item_total: null,
        job_alloc_id: this.jobAllocationId,
        job_id: this.jobId,
        last_modified_by: null,
        last_updated_at: null,
        owner: null,
        process_id: this.companyId,
        quote_inc: '1',
        selectedItem: {},
        selectedRateClass: {},
        wp_desc: null,
        wp_hrs: null,
        wp_qty: null,
        wp_rate: null,
        wp_total: null,
        quote_inc_ui: false,
      }

      if (this.rateClassList.length > 0) {
        let defaultRateClass = this.rateClassList.filter(val => val.id === '10055');
        wpQuote.selectedRateClass = defaultRateClass[0];
      } else {
        this.loading = false; this.disableSave = false;
        this.messageService.add({ severity: 'error', summary: 'error', detail: 'Costings Not defined for selected Rate Set. Contact Support Desk to get this fixed', life: 1000 });
      }
      wpQuote.display_order = this.wpQuoteList.length + 1;
      wpQuote.display_no = this.wpQuoteList.length + 1;
      this.consWpQuoteList = [...this.consWpQuoteList, wpQuote];
    } else {
      this.loading = false; this.disableSave = false;
      this.messageService.add({ severity: 'error', summary: 'error', detail: 'Rate Set Not Selected.', life: 1000 });
    }
    //await this.checkCostDetailsList();
    this.refereshFlag.emit('Add New WP Costing');
  }

  async copyRecord() {
    let count = this.selectedRecords.length;
    for (let record of this.selectedRecords) {
      count = count + 1;
      let costDetails = {
        created_at: new Date(),
        created_by: this.userDetails.id,
        display_no: count,
        display_order: count,
        entry_point: '2',
        head_id: record.head_id,
        id: null,
        item_desc: record.item_desc,
        item_hrs: record.item_hrs,
        item_id: record.item_id,
        item_name: record.item_name,
        item_price: record.item_price,
        item_qty: record.item_qty,
        item_rate: record.item_rate,
        item_total: record.item_total,
        job_alloc_id: record.job_alloc_id,
        job_id: record.job_id,
        last_modified_by: null,
        last_updated_at: null,
        owner: null,
        process_id: record.process_id,
        quote_inc: record.quote_inc,
        selectedItem: record.selectedItem,
        selectedRateClass: record.selectedRateClass,
        wp_desc: record.wp_desc,
        wp_hrs: record.wp_hrs,
        wp_qty: record.wp_qty,
        wp_rate: record.wp_rate,
        wp_total: record.wp_total
      }
      this.consWpQuoteList = [...this.consWpQuoteList, costDetails];
      this.loading = false; this.disableSave = false;
      this.messageService.add({ severity: 'success', summary: 'Successful', detail: 'Copied', life: 1000 });
      if (this.env.hosting == 'UK') {
        await this.checkCostDetailsList();
      }
    }
  }
  removeRecord() {
    let filterList = this.selectedRecords.filter(val => val.entry_point === '1' || val.entry_point === 1);
    if (filterList.length > 0) {
      this.loading = false; this.disableSave = false;
      this.messageService.add({ severity: 'error', summary: 'Error', detail: 'Tree  Manager Costing Cant be removed.', life: 1000 });
    } else {
      this.confirmationService.confirm({
        message: 'Are you sure you want to delete the selected Records?',
        header: 'Confirm',
        icon: 'pi pi-exclamation-triangle',
        accept: async () => {
          for (let record of this.selectedRecords) {
            this.CrudService.Delete(record.id, 'CostFormDetail').subscribe((data: any) => {
            });

          }
          this.consWpQuoteList = this.consWpQuoteList.filter(val => !this.selectedRecords.includes(val))
          console.log(' this.consWpQuoteList', this.consWpQuoteList)
          await this.calcResultTotal();
          await this.save()
          if (this.env.hosting == 'UK') {
            await this.checkCostDetailsList();
          }
          this.refereshFlag.emit('Remove Costing');
          this.loading = false; this.disableSave = true;
          this.messageService.add({ severity: 'success', summary: 'Successful', detail: 'Records Deleted', life: 1000 });
        }
      });
    }
  }

  async save() {
    this.loading = true;
    this.pmSubstantiationtemp = this.pmSubstantiation;
    if (this.costFormHead.wp_pm_notes == null || this.costFormHead.wp_pm_notes == '' || this.pmSubstantiation == null || this.pmSubstantiation == '') {
      this.loading = false; this.disableSave = false;
      this.messageService.add({ severity: 'error', summary: 'Error', detail: 'Fill required fields', life: 3000 });
      if (this.costFormHead.wp_pm_notes == null || this.costFormHead.wp_pm_notes == '') {
        this.req_error1 = true
      }
      else {
        this.req_error1 = false
      }
      if (this.pmSubstantiation == null || this.pmSubstantiation == '') {
        this.req_error = true
      }
      else {
        this.req_error = false
      }
    } else {
      this.req_error = false
      this.req_error1 = false

      let treeInfo: any = await this.CrudService.getWorkOrderInfo(this.jobId, this.jobAllocationId).toPromise();
      if (treeInfo.length === 0) {
        //console.log(treeInfo);
        this.loading = false;
        this.messageService.add({ severity: 'error', summary: 'error', detail: 'No Work Information available. Please enter Work Information before proceeding.', life: 2000 });
      } else {


        this.costFormHead.wp_pm_substantiation = this.pmSubstantiation;
        this.costFormHead.wp_pm_notes = this.costFormHead.wp_pm_notes;

        this.costFormHead.wp_sub_total = this.wp_sub_total;
        if (typeof this.wp_tax_total == 'undefined') {
          this.costFormHead.wp_tax_total = 0;
        } else {
          this.costFormHead.wp_tax_total = this.wp_tax_total;
        }
        this.costFormHead.wp_grand_total = this.wp_grand_total;
        if (this.selectedRateSet) {
          this.costFormHead.wp_rate_set_id = this.selectedRateSet.id;
          if (this.margin >= 2) {
            this.saveCostformHead();
            this.costFormHead.exceptionComment = '';
            this.costFormHead.exception_reason = '';
          }
          else {
            this.displayException = true;
          }


        } else {
          this.loading = false; this.disableSave = false;
          this.messageService.add({ severity: 'error', summary: 'Error', detail: 'RateSet Not Selected', life: 1000 });
        }
      }
      //console.log("item name check",this.selectedItem);
      // await this.checkCostDetailsList();
      this.refereshFlag.emit('Save WP Costing');
    }

  }

  saveWithException() {
    if (this.selectedException?.length == "0" || this.exceptionComment == null || this.exceptionComment == '') {
      this.exceptionError = true;
    }
    else {
      let exceptionMaster = this.selectedException?.valueset_detail_name;
      this.displayException = false;
      this.exceptionError = false;
      this.costFormHead.exceptionComment = this.exceptionComment;
      this.costFormHead.exception_reason = exceptionMaster;
      this.saveCostformHead();
    }


  }

  checkReason() {
    if (this.selectedException?.length == "0" || this.exceptionComment == null || this.exceptionComment == '') {
      this.exceptionError = true;
    }
    else {
      this.exceptionError = false;
    }
  }


  async saveCostformHead() {
    if (this.costFormHead.id) {
      this.costFormHead.rebate_total = this.rebate;
      if (this.costFormHead.exception_reason == undefined) {
        this.costFormHead.exception_reason = null
      }
      let data: any = await this.CrudService.Update(this.costFormHead, 'CostFormHead').toPromise();
      if (data.success === 1 && this.itemsList.item_name !== '') {
        await this.saveDetails();
      } else {
        this.loading = false; this.disableSave = false;
        this.messageService.add({ severity: 'error', summary: 'Error', detail: 'Costing Details Not Updated', life: 1000 });
      }

      /* if (this.costFormHead.sub_total > this.wp_sub_total || this.costFormHead.sub_total == '0' || this.costFormHead.sub_total == '0.0' || this.costFormHead.sub_total == null) {
        this.messageService.add({ severity: 'error', summary: 'Error', detail: 'TP Sub Total Should Be Higher Than WP Sub Total', life: 1000 });
      } else if (this.costFormHead.tp_grand_total > this.wp_grand_total || this.costFormHead.tp_grand_total == '0' || this.costFormHead.tp_grand_total == '0.0' || this.costFormHead.tp_grand_total == null) {
        this.messageService.add({ severity: 'error', summary: 'Error', detail: 'TP Grand Total Should Be Higher Than WP Grand Total', life: 1000 });
      } else {
        let data: any = await this.CrudService.Update(this.costFormHead, 'CostFormHead').toPromise();
        if (data.success === 1 && this.itemsList.item_name !== '') {
          await this.saveDetails();
        } else {
          this.messageService.add({ severity: 'error', summary: 'Error', detail: 'Costing Details Not Updated', life: 1000 });
        }
      } */
    } else {
      /* if (this.costFormHead.sub_total > this.wp_sub_total || this.costFormHead.sub_total == '0' || this.costFormHead.sub_total == '0.0' || this.costFormHead.sub_total == null) {
        this.messageService.add({ severity: 'error', summary: 'Error', detail: 'TP Sub Total Should Be Higher Than WP Sub Total', life: 1000 });
      } else if (this.costFormHead.tp_grand_total > this.wp_grand_total || this.costFormHead.tp_grand_total == '0' || this.costFormHead.tp_grand_total == '0.0' || this.costFormHead.tp_grand_total == null) {
        this.messageService.add({ severity: 'error', summary: 'Error', detail: 'TP Grand Total Should Be Higher Than WP Grand Total', life: 1000 });
      } */
      this.costFormHead.rebate_total = this.rebate;
      let data: any = await this.CrudService.Create(this.costFormHead, 'CostFormHead').toPromise();
      if (data.success === 1 && this.itemsList.item_name !== '') {
        this.costFormHead.id = data.id;
        await this.saveDetails();
        //this.messageService.add({ severity: 'success', summary: 'Successful', detail: 'Costing Details Created', life: 1000 });
      } else {
        this.loading = false; this.disableSave = false;
        this.messageService.add({ severity: 'error', summary: 'Error', detail: 'Costing Details Not Created', life: 1000 });
      }
    }
  }


  async saveDetails() {
    this.loading = true;
    let success = true;
    let count = 0;
    let failureCount = 0;
    let i = 0;
    for (let costDetail of this.consWpQuoteList) {
      i = i + 1;
      costDetail.display_no = i;
      if (costDetail.quote_inc_ui) {
        costDetail.quote_inc = '2';
      } else {
        costDetail.quote_inc = '1';
      }
      if (costDetail.selectedRateClass.id) {
        costDetail.item_rate = costDetail.selectedRateClass.id;
        if (costDetail.selectedItem.id) {
          costDetail.item_id = costDetail.selectedItem.id;
        }
      }
      if (costDetail.id) {
        let data: any = await this.CrudService.Update(costDetail, 'CostFormDetail').toPromise();
        if (data.success === 1) {
          count = count + 1;
        } else {
          success = false;
          failureCount = failureCount + 1;
          this.loading = false; this.disableSave = false;
          this.messageService.add({ severity: 'error', summary: 'Error', detail: 'WP Costing Details Not Updated', life: 1000 });
        }
      } else {
        costDetail.head_id = this.costFormHead.id;
        let data: any = await this.CrudService.Create(costDetail, 'CostFormDetail').toPromise();
        if (data.success === 1) {
          costDetail.id = data.id;
          count = count + 1;
        } else {
          failureCount = failureCount + 1;
          success = false;
        }

      }
    }
    if (success) {

      this.loading = false; this.disableSave = false;
      /* this.messageService.add({ severity: 'success', summary: 'Successful', detail: count +' Record(s) saved.', life: 1000 }); */
      this.messageService.add({ severity: 'success', summary: 'Successful', detail: 'Record(s) saved.', life: 1000 });
    } else {

      this.loading = false; this.disableSave = false;
      /* this.messageService.add({ severity: 'error', summary: 'Error', detail: 'Only '+ count+ 'Record(s) Saved. Unable to save '+failureCount+' Record(s)', life: 3000 }); */
      this.messageService.add({ severity: 'error', summary: 'Error', detail: 'Unable to save All Record(s)', life: 3000 });
    }
  }
  // this.showStatus(this.costFormHead.status);



  storeInSchedule(obj) {
    let message = '';
    let sms = '';
    let email = '';
    let contactUI = '';
    if (this.jobAllocationResults.info.TPEmail == '' && this.jobAllocationResults.info.TPMobile == '') {
      message = 'Quote approved. Notification not sent to the TP';
      sms = '';
      email = '';
      if (sms === '1' && email === '1') {
        contactUI = 'N/A';
      } else {
        contactUI = '';
      }
    } else {
      message = 'Quote auto Approved.  Notification has been sent to the TP (Email/SMS)';
      sms = '2';
      email = '2';
    }
    let store_data = {
      id: null,
      job_id: this.jobId,
      job_alloc_id: this.jobAllocationId,
      process_id: 1,
      visit_type: null,
      status: null,
      sched_date: null,
      PMOnly: '2',
      sched_note: message,
      start_time: null,
      end_time: null,
      created_by: this.userDetails.id,
      created_at: new Date(),
      phoned: '1',
      phone_no: null,
      sms: sms,
      sms_no: this.jobAllocationResults.info.TPMobile,
      email: email,
      emailaddress: this.jobAllocationResults.info.TPEmail,
      callback: '1',
      contactUI: contactUI
    }
    this.CrudService.Create(store_data, 'JobSchedule').subscribe((data: any) => {
    });
  }

  showStatus(status) {
    switch (status) {
      case '1':
        this.statusDesc = 'Submitted';
        break;
      case '2':
        this.statusDesc = 'Approved';
        break;
      case '3':
        this.statusDesc = 'Rejected';
        break;
      case '4':
        this.statusDesc = 'Saved';
        break;
      default:
        this.statusDesc = '';
    }
  }

  async calcResultTotal() {
    // debugger;
    //  console.log("costFormHead",this.costFormHead);
    // await this.checkCostDetailsList();
    let totalNumber: number = 0;
    //let tp_sub_total: number = 0;
    for (let details of this.consWpQuoteList) {
      if (details.quote_inc_ui) {
        totalNumber = totalNumber + parseFloat(details.wp_total);
        //tp_sub_total = Number(tp_sub_total) + Number(details.item_total);
      }
    }
    console.log('totalNumber', totalNumber)
    /* this.tp_sub_total = tp_sub_total;
    this.tp_grand_total = this.tp_sub_total + (this.tp_sub_total * this.taxrate / 100);
    this.tp_grand_total = parseFloat(this.tp_grand_total).toFixed(2);
 */

    if (totalNumber > 0) {
      this.wp_sub_total = totalNumber;
      await this.calculateRebate();
      this.wp_grand_total = this.wp_sub_total + (this.wp_sub_total * this.taxrate / 100);
      this.wp_grand_total = parseFloat(this.wp_grand_total).toFixed(2);
    } else {
      await this.calculateRebate();
      this.wp_sub_total = 0;
      this.wp_grand_total = 0;
      this.wp_grand_total = 0;
    }
    this.wp_tax_total = this.wp_sub_total * (this.taxrate / 100);
    this.wp_tax_total = parseFloat(this.wp_tax_total).toFixed(2);


    //this.calculateRebate();

    if (this.wp_sub_total == 0.00) {
      this.margin = 0;
    } else {
      this.margin = (1 - (this.costFormHead.sub_total / this.wp_sub_total)) * 100;
      this.margin = this.margin.toFixed(2);
    }
  }


  calculateRebate() {
    if (this.jobAllocationResults.info.client_rebate > 0) {
      this.rebate = parseFloat(this.jobAllocationResults.info.client_rebate);
      this.rebate = ((this.wp_sub_total) * (this.rebate / 100));
      this.rebate = this.rebate.toFixed(2);
    } else {
      this.rebate = 0;
    }
  }

  async onChangeIncQuote(wpCost, value) {
    if (value) {
      wpCost.quote_inc = '2';
      wpCost.quote_inc_ui = true;
      if (this.env.hosting == 'UK') {
        await this.checkCostDetailsList();
      }
    } else {
      wpCost.quote_inc = '1';
      wpCost.quote_inc_ui = false;
      if (this.env.hosting == 'UK') {
        await this.checkCostDetailsList();
      }
    }

    this.calcResultTotal();
  }

  async reCalculateTotals(costDetail) {
    costDetail.item_total = parseFloat(costDetail.item_hrs) * parseFloat(costDetail.item_qty) * parseFloat(costDetail.item_price);
    costDetail.item_total = costDetail.item_total.toFixed(2);
    costDetail.wp_total = parseFloat(costDetail.wp_hrs) * parseFloat(costDetail.wp_qty) * parseFloat(costDetail.wp_rate);
    costDetail.wp_total = costDetail.wp_total.toFixed(2);

    this.calcResultTotal();
  }

  async calculateItemPrice(costdetail) {


    if (costdetail.selectedItem != null && costdetail.selectedRateClass != null) {
      costdetail.item = costdetail.selectedItem.id;
      costdetail.item_rate = costdetail.selectedRateClass.id;

      this.CrudService.getSelectedItemDetails(costdetail.selectedItem.id, this.selectedRateSet.id, costdetail.selectedRateClass.id).subscribe(async (data: any[]) => {
        //console.log(data);
        if (data.length > 0) {
          let costView = data[0];
          costdetail.item_price = costView.contractor_rate;
          costdetail.wp_rate = costView.invoice_rate;

          costdetail.FixedRate = costView.FixedRate ? costView.FixedRate : '1';
          costdetail.allowEdit = false;
          if (costdetail.entry_point === '2') {
            if (this.selectedRateSet.id === '10076') {
              costdetail.allowEdit = true;
            }
          }
          if (costdetail.entry_point === '1') {
            if (this.costFormHead.rate_set_id === '10076') {
              costdetail.allowEdit = true;
            }
          }
          if (costdetail.FixedRate.toString() === '2') {
            costdetail.allowEdit = true;
          }
          if (costdetail.item_qty == null) {
            costdetail.item_qty = 1;
            costdetail.wp_qty = 1;
          }
          costdetail.item_hrs = 1;
          costdetail.wp_hrs = 1;
          if (this.env.hosting == 'UK') {
            await this.checkCostDetailsList();
          }
          this.reCalculateTotals(costdetail);
        } else {
          this.loading = false; this.disableSave = false;
          this.messageService.add({ severity: 'error', summary: 'error', detail: 'Costings Not defined for selected Items. Contact Support Desk to get this fixed', life: 1000 });
        }
      });
    }

  }


  tree_WorkTypeTextCouncil(text) {
    this.BaseText = 'Attend site, carefully dismantle ';
    this.dismantleText = 'mature tree in decline marked with yellow X by council';
    this.InscleanUpBasic = ' Remove all cut material and debris from site';
    this.InscleanUpMakeSafe = ' Stack all cut material safely and leave on site.';
    this.notesInsuranceBasic = ' Leave site safe, clean and tidy.';
    // this.InsPoorAccessExtraNote=" Very poor access, all cut material and debris to be carried manually to chipper for processing.";
    this.InsPoorAccessExtraNote = ' Limited access.';
    if (this.TreeData.no_tree == 1) {
      /*if((this.TreeData.work_category.includes('1'))){
         text= this.BaseText + "large failed tree";
      }else if((this.TreeData.work_category.includes('2'))){
         text= this.BaseText + "large failed limb";
      }else if ((this.TreeData.work_category.includes('3'))){
         text= this.BaseText +  "uprooted large stump";
      }else if((this.TreeData.work_category.includes('6'))){
         text= this.BaseText + "failed tree debris";
     }*/
      if (this.standingTree) {
        text = this.BaseText + this.dismantleText;
        if ((this.TreeData.work_category.includes('3'))) {
          text = text + ', grind stump 300 ml below ground level,';
        } else {
          text = text + ',';
        }
      } else {
        if ((this.TreeData.work_category.includes('1'))) {
          text = this.BaseText + 'large failed tree';
        } else if ((this.TreeData.work_category.includes('2'))) {
          text = this.BaseText + 'large failed limb';
        } else if ((this.TreeData.work_category.includes('3'))) {
          text = this.BaseText + 'large uprooted stump';
        } else if ((this.TreeData.work_category.includes('6'))) {
          text = this.BaseText + 'failed tree debris';
        }
      }

    } else if (this.TreeData.no_tree == 2) {
      if ((this.TreeData.work_category.includes('1')) && (this.TreeData.work_category.includes('2'))) {
        text = this.BaseText + 'large failed tree and failed limb';
      } else if ((this.TreeData.work_category.includes('2'))) {
        text = this.BaseText + '2 large failed limbs';
      } else if ((this.TreeData.work_category.includes('1'))) {
        text = this.BaseText + '2 large failed trees';
      }
    } else if (this.TreeData.no_tree > 2) {
      if ((this.TreeData.work_category.includes('1')) && (this.TreeData.work_category.includes('2'))) {
        text = this.BaseText + 'numerous large failed trees and failed limbs';
      } else if ((this.TreeData.work_category.includes('2'))) {
        text = this.BaseText + 'numerous large failed limbs';
      } else if ((this.TreeData.work_category.includes('1'))) {
        text = this.BaseText + 'numerous large failed trees';
      }
    }

    return text;
  }

  damagesTextCouncil(DamagesText) {
    let fenceDamageFlag = false;
    let roofDamageFlag = false;
    let otherDamageFlag = false;
    if (this.TreeData.fence != null && this.TreeData.fence !== '') {
      if ((this.TreeData.fence.includes('1')) || (this.TreeData.fence.includes('2')) || (this.TreeData.fence.includes('3')) || (this.TreeData.fence.includes('4')) || (this.TreeData.fence.includes('5'))) {
        fenceDamageFlag = true;
      }
    } if (this.TreeData.roof != null && this.TreeData.roof !== '') {
      if ((this.TreeData.roof.includes('1')) || (this.TreeData.roof.includes('2')) || (this.TreeData.roof.includes('3')) || (this.TreeData.roof.includes('4'))) {
        roofDamageFlag = true;
      }
    } if (this.TreeData.other != null && this.TreeData.other !== '') {
      if ((this.TreeData.other.includes('1')) || (this.TreeData.other.includes('2')) || (this.TreeData.other.includes('3'))
        || (this.TreeData.other.includes('4')) || (this.TreeData.other.includes('5')) || (this.TreeData.other.includes('6')) || (this.TreeData.other.includes('7'))) {
        otherDamageFlag = true;
      }
    } if (fenceDamageFlag && roofDamageFlag && otherDamageFlag) {
      //    DamagesText=DamagesText+" impacting onto and damaging boundary fence, roof and other manufactured structures." ;
      DamagesText = DamagesText + ' impacting onto and damaging boundary fence, roof, ' + this.JobService.ConvertOtherDamagesSub(this.TreeData.other) + '.';
    } else if (fenceDamageFlag && otherDamageFlag) {
      //    DamagesText=DamagesText+" impacting onto and damaging boundary fence and other manufactured structures.";
      DamagesText = DamagesText + ' impacting onto and damaging boundary fence, ' + this.JobService.ConvertOtherDamagesSub(this.TreeData.other) + '.';
    } else if (roofDamageFlag && otherDamageFlag) {
      //    DamagesText=DamagesText+" impacting onto and damaging the roof and other manufactured structures.";
      DamagesText = DamagesText + ' impacting onto and damaging roof, ' + this.JobService.ConvertOtherDamagesSub(this.TreeData.other) + '.';
    } else if (otherDamageFlag) {
      DamagesText = DamagesText + ' impacting onto and damaging ' + this.JobService.ConvertOtherDamagesSub(this.TreeData.other) + '.';
    } else if (fenceDamageFlag && roofDamageFlag) {
      DamagesText = DamagesText + ' impacting onto and damaging boundary fence and roof.';
    } else if (fenceDamageFlag) {
      DamagesText = DamagesText + ' impacting onto and damaging boundary fence.';
    } else if (roofDamageFlag) {
      DamagesText = DamagesText + ' impacting onto and damaging roof.';
    }


    return DamagesText;

  }

  removalAccessTextCouncil(Accesstext) {

    this.InscleanUpBasic = ' remove all cut material and debris from site';
    // this.InscleanUpMakeSafe =  " Stack all cut material safely and leave on site.";
    this.InscleanUpMakeSafe = ' Stack all cut material and debris safely on site';
    this.notesInsuranceBasic = ' Leave site safe, clean and tidy.';
    //        this.InsPoorAccessExtraNote=" Very poor access, all cut material and debris to be carried manually to chipper for processing.";
    this.InsPoorAccessExtraNote = ' Limited access.';
    if ((this.TreeData.work_category.includes('6'))) {
      Accesstext = Accesstext + this.InscleanUpBasic

      if ((this.TreeData.work_category.includes('3'))) {
        if (!(this.standingTree)) { Accesstext = Accesstext + ' including raised root plate.'; } else { Accesstext = Accesstext + '.'; }
      } else {
        Accesstext = Accesstext + '.';
      }
      Accesstext += this.notesInsuranceBasic;
    } else {
      Accesstext = Accesstext + this.InscleanUpMakeSafe

      Accesstext += this.notesInsuranceBasic;
    }

    if (this.TreeData.access != null) {
      if (this.TreeData.access.includes('3')) {
        Accesstext = Accesstext + this.InsPoorAccessExtraNote;
      } else if (this.TreeData.access.includes('2')) {
        Accesstext = Accesstext + this.InsPoorAccessExtraNote;
      }
    }
    return Accesstext;
  }


  tree_WorkTypeText(text) {

    this.BaseText = 'Attend site, carefully dismantle ';
    this.InscleanUpBasic = ' Remove all cut material and debris from site';
    this.InscleanUpMakeSafe = ' Stack all cut material safely and leave on site.';
    this.notesInsuranceBasic = ' Leave site safe, clean and clear for further building services. NOTE: All works conducted within insured boundary fence line only.';
    // this.InsPoorAccessExtraNote=" Very poor access, all cut material and debris to be carried manually to chipper for processing.";
    this.InsPoorAccessExtraNote = ' Limited access.';
    if (this.TreeData.no_tree == 1) {
      if ((this.TreeData.work_category.includes('1'))) {
        text = this.BaseText + 'large failed tree';
      } else if ((this.TreeData.work_category.includes('2'))) {
        text = this.BaseText + 'large failed limb';
      } else if ((this.TreeData.work_category.includes('3'))) {
        text = this.BaseText + 'large uprooted stump';
      } else if ((this.TreeData.work_category.includes('6'))) {
        text = this.BaseText + 'failed tree debris';
      }
    } else if (this.TreeData.no_tree == 2) {
      if ((this.TreeData.work_category.includes('1')) && (this.TreeData.work_category.includes('2'))) {
        text = this.BaseText + 'large failed tree and failed limb';
      } else if ((this.TreeData.work_category.includes('2'))) {
        text = this.BaseText + '2 large failed limbs';
      } else if ((this.TreeData.work_category.includes('1'))) {
        text = this.BaseText + '2 large failed trees';
      }
    } else if (this.TreeData.no_tree > 2) {
      if ((this.TreeData.work_category.includes('1')) && (this.TreeData.work_category.includes('2'))) {
        text = this.BaseText + 'numerous large failed trees and failed limbs';
      } else if ((this.TreeData.work_category.includes('2'))) {
        text = this.BaseText + 'numerous large failed limbs';
      } else if ((this.TreeData.work_category.includes('1'))) {
        text = this.BaseText + 'numerous large failed trees';
      }
    }
    return text;
  }


  damagesText(DamagesText) {
    var fenceDamageFlag = false;
    var roofDamageFlag = false;
    var otherDamageFlag = false;
    var houseFlag = false;
    var gutterFlag = false;
    var onlyHouseFlag = false;
    var HouseGutterFlag = false;
    // alert(this.TreeData.other);
    if (this.TreeData.fence != null && this.TreeData.fence !== '') {
      if ((this.TreeData.fence.includes('1')) || (this.TreeData.fence.includes('2')) || (this.TreeData.fence.includes('3')) || (this.TreeData.fence.includes('4')) || (this.TreeData.fence.includes('5'))) {
        fenceDamageFlag = true;
      }
    } if (this.TreeData.roof != null && this.TreeData.roof !== '') {
      if ((this.TreeData.roof.includes('1')) || (this.TreeData.roof.includes('2')) || (this.TreeData.roof.includes('3')) || (this.TreeData.roof.includes('4'))) {
        roofDamageFlag = true;
      }
    } if (this.TreeData.other != null && this.TreeData.other !== '') {
      if ((this.TreeData.other.includes('1')) || (this.TreeData.other.includes('2')) || (this.TreeData.other.includes('3'))
        || (this.TreeData.other.includes('4')) || (this.TreeData.other.includes('5')) || (this.TreeData.other.includes('6')) || (this.TreeData.other.includes('7'))) {
        otherDamageFlag = true;
        if ((this.TreeData.other.includes('5'))) {
          houseFlag = true;

          if (!((this.TreeData.other.includes('1')) || (this.TreeData.other.includes('2')) || (this.TreeData.other.includes('3'))
            || (this.TreeData.other.includes('4')) || (this.TreeData.other.includes('6')) || (this.TreeData.other.includes('7')))) {
            onlyHouseFlag = true;
          }
          if ((this.TreeData.other.includes('1'))) {
            if (!((this.TreeData.other.includes('2')) || (this.TreeData.other.includes('3'))
              || (this.TreeData.other.includes('4')) || (this.TreeData.other.includes('6')) || (this.TreeData.other.includes('7')))) {
              HouseGutterFlag = true;
            }
          }
        } if ((this.TreeData.other.includes('1'))) {
          gutterFlag = true;
        } // alert('HouseGutterFlag-'+HouseGutterFlag+' , onlyHouseFlag-'+onlyHouseFlag+' , HouseFlag- '+ houseFlag+' , GutterFlag -'+ gutterFlag);
      }
    } if (fenceDamageFlag && roofDamageFlag && otherDamageFlag) {
      //    DamagesText=DamagesText+" impacting onto and damaging boundary fence, roof and other manufactured structures." ;
      if (houseFlag) {
        if (onlyHouseFlag) {
          DamagesText = DamagesText + ' impacting onto and damaging boundary fence, house and roof.';
        } else if (HouseGutterFlag) {
          DamagesText = DamagesText + ' impacting onto and damaging boundary fence, house, roof and guttering.';
        } else {
          DamagesText = DamagesText + ' impacting onto and damaging boundary fence, house, roof, ' + this.JobService.ConvertOtherDamagesSubHouse(this.TreeData.other, houseFlag) + '.';
        }
      } else {
        DamagesText = DamagesText + ' impacting onto and damaging boundary fence, roof, ' + this.JobService.ConvertOtherDamagesSub(this.TreeData.other) + '.';
      }
    } else if (fenceDamageFlag && otherDamageFlag) {
      //    DamagesText=DamagesText+" impacting onto and damaging boundary fence and other manufactured structures.";
      DamagesText = DamagesText + ' impacting onto and damaging boundary fence, ' + this.JobService.ConvertOtherDamagesSub(this.TreeData.other) + '.';
    } else if (roofDamageFlag && otherDamageFlag) {
      //    DamagesText=DamagesText+" impacting onto and damaging the roof and other manufactured structures.";
      DamagesText = DamagesText + ' impacting onto and damaging roof, ' + this.JobService.ConvertOtherDamagesSubHouse(this.TreeData.other, houseFlag) + '.';
    } else if (otherDamageFlag) {
      DamagesText = DamagesText + ' impacting onto and damaging ' + this.JobService.ConvertOtherDamagesSub(this.TreeData.other) + '.';
    } else if (fenceDamageFlag && roofDamageFlag) {
      DamagesText = DamagesText + ' impacting onto and damaging boundary fence and roof.';
    } else if (fenceDamageFlag) {
      DamagesText = DamagesText + ' impacting onto and damaging boundary fence.';
    } else if (roofDamageFlag) {
      DamagesText = DamagesText + ' impacting onto and damaging roof.';
    }
    return DamagesText;
  }


  removalAccessText(Accesstext) {
    this.InscleanUpBasic = ' Remove all cut material and debris from site';
    // this.InscleanUpMakeSafe =  " Stack all cut material safely and leave on site.";
    this.InscleanUpMakeSafe = ' Stack all cut material and debris safely on site';
    this.notesInsuranceBasic = ' Leave site safe, clean and clear for further building services. NOTE: All works conducted within insured boundary fence line only.';
    //        this.InsPoorAccessExtraNote=" Very poor access, all cut material and debris to be carried manually to chipper for processing.";
    this.InsPoorAccessExtraNote = ' Limited access.';
    if ((this.TreeData.work_category.includes('6'))) {
      Accesstext = Accesstext + this.InscleanUpBasic
      if ((this.TreeData.work_category.includes('3'))) {
        Accesstext = Accesstext + ' including raised root plate.';
      } else {
        Accesstext = Accesstext + '.';
      }
      Accesstext += this.notesInsuranceBasic;
    } else {
      Accesstext = Accesstext + this.InscleanUpMakeSafe

      Accesstext += this.notesInsuranceBasic;
    }

    if (this.TreeData.access != null) {
      if (this.TreeData.access.includes('3')) {
        Accesstext = Accesstext + this.InsPoorAccessExtraNote;
      } else if (this.TreeData.access.includes('2')) {
        Accesstext = Accesstext + this.InsPoorAccessExtraNote;
      }
    }
    return Accesstext;

  }



  tree_WorkTypeTextSES(text) {
    this.BaseText = 'Attend site,';
    if (this.TreeData.no_tree == 1) {
      if ((this.TreeData.work_category.includes('1')) && (this.TreeData.work_category.includes('2'))) {
        //  text= this.BaseText + "carefully dismantle large failed tree and failed section of tree dangerously hung up and threatening to fall";
        text = this.BaseText + 'carefully dismantle large failed tree and failed section of tree';
      } else if ((this.TreeData.work_category.includes('1'))) {
        text = this.BaseText + 'carefully dismantle large failed tree';
      } else if ((this.TreeData.work_category.includes('2'))) {
        //  text= this.BaseText + " carefully dismantle large failed section of tree dangerously hung up and threatening to fall.";
        text = this.BaseText + ' carefully dismantle large failed section of tree';
      } else if ((this.TreeData.work_category.includes('3'))) {
        text = this.BaseText + 'carefully dismantle large uprooted stump';
      } else if ((this.TreeData.work_category.includes('6'))) {
        text = this.BaseText + 'carefully dismantle failed tree debris';
      }

    } else if (this.TreeData.no_tree == 2) {
      if ((this.TreeData.work_category.includes('1')) && (this.TreeData.work_category.includes('2'))) {
        //    text= this.BaseText + " carefully dismantle large failed tree and failed section of tree dangerously hung up and threatening to fall";
        text = this.BaseText + ' carefully dismantle large failed tree and failed section of tree';
      } else if ((this.TreeData.work_category.includes('2'))) {
        // text= this.BaseText + " carefully dismantle large failed sections of tree dangerously hung up and threatening to fall";
        text = this.BaseText + ' carefully dismantle large failed sections of tree';
      } else if ((this.TreeData.work_category.includes('1'))) {
        text = this.BaseText + 'carefully dismantle large failed trees';
      }
    } else if (this.TreeData.no_tree > 2) {
      if ((this.TreeData.work_category.includes('1')) && (this.TreeData.work_category.includes('2'))) {
        //      text= this.BaseText + " carefully dismantle numerous large failed trees and failed section of tree dangerously hung up and threatening to fall";
        text = this.BaseText + ' carefully dismantle numerous large failed trees and failed section of tree';
      } else if ((this.TreeData.work_category.includes('2'))) {
        //    text= this.BaseText + " carefully dismantle numerous large failed sections of tree dangerously hung up and threatening to fall";
        text = this.BaseText + ' carefully dismantle numerous large failed sections of tree';
      } else if ((this.TreeData.work_category.includes('1'))) {
        text = this.BaseText + ' carefully dismantle numerous large failed trees';
      }
    }
    return text;
  }


  damagesTextSES(DamagesText) {
    var fenceDamageFlag = false;
    var roofDamageFlag = false;
    var otherDamageFlag = false;
    if (this.TreeData.fence != null && this.TreeData.fence !== '') {
      if ((this.TreeData.fence.includes('1')) || (this.TreeData.fence.includes('2')) || (this.TreeData.fence.includes('3')) || (this.TreeData.fence.includes('4'))) {
        fenceDamageFlag = true;
      }
    } if (this.TreeData.roof != null && this.TreeData.roof !== '') {
      if ((this.TreeData.roof.includes('1')) || (this.TreeData.roof.includes('2')) || (this.TreeData.roof.includes('3'))) {
        roofDamageFlag = true;
      }
    } if (this.TreeData.other != null && this.TreeData.other !== '') {
      if ((this.TreeData.other.includes('1')) || (this.TreeData.other.includes('2')) || (this.TreeData.other.includes('3'))) {
        otherDamageFlag = true;
      }
    } if (fenceDamageFlag && roofDamageFlag && otherDamageFlag) {
      DamagesText = DamagesText + ' impacting onto and damaging boundary fence, roof and other manufactured structures.';
    } else if (fenceDamageFlag && otherDamageFlag) {
      DamagesText = DamagesText + ' impacting onto and damaging boundary fence and other manufactured structures.';
    } else if (roofDamageFlag && otherDamageFlag) {
      DamagesText = DamagesText + ' impacting onto and damaging roof and other manufactured structures.';
    } else if (otherDamageFlag) {
      DamagesText = DamagesText + ' impacting onto and damaging ' + this.JobService.ConvertOtherDamages(this.TreeData.other) + '.';
      // " other manufactured structures ";
    } else if (fenceDamageFlag && roofDamageFlag) {
      DamagesText = DamagesText + ' impacting onto and damaging boundary fence and roof.';
    } else if (fenceDamageFlag) {
      DamagesText = DamagesText + ' impacting onto and damaging boundary fence.';
    } else {
      DamagesText = DamagesText + '.';
    }
    return DamagesText;

  }


  removalAccessTextSES(Accesstext) {

    // this.SESMakeSafe=  " Stack all cut material safely and leave on site";
    this.SESMakeSafe = ' Stack all cut material and debris safely on site';
    //this.SESCleanUp =  " As approved by SES remove all cut material and debris from site";

    this.SESCleanUp = '';
    this.SESnotesBasic = ' Leave site safe, clean and clear.';
    this.SESEWPNotes = ' EWP will be required to undertake works in a safe manner.';

    if ((this.TreeData.work_category.includes('6'))) {
      if (this.SESRemoveDebris) {
        Accesstext = Accesstext + this.SESCleanUp;
      } else {
        Accesstext = Accesstext + this.SESMakeSafe;
      }
      if ((this.TreeData.work_category.includes('3'))) {
        Accesstext = Accesstext + ' including raised root plate.';
      } else {
        Accesstext = Accesstext + '.';
      }
    } else {
      if (this.SESRemoveDebris) {
        Accesstext = Accesstext + this.SESCleanUp;
      } else {
        Accesstext = Accesstext + this.SESMakeSafe + '.';

      }
    }
    Accesstext += this.SESnotesBasic;
    if (this.SESEWPInvolved) {
      Accesstext += this.SESEWPNotes;
    }
    /*if( this.TreeData.access!=null ){
       if(this.TreeData.access.includes('3')){
           Accesstext=Accesstext+this.InsPoorAccessExtraNote;
       }else if(this.TreeData.access.includes('2')){
           Accesstext=Accesstext+this.InsPoorAccessExtraNote;
       }
    }*/
    return Accesstext;

  }

  async selectAllToIncQuote(value) {
    if (value) {
      this.selectAll = true;
      for (let item of this.consWpQuoteList) {
        item.quote_inc = '2';
        item.quote_inc_ui = true;
      }

    } else {
      this.selectAll = false;
      for (let item of this.consWpQuoteList) {
        item.quote_inc = '1';
        item.quote_inc_ui = false;
      }
    }
    this.calcResultTotal();
    if (this.env.hosting == 'UK') {
      await this.checkCostDetailsList();
    }
  }

  async autoCalculateMargin() {
    let result = await this.CrudService.autoCalculateMargin(this.jobId, this.jobAllocationId, this.minThreshold ? this.minThreshold : '20', this.maxThreshold ? this.maxThreshold : '30', this.jobAllocationResults.info.WPCompanyId, 1, 1).toPromise();
    this.loading = true;
    this.consWpQuoteList = [];
    this.loading = false; this.disableSave = false;
    this.messageService.add({ severity: 'success', summary: 'Successful', detail: 'Auto Margin Calculation Success', life: 1000 });
    await this.loadData();

  }

  onCloseExceptionDialog(){
    this.loading=false;
    this.disableSave=false;
    this.displayException=false;
  }
}
