import { Component, OnInit } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { ConfirmationService, MessageService } from 'primeng/api';
import { DynamicDialogConfig, DynamicDialogRef, DialogService } from 'primeng/dynamicdialog';
import { CRUDService } from 'src/app/utilities/crud.service';
import { JOBService } from 'src/app/utilities/job.service';
import { JobAdminComponent } from '../../job/job-admin/job-admin.component';
import { JobDetailsApprovalsComponent } from 'src/app/job/job-details-approvals/job-details-approvals.component';

@Component({
  selector: 'app-jobs-list',
  templateUrl: './jobs-list.component.html',
  styleUrls: ['./jobs-list.component.scss'],
  providers: [DialogService, MessageService, ConfirmationService, JOBService]
})
export class JobsListComponent implements OnInit {
  category: any;
  subCatg: any;
  cols: any;
  selectedRecords: any;
  dataList: any[];
  exportColumns: any;
  loading: any;
  buckets: any;
  listOfJobs: any;
  jobKPITiles: any;
  speedDial: Boolean = false;
  bucket: any;
  catg: any;

  constructor(

    private crudService: CRUDService,
    private messageService: MessageService,
    private confirmationService: ConfirmationService,
    public ref: DynamicDialogRef,
    public config: DynamicDialogConfig,
    private dialogService: DialogService,
    public sanitizer: DomSanitizer,
    private router: Router,
    private JobService: JOBService

  ) { }

  viewDueIn = 0;
  
  ngOnInit() {
    this.selectedRecords = null;
    this.cols = [
      { field: 'JobNo', header: 'Job No' },
      { field: 'JobDate', header: 'Job Entry' },
      { field: 'req_by', header: 'Requested By' },
      { field: 'job_act_dt', header: 'Job Act Dt' },
      { field: 'ageing_status', header: 'Action Due Status' },
      { field: 'TPCost', header: 'Quote Amount' },
      { field: 'InvoiceAmount', header: 'Total' },
      { field: 'ClientSubtype', header: 'Client' },
      { field: 'TreeProfessional', header: 'Tree Professional' },
      { field: 'FullAddress', header: 'Site Address' },
      { field: 'job_claim_no', header: 'Claim No' },
      { field: 'SubStatus', header: 'Sub Status' },
      { field: 'LastComms', header: 'More Info' },
    ];

    //console.log('jobslist');
    if (typeof this.config.data !== 'undefined') {
      this.dataList = this.config.data.list;
      this.bucket = this.config.data.bucket;
      this.catg = this.config.data.catg;
    }
    let menuData = { para_1: 'TM' };
    sessionStorage.setItem('menuData', JSON.stringify(menuData));

    this.exportColumns = this.cols.map(col => ({ title: col.header, dataKey: col.field }));
    /* this.CrudService.getJobsForDashboard(this.category, this.subCatg).subscribe((res: any) => {
      //console.log(res);
      this.dataList = res;
    }) */
  }
  exportExcel(selectedDepots, table) {
    let JsonData: any[] = [];
    if (this.selectedRecords != null && this.selectedRecords.length > 0) {
      JsonData = this.selectedRecords;
    } else {
      if (typeof table.filteredValue !== 'undefined') {
        if (table.filteredValue.length !== this.dataList.length && table.filteredValue.length > 0) {
          JsonData = table.filteredValue;
        } else {
          JsonData = this.dataList;
        }
      } else {
        JsonData = this.dataList;
      }
    }

    let reformattedArray = JsonData.map(obj => {

      let rObj = {};
      this.cols.forEach(pair => {
        rObj[pair.header.toString()] = obj[pair.field];
      });
      return rObj;
    })

    import('xlsx').then(xlsx => {
      const worksheet = xlsx.utils.json_to_sheet(reformattedArray);
      const workbook = { Sheets: { data: worksheet }, SheetNames: ['data'] };
      const excelBuffer: any = xlsx.write(workbook, { bookType: 'xlsx', type: 'array' });
      this.saveAsExcelFile(excelBuffer, 'Joblist');
    });
  }

  saveAsExcelFile(buffer: any, fileName: string): void {
    import('file-saver').then(FileSaver => {
      const EXCEL_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
      const EXCEL_EXTENSION = '.xlsx';
      const data: Blob = new Blob([buffer], {
        type: EXCEL_TYPE
      });
      FileSaver.saveAs(data, fileName + '_export_' + new Date().getTime() + EXCEL_EXTENSION);
    });
  }

  exportPdf() {
    /* import('jspdf').then(jsPDF => {
      import('jspdf-autotable').then(x => {
      const doc = new jsPDF.default(0,0);
        if(typeof this.selectedRecords == 'undefined'){
            doc.autoTable(this.exportColumns, this.dataList);
           }else{
               doc.autoTable(this.exportColumns, this.selectedRecords);
        }
       doc.save('products.pdf');
      })
    })*/
  }

  async reloadList() {
    this.loading = true;
    let listOfJobs: any = await this.crudService.getDataByField('JobDashboardDataDump', 'TT', this.bucket.TT).toPromise();
    let jobsList = null;
    if (this.bucket != null && this.catg != null) {
      this.dataList = listOfJobs.filter(val => (val.Cat == this.catg));
    }
    this.loading = false;
  }

  async viewJobAction(job) {
    this.loading = true;
    if (job.JobStatus === 'Cost Saved' || job.JobStatus === 'Approve Quote' || job.JobStatus === 'Await Cost' || job.JobStatus === 'Quote Rejected') {
      const smryData: any = await this.crudService.getJobSummary(job.jobid, job.allocationid).toPromise();
      const jobAllocationResults = await this.JobService.getJobInfo(smryData[0]);
      const viewType = 'Quote';
      this.loading = false;
      /* this.dialogService.dialogComponentRef.destroy(); */
      const ref = this.dialogService.open(JobDetailsApprovalsComponent, {
        data: {
          jobId: job.jobid,
          allocId: job.allocationid,
          jobSummary: jobAllocationResults,
          viewType: viewType
        },
        width: '100%',
        height: '100%'
      })
      ref.onClose.subscribe(async (response: any) => {
        await this.reloadList();
      })
    } else if (job.JobStatus === 'Approve Invoice' || job.JobStatus === 'Await Invoice' || job.JobStatus === 'Accounts Processed' || job.JobStatus === 'Invoice Processed' || job.JobStatus === 'Accounts Proecessed' || job.JobStatus === 'Job Completed') {
      const smryData: any = await this.crudService.getJobSummary(job.jobid, job.allocationid).toPromise();
      const jobAllocationResults = await this.JobService.getJobInfo(smryData[0]);
      const viewType = 'Approval';
      this.loading = false;
      /*       this.dialogService.dialogComponentRef.destroy();*/
      const ref = this.dialogService.open(JobDetailsApprovalsComponent, {
        data: {
          jobId: job.jobid,
          allocId: job.allocationid,
          jobSummary: jobAllocationResults,
          viewType: viewType
        },
        width: '100%',
        height: '100%'
      })
      ref.onClose.subscribe(async (response: any) => {
        await this.reloadList();
      })
    } else if (job.JobStatus === 'Await Acceptance' || job.JobStatus === 'Send Offer' || job.JobStatus === 'Quote Rejected' ) {
      this.loading = false;
      this.editSelectedRecord(job);
    }
  }
  viewJob(job) {
    /* this.dialogService.dialogComponentRef.destroy(); */
    this.ref.close();
    this.router.navigate(['/job/details', job.jobid, job.allocationid])
  }
  editSelectedRecord(job) {
    /* this.dialogService.dialogComponentRef.destroy(); */
    const ref = this.dialogService.open(JobAdminComponent, {
      data: {
        jobId: job.jobid,
      },
      width: '100%',
      height: '100%'
    })
    ref.onClose.subscribe(async (response: any) => {
      await this.reloadList();
    })
  }

  switchView() {
    if (this.viewDueIn == 1) {
      this.viewDueIn = 0;
    } else {
      this.viewDueIn = 1;
    }
  }
}
