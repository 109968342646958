import { Component, OnInit } from '@angular/core';
import { BreadcrumbService } from 'src/app/breadcrumb.service';
import { SelectItem } from 'primeng/api';
import { ActivatedRoute, Router } from '@angular/router';
import { CRUDService } from 'src/app/utilities/crud.service';
import { ConfirmationService, MessageService } from 'primeng/api';
import { DialogService, DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { JobAdminComponent } from '../../job/job-admin/job-admin.component';
import { JOBService } from 'src/app/utilities/job.service';
import { JobOperationsComponent } from '../../job/job-operations/job-operations.component';
import { JobDetailsApprovalsComponent } from 'src/app/job/job-details-approvals/job-details-approvals.component';

@Component({
    selector: 'app-dashboard',
    templateUrl: './dashboard.component.html',
    styleUrls: ['./dashboard.component.css'],
    providers: [DialogService, MessageService, ConfirmationService, JOBService]
})
export class DashboardComponent implements OnInit {

    widgets: any[] = [];
    allMenus: any;
    companyId: any;
    userDetails: any;
    lineChartData: any;
    lineChartOptions: any;
    dropdownYears: SelectItem[];
    selectedYear: any;
    activeNews = 1;
    cars: any[];
    selectedCar: any;
    events: any[];
    jobWidgets: any;
    taskWidgets: any;
    jobsList: any[];
    displayDrillDown: Boolean = false;
    JobStatus: any;
    loading: Boolean = false;
    companyList: any;
    tpTaskJobs: any[] = [];
    TPCols = [
        { field: 'business_name', header: 'TP Name' },
        { field: 'count', header: 'Count' }
    ];

    redirectPage(module: string) {
        this.router.navigate([module]);
    }

    constructor(private breadcrumbService: BreadcrumbService,
        private route: ActivatedRoute,
        private crudService: CRUDService,
        private messageService: MessageService,
        private confirmationService: ConfirmationService,
        private router: Router,
        public ref: DynamicDialogRef,
        public config: DynamicDialogConfig,
        public dialogService: DialogService,
        private JobService: JOBService) {
        this.companyId = localStorage.getItem('company_id');
        this.userDetails = JSON.parse(localStorage.getItem('loggedInUser'));
        //console.log('dashboard');
        this.breadcrumbService.setItems([
            // {label: 'Dashboard', routerLink: ['/']}
        ]);

        this.crudService.getMenusByRole(this.userDetails.user_group_id, this.companyId).subscribe((data: any[]) => {
            //console.log(data);
            this.allMenus = [];
            data = data.filter(val => val.status !== '2');
            data = data.filter(val => (val.menu_type === '2' || val.menu_type === '3'));
            data = data.filter(val => val.menu_parent_id !== '0');
            data.forEach(tile => {
                tile.randomNumber = Math.floor(Math.random() * 4) + 1;
                this.allMenus.push(tile);
            });

            /* let userItems = data.filter(val => val.menu_type === '1');
            this.widgets.push({label: 'My Issues & Requests', items: userItems});

            let execItems = data.filter(val => val.menu_type === '2');
            this.widgets.push({label: 'Pending For Action', items: execItems});

            let adminItems = data.filter(val => val.menu_type === '3');
            this.widgets.push({label: 'Manage', items: adminItems}); */
        });
    }

    loadTaskTiles() {
        this.crudService.DashTaskBoardView().subscribe((data: any[]) => {
            this.taskWidgets = data[0];
        });
    }
    loadJobTiles() {
        this.crudService.DashJobStatusView().subscribe((data: any[]) => {
            data.sort((a, b) => a.display_order - b.display_order);
            data.forEach(tile => {
                tile.color = 'linear-gradient(256deg, ' + tile.display_colour + ' 0%,black 165%)';
                tile.randomNumber = Math.floor(Math.random() * 4) + 1;
            });
            this.jobWidgets = data;
            //console.log(data);
        });
    }
    async ngOnInit() {
        this.loading = true;
        this.loadJobTiles();
        this.loadTaskTiles();
        await this.loadTaskJobs();
        this.lineChartData = {
            labels: ['January', 'February', 'March', 'April', 'May', 'June', 'July'],
            datasets: [
                {
                    label: 'Sapphire',
                    data: [1, 2, 5, 3, 12, 7, 15],
                    borderColor: [
                        '#45b0d5'
                    ],
                    borderWidth: 3,
                    fill: false
                },
                {
                    label: 'Roma',
                    data: [3, 7, 2, 17, 15, 13, 19],
                    borderColor: [
                        '#d08770'
                    ],
                    borderWidth: 3,
                    fill: false
                }
            ]
        };
        this.lineChartOptions = {
            responsive: true,
            maintainAspectRatio: true,
            fontFamily: '\'Candara\', \'Calibri\', \'Courier\', \'serif\'',
            hover: {
                mode: 'index'
            },
            scales: {
                xAxes: [{
                    gridLines: {
                        display: false
                    },
                    ticks: {
                        fontColor: '#9199a9'
                    }
                }],
                yAxes: [{
                    gridLines: {
                        display: false
                    },
                    ticks: {
                        fontColor: '#9199a9'
                    }
                }]
            },
            legend: {
                display: true,
                labels: {
                    fontColor: '#9199a9'
                }
            }
        };

        this.dropdownYears = [
            { label: '2019', value: 2019 },
            { label: '2018', value: 2018 },
            { label: '2017', value: 2017 },
            { label: '2016', value: 2016 },
            { label: '2015', value: 2015 },
            { label: '2014', value: 2014 }
        ];
    }

    redirect(item) {
        //console.log(item);
        window.sessionStorage.setItem('menuData', JSON.stringify(item));
        if (item.router_url != null) {
            this.router.navigateByUrl(item.router_url);
        } else {
            this.router.navigateByUrl('/coming-soon');
        }
    }

    viewDrillDown(JobStatus) {
        /* this.crudService.getDashJobStatusDrillDown(JobStatus).subscribe((data: any[]) => {
            this.jobsList = data;
            //console.log(data);
            this.JobStatus = JobStatus;
        }); */
        this.crudService.getDataByField('Menus', 'id', '1').subscribe((data: any[]) => {
            const menuData = data[0];
            //console.log(data);
            window.sessionStorage.setItem('menuData', JSON.stringify(menuData));

            const ref = this.dialogService.open(JobOperationsComponent, {
                data: {
                    jobStatus: JobStatus,
                    reqId: this.companyId,
                    filter_by: null
                },
                width: '100%',
                height: '100%',
                styleClass: '',
                header: 'Job Operations - ' + JobStatus
            })
            ref.onClose.subscribe((response: any) => {
                this.loadJobTiles();
            })
        });
    }

    viewTask() {
        this.crudService.getDataByField('Menus', 'id', '83').subscribe((data: any[]) => {
            const menuData = data[0];
            //console.log(data);
            window.sessionStorage.setItem('menuData', JSON.stringify(menuData));
            this.router.navigateByUrl(menuData.router_url)
        })
    }

    async viewJobAction(job) {
        this.crudService.getDataByField('Menus', 'id', '83').subscribe((data: any[]) => {
            const menuData = data[0];
            //console.log(data);
            window.sessionStorage.setItem('menuData', JSON.stringify(menuData));
        });
        this.loading = true;
        if (this.JobStatus === 'Cost Saved' || this.JobStatus === 'Approve Quote' || this.JobStatus === 'Await Cost' || this.JobStatus === 'Quote Rejected') {
            const smryData: any = await this.crudService.getJobSummary(job.jobid, job.AllocationId).toPromise();
            const jobAllocationResults = await this.JobService.getJobInfo(smryData[0]);
            const viewType = 'Quote';
            this.loading = false;
            const ref = this.dialogService.open(JobDetailsApprovalsComponent, {
                data: {
                    jobId: job.jobid,
                    allocId: job.AllocationId,
                    jobSummary: jobAllocationResults,
                    viewType,
                    defaultTab: 'schedule'
                },
                width: '100%',
                height: '100%'
            })
        } else if (this.JobStatus === 'Approve Invoice' || this.JobStatus === 'Await Invoice' || this.JobStatus === 'Invoice Processed' || this.JobStatus === 'Accounts Proecessed' || this.JobStatus === 'Job Completed') {
            const smryData: any = await this.crudService.getJobSummary(job.jobid, job.AllocationId).toPromise();
            const jobAllocationResults = await this.JobService.getJobInfo(smryData[0]);
            const viewType = 'Approval';
            this.loading = false;
            const ref = this.dialogService.open(JobDetailsApprovalsComponent, {
                data: {
                    jobId: job.jobid,
                    allocId: job.AllocationId,
                    jobSummary: jobAllocationResults,
                    viewType,
                    defaultTab: 'schedule'
                },
                width: '100%',
                height: '100%'
            })
        } else if (this.JobStatus === 'Await Acceptance' || this.JobStatus === 'Send Offer') {
            this.loading = false;
            this.editSelectedRecord(job);
        } else if (this.JobStatus === 'Quote Rejected' || this.JobStatus === 'Send Offer') {
            this.loading = false;
            this.editSelectedRecord(job);
        }
    }

    editSelectedRecord(job) {
        const ref = this.dialogService.open(JobAdminComponent, {
            data: {
                jobId: job.jobid,
            },
            width: '100%',
            height: '100%'
        })
    }

    viewJob(job) {
        this.router.navigate(['/job/job-details', job.jobid, job.AllocationId])
    }

    async loadTaskJobs() {
        this.companyList = await this.crudService.getDataByField('Company', 'client_type_id', '3').toPromise();
        let tasksList: any = await this.crudService.getDataByField('SSPSearchView', 'wf_code', '7').toPromise();
        let TPList = [...new Set(tasksList.map(item => item.info_2))];
        TPList.forEach(tp => {
            let tasks = tasksList.filter(val => (val.info_2 == tp));
            let companyInfo = this.companyList.find(val => (val.business_name == tp));
            this.tpTaskJobs.push({ business_name: tp, count: tasks.length, tp_info: companyInfo });
        })
    }
}