import { Component, OnChanges, Input, OnInit } from '@angular/core';
import { CdkDragDrop, moveItemInArray, transferArrayItem } from '@angular/cdk/drag-drop';
import { ActivatedRoute, Router } from '@angular/router';
import { ConfirmationService, MessageService } from 'primeng/api';
import { DialogService, DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { CRUDService } from 'src/app/utilities/crud.service';
import { WorkFlowService } from 'src/app/utilities/workflow.service';
import { CommonService } from 'src/app/utilities/common.service';
import { JOBService } from 'src/app/utilities/job.service';
import { AnyRecordWithTtl } from 'dns';
import { ScheduleAdminComponent } from '../schedule-admin/schedule-admin.component';
import { JobDetailsApprovalsComponent } from 'src/app/job/job-details-approvals/job-details-approvals.component';

@Component({
  selector: 'app-job-checklist',
  templateUrl: './job-checklist.component.html',
  styleUrls: ['./job-checklist.component.scss'],
  providers: [DialogService, MessageService, ConfirmationService,JOBService,ScheduleAdminComponent]
  
})
export class JobChecklistComponent implements OnChanges, OnInit {

  @Input() AllocationId: any;
  @Input() JobId: any;
  companyId: any;
  userDetails: any;
  date: Date;
  checkListData : any;
  checkListItem : any;
  status : any;
  description : any;
  reported_at : any;
  loading : Boolean = false;
  taskPercent : any;
  jobSummary : any;
  showHeader : Boolean = false;
  submitted : Boolean = false;
  totalChecklist : any;
  inActivecheckList : any;
  flagData : any;
  viewType : any;
  defaultTab : any;
  defaultView : any;

  constructor(
    private route: ActivatedRoute,
    private crudService: CRUDService,
    private messageService: MessageService,
    private confirmationService: ConfirmationService,
    private commonservice: CommonService,
    private router: Router,
    private JobService: JOBService,
    public ref: DynamicDialogRef,
    private wfService: WorkFlowService,
    public config: DynamicDialogConfig,
    public dialogService: DialogService
  ) { }

  async ngOnInit(){
    this.loading = true;
    this.taskPercent = 0;
    this.totalChecklist = 0;
    this.inActivecheckList = 0;
    this.companyId = sessionStorage.getItem('company_id');
    this.userDetails = JSON.parse(sessionStorage.getItem('loggedInUser'));
    if(this.AllocationId != '1')
    {
      const smryData: any = await this.crudService.getJobSummary(this.JobId,this.AllocationId).toPromise();
      this.jobSummary = await this.JobService.getJobInfo(smryData[0]);
      this.showHeader = true;
    }
    else
    {
      this.showHeader = false;
    }
    
    this.checkListItem = {
      id: null,
      job_id: this.JobId,
      job_alloc_id: this.AllocationId,
      status: '1',
      checklist_code : 'Others',
      remarks : 'none',
      reported_at : new Date(),
      created_by: this.userDetails.id,
      created_at: new Date(),
      completed_at : '',
      origin : '1',
      description : ''
    }
    this.ngOnChanges();
    this.loadData();
    this.loading = false;

  }

  ngOnChanges() {

  }

  convertStringToDate(list){
    if(list && list.length > 0) {
      list.forEach(l => {
        if(l.reported_at) {
          l.reported_at = new Date(l.reported_at);
        }
      });
    }
  }

  
  numberRoundDecimal(v,n) {
    if(v == "NaN")
    {
      return "0";
    }
    else
    {
      return Math.round((v+Number.EPSILON)*Math.pow(10,n))/Math.pow(10,n)
    }
  }

  async loadData(){
    debugger
    this.loading  = true;
    this.crudService.getDataByField('job_wf_status', 'job_alloc_id', this.AllocationId).subscribe(async (data : any[]) => {
      this.flagData = data.sort((a, b) => b.flag.localeCompare(a.flag));
    });
    this.crudService.getDataByField('JobAllocationChecklist', 'job_id', this.JobId).subscribe(async (data : any[]) => {
      this.checkListData = data.sort((a, b) => a.id.localeCompare(b.id));
      if(this.checkListData.length>0)
      {
          this.checkListData = this.checkListData.filter(val => val.job_alloc_id === this.AllocationId);
          if(this.checkListData.length>0)
          {
            this.checkListData.forEach(item => {
              if (item.status == '2') {
                item.isChecked = true;
              } else {
                item.isChecked = false;
              }
            });
            this.convertStringToDate(this.checkListData);
            this.totalChecklist = this.checkListData.length
            this.inActivecheckList = this.checkListData.filter(val => val.status === '2').length;
            this.taskPercent = this.numberRoundDecimal((this.inActivecheckList*100)/this.totalChecklist,0);
            
          }
        }
    });
   
    this.loading  = false;
  }

  async viewJobAction(checklist_code)
  {
    this.loading = true;
    const smryData: any = await this.crudService.getJobSummary(this.JobId, this.AllocationId).toPromise();
    const jobAllocationResults = await this.JobService.getJobInfo(smryData[0]);
    console.log("smryData",smryData[0].JobStatus)
    let JobStatus = smryData[0].JobStatus;
    if (JobStatus === 'Cost Saved' || JobStatus === 'Approve Quote' || JobStatus === 'Await Cost' || JobStatus === 'Quote Rejected') {
      this.viewType = 'Quote';
    }
    else if(JobStatus === 'Approve Invoice' || JobStatus === 'Await Invoice' || JobStatus === 'Accounts Processed'
    || JobStatus === 'Invoice Processed' || JobStatus === 'Accounts Proecessed' || JobStatus === 'Job Completed'
    || JobStatus === 'Job Cancelled' || JobStatus === 'Allocation Cancelled')
    {
      this.viewType = 'Approval';
    }

    if(checklist_code == "BFR_IMG" || checklist_code == "AFT_IMG")
    {
      this.defaultTab = "images";
      this.defaultView = 1;
    }
    else if(checklist_code == "PO_DOC")
    {
      this.defaultTab = "documents";
      this.defaultView = 1;
    }
    else if(checklist_code == "SCHD_WORK" || checklist_code == "SITE_INSP")
    {
      this.defaultTab = "schedule";
      this.defaultView = 2;
    }
    else if(checklist_code == "QUOTE_APPR")
    {
      this.defaultTab = "quoteapproval";
      this.defaultView = 1;
    }
    else if(checklist_code == "WORK_INFO")
    {
      this.defaultTab = "treeinfo";
      this.defaultView = 1;
    }
    else if(checklist_code == "TP_COSTING")
    {
      this.defaultTab = "costing";
      this.defaultView = 1;
    }
    else if(checklist_code == "WP_COSTING")
    {
      this.defaultTab = "wpcosting";
      this.defaultView = 1;
    }
    else if(checklist_code == "INVOICE")
    {
      this.defaultTab = "sendemail";
      this.defaultView = 1;
    }
    else if(checklist_code == "P_INVOICE")
    {
      this.defaultTab = "jobinvoicesend";
      this.defaultView = 1;
    }
    else if(checklist_code == "CSO" || checklist_code == "KPI")
    {
      this.defaultTab = "kpi";
      this.defaultView = 1;
    }
    else if(checklist_code == "SHF")
    {
      this.defaultTab = "shf";
      this.defaultView = 1;
    }
    else if(checklist_code == "SCA")
    {
      this.defaultTab = "sca";
      this.defaultView = 1;
    }
    this.loading = false;
    if(this.defaultView == 1) // Job Approval Tab View
    {
      const ref = this.dialogService.open(JobDetailsApprovalsComponent, {
        data: {
          jobId: this.JobId,
          allocId: this.AllocationId,
          jobSummary: jobAllocationResults,
          viewType: this.viewType,
          selectedJobAllocId: this.AllocationId,
          defaultTab : this.defaultTab
        },
        width: '100%',
        height: '100%'
      });
      ref.onClose.subscribe((response: any) => {
        this.loadData();
      });
    }
    else if(this.defaultView == 2) //schedule Create View
    {
      const ref = this.dialogService.open(ScheduleAdminComponent, {
        data: {
          jobAllocationId: this.AllocationId,
          jobId: this.JobId,
          type: 'Schedule',
          jobSummary: jobAllocationResults
        },
        header: 'Job Schedule',
        width: '100%',
        height: '100%'
      });
      ref.onClose.subscribe((response: any) => {
        this.loadData();
      });

    }
    
  }

  async viewJobActionFlag(step)
  {
    this.loading = true;
    const smryData: any = await this.crudService.getJobSummary(this.JobId, this.AllocationId).toPromise();
    const jobAllocationResults = await this.JobService.getJobInfo(smryData[0]);
    console.log("smryData",smryData[0].JobStatus)
    let JobStatus = smryData[0].JobStatus;
    if (JobStatus === 'Cost Saved' || JobStatus === 'Approve Quote' || JobStatus === 'Await Cost' || JobStatus === 'Quote Rejected') {
      this.viewType = 'Quote';
    }
    else if(JobStatus === 'Approve Invoice' || JobStatus === 'Await Invoice' || JobStatus === 'Accounts Processed'
    || JobStatus === 'Invoice Processed' || JobStatus === 'Accounts Proecessed' || JobStatus === 'Job Completed'
    || JobStatus === 'Job Cancelled' || JobStatus === 'Allocation Cancelled')
    {
      this.viewType = 'Approval';
    }
    if(step == "schedule")
    {
      this.defaultTab = "schedule";
    }
    else if(step == "documents")
    {
      this.defaultTab = "documents";
    }
    else if(step == "images")
    {
      this.defaultTab = "images";
    }
    else if(step == "treeinfo")
    {
      this.defaultTab = "treeinfo";
    }
    else if(step == "costing")
    {
      this.defaultTab = "costing";
    }
    else if(step == "wpcosting")
    {
      this.defaultTab = "wpcosting";
    }
    else if(step == "shf")
    {
      this.defaultTab = "shf";
    }
    else if(step == "sca")
    {
      this.defaultTab = "sca";
    }
    else if(step == "kpi")
    {
      this.defaultTab = "kpi";
    }
    else if(step == "jobinvoicesend")
    {
      this.defaultTab = "jobinvoicesend";
    }
    else if(step == "sendemail")
    {
      this.defaultTab = "sendemail";
    }
    this.loading = false;
    const ref = this.dialogService.open(JobDetailsApprovalsComponent, {
      data: {
        jobId: this.JobId,
        allocId: this.AllocationId,
        jobSummary: jobAllocationResults,
        viewType: this.viewType,
        selectedJobAllocId: this.AllocationId,
        defaultTab : this.defaultTab
      },
      width: '100%',
      height: '100%'
    });
    ref.onClose.subscribe((response: any) => {
      this.loadData();
    });
    
  }

  async onValueChange(value, item) {
    if(value) {
      item.description = value;
    }
    console.log(item);
    let response = await this.commonservice.saveRecord('JobAllocationChecklist', item);
  }

  onFocusInput(event: any) {
    event.target.classList.add('editable');
  }
  onBlurInput(event: any, item) {
    event.target.classList.remove('editable');
    this.onValueChange(event.target.value, item);
  }
  setDate(event: any, item) {
    this.onValueChange(null, item);
  }

  async removeItem(item)
  {
    this.loading  = true;
    debugger
    this.crudService.deleteRecordByID('JobAllocationChecklist',item.id).subscribe((data: any) => {
      console.log("Data",data);
      if (data.success == 1) {
        this.checkListData = this.checkListData.filter((val: any) => val.id !== item.id)
        
      }
    });
    this.messageService.add({ severity: 'error', summary: 'Successful', detail: 'Task Deleted', life: 1000 });
      this.loading  = false;
      this.loadData();
  }

  async changeStatus(item)
  {
    if(item.isChecked)
    {
      item.status = 2;
    }
    else
    {
      item.status = 1;
    }
    item.updated_at = this.commonservice.getCurrentDateTime();
    item.completed_at = this.commonservice.getCurrentDateTime();
    let response = await this.commonservice.saveRecord('JobAllocationChecklist', item);
    if(response.success==true)
    {
      this.messageService.add({ severity: 'success', summary: 'Successful', detail: 'Updated Successfully', life: 1000 });
    }
    this.loadData();
  }

  async addCheckListItem() {
    this.loading  = true;
    this.submitted = true;
    debugger
    if(this.submitted && (this.description != "" && this.description != undefined && this.description != '') && (this.reported_at != "" && this.reported_at != undefined && this.reported_at != ''))
    {
    this.checkListItem.description = this.description;
    if(this.status)
    {
      this.checkListItem.status = 2;
    }
    else
    {
      this.checkListItem.status = 1;
    }
   
    this.checkListItem.reported_at = this.reported_at;

    let response = await this.commonservice.saveRecord('JobAllocationChecklist', this.checkListItem);
    if(response.success==true)
    {
      this.messageService.add({ severity: 'success', summary: 'Successful', detail: 'Task Added Successfully', life: 1000 });
    }
    this.loadData();
    this.status = "";
    this.description = "";
    this.reported_at = "";
    this.submitted = false;
  }
  else
  {
    this.messageService.add({ severity: 'error', summary: 'Alert', detail: 'Please Fill All the Fields', life: 1000 });
  }
  this.loading  = false;
  }

}

