import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ConfirmationService, MessageService } from 'primeng/api';
import { CRUDService } from 'src/app/utilities/crud.service';
import { DynamicDialogRef, DialogService } from 'primeng/dynamicdialog';
import { Calendar } from '@fullcalendar/core'; // include this line
import dayGridPlugin from '@fullcalendar/daygrid';
import timeGridPlugin from '@fullcalendar/timegrid';
import interactionPlugin, { Draggable } from '@fullcalendar/interaction';
import listPlugin from '@fullcalendar/list';
import { JobAdminComponent } from '../job-admin/job-admin.component';
import { CommonService } from 'src/app/utilities/common.service';
import { JobUserComponent } from '../../modules/job-user/job-user.component';
import { MenuItem } from 'primeng/api';
import { PanelMenuModule } from 'primeng/panelmenu';

@Component({
  selector: 'app-schedule-create',
  templateUrl: './schedule-create.component.html',
  styleUrls: ['./schedule-create.component.scss'],
  providers: [DialogService, MessageService, ConfirmationService]

})
export class ScheduleCreateComponent implements OnInit {

  companyId: any;
  staffList: any[];
  selectedStaff: any[];
  equipmentList: any[];
  selectedEquipment: any[];
  selectedDate: Date;
  jobList: any[];
  selectedJob: any;
  minDate: Date;
  maxDate: Date;
  events: any[];
  options: any;
  scheduleFor: any;
  displayPanel: boolean = true;
  display: boolean = false;
  orgStaff: any[];
  orgEquipments: any[];
  scheduleHeadList: any[];
  event: any;
  displayEventDialog: boolean;
  rangeDates: Date[];
  eventStaff: any[];
  eventEquipments: any[];
  eventJob: any;
  displayMenu: any;
  menuData: any;
  viewType: any;
  userDetails: any;
  appProps: any;

  ScheduleHead = {
    id: null,
    created_by: null,
    job_id: null,
    job_alloc_id: null,
    schedule_start_time: null,
    schedule_end_time: null,
    event_type: null,
    notes: null,
    priority: null,
    updated_by: null,
    status: null,
    allday: null,
    schedule_start_date: null,
    schedule_end_date: null,
    created_at: null,
    updated_at: null,
    staff: null,
    equipments: null,
  }

  ScheduleDetailModel = {
    id: null,
    head_id: null,
    staff_id: null,
    status: null,
    access_type: null,
    created_by: null,
    updated_by: null,
    created_at: null,
    updated_at: null,
  }

  ScheduleEquipmentDetailModel = {
    id: null,
    head_id: null,
    staff_id: null,
    equipment_id: null,
    status: null,
    access_type: null,
    created_by: null,
    updated_by: null,
    created_at: null,
    updated_at: null,
  }
  jobMenuModel: MenuItem[];
  items: MenuItem[];

  // tslint:disable-next-line: max-line-length
  constructor(private router: Router, private crudService: CRUDService, private messageService: MessageService, private confirmationService: ConfirmationService,
    private dialogService: DialogService,private commonservice: CommonService) {
    this.companyId = sessionStorage.getItem('company_id');
    this.userDetails = sessionStorage.getItem('loggedInUser');
  }

  ngOnInit() {
    // this.menuData = JSON.parse(sessionStorage.getItem('menuData'));
    // this.viewType = this.menuData.para_1;
    this.appProps = this.commonservice.getAppProperties();
    this.scheduleFor = 'Click on Calendar Icon in Staff or Equipments to View Respective Schedule'
    this.companyId = sessionStorage.getItem('company_id');
    this.crudService.getStaffsList(this.companyId).subscribe((staffData: any[]) => {
      this.staffList = staffData;
    });

    this.crudService.getEquipmentsList(this.companyId).subscribe((equipmentData: any[]) => {
      this.equipmentList = equipmentData;
    });

    this.crudService.getJobsList(this.companyId).subscribe((data: any[]) => {
      this.jobList = data;
      //console.log(this.jobList);
    });
    this.items = [{
      label: 'Options',
      items: [{
        label: 'Update',
        icon: 'pi pi-refresh',
        command: () => {
          this.update();
        }
      },
      {
        label: 'Delete',
        icon: 'pi pi-times',
        command: () => {
          this.delete();
        }
      }
      ]
    },
    {
      label: 'Navigate',
      items: [{
        label: 'Angular',
        icon: 'pi pi-external-link',
        url: 'http://angular.io'
      },
      {
        label: 'Router',
        icon: 'pi pi-upload',
        routerLink: '/fileupload'
      }
      ]
    }
    ];
    this.jobMenuModel = [
      {
        label: 'View/Edit Job Info', icon: 'pi pi-fw pi-check-square'/* , command: (event) => {
          this.viewJobDetails(event.item);
          //event.originalEvent: Browser event
          //event.item: menuitem metadata
        } */
      },
      { label: 'Schedule/Communications', icon: 'pi pi-fw pi-id-card', routerLink: ['/allcompanies'] },
      { label: 'Documents', icon: 'pi pi-fw pi-check-square', routerLink: ['/allcontractors'], state: { id: 'contractor', para_1: 'TM' } },
      {
        label: 'Images', icon: 'pi pi-fw pi-users',
        items: [
          { label: '4 Before Images', icon: 'pi pi-fw pi-check-square', routerLink: ['/job-images'] },
          { label: '2 After Images', icon: 'pi pi-fw pi-id-card', routerLink: ['/job-images'] },
          { label: 'Add New Image', icon: 'pi pi-fw pi-id-card', routerLink: ['/job-images'], state: { id: 'client', para_1: 'TP' } }]
      },
      { label: 'Costing', icon: 'pi pi-fw pi-user', routerLink: ['/allstaffs'], state: { id: 'staff', para_1: 'TM' } },
      {
        label: 'Others', icon: 'pi pi-fw pi-user',
        items: [
          { label: 'My Business', icon: 'pi pi-fw pi-check-square', routerLink: ['/companies'] },
          { label: 'Contractors', icon: 'pi pi-fw pi-id-card', routerLink: ['/contractors'], state: { id: 'contractor', para_1: 'TP' } },
          { label: 'Clients', icon: 'pi pi-fw pi-id-card', routerLink: ['/clients'], state: { id: 'client', para_1: 'TP' } },
          { label: 'Staff', icon: 'pi pi-fw pi-users', routerLink: ['/staffs'], state: { id: 'staff', para_1: 'TP' } },
          { label: 'Depot', icon: 'pi pi-fw pi-user', routerLink: ['/depots'], state: { id: 'depot', para_1: 'TP' } },
          { label: 'Change password', icon: 'pi pi-fw pi-user', routerLink: ['/changePassword'] }
        ]
      },
    ];
    this.companyId = sessionStorage.getItem('company_id');
    this.events = [];
    this.fetchEvents();
    this.options = {
      initialView: 'dayGridMonth',
      plugins: [dayGridPlugin, timeGridPlugin, interactionPlugin, listPlugin],
      nowIndicator: true,
      minTime: '09:00:00',
      maxTime: '19:00:00',
      dayHeaders: false,
      defaultView: 'dayGridMonth',
      header: {
        left: 'prev,today,next',
        center: 'title',
        right: 'dayGridMonth,timeGridWeek,timeGridDay,listMonth'
      },
      editable: true,
      nextDayThreshold: '09:00:00',
      dateClick: (e) => {
        this.dateClicked(e);
      },
      eventClick: (e) => {
        this.showSelectedEvent(e);
      },
      eventDrop: (e) => {
        alert('eventChanged : ');
        this.eventChanged(e);
      },
    };

    this.selectedDate = new Date();
    this.minDate = new Date();
    //this.maxDate = new Date(this.minDate.getDate() + 90);
    this.maxDate = new Date(new Date().getTime() + (1000 * 60 * 60 * 24 * 90));
  }

  selectStaff(staff: any) {
    this.messageService.add({ severity: 'info', summary: 'Staff Selected', detail: staff.first_name + ' ' + staff.last_name });
  }

  onRowSelect(event) {
    const staff = event.data;
    this.messageService.add({ severity: 'info', summary: 'Staff Selected', detail: staff.first_name + ' ' + staff.last_name });
  }

  onRowUnselect(event) {
    const staff = event.data;
    this.messageService.add({ severity: 'info', summary: 'Staff Un-Selected', detail: staff.first_name + ' ' + staff.last_name });
  }


  viewSchedule(staff: any) {
    this.scheduleFor = 'Displaying schedule for Staff  - ' + staff.first_name + ' ' + staff.last_name;
    this.events = [];
    this.crudService.getScheduleHeadByAny(staff.id, '0').subscribe((data: any[]) => {
      this.scheduleHeadList = data;
      for (let i = 0; i < this.scheduleHeadList.length; i++) {
        const event: any = {};
        if (this.scheduleHeadList[i].job_id !== '' && this.scheduleHeadList[i].job_id !== null) {
          event.title = 'Job Id : ' + this.scheduleHeadList[i].job_id;
          event.backgroundColor = 'blue';
          event.borderColor = 'black';
        } else {
          event.title = 'Non Job Schedule :' + this.scheduleHeadList[i].id;
          event.backgroundColor = 'orange';
          event.borderColor = 'black';
        }
        event.title = event.title + ' Staff : ' + this.scheduleHeadList[i].staff;
        event.title = event.title + ' Equipments : ' + this.scheduleHeadList[i].equipments;
        event.start = this.scheduleHeadList[i].schedule_start_date;
        event.end = this.scheduleHeadList[i].schedule_end_date;
        event.id = this.scheduleHeadList[i].id;
        this.events = [...this.events, event];
      }
    });
  }

  viewJobDetails(job: any) {
    if (this.viewType === 'TM') {
      const ref = this.dialogService.open(JobAdminComponent, {
        data: {
          JobId: this.eventJob.JobId,
        },
        header: 'Job Info',
        width: '100%',
        height: '100%'
      })

    } else {
      const ref = this.dialogService.open(JobUserComponent, {
        data: {
          JobId: this.eventJob.JobId,
        },
        header: 'Job Info',
        width: '100%',
        height: '100%'
      })
    }
  }

  viewEquipmentDetails(equipment: any) {
    this.scheduleFor = 'Displaying schedule for Equipment  - ' + equipment.equipment_name;
    this.events = [];
    this.crudService.getScheduleHeadByAny('0', equipment.id).subscribe((data: any[]) => {
      this.scheduleHeadList = data;
      for (let i = 0; i < this.scheduleHeadList.length; i++) {
        const event: any = {};
        if (this.scheduleHeadList[i].job_id !== '' && this.scheduleHeadList[i].job_id !== null) {
          event.title = 'Job Id : ' + this.scheduleHeadList[i].job_id;
          event.backgroundColor = 'blue';
          event.borderColor = 'black';
        } else {
          event.title = 'Non Job Schedule :' + this.scheduleHeadList[i].id;
          event.backgroundColor = 'orange';
          event.borderColor = 'black';
        }
        event.title = event.title + ' Staff : ' + this.scheduleHeadList[i].staff;
        event.title = event.title + ' Equipments : ' + this.scheduleHeadList[i].equipments;
        event.start = this.scheduleHeadList[i].schedule_start_date;
        event.end = this.scheduleHeadList[i].schedule_end_date;
        event.id = this.scheduleHeadList[i].id;
        this.events = [...this.events, event];
      }
    });
  }

  generateSchedule() {
    this.displayPanel = false;
    this.displayEventDialog = true;
    this.displayMenu = true;
    this.eventStaff = this.selectedStaff;
    this.eventEquipments = this.selectedEquipment;
    this.eventJob = this.selectedJob;

  }
  hideDialog() {
    //this.fetchEventDetails();
    this.displayEventDialog = false;
  }

  saveSchedule() {
    if (this.eventJob !== null) {
      //console.log('selectedJob', this.eventJob);
      this.ScheduleHead.job_id = this.eventJob.JobId;
    }
    //  this.ScheduleHead.job_id = this.selectedJob.JobId;
    this.ScheduleHead.event_type = 'JOB';
    if (this.ScheduleHead.id !== '' && this.ScheduleHead.id !== null) {
      this.crudService.Update(this.ScheduleHead, 'ScheduleHead').subscribe((data: any) => {
        this.saveStaffDetails();
        this.saveEquipmentDetails();
        this.messageService.add({ severity: 'success', summary: 'Successful', detail: 'Schedule Updated', life: 1000 });
      });
      this.fetchEvents();
    } else {
      this.crudService.Create(this.ScheduleHead, 'ScheduleHead').subscribe((data: any) => {
        if (data.success === 1) {
          this.ScheduleHead.id = data.id;
          //this.events = [...this.events, this.event];
          this.orgStaff = [];
          this.orgEquipments = [];
          this.saveStaffDetails();
          this.saveEquipmentDetails();
          this.fetchEvents();
          this.messageService.add({ severity: 'success', summary: 'Successful', detail: 'Schedule Created', life: 1000 });
        } else {
          this.messageService.add({ severity: 'error', summary: 'Error', detail: 'Schedule Not Created', life: 1000 });
        }
        this.fetchEvents();
      });
    }
    this.displayEventDialog = false;
  }


  saveEquipmentDetails() {
    if (this.orgEquipments.length === 0 && this.eventEquipments.length > 0) {
      for (let i = 0; i < this.eventEquipments.length; i++) {
        let equipmentsModel = {
          id: null,
          head_id: this.ScheduleHead.id,
          staff_id: null,
          equipment_id: this.eventEquipments[i].id,
          status: '1',
          access_type: null,
          created_by: null,
          updated_by: null,
          created_at: null,
          updated_at: null,
        }
        this.crudService.Create(equipmentsModel, 'ScheduleEquipmentDetail').subscribe((data: any) => {
        });
      }
    } else {
      for (let i = 0; i < this.eventEquipments.length; i++) {
        let EquipInfo = this.eventEquipments[i];
        let orgEquipmentArr = new Map(this.orgEquipments.map(col => [col.id, col.value]));
        //console.log(orgEquipmentArr);
        if (orgEquipmentArr.has(EquipInfo.id)) {
          orgEquipmentArr.delete(EquipInfo.id);
        } else {
          let equipmentsModel = {
            id: null,
            head_id: this.ScheduleHead.id,
            staff_id: null,
            equipment_id: this.eventEquipments[i].id,
            status: '1',
            access_type: null,
            created_by: null,
            updated_by: null,
            created_at: null,
            updated_at: null,
          }
          this.crudService.Create(equipmentsModel, 'ScheduleEquipmentDetail').subscribe((data: any) => {
          });
        }
      }
      for (let i = 0; i < this.orgEquipments.length; i++) {
        let EquipInfo = this.orgEquipments[i];
        let selEquipmentArr = new Map(this.eventEquipments.map(col => [col.id, col.value]));
        if (!selEquipmentArr.has(EquipInfo.id)) {
          //delete this.CRUDService.Create(staffInfo.id, 'ScheduleDetail').subscribe((data: any) => {});
        }
      }
    }
  }

  saveStaffDetails() {
    if (this.orgStaff.length === 0 && this.eventStaff.length > 0) {
      for (let i = 0; i < this.eventStaff.length; i++) {
        let staffModel = {
          id: null,
          head_id: this.ScheduleHead.id,
          staff_id: this.eventStaff[i].id,
          status: '1',
          access_type: null,
          created_by: null,
          updated_by: null,
          created_at: null,
          updated_at: null,
        }
        this.crudService.Create(staffModel, 'ScheduleDetail').subscribe((data: any) => {
        });
      }
    } else {
      for (let i = 0; i < this.eventStaff.length; i++) {
        let staffInfo = this.eventStaff[i];
        let orgStaffArr = new Map(this.orgStaff.map(col => [col.id, col.id]));
        //console.log(orgStaffArr);
        if (orgStaffArr.has(staffInfo.id)) {
          orgStaffArr.delete(staffInfo.id);
        } else {
          let staffModel = {
            id: null,
            head_id: this.ScheduleHead.id,
            staff_id: this.eventStaff[i].id,
            status: '1',
            access_type: null,
            created_by: null,
            updated_by: null,
            created_at: null,
            updated_at: null,
          }
          this.crudService.Create(staffModel, 'ScheduleDetail').subscribe((data: any) => {
          });
        }
      }
      for (let i = 0; i < this.orgStaff.length; i++) {
        let staffInfo = this.orgStaff[i];
        let selStaffArr = new Map(this.eventStaff.map(col => [col.id, col.value]));
        if (!selStaffArr.has(staffInfo.id)) {
          //delete this.CRUDService.Create(staffInfo.id, 'ScheduleDetail').subscribe((data: any) => {});
        }
      }
    }
  }

  fetchEvents() {
    this.events = [];
    this.crudService.getEventsList(this.companyId).subscribe((data: any[]) => {
      this.scheduleHeadList = data;
      for (let i = 0; i < this.scheduleHeadList.length; i++) {
        const event: any = {};
        if (this.scheduleHeadList[i].job_id !== '' && this.scheduleHeadList[i].job_id !== null) {
          event.title = 'Job Id : ' + this.scheduleHeadList[i].job_id;
          event.backgroundColor = 'blue';
          event.borderColor = 'black';
        } else {
          event.title = 'Non Job Schedule :' + this.scheduleHeadList[i].id;
          event.backgroundColor = 'orange';
          event.borderColor = 'black';
        }
        event.title = event.title + ' Staff : ' + this.scheduleHeadList[i].staff;
        event.title = event.title + ' Equipments : ' + this.scheduleHeadList[i].equipments;
        event.start = this.scheduleHeadList[i].schedule_start_date;
        event.end = this.scheduleHeadList[i].schedule_end_date;
        event.id = this.scheduleHeadList[i].id;
        this.events = [...this.events, event];
      }
    });
  }

  showSelectedEvent(e) {
    this.eventStaff = null;
    this.eventEquipments = null;
    this.crudService.getDataByField('ScheduleHead', 'id', e.event.id).subscribe((data: any[]) => {
      this.ScheduleHead = data[0];
      this.ScheduleHead.schedule_start_date = new Date(this.ScheduleHead.schedule_start_date);
      this.ScheduleHead.schedule_end_date = new Date(this.ScheduleHead.schedule_end_date);
      this.crudService.getEventsStaffs(this.ScheduleHead.id).subscribe((staffData: any[]) => {
        this.eventStaff = staffData;
        this.orgStaff = staffData;
      });
      this.crudService.getEventsEquipments(this.ScheduleHead.id).subscribe((equipmentData: any[]) => {
        this.eventEquipments = equipmentData;
        this.orgEquipments = equipmentData;

        this.displayEventDialog = true;
      });
      this.crudService.getDataByField('JobInfo', 'id', this.ScheduleHead.job_id).subscribe((jobData: any[]) => {
        this.eventJob = jobData[0];
      });
    });
    this.displayEventDialog = false;

  }

  deleteEvent() {
    this.confirmationService.confirm({
      message: 'Are you sure you want to delete this schedule : ' + this.ScheduleHead.id + '?',
      header: 'Confirm',
      icon: 'pi pi-exclamation-triangle',
      accept: () => {
        this.crudService.Delete(this.ScheduleHead.id, 'ScheduleHead').subscribe((data: any[]) => {
          this.messageService.add({ severity: 'success', summary: 'Successful', detail: 'Schedule Removed', life: 1000 });
          this.fetchEvents();
          this.displayEventDialog = false;
        });
      }
    });

  }

  dateClicked(e) {
    //console.log(e);
    this.ScheduleHead.schedule_start_date = new Date(e.dateStr);
    this.ScheduleHead.schedule_end_date = new Date(e.dateStr);
    this.displayEventDialog = true;
    this.eventStaff = this.selectedStaff;
    this.eventEquipments = this.selectedEquipment;
    this.eventJob = this.selectedJob;
  };

  eventChanged(e) {
    alert(e.title + ' was dropped on ');
    if (!confirm('Are you sure about this change?')) {
      e.revert();
    } else {

    }
  }

  loadScheduleAll() {
    this.events = [];
    let staffFilterList: String = '';
    let equipFilterList: String = '';

    if (this.selectedStaff.length > 0) {
      for (let i = 0; i < this.selectedStaff.length; i++) {
        if (i === 0) {
          staffFilterList = this.selectedStaff[i].id;
        } else {
          staffFilterList = staffFilterList + ', ' + this.selectedStaff[i].id;
        }
      }
    }
    for (let i = 0; i < this.selectedEquipment.length; i++) {
      if (i === 0) {
        equipFilterList = this.selectedEquipment[i].id;
      } else {
        equipFilterList = equipFilterList + ', ' + this.selectedEquipment[i].id;
      }
    }
    this.events = [];
    this.crudService.getScheduleHeadByAll(staffFilterList, equipFilterList).subscribe((data: any[]) => {
      this.scheduleHeadList = data;
      for (let i = 0; i < this.scheduleHeadList.length; i++) {
        const event: any = {};
        if (this.scheduleHeadList[i].job_id !== '' && this.scheduleHeadList[i].job_id !== null) {
          event.title = 'Job Id : ' + this.scheduleHeadList[i].job_id;
          event.backgroundColor = 'blue';
          event.borderColor = 'black';
        } else {
          event.title = 'Non Job Schedule :' + this.scheduleHeadList[i].id;
          event.backgroundColor = 'orange';
          event.borderColor = 'black';
        }
        event.title = event.title + ' Staff : ' + this.scheduleHeadList[i].staff;
        event.title = event.title + ' Equipments : ' + this.scheduleHeadList[i].equipments;
        event.start = this.scheduleHeadList[i].schedule_start_date;
        event.end = this.scheduleHeadList[i].schedule_end_date;
        event.id = this.scheduleHeadList[i].id;
        this.events = [...this.events, event];
      }
    });
  }

  loadScheduleAny() {
    this.events = [];
    let staffFilterList: String = '';
    let equipFilterList: String = '';
    if (this.selectedStaff.length > 0) {
      for (let i = 0; i < this.selectedStaff.length; i++) {
        if (i === 0) {
          staffFilterList = this.selectedStaff[i].id;
        } else {
          staffFilterList = staffFilterList + ', ' + this.selectedStaff[i].id;
        }
      }
    }
    for (let i = 0; i < this.selectedEquipment.length; i++) {
      if (i === 0) {
        equipFilterList = this.selectedEquipment[i].id;
      } else {
        equipFilterList = equipFilterList + ', ' + this.selectedEquipment[i].id;
      }
    }
    this.events = [];
    this.crudService.getScheduleHeadByAny(staffFilterList, equipFilterList).subscribe((data: any[]) => {
      this.scheduleHeadList = data;
      for (let i = 0; i < this.scheduleHeadList.length; i++) {
        const event: any = {};
        if (this.scheduleHeadList[i].job_id !== '' && this.scheduleHeadList[i].job_id !== null) {
          event.title = 'Job Id : ' + this.scheduleHeadList[i].job_id;
          event.backgroundColor = 'blue';
          event.borderColor = 'black';
        } else {
          event.title = 'Non Job Schedule :' + this.scheduleHeadList[i].id;
          event.backgroundColor = 'orange';
          event.borderColor = 'black';
        }
        event.title = event.title + ' Staff : ' + this.scheduleHeadList[i].staff;
        event.title = event.title + ' Equipments : ' + this.scheduleHeadList[i].equipments;
        event.start = this.scheduleHeadList[i].schedule_start_date;
        event.end = this.scheduleHeadList[i].schedule_end_date;
        event.id = this.scheduleHeadList[i].id;
        this.events = [...this.events, event];
      }
    });
  }
  update() {
    this.messageService.add({ severity: 'success', summary: 'Success', detail: 'Data Updated' });
  }

  delete() {
    this.messageService.add({ severity: 'warn', summary: 'Delete', detail: 'Data Deleted' });
  }


}