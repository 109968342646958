import { Component, OnInit, Input, OnDestroy } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { ConfirmationService, MessageService } from 'primeng/api';
import { CRUDService } from 'src/app/utilities/crud.service';
import { DynamicDialogRef, DialogService, DynamicDialogConfig } from 'primeng/dynamicdialog';
import { JOBService } from 'src/app/utilities/job.service';
import { environment } from 'src/environments/environment';
@Component({
  selector: 'app-tp-job-invoice-send',
  templateUrl: './tp-job-invoice-send.component.html',
  styleUrls: ['./tp-job-invoice-send.component.css']
})
export class TpJobInvoiceSendComponent implements OnInit {
  companyId: any;
  userDetails: any;
  cancel: any;
  clientList: any[];
  @Input() jobId: any
  @Input() jobAllocationId: any;
  @Input() jobAllocationResults: any;
  client_type_id: any;
  wp_inv_dt: Date;
  wp_amount_invoice: any;
  tp_pay_dt: any;
  mark_completeUI: any = false;
  initialTPInvDate: any;
  costedHours: any;
  costedStaff: any;
  SHFStaff: any;
  SHFHours: any;
  SHFMatrix: any = '1';
  invoice_no: any;
  wp_invoice_no: any;
  amount_invoice: any;
  mark_complete: any;
  costedTotal: any;
  SHFTotal: any;
  isInvoiced: any;
  shfCount: any = 0;
  imgCount: any = 0;
  env = environment;
  constructor(
    private route: ActivatedRoute,
    private crudService: CRUDService,
    private messageService: MessageService,
    private confirmationService: ConfirmationService,
    private router: Router,
    public ref: DynamicDialogRef,
    public config: DynamicDialogConfig,
    public dialogService: DialogService,
    public JobService: JOBService
  ) {
    this.companyId = sessionStorage.getItem('company_id');
    this.userDetails = JSON.parse(sessionStorage.getItem('loggedInUser'));
    this.client_type_id = this.userDetails.Client_Type;
  }

  async ngOnInit() {
    if (typeof this.jobId === 'undefined') {
      if (typeof this.config.data == 'undefined' || typeof (this.config.data.jobId) == 'undefined') {
        if (this.jobId == null || this.jobId === '') {
          this.jobId = parseInt(this.route.snapshot.paramMap.get('jobId'));
          if (this.jobAllocationId === null || this.jobAllocationId === '') {
            this.jobAllocationId = '1';
          } else {
            this.jobAllocationId = parseInt(this.route.snapshot.paramMap.get('jobAllocationId'));
          }
        }
        let smryData: any = await this.crudService.getJobSummary(this.jobId, this.jobAllocationId).toPromise();
        this.jobAllocationResults = await this.JobService.getJobInfo(smryData[0]);
      } else {
        this.jobId = this.config.data.jobId;
        this.jobAllocationId = this.config.data.jobAllocationId;
        this.jobAllocationResults = this.config.data.jobSummary;
      }
    }
    this.invoice_no = this.jobId;
    this.getTpInvoiceNoAndAmount();
    this.validateJobInfo();
    await this.getInvoiceStatus();
  }

  getSendInvoiceData() {
    if (this.client_type_id === 1) {
      this.crudService.getTpWpInvoiceNoAndAmount(this.jobId, this.jobAllocationId).subscribe((res: any[]) => {
        this.invoice_no = res[0].tp_invoice_no ? parseInt(res[0].tp_invoice_no) : parseInt(this.jobId);
        this.wp_invoice_no = res[0].wp_invoice_no ? parseInt(res[0].wp_invoice_no) : ((parseInt(res[0].WPMaxInvoice) + 1));
        this.wp_inv_dt = null;
        if (res[0].wp_inv_dt !== null && res[0].wp_inv_dt !== '') {
          this.wp_inv_dt = new Date(res[0].wp_inv_dt);
        }
        this.amount_invoice = res[0].tp_grand_total ? parseFloat(res[0].tp_grand_total) : 0;
        this.wp_amount_invoice = res[0].wp_grand_total ? parseFloat(res[0].wp_grand_total) : 0;
        //alert (res[0].TPInvDate);
        this.initialTPInvDate = res[0].TPInvDate ? new Date(res[0].TPInvDate).toLocaleDateString() : null;
        if (res[0].tp_pay_dt != null) {
          res[0].tp_pay_dt = new Date(res[0].tp_pay_dt).toLocaleDateString();
        }
        this.tp_pay_dt = res[0].tp_pay_dt;
        this.mark_complete = res[0].mark_complete;
        if (this.mark_complete == 1) {
          this.mark_completeUI = true;
        }
        else {
          this.mark_completeUI = false;   //1=checked(true) and 2=unchecked(false)
        }
        //              this.mark_completeUI=res[0].mark_complete?parseUI(res[0].mark_complete):null;

        this.crudService.compareTimeOnSite(this.jobAllocationId).subscribe((res: any[]) => {
          if (res.length > 0) {
            this.costedHours = res[0].costedHours;
            this.costedStaff = res[0].costedStaff;
            this.costedTotal = this.costedHours;
            this.SHFHours = (res[0].SHFHours / 60).toFixed(2);
            this.SHFStaff = res[0].SHFStaff;
            this.SHFTotal = ((res[0].SHFHours / 60) * this.SHFStaff).toFixed(2);
            if (((this.costedTotal / this.SHFTotal) - 1) > .25) {
              this.SHFMatrix = '3';
            } else if (((this.costedTotal / this.SHFTotal) - 1) > 0) {
              this.SHFMatrix = '2';
            } else if (((this.costedTotal / this.SHFTotal) - 1) < 0) {
              this.SHFMatrix = '1';
            }
          }
        });


      });
    } else {
      this.crudService.getTpInvoiceNoAndAmount(this.jobId, this.jobAllocationId).subscribe((res: any[]) => {
        this.invoice_no = res[0].tp_invoice_no ? parseInt(res[0].tp_invoice_no) : parseInt(this.jobId);
        //this.wp_invoice_no=res[0].wp_invoice_no?parseInt(res[0].wp_invoice_no):null;
        //this.wp_inv_dt=res[0].wp_inv_dt?utilService.DateToLocalTimeStringOnlyDate(res[0].wp_inv_dt):null;
        this.amount_invoice = res[0].tp_grand_total ? parseFloat(res[0].tp_grand_total) : 0;
        //this.wp_amount_invoice=res[0].wp_grand_total?parseFloat(res[0].wp_grand_total):0;
      });
    }
  }

  getTpWpInvoiceNoAndAmount() {
    this.crudService.getTpWpInvoiceNoAndAmount(this.jobId, this.jobAllocationId).subscribe((tpWpInvoice: any) => {
      if (tpWpInvoice) {
        this.invoice_no = tpWpInvoice[0].tp_invoice_no ? parseInt(tpWpInvoice[0].tp_invoice_no) : parseInt(this.jobId);
        this.wp_invoice_no = tpWpInvoice[0].wp_invoice_no ? parseInt(tpWpInvoice[0].wp_invoice_no) : ((parseInt(tpWpInvoice[0].WPMaxInvoice) + 1));
        this.wp_inv_dt = null;
        if (tpWpInvoice[0].wp_inv_dt !== null && tpWpInvoice[0].wp_inv_dt !== '') {
          this.wp_inv_dt = new Date(tpWpInvoice[0].wp_inv_dt);
        }
        this.amount_invoice = tpWpInvoice[0].tp_grand_total ? parseFloat(tpWpInvoice[0].tp_grand_total) : 0;
        this.wp_amount_invoice = tpWpInvoice[0].wp_grand_total ? parseFloat(tpWpInvoice[0].wp_grand_total) : 0;
        this.initialTPInvDate = tpWpInvoice[0].TPInvDate ? (tpWpInvoice[0].TPInvDate) : null;
        if (tpWpInvoice[0].tp_pay_dt != null) {
          tpWpInvoice[0].tp_pay_dt = (tpWpInvoice[0].tp_pay_dt);
        }
        this.tp_pay_dt = tpWpInvoice[0].tp_pay_dt;
        this.mark_complete = tpWpInvoice[0].mark_complete;
        if (this.mark_complete == 1) {
          this.mark_completeUI = true;
        }
        else {
          this.mark_completeUI = false;
        }
        this.crudService.compareTimeOnSite(this.jobAllocationId).subscribe((res: any) => {
          if (res.length > 0) {
            this.costedHours = res[0].costedHours;
            this.costedStaff = res[0].costedStaff;
            this.costedTotal = this.costedHours;
            this.SHFHours = (res[0].SHFHours / 60).toFixed(2);
            this.SHFStaff = res[0].SHFStaff;
            this.SHFTotal = ((res[0].SHFHours / 60) * this.SHFStaff).toFixed(2);
            if (((this.costedTotal / this.SHFTotal) - 1) > .25) {
              this.SHFMatrix = '3';
            } else if (((this.costedTotal / this.SHFTotal) - 1) > 0) {
              this.SHFMatrix = '2';
            } else if (((this.costedTotal / this.SHFTotal) - 1) < 0) {
              this.SHFMatrix = '1';
            }
          }
        });
      }
    })
  }
  getTpInvoiceNoAndAmount() {
    this.crudService.getTpInvoiceNoAndAmount(this.jobId, this.jobAllocationId).subscribe((tpInvoice: any) => {
      this.amount_invoice = tpInvoice[0].tp_grand_total;
    })
  }

  sendInvoice2() {
    this.crudService.updateTpInvoiceNo(this.invoice_no, this.jobId, this.jobAllocationId).subscribe(res => {
      this.crudService.updateTpInvoiceStatus(this.jobId, this.jobAllocationId, '1', new Date()).subscribe(res2 => {
        this.sendEmail();
      });
    });
  }

  resetAwaitInvoice() {
    this.crudService.updateTpInvoiceNo(null, this.jobId, this.jobAllocationId).subscribe(res => {
      this.crudService.resetToAwaitInvoice(this.jobId, this.jobAllocationId, null, null).subscribe(res2 => {
        this.sendEmail();
        this.storeInSchedule();
      });
    });
  }


  sendEmail() {
    var toAddress = this.jobAllocationResults.info.Email
    var ccAddress = null;//'venkat@agileitexperts.com';
    var mailSubject = '';
    if (this.jobAllocationResults.info.ClientCommType = 'Email Client') {
      if (this.jobAllocationResults.info.Email !== 'NA') {
        var params = {
          email: {
            to: toAddress, cc: ccAddress, bcc: 'shri@envirofrontier.com.au',
            msg: ' Hi Team, Claim Number ' + this.jobAllocationResults.info.job_claim_no + ' \n . Please be advised that job has been completed, as per our Tree Professional. We are in the process of obtaining insured\'s feedback. ' +
              ' Invoice and completion documents to follow in due course. If you have any queries or concerns for this job, please contact us on 1300 077 233. ' +
              ' Our Reference Number is TM ' + this.jobAllocationResults.JobNo + '. For any other tree works or quotation requests, please feel free to send them to makesafe@envirofrontier.com.au.  ' +
              '  We are more than happy to assist you.  \n' +
              '    Kind regards, Enviro Trees Customer Care Team \n',
            subject: 'Job Completed and Ready for Invoicing , TM Job Number - ' + this.jobAllocationResults.JobNo + ', Site Address - ' + this.jobAllocationResults.info.location,
            sms: null, type: 'email', selectedTpl: null, sufix: null
          }
        }
        this.crudService.sendMessage(params).subscribe((res2: any[]) => {

        });
      } else {
        const params = {
          email: {
            to: ccAddress, cc: '', bcc: 'shri@envirofrontier.com.au',
            msg: 'Missing Client Email Details --  Hi Team, Claim Number ' + this.jobAllocationResults.info.job_claim_no + ' \n . Please be advised that job has been completed, as per our Tree Professional. We are in the process of obtaining insured\'s feedback. ' +
              ' Invoice and completion documents to follow in due course. If you have any queries or concerns for this job, please contact us on 1300 077 233. ' +
              ' Our Reference Number is TM ' + this.jobAllocationResults.JobNo + '. For any other tree works or quotation requests, please feel free to send them to makesafe@envirofrontier.com.au.  ' +
              '  We are more than happy to assist you.  \n' +
              '    Kind regards, Enviro Trees Customer Care Team \n',
            subject: 'Missing Client Email Details -- Job Completed and Ready for Invoicing , TM Job Number - ' + this.jobAllocationResults.JobNo + ', Site Address - ' + this.jobAllocationResults.info.location,
            sms: null, type: 'email', selectedTpl: null, sufix: null
          }
        }
        this.crudService.sendMessage(params).subscribe((res2: any[]) => {

        });
      }
    } else if (this.jobAllocationResults.info.ClientCommType = 'Portal Client') {
      const params = {
        email: {
          to: ccAddress, cc: '', bcc: 'shri@envirofrontier.com.au',
          msg: 'To load in portal --  Hi Team, Claim Number ' + this.jobAllocationResults.info.job_claim_no + ' \n . Please be advised that job has been completed, as per our Tree Professional. We are in the process of obtaining insured\'s feedback. ' +
            ' Invoice and completion documents to follow in due course. If you have any queries or concerns for this job, please contact us on 1300 077 233. ' +
            ' Our Reference Number is TM ' + this.jobAllocationResults.JobNo + '. For any other tree works or quotation requests, please feel free to send them to makesafe@envirofrontier.com.au.  ' +
            '  We are more than happy to assist you.  \n' +
            '    Kind regards, Enviro Trees Customer Care Team \n',
          subject: 'To load in portal -- Job Completed and Ready for Invoicing , TM Job Number - ' + this.jobAllocationResults.JobNo + ', Site Address - ' + this.jobAllocationResults.info.location,
          sms: null, type: 'email', selectedTpl: null, sufix: null
        }
      }
      this.crudService.sendMessage(params).subscribe((res2: any[]) => {

      });
    }

  }


  storeInSchedule() {

    var message = 'TP Invoice details removed. Initial date of invoicing is - ' + new Date(this.initialTPInvDate).toLocaleString();
    var sms = '1';
    var email = '1';

    var store_data = {
      id: null,
      job_id: this.jobId,
      job_alloc_id: this.jobAllocationId,
      process_id: 1,
      visit_type: null,
      status: null,
      sched_date: null,
      PMOnly: '2',
      sched_note: message,
      start_time: null,
      end_time: null,
      created_by: this.userDetails.id,
      created_at: new Date(),
      phoned: '1',
      phone_no: null,
      sms: null,
      sms_no: null,
      email: null,
      emailaddress: null,
      callback: '1',
      contactUI: null
    }
    this.crudService.Create(store_data, 'JobSchedule').subscribe(res => {
      //console.log('add to schedule ' + res);
      return res;
    });
  }


  saveInvoiceDetailsOld() {
    this.wp_inv_dt = this.wp_inv_dt ? this.wp_inv_dt : null;
    this.tp_pay_dt = this.tp_pay_dt ? this.tp_pay_dt : null;
    // parseUI($this.mark_completeUI);
    if (this.wp_inv_dt == null) {
      this.wp_invoice_no = null;
    }
    this.crudService.updateWpInvoiceNo(this.wp_invoice_no, this.wp_inv_dt, this.tp_pay_dt, this.mark_complete, this.jobId, this.jobAllocationId, this.invoice_no).subscribe(res => {
      this.crudService.updateWpInvoiceStatus(this.jobId, this.jobAllocationId, '1', new Date()).subscribe(response => {
      });
    });
  }
  resetAwaitCost() { }
  // resetAwaitCost(){


  //   $scope.$emit('invoice_refresh');
  //   $('#sendInvoiceModal').modal('hide');
  //   $route.reload();
  // }


  async validateJobInfo(){
    let res: any = await this.crudService.getAfterImgCount(this.jobId, this.jobAllocationId).toPromise();
    this.imgCount = res ? res[0].count : 0;
    let res2: any = await this.crudService.getSHFStatus(this.jobId, this.jobAllocationId).toPromise();
    this.shfCount = res2 ? res2[0].count : 0;
  }

  async saveInvoiceDetails() {
    let res: any = await this.crudService.getAfterImgCount(this.jobId, this.jobAllocationId).toPromise();
    const count = res ? res[0].count : 0;
    if (count < 3) {
      this.messageService.add({ severity: 'error', summary: 'Please upload min 3 After Images', detail: 'Please upload min 3 After Images', life: 3000 });
      return false;
    } else {
      let res2: any = await this.crudService.getSHFStatus(this.jobId, this.jobAllocationId).toPromise();
      const shfCount = res2 ? res2[0].count : 0;
      if (this.env.hosting == 'AU' && shfCount < 1) {
        this.messageService.add({ severity: 'error', summary: 'Please submit Site Hazard Form to proceed with Invoicing', detail: 'Please submit Site Hazard Form to proceed with Invoicing', life: 3000 });
        return false;
      } else {
        this.wp_inv_dt = this.wp_inv_dt ? new Date(this.wp_inv_dt) : null;
        if (this.wp_inv_dt == null) {

          this.tp_pay_dt = this.tp_pay_dt ? new Date(this.tp_pay_dt) : null;
          // if (this.mark_completeUI == true) {
          // this.mark_complete = 1;
          // } else {
          this.mark_complete = 2;
          // }
          if (this.wp_inv_dt == null) {
            this.wp_invoice_no = null;
          }
          this.crudService.updateWpInvoiceNo(this.wp_invoice_no, this.wp_inv_dt, this.tp_pay_dt, this.mark_complete,
            this.jobId, this.jobAllocationId, this.invoice_no).subscribe((res2: any[]) => {
              this.crudService.updateWpInvoiceStatus(this.jobId, this.jobAllocationId, '1', new Date()).subscribe(async (res3: any[]) => {
                await this.getInvoiceStatus();
                this.messageService.add({ severity: 'success', summary: 'success', detail: 'Submitted.', life: 1000 });
              });
            });
        } else {
          this.messageService.add({ severity: 'error', summary: 'Invoice Date is Required', detail: 'Invoice Date must not be left blank.', life: 2000 });
        }
      }
    }
  }

  async getInvoiceStatus(){
    let data: any = await this.crudService.getDataByField('JobAllocation', 'id', this.jobAllocationId).toPromise();
    if(data[0].inv_status == null){
      this.isInvoiced = false;
    }else if(data[0].inv_status == '1'){
      this.isInvoiced = true;
    }
  }
}