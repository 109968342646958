import { Component, Input, OnInit, OnDestroy } from '@angular/core';
import { CRUDService } from 'src/app/utilities/crud.service';
import { ConfirmationService, MessageService } from 'primeng/api';
import { Contacts } from './contact'
import { DynamicDialogRef } from 'primeng/dynamicdialog';
import { DynamicDialogConfig } from 'primeng/dynamicdialog';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { CommonService } from 'src/app/utilities/common.service';

@Component({
  selector: 'app-contact',
  templateUrl: './contact.component.html',
  styleUrls: ['./contact.component.scss']
})

export class ContactComponent implements OnInit, OnDestroy {
  loading:Boolean=false;
  contacts: any[] = [];
  selectedContact: any[];
  contact: any;
  userDetails: any;
  submitted: any;
  isActive: boolean;
  @Input() newlayout: boolean = true;
  companyId: any;

  constructor(private CrudService: CRUDService,
    private messageService: MessageService,
    private confirmationService: ConfirmationService,
    private commonService: CommonService,
    public ref: DynamicDialogRef,
    public config: DynamicDialogConfig) {

    this.companyId = sessionStorage.getItem('company_id');
    this.userDetails = JSON.parse(sessionStorage.getItem('loggedInUser'));
  }

  @Input() id: number;
  hideContactCard: any = false;

  selectedPosition: any;
  positionList: any[];

  contactForm = new FormGroup({
    position_desc: new FormControl(''),
    mobile_no: new FormControl(''),
    email: new FormControl(''),
    phone: new FormControl(''),
    linkedin_url: new FormControl(''),
    first_name: new FormControl(''),

    last_name: new FormControl('', Validators.required),
    // name: new FormControl('', Validators.required),
  })

  newContact() {
    this.contactForm.markAsUntouched();
    this.contactForm.markAsPristine();
    this.isActive = true;
    this.contact = {
      company_id: this.id,
      created_at: null,
      created_by: null,
      email: null,
      phone: null,
      process_id: this.id,
      status: null,
      type: '6',
      position_desc:null,
      linkedin_url :null,
      first_name :null,
      last_name:null,
      mobile_no :null,

    }
    this.selectedPosition = {};
    this.hideContactCard = true;
  }

  ngOnInit() {
    this.loading=true;
    //console.log("mycheck",this.contact)
    if (typeof this.config.data === 'undefined' || typeof (this.config.data.id) === 'undefined') {
      //console.log('undefined');
    } else {
      this.id = this.config.data.id;
    }
    this.CrudService.getDataByField('CompanyContacts','company_id', this.id).subscribe((data: any[]) => {
      this.contacts = data;
    });

    this.CrudService.fetchValueSet('ClientPositionList').subscribe((data: any[]) => {
      this.positionList = data;
    });

    this.loading=false;
  }
  async saveRecordNew(){
    this.contactForm.get('phone').clearAsyncValidators();
    this.submitted = true;
    if (typeof this.selectedPosition.id !== 'undefined') {
      if (this.selectedPosition.id !== '' && this.selectedPosition.id !== null) {
        this.contact.position_id = this.selectedPosition.id;
        this.contact.position_name = this.selectedPosition.valueset_detail_name;
      }
    }
    if (this.isActive) {
      this.contact.status = '1';
    }
    if (!this.isActive) {
      this.contact.status = '2';
    }
    if (this.contact.first_name !== '' && this.contact.first_name !== null) {
       // this.contact.first_name = this.contact.first_name.charAt(0).toUpperCase()+this.contact.first_name.substr(1).toLowerCase();
      //  this.contact.last_name = this.contact.last_name.charAt(0).toUpperCase()+this.contact.last_name.substr(1).toLowerCase();
        console.log("contact",this.contact);
   let data = await this.commonService.saveRecord('CompanyContacts', this.contact);
   console.log("contact data",data);
   if (data.success === true) {
    this.CrudService.getContactsByCID(this.id).subscribe((data: any[]) => {
      this.contacts = data;
    })
    this.messageService.add({ severity: 'success', summary: 'Successful', detail: 'Contact Details Updated', life: 1000 });
    this.hideContactCard = false;
  } else {
    this.messageService.add({ severity: 'error', summary: 'Error', detail: 'Contact Details Not Updated', life: 1000 });
  }
      }
  }
  saveContact() {
    this.contactForm.get('phone').clearAsyncValidators();
    this.submitted = true;
    //console.log(this.contactForm.status);

    this.contact.position_id = null;
    this.contact.position_name = null;
    if (typeof this.selectedPosition.id !== 'undefined') {
      if (this.selectedPosition.id !== '' && this.selectedPosition.id !== null) {
        this.contact.position_id = this.selectedPosition.id;
        this.contact.position_name = this.selectedPosition.valueset_detail_name;
      }
    }
    if (this.isActive) {
      this.contact.status = '1';
    }
    if (!this.isActive) {
      this.contact.status = '2';
    }
    if (this.contact.first_name !== '' && this.contact.first_name !== null) {
        //this.contact.first_name = this.contact.first_name.charAt(0).toUpperCase()+this.contact.first_name.substr(1).toLowerCase();
        //this.contact.last_name = this.contact.last_name.charAt(0).toUpperCase()+this.contact.last_name.substr(1).toLowerCase();
      if (this.contact.id) {
        this.contact.last_updated_at = new Date().toDateString();
        this.contact.last_modified_by = this.userDetails.id;
        this.CrudService.Update(this.contact, 'CompanyContacts').subscribe((data: any) => {
          ////console.log(JSON.stringify(data));
          if (data.success === 1) {
            this.CrudService.getContactsByCID(this.id).subscribe((data: any[]) => {
              this.contacts = data;
            })

            this.messageService.add({ severity: 'success', summary: 'Successful', detail: 'Contact Details Updated', life: 1000 });
            this.hideContactCard = false;
          } else {
            this.messageService.add({ severity: 'error', summary: 'Error', detail: 'Contact Details Not Updated', life: 1000 });
          }
        });
      } else {
        this.contact.created_at = new Date().toDateString();
        this.contact.created_by = this.userDetails.id;
        this.CrudService.Create(this.contact, 'CompanyContacts').subscribe((data: any) => {
          if (data.success === 1) {
            this.contact.id = data.id;
            this.contacts = [...this.contacts, this.contact];
            this.messageService.add({ severity: 'success', summary: 'Successful', detail: 'Contact Created.', life: 1000 });
            this.hideContactCard = false;
          } else {
            this.messageService.add({ severity: 'error', summary: 'Error', detail: 'Contact Not Created', life: 1000 });
          }
        })
      }
    } else {
      //console.log("in else save");
      this.messageService.add({ severity: 'error', summary: 'Error', detail: 'Please enter Name to proceed with saving', life: 1000 });
    }
  }

  editContact(contact: Contacts) {
    this.contact = { ...contact }
    //console.log("mycheck",this.contact)
    if (this.contact.status === '' || this.contact.status === null || this.contact.status === '1') {
      this.contact.status = '1'
      this.isActive = true;
    }
    if (this.contact.status === '2') {
      this.contact.status = '2'
      this.isActive = false;
    }
    if (this.contact.position_id != null && this.contact.position_id !== '') {
      this.CrudService.getDataByField('ValueSetDetail', 'id', this.contact.position_id).subscribe((data: any[]) => {
        this.selectedPosition = data[0];
      });
    } else {
      this.selectedPosition = {};
    }
    this.hideContactCard = true;
  }

  cancel() {
    this.hideContactCard = false;
  }

  ngOnDestroy() {
    this.config.data = {};
  }

  deleteContact(contact: any) {
    this.confirmationService.confirm({
      message: 'Are you sure you want to delete contact : ' + contact.first_name+' '+contact.last_name + '?',
      header: 'Confirm',
      icon: 'pi pi-exclamation-triangle',
      accept: () => {
        this.contacts = this.contacts.filter(val => val.id !== contact.id);
        this.contact = {};
        this.CrudService.Delete(contact.id, 'CompanyContacts').subscribe((data: any[]) => {
          this.messageService.add({ severity: 'success', summary: 'Successful', detail: 'Contact Deleted', life: 1000 });
        });
      }
    });
  }

  findIndexById(id: string): number {
    let index = -1;
    for (let i = 0; i < this.contacts.length; i++) {
      if (this.contacts[i].id === id) {
        index = i;
        break;
      }
    }

    return index;
  }
}