import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { ConfirmationService, MessageService } from 'primeng/api';
import { Checkbox } from 'primeng/checkbox';
import { DialogService, DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { CRUDService } from 'src/app/utilities/crud.service';
import { JOBService } from 'src/app/utilities/job.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-tree-info',
  templateUrl: './tree-info.component.html',
  styleUrls: ['./tree-info.component.scss'],
})
export class TreeInfoComponent implements OnInit {
  @Input() jobId: any;
  @Input() jobAllocationId: any;
  @Input() jobSummary: any;
  @Output() refereshFlag: EventEmitter<any> = new EventEmitter();
  heights: any[] = [];
  responseHeights: any[];
  responseDiameter: any[];
  responseTreeLocation: any[];
  responseHealth: any[];
  responseAge: any[];
  responseDistance: any[];
  responseAccess: any[];
  responseWorkRequired: any[];
  responseWaste: any[];
  responseFence: any[];
  responseOther: any[];
  responseRoof: any[];
  selectedRecords: any[];
  selectedHeight: any[] = [];
  diameters: any[] = [];
  selectedDiameter: any[] = [];
  health: any[] = [];
  selectedHealth: any[] = [];
  treeAge: any[] = [];
  selectedAge: any[] = [];
  locations: any[] = [];
  selectedLocation: any[] = [];
  distance: any[] = [];
  selectedDistance: any[] = [];
  access: any[] = [];
  selectedAccess: any[] = []
  workRequired: any[] = [];
  selectedWorkRequired: any[] = [];
  cubicMeter: any[] = [];
  selectedCubicMeter: any[] = [];
  fence: any[] = [];
  selectedFence: any[] = [];
  roof: any[] = [];
  selectedRoof: any[] = [];
  damages: any[] = [];
  selectedDamages: any[] = [];
  RISD: any[] = [];
  selecteRISD: any = [];
  costHead: any[];
  selectedCostHead: any;
  costDetails: any;
  selectedHours: any;
  workOperationsInfo: any;

  costing: boolean = false;
  costFormHead: any[] = [];
  rateSetId: any;
  costDetailsList: any = [];
  itemsList: any[];
  selectedItem: any;
  subTotal: any = 0;
  grandTotal: any = 0;
  companyId: any;
  userDetails: any;
  jobAllocationDetails: any;
  changeHeight: boolean = false;
  previousHeight: any[];
  previousDiameter: any[];
  previousHealth: any[];
  previousAge: any[];
  changeLocation: boolean = false;
  previousLocation: any[];
  previousDistance: any[];
  previousAccess: any[];
  changeworkDetails: boolean = false;
  previousWorkRequired: any[];
  previousCubicMeter: any[];
  changetreeDamages: boolean = false;
  previousFence: any[];
  previousRoof: any[];
  previousDamages: any[];
  checked: boolean = false;
  isChecked: boolean = false;

  tree: any;
  treeLocation: any;
  workDetails: any;
  treeDamages: any;
  allcheck: any;
  disableDelete: boolean = false;
  disableFields: Boolean = false;
  loading: Boolean = true;
  showHeader: Boolean = false;
  cont_no_tree: any;
  constructor(
    private route: ActivatedRoute,
    private crudService: CRUDService,
    private messageService: MessageService,
    private confirmationService: ConfirmationService,
    private router: Router,
    public ref: DynamicDialogRef,
    public config: DynamicDialogConfig,
    public dialogService: DialogService,
    public JobService: JOBService,
    public sanitizer: DomSanitizer,
  ) {
    this.companyId = sessionStorage.getItem('company_id');
    this.userDetails = JSON.parse(sessionStorage.getItem('loggedInUser'));
    this.staticDropDowns();
  }

  async ngOnInit() {
    if (typeof this.jobId === 'undefined') {
      if (typeof this.config.data == 'undefined' || typeof (this.config.data.jobId) == 'undefined') {
        if (this.jobId == null || this.jobId === '') {
          this.jobId = parseInt(this.route.snapshot.paramMap.get('jobId'));
          if (this.jobAllocationId === null || this.jobAllocationId === '') {
            this.jobAllocationId = '1';
          } else {
            this.jobAllocationId = parseInt(this.route.snapshot.paramMap.get('jobAllocationId'));
          }
        }
        let smryData: any = await this.crudService.getJobSummary(this.jobId, this.jobAllocationId).toPromise();
        this.jobAllocationDetails = await this.JobService.getJobInfo(smryData[0]);
        this.showHeader = false;
      } else {
        this.jobId = this.config.data.jobId;
        this.jobAllocationId = this.config.data.jobAllocationId;
        this.jobAllocationDetails = this.config.data.jobSummary;
        this.jobSummary = this.config.data.jobSummary;
        if (typeof this.config.data.disable != 'undefined') {
          this.disableFields = this.config.data.disable;
        }
        this.showHeader = true;
      }
    } else {
      this.jobAllocationDetails = this.jobSummary;
    }
    this.getWorkOrderInfo();
  }

  getWorkOrderInfo() {
    this.crudService.getWorkOrderInfo(this.jobId, this.jobAllocationId).subscribe((res: any[]) => {

      //console.log(res);
      if (res.length > 0) {
        this.workOperationsInfo = res[0]
       this.cont_no_tree = this.workOperationsInfo.no_tree
        if (this.workOperationsInfo?.id != null && this.workOperationsInfo?.id != '') {
          this.disableDelete = false;
        }
        else {
          this.disableDelete = true;
        }
        if (this.workOperationsInfo.height != null) {
          for (const height of this.workOperationsInfo.height.split(',')) {
            let heightDetails = this.heights.filter(val => height === val.id)[0];
            if (typeof heightDetails != 'undefined') {
              this.selectedHeight.push(heightDetails);
            }
          }
        }


        if (this.workOperationsInfo.trunk != null) {
          for (const trunk of this.workOperationsInfo.trunk.split(',')) {
            let trunkDetails = this.diameters.filter(val => trunk === val.id)[0];
            if (typeof trunkDetails != 'undefined') {
              this.selectedDiameter.push(trunkDetails);
            }
          }
        }

        if (this.workOperationsInfo.tree_location != null) {
          for (const location of this.workOperationsInfo.tree_location.split(',')) {
            let locationDetails = this.locations.filter(val => location === val.id)[0];
            if (typeof locationDetails != 'undefined') {
              this.selectedLocation.push(locationDetails);
            }
          }
        }

        if (this.workOperationsInfo.health != null) {
          for (const health of this.workOperationsInfo.health.split(',')) {
            let healthDetails = this.health.filter(val => health === val.id)[0];
            if (typeof healthDetails != 'undefined') {
              this.selectedHealth.push(healthDetails);
            }
          }
        }

        if (this.workOperationsInfo.age != null) {
          for (const age of this.workOperationsInfo.age.split(',')) {
            let ageDetails = this.treeAge.filter(val => age === val.id)[0];
            if (typeof ageDetails != 'undefined') {
              this.selectedAge.push(ageDetails);
            }
          }
        }


        if (this.workOperationsInfo.distance != null) {
          for (const distance of this.workOperationsInfo.distance.split(',')) {
            let distanceDetails = this.distance.filter(val => distance === val.id)[0];
            if (typeof distanceDetails != 'undefined') {
              this.selectedDistance.push(distanceDetails);
            }
          }
        }

        if (this.workOperationsInfo.access != null) {
          for (const access of this.workOperationsInfo.access.split(',')) {
            let accessDetails = this.access.filter(val => access === val.id)[0];
            if (typeof accessDetails != 'undefined') {
              this.selectedAccess.push(accessDetails);
            }
          }
        }

        if (this.workOperationsInfo.work_category != null) {
          for (const work of this.workOperationsInfo.work_category.split(',')) {
            let workDetails = this.workRequired.filter(val => work === val.id)[0];
            if (typeof workDetails != 'undefined') {
              this.selectedWorkRequired.push(workDetails);
            }
          }
        }

        if (this.workOperationsInfo.waste != null) {
          for (const waste of this.workOperationsInfo.waste.split(',')) {
            let wasteDetails = this.cubicMeter.filter(val => waste === val.id)[0];
            if (typeof wasteDetails != 'undefined') {
              this.selectedCubicMeter.push(wasteDetails);
            }
          }
        }

        if (this.workOperationsInfo.fence != null) {
          for (const fence of this.workOperationsInfo.fence.split(',')) {
            let fenceDetails = this.fence.filter(val => fence === val.id)[0];
            if (typeof fenceDetails !== 'undefined' && typeof (fenceDetails) !== 'undefined') {
              let array: any[] = this.workOperationsInfo.fence.split(',');
              if (array.length > 1) {
                if (fenceDetails.id !== '6') {
                  this.selectedFence.push(fenceDetails);
                }
              } else {
                if (typeof fenceDetails != 'undefined') {
                  this.selectedFence.push(fenceDetails);
                }
              }
            } else {
              if (fence === '118') { this.selectedFence.push({ id: '1', name: 'Wooden' }); }
              if (fence === '117') { this.selectedFence.push({ id: '4', name: 'Chain Link' }); }
              if (fence === '121') { this.selectedFence.push({ id: '5', name: 'Other' }); }
              if (fence === '122') {
                let array: any[] = this.workOperationsInfo.fence.split(',');
                if (array.length === 1) {
                  this.selectedFence.push({ id: '6', name: 'N/A' });
                }
              }
            }
            // <option value="118">Timber</option> <option value="117">Metal(Chainlink)</option> <option value="120">Masonary (Colourbond)</option>
            //                                   <option value="122">Brush</option> <option value="121">Other</option> 
            //                this.fence = [ { id: '1', name: 'Wooden' }, { id: '2', name: 'Colourbond' }, { id: '3', name: 'Brush' }, { id: '4', name: 'Chain Link' },
            //                         { id: '5', name: 'Other' },   { id: '6', name: 'N/A' }, ] 

            //console.log('selected fence', this.selectedFence)
          }
        }


        if (this.workOperationsInfo.roof != null) {
          for (const roof of this.workOperationsInfo.roof.split(',')) {
            let roofDetails = this.roof.filter(val => roof === val.id)[0];
            if (typeof roofDetails != 'undefined') {
              this.selectedRoof.push(roofDetails);
            }
          }
        }


        if (this.workOperationsInfo.other != null) {
          for (const other of this.workOperationsInfo.other.split(',')) {
            let otherDetails = this.damages.filter(val => other === val.id)[0];
            if (typeof otherDetails != 'undefined') {
              this.selectedDamages.push(otherDetails);
            }
          }
        }
        this.loading = false
      } else {
        this.disableDelete = true;
        this.workOperationsInfo = {};
        this.loading = false
      }
    })
    this.previousHeight = this.selectedHeight;
    this.previousDiameter = this.selectedDiameter;
    this.previousHealth = this.selectedHealth;
    this.previousAge = this.selectedAge;
    this.previousLocation = this.selectedLocation;
    this.previousDistance = this.selectedDistance;
    this.previousAccess = this.selectedAccess;
    this.previousWorkRequired = this.selectedWorkRequired;
    this.previousCubicMeter = this.selectedCubicMeter;
    this.previousFence = this.selectedFence;
    this.previousRoof = this.selectedRoof;
    this.previousDamages = this.selectedDamages;
  }


  saveRecord() {
    
    this.workOperationsInfo.no_tree = this.workOperationsInfo.no_tree ? parseInt(this.workOperationsInfo.no_tree) : null;
    if (this.workOperationsInfo.no_tree < 0) {
      this.messageService.add({ severity: 'error', summary: 'Error', detail: 'Tree info Details Not Updated', life: 1000 });
      return;
    }
    if (this.selectedHeight.length > 0) {
      const users = this.selectedHeight.map(function (item) {
        return item.id;
      });
      //console.log(this.workOperationsInfo);
      this.workOperationsInfo.height = users;
    } else {
      this.workOperationsInfo.height = null;
      this.messageService.add({ severity: 'error', summary: 'Error', detail: 'Tree info Details Not Updated', life: 1000 });
      return;
    }

    if (this.selectedDiameter.length > 0) {
      const users = this.selectedDiameter.map(function (item) {
        return item.id;
      });
      this.workOperationsInfo.trunk = users;
    } else {
      this.workOperationsInfo.trunk = null;
      return;
    }

    if (this.selectedLocation.length > 0) {
      const users = this.selectedLocation.map(function (item) {
        return item.id;
      });
      this.workOperationsInfo.tree_location = users;
    } else {
      this.workOperationsInfo.tree_location = null;
      this.messageService.add({ severity: 'error', summary: 'Error', detail: 'Tree info Details Not Updated', life: 1000 });
      return;
    }

    if (this.selectedDistance.length > 0) {
      const users = this.selectedDistance.map(function (item) {
        return item.id;
      });
      this.workOperationsInfo.distance = users;
    } else {
      this.workOperationsInfo.distance = null;
      this.messageService.add({ severity: 'error', summary: 'Error', detail: 'Tree info Details Not Updated', life: 1000 });
      return;
    }


    if (this.selectedHealth.length > 0) {
      const users = this.selectedHealth.map(function (item) {
        return item.id;
      });
      this.workOperationsInfo.health = users;
    } else {
      this.workOperationsInfo.health = null;
      this.messageService.add({ severity: 'error', summary: 'Error', detail: 'Tree info Details Not Updated', life: 1000 });
      return;
    }

    if (this.selectedAge.length > 0) {
      const users = this.selectedAge.map(function (item) {
        return item.id;
      });
      this.workOperationsInfo.age = users;
    } else {
      this.workOperationsInfo.age = null;
      this.messageService.add({ severity: 'error', summary: 'Error', detail: 'Tree info Details Not Updated', life: 1000 });
      return;
    }


    if (this.selectedAccess.length > 0) {
      const users = this.selectedAccess.map(function (item) {
        return item.id;
      });
      this.workOperationsInfo.access = users;
    } else {
      this.workOperationsInfo.access = null;
      this.messageService.add({ severity: 'error', summary: 'Error', detail: 'Tree info Details Not Updated', life: 1000 });
      return;
    }

    if (this.selectedWorkRequired.length > 0) {
      const users = this.selectedWorkRequired.map(function (item) {
        return item.id;
      });
      this.workOperationsInfo.work_category = users;
    } else {
      this.workOperationsInfo.work_category = null;
      this.messageService.add({ severity: 'error', summary: 'Error', detail: 'Tree info Details Not Updated', life: 1000 });
      return;
    }

    if (this.selectedCubicMeter.length > 0) {
      const users = this.selectedCubicMeter.map(function (item) {
        return item.id;
      });
      this.workOperationsInfo.waste = users;
    } else {
      this.workOperationsInfo.waste = null;
      this.messageService.add({ severity: 'error', summary: 'Error', detail: 'Tree info Details Not Updated', life: 1000 });
      return;
    }

    if (this.selectedFence.length > 0) {
      const fenceValue = this.selectedFence.map(function (FenceItem) {
        if (typeof FenceItem != 'undefined') {

          if (!(FenceItem === 'undefined') && !(FenceItem === null) && !(FenceItem === '')) {
            return FenceItem.id;
          }
        }
      });
      this.workOperationsInfo.fence = fenceValue;
    } else {
      this.workOperationsInfo.fence = null;
      this.messageService.add({ severity: 'error', summary: 'Error', detail: 'Tree info Details Not Updated', life: 1000 });
      return;
    }

    if (this.selectedRoof.length > 0) {
      const users = this.selectedRoof.map(function (item) {
        return item.id;
      });
      this.workOperationsInfo.roof = users;
    } else {
      this.workOperationsInfo.roof = null;
      this.messageService.add({ severity: 'error', summary: 'Error', detail: 'Tree info Details Not Updated', life: 1000 });
      return;
    }

    if (this.selectedDamages.length > 0) {
      const users = this.selectedDamages.map(function (item) {
        return item.id;
      });
      this.workOperationsInfo.other = users;
    } else {
      this.workOperationsInfo.other = null;
      this.messageService.add({ severity: 'error', summary: 'Error', detail: 'Tree info Details Not Updated', life: 1000 });
      return;
    }


    //  this.workOperationsInfo ={
    //   id:null,
    //   job_id:this.jobId,
    //   job_no:null,
    //   job_alloc_id:this.jobAllocationId,
    //   height:heights,
    //   trunk:this.selectedDiameter,
    //   tree_location:this.selectedLocation,
    //   distance:this.selectedDistance,
    //   health:this.selectedHealth,
    //   age:this.selectedAge,
    //   access:this.selectedAccess,
    //   work_category:this.selectedWorkRequired,
    //   waste:this.selectedCubicMeter,
    //   fence:this.selectedFence,
    //   roof:this.selectedRoof,
    //   other:this.selectedDamages,
    //   no_tree:this.no_tree
    // }


    if (this.workOperationsInfo.id) {
      //console.log(this.workOperationsInfo);
      this.crudService.Update(this.workOperationsInfo, 'WorkOperationsInfo').subscribe((res: any) => {
        if (res.success === 1) {
          this.messageService.add({ severity: 'success', summary: 'Successful', detail: 'Tree info Details Updated', life: 1000 });
          this.disableDelete = false;
          this.refereshFlag.emit("Tree Info Saved");
        } else {
          this.messageService.add({ severity: 'error', summary: 'Error', detail: 'Tree info Details Not Updated', life: 1000 });
        }
      })
    } else {
      //console.log(this.workOperationsInfo);
      this.workOperationsInfo.job_id = this.jobId;
      this.workOperationsInfo.job_no = this.jobAllocationDetails.job_no;
      this.workOperationsInfo.job_alloc_id = this.jobAllocationId;

      this.crudService.Create(this.workOperationsInfo, 'WorkOperationsInfo').subscribe((data: any) => {
        if (data.success === 1) {
          this.workOperationsInfo.id = data.id;
          this.messageService.add({ severity: 'success', summary: 'Successful', detail: 'Tree info Details saved', life: 1000 });
          this.disableDelete = false;
          this.refereshFlag.emit("Tree Info Saved");
        } else {
          this.messageService.add({ severity: 'error', summary: 'Error', detail: 'Tree info Details Not saved', life: 1000 });
        }
      })
    }
    this.refereshFlag.emit("Tree Info Saved");
  }

  staticDropDowns() {
    this.diameters = [
      { id: '1', name: '0 to 400 mm' },
      { id: '2', name: '500 to 1000 mm' },
      { id: '3', name: 'Greater than 1000 mm' },
      { id: '4', name: 'N/A' },
    ];
    this.heights = [
      { id: '1', name: '0-5 meter' },
      { id: '2', name: '6-10 meter' },
      { id: '3', name: '11-15 meter' },
      { id: '33', name: '16-20 meter' },
      { id: '4', name: 'Greater than 20 meter' },
      { id: '5', name: 'N/A' },
    ];
    this.health = [
      { id: '1', name: 'Good' },
      { id: '2', name: 'Average' },
      { id: '3', name: 'Poor' },
      { id: '4', name: 'N/A' },
    ];
    this.treeAge = [
      { id: '1', name: 'Juvenile' },
      { id: '2', name: 'Semi-mature' },
      { id: '3', name: 'Mature' },
      { id: '4', name: 'N/A' },
    ]
    
    if (environment.hosting == 'UK') {
      this.locations = [
        { id: '1', name: 'Front of Property' },
        { id: '2', name: 'Side of Property' },
        { id: '3', name: 'Rear of Property' },
        { id: '4', name: 'Neighbours' },
        { id: '6', name: 'Council/Public Land' },
        { id: '5', name: 'N/A' },
      ];
      this.workRequired = [
        { id: '1', name: 'Tree Removal' },
        { id: '2', name: 'Tree Prune' },
        { id: '3', name: 'Stump' },
        { id: '4', name: 'Temp tarp' },
        { id: '5', name: 'Arborist Report' },
        { id: '6', name: 'Remove Debris' },
        { id: '7', name: 'N/A' },
      ];
    } else {
      this.locations = [
        { id: '1', name: 'Front of Property' },
        { id: '2', name: 'Side of Property' },
        { id: '3', name: 'Rear of Property' },
        { id: '4', name: 'Neighbours' },
        { id: '5', name: 'N/A' },
      ];
      this.workRequired = [
        { id: '1', name: 'Tree Removal' },
        { id: '2', name: 'Tree Prune' },
        { id: '3', name: 'Stump' },
        { id: '4', name: 'Temp tarp' },
        { id: '5', name: 'Temp fence' },
        { id: '6', name: 'Remove Debris' },
        { id: '7', name: 'N/A' },
      ];
    }
    
    this.distance = [
      { id: '1', name: '0-5 meters' },
      { id: '7', name: '6-10 meters' },
      { id: '2', name: '11-15 meters' },
      { id: '3', name: '15-20 meters' },
      { id: '4', name: 'Greater than 20 meters' },
      { id: '5', name: 'Neighbours' },
      { id: '6', name: 'N/A' },
    ]
    this.access = [
      { id: '1', name: 'Good' },
      { id: '2', name: 'Average' },
      { id: '3', name: 'Poor' },
      { id: '4', name: 'N/A' },
    ]

    this.cubicMeter = [
      { id: '1', name: '0-3 Cubic meter' },
      { id: '2', name: '3-6 Cubic meter' },
      { id: '3', name: '6-10 Cubic meter' },
      { id: '4', name: '10-15 Cubic meter' },
      { id: '5', name: 'Greater than 15 Cubic meter' },
      { id: '6', name: 'N/A' },
    ]
    this.fence = [
      { id: '1', name: 'Wooden' },
      { id: '2', name: 'Colourbond' },
      { id: '3', name: 'Brush' },
      { id: '4', name: 'Chain Link' },
      { id: '5', name: 'Other' },
      { id: '6', name: 'N/A' },
    ]
    this.roof = [
      { id: '1', name: 'Tiles' },
      { id: '2', name: 'Asbestos' },
      { id: '3', name: 'Tin' },
      { id: '4', name: 'Other' },
      { id: '5', name: 'N/A' },
    ]
    this.damages = [
      { id: '1', name: 'Gutter' },
      { id: '2', name: 'Play Equipment' },
      { id: '3', name: 'Pool' },
      { id: '4', name: 'Car Port/Garage' },
      { id: '5', name: 'House' },
      { id: '6', name: 'Shed' },
      { id: '7', name: 'Clothes Line' },
      { id: '8', name: 'Other' },
      { id: '9', name: 'N/A' }
    ]

  }

  checkuncheckall() {
    if (this.workOperationsInfo.allcheck == '1') {
      this.workOperationsInfo.no_tree = '0'
      if (this.workOperationsInfo.tree == '1' || this.workOperationsInfo.tree == '0' || this.workOperationsInfo.tree == undefined) {
        this.workOperationsInfo.tree = true;
        const res = this.treeNA();
      }
      if (this.workOperationsInfo.treeLocation == '1' || this.workOperationsInfo.treeLocation == '0' || this.workOperationsInfo.treeLocation == undefined) {
        this.workOperationsInfo.treeLocation = true;
        const res = this.treeLocationNA();
      }
      if (this.workOperationsInfo.workDetails == '1' || this.workOperationsInfo.workDetails == '0' || this.workOperationsInfo.workDetails == undefined) {
        this.workOperationsInfo.workDetails = true;
        const res = this.workDetailsNA();
      }
      if (this.workOperationsInfo.treeDamages == '1' || this.workOperationsInfo.treeDamages == '0' || this.workOperationsInfo.treeDamages == undefined) {
        this.workOperationsInfo.treeDamages = true;
        const res = this.treeDamagesNA();
      }
    }
    else {
      this.workOperationsInfo.no_tree = this.cont_no_tree
      if (this.workOperationsInfo.tree == '1' || this.workOperationsInfo.tree == '0' || this.workOperationsInfo.tree == undefined) {
        this.workOperationsInfo.tree = false;
        const res = this.treeNA();
      }
      if (this.workOperationsInfo.treeLocation == '1' || this.workOperationsInfo.treeLocation == '0' || this.workOperationsInfo.treeLocation == undefined) {
        this.workOperationsInfo.treeLocation = false;
        const res = this.treeLocationNA();
      }
      if (this.workOperationsInfo.workDetails == '1' || this.workOperationsInfo.workDetails == '0' || this.workOperationsInfo.workDetails == undefined) {
        this.workOperationsInfo.workDetails = false;
        const res = this.workDetailsNA();
      }
      if (this.workOperationsInfo.treeDamages == '1' || this.workOperationsInfo.treeDamages == '0' || this.workOperationsInfo.treeDamages == undefined) {
        this.workOperationsInfo.treeDamages = false;
        const res = this.treeDamagesNA();
      }
    }

  }

  checkAll() {
    if (this.workOperationsInfo.tree == false || this.workOperationsInfo.treeLocation == false || this.workOperationsInfo.workDetails == false || this.workOperationsInfo.treeDamages == false) {
      this.workOperationsInfo.allcheck = false;
    }
    else if (this.workOperationsInfo.tree == true && this.workOperationsInfo.treeLocation == true && this.workOperationsInfo.workDetails == true && this.workOperationsInfo.treeDamages == true) {
      this.workOperationsInfo.allcheck = true;
    }
  }

  treeNA() {
    if (this.workOperationsInfo.tree == '1') {
      this.previousHeight = this.selectedHeight;
      this.previousDiameter = this.selectedDiameter;
      this.previousHealth = this.selectedHealth;
      this.previousAge = this.selectedAge;

      this.selectedHeight = [{ id: '5', name: 'N/A' }];
      this.selectedDiameter = [{ id: '4', name: 'N/A' }];
      this.selectedHealth = [{ id: '4', name: 'N/A' }];
      this.selectedAge = [ { id: '4', name: 'N/A' }];
      
    }
    else {
      this.selectedHeight = this.previousHeight;
      this.selectedDiameter = this.previousDiameter;
      this.selectedHealth = this.previousHealth;
      this.selectedAge = this.previousAge;
    }
    this.checkAll();
  }
  treeLocationNA() {
    if (this.workOperationsInfo.treeLocation == '1') {
      this.previousLocation = this.selectedLocation;
      this.previousDistance = this.selectedDistance;
      this.previousAccess = this.selectedAccess;

      this.selectedLocation = [{ id: '5', name: 'N/A' }];
      this.selectedDistance = [{ id: '6', name: 'N/A' }];
      this.selectedAccess = [{ id: '4', name: 'N/A' }];
    }
    else {
      this.selectedLocation = this.previousLocation;
      this.selectedDistance = this.previousDistance;
      this.selectedAccess = this.previousAccess;
    }
    this.checkAll();
  }
  workDetailsNA() {
    if (this.workOperationsInfo.workDetails == '1') {
      this.previousWorkRequired = this.selectedWorkRequired;
      this.previousCubicMeter = this.selectedCubicMeter;

      this.selectedWorkRequired = [{ id: '7', name: 'N/A' }];
      this.selectedCubicMeter = [{ id: '6', name: 'N/A' }];
    }
    else {
      this.selectedWorkRequired = this.previousWorkRequired;
      this.selectedCubicMeter = this.previousCubicMeter;
    }
    this.checkAll();
  }
  treeDamagesNA() {
    if (this.workOperationsInfo.treeDamages == '1') {
      this.previousFence = this.selectedFence;
      this.previousRoof = this.selectedRoof;
      this.previousDamages = this.selectedDamages;

      this.selectedFence = [{ id: '6', name: 'N/A' }];
      this.selectedRoof = [{ id: '5', name: 'N/A' }];
      this.selectedDamages = [{ id: '9', name: 'N/A' }];
    }
    else {
      this.selectedFence = this.previousFence;
      this.selectedRoof = this.previousRoof;
      this.selectedDamages = this.previousDamages;
    }
    this.checkAll();
  }
  deleteRecord() {
    this.confirmationService.confirm({
      message: 'Are you sure you want to delete tree info : ' + this.jobId + '?',
      header: 'Confirm',
      icon: 'pi pi-exclamation-triangle',
      accept: () => {
        this.crudService.deleteRecordByID('WorkOperationsInfo', this.workOperationsInfo.id).subscribe(() => {
          this.messageService.add({ severity: 'success', summary: 'Successful', detail: 'Tree info Deleted', life: 1000 });
          this.disableDelete = true;
          this.refereshFlag.emit("Tree Info Deleted");
        });
      }
    });
  }
}