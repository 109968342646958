import { Component, OnInit, Input } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { ConfirmationService, MessageService } from 'primeng/api';
import { DialogService, DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { CRUDService } from 'src/app/utilities/crud.service';
import { WorkFlowService } from 'src/app/utilities/workflow.service';
import { JobAdminComponent } from '../../job/job-admin/job-admin.component';
import { JobUserComponent } from '../job-user/job-user.component';
import { JOBService } from 'src/app/utilities/job.service';
// import { JobDetailsApprovalsComponent } from 'src/app/job/job-details-approvals/job-details-approvals.component';
import { CommonService } from 'src/app/utilities/common.service';
import { TPRegistrationComponent } from '../tp-registration/tp-registration.component';
import { CompanyComplianceComponent } from '../company-compliance/company-compliance.component';
import { environment } from 'src/environments/environment';
import { JobDetailsApprovalsComponent } from 'src/app/job/job-details-approvals/job-details-approvals.component';
import { formatDate } from 'ngx-bootstrap';
import { DatePipe } from '@angular/common';
@Component({
  selector: 'app-task-details',
  templateUrl: './task-details.component.html',
  styleUrls: ['./task-details.component.scss'],
  providers: [DialogService, MessageService, ConfirmationService,DatePipe]
})
export class TaskDetailsComponent implements OnInit {
  @Input() taskId: any
  @Input() wf_code: any;
  workflow_params: any;
  showEvent: Boolean = false;
  checkList: any[] = [];
  taskDetails: any;
  taskCreationForm: FormGroup;
  submitted: any;
  end_date: any;
  supportStage: any;
  start_dt: any;
  next_followup: any;
  companyId: any;
  userDetails: any;
  priorityOptions: any[] = [];
  selectedPriority: any;
  selectedIssueType: any = {};
  staffList: any[];
  stagesList: any[];
  workflowDetails: any;
  selectedStage: any;
  selectedAllocatees: any[] = [];
  issue_status: any = '';
  previousUsers: any[];
  menuData: any;
  reqUser: any;
  allowEditing: Boolean = false;
  topbardata: any;
  isSupportComplete: boolean = false;
  tiles = ['issue-details', 'messages']
  tileView = 'issue-details';
  redirectMenu: any;
  saved: Boolean = false;
  selectedDate: Date;
  loading: Boolean = false;
  showInfoFieldsColumn: Boolean = false;
  info_fields: any;
  taskValues: any;
  next_followup_date: Date;
  event_remarks: any;
  selectedEvent: any;
  eventTypeList: any[] = [];
  categoryList: any[] = [];
  selectedCategory: any;
  allow_edit: any;
  issueTypeList : any;
  // issueTypeList = [
  //   { valueset_detail_name: '1', valueset_detail_desc: 'Job Related' },
  //   { valueset_detail_name: '2', valueset_detail_desc: 'Support' },
  //   { valueset_detail_name: '3', valueset_detail_desc: 'Network' },
  //   { valueset_detail_name: '4', valueset_detail_desc: 'Hardware' },
  //   { valueset_detail_name: '5', valueset_detail_desc: 'Software' },
  //   { valueset_detail_name: '6', valueset_detail_desc: 'Workstation' },
  //   { valueset_detail_name: '7', valueset_detail_desc: 'Other' }
  // ];
  dateFormat: any;
  taskDelShow:Boolean = true;
  removeallocate: any='';
  timeSlots: any[];
  filteredTimeSlots: any[];
  Un_timeSlots: string[];
  time: any;
  timeFromPicker :any;
  jobAllocationResults: any;
  env = environment;
  old_selectedallocatees: any[];
  constructor(
    private wfService: WorkFlowService,
    private route: ActivatedRoute,
    private crudService: CRUDService,
    private messageService: MessageService,
    private confirmationService: ConfirmationService,
    private router: Router,
    public ref: DynamicDialogRef,
    public config: DynamicDialogConfig,
    public dialogService: DialogService,
    private JobService: JOBService,
    private commonservice: CommonService,
    private datepipe: DatePipe,
  ) {
    this.companyId = sessionStorage.getItem('company_id');
    this.userDetails = JSON.parse(sessionStorage.getItem('loggedInUser'));
    this.priorityOptions = [{ code: 'Critical', name: 'Critical' },
    { code: 'High', name: 'High' },
    { code: 'Normal', name: 'Normal' },
    { code: 'Low', name: 'Low' }];
    this.taskDetails = {
      description: null,
      category: null,
      created_at: null,
      created_by: null,
      end_dt: null,
      id: null,
      name: null,
      next_followup: null,
      notes: null,
      priority: null,
      process_id: null,
      start_dt: null,
      status: null,
      tags: null,
      type: null,
      updated_at: null,
      updated_by: null,
      wd_code: null,
      wf_code: null
    }
    this.timeSlots = ['6:00am', '6:30am', '7:00am', '7:30am', '8:00am', '8:30am', '9:00am', '9:30am', '10:00am', '10:30am', '11:00am', '11:30am',
    '12:00pm', '12:30pm', '1:00pm', '1:30pm', '2:00pm', '2:30pm', '3:00pm', '3:30pm', '4:00pm', '4:30pm', '5:00pm', '5:30pm', '6:00pm', '6:30pm'];
  //'12:00am', '12:30am', '1:00am', '1:30am', '2:00am', '2:30am', '3:00am', '3:30am', '4:00am', '4:30am', '5:00am', '5:30am','7:00pm', '7:30pm', '8:00pm', '8:30pm', '9:00pm', '9:30pm', '10:00pm', '10:30pm', '11:00pm', '11:30pm'
  this.Un_timeSlots = ['7:00pm', '7:30pm', '8:00pm', '8:30pm', '9:00pm', '9:30pm', '10:00pm', '10:30pm','11:00pm',  '11:30pm'];
  this.filteredTimeSlots = this.timeSlots;
  }

  async ngOnInit() {
    debugger
    this.dateFormat = environment.DateFormat;
    this.issue_status = 'New';
    /* if (this.wf_code == null && typeof this.wf_code == 'undefined') {
      this.wf_code = this.menuData.para_1;
    } */
    if (typeof this.config.data !== 'undefined') {
      this.taskId = this.config.data.task_id;
      this.checkList = this.config.data.checkList;
      this.redirectMenu = this.config.data.redirectMenu;
      this.wf_code = this.config.data.wf_code;
      this.allow_edit = '1';
    } else {
      this.taskId = 0;
      this.checkList = [];
      this.redirectMenu = null;
      if (sessionStorage.getItem('menuData') != null && sessionStorage.getItem('menuData') !== '') {
      this.menuData = JSON.parse(sessionStorage.getItem('menuData'));
      this.allow_edit = this.menuData.allow_edit;
    }
    }
    this.taskDetails.wf_code = this.wf_code;
    this.taskCreationForm = new FormGroup({
      name: new FormControl(''),
      description: new FormControl(''),
      type: new FormControl(''),
      priority: new FormControl(''),
      notes: new FormControl(''),
      end_date: new FormControl(''),
      start_dt: new FormControl(''),
      next_followup: new FormControl('')
    });

    let params: any = await this.crudService.fetchWFCompanyParams(this.wf_code, this.companyId).toPromise();
    if (params.length > 0) {
      this.workflow_params = params[0];
    }
    if (this.workflow_params.issueType_vs !== '' && this.workflow_params.issueType_vs !== null) {
      let issueTypeList: any = await this.crudService.getByValuesetName(this.workflow_params.issueType_vs).toPromise();
      issueTypeList.sort((a, b) => a.valueset_detail_name > b.valueset_detail_name && 1 || -1);
      if (issueTypeList.length > 0) {
        this.issueTypeList = issueTypeList;
      }
    }
    if (this.workflow_params.category_vs !== '' && this.workflow_params.category_vs !== null) {
      let categoryList: any = await this.crudService.getByValuesetName(this.workflow_params.category_vs).toPromise();
      categoryList.sort((a, b) => a.valueset_detail_name > b.valueset_detail_name && 1 || -1);
      if (categoryList.length > 0) {
        this.categoryList = categoryList;
      }
    }
    let data: any = await this.crudService.getAllSSPUsers(this.companyId).toPromise();
    //data.sort((a, b) => a.full_name > b.full_name && 1 || -1);
    this.staffList = data;
    await this.fetchTaskInfo();
    if (this.wf_code === '7' || this.wf_code === 7) {
      this.showInfoFieldsColumn = true;
      this.info_fields = {
        field_1_caption: 'Job No',
        field_2_caption: 'Allocation ID',
        field_3_caption: 'WF Step'
      }
    } else {
      this.showInfoFieldsColumn = false;
      this.info_fields = {
        field_1_caption: 'Field 1',
        field_2_caption: 'Field 2',
        field_3_caption: 'Field 3'
      }
    }
  }

  async fetchTaskInfo() {
    let boardData: any = await this.wfService.fetchBoardDetails(this.wf_code).toPromise();
    this.workflowDetails = boardData;
    let columnsData: any = await this.wfService.fetchColumnsList(this.wf_code, this.userDetails.id).toPromise();
    this.stagesList = columnsData;
    let checkListData: any = await this.crudService.getDataByField('task_checklist', 'task_id', this.taskId).toPromise();
    checkListData.forEach(item => {
      if (item.checked == '1') {
        item.isChecked = true;
      } else {
        item.isChecked = false;
      }
    });
    this.checkList = checkListData;
    if (this.taskId === 0 || this.taskId == null) {
      this.taskDetails = {
        description: null,
        category: 1,
        created_at: new Date(),
        created_by: this.userDetails.id,
        end_dt: null,
        id: null,
        name: null,
        next_followup: null,
        notes: null,
        priority: 'Normal',
        process_id: this.companyId,
        start_dt: null,
        status: '0',
        tags: null,
        type: null,
        updated_at: null,
        updated_by: null,
        wd_code: this.stagesList[0].code,
        wf_code: this.wf_code
      }
      this.allowEditing = true;
      this.selectedPriority = { name: 'Normal', code: 'Normal' },
        this.selectedIssueType = {};
      this.selectedStage = this.stagesList[0];
      this.issue_status = this.getTicketStatus();
      this.start_dt = new Date();
      //this.end_date.setDate(new Date().getDate() + 1);
     // this.end_date = new Date(new Date().getTime() + (1000 * 60 * 60 * 24));
      this.end_date = new Date(new Date().getTime());
    } else {
      this.crudService.getDataByField('task_head', 'id', this.taskId).subscribe(async (res: any) => {
        this.taskDetails = res[0];
        console.log("this.taskDetails",this.taskDetails);
        this.selectedPriority = this.priorityOptions.find(val => val.code === this.taskDetails.priority);
        if(this.taskDetails.type == 1){
          this.selectedIssueType = this.issueTypeList.find(val => val.valueset_detail_name === this.config.data.step);
        }
        else{
          this.selectedIssueType = this.issueTypeList.find(val => val.valueset_detail_name === this.taskDetails.type);
        }
        this.selectedCategory = this.categoryList.find(val => val.code === this.taskDetails.category);
        this.selectedStage = this.stagesList.find(val => val.id === this.taskDetails.wd_code);
       // let formattedDt = new Date(this.taskDetails.end_dt).toUTCString()
       debugger
       var end_date = new Date(this.taskDetails.end_dt)
        this.end_date = new Date( end_date.getTime() + (end_date.getTimezoneOffset() * 60000));
        this.time = this.datepipe.transform( new Date(this.taskDetails.end_dt), "h:mma" ).toLowerCase();
        this.start_dt = new Date(this.taskDetails.start_dt);
        this.next_followup = new Date(this.taskDetails.next_followup);
        this.issue_status = this.getTicketStatus();
        this.supportStage = this.selectedStage;
        this.updateEditingStatus();
        this.crudService.getTaskUsers(this.taskId).subscribe((data: any[]) => {
          let usersList = data.filter(val => val.status === '1');
          this.selectedAllocatees = usersList;
          this.old_selectedallocatees = usersList
        });
        this.crudService.getDataByField('SSPSearchView', 'id', this.taskId).subscribe((taskData: any[]) => {
          this.taskValues = taskData[0];
        });
        if (this.taskDetails.wf_code == '1') {
          let data = await this.crudService.getDataByField('Company', 'id', this.taskDetails.tags).toPromise();
          let company = data[0];
          this.taskDetails.company_info = company;
        }else if (this.taskDetails.wf_code == '7') {
          let allocData : any = await this.crudService.getDataByField('JobAllocation', 'id', this.taskDetails.tags).toPromise();
          let data = await this.crudService.getDataByField('Company', 'id', allocData[0].contractor_id).toPromise();
          let company = data[0];
          this.taskDetails.company_info = company;
        }
      })
    }
  }

  async loadCompanyAction() {
    let data = await this.crudService.getDataByField('Company', 'id', this.taskDetails.tags).toPromise();
    let company = data[0];
    if (this.taskDetails.type == 'OB') {
      const ref = this.dialogService.open(TPRegistrationComponent, {
        data: {
          compToken: company.token,
        },
        width: '100%',
        height: '100%'
      })
      ref.onClose.subscribe((response: any) => {
      });
    } else if (this.taskDetails.type == 'CC' || this.taskDetails.type == 'SC') {
      const ref = this.dialogService.open(CompanyComplianceComponent, {
        header: company.business_name,
        footer: company.email + ', ' + company.phone,
        data: {
          compToken: company.token,
          compId: company.id,
        },
        width: '100%',
        height: '100%'
      })
    }
  }

  updateDateTime(d, e) {
    if (d && e) {
      this[d] = e;
    }
  }
  async saveRecord() {
    /* //console.log(this.taskCreationForm.status);
    //console.log(this.taskDetails); */

    if (typeof this.selectedIssueType?.valueset_detail_name !== 'undefined' && this.taskDetails.description !== '' && this.taskDetails.description !== null && this.start_dt != null && this.end_date != null) {
      if (this.end_date.getTime() < this.start_dt.getTime() && this.taskDetails.id == '') {
        this.messageService.add({ severity: 'error', summary: 'Invalid Date Selected', detail: 'Required By Date Cant be lesser than Report Date.', life: 4000 });
      } else {

        this.taskDetails.priority = this.selectedPriority.code;
        this.taskDetails.type = this.selectedIssueType.valueset_detail_name;
        // this.taskDetails.notes = this.selectedIssueType.valueset_detail_name;
        this.taskDetails.wd_code = this.selectedStage.code;
        /* if (this.taskCreationForm.status === 'VALID') { */
       
        // let today3 = new Date(this.end_date)
        // var date = this.datepipe.transform(new Date(today3), "yyyy-MM-ddThh:mm:ssZZZZZ")
        // this.taskDetails.end_dt =  new Date(date)

let followup_dateTime: any = null;
if (typeof this.end_date !== 'undefined') {
  if (this.end_date !== null && this.time !== '' && this.time !== null) {

    const dateFromPicker1 = formatDate(this.end_date, 'M/DD/YYYY', 'en-US')
    const dateFromPicker = dateFromPicker1.toLocaleString().replace(', 00:00:00', '');
    if(this.time != undefined)
    {
      this.timeFromPicker = this.time;
    }
    else
    {
      this.timeFromPicker = '10:00am';
    }
    debugger
    const dateParts: any[] = dateFromPicker.split("/");
    const timeParts: any[] = this.timeFromPicker.split(":");
    let minutes: any;
    let hours: any;
    if (timeParts[1].includes('pm')) {
      if(timeParts[0]=="12"){
        hours = parseInt(timeParts[0]);
      minutes = timeParts[1].replace('pm', '');
      }
      else{
         hours = parseInt(timeParts[0]) + 12;
      minutes = timeParts[1].replace('pm', '');
      }

    } else {
      hours = parseInt(timeParts[0]);
      minutes = timeParts[1].replace('am', '');

    }
    const localDate = new Date(dateParts[2],dateParts[0]-1,dateParts[1], hours, minutes);
    followup_dateTime = localDate;
    this.taskDetails.end_dt = followup_dateTime;
    debugger
  }
}

        this.taskDetails.start_dt = this.start_dt;
        this.taskDetails.name = this.selectedIssueType.name;
        this.taskDetails.next_followup = this.next_followup ? this.next_followup : null;
        if (this.taskId) {
          this.taskDetails.updated_at = new Date();
          debugger
          let data = await this.commonservice.saveRecord('task_head', this.taskDetails);
          if (data.success) {
            if (this.supportStage.code !== this.taskDetails.wd_code) {
              this.wfService.updateAction(this.taskDetails.id, 'Support Status Changed From ' + this.supportStage.name + ' To ' + this.selectedStage.name, 'Support Status Updated', this.companyId, this.userDetails.id);
              this.supportStage = this.selectedStage;
              this.notifyUsers(this.taskDetails.id + '- Support Status Updated',
                'Please Review.',
                'Notification Sent for Review to Requester.',
                this.reqUser);
            }
            this.messageService.add({ severity: 'success', summary: 'Successful', detail: 'Record Updated', life: 4000 });
          await  this.saveTaskUsers("save");
            //this.ref.close(this.taskDetails);
            this.updateEditingStatus();
            debugger  
            let wfStatusData: any = await this.crudService.getDataByField('job_wf_status', 'task_id', this.taskDetails.id).toPromise();
            wfStatusData[0].follow_up_dt = this.taskDetails.end_dt
            wfStatusData[0].step = this.taskDetails.type
            debugger
            // let usersArr: any[] = [];
            // if(this.selectedAllocatees.length >0){
            //   this.selectedAllocatees?.forEach(user => {
            //     let userInfo = this.staffList.find(val => (val.user_id == user.user_id));
            //     usersArr.push(userInfo.full_name);
            //   });
            //   wfStatusData[0].users = '';
            //   wfStatusData[0].users = usersArr.toString();
            // }
            debugger
            let wfStatusData2: any = await this.commonservice.saveRecord('Job_WF_Status',wfStatusData[0] )
            console.log("this.wfStatusData2",wfStatusData2);

          } else {
            this.messageService.add({ severity: 'error', summary: 'Error', detail: 'Record Not Updated', life: 4000 });
          }
        } else {
          //console.log(this.taskDetails);
          this.taskDetails.next_followup = null;
          this.taskDetails.status = '2';
          debugger
          this.crudService.Create(this.taskDetails, 'taskHead').subscribe((data: any) => {
            if (data.success === 1) {
              this.taskId = data.id;
              this.taskDetails.id = data.id;
              this.supportStage = this.selectedStage;
              this.messageService.add({ severity: 'success', summary: 'Successful', detail: 'Record Created. ', life: 4000 });
              this.wfService.updateAction(this.taskDetails.id, 'Incident raised', 'Created', this.companyId, this.userDetails.id);
              this.saveTaskUsers("save");
              this.updateEditingStatus();
              /* this.ref.close(this.taskDetails); */
            } else {
              this.messageService.add({ severity: 'error', summary: 'Error', detail: 'Record Not Created', life: 4000 });
            }
          });
        }
      }
      /* } */
    } else {
      this.messageService.add({ severity: 'error', summary: 'Issue Details Required', detail: 'Issue Details cant be left blank.', life: 4000 });
    }

  }
  async saveTaskUsers(action) {
   await this.crudService.getTaskUsers(this.taskId).subscribe(async (taskusersList: any) => {
    await  taskusersList.forEach(async (taskuser) => {
        let userList = this.selectedAllocatees.filter(val => val.user_id === taskuser.user_id);
        taskuser.id = taskuser.task_user_id;
        taskuser.updated_at = new Date().toLocaleString();
        taskuser.updated_by = this.userDetails.id;
        taskuser.action_yn = 'Y';
        taskuser.notify_yn = 'Y';
        taskuser.task_id = this.taskId;
        taskuser.wf_wd_id = null;
        taskuser.created_by = taskuser.task_created_by;
        taskuser.created_at = taskuser.task_created_at;
        if (userList.length > 0) {
          let changed = false;
          if (taskuser.status == '3') {
            changed = true;
          }
          taskuser.status = '1';
         await this.crudService.Update(taskuser, 'taskUsers').subscribe(async (data: any) => {
            if (changed) {
              await   this.wfService.updateAction(this.taskDetails.id, 'Incident allocated to ' + taskuser.full_name, 'Staff Re-Allocated', this.companyId, this.userDetails.id);
              this.notifyUsers('Ticked Allocated : ' + this.taskDetails.id,
                'Please Login to the portal to view Additional Details',
                'Allocation Notification to ' + taskuser.user_id + ' - ' + taskuser.full_name + ' Sent.',
                taskuser.user_id);

            }
            if(action=='save'){
              this.sendallocationNotification(this.jobAllocationResults,taskuser,taskuser.status)
            }
          });
        } else {

          taskuser.status = '3';
         await this.crudService.Update(taskuser, 'taskUsers').subscribe(async (data: any) => {
          debugger
          await   this.wfService.updateAction(this.taskDetails.id, 'Incident un-allocated from ' + taskuser.full_name, 'Staff De-Allocated', this.companyId, this.userDetails.id);
          if(this.removeallocate !==''){
            this.notifyUsers('Ticked Un-Allocated : ' + this.taskDetails.id,
            'Please contact Admin/Requested Staff for details.',
            'Un-allocation Notification to ' + this.removeallocate.user_id + ' - ' + this.removeallocate.full_name + ' Sent.',
            taskuser.user_id);
            this.removeallocate=''
          }
          });
        }
        this.issue_status = this.getTicketStatus();
      });

      if(action=='save'){
        this.messageService.add({ severity: 'success', detail: 'Notification to Allocated Users Sent', life: 4000 });
       }
      this.selectedAllocatees.forEach((allocatee) => {
        let newuser: any = {};
        let userList = taskusersList.filter(val => val.user_id === allocatee.user_id);
        if (userList.length > 0) {
        } else {
          newuser.user_id = allocatee.user_id;
          newuser.task_id = this.taskId;
          newuser.action_yn = 'Y';
          newuser.notify_yn = 'Y';
          newuser.wf_wd_id = null;
          newuser.wf_code = null;
          newuser.wd_code = null;
          newuser.status = '1';
          newuser.created_at = new Date().toLocaleString();
          newuser.created_by = this.userDetails.id;
          newuser.updated_at = null;
          newuser.updated_by = null;
          this.crudService.Create(newuser, 'taskUsers').subscribe(async (data: any) => {
            if (data.success == 1) {
              this.notifyUsers('Incident Allocated : ' + this.taskDetails.id,
                'Please Login to the portal to view Additional Details',
                'Allocation Notification to ' + allocatee.user_id + ' - ' + allocatee.full_name + ' Sent.',
                allocatee.user_id);
             await this.wfService.updateAction(this.taskDetails.id, 'Incident allocated to ' + allocatee.full_name, 'Staff Allocated', this.companyId, this.userDetails.id);
              newuser.id = data.id;
            }
            if(action=='save'){
              this.sendallocationNotification(this.jobAllocationResults,newuser,newuser.status)
            }       
          });
        }
      });
     await this.old_selectedallocatees.forEach((allocatee) => {
     if(allocatee.status=='3' && action=='save'){
      this.sendallocationNotification(this.jobAllocationResults,allocatee,allocatee.status)
     }
      })
      if(action=='save'){
        this.messageService.add({ severity: 'success', detail: 'Notification to Un-Allocated Users Sent', life: 4000 });
       }     
    });
  }
  async sendallocationNotification(ScheduleFormData,user,status) {
    
    let jobtaskData: any = await this.crudService.getTaskdetail(this.taskId).toPromise();
    console.log("wfStatusData",job_task_Data);
    var job_task_Data =jobtaskData[0]
    this.loading = true;
    debugger
    //console.log('this.jobAllocationResults?.info?.job_claim_no',this.jobAllocationResults?.info?.job_claim_no)
    if(job_task_Data.Job_Claim_No != '' || job_task_Data.Job_Claim_No != null)
    {
      var claimNo :any = job_task_Data.Job_Claim_No;
    }
    if(job_task_Data.Job_Claim_No == '' || job_task_Data.Job_Claim_No == null)
    {
      var claimNo :any = "N/A";
    }
    if(this.env.hosting == 'UK'){
      var emailSubject = claimNo + ', Site Address - ' + job_task_Data.Client_Address +','+job_task_Data.Client_Suburb+','+job_task_Data.Client_Post_Code;
    }
    else{
      var emailSubject = 'Claim Number - ' + claimNo + ', Site Address - ' + job_task_Data.Client_Address +','+job_task_Data.Client_Suburb+','+job_task_Data.Client_Post_Code;
    } 
    var time = this.datepipe.transform( new Date(job_task_Data.Followup_Date), "dd/MM/yyyy h:mma" ).toLowerCase();
if(status =='1'){
  var message_content = 'Hi ' +user.full_name  + ' , <br/><br/>You have been allocated a task for job number '+ job_task_Data.Job_No+'.' 
  + '<p style="margin-left:30px"><br/><b>Job No:</b> ' + job_task_Data.Job_No
  + '<br/><b>Task ID: </b>' + job_task_Data.Task_ID  
  + '<br/><b>Task Details: </b>' + job_task_Data.Task_Name +', '+job_task_Data.Task_Description+ '<br/>' 
  + '<b>Follow Up Date: </b>' + time + '<br/>' 
  + '<b>Priority: </b>' +job_task_Data.Priority + '<br/>' 
  + '<br/><b>Client Name: </b>' +job_task_Data.Client_Name + '<br/>' + '<b>Address: </b>' +job_task_Data.Client_Address+', '+job_task_Data.Client_Suburb+', '+job_task_Data.Client_Post_Code
  + '<br/><b>Contact: </b>' +job_task_Data.Client_Mobile +', '+job_task_Data.Client_Mail + '<br/>' 
  + '<br/><b>TP Name: </b>' +job_task_Data.TP_Name + '<br/>' + '<b>Address: </b>' +job_task_Data.TP_Address+', '+job_task_Data.TP_Suburb
  + '<br/><b>Contact: </b>' +job_task_Data.TP_Contact_Name +', '+job_task_Data.TP_Phone+', '+job_task_Data.TP_Email
  +'</p>'
  }
  else if(status =='3'){
    var message_content = 'Hi ' +user.full_name  + ' , <br/><br/>You have been unallocated from a task for job number '+ job_task_Data.Job_No +'.'
    + '<p style="margin-left:30px"><br/><b>Job No:</b> ' + job_task_Data.Job_No
    + '<br/><b>Task ID: </b>' + job_task_Data.Task_ID  
    + '<br/><b>Task Details: </b>' + job_task_Data.Task_Name +', '+job_task_Data.Task_Description+ '<br/>' 
    + '<b>Follow Up Date: </b>' + time + '<br/>' 
    + '<b>Priority: </b>' +job_task_Data.Priority + '<br/>' 
    + '<br/><b>Client Name: </b>' +job_task_Data.Client_Name + '<br/>' + '<b>Address: </b>' +job_task_Data.Client_Address+', '+job_task_Data.Client_Suburb+', '+job_task_Data.Client_Post_Code
    + '<br/><b>Contact: </b>' +job_task_Data.Client_Mobile +', '+job_task_Data.Client_Mail + '<br/>' 
    + '<br/><b>TP Name: </b>' +job_task_Data.TP_Name + '<br/>' + '<b>Address: </b>' +job_task_Data.TP_Address+', '+job_task_Data.TP_Suburb
    + '<br/><b>Contact: </b>' +job_task_Data.TP_Contact_Name +', '+job_task_Data.TP_Phone+', '+job_task_Data.TP_Email
    +'</p>'
  }

  
   let mailSubject = '';

    let documentList = [];
    let data: any = {
      email: {
        from: '',
        //need to configure user mails (to:user.email) while moving to live
        to: user.email, 
        cc: '',
        bcc: '',
        subject: emailSubject,
        message: message_content,
        msg: message_content,
        ClientId: job_task_Data.Client_ID,
        ref_type: 'Flagging',
        ref_id: 1   // doubt
      },
      
    }
    let temp_type = 'email';

    let atts = {  };
    if(this.env.emailRoutes.common == 'sendgrid')
    {
      const datobj2 = {
        subject : data.email.subject,
        body : data.email.message,
        toEmail : data.email.to,
        ref_id : 1,
        ref_type : 'Flagging',
        attachments: atts
        }
  
      if(datobj2.toEmail?.length>0)
      {
        console.log('datobjjj11',datobj2);
        debugger
        this.crudService.sendmailSendgrid(datobj2,'commonMail2').subscribe((res2: any[]) => {
          console.log("res2",res2);        
         });
      }

    }
  
    this.loading = false;
  }
  removeUser(taskuser) {
    // console.log(userinfo);
    // this.removeallocate = userinfo
    // this.selectedAllocatees = this.selectedAllocatees.filter(val => val.user_id !== userinfo.user_id);
    // this.saveTaskUsers();
    this.removeallocate = taskuser
    taskuser.id = taskuser.task_user_id;
    taskuser.updated_at = new Date().toLocaleString();
    taskuser.updated_by = this.userDetails.id;
    taskuser.action_yn = 'Y';
    taskuser.notify_yn = 'Y';
    taskuser.task_id = this.taskId;
    taskuser.wf_wd_id = null;
    taskuser.created_by = taskuser.task_created_by;
    taskuser.created_at = taskuser.task_created_at;
    this.selectedAllocatees = this.selectedAllocatees.filter(val => val.user_id !== taskuser.user_id);
   // this.saveTaskUsers();
   taskuser.status = '3';   
     this.crudService.Update(taskuser, 'taskUsers').subscribe(async (data: any) => {
     await   this.wfService.updateAction(this.taskDetails.id, 'Incident un-allocated from ' + taskuser.full_name, 'Staff De-Allocated', this.companyId, this.userDetails.id);
     if(this.removeallocate !==''){
       this.notifyUsers('Ticked Un-Allocated : ' + this.taskDetails.id,
       'Please contact Admin/Requested Staff for details.',
       'Un - Allocation Notification to ' + this.removeallocate.user_id + ' - ' + this.removeallocate.full_name + ' Sent.',
       taskuser.user_id);
       this.removeallocate=''
     }
     });
     
  }

  updateStatus(action, status) {
    this.confirmationService.confirm({
      message: 'The current changes will be Saved and ' + action + '. Do you want to Proceed?',
      header: 'Confirmation',
      icon: 'pi pi-exclamation-triangle',
      accept: () => {
        this.taskDetails.status = status;
        if (status === '4') {
          this.selectedStage = this.stagesList[0];
        }
        this.saveRecord();

        this.wfService.updateAction(this.taskDetails.id, 'Status ' + action, action, this.companyId, this.userDetails.id);

        this.notifyUsers('Issue No ' + this.taskDetails.id + ' - ' + action,
          'The incident has been ' + action + ' by ' + this.userDetails.first_name + ' ' + this.userDetails.last_name,
          'Notification Sent to Support Team Involved.',
          this.selectedAllocatees);
      }
    });
  }

  sendMail() {
    /*  const params = {
          sms: null,
          email: this.templ.template[0].email,
          type: 'email',
          selectedTpl: null,
          sufix: null,
          attachments: null, //atts,
          jobData: null,
          replyTo: null,
          account: this.EmailAccount
        }
        this.crudService.sendMessage(params).subscribe((res2: any[]) => {
          this.JobService.storeInSchedule(this.jobAllocationDetails.jobSummary);
        }); */
  }

  getTicketStatus(): any {
    let status_desc = '';
    if (!this.taskDetails.id) {
      status_desc = 'New';
    } else if (this.taskDetails.status == '1') {
      status_desc = 'Open';
    } else if (this.taskDetails.status == '2') {
      status_desc = 'Submitted';
    } else if (this.taskDetails.status == '3') {
      status_desc = 'Closed';
    } else if (this.taskDetails.status == '4') {
      status_desc = 'Re-Opened';
    } else if (this.taskDetails.status == '5') {
      status_desc = 'Archived';
    }
    return status_desc;
  }

  submitTicket() {
    this.confirmationService.confirm({
      message: 'The current changes will be Saved and Submitted. Are you sure you want to Proceed?',
      header: 'Confirm',
      icon: 'pi pi-exclamation-triangle',
      accept: () => {
        this.taskDetails.status = '2';
        this.saveRecord();
        /* this.notifyUsers('Ticked Updated and Submitted', 'Mail Notifications to the support team Sent.', this.selectedAllocatees); */
      }
    });
  }

  notifyUsers(subject, message, notification, users) {
    let allocatees = null;
    let mail_ids = null;
    //console.log(users)
   // this.messageService.add({ severity: 'success', summary: subject, detail: notification, life: 4000 });
    if (this.selectedAllocatees.length > 0) {

      this.selectedAllocatees.forEach(allocatee => {
        if (allocatees === null) {
          allocatees = allocatee.first_name + ' ' + allocatee.last_name;
          mail_ids = allocatee.email ? allocatee.email : 'admin@agileitexperts.com';
        } else {
          allocatees = allocatees + ', ' + allocatee.first_name + ' ' + allocatee.last_name;
          mail_ids = mail_ids + ', ' + allocatee.email ? allocatee.email : 'admin@agileitexperts.com'
        }
        let AppNotifications: any = {
          status: '1',
          user_id: allocatee.user_id,
          message,
          read_status: '0',
          title: subject,
          ref_type: 'TASK',
          read_on: null,
          ref_id: this.taskDetails.id
        }
        AppNotifications.title = 'Task Notification';
        this.wfService.updateRecord('AppNotifications', AppNotifications);
      });
    } else {
      allocatees = 'Unallocated.'
    }
    /* let data={sms:null,email:{ cc:'', subject:'test ',bcc:'envirofrontiersk@gmail.com',to:'krishnanshridhar@gmail.com', attachments:'',msg:'This is to notify that a new ticket has been created . Please refer to below details.' }, issueDetails:{ id:'22', issue_description:'500 error on opening job page', priority:'Critical',created_by:'Shri',AllocatedTo:'Venkat' }, type:'email',selectedTpl:null,sufix:null,jobData:null,attachments:{},replyTo:'krishnanshridhar@gmail.com',account:'AU'} */



    let data = {
      sms: null,
      email: {
        cc: mail_ids,
        subject: subject,
        bcc: 'envirofrontiersk@gmail.com',
        to: 'venkat@agileitexperts.com, ' + this.reqUser.email,
        attachments: '',
        msg: message,
        message: message
      },
      issueDetails: {
        id: this.taskDetails.id,
        issue_description: this.taskDetails.description,
        priority: this.taskDetails.priority,
        created_by: this.topbardata.reqby,
        AllocatedTo: allocatees
      },
      type: 'email',
      selectedTpl: null,
      sufix: null,
      jobData: null,
      attachments: {},
      replyTo: 'krishnanshridhar@gmail.com',
      account: 'AU'
    };
    this.crudService.sendMail(data).subscribe((response: any) => {
      console.log(response)
    });
  }

  loadListPage() {
    this.confirmationService.confirm({
      message: 'The current changes will be lost. Are you sure you want to Proceed?',
      header: 'Confirm',
      icon: 'pi pi-exclamation-triangle',
      accept: () => {
        //console.log('confirmed');
        if (typeof this.ref !== 'undefined') {
          this.ref.close('Closed');
        }

        if (typeof this.redirectMenu.menu_id !== 'undefined') {
          this.router.navigateByUrl(this.redirectMenu.router_url);
        } else {
          this.crudService.getDataByField('MenuModalView', 'menu_id', '69').subscribe((menuItem: any[]) => {
            if (menuItem.length > 0) {
              sessionStorage.setItem('menuData', JSON.stringify(menuItem[0]));
              let item = menuItem[0];
              this.router.navigateByUrl(item.router_url);
            }
          });
        }
      }
    });
  }

  updateEditingStatus() {
    if (this.taskDetails.id) {

      if ((this.allow_edit === '1' || this.taskDetails.created_by === this.userDetails.id) &&
        (this.taskDetails.status === '1' || this.taskDetails.status === '2' || this.taskDetails.status === '4')) {
        this.allowEditing = true;
      } else {
        this.allowEditing = false;
      }
    } else {
      this.allowEditing = true;
    }
    if (this.selectedStage.id === this.stagesList[this.stagesList.length - 1].id) {
      this.isSupportComplete = true;
    } else {
      this.isSupportComplete = false;
    }

    this.topBarInfo();
    this.allowEditing = true;

  }

  topBarInfo() {
    this.topbardata = [];
    /* if(this.taskDetails.end_dt - new Date()) */
    let dt1 = new Date();
    let dt2 = new Date(this.taskDetails.end_dt);
    let diff = Math.floor((Date.UTC(dt2.getFullYear(), dt2.getMonth(), dt2.getDate()) - Date.UTC(dt1.getFullYear(), dt1.getMonth(), dt1.getDate())) / (1000 * 60 * 60 * 24));

    if (diff > 0) {
      this.topbardata.due = 'Due In ' + diff + ' Days';
    } else if (diff < 0) {
      this.topbardata.due = diff + ' Days';
    } else if (diff == 0) {
      this.topbardata.due = 'Due Today';
    }

    let reqUserInfo = this.staffList.filter(val => val.user_id === this.taskDetails.created_by);
    if (reqUserInfo.length > 0) {
      this.reqUser = reqUserInfo[0];
      this.topbardata.reqby = this.reqUser.first_name + ' ' + this.reqUser.last_name;
    } else {
      this.topbardata.reqby = 'Admin';
    }

    this.topbardata.status = this.issue_status;
    if(this.topbardata.status == 'Closed'){
      this.topbardata.flagged = false
    }
    else{
      this.topbardata.flagged = true
    }
    this.topbardata.created_dt = new Date(this.taskDetails.created_at);
  }

  async viewTaskAction() {
    this.loading = true;
    if (this.taskDetails.wf_code == '1') {
      await this.loadCompanyAction();
    } else if (this.taskDetails.wf_code == '7') {
      await this.loadJobAction();
    }
  }

  async loadJobAction() {
    this.loading = true;
    let task_values: any;
    let taskValuesData: any = await this.crudService.getDataByField('task_values', 'task_id', this.taskId).toPromise();
    if (taskValuesData.length > 0) {
      task_values = taskValuesData[0];
      if (task_values.step !== null && task_values.step !== '') {
      }
    }
    if (task_values.job_alloc_id !== '1') {
      let smryData: any = await this.crudService.getJobSummary(task_values.job_id, task_values.job_alloc_id).toPromise();
      let jobAllocationResults = await this.JobService.getJobInfo(smryData[0]);
      this.jobAllocationResults = jobAllocationResults
      this.loading = false;
      let JobStatus: any = 'Approve Quote';
      if (JobStatus === 'Cost Saved' || JobStatus === 'Approve Quote' || JobStatus === 'Await Cost' || JobStatus === 'Quote Rejected') {
        let viewType = 'Quote';
        const ref = this.dialogService.open(JobDetailsApprovalsComponent, {
          data: {
            jobId: task_values.job_id,
            allocId: task_values.job_alloc_id,
            jobSummary: jobAllocationResults,
            viewType: viewType,
            defaultTab: task_values.step
          },
          width: '100%',
          height: '100%'
        })
        ref.onClose.subscribe((response: any) => {
          //this.loadAllJobsList();
        });
      } else if (JobStatus === 'Approve Invoice' || JobStatus === 'Await Invoice' || JobStatus === 'Invoice Processed' || JobStatus === 'Accounts Proecessed' || JobStatus === 'Job Completed') {
        let viewType = 'Approval';
        const ref = this.dialogService.open(JobDetailsApprovalsComponent, {
          data: {
            jobId: task_values.job_id,
            allocId: task_values.job_alloc_id,
            jobSummary: jobAllocationResults,
            viewType: viewType,
            defaultTab: task_values.step
          },
          width: '100%',
          height: '100%'
        })
        ref.onClose.subscribe((response: any) => {
          // this.loadAllJobsList();
        });

      } else if (JobStatus === 'Await Acceptance' || JobStatus === 'Send Offer' || JobStatus === 'Quote Rejected') {
        const ref = this.dialogService.open(JobAdminComponent, {
          data: {
            jobId: task_values.job_id,
          },
          width: '100%',
          height: '100%'
        })
        ref.onClose.subscribe((response: any) => {
          //this.loadAllJobsList();
        });
      }
    } else {
      const ref = this.dialogService.open(JobAdminComponent, {
        data: {
          jobId: task_values.job_id,
        },
        width: '100%',
        height: '100%'
      })
      ref.onClose.subscribe((response: any) => {
        //this.loadAllJobsList();
      });
    }
  }

  updateEvent() {
    this.eventTypeList = [{ code: 'communication', name: 'New Communication' },
    { code: 'followup', name: 'Set Reminder/Follow Up' },
    { code: 'notify', name: 'Notify All Allocated Users' },
    { code: 'action', name: 'Action Info' },
    ];
    this.selectedEvent = null
    this.next_followup_date = null
    this.event_remarks = null
    this.showEvent = true;
    // this.next_followup_date = this.commonservice.DateToLocalTimeString(new Date());
  }

  saveEventInfo() {
    if (this.event_remarks !== null) {
      this.next_followup = this.next_followup_date ? this.next_followup_date : null;
      this.taskDetails.notes = 'Remarks : ' + this.event_remarks + '. Date :' + new Date().toLocaleString();
      if (this.selectedEvent.code === 'communication') {
        this.wfService.updateAction(this.taskDetails.id, this.event_remarks, 'Communication', this.companyId, this.userDetails.id);
        this.JobService.updateJobSchedule(this.taskValues.job_id, this.taskValues.job_alloc_id, this.event_remarks, this.userDetails);
        this.saveRecord();
        this.showEvent = false;
      } else if (this.selectedEvent.code === 'followup') {
        if (this.next_followup_date !== null) {
          this.wfService.updateAction(this.taskDetails.id, this.event_remarks, 'Follow-Up', this.companyId, this.userDetails.id);
          this.saveRecord();
          this.showEvent = false;
        } else {
          this.messageService.add({ severity: 'error', summary: 'Follow-Up Date cant be left empty for the Selected Event Type', detail: 'Follow-Up Date cant be left empty for the Selected Event Type', life: 4000 });
        }
      } else if (this.selectedEvent.code === 'notify') {
        this.notifyUsers('Task Notification : ' + this.taskDetails.id + ', Job No : ' + this.taskValues.job_alloc_id,
          this.event_remarks,
          'Task Updated. Notification to Allocated Users Sent.',
          this.userDetails.id);
        this.wfService.updateAction(this.taskDetails.id, this.event_remarks, 'Notification', this.companyId, this.userDetails.id);
        this.saveRecord();
        this.showEvent = false;
      } else if (this.selectedEvent.code === 'action') {
        this.notifyUsers('Task Update : ' + this.taskDetails.id + ', Job No : ' + this.taskValues.job_alloc_id,
          this.event_remarks,
          'Task Updated. Notification to Allocated Users Sent.',
          this.userDetails.id);
        this.wfService.updateAction(this.taskDetails.id, this.event_remarks, 'Action', this.companyId, this.userDetails.id);
        this.saveRecord();
        this.showEvent = false;
      }
      this.messageService.add({ severity: 'success', summary: 'Event Details Updated', detail: 'Event Details Updated', life: 4000 });
    } else {
      this.messageService.add({ severity: 'error', summary: 'Remarks cant be left Blank', detail: 'Remarks cant be left Blank', life: 4000 });
    }
  }
  taskDetail() {
    const Status = document.getElementById("status");
    const Priority = document.getElementById("priority");
    const Due = document.getElementById("due");
    const RedBy = document.getElementById("reqby");
    const EndDate = document.getElementById("endDate");
    if (Priority.className == "labelShow"
      && Due.className == "labelShow"
      && RedBy.className == "labelShow"
      && EndDate.className == "labelShow"
      && Status.className == "labelShow"
    ) {
      Status.className = "priLabel";
      Priority.className = "priLabel";
      Due.className = "priLabel";
      RedBy.className = "priLabel";
      EndDate.className = "priLabel";
    } 
    else {
      Status.className = "labelShow";
      Priority.className = "labelShow";
      Due.className = "labelShow";
      RedBy.className = "labelShow";
      EndDate.className = "labelShow";
    }
}
search(event) {
  var currentTime = new Date();
  if(formatDate(this.end_date, 'M/d/yy', 'en-US') == formatDate(currentTime, 'M/d/yy', 'en-US')){
  this.filteredTimeSlots = this.filteredTimeSlots.filter((val: any) => val.includes(event.query));
}else {
  this.filteredTimeSlots = this.timeSlots.filter((val: any) => val.includes(event.query));
}
}
}
