import { Component, OnInit, Input, OnDestroy } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { ConfirmationService, MessageService } from 'primeng/api';
import { DialogService, DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { CRUDService } from 'src/app/utilities/crud.service';
import { JOBService } from 'src/app/utilities/job.service';
import { delay } from 'rxjs/operators';
import { JobKpiComponent } from '../../job/job-kpi/job-kpi.component';
import { JobAdminComponent } from '../../job/job-admin/job-admin.component';

@Component({
  selector: 'app-tile',
  templateUrl: './tile.component.html',
  styleUrls: ['./tile.component.scss'],
  providers: [DialogService, ConfirmationService, MessageService]
})
export class TileComponent implements OnInit {
  companyId: any;
  ratingDetails: any[] = [];
  userDetails: any;
  displayDetails: Boolean = false;
  title: any;
  cols: any[] = [];
  constructor(
    private route: ActivatedRoute,
    private crudService: CRUDService,
    private messageService: MessageService,
    private confirmationService: ConfirmationService,
    private router: Router,
    public ref: DynamicDialogRef,
    public config: DynamicDialogConfig,
    public dialogService: DialogService,
    public JobService: JOBService
  ) { }

  ngOnInit() {
    this.companyId = sessionStorage.getItem('company_id');
    this.userDetails = JSON.parse(sessionStorage.getItem('loggedInUser'));
  }

  showRatingDetails() {
    this.displayDetails = true;
    this.title = 'KPI Details';
    this.ratingDetails = [{ job_id: '18773', job_alloc_id: '10912', safety: '5', compliance: '4', quality: '3' },
    { job_id: '18762', job_alloc_id: '10911', safety: '5', compliance: '5', quality: '5' }];
  }

  openJobKPI(jobData){
    const ref = this.dialogService.open(JobKpiComponent, {
      data: {
        jobAllocationId: jobData.job_alloc_id,
        jobId: jobData.job_id
      },
      header: 'Job KPI',
      width: '100%',
      height: '100%'
    });
  }

  openJob(jobData){
    const ref = this.dialogService.open(JobAdminComponent, {
        data: {
          JobId: jobData.Job,
        },
        header: 'Job Info',
        width: '100%',
        height: '100%'
    })
  }
}